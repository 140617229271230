import React, { useEffect, useState } from "react";
import './style.scss';
import { Row, Col, Button, Divider, Select, Modal, Input, Form, Radio } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import NudgesList from "../nudges-list";
import VelocityChart from "../velocity-chart";
import UserCommnet from "../user-comment";
import CommentBox from "../comment-box";
import { loadNudges } from "../../slicers/nudges.slicer";
import { useDispatch } from "react-redux";
import { loadBookmarks } from "../../slicers/bookmarks.slicer";
import { formatDateString1, formatDateString2, formatTime, formatTime1, onScrollStop, reviewTypes } from "../../utilities/helper";
import { getReviewAlerts, loadReviewAlerts, updateInaccurateNudge } from "../../slicers/reviewAlerts.slicer";
import { Tooltip } from 'antd';

const { Option } = Select;

export default function ProfileReview(props) {
	const [selectedNudgeId, setSelectedNudgeId] = useState(props.nudge_id);
	const [selectedNudge, setSelectedNudge] = useState({});
	const dispatch = useDispatch();
	const [chartData, setChartData] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isApprovedModalVisible, setIsApprovedModalVisible] = useState(false);
	const [isDeclinedModalVisible, setIsDeclinedModalVisible] = useState(false);

	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 20 },
	};

	useEffect(() => {
		// console.log("NUDGE CHANGED", selectedNudgeId)
		if (props.data && props.data.length > 0 && !selectedNudgeId) { setSelectedNudgeId(props.data[0].nudge_id); setSelectedNudge(props.data[0]); }
		props.data && props.data.filter(val => val.nudge_id === selectedNudgeId) ? setChartData(props.data.filter(val => val.nudge_id === selectedNudgeId)[0]) : setChartData(null);
		// if (props.data && props.data.slice(props.load).findIndex(val => val.nudge_id === selectedNudgeId) !== -1) setLoad(props.data.findIndex(val => val.nudge_id === selectedNudgeId) + 1)
    // const nudgeListElement = document.getElementById("nudges-list");
    // if (nudgeListElement) nudgeListElement.scrollTop = props.nudgeScrollTop[props.loadReport];
    // if (nudgeListElement) nudgeListElement.scrollTop = nudgeListElement.scrollHeight;
	}, [props.data])

	useEffect(() => {
		props.data && props.data.filter(val => val.nudge_id === selectedNudgeId) ? setChartData(props.data.filter(val => val.nudge_id === selectedNudgeId)[0]) : setChartData(null);
		// console.log("Messages", props.messages.filter((val) => val.nudge_id === selectedNudgeId).map((val2) => (val2)))
	}, [selectedNudgeId])

	const showModal = (reviewType) => {
    if (reviewType === reviewTypes.approved) setIsApprovedModalVisible(true);
    if (reviewType === reviewTypes.declined) setIsDeclinedModalVisible(true);
    if (!reviewType) setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsApprovedModalVisible(false);
	};

	// const handleCancel = () => {
	// 	setIsApprovedModalVisible(false);
	// };

  const handleScroll = (e) => {
    // if (!e.target) return;
    // if (e.target.scrollTop && e.target.scrollTop !== props.nudgeScrollTop[props.loadReport])
    //   props.setNudgeScrollTop({ ...props.nudgeScrollTop, [props.loadReport]: e.target.scrollTop });
  };

	return (
		<div className="performance-wrap">
			<Row gutter={16} style={{ justifyContent: "center" }}>
				<Col span={12}>
					<Row style={{marginBottom: 18}}>
						<Col>
							<label className="label-block filter-label">Status</label>
							<Select defaultValue={props.reviewType} value={props.reviewType} dropdownMatchSelectWidth={false} maxTagCount="responsive" 
                onChange={(val) => {
                  props.setReviewType(val);
                  dispatch(getReviewAlerts(val));
                }}>
								<Option value={reviewTypes.approved}>Inaccurate</Option>
								<Option value={reviewTypes.declined}>Accurate</Option>
								<Option value={reviewTypes.pending}>To be reviewed</Option>
							</Select>
						</Col>
					</Row>
					<div className="performance-left">
						{/* <h2 className="p-title">This Period</h2> */}
						{/* <Radio.Group size="large" style={{ marginBottom: 8 }} onChange={(e) => props.setNudgePeriod({ ...props.nudgePeriod, [props.loadReport]: e.target.value })} value={Number(props.nudgePeriod[props.loadReport])}>
							<Radio.Button value={1}>This Period</Radio.Button>
							<Radio.Button value={0}>Last Period</Radio.Button>
						</Radio.Group> */}
						<h4 style={{ fontSize: 18, lineHeight: '24px', color: '#393939', marginLeft: '24px' }}>This Period</h4>
						{props.data && props.data.length !== 0 ? <div id="nudges-list" className="nudges-list-wrap" onScroll={(e) => onScrollStop(e, handleScroll)}>
							{props.data && props.data.map((val) => (
								<NudgesItem title={val.nudge_description} selected={selectedNudgeId === val.nudge_id} onClick={() => { setSelectedNudgeId(val.nudge_id); setSelectedNudge(val)}} createdAt={val.reviewed_date} createdBy={val.raised_by_user_name} nudgeTrend={val.nudge_trend} isAction={props.reviewType === reviewTypes.pending && Boolean(props.user.is_review_alert)} isDetail={props.reviewType !== reviewTypes.pending} showModal={showModal} />))}
						</div> : props.profile ? <div className="no-data">No alerts</div> : <div className="no-data">No alerts</div>}
						{props.remainingNudges > 0 && <div style={{ textAlign: "center", marginTop: 8 }}>
							<Button onClick={() => dispatch(loadReviewAlerts(props.reviewType, 4))}>Load More</Button>
						</div>}
					</div>
				</Col>
				<Col span={12}>
					{props.data && props.data.length !== 0 && selectedNudgeId && <VelocityChart tab={props.tab} id={selectedNudgeId} scroll={props.scroll} title={chartData && chartData.nudge_kpi} data={chartData} tableData={[]} hideBookMark={true} hideMenu={true} loadData={props.loadData} />}
					{props.data && props.data.length !== 0 && props.messages.filter((val) => val.nudge_id === selectedNudgeId).length !== 0 && <div className="messages-area">
						{props.messages.filter((val) => val.nudge_id === selectedNudgeId).map((val2) => (
							<>
								<UserCommnet message={val2} />
								<Divider style={{ margin: '16px 0', borderColor: '#E6E6E6' }} />
							</>
						))
						}
					</div>}
					{props.data && props.data.length !== 0 && <CommentBox sendMsg={props.sendMsg} id={selectedNudgeId} />}
				</Col>
			</Row>
			<Modal
        title="Comment for Inaccuracy"
        className="review-modal"
        visible={isApprovedModalVisible}
        footer={null}
        onCancel={() => setIsApprovedModalVisible(false)}
      >
        <Form
          name="comment"
          initialValues={{ remember: true }}
          onFinish={(values) => {
            console.log("values", values);
            dispatch(updateInaccurateNudge(selectedNudgeId, reviewTypes.approved, values.review_comment));
          }}
        >
          <Form.Item
            placeholder="Add Comment"
            name="review_comment"
            rules={[
              {
                required: true,
                message: "Please input your review comment!",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button type="primary" size="large" htmlType="submit">
              Add Comment
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Comment for Accuracy"
        className="review-modal"
        visible={isDeclinedModalVisible}
        footer={null}
        onCancel={() => setIsDeclinedModalVisible(false)}
      >
        <Form
          name="comment"
          initialValues={{ remember: true }}
          onFinish={(values) => {
            console.log("values", values);
            dispatch(updateInaccurateNudge(selectedNudgeId, reviewTypes.declined, values.review_comment));
          }}
        >
          <Form.Item
            placeholder="Add Comment"
            name="review_comment"
            rules={[
              {
                required: true,
                message: "Please input your review comment!",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button type="primary" size="large" htmlType="submit">
              Add Comment
            </Button>
          </Form.Item>
        </Form>
      </Modal>
			<Modal
        title="Comment"
        className="review-modal"
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        <div className="comment-text">
          <h4>{selectedNudge.reviewed_by_user_name}:</h4>
          <p>{selectedNudge.review_comment}</p>
          {/* <span>19 Oct 2021, 8:28 PM</span> */}
          <span>{formatDateString2(selectedNudge.reviewed_date)}, {formatTime1(selectedNudge.reviewed_date)}</span>
        </div>
      </Modal>
		</div >
	);
}

function NudgesItem({ selected, onClick, title, createdAt, createdBy, showModal, isAction, isDetail }) {
  return (
    <div className={`nudges-list ${selected ? "selected" : ""}`} onClick={onClick}>
      <div className="nr-left">
        <h4>{title}</h4>
        <ul className="nr-time-date">
          <li>{formatTime(createdAt)}</li>
          <li>{formatDateString1(createdAt)}</li>
          <li>{createdBy && createdBy.split(" ")[0]}</li>
        </ul>
      </div>
      <div className="nr-right">
        {isAction && <><Tooltip title="Inaccurate Alert">
          <a href="javascript:void(0)" onClick={() => showModal(reviewTypes.approved)}>
            <CheckCircleOutlined style={{ fontSize: 24, color: "#00C853" }} />
          </a>
        </Tooltip>
        <Tooltip title="Accurate Alert">
          <a href="javascript:void(0)" onClick={() => showModal(reviewTypes.declined)}>
            <CloseCircleOutlined style={{ fontSize: 24, color: "#EC3225" }} />
          </a>
        </Tooltip></>}
        {isDetail && <a href="javascript:void(0)" onClick={() => showModal()}>
          <img src="./assets/images/icon-message.svg" width="22" />
        </a>}
      </div>
    </div>
  );
}