import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout, Row, Col, Button } from "antd";
import { withRouter } from "react-router-dom";
import Wrapper from "../../components/wrapper";
import Loader from "../../components/loader";
import { getData, isUserLoggedIn } from "../../slicers/nudges.slicer";
import { Typography } from "antd";
import "./nudges.scss";
import NudgeCard from "../../components/nudge-card";
import decreasePNG from "../../../src/assets/images/decrease.png";
import increasePNG from "../../../src/assets/images/increase.png";
import { Mailer } from "nodemailer-react";
import { appInsights } from "../../utilities/appInsights";
import { allBrands, brandSplit, marsPN } from "../../utilities/helper";
const { Text, Link } = Typography;

const { Content } = Layout;

const transport = {
  service: "hotmail",
  auth: {
    user: "rachit.matani@effem.com",
    pass: "Diwali@0901",
  },
};

function Nudges(props) {
  const dispatch = useDispatch();
  const {
    loading,
    nudgesPerfData,
    nudgesVolData,
    nudgesDistData,
    nudgesInnData,
    isLoggedIn,
  } = useSelector((state) => state.nudges);
  const { defaultBrand } = useSelector((state) => state.profile);
  const { thisPeriod } = useSelector((state) => state.refreshDate);
  const isDefaultBrandMarsBDB = defaultBrand.toUpperCase() === allBrands;

  let nudgeText = {
    nudges: [
      {
        nudge_description: "Nutro Core eCom declined at -10.8% L4W CYA",
        nudge_trend: "-10.8",
        id: 0,
      },
      {
        nudge_description: "Nutro grew in FDM at +5.3% L4W CYA",
        nudge_trend: "5.3",
        id: 1,
      },
      {
        nudge_description: "P9 Nutro Core Dry Dog declined at -8.1% L4W CYA.",
        nudge_trend: "-8.1",
        id: 2,
      },
    ],
  };
  const [performanceNudges, setPerformanceNudges] = useState([]);
  const [volShiftNudges, setVolShiftNudges] = useState([]);
  const [distributionNudges, setDistributionNudges] = useState([]);
  const [innovationNudges, setInnovationNudges] = useState([]);

  async function testEmail(fromEmail, toEmail) {
    let defaults = {
      from: fromEmail,
    };

    const WelcomeEmail = ({ firstName }) => ({
      subject: `👋 ${firstName}`,
      body: (
        <div>
          <p>Hello {firstName}!</p>
          <p>Hope you'll enjoy the package!</p>
        </div>
      ),
    });

    const PasswordEmail = <></>; /* ... */
    const ReminderEmail = <></>; /* ... */

    const mailer = Mailer(
      { transport, defaults },
      { WelcomeEmail, PasswordEmail, ReminderEmail }
    );

    mailer.send(
      "WelcomeEmail",
      { firstName: "Mathieu" },
      {
        to: "rachit.matani@effem.com",
      }
    );
  }

  useEffect(() => {
    testEmail();
    dispatch(getData());
  }, [defaultBrand]);

  useEffect(() => {
    nudgesPerfData &&
      nudgesPerfData["nudges"] &&
      setPerformanceNudges(nudgesPerfData["nudges"]);
  }, [nudgesPerfData]);

  useEffect(() => {
    nudgesVolData &&
      nudgesVolData["nudges"] &&
      setVolShiftNudges(nudgesVolData["nudges"]);
  }, [nudgesVolData]);

  useEffect(() => {
    nudgesDistData &&
      nudgesDistData["nudges"] &&
      setDistributionNudges(nudgesDistData["nudges"]);
  }, [nudgesDistData]);

  useEffect(() => {
    nudgesInnData &&
      nudgesInnData["nudges"] &&
      setInnovationNudges(nudgesInnData["nudges"]);
  }, [nudgesInnData]);

  const title = `${
    isDefaultBrandMarsBDB ? marsPN : brandSplit(defaultBrand)
  } - Alerts for period`;

  return (
    <Wrapper heading={"0"}>
      <Content
        className="site-layout-background content-body"
        style={{ background: "#fff" }}
      >
        <Loader loading={loading > 0}>
          <Row>
            <div className="trackboard-info" style={{ marginBottom: 8 }}>
              <p>
                {title} - {thisPeriod}
              </p>
            </div>
          </Row>
          <Row gutter={30}>
            <Col md={12}>
              <NudgeReport
                title="Performance Summary"
                nudges={performanceNudges}
                history={props.history}
                path="/alerts-1"
                trackEventName="ALERTS_FOR_PERFORMANCE"
              />
            </Col>
            <Col md={12}>
              <NudgeReport
                title="Competition Summary"
                nudges={volShiftNudges}
                history={props.history}
                path="/alerts-2"
                trackEventName="ALERTS_FOR_COMPITITION"
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col md={12}>
              <NudgeReport
                title="Distribution Summary"
                nudges={distributionNudges}
                history={props.history}
                path="/alerts-3"
                trackEventName="ALERTS_FOR_DISTRIBUTION"
              />
            </Col>
            <Col md={12}>
              <NudgeReport
                title="Innovation Tracker"
                nudges={innovationNudges}
                history={props.history}
                path="/alerts-4"
                trackEventName="ALERTS_FOR_INNOVATION"
              />
            </Col>
          </Row>
        </Loader>
      </Content>
    </Wrapper>
  );
}

const NudgeReport = ({ title, nudges, history, path, trackEventName }) => (
  <div className="alerts-card">
    <h2>{title}</h2>
    <div className="outer-card-alerts">
      {nudges && nudges.length !== 0 ? (
        nudges.map((val) => {
          return (
            <div
              style={{ marginBottom: "10px" }}
              onClick={() =>
                history.push(
                  path,
                  appInsights.trackEvent({ name: trackEventName }),
                  {
                    nudge_id: val.nudge_id_key,
                  }
                )
              }
            >
              <NudgeCard
                heading={val["nudge_description"] && val["nudge_description"]}
                rate={val["nudge_trend"] ? Number(val["nudge_trend"]) : 0}
                increase={increasePNG}
                decrease={decreasePNG}
              />
            </div>
          );
        })
      ) : (
        <NudgeCard
          heading={"No alerts"}
          rate={-1}
          increase={increasePNG}
          decrease={decreasePNG}
        />
      )}
      <div className="button-div">
        <Button
          type="primary"
          style={{
            display: "flex",
            justifyContent: "flex",
            borderRadius: "5px",
          }}
          onClick={(e) => {
            history.push(path);
          }}
        >
          View all
        </Button>
      </div>
    </div>
  </div>
);

export default withRouter(Nudges);
