import React, { useState, useEffect, Suspense, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthorizationAxios } from '../../utilities/services/api.service';
import { ENDPOINTS } from '../../utilities/constants';
import App from '../../app';
import { setdefaultBrand, setUserDesignation, setUser, setLatestTimeYear } from '../../slicers/profile.slicer';
import { getCompetitorBrands } from '../../slicers/profile.slicer';
import { getDropdownFilters } from '../../slicers/dataSources.slicer';
import { msalInstance } from './msalConfig';
import { appInsights } from "../../utilities/appInsights";
import { isUserLoggedIn } from '../../slicers/nudges.slicer';
import * as Yup from "yup";
import { Formik } from "formik";
import CustomLoader from '../../components/custom-loader/custom-loader';
import { Button, Col, Form, Row, Modal } from 'antd';
import FormSelect from '../../components/forms/FormSelect';
import FormTreeSelect from '../../components/forms/FormTreeSelect';
import { addEditUser, fetchDropdowns } from '../../slicers/admin.slicer';
import { allBrands, userActions } from '../../utilities/helper';
import FormInput from '../../components/forms/FormInput';
import { getFilterData } from '../../slicers/innovation-tracker.slicer';
import LoaderAuth from '../../components/loader-auth';

export const DEVMODE = true;

function CheckAuthorization(props) {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.nudges)
  const { user } = useSelector((state) => state.profile)
  const { loading: adminLoading, dropdowns } = useSelector((state) => state.admin);

  const [state, setstate] = useState({ EMAIL_ID: '' });
  const [loading, setloading] = useState(false);
  const [accessLoading, setAceessLoading] = useState(false);
  const [notAuth, setnotAuth] = useState(true);
  const [error, seterror] = useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [brands, setBrands] = useState([]);


  let counter = 0;
  const maxCounterLimit = 0;

  useEffect(() => {
    setloading(true);
    msalInstance.handleRedirectPromise().then((tokenResponse) => {
      console.log("MSAL Instance Redirect Token", tokenResponse)
      if (!tokenResponse) {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 0) {
          msalInstance.loginRedirect();
        } else {
          sessionStorage.setItem("email", accounts[0].username);
          console.log("refresh")
          getAuthorization({});
        }
      }
      else {
        sessionStorage.setItem("email", tokenResponse.account.username);
        getAuthorization(tokenResponse.account);
        console.log("Login")
        appInsights.trackEvent({ name: 'LOGIN', user_AccountId: tokenResponse.account.username })

        dispatch(isUserLoggedIn());
      }
    }).catch((err) => {
      console.log("Route configuration", err);
      setloading(false);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(dropdowns.brands).length === 0) return;

    const brandsList = Object.keys(dropdowns.brands).map((brand) => ({
      title: brand,
      value: brand,
      children: dropdowns.brands[brand].map((subBrand) => ({
        title: subBrand,
        value: subBrand,
      })),
    }));

    setBrands(brandsList);
  }, [dropdowns]);

  useEffect(() => dispatch(fetchDropdowns()), []);


  async function getAuthorization(msalAccount) {

    // dispatch(getFilterData({}))

    let email = sessionStorage.getItem("email");
    let user_email = encodeURIComponent(JSON.stringify({ user_email: email }));
    let custom_url = `${ENDPOINTS.verifyUser}?${user_email}`;
    // let custom_url = ENDPOINTS.verifyUser + email;
    AuthorizationAxios(custom_url, 'GET', msalAccount).then(val => val.data).then(val2 => {
      if (val2.errorCode === undefined) {
        dispatch(setUser(val2.user));
        dispatch(setUserDesignation(val2.user.user_designation));
        dispatch(getDropdownFilters());
        dispatch(setLatestTimeYear([val2.innovation_latest_year]))
        let temp = new URL(window.location.href)
        if (temp.search.length > 1) {
          try {
            let preFilters = JSON.parse(decodeURIComponent(temp.search.substring(1)))
            dispatch(setdefaultBrand(preFilters.default_brand[0]))
            console.log("Brand Assigned", preFilters.default_brand[0])
          }
          catch (e) {
            dispatch(setdefaultBrand(val2.user.preferred_brand));
          }
        }
        else {
          dispatch(setdefaultBrand(val2.user.preferred_brand));
        }
        setstate({ EMAIL_ID: email });
        setnotAuth(false);
        dispatch(getCompetitorBrands());
      }
      else {
        setnotAuth(true);
        setstate({ EMAIL_ID: email });
      }
      setloading(false);
    }).catch(err => {
      {
        console.log("Get Authorization Error", err)
        setstate({ EMAIL_ID: email });
        setnotAuth(true);
      }
      setloading(false);
    })
  }

  const openInfo = (title) => Modal.info({ title });
  const openSuccess = () => {
    Modal.success({
      width: 350,
      title: 'Access is provided to your ID!',
      onOk: () => window.location = "/",
      okText: "Go to dashboard",
    });
  };

  const openError = () => {
    Modal.error({
      content: 'Something went wrong.',
    });
  };

  // Formik
  const initialValues = {
    user_email: "",
    user_designation: "",
    customer_country: "US",
    preferred_brand: allBrands,
    user_first_name: "",
    uesr_middle_name: "",
    user_last_name: "",
    user_is_admin: 0,
    is_audit_enabled: 0,
    is_review_alert: 0,
  };

  const formikRef = useRef();
  const validationSchema = Yup.object().shape({
    user_first_name: Yup.string().required("Required Field"),
    user_last_name: Yup.string().required("Required Field"),
    user_designation: Yup.string().required("Required Field"),
  });

  const resetForm = () => {
    if (!formikRef.current) return;
    formikRef.current.resetForm();
  };

  function handleRequest(values) {
    values.user_email = state.EMAIL_ID;
    setAceessLoading(true);
    console.log("values", values);
    if (values.user_email) {
      dispatch(
        addEditUser(
          values,
          () => {
            resetForm();
            openSuccess();
            setAceessLoading(false);
          },
          false,
          userActions.requested
        )
      );
    } else openInfo("Email is required");
  }

  return (
    loading ? <LoaderAuth /> : notAuth ? <>
      <div className="authorization">
        <CustomLoader loading={accessLoading || adminLoading} />
        <Row justify="center">
          <Col span={24}>
            <img width={200} src="/assets/images/access.png" />
            <h3>You do not have access to Cascade yet</h3>
            <p>To register please fill in the below details</p>
          </Col>
        </Row>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleRequest}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => (
            <Form layout="vertical" className='request-form'>
              <Row gutter={20}>
                <Col span={6}>
                  <FormInput name="user_first_name" label="First Name *" />
                </Col>
                <Col span={6}>
                  <FormInput name="user_last_name" label="Last Name *" />
                </Col>
                <Col span={6}>
                  <FormSelect
                    name="user_designation"
                    options={dropdowns.roles}
                    label="Role *"
                  />
                </Col>
                <Col span={6}>
                  <FormTreeSelect
                    name="preferred_brand"
                    data={brands}
                    label="Preferred Brand"
                  />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={24}>
                  <Button
                    size='large'
                    type="primary"
                    style={{ width: 160, marginTop: 5 }}
                    onClick={handleSubmit}
                  >
                    SUBMIT
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </> : error ? <div>Error occured, Please reload the App</div> : state.EMAIL_ID ? <Suspense fallback={'Loading...'}><App {...props} /></Suspense> : ""
  );
}

export default CheckAuthorization;
