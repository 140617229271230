import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep, isEqual } from "lodash";
import CustomAxios from "../utilities/services/api.service";
import { ENDPOINTS } from "../utilities/constants";
import {
  decodeTreeSelectValue,
  decodeTreeSelectKey,
  encodeTreeSelectValue,
  encodeTreeSelectKey,
  hasValue,
  allBrands,
} from "../utilities/helper";
import {
  kpiDropdown,
  kpiDropdownSales,
} from "../pages/be-available/baUIHelper";
import {
  competitionBenchmarkFormat,
  trafficSourceData,
  viewByShare,
  shareOfShelfKPI,
  declareAppliedFilters
} from "../pages/be-visible/bvUIHelper";
import { getState } from "../store";

/**
 * Share of Shelf Slicer
 * It manages the Redux State/Reducer/Actions for Share of Shelf usecase.
 */

const initialState = {
  loading: 0,
  macroLoading: false,
  microLoading: false,
  dropdownLoading: false,

  // Top filters data
  pets: [],
  techs: [],
  subTechs: [],
  feedingPhilosophies: [],
  textures: [],
  retailers: [],
  retailerDropdown: [],
  timeAggregates: [],
  yearPeriods: [],
  benchmarkPeriods: [],
  keywordCategory: [],
  subKeywordCategory: [],
  keywordType: [],
  keyword: [],
  // Performance filters data
  competitors: [],
  // Competition & benchmark filters data
  brands: [],
  subBrands: [],
  customerRetailers: [],
  categoryBenchmarks: [],
  competitionBenchmarks: [],
  // kpis: [],

  filters: {
    view: ["share_of_shelf"],
    product_pet: [],
    product_technology: [],
    product_sub_technology: [],
    product_feed_philosophy: [],
    product_texture: [],
    customer_retailer_details: ["Amazon"],
    year_period: [],
    time_aggregate: [],
    benchmark: [],
    product_keyword_category: [],
    product_keyword_sub_technology: [],
    product_keyword_type: [],
    product_keyword: [],
    default_brand: ["NUTRO"],
    view_by: [Object.keys(viewByShare)[0]], //"R- Retailer/S-Sub Brand/C-Competitor" (initial load-R),
    selected_competitor: [],
    performance_summary_kpi: [shareOfShelfKPI[0]],
    deep_dive_list: [],
    selected_retailer: [],
    performance_summary_type: [trafficSourceData[0]],
    performance_summary_keyword: ["ALL"],
    keyword: [],
    keyword_refresh_flag: [0],
    // competition & benchmark
    compare_via: [],
    compare_deep_dive_list: [],
    category_benchmark: [],
    competition_benchmark: [],
  },
  appliedFilters: {
    view: ["share_of_shelf"],
    product_pet: ["ALL"],
    product_technology: ["ALL"],
    product_sub_technology: ["ALL"],
    product_feed_philosophy: ["ALL"],
    product_texture: ["ALL"],
    customer_retailer_details: [],
    year_period: [],
    time_aggregate: [],
    benchmark: [],
    product_keyword_category: [],
    product_keyword_sub_technology: [],
    product_keyword_type: [],
    product_keyword: [],
    default_brand: ["NUTRO"],
    view_by: [Object.keys(viewByShare)[0]],
    selected_competitor: [],
    performance_summary_kpi: [shareOfShelfKPI[0]],
    deep_dive_list: [],
    selected_retailer: [],
    performance_summary_type: [trafficSourceData[0]],
    performance_summary_keyword: ["ALL"],
    keyword: [],
    keyword_refresh_flag: [0],
    // competition & benchmark
    compare_via: [],
    compare_deep_dive_list: [],
    category_benchmark: [],
    competition_benchmark: [],
  },
  cardData: {},
  strategyData: [],
  strategyDataDuplicate: [],
  comparisonTrendData: [],
  deepdiveSingleTrendData: [],
  deepdiveMultiTrendData: [],
  trendData: [],
  macroTableData: [],
  microTableData: [],
  competitionTableData: cloneDeep(competitionBenchmarkFormat),
  showLabel: false,
  // onMouseLeave
  selectedCompetitor: [],
  selectedRetailer: [],
};

const shareOfShelfSlicer = createSlice({
  name: "shareOfShelf",
  initialState,
  reducers: {
    resetBeAvailable: () => initialState,
    setLoading: (state, action) => ({
      ...state,
      loading: state.loading + (action.payload ? 1 : -1),
    }),
    setDropdownLoading: (state, action) => ({
      ...state,
      dropdownLoading: action.payload,
    }),
    setMacroLoading: (state, action) => ({
      ...state,
      macroLoading: action.payload,
    }),
    setMicroLoading: (state, action) => ({
      ...state,
      microLoading: action.payload,
    }),

    // Set top filters
    setPets: (state, action) => ({ ...state, pets: action.payload }),
    setTechs: (state, action) => ({ ...state, techs: action.payload }),
    setSubTechs: (state, action) => ({ ...state, subTechs: action.payload }),
    setFeedingPhilosophies: (state, action) => ({
      ...state,
      feedingPhilosophies: action.payload,
    }),
    setTextures: (state, action) => ({ ...state, textures: action.payload }),
    setRetailers: (state, action) => ({ ...state, retailers: action.payload }),
    setRetailerDropdown: (state, action) => ({
      ...state,
      retailerDropdown: action.payload,
    }),
    setTimeAggregates: (state, action) => ({
      ...state,
      timeAggregates: action.payload,
    }),
    setYearPeriods: (state, action) => ({
      ...state,
      yearPeriods: action.payload,
    }),
    setBenchmarkPeriods: (state, action) => ({
      ...state,
      benchmarkPeriods: action.payload,
    }),
    setKeywordCategory: (state, action) => ({
      ...state,
      keywordCategory: action.payload,
    }),
    setSubKeywordCategory: (state, action) => ({
      ...state,
      subKeywordCategory: action.payload,
    }),
    setKeywordType: (state, action) => ({
      ...state,
      keywordType: action.payload,
    }),
    setKeyword: (state, action) => ({
      ...state,
      keyword: action.payload,
    }),

    // Set performance filters
    setCompetitors: (state, action) => ({
      ...state,
      competitors: action.payload,
    }),

    // Set Competition filters
    setBrands: (state, action) => ({
      ...state,
      brands: action.payload,
    }),
    setSubBrands: (state, action) => ({
      ...state,
      subBrands: action.payload,
    }),
    setCustomerRetailers: (state, action) => ({
      ...state,
      customerRetailers: action.payload,
    }),
    setCategoryBenchmarks: (state, action) => ({
      ...state,
      categoryBenchmarks: action.payload,
    }),
    setCompetitionBenchmarks: (state, action) => ({
      ...state,
      competitionBenchmarks: action.payload,
    }),

    // Report
    setFilters: (state, action) => ({
      ...state,
      filters: action.payload,
    }),
    setAppliedFilters: (state, action) => ({
      ...state,
      appliedFilters: action.payload,
    }),
    setCardData: (state, action) => ({
      ...state,
      cardData: action.payload,
    }),
    setStrategyData: (state, action) => ({
      ...state,
      strategyData: action.payload,
    }),
    setDuplicateStrategyData: (state, action) => ({
      ...state,
      strategyDataDuplicate: action.payload,
    }),
    setComparisonTrendData: (state, action) => ({
      ...state,
      comparisonTrendData: action.payload,
    }),
    setDeepdiveSingleTrendData: (state, action) => ({
      ...state,
      deepdiveSingleTrendData: action.payload,
    }),
    setDeepdiveMultiTrendData: (state, action) => ({
      ...state,
      deepdiveMultiTrendData: action.payload,
    }),
    setTrendData: (state, action) => ({
      ...state,
      trendData: action.payload,
    }),
    setDeepdiveMultiTrendData: (state, action) => ({
      ...state,
      deepdiveMultiTrendData: action.payload,
    }),
    setMacroTableData: (state, action) => ({
      ...state,
      macroTableData: action.payload,
    }),
    setMicroTableData: (state, action) => ({
      ...state,
      microTableData: action.payload,
    }),
    setCompetitionTableData: (state, action) => ({
      ...state,
      competitionTableData: action.payload,
    }),

    // on Mouse Leave
    setSelectedCompetitor: (state, action) => ({
      ...state,
      selectedCompetitor: action.payload,
    }),
    // on Mouse Leave
    setSelectedRetailer: (state, action) => ({
      ...state,
      selectedRetailer: action.payload,
    }),
    setShowLabel: (state, action) => ({
      ...state,
      showLabel: action.payload,
    }),
  },
});

export const {
  resetBeAvailable,
  setLoading,
  setDropdownLoading,
  setMacroLoading,
  setMicroLoading,
  setPets,
  setTechs,
  setSubTechs,
  setFeedingPhilosophies,
  setTextures,
  setRetailers,
  setRetailerDropdown,
  setTimeAggregates,
  setYearPeriods,
  setBenchmarkPeriods,
  setKeywordCategory,
  setSubKeywordCategory,
  setKeywordType,
  setKeyword,
  setCompetitors,
  setBrands,
  setSubBrands,
  setCustomerRetailers,
  setCategoryBenchmarks,
  setCompetitionBenchmarks,
  // setKpis,

  setFilters,
  setAppliedFilters,
  setCardData,
  setStrategyData,
  setDuplicateStrategyData,
  setComparisonTrendData,
  setDeepdiveSingleTrendData,
  setDeepdiveMultiTrendData,
  setTrendData,
  setMacroTableData,
  setMicroTableData,
  setCompetitionTableData,

  // on Mouse Leave
  setSelectedCompetitor,
  setSelectedRetailer,
  setShowLabel
} = shareOfShelfSlicer.actions;

export const getInitialFilters = () => cloneDeep(initialState.filters);

export const getInitialBottomFilters = () => {
  const state = getState().shareOfShelf;

  const initialFilters = cloneDeep(initialState.filters);
  const oldFilters = cloneDeep(state.filters);

  return {
    ...initialFilters,
    product_pet: oldFilters.product_pet,
    product_technology: oldFilters.product_technology,
    product_sub_technology: oldFilters.product_sub_technology,
    product_feed_philosophy: oldFilters.product_feed_philosophy,
    product_texture: oldFilters.product_texture,
    customer_retailer_details: oldFilters.customer_retailer_details,
    year_period: oldFilters.year_period,
    time_aggregate: oldFilters.time_aggregate,
    benchmark: oldFilters.benchmark,
    product_keyword_category: oldFilters.product_keyword_category,
    product_keyword_sub_technology: oldFilters.product_keyword_sub_technology,
    product_keyword_type: oldFilters.product_keyword_type,
    product_keyword: oldFilters.product_keyword,
  }
}

export const processFilters = (filters, getState) => {
  let state = getState().shareOfShelf;
  let brand = getState().profile.defaultBrand;

  let tempFilters = { ...filters };
  if (
    tempFilters.product_pet &&
    tempFilters.product_pet.length === 0
  ) {
    tempFilters["product_pet"] = "ALL";
  }
  if (
    tempFilters.product_technology &&
    tempFilters.product_technology.length === 0
  ) {
    tempFilters["product_technology"] = "ALL";
  }
  if (
    tempFilters.product_sub_technology &&
    tempFilters.product_sub_technology.length === 0
  ) {
    tempFilters["product_sub_technology"] = "ALL";
  }
  if (
    tempFilters.product_feed_philosophy &&
    tempFilters.product_feed_philosophy.length === 0
  ) {
    tempFilters["product_feed_philosophy"] = "ALL";
  }
  if (
    tempFilters.product_texture &&
    tempFilters.product_texture.length === 0
  ) {
    tempFilters["product_texture"] = "ALL";
  }
  if (
    tempFilters.product_keyword_category &&
    (tempFilters.product_keyword_category.length === 0 ||
      tempFilters.product_keyword_category.length ===
      state.keywordCategory.length)
  ) {
    tempFilters["product_keyword_category"] = "ALL";
  }
  if (
    tempFilters.product_keyword_sub_technology &&
    (tempFilters.product_keyword_sub_technology.length === 0 ||
      tempFilters.product_keyword_sub_technology.length ===
      state.subKeywordCategory.length)
  ) {
    tempFilters["product_keyword_sub_technology"] = "ALL";
  }
  if (
    tempFilters.product_keyword_type &&
    (tempFilters.product_keyword_type.length === 0 ||
      tempFilters.product_keyword_type.length === state.keywordType.length)
  ) {
    tempFilters["product_keyword_type"] = "ALL";
  }
  if (
    tempFilters.product_keyword &&
    (tempFilters.product_keyword.length === 0 ||
      tempFilters.product_keyword.length === state.keyword.length)
  ) {
    tempFilters["product_keyword"] = "ALL";
  }
  if (tempFilters.keyword_refresh_flag) tempFilters["keyword_refresh_flag"] = tempFilters.keyword_refresh_flag[0];
  if (brand) {
    if (brand.split(":").length === 2) {
      tempFilters["product_brand"] = brand.split(":")[0];
      tempFilters["product_secondary_brand"] = brand.split(":")[1];
    } else {
      tempFilters["product_brand"] = brand;
      tempFilters["product_secondary_brand"] = "";
    }
  }

  if (tempFilters["selected_competitor"])
    tempFilters["selected_competitor"] = encodeTreeSelectKey(
      tempFilters["selected_competitor"]
    );
  if (tempFilters["category_benchmark"])
    tempFilters["category_benchmark"] = encodeTreeSelectValue(
      tempFilters["category_benchmark"]
    );
  if (tempFilters["competition_benchmark"])
    tempFilters["competition_benchmark"] = encodeTreeSelectValue(
      tempFilters["competition_benchmark"]
    );
  if (tempFilters["competition_kpi"])
    tempFilters["competition_kpi"] = encodeTreeSelectKey(
      tempFilters["competition_kpi"]
    );

  return tempFilters;
};

export const updateRetailerDropdown = (filters, retailers, dispatch) => {
  const newFilters = { ...filters };
  if (retailers.length === 0) return [];
  const retailerDropdown = retailers.filter(
    (retailer) => !filters.customer_retailer_details.includes(retailer)
  );

  const filteredSelectedRetailers = [...newFilters.selected_retailer].filter(
    (retailer) => retailerDropdown.includes(retailer)
  );
  const selectedRetailers = [...newFilters.selected_retailer];
  if (!isEqual(filteredSelectedRetailers.sort(), selectedRetailers.sort())) {
    newFilters.selected_retailer = filteredSelectedRetailers;
    dispatch(setSelectedRetailer(filteredSelectedRetailers));
  }

  dispatch(setRetailerDropdown(retailerDropdown));
  return newFilters;
};

const setFiltersDefault = (
  dispatch,
  response,
  getState,
  initialLoad = false,
  brandChanged = false
) => {
  const state = getState().shareOfShelf;
  const filters = state.filters;
  const appliedFilters = state.appliedFilters;

  const newFilters = { ...filters };
  if (response.data.default_customer_retailer_details)
    if (filters.customer_retailer_details[0] === "Amazon") {
      newFilters.customer_retailer_details = [
        "Amazon"
        // response.data.default_customer_retailer_details,
      ]
    }
  if (response.data.default_time_aggregate)
    newFilters.time_aggregate = [response.data.default_time_aggregate];
  if (newFilters.year_period.length === 0 && response.data.default_year_period)
    newFilters.year_period = [response.data.default_year_period];
  if (response.data.default_benchmark_period)
    newFilters.benchmark = [response.data.default_benchmark_period];

  newFilters.product_pet = newFilters.product_pet.filter(item => (response.data.product_pet || state.pets).includes(item));
  newFilters.product_technology = newFilters.product_technology.filter(item => (response.data.product_technology || state.techs).includes(item));
  newFilters.product_sub_technology = newFilters.product_sub_technology.filter(item => (response.data.product_sub_technology || state.subTechs).includes(item));
  newFilters.product_feed_philosophy = newFilters.product_feed_philosophy.filter(item => (response.data.product_feed_philosophy || state.feedingPhilosophies).includes(item));
  newFilters.product_texture = newFilters.product_texture.filter(item => (response.data.product_texture || state.textures).includes(item));
  newFilters.product_keyword_category = newFilters.product_keyword_category.filter(item => (response.data.product_keyword_category || state.keywordCategory).includes(item));
  newFilters.product_keyword_sub_technology = newFilters.product_keyword_sub_technology.filter(item => (response.data.product_keyword_sub_technology || state.subKeywordCategory).includes(item));
  newFilters.product_keyword_type = newFilters.product_keyword_type.filter(item => (response.data.product_keyword_type || state.keywordType).includes(item));
  newFilters.product_keyword = newFilters.product_keyword.filter(item => (response.data.product_keyword || state.keyword).includes(item));

  dispatch(setFilters(newFilters));

  if (initialLoad) {
    const newAppliedFilters = { ...appliedFilters };
    if (response.data.default_customer_retailer_details)
      if (filters.customer_retailer_details[0] === "Amazon") {
        newAppliedFilters.customer_retailer_details = [
          "Amazon"
          // response.data.default_customer_retailer_details,
        ];
      }
    if (response.data.default_time_aggregate)
      newAppliedFilters.time_aggregate = [response.data.default_time_aggregate];
    if (response.data.default_year_period)
      newAppliedFilters.year_period = [response.data.default_year_period];
    if (response.data.default_benchmark_period)
      newAppliedFilters.benchmark = [response.data.default_benchmark_period];

    if (!brandChanged)
      dispatch(getFilterData({
        // default filters
        customer_retailer_details: newAppliedFilters.customer_retailer_details,
        time_aggregate: newAppliedFilters.time_aggregate,
        year_period: newAppliedFilters.year_period,
        benchmark: newAppliedFilters.benchmark,
        keyword_refresh_flag: newAppliedFilters.keyword_refresh_flag,
      }))
    // Filters Applied: Remove the selected values which are not prsent in the dropdown's items.
    newAppliedFilters.product_pet = newFilters.product_pet;
    newAppliedFilters.product_technology = newFilters.product_technology;
    newAppliedFilters.product_sub_technology = newFilters.product_sub_technology;
    newAppliedFilters.product_feed_philosophy = newFilters.product_feed_philosophy;
    newAppliedFilters.product_texture = newFilters.product_texture;
    newAppliedFilters.product_keyword_category = newFilters.product_keyword_category;
    newAppliedFilters.product_keyword_sub_technology = newFilters.product_keyword_sub_technology;
    newAppliedFilters.product_keyword_type = newFilters.product_keyword_type;
    newAppliedFilters.product_keyword = newFilters.product_keyword;

    dispatch(setAppliedFilters(declareAppliedFilters(newAppliedFilters, state)));

  }

  return newFilters;
};

const getFilterForBrandChange = (filters) => {
  let newFilters = {};
  if (filters.product_pet.length > 0) {
    newFilters.product_pet = filters.product_pet;
  }
  if (filters.product_technology.length > 0) {
    newFilters.product_pet = filters.product_pet;
    newFilters.product_technology = filters.product_technology;
  }

  newFilters = {
    ...newFilters,
    customer_retailer_details: filters.customer_retailer_details,
    time_aggregate: filters.time_aggregate,
    year_period: filters.year_period,
    benchmark: filters.benchmark,
    keyword_refresh_flag: filters.keyword_refresh_flag,
  }

  return newFilters;
}

export const getAllFilters = (isBrandChanged = false, changeSelectedBrand = null) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.shareOfShelfDropdown, "GET")
    .then((response) => {
      if (response.data.product_pet)
        dispatch(setPets(response.data.product_pet));
      if (response.data.product_technology)
        dispatch(setTechs(response.data.product_technology));
      if (response.data.product_sub_technology)
        dispatch(setSubTechs(response.data.product_sub_technology));
      if (response.data.product_feed_philosophy)
        dispatch(setFeedingPhilosophies(response.data.product_feed_philosophy));
      if (response.data.product_texture)
        dispatch(setTextures(response.data.product_texture));
      if (response.data.customer_retailer_details)
        dispatch(setRetailers(response.data.customer_retailer_details));
      if (response.data.year_period_dropdown)
        dispatch(setYearPeriods(response.data.year_period_dropdown));
      if (response.data.time_aggregate_dropdown)
        dispatch(setTimeAggregates(response.data.time_aggregate_dropdown));
      if (response.data.benchmark_dropdown)
        dispatch(setBenchmarkPeriods(response.data.benchmark_dropdown));
      if (response.data.product_keyword_category)
        dispatch(setKeywordCategory(response.data.product_keyword_category));
      if (response.data.product_keyword_sub_technology)
        dispatch(setSubKeywordCategory(response.data.product_keyword_sub_technology));
      if (response.data.product_keyword_type)
        dispatch(setKeywordType(response.data.product_keyword_type));
      if (response.data.product_keyword)
        dispatch(setKeyword(response.data.product_keyword));

      const newFilters = setFiltersDefault(dispatch, response, getState, true, true);
      if (response.data.customer_retailer_details)
        updateRetailerDropdown(
          newFilters,
          response.data.customer_retailer_details,
          dispatch
        );

      dispatch(getComparisonTrendData(newFilters));
      dispatch(getCardData(newFilters));
      dispatch(getScatterChartData(newFilters));
      dispatch(getCompetitionFilterData(newFilters));
      dispatch(getTrendData(newFilters));

      if (isBrandChanged && changeSelectedBrand) {
        changeSelectedBrand();
        dispatch(getFilterData(getFilterForBrandChange(getState().shareOfShelf.filters)));
      }

      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
    });
};

export const getFilterData = (filters, loadPage = false, isBrandChanged = false, changeSelectedBrand = null) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setDropdownLoading(true));
  CustomAxios(ENDPOINTS.shareOfShelfDropdown + "?" + encodedURL, "GET")
    .then((response) => {
      if (response.data.product_pet)
        dispatch(setPets(response.data.product_pet));
      if (response.data.product_technology)
        dispatch(setTechs(response.data.product_technology));
      if (response.data.product_sub_technology)
        dispatch(setSubTechs(response.data.product_sub_technology));
      if (response.data.product_feed_philosophy)
        dispatch(setFeedingPhilosophies(response.data.product_feed_philosophy));
      if (response.data.product_texture)
        dispatch(setTextures(response.data.product_texture));
      if (response.data.customer_retailer_details)
        dispatch(setRetailers(response.data.customer_retailer_details));
      if (response.data.year_period_dropdown)
        dispatch(setYearPeriods(response.data.year_period_dropdown));
      if (response.data.time_aggregate_dropdown)
        dispatch(setTimeAggregates(response.data.time_aggregate_dropdown));
      if (response.data.benchmark_dropdown)
        dispatch(setBenchmarkPeriods(response.data.benchmark_dropdown));
      if (response.data.product_keyword_category)
        dispatch(setKeywordCategory(response.data.product_keyword_category));
      if (response.data.product_keyword_sub_technology)
        dispatch(setSubKeywordCategory(response.data.product_keyword_sub_technology));
      if (response.data.product_keyword_type)
        dispatch(setKeywordType(response.data.product_keyword_type));
      if (response.data.product_keyword)
        dispatch(setKeyword(response.data.product_keyword));

      const newFilters = setFiltersDefault(dispatch, response, getState, loadPage);

      if (loadPage) {
        dispatch(getComparisonTrendData(newFilters));
        dispatch(getCardData(newFilters));
        dispatch(getScatterChartData(newFilters));
        dispatch(getCompetitionFilterData(newFilters));
        dispatch(getTrendData(newFilters));
      }

      if (isBrandChanged && changeSelectedBrand) {
        changeSelectedBrand();
        dispatch(getFilterData(getFilterForBrandChange(getState().shareOfShelf.filters)));
      }

      dispatch(setDropdownLoading(false));
    })
    .catch((err) => {
      dispatch(setDropdownLoading(false));
    });
};

export const getCompetitionFilterData =
  (filters) => async (dispatch, getState) => {
    let tempFilters = processFilters(filters, getState);
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

    dispatch(setLoading(true));
    CustomAxios(ENDPOINTS.ecomCompetitionDropdown + "?" + encodedURL, "GET")
      .then((response) => {
        if (response.data.brand_list)
          dispatch(setBrands(response.data.brand_list));
        if (response.data.sub_brand_list)
          dispatch(setSubBrands(response.data.sub_brand_list));
        if (response.data.retailer_list)
          dispatch(setCustomerRetailers(response.data.retailer_list));

        if (response.data.category_benchmark)
          dispatch(
            setCategoryBenchmarks(
              decodeTreeSelectValue(response.data.category_benchmark)
            )
          );

        if (response.data.competition_benchmark)
          dispatch(
            setCompetitionBenchmarks(
              decodeTreeSelectValue(response.data.competition_benchmark)
            )
          );

        if (response.data.competitor_list)
          dispatch(
            setCompetitors(decodeTreeSelectKey(response.data.competitor_list))
          );

        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));

      });
  };

export const getCardData = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.shareOfShelfCardData + "?" + encodedURL, "GET")
    .then((response) => {
      dispatch(setCardData(response.data));

      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      dispatch(setCardData(initialState.cardData));
    });
};

const updateDeepdiveList = (comparisonTrendData, dispatch, filters) => {
  const newFilters = { ...filters };
  if (newFilters.performance_summary_kpi[0] === kpiDropdownSales)
    newFilters.performance_summary_kpi = [kpiDropdown[0]];

  if (newFilters.view_by[0] === "R")
    newFilters.deep_dive_list = newFilters.customer_retailer_details;
  else if (comparisonTrendData.length === 0) newFilters.deep_dive_list = [];
  else
    newFilters.deep_dive_list = [
      comparisonTrendData[0][viewByShare[newFilters.view_by[0]]],
    ];

  dispatch(setFilters(newFilters));
  return newFilters;
};

export const getComparisonTrendData =
  (filters, loadTableData = true) =>
    async (dispatch, getState) => {
      let tempFilters = processFilters(filters, getState);
      let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

      dispatch(setLoading(true));
      CustomAxios(ENDPOINTS.shareOFShelfComparisonTrend + "?" + encodedURL, "GET")
        .then((response) => {
          const filtersInState = cloneDeep(getState().shareOfShelf.filters);

          if (response.data.default_brand && filters.view_by[0] == "C") {
            const defaultBrand = response.data.default_brand;
            const defaultCompetitors = [];
            Object.keys(defaultBrand).forEach((brand) => {
              defaultBrand[brand].forEach((type) => {
                defaultCompetitors.push(`${brand}:${type}`);
              });
            });

            const selectedCompetitorsState = [
              ...filtersInState.selected_competitor,
            ];
            if (
              !isEqual(selectedCompetitorsState.sort(), defaultCompetitors.sort())
            ) {
              filtersInState.selected_competitor = defaultCompetitors;
              dispatch(setSelectedCompetitor(defaultCompetitors));
            }
          }

          let newFilters;
          if (response.data.perf_summary) {
            const comparisonTrend = response.data.perf_summary.perf_grid.map(
              ({ bar_label, kpi_value, share_of_shelf }) => ({
                [viewByShare[filters.view_by[0]]]: bar_label,
                [filters.performance_summary_kpi[0]]: kpi_value,
                [filters.performance_summary_type[0]]: share_of_shelf,
              })
            );
            dispatch(setComparisonTrendData(comparisonTrend));
            newFilters = updateDeepdiveList(
              comparisonTrend,
              dispatch,
              filtersInState
            );
          } else {
            dispatch(setComparisonTrendData(initialState.comparisonTrendData));
            newFilters = updateDeepdiveList(
              initialState.comparisonTrendData,
              dispatch,
              filtersInState
            );
          }


          dispatch(getDeepdiveTrendData(newFilters));

          if (loadTableData) {
            dispatch(getMacroTableData(newFilters));
            // dispatch(getMicroTableData(newFilters));
          }

          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          const filtersInState = getState().shareOfShelf.filters;
          dispatch(setComparisonTrendData(initialState.comparisonTrendData));
          updateDeepdiveList(
            initialState.comparisonTrendData,
            dispatch,
            filtersInState
          );
        });
    };

export const getDeepdiveTrendData = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));
  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.shareOFShelfDeepDiveTrend + "?" + encodedURL, "GET")
    .then((response) => {
      if (response.data.perf_summary_deepdive) {
        if (filters.deep_dive_list.length > 1) {
          const { line_kpi, ...lineData } =
            response.data.perf_summary_deepdive.line_dict;
          const deepdiveTrend = [];
          Object.keys(lineData).forEach((trend) => {
            deepdiveTrend.push(
              ...lineData[trend].map(({ label, value }) => ({
                Periods: label,
                [filters.performance_summary_kpi[0]]: value,
                Trend: trend,
              }))
            );
          });
          dispatch(setDeepdiveMultiTrendData(deepdiveTrend));
        } else {
          const deepdiveTrend =
            response.data.perf_summary_deepdive.bar_dict.bar_grid.map(
              ({ time_year_period, bar_value, share_of_shelf }) => ({
                Periods: time_year_period,
                [filters.performance_summary_type[0]]: share_of_shelf,
                [filters.performance_summary_kpi[0]]: bar_value,
              })
            );
          dispatch(setDeepdiveSingleTrendData(deepdiveTrend));
        }
      } else {
        dispatch(
          setDeepdiveSingleTrendData(initialState.deepdiveSingleTrendData)
        );
        dispatch(
          setDeepdiveMultiTrendData(initialState.deepdiveMultiTrendData)
        );
      }

      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      dispatch(
        setDeepdiveSingleTrendData(initialState.deepdiveSingleTrendData)
      );
      dispatch(setDeepdiveMultiTrendData(initialState.deepdiveMultiTrendData));
    });
};

export const getScatterChartData = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.shareOfShelfScatterChart + "?" + encodedURL, "GET")
    .then((response) => {
      dispatch(setStrategyData(response.data));
      dispatch(setDuplicateStrategyData(response.data));

      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      dispatch(setDuplicateStrategyData(initialState.strategyDataDuplicate));
      dispatch(setStrategyData(initialState.strategyData));
    });
};

export const getTrendData = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));
  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.shareOfShelfTraffic_chart + "?" + encodedURL, "GET")
    .then((response) => {
      const trafficTrend = response.data.bar_grid.map(
        ({ time_year_period, bar_value, line_value }) => ({
          Periods: time_year_period,
          "Traffic Share": bar_value,
          "Share of Shelf": line_value,
        })
      );
      dispatch(setTrendData(trafficTrend));
      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      dispatch(setTrendData(initialState.trendData));
    });
};

const processMacro = (parentIndex, responseData, stateData, parameters = null) => {

  // 0-0-0
  if (hasValue(parentIndex)) {
    const indexes = parentIndex.toString().split("-");

    let row = stateData[indexes[0]];
    indexes.forEach((index, i) => {
      if (i > 0) {
        row = row.table_hierarchy_data[index];
      }
    });

    let hierarchyCount = 3;
    if (parameters && parameters.filters.view_by?.[0] === "C") {
      hierarchyCount = 4;
    }


    row.table_hierarchy_data = responseData.map((data, index) => ({
      ...data,
      index: `${parentIndex}-${index}`,
      table_hierarchy_data: indexes.length < hierarchyCount ? [] : null,
    }));
    return stateData;
  }

  return responseData.map((data, index) => ({
    ...data,
    index,
    table_hierarchy_data: data.table_key === "Total" ? null : [],
  }));
};

export const getMacroTableData =
  (filters, index = null) =>
    async (dispatch, getState) => {
      let tempFilters = processFilters(filters, getState);
      let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

      dispatch(setMacroLoading(true));
      CustomAxios(ENDPOINTS.shareShelfMarcroTable + "?" + encodedURL, "GET")
        .then((response) => {
          const { defaultBrand } = getState().profile;
          const macroTableData = cloneDeep(
            getState().shareOfShelf.macroTableData
          );
          const sortData = (data) => {
            const selectedRetailer = filters.customer_retailer_details[0];
            if (!selectedRetailer) return data;

            return [...data].sort((a, b) => {
              if (a.table_key === "Total") return -1;
              if (b.table_key === "Total") return 1;

              const petTechA = a.total_share_of_shelf_pet_tech?.[selectedRetailer]?.abs || 0;
              const petTechB = b.total_share_of_shelf_pet_tech?.[selectedRetailer]?.abs || 0;

              if (petTechA !== petTechB) return petTechB - petTechA;
              return a.table_key.localeCompare(b.table_key);
            });
          };

          const sortedData = sortData(response.data);
          let processData = processMacro(index, sortedData, macroTableData, { defaultBrand, filters });

          dispatch(
            setMacroTableData(processData)
          );

          dispatch(setMacroLoading(false));
        })
        .catch(() => {
          dispatch(setMacroLoading(false));
          const macroTableData = cloneDeep(
            getState().shareOfShelf.macroTableData
          );
          dispatch(
            setMacroTableData(
              processMacro(index, initialState.macroTableData, macroTableData)
            )
          );
        });
    };

export const getMicroTableData = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setMicroLoading(true));
  CustomAxios(ENDPOINTS.shareShelfMircroTable + "?" + encodedURL, "GET")
    .then((response) => {
      dispatch(setMicroTableData(response.data));

      dispatch(setMicroLoading(false));
    })
    .catch((err) => {
      dispatch(setMicroLoading(false));
      dispatch(setMicroTableData(initialState.microTableData));
    });
};

const processCompetitionTableData = (parentIndex, responseData, stateData) => {
  stateData[parentIndex].table_hierarchy_data = responseData.map(
    (item, childIndex) => ({ ...item, index: `${parentIndex}-${childIndex}` })
  );
  return stateData;
};

export const getInternalBenchmarkData =
  (filters) => async (dispatch, getState) => {
    let tempFilters = processFilters(filters, getState);
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

    dispatch(setLoading(true));
    CustomAxios(ENDPOINTS.sosBenchmarkInternal + "?" + encodedURL, "GET")
      .then((response) => {
        const competitionTableData = cloneDeep(
          getState().shareOfShelf.competitionTableData
        );
        dispatch(
          setCompetitionTableData(
            processCompetitionTableData(0, response.data, competitionTableData)
          )
        );

        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        const competitionTableData = cloneDeep(
          getState().shareOfShelf.competitionTableData
        );
        dispatch(
          setCompetitionTableData(
            processCompetitionTableData(0, [], competitionTableData)
          )
        );
      });
  };
export const getCategoryBenchmarkData =
  (filters) => async (dispatch, getState) => {
    if (filters.category_benchmark.length === 0) {
      dispatch(setLoading(true));
      setTimeout(() => {
        dispatch(setLoading(false));
        const competitionTableData = cloneDeep(
          getState().shareOfShelf.competitionTableData
        );
        dispatch(
          setCompetitionTableData(
            processCompetitionTableData(2, [], competitionTableData)
          )
        );
      });
    } else {
      let tempFilters = processFilters(filters, getState);
      let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

      dispatch(setLoading(true));
      CustomAxios(ENDPOINTS.sosBenchmarkCategory + "?" + encodedURL, "GET")
        .then((response) => {
          const competitionTableData = cloneDeep(
            getState().shareOfShelf.competitionTableData
          );
          dispatch(
            setCompetitionTableData(
              processCompetitionTableData(
                2,
                response.data,
                competitionTableData
              )
            )
          );

          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          const competitionTableData = cloneDeep(
            getState().shareOfShelf.competitionTableData
          );
          dispatch(
            setCompetitionTableData(
              processCompetitionTableData(2, [], competitionTableData)
            )
          );
        });
    }
  };

export const getCompetitionBenchmarkData =
  (filters) => async (dispatch, getState) => {
    if (filters.competition_benchmark.length === 0) {
      dispatch(setLoading(true));
      setTimeout(() => {
        dispatch(setLoading(false));
        const competitionTableData = cloneDeep(
          getState().shareOfShelf.competitionTableData
        );
        dispatch(
          setCompetitionTableData(
            processCompetitionTableData(1, [], competitionTableData)
          )
        );
      });
    } else {
      let tempFilters = processFilters(filters, getState);
      let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

      dispatch(setLoading(true));
      CustomAxios(ENDPOINTS.sosBenchmarkCompetition + "?" + encodedURL, "GET")
        .then((response) => {
          const competitionTableData = cloneDeep(
            getState().shareOfShelf.competitionTableData
          );
          dispatch(
            setCompetitionTableData(
              processCompetitionTableData(
                1,
                response.data,
                competitionTableData
              )
            )
          );

          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          const competitionTableData = cloneDeep(
            getState().shareOfShelf.competitionTableData
          );
          dispatch(
            setCompetitionTableData(
              processCompetitionTableData(1, [], competitionTableData)
            )
          );
        });
    }
  };

export default shareOfShelfSlicer.reducer;
