import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep, isEqual } from "lodash";
import CustomAxios from "../utilities/services/api.service";
import { ENDPOINTS } from "../utilities/constants";
import {
  decodeTreeSelectValue,
  decodeTreeSelectKey,
  encodeTreeSelectValue,
  encodeTreeSelectKey,
} from "../utilities/helper";
import {
  competitionBenchmarkFormat,
  defaultCompetitionKpi,
  kpiDropdown,
  kpiDropdownSales,
  rowKeyMacro,
  rowKeyMicro,
  viewBy,
  declareAppliedFilters,
} from "../pages/be-available/baUIHelper";
import { getState } from "../store";

/**
 * BeAvailalbe Slicer
 * It manages the Redux State/Reducer/Actions for BeAvailalbe usecase.
 */

// Initial state of the Redux store for the BeAvailable use case
const initialState = {
  loading: 0,
  microLoading: false,
  dropdownLoading: false,

  // Top filters data
  pets: [],
  techs: [],
  subTechs: [],
  feedingPhilosophies: [],
  textures: [],
  retailers: [],
  timeAggregates: [],
  yearPeriods: [],
  benchmarkPeriods: [],

  // Performance filters data
  competitors: [],

  // Competition & benchmark filters data
  brands: [],
  subBrands: [],
  customerRetailers: [],
  categoryBenchmarks: [],
  competitionBenchmarks: [],
  // kpis: [],

  filters: {
    view: ["be_available"],
    product_pet: [],
    product_technology: [],
    product_sub_technology: [],
    product_feed_philosophy: [],
    product_texture: [],
    customer_retailer_details: ["Amazon"],
    year_period: [],
    time_aggregate: [],
    benchmark: [],
    default_brand: ["NUTRO"],
    view_by: [Object.keys(viewBy)[0]], //"R- Retailer/S-Sub Brand/C-Competitor" (initial load-R),
    selected_competitor: [],
    performance_summary_comparison_kpi: [kpiDropdown[0]],
    performance_summary_deepdive_kpi: [kpiDropdown[0]],
    deep_dive_list: [],
    selected_retailer: [],

    // competition & benchmark
    compare_via: [],
    compare_deep_dive_list: [],
    category_benchmark: [],
    competition_benchmark: [],
    competition_kpi: [...defaultCompetitionKpi],
    upc_graph_kpi: [],
  },
  appliedFilters: {
    view: ["be_available"],
    product_pet: ["ALL"],
    product_technology: ["ALL"],
    product_sub_technology: ["ALL"],
    product_feed_philosophy: ["ALL"],
    product_texture: ["ALL"],
    customer_retailer_details: [],
    year_period: [],
    time_aggregate: [],
    benchmark: [],
    default_brand: ["NUTRO"],
    view_by: [Object.keys(viewBy)[0]],
    selected_competitor: [],
    performance_summary_comparison_kpi: [kpiDropdown[0]],
    performance_summary_deepdive_kpi: [kpiDropdown[0]],
    deep_dive_list: [],
    selected_retailer: [],

    // competition & benchmark
    compare_via: [],
    compare_deep_dive_list: [],
    category_benchmark: [],
    competition_benchmark: [],
    competition_kpi: [...defaultCompetitionKpi],
    upc_graph_kpi: [],
  },
  cardData: {},
  comparisonTrendData: [],
  deepdiveSingleTrendData: [],
  deepdiveMultiTrendData: [],
  macroTableData: [],
  microTableData: [],
  competitionTableData: cloneDeep(competitionBenchmarkFormat),

  // onMouseLeave
  selectedCompetitor: [],
};

// Create a Redux slice using createSlice from @reduxjs/toolkit
const beAvailableSlicer = createSlice({
  name: "beAvailable",
  initialState,
  reducers: {
    resetBeAvailable: () => initialState,
    setLoading: (state, action) => ({
      ...state,
      loading: state.loading + (action.payload ? 1 : -1),
    }),
    setMicroLoading: (state, action) => ({
      ...state,
      microLoading: action.payload,
    }),
    setDropdownLoading: (state, action) => ({
      ...state,
      dropdownLoading: action.payload,
    }),
    // Set top filters
    setPets: (state, action) => ({ ...state, pets: action.payload }),
    setTechs: (state, action) => ({ ...state, techs: action.payload }),
    setSubTechs: (state, action) => ({ ...state, subTechs: action.payload }),
    setFeedingPhilosophies: (state, action) => ({
      ...state,
      feedingPhilosophies: action.payload,
    }),
    setTextures: (state, action) => ({ ...state, textures: action.payload }),
    setRetailers: (state, action) => ({ ...state, retailers: action.payload }),
    setTimeAggregates: (state, action) => ({
      ...state,
      timeAggregates: action.payload,
    }),
    setYearPeriods: (state, action) => ({
      ...state,
      yearPeriods: action.payload,
    }),
    setBenchmarkPeriods: (state, action) => ({
      ...state,
      benchmarkPeriods: action.payload,
    }),

    // Set performance filters
    setCompetitors: (state, action) => ({
      ...state,
      competitors: action.payload,
    }),

    // Set Competition filters
    setBrands: (state, action) => ({
      ...state,
      brands: action.payload,
    }),
    setSubBrands: (state, action) => ({
      ...state,
      subBrands: action.payload,
    }),
    setCustomerRetailers: (state, action) => ({
      ...state,
      customerRetailers: action.payload,
    }),
    setCategoryBenchmarks: (state, action) => ({
      ...state,
      categoryBenchmarks: action.payload,
    }),
    setCompetitionBenchmarks: (state, action) => ({
      ...state,
      competitionBenchmarks: action.payload,
    }),

    // Report
    setFilters: (state, action) => ({
      ...state,
      filters: action.payload,
    }),
    setAppliedFilters: (state, action) => ({
      ...state,
      appliedFilters: action.payload,
    }),
    setCardData: (state, action) => ({
      ...state,
      cardData: action.payload,
    }),
    setComparisonTrendData: (state, action) => ({
      ...state,
      comparisonTrendData: action.payload,
    }),
    setDeepdiveSingleTrendData: (state, action) => ({
      ...state,
      deepdiveSingleTrendData: action.payload,
    }),
    setDeepdiveMultiTrendData: (state, action) => ({
      ...state,
      deepdiveMultiTrendData: action.payload,
    }),
    setMacroTableData: (state, action) => ({
      ...state,
      macroTableData: action.payload,
    }),
    setMicroTableData: (state, action) => ({
      ...state,
      microTableData: action.payload,
    }),
    setCompetitionTableData: (state, action) => ({
      ...state,
      competitionTableData: action.payload,
    }),

    // on Mouse Leave
    setSelectedCompetitor: (state, action) => ({
      ...state,
      selectedCompetitor: action.payload,
    }),
  },
});

// Export actions created by the Redux slice for use in components and middleware
export const {
  resetBeAvailable,
  setLoading,
  setMicroLoading,
  setDropdownLoading,
  setPets,
  setTechs,
  setSubTechs,
  setFeedingPhilosophies,
  setTextures,
  setRetailers,
  setTimeAggregates,
  setYearPeriods,
  setBenchmarkPeriods,
  setCompetitors,

  setBrands,
  setSubBrands,
  setCustomerRetailers,
  setCategoryBenchmarks,
  setCompetitionBenchmarks,
  // setKpis,

  setFilters,
  setAppliedFilters,
  setCardData,
  setComparisonTrendData,
  setDeepdiveSingleTrendData,
  setDeepdiveMultiTrendData,
  setMacroTableData,
  setMicroTableData,
  setCompetitionTableData,

  // on Mouse Leave
  setSelectedCompetitor,
} = beAvailableSlicer.actions;

// Function to get a deep clone of the initial filters state
export const getInitialFilters = () => cloneDeep(initialState.filters);

export const getInitialBottomFilters = () => {
  const state = getState().beAvailable;

  const initialFilters = cloneDeep(initialState.filters);
  const oldFilters = cloneDeep(state.filters);

  return {
    ...initialFilters,
    product_pet: oldFilters.product_pet,
    product_technology: oldFilters.product_technology,
    product_sub_technology: oldFilters.product_sub_technology,
    product_feed_philosophy: oldFilters.product_feed_philosophy,
    product_texture: oldFilters.product_texture,
    customer_retailer_details: oldFilters.customer_retailer_details,
    year_period: oldFilters.year_period,
    time_aggregate: oldFilters.time_aggregate,
    benchmark: oldFilters.benchmark,
  }
}

export const processFilters = (filters, getState) => {
  // Get the current state of the beAvailable slice from the Redux store
  let state = getState().beAvailable;
  let brand = getState().profile.defaultBrand;

  let tempFilters = { ...filters };
  // Special case for pet filter
  if (
    tempFilters.product_pet &&
    tempFilters.product_pet.length === 0
  ) {
    tempFilters["product_pet"] = "ALL";
  }
  //Special case for technology filter
  if (
    tempFilters.product_technology &&
    tempFilters.product_technology.length === 0
  ) {
    tempFilters["product_technology"] = "ALL";
  }
  //Special case for sub technology filter
  if (
    tempFilters.product_sub_technology &&
    tempFilters.product_sub_technology.length === 0
  ) {
    tempFilters["product_sub_technology"] = "ALL";
  }
  // Special case for feeding philosophy filter
  if (
    tempFilters.product_feed_philosophy &&
    tempFilters.product_feed_philosophy.length === 0
  ) {
    tempFilters["product_feed_philosophy"] = "ALL";
  }
  // Special case for texture filter
  if (
    tempFilters.product_texture &&
    tempFilters.product_texture.length === 0

  ) {
    tempFilters["product_texture"] = "ALL";
  }
  // Handle the brand filter based on the default brand in the profile state
  if (brand) {
    if (brand.split(":").length === 2) {
      tempFilters["product_brand"] = brand.split(":")[0];
      tempFilters["product_secondary_brand"] = brand.split(":")[1];
    } else {
      tempFilters["product_brand"] = brand;
      tempFilters["product_secondary_brand"] = "";
    }
  }
  // Encode certain filters using helper functions
  if (tempFilters["selected_competitor"])
    tempFilters["selected_competitor"] = encodeTreeSelectKey(
      tempFilters["selected_competitor"]
    );
  if (tempFilters["category_benchmark"])
    tempFilters["category_benchmark"] = encodeTreeSelectValue(
      tempFilters["category_benchmark"]
    );
  if (tempFilters["competition_benchmark"])
    tempFilters["competition_benchmark"] = encodeTreeSelectValue(
      tempFilters["competition_benchmark"]
    );
  if (tempFilters["competition_kpi"])
    tempFilters["competition_kpi"] = encodeTreeSelectKey(
      tempFilters["competition_kpi"]
    );

  return tempFilters;
};

// Function to set default filters and applied filters in the state
const setFiltersDefault = (
  dispatch,
  response,
  getState,
  initialLoad = false,
  brandChanged = false
) => {
  // Extract the required data from the state and response
  const state = getState().beAvailable;
  const filters = state.filters;
  const appliedFilters = state.appliedFilters;

  const newFilters = { ...filters };
  if (
    // newFilters.customer_retailer_details.length === 0 &&
    response.data.default_customer_retailer_details
  )
    if (filters.customer_retailer_details[0] === "Amazon") {
      newFilters.customer_retailer_details = [
        "Amazon"
        // response.data.default_customer_retailer_details,
      ];
    }
  if (
    // newFilters.time_aggregate.length === 0 &&
    response.data.default_time_aggregate
  )
    newFilters.time_aggregate = [response.data.default_time_aggregate];
  if (newFilters.year_period.length === 0 && response.data.default_year_period)
    newFilters.year_period = [response.data.default_year_period];
  if (
    // newFilters.benchmark.length === 0 &&
    response.data.default_benchmark_period
  )
    newFilters.benchmark = [response.data.default_benchmark_period];

  // Remove the selected values which are not prsent in the dropdown's items.
  newFilters.product_pet = newFilters.product_pet.filter(item => (response.data.product_pet || state.pets).includes(item));
  newFilters.product_technology = newFilters.product_technology.filter(item => (response.data.product_technology || state.techs).includes(item));
  newFilters.product_sub_technology = newFilters.product_sub_technology.filter(item => (response.data.product_sub_technology || state.subTechs).includes(item));
  newFilters.product_feed_philosophy = newFilters.product_feed_philosophy.filter(item => (response.data.product_feed_philosophy || state.feedingPhilosophies).includes(item));
  newFilters.product_texture = newFilters.product_texture.filter(item => (response.data.product_texture || state.textures).includes(item));

  dispatch(setFilters(newFilters));  // Dispatch the new filters to update the state

  if (initialLoad) {
    const newAppliedFilters = { ...appliedFilters };
    if (response.data.default_customer_retailer_details)
      if (filters.customer_retailer_details[0] === "Amazon") {
        newAppliedFilters.customer_retailer_details = [
          "Amazon"
          // response.data.default_customer_retailer_details,
        ];
      }
    if (response.data.default_time_aggregate)
      newAppliedFilters.time_aggregate = [response.data.default_time_aggregate];
    if (response.data.default_year_period)
      newAppliedFilters.year_period = [response.data.default_year_period];
    if (response.data.default_benchmark_period)
      newAppliedFilters.benchmark = [response.data.default_benchmark_period];
 

    newAppliedFilters.product_pet = newFilters.product_pet;
    newAppliedFilters.product_technology = newFilters.product_technology;
    newAppliedFilters.product_feed_philosophy = newFilters.product_feed_philosophy;
    newAppliedFilters.product_texture = newFilters.product_texture;
    dispatch(setAppliedFilters(declareAppliedFilters(newAppliedFilters, state)));
  }

  return newFilters;
};

const getFilterForBrandChange = (filters) => {
  let newFilters = {};
  if (filters.product_pet.length > 0) {
    newFilters.product_pet = filters.product_pet;
  }
  if (filters.product_technology.length > 0) {
    newFilters.product_pet = filters.product_pet;
    newFilters.product_technology = filters.product_technology;
  }

  newFilters = {
    ...newFilters,
    customer_retailer_details: filters.customer_retailer_details,
    time_aggregate: filters.time_aggregate,
    year_period: filters.year_period,
    benchmark: filters.benchmark,
  }

  return newFilters;
}

// Function to fetch all the initial filters data from the server
export const getAllFilters = (isBrandChanged = false, changeSelectedBrand = null) => async (dispatch, getState) => {
  dispatch(setLoading(true));

  CustomAxios(ENDPOINTS.ecomDropdown, "GET")
    .then((response) => {
      if (response.data.product_pet)
        dispatch(setPets(response.data.product_pet));
      if (response.data.product_technology)
        dispatch(setTechs(response.data.product_technology));
      if (response.data.product_sub_technology)
        dispatch(setSubTechs(response.data.product_sub_technology));
      if (response.data.product_feed_philosophy)
        dispatch(setFeedingPhilosophies(response.data.product_feed_philosophy));
      if (response.data.product_texture)
        dispatch(setTextures(response.data.product_texture));
      if (response.data.customer_retailer_details)
        dispatch(setRetailers(response.data.customer_retailer_details));
      if (response.data.year_period_dropdown)
        dispatch(setYearPeriods(response.data.year_period_dropdown));
      if (response.data.time_aggregate_dropdown)
        dispatch(setTimeAggregates(response.data.time_aggregate_dropdown));
      if (response.data.benchmark_dropdown)
        dispatch(setBenchmarkPeriods(response.data.benchmark_dropdown));

      const newFilters = setFiltersDefault(dispatch, response, getState, true, true);
      dispatch(getComparisonTrendData(newFilters));
      dispatch(getCardData(newFilters));
      dispatch(getCompetitionFilterData(newFilters));

      if (isBrandChanged && changeSelectedBrand) {
        changeSelectedBrand();
        dispatch(getFilterData(getFilterForBrandChange(getState().beAvailable.filters)));
      }

      dispatch(setLoading(false));
    })
    .catch((err) => {
      console.log("err", err);
      dispatch(setLoading(false));
    });
};

// Function to fetch filter data based on the provided filters
export const getFilterData = (filters, loadPage = false, isBrandChanged = false, changeSelectedBrand = null) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setDropdownLoading(true));
  CustomAxios(ENDPOINTS.ecomDropdown + "?" + encodedURL, "GET")
    .then((response) => {
      if (response.data.product_pet)
        dispatch(setPets(response.data.product_pet));
      if (response.data.product_technology)
        dispatch(setTechs(response.data.product_technology));
      if (response.data.product_sub_technology)
        dispatch(setSubTechs(response.data.product_sub_technology));
      if (response.data.product_feed_philosophy)
        dispatch(setFeedingPhilosophies(response.data.product_feed_philosophy));
      if (response.data.product_texture)
        dispatch(setTextures(response.data.product_texture));
      if (response.data.customer_retailer_details)
        dispatch(setRetailers(response.data.customer_retailer_details));
      if (response.data.year_period_dropdown)
        dispatch(setYearPeriods(response.data.year_period_dropdown));
      if (response.data.time_aggregate_dropdown)
        dispatch(setTimeAggregates(response.data.time_aggregate_dropdown));
      if (response.data.benchmark_dropdown)
        dispatch(setBenchmarkPeriods(response.data.benchmark_dropdown));

      const newFilters = setFiltersDefault(dispatch, response, getState, loadPage);
      if (loadPage) {
        dispatch(getComparisonTrendData(newFilters));
        dispatch(getCardData(newFilters));
        dispatch(getCompetitionFilterData(newFilters));
      }

      if (isBrandChanged && changeSelectedBrand) {
        changeSelectedBrand();
        dispatch(getFilterData(getFilterForBrandChange(getState().beAvailable.filters)));
      }

      dispatch(setDropdownLoading(false));
    })
    .catch((err) => {
      dispatch(setDropdownLoading(false));
    });
};

// Function to fetch competition filter data based on the provided filters
export const getCompetitionFilterData =
  (filters) => async (dispatch, getState) => {
    let tempFilters = processFilters(filters, getState);
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

    dispatch(setLoading(true));
    CustomAxios(ENDPOINTS.ecomCompetitionDropdown + "?" + encodedURL, "GET")
      .then((response) => {
        if (response.data.brand_list)
          dispatch(setBrands(response.data.brand_list));
        if (response.data.sub_brand_list)
          dispatch(setSubBrands(response.data.sub_brand_list));
        if (response.data.retailer_list)
          dispatch(setCustomerRetailers(response.data.retailer_list));

        if (response.data.category_benchmark)
          dispatch(
            setCategoryBenchmarks(
              decodeTreeSelectValue(response.data.category_benchmark)
            )
          );

        if (response.data.competition_benchmark)
          dispatch(
            setCompetitionBenchmarks(
              decodeTreeSelectValue(response.data.competition_benchmark)
            )
          );

        if (response.data.competitor_list)
          dispatch(
            setCompetitors(decodeTreeSelectKey(response.data.competitor_list))
          );

        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));

      });
  };

// Function to fetch card data based on the provided filters
export const getCardData = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.cardData + "?" + encodedURL, "GET")
    .then((response) => {
      dispatch(setCardData(response.data));

      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      dispatch(setCardData(initialState.cardData));
    });
};

// Function to update the deep dive list and fetch deep dive trend data
const updateDeepdiveList = (comparisonTrendData, dispatch, filters) => {
  const newFilters = { ...filters };
  if (newFilters.performance_summary_deepdive_kpi[0] === kpiDropdownSales)
    newFilters.performance_summary_deepdive_kpi = [kpiDropdown[0]];

  if (newFilters.view_by[0] === "R")
    newFilters.deep_dive_list = newFilters.customer_retailer_details;
  else if (comparisonTrendData.length === 0) newFilters.deep_dive_list = [];
  else
    newFilters.deep_dive_list = [
      comparisonTrendData[0][viewBy[newFilters.view_by[0]]],
    ];

  dispatch(setFilters(newFilters));
  return newFilters;
};

// Function to fetch comparison trend data based on the provided filters
export const getComparisonTrendData =
  (filters) => async (dispatch, getState) => {
    let tempFilters = processFilters(filters, getState);
    tempFilters.performance_summary_kpi =
      tempFilters.performance_summary_comparison_kpi;
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

    dispatch(setLoading(true));
    CustomAxios(ENDPOINTS.comparisonTrend + "?" + encodedURL, "GET")
      .then((response) => {
        const filtersInState = cloneDeep(getState().beAvailable.filters);

        if (response.data.default_brand && filters.view_by[0] == "C") {
          const defaultBrand = response.data.default_brand;
          const defaultCompetitors = [];
          Object.keys(defaultBrand).forEach((brand) => {
            defaultBrand[brand].forEach((type) => {
              defaultCompetitors.push(`${brand}:${type}`);
            });
          });

          const selectedCompetitorsState = [
            ...filtersInState.selected_competitor,
          ];
          if (
            !isEqual(selectedCompetitorsState.sort(), defaultCompetitors.sort())
          ) {
            filtersInState.selected_competitor = defaultCompetitors;
            dispatch(setSelectedCompetitor(defaultCompetitors));
          }
        }

        let newFilters;
        if (response.data.perf_summary) {
          const comparisonTrend = response.data.perf_summary.perf_grid.map(
            ({ bar_label, kpi_value, sales_value }) => ({
              [viewBy[filters.view_by[0]]]: bar_label,
              [filters.performance_summary_comparison_kpi[0]]: kpi_value,
              "Sales": sales_value,
            })
          );
          dispatch(setComparisonTrendData(comparisonTrend));
          newFilters = updateDeepdiveList(
            comparisonTrend,
            dispatch,
            filtersInState
          );
        } else {
          dispatch(setComparisonTrendData(initialState.comparisonTrendData));
          newFilters = updateDeepdiveList(
            initialState.comparisonTrendData,
            dispatch,
            filtersInState
          );
        }


        dispatch(getDeepdiveTrendData(newFilters));
        dispatch(getMacroTableData(newFilters));
        dispatch(getMicroTableData(newFilters));

        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        const filtersInState = getState().beAvailable.filters;
        dispatch(setComparisonTrendData(initialState.comparisonTrendData));
        updateDeepdiveList(
          initialState.comparisonTrendData,
          dispatch,
          filtersInState
        );
      });
  };

// Function to fetch deep dive trend data based on the provided filters
export const getDeepdiveTrendData = (filters) => async (dispatch, getState) => {
  // Process filters before sending to the server
  let tempFilters = processFilters(filters, getState);
  tempFilters.performance_summary_kpi =
    tempFilters.performance_summary_deepdive_kpi;
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));
  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.deepdiveTrend + "?" + encodedURL, "GET")
    .then((response) => {
      // Update the state with the fetched data
      if (response.data.perf_summary_deepdive) {
        // Handle different cases based on the number of deep dive items
        if (filters.deep_dive_list.length > 1) {
          const { line_kpi, ...lineData } =
            response.data.perf_summary_deepdive.line_dict;
          const deepdiveTrend = [];
          Object.keys(lineData).forEach((trend) => {
            deepdiveTrend.push(
              ...lineData[trend].map(({ label, value }) => ({
                Periods: label,
                [filters.performance_summary_deepdive_kpi[0]]: value,
                Trend: trend,
              }))
            );
          });
          dispatch(setDeepdiveMultiTrendData(deepdiveTrend));
        } else {
          const deepdiveTrend =
            response.data.perf_summary_deepdive.bar_dict.bar_grid.map(
              ({ time_year_period, bar_value, sales_value }) => ({
                Periods: time_year_period,
                [filters.performance_summary_deepdive_kpi[0]]: bar_value,
                "Sales": sales_value,
              })
            );
          dispatch(setDeepdiveSingleTrendData(deepdiveTrend));
        }
      } else {
        dispatch(
          setDeepdiveSingleTrendData(initialState.deepdiveSingleTrendData)
        );
        dispatch(
          setDeepdiveMultiTrendData(initialState.deepdiveMultiTrendData)
        );
      }

      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      setDeepdiveSingleTrendData(initialState.deepdiveSingleTrendData);
      setDeepdiveMultiTrendData(initialState.deepdiveMultiTrendData);
    });
};

// Helper function to process macro table data recursively
function processMacro(data, parentKey = null) {
  return data.reduce((previousValue, currentValue) => {
    const table_hierarchy_data = processMacro(
      currentValue.table_hierarchy_data || [],
      currentValue.table_key
    );

    previousValue.push({
      ...currentValue,
      [rowKeyMacro]: currentValue.table_hierarchy_data
        ? { parent_key: [currentValue.table_key] }
        : { parent_key: [parentKey], child_key: [currentValue.table_key] },
      table_hierarchy_data: table_hierarchy_data.length
        ? table_hierarchy_data
        : null,
    });
    return previousValue;
  }, []);
}

// Function to fetch macro table data based on the provided filters
export const getMacroTableData = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.marcroTable + "?" + encodedURL, "GET")
    .then((response) => {
      dispatch(setMacroTableData(processMacro(response.data)));

      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      dispatch(setMacroTableData(initialState.macroTableData));
    });
};

// Helper function to process micro table data
function processMicro(data) {
  return data.map((record, index) => ({ ...record, [rowKeyMicro]: index }));
}

// Function to fetch micro table data based on the provided filters
export const getMicroTableData = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setMicroLoading(true));
  CustomAxios(ENDPOINTS.mircroTable + "?" + encodedURL, "GET")
    .then((response) => {
      if (response.data && Array.isArray(response.data))
        dispatch(setMicroTableData(processMicro(response.data)));
      else dispatch(setMicroTableData(initialState.microTableData));
      dispatch(setMicroLoading(false));
    })
    .catch((err) => {
      dispatch(setMicroLoading(false));
      dispatch(setMicroTableData(initialState.microTableData));
    });
};

// Helper function to process competition table data
const processCompetitionTableData = (parentIndex, responseData, stateData) => {
  stateData[parentIndex].table_hierarchy_data = responseData.map(
    (item, childIndex) => ({ ...item, index: `${parentIndex}-${childIndex}` })
  );
  return stateData;
};

// Function to fetch internal benchmark data based on the provided filters
export const getInternalBenchmarkData =
  (filters) => async (dispatch, getState) => {
    let tempFilters = processFilters(filters, getState);
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

    dispatch(setLoading(true));
    CustomAxios(ENDPOINTS.benchmarkInternal + "?" + encodedURL, "GET")
      .then((response) => {
        const competitionTableData = cloneDeep(
          getState().beAvailable.competitionTableData
        );
        dispatch(
          setCompetitionTableData(
            processCompetitionTableData(0, response.data, competitionTableData)
          )
        );

        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        const competitionTableData = cloneDeep(
          getState().beAvailable.competitionTableData
        );
        dispatch(
          setCompetitionTableData(
            processCompetitionTableData(0, [], competitionTableData)
          )
        );
      });
  };

// Function to fetch category benchmark data based on the provided filters
export const getCategoryBenchmarkData =
  (filters) => async (dispatch, getState) => {
    if (filters.category_benchmark.length === 0) {
      dispatch(setLoading(true));
      setTimeout(() => {
        dispatch(setLoading(false));
        const competitionTableData = cloneDeep(
          getState().beAvailable.competitionTableData
        );
        dispatch(
          setCompetitionTableData(
            processCompetitionTableData(1, [], competitionTableData)
          )
        );
      });
    } else {
      let tempFilters = processFilters(filters, getState);
      let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

      dispatch(setLoading(true));
      CustomAxios(ENDPOINTS.benchmarkCategory + "?" + encodedURL, "GET")
        .then((response) => {
          const competitionTableData = cloneDeep(
            getState().beAvailable.competitionTableData
          );
          dispatch(
            setCompetitionTableData(
              processCompetitionTableData(
                1,
                response.data,
                competitionTableData
              )
            )
          );

          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          const competitionTableData = cloneDeep(
            getState().beAvailable.competitionTableData
          );
          dispatch(
            setCompetitionTableData(
              processCompetitionTableData(1, [], competitionTableData)
            )
          );
        });
    }
  };

// Function to fetch competition benchmark data based on the provided filters
export const getCompetitionBenchmarkData =
  (filters) => async (dispatch, getState) => {
    if (filters.competition_benchmark.length === 0) {
      // If competition benchmark is not selected, simulate loading and set empty data
      dispatch(setLoading(true));
      setTimeout(() => {
        dispatch(setLoading(false));
        const competitionTableData = cloneDeep(
          getState().beAvailable.competitionTableData
        );
        dispatch(
          setCompetitionTableData(
            processCompetitionTableData(2, [], competitionTableData)
          )
        );
      });
    } else {
      let tempFilters = processFilters(filters, getState);
      let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

      dispatch(setLoading(true));
      CustomAxios(ENDPOINTS.benchmarkCompetition + "?" + encodedURL, "GET")
        .then((response) => {
          const competitionTableData = cloneDeep(
            getState().beAvailable.competitionTableData
          );
          dispatch(
            setCompetitionTableData(
              processCompetitionTableData(
                2,
                response.data,
                competitionTableData
              )
            )
          );

          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          const competitionTableData = cloneDeep(
            getState().beAvailable.competitionTableData
          );
          dispatch(
            setCompetitionTableData(
              processCompetitionTableData(2, [], competitionTableData)
            )
          );
        });
    }
  };

export default beAvailableSlicer.reducer;
