import React, {useState} from "react";
import { Button, Input } from "antd";

const { TextArea } = Input;

export default function CommentBox(props) {
    const [comment, setComment] = useState(null)
    return(
        <div className="comment-input">
            <TextArea
            placeholder="Type your comment here"
            value={comment}
            onChange={(e)=>setComment(e.target.value)}
            autoSize={{ minRows: 2, maxRows: 6 }}
            style={{marginBottom: 12}}
            />
            <div style={{textAlign: "right"}}>
                <Button type="primary" onClick={()=>{props.sendMsg(comment,props.id);setComment(null)}}>Submit</Button>
            </div>
        </div>
    )
}