import { orderBy } from "lodash";
import pptxgen from "pptxgenjs";
import { formatNumber } from "../../utilities/helper";
import { getState } from "../../store";
import { rowKey } from "./itUIHelper";
import ExcelJS from "exceljs/dist/es5/exceljs.browser.js";
import * as FileSaver from "file-saver";
import {exportConsumerReview} from '../../utilities/exports/excel.js'

/**
 * CustomTable
 * This function creates a custom table based on the given columns and data.
 */

function createCustomTable(columns, data,tableLaunchYear, isDataBold = true, pageSize = 20) {
    
    const headerOptions = {
      // bold: true,
      align: "center",
      fill: "0000A0",
      color: "ffffff",
      border: { type: "solid" },
    };
    const dataOptions = {
      // bold: isDataBold,
      align: "center",
      border: { type: "solid" },
    };
  
    const header = [];
    const subHeader = [];
    const rows = [];
    const pages = [];

    columns.forEach(({ title, children, dataIndex }) => {
      
     
      if (children) {
        header.push({
          text: title,
          options: { ...headerOptions, colspan: children.length },
        });
        subHeader.push(
          ...children.map(({ title }) => ({
            text: title,
            options: headerOptions,
          }))
        );
      } else {
        if(dataIndex === 'fact_revenue_er' || dataIndex === 'fact_revenue_target'){
          header.push({  text: `${title} (${tableLaunchYear})`, options: headerOptions });
        }else{
          header.push({  text: title, options: headerOptions });
        }
      }
    });
  
    
    const hasChildren = subHeader.length > 0;
    rows.push(header);
    if (hasChildren) rows.push(subHeader);
  
    // data
    data.forEach((record, index) => {
      const row = [];
      columns.forEach(({ dataIndex, children, renderPPT }) => {
        let recLength = record?.retailer_data
        if (children) {
          children.forEach(({ dataIndex, renderPPT }) => {
            
            if (renderPPT)
              row.push({
                text: renderPPT(record[dataIndex]),
                options: dataOptions,
              });
            else {
              row.push({ text: dataIndex === 'fact_percent_er_estimated_store_sale' || dataIndex === 'fact_percent_acv_reach_actual' ? `${formatNumber(record[dataIndex])} %` :dataIndex === 'er_dollar_per_tdp_target' || dataIndex === 'er_dollar_per_tdp_actual'  ? `$${formatNumber(record[dataIndex])}` : formatNumber(record[dataIndex]) , options: { ...dataOptions, align: 'left', margin: recLength && recLength ? 0 : [0, 0, 0, 0.2] } });
            }
          });
        } else {
            
          if (renderPPT)
            row.push({
              text: renderPPT(record[dataIndex]),
              options: dataOptions,
            });
          else row.push({ text: dataIndex === 'fact_rsv_target_yee' || dataIndex === 'fact_rsv_actuals_yee'  ? `$${formatNumber(record[dataIndex])}` : formatNumber(record[dataIndex]), options: { ...dataOptions, align: 'left', margin: recLength && recLength ? 0 : [0, 0, 0, 0.2] } });
        }
      });
  
      rows.push(row);
      if ((index + 1) % pageSize === 0) {
        pages.push(rows.splice(0));
        rows.push(header);
        if (hasChildren) rows.push(subHeader);
      }
    });
    if (data.length < pageSize) pages.push(rows);
    return pages;
  }

  /** 
   * Innovation ppt
   * This function exports data to a PowerPoint presentation using the pptxgen library.
  */
export const pptExportInnovation = async(gsvChart,filename, tableData, cardData, tableCol, itColsDistribution, trendData, appliedFilters,filters, defaultBrand, data2, tableLaunchYear, ratings_grid, reviewData, itColsSentiment, reviewFilterBy, localFilter, sortOrder, selectedRow) =>{
  const { skuList, retailerList } = getState().innovationTracker;
  
    let pres = new pptxgen();

    // 1. Define the layout
    pres.defineLayout({ name: "A3", width: 13, height: 7.5 });
    pres.layout = "A3";
    // 2. Add a Slide to the presentation
    const slide = pres.addSlide();
    slide.addText(`Filters applied: ​Brand – ${defaultBrand}; Pet – ${appliedFilters?.product_pet}; Technology – ${appliedFilters?.product_technology}; Innovation Type – ${appliedFilters?.innovation_type}; Launch Year – ${filters?.time_year};  Innovation – ${appliedFilters?.innovation}`, { x: 0.5, y: 0.5, fontSize: 12, fontFace: "Mars Centra" });

    slide.addText(`GSV : Actual vs Demand Forecast - Total Market`,{ x: 4.7, y: 0.9, fontSize: 16, bold:true, fontFace: "Mars Centra" })

    let inno = gsvChart.map((item) => item.innovation.split("_")[1]);
    let uniqueinno = [...new Set(inno)];
    let opt = {
      x: 0.5,
      y: 1.3,
      w: "95%",
      h: "80%",
      chartArea: { fill: { color: "F1F1F1" } },
      barDir: "col",
      barGrouping: "stacked",
      catAxisLabelFontFace: "Arial",
      catAxisLabelFontSize: 12,
      showLegend: false,
      valAxisLabelFormatCode: '[>=1000000]#,##0.00,,"M";[>=1000]#,##0,"K"',
    };
    const label = gsvChart.map((i)=>i.period)
    const uniqlabel = [...new Set(label)]

    let chartType = [];

    for (let innovation of uniqueinno) {
      let dataL = gsvChart.filter((i) => i.innovation.split("_")[1] === innovation).map((i)=>i)
      const color_random = ["#" + ("00000" + Math.floor(Math.random() * Math.pow(16, 6)).toString(16)).slice(-6)];
      let actualValue = []
      let forcastValue = []
      for(let label of uniqlabel){
        const actualFind = dataL.find((i)=>i.period === label &&  i.innovation.split("_")[0] === 'ACTUAL')
        const forcastFind = dataL.find((i)=>i.period === label)
        if(actualFind){
          actualValue.push(actualFind.value)
        } 
        else {
          actualValue.push(null)
        }
    }
      for(let label of uniqlabel){
       
        const forcastFind = dataL.find((i)=>i.period === label)
        if(forcastFind){
          forcastValue.push(forcastFind.value)
        } 
        else {
          forcastValue.push(null)
        }
    }
      chartType.push(
        {
          type: pres.charts.LINE,
          data: [
            {
              name: innovation,
              labels: uniqlabel,
              values: actualValue
            }
          ],
          options: {
            barGrouping: "standard",
            lineDash: "solid",
            chartColors: color_random
          }
        },
        {
          type: pres.charts.LINE,
          data: [
            {
              name: innovation,
              labels:uniqlabel,
              values: forcastValue
            }
          ],
          options: {
            barGrouping: "standard",
            lineDash: "dash",
            chartColors: color_random
          }
        }
      );
    }
    
slide.addChart(chartType, opt);

if(cardData && tableData.length > 0){
const slide2 = pres.addSlide();
slide2.addText(`Filters applied: ​Brand – ${defaultBrand}; Pet – ${appliedFilters?.product_pet}; Technology – ${appliedFilters?.product_technology}; Innovation Type – ${appliedFilters?.innovation_type}; Launch Year – ${filters?.time_year};  Innovation – ${appliedFilters?.innovation}`, { x: 0.5, y: 0.5, fontSize: 12, fontFace: "Mars Centra" });

slide2.addText(`Launch Performance: Total Market`,{ x: 5, y: 0.8, fontSize: 16, bold:true, fontFace: "Mars Centra" })

    const textOptionsChart = {
        w: 2.8,
        h: 1.3,
        y: 1.6,
        align: "center",
        line: { width: "2", color: "dcdcdc" },
        fill: "ffffff",
        shape: pres.ShapeType.roundRect,
        rectRadius: 0.2,
        fontSize: 20,
        margin: 0.5
      };
      slide2.addText([
        { text: `MKT RSV YEE (${cardData?.launch_year})`, options: { breakLine: true, fontSize: 14, fontFace: "Mars Centra", bold: true } },
        { text: "\n" },
        { text: `Target`, options: { breakLine: true, fontSize: 12, fontFace: "Mars Centra" }},
        { text: `${formatNumber(cardData?.fact_rsv_target_yee === 0 ? "NA" : `$${formatNumber(cardData?.fact_rsv_target_yee)}`)}`, options: { breakLine: true, fontSize: 12, fontFace: "Mars Centra" }},
        { text: `Actual`, options: { breakLine: true, fontSize: 12, fontFace: "Mars Centra" }},
        { text: `${formatNumber(cardData?.fact_rsv_actuals_yee === 0 ? "NA" : `$${formatNumber(cardData?.fact_rsv_actuals_yee)}`)}  ${formatNumber(cardData.rsv_yee_variance) === 0 ? "NA" : `${formatNumber(cardData.rsv_yee_variance)}%`}`, options: { breakLine: true, fontSize: 12, fontFace: "Mars Centra" }},
      ], {
        ...textOptionsChart,
        x: 3,
        y: 1,
      });
      slide2.addText([
        { text: `GSV YEE (${cardData?.launch_year})`, options: { breakLine: true, fontSize: 14, fontFace: "Mars Centra", bold: true } },
        { text: "\n" },
        { text: `Target`, options: { breakLine: true, fontSize: 12, fontFace: "Mars Centra" }},
        { text: `${formatNumber(cardData?.fact_gsv_target_yee === 0 ? "NA" : `$${formatNumber(cardData?.fact_gsv_target_yee)}`)}`, options: { breakLine: true, fontSize: 12, fontFace: "Mars Centra" }},
        { text: `Actual`, options: { breakLine: true, fontSize: 12, fontFace: "Mars Centra" }},
        { text: `${formatNumber(cardData?.fact_gsv_actuals_yee === 0 ? "NA" : `$${formatNumber(cardData?.fact_gsv_actuals_yee)}`)}  ${formatNumber(cardData.gsv_yee_variance) === 0 ? "NA" : `${formatNumber(cardData.gsv_yee_variance)}%`}`, options: { breakLine: true, fontSize: 12, fontFace: "Mars Centra" }},
      ], {
        ...textOptionsChart,
        x: 8,
        y: 1,
      });
      
      if(tableData && tableData.length > 0){
        
        slide2.addText(`Launch Performance via Retailers`,{ x: 5, y: 2.6, fontSize: 16, bold:true, fontFace: "Mars Centra" })
      const luanchPerfTable = createCustomTable(tableCol,tableData, tableLaunchYear)
slide2.addTable(luanchPerfTable[0], {
        x: 0.4,
        y: 2.9,
        h: 4.5,
        colW: [2.4, 2, 2, 2, 2, 2],
        border: { pt: 0.1, color: "818181" },
        align: "left",
        valign: "middle",
        // fontFace: "Segoe UI",
        fontSize: 8,
        // autoPage: true,
        // autoPageRepeatHeader: true,
        // autoPageLineWeight: -0.4,
        verbose: true,
});
}
}

if(tableData && tableData.length > 0 ){
const slide3 = pres.addSlide();
      slide3.addText(`Filters applied: ​Brand – ${defaultBrand}; Pet – ${appliedFilters?.product_pet}; Technology – ${appliedFilters?.product_technology}; Innovation Type – ${appliedFilters?.innovation_type}; Launch Year – ${filters?.time_year};  Innovation – ${appliedFilters?.innovation}`, { x: 0.5, y: 0.5, fontSize: 12, fontFace: "Mars Centra" });

      slide3.addText(`Launch Performance via Retailers`,{ x: 5.5, y: 1, fontSize: 16, bold:true, fontFace: "Mars Centra" })

      const luanchPerfDistriTable = createCustomTable(itColsDistribution,tableData)
      slide3.addTable(luanchPerfDistriTable[0], {
              x: 0.4,
              y: 1.5,
              h: 6,
              colW: [2.3, 1.6, 1.6, 1.6, 1.6, 1.6, 1.6],
              border: { pt: 0.1, color: "818181" },
              align: "left",
              valign: "middle",
              // fontFace: "Segoe UI",
              fontSize: 8,
              // autoPage: true,
              // autoPageRepeatHeader: true,
              // autoPageLineWeight: -0.4,
              verbose: true,
      });
    }

// const slide4 = pres.addSlide();
// slide4.addText(`Filters applied: ​Brand – ${defaultBrand}; Pet – ${appliedFilters?.product_pet}; Technology – ${appliedFilters?.product_technology}; Innovation Type – ${appliedFilters?.innovation_type}; Launch Year – ${filters?.time_year};  Innovation – ${appliedFilters?.innovation}`, { x: 0.5, y: 0.5, fontSize: 12, fontFace: "Mars Centra" });

// // const driveData = []
// // const name = data2.map((value)=>value.type)
// // const uniName = [...new Set(name)]
// // const label = data2.map((value)=>value.brands)
// // const valueChart = data2.map((value)=>value.revenue)
// // for(let value of uniName){
// //   driveData.push({
// //     name: value,
// //     labels: label,
// //     values: valueChart
// //   })
// // }

//     //First Chart

//     // slide4.addChart(
//     //   pres.charts.BAR,
//     //   driveData,
//     //   {
//     //     x: 0.5,
//     //     y: 0.5,
//     //     w: "90%",
//     //     h: "90%",
//     //     chartArea: { fill: { color: "F1F1F1", transparency: 50 } },
  
//     //     valAxisMaxVal: 1,
//     //     barDir: "bar",
//     //     axisLabelFormatCode: "#%",
//     //     catGridLine: { color: "D8D8D8", style: "dash", size: 1 },
//     //     valGridLine: { color: "D8D8D8", style: "dash", size: 1 },
//     //     catAxisLineShow: false,
//     //     valAxisLineShow: false,
//     //     barGrouping: "stacked",
//     //     catAxisLabelPos: "low",
//     //     valueBarColors: true,
//     //     shadow: { type: "none" },
//     //     chartColors: ["0077BF", "4E9D2D"],
//     //     invertedColors: ["0065A2", "428526"],
//     //     barGapWidthPct: 25,
//     //     valAxisMajorUnit: 0.2,
//     //   }
//     // );

// slide4.addChart(
// 		pres.charts.BAR,
// 		[
// 			{
// 				name: "Excel Date Values",
// 				labels: [37987, 38018, 38047, 38078, 38108, 38139],
// 				values: [-20, 30, -10, 25, 15, -5],
// 			},
// 		],
// 		{
// 			x: 0.5,
// 			y: 0.6,
// 			w: 11.0,
// 			h: 3,
// 			chartArea: { fill: { color: "404040" } },
// 			barDir: "bar",
// 			chartColors: ["0077BF", "4E9D2D", "ECAA00", "5FC4E3", "DE4216", "154384"],
// 			//
// 			// catAxisLabelColor: "F1F1F1",
// 			catLabelFormatCode: "yyyy-mm",
//             catAxisLabelPos:"low",
// 			/*
// 			valAxisLabelColor: "F1F1F1",
// 			valAxisMajorUnit: 15,
// 			valAxisDisplayUnit: "hundreds",
// 			valAxisMaxVal: 45,
// 			valLabelFormatCode: "$0", // @since v3.3.0
// 			*/
// 			valAxisHidden: true,
// 			//
// 			showTitle: true,
// 			title: "Drivers & Drainers",
// 			titleColor: "0088CC",
// 			titleFontSize: 14,
// 		}
// );
//     // Second Chart

// let opts = {
//     x: 0.5,
//     y: 4,
//     w: 11,
//     h: 3.0,
//     barDir: "col",
//     catAxisLabelColor: "666666",
//     catAxisLabelFontFace: "Arial",
//     catAxisLabelFontSize: 12,
//     catAxisOrientation: "minMax",
//     showLegend: false,
//     showTitle: true,
//     title: "Media Spends",
//     titleColor: "0088CC",
//     titleFontSize: 14,
//     valAxisMaxVal: 100,
//     valAxisMajorUnit: 10,

//     valAxes: [
//         {
//             showValAxisTitle: true,
//             valAxisTitle: "Primary Value Axis",
//         },
//         {
//             showValAxisTitle: true,
//             valAxisTitle: "Secondary Value Axis",
//             valGridLine: { style: "none" },
//         },
//     ],

//     catAxes: [
//         {
//             catAxisTitle: "Primary Category Axis",
//         },
//         {
//             catAxisHidden: true,
//         },
//     ],
// };

// let labels = ["April", "May", "June", "July", "August"];
// let chartTypes = [
//     {
//         type: pres.charts.BAR,
//         data: [
//             {
//                 name: "Bottom",
//                 labels: labels,
//                 values: [17, 26, 53, 10, 4],
//             },
//         ],
//         options: {
//             chartColors: ["0000FF"],
//             barGrouping: "stacked",
//         },
//     },
//     {
//         type: pres.charts.LINE,
//         data: [
//             {
//                 name: "Current",
//                 labels: labels,
//                 values: [5, 3, 2, 4, 7],
//             },
//         ],
//         options: {
//             barGrouping: "standard",
//             secondaryValAxis: !!opts.valAxes,
//             secondaryCatAxis: !!opts.catAxes,
//         },
//     },
// ];
// slide4.addChart(chartTypes, opts);

// const slide5 = pres.addSlide();
// slide5.addText(`Filters applied: ​Brand – ${defaultBrand}; Pet – ${appliedFilters?.product_pet}; Technology – ${appliedFilters?.product_technology}; Innovation Type – ${appliedFilters?.innovation_type}; Launch Year – ${filters?.time_year};  Innovation – ${appliedFilters?.innovation}`, { x: 0.5, y: 0.5, fontSize: 12, fontFace: "Mars Centra" });

// slide5.addChart(
//     pres.charts.BAR,
//     [
//         {
//             name: "Two Color Series",
//             labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
//             values: [0.2, 0.3, 0.1, 0.25, 0.15, 0.05],
//         },
//     ],
//     {
//         x: 0.5,
//         y: 0.5,
//         w: 11,
//         h: 3,
//         chartArea: { fill: { color: "404040" } },
//         plotArea: { fill: { color: "202020" } },
//         // catAxisLabelColor: "F1F1F1",
//         // valAxisLabelColor: "F1F1F1",
//         // valAxisLineColor: "7F7F7F",
//         valGridLine: { color: "7F7F7F" },
//         // dataLabelColor: "B7B7B7",
//         valAxisHidden: true,
//         barDir: "col", // `col`(vert) | `bar`(horiz)
//         showValue: true,
//         dataLabelPosition: "outEnd",
//         dataLabelFormatCode: "#%",
//         valAxisLabelFormatCode: "0.#0",
//         // chartColors: ["0077BF", "4E9D2D", "ECAA00", "5FC4E3", "DE4216", "154384", "7D666A", "A3C961", "EF907B", "9BA0A3"],
//         valAxisMaxVal: 0.4,
//         barGapWidthPct: 50,
//         showLegend: false,
//         // legendPos: "r",
//         // legendColor: "F1F1F1",
//         showTitle: true,
//         title: "Volume Source",
//         titleColor: "0088CC",
//         titleFontSize: 14
//     }
// );

// slide5.addChart(
//     pres.charts.BAR,
//     [
//         {
//             name: "Excel Date Values",
//             labels: [37987, 38018, 38047, 38078, 38108, 38139],
//             values: [20, 30, 10, 25, 15, 5],
//         },
//     ],
//     {
//         x: 0.5,
//         y: 4,
//         w: 11,
//         h: 3,
//         chartArea: { fill: { color: "404040" } },
//         barDir: "bar",
//         chartColors: ["0077BF", "4E9D2D"],
//         //
//         // catAxisLabelColor: "F1F1F1",
//         catLabelFormatCode: "yyyy-mm",
//         /*
//         valAxisLabelColor: "F1F1F1",
//         valAxisMajorUnit: 15,
//         valAxisDisplayUnit: "hundreds",
//         valAxisMaxVal: 45,
//         valLabelFormatCode: "$0", // @since v3.3.0
//         */
//         valAxisHidden: true,
//         //
//         showTitle: true,
//         title: "Share of Shifting Gains",
//         titleColor: "0088CC",
//         titleFontSize: 14,
//     }
// );

if(trendData && trendData.length > 0){
const slide6 = pres.addSlide();
slide6.addText(`Filters applied: ​Brand – ${defaultBrand}; Pet – ${appliedFilters?.product_pet}; Technology – ${appliedFilters?.product_technology}; Innovation Type – ${appliedFilters?.innovation_type}; Launch Year – ${filters?.time_year};  Innovation – ${appliedFilters?.innovation}`, { x: 0.5, y: 0.5, fontSize: 12, fontFace: "Mars Centra" });

slide6.addText(`Launch Performance Trend - ${selectedRow[0] && selectedRow[0][rowKey]}`,{ x: 4.7, y: 1, fontSize: 16, bold:true, fontFace: "Mars Centra" })

// get innovation Name
let resArr = [];
await trendData.forEach(function(item){
  let i = resArr.findIndex(x => x.innovation === item.innovation);
  if(i <= -1){
    resArr.push(item.innovation);
  }
});
const benchMarkName = [...new Set(resArr)] 
let labels = trendData.map((item)=> item.time_week);
let uniqlabel = [...new Set(labels)]
let newArr = [];

for(let item of benchMarkName){
  const dataL = trendData.filter((i)=>i.innovation === item).map((i)=>i)
let values = [];
 for(let label of uniqlabel){
  const actualFind = dataL.find((i)=>i.time_week === label)
  if(actualFind){
    values.push(actualFind.kpi_result)
  } 
  else {
    values.push(null)
  }
}

newArr.push({
  name: item,
  labels : uniqlabel,
  values: values,
 })
 
}

let optsChartLine3 = {
  x: 0.5,
  y: 1.5,
  w: 12.0,
  h: 5.0,
  chartArea: { fill: { color: "F1F1F1" } },
  // chartColors: COLORS_RYGU,
  // lineDataSymbolSize: 10,
  shadow: { type: "none" },
  //displayBlanksAs: 'gap', // NOTE: uncomment only for test - looks broken otherwise!
  showLegend: true,
  legendPos: "b",
  titleColor: "0088CC",
  titleFontSize: 14,
  catAxisLabelColor: "000000",
  catAxisLabelFontFace: "Mars Centra",
  catAxisLabelFontSize: 10,
};

slide6.addChart(pres.charts.LINE, newArr, optsChartLine3);

}

if(ratings_grid && ratings_grid.length > 0){
let topthemes = ""
const themes = reviewData && reviewData?.review_tags
  for(let value of themes){
    topthemes += `${value ? value :''}\n`
  }
const slide7 = pres.addSlide();
slide7.addText(`Filters applied: ​Brand – ${defaultBrand}; Pet – ${appliedFilters?.product_pet}; Technology – ${appliedFilters?.product_technology}; Innovation Type – ${appliedFilters?.innovation_type}; Launch Year – ${filters?.time_year};  Innovation – ${appliedFilters?.innovation}`, { x: 0.5, y: 0.5, fontSize: 12, fontFace: "Mars Centra" });

slide7.addText(`Consumer Reviews`,{ x: 5.5, y: 1, fontSize: 16, bold:true, fontFace: "Mars Centra" })


slide7.addText(`SKU: ${localFilter.selected_sku[0] ? localFilter.selected_sku[0] : 'NA'}; ${localFilter.consumer_tab[0] === "A" ? `All Retailers : ${localFilter.customer_retailer_details.length === 0 ? retailerList.join() : localFilter.customer_retailer_details.join()}` : `${localFilter.consumer_tab[0] === "B"}` ? `Best Retailer : ${localFilter.customer_retailer_details.length === 0 ? retailerList.join() : localFilter.customer_retailer_details.join()}` :  `${localFilter.consumer_tab[0] === "W"}` ? `Worst Retailer : ${localFilter.customer_retailer_details.length === 0 ? retailerList.join() : localFilter.customer_retailer_details.join()}` : `${localFilter.consumer_tab[0] === "C" ? `Compare Retailers : ${localFilter.customer_retailer_details.length === 0 ? retailerList.join() : localFilter.customer_retailer_details.join()}` : retailerList.join()}`}` , { x: 0.5, y: 1.5, fontSize: 12, fontFace: "Mars Centra" })

slide7.addText(`Overall Ratings: ${reviewData?.ratings?.ratings_summary?.overall_ratings ? formatNumber(reviewData?.ratings?.ratings_summary?.overall_ratings) : 0}`,{ x: 0.5, y: 2, fontSize: 14, fontFace: "Mars Centra" })
slide7.addText(`Total Reviews: ${reviewData?.ratings?.ratings_summary?.total_reviews ? reviewData?.ratings?.ratings_summary?.total_reviews : 0}`,{ x: 5, y: 2, fontSize: 14, fontFace: "Mars Centra" })


slide7.addText(`Sentiment Analysis`,{ x: 9, y: 3, fontSize: 16, bold:true, fontFace: "Mars Centra" })

slide7.addText(`Overall Sentiment: ${reviewData?.sentiment[0]?.overall_sentiment}`,{ x: 8, y: 3.5, fontSize: 14, fontFace: "Mars Centra" })
slide7.addText(`Overall Sentiment Count: ${reviewData?.sentiment[0]?.overall_sentiment_count}`,{ x: 8, y: 4, fontSize: 14, fontFace: "Mars Centra" })

slide7.addText(`Top Themes`,{ x: 9, y: 4.5, fontSize: 16, bold:true, fontFace: "Mars Centra",breakLine: true })
slide7.addText(`${topthemes}`,{ x: 8, y: 5.5, fontSize: 14, fontFace: "Mars Centra" })

let arrDataHighVals = [
  {
    labels: ratings_grid?.map((item) => item.rating_label),
    values: ratings_grid?.map((item) => item.rating_value),
  },
];

let optsChartBar1 = {
  x: 0.5,
  y: 2.3,
  w: 7,
  h: 4.8,
  title: 'Ratings',
  titleAlign: 'center',
  showDataTableHorzBorder: false,
  showDataTableVertBorder: false,
  showDataTableOutline: false,
  chartColors: ['#FCD374'],
  valGridLine: 'solid',
  dataLabelFormatCode: '##0.0"%"',
  showTitle: true,
  showValue: true,
  valueFontSize: 12,
  color: "000000",
  barDir: "bar",
  fill: "FFFFFF",
  titleFontSize: 18,
  catAxisLabelColor: "000000",
  catAxisLabelFontFace: "Mars Centra",
  catAxisLabelFontSize: 10,
  // catAxisOrientation: "maxMin",
  dataLabelFontSize: 12,
};

slide7.addChart(pres.charts.BAR, arrDataHighVals, optsChartBar1);

}
const tableDatas = reviewFilterBy[localFilter.filter_by[0]] && orderBy(reviewFilterBy[localFilter.filter_by[0]][localFilter.sub_filter_by[0]], ["review_date"], sortOrder ? ["desc"] : ["asc"])?.map((val, i) => val)
if(tableDatas && tableDatas.length > 0){
const slide8 = pres.addSlide();
slide8.addText(`Filters applied: ​Brand – ${defaultBrand}; Pet – ${appliedFilters?.product_pet}; Technology – ${appliedFilters?.product_technology}; Innovation Type – ${appliedFilters?.innovation_type}; Launch Year – ${filters?.time_year};  Innovation – ${appliedFilters?.innovation}`, { x: 0.5, y: 0.5, fontSize: 12, fontFace: "Mars Centra" });

slide8.addText(`Reviews`, { x: 5.5, y: 0.9, fontSize: 16, fontFace: "Mars Centra", bold:true });

slide8.addText(`Filter By: ${localFilter?.filter_by[0]}, ${localFilter?.sub_filter_by[0]}`, { x: 0.5, y: 1.2, fontSize: 12, fontFace: "Mars Centra" });


 const sentimentData = createCustomTable(itColsSentiment, tableDatas.slice(0,5))
 slide8.addTable(sentimentData[0], {
  x: 0.4,
  y: 1.5,
  h: 5.5,
  colW: [2, 3.5, 3, 3],
  border: { pt: 0.1, color: "818181" },
  align: "left",
  valign: "middle",
  fontSize: 8,
  verbose: true,
});
slide8.addText("For complete table, please refer to the excel extract - Consumer Review.xlsx", { x: 0.4, y: 7.3, fontSize: 14, fontFace: "Mars Centra" });

const wb = new ExcelJS.Workbook();
exportConsumerReview(localFilter, sortOrder, "Consumer Review", true, wb);

const buf = await wb.xlsx.writeBuffer();
FileSaver.saveAs(new Blob([buf]), "Consumer Review.xlsx");
}

    pres.writeFile({ fileName: filename })
}