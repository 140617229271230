import React, { useEffect } from 'react';
import { Column } from '@ant-design/plots';
import { useState } from 'react';
import { formatDateShort, formatMonth } from '../../utilities/helper';

export default function ColumnChart(props) {

    const config = {
        data: props.data,
        xField: props.xField,
        yField: props.yField,
        seriesField: props.seriesField,
        isGroup: props.isGroup,
        padding: 75,
        // dodgePadding: props.dodgePadding,
        yAxis: {
            title: {
                text: props.yTitle
            }
        },
        legend: {
            layout: 'horizontal',
            position: 'center'
        },
        xAxis: {
            title: {
                text: props.select === "1"  ? props.xTitle : props.select === "2" ? "Week" : "Month" 
            },
            label: {
                style: {
                    fill: '#000000',
                    opacity: 0.7,
                },
                formatter: (val) =>props.select === "3" ? `${formatMonth(val)}`:`${formatDateShort(val)}`,
            },
        },

    }

    return <Column {...config} />
}