import { Radio, Dropdown, Menu, Modal, Button } from "antd";
import React, { useEffect, useState } from "react";
import { MoreOutlined } from "@ant-design/icons";
import CustomTable from "../customTable";
import { exportTable } from "../../utilities/exports/excel";
import { formatNumber, reviewTypes } from "../../utilities/helper";
import DualAxesChart from "../dual-axes";
import {
  bookmarkNudges,
  markAsInaccurateNudge,
  markAsIrrelevantNudge,
} from "../../slicers/bookmarks.slicer";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../loader";
import { appInsights } from "../../utilities/appInsights";
import "./style.scss";

// const data = [
// 	{ year: 'P01', key: 'Average', value: 4.4 },
// 	{ year: 'P01', key: 'Velocity LY', value: 6.4 },
// 	{ year: 'P01', key: 'Velocity TY', value: 13.0 },
// 	{ year: 'P02', key: 'Average', value: 4.5 },
// 	{ year: 'P02', key: 'Velocity LY', value: 8.3 },
// 	{ year: 'P02', key: 'Velocity TY', value: 13.1 },
// 	{ year: 'P03', key: 'Average', value: 3.8 },
// 	{ year: 'P03', key: 'Velocity LY', value: 8.4 },
// 	{ year: 'P03', key: 'Velocity TY', value: 13.5 },
// 	{ year: 'P04', key: 'Average', value: 3.7 },
// 	{ year: 'P04', key: 'Velocity LY', value: 6.0 },
// 	{ year: 'P04', key: 'Velocity TY', value: 12.1 },
// 	{ year: 'P05', key: 'Average', value: 4.0 },
// 	{ year: 'P05', key: 'Velocity LY', value: 6.8 },
// 	{ year: 'P05', key: 'Velocity TY', value: 11.8 }
// ];

export default function VelocityChart(props) {
  const [isBookmarked, setIsBookmarked] = useState(false);
  const dispatch = useDispatch();
  const {
    bookmarksLoading,
    bookmarksPerf,
    bookmarksVol,
    bookmarksDist,
    bookmarksInn,
  } = useSelector((state) => state.bookmarks);
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [tableCols, setTableCols] = useState(null);
  const [dataType, setDataType] = useState("a");
  const [yBarUnit, setYBarUnit] = useState("");
  const [isIrrelevantModalVisible, setIsIrrelevantModalVisible] =
    useState(false);
  const [isInaccurateModalVisible, setIsInaccurateModalVisible] =
    useState(false);

  useEffect(() => {
    // let tempIsBookmarked = localStorage.getItem('bookmarks') ? JSON.parse(localStorage.getItem('bookmarks')).includes(props.id) : false;
    if (props.tab === "Performance-Summary") {
      if (Object.keys(bookmarksPerf).length !== 0) {
        let tempIsBookmarked = bookmarksPerf.nudges?.findIndex(
          (item) => item.nudge_id === props.id
        );
        setIsBookmarked(tempIsBookmarked !== -1 ? 1 : 0);
      } else setIsBookmarked(false);
    } else if (props.tab === "Competition-Summary") {
      if (Object.keys(bookmarksVol).length !== 0) {
        let tempIsBookmarked = bookmarksVol.nudges?.findIndex(
          (item) => item.nudge_id === props.id
        );
        setIsBookmarked(tempIsBookmarked !== -1 ? 1 : 0);
      } else setIsBookmarked(false);
    } else if (props.tab === "Distribution-Summary") {
      if (Object.keys(bookmarksDist).length !== 0) {
        let tempIsBookmarked = bookmarksDist.nudges?.findIndex(
          (item) => item.nudge_id === props.id
        );
        setIsBookmarked(tempIsBookmarked !== -1 ? 1 : 0);
      } else setIsBookmarked(false);
    } else if (props.tab === "Innovation-Tracker") {
      if (Object.keys(bookmarksInn).length !== 0) {
        let tempIsBookmarked = bookmarksInn.nudges?.findIndex(
          (item) => item.nudge_id === props.id
        );
        setIsBookmarked(tempIsBookmarked !== -1 ? 1 : 0);
      } else setIsBookmarked(false);
    }
  }, [props.id, bookmarksPerf, bookmarksVol, bookmarksDist, bookmarksInn]);
  // const changeBookmark = () => {
  // 	let allBookmarks = JSON.parse(localStorage.getItem('bookmarks')) ?? [];
  // 	if (isBookmarked) {
  // 		let ind = allBookmarks.findIndex((val) => val === props.id)
  // 		if (ind > -1) {
  // 			allBookmarks.splice(ind, 1)
  // 		}
  // 	}
  // 	else {
  // 		allBookmarks.push(props.id)
  // 	}
  // 	localStorage.setItem('bookmarks', JSON.stringify(allBookmarks))
  // 	setIsBookmarked(!isBookmarked)
  // }

  useEffect(() => {
    let chart = [];
    if (props.data) {
      // if (props.tab === "Performance-Summary") {
      // 	setChartData(props.data.chart.filter(val => !val.key.includes("AVG")))
      // }
      // else if (props.tab === "Competition-Summary") {
      // 	setChartData(props.data.chart.filter(val => !val.key.includes("AVG")))
      // }
      // else {
      // 	setChartData(props.data.chart.filter(val => !val.key.includes("YA") && !val.key.includes("AVG")))
      // }
      // console.log("Final Data", finalData)

      // if (props.tab === "Performance-Summary") {
      // 	setChartData(props.data.chart)
      // }
      // else if (props.tab === "Competition-Summary") {
      // 	setChartData(props.data.chart)
      // }
      // else if (props.tab === "Distribution-Summary") {
      // 	setChartData(props.data.chart)
      // }
      // else if (props.tab === "Innovation-Tracker") {
      // 	setChartData(props.data.chart)
      // }
      if (props.tab) setChartData(props.data.chart);
      let title = [
        ...new Set(
          [].concat.apply(
            [],
            props.data.table.map((val) => Object.keys(val))
          )
        ),
      ];
      let cols = title.map((val) => {
        if (val === "nudge_value_period_average") {
          return {};
        } else {
          return {
            title: val,
            dataIndex: val,
            render: (val, rec) => {
              if (typeof val === "number") {
                return formatNumber(val, 1);
              } else {
                return val;
              }
            },
          };
        }
      });
      setTableCols(cols);
      setTableData(props.data.table);

      const barUnits = props.data.chart_bar_key.split("(In ");
      console.log("barUnits", barUnits);
      if (barUnits[1] === "$)") setYBarUnit("$");
      else if (barUnits[1] === "%)") setYBarUnit("%");
      else setYBarUnit("");
    } else setYBarUnit("");
  }, [props.data]);

  // const color = ({ key }) => {
  // 	let colorSet = ["#F06055", "#FAB518", "#098BCB"]
  // 	if (data) {
  // 		let keys = [...new Set(data.map(val => val.key))]
  // 		let value = keys.filter(val => val === key)
  // 		return colorSet[keys.findIndex(val => val === value[0])]
  // 	}
  // 	else {
  // 		return colorSet[0]
  // 	}
  // }

  function menu(columns, data, filename, selectedNudge) {
    const getInaccurateText = (status) => {
      if (status === reviewTypes.accurate)
        return (
          <p>
            <strong>Mark Alert as Inaccurate</strong> <br />
            When you mark it as inaccurate, the <br />
            Market Insights team will review it
          </p>
        );
      if (status === reviewTypes.pending)
        return (
          <p>
            Mark Alert as Inaccurate <br />
            Alert is currently under review by the <br />
            Market Insights team as it was flagged by <br />a user
          </p>
        );
      if (status === reviewTypes.declined)
        return (
          <p>
            Mark Alert as Inaccurate <br />
            Request has been declined by the <br />
            Market Insights team
          </p>
        );
    };

    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            appInsights.trackEvent({
              name:
                props.tab === "Performance-Summary"
                  ? "EXPORT_DATA_ALERTS_PERFORMANCE"
                  : props.tab === "Competition-Summary"
                  ? "EXPORT_DATA_ALERTS_COMPITITION"
                  : props.tab === "Distribution-Summary"
                  ? "EXPORT_DATA_ALERTS_DISTRIBUTUION"
                  : "EXPORT_DATA_ALERTS_INNOVATION",
            });
            data && exportTable(columns, data, filename);
          }}
        >
          <p>
            <strong>Export Data</strong> <br />
            You can export this data in CSV format
          </p>
        </Menu.Item>
        <Menu.Item onClick={() => setIsIrrelevantModalVisible(true)}>
          <p>
            <strong>Mark Alert as Irrelevant</strong> <br />
            When you mark it as Irrelevant, you will <br />
            not see the alert in your feed
          </p>
        </Menu.Item>
        {selectedNudge.nudge_status && (
          <Menu.Item
            disabled={selectedNudge.inaccurate_disabled}
            onClick={() => setIsInaccurateModalVisible(true)}
          >
            {getInaccurateText(selectedNudge.nudge_status)}
          </Menu.Item>
        )}
      </Menu>
    );
  }

  return (
    <>
      <div className="velocity-chart-wrap">
        <div className="velocity-chart-head">
          <h4>{props.title}</h4>
          <ul className="inline-list">
            <li>
              <Radio.Group
                defaultValue={dataType}
                onChange={(e) => setDataType(e.target.value)}
                size="middle"
              >
                <Radio.Button value="a">Chart</Radio.Button>
                <Radio.Button value="b">Table</Radio.Button>
              </Radio.Group>
            </li>
            {!props.hideBookMark && (
              <li>
                <Loader loading={bookmarksLoading} tip={null}>
                  <div
                    className="bookmark-logo-border"
                    onClick={() => {
                      appInsights.trackEvent({
                        name:
                          props.tab === "Performance-Summary"
                            ? "BOOKMARK_FOR_PERFORMANCE"
                            : props.tab === "Competition-Summary"
                            ? "BOOKMARK_FOR_COMPITITION"
                            : props.tab === "Distribution-Summary"
                            ? "BOOKMARK_FOR_DISTRIBUTION"
                            : "BOOKMARK_FOR_INNOVATION",
                      });
                      dispatch(
                        bookmarkNudges(props.tab, props.id, !isBookmarked)
                      );
                    }}
                  >
                    <div
                      className="bookmark-logo"
                      style={{ background: isBookmarked ? "black" : "white" }}
                    />
                  </div>
                </Loader>
              </li>
            )}
            {!props.hideMenu && (
              <li>
                <Dropdown
                  overlay={menu(
                    tableCols,
                    tableData,
                    props.tab,
                    props.selectedNudge
                  )}
                  trigger={["click"]}
                  placement="bottomRight"
                  className="d-more"
                >
                  <a
                    className="ant-dropdown-link"
                    style={{ padding: "10px 0" }}
                    onClick={(e) => e.preventDefault()}
                  >
                    <MoreOutlined />
                  </a>
                </Dropdown>
              </li>
            )}
          </ul>
        </div>
        {dataType === "a" && (
          <div className="v-chart-wrap">
            <DualAxesChart
              data={chartData}
              xField={"period_year"}
              yField={
                chartData &&
                [
                  ...new Set(
                    [].concat.apply(
                      [],
                      chartData.map((val) => Object.keys(val))
                    )
                  ),
                ].filter((val1) => val1 !== "period_year")
              }
              yBar={props.data && props.data.chart_bar_key}
              yLine={props.data && props.data.chart_line_key}
              yBarUnit={yBarUnit}
            />
          </div>
        )}
        {dataType === "b" && (
          <CustomTable
            hideFilter={true}
            scroll={props.scroll}
            hideUpperSection={true}
            checkbox={false}
            data={tableData && tableData}
            columns={tableCols && tableCols}
          />
        )}
      </div>
      <AlertModal
        title="Are you sure you want to mark as irrelevant?"
        action={() => dispatch(markAsIrrelevantNudge(props.tab, props.id, props.loadData))}
        tab={props.tab}
        type="IRRELEVANT"
        visible={isIrrelevantModalVisible}
        setVisible={setIsIrrelevantModalVisible}
      />
      <AlertModal
        title="Are you sure you want to mark as inaccurate?"
        action={() => dispatch(markAsInaccurateNudge(props.tab, props.id, props.loadData))}
        tab={props.tab}
        type="INACCURATE"
        visible={isInaccurateModalVisible}
        setVisible={setIsInaccurateModalVisible}
      />
    </>
  );
}

const AlertModal = ({ title, action, tab, type, visible, setVisible }) => (
  <Modal
    title={title}
    visible={visible}
    footer={[
      <Button
        key="back"
        onClick={() => {
          action();
          setVisible(false);
          appInsights.trackEvent({
            name:
              tab === "Performance-Summary"
                ? `MARK_ALERT_${type}_PERFORMANCE`
                : tab === "Competition-Summary"
                ? `MARK_ALERT_${type}_COMPITITION`
                : tab === "Distribution-Summary"
                ? `MARK_ALERT_${type}_DISTRIBUTION`
                : `MARK_ALERT_${type}_INNOVATION`,
          });
        }}
      >
        Confirm
      </Button>,
      <Button key="submit" type="primary" onClick={() => setVisible(false)}>
        Cancel
      </Button>,
    ]}
  >
    <p>This operation cannot be reversed.</p>
  </Modal>
);
