import { createSlice } from "@reduxjs/toolkit";
import CustomAxios from "../utilities/services/api.service";
import { ENDPOINTS } from "../utilities/constants";
import { brandSplit, isSubBrand, openNotificationWithIcon, setWindowHistory } from "../utilities/helper";
import { isPackSize } from "../pages/volume-shifting";
import { getState } from "../store";
import { cloneDeep } from "lodash";
import { declareAppliedFilters } from "../pages/distribution-summary/dsUIHelper";

/**
 * Distribution Summary Slicer
 * It manages the Redux State/Reducer/Actions for Distribution Summary usecase.
 */

// Define the initial state of the Redux store for the Distribution Summary
const initialState = {
  // Arrays to store various data
  pets: [],
  techs: [],
  subTechs: [],
  lifestage:[],
  packSize: [],
  retailers: [],
  years: [],
  periods: [],
  kpis: [],
  brand_dropdown: [],
  subBrands: [""],
  subBrandsLoaded: false,
  brand_colors: {},
  // Filters object to store various filter option
  filters: {
    view: ["distribution_summary"],
    product_pet: [],
    product_technology: [],
    product_sub_technology: [],
    product_lifestage:[],
    product_package: [],
    customer_channel: [],
    customer_retailer_details: [],
    default_brand: ["NUTRO CORE"],
    time_year: [],
    time_period: [],
    top: [1],
    kpi: ["TDP"],
    search: ["SKU"],
    trend_secondary_brand: [],
    time_aggregates: ["4w"],
    tail_cut_off_rsv: ["1000"],
    tail_cut_off_wd: ["0.1"],
  },
  appliedFilters: {
    view: ["distribution_summary"],
    product_pet: [],
    product_technology: ["ALL"],
    product_sub_technology: ["ALL"],
    product_lifestage:["All"],
    product_package: ["ALL"],
    customer_channel: [],
    customer_retailer_details: [],
    default_brand: ["NUTRO CORE"],
    time_year: [],
    time_period: [],
    top: [1],
    kpi: ["TDP"],
    search: ["SKU"],
    trend_secondary_brand: [],
    time_aggregates: ["4w"],
    tail_cut_off_rsv: ["1000"],
    tail_cut_off_wd: ["0.1"],
  },
  // Object to store card data
  card_data: {
    fact_csl_current_period_worst_case: "0",
    fact_csl_variance: "0",
    fact_csl_ya: "0",
    fact_dollar_per_dollar_million_acv_current_period: "0",
    fact_dollar_per_dollar_million_acv_variance: "0",
    fact_dollar_per_dollar_million_acv_ya: "0",
    fact_tdp_current_period: "0",
    fact_tdp_variance: "0",
    fact_tdp_ya: "0",
    is_distribution: 1,
  },
  preFilters: {},
  table_data: [],
  trend_data: {},
  card_data_is_distribution: 1,
  table_data_is_distribution: 1,
  scatter_data_is_distribution: 1,
  bubble_chart: {},
  loading: 0,
  filterLoading: 0,
  reportRefreshDist: false,
};

// Create a slice of the Redux store using createSlice from "@reduxjs/toolkit"
const distSummarySlicer = createSlice({
  name: "distSummary",
  initialState,
  reducers: {
    resetDistributionSummary: (state) => initialState,
    setLoading: (state, action) => ({
      ...state,
      loading: state.loading + (action.payload ? 1 : -1),
    }),
    setFilterLoading: (state, action) => ({ ...state, filterLoading: state.filterLoading + (action.payload ? 1 : -1) }),
    setPets: (state, action) => ({
      ...state,
      pets: action.payload,
    }),
    setTechs: (state, action) => ({
      ...state,
      techs: action.payload,
    }),
    setSubTechs: (state, action) => ({
      ...state,
      subTechs: action.payload,
    }),
    setLifeStage: (state, action) => ({
      ...state,
      lifestage: action.payload,
    }),
    setPackSize: (state, action) => ({
      ...state,
      packSize: action.payload
    }),
    setReportRefreshDist: (state, action) => ({
      ...state,
      reportRefreshDist: action.payload,
    }),
    setRetailers: (state, action) => ({
      ...state,
      retailers: action.payload,
    }),
    setYears: (state, action) => ({
      ...state,
      years: action.payload,
    }),
    setPeriods: (state, action) => ({
      ...state,
      periods: action.payload,
    }),
    setKpis: (state, action) => ({
      ...state,
      kpis: action.payload,
    }),
    setBrands: (state, action) => ({
      ...state,
      brand_dropdown: action.payload,
    }),
    setSubBrands: (state, action) => ({
      ...state,
      subBrands: action.payload,
      subBrandsLoaded: true,
    }),
    setBrandColors: (state, action) => ({
      ...state,
      brand_colors: action.payload,
    }),
    setCardData: (state, action) => ({
      ...state,
      card_data: action.payload,
    }),
    setTableData: (state, action) => ({
      ...state,
      table_data: action.payload,
    }),
    setTrendData: (state, action) => ({
      ...state,
      trend_data: action.payload,
    }),
    setBubbleChart: (state, action) => ({
      ...state,
      bubble_chart: action.payload,
    }),
    setFilters: (state, action) => ({
      ...state,
      filters: action.payload,
    }),
    setAppliedFilters: (state, action) => ({
      ...state,
      appliedFilters: action.payload,
    }),
    setPreFilters: (state, action) => ({
      ...state,
      preFilters: { ...state.preFilters, ...action.payload },
    }),
    resetPreFilters: (state, action) => ({
      ...state,
      preFilters: initialState.preFilters,
    }),
    setCardDataIsDistribution: (state, action) => ({
      ...state,
      card_data_is_distribution: action.payload,
    }),
    setTableDataIsDistribution: (state, action) => ({
      ...state,
      table_data_is_distribution: action.payload,
    }),
    setScatterDataIsDistribution: (state, action) => ({
      ...state,
      scatter_data_is_distribution: action.payload,
    }),
  },
});

export const {
  resetDistributionSummary,
  setLoading,
  setFilterLoading,
  setPets,
  setTechs,
  setSubTechs,
  setLifeStage,
  setPackSize,
  setRetailers,
  setYears,
  setPeriods,
  setKpis,
  setBrands,
  setSubBrands,
  setReportRefreshDist,
  setBrandColors,
  setFilters,
  setAppliedFilters,
  setCardData,
  setTableData,
  setTrendData,
  setBubbleChart,
  setPreFilters,
  resetPreFilters,
  setCardDataIsDistribution,
  setTableDataIsDistribution,
  setScatterDataIsDistribution,
} = distSummarySlicer.actions;

export const getInitialBottomFilters = () => {
  const state = getState().distSummary;

  const initialFilters = cloneDeep(initialState.filters);
  const oldFilters = cloneDeep(state.filters);

  return {
    ...initialFilters,
    product_pet: oldFilters.product_pet,
    product_technology: oldFilters.product_technology,
    product_sub_technology: oldFilters.product_sub_technology,
    product_lifestage :oldFilters.product_lifestage,
    product_package: oldFilters.product_package,
    customer_channel: oldFilters.customer_channel,
    customer_retailer_details: oldFilters.customer_retailer_details,
    time_year: oldFilters.time_year,
    time_period: oldFilters.time_period,
  }
}

const processFilters = (filters, getState) => {
  let brand = getState().profile.defaultBrand;

  let tempFilters = { ...filters };
  if (tempFilters.product_pet && tempFilters.product_pet.length === 0) {
    tempFilters["product_pet"] = ["ALL"];
  }
  if (
    tempFilters.product_technology &&
    tempFilters.product_technology.length === 0
  ) {
    tempFilters["product_technology"] = ["ALL"];
  }
  if (
    tempFilters.product_sub_technology &&
    tempFilters.product_sub_technology.length === 0
  ) {
    tempFilters["product_sub_technology"] = ["ALL"];
  }
  if (
    tempFilters.product_lifestage &&
    tempFilters.product_lifestage.length === 0
  ) {
    tempFilters["product_lifestage"] = ["ALL"];
  }
  if (
    tempFilters.product_package &&
    tempFilters.product_technology.length === 1 &&
    isPackSize.includes(tempFilters.product_technology[0]) &&
    tempFilters.product_package.length === 0
  ) {
    tempFilters["product_package"] = ["ALL"];
  }
  if (
    tempFilters.customer_channel &&
    tempFilters.customer_channel.length === 0
  ) {
    tempFilters["customer_channel"] = ["ALL"];
  }
  if (
    tempFilters.customer_retailer_details &&
    tempFilters.customer_retailer_details.length === 0
  ) {
    tempFilters["customer_retailer_details"] = ["ALL"];
  }
  if (tempFilters.time_year && tempFilters.time_year.length === 0) {
    tempFilters["time_year"] = ["ALL"];
  }
  if (tempFilters.time_period && tempFilters.time_period.length === 0) {
    tempFilters["time_period"] = ["ALL"];
  }
  if (brand) {
    if (brand.split(":").length === 2) {
      tempFilters['product_brand'] = brand.split(":")[0]
      tempFilters['product_secondary_brand'] = brand.split(":")[1]
    }
    else {
      tempFilters['product_brand'] = brand
      tempFilters['product_secondary_brand'] = ""
    }
  }

  return tempFilters;
}

// Function to set default filters and applied filters in the state
const setFiltersDefault = (
  dispatch,
  response,
  getState,
  initialLoad = false
) => {
  // Extract the required data from the state and response
  const state = getState().distSummary;
  const filters = state.filters;
  const appliedFilters = state.appliedFilters;

  const newFilters = { ...filters };

  // Remove the selected values which are not prsent in the dropdown's items.
  newFilters.product_pet = newFilters.product_pet.filter(item => (response.data.product_pet || state.pets).includes(item));
  newFilters.product_sub_technology = newFilters.product_sub_technology.filter(item => (response.data.product_sub_technology || state.subTechs).includes(item));
  newFilters.product_lifestage = newFilters.product_lifestage.filter(item => (response.data.product_lifestage || state.lifestage).includes(item));
  newFilters.product_technology = newFilters.product_technology.filter(item => ((response.data.product_package && Object.keys(response.data.product_package)) || state.techs).includes(item));
  if (newFilters.product_package) newFilters.product_package = newFilters.product_package.filter(item => (response.data.product_package[newFilters.product_technology[0]] || state.packSize[newFilters.product_technology[0]] || []).includes(item));

  dispatch(setFilters(newFilters));  // Dispatch the new filters to update the state

  if (initialLoad) {
    const newAppliedFilters = { ...appliedFilters };

    // Filters Applied: Remove the selected values which are not prsent in the dropdown's items.
    newAppliedFilters.product_pet = newFilters.product_pet;
    newAppliedFilters.product_technology = newFilters.product_technology;
    newAppliedFilters.product_sub_technology = newFilters.product_sub_technology;
    newAppliedFilters.product_lifestage = newFilters.product_lifestage;
    if (newFilters.product_package) newAppliedFilters.product_package = newFilters.product_package;

    dispatch(setAppliedFilters(declareAppliedFilters(newAppliedFilters, { techs: state.techs, subTechs: state.subTechs, lifestage:state.lifestage,packSize: state.packSize[newFilters.product_technology[0]] })));
  }

  return newFilters;
};

const getFilterForBrandChange = (filters) => {
  const newFilters = {};
  if (filters.product_pet.length > 0) {
    newFilters.product_pet = filters.product_pet;
  }
  if (filters.product_technology.length > 0) {
    newFilters.product_pet = filters.product_pet;
    newFilters.product_technology = filters.product_technology;
  }

  return newFilters;
}

// Action to get all filters data.
export const getAllFilters = (brand, loadedTable, loadedScatter, isBrandChanged = false, changeSelectedBrand = null) => async (dispatch, getState) => {
  let brand = getState().profile.defaultBrand;
  let tempFilters = {};
  if (brand) {
    if (brand.split(":").length === 2) {
      tempFilters["product_brand"] = brand.split(":")[0];
      tempFilters["product_secondary_brand"] = brand.split(":")[1];
    } else {
      tempFilters["product_brand"] = brand;
      tempFilters["product_secondary_brand"] = "";
    }
  }

  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters))

  dispatch(setFilterLoading(true));
  // Make the API call to fetch all filters data
  CustomAxios(ENDPOINTS.distSummary + "?" + encodedURL, "GET")
    .then((response) => {
      if (response.data.product_pet)
        dispatch(setPets(response.data.product_pet));
      if (response.data.product_package && Object.keys(response.data.product_package))
        dispatch(setTechs(Object.keys(response.data.product_package)));
      if (response.data.product_sub_technology)
        dispatch(setSubTechs(response.data.product_sub_technology));
      if (response.data.product_lifestage)
        dispatch(setLifeStage(response.data.product_lifestage));
      if (response.data.product_package)
        dispatch(setPackSize(response.data.product_package));
      if (response.data.customer_retailer_details)
        dispatch(setRetailers(response.data.customer_retailer_details));
      if (response.data.time_year) dispatch(setYears(response.data.time_year));
      if (response.data.time_period)
        dispatch(setPeriods(response.data.time_period));
      if (response.data.kpi_dropdown)
        dispatch(setKpis(response.data.kpi_dropdown));

      const newFilters = setFiltersDefault(dispatch, response, getState, true);
      dispatch(getData(newFilters, brand, loadedTable, loadedScatter));

      if (isBrandChanged && changeSelectedBrand) {
        changeSelectedBrand();
        dispatch(getFilterData(getFilterForBrandChange(getState().distSummary.filters)));
      }

      dispatch(setFilterLoading(false));
    })
    .catch((err) => {
      // Set loading state back to false after the API call is complete
      dispatch(setFilterLoading(false));
    });
};

// Action to get filter data based on the provided filters.
export const getFilterData = (filters, loadPage = false, brandName = null, loadedTable = null, loadedScatter = null, isBrandChanged = false, changeSelectedBrand = null) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);

  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setFilterLoading(true));
  CustomAxios(ENDPOINTS.distSummary + "?" + encodedURL, "GET")
    .then((response) => {
      if (response.data.product_pet)
        dispatch(setPets(response.data.product_pet));
      if (response.data.product_package && Object.keys(response.data.product_package))
        dispatch(setTechs(Object.keys(response.data.product_package)));
      if (response.data.product_sub_technology)
        dispatch(setSubTechs(response.data.product_sub_technology))
      if (response.data.product_lifestage)
        dispatch(setLifeStage(response.data.product_lifestage))
      if (response.data.product_package)
        dispatch(setPackSize(response.data.product_package))
      if (response.data.customer_retailer_details)
        dispatch(setRetailers(response.data.customer_retailer_details));
      if (response.data.time_year) dispatch(setYears(response.data.time_year));
      if (response.data.time_period)
        dispatch(setPeriods(response.data.time_period));
      if (response.data.kpi_dropdown)
        dispatch(setKpis(response.data.kpi_dropdown));

      const newFilters = setFiltersDefault(dispatch, response, getState, loadPage);

      if (loadPage) {
        dispatch(getData(newFilters, brandName, loadedTable, loadedScatter));
        setWindowHistory(encodeURIComponent(JSON.stringify(newFilters)));
      }

      if (isBrandChanged && changeSelectedBrand) {
        changeSelectedBrand();
        dispatch(getFilterData(getFilterForBrandChange(getState().distSummary.filters)));
      }

      dispatch(setFilterLoading(false));
    })
    .catch((err) => {
      dispatch(setFilterLoading(false));
    });
};

// Action to get card data based on the provided filters.
export const getCardData = (filters) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const newFilters = cloneDeep(filters);
  delete newFilters.top;
  delete newFilters.kpi;
  newFilters.card = [1];

  let tempFilters = processFilters(newFilters, getState);

  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));
  CustomAxios(ENDPOINTS.distSummary + "?" + encodedURL, "GET")
    .then((response2) => {
      if (response2.data.distribution_summary) {
        dispatch(setCardData(response2.data.distribution_summary.card_data[0]));
        dispatch(
          setCardDataIsDistribution(
            response2.data.distribution_summary.card_data[0].is_distribution
          )
        );
      } else dispatch(initialState.card_data);
      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
    });
};

// Action to get table data based on the provided filters.
export const getTableData = (filters, loadedTable) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  let tempFilters = processFilters(filters, getState);

  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));
  CustomAxios(ENDPOINTS.distSummary + "?" + encodedURL, "GET")
    .then((response2) => {
      loadedTable();
      if (response2.data.distribution_summary) {
        dispatch(setTableData(response2.data.distribution_summary.table_data));
        dispatch(
          setTableDataIsDistribution(
            response2.data.distribution_summary.is_distribution
          )
        );
        dispatch(
          setPreFilters({
            time_year: response2.data.distribution_summary.time_year,
            time_period: response2.data.distribution_summary.time_period,
            customer_channel:
              response2.data.distribution_summary.customer_channel,
            customer_retailer_details:
              response2.data.distribution_summary.customer_retailer_details,
          })
        );
      } else {
        dispatch(setTableData([]));
        dispatch(
          setPreFilters({
            time_year: [],
            time_period: [],
            customer_channel: [],
            customer_retailer_details: [],
          })
        );
      }
      dispatch(setLoading(false));
    })
    .catch((err) => {
      loadedTable();
      dispatch(setLoading(false));
    });
};

// Action to get sub-brand filter data based on the provided filters.
export const getSubBrandFilter = (filters) => async (dispatch, getState) => {
  let state = getState().distSummary;
  let brand = getState().profile.defaultBrand
  dispatch(setLoading(true));
  let tempFilters = processFilters(filters, getState);

  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));
  CustomAxios(ENDPOINTS.productSubBrand + "?" + encodedURL, "GET")
    .then((response2) => {
      if (response2.data[brandSplit(brand)] && response2.data[brandSplit(brand)].length > 0) dispatch(setSubBrands(response2.data[brandSplit(brand)]));
      else if (state.subBrands[0] !== "") dispatch(setSubBrands([""]));
      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      if (state.subBrands[0] !== "") dispatch(setSubBrands([""]));
    });
};

// Action to get trend data based on the provided filters.
export const getTrendData = (filters) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  let tempFilters = processFilters(filters, getState);

  if (tempFilters.search && tempFilters.search.length > 0) {
    tempFilters["search"] = tempFilters.search[0];
  }
  if (tempFilters.trend_secondary_brand && tempFilters.trend_secondary_brand.length > 0) {
    tempFilters["trend_secondary_brand"] = tempFilters.trend_secondary_brand[0];
  }
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));
  CustomAxios(ENDPOINTS.chartTrend + "?" + encodedURL, "GET")
    .then((response2) => {
      if (response2.data.table_data) {
        dispatch(setTrendData(response2.data.table_data));
      } else dispatch(setTrendData({}));
      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      dispatch(setTrendData({}));
    });
};

// Action to get scatter chart data based on the provided filters and brand.
export const getScatterData =
  (filters, brand, loadedScatter) => async (dispatch, getState) => {
    let state = getState().distSummary;
    let defaultBrand = getState().profile.defaultBrand
    dispatch(setLoading(true));
    const newFilters = JSON.parse(JSON.stringify(filters));
    delete newFilters.top;
    delete newFilters.kpi;
    newFilters.brand = brand;
    let tempFilters = processFilters(newFilters, getState);

    if ((tempFilters.brand && tempFilters.brand.length === 0)) {
      tempFilters["brand"] = [defaultBrand];
    }
    else if (tempFilters.brand.length === state.brand_dropdown.length) {
      tempFilters["brand"] = ["ALL"];
    }

    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));
    CustomAxios(ENDPOINTS.distSummary + "?" + encodedURL, "GET")
      .then((response2) => {
        loadedScatter();
        if (response2.data.distribution_summary) {
          dispatch(
            setBrands(Object.keys(response2.data.distribution_summary.brand_dropdown))
          );
          dispatch(
            setBrandColors(response2.data.distribution_summary.brand_dropdown)
          );
          dispatch(
            setBubbleChart(response2.data.distribution_summary.bubble_chart)
          );
          dispatch(
            setScatterDataIsDistribution(response2.data.distribution_summary.is_distribution)
          );
          dispatch(
            setPreFilters({
              median_x_axis: response2.data.distribution_summary.median_x_axis,
              median_y_axis: response2.data.distribution_summary.median_y_axis,
              sku_in_tail: response2.data.distribution_summary.sku_in_tail,
              tail_sales_value:
                response2.data.distribution_summary.tail_sales_value,
              tail_sales_percentage:
                response2.data.distribution_summary.tail_sales_percentage,
              rsv_limit: response2.data.distribution_summary.rsv_limit,
              wd_limit: response2.data.distribution_summary.wd_limit,
            })
          );
        } else {
          dispatch(setBubbleChart({}));
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        loadedScatter();
        dispatch(setLoading(false));
      });
  };

export const getData = (filters, brand, loadedTable, loadedScatter) => async (dispatch) => {
  dispatch(getCardData(filters));
  dispatch(getTableData(filters, loadedTable));
  dispatch(getTrendData(filters));
  dispatch(getScatterData(filters, brand, loadedScatter));
};

// Action to save the DS report with the provided details.
export const saveDSReport =
  (
    filters,
    accessValue,
    reportId,
    reportName,
    components,
    createReport,
    setComponents,
    setNewReport,
    setOpenSaveDialog,
    setSaveLoading
  ) =>
    async (dispatch, getState) => {
      let brand = getState().profile.defaultBrand
      let profile = getState().profile
      let saveReport = {
        report_id: reportId,
        report_name: reportName,
        report_access: accessValue,
        user_email: sessionStorage.getItem("email"),
        user_name: profile.user.user_name,
        user_designation: profile.userDesignation,
        // team_cluster: profile.teamCluster,
        distribution_components: components,
        product_pet: filters.product_pet,
        product_technology: filters.product_technology,
        product_sub_technology: filters.product_sub_technology,
        product_lifestage: filters.product_lifestage,
        product_package: filters.product_package,
        distribution_channel: filters.customer_channel,
        distribution_retailer: filters.customer_retailer_details,
        distribution_kpi: filters.kpi,
        distribution_sort_order: filters.top,
        distribution_record_count: filters.pageSize,
        distribution_scatter_brands: filters.brand,
        time_year: filters.time_year,
        time_period: filters.time_period,
        product_brand: brand.split(":").length === 2 ? brand.split(":")[0] : brand,
        product_secondary_brand: brand.split(":").length === 2 ? brand.split(":")[1] : ""
      };

      let addToExisting = {
        report_id: reportId,
        report_name: reportName,
        user_name: profile.user.user_name,
        user_email: sessionStorage.getItem("email"),
        time_year: filters.time_year,
        time_period: filters.time_period,
        product_brand: brand.split(":").length === 2 ? brand.split(":")[0] : brand,
        product_secondary_brand: brand.split(":").length === 2 ? brand.split(":")[1] : "",
        // team_cluster: profile.teamCluster,
        distribution_components: components,
        distribution_channel: filters.customer_channel,
        distribution_retailer: filters.customer_retailer_details,
        distribution_kpi: filters.kpi,
        distribution_sort_order: filters.top,
        distribution_record_count: filters.pageSize,
        distribution_scatter_brands: filters.brand,
      };

      setSaveLoading(true);
      CustomAxios(ENDPOINTS.saveReport, "POST", createReport ? saveReport : addToExisting)
        .then((response) => {
          if (response.data.error_code === undefined) {
            openNotificationWithIcon("success", response.data.message);
            setComponents([]);
            setOpenSaveDialog(false);
            setNewReport(false);
          } else {
            openNotificationWithIcon("error", "Error saving report. Please contact support.");
          }
          setSaveLoading(false);
        })
        .catch((err) => {
          setSaveLoading(false);

          openNotificationWithIcon("error", "Error Occured");
        });
    };

export default distSummarySlicer.reducer;
