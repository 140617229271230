import React, { useEffect, useState } from "react";
import { Table, Radio, Dropdown, Menu, Select, Checkbox } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import "./customTable.scss";

const menu = (exportData) => (
  <Menu>
    <Menu.Item key="0" onClick={exportData}>
      Export Data
    </Menu.Item>
  </Menu>
);

const { Option } = Select;
/**
 * NutroSKUsTable
 * Component for rendering a table to display Nutro SKUs with various filters and options.
 */
export default function NutroSKUsTable({
  columns,
  data,
  dropdownData,
  checkbox,
  heading,
  onChange,
  hideFilter,
  showCore,
  rowKey,
  childrenColumnName,
  rowSelection,
  filters,
  changeFilter,
  pageSize,
  setPageSize,
  exportData,
  value
}) {
  return (
    <div className={`nutro-skus ${checkbox ? 'trackboard-edit' : ''} ${checkbox && value ? 'trackboard-selected' : ''}`} style={{ marginBottom: 16 }}>
      <div className="section-title">
        {checkbox && (
          <Checkbox value={value} onChange={onChange} className="d-check"></Checkbox>
        )}
        <h4>{heading}</h4>
        <Dropdown
          overlay={() => menu(exportData)}
          trigger={["click"]}
          placement="bottomRight"
          className="d-more"
        >
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <MoreOutlined />
          </a>
        </Dropdown>
      </div>
      {!hideFilter && (
        <div className="nutro-skus-filter">
          <div>
            <span className="view-label">View Top</span>
            <Radio.Group
              defaultValue={pageSize}
              size="small"
              onChange={(e) => setPageSize(e.target.value)}
            >
              <Radio.Button value={20}>20</Radio.Button>
              <Radio.Button value={40}>40</Radio.Button>
              <Radio.Button value={60}>60</Radio.Button>
              <Radio.Button value={80}>80</Radio.Button>
              <Radio.Button value={100}>100</Radio.Button>
            </Radio.Group>
          </div>
          <div>
            <Radio.Group
              defaultValue={filters.top[0]}
              size="small"
              onChange={(e) => changeFilter("top", [e.target.value])}
            >
              <Radio.Button value={1}>Winners</Radio.Button>
              <Radio.Button value={0}>Losers</Radio.Button>
            </Radio.Group>
          </div>
          {showCore && (
            <div>
              <Checkbox
                checked={filters.core_ind[0]}
                onChange={(e) =>
                  changeFilter("core_ind", [e.target.checked ? 1 : 0])
                }
                className="d-check"
              >
                Core
              </Checkbox>
              {/* <Radio.Group
                defaultValue={filters.core_ind[0]}
                size="small"
                onChange={(e) =>
                  changeFilter("core_ind", [filters.core_ind[0] ? 0 : 1])
                }
              >
                <Radio.Button value={1} checked={false}>Core</Radio.Button>
              </Radio.Group> */}
            </div>
          )}
          <div>
            <span className="view-label">Sort by</span>
            <Select
              defaultValue={["TDP"]}
              size="small"
              style={{ width: 110 }}
              value={filters.kpi}
              onChange={(val) => changeFilter("kpi", [val])}
            >
              {dropdownData.map((val, index) => (
                <Option key={index} value={val}>
                  {val}
                </Option>
              ))}
              {/* <Option value="TDP">TDP</Option>
              <Option value="Velocity">Velocity</Option>
              <Option value="Sales">Eq LBs</Option>
              <Option value="Value">MKT RSV</Option> */}
            </Select>
          </div>
        </div>
      )}
      <div className="nutro-table">
        <Table
          rowKey={rowKey}
          expandable={{ childrenColumnName: childrenColumnName }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ y: 240 }}
        />
      </div>
    </div>
  );
}
