import { createSlice } from '@reduxjs/toolkit';
import CustomAxios from '../utilities/services/api.service';
import { ENDPOINTS } from '../utilities/constants';
import { openNotificationWithIcon, setWindowHistory } from '../utilities/helper';
import { isPackSize } from "../pages/volume-shifting";
import { declareAppliedFilters } from '../pages/innovation-tracker/itUIHelper';

/**
 * Innovation Tracker Slicer
 * It manages the Redux State/Reducer/Actions for Innovation Tracker usecase.
 */

const initialState = {
    loading: 0,
    pets: [],
    techs: [],
    subTechs: [],
    lifestage:[],
    packSize: {},
    innovationTypes: [],
    years: [],
    innovation: [],
    kpi: [],
    benchmark: [],
    latestTimeYear: [],
    filters: {
        view: ["innovation_tracker"],
        product_pet: [],
        product_technology: [],
        product_sub_technology: [],
        product_lifestage:[],
        product_package: [],
        innovation_type: [],
        time_year: [],
        innovation: [],
        default_brand: ["NUTRO"],
        selected_innovation: [],
        selected_retailer: [],
        launch_as_benchmark: [1],
        output_line: ['I'],
        dropdown_values: ['I'],
        benchmark_values: [],
        trendchart_kpi: [],
        consumer_tab: ["A"],
        selected_sku: [], 
        theme_group: [],
        customer_retailer_details: [],
        selected_retailer_key: [-1],
        filter_by: [],
        sub_filter_by: [],
        sample_group: [],
        sample_short: [],
        sub_sub_filter_by: [],
    },
    reportRefreshInnovation: false,
    appliedFilters: {
        view: ["innovation_tracker"],
        product_pet: ["ALL"],
        product_technology: ["ALL"],
        product_sub_technology: ["ALL"],
        product_lifestage:["ALL"],
        product_package: ["ALL"],
        innovation_type: ["ALL"],
        time_year: [],
        innovation: ["ALL"],
        default_brand: ["NUTRO"],
        selected_innovation: [],
        selected_retailer: [],
        launch_as_benchmark: [1],
        output_line: ['I'],
        dropdown_values: ['I'],
        benchmark_values: [],
        trendchart_kpi: [],
        consumer_tab: ["A"],
        selected_sku: ["ALL"],
        theme_group: [],
        customer_retailer_details: ["ALL"],
        selected_retailer_key: [-1],
        filter_by: [],
        sub_filter_by: [],
        sample_group: [],
        sample_short: [],
        sub_sub_filter_by: [],
    },
    skuList: [],
    retailerList: [],
    reviewFilterBy: {},
    tableData: [],
    cardData: {},
    tableLaunchYear: '',
    trendData: [],
    reviewData: {},
    retailerImage: [],
    gsvChart: [],
    defaultBenchmark: [],
    drainersData: [],
    chartPreview: [],
    legendName: {
        name: '', isExist: false
    }
}

// Create the innovation tracker slice using createSlice from Redux Toolkit
const innovationTrackerSlicer = createSlice({
    name: 'innovationTracker',
    initialState,
    reducers: {
        resetInnovationTracker: (state) => initialState,
        setLoading: (state, action) => ({ ...state, loading: state.loading + (action.payload ? 1 : -1) }),
        setPets: (state, action) => ({ ...state, pets: action.payload }),
        setSubTechs: (state, action) => ({
            ...state,
            subTechs: action.payload
        }),
        setLifeStage: (state, action) => ({
            ...state,
            lifestage: action.payload
        }),
        setTechs: (state, action) => ({ ...state, techs: action.payload }),
        setPackSize: (state, action) => ({ ...state, packSize: action.payload }),
        setInnovationTypes: (state, action) => ({ ...state, innovationTypes: action.payload }),
        setYears: (state, action) => ({ ...state, years: action.payload }),
        setInnovation: (state, action) => ({ ...state, innovation: action.payload }),
        setLatestTimeYear: (state, action) => ({ ...state, latestTimeYear: action.payload }),
        setKpi: (state, action) => ({ ...state, kpi: action.payload }),
        setBenchmark: (state, action) => ({ ...state, benchmark: action.payload }),
        setFilters: (state, action) => ({
            ...state,
            filters: action.payload
        }),
        setAppliedFilters: (state, action) => ({
            ...state,
            appliedFilters: action.payload
        }),
        setCardData: (state, action) => ({
            ...state,
            cardData: action.payload
        }),
        setTableData: (state, action) => ({
            ...state,
            tableData: action.payload
        }),
        setTableLaunchYear: (state, action) => ({
            ...state,
            tableLaunchYear: action.payload
        }),
        setTrendData: (state, action) => ({
            ...state,
            trendData: action.payload
        }),
        setSkuList: (state, action) => ({
            ...state,
            skuList: action.payload
        }),
        setRetailerList: (state, action) => ({
            ...state,
            retailerList: action.payload
        }),
        setReviewFilterBy: (state, action) => ({
            ...state,
            reviewFilterBy: action.payload
        }),
        setReportRefreshInnovation: (state, action) => ({
            ...state,
            reportRefreshInnovation: action.payload,
        }),
        setReviewData: (state, action) => ({
            ...state,
            reviewData: action.payload
        }),
        setRetailerImage: (state, action) => ({
            ...state,
            retailerImage: action.payload
        }),
        setGsvChart: (state, action) => ({
            ...state,
            gsvChart: action.payload
        }),
        setDefaultBenchmark: (state, action) => ({
            ...state,
            defaultBenchmark: action.payload
        }),
        setDrainersData: (state, action) => ({
            ...state,
            drainersData: action.payload
        }),
        setChartPreview: (state, action) => ({
            ...state,
            chartPreview: action.payload
        }),
        setLegendName: (state, action) => ({
            ...state,
            legendName: action.payload
        })
    },
})

export const { resetInnovationTracker, setLoading, setPets, setTechs, setLifeStage,setPackSize, setSubTechs, setYears, setLatestTimeYear, setFilters, setAppliedFilters, setInnovationTypes, setInnovation, setKpi, setCardData, setTableData, setTableLaunchYear, setTrendData, setSkuList, setRetailerList, setReviewFilterBy, setReportRefreshInnovation, setReviewData, setRetailerImage, setBenchmark, setGsvChart, setDefaultBenchmark, setDrainersData, setChartPreview, setLegendName } = innovationTrackerSlicer.actions

// Function to process filters and adjust them based on certain conditions
const processFilters = (filters, getState) => {
    let state = getState().innovationTracker
    let brand = getState().profile.defaultBrand

    let tempFilters = { ...filters }
    // If product_pet filter is empty or includes all pets, set it to 'ALL'
    if (tempFilters.product_pet && (tempFilters.product_pet.length === 0 || tempFilters.product_pet.length === state.pets.length)) {
        tempFilters['product_pet'] = 'ALL'
    }
    // If product_technology filter is empty or includes all techs, set it to 'ALL'
    if (tempFilters.product_technology && (tempFilters.product_technology.length === 0 || tempFilters.product_technology.length === state.techs.length)) {
        tempFilters['product_technology'] = 'ALL'
    }
    // If product_sub_technology filter is empty or includes all techs, set it to 'ALL'
    if (tempFilters.product_sub_technology && (tempFilters.product_sub_technology.length === 0 || tempFilters.product_sub_technology.length === state.subTechs.length)) {
        tempFilters['product_sub_technology'] = 'ALL'
    }
     // If product_lifestage filter is empty or includes all techs, set it to 'ALL'
    if (tempFilters.product_lifestage && (tempFilters.product_lifestage.length === 0 || tempFilters.product_lifestage.length === state.lifestage.length)) {
        tempFilters['product_lifestage'] = 'ALL'
    }
    // If product_package filter includes only one tech (packSize) and is empty or includes all packSizes, set it to 'ALL'
    if (tempFilters.product_package && tempFilters.product_technology.length === 1 && isPackSize.includes(tempFilters.product_technology[0]) && (tempFilters.product_package.length === 0 || (state.packSize[tempFilters.product_technology[0]] && tempFilters.product_package.length === state.packSize[tempFilters.product_technology[0]].length))) {
        tempFilters['product_package'] = 'ALL'
    }
    // If innovation_type filter is empty or includes all innovation types, set it to 'ALL'
    if (tempFilters.innovation_type && (tempFilters.innovation_type.length === 0 || tempFilters.innovation_type.length === state.innovationTypes.length)) {
        tempFilters['innovation_type'] = 'ALL'
    }
    // If time_year filter is empty or includes all years, keep it as it is.
    if (tempFilters.time_year && (tempFilters.time_year.length === 0 || tempFilters.time_year.length === state.years.length)) {
        tempFilters['time_year'] = tempFilters.time_year
    }
    // If innovation filter is empty or includes all innovations, set it to 'ALL'
    if (tempFilters.innovation && (tempFilters.innovation.length === 0 || tempFilters.innovation.length === state.innovation.length)) {
        tempFilters['innovation'] = 'ALL'
    }
    // If customer_retailer_details filter is empty or includes all retailers, set it to 'ALL'
    if (tempFilters.customer_retailer_details && (tempFilters.customer_retailer_details.length === 0 || tempFilters.customer_retailer_details.length === state.retailerList.length)) {
        tempFilters['customer_retailer_details'] = 'ALL'
    }
    // Set the product_brand and product_secondary_brand filters based on the default brand
    if (brand) {
        if (brand.split(":").length === 2) {
            tempFilters['product_brand'] = brand.split(":")[0]
            tempFilters['product_secondary_brand'] = brand.split(":")[1]
        }
        else {
            tempFilters['product_brand'] = brand
            tempFilters['product_secondary_brand'] = ""
        }
    }

    return tempFilters;
}

const renewFilters = (filters, getState) => {
    let state = getState().innovationTracker

    let tempFilters = { ...filters }
    // If product_pet filter is empty or includes all pets, set it to 'ALL'
    if (tempFilters.product_pet.length > 0 && state.pets.length > 0 && (!state.pets.includes(tempFilters.product_pet[0]))) {
        tempFilters['product_pet'] = 'ALL'
        tempFilters['product_technology'] = 'ALL'
        tempFilters['product_sub_technology'] = 'ALL'
        tempFilters['product_lifestage'] = 'ALL'
        tempFilters['innovation_type'] = 'ALL'
        tempFilters['innovation'] = 'ALL'
    }

    return tempFilters;
}


const getFilterForBrandChange = (filters) => {
    const newFilters = {};
    if (filters.time_year.length > 0) {
      newFilters.time_year = filters.time_year;
    }
    if (filters.product_pet.length > 0) {
        newFilters.time_year = filters.time_year;
      newFilters.product_pet = filters.product_pet;
    }
    if (filters.product_technology.length > 0) {
        newFilters.time_year = filters.time_year;
      newFilters.product_pet = filters.product_pet;
      newFilters.product_technology = filters.product_technology;
    }
  
    return newFilters;
  }

// Action to fetch filter data from the server based on the given filters
export const getFilterData = (filters, firstLoad = false, setUrl = false, setLocalFilter = () => { },isBrandChanged = false, changeSelectedBrand = null) => async (dispatch, getState) => {
    dispatch(setLoading(true)) // Dispatch the action to set loading to true
    let tempFilters = processFilters(filters, getState);
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters))

    CustomAxios(ENDPOINTS.innovationTracker + '?' + encodedURL, 'GET').then((response) => {
        if (!firstLoad) {
            if (response.data.product_pet) dispatch(setPets(response.data.product_pet))
            if (response.data.product_sub_technology) dispatch(setSubTechs(response.data.product_sub_technology))
            if (response.data.product_lifestage) dispatch(setLifeStage(response.data.product_lifestage))
            if (response.data.product_package && Object.keys(response.data.product_package).length > 0) {
                dispatch(setPackSize(response.data.product_package))
                dispatch(setTechs(Object.keys(response.data.product_package)))
            }
            if (response.data.innovation_type) dispatch(setInnovationTypes(response.data.innovation_type))
            if (response.data.innovation) dispatch(setInnovation(response.data.innovation))
            if (response.data.trendchart_kpi) dispatch(setKpi(response.data.trendchart_kpi))

            // Remove the selected values which are not prsent in the dropdown's items.
            // -> Top line filters
            const state = getState().innovationTracker;
            const newFilters = { ...state.filters };

            newFilters.product_pet = newFilters.product_pet.filter(item => (response.data.product_pet || state.pets).includes(item));
            newFilters.product_sub_technology = newFilters.product_sub_technology.filter(item => (response.data.product_sub_technology || state.subTechs).includes(item));
            newFilters.product_lifestage = newFilters.product_lifestage.filter(item => (response.data.product_lifestage || state.lifestage).includes(item));
            newFilters.product_technology = newFilters.product_technology.filter(item => ((response.data.product_package && Object.keys(response.data.product_package)) || state.techs).includes(item));
            if (newFilters.product_package) newFilters.product_package = newFilters.product_package.filter(item => (response.data.product_package[newFilters.product_technology[0]] || state.packSize[newFilters.product_technology[0]] || []).includes(item));
            newFilters.innovation_type = newFilters.innovation_type.filter(item => (response.data.innovation_type || state.innovationTypes).includes(item));
            newFilters.time_year = newFilters.time_year.filter(item => (response.data.time_year || state.years).includes(item));

            dispatch(setFilters(newFilters));
            setLocalFilter((prev) => ({ ...prev, ...newFilters }));

            if (setUrl) {
                dispatch(setAppliedFilters(declareAppliedFilters(newFilters, { pets: state.pets,subTechs: state.subTechs,lifestage:state.lifestage, techs: state.techs, packSize: state.packSize[newFilters.product_technology[0]], innovationTypes: state.innovationTypes, years: state.years, innovation: state.innovation, retailerList: state.retailerList })));
                setWindowHistory(encodeURIComponent(JSON.stringify(newFilters)));
            }
        }
        if (response.data.time_year) dispatch(setYears(response.data.time_year))
        if (response.data.latest_time_year && firstLoad) dispatch(setLatestTimeYear([response.data.latest_time_year]))

        // let newFilters = { ...filters }
        // newFilters.product_pet = newFilters.product_pet.filter(item => (response.data.product_pet || state.pets).includes(item));
        // newFilters.product_technology = newFilters.product_technology.filter(item => ((response.data.product_package && Object.keys(response.data.product_package)) || state.techs).includes(item));
        // if (newFilters.product_package) newFilters.product_package = newFilters.product_package.filter(item => (response.data.product_package[newFilters.product_technology[0]] || state.packSize[newFilters.product_technology[0]] || []).includes(item));
        // newFilters.innovation_type = newFilters.innovation_type.filter(item => (response.data.innovation_type || state.innovationTypes).includes(item));
        // newFilters.innovation = newFilters.innovation.filter(item => ((response.data.innovation && Object.keys(response.data.innovation)) || state.innovation).includes(item));

        // dispatch(setFilters(newFilters))
        // let encodedSearch = encodeURIComponent(JSON.stringify({ ...newFilters }));
        // if (window.history.pushState) {
        //     let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + encodedSearch;
        //     window.history.pushState({ path: newurl }, '', newurl);
        // }
        if (isBrandChanged && changeSelectedBrand) {
            changeSelectedBrand();
            dispatch(getFilterData(getFilterForBrandChange(getState().innovationTracker.filters)));
          }
        dispatch(setLoading(false))
    }).catch((err) => {
        dispatch(setLoading(false))
    })
}

// Action to fetch the latest time year from the server
export const getLatestTimeYear = () => async (dispatch, getState) => {
    dispatch(setLoading(true))
    let state = getState().innovationTracker
    let tempFilters = processFilters({}, getState);
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters))

    CustomAxios(ENDPOINTS.innovationTracker + '?' + encodedURL, 'GET').then((response) => {
        if (response.data.latest_time_year) dispatch(setLatestTimeYear([response.data.latest_time_year]))
        dispatch(setLoading(false))
    }).catch((err) => {
        dispatch(setLoading(false))
    })
}
// Action to fetch the data from the server
export const getTableData = (filters) => async (dispatch, getState) => {
    let renewedFilters = renewFilters(filters, getState);
    let tempFilters = processFilters(renewedFilters, getState);
    tempFilters = {
        ...tempFilters,
        selected_innovation: tempFilters.selected_innovation[0],
        launch_as_benchmark: tempFilters.launch_as_benchmark[0],
        trendchart_kpi: tempFilters.trendchart_kpi[0]
    }
    if (tempFilters.product_package.length === 0) tempFilters.product_package = 'ALL';
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

    dispatch(setLoading(true));
    CustomAxios(ENDPOINTS.launchPerformance + '?' + encodedURL, 'GET').then((response) => {
        dispatch(setLoading(false));

        if (response.data.table_data) {
            const index = response.data.table_data.findIndex(item => item.customer_retailer_details === "Total Market");
            const totalMarket = response.data.table_data.splice(index, 1)[0]; // Remove total market from its current position
            response.data.table_data.unshift(totalMarket); // Add it to the beginning of the array
            dispatch(setTableData(response.data.table_data));
        }
        else {
            dispatch(setTableData(initialState.tableData));
        }
        if (response.data.card_data) dispatch(setCardData(response.data.card_data));
        else dispatch(setCardData(initialState.cardData));
        if (response.data.launch_year) dispatch(setTableLaunchYear(response.data.launch_year));
        else dispatch(setTableLaunchYear(initialState.launch_year));

    }).catch((err) => {
        dispatch(setLoading(false));
        dispatch(setTableData(initialState.tableData));
        dispatch(setCardData(initialState.cardData));
    });
}

// Action to fetch benchmark data for the innovation tracker based on the selected filters.
export const getBenchmark = (filters) => async (dispatch, getState) => {
    let renewedFilters = renewFilters(filters, getState);
    let tempFilters = processFilters(renewedFilters, getState);
    tempFilters = {
        ...tempFilters,
        selected_innovation: tempFilters.selected_innovation[0],
        launch_as_benchmark: tempFilters.launch_as_benchmark[0],
        trendchart_kpi: tempFilters.trendchart_kpi[0],
        selected_retailer: tempFilters.selected_retailer[0],
    }
    if (tempFilters.product_package.length === 0) tempFilters.product_package = 'ALL';
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

    dispatch(setLoading(true));
    CustomAxios(ENDPOINTS.benchmarkDropdown + '?' + encodedURL, 'GET').then(({ data }) => {
        dispatch(setLoading(false));
        if (data) dispatch(setBenchmark(data));
        else dispatch(setBenchmark(initialState.benchmark));
    }).catch((err) => {
        dispatch(setLoading(false));
        dispatch(setBenchmark(initialState.benchmark));
    });
}

// Action to fetch trend data for the innovation tracker based on the selected filters.
export const getTrendData = (filters) => async (dispatch, getState) => {
    let renewedFilters = renewFilters(filters, getState);
    let tempFilters = processFilters(renewedFilters, getState);
    tempFilters = {
        ...tempFilters,
        selected_innovation: tempFilters.selected_innovation[0],
        launch_as_benchmark: tempFilters.launch_as_benchmark[0],
        trendchart_kpi: tempFilters.trendchart_kpi[0],
        selected_retailer: tempFilters.selected_retailer[0],
        output_line: tempFilters.output_line[0],
        dropdown_values: tempFilters.dropdown_values[0],
    }
    if (tempFilters.product_package.length === 0) tempFilters.product_package = 'ALL';

    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));
    // encodedURL = "%7B%22view%22%3A%5B%22innovation_tracker%22%5D%2C%22product_pet%22%3A%22ALL%22%2C%22product_technology%22%3A%22ALL%22%2C%22product_package%22%3A%5B%5D%2C%22innovation_type%22%3A%22Range%20Extension%22%2C%22time_year%22%3A%5B2022%5D%2C%22innovation%22%3A%22Temptations%20Meaty%20Bites%22%2C%22product_brand%22%3A%22ALL%20BRANDS%22%2C%22product_secondary_brand%22%3A%22%22%2C%22launch_as_benchmark%22%3A1%2C%22trendchart_kpi%22%3A%22Velocity%22%2C%22benchmark_values%22%3A%5B%22ALL%22%5D%7D";

    dispatch(setLoading(true));
    CustomAxios(ENDPOINTS.benchmarkData + '?' + encodedURL, 'GET').then(({ data }) => {

        dispatch(setLoading(false));
        if (data) {
            const trendData = [];
            if (data['Category Average']) {
                trendData.push(...data['Category Average'].map((item) => ({ ...item, innovation: "Category Average" })));
            }

            const defaultBenchmark = [];
            Object.keys(data).forEach((petTech) => {
                if (petTech !== "Category Average") {
                    Object.keys(data[petTech]).forEach((brand) => {
                        Object.keys(data[petTech][brand]).forEach((type) => {
                            const benchmark = `${petTech}:${brand}:${type}`;
                            trendData.push(...data[petTech][brand][type].data.map((item) => ({ ...item, innovation: `${type}(Period at Scale: ${data[petTech][brand][type].time_first_period_at_scale})(Launch Period: ${data[petTech][brand][type].launch_period_year})` })));
                            if (type !== tempFilters.selected_innovation) defaultBenchmark.push(benchmark);
                        })
                    })
                }
            });
            dispatch(setTrendData(trendData));
            dispatch(setDefaultBenchmark(defaultBenchmark));
        }
        else dispatch(setTrendData(initialState.trendData));
    }).catch((err) => {
        dispatch(setLoading(false));
        dispatch(setTrendData(initialState.trendData));
        dispatch(setDefaultBenchmark(initialState.defaultBenchmark));
    });
}

// Action to fetch drainers data for the innovation tracker based on the selected filters.
export const getDrainersData = (filters) => async (dispatch, getState) => {
    let renewedFilters = renewFilters(filters, getState);
    let tempFilters = processFilters(renewedFilters, getState);
    tempFilters = {
        ...tempFilters,
        selected_retailer: tempFilters.selected_retailer[0],
    }
    if (tempFilters.product_package.length === 0) tempFilters.product_package = 'ALL';

    // let encodedURL = "%7B%22view%22%3A%5B%22innovation_tracker%22%5D%2C%22product_pet%22%3A%22ALL%22%2C%22product_technology%22%3A%22ALL%22%2C%22product_package%22%3A%22ALL%22%2C%22innovation_type%22%3A%22ALL%22%2C%22time_year%22%3A%5B2022%5D%2C%22innovation%22%3A%22ALL%22%2C%22default_brand%22%3A%5B%22GREENIES%22%5D%2C%22selected_innovation%22%3A%22Canine%20Greenies%20Bites%22%2C%22selected_retailer%22%3A%22Total%20Market%22%2C%22launch_as_benchmark%22%3A1%2C%22output_line%22%3A%22I%22%2C%22dropdown_values%22%3A%22I%22%2C%22benchmark_values%22%3A%5B%5D%2C%22trendchart_kpi%22%3A%22RSV%22%2C%22consumer_tab%22%3A%5B%22A%22%5D%2C%22selected_sku%22%3A%5B%22Canine%20Greenies%20Bites%22%5D%2C%22customer_retailer_details%22%3A%22ALL%22%2C%22selected_retailer_key%22%3A%5B-1%5D%2C%22filter_by%22%3A%5B%5D%2C%22sub_filter_by%22%3A%5B%5D%2C%22product_brand%22%3A%22GREENIES%22%2C%22product_secondary_brand%22%3A%22%22%7D";
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

    dispatch(setLoading(true));
    CustomAxios(ENDPOINTS.drainerChart + '?' + encodedURL, 'GET').then(({ data }) => {
        dispatch(setLoading(false));
        let allData = []
        Object.values(data).map((item) => {
            item.map((drain) => {
                allData.push({
                    brands: drain.ui_kpi,
                    revenue: drain.fact_percent_change_value,
                    type: drain.is_driver === 0 ? "Negative Impact" : "Positive Impact",
                })
            })

        })
        dispatch(setDrainersData(allData))
    }).catch((err) => {
        dispatch(setLoading(false));
    });
}

// Action to fetch review data for the innovation tracker based on the selected filters.
export const getReviewData = (filters) => async (dispatch, getState) => {
    let renewedFilters = renewFilters(filters, getState);
    let tempFilters = processFilters(renewedFilters, getState);
    tempFilters = {
        ...tempFilters,
        selected_innovation: tempFilters.selected_innovation[0],
        launch_as_benchmark: tempFilters.launch_as_benchmark[0],
        trendchart_kpi: tempFilters.trendchart_kpi[0],
        consumer_tab: tempFilters.consumer_tab[0],
        selected_sku: tempFilters.selected_sku.length > 0 ? tempFilters.selected_sku[0] : "ALL",
    }
    if (tempFilters.product_package.length === 0) tempFilters.product_package = 'ALL';

    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));
    // encodedURL = "%7B%22view%22%3A%5B%22innovation_tracker%22%5D%2C%22product_pet%22%3A%22ALL%22%2C%22product_technology%22%3A%22ALL%22%2C%22product_package%22%3A%5B%22ALL%22%5D%2C%22innovation_type%22%3A%22ALL%22%2C%22time_year%22%3A%5B2022%5D%2C%22innovation%22%3A%22ALL%22%2C%22selected_innovation%22%3A%22CESAR%C2%AE%20Wet%20Dog%20Minis%20Launch%22%2C%22product_brand%22%3A%22ALL%20BRANDS%22%2C%22product_secondary_brand%22%3A%22%22%2C%22consumer_tab%22%3A%22A%22%2C%22selected_sku%22%3A%22ALL%22%2C%22customer_retailer_details%22%3A%5B%22ALL%22%5D%7D";

    dispatch(setLoading(true));
    CustomAxios(ENDPOINTS.consumerReview + '?' + encodedURL, 'GET').then(({ data }) => {
        dispatch(setLoading(false));
        if (data.sku_list) dispatch(setSkuList(data.sku_list));
        if (data.retailer_list) dispatch(setRetailerList(data.retailer_list));
        if (data.best_retailer) dispatch(setRetailerList([data.best_retailer]));
        if (data.worst_retailer) dispatch(setRetailerList([data.worst_retailer]));
        if (data.filter_by) dispatch(setReviewFilterBy(data.filter_by)); else dispatch(setReviewFilterBy(initialState.reviewFilterBy));
        if (data) dispatch(setReviewData(data));
        else dispatch(setReviewData(initialState.reviewData));
    }).catch((err) => {
        dispatch(setLoading(false));
        dispatch(setReviewData(initialState.reviewData));
    });

    if (tempFilters.consumer_tab === "A" && tempFilters.customer_retailer_details === "ALL") {
        dispatch(setLoading(true));
        CustomAxios(ENDPOINTS.retailerImage + '?' + encodedURL, 'GET').then(({ data }) => {
            dispatch(setLoading(false));
            if (data && Array.isArray(data)) dispatch(setRetailerImage(data));
            else dispatch(setRetailerImage(initialState.retailerImage));
        }).catch((err) => {
            dispatch(setLoading(false));
            dispatch(setRetailerImage(initialState.retailerImage));
        });
    }
}

// Action to fetch chart data for the innovation tracker based on the selected filters.
export const getChartData = (filters) => async (dispatch, getState) => {
    let renewedFilters = renewFilters(filters, getState);
    let tempFilters = processFilters(renewedFilters, getState);
    delete tempFilters.product_package
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters))
    dispatch(setLoading(true))
    CustomAxios(ENDPOINTS.innovationChart + '?' + encodedURL, 'GET').then((response2) => {
        if (!response2.data.message) {
            let chartData = []
            Object.values(response2.data).map((e, i) => {
                chartData = [...chartData, ...e]
            }
            )
            let gsv = []
            if (tempFilters.innovation.length > 1) {
                gsv = chartData.map((value) => {
                    return {
                        period: value.year_period,
                        innovation: value.gsv_flag_innovation,
                        value: value.gsv_value,
                    }
                })
            } else {
                // let state = getState().refre
                let actual = chartData.filter((i) => i.gsv_flag_innovation.includes("ACTUAL")).map((value) => {
                    return {
                        period: value.year_period,
                        innovation: `ACTUAL_${value.gsv_flag_innovation.split('_')[1]}`,
                        value: value.gsv_actual,
                    }
                })
                let forecast = chartData.map((value) => {
                    return {
                        period: value.year_period,
                        innovation: `FORECAST_${value.gsv_flag_innovation.split('_')[1]}`,
                        value: value.gsv_forecast,
                    }
                })
                gsv = [...actual, ...forecast]
            }

            let sortData = gsv.sort((a, b) => parseInt(a.period.replace(/[^0-9]/g, '')) - parseInt(b.period.replace(/[^0-9]/g, '')));
            dispatch(setGsvChart(sortData))
            dispatch(setLoading(false))
        } else {
            dispatch(setGsvChart([]))
            dispatch(setLoading(false))
        }

    }).catch((err) => {
        dispatch(setLoading(false))
    })
}

// Action to save the innovation tracker report to the server.
export const saveITReport =
    (
        filters,
        accessValue,
        reportId,
        reportName,
        components,
        createReport,
        setComponents,
        setNewReport,
        setOpenSaveDialog,
        setSaveLoading
    ) =>
        async (dispatch, getState) => {
            let brand = getState().profile.defaultBrand
            let profile = getState().profile
            let saveReport = {
                report_id: reportId,
                report_name: reportName,
                report_access: accessValue,
                user_email: sessionStorage.getItem("email"),
                user_name: profile.user.user_name,
                user_designation: profile.userDesignation,
                // team_cluster: profile.teamCluster,
                innovation_components: components,
                product_pet: filters.product_pet,
                product_sub_technology: filters.product_sub_technology,
                product_lifestage: filters.product_lifestage,
                product_technology: filters.product_technology,
                product_package: filters.product_package,
                innovation_type: filters.innovation_type,
                innovation_benchmark_values: filters.benchmark_values,
                innovation_trendchart_kpi: filters.trendchart_kpi,
                innovation: filters.innovation,
                selected_innovation: filters.selected_innovation,
                time_year: filters.time_year,
                innovation_years: filters.time_year.map(val => String(val)),
                time_period: filters.time_period,
                product_brand: brand.split(":").length === 2 ? brand.split(":")[0] : brand,
                product_secondary_brand: brand.split(":").length === 2 ? brand.split(":")[1] : ""
            };

            let addToExisting = {
                report_id: reportId,
                report_name: reportName,
                user_name: profile.user.user_name,
                user_email: sessionStorage.getItem("email"),
                // team_cluster: profile.teamCluster,
                innovation_components: components,
                innovation_type: filters.innovation_type,
                innovation_benchmark_values: filters.benchmark_values,
                innovation_trendchart_kpi: filters.trendchart_kpi,
                innovation: filters.innovation,
                innovation_years: filters.time_year,
                selected_innovation: filters.selected_innovation,
            };

            setSaveLoading(true);
            CustomAxios(ENDPOINTS.saveReport, "POST", createReport ? saveReport : addToExisting)
                .then((response) => {
                    if (response.data.error_code === undefined) {
                        openNotificationWithIcon("success", response.data.message);
                        setComponents([]);
                        setOpenSaveDialog(false);
                        setNewReport(false);
                    } else {
                        openNotificationWithIcon("error", "Error saving report. Please contact support.");
                    }
                    setSaveLoading(false);
                })
                .catch((err) => {
                    setSaveLoading(false);

                    openNotificationWithIcon("error", "Error Occured");
                });
        };

export default innovationTrackerSlicer.reducer