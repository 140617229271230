import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Layout,
  Row,
  Col,
  Button,
  Switch,
  Radio,
  Tabs,
  Select,
  TreeSelect,
} from "antd";
import { DownloadOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { useHistory, withRouter } from "react-router-dom";
import Loader from "../../components/loader";
import Wrapper from "../../components/wrapper";
import {
  addSymbol,
  allBrands,
  brandSplit,
  formatNumber,
  hasValue,
  itemsListed,
  marsPN,
  signNames,
  signs,
  symbols,
  valueTypes,
} from "../../utilities/helper";
import {
  getData,
  getKPIComparisonChartData,
  setCompareVia,
  setKpi,
  setDeepDiveList,
  setKpiForDeepDive,
  setCompareViaEcom,
  setKpi1,
  getEcomKPIComparisonChartData,
  setEcomDeepDiveList,
  setEcomKpiForDeepDive,
  setEcomDeepDiveChart,
  setEcomComparisonChartData,
  setKpi2,
  setComparisonChartData,
  setDeepDiveChart,
} from "../../slicers/trackboard.slicer";
import "./trackboard.scss";
import { exportTrackboard, numFmt } from "../../utilities/exports/excel";
import { setExportData } from "../../slicers/exportData.slicer";
import {
  resetPreFilters,
  resetDistributionSummary,
  setReportRefreshDist,
} from "../../slicers/distributionSummary.slicer";
import {
  resetPerformanceSummary,
  setReportRefreshPerf,
} from "../../slicers/performanceSummary.slicer";
import {
  getAllFilters,
  resetVolumeShifting,
  setReportRefreshVol,
} from "../../slicers/volumeShiftingSummary.slicer";
import {
  resetInnovationTracker,
  setReportRefreshInnovation,
} from "../../slicers/innovation-tracker.slicer";
import { paths } from "../../utilities/paths";
import {
  compareViaForTrackboard,
  compareViaForTrackboardEcom,
  trackboardKpiData,
  trackboardKpiDataForEcom,
  trackboardKpiDataForMars,
  yearTimePeriodLimits,
} from "../../utilities/constants";
import { ColumnBulletChart, ColumnLineChart, MultiLineChart } from "./tbUIHelper";

const { Content } = Layout;
const { TabPane } = Tabs;

/**
 * Trackboard
 * It manages displaying of Trackboard with different tabs and functionalities.
 */

function Trackboard() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { defaultBrand } = useSelector((state) => state.profile);
  const {
    loading,
    trackboardData,
    innovationData,
    competitionData,
    compareVia,
    kpi,
    kpi1,
    kpi2,
    kpiForDeepDive,
    comparisonChartData,
    deepDiveList,
    deepDiveChartData,
    compareViaEcom,
    ecomComparisonChartData,
    ecomDeepDiveList,
    ecomKpiForDeepDive,
    ecomDeepDiveChartData,
  } = useSelector((state) => state.trackboard);
  const { reportRefreshPerf } = useSelector((state) => state.perfSummary);
  const { trackboard } = useSelector((state) => state.refreshDate);
  const exportData = useSelector((state) => state.exportData);
  const [ytdToggle, setYTDToggle] = useState(false);
  const [tab, setTab] = useState(0);
  const isDefaultBrandMarsBDB = defaultBrand.toUpperCase() === allBrands;

  const KPIHeading = ({ heading, path }) => (
    <div className="trackboard-kpi-heading">
      <h2>
        {heading}
        <div className="trackboard-icon" onClick={() => history.push(path)}>
          <ArrowUpOutlined rotate={45} />
        </div>
      </h2>
    </div>
  );

  const KPICard = ({
    title,
    value,
    decimals = 1,
    benchmark1Value,
    benchmark2Value,
    benchmark3Value,
    valueSymbol,
    benchmark1Symbol,
    benchmark2Symbol,
    benchmark3Symbol,
    benchmark1Conditional,
  }) => {
    const benchmark1Sign =
      hasValue(benchmark1Value) && benchmark1Value > 0
        ? signNames.POSITIVE
        : signNames.NEGATIVE;
    const benchmark2Sign =
      hasValue(benchmark2Value) && benchmark2Value > 0
        ? signNames.POSITIVE
        : signNames.NEGATIVE;
    const benchmark3Sign =
      hasValue(benchmark3Value) && benchmark3Value > 0
        ? signNames.POSITIVE
        : signNames.NEGATIVE;

    const getBenchmark1 = () => (
      <>
        {hasValue(benchmark1Value) && (
          <div class="kpi-key-value-pair">
            <div>{isDefaultBrandMarsBDB ? "Category vs YA" : "Mars vs YA"}</div>
            <div class={`kpi-value ${benchmark1Sign}`}>
              {formatNumber(benchmark1Value, 1, true) === valueTypes.NA
                ? valueTypes.NA
                : (benchmark1Sign === signNames.POSITIVE
                  ? signs[benchmark1Sign]
                  : "") +
                addSymbol(formatNumber(benchmark1Value), benchmark1Symbol)}
            </div>
          </div>
        )}
      </>
    );

    return (
      <div class="kpi-card">
        <div class="kpi-card-content">
          <div class="kpi-key-value-pair">
            <h2>{title}</h2>
            {hasValue(value) && (
              <h4>
                {formatNumber(value, 1, true) === valueTypes.NA
                  ? valueTypes.NA
                  : addSymbol(formatNumber(value, decimals), valueSymbol)}
              </h4>
            )}
          </div>
          {!benchmark1Conditional && getBenchmark1()}
          {benchmark1Conditional && !isDefaultBrandMarsBDB && getBenchmark1()}
          {hasValue(benchmark2Value) && (
            <div class="kpi-key-value-pair">
              <div>vs YA</div>
              <div class={`kpi-value ${benchmark2Sign}`}>
                {formatNumber(benchmark2Value, 1, true) === valueTypes.NA
                  ? valueTypes.NA
                  : (benchmark2Sign === signNames.POSITIVE
                    ? signs[benchmark2Sign]
                    : "") +
                  addSymbol(formatNumber(benchmark2Value), benchmark2Symbol)}
              </div>
            </div>
          )}
          {hasValue(benchmark3Value) && (
            <div class="kpi-key-value-pair">
              <div>vs Target</div>
              <div class={`kpi-value ${benchmark3Sign}`}>
                {formatNumber(benchmark3Value, 1, true) === valueTypes.NA
                  ? valueTypes.NA
                  : (benchmark3Sign === signNames.POSITIVE
                    ? signs[benchmark3Sign]
                    : "") +
                  addSymbol(formatNumber(benchmark3Value), benchmark3Symbol)}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const title = `Total ${isDefaultBrandMarsBDB ? marsPN : brandSplit(defaultBrand)
    } - Total Market Performance ${ytdToggle ? "YTD" : "for Period"}`;

  useEffect(() => {
    dispatch(getData(ytdToggle));
    dispatch(resetPreFilters());
    if (reportRefreshPerf) {
      dispatch(resetPerformanceSummary());
      dispatch(setReportRefreshPerf(false));
      dispatch(resetDistributionSummary());
      dispatch(setReportRefreshDist(false));
      dispatch(resetVolumeShifting());
      dispatch(setReportRefreshVol(false));
      dispatch(resetInnovationTracker());
      dispatch(setReportRefreshInnovation(false));
      dispatch(getAllFilters());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultBrand]);

  useEffect(() => {
    dispatch(getData(ytdToggle));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ytdToggle]);

  useEffect(() => {
    if (compareVia) {
      if (tab === 0) {
        dispatch(getKPIComparisonChartData(ytdToggle));
        dispatch(setKpi1("dollar"));
        dispatch(setKpi2("dollar_share"));
        dispatch(setEcomDeepDiveList([]));
        dispatch(setEcomKpiForDeepDive("dollar"));
        dispatch(setCompareViaEcom("Category"));
        dispatch(setEcomDeepDiveChart([]));
        dispatch(setEcomComparisonChartData([]));
      }
    }
  }, [compareVia, ytdToggle, tab, defaultBrand]);

  useEffect(() => {
    if (compareViaEcom) {
      if (tab === 1) {
        dispatch(getEcomKPIComparisonChartData(ytdToggle));
        dispatch(setCompareVia("Category"));
        dispatch(setKpi(["dollar", "dollar_share"]));
        dispatch(setKpiForDeepDive("dollar"));
        dispatch(setDeepDiveList([]));
        dispatch(setComparisonChartData([]));
        dispatch(setDeepDiveChart([]));
      }
    }
  }, [compareViaEcom, ytdToggle, tab, defaultBrand]);
  const exportPage = () =>
    exportTrackboard(
      exportData,
      isDefaultBrandMarsBDB,
      "Trackboard",
      innovationData
    );
  const [stored, setStored] = useState(false);
  useEffect(() => {
    if (!trackboardData) return;
    if (Object.keys(trackboardData).length === 0) return;
    dispatch(
      setExportData({
        kpi: {
          header: "",
          title,
          data: trackboardData,
        },
      })
    );
  }, [dispatch, trackboardData]);

  const brandName =
    defaultBrand === allBrands ? "MARS" : defaultBrand.split(":")[0];
  const competitionSummary =
    competitionData.competition_summary &&
    Object.keys(competitionData.competition_summary)
      .filter((channel) => !channel.toLowerCase().includes("total"))
      .slice(0, 4)
      .map((channel) => ({
        channel,
        channelName: channel.split(" ")[0].replace("Ecom", "eCom"),
      }));

  const { Option } = Select;
  const { SHOW_PARENT } = TreeSelect;

  const last12PeriodRecords = (data, skipRecord) => {
    let uniqueTimePeriods = [...new Set(data.map(item => item.time_year_period))];

    // Sort them in descending order
    uniqueTimePeriods.sort((a, b) => {
      if (a > b) return -1;
      if (a < b) return 1;
      return 0;
    });

    // Take the first 12 unique values
    let last12UniqueTimePeriods = uniqueTimePeriods.slice(0, skipRecord);
    return data.filter(item => last12UniqueTimePeriods.includes(item.time_year_period));

  }
  const sortedComparisonChartData = [...comparisonChartData].sort((a, b) => b[kpi[0]] - a[kpi[0]]);

  const sortedEcomComparisonChartData = [...ecomComparisonChartData].sort((a, b) => {
    const kpi1Comparison = b[kpi1] - a[kpi1];
    if (kpi1Comparison !== 0) {
      return kpi1Comparison;
    }
    return b[kpi2] - a[kpi2];
  });
  return (
    <Wrapper heading={"1"}>
      <Content
        className={[
          "site-layout-background content-body",
          "trackboard-content-v2",
        ]}
      >
        <Loader loading={loading > 0}>
          <Row>
            <div className="trackboard-info-v2">
              <p>
                {title} - {trackboardData.latest_time_period_year}
              </p>
              <a href="javascript:void(0)" onClick={exportPage}>
                <DownloadOutlined />
              </a>
            </div>
          </Row>
          <Row>
            <div className="refreshed-on">
              <span>Last Refreshed on: {trackboard.last_refresh}</span>
              <div className="ytd_toggle">
                <div className={ytdToggle ? "unselected" : "selected"}>
                  Period
                </div>
                <div className="switch">
                  <Switch
                    size="small"
                    checked={ytdToggle}
                    onChange={(e) => setYTDToggle(e)}
                  />
                </div>
                <div className={!ytdToggle ? "unselected" : "selected"}>
                  YTD
                </div>
              </div>
              <span>Next Refresh on: {trackboard.next_refresh}</span>
            </div>
          </Row>
          <Row>
            <Col span={24}>
              <div class="trackboard-container">
                <div class="trackboard-card trackboard-left-card">
                  <Row>
                    <div className="trackboard-nested-tabs">
                      <Radio.Group
                        defaultValue={0}
                        buttonStyle="solid"
                        value={tab}
                        onChange={(e) => {
                          setTab(e.target.value)
                          if (e.target.value === 1) {

                          }
                        }
                        }
                      >
                        <Radio.Button value={0}>
                          {brandName} Total Performance
                        </Radio.Button>
                        <Radio.Button value={1}>eCom Diagnostics</Radio.Button>
                      </Radio.Group>
                    </div>
                  </Row>

                  {tab === 0 && (
                    <>
                      <div>
                        <KPIHeading
                          heading="Performance Summary"
                          path={paths.performanceSummary}
                        />
                        <div class="kpi-cards-container">
                          <KPICard
                            title="RSV"
                            value={trackboardData.perf_summary_rsv?.value}
                            benchmark1Value={
                              trackboardData.perf_summary_rsv?.mars_ya
                            }
                            benchmark2Value={
                              trackboardData.perf_summary_rsv?.ya
                            }
                            valueSymbol={symbols.dollar}
                            benchmark1Symbol={symbols.percent}
                            benchmark2Symbol={symbols.percent}
                          />
                          <KPICard
                            title="GSV"
                            value={trackboardData.perf_summary_gsv?.value}
                            benchmark1Value={
                              trackboardData.perf_summary_gsv?.mars_ya
                            }
                            benchmark2Value={
                              trackboardData.perf_summary_gsv?.ya
                            }
                            valueSymbol={symbols.dollar}
                            benchmark1Symbol={symbols.percent}
                            benchmark2Symbol={symbols.percent}
                            benchmark1Conditional={true}
                          />
                          <KPICard
                            title="NSV"
                            value={trackboardData.perf_summary_nsv?.value}
                            benchmark1Value={
                              trackboardData.perf_summary_nsv?.mars_ya
                            }
                            benchmark2Value={
                              trackboardData.perf_summary_nsv?.ya
                            }
                            valueSymbol={symbols.dollar}
                            benchmark1Symbol={symbols.percent}
                            benchmark2Symbol={symbols.percent}
                            benchmark1Conditional={true}
                          />
                        </div>
                      </div>
                      <div>
                        <KPIHeading
                          heading="Competition Summary"
                          path={paths.volumeShifting}
                        />

                        <div class="kpi-cards-container">
                          <KPICard
                            title={
                              competitionSummary &&
                              `${brandName} ${competitionSummary[0].channelName} $ Share`
                            }
                            value={
                              competitionSummary &&
                              competitionData.competition_summary[
                                competitionSummary[0].channel
                              ].dollar_share
                            }
                            benchmark2Value={
                              competitionSummary &&
                              competitionData.competition_summary[
                                competitionSummary[0].channel
                              ].dollar_share_variance
                            }
                            valueSymbol={symbols.percent}
                            benchmark2Symbol={symbols.points}
                          />
                          <KPICard
                            title={
                              competitionSummary &&
                              `${brandName} ${competitionSummary[1].channelName} $ Share`
                            }
                            value={
                              competitionSummary &&
                              competitionData.competition_summary[
                                competitionSummary[1].channel
                              ].dollar_share
                            }
                            benchmark2Value={
                              competitionSummary &&
                              competitionData.competition_summary[
                                competitionSummary[1].channel
                              ].dollar_share_variance
                            }
                            valueSymbol={symbols.percent}
                            benchmark2Symbol={symbols.points}
                          />
                        </div>
                        <div class="kpi-cards-container">
                          <KPICard
                            title={
                              competitionSummary &&
                              `${brandName} ${competitionSummary[2].channelName} $ Share`
                            }
                            value={
                              competitionSummary &&
                              competitionData.competition_summary[
                                competitionSummary[2].channel
                              ].dollar_share
                            }
                            benchmark2Value={
                              competitionSummary &&
                              competitionData.competition_summary[
                                competitionSummary[2].channel
                              ].dollar_share_variance
                            }
                            valueSymbol={symbols.percent}
                            benchmark2Symbol={symbols.points}
                          />
                          <KPICard
                            title={
                              competitionSummary &&
                              `${brandName} ${competitionSummary[3].channelName} $ Share`
                            }
                            value={
                              competitionSummary &&
                              competitionData.competition_summary[
                                competitionSummary[3].channel
                              ].dollar_share
                            }
                            benchmark2Value={
                              competitionSummary &&
                              competitionData.competition_summary[
                                competitionSummary[3].channel
                              ].dollar_share_variance
                            }
                            valueSymbol={symbols.percent}
                            benchmark2Symbol={symbols.points}
                          />
                        </div>
                      </div>
                      <div>
                        <KPIHeading
                          heading="Distribution Summary"
                          path={paths.distributionSummary}
                        />
                        <div class="kpi-cards-container">
                          <KPICard
                            title="TDP"
                            value={trackboardData.dist_summary_tdp?.value}
                            benchmark1Value={
                              trackboardData.dist_summary_tdp?.mars_ya
                            }
                            benchmark2Value={
                              trackboardData.dist_summary_tdp?.ya
                            }
                            valueSymbol={symbols.dollar}
                            benchmark1Symbol={symbols.percent}
                            benchmark2Symbol={symbols.percent}
                          />
                          <KPICard
                            title="$/TDP"
                            value={
                              trackboardData.dist_summary_dollar_tdp?.value
                            }
                            benchmark1Value={
                              trackboardData.dist_summary_dollar_tdp?.mars_ya
                            }
                            benchmark2Value={
                              trackboardData.dist_summary_dollar_tdp?.ya
                            }
                            valueSymbol={symbols.dollar}
                            benchmark1Symbol={symbols.percent}
                            benchmark2Symbol={symbols.percent}
                          />
                          <KPICard
                            title="CSL"
                            value={trackboardData.dist_summary_csl?.value}
                            benchmark1Value={
                              trackboardData.dist_summary_csl?.mars_ya
                            }
                            benchmark2Value={
                              trackboardData.dist_summary_csl?.ya
                            }
                            valueSymbol={symbols.percent}
                            benchmark1Symbol={symbols.points}
                            benchmark2Symbol={symbols.points}
                            benchmark1Conditional={true}
                          />
                        </div>
                      </div>
                      <div>
                        <KPIHeading
                          heading="Portfolio Summary"
                          path={paths.portfolioSummary}
                        />

                        <div class="kpi-cards-container">
                          <KPICard
                            title="$ Sales Share"
                            value={
                              trackboardData.vol_shifting_sales_share_usd?.value
                            }
                            benchmark1Value={
                              trackboardData.vol_shifting_sales_share_usd
                                ?.mars_ya
                            }
                            benchmark2Value={
                              trackboardData.vol_shifting_sales_share_usd?.ya
                            }
                            valueSymbol={symbols.percent}
                            benchmark1Symbol={symbols.points}
                            benchmark2Symbol={symbols.points}
                            benchmark1Conditional={true}
                          />
                          <KPICard
                            title="Lbs Vol. Share"
                            value={
                              trackboardData.vol_shifting_sales_share_tonne
                                ?.value
                            }
                            benchmark1Value={
                              trackboardData.vol_shifting_sales_share_tonne
                                ?.mars_ya
                            }
                            benchmark2Value={
                              trackboardData.vol_shifting_sales_share_tonne?.ya
                            }
                            valueSymbol={symbols.percent}
                            benchmark1Symbol={symbols.points}
                            benchmark2Symbol={symbols.points}
                            benchmark1Conditional={true}
                          />
                        </div>
                      </div>
                      <div>
                        <KPIHeading
                          heading="Innovation"
                          path={paths.innovationTracker}
                        />
                        <div class="kpi-cards-container">
                          <KPICard
                            title="RSV YEE"
                            value={innovationData.rsv_actuals}
                            benchmark3Value={innovationData.rsv_yee_variance}
                            valueSymbol={symbols.dollar}
                            benchmark3Symbol={symbols.percent}
                          />
                          <KPICard
                            title="GSV YEE"
                            value={innovationData.gsv_actuals}
                            benchmark3Value={innovationData.gsv_yee_variance}
                            valueSymbol={symbols.dollar}
                            benchmark3Symbol={symbols.percent}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {tab === 1 && (
                    <>
                      <div>
                        <KPIHeading heading="eCom" path={paths.ecom} />
                        <div class="kpi-cards-container">
                          <KPICard
                            title="RSV"
                            value={trackboardData.ecom_rsv?.value}
                            benchmark1Value={trackboardData.ecom_rsv?.mars_ya}
                            benchmark2Value={trackboardData.ecom_rsv?.ya}
                            valueSymbol={symbols.dollar}
                            benchmark1Symbol={symbols.percent}
                            benchmark2Symbol={symbols.percent}
                          />
                          <KPICard
                            title="$ Sales Share"
                            value={trackboardData.ecom_sales_share?.value}
                            benchmark1Value={
                              trackboardData.ecom_sales_share?.mars_ya
                            }
                            benchmark2Value={
                              trackboardData.ecom_sales_share?.ya
                            }
                            valueSymbol={symbols.percent}
                            benchmark1Symbol={symbols.points}
                            benchmark2Symbol={symbols.points}
                            benchmark1Conditional={true}
                          />

                        </div>
                        <div class="kpi-cards-container">

                          <KPICard
                            title="CSL"
                            value={trackboardData.ecom_csl?.value}
                            benchmark1Value={trackboardData.ecom_csl?.mars_ya}
                            benchmark2Value={trackboardData.ecom_csl?.ya}
                            valueSymbol={symbols.percent}
                            benchmark1Symbol={symbols.points}
                            benchmark2Symbol={symbols.points}
                          />
                        </div>
                      </div>
                      <div>
                        <KPIHeading
                          heading="Be Available"
                          path={paths.beAvailable}
                        />
                        <div class="kpi-cards-container">
                          <KPICard
                            title="# of items listed"
                            decimals={0}
                            value={trackboardData.ecom_items_listed?.value}
                            benchmark1Value={
                              trackboardData.ecom_items_listed?.mars_ya
                            }
                            benchmark2Value={
                              trackboardData.ecom_items_listed?.ya
                            }
                            benchmark1Symbol={symbols.percent}
                            benchmark2Symbol={symbols.percent}
                          />

                        </div>
                        <div class="kpi-cards-container">
                          <KPICard
                            title="% of items in stock"
                            value={trackboardData.ecom_items_in_stock?.value}
                            benchmark1Value={
                              trackboardData.ecom_items_in_stock?.mars_ya
                            }
                            benchmark2Value={
                              trackboardData.ecom_items_in_stock?.ya
                            }
                            valueSymbol={symbols.percent}
                            benchmark1Symbol={symbols.points}
                            benchmark2Symbol={symbols.points}
                          />
                        </div>
                      </div>
                      <div>
                        <KPIHeading
                          heading="Be Visible"
                          path={paths.shareOfShelf}
                        />
                        <div class="kpi-cards-container">
                          <KPICard
                            title={<div class="be-visible-title">Share of Shelf</div>}
                            value={trackboardData.ecom_share_of_shelf?.value}
                            // benchmark1Value={trackboardData.ecom_share_of_shelf?.mars_ya}
                            // benchmark2Value={trackboardData.ecom_share_of_shelf?.ya}
                            valueSymbol={symbols.percent}
                          // benchmark1Symbol={symbols.points}
                          // benchmark2Symbol={symbols.points}
                          />
                          <KPICard
                            title="Average Placement"
                            decimals={0}
                            value={trackboardData.ecom_avg_placement?.value}
                            benchmark1Value={
                              trackboardData.ecom_avg_placement?.mars_ya
                            }
                            benchmark2Value={
                              trackboardData.ecom_avg_placement?.ya
                            }
                            benchmark1Symbol={symbols.percent}
                            benchmark2Symbol={symbols.percent}
                          />


                        </div>
                        {/* <div class="kpi-cards-container">
                          <KPICard
                            title={<div class="be-visible-title">Stackline Traffic</div>}
                            decimals={0}
                            value={trackboardData.ecom_stackline_traffic?.value}
                            benchmark1Value={
                              trackboardData.ecom_stackline_traffic?.mars_ya
                            }
                            benchmark2Value={
                              trackboardData.ecom_stackline_traffic?.ya
                            }
                            benchmark1Symbol={symbols.percent}
                            benchmark2Symbol={symbols.percent}
                          />
                          <KPICard
                            title={<div class="be-visible-title">Similarweb Traffic</div>}
                            decimals={2}
                            value={trackboardData.ecom_similarweb_traffic?.value}
                            benchmark1Value={
                              trackboardData.ecom_similarweb_traffic?.mars_ya
                            }
                            benchmark2Value={
                              trackboardData.ecom_similarweb_traffic?.ya
                            }
                            benchmark1Symbol={symbols.percent}
                            benchmark2Symbol={symbols.percent}
                          />
                        </div> */}

                      </div>
                    </>
                  )}
                </div>
                <div class="trackboard-card trackboard-right-card">
                  {tab === 0 ? (
                    <Row>
                      <Col span={24}>
                        <div className="nutro-skus-filter">
                          <div className="nutro-skus-filter-category">
                            <Col>
                              <label className="kpi-labels">Compare via</label>
                              <Select
                                placeholder="Please select"
                                style={{ width: 130 }}
                                dropdownMatchSelectWidth={false}
                                maxTagCount="responsive"
                                // value={filters?.trend_compare_via && filters?.trend_compare_via[0]}
                                onChange={(value) => {
                                  dispatch(setCompareVia(value));
                                }}
                                value={compareVia}
                              >
                                {compareViaForTrackboard.map((compare, index) => (
                                  <Option key={index} value={compare}>
                                    {compare}
                                  </Option>
                                ))}
                              </Select>
                            </Col>
                            <Col>
                              <label className="kpi-labels">Select KPI's</label>

                              <Select
                                placeholder="Please select"
                                style={{ width: 130 }}
                                dropdownMatchSelectWidth={false}
                                maxTagCount="responsive"
                                mode="multiple"
                                onChange={(value) => {

                                  if (
                                    value[value.length - 1].includes("dollar")
                                  ) {
                                    dispatch(
                                      setKpi(["dollar", "dollar_share"])
                                    );
                                  } else if (
                                    value[value.length - 1].includes("tdp")
                                  ) {
                                    dispatch(setKpi(["tdp", "tdp_share"]));
                                  } else {
                                    dispatch(
                                      setKpi(["dollar", "dollar_share"])
                                    );
                                  }
                                }}
                                value={kpi}
                              >
                                {Object.keys(trackboardKpiDataForMars).map(
                                  (compare, index) => (
                                    <Option key={index} value={compare}>
                                      {trackboardKpiDataForMars[compare]}
                                    </Option>
                                  )
                                )}
                              </Select>

                            </Col>
                          </div>
                        </div>
                      </Col>
                      <Col span={24} className="column-bullet-chart">
                        <div className="section-title">
                          <h4>KPI comparison by {compareVia}</h4>
                          <p>Select KPI from the dropdown</p>
                        </div>
                        <ColumnBulletChart
                          kpiOption={trackboardKpiDataForMars}
                          kpi={kpi[0]}
                          data={sortedComparisonChartData}
                          xField={[
                            `${compareVia === "Category"
                              ? "product_category"
                              : "customer_channel"
                            }`,
                          ]}
                          viewBy={
                            `${compareVia === "Category"
                              ? "product_category"
                              : "customer_channel"
                            }`
                          }
                          yField={kpi}
                          yFieldNumFmt={[numFmt.percentage, numFmt.percentage]}
                          yBarUnit="$"
                          yBulletUnit={kpi[0].includes("tdp") ? "$" : "%"}
                          value={deepDiveList}
                          onChange={(value) => {
                            sessionStorage.setItem("TrackBoardComparisonTrendChart", JSON.stringify(value))
                            dispatch(setDeepDiveList(value));
                          }}
                          // yBarUnit="%"
                          stored={stored}
                          setStored={setStored}
                          storageKey="TrackBoardComparisonTrendChart"
                        />
                      </Col>
                      <Col span={24} className="column-bullet-chart">
                        <div className="section-title">
                          <h4>
                            KPI Trend -{" "}
                            {deepDiveList.length > 1
                              ? "Deep Dive"
                              : deepDiveList[0]}
                          </h4>
                          <p>Select {compareVia} from the dropdown</p>
                        </div>
                        <div className="retailer-filter">
                          <label className="kpi-labels">{compareVia}</label>
                          <Select
                            placeholder="Please select"
                            style={{ width: 80 }}
                            dropdownMatchSelectWidth={false}
                            mode="multiple"
                            maxTagCount="responsive"
                            value={deepDiveList}
                            onChange={(value) => {
                              dispatch(setDeepDiveList(value));
                            }}
                          >
                            {comparisonChartData
                              .map(
                                (i) =>
                                  i[
                                  compareVia === "Category"
                                    ? "product_category"
                                    : "customer_channel"
                                  ]
                              )
                              .map((compare, index) => (
                                <Option key={index} value={compare}>
                                  {compare}
                                </Option>
                              ))}
                          </Select>
                          {deepDiveList.length > 1 && (
                            <>
                              <label className="kpi-labels mleft">KPI</label>
                              <Select
                                placeholder="Please select"
                                style={{ width: 80 }}
                                dropdownMatchSelectWidth={false}
                                maxTagCount="responsive"
                                onChange={(value) => {

                                  dispatch(setKpiForDeepDive(value));
                                }}
                                value={kpiForDeepDive}
                              >
                                {Object.keys(trackboardKpiDataForMars).map(
                                  (compare, index) => (
                                    <Option key={index} value={compare}>
                                      {trackboardKpiDataForMars[compare]}
                                    </Option>
                                  )
                                )}
                              </Select>
                            </>
                          )}
                        </div>
                        <div style={{ marginTop: 77 }}>
                          {deepDiveList.length > 1 ? (

                            <MultiLineChart
                              kpiOption={trackboardKpiDataForMars}
                              compareVia={compareVia}
                              kpi={kpiForDeepDive}
                              data={
                                deepDiveChartData.filter((item) => deepDiveList.includes(item[
                                  compareVia === "Category"
                                    ? "product_category"
                                    : "customer_channel"
                                ])
                                )
                              }
                              xField={["time_year_period"]}
                              yField={kpiForDeepDive}
                              yFieldNumFmt={[numFmt.number, numFmt.percentage]}
                              yBarUnit="$"
                              yBulletUnit={kpiForDeepDive.includes("tdp") ? "$" : "%"}
                              seriesField={compareVia === "Category"
                                ? "product_category"
                                : "customer_channel"}
                            />
                          ) : (
                            <ColumnLineChart
                              kpiOption={trackboardKpiDataForMars}
                              kpi={kpi[0]}
                              data={
                                deepDiveList.length === 1
                                  ? deepDiveChartData.filter(
                                    (item) =>
                                      item[
                                      compareVia === "Category"
                                        ? "product_category"
                                        : "customer_channel"
                                      ] === deepDiveList[0]
                                  )
                                  : []
                              }
                              xField={["time_year_period"]}
                              yField={kpi}
                              yFieldNumFmt={[numFmt.number, numFmt.percentage]}
                              yBarUnit="$"
                              yBulletUnit={kpi[0].includes("tdp") ? "$" : "%"}
                            />
                          )}
                        </div>
                      </Col>
                    </Row>) :
                    <Row>
                      <Col span={24}>
                        <div className="nutro-skus-filter">
                          <div className="nutro-skus-filter-category">
                            <Col>
                              <label className="kpi-labels">Compare via</label>
                              <Select
                                placeholder="Please select"
                                style={{ width: 80 }}
                                dropdownMatchSelectWidth={false}
                                maxTagCount="responsive"
                                // value={filters?.trend_compare_via && filters?.trend_compare_via[0]}
                                onChange={(value) => {
                                  dispatch(setCompareViaEcom(value));
                                }}
                                value={compareViaEcom}
                              >
                                {compareViaForTrackboardEcom.map((compare, index) => (
                                  <Option key={index} value={compare}>
                                    {compare}
                                  </Option>
                                ))}
                              </Select>
                            </Col>
                            <Col>
                              <label className="kpi-labels">KPI 1</label>

                              <Select
                                placeholder="Please select"
                                style={{ width: 80 }}
                                dropdownMatchSelectWidth={false}
                                maxTagCount="responsive"
                                // mode="multiple"
                                onChange={(value) => {
                                  dispatch(setKpi1(value))

                                }}
                                value={kpi1}
                              >
                                {Object.keys(trackboardKpiDataForEcom).filter((item) => kpi2 !== item).map(
                                  (compare, index) => (
                                    <Option key={index} value={compare}>
                                      {trackboardKpiDataForEcom[compare]}
                                    </Option>
                                  )
                                )}
                              </Select>

                            </Col>
                            <Col>
                              <label className="kpi-labels">KPI 2</label>

                              <Select
                                placeholder="Please select"
                                style={{ width: 80 }}
                                dropdownMatchSelectWidth={false}
                                maxTagCount="responsive"
                                // mode="multiple"
                                onChange={(value) => {
                                  dispatch(setKpi2(value))

                                }}
                                value={kpi2}
                              >
                                {Object.keys(trackboardKpiDataForEcom).filter((item) => kpi1 !== item).map(
                                  (compare, index) => (
                                    <Option key={index} value={compare}>
                                      {trackboardKpiDataForEcom[compare]}
                                    </Option>
                                  )
                                )}
                              </Select>

                            </Col>
                          </div>
                        </div>
                      </Col>
                      <Col span={24} className="column-bullet-chart">
                        <div className="section-title">
                          <h4>KPI comparison by {compareViaEcom}</h4>
                          <p>Select KPI from the dropdown</p>
                        </div>
                        <ColumnBulletChart
                          kpiOption={trackboardKpiDataForEcom}
                          kpi={kpi1}
                          data={sortedEcomComparisonChartData}
                          xField={[
                            `${compareViaEcom === "Category"
                              ? "product_category"
                              : "customer_retailer_details"
                            }`,
                          ]}
                          viewBy={
                            `${compareViaEcom === "Category"
                              ? "product_category"
                              : "customer_retailer_details"
                            }`
                          }
                          yField={[kpi1, kpi2]}
                          yFieldNumFmt={[numFmt.percentage, numFmt.percentage]}
                          yBarUnit="$"
                          yBulletUnit={kpi1.includes("tdp") ? "$" : "%"}
                          value={ecomDeepDiveList}
                          onChange={(value) => {
                            sessionStorage.setItem("TrackBoardEcomComparisonTrendChart", JSON.stringify(value))
                            dispatch(setEcomDeepDiveList(value));
                          }}
                          // yBarUnit="%"
                          stored={stored}
                          setStored={setStored}
                          storageKey="TrackBoardEcomComparisonTrendChart"
                        />
                      </Col>
                      <Col span={24} className="column-bullet-chart">
                        <div className="section-title">
                          <h4>
                            KPI Trend -{" "}
                            {ecomDeepDiveList.length > 1
                              ? "Deep Dive"
                              : ecomDeepDiveList[0]}
                          </h4>
                          <p>Select {compareViaEcom} from the dropdown</p>
                        </div>
                        <div className="retailer-filter">
                          <label className="kpi-labels">{compareViaEcom}</label>
                          <Select
                            placeholder="Please select"
                            style={{ width: 80 }}
                            dropdownMatchSelectWidth={false}
                            mode="multiple"
                            maxTagCount="responsive"
                            value={ecomDeepDiveList}
                            onChange={(value) => {
                              dispatch(setEcomDeepDiveList(value));
                            }}
                          >
                            {ecomComparisonChartData
                              .map(
                                (i) =>
                                  i[
                                  compareViaEcom === "Category"
                                    ? "product_category"
                                    : "customer_retailer_details"
                                  ]
                              )
                              .map((compare, index) => (
                                <Option key={index} value={compare}>
                                  {compare}
                                </Option>
                              ))}
                          </Select>
                          {ecomDeepDiveList.length > 1 && (
                            <>
                              <label className="kpi-labels mleft">KPI</label>
                              <Select
                                placeholder="Please select"
                                style={{ width: 80 }}
                                dropdownMatchSelectWidth={false}
                                maxTagCount="responsive"
                                onChange={(value) => {

                                  dispatch(setEcomKpiForDeepDive(value));
                                }}
                                value={ecomKpiForDeepDive}
                              >
                                {Object.keys(trackboardKpiDataForEcom).map(
                                  (compare, index) => (
                                    <Option key={index} value={compare}>
                                      {trackboardKpiDataForEcom[compare]}
                                    </Option>
                                  )
                                )}
                              </Select>
                            </>
                          )}
                        </div>
                        <div style={{ marginTop: 77 }}>
                          {ecomDeepDiveList.length > 1 ? (

                            <MultiLineChart
                              kpiOption={trackboardKpiDataForEcom}
                              compareVia={compareViaEcom}
                              kpi={ecomKpiForDeepDive}
                              data={
                                [ecomKpiForDeepDive].some(element => ["share_of_shelf", "itmes_in_stock", "traffic_stackline", "traffic_similarweb"].includes(element)) ?
                                  last12PeriodRecords(ecomDeepDiveChartData.filter((item) => ecomDeepDiveList.includes(item[
                                    compareViaEcom === "Category"
                                      ? "product_category"
                                      : "customer_retailer_details"
                                  ])
                                  ), yearTimePeriodLimits.l12p) :
                                  last12PeriodRecords(ecomDeepDiveChartData.filter((item) => ecomDeepDiveList.includes(item[
                                    compareViaEcom === "Category"
                                      ? "product_category"
                                      : "customer_retailer_details"
                                  ])
                                  ), yearTimePeriodLimits.l24p)
                              }
                              xField={["time_year_period"]}
                              yField={ecomKpiForDeepDive}
                              yFieldNumFmt={[numFmt.number, numFmt.percentage]}
                              yBarUnit="$"
                              yBulletUnit={ecomKpiForDeepDive.includes("tdp") ? "$" : "%"}
                              seriesField={compareViaEcom === "Category"
                                ? "product_category"
                                : "customer_retailer_details"}
                            />
                          ) : (
                            <ColumnLineChart
                              kpiOption={trackboardKpiDataForEcom}
                              kpi={kpi1}
                              data={
                                ecomDeepDiveList.length === 1
                                  ? [kpi1, kpi2].some(element =>
                                    ["share_of_shelf", "itmes_in_stock", "traffic_stackline", "traffic_similarweb"].includes(element)
                                  )
                                    ? last12PeriodRecords(
                                      ecomDeepDiveChartData.filter(
                                        item =>
                                          item[
                                          compareViaEcom === "Category"
                                            ? "product_category"
                                            : "customer_retailer_details"
                                          ] === ecomDeepDiveList[0]
                                      ),
                                      yearTimePeriodLimits.l12p
                                    )
                                    : last12PeriodRecords(
                                      ecomDeepDiveChartData.filter(
                                        item =>
                                          item[
                                          compareViaEcom === "Category"
                                            ? "product_category"
                                            : "customer_retailer_details"
                                          ] === ecomDeepDiveList[0]
                                      ),
                                      yearTimePeriodLimits.l24p
                                    )
                                  : []
                              }
                              xField={["time_year_period"]}
                              yField={[kpi1, kpi2]}
                              yFieldNumFmt={[numFmt.number, numFmt.percentage]}
                              yBarUnit="$"
                              yBulletUnit={[kpi1, kpi2].includes("tdp") ? "$" : "%"}
                            />
                          )}
                        </div>

                      </Col>
                    </Row>
                  }
                </div>
              </div>
            </Col>
          </Row>
        </Loader>
      </Content>
    </Wrapper>
  );
}

export default withRouter(Trackboard);
