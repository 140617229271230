import React, { useEffect, useState } from 'react';
import { Avatar, Dropdown, Menu, Popover, Select, TreeSelect } from 'antd';
import { UserOutlined, DownOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getHeaderImage, setBrandReset, setdefaultBrand } from '../../slicers/profile.slicer';
import { brandSplit, noHeaderPaths, openNotificationWithIcon } from '../../utilities/helper';
import { appInsights } from '../../utilities/appInsights';
import { setClusterNotification } from '../../slicers/dataSources.slicer';
// import FaqIcon from 'public/assets/images/faq.png';
import { resetInnovationTracker } from "../../slicers/innovation-tracker.slicer";

// Dropdown menu for user profile actions
const menu = (props) => (
  <Menu>
    <Menu.Item key="0">
      <a rel="noopener noreferrer" onClick={() => { appInsights.trackEvent({ name: 'MY_PROFILE' }); props.history.push("/profile") }}>
        My Profile
      </a>
    </Menu.Item>
    {Boolean(props.user_is_admin) && <Menu.Item key="1">
      <a rel="noopener noreferrer" onClick={() => props.history.push("/admin")}>
        Admin
      </a>
    </Menu.Item>}
    {/* {true && <Menu.Item key="1">
      <a rel="noopener noreferrer" onClick={() => props.history.push("/admin")}>
        Admin
      </a>
    </Menu.Item>} */}
    <Menu.Item key="2">
      <a rel="noopener noreferrer" onClick={e => {
        sessionStorage.clear();
        props.history.push('/');
        window.location.reload();
      }}>
        Logout
      </a>
    </Menu.Item>
  </Menu>
);


const { Option } = Select;

const { TreeNode } = TreeSelect;




function Index(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [select, setSelect] = useState(false);
  const { brandReset, defaultBrand, competitorBrands, genericImage, user: { user_is_admin } } = useSelector((state) => state.profile)
  const { clusterNotification } = useSelector((state) => state.dataSources)
  const [brandDropdown, setBrandDropdown] = useState([]);
  const [valueDropdown, setValueDropdown] = useState(null);

  let header_1 = { "0": "Alerts", "1": "Trackboard", "2": "Data Sources", "4": "Profile", "5": "Admin" }
  let header_2 = { "0": "Deep Dive", "6": "Performance Summary", "7": "Competition Summary", "8": "Distribution Summary", "9": props.reportName, "10": "Export", "11": "Innovation Tracker", "12": "Be Available", "13": "Be Visible", "14": "Diagnostics", "15": "Traffic", "16": "Conversion", "17": "Be Selected", "18": "Portfolio Summary" }
  let header_3 = { "0": "eCom" }
  let header_url = { "0": '/', "1": '/trackboard', "4": '/profile', "2": "/data-sources" }


  useEffect(() => {
    dispatch(getHeaderImage(defaultBrand));
    dispatch(resetInnovationTracker());

    setValueDropdown(defaultBrand);
  }, [])


  useEffect(() => {
    const brands = JSON.parse(JSON.stringify(competitorBrands));
    if (brands) {
      setBrandDropdown(
        Object.keys(brands).filter((brand) => brand !== 'mars_brand_list').map((brand) => ({
          title: brand,
          value: brand,
          children: brands[brand] && brands[brand]["secondary_brand_data"] ? Object.keys(brands[brand]["secondary_brand_data"]).map((item) => ({
            title: item,
            value: `${brand}:${item}`
          })) : []
        }))
      );
    }

  }, [competitorBrands])


  function brandResetHeader() {
    if (brandReset === 1 && !history.location.pathname.includes("report")) {

      dispatch(setdefaultBrand("ALL BRANDS"))
      dispatch(setBrandReset(0))
    }
  }

  function onChange(value) {
    let temp = new URL(window.location.href)
    if (temp.search.length > 1) {
      try {
        let preFilters = JSON.parse(decodeURIComponent(temp.search.substring(1)))

        if (preFilters.view && preFilters.view[0] && preFilters.view[0] === "volume_shifting") {
          let newBrands = [];
          if (value.split(":").length === 2) {
            newBrands = competitorBrands[value.split(":")[0]]["secondary_brand_data"][value.split(":")[1]]["competitor_data"][preFilters.product_pet.length !== 0 ? preFilters.product_pet[0].toUpperCase() : "ALL"] ? competitorBrands[value.split(":")[0]]["secondary_brand_data"][value.split(":")[1]]["competitor_data"][preFilters.product_pet.length !== 0 ? preFilters.product_pet[0].toUpperCase() : "ALL"] : competitorBrands[value.split(":")[0]]["secondary_brand_data"][value.split(":")[1]]["competitor_data"]["ALL"];
          }
          else {
            newBrands = competitorBrands[value.split(":")[0]]["competitor_data"][preFilters.product_pet.length !== 0 ? preFilters.product_pet[0].toUpperCase() : "ALL"] ? competitorBrands[value.split(":")[0]]["competitor_data"][preFilters.product_pet.length !== 0 ? preFilters.product_pet[0].toUpperCase() : "ALL"] : competitorBrands[value.split(":")[0]]["competitor_data"]["ALL"];

          }

          let encodedSearch = encodeURIComponent(JSON.stringify({ ...preFilters, default_brand: [value], brandList: newBrands ? newBrands : [] }));
          if (window.history.pushState) {
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + encodedSearch;
            window.history.pushState({ path: newurl }, '', newurl);
          }
        }
        else if (preFilters.view && preFilters.view[0] && preFilters.view[0] === "innovation_tracker") {
          let encodedSearch = encodeURIComponent(JSON.stringify({ ...preFilters, default_brand: [value], selected_innovation: [], innovation: [] }));
          if (window.history.pushState) {
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + encodedSearch;
            window.history.pushState({ path: newurl }, '', newurl);
          }
        }
        else if (preFilters.view && preFilters.view[0] && preFilters.view[0] === "report") {
          let encodedSearch = encodeURIComponent(JSON.stringify({ ...preFilters, default_brand: [value], selected_innovation: [], innovation: [], innovation_years: [2022] }));
          if (window.history.pushState) {
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + encodedSearch;
            window.history.pushState({ path: newurl }, '', newurl);
          }
        }
        else {
          let encodedSearch = encodeURIComponent(JSON.stringify({ ...preFilters, default_brand: [value] }));
          console.log("PreFilters", preFilters)
          if (window.history.pushState) {
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + encodedSearch;
            window.history.pushState({ path: newurl }, '', newurl);
          }
        }
      }
      catch (e) {

      }
    }
    setSelect(false);
    dispatch(getHeaderImage(value));
    setValueDropdown(value);
    dispatch(setdefaultBrand(value));
  }

  // useEffect(() => {
  //   if (clusterNotification) {
  //     openNotificationWithIcon('success', 'Cluster Initiated. You can now preview/export files.')
  //   }
  // }, [clusterNotification])

  useEffect(() => {
    if (clusterNotification) {
      dispatch(setClusterNotification(false))
    }
  }, [])



  return (
    <header className="site-header" onClick={brandResetHeader}>
      {/* Left section of the header */}
      <div className="header-left">
        {/* Logo */}
        <div className="site-logo">
          <img src="/assets/images/mars-logo.svg" width="80" alt="Mars" />
        </div>
        {/* Brand selection dropdown */}
        {!props.hideHeaderBrand && !noHeaderPaths.includes(location.pathname) && <><span className="header-divider"></span>
          <div className="select-competitor">
            <div className="logo-img">
              <img src={"data:image/png;base64, " + genericImage} onClick={() => { props.history.push("/"); setSelect(!select) }} alt="logo" />
            </div>
            <span className="brand-name">{valueDropdown && valueDropdown.split(':').length === 2 ? valueDropdown.split(':')[1] : valueDropdown}</span>

            {select &&
              <TreeSelect
                className="header-select"
                showSearch={true}
                autoFocus={true}
                placeholder={brandSplit(defaultBrand)}
                defaultValue={defaultBrand}
                bordered={false}
                onChange={(value) => onChange(value)}
                style={{ width: '100%', minWidth: 180 }}
                dropdownStyle={{ minWidth: "10%" }}
                value={valueDropdown}
                defaultOpen={true}
                treeDefaultExpandAll
                onBlur={() => { setSelect(false) }}
                treeData={brandDropdown}
              />
            }

            {!select ? <DownOutlined onClick={() => { setSelect(true) }} /> : <CloseOutlined onClick={() => { setSelect(false) }} style={{ marginRight: 10, marginLeft: 10 }} />}
          </div></>}

        {/* Page name */}
        <div className="page-name">
          <ul>
            {!props.innerTab && <li>{header_1[props.tab]}</li>}
            {props.innerTab && <><li><a href="javascript:void(0)" onClick={() => history.push(header_url[props.tab])}>{header_1[props.tab]}</a></li>
              {props.ecomTab && <li><a href="javascript:void(0)" onClick={() => history.push("/ecom")}>{header_3[props.ecomTab]}</a></li>}
              <li>{header_2[props.innerTab]}</li></>}
          </ul>
        </div>
      </div>
      {/* Right section of the header */}
      <div className="header-right">
        <div>
          <ul className="menu">
            <li className={"menu-item" + " " + (props.tab === "0" ? "active" : "")} onClick={() => { props.history.push("/"); }}>Alerts</li>
            <li className={"menu-item" + " " + (props.tab === "1" || props.tab === "6" ? "active" : "")} onClick={() => { props.history.push("/trackboard"); }}>Trackboard</li>
            <li className={"menu-item" + " " + (props.tab === "2" ? "active" : "")} onClick={() => { props.history.push("/data-sources"); }}>Data Sources</li>
          </ul>
        </div>
        <div style={{
          width: "15%",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-evenly",
        }}>

          <a href="https://teams.microsoft.com/l/channel/19%3aZ97tXMoHHG1V4DCGMq_Wfi9ycRdCCjTMm9HJq7qO-Eg1%40thread.tacv2/General?groupId=7b075e50-0906-4a5d-bf1b-b8a26ba26c52&tenantId=2fc13e34-f03f-498b-982a-7cb446e25bc6" target="_blank">
            <Popover placement="bottom" content={"Click to see FAQ"}>
              <InfoCircleOutlined style={{ marginRight: 32 }} />
            </Popover>
          </a>

          <Dropdown overlay={() => menu({ ...props, user_is_admin })} placement="bottomRight">
            <a className="ant-dropdown-link" style={{ display: 'inline-block' }} onClick={e => e.preventDefault()}>
              <Avatar size={36} icon={<UserOutlined />} className={"user-image"} />
            </a>
          </Dropdown>
        </div>
      </div>
    </header >
  );
}

export default withRouter(Index);
