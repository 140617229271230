import React, { useEffect, useState, useCallback } from "react";
import {
  Col,
  Layout,
  Row,
  Form,
  Button,
  Select,
  Menu,
  Dropdown,
  Table,
  TreeSelect,
  Tooltip,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { DualAxes } from "@ant-design/plots";
import { isEqual, cloneDeep } from "lodash";
import { useHistory, withRouter } from "react-router-dom";
import Wrapper from "../../components/wrapper";
import {
  DownloadOutlined,
  PlusCircleOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";
import DiagnosticsCard from "../../components/diagnostics-card";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../components/loader";
import { appInsights } from "../../utilities/appInsights";
import {
  getAllFilters,
  getCardData,
  getCategoryBenchmarkData,
  getComparisonTrendData,
  getCompetitionBenchmarkData,
  getCompetitionFilterData,
  getCompetitionTableData,
  getDeepdiveTrendData,
  // getBenchmark,
  getFilterData,
  getInitialFilters,
  getInternalBenchmarkData,
  getMacroTableData,
  getKpiTrendsData,
  setKpi1,
  setKpi2,
  processFilters,
  // getTableData,
  setAppliedFilters,
  setFilters,
  setSelectedCompetitor,
  getInitialBottomFilters,
  getCompetitionFilterDataForTrendChart,
  getTotalPetBenchmarkData,
} from "../../slicers/diagnostics.slicer";
import {
  setWindowHistory,
  allBrands,
  symbols,
  getTitleWidth,
  formatNumber,
} from "../../utilities/helper";
import Navigator from "../../components/bread-crumbs";
import { isPackSize } from "../volume-shifting";
import {
  rowKeyCompetitionBenchmark,
  viewBy,
  rowKeyMacro,
  declareAppliedFilters,
} from "./dgUIHelper";
import columns from "./dgColumns";
import baColumn from "../be-available/baColumns";
import {
  ecomTableData,
  ecomCompititionData,
  config,
  subBradnData,
  categoryData,
  compititionData,
  kpiData,
  rowKeyCompetition,
} from "./dgUIHelper";
import { getState } from "../../store";
import {
  competitionKpiData,
  competitionKpiExportData,
  defaultCompetitionKpi,
} from "./dgUIHelper";
import CompetitionAndBenchmark, {
  compareVia,
} from "../../components/competition-benchmark";
import "./diagnostics.scss";
import { setExportData } from "../../slicers/exportData.slicer";
import mdiCart from "../../assets/images/mdi_cart.svg";
import mdiDecagram from "../../assets/images/mdi_check-decagram.svg";
import shareColumns from "../be-visible/bvColumns";
import mdiCheck from "../../assets/images/mdi_eye-check.svg";
import mdiView from "../../assets/images/mdi_view-dashboard.svg";
import { ReactComponent as MdiView } from "../../assets/images/mdi_view-dashboard.svg";
import CustomTable from "../../components/customTable";
import {
  exportDGKPIs,
  exportCompetitionBenchmarkTable,
  formatNumberExcel,
  numFmt,
  exportDGTrends,
  exportDGExcel,
  exportTotalEcomTable,
  exportDGTables,
} from "../../utilities/exports/excel";
import { menu } from "../be-available/baUIHelper";
import { pptExportDiagnostics } from "../../utilities/exports/ecomppt";
import { compareViaForTrend } from "../../utilities/constants";
import FilterDropDown from "./filterDropDown";
const { Content } = Layout;
const { Option } = Select;

/**
 * Diagnostics
 * It manages displaying of Diagnostics with different tabs and functionalities.
 */
function Diagnostics(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    loading,
    microLoading,
    dropdownLoading,
    pets,
    techs,
    subTechs,
    lifestage,
    feedingPhilosophies,
    textures,
    retailers,
    timeAggregates,
    yearPeriods,
    benchmarkPeriods,
    keyword,
    keywordType,
    keywordCategory,
    competitors,

    brands,
    subBrands,
    customerRetailers,
    categoryBenchmarks,
    competitionBenchmarks,

    trendBrands,
    trendSubBrands,
    trendCompetitionBenchmarks,
    // kpis,

    filters,
    appliedFilters,
    cardData,
    kpiTrendsData,
    comparisonTrendData,
    deepdiveSingleTrendData,
    deepdiveMultiTrendData,
    macroTableData,
    microTableData,
    competitionTableData,
    selectedCompetitor,
  } = useSelector((state) => state.diagnostics);
  const exportData = useSelector((state) => state.exportData);
  const { diagnostics } = useSelector((state) => state.refreshDate);
  const [selectedRetailer, setSelectedRetailer] = useState([]);
  const [retailerDropdown, setRetailerDropdown] = useState([]);
  const [selectedDeepdive, setSelectedDeepdive] = useState([]);
  const [cbRetailers, setCbRetailers] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCompetition, setSelectedCompetition] = useState([]);
  const [lastDefaultBrand, setLastDefaultBrand] = useState("");
  const [filterChanged, setFilterChanged] = useState(false);
  const [newReport, setNewReport] = useState(false);
  const [newReportComponents, setNewReportComponents] = useState([]);
  const [components, setComponents] = useState([]);
  // const [competitionColumns, setCompetitionColumns] = useState(
  //   columns.dgCompetitionCols
  // );
  const [macroColumns, setMacroColumns] = useState(columns.dgEcomPerf);
  const [macroColumnsExport, setMacroColumnsExport] = useState(
    columns.dgEcomPerf
  );
  const [competitionColumns, setCompetitionColumns] = useState(
    shareColumns.baCompetitionCols
  );
  const [competitionColumnsExport, setCompetitionColumnsExport] = useState(
    shareColumns.baCompetitionColsExport
  );
  const { SHOW_PARENT } = TreeSelect;
  const { defaultBrand } = useSelector((state) => state.profile);
  const [selectedBrand, setSelectedBrand] = useState(defaultBrand);
  const isAllBrands = defaultBrand === allBrands;

  if (isAllBrands) viewBy.S = "Brand";
  else viewBy.S = "Sub Brand";

  const isReport = props.isReport;

  const getKpiTitle = () => `KPI Comparision Trend - ${defaultBrand}`;

  const appendRetailers1Level = (
    columnIndex,
    columns,
    record,
    kpi,
    renderFunction,
    renderPPTFunction
  ) => {
    if (record[kpi]) {
      const isSingleRetailer = Object.keys(record[kpi]).length === 1;
      const parentTitle = columns[columnIndex].title;
      const parentWidth = getTitleWidth(parentTitle);

      columns[columnIndex].children = Object.keys(record[kpi]).map(
        (retailer) => {
          const retailerWidth = getTitleWidth(retailer);
          return {
            title: retailer,
            dataIndex: [kpi, retailer],
            dataIndexPPT: kpi,
            key: retailer,
            width: isSingleRetailer
              ? Math.max(parentWidth, retailerWidth)
              : retailerWidth,
            render: (val) => renderFunction(val),
            renderPPT: (val) => renderPPTFunction(val),
            className: "sub-td-border-left",
          };
        }
      );

      if (columns[columnIndex].children.length)
        columns[columnIndex].children[0].className = "td-border-left";
    }
  };

  const appendRetailersExport = (
    columns,
    record,
    kpi,
    title,
    renderTextAbs,
    renderTextVariance
  ) => {
    if (record[kpi]) {
      Object.keys(record[kpi]).forEach((retailer) => {
        //   // abs
        columns.push({
          title: `${title} ${retailer}`,
          dataIndex: kpi,
          key: kpi,
          renderText: (val) =>
            val && val[retailer] ? renderTextAbs(val && val[retailer]) : "",
        });
        columns.push({
          title: `${title} ${retailer} vs Benchmark`,
          dataIndex: kpi,
          key: kpi,
          renderText: (val) =>
            val && val[retailer]
              ? renderTextVariance(val && val[retailer])
              : "",
        });
      });
    }
  };

  const changeSelectedBrand = () => setSelectedBrand(defaultBrand);

  const getData = (
    filters,
    fetchFilterData = true,
    clearHistory = true,
    initialFilterData = true
  ) => {
    dispatch(
      setAppliedFilters(
        declareAppliedFilters(filters, {
          subTechs,
          lifestage,
          techs,
          feedingPhilosophies,
          textures,
        })
      )
    );
    dispatch(setFilters(filters));

    const isBrandChanged = defaultBrand !== selectedBrand;

    // API calls
    if (fetchFilterData)
      if (initialFilterData)
        dispatch(getAllFilters(isBrandChanged, changeSelectedBrand));
      else
        dispatch(
          getFilterData(
            {
              customer_retailer_details: filters.customer_retailer_details,
              time_aggregate: filters.time_aggregate,
              year_period: filters.year_period,
              benchmark: filters.benchmark,
            },
            true,
            isBrandChanged,
            changeSelectedBrand
          )
        );
    else {
      dispatch(getCardData(filters));
      dispatch(getMacroTableData(filters));
      dispatch(getKpiTrendsData(filters));
      dispatch(getCompetitionFilterData(filters));
      dispatch(getCompetitionFilterDataForTrendChart(filters));
      dispatch(getTotalPetBenchmarkData(filters));
      dispatch(getInternalBenchmarkData(filters));
      dispatch(getCategoryBenchmarkData(filters));
      dispatch(getCompetitionBenchmarkData(filters));
    }
  };

  const updateRetailerDropdown = (filters, isApply = false) => {
    const newFilters = { ...filters };
    if (retailers.length === 0) return [];
    const retailerDropdown = retailers.filter(
      (retailer) =>
        !(
          isApply ? filters : appliedFilters
        ).customer_retailer_details.includes(retailer)
    );

    const filteredSelectedRetailers = [...newFilters.selected_retailer].filter(
      (retailer) => retailerDropdown.includes(retailer)
    );
    const selectedRetailers = [...newFilters.selected_retailer];
    if (!isEqual(filteredSelectedRetailers.sort(), selectedRetailers.sort())) {
      newFilters.selected_retailer = filteredSelectedRetailers;
      setSelectedRetailer(filteredSelectedRetailers);
    }

    setRetailerDropdown(retailerDropdown);
    return newFilters;
  };

  const applyFilters = () => {
    const newFilters = {
      ...updateRetailerDropdown(filters, retailers, dispatch),
      default_brand: [defaultBrand],
      deep_dive_list: [],
    };
    setWindowHistory(encodeURIComponent(JSON.stringify(newFilters)));
    getData(newFilters, false, false);
  };

  const getRemainingFilters = (label) => {
    if (!filterChanged) return;

    setFilterChanged(false);
    if (label === "product_pet") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_technology") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_sub_technology") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_lifestage") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_feed_philosophy") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_feed_philosophy: filters.product_feed_philosophy,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_texture") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "customer_retailer_details") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          customer_retailer_details: filters.customer_retailer_details,

          // default filters
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "year_period") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          customer_retailer_details: filters.customer_retailer_details,
          year_period: filters.year_period,

          // default filters
          time_aggregate: filters.time_aggregate,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "time_aggregate") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          customer_retailer_details: filters.customer_retailer_details,
          year_period: filters.year_period,
          time_aggregate: filters.time_aggregate,

          // default filters
          benchmark: filters.benchmark,
        })
      );
    }
  };

  const changeFilter = (label, selectedValue) => {
    if (!isEqual(filters[label], selectedValue)) setFilterChanged(true);

    let newFilters = { ...filters };
    if (
      selectedValue &&
      selectedValue.length &&
      selectedValue.includes("all")
    ) {
      let all = [];
      if (label === "product_pet") all = pets;
      else if (label === "product_technology") all = techs;
      else if (label === "product_sub_technology") all = subTechs;
      else if (label === "product_lifestage") all = lifestage;
      else if (label === "product_feed_philosophy") all = feedingPhilosophies;
      else if (label === "product_texture") all = textures;
      if (selectedValue.length === all.length + 1) selectedValue = [];
      else selectedValue = [...all];
    }

    newFilters[label] = selectedValue;
    dispatch(setFilters(newFilters));
    // setLocalFilter(newFilters);
    return newFilters;
  };

  // const appendRetailers2Level = (
  //   columnIndex,
  //   childIndex,
  //   columns,
  //   record,
  //   kpi,
  //   renderFunction
  // ) => {
  //   if (record[kpi]) {
  //     const isSingleRetailer = (columns[columnIndex].children[
  //       childIndex
  //     ].children = Object.keys(record[kpi]).length === 1);
  //     columns[columnIndex].children[childIndex].children = Object.keys(
  //       record[kpi]
  //     ).map((retailer) => {
  //       const retailerWidth = getTitleWidth(retailer);

  //       return {
  //       // title: retailer,
  //       dataIndex: [kpi, retailer],
  //       key: retailer,
  //       width: isSingleRetailer
  //         ? Math.max(parentWidth, retailerWidth)
  //         : retailerWidth,
  //       render: (val) => renderFunction(val && val[retailer])
  //     }});

  //     if (columns[columnIndex].children.length)
  //       columns[columnIndex].children[0].className = "td-border-left";
  //   }
  // };

  const appendRetailers = (
    columnIndex,
    childIndex,
    columns,
    record,
    kpi,
    renderFunction,
    renderPPTFunction,
    sort
  ) => {
    if (record[kpi]) {
      columns[columnIndex].children[childIndex].children = Object.keys(
        record[kpi]
      ).map((retailer) => {
        return {
          title: retailer,
          dataIndex: [kpi, retailer],
          dataIndexPPT: kpi,
          key: `${kpi}${retailer}`,
          width: 150,
          render: (val) => renderFunction(val),
          renderPPT: (val) => renderPPTFunction(val),
          sorter: sort
            ? (a, b) =>
                a.table_key === "MARS BDB" || b.table_key === "MARS BDB"
                  ? 0
                  : a[kpi][retailer]?.abs < b[kpi][retailer]?.abs
                  ? -1
                  : 1
            : false,
          defaultSortOrder: sort && kpi === "sales_share" ? "descend" : "",
        };
      });
      if (columns[columnIndex].children.length)
        columns[columnIndex].children[0].className = "td-border-left";
    }
  };

  const formatTableData = (table_data) => {
    const tableDataInStore = JSON.parse(JSON.stringify(table_data));
    const tableData = [];
    tableDataInStore.forEach((data) => {
      tableData.push(data);
      if (data.table_hierarchy_data)
        tableData.push(...data.table_hierarchy_data);
    });
    dispatch(setExportData({ tableData: { data: tableData } }));
  };

  const formatTotalEcomTableData = (table_data) => {
    const tableDataInStore = JSON.parse(JSON.stringify(table_data));
    const tableData = [];
    tableDataInStore.forEach((data) => {
      tableData.push(data);
      if (data.table_hierarchy_data)
        tableData.push(...data.table_hierarchy_data);
    });
    dispatch(setExportData({ macroTableData: { data: tableData } }));
  };
  useEffect(() => {
    // Macro table dynamic column binding
    if (macroTableData.length) {
      const record = macroTableData[0];
      const dgMacroColumns = cloneDeep(columns.dgEcomPerf);
      const dgMacroColumnsExport = cloneDeep(columns.dgEcomPerfExport);
      if (filters.view_by[0] === "C") dgMacroColumns[0].title = "Brand";
      appendRetailers(
        1,
        0,
        dgMacroColumns,
        record,
        "sales_share", // $ Share
        columns.renderPercentagePointsFunction,
        columns.renderPPTPercentagePtsFunction,
        true
      );
      appendRetailers(
        1,
        1,
        dgMacroColumns,
        record,
        "sales", // $ Sales
        columns.renderAbsPercentageFunction,
        columns.renderPPTNumberPercentFunction,
        true
      );
      appendRetailers(
        2,
        0,
        dgMacroColumns,
        record,
        "items_listed",
        columns.renderWholeNumberPercentFunction,
        columns.renderPPTWholeNumberPercentageFunction
      );
      appendRetailers(
        2,
        1,
        dgMacroColumns,
        record,
        "in_stock",
        columns.renderPercentagePointsFunction,
        columns.renderPPTPercentagePtsFunction
      );
      appendRetailers(
        3,
        0,
        dgMacroColumns,
        record,
        "total_share_of_shelf",
        columns.renderPercentagePointsFunction,
        columns.renderPPTPercentagePtsFunction,
        true
      );
      // hide the Be Selected KPI (task id : 237423)
      // appendRetailers(
      //   4,
      //   0,
      //   dgMacroColumns,
      //   record,
      //   "average_ratings",
      //   columns.renderAbsPercentageFunction,
      //   columns.renderPPTNumberPercentFunction,
      // );
      // appendRetailers(
      //   4,
      //   1,
      //   dgMacroColumns,
      //   record,
      //   "reviews",
      //   columns.renderAbsPercentageFunction,
      //   columns.renderPPTNumberPercentFunction,
      // );

      //excel export
      appendRetailersExport(
        dgMacroColumnsExport,
        record,
        "sales_share", // $ Share
        dgMacroColumns[1].children[0].title,
        baColumn.renderTextAbsPercentageFunction,
        baColumn.renderTextVarianceNumberFunction
      );
      appendRetailersExport(
        dgMacroColumnsExport,
        record,
        "sales", // $ Sales
        dgMacroColumns[1].children[1].title,
        baColumn.renderTextAbsNumber,
        baColumn.renderTextVariancePercentageFunction
      );
      appendRetailersExport(
        dgMacroColumnsExport,
        record,
        "items_listed",
        dgMacroColumns[2].children[0].title,
        baColumn.renderTextWholeNumberFunction,
        baColumn.renderTextVariancePercentageFunction
      );
      appendRetailersExport(
        dgMacroColumnsExport,
        record,
        "in_stock",
        dgMacroColumns[2].children[1].title,
        baColumn.renderTextAbsPercentageFunction,
        baColumn.renderTextVarianceNumberFunction
      );
      appendRetailersExport(
        dgMacroColumnsExport,
        record,
        "total_share_of_shelf",
        dgMacroColumns[3].children[0].title,
        baColumn.renderTextAbsPercentageFunction,
        baColumn.renderTextVarianceNumberFunction
      );
      // hide the Be Selected KPI (task id : 237423)
      // appendRetailersExport(
      //   dgMacroColumnsExport,
      //   record,
      //   "average_ratings",
      //   dgMacroColumns[4].children[0].title,
      //   baColumn.renderTextAbsNumber,
      //   baColumn.renderTextVariancePercentageFunction,
      // )
      // appendRetailersExport(
      //   dgMacroColumnsExport,
      //   record,
      //   "reviews",
      //   dgMacroColumns[4].children[1].title,
      //   baColumn.renderTextAbsNumber,
      //   baColumn.renderTextVariancePercentageFunction,
      // )

      setMacroColumns(dgMacroColumns);
      setMacroColumnsExport(dgMacroColumnsExport);
      formatTotalEcomTableData(macroTableData);
    }
  }, [macroTableData]);

  useEffect(() => {
    const initialFilters = {
      ...getInitialBottomFilters(),
      compare_via: isAllBrands ? [compareVia.manufacturer] : [compareVia.brand],
      trend_compare_via: isAllBrands
        ? [compareVia.manufacturer]
        : [compareVia.brand], //added for trend chart's compare via filter, set "manufacturer" default section when "All Brand" selected
      default_brand: [defaultBrand],
    };

    let temp = new URL(window.location.href);
    if (temp.search.length > 1) {
      try {
        let preFilters = JSON.parse(
          decodeURIComponent(temp.search.substring(1))
        );
        let currentFilter = { ...filters };
        for (const [k, v] of Object.entries(preFilters)) {
          if (k in currentFilter) {
            currentFilter = {
              ...currentFilter,
              [k]: [...v],
            };
          }
        }

        currentFilter = {
          ...currentFilter,
          default_brand: [defaultBrand],
          deep_dive_list: [],
        };
        setWindowHistory(encodeURIComponent(JSON.stringify(currentFilter)));
        getData(currentFilter, true, false, false);
      } catch (e) {
        getData(initialFilters);
      }
    } else {
      getData(initialFilters);
    }

    setLastDefaultBrand(defaultBrand);
  }, [defaultBrand, props.reportApply]);

  const compititionKpiIndex = (defaultArray, outputArray) => {
    let correctOutputArray = [...defaultArray]; // Create a copy of the default array
    let outputSet = new Set(outputArray); // Convert the output array to a Set for faster lookups

    // Remove items from the correctOutputArray that are not in the outputSet
    for (let i = 0; i < correctOutputArray.length; i++) {
      if (!outputSet.has(correctOutputArray[i])) {
        correctOutputArray.splice(i, 1);
        i--; // Decrement i to account for the removed item
      }
    }

    // Add any items from the outputSet that are not in the correctOutputArray
    for (let item of outputSet) {
      if (!correctOutputArray.includes(item)) {
        correctOutputArray.push(item);
      }
    }

    return correctOutputArray;
  };

  // Competition table dynamic column binding
  useEffect(() => {
    if (competitionTableData.length) {
      let record = {};
      competitionTableData.some((competitionTableRow) => {
        if (competitionTableRow.table_hierarchy_data?.length) {
          record = competitionTableRow.table_hierarchy_data[0];
          return true;
        }
      });

      const baCompetitionColumns = cloneDeep(shareColumns.baCompetitionCols);
      const baCompetitionColumnsExport = cloneDeep(
        shareColumns.baCompetitionColsExport
      );

      let columnIndex = compititionKpiIndex(
        defaultCompetitionKpi,
        filters.competition_kpi
      );
      columnIndex.forEach((kpiValue, index) => {
        const kpiPair = kpiValue.split(":");
        const kpiParent = competitionKpiData.find(
          ({ value }) => value === kpiPair[0]
        );
        const kpiChild = kpiParent.children.find(
          ({ value }) => value === kpiValue
        );

        const kpiParentExport = competitionKpiExportData.find(
          ({ value }) => value === kpiPair[0]
        );
        const kpiChildExport = kpiParentExport.children.find(
          ({ value }) => value === kpiValue
        );
        baCompetitionColumns.push({ title: kpiPair[1] });
        appendRetailers1Level(
          index + 1,
          baCompetitionColumns,
          record,
          kpiChild.key,
          kpiChild.renderFunction,
          kpiChild.renderPPTFunction
        );
        // baCompetitionColumnsExport.push({ title: kpiPair[1] });
        appendRetailersExport(
          baCompetitionColumnsExport,
          record,
          kpiChildExport.key,
          kpiPair[1],
          kpiChildExport.renderAbsFunction,
          kpiChildExport.renderVarFunction
        );
      });

      setCompetitionColumnsExport(baCompetitionColumnsExport);
      setCompetitionColumns(baCompetitionColumns);
      formatTableData(competitionTableData);
    }
  }, [competitionTableData, filters.competition_kpi]);

  useEffect(() => {
    const processedFilters = processFilters(filters, getState);
    processedFilters.view_by = [viewBy[processedFilters.view_by[0]]];
    dispatch(setExportData({ filters: processedFilters }));
  }, [filters]);

  const prepareTableExport = (exportData) => {
    const data = JSON.parse(JSON.stringify(exportData));

    data.filters = {
      product_brand: data.filters.product_brand,
      product_secondary_brand: data.filters.product_secondary_brand,
      product_pet: data.filters.product_pet,
      product_technology: data.filters.product_technology,
      product_sub_technology: data.filters.product_sub_technology,
      product_lifestage: data.filters.product_lifestage,
      product_feed_philosophy: data.filters.product_feed_philosophy,
      product_texture: data.filters.product_texture,
      customer_retailer_details: data.filters.customer_retailer_details,
      time_aggregate: data.filters.time_aggregate,
      year_period: data.filters.year_period,
      benchmark: data.filters.benchmark,
    };

    return data;
  };

  const exportTableData = () =>
    exportCompetitionBenchmarkTable(
      competitionColumnsExport,
      prepareTableExport(exportData)
      // getMicroSheetName()
    );

  const exportTotalEcom = () => {
    exportTotalEcomTable(macroColumnsExport, prepareTableExport(exportData));
  };
  // kpi excel export
  useEffect(() => {
    if (!cardData) return;
    if (Object.keys(cardData).length === 0) {
      dispatch(setExportData({ kpi: {} }));
      return;
    }

    dispatch(
      setExportData({
        kpi: {
          rsv: {
            title: "MKT RSV",
            abs: formatNumberExcel(cardData.dollar && cardData.dollar?.abs),
            absNumFmt: numFmt.wholeNumber,
            variance: formatNumberExcel(
              cardData.dollar && cardData.dollar?.variance,
              true
            ),
            varianceNumFmt: numFmt.percentage,
            value: formatNumber(cardData?.dollar?.abs),
            change: cardData?.dollar?.variance,
            valueSymbol: symbols.dollar,
            changeSymbol: symbols.percent,
          },
          dollarShare: {
            title: "$ Share",
            abs: formatNumberExcel(
              cardData.dollar_share && cardData.dollar_share?.abs,
              true
            ),
            absNumFmt: numFmt.percentage,
            variance: formatNumberExcel(
              cardData.dollar_share && cardData.dollar_share?.variance
            ),
            varianceNumFmt: numFmt.number,
            value: formatNumber(cardData?.dollar_share?.abs),
            change: cardData.dollar_share?.variance,
            valueSymbol: symbols.percent,
            changeSymbol: symbols.pts,
          },
          eqLbs: {
            title: "Eq LBs",
            abs: formatNumberExcel(cardData.volume && cardData.volume?.abs),
            absNumFmt: numFmt.wholeNumber,
            variance: formatNumberExcel(
              cardData.volume && cardData.volume?.variance,
              true
            ),
            varianceNumFmt: numFmt.percentage,
            value: formatNumber(cardData?.volume?.abs),
            change: cardData.volume?.variance,
            // valueSymbol: symbols.percent,
            changeSymbol: symbols.percent,
          },
          lbsShare: {
            title: "LBs Share",
            abs: formatNumberExcel(
              cardData.volume_share && cardData.volume_share?.abs,
              true
            ),
            absNumFmt: numFmt.percentage,
            variance: formatNumberExcel(
              cardData.volume_share && cardData.volume_share?.variance
            ),
            varianceNumFmt: numFmt.number,
            value: formatNumber(cardData?.volume_share?.abs),
            change: cardData.volume_share?.variance,
            valueSymbol: symbols.percent,
            changeSymbol: symbols.pts,
          },
          unit: {
            title: "Units",
            abs: formatNumberExcel(cardData.unit && cardData.unit?.abs),
            absNumFmt: numFmt.wholeNumber,
            variance: formatNumberExcel(
              cardData.unit && cardData.unit?.variance,
              true
            ),
            varianceNumFmt: numFmt.percentage,
            value: formatNumber(cardData?.unit?.abs),
            change: cardData.unit?.variance,
            // valueSymbol: symbols.percent,
            changeSymbol: symbols.percent,
          },
          unitShare: {
            title: "Unit Share",
            abs: formatNumberExcel(
              cardData.unit_share && cardData.unit_share?.abs,
              true
            ),
            absNumFmt: numFmt.percentage,
            variance: formatNumberExcel(
              cardData.unit_share && cardData.unit_share?.variance
            ),
            varianceNumFmt: numFmt.number,
            value: formatNumber(cardData?.unit_share?.abs),
            change: cardData.unit_share?.variance,
            valueSymbol: symbols.percent,
            changeSymbol: symbols.pts,
          },
        },
      })
    );
  }, [cardData]);

  const exportKPIs = () =>
    exportDGKPIs(prepareTableExport(exportData), "Diagnostics_KPI_Layer");

  // kpi comparision trend excel export
  const prepareTrendExport = (exportData) => {
    const data = JSON.parse(JSON.stringify(exportData));

    data.filters = {
      product_brand: data.filters.product_brand,
      product_secondary_brand: data.filters.product_secondary_brand,
      product_pet: data.filters.product_pet,
      product_technology: data.filters.product_technology,
      product_sub_technology: data.filters.product_sub_technology,
      product_lifestage: data.filters.product_lifestage,
      product_feed_philosophy: data.filters.product_feed_philosophy,
      product_texture: data.filters.product_texture,
      customer_retailer_details: data.filters.customer_retailer_details,
      time_aggregate: data.filters.time_aggregate,
      year_period: data.filters.year_period,
      benchmark: data.filters.benchmark,
      kpi1: data.filters.kpi1,
      kpi2: data.filters.kpi2,
    };

    return data;
  };

  useEffect(() => {
    if (kpiTrendsData?.line_chart) {
      let fieldData = [...filters.kpi1, ...filters.kpi2];
      const findKpi3 =
        kpiTrendsData &&
        kpiTrendsData?.line_chart.find((i) => i.name === "kpi_3");
      if (findKpi3 && filters?.trend_compare_deep_dive_list?.length) {
        fieldData = [
          ...fieldData,
          `${filters.kpi1} ${filters?.trend_compare_deep_dive_list[0]}`,
          `${filters.kpi2} ${filters?.trend_compare_deep_dive_list[0]}`,
        ];
      }
      const modifyTrendData = [
        ...kpiTrendsData.line_chart,
        ...kpiTrendsData.line_chart_graph,
      ];

      const newTrendData = modifyTrendData.reduce((arr, obj) => {
        const findItem = arr.findIndex((i) => i.time === obj.time);
        if (findItem !== -1) {
          arr[findItem] = {
            ...arr[findItem],
            [obj.name]:
              obj.name === "kpi_1" || obj.name === "kpi_3"
                ? obj["kpi_1_3"]
                : obj["kpi_2_4"],
          };
        } else {
          arr.push({
            time: obj.time,
            [obj.name]:
              obj.name === "kpi_1" || obj.name === "kpi_3"
                ? obj["kpi_1_3"]
                : obj["kpi_2_4"],
          });
        }
        return arr;
      }, []);

      dispatch(
        setExportData({
          line: {
            title: getKpiTitle(),
            data: newTrendData,
            series: fieldData,
            xField: "time_year_period",
          },
        })
      );
    }
  }, [kpiTrendsData]);

  const exportComparisionTrend = () => {
    exportDGTrends(prepareTrendExport(exportData));
  };

  // useEffect(() => {
  //   if (competitionTableData.length) {
  //     let record = {};
  //     competitionTableData.some((competitionTableRow) => {
  //       if (competitionTableRow.table_hierarchy_data.length) {
  //         record = competitionTableRow.table_hierarchy_data[0];
  //         return true;
  //       }
  //     });

  //     const baCompetitionColumns = cloneDeep(columnsBA.baCompetitionCols);

  //     filters.competition_kpi.forEach((kpiValue, index) => {
  //       const kpiPair = kpiValue.split(":");
  //       const kpiParent = competitionKpiData.find(
  //         ({ value }) => value === kpiPair[0]
  //       );
  //       const kpiChild = kpiParent.children.find(
  //         ({ value }) => value === kpiValue
  //       );

  //       baCompetitionColumns.push({ title: kpiPair[1] });

  //       appendRetailers(
  //         index + 1,
  //         baCompetitionColumns,
  //         record,
  //         kpiChild.key,
  //         kpiChild.renderFunction
  //       );
  //     });

  //     setCompetitionColumns(baCompetitionColumns);
  //   }
  // }, [competitionTableData, filters.competition_kpi]);

  const chartData = [
    {
      $_Share: 10,
      $_Sales: 1000,
      kpi_result: "P6W4",
    },
    {
      $_Share: 17,
      $_Sales: 1500,
      kpi_result: "P7W1",
    },
    {
      $_Share: 12,
      kpi_result: "P7W2",
      $_Sales: 1200,
    },
    {
      $_Share: 20,
      kpi_result: "P7W3",
      $_Sales: 1500,
    },
    {
      $_Share: 15,
      kpi_result: "P7W4",
      $_Sales: 1800,
    },
    {
      $_Share: 17,
      kpi_result: "P8W1",
      $_Sales: 2000,
    },
    {
      $_Share: 20,
      kpi_result: "P8W2",
      $_Sales: 2200,
    },
    {
      $_Share: 15,
      kpi_result: "P8W3",
      $_Sales: 1900,
    },
    {
      $_Share: 18,
      kpi_result: "P8W4",
      $_Sales: 1600,
    },
    {
      $_Share: 19,
      kpi_result: "P9W1",
      $_Sales: 1300,
    },
    {
      $_Share: 21,
      kpi_result: "P9W2",
      $_Sales: 1800,
    },
    {
      $_Share: 17,
      kpi_result: "P9W3",
      $_Sales: 2000,
    },
    {
      $_Share: 20,
      kpi_result: "P9W4",
      $_Sales: 2200,
    },
  ];
  // "manufacturer" and "brand" if all brands are chosen, otherwise "brand" and "subBrand".
  const compareViaList = Object.keys(compareViaForTrend).filter((compare) =>
    isAllBrands ? compare !== "subBrand" : compare !== "manufacturer"
  );
  return (
    <Wrapper
      hideHeader={props.hideReportHeader}
      hidePage={isReport}
      heading={"1"}
      innerHeading={"14"}
      ecomTab={"0"}
    >
      <Content
        className={
          "site-layout-background" +
          (isReport && components.length === 0
            ? " content-none"
            : " content-body")
        }
      >
        <Loader loading={isReport ? props.reportLoader : loading > 0}>
          {!props.hideReportFilters && (
            <>
              <Row>
                <Navigator refreshDate={diagnostics} />
              </Row>
              <Row className="diagnostics-card">
                <div>
                  <span>
                    <img src={mdiView} alt="Diagnostics" width="22" />
                  </span>{" "}
                  <b> Diagnostics</b> Utilize digital KPIs to measure and
                  enhance brand performance, optimize metrics, and drive share
                  growth on top eRetail platforms.
                </div>
                {/* {!newReport && (
                  <>
                    <Col>
                      <Dropdown
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              onClick={() => {
                                appInsights.trackEvent({
                                  name: "PPT_FOR_DIAGNOSTICS",
                                });

                                pptExportDiagnostics(
                                  exportData,
                                  cbRetailers,
                                  macroColumns,
                                  competitionColumns,
                                  defaultBrand,
                                  "Diagnostics"
                                );
                                exportDGTables(
                                  prepareTableExport(exportData),
                                  competitionColumnsExport,
                                  macroColumnsExport
                                );
                              }}
                            >
                              Download as PPT
                            </Menu.Item>
                            <Menu.Item
                              onClick={() => {
                                appInsights.trackEvent({
                                  name: "EXCEL_FOR_DIAGNOSTICS",
                                });
                                exportDGExcel(
                                  prepareTableExport(exportData),
                                  competitionColumnsExport,
                                  macroColumnsExport
                                );
                              }}
                            >
                              Download as Excel
                            </Menu.Item>
                          </Menu>
                        )}
                        className="d-more"
                      >
                        <Button icon={<DownloadOutlined />}>
                          Export Report
                        </Button>
                      </Dropdown>
                    </Col>
                  </>
                )}     */}
                
              </Row>

              <Row
                align="middle"
                style={{ marginBottom: 16 }}
                gutter={[16, 16]}
              >
                <Col>
                  <Form
                    name="horizontal_login"
                    className="performance-filter"
                    layout="inline"
                  >
                    <Row gutter={8}>
                      <div className="filters-ps">
                        <Col>
                          <Tooltip title="Use filters to view selected Category, Tech, Sub Tech, Feeding Philosophy, and/or Texture by Retailer">
                            <label className="label-block">Pet</label>
                            <Select
                              placeholder="All"
                              mode="multiple"
                              defaultValue={["all"]}
                              dropdownMatchSelectWidth={false}
                              autoClearSearchValue={false}
                              style={{ width: 70 }}
                              placement="bottomLeft"
                              maxTagCount="responsive"
                              value={filters.product_pet}
                              onMouseLeave={() =>
                                getRemainingFilters("product_pet")
                              }
                              onChange={(val) => {
                                changeFilter("product_pet", val);
                              }}
                            >
                              <Option value={"all"}>{"All"}</Option>
                              {pets.map((val) => (
                                <Option value={val}>{val}</Option>
                              ))}
                            </Select>
                          </Tooltip>
                        </Col>
                        <Col>
                          <label className="label-block">Technology</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            style={{ width: 113 }}
                            maxTagCount="responsive"
                            value={filters.product_technology}
                            loading={dropdownLoading}
                            autoClearSearchValue={false}
                            allowClear
                            placement="bottomLeft"
                            onMouseLeave={() =>
                              getRemainingFilters("product_technology")
                            }
                            onChange={(val) => {
                              changeFilter("product_technology", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {techs.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Sub Technology</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            style={{ width: 113 }}
                            maxTagCount="responsive"
                            value={filters.product_sub_technology}
                            loading={dropdownLoading}
                            autoClearSearchValue={false}
                            allowClear
                            placement="bottomLeft"
                            onMouseLeave={() =>
                              getRemainingFilters("product_sub_technology")
                            }
                            onChange={(val) => {
                              changeFilter("product_sub_technology", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {subTechs.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Life Stage</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            style={{ width: 113 }}
                            maxTagCount="responsive"
                            value={filters.product_lifestage}
                            loading={dropdownLoading}
                            autoClearSearchValue={false}
                            allowClear
                            placement="bottomLeft"
                            onMouseLeave={() =>
                              getRemainingFilters("product_lifestage")
                            }
                            onChange={(val) => {
                              changeFilter("product_lifestage", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {lifestage.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">
                            Feeding Philosophy
                          </label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            placement="bottomLeft"
                            allowClear
                            style={{ width: 124 }}
                            autoClearSearchValue={false}
                            loading={dropdownLoading}
                            maxTagCount="responsive"
                            value={filters.product_feed_philosophy}
                            onMouseLeave={() =>
                              getRemainingFilters("product_feed_philosophy")
                            }
                            onChange={(val) => {
                              changeFilter("product_feed_philosophy", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {feedingPhilosophies.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Texture</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            placement="bottomLeft"
                            style={{ width: 113 }}
                            allowClear
                            maxTagCount="responsive"
                            autoClearSearchValue={false}
                            value={filters.product_texture}
                            loading={dropdownLoading}
                            onMouseLeave={() =>
                              getRemainingFilters("product_texture")
                            }
                            onChange={(val) => {
                              changeFilter("product_texture", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {textures.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <Tooltip title="Top 7 KPIs coming soon">
                            <label className="label-block">Retailer</label>
                            <Select
                              placeholder="All"
                              placement="bottomLeft"
                              dropdownMatchSelectWidth={false}
                              style={{ width: 95 }}
                              maxTagCount="responsive"
                              value={filters.customer_retailer_details}
                              // onMouseLeave={() =>
                              //   getRemainingFilters("customer_retailer_details")
                              // }
                              onChange={(val) => {
                                // changeFilter("customer_retailer_details", [val]);
                                dispatch(
                                  setFilters({
                                    ...filters,
                                    deep_dive_list: [],
                                    customer_retailer_details: [val],
                                  })
                                );
                              }}
                              showSearch
                            >
                              {retailers?.map((retailer) => (
                                <Option value={retailer}>{retailer}</Option>
                              ))}
                            </Select>
                          </Tooltip>
                        </Col>
                        <Col>
                          <label className="label-block">Time Aggregate</label>
                          <Select
                            placeholder="All"
                            placement="bottomLeft"
                            // defaultValue={["L4W"]}
                            style={{ width: 102 }}
                            maxTagCount="responsive"
                            dropdownMatchSelectWidth={false}
                            value={filters.time_aggregate}
                            loading={dropdownLoading}
                            onMouseLeave={() =>
                              getRemainingFilters("time_aggregate")
                            }
                            onChange={(val) => {
                              changeFilter("time_aggregate", [val]);
                            }}
                            showSearch
                          >
                            {timeAggregates.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Year Period</label>
                          <Select
                            placeholder="All"
                            placement="bottomLeft"
                            // defaultValue={["2022 p05"]}
                            dropdownStyle={{ minWidth: "130px" }}
                            style={{ width: 90 }}
                            maxTagCount="responsive"
                            value={filters.year_period}
                            loading={dropdownLoading}
                            onMouseLeave={() =>
                              getRemainingFilters("year_period")
                            }
                            onChange={(val) => {
                              changeFilter("year_period", [val]);
                            }}
                            showSearch
                          >
                            {yearPeriods.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">
                            Benchmark Period
                          </label>
                          <Select
                            placeholder="All"
                            // defaultValue={["P3"]}
                            style={{ width: 115 }}
                            maxTagCount="responsive"
                            dropdownMatchSelectWidth={false}
                            // dropdownStyle={{ minWidth: "130px" }}
                            loading={dropdownLoading}
                            value={filters.benchmark}
                            onChange={(val) => {
                              changeFilter("benchmark", [val]);
                            }}
                            showSearch
                          >
                            {benchmarkPeriods.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                      </div>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <Col>
                <Row justify="space-between" align="middle" style={{ marginBottom: "1%" }}>
                  <Col>
                    <Button
                      type="primary"
                      onClick={applyFilters}
                      disabled={dropdownLoading}
                    >
                      Apply
                    </Button>
                  </Col>
                  <Col>
                  {!newReport && (
                      <Dropdown
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              onClick={() => {
                                appInsights.trackEvent({
                                  name: "PPT_FOR_DIAGNOSTICS",
                                });

                                pptExportDiagnostics(
                                  exportData,
                                  cbRetailers,
                                  macroColumns,
                                  competitionColumns,
                                  defaultBrand,
                                  "Diagnostics"
                                );
                                exportDGTables(
                                  prepareTableExport(exportData),
                                  competitionColumnsExport,
                                  macroColumnsExport
                                );
                              }}
                            >
                              Download as PPT
                            </Menu.Item>
                            <Menu.Item
                              onClick={() => {
                                appInsights.trackEvent({
                                  name: "EXCEL_FOR_DIAGNOSTICS",
                                });
                                exportDGExcel(
                                  prepareTableExport(exportData),
                                  competitionColumnsExport,
                                  macroColumnsExport
                                );
                              }}
                            >
                              Download as Excel
                            </Menu.Item>
                          </Menu>
                        )}
                        className="d-more"
                      >
                        <Button icon={<DownloadOutlined />}>
                          Export Report
                        </Button>
                      </Dropdown>
                    )}
                  </Col>
                </Row>
              </Col>
            </>
          )}
          {!isReport && (
            <Row className="sticky-filter">
              <Col span={24} className="Filters-apply">
                <div className="Report-selected-filters">
                  <label>Report Filters:</label>
                  <div className="filter-item">
                    Pet -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_pet) &&
                      !appliedFilters.product_pet.includes("ALL")
                        ? appliedFilters.product_pet.join()
                        : "CAT,DOG"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Technology -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_technology) &&
                      appliedFilters.product_technology.length > 0
                        ? appliedFilters.product_technology.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Sub Technology -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_sub_technology)
                        ? appliedFilters.product_sub_technology.length === 0 ||
                          appliedFilters.product_sub_technology.length ===
                            subTechs.length
                          ? "ALL"
                          : appliedFilters.product_sub_technology.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Life Stage -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_lifestage)
                        ? appliedFilters.product_lifestage.length === 0 ||
                          appliedFilters.product_lifestage.length ===
                            lifestage.length
                          ? "ALL"
                          : appliedFilters.product_lifestage.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Feeding Philosophy -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_feed_philosophy)
                        ? appliedFilters.product_feed_philosophy.length === 0 ||
                          appliedFilters.product_feed_philosophy.length ===
                            feedingPhilosophies.length
                          ? "ALL"
                          : appliedFilters.product_feed_philosophy.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Texture -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_texture)
                        ? appliedFilters.product_texture.length === 0 ||
                          appliedFilters.product_texture.length ===
                            textures.length
                          ? "ALL"
                          : appliedFilters.product_texture.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Retailer -{" "}
                    <span>
                      {Array.isArray(appliedFilters.customer_retailer_details)
                        ? appliedFilters.customer_retailer_details.length ===
                            0 ||
                          appliedFilters.customer_retailer_details.length ===
                            retailers.length
                          ? "ALL"
                          : appliedFilters.customer_retailer_details.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Time Aggregate -{" "}
                    <span>{appliedFilters.time_aggregate}</span>
                  </div>
                  <div className="filter-item">
                    Year Period - <span>{appliedFilters.year_period}</span>
                  </div>
                  <div className="filter-item">
                    Benchmark Period - <span>{appliedFilters.benchmark}</span>
                  </div>
                  {/* <div className="filter-item">Competitor - <span>Nestle</span></div> */}
                </div>
              </Col>
            </Row>
          )}
          <div
            className={`first-section-ps ps-top`}
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <Row gutter={[12, 12]} justify="space-around">
              <Col className="ecom-card">
                <DiagnosticsCard
                  title="MKT RSV"
                  value={cardData.dollar && cardData.dollar?.abs}
                  variance={cardData.dollar && cardData.dollar?.variance}
                  varianceSymbol={symbols.percent}
                />
              </Col>
              <Col className="ecom-card">
                <DiagnosticsCard
                  title="$ Share"
                  value={cardData.dollar_share && cardData.dollar_share?.abs}
                  variance={
                    cardData.dollar_share && cardData.dollar_share?.variance
                  }
                  symbol={symbols.percent}
                  varianceSymbol={symbols.pts}
                />
              </Col>
              <Col className="ecom-card">
                <DiagnosticsCard
                  title="Eq LBs"
                  value={cardData.volume && cardData.volume?.abs}
                  variance={cardData.volume && cardData.volume?.variance}
                  varianceSymbol={symbols.percent}
                />
              </Col>
              <Col className="ecom-card">
                <DiagnosticsCard
                  title="LBs Share"
                  value={cardData.volume_share && cardData.volume_share?.abs}
                  variance={
                    cardData.volume_share && cardData.volume_share?.variance
                  }
                  symbol={symbols.percent}
                  varianceSymbol={symbols.pts}
                />
              </Col>
              <Col className="ecom-card">
                <DiagnosticsCard
                  title="Units"
                  value={cardData.unit && cardData.unit?.abs}
                  variance={cardData.unit && cardData.unit?.variance}
                  varianceSymbol={symbols.percent}
                />
              </Col>
              <Col className="ecom-card">
                <DiagnosticsCard
                  title="Unit Share"
                  value={cardData.unit_share && cardData.unit_share?.abs}
                  variance={
                    cardData.unit_share && cardData.unit_share?.variance
                  }
                  symbol={symbols.percent}
                  varianceSymbol={symbols.pts}
                  exportKPIs={exportKPIs}
                />
              </Col>
            </Row>
          </div>
          {/* <Row className="diagnostics-table"> */}
          <div className="next-skus">
            <CustomTable
              rowKey={rowKeyMacro}
              heading="Total eCom Performance"
              childrenColumnName="table_hierarchy_data"
              hideFilter={true}
              data={macroTableData}
              checkbox={newReport}
              value={newReportComponents.includes("2")}
              columns={macroColumns}
              scroll={true}
              xScroll="max-content"
              exportData={exportTotalEcom}
            />
          </div>
          {/* </Row> */}
          {/* <Row className="compitition-table">
            <CompetitionAndBenchmark
              description="Select a row to compare KPI's in the graph below"
              subBrandData={subBradnData}
              categoryData={categoryData}
              competitionData={compititionData}
              kpiData={kpiData}
              columns={columns.psEcomCompitition}
              tableData={ecomCompititionData}
              xScroll={false}
              rowSelection={{
                type: 'radio',
                hideSelectAll: true,
                renderCell: (val, rec, index, node) => !rec.brand_data ? node : null
              }}
              rowKey={rowKeyCompetition}
              childrenColumnName="brand_data"
              defaultExpandedRowKeys={
                ecomCompititionData.length
                  ? ecomCompititionData.map(
                    (item) => item[rowKeyCompetition]
                  )
                  : []
              }
            />
            {/* <CompetitionAndBenchmark
                isAllBrands={isAllBrands}
                brandData={brands}
                subBrandData={subBrands}
                retailerData={customerRetailers}
                categoryData={categoryBenchmarks}
                competitionData={competitionBenchmarks}
                kpiData={competitionKpiData}
                columns={competitionColumns}
                tableData={competitionTableData}
                rowKey={rowKeyCompetition}
                childrenColumnName="table_hierarchy_data"
                defaultExpandedRowKeys={
                  competitionTableData.length
                    ? competitionTableData.map((item) => item[rowKeyCompetition])
                    : []
                }
                // Compare via
                compareViaValue={filters.compare_via[0]}
                compareViaOnChange={(val) => {
                  setSelectedDeepdive([]);
                  const newFilters = {
                    ...filters,
                    compare_via: [val],
                    compare_deep_dive_list: [],
                  };
                  dispatch(setFilters(newFilters));
                  dispatch(getCompetitionFilterData(newFilters));
                  // dispatch(getCompetitionTableData(newFilters));
                  dispatch(getInternalBenchmarkData(newFilters));
                }}
                // Compare Deepdive
                deepdiveValue={filters.compare_deep_dive_list}
                deepdiveOnChange={(val) =>
                  dispatch(
                    setFilters({ ...filters, compare_deep_dive_list: val })
                  )
                }
                deepdiveOnMouseLeave={() => {
                  const selectedDeepdiveOnMouseLeave = [...selectedDeepdive];
                  const selectedDeepdiveOnChange = [
                    ...filters.compare_deep_dive_list,
                  ];
                  if (
                    !isEqual(
                      selectedDeepdiveOnMouseLeave.sort(),
                      selectedDeepdiveOnChange.sort()
                    )
                  ) {
                    setSelectedDeepdive(filters.compare_deep_dive_list);
                    // dispatch(getCompetitionTableData(filters));
                    dispatch(getInternalBenchmarkData(filters));
                  }
                }}
                // Category
                categoryValue={filters.category_benchmark}
                categoryOnChange={(val) =>
                  dispatch(setFilters({ ...filters, category_benchmark: val }))
                }
                categoryOnMouseLeave={() => {
                  const selectedCategoryOnMouseLeave = [...selectedCategory];
                  const selectedCategoryOnChange = [
                    ...filters.category_benchmark,
                  ];
                  if (
                    !isEqual(
                      selectedCategoryOnMouseLeave.sort(),
                      selectedCategoryOnChange.sort()
                    )
                  ) {
                    setSelectedCategory(filters.category_benchmark);
                    // dispatch(getCompetitionTableData(filters));
                    dispatch(getCategoryBenchmarkData(filters));
                  }
                }}
                // Competition
                competitionValue={filters.competition_benchmark}
                competitionOnChange={(val) =>
                  dispatch(setFilters({ ...filters, competition_benchmark: val }))
                }
                competitionOnMouseLeave={() => {
                  const selectedCompetitionOnMouseLeave = [
                    ...selectedCompetition,
                  ];
                  const selectedCompetitionOnChange = [
                    ...filters.competition_benchmark,
                  ];
                  if (
                    !isEqual(
                      selectedCompetitionOnMouseLeave.sort(),
                      selectedCompetitionOnChange.sort()
                    )
                  ) {
                    setSelectedCompetition(filters.competition_benchmark);
                    // dispatch(getCompetitionTableData(filters));
                    dispatch(getCompetitionBenchmarkData(filters));
                  }
                }}
                // Competition KPI
                kpiValue={filters.competition_kpi}
                kpiOnChange={(val) =>
                  dispatch(setFilters({ ...filters, competition_kpi: val }))
                }
                // kpiOnMouseLeave={() => {
                //   const selectedKpiOnMouseLeave = [...selectedKpi];
                //   const selectedKpiOnChange = [...filters.competition_kpi];
                //   if (
                //     !isEqual(
                //       selectedKpiOnMouseLeave.sort(),
                //       selectedKpiOnChange.sort()
                //     )
                //   ) {
                //     setSelectedKpi(filters.competition_kpi);
                //     dispatch(getCompetitionTableData(filters));
                //   }
                // }}
            /> */}
          {/* </Row> */}
          <Row className="compitition-table next-skus">
            <CompetitionAndBenchmark
              isAllBrands={isAllBrands}
              brandData={brands}
              subBrandData={subBrands}
              retailerData={customerRetailers}
              categoryData={categoryBenchmarks}
              competitionData={competitionBenchmarks}
              kpiData={competitionKpiData}
              columns={competitionColumns}
              tableData={competitionTableData}
              exportData={exportTableData}
              rowSelection={{
                type: "radio",
                hideSelectAll: true,
                renderCell: (val, rec, index, node) =>
                  !rec.table_hierarchy_data ? node : null,
              }}
              rowKey={rowKeyCompetition}
              childrenColumnName="table_hierarchy_data"
              defaultExpandedRowKeys={
                competitionTableData.length
                  ? competitionTableData.map((item) => item[rowKeyCompetition])
                  : []
              }
              // Compare via
              compareViaValue={filters.compare_via[0]}
              compareViaOnChange={(val) => {
                setSelectedDeepdive([]);
                const newFilters = {
                  ...filters,
                  compare_via: [val],
                  compare_deep_dive_list: [],
                  competition_benchmark: [],
                };
                dispatch(setFilters(newFilters));
                dispatch(getCompetitionFilterData(newFilters));
                dispatch(getTotalPetBenchmarkData(newFilters));
                dispatch(getInternalBenchmarkData(newFilters));
                dispatch(getCategoryBenchmarkData(newFilters));
                dispatch(getCompetitionBenchmarkData(newFilters));
              }}
              // Compare Deepdive
              deepdiveValue={filters.compare_deep_dive_list}
              deepdiveOnChange={(val) =>
                dispatch(
                  setFilters({ ...filters, compare_deep_dive_list: val })
                )
              }
              deepdiveOnMouseLeave={() => {
                const selectedDeepdiveOnMouseLeave = [...selectedDeepdive];
                const selectedDeepdiveOnChange = [
                  ...filters.compare_deep_dive_list,
                ];
                if (
                  !isEqual(
                    selectedDeepdiveOnMouseLeave.sort(),
                    selectedDeepdiveOnChange.sort()
                  )
                ) {
                  setSelectedDeepdive(filters.compare_deep_dive_list);
                  dispatch(getTotalPetBenchmarkData(filters));
                  dispatch(getInternalBenchmarkData(filters));
                  dispatch(getCategoryBenchmarkData(filters));
                  dispatch(getCompetitionBenchmarkData(filters));

                  if (filters.compare_via[0] === "Retailers")
                    setCbRetailers(filters.compare_deep_dive_list);
                  else setCbRetailers([]);
                }
              }}
              // Category
              categoryValue={filters.category_benchmark}
              categoryOnChange={(val) =>
                dispatch(setFilters({ ...filters, category_benchmark: val }))
              }
              categoryOnMouseLeave={() => {
                const selectedCategoryOnMouseLeave = [...selectedCategory];
                const selectedCategoryOnChange = [
                  ...filters.category_benchmark,
                ];
                if (
                  !isEqual(
                    selectedCategoryOnMouseLeave.sort(),
                    selectedCategoryOnChange.sort()
                  )
                ) {
                  setSelectedCategory(filters.category_benchmark);
                  dispatch(getCategoryBenchmarkData(filters));
                }
              }}
              // Competition
              competitionValue={filters.competition_benchmark}
              competitionOnChange={(val) =>
                dispatch(setFilters({ ...filters, competition_benchmark: val }))
              }
              competitionOnMouseLeave={() => {
                const selectedCompetitionOnMouseLeave = [
                  ...selectedCompetition,
                ];
                const selectedCompetitionOnChange = [
                  ...filters.competition_benchmark,
                ];
                if (
                  !isEqual(
                    selectedCompetitionOnMouseLeave.sort(),
                    selectedCompetitionOnChange.sort()
                  )
                ) {
                  setSelectedCompetition(filters.competition_benchmark);
                  dispatch(getCompetitionBenchmarkData(filters));
                }
              }}
              // Competition KPI
              kpiValue={filters.competition_kpi}
              kpiOnChange={(val) =>
                dispatch(setFilters({ ...filters, competition_kpi: val }))
              }
            />
          </Row>
          <Row className="diagnostics-chart">
            <div className="kpiChart">
              <div>
                <h4>{getKpiTitle()}</h4>
                <p className="kpi-text">Select the KPI's to see the trend</p>
                <Dropdown
                  overlay={() => menu(exportComparisionTrend)}
                  trigger={["click"]}
                  placement="bottomRight"
                  className="d-more"
                >
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    <MoreOutlined />
                  </a>
                </Dropdown>
              </div>
              <div className="kpi-dropdown">
                <FilterDropDown
                  filters={filters}
                  compareVia={compareVia}
                  compareViaOnChange={(val) => {
                    setSelectedDeepdive([]);
                    const newFilters = {
                      ...filters,
                      trend_compare_via: [val],
                      trend_compare_deep_dive_list: [],
                    };
                    dispatch(setFilters(newFilters));
                    dispatch(getKpiTrendsData(newFilters));
                    dispatch(getCompetitionFilterDataForTrendChart(newFilters));
                  }}
                  compareViaList={compareViaList}
                  trendCompetitionBenchmarks={trendCompetitionBenchmarks}
                  SHOW_PARENT={SHOW_PARENT}
                  compititionOnChanege={(val) => {
                    dispatch(
                      setFilters({
                        ...filters,
                        trend_compare_deep_dive_list: val ? [val] : [],
                      })
                    );
                    dispatch(
                      getKpiTrendsData({
                        ...filters,
                        trend_compare_deep_dive_list: val ? [val] : [],
                      })
                    );
                  }}
                  isAllBrands={isAllBrands}
                  brandOnchange={(val) => {
                    dispatch(
                      setFilters({
                        ...filters,
                        trend_compare_deep_dive_list: val ? [val] : [],
                      })
                    );
                    dispatch(
                      getKpiTrendsData({
                        ...filters,
                        trend_compare_deep_dive_list: val ? [val] : [],
                      })
                    );
                  }}
                  trendBrands={trendBrands}
                  subBrandOnChange={(val) => {
                    dispatch(
                      setFilters({
                        ...filters,
                        trend_compare_deep_dive_list: val ? [val] : [],
                      })
                    );
                    dispatch(
                      getKpiTrendsData({
                        ...filters,
                        trend_compare_deep_dive_list: val ? [val] : [],
                      })
                    );
                  }}
                  trendSubBrands={trendSubBrands}
                  kpiData={kpiData}
                  kpi1OnChange={(val) => {
                    dispatch(setKpi1([val]));
                    dispatch(getKpiTrendsData({ ...filters, kpi1: [val] }));
                  }}
                  kpi2OnChange={(val) => {
                    dispatch(setKpi2([val]));
                    dispatch(getKpiTrendsData({ ...filters, kpi2: [val] }));
                  }}
                />
              </div>
              <Row style={{ width: "100%" }}>
                <DualAxes
                  {...config(filters, appliedFilters)}
                  data={[
                    kpiTrendsData.line_chart || [],
                    kpiTrendsData.line_chart_graph || [],
                  ]}
                  style={{ width: "100%" }}
                />
              </Row>
            </div>
          </Row>
        </Loader>
      </Content>
    </Wrapper>
  );
}

export default withRouter(Diagnostics);
