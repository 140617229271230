import column from "./dgColumns";
import baColumn from "../be-available/baColumns"
import { formatNumber } from "./../../utilities/helper"
import { cloneDeep } from "lodash";
import { setDeclareAppliedFilters } from "../../utilities/helper";

export const onChange = (checked) => {

};
export const rowKeyMacro = "index";
export const rowKey = "index";
export const rowKeyCompetition = "index";
export const rowKeyCompetitionBenchmark = "table_key";



// Set the filters applied based on the filters selected on the UI while clicking on Apply button (Special for Conversion usecase)
export const declareAppliedFilters = (inputFilter, filtersData) => {
  const { subTechs, techs, feedingPhilosophies, textures } = filtersData;
  let finalFilter = cloneDeep(inputFilter);

  if (
    finalFilter["product_pet"].length === 0
  )
    finalFilter["product_pet"] = ["ALL"];
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_technology", techs);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_sub_technology", subTechs);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_feed_philosophy", feedingPhilosophies);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_texture", textures);

  return finalFilter;
};

export const viewBy = {
  R: "Retailer",
  S: "Sub Brand",
  C: "Competition",
};

// Competition and Benchmark default selected KPIs
export const defaultCompetitionKpi = [
  "Basic KPIs:$ Sales",
  "Basic KPIs:$ Share",
  "Basic KPIs:Unit Sales",
  "Basic KPIs:Share of Unit Sales",
  "Basic KPIs:EQ LBs",
  "Basic KPIs:LBs Share",
  "Be Visible:Share of Shelf",
  // "Be Visible:Traffic",
  // "Be Visible:Traffic Share",
  "Be Available:In Stock %",
  "Be Available:# of items",
];

// Formatter object for the data being populated in the Competiton and Benchmark Table
export const competitionKpiData = [
  {
    title: "Basic KPIs",
    value: "Basic KPIs",
    checkable: false,
    children: [
      {
        title: "$ Sales",
        value: "Basic KPIs:$ Sales",
        key: "dollar_sales",
        renderFunction: column.renderDollarPercentFunction,
        renderPPTFunction: column.renderPPTDollarPercentFunction,
      },
      {
        title: "$ Share",
        value: "Basic KPIs:$ Share",
        key: "dollar_share",
        renderFunction: column.renderPercentagePointsFunction,
        renderPPTFunction: column.renderPPTPercentagePtsFunction,
      },
      {
        title: "Unit Sales",
        value: "Basic KPIs:Unit Sales",
        key: "unit_sales",
        renderFunction: column.renderNumberPercentFunction,
        renderPPTFunction: column.renderPPTNumberPercentFunction,
      },
      {
        title: "Share of Unit Sales",
        value: "Basic KPIs:Share of Unit Sales",
        key: "share_unit_sales",
        renderFunction: column.renderPercentPointsFunction,
        renderPPTFunction: column.renderPPTPercentagePtsFunction,
      },
      {
        title: "EQ LBs",
        value: "Basic KPIs:EQ LBs",
        key: "eq_lbs",
        renderFunction: column.renderNumberPercentFunction,
        renderPPTFunction: column.renderPPTNumberPercentFunction,
      },
      {
        title: "LBs Share",
        value: "Basic KPIs:LBs Share",
        key: "eq_lbs_share",
        renderFunction: column.renderPercentagePointsFunction,
        renderPPTFunction: column.renderPPTPercentagePtsFunction,
      },
    ],
  },
  {
    title: "Be Visible",
    value: "Be Visible",
    checkable: false,
    children: [
      {
        title: "Share of Shelf",
        value: "Be Visible:Share of Shelf",
        key: "share_shelf",
        renderFunction: column.renderPercentPointsFunction,
        renderPPTFunction: column.renderPPTPercentagePtsFunction,
      },

    ],
  },
  {
    title: "Be Available",
    value: "Be Available",
    checkable: false,
    children: [
      {
        title: "In Stock %",
        value: "Be Available:In Stock %",
        key: "in_stock",
        renderFunction: column.renderPercentagePointsFunction,
        renderPPTFunction: column.renderPPTPercentagePtsFunction,
      },
      {
        title: "# of items",
        value: "Be Available:# of items",
        key: "number_items",
        renderFunction: column.renderWholeNumberPercentFunction,
        renderPPTFunction: column.renderPPTWholeNumberPercentageFunction,
      },
    ],
  },
];
export const competitionKpiExportData = [
  {
    title: "Basic KPIs",
    value: "Basic KPIs",
    checkable: false,
    children: [
      {
        title: "$ Sales",
        value: "Basic KPIs:$ Sales",
        key: "dollar_sales",
        renderAbsFunction: baColumn.renderTextDollarFunction,
        renderVarFunction: baColumn.renderTextVariancePercentageFunction
      },
      {
        title: "$ Share",
        value: "Basic KPIs:$ Share",
        key: "dollar_share",
        renderAbsFunction: baColumn.renderTextAbsPercentageFunction,
        renderVarFunction: baColumn.renderTextVarianceNumberFunction,
      },
      {
        title: "Unit Sales",
        value: "Basic KPIs:Unit Sales",
        key: "unit_sales",
        renderAbsFunction: baColumn.renderTextWholeNumberFunction,
        renderVarFunction: baColumn.renderTextVariancePercentageFunction,
      },
      {
        title: "Share of Unit Sales",
        value: "Basic KPIs:Share of Unit Sales",
        key: "share_unit_sales",
        renderAbsFunction: baColumn.renderTextAbsPercentageFunction,
        renderVarFunction: baColumn.renderTextVarianceNumberFunction,
      },
      {
        title: "EQ LBs",
        value: "Basic KPIs:EQ LBs",
        key: "eq_lbs",
        renderAbsFunction: baColumn.renderTextWholeNumberFunction,
        renderVarFunction: baColumn.renderTextVariancePercentageFunction,
      },
      {
        title: "LBs Share",
        value: "Basic KPIs:LBs Share",
        key: "eq_lbs_share",
        renderAbsFunction: baColumn.renderTextAbsPercentageFunction,
        renderVarFunction: baColumn.renderTextVarianceNumberFunction,
      },
    ],
  },
  {
    title: "Be Visible",
    value: "Be Visible",
    checkable: false,
    children: [
      {
        title: "Share of Shelf",
        value: "Be Visible:Share of Shelf",
        key: "share_shelf",
        renderAbsFunction: baColumn.renderTextAbsPercentageFunction,
        renderVarFunction: baColumn.renderTextVarianceNumberFunction,
      },
      // {
      //   title: "Traffic",
      //   value: "Be Visible:Traffic",
      //   key: "traffic",
      //   renderFunction: column.renderPercentageFunction,
      // },

      {
        title: "Fair Share",
        value: "Be Visible:Fair Share",
        key: "fair_share",
        renderAbsFunction: baColumn.renderTextAbsPercentageFunction,
        renderVarFunction: baColumn.renderTextVarianceNumberFunction,
      },
      {
        title: "Organic Fair Share",
        value: "Be Visible:Organic Fair Share",
        key: "organic_fair_share",
        renderAbsFunction: baColumn.renderTextAbsPercentageFunction,
        renderVarFunction: baColumn.renderTextVarianceNumberFunction,
      },
      {
        title: "Paid Fair Share",
        value: "Be Visible:Paid Fair Share",
        key: "paid_fair_share",
        renderAbsFunction: baColumn.renderTextAbsPercentageFunction,
        renderVarFunction: baColumn.renderTextVarianceNumberFunction,
      },
      // {
      //   title: "Traffic Share",
      //   value: "Be Visible:Traffic Share",
      //   key: "traffic_share",
      //   renderFunction: column.renderPercentPointsFunction,
      // },
    ],
  },
  {
    title: "Be Available",
    value: "Be Available",
    checkable: false,
    children: [
      {
        title: "In Stock %",
        value: "Be Available:In Stock %",
        key: "in_stock",
        renderAbsFunction: baColumn.renderTextAbsPercentageFunction,
        renderVarFunction: baColumn.renderTextVarianceNumberFunction,
      },
      {
        title: "# of items",
        value: "Be Available:# of items",
        key: "number_items",
        renderAbsFunction: baColumn.renderTextWholeNumberFunction,
        renderVarFunction: baColumn.renderTextVariancePercentageFunction,
      },
    ],
  },
];


export const dummyKpiData = {
  sum_value_4w: "...",
  sum_value_4w_ya: "...",
  sum_value_yee: "...",
  fact_yee: "...",
  sum_value_ytd: "...",
  sum_value_ytd_ya: "...",
};
export const ecomTableData2 = [
  {
    table_hierarchy_data: [
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        items_listed: { abs: 20.0, variance: 20.0 },
        in_stock: { abs: 20.0, variance: 20.0 },
      },
    ],
    table_key: "CESAR BUTCHER'S SELECTS",
    items_listed: { abs: 20.0, variance: 20.0 },
    in_stock: { abs: 20.0, variance: 20.0 },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        items_listed: { abs: 15.0714, variance: -13.524895860826492 },
        in_stock: { abs: 55.82, variance: -8.730370370370387 },
      },
    ],
    table_key: "CESAR CANINE CUISINE",
    items_listed: { abs: 15.0714, variance: -13.524895860826469 },
    in_stock: { abs: 55.82, variance: -8.730370370370387 },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        items_listed: { abs: 5.0, variance: 0.0 },
        in_stock: { abs: 71.42857142857143, variance: 0.0 },
      },
    ],
    table_key: "CESAR FILETS IN GRAVY",
    items_listed: { abs: 5.0, variance: 0.0 },
    in_stock: { abs: 71.42857142857143, variance: 0.0 },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        items_listed: { abs: 5.964300000000001, variance: -0.59499999999999 },
        in_stock: { abs: 42.60214285714286, variance: -0.25499999999999545 },
      },
    ],
    table_key: "CESAR HOME DELIGHTS",
    items_listed: { abs: 5.964300000000001, variance: -0.59499999999999 },
    in_stock: { abs: 42.60214285714286, variance: -0.25499999999999545 },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        items_listed: { abs: 0.0, variance: 0.0 },
        in_stock: { abs: 0.0, variance: 0.0 },
      },
    ],
    table_key: "CESAR MINIS",
    items_listed: { abs: 0.0, variance: 0.0 },
    in_stock: { abs: 0.0, variance: 0.0 },
  },
]
export const ecomTableData = [
  {
    table_hierarchy_data: [
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        }
      },
    ],
    table_key: "CESAR BUTCHER'S SELECTS",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    }
  },
  {
    table_hierarchy_data: [
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        be_available: {
          items_listed: { abs: 15.0714, variance: -13.524895860826492 },
          in_stock: { abs: 55.82, variance: -8.730370370370387 },
        },
      },
    ],
    table_key: "CESAR CANINE CUISINE",
    be_available: {
      items_listed: { abs: 15.0714, variance: -13.524895860826469 },
      in_stock: { abs: 55.82, variance: -8.730370370370387 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        be_available: {
          items_listed: { abs: 5.0, variance: 0.0 },
          in_stock: { abs: 71.42857142857143, variance: 0.0 },
        },
      },
    ],
    table_key: "CESAR FILETS IN GRAVY",
    be_available: {
      items_listed: { abs: 5.0, variance: 0.0 },
      in_stock: { abs: 71.42857142857143, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        be_available: {
          items_listed: { abs: 5.964300000000001, variance: -0.59499999999999 },
          in_stock: { abs: 42.60214285714286, variance: -0.25499999999999545 },
        },
      },
    ],
    table_key: "CESAR HOME DELIGHTS",
    be_available: {
      items_listed: { abs: 5.964300000000001, variance: -0.59499999999999 },
      in_stock: { abs: 42.60214285714286, variance: -0.25499999999999545 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "CESAR MINIS",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 33.333333333333336, variance: 0.0 },
        },
      },
    ],
    table_key: "CESAR PUPPY",
    be_available: {
      items_listed: { abs: 1.0, variance: 0.0 },
      in_stock: { abs: 33.333333333333336, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        be_available: {
          items_listed: {
            abs: 3.9286000000000003,
            variance: -1.7849999999999921,
          },
          in_stock: { abs: 65.47666666666667, variance: -1.1899999999999977 },
        },
      },
    ],
    table_key: "CESAR SAVORY DELIGHTS",
    be_available: {
      items_listed: { abs: 3.9286000000000003, variance: -1.7849999999999921 },
      in_stock: { abs: 65.47666666666667, variance: -1.1899999999999977 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        be_available: {
          items_listed: { abs: 9.0, variance: 0.0 },
          in_stock: { abs: 81.81818181818181, variance: 0.0 },
        },
      },
    ],
    table_key: "CESAR SIMPLY CRAFTED",
    be_available: {
      items_listed: { abs: 9.0, variance: 0.0 },
      in_stock: { abs: 81.81818181818181, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "CESAR SUNRISE",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        be_available: {
          items_listed: { abs: 4.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
    ],
    table_key: "CESAR VARIETY PACK",
    be_available: {
      items_listed: { abs: 4.0, variance: 0.0 },
      in_stock: { abs: 100.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        be_available: {
          items_listed: {
            abs: 5.928599999999999,
            variance: -1.1900000000000133,
          },
          in_stock: { abs: 98.81, variance: -1.1899999999999977 },
        },
      },
    ],
    table_key: "CESAR WHOLESOME BOWLS",
    be_available: {
      items_listed: { abs: 5.9286, variance: -1.1899999999999946 },
      in_stock: { abs: 98.81, variance: -1.1899999999999977 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "10 - 13.9 LBS",
        be_available: {
          items_listed: {
            abs: 3.9286000000000003,
            variance: -1.7849999999999921,
          },
          in_stock: { abs: 98.215, variance: -1.7849999999999966 },
        },
      },
      {
        table_key: "10.0 - 12.9 LBS",
        be_available: {
          items_listed: {
            abs: 2.9286000000000003,
            variance: -2.3799999999999932,
          },
          in_stock: { abs: 97.62, variance: -2.3799999999999955 },
        },
      },
      {
        table_key: "2.0 - 5.9 LBS",
        be_available: {
          items_listed: { abs: 5.0, variance: 0.0 },
          in_stock: { abs: 83.33333333333333, variance: 0.0 },
        },
      },
      {
        table_key: "20 - 26.9 LBS",
        be_available: {
          items_listed: { abs: 3.8572, variance: -3.5699999999999954 },
          in_stock: { abs: 96.43, variance: -3.569999999999993 },
        },
      },
      {
        table_key: "4 - 7.9 LBS",
        be_available: {
          items_listed: { abs: 4.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "CAN LRG 11.0-19.9OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "SNGL SRV 56.0-100.9G",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "CRAVE CORE",
    be_available: {
      items_listed: { abs: 19.7144, variance: -1.4279999999999937 },
      in_stock: { abs: 51.88, variance: -0.7515789473684151 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 25.0+OZ",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 50.0, variance: 0.0 },
        },
      },
    ],
    table_key: "DENTASTIX BACON",
    be_available: {
      items_listed: { abs: 1.0, variance: 0.0 },
      in_stock: { abs: 50.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 25.0+OZ",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 50.0, variance: 0.0 },
        },
      },
    ],
    table_key: "DENTASTIX BEEF",
    be_available: {
      items_listed: { abs: 1.0, variance: 0.0 },
      in_stock: { abs: 50.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 0-6.9OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "CHW/SFT/JRKY 13.0-24.9OZ",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 50.0, variance: 0.0 },
        },
      },
    ],
    table_key: "DENTASTIX CHEWY CHUNX",
    be_available: {
      items_listed: { abs: 1.0, variance: 0.0 },
      in_stock: { abs: 25.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 13.0-24.9OZ",
        be_available: {
          items_listed: { abs: 2.0, variance: 0.0 },
          in_stock: { abs: 50.0, variance: 0.0 },
        },
      },
    ],
    table_key: "DENTASTIX DUAL",
    be_available: {
      items_listed: { abs: 2.0, variance: 0.0 },
      in_stock: { abs: 50.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 13.0-24.9OZ",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 50.0, variance: 0.0 },
        },
      },
      {
        table_key: "CHW/SFT/JRKY 7.0-12.9OZ",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 50.0, variance: 0.0 },
        },
      },
    ],
    table_key: "DENTASTIX FRESH",
    be_available: {
      items_listed: { abs: 2.0, variance: 0.0 },
      in_stock: { abs: 50.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 0-6.9OZ",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 33.333333333333336, variance: 0.0 },
        },
      },
      {
        table_key: "CHW/SFT/JRKY 13.0-24.9OZ",
        be_available: {
          items_listed: { abs: 3.0, variance: 0.0 },
          in_stock: { abs: 60.0, variance: 0.0 },
        },
      },
      {
        table_key: "CHW/SFT/JRKY 25.0+OZ",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 50.0, variance: 0.0 },
        },
      },
    ],
    table_key: "DENTASTIX ORIGINAL",
    be_available: {
      items_listed: { abs: 5.0, variance: 0.0 },
      in_stock: { abs: 50.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 25.0+OZ",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 50.0, variance: 0.0 },
        },
      },
    ],
    table_key: "DENTASTIX VARIETY",
    be_available: {
      items_listed: { abs: 1.0, variance: 0.0 },
      in_stock: { abs: 50.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 25.0+OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "GREENIES AGING CARE",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 7.0-12.9OZ",
        be_available: {
          items_listed: { abs: 2.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
    ],
    table_key: "GREENIES ANYTIME BITES",
    be_available: {
      items_listed: { abs: 2.0, variance: 0.0 },
      in_stock: { abs: 100.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 7.0-12.9OZ",
        be_available: {
          items_listed: { abs: 4.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
    ],
    table_key: "GREENIES BLUEBERRY",
    be_available: {
      items_listed: { abs: 4.0, variance: 0.0 },
      in_stock: { abs: 100.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 0-6.9OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "GREENIES BREATH BUSTER",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "MEGA 4.1-9OZ",
        be_available: {
          items_listed: { abs: 4.9643, variance: -0.7140000000000035 },
          in_stock: { abs: 55.15888888888889, variance: -0.3966666666666683 },
        },
      },
      {
        table_key: "REG 2.1-4OZ",
        be_available: {
          items_listed: { abs: 4.9643, variance: -0.7140000000000035 },
          in_stock: { abs: 62.05375, variance: -0.44624999999999915 },
        },
      },
      {
        table_key: "SUPER MEGA 9.1-13 OZ",
        be_available: {
          items_listed: {
            abs: 3.9286000000000003,
            variance: -1.7849999999999921,
          },
          in_stock: { abs: 49.1075, variance: -0.8924999999999983 },
        },
      },
      {
        table_key: "VALUE SIZE 13.1-21OZ",
        be_available: {
          items_listed: { abs: 4.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
    ],
    table_key: "GREENIES DENTAL",
    be_available: {
      items_listed: { abs: 17.8572, variance: -0.7933333333333397 },
      in_stock: { abs: 61.57655172413793, variance: -0.49241379310345224 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 7.0-12.9OZ",
        be_available: {
          items_listed: { abs: 4.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
    ],
    table_key: "GREENIES FRESH",
    be_available: {
      items_listed: { abs: 4.0, variance: 0.0 },
      in_stock: { abs: 100.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 0-6.9OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "GREENIES GINGERBREAD",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 0-6.9OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "CHW/SFT/JRKY 25.0+OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "CHW/SFT/JRKY 7.0-12.9OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "GREENIES GRAIN FREE",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 0-6.9OZ",
        be_available: {
          items_listed: { abs: 7.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "CHW/SFT/JRKY 13.0-24.9OZ",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "CHW/SFT/JRKY 25.0+OZ",
        be_available: {
          items_listed: { abs: 13.8929, variance: -0.7650000000000046 },
          in_stock: { abs: 99.235, variance: -0.7650000000000006 },
        },
      },
      {
        table_key: "CHW/SFT/JRKY 7.0-12.9OZ",
        be_available: {
          items_listed: { abs: 4.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
    ],
    table_key: "GREENIES ORIGINAL",
    be_available: {
      items_listed: { abs: 25.8929, variance: -0.41192307692307345 },
      in_stock: { abs: 99.58807692307693, variance: -0.41192307692307395 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 0-6.9OZ",
        be_available: {
          items_listed: { abs: 2.0, variance: 0.0 },
          in_stock: { abs: 50.0, variance: 0.0 },
        },
      },
      {
        table_key: "CHW/SFT/JRKY 13.0-24.9OZ",
        be_available: {
          items_listed: { abs: 4.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "CHW/SFT/JRKY 7.0-12.9OZ",
        be_available: {
          items_listed: { abs: 4.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "REG 2.1-4OZ",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "TRIAL 0-2OZ",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
    ],
    table_key: "GREENIES PILL POCKETS",
    be_available: {
      items_listed: { abs: 12.0, variance: 0.0 },
      in_stock: { abs: 85.71428571428571, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 7.0-12.9OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: -100.0 },
          in_stock: { abs: 0.0, variance: -23.215 },
        },
      },
    ],
    table_key: "GREENIES PUMPKIN SPICE",
    be_available: {
      items_listed: { abs: 0.0, variance: -100.0 },
      in_stock: { abs: 0.0, variance: -23.215 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 7.0-12.9OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "GREENIES PUPPY",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 0-6.9OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "GREENIES SCARY BERRY",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "MEGA 4.1-9OZ",
        be_available: {
          items_listed: { abs: 4.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "REG 2.1-4OZ",
        be_available: {
          items_listed: { abs: 4.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "VALUE SIZE 13.1-21OZ",
        be_available: {
          items_listed: { abs: 4.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
    ],
    table_key: "GREENIES SMART BITES",
    be_available: {
      items_listed: { abs: 12.0, variance: 0.0 },
      in_stock: { abs: 100.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 25.0+OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "GREENIES VARIETY PACK",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 25.0+OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "GREENIES WEIGHT MGMNT",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "27 - 35.9 LBS",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "36 - 44.9 LBS",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
    ],
    table_key: "IAMS ADVANCED HEALTH",
    be_available: {
      items_listed: { abs: 2.0, variance: 0.0 },
      in_stock: { abs: 100.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "14 - 19.9 LBS",
        be_available: {
          items_listed: { abs: 5.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "27 - 35.9 LBS",
        be_available: {
          items_listed: { abs: 4.0, variance: 0.0 },
          in_stock: { abs: 80.0, variance: 0.0 },
        },
      },
      {
        table_key: "36 - 44.9 LBS",
        be_available: {
          items_listed: { abs: 1.9643000000000002, variance: 0.0 },
          in_stock: { abs: 49.1075, variance: 0.0 },
        },
      },
      {
        table_key: "4 - 7.9 LBS",
        be_available: {
          items_listed: { abs: 4.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
    ],
    table_key: "IAMS CORE",
    be_available: {
      items_listed: { abs: 14.964300000000001, variance: 0.0 },
      in_stock: { abs: 83.135, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "SNGL SRV 56.0-100.9G",
        be_available: {
          items_listed: { abs: 9.0, variance: 0.0 },
          in_stock: { abs: 56.25, variance: 0.0 },
        },
      },
    ],
    table_key: "IAMS PERFECT PORTIONS",
    be_available: {
      items_listed: { abs: 9.0, variance: 0.0 },
      in_stock: { abs: 56.25, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "13.0 - 16.9 LBS",
        be_available: {
          items_listed: { abs: 6.8572, variance: -2.0399999999999863 },
          in_stock: { abs: 76.19111111111111, variance: -1.586666666666659 },
        },
      },
      {
        table_key: "14 - 19.9 LBS",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 50.0, variance: 0.0 },
        },
      },
      {
        table_key: "17.0 - 22.9 LBS",
        be_available: {
          items_listed: {
            abs: 1.8929000000000002,
            variance: -5.354999999999988,
          },
          in_stock: { abs: 94.64500000000001, variance: -5.35499999999999 },
        },
      },
      {
        table_key: "2.0 - 5.9 LBS",
        be_available: {
          items_listed: { abs: 6.0, variance: 0.0 },
          in_stock: { abs: 85.71428571428571, variance: 0.0 },
        },
      },
      {
        table_key: "27 - 35.9 LBS",
        be_available: {
          items_listed: { abs: 3.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "36 - 44.9 LBS",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "4 - 7.9 LBS",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "6.0 - 9.9 LBS",
        be_available: {
          items_listed: { abs: 6.999999999999999, variance: 0.0 },
          in_stock: { abs: 77.77777777777777, variance: 0.0 },
        },
      },
      {
        table_key: "CAN LRG 11.0-19.9OZ",
        be_available: {
          items_listed: { abs: 6.0, variance: 0.0 },
          in_stock: { abs: 85.71428571428571, variance: 0.0 },
        },
      },
    ],
    table_key: "IAMS PROACTIVE HEALTH",
    be_available: {
      items_listed: { abs: 33.7501, variance: -0.7349999999999902 },
      in_stock: { abs: 82.31731707317074, variance: -0.6095121951219369 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "10 - 13.9 LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "20 - 26.9 LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "4 - 7.9 LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "KARMA CORE",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CAN LRG 11.0-19.9OZ",
        be_available: {
          items_listed: { abs: 9.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "SNGL SRV 56.0-100.9G",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "NUTRO CORE CAN",
    be_available: {
      items_listed: { abs: 9.0, variance: 0.0 },
      in_stock: { abs: 52.94117647058823, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "BISCUIT 0-12.9OZ",
        be_available: {
          items_listed: { abs: 2.0, variance: 0.0 },
          in_stock: { abs: 40.0, variance: 0.0 },
        },
      },
      {
        table_key: "BISCUIT 13.0-25.9OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "NUTRO CORE CRUNCHY",
    be_available: {
      items_listed: { abs: 2.0, variance: 0.0 },
      in_stock: { abs: 22.22222222222222, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "13.0 - 16.9 LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "2.0 - 5.9 LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "20 - 26.9 LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "6.0 - 9.9 LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "NUTRO CORE GRAIN FREE",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "10 - 13.9 LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "20 - 26.9 LBS",
        be_available: {
          items_listed: { abs: 5.964300000000001, variance: -0.59499999999999 },
          in_stock: { abs: 99.40500000000002, variance: -0.5949999999999847 },
        },
      },
      {
        table_key: "4 - 7.9 LBS",
        be_available: {
          items_listed: {
            abs: 1.9643000000000002,
            variance: -1.7849999999999921,
          },
          in_stock: { abs: 98.215, variance: -1.7849999999999966 },
        },
      },
      {
        table_key: "CAN LRG 11.0-19.9OZ",
        be_available: {
          items_listed: {
            abs: 2.9642999999999997,
            variance: -1.1900000000000133,
          },
          in_stock: { abs: 98.81, variance: -1.1899999999999977 },
        },
      },
    ],
    table_key: "NUTRO CORE LID",
    be_available: {
      items_listed: { abs: 10.8929, variance: -0.9736363636363715 },
      in_stock: { abs: 83.79153846153847, variance: -0.8238461538461479 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CHW/SFT/JRKY 0-6.9OZ",
        be_available: {
          items_listed: { abs: 4.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "CHW/SFT/JRKY 7.0-12.9OZ",
        be_available: {
          items_listed: { abs: 2.0, variance: 0.0 },
          in_stock: { abs: 50.0, variance: 0.0 },
        },
      },
    ],
    table_key: "NUTRO CORE MINIT BITES",
    be_available: {
      items_listed: { abs: 6.0, variance: 0.0 },
      in_stock: { abs: 75.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "10 - 13.9 LBS",
        be_available: {
          items_listed: { abs: 8.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "14 - 19.9 LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "27 - 35.9 LBS",
        be_available: {
          items_listed: {
            abs: 14.928600000000001,
            variance: -0.47599999999998754,
          },
          in_stock: { abs: 99.52400000000002, variance: -0.4759999999999849 },
        },
      },
      {
        table_key: "36 - 44.9 LBS",
        be_available: {
          items_listed: { abs: 3.9643, variance: -0.8924999999999961 },
          in_stock: { abs: 99.1075, variance: -0.8924999999999983 },
        },
      },
      {
        table_key: "4 - 7.9 LBS",
        be_available: {
          items_listed: { abs: 5.964300000000001, variance: -0.59499999999999 },
          in_stock: { abs: 74.55375000000001, variance: -0.44624999999999204 },
        },
      },
    ],
    table_key: "NUTRO CORE NATUAL CHOICE",
    be_available: {
      items_listed: { abs: 32.857200000000006, variance: -0.4327272727272547 },
      in_stock: { abs: 73.016, variance: -0.31733333333332325 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "SNGL SRV 56.0-100.9G",
        be_available: {
          items_listed: { abs: 12.0, variance: 0.0 },
          in_stock: { abs: 54.54545454545455, variance: 0.0 },
        },
      },
    ],
    table_key: "NUTRO CORE PP GRAIN FREE",
    be_available: {
      items_listed: { abs: 12.0, variance: 0.0 },
      in_stock: { abs: 54.54545454545455, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CAN LRG 11.0-19.9OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 50.0, variance: 0.0 },
        },
      },
    ],
    table_key: "NUTRO CORE PUPPY",
    be_available: {
      items_listed: { abs: 1.0, variance: 0.0 },
      in_stock: { abs: 25.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "10 - 13.9 LBS",
        be_available: {
          items_listed: { abs: 2.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "20 - 26.9 LBS",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "27 - 35.9 LBS",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "4 - 7.9 LBS",
        be_available: {
          items_listed: { abs: 2.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        be_available: {
          items_listed: { abs: 3.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
    ],
    table_key: "NUTRO CORE SO SIMPLE",
    be_available: {
      items_listed: { abs: 9.0, variance: 0.0 },
      in_stock: { abs: 100.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        be_available: {
          items_listed: { abs: 5.0, variance: 0.0 },
          in_stock: { abs: 45.45454545454545, variance: 0.0 },
        },
      },
    ],
    table_key: "NUTRO CORE TRAYS",
    be_available: {
      items_listed: { abs: 5.0, variance: 0.0 },
      in_stock: { abs: 45.45454545454545, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "13.0 - 16.9 LBS",
        be_available: {
          items_listed: {
            abs: 2.9286000000000003,
            variance: -2.3799999999999932,
          },
          in_stock: { abs: 58.572, variance: -1.4279999999999973 },
        },
      },
      {
        table_key: "2.0 - 5.9 LBS",
        be_available: {
          items_listed: {
            abs: 13.928600000000001,
            variance: -0.5099999999999882,
          },
          in_stock: { abs: 87.05375000000001, variance: -0.44624999999999204 },
        },
      },
      {
        table_key: "6.0 - 9.9 LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "NUTRO CORE WHOLESOME ESSENTIAL",
    be_available: {
      items_listed: { abs: 16.857200000000002, variance: -0.8399999999999859 },
      in_stock: { abs: 62.43407407407408, variance: -0.5288888888888792 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "10 - 13.9 LBS",
        be_available: {
          items_listed: { abs: 2.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "20 - 26.9 LBS",
        be_available: {
          items_listed: {
            abs: 6.964300000000001,
            variance: -0.5099999999999882,
          },
          in_stock: { abs: 99.49000000000001, variance: -0.5099999999999909 },
        },
      },
      {
        table_key: "36 - 44.9 LBS",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "4 - 7.9 LBS",
        be_available: {
          items_listed: { abs: 2.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "6.0 - 9.9 LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "SNGL SRV 56.0-100.9G",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "NUTRO MAX",
    be_available: {
      items_listed: { abs: 11.964300000000001, variance: -0.29749999999998755 },
      in_stock: { abs: 52.01869565217392, variance: -0.15521739130434042 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "14 - 19.9 LBS",
        be_available: {
          items_listed: { abs: 5.964300000000001, variance: -0.59499999999999 },
          in_stock: { abs: 99.40500000000002, variance: -0.5949999999999847 },
        },
      },
      {
        table_key: "20 - 26.9 LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "27 - 35.9 LBS",
        be_available: {
          items_listed: { abs: 3.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "4 - 7.9 LBS",
        be_available: {
          items_listed: { abs: 4.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "8 - 9.9 LBS",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        be_available: {
          items_listed: { abs: 9.0, variance: 0.0 },
          in_stock: { abs: 47.36842105263158, variance: 0.0 },
        },
      },
    ],
    table_key: "NUTRO ULTRA",
    be_available: {
      items_listed: { abs: 22.9643, variance: -0.15521739130434134 },
      in_stock: { abs: 67.54205882352942, variance: -0.10499999999998977 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "10.0 - 12.9 LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "2.0 - 5.9 LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "CAN LRG 11.0-19.9OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "SNGL SRV 56.0-100.9G",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "TRAY/TUB SML (0-4.9OZ)",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "NUTRO WILD FRONTIER",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "14 - 19.9 LBS",
        be_available: {
          items_listed: { abs: 2.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "36 - 44.9 LBS",
        be_available: {
          items_listed: { abs: 2.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "45+ LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "PEDIGREE ADULT",
    be_available: {
      items_listed: { abs: 4.0, variance: 0.0 },
      in_stock: { abs: 80.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CAN LRG 11.0-19.9OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: -100.0 },
          in_stock: { abs: 0.0, variance: -92.86 },
        },
      },
    ],
    table_key: "PEDIGREE AO SUBBR",
    be_available: {
      items_listed: { abs: 0.0, variance: -100.0 },
      in_stock: { abs: 0.0, variance: -92.86 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "36 - 44.9 LBS",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "45+ LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "PEDIGREE BIG DOG",
    be_available: {
      items_listed: { abs: 1.0, variance: 0.0 },
      in_stock: { abs: 50.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CAN JUMBO 20.0+OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "CAN LRG 11.0-19.9OZ",
        be_available: {
          items_listed: { abs: 6.0, variance: 0.0 },
          in_stock: { abs: 54.54545454545455, variance: 0.0 },
        },
      },
      {
        table_key: "PCH SML 0-8.9OZ",
        be_available: {
          items_listed: { abs: 2.0, variance: 0.0 },
          in_stock: { abs: 66.66666666666667, variance: 0.0 },
        },
      },
    ],
    table_key: "PEDIGREE CHOICE CUTS",
    be_available: {
      items_listed: { abs: 8.0, variance: 0.0 },
      in_stock: { abs: 44.44444444444444, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CAN JUMBO 20.0+OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "CAN LRG 11.0-19.9OZ",
        be_available: {
          items_listed: { abs: 10.0, variance: 0.0 },
          in_stock: { abs: 76.92307692307692, variance: 0.0 },
        },
      },
      {
        table_key: "PCH SML 0-8.9OZ",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
    ],
    table_key: "PEDIGREE GROUND DINNER",
    be_available: {
      items_listed: { abs: 11.0, variance: 0.0 },
      in_stock: { abs: 64.70588235294117, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "14 - 19.9 LBS",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "20 - 26.9 LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "36 - 44.9 LBS",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "45+ LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "CAN LRG 11.0-19.9OZ",
        be_available: {
          items_listed: { abs: 6.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "PCH SML 0-8.9OZ",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
    ],
    table_key: "PEDIGREE HIGH PROTEIN",
    be_available: {
      items_listed: { abs: 9.0, variance: 0.0 },
      in_stock: { abs: 81.81818181818181, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CAN LRG 11.0-19.9OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "PEDIGREE HOMESTYLE MEALS",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "CAN LRG 11.0-19.9OZ",
        be_available: {
          items_listed: { abs: 2.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "PCH SML 0-8.9OZ",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
    ],
    table_key: "PEDIGREE LIFESTAGE",
    be_available: {
      items_listed: { abs: 3.0, variance: 0.0 },
      in_stock: { abs: 100.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "BISCUIT 96.0+OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "PEDIGREE MARROBONE",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "0 - 3.9 LBS",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "14 - 19.9 LBS",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 50.0, variance: 0.0 },
        },
      },
      {
        table_key: "27 - 35.9 LBS",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
    ],
    table_key: "PEDIGREE PUPPY",
    be_available: {
      items_listed: { abs: 3.0, variance: 0.0 },
      in_stock: { abs: 75.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "0 - 3.9 LBS",
        be_available: {
          items_listed: { abs: 2.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "14 - 19.9 LBS",
        be_available: {
          items_listed: {
            abs: 2.1786000000000003,
            variance: -31.460391367268603,
          },
          in_stock: { abs: 54.465, variance: -25.0 },
        },
      },
    ],
    table_key: "PEDIGREE SMALL DOG",
    be_available: {
      items_listed: { abs: 4.1786, variance: -19.31023828834048 },
      in_stock: { abs: 69.64333333333333, variance: -16.66666666666667 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "14 - 19.9 LBS",
        be_available: {
          items_listed: { abs: 1.0, variance: -41.66715277372689 },
          in_stock: { abs: 50.0, variance: -35.715 },
        },
      },
    ],
    table_key: "PEDIGREE TENDER BITES ADULT",
    be_available: {
      items_listed: { abs: 1.0, variance: -41.66715277372689 },
      in_stock: { abs: 50.0, variance: -35.715 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "0 - 3.9 LBS",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "CAN LRG 11.0-19.9OZ",
        be_available: {
          items_listed: { abs: 3.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
    ],
    table_key: "PEDIGREE TNDER BITES SMALL DOG",
    be_available: {
      items_listed: { abs: 4.0, variance: 0.0 },
      in_stock: { abs: 100.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "TRIAL 0-2OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "SHEBA BROTHS",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "TRIAL 0-2OZ",
        be_available: {
          items_listed: { abs: 2.0, variance: 0.0 },
          in_stock: { abs: 66.66666666666667, variance: 0.0 },
        },
      },
    ],
    table_key: "SHEBA MEATY TENDER STICKS",
    be_available: {
      items_listed: { abs: 2.0, variance: 0.0 },
      in_stock: { abs: 66.66666666666667, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "SNGL SRV 56.0-100.9G",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "SHEBA PERECT PORTIONS GRDN MDL",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "SNGL SRV 56.0-100.9G",
        be_available: {
          items_listed: { abs: 20.0, variance: 0.0 },
          in_stock: { abs: 62.5, variance: 0.0 },
        },
      },
    ],
    table_key: "SHEBA PERFECT PORTIONS",
    be_available: {
      items_listed: { abs: 20.0, variance: 0.0 },
      in_stock: { abs: 62.5, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "SNGL SRV 56.0-100.9G",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 25.0, variance: 0.0 },
        },
      },
    ],
    table_key: "SHEBA PERFECT PORTIONS BISTRO",
    be_available: {
      items_listed: { abs: 1.0, variance: 0.0 },
      in_stock: { abs: 25.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "MEGA 4.1-9OZ",
        be_available: {
          items_listed: { abs: 6.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "REG 2.1-4OZ",
        be_available: {
          items_listed: {
            abs: 10.964300000000001,
            variance: -0.32454545454544403,
          },
          in_stock: { abs: 99.67545454545456, variance: -0.3245454545454436 },
        },
      },
      {
        table_key: "SUPER VALUE 21.1+",
        be_available: {
          items_listed: { abs: 4.9643, variance: -0.7140000000000035 },
          in_stock: { abs: 99.286, variance: -0.7139999999999986 },
        },
      },
      {
        table_key: "TRIAL 0-2OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "VALUE SIZE 13.1-21OZ",
        be_available: {
          items_listed: { abs: 6.8929, variance: -1.529999999999987 },
          in_stock: { abs: 76.58777777777777, variance: -1.1899999999999977 },
        },
      },
    ],
    table_key: "TEMPTATIONS CORE",
    be_available: {
      items_listed: { abs: 28.8215, variance: -0.6155172413793092 },
      in_stock: { abs: 87.3378787878788, variance: -0.5409090909090821 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "MEGA 4.1-9OZ",
        be_available: {
          items_listed: { abs: 3.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "TRIAL 0-2OZ",
        be_available: {
          items_listed: { abs: 3.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
    ],
    table_key: "TEMPTATIONS CREAMY PURRRR-EE",
    be_available: {
      items_listed: { abs: 6.0, variance: 0.0 },
      in_stock: { abs: 100.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "MEGA 4.1-9OZ",
        be_available: {
          items_listed: { abs: 1.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "REG 2.1-4OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "TEMPTATIONS FUNCTIONALS",
    be_available: {
      items_listed: { abs: 1.0, variance: 0.0 },
      in_stock: { abs: 50.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "REG 2.1-4OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "TEMPTATIONS JUMBO STUFF",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "TRIAL 0-2OZ",
        be_available: {
          items_listed: { abs: 3.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
    ],
    table_key: "TEMPTATIONS MEATY BITES",
    be_available: {
      items_listed: { abs: 3.0, variance: 0.0 },
      in_stock: { abs: 100.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "MEGA 4.1-9OZ",
        be_available: {
          items_listed: { abs: 3.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "SUPER VALUE 21.1+",
        be_available: {
          items_listed: { abs: 3.0, variance: 0.0 },
          in_stock: { abs: 100.0, variance: 0.0 },
        },
      },
      {
        table_key: "VALUE SIZE 13.1-21OZ",
        be_available: {
          items_listed: { abs: 3.0, variance: 0.0 },
          in_stock: { abs: 75.0, variance: 0.0 },
        },
      },
    ],
    table_key: "TEMPTATIONS MIX UPS",
    be_available: {
      items_listed: { abs: 9.0, variance: 0.0 },
      in_stock: { abs: 90.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "TRIAL 0-2OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "TEMPTATIONS SNACKY MOUSE",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
  {
    table_hierarchy_data: [
      {
        table_key: "20 - 26.9 LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "4 - 7.9 LBS",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "CAN LRG 11.0-19.9OZ",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
      {
        table_key: "SNGL SRV 56.0-100.9G",
        be_available: {
          items_listed: { abs: 0.0, variance: 0.0 },
          in_stock: { abs: 0.0, variance: 0.0 },
        },
      },
    ],
    table_key: "WILD FRONTIER",
    be_available: {
      items_listed: { abs: 0.0, variance: 0.0 },
      in_stock: { abs: 0.0, variance: 0.0 },
    },
  },
];
export const ecomCompititionData = [
  {
    title: "Internal",
    key: "Internal",
    brand_data: [
      {
        title: "Mars",
        dollar_share_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        dollar_share_retailer: {
          abs: 12,
          variance: 10.0,
        },
        dollar_sales_total_ecom: {
          abs: 110000,
          variance: 10.0,
        },
        dollar_sales_retailer: {
          abs: 110000,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        number_items_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_reviews: {
          abs: 12,
          variance: 10.0,
        },
        number_items_rate: {
          abs: 12,
          variance: 10.0,
        },
      },
      {
        title: "Greenies",
        dollar_share_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        dollar_share_retailer: {
          abs: 12,
          variance: 10.0,
        },
        dollar_sales_total_ecom: {
          abs: 110000,
          variance: 10.0,
        },
        dollar_sales_retailer: {
          abs: 110000,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        number_items_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_reviews: {
          abs: 12,
          variance: 10.0,
        },
        number_items_rate: {
          abs: 12,
          variance: 10.0,
        },
      },
    ],
  },
  {
    title: "Category Benchmark",
    brand_data: [
      {
        title: "Nutro",
        dollar_share_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        dollar_share_retailer: {
          abs: 12,
          variance: 10.0,
        },
        dollar_sales_total_ecom: {
          abs: 110000,
          variance: 10.0,
        },
        dollar_sales_retailer: {
          abs: 110000,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        number_items_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_reviews: {
          abs: 12,
          variance: 10.0,
        },
        number_items_rate: {
          abs: 12,
          variance: 10.0,
        },
      },
      {
        title: "Cesar",
        dollar_share_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        dollar_share_retailer: {
          abs: 12,
          variance: 10.0,
        },
        dollar_sales_total_ecom: {
          abs: 110000,
          variance: 10.0,
        },
        dollar_sales_retailer: {
          abs: 110000,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        number_items_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_reviews: {
          abs: 12,
          variance: 10.0,
        },
        number_items_rate: {
          abs: 12,
          variance: 10.0,
        },
      },
    ],
  },
  {
    title: "Competition Benchmark",
    brand_data: [
      {
        title: "Crave",
        dollar_share_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        dollar_share_retailer: {
          abs: 12,
          variance: 10.0,
        },
        dollar_sales_total_ecom: {
          abs: 110000,
          variance: 10.0,
        },
        dollar_sales_retailer: {
          abs: 110000,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        number_items_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_reviews: {
          abs: 12,
          variance: 10.0,
        },
        number_items_rate: {
          abs: 12,
          variance: 10.0,
        },
      },
      {
        title: "IAMS",
        dollar_share_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        dollar_share_retailer: {
          abs: 12,
          variance: 10.0,
        },
        dollar_sales_total_ecom: {
          abs: 110000,
          variance: 10.0,
        },
        dollar_sales_retailer: {
          abs: 110000,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        number_items_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_reviews: {
          abs: 12,
          variance: 10.0,
        },
        number_items_rate: {
          abs: 12,
          variance: 10.0,
        },
      },
    ],
  },
];
export const subBradnData = [
  {
    title: "Original",
    value: "Original",
    key: "Original",
    children: [],
  },
  {
    title: "Pill Pocket",
    value: "Pill Pocket",
    key: "Pill Pocket",
    children: [],
  },
  {
    title: "Denatal",
    value: "Denatal",
    key: "Denatal",
    children: [],
  },
];
export const categoryData = [
  {
    title: "Category",
    value: "Category",
    key: "Category",
    checkable: false,
    children: [
      {
        title: "Dry Dog",
        value: "Dry Dog",
        key: "Dry Dog",
      },
      {
        title: "Wet Dog",
        value: "Wet Dog",
        key: "Wet Dog",
      },
      {
        title: "Dry Cat",
        value: "Dry Cat",
        key: "Dry Cat",
      },
    ],
  },
  {
    title: "Category + Feeding Philosophy",
    value: "Category + Feeding Philosophy",
    key: "Category + Feeding Philosophy",
    checkable: false,
    children: [
      {
        title: "Dry Dog Oral Cat",
        value: "Dry Dog Oral Cat",
        key: "Dry Dog Oral Cat",
      },
    ],
  },
];

export const compititionData = [
  {
    title: "Mars",
    value: "Mars",
    key: "Mars",
    checkable: false,
    children: [
      {
        title: "Temptation",
        value: "Temptation",
        key: "Temptation",
        children: [
          {
            title: "Temptations Core",
            value: "Temptations Core",
            key: "Temptations Core",
          },
          {
            title: "Temptations Mix ups",
            value: "Temptations Mix ups",
            key: "Temptations Mix ups",
          },
          {
            title: "Temptations Creamy",
            value: "Temptations Creamy",
            key: "Temptations Creamy",
          },
        ],
      },
      {
        title: "Pedigree",
        value: "Pedigree",
        key: "Pedigree",
      },
      {
        title: "Sheba",
        value: "Sheba",
        key: "Sheba",
      },
    ],
  },
  {
    title: "Compitition",
    value: "Compitition",
    key: "Compitition",
    checkable: false,
    children: [
      {
        title: "Milk Bone",
        value: "Milk Bone",
        key: "Milk Bone",
        children: [
          {
            title: "Wonderbones",
            value: "Wonderbones",
            key: "Wonderbones",
          },
          {
            title: "Soft & Chewy",
            value: "Soft & Chewy",
            key: "Soft & Chewy",
          },
          {
            title: "Maro Snacks",
            value: "Maro Snacks",
            key: "Maro Snacks",
          },
        ],
      },
    ],
  },
];

export const kpiData = [
  {
    title: "Basic KPIs",
    value: "Basic KPIs",
    key: "Basic KPIs",
    checkable: false,
    selectable: false,
    children: [
      {
        title: "$ Share",
        value: "$ Share",
        key: "$ Share",
      },
      {
        title: "$ Sales",
        value: "$ Sales",
        key: "$ Sales",
      },
      {
        title: "Unit Sales",
        value: "Unit Sales",
        key: "Unit Sales",
      },
      // {
      //   title: "Eq Lbs",
      //   value: "Eq Lbs",
      //   key: "Eq Lbs",
      // },
      // {
      //   title: "Eq Lbs Share",
      //   value: "Eq Lbs Share",
      //   key: "Eq Lbs Share",
      // },
    ],
  },
  {
    title: "Be Available",
    value: "Be Available",
    key: "Be Available",
    checkable: false,
    selectable: false,
    children: [
      {
        title: "In Stock %",
        value: "In Stock %",
        key: "In Stock %",
      },
      {
        title: "# of Items Listed",
        value: "# of Items Listed",
        key: "# of Items Listed",
      },
    ],
  },
  {
    title: "Be Visible",
    value: "Be Visible",
    key: "Be Visible",
    checkable: false,
    selectable: false,
    children: [
      {
        title: "Share of Shelf",
        value: "Share of Shelf",
        key: "Share of Shelf",
      },
    ],
  },
  // {
  //   title: "Be Selected",
  //   value: "Be Selected",
  //   key: "Be Selected",
  //   checkable: false,
  //   children: [
  //     // {
  //     //   title: "Average Ratings",
  //     //   value: "Average Ratings",
  //     //   key: "Average Ratings",
  //     // },
  //     // {
  //     //   title: "Average # of Reviews",
  //     //   value: "Average # of Reviews",
  //     //   key: "Average # of Reviews",
  //     // },
  //   ],
  // },
];

const data = [
  {
    $_Share: 10,
    $_Sales: 1000,
    kpi_result: "P6W4",
  },
  {
    $_Share: 17,
    $_Sales: 1500,
    kpi_result: "P7W1",
  },
  {
    $_Share: 12,
    kpi_result: "P7W2",
    $_Sales: 1200,
  },
  {
    $_Share: 20,
    kpi_result: "P7W3",
    $_Sales: 1500,
  },
  {
    $_Share: 15,
    kpi_result: "P7W4",
    $_Sales: 1800,
  },
  {
    $_Share: 17,
    kpi_result: "P8W1",
    $_Sales: 2000,
  },
  {
    $_Share: 20,
    kpi_result: "P8W2",
    $_Sales: 2200,
  },
  {
    $_Share: 15,
    kpi_result: "P8W3",
    $_Sales: 1900,
  },
  {
    $_Share: 18,
    kpi_result: "P8W4",
    $_Sales: 1600,
  },
  {
    $_Share: 19,
    kpi_result: "P9W1",
    $_Sales: 1300,
  },
  {
    $_Share: 21,
    kpi_result: "P9W2",
    $_Sales: 1800,
  },
  {
    $_Share: 17,
    kpi_result: "P9W3",
    $_Sales: 2000,
  },
  {
    $_Share: 20,
    kpi_result: "P9W4",
    $_Sales: 2200,
  },
];
const kpiField = ["kpi_1", "kpi_2", "kpi_3", "kpi_4"];

export const config = (filters) => {
  const percentKpis = ["$ Share", "In Stock %", "Share of Shelf"];
  const isKpi1Percent = percentKpis.includes(filters.kpi1[0]);
  const isKpi2Percent = percentKpis.includes(filters.kpi2[0]);
  return {
    xField: "time",
    yField: ["kpi_1_3", "kpi_2_4"],
    point: {
      shape: "circle",
    },
    yAxis: {
      "kpi_1_3": {
        title: { text: filters.kpi1[0] },
        label: {
          formatter: (val) => isKpi1Percent ? `${formatNumber(val, 0)}%` : `${formatNumber(val, 0)}`,
        },
        min: 0,
      },
      "kpi_2_4": {
        title: { text: filters.kpi2[0] },
        label: {
          formatter: (val) => isKpi2Percent ? `${formatNumber(val, 0)}%` : `${formatNumber(val, 0)}`,
        },
        min: 0,
      },
    },
    xAxis: {
      title: {
        text: "Periods",
      },
    },
    geometryOptions: [
      {
        geometry: "line",
        point: {},
        seriesField: "name",
      },
      {
        geometry: "line",
        point: {},
        seriesField: "name",
      },
    ],
    interactions: [
      {
        type: "custom-marker-interaction",
      },
    ],
    legend: {
      position: "top-left",
      marker: {
        symbol: "square",
      },
      itemName: {
        formatter: (seriesField) => {
          const seriesFieldFormatters = {
            "kpi_1": () => `${filters.default_brand[0] === "ALL BRANDS" ? "MARS" : filters.default_brand[0]} ${filters.kpi1[0]}`,
            "kpi_3": () => `${filters.trend_compare_deep_dive_list[0]} ${filters.kpi1[0]}`,
            "kpi_4": () => `${filters.trend_compare_deep_dive_list[0]} ${filters.kpi2[0]}`,
            "default": () => `${filters.default_brand[0] === "ALL BRANDS" ? "MARS" : filters.default_brand[0]} ${filters.kpi2[0]}`,
          };
          const selectedFormatter = seriesFieldFormatters[seriesField] || seriesFieldFormatters["default"];
          return selectedFormatter();
        },
      },
    },
    tooltip: {
      formatter: (data) => {
        const kpiMapping = {
          [kpiField[0]]: {
            name: filters.kpi1[0], value: isKpi1Percent ? `${formatNumber(data.kpi_1_3, 1)}%` : filters.kpi1[0] === "# of Items Listed" ? formatNumber(data.kpi_1_3, 0) : formatNumber(data.kpi_1_3, 1)
          },
          [kpiField[1]]: {
            name: filters.kpi2[0], value: isKpi2Percent ? `${formatNumber(data.kpi_2_4, 1)}%` : filters.kpi2[0] === "# of Items Listed" ? formatNumber(data.kpi_2_4, 0) : formatNumber(data.kpi_2_4, 1)
          },
          [kpiField[2]]: {
            name: `${filters.trend_compare_deep_dive_list[0]} ${filters.kpi1[0]}`, value: isKpi1Percent ? `${formatNumber(data.kpi_1_3, 1)}%` : filters.kpi1[0] === "# of Items Listed" ? formatNumber(data.kpi_1_3, 0) : formatNumber(data.kpi_1_3, 1)
          },
          [kpiField[3]]: {
            name: `${filters.trend_compare_deep_dive_list[0]} ${filters.kpi2[0]}`, value: isKpi2Percent ? `${formatNumber(data.kpi_2_4, 1)}%` : filters.kpi2[0] === "# of Items Listed" ? formatNumber(data.kpi_2_4, 0) : formatNumber(data.kpi_2_4, 1)
          }
        }
        const mapping = kpiMapping[data.name];
        if (mapping) {
          return { name: ["kpi_1", "kpi_2"].includes(data.name) ? `${filters.default_brand[0] === "ALL BRANDS" ? "MARS" : filters.default_brand[0]} ${mapping.name}` : mapping.name, value: mapping.value };
        }
        return null;
      },
    },
  }
};
