import { cloneDeep } from "lodash"
import { setDeclareAppliedFilters } from "../../utilities/helper"

export const onChange = (checked) => {

}

export const rowKey = 'index'

export const noRowData = [{
  "waterfall_data": [],
  "top_bottom_sku": [{ 'top_sku': [], 'bottom_sku': {} }],
  "sum_fact_dollar": 0,
  "sum_fact_tdp": 0,
  "fact_dollar_ytd": 0,
  "fact_dollar_ytd_ya": 0,
  "sum_fact_percent_csl_worst_case_4w": 0,
  "sum_fact_dollar_acv": 0,
  "sum_fact_percent_growth": 0,
  "index": 1,
  "retailer_data": [],
  "retailer_key": "Total Market",
  "gsv_pie": {
    "sum_value_4w": 0,
    "sum_value_4w_ya": 0,
    "sum_value_ytd": 0,
    "sum_value_ytd_ya": 0,
    "target": 0,
    "percent_growth": 0,
    "percent_growth_ytd": 0,
    "fact_yee": 0
  },
  "nsv_pie": {
    "sum_value_4w": 0,
    "sum_value_4w_ya": 0,
    "sum_value_ytd": 0,
    "sum_value_ytd_ya": 0,
    "target": 0,
    "percent_growth": 0,
    "percent_growth_ytd": 0
  },
  "mac_pie": {
    "sum_value_4w": 0,
    "sum_value_4w_ya": 0,
    "sum_value_ytd": 0,
    "sum_value_ytd_ya": 0,
    "target": 0,
    "percent_growth": 0,
    "percent_growth_ytd": 0
  }
}]

export const getPieData = (ytd_val, target_val, ytd_label) => {
  let retData = []


  if (ytd_val < target_val) {
    retData.push({
      type: `${ytd_label}`,
      value: ytd_val
    })
    retData.push({
      type: 'Remaining',
      value: target_val - ytd_val
    })
    retData.push({
      type: 'Target',
      value: 1
    })
    retData.push({
      type: 'Remaining2',
      value: Math.max(0, 100 - target_val - 1)
    })
    return retData
  }
  else if (ytd_val > target_val) {
    retData.push({
      type: `${ytd_label}`,
      value: target_val
    })
    retData.push({
      type: 'Target',
      value: 1
    })
    retData.push({
      type: `${ytd_label}2`,
      value: ytd_val - target_val
    })
    retData.push({
      type: 'Remaining',
      value: Math.max(0, 100 - ytd_val - 1)
    })
    return retData
  }
  else {
    retData.push({
      type: `${ytd_label}`,
      value: target_val
    })
    retData.push({
      type: 'Target',
      value: 1
    })
    retData.push({
      type: 'Remaining',
      value: Math.max(0, 100 - ytd_val - 1)
    })
    return retData
  }
}

export const getBulletData = (ytd_val, target_val, ytd_label) => {
  return [
    {
      // title: ytd_label,
      ranges: target_val < 0 ? [target_val, 100] : [100],
      measures: [ytd_val],
      target: target_val,
    },
  ]
}

export const dummyKpiData = {
  sum_value_4w: '...',
  sum_value_4w_ya: '...',
  sum_value_yee: '...',
  fact_yee: '...',
  sum_value_ytd: '...',
  sum_value_ytd_ya: '...',
}

// Set the filters applied based on the filters selected on the UI while clicking on Apply button (Special for Conversion usecase)
export const declareAppliedFilters = (inputFilter, filtersData) => {
  const { techs, subTechs, packSize } = filtersData;
  let finalFilter = cloneDeep(inputFilter);

  // finalFilter = setDeclareAppliedFilters(finalFilter, "product_pet", pets);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_technology", techs);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_sub_technology", subTechs);
  if (finalFilter.product_package && packSize) finalFilter = setDeclareAppliedFilters(finalFilter, "product_package", packSize);

  return finalFilter;
};