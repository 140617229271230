import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { isEqual, orderBy } from 'lodash';
import Wrapper from "../../components/wrapper";
import { Col, Layout, Row, Form, Button, Select, Menu, Dropdown, Tabs, Table, Switch, Radio, TreeSelect, Empty, Rate, Checkbox, Collapse, Popover } from "antd";
import Loader from "../../components/loader";
import { DownloadOutlined, PlusCircleOutlined, LeftOutlined, RightOutlined, CaretUpFilled, CaretDownFilled, DashOutlined, LineOutlined, SortAscendingOutlined, SortDescendingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import './innovation.scss';
import { Line, Bar, Column } from '@ant-design/plots';
import { useDispatch, useSelector } from 'react-redux';
import { getBenchmark, getFilterData, getTableData, setAppliedFilters, setFilters, getChartData, getTrendData, getReviewData, setSkuList, setRetailerList, getLatestTimeYear, getDrainersData, setChartPreview, setLegendName } from '../../slicers/innovation-tracker.slicer';
import { isPackSize } from '../volume-shifting';
import { declareAppliedFilters, KPICard, rowKey } from './itUIHelper';
import columns from "./itColumns";
import { arraySearch, config4, config5, data2, formatNumber, openNotificationWithIcon, symbols } from '../../utilities/helper';
import CustomTable from "../../components/customTable";
import { pptExportInnovation } from './innovation-ppt';
import NewReportForm from '../../components/newReportForm';
import driversChart from '../../assets/images/driversChart.png';
import { MoreOutlined } from '@ant-design/icons';
import { exportConsumerReview, exportGsvDemandChart, exportInnoKPIs, exportInnovationTracker, exportlaunchperfTable, exportLaunchPerfTrend } from '../../utilities/exports/excel';
import { appInsights } from "../../utilities/appInsights";
import { itConstants } from '../../utilities/constants';


const { Content } = Layout;
const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;

const { TabPane } = Tabs;

/**
 * InnovationTracker
 * It manages displaying of InnovationTracker with different tabs and functionalities.
 */

export function InnovationTracker(props) {
  const dispatch = useDispatch();
  const [filterChanged, setFilterChanged] = useState(false);
  const { innovationTracker } = useSelector((state) => state.refreshDate);
  const { defaultBrand } = useSelector((state) => state.profile)
  const [selectedBrand, setSelectedBrand] = useState(defaultBrand);
  const { loading, pets, techs,subTechs,lifestage, packSize, innovationTypes, years, latestTimeYear, innovation, kpi, benchmark, defaultBenchmark, filters, appliedFilters, cardData, tableData, tableLaunchYear, trendData, gsvChart, skuList, retailerList, reviewFilterBy, retailerImage, reviewData, drainersData, chartPreview, legendName } = useSelector((state) => state.innovationTracker)
  const [isRevenueData, setIsRevenueData] = useState(false);
  const [components, setComponents] = useState([]);
  const [benchmarks, setBenchmarks] = useState([]);
  const [newReport, setNewReport] = useState(false);
  const [createReport, setCreateReport] = useState(true);
  const [newReportComponents, setNewReportComponents] = useState([]);
  const [openSaveReportDialog, setOpenSaveReportDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState([]);
  const [innovationTab, setInnovationTab] = useState([]);
  const [innovationLoaded, setInnovationLoaded] = useState(false);
  const [selectedInnovation, setSelectedInnovation] = useState("");
  const [selectedRetailer, setSelectedRetailer] = useState("");
  const [selectedDefaultBrand, setSelectedDefaultBrand] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [sortLoading, setSortLoading] = useState(false);
  const [localFilter, setLocalFilter] = useState({
    view: ["innovation_tracker"],
    product_pet: [],
    product_technology: [],
    product_package: [],
    innovation_type: [],
    time_year: latestTimeYear,
    innovation: [],
    default_brand: ["NUTRO"],
    selected_innovation: [],
    selected_retailer: [],
    launch_as_benchmark: [1],
    output_line: ['I'],
    dropdown_values: ['I'],
    benchmark_values: [],
    trendchart_kpi: [],
    consumer_tab: ["A"],
    selected_sku: [],
    customer_retailer_details: [],
    selected_retailer_key: [-1],
    filter_by: [],
    sub_filter_by: [],
    sub_sub_filter_by: [],
  });
  const [benchmarkValues, setBenchmarkValues] = useState([]);
  const [retailerValues, setRetailerValues] = useState([]);
  const [reportApply, setReportApply] = useState(false);
  // const [chartPreview, setChartPreview] = useState([]);
  const [legendPreview, setLegendPreview] = useState(false)
  // const [legendName, setLegendName] = useState({
  //   name: '', isExist: false})
  const changeSelectedBrand = () => setSelectedBrand(defaultBrand);

  const exportKPIs = () => exportInnoKPIs(cardData, "Innovation_Tracker_KPI_Layer", appliedFilters, localFilter);
  const exportDataTable = () => exportlaunchperfTable(columns.itColsRevenue, columns.itColsDistribution, tableData, "Launch Performance via Retailers", appliedFilters, isRevenueData, tableLaunchYear, localFilter);
  const exportLaunchPerf = () => exportLaunchPerfTrend(trendData, "Launch_Performance_Trend", appliedFilters, localFilter, selectedRow);
  const exportGsvChart = () => exportGsvDemandChart(gsvChart, "Innovation_GSV_Actual_vs_Demand_Forecast", appliedFilters);

  const isReport = props.isReport
  const isCompareRetailer = localFilter.consumer_tab[0] === "C";
  const ratings_grid = reviewData?.ratings?.ratings_grid;
  const isTooltipC = ratings_grid && ratings_grid.length > 0 && ratings_grid[0].tool_tip;
  const menu = (exportData) => (
    <Menu>
      <Menu.Item key="0" onClick={exportData}>Export Data</Menu.Item>
    </Menu>
  );
  const changeNewReportComponent = (val, e) => {
    let temp = [...newReportComponents];
    if (e.target.checked) {
      temp.push(val)
      setNewReportComponents(temp);
    }
    else {
      let ind = temp.findIndex((i) => i === val)
      if (ind > -1) {
        temp.splice(ind, 1)
        setNewReportComponents(temp)
      }
    }
  }

  const applyFilters = () => {
    setInnovationLoaded(false);
    setInnovationTab(innovation.length === 0 ? [] : appliedFilters.innovation.includes("ALL") ? innovation : appliedFilters.innovation);

    let newFilters = {}
    if (filters.innovation.length === 0 || filters.innovation.length === innovation.length)
      newFilters = { ...filters, selected_innovation: innovation.length === 0 ? [] : [innovation[0]], selected_sku: [], benchmark_values: [] };
    else
      newFilters = { ...filters, selected_innovation: [filters.innovation[0]], selected_sku: [], benchmark_values: [] };

    if (filters.trendchart_kpi.length === 0)
      newFilters = { ...newFilters, trendchart_kpi: [kpi[0]] };


    dispatch(setFilters(newFilters));



    let encodedSearch = encodeURIComponent(JSON.stringify({ ...newFilters, default_brand: [defaultBrand] }))
    if (window.history.pushState) {
      let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + encodedSearch;
      window.history.pushState({ path: newurl }, '', newurl);
    }
    dispatch(getChartData(newFilters))
    if (innovation[0] === selectedInnovation) {
      dispatch(getTableData(newFilters));
      dispatch(getBenchmark(newFilters));
      dispatch(getTrendData(newFilters));
      dispatch(getReviewData(newFilters));
      dispatch(getDrainersData(newFilters))
    }
    dispatch(setAppliedFilters(declareAppliedFilters(newFilters, { pets, techs, subTechs,lifestage, packSize: packSize[newFilters.product_technology[0]], innovationTypes, years, innovation, retailerList })));
    setLocalFilter(newFilters)
    dispatch(setLegendName({ name: '', isExist: false }))
  }


  const [unique_innovation, setInoLegend] = useState([])
  const [legendCount, setLegendCount] = useState(0)
  const [inno_color, setInnoColor] = useState({})
  const [randomColor, setRandomColor] = useState([])

  useEffect(() => {
    let temp = new URL(window.location.href)
    if (temp.search.length > 1) {
      try {
        let preFilters = JSON.parse(decodeURIComponent(temp.search.substring(1)))
        let currentFilter = { ...filters }
        for (const [k, v] of Object.entries(preFilters)) {
          if (isReport) {
            if (k === 'innovation_years') {
              currentFilter = {
                ...currentFilter,
                time_year: [...v]
              }
            }
            else if (k === "time_year") {
              //skip
            }
          }
          if (k in currentFilter) {
            currentFilter = {
              ...currentFilter,
              [k]: [...v]
            }
          }
        }

        dispatch(setAppliedFilters(declareAppliedFilters(currentFilter, { pets, techs,subTechs,lifestage, packSize: packSize[currentFilter.product_technology[0]], innovationTypes, years, innovation, retailerList })));
        currentFilter = { ...currentFilter, default_brand: [defaultBrand], selected_retailer: ['Total Market'] }

        dispatch(setFilters(currentFilter))
        setLocalFilter(currentFilter)
        setComponents(preFilters.innovation_components)

        setSelectedInnovation(currentFilter.selected_innovation.length === 0 ? "" : currentFilter.selected_innovation.length[0])
        // if (!isReport || preFilters.innovation_components.length) {
        //   if (currentFilter.selected_innovation.length
        //     && currentFilter.time_year.length && (currentFilter.selected_innovation[0] !== selectedInnovation
        //       || selectedDefaultBrand !== defaultBrand || reportApply !== props.reportApply)) {
        //     setSelectedInnovation(currentFilter.selected_innovation[0]);
        //     setSelectedDefaultBrand(defaultBrand);
        //     dispatch(getTableData(currentFilter));
        //     dispatch(getReviewData(currentFilter));
        //     dispatch(getChartData(currentFilter));
        //   }
        //   if (currentFilter.selected_innovation.length
        //     && currentFilter.trendchart_kpi.length
        //     && currentFilter.selected_retailer.length
        //     && currentFilter.time_year.length && (currentFilter.selected_retailer[0] !== selectedRetailer
        //       || currentFilter.selected_innovation[0] !== selectedInnovation
        //       || selectedDefaultBrand !== defaultBrand || reportApply !== props.reportApply)) {
        //     setReportApply(props.reportApply)
        //     setSelectedRetailer(currentFilter.selected_retailer[0]);
        //     dispatch(getBenchmark(currentFilter));
        //     dispatch(getTrendData(currentFilter));
        //     dispatch(getDrainersData(currentFilter));
        //   }
        // }
        const isBrandChanged = defaultBrand !== selectedBrand;

        if ((reportApply === props.reportApply || !isReport)) dispatch(getFilterData({ time_year: currentFilter.time_year }, false, true, setLocalFilter,isBrandChanged, changeSelectedBrand));

      }
      catch (e) {

        if (latestTimeYear.length === 0) dispatch(getFilterData({}, true));
        if (latestTimeYear.length) dispatch(getFilterData({ time_year: latestTimeYear }));
        if (latestTimeYear.length && filters.time_year.length === 0) {
          dispatch(getChartData({ ...filters, default_brand: [defaultBrand], time_year: latestTimeYear }))
          dispatch(setFilters({ ...filters, default_brand: [defaultBrand], time_year: latestTimeYear }))
          const newAppliedFilters = { ...appliedFilters, time_year: latestTimeYear };
          dispatch(setAppliedFilters(declareAppliedFilters(newAppliedFilters, { pets, techs,subTechs, lifestage, packSize: packSize[newAppliedFilters.product_technology[0]], innovationTypes, years, innovation, retailerList })));
          setLocalFilter({ ...filters, default_brand: [defaultBrand], time_year: latestTimeYear })
        }
      }
    }
    else {
      if (latestTimeYear.length === 0) dispatch(getFilterData({}, true));
      if (latestTimeYear.length) dispatch(getFilterData({ time_year: latestTimeYear }));

      if (latestTimeYear.length) {
        dispatch(getChartData({ ...filters, default_brand: [defaultBrand], time_year: latestTimeYear }))
      }
      if (latestTimeYear.length && filters.time_year.length === 0) {
        dispatch(setFilters({ ...filters, default_brand: [defaultBrand], time_year: latestTimeYear }))
        const newAppliedFilters = { ...appliedFilters, time_year: latestTimeYear };
        dispatch(setAppliedFilters(declareAppliedFilters(newAppliedFilters, { pets, techs,subTechs,lifestage,packSize: packSize[newAppliedFilters.product_technology[0]], innovationTypes, years, innovation, retailerList })));
        setLocalFilter({ ...filters, default_brand: [defaultBrand], time_year: latestTimeYear })
      }

    }
    dispatch(setLegendName({ name: '', isExist: false }))
  }, [latestTimeYear, defaultBrand, props.reportApply])



  useEffect(() => {
    if (gsvChart?.length > 0) {
      const innovation_color = gsvChart.map((item, index) => item.innovation.split("_")[1])
      let unique = [...new Set(innovation_color)]
      setInoLegend([...unique])

      for (let index in unique) {
        const color_random = "#" + ("00000" + Math.floor(Math.random() * Math.pow(16, 6)).toString(16)).slice(-6);
        setInnoColor((inno_color) => ({ ...inno_color, [unique[index]]: color_random }))
      }
    }

    setLegendCount(0)
  }, [gsvChart])
  const handleLegend = (data) => {
    if (data !== legendName.name) {
      dispatch(setLegendName({ name: data, isExist: true }))
    } else {
      dispatch(setLegendName({ name: '', isExist: false }))
    }
    // const legendData = gsvChart.filter((i)=>i.innovation.split("_")[1] === data).map((item)=>{
    //   return item
    // })
    // setChartPreview(legendData)
    // setLegendPreview(true)
  }
  useEffect(() => {
    if (legendName.name !== "") {
      const legendData = gsvChart.filter((i) => i.innovation.split("_")[1] === legendName.name).map((item) => {
        return item
      })
      dispatch(setChartPreview(legendData))
    }
  }, [legendName.name])

  const configOne = {
    data: legendName.isExist ? chartPreview : gsvChart && gsvChart.length ? gsvChart : [],
    xField: "period",
    yField: "value",
    seriesField: "innovation",
    yAxis: {
      title: {
        text: 'GSV'
      },
      label: {
        formatter: (data) => {
          return formatNumber(data, 0).replace(',', '')
        }
      }
    },
    color: ({ innovation }) => {

      return inno_color[innovation.split("_")[1]];
    },
    tooltip: {
      formatter: (data) => {
        if (appliedFilters.innovation.length === 1 && appliedFilters.innovation[0] !== 'ALL') {
          return { name: data.innovation, value: formatNumber(data.value, 1).replace(',', '') };
        } else {
          return { name: data.innovation.split("_")[1], value: formatNumber(data.value, 1).replace(',', '') };
        }
      },

      shared: appliedFilters.innovation.length === 1 && appliedFilters.innovation[0] !== 'ALL' ? true : false,
    },
    lineStyle: ({ innovation }) => {
      if (innovation.split("_")[0] === "FORECAST") {
        return {
          lineDash: [4, 4],
          opacity: 1,
        };
      }
      return {
        opacity: 1,
      };
    },
    legend: false,
    // legend: {
    //   layout: "horizontal",
    //   position: "top",
    //   maxRow: 7,
    //   PageNavigatorMarkerStyle: {
    //     size: 2
    //   },
    //   flipPage: false,
    //   itemName: {
    //     formatter: (data)=>{

    //       return data.split("_")[1]
    //       // text: '',
    //       // item: data && data.innovation?.split("_")[1]
    //     }
    //   },
    //   // items: {
    //   //   LegendItem: {

    //   //   }
    //   // }
    // //   items: {
    // //   LegendItemName: {
    // //     formatter: ({innovation})=>({
    // //       text: innovation.split("_")[1]
    // //     })
    // //   }
    // // }
    // },
    interactions: [
      // {
      //   type: 'element-selected',
      // },
      {
        type: 'element-highlight',
      },
      // { 
      //   type: 'tooltip',
      //   cfg: { start: [{ trigger: 'element:click', action: 'tooltip:show' }] } ,
      // },
    ],
  };

  useEffect(() => {
    for (let value of trendData) {
      const color_random = "#" + ("00000" + Math.floor(Math.random() * Math.pow(16, 6)).toString(16)).slice(-6);
      setRandomColor((randomColor) => ({ ...randomColor, [value.innovation]: color_random }))
    }
  }, [trendData])

  const configTrend = {
    data: trendData?.length ? trendData : [],
    xField: 'time_week',
    yField: 'kpi_result',
    seriesField: 'innovation',
    annotations: [
      {
        type: "dataMarker",
        position: trendData?.length ? { time_week: "W04" } : ["0%", "0"],
        point: null,
        line: {
          length: "330",
          style: {
            lineDash: [4, 2],
            stroke: "#ff0000"
          }
        }
      },
    ],
    point: {
      shape: 'circle',
    },
    xAxis: {
      label: {
        text: 'Week'
      }
    },
    yAxis: {
      label: {
        text: 'Label'
      }
    },

    color: ({ innovation }) => innovation === "Category Average" ? '#FC8970' : randomColor[innovation],
    tooltip: {
      formatter: (data) => ({
        name: data.innovation,
        value: formatNumber(data.kpi_result)
      }),
    },
  };
  const configBarConsumerReview = {
    data: (ratings_grid ? ratings_grid : []).map((rec) => ({ ...rec, rating_label: rec.rating_label.toString() })),
    xField: 'rating_value',
    yField: 'rating_label',
    color: '#FCD374',
    minBarWidth: 28,
    maxBarWidth: 28,
    autoFit: true,
    legend: false,
    tooltip: {
      showTitle: false,
      formatter: (data) => ({ name: data.rating_label, value: formatNumber(data.rating_value) + (isCompareRetailer ? "" : "%") }),
    },
    label: {
      position: 'left',
      formatter: (data) => formatNumber(data.rating_value) + (isCompareRetailer ? "" : "%"),
    },
  };
  const configDrivers = {
    data: drainersData,
    height: 320,
    xField: 'revenue',
    yField: 'brands',
    seriesField: 'type',
    minBarWidth: 28,
    maxBarWidth: 28,
    label: {
      position: 'right',
      offset: 4,
      style: {
        fill: '#393939'
      },
      formatter: (data) => formatNumber(data.revenue),
    },
    legend: {
      position: 'bottom',
      offsetY: 10
    },
    tooltip: {
      formatter: (data) => ({ name: data.type, value: formatNumber(data.revenue) }),
    },
    color: ({ type }) => {
      return type === 'Positive Impact' ? '#00C853' : '#F06055';
    }
  };
  if (isCompareRetailer && isTooltipC) {
    configBarConsumerReview.seriesField = 'tool_tip';
    configBarConsumerReview.tooltip = {
      showTitle: false,
      customItems: (originalItems) => originalItems[0].data?.tool_tip?.map((rate) => ({ name: `${formatNumber(rate.split(",")[1])}%`, value: `rated ${rate.split(",")[0]}` })),
    }
  }

  // converting the benchmark data to tree data
  useEffect(() => {
    const benchmarkInStore = JSON.parse(JSON.stringify(benchmark));
    if (Object.keys(benchmarkInStore).length > 0) {
      setBenchmarks(
        Object.keys(benchmarkInStore).map((brand) => ({
          title: brand,
          value: brand,
          children: Object.keys(benchmarkInStore[brand]).map((innovation) => ({
            title: innovation,
            value: `${brand}:${innovation}`,
            children: benchmarkInStore[brand][innovation].map((data) => ({
              title: data,
              value: `${brand}:${innovation}:${data}`
            })),
          })),
        }))
      );
    }

  }, [benchmark]);

  useEffect(() => {
    if (!innovation.length || !innovationTab.length) return;
  
    const filterByKeys = Object.keys(reviewFilterBy);
    const firstSubFilterKey = filterByKeys.length ? Object.keys(reviewFilterBy[filterByKeys[0]])[0] : null;
  
    let updatedFilter = {
      ...localFilter,
      trendchart_kpi: kpi.length && !localFilter.trendchart_kpi.length ? [kpi[0]] : localFilter.trendchart_kpi,
      selected_innovation: !localFilter.selected_innovation.length || !innovationTab.includes(localFilter.selected_innovation[0]) || selectedDefaultBrand !== defaultBrand ? [innovationTab[0]] : localFilter.selected_innovation,
      selected_sku: !localFilter.selected_sku.length && skuList.length ? [skuList[0]] : (!skuList.includes(localFilter.selected_sku[0]) ? (skuList.length ? [skuList[0]] : []) : localFilter.selected_sku),
      filter_by: filterByKeys.length ? [filterByKeys[0]] : [],
      sub_filter_by: firstSubFilterKey ? [firstSubFilterKey] : [],
      sub_sub_filter_by: (filterByKeys.length && filterByKeys[0] === "Themes" && firstSubFilterKey)
        ? Object.keys(reviewFilterBy["Themes"][firstSubFilterKey]).slice(0, 1)
        : []
    };
  
    if (!isEqual(localFilter.benchmark_values, defaultBenchmark)) {
      updatedFilter = { ...updatedFilter, benchmark_values: defaultBenchmark };
      setBenchmarkValues(defaultBenchmark);
    }
  
    if (!isEqual(localFilter, updatedFilter)) setLocalFilter(updatedFilter);
  
  }, [innovation, innovationTab, defaultBenchmark, kpi, skuList, reviewFilterBy]);
 
  useEffect(() => {
    if (localFilter.filter_by.length > 0 && reviewFilterBy) {
      const firstSubFilter =
        reviewFilterBy[localFilter.filter_by[0]] &&
        Object.keys(reviewFilterBy[localFilter.filter_by[0]])[0];
  
      if (firstSubFilter) {
        const newFilters = {
          ...localFilter,
          sub_filter_by: [firstSubFilter],
          sub_sub_filter_by: []
        };
        dispatch(setFilters(newFilters));
        setLocalFilter(newFilters); // Update localFilter to ensure consistency
      }
    }
  }, [localFilter.filter_by, reviewFilterBy, dispatch]);
  
  useEffect(() => {
    if (localFilter.filter_by.length > 0 && localFilter.sub_filter_by.length > 0 && reviewFilterBy) {
      const firstSubSubFilter =
        reviewFilterBy[localFilter.filter_by[0]] &&
        reviewFilterBy[localFilter.filter_by[0]][localFilter.sub_filter_by[0]] &&
        Object.keys(reviewFilterBy[localFilter.filter_by[0]][localFilter.sub_filter_by[0]])[0];
  
      if (firstSubSubFilter) {
        const newFilters = {
          ...localFilter,
          sub_sub_filter_by: [firstSubSubFilter]
        };
        dispatch(setFilters(newFilters));
        setLocalFilter(newFilters); // Update localFilter to ensure consistency
      }
    }
  }, [localFilter.sub_filter_by, reviewFilterBy, dispatch]);

  useEffect(() => {


    // if (selectedDefaultBrand !== defaultBrand) {
    //   setSelectedInnovation("");
    // }
    // if (selectedDefaultBrand !== defaultBrand && localFilter.selected_innovation[0] === selectedInnovation && localFilter.time_year.length) {
    //   dispatch(getChartData({ ...localFilter, default_brand: [defaultBrand] }));
    // }
    if (selectedDefaultBrand !== defaultBrand && localFilter.selected_innovation[0] === selectedInnovation) {
      return
    }

    let filterValues = { ...localFilter, default_brand: [defaultBrand] }
    if (selectedDefaultBrand === defaultBrand && localFilter.selected_innovation[0] !== selectedInnovation && filterValues.time_year.length) {
      dispatch(getChartData({ ...filters, default_brand: [defaultBrand] }));
    }
    if (selectedDefaultBrand !== defaultBrand && localFilter.selected_innovation[0] !== selectedInnovation && filterValues.time_year.length) {
      dispatch(getChartData({ ...filters, default_brand: [defaultBrand] }));
    }


    if (filterValues.selected_innovation.length
      && (filterValues.selected_innovation[0] !== selectedInnovation
        || selectedDefaultBrand !== defaultBrand || (reportApply !== props.reportApply && isReport)) && filterValues.time_year.length) {
      setSelectedInnovation(filterValues.selected_innovation[0]);
      dispatch(setSkuList([]));
      dispatch(setRetailerList([]));
      if (skuList.includes(filterValues.selected_sku[0]) && selectedDefaultBrand === defaultBrand) {
        dispatch(getReviewData(filterValues))
      }
      else {
        dispatch(getReviewData({ ...filterValues, selected_sku: [] }))
      }
      dispatch(getTableData(filterValues));
      setSelectedDefaultBrand(defaultBrand);
    }








    if (filterValues.selected_innovation.length
      && filterValues.trendchart_kpi.length
      && filterValues.selected_retailer.length
      && (filterValues.selected_retailer[0] !== selectedRetailer
        || filterValues.selected_innovation[0] !== selectedInnovation
        || selectedDefaultBrand !== defaultBrand || (reportApply !== props.reportApply)) && filterValues.time_year.length) {
      setReportApply(props.reportApply)
      setSelectedRetailer(filterValues.selected_retailer[0]);
      dispatch(getBenchmark(filterValues));
      dispatch(getTrendData({ ...filterValues, benchmark_values: [] }));
      dispatch(getDrainersData(filterValues));
    }
  }, [localFilter.trendchart_kpi, localFilter.selected_innovation, localFilter.selected_retailer, defaultBrand])
  useEffect(() => {
    if (!innovationLoaded) {

      setInnovationTab(innovation.length === 0 ? [] : appliedFilters.innovation.includes("ALL") ? innovation : appliedFilters.innovation);
    }
  }, [innovation, innovationLoaded]);

  useEffect(() => {
    if (tableData.length && tableData[0] && tableData[0][rowKey]) {

      const key = tableData[0][rowKey];
      setLocalFilter({ ...localFilter, selected_innovation: [localFilter.selected_innovation.length > 0 ? localFilter.selected_innovation[0] : []], selected_retailer: [key] });
      setSelectedRowKey([key]);
      setSelectedRow([tableData[0]]);
    }
    else {

      setSelectedRowKey([]);
      setSelectedRow([]);
      // setLocalFilter({ ...filters, selected_retailer: [] });
      // setLocalFilter({ ...filters, selected_innovation: [localFilter.selected_innovation.length > 0 ? localFilter.selected_innovation[0] : []], selected_retailer: [] });

    }
  }, [tableData]);

  const getRemainingFilters = (label) => {
    if (!filterChanged) return

    setFilterChanged(false)
    const isProductPackage = filters.product_technology.length === 1 && isPackSize.includes(filters.product_technology[0]);

    if (label === 'time_year') {
      dispatch(getFilterData({
        'time_year': filters.time_year
      }))
    }
    else if (label === 'product_pet') {
      dispatch(getFilterData({
        'product_pet': filters.product_pet,
        'time_year': filters.time_year
      }))
    }
    else if (label === 'product_technology') {
      dispatch(getFilterData({
        'product_pet': filters.product_pet,
        'product_technology': filters.product_technology,
        'time_year': filters.time_year
      }))
    }
    else if (label === 'product_sub_technology') {
      dispatch(getFilterData({
        'product_pet': filters.product_pet,
        'product_technology': filters.product_technology,
        'product_sub_technology': filters.product_sub_technology,
        'time_year': filters.time_year
      }))
    }
    else if (label === 'product_lifestage') {
      dispatch(getFilterData({
        'product_pet': filters.product_pet,
        'product_technology': filters.product_technology,
        'product_sub_technology': filters.product_sub_technology,
        'product_lifestage':filters.product_lifestage,
        'time_year': filters.time_year
      }))
    }
    else if (label === "product_package") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_package: filters.product_package,
          time_year: filters.time_year
        })
      );
    }
    else if (label === "innovation_type") {
      dispatch(
        getFilterData(isProductPackage ? {
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage:filters.product_lifestage,
          product_package: filters.product_package,
          innovation_type: filters.innovation_type,
          time_year: filters.time_year
        } : {
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage:filters.product_lifestage,
          innovation_type: filters.innovation_type,
          time_year: filters.time_year
        })
      );
    }
  }

  const changeFilter = (label, selectedValue) => {
    setInnovationLoaded(true);
    if (JSON.stringify(filters[label]) !== JSON.stringify(selectedValue)) {
      setFilterChanged(true);
    }
    let newFilters = { ...localFilter }
    if (selectedValue && selectedValue.length && selectedValue.includes("all")) {
      let all = [];
      if (label === 'product_pet') all = pets;
      else if (label === 'product_package' && filters.product_technology.length === 1) all = packSize[filters.product_technology[0]];
      else if (label === 'product_sub_technology') all = subTechs;
      else if (label === 'product_lifestage') all = lifestage;
      else if (label === 'product_technology') all = techs;
      else if (label === 'innovation_type') all = innovationTypes;
      else if (label === "time_year") all = years;
      else if (label === 'innovation') all = innovation;
      else if (label === 'customer_retailer_details') all = retailerList;
      if (selectedValue.length === all.length + 1) {
        selectedValue = [];
      }
      else selectedValue = [...all];
    }

    if (label === "product_technology" && !isPackSize.includes(selectedValue)) newFilters["product_package"] = []
    newFilters[label] = selectedValue
    dispatch(setFilters(newFilters))
    setLocalFilter(newFilters)
    return newFilters;
  }

  const onTabChange = (key) => {
    const selected_innovation = appliedFilters.innovation.includes("ALL")
      ? innovationTab[key]
      : appliedFilters.innovation[key];
    changeFilter("selected_innovation", [selected_innovation]);
    const newFilters = {
      ...localFilter,
      selected_innovation: !selected_innovation || selected_innovation === null ? [] : [selected_innovation],
      selected_sku: []
    };
    setLocalFilter(newFilters);
  };

  const getActiveTabKey = () => {

    if (localFilter.selected_innovation.length === 0) return "0";

    let activeTabKey = (
      appliedFilters.innovation.includes("ALL")
        ? innovationTab.findIndex(
          (innovationName) => innovationName === localFilter.selected_innovation[0]
        )
        : appliedFilters.innovation.findIndex(
          (innovationName) => innovationName === localFilter.selected_innovation[0]
        )
    ).toString();


    return activeTabKey
  };


  const handleNavigateRetailers = (operation) => {
    if (retailerList.length <= 1) return;

    let key = localFilter.selected_retailer_key[0] + operation;
    if (key > retailerList.length - 1) return;
    if (key < -1) return;

    const newFilter = { ...localFilter, selected_retailer_key: [key], customer_retailer_details: key < 0 ? [] : [retailerList[key]] };
    dispatch(setFilters(newFilter));
    setLocalFilter(newFilter);
    dispatch(getReviewData(newFilter));
  }

  const getSentimentClass = (overall_sentiment) => {
    if (!overall_sentiment) return "";

    const overallSentiment = Array.isArray(overall_sentiment) ? overall_sentiment[1].toLowerCase() : overall_sentiment.toLowerCase();
    if (overallSentiment.includes("positive")) return "positive";
    if (overallSentiment.includes("neutral")) return "neutral";
    if (overallSentiment.includes("negative")) return "negative";
    return "";
  }
  const driversDrainers = () => {
    return (!isReport || components.includes("4")) && (<Row><Col md={24}>
      {newReport && <Checkbox value={newReportComponents.includes('4')} onChange={(e) => changeNewReportComponent('4', e)} className="d-check"></Checkbox>}
      <div className={`innovation-card-wrap innovation-card-bg   ${newReport ? 'trackboard-edit' : ''} ${newReport && newReportComponents.includes('4') ? 'trackboard-selected' : ''}`} style={{ background: "#f9f9f9", marginBottom: 40, justifyContent: "center" }}>
        <div className='innovation-card left-70'>
          <h5 className='innovation-card-title'>Impact vs Target - {selectedRow[0] && selectedRow[0][rowKey]}</h5>
          <div className='revenue-wrap'>
            <div className='revenue'>
              <h5>MKT RSV YEE</h5>
              <div className='revenue-item'>
                <h5>TARGET</h5>
                <p>{selectedRow[0] && `${selectedRow[0]?.fact_rsv_target_yee ? `$${formatNumber(selectedRow[0]?.fact_rsv_target_yee)}` : "NA"}`}</p>
              </div>
              <div className='revenue-item'>
                <h5>ACTUAL</h5>
                <p>{selectedRow[0] && `${selectedRow[0]?.fact_rsv_actuals_yee ? `$${formatNumber(selectedRow[0]?.fact_rsv_actuals_yee)}` : "NA"}`}<span>{selectedRow[0] && `${selectedRow[0]?.er_vs_target ? `${formatNumber(selectedRow[0]?.er_vs_target)}%` : "NA"}`}</span></p>
              </div>
            </div>
            <div className='bar-chart'>
              <Bar {...configDrivers} />
            </div>
          </div>
        </div>
        {/* <div className='innovation-card right-30'>
        <h5 className='innovation-card-title'>Media Spends</h5>
        <DualAxes {...config3} />
      </div> */}
      </div>
    </Col>
    </Row>)
  }
  const volumeSource = () => {
    return (!isReport || components.includes("5")) && (
      <Row style={{ "width": "1300px" }}>
        <Col md={24}>
          <div className={`innovation-card-wrap innovation-card-bg ${newReport ? 'trackboard-edit' : ''} ${newReport && newReportComponents.includes('5') ? 'trackboard-selected' : ''}`} style={{ marginBottom: 40 }}>
            {newReport && <Checkbox value={newReportComponents.includes('5')} onChange={(e) => changeNewReportComponent('5', e)} className="volumSource-check"></Checkbox>}
            <div className='innovation-card left-40'>
              <h5 className='innovation-card-title'>Volume Source</h5>
              <Column {...config4} />
            </div>
            <div className='innovation-card right-60'>
              <h5 className='innovation-card-title'>Share of Shifting Gains</h5>
              <Bar {...config5} />
              {/* <Table className='borderless-table' dataSource={dataSource2} columns={columns2} pagination={false} /> */}
            </div>
          </div>
        </Col>
      </Row>)
  }

  const InnovationTab = (tabName, key) => {
    return (
      <TabPane tab={tabName} key={key}>

        {(!isReport || components.includes("2")) && (<div className={`innovation-card-bg`}>
          <h5 className='innovation-card-title'>Launch Performance: Total Market
            <span>
              <Dropdown overlay={() => menu(exportKPIs)} trigger={['click']} placement="bottomRight" className="d-more">
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ position: 'absolute', right: 48, fontSize: 14 }}>
                  <MoreOutlined />
                </a>
              </Dropdown>
            </span>
          </h5>
          {newReport && <Checkbox value={newReportComponents.includes('2')} onChange={(e) => changeNewReportComponent('2', e)} className="innovation-kpi"></Checkbox>}
          <div className={`iv-five-column innovation-card-bg  ${newReport ? 'trackboard-edit' : ''} ${newReport && newReportComponents.includes('2') ? 'trackboard-selected' : ''}`} style={{ position: 'relative' }}>
            <KPICard title={`MKT RSV YEE (${cardData?.launch_year ? cardData?.launch_year : 'NA'})`} target={cardData.fact_rsv_target_yee} actual={cardData.fact_rsv_actuals_yee} variance={cardData.rsv_yee_variance} symbol={symbols.dollar} />
            <KPICard title={`GSV YEE (${cardData?.launch_year ? cardData?.launch_year : "NA"})`} target={cardData.fact_gsv_target_yee} actual={cardData.fact_gsv_actuals_yee} variance={cardData.gsv_yee_variance} symbol={symbols.dollar} />
            {/* <KPICard title={`MEDIA SPENDS (${cardData?.launch_year ? cardData?.launch_year : "NA"})`} target={cardData.fact_media_spends_target} actual={cardData.fact_media_spends_actuals} variance={cardData.media_variance} symbol={symbols.dollar} />
            <KPICard title={`TDP (${cardData?.launch_year ? cardData?.launch_year : "NA"})`} target={cardData.fact_tdp_target} actual={cardData.fact_tdp_actuals} variance={cardData.tdp_variance} /> */}
          </div></div>)}

        {(!isReport || components.includes("3")) && (<><div className={`innovation-card  ${newReport ? 'trackboard-edit' : ''} ${newReport && newReportComponents.includes('3') ? 'trackboard-selected' : ''}`} style={{ marginBottom: 40 }}>
          <div style={{ position: 'relative', marginBottom: 16 }}>
            {newReport && <Checkbox value={newReportComponents.includes('3')} onChange={(e) => changeNewReportComponent('3', e)} className="d-check" style={{ marginBottom: 10 }}></Checkbox>}
            <h5 className='innovation-card-title'>Launch Performance via Retailers</h5>
            <h6 className='innovation-card-sub-title'>{innovationTab[getActiveTabKey()]} - {itConstants.launchPeriod} - {tableData?.[0]?.time_er_launch_period}</h6>
            <div className="toggle-switch" style={{ position: 'absolute', right: 0, top: 0 }}>
              <span className={!isRevenueData ? 'active' : ''}>Revenue</span>
              <Switch size="small" checked={isRevenueData} onChange={(e) => setIsRevenueData(e)} />
              <span className={isRevenueData ? 'active' : ''}>Distribution</span>
              <Dropdown overlay={() => menu(exportDataTable)} trigger={['click']} placement="bottomRight" className="d-more">
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ marginLeft: '15px' }}>
                  <MoreOutlined />
                </a>
              </Dropdown>
            </div>
          </div>
          <div className="nutro-table">
            <Table
              rowKey={rowKey}
              dataSource={tableData}
              rowSelection={{
                checkStrictly: true,
                selectedRowKeys: selectedRowKey,
                type: 'radio',
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelectedRowKey(selectedRowKeys)
                  setSelectedRow(selectedRows)

                  changeFilter("selected_retailer", selectedRowKeys)
                  const newFilters = { ...localFilter, selected_retailer: selectedRowKeys };
                  setLocalFilter(newFilters);
                },
                hideSelectAll: true
              }}
              columns={isRevenueData ? columns.itColsDistribution : columns.itColsRevenue && columns.itColsRevenue.map((item) => {
                if (item.dataIndex === 'fact_revenue_er' || item.dataIndex === 'fact_revenue_target') {
                  return { ...item, title: `${item.title}(${tableLaunchYear})` }
                } else {
                  return item
                }
              })}
              pagination={false}
            />
          </div>
          {/* {!isRevenueData && <Table className='custom-table' dataSource={tableData} columns={columns.itColsRevenue} pagination={false} />}
            {isRevenueData && <Table style={{ whiteSpace: 'pre' }} className='custom-table' dataSource={tableData} columns={columns.itColsDistribution} pagination={false} />} */}
        </div></>)}

        {/* commented Innovation Incrementality placeholder code as of it's not part of the roadmap at the moment */}

        {/* {!isReport && <Row className="new-launch">
          <p style={{ marginRight: 10 }}>
            <Popover placement="bottom" content={<img src={driversChart} style={{ width: "1300px" }} />} trigger="hover">
              <a style={{
                fontWeight: "bold",
                "text-decoration-line": "underline"
              }}>Innovation Incrementality</a>
            </Popover> for new launches coming soon!</p>
          <Button onClick={() => openNotificationWithIcon('success', 'Coming soon! We will notify you')}>Click here to register interest</Button>

        </Row>} */}
        {drainersData.length > 0 && driversDrainers()}
        {/* {chartPreview && volumeSource()} */}

        {
          (!isReport || components.includes("6")) && (<div className={`innovation-card ${newReport ? 'trackboard-edit' : ''} ${newReport && newReportComponents.includes('6') ? 'trackboard-selected' : ''}`} style={{ marginBottom: 40 }}>
            <div style={{ position: 'relative' }}>
              {newReport && <Checkbox value={newReportComponents.includes('6')} onChange={(e) => changeNewReportComponent('6', e)} className="d-check" style={{ marginBottom: 10 }}></Checkbox>}
              <h5 className='innovation-card-title'>Launch Performance Trend - {selectedRow[0] && selectedRow[0][rowKey]}</h5>
              <div className="toggle-switch" style={{ position: 'absolute', right: 0, top: 0 }}>
                <span className={Boolean(filters.launch_as_benchmark[0]) ? 'active' : ''}>Launches</span>
                <Switch size="small" checked={!Boolean(filters.launch_as_benchmark[0])} onChange={(e) => {
                  changeFilter('launch_as_benchmark', [Number(!e)]);
                  const newFilters = { ...localFilter, launch_as_benchmark: [Number(!e)], output_line: Number(!e) ? ['I'] : ['S'] };
                  setBenchmarkValues([]);
                  setLocalFilter(newFilters);
                  dispatch(getBenchmark(newFilters));
                  dispatch(getTrendData(newFilters));
                }} />
                <span className={!Boolean(filters.launch_as_benchmark[0]) ? 'active' : ''}>SKU</span>
                <Dropdown overlay={() => menu(exportLaunchPerf)} trigger={['click']} placement="bottomRight" className="d-more">
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ marginLeft: '15px' }}>
                    <MoreOutlined />
                  </a>
                </Dropdown>
              </div>
            </div>
            <Row align="middle" style={{ marginBottom: 16 }} gutter={18}>
              <Col span={24}>
                <Form layout="inline">
                  <Row gutter={12}>
                    <Col>
                      <label className="label-block">KPI</label>
                      <Select
                        value={localFilter.trendchart_kpi[0]}
                        onChange={(val) => {
                          changeFilter('trendchart_kpi', [val]);
                          const newFilters = { ...localFilter, trendchart_kpi: [val] };
                          setLocalFilter(newFilters);
                          dispatch(getTrendData(newFilters));
                        }}
                        dropdownMatchSelectWidth={false}
                        placement="bottomLeft"
                        style={{ width: 90 }}
                        maxTagCount="responsive">
                        {kpi.map((val) => (
                          <Option value={val}>{val}</Option>
                        ))}
                      </Select>
                    </Col>
                    <Col>
                      <label className="label-block">Benchmark</label>
                      <TreeSelect
                        placeholder="Please select"
                        treeCheckable
                        showCheckedStrategy={SHOW_PARENT}
                        style={{ width: 250 }}
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        value={localFilter.benchmark_values}
                        treeData={benchmarks}
                        onChange={(val) => {
                          changeFilter("benchmark_values", val)
                          setLocalFilter({
                            ...localFilter, benchmark_values: val,
                            dropdown_values: localFilter.launch_as_benchmark[0] ? ['I'] : ['S'],
                          });
                        }}
                        onMouseLeave={() => {
                          const benchmarkValuesInTree = JSON.parse(JSON.stringify(benchmarkValues));
                          const benchmarkValuesInFilters = JSON.parse(JSON.stringify(localFilter.benchmark_values));
                          if (JSON.stringify(benchmarkValuesInTree.sort()) !== JSON.stringify
                            (benchmarkValuesInFilters.sort())) {
                            setBenchmarkValues(localFilter.benchmark_values);
                            dispatch(getTrendData(localFilter));
                          }
                        }}
                        dropdownMatchSelectWidth={false}
                        maxTagCount="responsive"
                        allowClear
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '-10px' }}>
              {(appliedFilters.product_technology[0] === "ALL" && arraySearch(techs, "MM") || arraySearch(appliedFilters.product_technology, "MM")) && <span style={{ listStyle: 'none' }}><DashOutlined style={{ fontSize: '20px', fontWeight: 'bold', color: '#ff0000', verticalAlign: "middle" }} /> &nbsp; Period @ Scale $25k for MM</span>}
              {(appliedFilters.product_technology[0] === "ALL" && arraySearch(techs, "TREATS") || arraySearch(appliedFilters.product_technology, "TREATS")) && <span style={{ listStyle: 'none', marginLeft: '50px' }}><DashOutlined style={{ fontSize: '20px', fontWeight: 'bold', color: '#ff0000', verticalAlign: "middle" }} /> &nbsp; Period @ Scale $10k for Treats</span>}
            </div>
            <Line {...configTrend} />
          </div>

          )
        }
        {(!isReport || components.includes("7")) && (!Boolean(Object.values(reviewFilterBy).map(val => Object.values(reviewFilterBy).length === 0)) || retailerList && retailerList.length && retailerList.length !== 0) && (<div className={`innovation-card ${newReport ? 'trackboard-edit' : ''} ${newReport && newReportComponents.includes('7') ? 'trackboard-selected' : ''}`} style={{ marginBottom: 60 }}>
          {newReport && <Checkbox value={newReportComponents.includes('7')} onChange={(e) => changeNewReportComponent('7', e)} className="d-check-consumer" style={{ marginBottom: 10 }}></Checkbox>}
          <h5 className='innovation-card-title' > Consumer Reviews</h5>
          <h2 className='review-title'>{selectedInnovation}</h2>

          <div className='custom-radio'>
            <Radio.Group value={localFilter.selected_sku.length > 0 ? localFilter.selected_sku[0] : ""} onChange={(e) => {
              changeFilter("selected_sku", [e.target.value]);
              const newFilters = { ...localFilter, selected_sku: [e.target.value], consumer_tab: ["A"], customer_retailer_details: [], selected_retailer_key: [-1] };
              setLocalFilter(newFilters);
              setRetailerValues([]);
              // dispatch(setRetailerList([]));
              dispatch(getReviewData(newFilters));
            }} buttonStyle="solid">
              {skuList.map((sku) => <Radio.Button value={sku}>{sku}</Radio.Button>)}
            </Radio.Group>
          </div>
          <div className="mb-20">
            <Radio.Group value={localFilter.consumer_tab[0]} onChange={(e) => {
              changeFilter("consumer_tab", [e.target.value]);
              const newFilters = { ...localFilter, consumer_tab: [e.target.value], customer_retailer_details: [], selected_retailer_key: [-1] };
              setLocalFilter(newFilters);
              setRetailerValues([]);
              dispatch(getReviewData(newFilters))
            }}>
              <Radio.Button value="A">All Retailers</Radio.Button>
              <Radio.Button value="B">Best Retailer</Radio.Button>
              <Radio.Button value="W">Worst Retailer</Radio.Button>
              <Radio.Button value="C">Compare Retailers</Radio.Button>
            </Radio.Group>
          </div>

          <div className='reviews-wrap'>
            <div className='rating-wrap'>
              <div className='rating'>
                {localFilter.consumer_tab[0] === "A" && (
                  <div className="rating-head">
                    <LeftOutlined className={localFilter.selected_retailer_key[0] < 0 || retailerList.length === 1 ? "disabled" : ""} onClick={() => handleNavigateRetailers(-1)} />
                    <h5>{retailerList.length === 1 ? retailerList[0] : retailerList.length === 0 || localFilter.selected_retailer_key[0] < 0 ? "All" : retailerList[localFilter.selected_retailer_key[0]]}</h5>
                    <RightOutlined className={localFilter.selected_retailer_key[0] >= retailerList.length - 1 || retailerList.length === 1 ? "disabled" : ""} onClick={() => handleNavigateRetailers(1)} />
                  </div>
                )}
                {localFilter.consumer_tab[0] === "B" && (
                  <div className="rating-head">
                    <h5>{reviewData.best_retailer}</h5>
                  </div>
                )}
                {localFilter.consumer_tab[0] === "W" && (
                  <div className="rating-head">
                    <h5>{reviewData.worst_retailer}</h5>
                  </div>
                )}
                {isCompareRetailer && (
                  <div className="rating-head-c">
                    <label className="label-block">Retailer</label>
                    <Select
                      placeholder="All"
                      mode="multiple"
                      defaultValue={["All"]}
                      value={localFilter.customer_retailer_details}
                      onChange={(val) => {
                        changeFilter("customer_retailer_details", val);
                        let newFilters = {};
                        if (val.includes("all")) newFilters = { ...localFilter, customer_retailer_details: [] }
                        else newFilters = { ...localFilter, customer_retailer_details: val }
                        setLocalFilter(newFilters);
                      }}
                      onBlur={() => {
                        const retailerValuesInDropdown = JSON.parse(JSON.stringify(retailerValues));
                        const retailerValuesInFilters = JSON.parse(JSON.stringify(localFilter.customer_retailer_details));
                        if (JSON.stringify(retailerValuesInDropdown.sort()) !== JSON.stringify(retailerValuesInFilters.sort())) {
                          setRetailerValues(localFilter.customer_retailer_details);
                          dispatch(getReviewData(localFilter));
                        }
                      }}
                      dropdownMatchSelectWidth={false}
                      style={{ width: 130 }}
                      placement="bottomLeft"
                      maxTagCount="responsive"
                    >
                      <Option value={'all'}>{'All'}</Option>
                      {retailerList.map((retailer) => (
                        <Option value={retailer}>{retailer}</Option>
                      ))}
                    </Select>
                  </div>
                )}

                <div className='rating-stats'>
                  <div>
                    <label>Overall ratings:</label>
                    <span></span>
                    <span><Rate allowHalf disabled value={reviewData?.ratings?.ratings_summary?.overall_ratings} /></span>
                  </div>
                  <div>
                    <label>Total Reviews:</label>
                    <span>{reviewData?.ratings?.ratings_summary?.total_reviews}</span>
                  </div>
                </div>
                <div className='rating-chart-wrap'>
                  <h5 className='rating-chart-head'>{isCompareRetailer ? "Average Ratings" : "Ratings"}</h5>
                  <Bar {...configBarConsumerReview} />
                </div>
              </div>
              {isCompareRetailer ? <div className='analysis-list'>
                <h4 className='review-section-title'>Sentiment Analysis</h4>
                {reviewData?.sentiment?.map((analysis) => (
                  <div className={`sentiment-box ${getSentimentClass(analysis.overall_sentiment)}`}>
                    <img src={`assets/images/icon-review-${getSentimentClass(analysis.overall_sentiment)}.svg`} alt="smiley" />
                    <h5>{analysis.overall_sentiment[0]} - <span>{analysis.overall_sentiment[1]}</span></h5>
                  </div>
                ))}
                <TopThemes />
              </div> : <div className='analysis'>
                <h4 className='review-section-title'>Sentiment Analysis</h4>
                <div className={`sentiment-box ${getSentimentClass(reviewData?.sentiment && reviewData?.sentiment[0]?.overall_sentiment)}`}>
                  <img src={`assets/images/icon-review-${getSentimentClass(reviewData?.sentiment && reviewData?.sentiment[0]?.overall_sentiment)}.svg`} alt="smiley" />
                  <h5>{reviewData?.sentiment && reviewData?.sentiment[0]?.overall_sentiment}</h5>
                  <p>More than {reviewData?.sentiment && reviewData?.sentiment[0]?.overall_sentiment_count} people have given {getSentimentClass(reviewData?.sentiment && reviewData?.sentiment[0]?.overall_sentiment)} response</p>
                </div>
                <TopThemes />
              </div>}
            </div>
            {/* <div className='traffic'>
                <h4 className='review-section-title'>Top Traffic</h4>
                <div className='top-keywords'>
                  <h5>Top online keywords tags</h5>
                  <ul>
                    <li>Dog treats</li>
                    <li>small training treats</li>
                    <li>little bites</li>
                    <li>Small Pieces</li>
                  </ul>
                </div>
                <Column {...config8} />
              </div> */}
            <div className='traffic'>
              <div className="review-section">
                <h4 className='review-section-title'>Reviews</h4>
                <Button type="link" icon={<DownloadOutlined />} onClick={() => exportConsumerReview(localFilter, sortOrder, "Consumer Review")}>Export</Button>
              </div>
              <div className='filter-box'>
                <div className='filter-inner'>
                  Filter By
                  <Select
                    style={{ marginLeft: 9, 
                    width: localFilter.filter_by[0] === "Themes" ? 90 : 120}}
                    value={localFilter.filter_by[0]}
                    onChange={(val) => {
                      changeFilter('filter_by', [val]);
                      const newFilters = { ...localFilter, filter_by: [val] };
                      if (Object.keys(reviewFilterBy[val]).length > 0)
                        newFilters.sub_filter_by = [Object.keys(reviewFilterBy[val])[0]];
                      else newFilters.sub_filter_by = [];
                      setLocalFilter(newFilters);
                    }}
                    dropdownMatchSelectWidth={false}
                    placement="bottomLeft"
                    maxTagCount="responsive">
                    {Object.keys(reviewFilterBy).map((val) => (
                      <Option value={val}>{val}</Option>
                    ))}
                  </Select>
                </div>
                <div className='filter-inner'>
                  {localFilter.filter_by.length > 0 ? localFilter.filter_by[0] : "Sub Filter By"}
                  <Select
                    style={{ marginLeft: 5, 
                    width: localFilter.filter_by[0] === "Themes" ? 90 : 120}}
                    value={localFilter.sub_filter_by[0]}
                    onChange={(val) => {
                      changeFilter('sub_filter_by', [val]);
                      const newFilters = { ...localFilter, sub_filter_by: [val] };
                      setLocalFilter(newFilters);
                    }}
                    dropdownMatchSelectWidth={false}
                    placement="bottomLeft"
                    maxTagCount="responsive">
                    {reviewFilterBy[localFilter.filter_by[0]] && Object.keys(reviewFilterBy[localFilter.filter_by[0]]).map((val) => (
                      <Option value={val}>{val}</Option>
                    ))}
                  </Select>
                </div>
                {localFilter.filter_by[0] === "Themes" && (<div className="filter-inner">
                        Sub Theme
                        <Select
                          style={{ marginLeft: 5, width: 90 }}
                          dropdownMatchSelectWidth={false}
                          placement="bottomLeft"
                          maxTagCount="responsive"
                          value={localFilter.sub_sub_filter_by[0]}
                          onChange={(val) => {
                            changeFilter("sub_sub_filter_by", [val]);
                            const newFilters = {
                              ...localFilter,
                              sub_sub_filter_by: [val],
                            };
                            setLocalFilter(newFilters);
                          }}
                          defaultValue={
                            localFilter.sub_sub_filter_by[0] ||
                            (reviewFilterBy &&
                            localFilter.filter_by.length > 0 &&
                            reviewFilterBy[localFilter.filter_by[0]] &&
                            localFilter.sub_filter_by.length > 0 &&
                            reviewFilterBy[localFilter.filter_by[0]][localFilter.sub_filter_by[0]] &&
                            Object.keys(reviewFilterBy[localFilter.filter_by[0]][localFilter.sub_filter_by[0]])[0])
                          }
                        >
                          {reviewFilterBy &&
                          localFilter.filter_by.length > 0 &&
                          reviewFilterBy[localFilter.filter_by[0]] &&
                          localFilter.sub_filter_by.length > 0 &&
                          reviewFilterBy[localFilter.filter_by[0]][localFilter.sub_filter_by[0]] &&
                          Object.keys(reviewFilterBy[localFilter.filter_by[0]][localFilter.sub_filter_by[0]]).map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </div>)}
                <div style={{ fontSize: 23 }}>
                  {sortOrder ? <SortAscendingOutlined onClick={() => { setSortLoading(true); setTimeout(() => { setSortOrder(!sortOrder); setSortLoading(false); }) }} /> : <SortDescendingOutlined onClick={() => { setSortLoading(true); setTimeout(() => { setSortOrder(!sortOrder); setSortLoading(false); }) }} />}
                  {/* {sortOrder ? <SortAscendingOutlined onClick={() => setSortOrder(!sortOrder)} /> : <SortDescendingOutlined onClick={() => setSortOrder(!sortOrder)} />} */}
                </div>
              </div>
              {localFilter.filter_by[0] != "Themes" && (<div className="review-list">
                {reviewFilterBy[localFilter.filter_by[0]] && orderBy(reviewFilterBy[localFilter.filter_by[0]][localFilter.sub_filter_by[0]], ["review_date"], sortOrder ? ["desc"] : ["asc"])?.map((val, i) =>
                  <ReviewBox rating={val.sentence_rating} review={val.sentence} owner={val.reviewed_by_user} reviewDate={`Reviewed on ${moment(val.review_date).format("D MMM YYYY")}`} image={"/assets/images/mars-logo.svg"} retailer={val.retailer} />
                )}
              </div>)}
              {localFilter.filter_by[0] === "Themes" && (<div className="review-list">
                      {reviewFilterBy &&
                        localFilter.filter_by.length > 0 &&
                        reviewFilterBy[localFilter.filter_by[0]] &&
                        localFilter.sub_filter_by.length > 0 &&
                        reviewFilterBy[localFilter.filter_by[0]][localFilter.sub_filter_by[0]] &&
                        localFilter.sub_sub_filter_by.length > 0 &&
                        reviewFilterBy[localFilter.filter_by[0]][localFilter.sub_filter_by[0]][localFilter.sub_sub_filter_by[0]] &&
                        orderBy(
                          reviewFilterBy[localFilter.filter_by[0]][localFilter.sub_filter_by[0]][localFilter.sub_sub_filter_by[0]],
                          ["review_date"],
                          sortOrder ? ["desc"] : ["asc"]
                        ).map((val, i) => (
                          <ReviewBox
                            key={i}
                            rating={val.sentence_rating}
                            review={val.sentence}
                            owner={val.reviewed_by_user}
                            reviewDate={`Reviewed on ${moment(val.review_date).format("D MMM YYYY")}`}
                            image={"/assets/images/mars-logo.svg"}
                            retailer={val.retailer}
                          />
                        ))}
                    </div> )}
            </div>
          </div>
        </div>)}

      </TabPane >)
  }

  function ReviewBox(props) {
    const retailer = retailerImage && retailerImage.find(({ customer_retailer_details }) => customer_retailer_details === props.retailer);

    return (
      <div className='review-box'>
        <Row style={{ justifyContent: "space-between" }}>
          <Rate allowHalf disabled defaultValue={props.rating} style={{ fontSize: "larger" }} />
          {retailer ? <img src={`data:image/png;base64,${retailer.image_data}`} style={{ height: 30, width: 70 }} /> : props.retailer}
        </Row>
        <Row>
          <p style={{ fontWeight: "bold" }}>{props.review ? props.review : "NA"}</p>
        </Row>
        <Row style={{ fontSize: "smaller" }}>
          <p>{props.owner ? props.owner : "NA"} | {props.reviewDate ? props.reviewDate : "NA"}</p>
        </Row>
      </div>)
  }

  function TopThemes() {
    return (
      <div className='top-keywords'>
        <h5 style={{ textAlign: 'center' }}>Top Themes</h5>
        <ul>
          {reviewData?.review_tags && reviewData?.review_tags.map((tag) => <li>{tag}</li>)}
        </ul>
      </div>
    )
  }

  const { Panel } = Collapse;
  return (
    <Wrapper hideHeader={props.hideReportHeader} hidePage={isReport} heading={"1"} innerHeading={"11"}>
      <Content className={"site-layout-background" + (isReport && components.length === 0 ? " content-none" : " content-body")}>
        <Loader loading={(isReport ? props.reportLoader : loading > 0) || sortLoading}>
          {!props.hideReportFilters && <> <Row>
            <div className="refreshed-on">
              <span>Last Refreshed on: {innovationTracker.last_refresh}, Data upto: {innovationTracker.latest_period_available}</span>
              <span>Next Refresh on: {innovationTracker.next_refresh}</span>
            </div>
          </Row>
            <Row align="middle" style={{ marginBottom: 16 }} gutter={[16, 16]}>
              <Col span={16}>
                <Form name="horizontal_login" className="performance-filter" layout="inline">
                  <Row gutter={12}>
                    <div className="filters-ps">
                      <Col>
                        <label className="label-block">Launch Year</label>
                        <Select
                          mode="multiple"
                          onBlur={() => getRemainingFilters('time_year')}
                          value={filters.time_year}
                          onChange={(val) => changeFilter('time_year', val)}
                          style={{ width: 80 }}
                          maxTagCount="responsive" >
                          <Option value={'all'}>{'All'}</Option>
                          {years.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>
                      <Col>
                        <label className="label-block">Pet</label>
                        <Select placeholder="All"
                          onMouseLeave={() => getRemainingFilters('product_pet')}
                          mode="multiple" defaultValue={['all']} value={filters.product_pet} onChange={(val) => { changeFilter('product_pet', val); }} dropdownMatchSelectWidth={false} style={{ width: 100 }} placement="bottomLeft" maxTagCount="responsive" >
                          <Option value={'all'}>{'All'}</Option>
                          {pets.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>
                      <Col>
                        <label className="label-block">Technology</label>
                        <Select placeholder="All"
                          onMouseLeave={() => getRemainingFilters('product_technology')}
                          mode="multiple" defaultValue={['all']} value={filters.product_technology} onChange={(val) => { changeFilter('product_technology', val); }} dropdownMatchSelectWidth={false} placement="bottomLeft" style={{ width: 120 }} maxTagCount="responsive" >
                          <Option value={'all'}>{'All'}</Option>
                          {techs.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>
                      <Col>
                        <label className="label-block">Sub Technology</label>
                        <Select placeholder="All"
                         onMouseLeave={() => getRemainingFilters('product_sub_technology')}
                         mode="multiple" defaultValue={['all']} value={filters.product_sub_technology} onChange={(val) => { changeFilter('product_sub_technology', val); }} dropdownMatchSelectWidth={false} placement="bottomLeft" style={{ width: 120 }} maxTagCount="responsive" >
                          <Option value={"all"}>{"All"}</Option>
                          {subTechs.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>
                      <Col>
                        <label className="label-block">Life Stage</label>
                        <Select placeholder="All"
                         onMouseLeave={() => getRemainingFilters('product_lifestage')}
                         mode="multiple" defaultValue={['all']} value={filters.product_lifestage} onChange={(val) => { changeFilter('product_lifestage', val); }} dropdownMatchSelectWidth={false} placement="bottomLeft" style={{ width: 120 }} maxTagCount="responsive" >
                          <Option value={"all"}>{"All"}</Option>
                          {lifestage.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>

                      {filters.product_technology.length === 1 && isPackSize.includes(filters.product_technology[0]) && <Col>
                        <label className="label-block">Pack Type</label>
                        <Select placeholder="All" onMouseLeave={() => getRemainingFilters('product_package')} mode="multiple" value={filters.product_package} onChange={(val) => { changeFilter('product_package', val) }} dropdownMatchSelectWidth={false} placement="bottomLeft" style={{ width: 85 }} maxTagCount="responsive" >
                          <Option value={'all'}>{'All'}</Option>
                          {packSize[filters.product_technology[0]] && packSize[filters.product_technology[0]].map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>}
                      <Col>
                        <label className="label-block">Innovation Type</label>
                        <Select placeholder="All"
                          onMouseLeave={() => getRemainingFilters('innovation_type')}
                          mode="multiple" defaultValue={['all']} value={filters.innovation_type} onChange={(val) => { changeFilter('innovation_type', val) }} dropdownMatchSelectWidth={false} allowClear placement="bottomLeft" style={{ width: 140 }} maxTagCount="responsive" >
                          <Option value={'all'}>{'All'}</Option>
                          {innovationTypes.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>
                      <Col>
                        <label className="label-block">Innovation</label>
                        <Select placeholder="All" mode="multiple" value={filters.innovation} onChange={(val) => { changeFilter('innovation', val) }} dropdownMatchSelectWidth={false} allowClear placement="bottomLeft" style={{ width: 160 }} maxTagCount="responsive">
                          <Option value={'all'}>{'All'}</Option>
                          {innovation.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>
                    </div>
                    <Col>
                      <Button type="primary" style={{ marginTop: '27%' }} onClick={applyFilters}>Apply</Button>
                    </Col>
                  </Row>

                </Form>
              </Col>
              <Col span={8}  style={{alignSelf: "baseline", marginTop: 7}}>
                <Row gutter={20} justify="end" style={{ marginTop: '3%' }}>
                  {!newReport && <><Col>
                    <Dropdown
                      overlay={() => (
                        <Menu>
                          <Menu.Item onClick={() => {
                            appInsights.trackEvent({ name: 'PPT_FOR_INNOVATION' });
                            pptExportInnovation(gsvChart, "Innovation-Tracker", tableData, cardData, columns.itColsRevenue, columns.itColsDistributionPpt, trendData, appliedFilters, filters, defaultBrand, data2, tableLaunchYear, ratings_grid, reviewData, columns.itColsSentiment, reviewFilterBy, localFilter, sortOrder, selectedRow)
                          }}>Download as PPT</Menu.Item>
                          <Menu.Item onClick={() => {
                            appInsights.trackEvent({ name: 'EXCEL_FOR_INNOVATION' });
                            exportInnovationTracker(columns.itColsRevenue, columns.itColsDistribution, cardData, tableData, trendData, gsvChart, appliedFilters, localFilter, isRevenueData, tableLaunchYear, sortOrder, selectedRow)
                          }}>Download as Excel</Menu.Item>
                        </Menu>
                      )}
                      className="d-more">
                      <Button icon={<DownloadOutlined />}>Export Report</Button>
                    </Dropdown>
                  </Col>
                    {!isReport && (<Col>
                      <Dropdown
                        placement="bottomRight"
                        overlay={() => (
                          <Menu>
                            <Menu.Item onClick={(e) => { appInsights.trackEvent({ name: 'CREATE_SELF_SERVE_REPORT_INNOVATION' }); setNewReport(true); setCreateReport(true); }}>Create self serve Report</Menu.Item>
                            <Menu.Item onClick={(e) => { appInsights.trackEvent({ name: 'ADD_EXISTING_REPORT_INNOVATION' }); setNewReport(true); setCreateReport(false); }}>Add to Existing Report</Menu.Item>
                          </Menu>
                        )}>
                        <Button
                          icon={<PlusCircleOutlined />}
                          type="primary"
                        >
                          Create Report
                        </Button>
                      </Dropdown>
                    </Col>)}
                  </>}
                  {newReport && <>
                    <Col>
                      <Button type="secondary" onClick={(e) => { setNewReport(false); setNewReportComponents([]); }}>Cancel</Button>
                    </Col>
                    <Col>
                      <Button type="primary" onClick={(e) => { if (newReportComponents.length === 0) openNotificationWithIcon('error', 'No KPI components selected'); else setOpenSaveReportDialog(true); }}>Save Report</Button>
                    </Col>
                  </>}
                </Row>

              </Col>
            </Row></>}
          {!isReport && <Row className="sticky-filter">
            <Col span={24}>
              <div className="selected-filters">
                <label>Filters Applied:</label>
                <div className="filter-item">Pet - <span>{Array.isArray(appliedFilters.product_pet) && appliedFilters.product_pet.length > 0 && !appliedFilters.product_pet.includes("ALL") ? appliedFilters.product_pet.join() : "CAT,DOG"}</span></div>
                <div className="filter-item">Technology - <span>{Array.isArray(appliedFilters.product_technology) ? appliedFilters.product_technology.join() : "ALL"}</span></div>
                {appliedFilters.product_technology.length === 1 && isPackSize.includes(appliedFilters.product_technology[0]) && <div className="filter-item">Pack Type - <span>{Array.isArray(appliedFilters.product_package) ? appliedFilters.product_package.join() : "ALL"}</span></div>}
                <div className="filter-item">Sub Technology - <span>{Array.isArray(appliedFilters.product_sub_technology) ? appliedFilters.product_sub_technology.join() : "ALL"}</span></div>
                <div className="filter-item">Life Stage - <span>{Array.isArray(appliedFilters.product_lifestage) ? appliedFilters.product_lifestage.join() : "ALL"}</span></div>
                <div className="filter-item">Innovation Type - <span>{Array.isArray(appliedFilters.innovation_type) ? appliedFilters.innovation_type.join() : "ALL"}</span></div>
                <div className="filter-item">Launch Year - <span>{Array.isArray(appliedFilters.time_year) ? appliedFilters.time_year.join() : "ALL"}</span></div>
                <div className="filter-item">Innovation - <span>{Array.isArray(appliedFilters.innovation) ? appliedFilters.innovation.join() : "ALL"}</span></div>
              </div>
            </Col>
          </Row>}

          {<div className="vs-title" style={isReport ? { marginBottom: 0 } : { marginTop: 28 }}>
            <div>
              <h2>Innovation Tracker</h2>
            </div>
          </div>}
          {(isReport ? (components.length !== 0) : false) &&
            <Row>
              <Col md={3}>
                <label className="label-block">Innovation Type</label>
                <Select
                  placeholder="All"
                  onBlur={() => {
                    props.remainingFilters('innovation_type')
                  }}
                  mode="multiple"
                  defaultValue={['all']}
                  value={props.reportFilter.innovation_type}
                  onChange={(val) => {
                    props.onInnovationTypeChange(val)
                  }}
                  dropdownMatchSelectWidth={false}
                  allowClear
                  placement="bottomLeft"
                  style={{ width: 140 }}
                  maxTagCount="responsive" >

                  <Option value={'all'}>{'All'}</Option>
                  {innovationTypes.map((val) => (
                    <Option value={val}>{val}</Option>
                  ))}
                </Select>
              </Col>
              <Col md={4}>
                <label className="label-block">Launch Year</label>
                <Select
                  placeholder="All"
                  mode="multiple"
                  defaultValue={['all']}
                  value={props.reportFilter.innovation_years}
                  onChange={(val) => { props.onInnovationYearChange(val) }}
                  onBlur={() => {
                    props.remainingFilters('time_year')
                    props.applyFilters("innovation")
                  }
                  }
                  dropdownMatchSelectWidth={false}
                  allowClear
                  placement="bottomLeft"
                  style={{ width: 140 }}
                  maxTagCount="responsive">

                  <Option value={'all'}>{'All'}</Option>
                  {years.map((val) => (
                    <Option value={val}>{val}</Option>
                  ))}
                </Select>
              </Col>
              <Col md={4}>
                <label className="label-block">Innovation</label>
                <Select
                  placeholder="All"
                  mode="multiple"
                  defaultValue={['all']}
                  value={props.reportFilter.innovation}
                  onChange={(val) => { props.onInnovationChange(val) }}
                  onBlur={() => {
                    props.applyFilters("innovation")
                  }
                  }
                  dropdownMatchSelectWidth={false}
                  allowClear
                  placement="bottomLeft"
                  style={{ width: 200 }}
                  maxTagCount="responsive">

                  <Option value={'all'}>{'All'}</Option>
                  {innovation.map((val) => (
                    <Option value={val}>{val}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
          }

          {innovation.length > 0 && newReport && <Row>
            <Col span={24}>
              <div className="common-callout">
                <p>Select KPI Components you want to add in your report by clicking on the checkboxes</p>
              </div>
            </Col>
          </Row>}



          {innovationTab.length > 0 && (!isReport || components.includes("1")) && (<div className={`innovation-card ${isReport ? " top-chart-margin" : ""} ${newReport ? 'trackboard-edit' : ''} ${newReport && newReportComponents.includes('1') ? 'trackboard-selected' : ''}`} style={{ position: 'relative' }}>
            <div className="gsvChart">
              {newReport && <Checkbox value={newReportComponents.includes('1')} onChange={(e) => changeNewReportComponent('1', e)} className="d-check" style={{ top: 10, marginBottom: 10 }}></Checkbox>}
              <div className="gsv-heading">
                <h4 className="innovation-card-title">GSV : Actual vs Demand Forecast - Total Market</h4>
              </div>
              <div>
                <span style={{ listStyle: 'none' }}><LineOutlined className="gsv-legend" /> &nbsp; GSV Actual</span>&nbsp; &nbsp;
                <span style={{ listStyle: 'none' }}><DashOutlined className="gsv-legend" /> &nbsp; GSV Demand Forecast</span>
              </div>
              <Dropdown overlay={() => menu(exportGsvChart)} trigger={['click']} placement="bottomRight" className="d-more">
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                  <MoreOutlined />
                </a>
              </Dropdown>
            </div>
            {gsvChart && gsvChart.length ?
              <div className="gsvChart">
                <ul className='innovation-list'>
                  {
                    // && unique_innovation.slice(legendCount * 6, (legendCount * 6) + 6)
                    unique_innovation.map((item, index) => {
                      return (
                        <li style={{ listStyle: 'none', cursor: 'pointer' }} onClick={() => handleLegend(item)} className={legendName.isExist && legendName.name === item ? 'selectLegend' : !legendName.isExist ? 'selectLegend' : 'legend-style'}><span style={{ background: inno_color[item], width: '20px', height: "3px", borderRadius: "50px", display: 'inline-block' }} ></span> &nbsp; {item}</li>
                      )
                    })}
                </ul>
                <div>

                  {/* <div style={{ marginInlineEnd: '60px', fontSize: '15px' }}>
                    <button className={(((unique_innovation.length / 6))) <= legendCount + 1 ? 'btn-dissabled' : 'btn-ennabled'} disabled={(((unique_innovation.length / 6))) <= legendCount + 1 ? true : false} style={{ border: "none", background: 'transparent', cursor: 'pointer' }} onClick={() => setLegendCount(legendCount + 1)}><CaretUpFilled /> </button>

                    <span>{legendCount + 1}/{(unique_innovation.length / 6) === 0 ? 1 : (unique_innovation.length) % 6 == 0 ? unique_innovation.length / 6 : parseInt(unique_innovation.length / 6) + 1}</span>

                    <button className={(legendCount + 1) <= 1 ? 'btn-dissabled' : 'btn-ennabled'} disabled={(legendCount + 1) <= 1 ? true : false} onClick={() => setLegendCount(legendCount - 1)} style={{ border: "none", background: 'transparent', cursor: 'pointer' }} ><CaretDownFilled /> </button>
                  </div>  */}
                </div>
              </div>
              : <Empty className="gsv-empty" />}
            <Line {...configOne} />
          </div>)}

          {innovationTab.length > 0 && <hr style={{ margin: '20px 0 16px 0' }} />}

          {innovationTab.length > 0 && (!isReport || components.includes('3') || components.includes('4') || components.includes('5') || components.includes('6') || components.includes("7")) && <div>
            <h5>Select innovation to view data for:</h5>
            <Tabs activeKey={getActiveTabKey()} onChange={onTabChange}>
              {
                appliedFilters.innovation.includes("ALL") ?
                  innovationTab.map((tabName, i) => InnovationTab(tabName, i))
                  :
                  appliedFilters.innovation.map((tabName, i) => InnovationTab(tabName, i))}
            </Tabs>
          </div>}

          {innovationTab.length === 0 &&
            <div className="authorization">
              <h3 style={{ fontSize: 18 }}>No innovations available for {appliedFilters.time_year.toString()} </h3>
            </div>}



          <NewReportForm filters={{ ...localFilter }} setNewReport={setNewReport} createReport={createReport} newReportComponents={newReportComponents} reportType={'innovation-tracker'} setNewReportComponents={setNewReportComponents} openSaveReportDialog={openSaveReportDialog} setOpenSaveReportDialog={setOpenSaveReportDialog} />
        </Loader>
      </Content>
    </Wrapper >
  )
}