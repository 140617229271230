import React from "react";
import { useSelector } from "react-redux";
import { Layout, Row } from "antd";
import { withRouter, useHistory } from "react-router-dom";
import Loader from "../../components/loader";
import Wrapper from "../../components/wrapper";
import "./ecomDashboard.scss";
import mdiCart from "../../assets/images/mdi_cart_green.svg";
import mdiDecagram from "../../assets/images/mdi_check-decagram_blue.svg";
import mdiCheck from "../../assets/images/mdi_eye-check_pink.svg";
import mdiView from "../../assets/images/mdi_view-dashboard_orange.svg";
import beAvailableImage from "../../assets/images/image 129.png";
import beSelectedImage from "../../assets/images/image 130.png";
import diagnosticImage from "../../assets/images/image 133.png";
import beVisibleImage from "../../assets/images/image 134.png";
import EcomCard from "../../components/ecom-card";

const { Content } = Layout;

/**
 * EcomDashboard
 * This component renders the eCom Dashboard displaying various e-commerce diagnostic cards and data.
 */

function EcomDashboard(props) {
  const { loading } = useSelector(
    (state) => state.trackboard
  );
  const history = useHistory();

  return (
    <Wrapper heading={"1"}>
      <Content
        className={["site-layout-background content-body", "ecomdash-content"]}
      >
        <Loader loading={loading > 0}>
          <Row>
            <div className="ecom-info">
              <h3>eCom Diagnostics</h3>
              <hr />
              <p>
                eCom Diagnostics enables us to look at the three factors that
                underpin digital growth. Bringing these into a singular place
                allows analysis to be done at both individual and holistic
                levels.
              </p>
              <p>
                We are following : Be Available (Listed, In Stock) * Be Visible
                (Traffic, Search, Share of Shelf) * Be Selected (Conversion,
                Content, Ratings & Reviews) = Growth (Sales, Share)
              </p>
            </div>
          </Row>
          <div className="card-main-container">
            <Row className="card-row">
              <EcomCard
                history={history}
                imageSrc={diagnosticImage}
                iconSrc={mdiView}
                title="Diagnostics"
                description="Brands control sales and share online with availability, visibility, and conversion. These metrics include product offerings, traffic, content performance, and ratings. Improving them can boost search rankings and sales on top eRetailers."
                questions={["What is my current share on top eRetailers?", "Are my competitors growing dollar sales and share online?"]}
                deepDiveLink="/diagnostics"
                tooltipContent={<div >
                  <div className="custom-container">
                    <span className="diagnostics-label">Sales</span>
                    <span className="description">Total dollars sold at retail</span>
                    <span className="source">Source: Stackline, 84:51</span>
                  </div>

                  <div className="custom-container">
                    <span className="diagnostics-label">Unit Sales</span>
                    <span className="description">Total dollars sold at retail</span>
                    <span className="source">Source: Stackline, 84:51</span>
                  </div>

                  <div className="custom-container">
                    <span className="diagnostics-label">$ Share</span>
                    <span className="description">Total dollars sold at retail</span>
                    <span className="source">Source: Stackline, 84:51</span>
                  </div>
                </div>}
              />
              <EcomCard
                history={history}
                imageSrc={beAvailableImage}
                iconSrc={mdiDecagram}
                title="Be Available"
                description="In-stock products are critical to online sales and conversion. Out-of-stock items not only impact sales but also search ranking, requiring reinvestment to recover."
                questions={["How does my brand’s item count compare to my competition?", "What % of my brand is in-stock on a retailers?"]}
                deepDiveLink="/be-available"
                tooltipContent={<div >
                  <div className="custom-container">
                    <span className="be-available-label">In Stock %</span>
                    <span className="description">Of the # of items listed on a retailer site the % showing as in stock</span>
                  </div>

                  <div className="custom-container">
                    <span className="be-available-label"># of Items Listed</span>
                    <span className="description"># of items seen on a retailer site</span>
                    <span className="source">regardless of stock status</span>
                  </div>

                  <div className="custom-container">
                    <span className="be-available-label">CSL (Mars Only)</span>
                    <span className="description">Of the quantity ordered by the retailer the % that was shipped</span>
                  </div>
                </div>}
              />
              <EcomCard
                history={history}
                imageSrc={beVisibleImage}
                iconSrc={mdiCheck}
                title="Be Visible"
                description="Search and taxonomy navigation are top shopping choices for eRetailer customers. First-page visibility on high-traffic search results will boost product sales. Optimize brand listings with relevant keywords to increase visibility and drive sales"
                questions={["How does my brand’s share of shelf compare to my competition?", "What search terms are accelerating and decelerating in my subcategory?"]}
                deepDiveLink="/share-of-shelf"
                tooltipContent={<div >
                  <div className="custom-container">
                    <span className="be-visible-label">Traffic</span>
                    <span className="description">Unique estimated views of a product detail page</span>
                  </div>

                  <div className="custom-container">
                    <span className="be-visible-label">Average Placement</span>
                    <span className="description">The average of an item(s) rank</span>
                    <span className="source">on the first page of search results</span>
                  </div>

                  <div className="custom-container">
                    <span className="be-visible-label">Share of Shelf</span>
                    <span className="description">Share of placements that an item has on the first page of search results.  A higher position is weighted ore than a lower one</span>
                  </div>
                </div>}
              />
              <EcomCard
                history={history}
                imageSrc={beSelectedImage}
                iconSrc={mdiCart}
                buttonDisabled={true}
                title="Be Selected"
                description="Brands control sales and share online with availability, visibility, and conversion. These metrics include product offerings, traffic, content performance, and ratings. Improving them can boost search rankings and sales on top eRetailers."
                questions={["What is my current share on top eRetailers?", "Are my competitors growing dollar sales and share online?"]}
                deepDiveLink="/conversion"
                tooltipContent={<div >
                  <div className="custom-container">
                    <span className="be-selected-label">Conversion Rating</span>
                    <span className="description">Unique estimated purchases of a product out of its total estimated product detail page views</span>
                  </div>

                  <div className="custom-container">
                    <span className="be-selected-label"># of Items Listed</span>
                    <span className="description">The average of what consumers have rated a product</span>
                  </div>

                  <div className="custom-container">
                    <span className="be-selected-label">CSL (Mars Only)</span>
                    <span className="description">Quality content drives exceptional shopper experiences and boosts conversion. The listed content metrics measure compliance and uncover opportunities.</span>
                  </div>
                </div>}
              />

            </Row>
          </div>
        </Loader>
      </Content>
    </Wrapper>
  );
}

export default withRouter(EcomDashboard);
