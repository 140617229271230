import React from "react";
import { useSelector } from "react-redux";
import { Row } from 'antd'
import { withRouter } from "react-router-dom";
import { allBrands, valueTypes } from "../../utilities/helper";
import "./trackboardCard.scss";

function TrackboardCard(props) {
    const { defaultBrand } = useSelector(state => state.profile)
    const { heading, data, kpi1, kpi2, hideSubKpiPercent, hideKpiPercent } = props
    const isDefaultBrandMarsBDB = defaultBrand.toUpperCase() === allBrands

    return (
        <div className='inner-card'>
            <Row className='card-header'>
                <Row style={{ justifyContent: 'center' }}>
                    <div className='card-heading'>{heading}</div>
                </Row>
                <Row style={{ justifyContent: 'center' }}>
                    <div className='card-value'>
                        {data} {data === valueTypes.NA ? '' : hideKpiPercent ? <></> : <>%</>}
                    </div>
                </Row>
            </Row>
            <Row className='card-data'>
                <Row>
                    <div className={isDefaultBrandMarsBDB ? 'card-kpi-mars-right' : 'card-kpi-mars-right'}>
                        <Row
                            className={
                                kpi1 && isNaN(kpi1.slice(-1))
                                    ? Number(kpi1.slice(0, -1)) >= 0
                                        ? 'card-kpi-green'
                                        : 'card-kpi-red'
                                    : Number(kpi1) >= 0
                                        ? 'card-kpi-green'
                                        : 'card-kpi-red'
                            }
                        >
                            {!['Share of Shelf'].includes(heading) ? kpi1 === valueTypes.NA
                                ? valueTypes.NA
                                : `${kpi1 && isNaN(kpi1.slice(-1))
                                    ? Number(kpi1.slice(0, -1)) >= 0
                                        ? '+'
                                        : '-'
                                    : Number(kpi1) >= 0
                                        ? '+'
                                        : '-'
                                }
                        ${kpi1 && isNaN(kpi1.slice(-1)) ? kpi1 : Math.abs(kpi1)}
                        ${hideSubKpiPercent ? '%' : ' bps'}` : <></>}
                        </Row>
                        {!['Share of Shelf'].includes(heading) ?
                            ['MKT RSV YEE', 'GSV YEE'].includes(heading) ? (
                                <Row className='card-kpi-black'>vs Target</Row>
                            ) : (
                                <Row className='card-kpi-black'>vs YA</Row>
                            )
                            : <></>}
                    </div>
                </Row>
                <Row style={{ justifyContent: 'center' }}>
                    {['GSV YEE', 'MKT RSV YEE'].includes(heading) ? (
                        <>
                        </>
                    ) : isDefaultBrandMarsBDB ? (
                        ![
                            'GSV',
                            'NSV',
                            '$ Sales Share',
                            'Pound Volume Share',
                            'CSL',
                            'Share of Shelf'
                        ].includes(heading) ? (
                            <div className='card-kpi-mars-left'>
                                <div
                                    className={
                                        Number(kpi2) >= 0 ? 'card-kpi-green' : 'card-kpi-red'
                                    }
                                >
                                    {kpi2 === valueTypes.NA
                                        ? valueTypes.NA
                                        : `${Number(kpi2) >= 0 ? '+' : '-'}${Math.abs(kpi2)}${hideSubKpiPercent ? '%' : ' bps'
                                        }`}
                                </div>
                                <div className='card-kpi-black'>Category vs YA</div>
                            </div>
                        ) : (
                            <>
                            </>
                        )
                    ) : !['Share of Shelf'].includes(heading) && (
                        <div className='card-kpi-mars-right'>
                            <div
                                className={Number(kpi2) >= 0 ? 'card-kpi-green' : 'card-kpi-red'}
                            >
                                {kpi2 === valueTypes.NA
                                    ? valueTypes.NA
                                    : `${Number(kpi2) >= 0 ? '+' : '-'}${Math.abs(kpi2)}${hideSubKpiPercent ? '%' : ' bps'
                                    }`}
                            </div>
                            <div className='card-kpi-black'>Mars vs YA</div>
                        </div>
                    )}
                </Row>
            </Row>
        </div>
    )
}

export default withRouter(TrackboardCard)
