import React from "react";
import DateTime from "../date-time";
import './style.scss';

export default function UserCommnet(props) {
    return (
        <div className="user-comment-wrap">
            <div className="user-comment-head">
                <div className="user-image">
                    <img src="/assets/images/user.png" />
                </div>
                <h4>{props.message.added_by}</h4>
            </div>
            <p>{props.message.message}</p>
            <ul className="date-time">
                <li>{(new Date(props.message.time)).toLocaleTimeString(navigator.language, {
    hour: '2-digit',
    minute:'2-digit'
  })}</li>
                <li>{(new Date(props.message.time).toDateString())}</li>
            </ul>
        </div>
    )
}