import { createSlice } from '@reduxjs/toolkit';
import CustomAxios from '../utilities/services/api.service';
import { ENDPOINTS } from '../utilities/constants';
import { setLoading } from './nudges.slicer';
import { isSubBrand, openNotificationWithIcon } from '../utilities/helper'

const initialState = {
    bookmarksPerf: {},
    bookmarksVol: {},
    bookmarksDist: {},
    bookmarksInn: {},
    bookmarksLoading: 0,
    nudgesLoading: 0,
}

const bookmarkSlicer = createSlice({
    name: 'bookmarks',
    initialState,
    reducers: {
        setBookmarksLoading: (state, action) => ({ ...state, bookmarksLoading: state.bookmarksLoading + (action.payload ? 1 : -1) }),
        setNudgesLoading: (state, action) => ({ ...state, nudgesLoading: state.nudgesLoading + (action.payload ? 1 : -1) }),
        setBookmarksPerf: (state, action) => ({
            ...state,
            bookmarksPerf: action.payload,
        }),
        setBookmarksVol: (state, action) => ({
            ...state,
            bookmarksVol: action.payload,
        }),
        setBookmarksDist: (state, action) => ({
            ...state,
            bookmarksDist: action.payload,
        }),
        setBookmarksInn: (state, action) => ({
            ...state,
            bookmarksInn: action.payload,
        })
    },
})

export const { setBookmarksLoading, setNudgesLoading, setBookmarksPerf, setBookmarksVol, setBookmarksDist, setBookmarksInn } = bookmarkSlicer.actions

export const getBookmarks = (nudgePeriod) => (dispatch, getState) => {
    let email_id = sessionStorage.getItem("email");
    let reports = ["PERF", "VOL", "DSTR", "INN"];
    let defaultBrand = getState().profile.defaultBrand;

    reports.forEach(val => {
        dispatch(setLoading(true))
        let currentString = {
            report: [val],
            nudge_count: [6],
            nudge_count_view: [0],
            bookmark_by: [email_id],
            product_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[0] : defaultBrand,
            product_secondary_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[1] : "",
            nudge_period: nudgePeriod && nudgePeriod[val],
        }
        let encodedURL = encodeURIComponent(JSON.stringify(currentString))

        CustomAxios(ENDPOINTS.nudgesDeepDive + '?' + encodedURL, 'GET').then((response) => {
            if (response.data.nudges) {
                if (val === "PERF") {
                    dispatch(setBookmarksPerf(response.data))
                }
                else if (val === "VOL") {
                    dispatch(setBookmarksVol(response.data))
                }
                else if (val === "DSTR") {
                    dispatch(setBookmarksDist(response.data))
                }
                else if (val === "INN") {
                    dispatch(setBookmarksInn(response.data))
                }
            }
            else {
                if (val === "PERF") {
                    dispatch(setBookmarksPerf({}))
                }
                else if (val === "VOL") {
                    dispatch(setBookmarksVol({}))
                }
                else if (val === "DSTR") {
                    dispatch(setBookmarksDist({}))
                }
                else if (val === "INN") {
                    dispatch(setBookmarksInn({}))
                }
            }
            dispatch(setLoading(false))
        }).catch((err) => {
            console.log("Bookmarks Get Data error occured", err)
            dispatch(setLoading(false))
        })
    }
    )
}

export const loadBookmarks = (report, load, nudge_period) => async (dispatch, getState) => {
    let state = getState().bookmarks;
    let email_id = sessionStorage.getItem("email");
    let defaultBrand = getState().profile.defaultBrand;
    dispatch(setLoading(true))
    let nudge_count_view = []
    if (report === "PERF") {
        nudge_count_view = [state.bookmarksPerf?.nudges.length]
    }
    else if (report === "VOL") {
        nudge_count_view = [state.bookmarksVol?.nudges.length]
    }
    else if (report === "DSTR") {
        nudge_count_view = [state.bookmarksDist?.nudges.length]
    }
    else if (report === "INN") {
        nudge_count_view = [state.bookmarksInn?.nudges.length]
    }
    else {
        nudge_count_view = [6]
    }
    let currentString = {
        report: [report],
        nudge_count: [load],
        nudge_count_view: nudge_count_view,
        bookmark_by: [email_id],
        product_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[0] : defaultBrand,
        product_secondary_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[1] : "",
        nudge_period,

    }
    let encodedURL = encodeURIComponent(JSON.stringify(currentString))

    CustomAxios(ENDPOINTS.nudgesDeepDive + '?' + encodedURL, 'GET').then((response) => {
        if (response.data.nudges) {
            if (report === "PERF") {
                let existingNudges = [...state.bookmarksPerf.nudges]
                existingNudges.push(...response.data.nudges)
                let nudges = {
                    nudges: [...existingNudges],
                    remaining_nudges: response.data.remaining_nudges
                }
                dispatch(setBookmarksPerf(nudges))
            }
            else if (report === "VOL") {
                let existingNudges = [...state.bookmarksVol.nudges]
                existingNudges.push(...response.data.nudges)
                let nudges = {
                    nudges: [...existingNudges],
                    remaining_nudges: response.data.remaining_nudges
                }
                dispatch(setBookmarksVol(nudges))
            }
            else if (report === "DSTR") {
                let existingNudges = [...state.bookmarksDist.nudges]
                existingNudges.push(...response.data.nudges)
                let nudges = {
                    nudges: [...existingNudges],
                    remaining_nudges: response.data.remaining_nudges
                }
                dispatch(setBookmarksDist(nudges))
            }
            else if (report === "INN") {
                let existingNudges = [...state.bookmarksInn.nudges]
                existingNudges.push(...response.data.nudges)
                let nudges = {
                    nudges: [...existingNudges],
                    remaining_nudges: response.data.remaining_nudges
                }
                dispatch(setBookmarksInn(nudges))
            }
        }
        dispatch(setLoading(false))
    }).catch((err) => {
        console.log("Nudges Load error occured", err)
        dispatch(setLoading(false))
    })

}

export const bookmarkNudges = (report, nudge_id, type) => (dispatch, getState) => {
    let state = getState().bookmarks;
    let nudgesState = getState().nudges;
    let defaultBrand = getState().profile.defaultBrand;
    let email_id = sessionStorage.getItem("email");
    let time_year = 2022;
    let time_period = "P01";
    let reportName = "INN";
    let nudge = {};

    // console.log("nudge_id", nudge_id);


    if (report === "Performance-Summary") {
        nudge = type ? nudgesState.nudgesDeepDivePerf["nudges"].filter(item => item.nudge_id === nudge_id)[0] : state.bookmarksPerf["nudges"].filter(item => item.nudge_id === nudge_id)[0]
        reportName = "PERF"
        time_year = nudge.time_year
        time_period = nudge.time_period
    }
    else if (report === "Competition-Summary") {
        nudge = type ? nudgesState.nudgesDeepDiveVol["nudges"].filter(item => item.nudge_id === nudge_id)[0] : state.bookmarksVol["nudges"].filter(item => item.nudge_id === nudge_id)[0]
        reportName = "VOL"
        time_year = nudge.time_year
        time_period = nudge.time_period
    }
    else if (report === "Distribution-Summary") {
        nudge = type ? nudgesState.nudgesDeepDiveDist["nudges"].filter(item => item.nudge_id === nudge_id)[0] : state.bookmarksDist["nudges"].filter(item => item.nudge_id === nudge_id)[0]
        reportName = "DSTR"
        time_year = nudge.time_year
        time_period = nudge.time_period
    }
    else if (report === "Innovation-Tracker") {
        nudge = type ? nudgesState.nudgesDeepDiveInn["nudges"].filter(item => item.nudge_id === nudge_id)[0] : state.bookmarksInn["nudges"].filter(item => item.nudge_id === nudge_id)[0]
        reportName = "INN"
        time_year = nudge.time_year
        time_period = nudge.time_period
    }

    if (nudge && time_period && time_year) {
        let body = {
            "bookmark_by": [email_id],
            "nudge_report": [reportName],
            "nudge_id_group": [String(nudge_id)],
            "time_year": [time_year],
            "time_period": [time_period],
            "bookmark_type": [String(type)],
            product_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[0] : defaultBrand,
            product_secondary_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[1] : "",
        }
        let existingNudges = []
        let remaining_nudges = 0

        if (report === "Performance-Summary" && Object.keys(state.bookmarksPerf).length !== 0) {
            existingNudges = [...state.bookmarksPerf.nudges]
            remaining_nudges = state.bookmarksPerf.remaining_nudges
        }
        else if (report === "Competition-Summary" && Object.keys(state.bookmarksVol).length !== 0) {
            existingNudges = [...state.bookmarksVol.nudges]
            remaining_nudges = state.bookmarksVol.remaining_nudges
        }
        else if (report === "Distribution-Summary" && Object.keys(state.bookmarksDist).length !== 0) {
            existingNudges = [...state.bookmarksDist.nudges]
            remaining_nudges = state.bookmarksDist.remaining_nudges
        }
        else if (report === "Innovation-Tracker" && Object.keys(state.bookmarksInn).length !== 0) {
            existingNudges = [...state.bookmarksInn.nudges]
            remaining_nudges = state.bookmarksInn.remaining_nudges
        }


        dispatch(setBookmarksLoading(true));

        CustomAxios(ENDPOINTS.bookmarkNudge, 'POST', body).then((response) => {
            if (response.data.errorCode === undefined) {
                if (type) {
                    existingNudges.push(nudge)
                    let nudges = {
                        nudges: [...existingNudges],
                        remaining_nudges: remaining_nudges
                    }
                    if (report === "Performance-Summary") dispatch(setBookmarksPerf(nudges))
                    else if (report === "Competition-Summary") dispatch(setBookmarksVol(nudges))
                    else if (report === "Distribution-Summary") dispatch(setBookmarksDist(nudges))
                    else if (report === "Innovation-Tracker") dispatch(setBookmarksInn(nudges))
                }
                else {
                    let existing = existingNudges.filter(item => item.nudge_id !== nudge.nudge_id)
                    let nudges = {
                        nudges: [...existing],
                        remaining_nudges: remaining_nudges
                    }
                    if (report === "Performance-Summary") dispatch(setBookmarksPerf(nudges))
                    else if (report === "Competition-Summary") dispatch(setBookmarksVol(nudges))
                    else if (report === "Distribution-Summary") dispatch(setBookmarksDist(nudges))
                    else if (report === "Innovation-Tracker") dispatch(setBookmarksInn(nudges))
                }
            }
            else {
                openNotificationWithIcon("error", "Bookmark Error")
            }
            dispatch(setBookmarksLoading(false))
        }).catch((err) => {
            openNotificationWithIcon("error", "Bookmark Error")
            console.error(err)
            dispatch(setBookmarksLoading(false))
        })
    }
    else {
        openNotificationWithIcon("error", "Bookmark Error")
    }


}

export const markAsIrrelevantNudge = (report, nudge_id, loadData) => (dispatch, getState) => {
    // let state = getState().bookmarks;
    let nudgesState = getState().nudges;
    let defaultBrand = getState().profile.defaultBrand;
    let email_id = sessionStorage.getItem("email");
    let time_year = 2022;
    let time_period = "P01";
    let reportName = "INN";
    let nudge = {};


    if (report === "Performance-Summary") {
        nudge = nudgesState.nudgesDeepDivePerf["nudges"].filter(item => item.nudge_id === nudge_id)[0]
        reportName = "PERF"
        time_year = nudge.time_year
        time_period = nudge.time_period
    }
    else if (report === "Competition-Summary") {
        nudge = nudgesState.nudgesDeepDiveVol["nudges"].filter(item => item.nudge_id === nudge_id)[0]
        reportName = "VOL"
        time_year = nudge.time_year
        time_period = nudge.time_period
    }
    else if (report === "Distribution-Summary") {
        nudge = nudgesState.nudgesDeepDiveDist["nudges"].filter(item => item.nudge_id === nudge_id)[0]
        reportName = "DSTR"
        time_year = nudge.time_year
        time_period = nudge.time_period
    }
    else if (report === "Innovation-Tracker") {
        nudge = nudgesState.nudgesDeepDiveInn["nudges"].filter(item => item.nudge_id === nudge_id)[0]
        reportName = "INN"
        time_year = nudge.time_year
        time_period = nudge.time_period
    }

    if (nudge && time_period && time_year) {
        let body = {
            "bookmark_by": email_id,
            "nudge_report": reportName,
            "nudge_time_year": time_year,
            "nudge_time_period": time_period,
            "irrelevant_nudge_id": [String(nudge_id)],
            product_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[0] : defaultBrand,
            product_secondary_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[1] : "",
        }

        dispatch(setNudgesLoading(true));
        CustomAxios(ENDPOINTS.irrelevantNudge, 'POST', body).then((response) => {
            dispatch(setNudgesLoading(false))
            // reload nudges
            loadData();
            openNotificationWithIcon("success", "Alert has been marked as irrelevant; you will not see it any more");
        }).catch((err) => {
            dispatch(setNudgesLoading(false))
            console.error("Irrelevant Error", err);
            openNotificationWithIcon("error", "Irrelevant Error")
        })
    }
    else {
        console.error("Irrelevant Error", nudge, time_period, time_year);
        openNotificationWithIcon("error", "Irrelevant Error")
    }
}

export const markAsInaccurateNudge = (report, nudge_id, loadData) => (dispatch, getState) => {
    // let state = getState().bookmarks;
    let nudgesState = getState().nudges;
    let defaultBrand = getState().profile.defaultBrand;
    let user_name = getState().profile.user.user_name;
    let email_id = sessionStorage.getItem("email");
    let time_year = 2022;
    let time_period = "P01";
    let reportName = "INN";
    let nudge = {};


    if (report === "Performance-Summary") {
        nudge = nudgesState.nudgesDeepDivePerf["nudges"].filter(item => item.nudge_id === nudge_id)[0]
        reportName = "PERF"
        time_year = nudge.time_year
        time_period = nudge.time_period
    }
    else if (report === "Competition-Summary") {
        nudge = nudgesState.nudgesDeepDiveVol["nudges"].filter(item => item.nudge_id === nudge_id)[0]
        reportName = "VOL"
        time_year = nudge.time_year
        time_period = nudge.time_period
    }
    else if (report === "Distribution-Summary") {
        nudge = nudgesState.nudgesDeepDiveDist["nudges"].filter(item => item.nudge_id === nudge_id)[0]
        reportName = "DSTR"
        time_year = nudge.time_year
        time_period = nudge.time_period
    }
    else if (report === "Innovation-Tracker") {
        nudge = nudgesState.nudgesDeepDiveInn["nudges"].filter(item => item.nudge_id === nudge_id)[0]
        reportName = "INN"
        time_year = nudge.time_year
        time_period = nudge.time_period
    }

    if (nudge && time_period && time_year) {
        let body = {
            "user_email": email_id,
            "nudge_report": reportName,
            "nudge_time_year": time_year,
            "nudge_time_period": time_period,
            "nudge_id": String(nudge_id),
            "raised_by_user_name": user_name,
            product_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[0] : defaultBrand,
            product_secondary_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[1] : "",
        }

        dispatch(setNudgesLoading(true));
        CustomAxios(ENDPOINTS.inaccurateNudge, 'POST', body).then((response) => {
            dispatch(setNudgesLoading(false))
            // reload nudges
            loadData();
            openNotificationWithIcon("success", "Alert has been submitted for inaccuracy and will be reviewed by the Market Insights team");
        }).catch((err) => {
            openNotificationWithIcon("error", "Inaccurate Error")
            console.error(err)
            dispatch(setNudgesLoading(false))
        })
    }
    else {
        openNotificationWithIcon("error", "Inaccurate Error")
    }
}



export default bookmarkSlicer.reducer