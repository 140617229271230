import pptxgen from "pptxgenjs";
import html2canvas from "html2canvas";
import { addSymbol, allBrands, brandSplit, formatNumber, getTimeAggregates, openNotificationWithIcon } from "../helper";
import ExcelJS from "exceljs/dist/es5/exceljs.browser.js";
import * as FileSaver from "file-saver";
import { exportCustomTable } from "./excel";
import { colors } from '../helper';
import { dcom } from "../../pages/distribution-summary/dsUIHelper";

// import exportCustomTable from '../../react_code/src/exports'
function slideImage(
  className,
  slide,
  imageSize,
  presentation,
  scroll = false,
  filename = "",
  pres = false
) {
  html2canvas(
    document.getElementsByClassName(className)[0],
    scroll && { scrollY: -window.scrollY }
  ).then((canvas) => {
    let image = canvas.toDataURL("image/png");
    let imageString = String(image);
    
    slide.addImage({ ...imageSize, data: String(imageString.split(":")[1]) });
    if (pres) {
      presentation.writeFile({ fileName: filename });
    }
  });
}

export function createEcomCustomTable(
  columns,
  data,
  isDataBold = true,
  pageSize = 20,
  filters,
  fixedColumn
) {
  
  const headerOptions = {
    // bold: true,
    align: "center",
    fill: "0000A0",
    color: "ffffff",
    border: { type: "solid" },
  };
  const dataOptions = {
    // bold: isDataBold,
    align: "center",
    border: { type: "solid" },
  };

  const header = [];
  const subHeader = [];
  const headerText = [];
  const rows = [];
  const pages = [];

  columns.forEach(({ title, children }) => {
    
    if (children) {
      header.push({
        text: title,
        options: { ...headerOptions, colspan: children.length },
      });
      subHeader.push(
        ...children.map(({ title }) => ({
          text: title,
          options: headerOptions,
        }))
      );
    } else {
      header.push({ text: title, options: headerOptions });
    }
  });

  
  const hasChildren = subHeader.length > 0;
  rows.push(header);
  if (hasChildren) rows.push(subHeader);

  // rows.push(headerText);
  // data
  data.forEach((record, index) => {
    const row = [];
    columns.forEach(({ dataIndexPPT, children, renderPPT }) => {
      let recLength = record?.table_hierarchy_data;
      if (children) {
        children.forEach(({ dataIndexPPT, renderPPT, title }) => {
          // second level loop
          if (renderPPT) {
            Object.keys(record[dataIndexPPT]).forEach((val) => {
              if (val === filters && val === title) {
                row.push({
                  text: renderPPT(record[dataIndexPPT][val]),
                  options: dataOptions,
                });
              } else {
                if (!!fixedColumn.find((i) => i === val)) {
                  row.push({
                    text: renderPPT(record[dataIndexPPT][val]),
                    options: dataOptions,
                  });
                }
              }
            });
          } else {
            row.push({
              text: record[dataIndexPPT],
              options: dataOptions,
            });
          }
        });
      } else {
        if (renderPPT)
          row.push({
            text: renderPPT(record[dataIndexPPT]),
            options: dataOptions,
          });
        else
          row.push({
            text: record[dataIndexPPT],
            options: {
              ...dataOptions,
              align: "left",
              margin: recLength && recLength ? 0 : [0, 0, 0, 0.2],
            },
          });
      }
    });
    rows.push(row);
    if ((index + 1) % pageSize === 0) {
      pages.push(rows.splice(0));
      rows.push(header);
      if (hasChildren) rows.push(subHeader);
    }
  });
  if (data.length < pageSize) pages.push(rows);
  return pages;
}

function createCustomTable(columns, data, isDataBold = true, pageSize = 20) {
  
  const headerOptions = {
    // bold: true,
    align: "center",
    fill: "0000A0",
    color: "ffffff",
    border: { type: "solid" },
  };
  const dataOptions = {
    // bold: isDataBold,
    align: "center",
    border: { type: "solid" },
  };

  const header = [];
  const subHeader = [];
  const rows = [];
  const pages = [];

  columns.forEach(({ title, children }) => {
    
    if (children) {
      header.push({
        text: title,
        options: { ...headerOptions, colspan: children.length },
      });
      subHeader.push(
        ...children.map(({ title }) => ({
          text: title,
          options: headerOptions,
        }))
      );
    } else {
      header.push({ text: title, options: headerOptions });
    }
  });

  
  const hasChildren = subHeader.length > 0;
  rows.push(header);
  if (hasChildren) rows.push(subHeader);

  // data
  data.forEach((record, index) => {
    const row = [];
    columns.forEach(({ dataIndex, children, renderPPT }) => {
      let recLength = record?.retailer_data
      if (children) {
        children.forEach(({ dataIndex, renderPPT }) => {
          if (renderPPT){
            row.push({
              text: renderPPT(record[dataIndex]),
              options: dataOptions,
            });
          }else {
            row.push({ text: record[dataIndex], options: { ...dataOptions, align: 'left', margin: recLength && recLength ? 0 : [0, 0, 0, 0.2] } });
          }
        });
      } else {
        if (renderPPT)
          row.push({
            text: renderPPT(record[dataIndex]),
            options: dataOptions,
          });
        else row.push({ text: record[dataIndex], options: { ...dataOptions, align: 'left', margin: recLength && recLength ? 0 : [0, 0, 0, 0.2] } });
      }
    });

    rows.push(row);
    if ((index + 1) % pageSize === 0) {
      pages.push(rows.splice(0));
      rows.push(header);
      if (hasChildren) rows.push(subHeader);
    }
  });
  if (data.length < pageSize) pages.push(rows);
  return pages;
}
function createCustomTableForBrand(columns, data, cards, isDataBold = true, pageSize = 20) {
  const headerOptions = {
    bold: true,
    align: "center",
    fill: "0000A0",
    color: "ffffff",
    border: { type: "solid" },
  };
  const dataOptions = {
    bold: isDataBold,
    align: "left",
    border: { type: "solid" },
  };

  const header = [];
  const subHeader = [];
  const rows = [];
  const pages = [];

  columns.forEach(({ title, children }) => {
    
    if (children) {
      header.push({
        text: title,
        options: { ...headerOptions, colspan: children.length },
      });
      subHeader.push(
        ...children.map(({ title }) => ({
          text: title,
          options: headerOptions,
        }))
      );
    } else {
      header.push({ text: title, options: headerOptions });
    }
  });

  
  const hasChildren = subHeader.length > 0;
  rows.push(header);
  if (hasChildren) rows.push(subHeader);

  // data
  const getMainBrand = data.find((item) => item.customer_channel === cards[1])
  rows.push([
    { text: getMainBrand.customer_channel, options: dataOptions },
    { text: (getMainBrand.csl_sum_latest_4w).toFixed(1), options: dataOptions },
    { text: `$${formatNumber(getMainBrand.dollar_sum_latest_4w.abs)}`, options: dataOptions },
    { text: `$${formatNumber(getMainBrand.dollar_sum_latest_13w.abs)}`, options: dataOptions },
    { text: `$${formatNumber(getMainBrand.dollar_sum_latest_52w.abs)}`, options: dataOptions },
    { text: `$${formatNumber(getMainBrand.dollar_sum_latest_ytd.abs)}`, options: dataOptions },
    { text: `$${formatNumber(getMainBrand.dollar_sum_latest_4w.change)}`, options: dataOptions },
    { text: `$${formatNumber(getMainBrand.dollar_sum_latest_13w.change)}`, options: dataOptions },
    { text: `$${formatNumber(getMainBrand.dollar_sum_latest_52w.change)}`, options: dataOptions },
    { text: `$${formatNumber(getMainBrand.dollar_sum_latest_ytd.change)}`, options: dataOptions },

  ])
  getMainBrand.retailer_data.forEach((record, index) => {
    rows.push([
      { text: record.customer_channel, options: { ...dataOptions, margin: [0, 0, 0, 0.3] } },
      { text: (record.csl_sum_latest_4w).toFixed(1), options: dataOptions },
      { text: `$${formatNumber(record.dollar_sum_latest_4w.abs)}`, options: dataOptions },
      { text: `$${formatNumber(record.dollar_sum_latest_13w.abs)}`, options: dataOptions },
      { text: `$${formatNumber(record.dollar_sum_latest_52w.abs)}`, options: dataOptions },
      { text: `$${formatNumber(record.dollar_sum_latest_ytd.abs)}`, options: dataOptions },
      { text: `$${formatNumber(record.dollar_sum_latest_4w.change)}`, options: dataOptions },
      { text: `$${formatNumber(record.dollar_sum_latest_13w.change)}`, options: dataOptions },
      { text: `$${formatNumber(record.dollar_sum_latest_52w.change)}`, options: dataOptions },
      { text: `$${formatNumber(record.dollar_sum_latest_ytd.change)}`, options: dataOptions },
      // {text:`${formatNumber(record.tdp_latest_4w.abs)}`,options: dataOptions},
      // {text:`${formatNumber(record.tdp_latest_13w.abs)}`,options: dataOptions},
      // {text:`${formatNumber(record.tdp_latest_52w.abs)}`,options: dataOptions},
      // {text:`$${formatNumber(record.acv_latest_4w.abs)}`,options: dataOptions},
      // {text:`$${formatNumber(record.acv_latest_13w.abs)}`,options: dataOptions},
      // {text:`$${formatNumber(record.acv_latest_52w.abs)}`,options: dataOptions}, 
    ])
    // rows.push(row);
    // if ((index + 1) % pageSize === 0) {
    //   pages.push(rows.splice(0));
    //   rows.push(header);
    //   if (hasChildren) rows.push(subHeader);
    // }
  });

  

  if (data.length < pageSize) pages.push(rows);
  return pages;
}
function createCustomTableForBrandShare(columns, data, cards, isDataBold = true, pageSize = 20) {
  const headerOptions = {
    bold: true,
    align: "center",
    fill: "0000A0",
    color: "ffffff",
    border: { type: "solid" },
  };
  const dataOptions = {
    bold: isDataBold,
    align: "left",
    border: { type: "solid" },
  };

  const header = [];
  const subHeader = [];
  const rows = [];
  const pages = [];

  columns.forEach(({ title, children }) => {
    
    if (children) {
      header.push({
        text: title,
        options: { ...headerOptions, colspan: children.length },
      });
      subHeader.push(
        ...children.map(({ title }) => ({
          text: title,
          options: headerOptions,
        }))
      );
    } else {
      header.push({ text: title, options: headerOptions });
    }
  });

 
  const hasChildren = subHeader.length > 0;
  rows.push(header);
  if (hasChildren) rows.push(subHeader);

  // data
  const getMainBrand = data.find((item) => item.customer_channel === cards[1])
  rows.push([
    { text: getMainBrand.customer_channel, options: dataOptions },
    { text: `${formatNumber(getMainBrand.dollar_share_latest_4w.abs)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.dollar_share_latest_13w.abs)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.dollar_share_latest_52w.abs)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.dollar_share_latest_ytd.abs)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.dollar_share_latest_4w.change)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.dollar_share_latest_13w.change)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.dollar_share_latest_52w.change)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.dollar_share_latest_ytd.change)}`, options: dataOptions },

  ])
  getMainBrand.retailer_data.forEach((record, index) => {
    rows.push([
      { text: record.customer_channel, options: { ...dataOptions, margin: [0, 0, 0, 0.3] } },
      { text: `${formatNumber(record.dollar_share_latest_4w.abs)}`, options: dataOptions },
      { text: `${formatNumber(record.dollar_share_latest_13w.abs)}`, options: dataOptions },
      { text: `${formatNumber(record.dollar_share_latest_52w.abs)}`, options: dataOptions },
      { text: `${formatNumber(record.dollar_share_latest_ytd.abs)}`, options: dataOptions },
      { text: `${formatNumber(record.dollar_share_latest_4w.change)}`, options: dataOptions },
      { text: `${formatNumber(record.dollar_share_latest_13w.change)}`, options: dataOptions },
      { text: `${formatNumber(record.dollar_share_latest_52w.change)}`, options: dataOptions },
      { text: `${formatNumber(record.dollar_share_latest_ytd.change)}`, options: dataOptions },

      // {text:`$${formatNumber(record.acv_latest_4w.abs)}`,options: dataOptions},
      // {text:`$${formatNumber(record.acv_latest_13w.abs)}`,options: dataOptions},
      // {text:`$${formatNumber(record.acv_latest_52w.abs)}`,options: dataOptions}, 
    ])
    // rows.push(row);
    // if ((index + 1) % pageSize === 0) {
    //   pages.push(rows.splice(0));
    //   rows.push(header);
    //   if (hasChildren) rows.push(subHeader);
    // }
  });

  

  if (data.length < pageSize) pages.push(rows);
  return pages;
}
function createCustomTableForBrandTdp(columns, data, cards, isDataBold = true, pageSize = 20) {
  const headerOptions = {
    bold: true,
    align: "center",
    fill: "0000A0",
    color: "ffffff",
    border: { type: "solid" },
  };
  const dataOptions = {
    bold: isDataBold,
    align: "left",
    border: { type: "solid" },
  };

  const header = [];
  const subHeader = [];
  const rows = [];
  const pages = [];

  columns.forEach(({ title, children }) => {
    
    if (children) {
      header.push({
        text: title,
        options: { ...headerOptions, colspan: children.length },
      });
      subHeader.push(
        ...children.map(({ title }) => ({
          text: title,
          options: headerOptions,
        }))
      );
    } else {
      header.push({ text: title, options: headerOptions });
    }
  });

  
  const hasChildren = subHeader.length > 0;
  rows.push(header);
  if (hasChildren) rows.push(subHeader);

  // data
  const getMainBrand = data.find((item) => item.customer_channel === cards[1])
  rows.push([
    { text: getMainBrand.customer_channel, options: dataOptions },
    { text: `${formatNumber(getMainBrand.tdp_latest_4w.abs)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.tdp_latest_13w.abs)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.tdp_latest_52w.abs)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.tdp_latest_ytd.abs)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.tdp_latest_4w.change)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.tdp_latest_13w.change)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.tdp_latest_52w.change)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.tdp_latest_ytd.change)}`, options: dataOptions },

  ])
  getMainBrand.retailer_data.forEach((record, index) => {
    rows.push([
      { text: record.customer_channel, options: { ...dataOptions, margin: [0, 0, 0, 0.3] } },
      { text: `${formatNumber(record.tdp_latest_4w.abs)}`, options: dataOptions },
      { text: `${formatNumber(record.tdp_latest_13w.abs)}`, options: dataOptions },
      { text: `${formatNumber(record.tdp_latest_52w.abs)}`, options: dataOptions },
      { text: `${formatNumber(record.tdp_latest_ytd.abs)}`, options: dataOptions },
      { text: `${formatNumber(record.tdp_latest_4w.change)}`, options: dataOptions },
      { text: `${formatNumber(record.tdp_latest_13w.change)}`, options: dataOptions },
      { text: `${formatNumber(record.tdp_latest_52w.change)}`, options: dataOptions },
      { text: `${formatNumber(record.tdp_latest_ytd.change)}`, options: dataOptions },
      // {text:`$${formatNumber(record.acv_latest_4w.abs)}`,options: dataOptions},
      // {text:`$${formatNumber(record.acv_latest_13w.abs)}`,options: dataOptions},
      // {text:`$${formatNumber(record.acv_latest_52w.abs)}`,options: dataOptions}, 
    ])
    // rows.push(row);
    // if ((index + 1) % pageSize === 0) {
    //   pages.push(rows.splice(0));
    //   rows.push(header);
    //   if (hasChildren) rows.push(subHeader);
    // }
  });

  

  if (data.length < pageSize) pages.push(rows);
  return pages;
}
function createCustomTableForBrand$Tdp(columns, data, cards, isDataBold = true, pageSize = 20) {
  const headerOptions = {
    bold: true,
    align: "center",
    fill: "0000A0",
    color: "ffffff",
    border: { type: "solid" },
  };
  const dataOptions = {
    bold: isDataBold,
    align: "left",
    border: { type: "solid" },
  };

  const header = [];
  const subHeader = [];
  const rows = [];
  const pages = [];

  columns.forEach(({ title, children }) => {
    
    if (children) {
      header.push({
        text: title,
        options: { ...headerOptions, colspan: children.length },
      });
      subHeader.push(
        ...children.map(({ title }) => ({
          text: title,
          options: headerOptions,
        }))
      );
    } else {
      header.push({ text: title, options: headerOptions });
    }
  });

  
  const hasChildren = subHeader.length > 0;
  rows.push(header);
  if (hasChildren) rows.push(subHeader);

  // data
  const getMainBrand = data.find((item) => item.customer_channel === cards[1])
  
  rows.push([
    { text: getMainBrand.customer_channel, options: dataOptions },
    { text: `$${formatNumber(getMainBrand.acv_latest_4w.abs)}`, options: dataOptions },
    { text: `$${formatNumber(getMainBrand.acv_latest_13w.abs)}`, options: dataOptions },
    { text: `$${formatNumber(getMainBrand.acv_latest_52w.abs)}`, options: dataOptions },
    { text: `$${formatNumber(getMainBrand.acv_latest_ytd.abs)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.acv_latest_4w.change)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.acv_latest_13w.change)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.acv_latest_52w.change)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.acv_latest_ytd.change)}`, options: dataOptions },

  ])
  getMainBrand.retailer_data.forEach((record, index) => {
    
    rows.push([
      { text: record.customer_channel, options: { ...dataOptions, margin: [0, 0, 0, 0.3] } },
      { text: `$${formatNumber(record.acv_latest_4w.abs)}`, options: dataOptions },
      { text: `$${formatNumber(record.acv_latest_13w.abs)}`, options: dataOptions },
      { text: `$${formatNumber(record.acv_latest_52w.abs)}`, options: dataOptions },
      { text: `$${formatNumber(record.acv_latest_ytd.abs)}`, options: dataOptions },
      { text: `${formatNumber(record.acv_latest_4w.change)}`, options: dataOptions },
      { text: `${formatNumber(record.acv_latest_13w.change)}`, options: dataOptions },
      { text: `${formatNumber(record.acv_latest_52w.change)}`, options: dataOptions },
      { text: `${formatNumber(record.acv_latest_ytd.change)}`, options: dataOptions },
    ])
    // rows.push(row);
    // if ((index + 1) % pageSize === 0) {
    //   pages.push(rows.splice(0));
    //   rows.push(header);
    //   if (hasChildren) rows.push(subHeader);
    // }
  });

  

  if (data.length < pageSize) pages.push(rows);
  return pages;
}
function createCustomTableForBrandGsv(columns, data, cards, isDataBold = true, pageSize = 20) {
  const headerOptions = {
    bold: true,
    align: "center",
    fill: "0000A0",
    color: "ffffff",
    border: { type: "solid" },
  };
  const dataOptions = {
    bold: isDataBold,
    align: "left",
    border: { type: "solid" },
  };

  const header = [];
  const subHeader = [];
  const rows = [];
  const pages = [];

  columns.forEach(({ title, children }) => {
    
    if (children) {
      header.push({
        text: title,
        options: { ...headerOptions, colspan: children.length },
      });
      subHeader.push(
        ...children.map(({ title }) => ({
          text: title,
          options: headerOptions,
        }))
      );
    } else {
      header.push({ text: title, options: headerOptions });
    }
  });

  
  const hasChildren = subHeader.length > 0;
  rows.push(header);
  if (hasChildren) rows.push(subHeader);

  // data
  const getMainBrand = data.find((item) => item.customer_channel === cards[1])
  
  rows.push([
    { text: getMainBrand.customer_channel, options: dataOptions },
    { text: `$${formatNumber(getMainBrand.gsv_latest_4w.abs)}`, options: dataOptions },
    { text: `$${formatNumber(getMainBrand.gsv_latest_13w.abs)}`, options: dataOptions },
    { text: `$${formatNumber(getMainBrand.gsv_latest_52w.abs)}`, options: dataOptions },
    { text: `$${formatNumber(getMainBrand.gsv_latest_ytd.abs)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.gsv_latest_4w.change)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.gsv_latest_13w.change)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.gsv_latest_52w.change)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.gsv_latest_ytd.change)}`, options: dataOptions },

  ])
  getMainBrand.retailer_data.forEach((record, index) => {
    
    rows.push([
      { text: record.customer_channel, options: { ...dataOptions, margin: [0, 0, 0, 0.3] } },
      { text: `$${formatNumber(record.gsv_latest_4w.abs)}`, options: dataOptions },
      { text: `$${formatNumber(record.gsv_latest_13w.abs)}`, options: dataOptions },
      { text: `$${formatNumber(record.gsv_latest_52w.abs)}`, options: dataOptions },
      { text: `$${formatNumber(record.gsv_latest_ytd.abs)}`, options: dataOptions },
      { text: `${formatNumber(record.gsv_latest_4w.change)}`, options: dataOptions },
      { text: `${formatNumber(record.gsv_latest_13w.change)}`, options: dataOptions },
      { text: `${formatNumber(record.gsv_latest_52w.change)}`, options: dataOptions },
      { text: `${formatNumber(record.gsv_latest_ytd.change)}`, options: dataOptions },
    ])
    // rows.push(row);
    // if ((index + 1) % pageSize === 0) {
    //   pages.push(rows.splice(0));
    //   rows.push(header);
    //   if (hasChildren) rows.push(subHeader);
    // }
  });

  

  if (data.length < pageSize) pages.push(rows);
  return pages;
}
function createCustomTableForBrandNsv(columns, data, cards, isDataBold = true, pageSize = 20) {
  const headerOptions = {
    bold: true,
    align: "center",
    fill: "0000A0",
    color: "ffffff",
    border: { type: "solid" },
  };
  const dataOptions = {
    bold: isDataBold,
    align: "left",
    border: { type: "solid" },
  };

  const header = [];
  const subHeader = [];
  const rows = [];
  const pages = [];

  columns.forEach(({ title, children }) => {
    
    if (children) {
      header.push({
        text: title,
        options: { ...headerOptions, colspan: children.length },
      });
      subHeader.push(
        ...children.map(({ title }) => ({
          text: title,
          options: headerOptions,
        }))
      );
    } else {
      header.push({ text: title, options: headerOptions });
    }
  });

 
  const hasChildren = subHeader.length > 0;
  rows.push(header);
  if (hasChildren) rows.push(subHeader);

  // data
  const getMainBrand = data.find((item) => item.customer_channel === cards[1])
  
  rows.push([
    { text: getMainBrand.customer_channel, options: dataOptions },
    { text: `$${formatNumber(getMainBrand.nsv_latest_4w.abs)}`, options: dataOptions },
    { text: `$${formatNumber(getMainBrand.nsv_latest_13w.abs)}`, options: dataOptions },
    { text: `$${formatNumber(getMainBrand.nsv_latest_52w.abs)}`, options: dataOptions },
    { text: `$${formatNumber(getMainBrand.nsv_latest_ytd.abs)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.nsv_latest_4w.change)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.nsv_latest_13w.change)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.nsv_latest_52w.change)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.nsv_latest_ytd.change)}`, options: dataOptions },

  ])
  getMainBrand.retailer_data.forEach((record, index) => {
    
    rows.push([
      { text: record.customer_channel, options: { ...dataOptions, margin: [0, 0, 0, 0.3] } },
      { text: `$${formatNumber(record.nsv_latest_4w.abs)}`, options: dataOptions },
      { text: `$${formatNumber(record.nsv_latest_13w.abs)}`, options: dataOptions },
      { text: `$${formatNumber(record.nsv_latest_52w.abs)}`, options: dataOptions },
      { text: `$${formatNumber(record.nsv_latest_ytd.abs)}`, options: dataOptions },
      { text: `${formatNumber(record.nsv_latest_4w.change)}`, options: dataOptions },
      { text: `${formatNumber(record.nsv_latest_13w.change)}`, options: dataOptions },
      { text: `${formatNumber(record.nsv_latest_52w.change)}`, options: dataOptions },
      { text: `${formatNumber(record.nsv_latest_ytd.change)}`, options: dataOptions },
    ])
    // rows.push(row);
    // if ((index + 1) % pageSize === 0) {
    //   pages.push(rows.splice(0));
    //   rows.push(header);
    //   if (hasChildren) rows.push(subHeader);
    // }
  });


  if (data.length < pageSize) pages.push(rows);
  return pages;
}
function createCustomTableForBrandMac(columns, data, cards, isDataBold = true, pageSize = 20) {
  const headerOptions = {
    bold: true,
    align: "center",
    fill: "0000A0",
    color: "ffffff",
    border: { type: "solid" },
  };
  const dataOptions = {
    bold: isDataBold,
    align: "left",
    border: { type: "solid" },
  };

  const header = [];
  const subHeader = [];
  const rows = [];
  const pages = [];

  columns.forEach(({ title, children }) => {
    
    if (children) {
      header.push({
        text: title,
        options: { ...headerOptions, colspan: children.length },
      });
      subHeader.push(
        ...children.map(({ title }) => ({
          text: title,
          options: headerOptions,
        }))
      );
    } else {
      header.push({ text: title, options: headerOptions });
    }
  });

  
  const hasChildren = subHeader.length > 0;
  rows.push(header);
  if (hasChildren) rows.push(subHeader);

  // data
  const getMainBrand = data.find((item) => item.customer_channel === cards[1])
  
  rows.push([
    { text: getMainBrand.customer_channel, options: dataOptions },
    { text: `${formatNumber(getMainBrand.mac_latest_4w.abs)}%`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.mac_latest_13w.abs)}%`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.mac_latest_52w.abs)}%`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.mac_latest_ytd.abs)}%`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.mac_latest_4w.change)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.mac_latest_13w.change)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.mac_latest_52w.change)}`, options: dataOptions },
    { text: `${formatNumber(getMainBrand.mac_latest_ytd.change)}`, options: dataOptions },

  ])
  getMainBrand.retailer_data.forEach((record, index) => {
    rows.push([
      { text: record.customer_channel, options: { ...dataOptions, margin: [0, 0, 0, 0.3] } },
      { text: `${formatNumber(record.mac_latest_4w.abs)}%`, options: dataOptions },
      { text: `${formatNumber(record.mac_latest_13w.abs)}%`, options: dataOptions },
      { text: `${formatNumber(record.mac_latest_52w.abs)}%`, options: dataOptions },
      { text: `${formatNumber(record.mac_latest_ytd.abs)}%`, options: dataOptions },
      { text: `${formatNumber(record.mac_latest_4w.change)}`, options: dataOptions },
      { text: `${formatNumber(record.mac_latest_13w.change)}`, options: dataOptions },
      { text: `${formatNumber(record.mac_latest_52w.change)}`, options: dataOptions },
      { text: `${formatNumber(record.mac_latest_ytd.change)}`, options: dataOptions },
    ])
    // rows.push(row);
    // if ((index + 1) % pageSize === 0) {
    //   pages.push(rows.splice(0));
    //   rows.push(header);
    //   if (hasChildren) rows.push(subHeader);
    // }
  });
  if (data.length < pageSize) pages.push(rows);
  return pages;
}


export async function pptExportDistribution(
  exportData,
  filename,
  nutroSKUColumns,
  allSKUColumns,
  DefaultBrand,
  isReport,
  components,
  { kpiData, tableData, lineData, scatterData },
  nutroSKUColumnsExport,
  allSKUColumnsExport
) {
  
  const { pptLine, pptTableData, pptScatter, filters } = JSON.parse(
    JSON.stringify(exportData)
  );

  const isDcom = () => filters.customer_channel[0] === dcom;

  try {
    if (Object.keys(pptLine).length === 0) throw new Error("No Data");
    if (Object.keys(pptTableData).length === 0) throw new Error("No Data");
    if (Object.keys(pptScatter).length === 0) throw new Error("No Data");

    let pres = new pptxgen();
    // Define new layout for the Presentation
    pres.defineLayout({ name: "A3", width: 13, height: 7.5 });
    pres.layout = "A3";

    const slide = pres.addSlide();
    // Filters & KPIs
    // slideImage("filters-ps", slide, { x: 0.5, y: 1.5, w: 16, h: 0.7 }, pres);
    slide.addText(`Filters applied: ​Brand – ${DefaultBrand}; Pet – ${exportData?.filters?.product_pet}; Technology – ${exportData?.filters?.product_technology};Channel – ${exportData?.filters?.customer_channel} Year – ${exportData?.filters?.time_year}; Period – ${exportData?.filters?.time_period}`, { x: 0.5, y: 0.5, w: 20, fontSize: 14, fontFace: "Mars Centra" });

    slide.addText(`Distribution Summary`, { x: 1.5, y: 2, fontSize: 20, fontFace: "Mars Centra", align: 'center', bold: true });

    // const textLableOptions = {
    //   w: 4.5,
    //   h: 0.5,
    //   y: 5.5,
    //   align: "center",
    //   // line: { width: "2", color: "dcdcdc" },
    //   fill: "ffffff",
    //   // shape: pres.ShapeType.roundRect,
    //   // rectRadius: 0.2,
    //   fontSize: 11,
    //   bold:true
    // };
    const textOptions = {
      w: 3,
      h: 1.3,
      y: 1.5,
      align: "center",
      line: { width: "2", color: "dcdcdc" },
      fill: "ffffff",
      shape: pres.ShapeType.roundRect,
      rectRadius: 0.2,
      fontSize: 14,
      margin: 0.5
    };
    const textOptionsData = {
      w: 1.5,
      h: 1,
      y: 2,
      align: "center",
      line: { width: "2", color: "dcdcdc" },
      fill: "ffffff",
      shape: pres.ShapeType.roundRect,
      rectRadius: 0.2,
      fontSize: 14,
      margin: 0.5
    };


    slide.addText([
      { text: `${exportData?.kpi?.tdp?.title}`, options: { breakLine: true, fontSize: 18, fontFace: "Mars Centra", color: '4d4a4a', bold: true } },
      { text: "\n", options: { breakLine: true } },
      { text: `${(exportData?.kpi?.tdp?.value).toFixed(1)}`, options: { breakLine: true, fontSize: 25, fontFace: "Mars Centra", color: '0000A0' } },
    ], {
      ...textOptions,
      x: 1,
      y: 3,
    });
    slide.addText([
      { text: `vs YA`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: '4d4a4a', bold: true } },
      { text: "\n", options: { breakLine: true } },
      { text: `${(exportData?.kpi?.tdp?.vsYA).toFixed(1)}%`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: '00C853' } },
    ], {
      ...textOptionsData,
      x: 1,
      y: 4.4,
    });
    slide.addText([
      { text: `Mark vs YA`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: '4d4a4a', bold: true } },
      { text: "\n", options: { breakLine: true } },
      { text: `${(exportData?.kpi?.tdp?.marsVsYA).toFixed(1)}%`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: 'EC3225' } },
    ], {
      ...textOptionsData,
      x: 2.5,
      y: 4.4,
    });
    slide.addText([
      { text: `${exportData?.kpi?.dollarVsTdp?.title}`, options: { breakLine: true, fontSize: 18, fontFace: "Mars Centra", color: '4d4a4a', bold: true } },
      { text: "\n", options: { breakLine: true } },
      { text: `$${formatNumber(exportData?.kpi?.dollarVsTdp?.value)}`, options: { breakLine: true, fontSize: 25, fontFace: "Mars Centra", color: '0000A0' } },
    ], {
      ...textOptions,
      x: 5,
      y: 3
    });
    slide.addText([
      { text: `vs YA`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: '4d4a4a', bold: true } },
      { text: "\n", options: { breakLine: true } },
      { text: `${(exportData?.kpi?.dollarVsTdp?.vsYA).toFixed(1)}%`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: '00C853' } },
    ], {
      ...textOptionsData,
      x: 5,
      y: 4.4,
    });
    slide.addText([
      { text: `Mark vs YA`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: '4d4a4a', bold: true } },
      { text: "\n", options: { breakLine: true } },
      { text: `${(exportData?.kpi?.dollarVsTdp?.marsVsYA).toFixed(1)}%`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: 'EC3225' } },
    ], {
      ...textOptionsData,
      x: 6.5,
      y: 4.4,
    });
    slide.addText([
      { text: `${exportData?.kpi?.csl?.title}`, options: { breakLine: true, fontSize: 18, fontFace: "Mars Centra", color: '4d4a4a', bold: true } },
      { text: "\n", options: { breakLine: true } },
      { text: `${(exportData?.kpi?.csl?.value).toFixed(1)}%`, options: { breakLine: true, fontSize: 25, fontFace: "Mars Centra", color: '0000A0' } },
    ], {
      ...textOptions,
      x: 9,
      y: 3
    });
    slide.addText([
      { text: `vs YA`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: '4d4a4a', bold: true } },
      { text: "\n", options: { breakLine: true } },
      { text: `${(exportData?.kpi?.csl?.vsYA).toFixed(1)} bps`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: '00C853' } },
    ], {
      ...textOptionsData,
      x: 9,
      y: 4.4,
    });
    slide.addText([
      { text: `Mark vs YA`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: '4d4a4a', bold: true } },
      { text: "\n", options: { breakLine: true } },
      { text: `${(exportData?.kpi?.csl?.marsVsYA).toFixed(1)} bps`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: 'EC3225' } },
    ], {
      ...textOptionsData,
      x: 10.5,
      y: 4.4,
    });
    // slideImage(
    //   "first-section-ps",
    //   slide,
    //   { x: 0.5, y: 2.7, w: 17, h: 2.7 },
    //   pres
    // );

    // Line Chart 1

    const slide2 = pres.addSlide();
    slide2.addText(`Filters applied: ​Brand – ${DefaultBrand}; Pet – ${exportData?.filters?.product_pet}; Technology – ${exportData?.filters?.product_technology};Channel – ${exportData?.filters?.customer_channel} Year – ${exportData?.filters?.time_year}; Period – ${exportData?.filters?.time_period}`, { x: 0.5, y: 0.5, w: 20, fontSize: 14, fontFace: "Mars Centra" });

    if (isDcom())
      slide2.addText(`${exportData.pptLine.velocityCsl.title}`, { x: 5.5, y: 1, fontSize: 16, fontFace: "Mars Centra", bold: true });
    else {
      slide2.addText(`${exportData.pptLine.tdpVelocity.title}`, { x: 1.5, y: 1, fontSize: 16, fontFace: "Mars Centra", bold: true });
      slide2.addText(`${exportData.pptLine.velocityCsl.title}`, { x: 8, y: 1, fontSize: 16, fontFace: "Mars Centra", bold: true });
    }
    

    const { tdpVelocity, velocityCsl } = pptLine;
    const chartOptions = {
      showTitle: true,
      showLegend: true,
      showCatAxisTitle: true,
      showValue: true,
      catAxisTitle: "Periods",
      legendPos: "t",
      valGridLine: { style: "none" }
      // catAxisMultiLevelLabels: true,
      // secondaryCatAxis:true,
      // secondaryValAxis:true,
      // valAxisHidden : false
      // catAxisHidden: true
    };

if (isDcom()) {
  let labels1 = velocityCsl.data[1].labels;
    let chartTypes1 = [
      // {
      //   type: pres.charts.LINE,
      //   data: [
      //     {
      //       name: velocityCsl.data[0].name,
      //       labels: labels1,
      //       values: velocityCsl.data[0].values,
      //     },
      //   ],
      //   options: {
      //     chartColors: ["FBEC5D"],
      //     barGrouping: "standard",
      //     secondaryValAxis: true,
      //     secondaryCatAxis: true,
      //   },
      // },
      {
        type: pres.charts.LINE,
        data: [
          {
            name: velocityCsl.data[1].name,
            labels: labels1,
            values: velocityCsl.data[1].values,
          },
        ],
        options: {
          chartColors: ["FF5733"],
          barGrouping: "stacked",
        },
      },

    ];
    let opts1 = {
      x: 1.5,
      y: 1.5,
      w: 10,
      h: 5.5,
      chartArea: { fill: { color: "F1F1F1" } },

      barDir: "col",
      catAxisLabelColor: "666666",
      catAxisLabelFontFace: "Mars Centra",
      catAxisLabelFontSize: 10,
      catAxisOrientation: "minMax",
      showLegend: false,
      showTitle: false,
      // valAxisMaxVal: 100,
      // valAxisMajorUnit: 10,


      valAxes: [
        {
          showValAxisTitle: true,
          valAxisTitle: "CSL",
          valGridLine: { style: "none" },
        },
        // {
        //   showValAxisTitle: true,
        //   valAxisTitle: "Velocity",
        //   valGridLine: { style: "none" },
        // },
      ],

      catAxes: [
        {
          catAxisTitle: "Primary Category Axis",
        },
        // {
        //   catAxisHidden: true,
        //   valGridLine: { style: "none" },
        // },
      ],
    };
    slide2.addChart(chartTypes1, opts1);
} else {
  let labels = tdpVelocity.data[0].labels;
    let chartTypes = [
      {
        type: pres.charts.LINE,
        data: [
          {
            name: tdpVelocity.data[1].name,
            labels: labels,
            values: tdpVelocity.data[1].values,
          },
        ],
        options: {
          chartColors: ["FBEC5D"],
          barGrouping: "standard",
          secondaryValAxis: true,
          secondaryCatAxis: true,
        },
      },
      {
        type: pres.charts.LINE,
        data: [
          {
            name: tdpVelocity.data[0].name,
            labels: labels,
            values: tdpVelocity.data[0].values,
          },
        ],
        options: {
          chartColors: ["ff5733"],
          barGrouping: "stacked",
        },
      },

    ];
    let opts = {
      x: 0.5,
      y: 1.5,
      w: 6,
      h: 5.5,
      chartArea: { fill: { color: "F1F1F1" } },

      barDir: "col",
      catAxisLabelColor: "666666",
      catAxisLabelFontFace: "Mars Centra",
      catAxisLabelFontSize: 10,
      catAxisOrientation: "minMax",
      showLegend: false,
      showTitle: false,
      // valAxisMaxVal: 100,
      // valAxisMajorUnit: 10,
      valAxes: [
        {
          showValAxisTitle: true,
          valAxisTitle: "TDP",
          valGridLine: { style: "none" },

        },
        {
          showValAxisTitle: true,
          valAxisTitle: "Velocity",
          valGridLine: { style: "none" },
        },
      ],

      catAxes: [
        {
          catAxisTitle: "Primary Category Axis",
        },
        {
          catAxisHidden: true,
          valGridLine: { style: "none" },
        },
      ],
    };
    slide2.addChart(chartTypes, opts);

    // line chart 2
    let labels1 = velocityCsl.data[0].labels;
    let chartTypes1 = [
      {
        type: pres.charts.LINE,
        data: [
          {
            name: velocityCsl.data[0].name,
            labels: labels1,
            values: velocityCsl.data[0].values,
          },
        ],
        options: {
          chartColors: ["FBEC5D"],
          barGrouping: "standard",
          secondaryValAxis: true,
          secondaryCatAxis: true,
        },
      },
      {
        type: pres.charts.LINE,
        data: [
          {
            name: velocityCsl.data[1].name,
            labels: labels1,
            values: velocityCsl.data[1].values,
          },
        ],
        options: {
          chartColors: ["FF5733"],
          barGrouping: "stacked",
        },
      },

    ];
    let opts1 = {
      x: 7,
      y: 1.5,
      w: 6,
      h: 5.5,
      chartArea: { fill: { color: "F1F1F1" } },

      barDir: "col",
      catAxisLabelColor: "666666",
      catAxisLabelFontFace: "Mars Centra",
      catAxisLabelFontSize: 10,
      catAxisOrientation: "minMax",
      showLegend: false,
      showTitle: false,
      // valAxisMaxVal: 100,
      // valAxisMajorUnit: 10,


      valAxes: [
        {
          showValAxisTitle: true,
          valAxisTitle: "CSL",
          valGridLine: { style: "none" },
        },
        {
          showValAxisTitle: true,
          valAxisTitle: "Velocity",
          valGridLine: { style: "none" },
        },
      ],

      catAxes: [
        {
          catAxisTitle: "Primary Category Axis",
        },
        {
          catAxisHidden: true,
          valGridLine: { style: "none" },
        },
      ],
    };
    slide2.addChart(chartTypes1, opts1);
}
    
    // slide.addChart(pres.ChartType.line, velocityCsl.data, {
    //   ...chartOptions,
    //   x: 8.5,
    //   y: 6.5,
    //   w: 7.5,
    //   h: 5.5,
    //   title: velocityCsl.title,
    //   catAxisMultiLevelLabels: true,
    //   barDir: "col",
    // });

    // Scatter Chart
    const slide3 = pres.addSlide();

    // const scatterData = [
    //   { name: "X-Axis", values: [0, 1, 2, 3, 4, 5] },
    //   { name: "Y-Value 1", values: [90, 80, 70, 85, 75, 92], options: { fill: "000000" } },
    //   { name: "Y-Value 2", values: [21, 32, 40, 49, 31, 29], options: { fill: "ffff00" } },
    // ];
    slide3.addText(`Filters applied: ​Brand – ${DefaultBrand}; Pet – ${exportData?.filters?.product_pet}; Technology – ${exportData?.filters?.product_technology};Channel – ${exportData?.filters?.customer_channel} Year – ${exportData?.filters?.time_year}; Period – ${exportData?.filters?.time_period}; Time Aggregates – ${getTimeAggregates(exportData?.filters?.time_aggregates)}`, { x: 0.5, y: 0.5, w: 20, fontSize: 14, fontFace: "Mars Centra" });

    const { chart, table } = pptScatter;
    slide3.addText(chart.title, { w: "100%", h: 0.5, y: 1, x: 0.5, align: "center", bold: true });
    slide3.addText(chart.header, {
      x: 4.2,
      y: 1.7,
      w: 5.5,
      h: 0.7,
      fontSize: 14,
      fill: "e5e5e5",
    });

    slide3.addChart(pres.ChartType.scatter, chart.data, {
      ...chartOptions,
      x: 3,
      y: 2.3,
      w: 7,
      h: 4.5,
      lineSize: 0,
      chartColors: ["fdb433", "006e43"],
      lineDataSymbolLineColor: "ffffff",
      lineDataSymbolSize: 12,
      showValAxisTitle: true,
      showValue: false,
      showTitle: false,
      catAxisTitle: chart.xTitle,
      valAxisTitle: chart.yTitle,
      showLegend: false,
    });

    const slide4 = pres.addSlide();

    slide4.addText(`Filters applied: ​Brand – ${DefaultBrand}; Pet – ${exportData?.filters?.product_pet}; Technology – ${exportData?.filters?.product_technology};Channel – ${exportData?.filters?.customer_channel} Year – ${exportData?.filters?.time_year}; Period – ${exportData?.filters?.time_period}`, { x: 0.5, y: 0.5, w: 20, fontSize: 14, fontFace: "Mars Centra" });
    slide4.addText(chart.title, { w: "100%", h: 0.5, y: 0.7, x: 0.5, align: "center", bold: true });

    const dataOptions = {
      align: "center",
      border: { type: "solid", color: "e5e5e5" },
      fontSize: 12,
    };

    const median = [
      [
        {
          text: "Category Median",
          options: {
            bold: true,
            align: "center",
            fill: "e5e5e5",
            color: "0000a0",
            border: { type: "solid", color: "e5e5e5" },
            fontSize: 12,
            colspan: 2,
          },
        },
      ],
      [
        { text: `${chart.xTitle} ${chart.xBaseline}`, options: dataOptions },
        { text: `${chart.yTitle} ${chart.yBaseline}`, options: dataOptions },
      ],
    ];
    slide3.addTable(median, { x: 4.2, y: 6.8, w: 5.6, rowH: 0.3 });

    const allSKUTable = createCustomTable(allSKUColumns, table.data, false);
    slide4.addTable(allSKUTable[0], {
      x: 1.5,
      y: 1.1,
      w: 6,
      h: 4.7,
      align: "center",
      colW: [3.5, 1.3, 1.3, 1.3, 1.3, 1.3],
    });
    slide4.addText("For complete table, please refer to the excel extract - Distribution_Summary(tables).xlsx", { x: 1.5, y: 7.3, fontSize: 14, fontFace: "Mars Centra" });

    // Table Data
    const pages = createCustomTable(nutroSKUColumns, pptTableData.data);
    const slide5 = pres.addSlide();
    // // slideImage(
    // //   "filters-ps",
    // //   slide2,
    // //   { x: 0.5, y: 0.5, w: 10, h: 0.7 },
    // //   pres,
    // //   false,
    // //   filename,
    // //   true
    // // );
    slide5.addText(`Filters applied: ​Brand – ${DefaultBrand}; Pet – ${exportData?.filters?.product_pet}; Technology – ${exportData?.filters?.product_technology};Channel – ${exportData?.filters?.customer_channel} Year – ${exportData?.filters?.time_year}; Period – ${exportData?.filters?.time_period}`, { x: 0.5, y: 0.5, w: 20, fontSize: 14, fontFace: "Mars Centra" });

    slide5.addText(`All Brands SKUs - ${exportData.filters.customer_channel[0]}`, { x: 1, y: 1, fontSize: 16, fontFace: "Mars Centra", align: "center", bold: true });

    // const colW = [0.4, 1.8, 0.5, 0.5, 0.6, 0.6, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.6, 0.6, 0.6];
    const colW = [0.4, 2.7, 0.5, 0.5, 0.6, 0.6, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7, 0.7];

    
    pages.forEach((page, index) => {
      const data = page.slice(0, 7)
      if (index === 0)
        slide5.addTable(data, {
          y: 1.5,
          x: 0.25,
          align: "center",
          colW,
        //   fontSize: 10
        });
      else {
        const slide3 = pres.addSlide();
        slide3.addTable(data, {
          y: 1.5,
          x: 0.25,
          align: "center",
          colW,
        //   fontSize: 10
        });
      }
    });
    slide5.addText("For complete table, please refer to the excel extract - Distribution_Summary(tables).xlsx", { x: 0.7, y: 7.1, fontSize: 14, fontFace: "Mars Centra" });

    const wb = new ExcelJS.Workbook();
    if (!isReport) exportCustomTable(nutroSKUColumnsExport, tableData, `${brandSplit(DefaultBrand)}_SKUs`, true, wb);
    if (!isReport) exportCustomTable(allSKUColumnsExport, scatterData, "Distribution vs Velocity - All SKUs", true, wb);

    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), "Distribution_Summary(tables).xlsx");
    openNotificationWithIcon("success", "Downloading..");
    pres.writeFile({ fileName: filename })
  } catch (error) {
    
    openNotificationWithIcon("error", "Download Failed");
  }
}

export async function pptExportPerformance(exportData, filename, psColsRsv, psColsShare, psColsTdp, psCols$Tdp, table_data, defaultBrand, brandToggle, psColsFinancialRsv, psColsFinancialGsv, psColsFinancialNSV, psColsFinancialMAC) {
  const { pptTableData } = JSON.parse(JSON.stringify(exportData));
  
  try {
    if (Object.keys(pptTableData).length === 0) throw new Error("No Data");

    let pres = new pptxgen();

    // 1. Define the layout
    pres.defineLayout({ name: "A3", width: 13, height: 7.5 });
    pres.layout = "A3";
    // 2. Add a Slide to the presentation
    const slide = pres.addSlide();

    const imageSize = { x: 1.7, y: 3, w: 38, h: 6 };
    slide.addText(`Filters applied: Brand – ${defaultBrand};  Pet – ${exportData?.filters?.product_pet.join()} ; Technology – ${exportData?.filters?.product_technology.join()}; Year – ${exportData?.filters?.time_year.join()}; Period – ${exportData?.filters?.time_period.join()}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });
    // slideImage(
    //   "filters-ps",
    //   slide,
    //   { ...imageSize, y: 0.35, w: 9, h: 1 },
    //   pres
    // );
    //  slideImage("first-section-cards-ps", slide, { ...imageSize, y: 2,h:2.5 }, pres)
    let scroll = true
    //  await html2canvas(
    //   document.getElementsByClassName('first-section-cards-ps')[0],
    //   scroll && { scrollY: -window.scrollY }
    // ).then((canvas) => {
    //   let image = canvas.toDataURL("image/png");
    //   let imageString = String(image);
    //   slide.addImage({  ...imageSize, y: 2,h:2.5, data: String(imageString.split(":")[1]) });
    // });
    const textOptionsChart = {
      w: 2,
      h: 1.1,
      y: 1.5,
      align: "center",
      line: { width: "2", color: "dcdcdc" },
      fill: "ffffff",
      shape: pres.ShapeType.roundRect,
      rectRadius: 0.2,
      fontSize: 20,
      margin: 0.5
    };
    slide.addText([
      { text: `MKT RSV`, options: { breakLine: true, fontSize: 20, fontFace: "Mars Centra" } },
      { text: `$${formatNumber(exportData?.kpi?.data?.sum_fact_dollar)}`, options: { breakLine: true, fontSize: 20, fontFace: "Mars Centra", color: '0000A0' } },
    ], {
      ...textOptionsChart,
      x: 0.5,
      y: 1.5,
    });
    slide.addText([
      { text: `% Growth`, options: { breakLine: true, fontSize: 20, fontFace: "Mars Centra" } },
      { text: `${(exportData?.kpi?.data?.sum_fact_percent_growth).toFixed(1)}%`, options: { breakLine: true, fontSize: 20, fontFace: "Mars Centra", color: '0000A0' } },
    ], {
      ...textOptionsChart,
      x: 3,
      y: 1.5,
    });
    slide.addText([
      { text: `TDP`, options: { breakLine: true, fontSize: 20, fontFace: "Mars Centra" } },
      { text: `${formatNumber(exportData?.kpi?.data?.sum_fact_tdp)}`, options: { breakLine: true, fontSize: 20, fontFace: "Mars Centra", color: '0000A0' } },
    ], {
      ...textOptionsChart,
      x: 5.5,
      y: 1.5,
    });
    slide.addText([
      { text: `$/TDP`, options: { breakLine: true, fontSize: 20, fontFace: "Mars Centra" } },
      { text: `$${formatNumber(exportData?.kpi?.data?.sum_fact_dollar_acv)}`, options: { breakLine: true, fontSize: 20, fontFace: "Mars Centra", color: '0000A0' } },
    ], {
      ...textOptionsChart,
      x: 8,
      y: 1.5,
    });
    slide.addText([
      { text: `CSL %`, options: { breakLine: true, fontSize: 20, fontFace: "Mars Centra" } },
      { text: `${(exportData?.kpi?.data?.sum_fact_percent_csl_worst_case_4w).toFixed(1)}%`, options: { breakLine: true, fontSize: 20, fontFace: "Mars Centra", color: '0000A0' } },
    ], {
      ...textOptionsChart,
      x: 10.5,
      y: 1.5,
    });

    const areThree = false;
    const xDiff = areThree ? 1.25 : 3;
    const xStart = areThree ? 0.25 : 1;

    const xGSV = 1;
    const xNSV = 4;
    const xMAC = 8;

    const isGSVNegative = Math.sign(parseFloat(exportData?.kpi?.gsv?.kpiData?.percent_growth).toFixed(2)) === 1 ? false : true;
    const isNSVNegative = Math.sign(parseFloat(exportData?.kpi?.nsv?.kpiData?.percent_growth).toFixed(2)) === 1 ? false : true;
    const isMACNegative = Math.sign(parseFloat(exportData?.kpi?.['mac%']?.kpiData?.percent_growth).toFixed(1)) === 1 ? false : true;

    const chartOptions = {
      showTitle: true,
      showLegend: isGSVNegative ? false : true,
      showCatAxisTitle: true,
      showValue: true,
      catAxisTitle: "Periods",
      legendPos: "t",
      legendFontSize: 18,
    };

    const textOptions = {
      w: 1.4,
      h: 0.8,
      y: 6,
      align: "center",
      line: { width: "2", color: "dcdcdc" },
      fill: "ffffff",
      shape: pres.ShapeType.roundRect,
      rectRadius: 0.2,
      fontSize: 14,
      fontFace: "Mars Centra"
    };

    // GSV
    const gsvData = parseFloat(exportData?.kpi?.gsv?.periodToggle ? exportData?.kpi?.gsv?.kpiData?.percent_growth : exportData?.kpi?.gsv?.kpiData?.percent_growth_ytd).toFixed(2) || 0.0
    const gsvTarget = parseFloat(exportData?.kpi?.gsv?.periodToggle ? exportData?.kpi?.gsv?.kpiData?.target : exportData?.kpi?.gsv?.kpiData?.target_ytd).toFixed(2) || 0.0
    // let gsvDataHighVals = [
    //   {
    //     name: "Growth %",
    //     labels: "GSV Growth",
    //     values: gsvData,
    //   },
    //   {
    //     name: "Target",
    //     labels: "GSV Growth",
    //     values: gsvData,

    //   },
    // ];

    // let gsvChartBar1 = {
    //   x: 0.5,
    //   y: 3,
    //   w: 3.5,
    //   h: 4,
    //   title: 'GSV Growth',
    //   titleAlign: 'left',
    //   showDataTableHorzBorder: false,
    //   showDataTableVertBorder: false,
    //   showDataTableOutline: false,
    //   chartColors: ["80ebdc", "097969"],
    //   dataLabelFormatCode: '[>=1000000]#,##0.00,,"M";[>=1000]#,##0,"K"',
    //   // titleColor:'000000',
    //   showTitle: true,
    //   showValue: true,
    //   legendFontSize: 16,
    //   valueFontSize: 12,
    //   legendPos: 'b',
    //   legendColor: "000000",
    //   showLegend: true,
    //   color: "000000",
    //   barDir: "bar",
    //   fill: "FFFFFF",
    //   titleFontSize: 18,
    //   valAxisHidden: true,
    //   valAxisLineShow: true,
    //   // border: { pt: "3", color: "00EE00" }, // @deprecated - legacy text only (dont use this syntax - use `plotArea`)
    //   catAxisLabelFontFace: "Helvetica Neue",
    //   catAxisLabelFontSize: 12,
    //   catAxisOrientation: "maxMin",
    //   catAxisMajorTickMark: "in",
    //   catAxisMinorTickMark: "cross",
    //   valAxisMajorTickMark: "cross",
    //   valAxisMinorTickMark: "out",
    //   dataLabelFontSize: 12,
    //   valGridLine: { style: "none" }
    // };

    // slide.addChart(pres.charts.BAR, gsvDataHighVals, gsvChartBar1);
    

    let gsvDataHighVals = [
      {
        name: `${exportData?.kpi?.gsv?.fieldTitle}`,
        labels: [`${exportData?.kpi?.gsv?.fieldTitle}`],
        values: [gsvData],
      },
      {
        name: "Target",
        labels: ['Target'],
        values: [gsvTarget],

      },
    ];

    let gsvChartBar1 = {
      x: 0.5,
      y: 3,
      w: 3.5,
      h: 2,
      title: `${exportData?.kpi?.gsv?.fieldTitle} (${exportData?.kpi?.gsv?.periodToggle ? 'Period' : 'YTD'})`,
      titleAlign: 'left',
      showDataTableHorzBorder: false,
      showDataTableVertBorder: false,
      showDataTableOutline: false,
      chartColors: [gsvData > 0 ? (gsvData > gsvTarget ? colors.success : colors.warning) : colors.danger, "#1dc8ff"],
      dataLabelFormatCode: '##0.0',
      // titleColor:'000000',
      showTitle: true,
      showValue: true,
      legendFontSize: 16,
      valueFontSize: 12,
      legendPos: 'b',
      legendColor: "000000",
      showLegend: true,
      color: "000000",
      barDir: "bar",
      fill: "FFFFFF",
      titleFontSize: 18,
      valAxisHidden: true,
      valAxisLineShow: true,
      // border: { pt: "3", color: "00EE00" }, // @deprecated - legacy text only (dont use this syntax - use `plotArea`)
      catAxisLabelFontFace: "Helvetica Neue",
      catAxisLabelFontSize: 12,
      catAxisLabelPos: "high",
      catAxisOrientation: "maxMin",
      catAxisMajorTickMark: "in",
      catAxisMinorTickMark: "cross",
      valAxisMajorTickMark: "cross",
      valAxisMinorTickMark: "out",
      dataLabelFontSize: 12,
      valGridLine: { style: "none" }
    };

    slide.addChart(pres.charts.BAR, gsvDataHighVals, gsvChartBar1);
    // slide.addChart(pres.charts.BAR, arrDataHighVals1, optsChartBar2);


    // const gsvChartData = [
    //   {
    //     name: "Donut",
    //     labels: [`${exportData?.kpi?.gsv?.periodToggle ? 'Period' : 'YTD'}`, "", ""],
    //     values: [gsvData, (100 - Math.abs(gsvData)), -100],
    //   },
    // ];

    // slide.addChart(pres.ChartType.doughnut, gsvChartData, {
    //   ...chartOptions,
    //   x: 0.5,
    //   y: 3,
    //   w: 3.5,
    //   h: 4,
    //   firstSliceAng: 270,
    //   holeSize: 55,
    //   chartColors: [isGSVNegative ? "ff0000" : "80ebdc", "c7c7c7", "ffffff"],
    //   showPercent: false,
    //   showLabel: false,
    //   title: `${exportData?.kpi?.gsv?.fieldTitle}`,
    //   fontFace: "Mars Centra",
    //   titleFontSize: 18,
    //   titleFontFace: "Mars Centra",
    //   dataLabelFontSize: 12,
    //   dataLabelFormatCode: '#,##0.0"%"',
    //   bold: true
    // });

    if (exportData?.kpi?.gsv?.kpiValues?.length >= 3 && exportData?.kpi?.gsv?.periodToggle) {
      slide.addText(`${exportData?.kpi?.gsv?.periodToggle ? exportData?.kpi?.gsv?.kpiValues[0]?.title : exportData?.kpi?.gsv?.ytdValues[0]?.title}\n${exportData?.kpi?.gsv?.periodToggle ? exportData?.kpi?.gsv?.kpiValues[0]?.value : exportData?.kpi?.gsv?.ytdValues[0]?.value}`, {
        x: 0.5,
        w: 1.3,
        h: 0.8,
        y: 6,
        align: "center",
        line: { width: "2", color: "dcdcdc" },
        fill: "ffffff",
        shape: pres.ShapeType.roundRect,
        rectRadius: 0.2,
        fontSize: 14,
        fontFace: "Mars Centra"
      });

      slide.addText(`${exportData?.kpi?.gsv?.periodToggle ? exportData?.kpi?.gsv?.kpiValues[1]?.title : exportData?.kpi?.gsv?.ytdValues[1]?.title}\n${exportData?.kpi?.gsv?.periodToggle ? exportData?.kpi?.gsv?.kpiValues[1]?.value : exportData?.kpi?.gsv?.ytdValues[1]?.value}`, {
        x: 1.8,
        w: 1.3,
        h: 0.8,
        y: 6,
        align: "center",
        line: { width: "2", color: "dcdcdc" },
        fill: "ffffff",
        shape: pres.ShapeType.roundRect,
        rectRadius: 0.2,
        fontSize: 14,
        fontFace: "Mars Centra"
      });

      slide.addText(`${exportData?.kpi?.gsv?.periodToggle ? exportData?.kpi?.gsv?.kpiValues[2]?.title : exportData?.kpi?.gsv?.ytdValues[2]?.title}\n${exportData?.kpi?.gsv?.periodToggle ? exportData?.kpi?.gsv?.kpiValues[2]?.value : exportData?.kpi?.gsv?.ytdValues[2]?.value}`, {
        x: 3.1,
        w: 1.3,
        h: 0.8,
        y: 6,
        align: "center",
        line: { width: "2", color: "dcdcdc" },
        fill: "ffffff",
        shape: pres.ShapeType.roundRect,
        rectRadius: 0.2,
        fontSize: 14,
        fontFace: "Mars Centra"
      });
    }
    else {
      slide.addText(`${exportData?.kpi?.gsv?.periodToggle ? exportData?.kpi?.gsv?.kpiValues[0]?.title : exportData?.kpi?.gsv?.ytdValues[0]?.title}\n${exportData?.kpi?.gsv?.periodToggle ? exportData?.kpi?.gsv?.kpiValues[0]?.value : exportData?.kpi?.gsv?.ytdValues[0]?.value}`, {
        x: 0.9,
        ...textOptions,
      });

      slide.addText(`${exportData?.kpi?.gsv?.periodToggle ? exportData?.kpi?.gsv?.kpiValues[1]?.title : exportData?.kpi?.gsv?.ytdValues[1]?.title}\n${exportData?.kpi?.gsv?.periodToggle ? exportData?.kpi?.gsv?.kpiValues[1]?.value : exportData?.kpi?.gsv?.ytdValues[1]?.value}`, {
        x: 2.3,
        ...textOptions,
      });
    }

    // NSV
    const nsvData = parseFloat(exportData?.kpi?.nsv?.periodToggle ? exportData?.kpi?.nsv?.kpiData?.percent_growth : exportData?.kpi?.nsv?.kpiData?.percent_growth_ytd).toFixed(2) || 0.0
    const nsvTarget = parseFloat(exportData?.kpi?.nsv?.periodToggle ? exportData?.kpi?.nsv?.kpiData?.target : exportData?.kpi?.nsv?.kpiData?.target_ytd).toFixed(2) || 0.0

    // const nsvChartData = [
    //   {
    //     name: "Donut",
    //     labels: [`${exportData?.kpi?.nsv?.periodToggle ? 'Period' : 'YTD'}`, "", ""],
    //     values: [nsvData, 100 - Math.abs(nsvData), -100],
    //   },
    // ];

    // slide.addChart(pres.ChartType.doughnut, nsvChartData, {
    //   ...chartOptions,
    //   x: 5,
    //   y: 3,
    //   w: 3.5,
    //   h: 4,
    //   firstSliceAng: 270,
    //   holeSize: 55,
    //   chartColors: [isNSVNegative ? "ff0000" : "80ebdc", "c7c7c7", "ffffff"],
    //   showPercent: false,
    //   showLabel: false,
    //   title: `${exportData?.kpi?.nsv?.fieldTitle}`,
    //   fontFace: "Mars Centra",
    //   titleFontSize: 18,
    //   titleFontFace: "Mars Centra",
    //   dataLabelFontSize: 12,
    //   dataLabelFormatCode: '#,##0.0"%"',
    //   bold: true
    // });

    let nsvDataHighVals = [
      {
        name: `${exportData?.kpi?.nsv?.fieldTitle}`,
        labels: [`${exportData?.kpi?.nsv?.fieldTitle}`],
        values: [nsvData],
      },
      {
        name: "Target",
        labels: ['Target'],
        values: [nsvTarget],

      },
    ];

    let nsvChartBar1 = {
      x: 5,
      y: 3,
      w: 3.5,
      h: 2,
      title: `${exportData?.kpi?.nsv?.fieldTitle} (${exportData?.kpi?.nsv?.periodToggle ? 'Period' : 'YTD'})`,
      titleAlign: 'left',
      showDataTableHorzBorder: false,
      showDataTableVertBorder: false,
      showDataTableOutline: false,
      chartColors: [nsvData > 0 ? (nsvData > nsvTarget ? colors.success : colors.warning) : colors.danger, "#1dc8ff"],
      dataLabelFormatCode: '##0.0',
      // titleColor:'000000',
      showTitle: true,
      showValue: true,
      legendFontSize: 16,
      valueFontSize: 12,
      legendPos: 'b',
      legendColor: "000000",
      showLegend: true,
      color: "000000",
      barDir: "bar",
      fill: "FFFFFF",
      titleFontSize: 18,
      valAxisHidden: true,
      valAxisLineShow: true,
      // border: { pt: "3", color: "00EE00" }, // @deprecated - legacy text only (dont use this syntax - use `plotArea`)
      catAxisLabelFontFace: "Helvetica Neue",
      catAxisLabelFontSize: 12,
      catAxisLabelPos: "high",
      catAxisOrientation: "maxMin",
      catAxisMajorTickMark: "in",
      catAxisMinorTickMark: "cross",
      valAxisMajorTickMark: "cross",
      valAxisMinorTickMark: "out",
      dataLabelFontSize: 12,
      valGridLine: { style: "none" }
    };

    slide.addChart(pres.charts.BAR, nsvDataHighVals, nsvChartBar1);


    if (exportData?.kpi?.nsv?.kpiValues?.length >= 3 && exportData?.kpi?.nsv?.periodToggle) {
      slide.addText(`${exportData?.kpi?.nsv?.periodToggle ? exportData?.kpi?.nsv?.kpiValues[0]?.title : exportData?.kpi?.nsv?.ytdValues[0]?.title}\n${exportData?.kpi?.nsv?.periodToggle ? exportData?.kpi?.nsv?.kpiValues[0]?.value : exportData?.kpi?.nsv?.ytdValues[0]?.value}`, {
        x: 5.1,
        w: 1.2,
        h: 0.8,
        y: 6,
        align: "center",
        line: { width: "2", color: "dcdcdc" },
        fill: "ffffff",
        shape: pres.ShapeType.roundRect,
        rectRadius: 0.2,
        fontSize: 14,
        fontFace: "Mars Centra"
      });

      slide.addText(`${exportData?.kpi?.nsv?.periodToggle ? exportData?.kpi?.nsv?.kpiValues[1]?.title : exportData?.kpi?.nsv?.ytdValues[1]?.title}\n${exportData?.kpi?.nsv?.periodToggle ? exportData?.kpi?.nsv?.kpiValues[1]?.value : exportData?.kpi?.nsv?.ytdValues[1]?.value}`, {
        x: 6.3,
        w: 1.2,
        h: 0.8,
        y: 6,
        align: "center",
        line: { width: "2", color: "dcdcdc" },
        fill: "ffffff",
        shape: pres.ShapeType.roundRect,
        rectRadius: 0.2,
        fontSize: 14,
        fontFace: "Mars Centra"
      });
      slide.addText(`${exportData?.kpi?.nsv?.periodToggle ? exportData?.kpi?.nsv?.kpiValues[2]?.title : exportData?.kpi?.nsv?.ytdValues[2]?.title}\n${exportData?.kpi?.nsv?.periodToggle ? exportData?.kpi?.nsv?.kpiValues[2]?.value : exportData?.kpi?.nsv?.ytdValues[2]?.value}`, {
        x: 7.5,
        w: 1.2,
        h: 0.8,
        y: 6,
        align: "center",
        line: { width: "2", color: "dcdcdc" },
        fill: "ffffff",
        shape: pres.ShapeType.roundRect,
        rectRadius: 0.2,
        fontSize: 14,
        fontFace: "Mars Centra"
      });
    }
    else {
      slide.addText(`${exportData?.kpi?.nsv?.periodToggle ? exportData?.kpi?.nsv?.kpiValues[0]?.title : exportData?.kpi?.nsv?.ytdValues[0]?.title}\n${exportData?.kpi?.nsv?.periodToggle ? exportData?.kpi?.nsv?.kpiValues[0]?.value : exportData?.kpi?.nsv?.ytdValues[0]?.value}`, {
        x: 5.4,
        ...textOptions,
      });

      slide.addText(`${exportData?.kpi?.nsv?.periodToggle ? exportData?.kpi?.nsv?.kpiValues[1]?.title : exportData?.kpi?.nsv?.ytdValues[1]?.title}\n${exportData?.kpi?.nsv?.periodToggle ? exportData?.kpi?.nsv?.kpiValues[1]?.value : exportData?.kpi?.nsv?.ytdValues[1]?.value}`, {
        x: 6.8,
        ...textOptions,
      });
    }


    // MAC
    const macData = parseFloat(exportData?.kpi?.['mac%']?.periodToggle ? exportData?.kpi?.['mac%']?.kpiData?.percent_growth : exportData?.kpi?.['mac%']?.kpiData?.percent_growth_ytd).toFixed(2) || 0.0
    const macTarget = parseFloat(exportData?.kpi?.['mac%']?.periodToggle ? exportData?.kpi?.['mac%']?.kpiData?.target : exportData?.kpi?.['mac%']?.kpiData?.target_ytd).toFixed(2) || 0.0

    // const targetData = parseFloat(exportData?.kpi?.['mac%']?.periodToggle ? exportData?.kpi?.['mac%']?.kpiData?.target : exportData?.kpi?.['mac%']?.kpiData?.target_ytd).toFixed(2) || 0.0
    // const macChartData = [
    //   {
    //     name: "Donut",
    //     labels: [`${exportData?.kpi?.['mac%']?.periodToggle ? 'Period' : 'YTD'}`, "", ""],
    //     values: [macData, (100 - Math.abs(macData)), -100],
    //   },
    // ];

    // slide.addChart(pres.ChartType.doughnut, macChartData, {
    //   ...chartOptions,
    //   x: 9,
    //   y: 3,
    //   w: 3.5,
    //   h: 4,
    //   firstSliceAng: 270,
    //   holeSize: 55,
    //   chartColors: [isMACNegative ? "ff0000" : "80ebdc", "c7c7c7", "ffffff"],
    //   showPercent: false,
    //   showLabel: false,
    //   showValue: true,
    //   title: `${exportData?.kpi?.['mac%']?.fieldTitle}`,
    //   fontFace: "Mars Centra",
    //   titleFontSize: 18,
    //   titleFontFace: "Mars Centra",
    //   dataLabelFontSize: 12,
    //   dataLabelFormatCode: '#,##0.0"%"',
    //   bold: true
    // });

    let macDataHighVals = [
      {
        name: `${exportData?.kpi?.['mac%']?.fieldTitle}`,
        labels: [`${exportData?.kpi?.['mac%']?.fieldTitle}`],
        values: [macData],
      },
      {
        name: "Target",
        labels: ['Target'],
        values: [macTarget],

      },
    ];

    let macChartBar1 = {
      x: 9,
      y: 3,
      w: 3.5,
      h: 2,
      title: `${exportData?.kpi?.['mac%']?.fieldTitle} (${exportData?.kpi?.['mac%']?.periodToggle ? 'Period' : 'YTD'})`,
      titleAlign: 'left',
      showDataTableHorzBorder: false,
      showDataTableVertBorder: false,
      showDataTableOutline: false,
      chartColors: [macData > 0 ? (macData > macTarget ? colors.success : colors.warning) : colors.danger, "#1dc8ff"],
      dataLabelFormatCode: '##0.0',
      // titleColor:'000000',
      showTitle: true,
      showValue: true,
      legendFontSize: 16,
      valueFontSize: 12,
      legendPos: 'b',
      legendColor: "000000",
      showLegend: true,
      color: "000000",
      barDir: "bar",
      fill: "FFFFFF",
      titleFontSize: 18,
      valAxisHidden: true,
      valAxisLineShow: true,
      // border: { pt: "3", color: "00EE00" }, // @deprecated - legacy text only (dont use this syntax - use `plotArea`)
      catAxisLabelFontFace: "Helvetica Neue",
      catAxisLabelFontSize: 12,
      catAxisLabelPos: "high",
      catAxisOrientation: "maxMin",
      catAxisMajorTickMark: "in",
      catAxisMinorTickMark: "cross",
      valAxisMajorTickMark: "cross",
      valAxisMinorTickMark: "out",
      dataLabelFontSize: 12,
      valGridLine: { style: "none" }
    };

    slide.addChart(pres.charts.BAR, macDataHighVals, macChartBar1);

    if (exportData?.kpi?.['mac%']?.kpiValues?.length >= 3 && exportData?.kpi?.['mac%']?.periodToggle) {
      slide.addText(`${exportData?.kpi?.['mac%']?.periodToggle ? exportData?.kpi?.['mac%']?.kpiValues[0]?.title : exportData?.kpi?.['mac%']?.ytdValues[0]?.title}\n${exportData?.kpi?.['mac%']?.periodToggle ? exportData?.kpi?.['mac%']?.kpiValues[0]?.value : exportData?.kpi?.['mac%']?.ytdValues[0]?.value}`, {
        x: 9.2,
        w: 1.2,
        h: 0.8,
        y: 6,
        align: "center",
        line: { width: "2", color: "dcdcdc" },
        fill: "ffffff",
        shape: pres.ShapeType.roundRect,
        rectRadius: 0.2,
        fontSize: 14,
        fontFace: "Mars Centra"
      });

      slide.addText(`${exportData?.kpi?.['mac%']?.periodToggle ? exportData?.kpi?.['mac%']?.kpiValues[1]?.title : exportData?.kpi?.['mac%']?.ytdValues[1]?.title}\n${exportData?.kpi?.['mac%']?.periodToggle ? exportData?.kpi?.['mac%']?.kpiValues[1]?.value : exportData?.kpi?.['mac%']?.ytdValues[1]?.value}`, {
        x: 10.4,
        w: 1.2,
        h: 0.8,
        y: 6,
        align: "center",
        line: { width: "2", color: "dcdcdc" },
        fill: "ffffff",
        shape: pres.ShapeType.roundRect,
        rectRadius: 0.2,
        fontSize: 14,
        fontFace: "Mars Centra"
      });

      slide.addText(`${exportData?.kpi?.['mac%']?.periodToggle ? exportData?.kpi?.['mac%']?.kpiValues[2]?.title : exportData?.kpi?.['mac%']?.ytdValues[2]?.title}\n${exportData?.kpi?.['mac%']?.periodToggle ? exportData?.kpi?.['mac%']?.kpiValues[2]?.value : exportData?.kpi?.['mac%']?.ytdValues[2]?.value}`, {
        x: 11.6,
        w: 1.2,
        h: 0.8,
        y: 6,
        align: "center",
        line: { width: "2", color: "dcdcdc" },
        fill: "ffffff",
        shape: pres.ShapeType.roundRect,
        rectRadius: 0.2,
        fontSize: 14,
        fontFace: "Mars Centra"
      });
    }
    else {
      slide.addText(`${exportData?.kpi?.['mac%']?.periodToggle ? exportData?.kpi?.['mac%']?.kpiValues[0]?.title : exportData?.kpi?.['mac%']?.ytdValues[0]?.title}\n${exportData?.kpi?.['mac%']?.periodToggle ? exportData?.kpi?.['mac%']?.kpiValues[0]?.value : exportData?.kpi?.['mac%']?.ytdValues[0]?.value}`, {
        x: 9.5,
        ...textOptions,
      });

      slide.addText(`${exportData?.kpi?.['mac%']?.periodToggle ? exportData?.kpi?.['mac%']?.kpiValues[1]?.title : exportData?.kpi?.['mac%']?.ytdValues[1]?.title}\n${exportData?.kpi?.['mac%']?.periodToggle ? exportData?.kpi?.['mac%']?.kpiValues[1]?.value : exportData?.kpi?.['mac%']?.ytdValues[1]?.value}`, {
        x: 10.9,
        ...textOptions,
      });
    }




    // slideImage("third-section-ps", slide, { ...imageSize, y: 8.6 ,h : 4.7}, pres);
    // await html2canvas(
    //   document.getElementsByClassName('third-section-ps')[0],
    //   scroll && { scrollY: -window.scrollY }
    // ).then((canvas) => {
    //   let image = canvas.toDataURL("image/png");
    //   let imageString = String(image);
    //   slide.addImage({  ...imageSize, y: 17 ,h :11, data: String(imageString.split(":")[1]) });
    // });
    const slide2 = pres.addSlide();
    slide2.addText(`Filters applied: Brand – ${defaultBrand};  Pet – ${exportData?.filters?.product_pet.join()} ; Technology – ${exportData?.filters?.product_technology.join()}; Year – ${exportData?.filters?.time_year.join()}; Period – ${exportData?.filters?.time_period.join()}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });

    let optsBar1 = {
      x: 0.5,
      y: 1.5,
      w: 12,
      h: 5,
      barDir: "col",
      barGrouping: "stacked",
      dataLabelColor: "FFFFFF",
      dataLabelFontFace: "Mars Centra",
      dataLabelFontSize: 8,
      dataLabelFormatCode: '#,##0.0,,"M"',
      showValue: true,
      catAxisLabelColor: "000000",
      chartColors: ["FFFFFF", "00A36C"],
      valGridLine: { style: "none" },
      catAxisLabelFontFace: "Mars Centra",
      catAxisLabelFontSize: 10,
      catAxisOrientation: "minMax",
      showLegend: false,
      showTitle: false,
    };
    let lableName = exportData?.waterfall?.data?.map((value) => {
      return value.label
    })
    let arrData = exportData?.waterfall?.data?.map((value) => {
      return value.value
    })
    let arrData2 = []
    exportData?.waterfall?.data?.length > 0 && exportData.waterfall.data.forEach((data, index) => {
      if (index === 0) {
        arrData2.push(0)
      } else if (index === exportData.waterfall.data.length - 1) {
        arrData2.push(0)

      } else {
        let val1 = Math.sign(data.value);
        let val2
        if (val1 == 1 || val1 == 0) {
          let sum = 0;

          for (let i = 0; i <= index - 1; i++) {
            sum += arrData[i];
          }
          arrData2.push(sum)
        } else {
          val2 = arrData.filter((item, i) => i < index - 1).reduce((partialSum, a) => partialSum + a, 0) - data.value
        }
        // arrData2.push(val2)
      }
    })
    
    let arrDataRegions = [
      {
        name: "Region 3",
        labels: lableName,
        values: arrData2
      },
      {
        name: "Region 4",
        labels: lableName,
        values: arrData,
      },
    ];
    // slide2.addChart(pres.charts.BAR,arrDataRegions, optsBar1);
    // slideImage("third-section-ps", slide2, { ...imageSize, y: 14 }, pres);

    let chart1data = [arrData[0], arrData[arrData.length - 1]]
    let chart2data = arrData.slice(1, -1);
    let chart1lable = [lableName[0], lableName[lableName.length - 1]]
    let chart2lable = lableName.slice(1, -1);
    
    let colorCode = chart1data.map((item) => Math.sign(item) === 1 ? "4E9D2D" : "DE4216")
    let colorCode1 = chart2data.map((item) => Math.sign(item) === 1 ? "4E9D2D" : "DE4216")


    let waterfalldata1 = [
      {
        name: "Region 3",
        labels: chart1lable,
        values: chart1data,
      }
    ]
    let waterfalldata2 = [
      {
        name: "Region 4",
        labels: chart2lable,
        values: chart2data,
      }
    ]
    slide2.addText(`${defaultBrand} MKT RSV Change vs YA - Total Market : By (${exportData?.waterfall?.filters?.["Product Granularity"][0]})`, { x: 3.8, y: 1, fontSize: 16, fontFace: "Mars Centra", bold: true });

    slide2.addText(`${chart1lable[0]}\n${formatNumber(chart1data[0])}`, {
      x: 5.4,
      w: 1.2,
      h: 0.8,
      y: 1.5,
      align: "center",
      line: { width: "2", color: "dcdcdc" },
      fill: "ffffff",
      shape: pres.ShapeType.roundRect,
      rectRadius: 0.2,
      fontSize: 12,
      fontFace: "Mars Centra"
    });

    slide2.addText(`${chart1lable[1]}\n${formatNumber(chart1data[1])}`, {
      x: 6.6,
      w: 1.2,
      h: 0.8,
      y: 1.5,
      align: "center",
      line: { width: "2", color: "dcdcdc" },
      fill: "ffffff",
      shape: pres.ShapeType.roundRect,
      rectRadius: 0.2,
      fontSize: 12,
      fontFace: "Mars Centra"
    });

    slide2.addChart(pres.charts.BAR, waterfalldata2, {
      x: 3,
      y: 2.8,
      w: 7,
      h: 4,
      chartArea: { fill: { color: "404040" } },
      showValue: true,
      // dataLabelFormatCode: '[>=1000000]#,##0.0,,"M";[>=1000]#,##0,"K"',
      dataLabelFormatCode: '##0.0',
      barDir: "col",
      // chartColors: [Math.sign(arrData) === 1 ?  "4E9D2D" : "0077BF"],
      chartColors: colorCode1,
      //
      // valAxisMajorUnit: 15,
      // valAxisDisplayUnit: "hundreds",
      // valAxisMaxVal: 45,
      // valLabelFormatCode: "$0", // @since v3.3.0
      catGridLine: { style: "none" },
      valGridLine: { style: "none" },
      valAxisHidden: false,
      catAxisHidden: false,
      catAxisLabelFontSize: 9,
      valAxisLabelFontSize: 9,
      //
      // showTitle: true,
      // title: "Categories can be Multi-Color",
      // titleColor: "0088CC",
      // titleFontSize: 14
    });

    // let chartImageSize = { x: 1, y: 2, w: 11.4, h: 4.7 }
    // await html2canvas(
    //   document.getElementsByClassName('third-section-ps')[0],
    //   scroll && { scrollY: -window.scrollY }
    // ).then((canvas) => {
    //   let image = canvas.toDataURL("image/png");
    //   let imageString = String(image);
    
    //   slide2.addImage({ ...chartImageSize, data: String(imageString.split(":")[1]) });
    // });

    let arrDataHighVals = [
      {
        name: "CY Value",
        labels: exportData?.bar?.sku?.top?.data.map((item) => item.label),
        values: exportData?.bar?.sku?.top?.data.map((item) => item.current_year),
      },
      {
        name: "YA Value",
        labels: exportData?.bar?.sku?.top?.data.map((item) => item.label),
        values: exportData?.bar?.sku?.top?.data.map((item) => item.year_ago),

      },
    ];
    let arrDataHighVals1 = [
      {
        name: "CY Value",
        labels: exportData?.bar?.sku?.bottom?.data.map((item) => item.label),
        values: exportData?.bar?.sku?.bottom?.data.map((item) => item.current_year),
      },
      {
        name: "YA Value",
        labels: exportData?.bar?.sku?.bottom?.data.map((item) => item.label),
        values: exportData?.bar?.sku?.bottom?.data.map((item) => item.year_ago),

      },
    ];

    const slide3 = pres.addSlide();
    slide3.addText(`Filters applied: Brand – ${defaultBrand};  Pet – ${exportData?.filters?.product_pet.join()} ; Technology – ${exportData?.filters?.product_technology.join()}; Year – ${exportData?.filters?.time_year.join()}; Period – ${exportData?.filters?.time_period.join()}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });

    let optsChartBar1 = {
      x: 0,
      y: 1,
      w: 6.5,
      h: 6,
      title: 'Top 5 Sub Brands by MKT RSV',
      titleAlign: 'left',
      showDataTableHorzBorder: false,
      showDataTableVertBorder: false,
      showDataTableOutline: false,
      chartColors: ["80ebdc", "097969"],
      dataLabelFormatCode: '[>=1000000]#,##0.00,,"M";[>=1000]#,##0,"K"',
      // titleColor:'000000',
      showTitle: true,
      showValue: true,
      legendFontSize: 16,
      valueFontSize: 12,
      legendPos: 'b',
      legendColor: "000000",
      showLegend: true,
      color: "000000",
      barDir: "bar",
      fill: "FFFFFF",
      titleFontSize: 18,
      valAxisHidden: true,
      valAxisLineShow: true,
      // border: { pt: "3", color: "00EE00" }, // @deprecated - legacy text only (dont use this syntax - use `plotArea`)
      catAxisLabelFontFace: "Helvetica Neue",
      catAxisLabelFontSize: 12,
      catAxisOrientation: "maxMin",
      catAxisMajorTickMark: "in",
      catAxisMinorTickMark: "cross",
      valAxisMajorTickMark: "cross",
      valAxisMinorTickMark: "out",
      dataLabelFontSize: 12,
      valGridLine: { style: "none" }
    };
    let optsChartBar2 = {
      x: 6.5,
      y: 1,
      w: 6.5,
      h: 6,
      title: 'Bottom 5 Sub Brands by MKT RSV',
      titleAlign: 'left',
      showDataTableHorzBorder: false,
      showDataTableVertBorder: false,
      showDataTableOutline: false,
      titleColor: '000000',
      chartColors: ["F4BB44", "CC7722"],
      dataLabelFormatCode: '[>=1000000]#,##0.00,,"M";[>=1000]#,##0,"K"',
      showTitle: true,
      showValue: true,
      valueFontSize: 12,
      legendFontSize: 16,
      legendPos: 'b',
      legendColor: "000000",
      showLegend: true,
      color: "000000",
      barDir: "bar",
      fill: "FFFFFF",
      titleFontSize: 18,
      valAxisHidden: true,
      valAxisLineShow: true,
      // border: { pt: "3", color: "00EE00" }, // @deprecated - legacy text only (dont use this syntax - use `plotArea`)
      catAxisLabelFontFace: "Helvetica Neue",
      catAxisLabelFontSize: 12,
      catAxisOrientation: "maxMin",
      catAxisMajorTickMark: "in",
      catAxisMinorTickMark: "cross",
      valAxisMajorTickMark: "cross",
      valAxisMinorTickMark: "out",
      dataLabelFontSize: 12,
      valGridLine: { style: "none" }
    };
    slide3.addChart(pres.charts.BAR, arrDataHighVals, optsChartBar1);
    slide3.addChart(pres.charts.BAR, arrDataHighVals1, optsChartBar2);
    // slideImage("filters-ps", slide2, { ...imageSize, y: 0.35, w: 18, h: 1.5 }, pres)
    // slideImage("second-section-ps", slide2, { ...imageSize, y: 2, h: 18 }, pres, true, filename, true)

    // Table Data
    const pagesRsv = createCustomTable(psColsRsv, pptTableData.data);
    
    const slide4 = pres.addSlide();
    slide4.addText(`Filters applied: Brand – ${defaultBrand};  Pet – ${exportData?.filters?.product_pet.join()} ; Technology – ${exportData?.filters?.product_technology.join()}; Year – ${exportData?.filters?.time_year.join()}; Period – ${exportData?.filters?.time_period.join()}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });
    const colW = [
      1.7, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1
    ];
    pagesRsv.forEach((page, index) => {
      if (index === 0)
        slide4.addTable(page, {
          y: 0.8,
          x: 0.5,
          align: "center",
          colW,
          // rowH:1,
          fontSize: 12,
          fontFace: "Mars Centra"
        });
      else {
        const slide3 = pres.addSlide();
        slide3.addTable(page, {
          y: 6,
          x: 1.7,
          align: "center",
          colW,
          rowH: 1,
          fontSize: 25,
          fontFace: "Mars Centra"
        });
      }
    });

    const pagesShare = createCustomTable(psColsShare, pptTableData.data);
    const slide5 = pres.addSlide();
    slide5.addText(`Filters applied: Brand – ${defaultBrand};  Pet – ${exportData?.filters?.product_pet.join()} ; Technology – ${exportData?.filters?.product_technology.join()}; Year – ${exportData?.filters?.time_year.join()}; Period – ${exportData?.filters?.time_period.join()}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });
    const colW1 = [
      1.5, 1, 1, 1, 1, 1, 1, 1
    ];
    pagesShare.forEach((page, index) => {
      if (index === 0)
        slide5.addTable(page, {
          y: 0.8,
          x: 0.5,
          align: "center",
          colW1,
          // rowH:1,
          fontSize: 12,
          fontFace: "Mars Centra"
        });
      else {
        const slide3 = pres.addSlide();
        slide3.addTable(page, {
          y: 6,
          x: 1.7,
          align: "center",
          colW1,
          rowH: 1,
          fontSize: 25,
          fontFace: "Mars Centra"
        });
      }
    });

    const pagesTdp = createCustomTable(psColsTdp, pptTableData.data);
    const slide6 = pres.addSlide();
    slide6.addText(`Filters applied: Brand – ${defaultBrand};  Pet – ${exportData?.filters?.product_pet.join()} ; Technology – ${exportData?.filters?.product_technology.join()}; Year – ${exportData?.filters?.time_year.join()}; Period – ${exportData?.filters?.time_period.join()}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });
    const colW2 = [
      1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5
    ];
    pagesTdp.forEach((page, index) => {
      if (index === 0)
        slide6.addTable(page, {
          y: 0.8,
          x: 0.5,
          align: "center",
          colW2,
          // rowH:1,
          fontSize: 12,
          fontFace: "Mars Centra"
        });
      else {
        const slide3 = pres.addSlide();
        slide6.addTable(page, {
          y: 6,
          x: 1.7,
          align: "center",
          colW1,
          rowH: 1,
          fontSize: 25,
          fontFace: "Mars Centra"
        });
      }
    });

    const pages$Tdp = createCustomTable(psCols$Tdp, pptTableData.data);
    const slide7 = pres.addSlide();
    slide7.addText(`Filters applied: Brand – ${defaultBrand};  Pet – ${exportData?.filters?.product_pet.join()} ; Technology – ${exportData?.filters?.product_technology.join()}; Year – ${exportData?.filters?.time_year.join()}; Period – ${exportData?.filters?.time_period.join()}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });
    const colW3 = [
      1.5, 1, 1, 1, 1, 1, 1, 1
    ];
    pages$Tdp.forEach((page, index) => {
      if (index === 0)
        slide7.addTable(page, {
          y: 0.8,
          x: 0.5,
          align: "center",
          colW3,
          // rowH:1,
          fontSize: 12,
          fontFace: "Mars Centra"
        });
      else {
        const slide3 = pres.addSlide();
        slide6.addTable(page, {
          y: 6,
          x: 1.7,
          align: "center",
          colW1,
          rowH: 1,
          fontSize: 25,
          fontFace: "Mars Centra"
        });
      }
    });

    const pagesFinancialGsv = createCustomTable(psColsFinancialGsv, pptTableData.data);
    const slide8 = pres.addSlide();
    slide8.addText(`Filters applied: Brand – ${defaultBrand};  Pet – ${exportData?.filters?.product_pet.join()} ; Technology – ${exportData?.filters?.product_technology.join()}; Year – ${exportData?.filters?.time_year.join()}; Period – ${exportData?.filters?.time_period.join()}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });
    const colW4 = [
      1.5, 1, 1, 1, 1, 1, 1, 1
    ];
    pagesFinancialGsv.forEach((page, index) => {
      if (index === 0)
        slide8.addTable(page, {
          y: 0.8,
          x: 0.5,
          align: "center",
          colW4,
          // rowH:1,
          fontSize: 12,
          fontFace: "Mars Centra"
        });
      else {
        const slide3 = pres.addSlide();
        slide3.addTable(page, {
          y: 6,
          x: 1.7,
          align: "center",
          colW4,
          rowH: 1,
          fontSize: 25,
          fontFace: "Mars Centra"
        });
      }
    });

    const pagesFinancialNsv = createCustomTable(psColsFinancialNSV, pptTableData.data);
    const slide9 = pres.addSlide();
    slide9.addText(`Filters applied: Brand – ${defaultBrand};  Pet – ${exportData?.filters?.product_pet.join()} ; Technology – ${exportData?.filters?.product_technology.join()}; Year – ${exportData?.filters?.time_year.join()}; Period – ${exportData?.filters?.time_period.join()}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });
    const colW5 = [
      1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5
    ];
    pagesFinancialNsv.forEach((page, index) => {
      if (index === 0)
        slide9.addTable(page, {
          y: 0.8,
          x: 0.5,
          align: "center",
          colW5,
          // rowH:1,
          fontSize: 12,
          fontFace: "Mars Centra"
        });
      else {
        const slide3 = pres.addSlide();
        slide9.addTable(page, {
          y: 6,
          x: 1.7,
          align: "center",
          colW5,
          rowH: 1,
          fontSize: 25,
          fontFace: "Mars Centra"
        });
      }
    });

    const pagesFinancialMac = createCustomTable(psColsFinancialMAC, pptTableData.data);
    const slide10 = pres.addSlide();
    slide10.addText(`Filters applied: Brand – ${defaultBrand};  Pet – ${exportData?.filters?.product_pet.join()} ; Technology – ${exportData?.filters?.product_technology.join()}; Year – ${exportData?.filters?.time_year.join()}; Period – ${exportData?.filters?.time_period.join()}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });
    const colW6 = [
      1.5, 1, 1, 1, 1, 1, 1, 1
    ];
    pagesFinancialMac.forEach((page, index) => {
      if (index === 0)
        slide10.addTable(page, {
          y: 0.8,
          x: 0.5,
          align: "center",
          colW6,
          // rowH:1,
          fontSize: 12,
          fontFace: "Mars Centra"
        });
      else {
        const slide3 = pres.addSlide();
        slide10.addTable(page, {
          y: 6,
          x: 1.7,
          align: "center",
          colW6,
          rowH: 1,
          fontSize: 25,
          fontFace: "Mars Centra"
        });
      }
    });


    pres.writeFile({ fileName: filename })
    openNotificationWithIcon("success", "Downloading..");
  } catch (error) {
    
    openNotificationWithIcon("error", "Download Failed");
  }
}
export async function pptExportVolume(filename, sections = [], tableData, channel_data, retailer_data, filters, pCols, card, exportBrand, DefaultBrand, psColsRsv, psColsShare, psColsTdp, psColsDollarTdp, psColsFinancialRsv, psColsFinancialGsv, psColsFinancialNSV, psColsFinancialMAC, channel, selectCard) {
  
  const { compare_via, time_aggregates } = filters;
  let exportData
  if (compare_via[0] === 'brand') {
    exportData = tableData
  } else if (compare_via[0] === 'channel') {
    exportData = channel_data
  } else if (compare_via[0] === 'retailer') {
    exportData = retailer_data
  }
  try {
    let pres = new pptxgen();

    const createGroups = async (arr, numGroups, i) => {

      let tempArray = [];
      for (i = 0; i < arr.length; i += numGroups) {
        tempArray.push(arr.slice(i, i + numGroups));
      }
      return tempArray
      // const perGroup = Math.ceil(arr.length / numGroups);
      
      // return new Array(numGroups)
      //   .fill('')
      //   .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup));
    }
    if (compare_via[0] == 'brand') {
      pres.defineLayout({ name: 'A3', width: 13, height: 7.5 });
      pres.layout = 'A3'

      let scroll = false;
      let imageSize = { x: 1, y: 0.5, w: 17.5, h: 4 }
      // await html2canvas(
      //   document.getElementsByClassName('volume-head')[0],
      //   scroll && { scrollY: -window.scrollY }
      // ).then((canvas) => {
      //   let image = canvas.toDataURL("image/png");
      //   let imageString = String(image);
      
      //   slide.addImage({ ...imageSize, data: String(imageString.split(":")[1]) });

      // });


      const items = exportData
      const result = await createGroups(items, 3)
      

      const textLableOptions = {
        w: 1.5,
        h: 0.5,
        y: 1.5,
        align: "center",
        valign: 'middle',
        bold: true,
        // line: { width: "2", color: "dcdcdc" },
        fill: "ffffff",
        // shape: pres.ShapeType.roundRect,
        // rectRadius: 0.2,
        fontSize: 12,
        fontFace: "Mars Centra"
      };
      const textOptions = {
        w: 1.6,
        h: 1,
        y: 2,
        align: "center",
        line: { width: "2", color: "dcdcdc" },
        fill: "ffffff",
        shape: pres.ShapeType.roundRect,
        rectRadius: 0.2,
        fontSize: 10,
        // margin: 0.5,
        fontFace: "Mars Centra"
      };
      let rsvArr = []
      let shareArr = []
      let tdpArr = []
      let $TdpArr = []
      let gsvArr = []
      let nsvArr = []
      let macArr = []
      let isActive = false;
      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        
        window['slide' + (index + 1)] = pres.addSlide();
        window['slide' + (index + 1)].addText(`Filters applied: ​Brand – ${DefaultBrand}; Pet – ${exportBrand?.filters?.product_pet}; Technology – ${exportBrand?.filters?.product_technology}; Year – ${exportBrand?.filters?.time_year}; Period – ${exportBrand?.filters?.time_period}; Time Aggregates – ${getTimeAggregates(exportBrand?.filters?.time_aggregates)}`, { x: 0.5, y: 0.5, w: 20, fontSize: 14, fontFace: "Mars Centra" });

        window['slide' + (index + 1)].addText(`Volume Shifting`, { x: 1.5, y: 1, fontSize: 16, fontFace: "Mars Centra", bold: true, align: 'center', margin: [0, 0, 0, 3] });
        element.map((item, i) => {
          window['slide' + (index + 1)].addText(``, {
            ...textLableOptions,
            x: 0.5,
            y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
          });
          window['slide' + (index + 1)].addText(``, {
            ...textLableOptions,
            x: 2.2,
            y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
          });
          window['slide' + (index + 1)].addText(`TOTAL`, {
            ...textLableOptions,
            x: 3.9,
            y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
          });
          window['slide' + (index + 1)].addText(`FDM excluding\n Omni`, {
            ...textLableOptions,
            x: 5.6,
            y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
          });
          window['slide' + (index + 1)].addText(`Supers excluding\n Omni`, {
            ...textLableOptions,
            x: 7.3,
            y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
          });
          window['slide' + (index + 1)].addText(`Indy excluding\n Omni`, {
            ...textLableOptions,
            x: 9,
            y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
          });
          window['slide' + (index + 1)].addText(`Ecom including\n Omni`, {
            ...textLableOptions,
            x: 10.7,
            y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
          });
          //cards
          window['slide' + (index + 1)].addText(`${item.brand}`, {
            ...textOptions,
            x: 0.5,
            y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.8) - 0.5) * i)) : textOptions.y,
          });
          window['slide' + (index + 1)].addText([
            { text: "$ Sales mm", options: { breakLine: true, fontSize: 10, fontFace: "Mars Centra", color: '4d4a4a' } },
            { text: "%CYA", options: { breakLine: true, fontSize: 12, fontFace: "Mars Centra", color: '4d4a4a' } },
            { text: "\n" },
            { text: "$ Share", options: { breakLine: true, fontSize: 12, fontFace: "Mars Centra", color: '4d4a4a' } },
            { text: "%CYA", options: { fontSize: 12, fontFace: "Mars Centra", color: '4d4a4a' } },
          ], {
            ...textOptions,
            x: 2.2,
            y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.8) - 0.5) * i)) : textOptions.y
          });
          window['slide' + (index + 1)].addText([
            { text: item[`dollar_sum_latest_${time_aggregates}`] ? `$${formatNumber(item[`dollar_sum_latest_${time_aggregates}`])}` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item[`dollar_variance_latest_${time_aggregates}`] ? `${(item[`dollar_variance_latest_${time_aggregates}`]).toFixed(1)}%` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: "\n" },
            { text: item[`dollar_share_latest_${time_aggregates}`] ? `${(item[`dollar_share_latest_${time_aggregates}`]).toFixed(1)}%` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item[`dollar_share_variance_latest_${time_aggregates}`] ? `${(item[`dollar_share_variance_latest_${time_aggregates}`]).toFixed(1)} bps` : 'NA', options: { color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
          ], {
            ...textOptions,
            x: 3.9,
            y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.8) - 0.5) * i)) : textOptions.y
          });
          window['slide' + (index + 1)].addText([
            { text: item.table_data[0][`dollar_sum_latest_${time_aggregates}`].abs ? `$${formatNumber(item.table_data[0][`dollar_sum_latest_${time_aggregates}`].abs)}` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item.table_data[0][`dollar_sum_latest_${time_aggregates}`].change ? `${(item.table_data[0][`dollar_sum_latest_${time_aggregates}`].change).toFixed(1)}%` : 'NA', options: { breakLine: true, color: Math.sign(item.table_data[0][`dollar_sum_latest_${time_aggregates}`].change) === -1 ? 'ec3225' : '00c653', fontSize: 12, fontFace: "Mars Centra" } },
            { text: "\n" },
            { text: item.table_data[0][`dollar_share_latest_${time_aggregates}`].abs ? `${(item.table_data[0][`dollar_share_latest_${time_aggregates}`].abs).toFixed(1)}%` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item.table_data[0][`dollar_share_latest_${time_aggregates}`].change ? `${(item.table_data[0][`dollar_share_latest_${time_aggregates}`].change).toFixed(1)} bps` : 'NA', options: { color: Math.sign(item.table_data[0][`dollar_share_latest_${time_aggregates}`].change) === -1 ? 'ec3225' : '00c853', fontSize: 12, fontFace: "Mars Centra" } },
          ], {
            ...textOptions,
            x: 5.6,
            y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.8) - 0.5) * i)) : textOptions.y
          });
          window['slide' + (index + 1)].addText([
            { text: item.table_data[1][`dollar_sum_latest_${time_aggregates}`].abs ? `$${formatNumber(item.table_data[1][`dollar_sum_latest_${time_aggregates}`].abs)}` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item.table_data[1][`dollar_sum_latest_${time_aggregates}`].change ? `${(item.table_data[1][`dollar_sum_latest_${time_aggregates}`].change).toFixed(1)}%` : 'NA', options: { breakLine: true, fontSize: 12, fontFace: "Mars Centra", color: Math.sign(item.table_data[1][`dollar_sum_latest_${time_aggregates}`].change) === -1 ? 'ec3225' : '00c853' } },
            { text: "\n" },
            { text: item.table_data[1][`dollar_share_latest_${time_aggregates}`].abs ? `${(item.table_data[1][`dollar_share_latest_${time_aggregates}`].abs).toFixed(1)}%` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item.table_data[1][`dollar_share_latest_${time_aggregates}`].change ? `${(item.table_data[1][`dollar_share_latest_${time_aggregates}`].change).toFixed(1)} bps` : 'NA', options: { color: Math.sign(item.table_data[1][`dollar_share_latest_${time_aggregates}`].change) === -1 ? 'ec3225' : '00c853', fontSize: 12, fontFace: "Mars Centra" } },
          ], {
            ...textOptions,
            x: 7.3,
            y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.8) - 0.5) * i)) : textOptions.y
          });
          window['slide' + (index + 1)].addText([
            { text: item.table_data[2][`dollar_sum_latest_${time_aggregates}`].abs ? `$${formatNumber(item.table_data[2][`dollar_sum_latest_${time_aggregates}`].abs)}` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item.table_data[2][`dollar_sum_latest_${time_aggregates}`].change ? `${(item.table_data[2][`dollar_sum_latest_${time_aggregates}`].change).toFixed(1)}%` : 'NA', options: { breakLine: true, color: Math.sign(item.table_data[2][`dollar_sum_latest_${time_aggregates}`].change) === -1 ? 'ec3225' : '00c853', fontSize: 12, fontFace: "Mars Centra" } },
            { text: "\n" },
            { text: item.table_data[2][`dollar_share_latest_${time_aggregates}`].abs ? `${(item.table_data[2][`dollar_share_latest_${time_aggregates}`].abs).toFixed(1)}%` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item.table_data[2][`dollar_share_latest_${time_aggregates}`].change ? `${(item.table_data[2][`dollar_share_latest_${time_aggregates}`].change).toFixed(1)} bps` : 'NA', options: { color: Math.sign(item.table_data[2][`dollar_share_latest_${time_aggregates}`].change) === -1 ? 'ec3225' : '00c853', fontSize: 12, fontFace: "Mars Centra" } },

          ], {
            ...textOptions,
            x: 9,
            y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.8) - 0.5) * i)) : textOptions.y
          });
          window['slide' + (index + 1)].addText([
            { text: item.table_data[3][`dollar_sum_latest_${time_aggregates}`].abs ? `$${formatNumber(item.table_data[3][`dollar_sum_latest_${time_aggregates}`].abs)}` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item.table_data[3][`dollar_sum_latest_${time_aggregates}`].change ? `${(item.table_data[3][`dollar_sum_latest_${time_aggregates}`].change).toFixed(1)}%` : 'NA', options: { breakLine: true, color: Math.sign(item.table_data[3][`dollar_sum_latest_${time_aggregates}`].change) === -1 ? 'ec3225' : '00c853', fontSize: 12, fontFace: "Mars Centra" } },
            { text: "\n" },
            { text: item.table_data[3][`dollar_share_latest_${time_aggregates}`].abs ? `${(item.table_data[3][`dollar_share_latest_${time_aggregates}`].abs).toFixed(1)}%` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item.table_data[3][`dollar_share_latest_${time_aggregates}`].change ? `${(item.table_data[3][`dollar_share_latest_${time_aggregates}`].change).toFixed(1)} bps` : 'NA', options: { color: Math.sign(item.table_data[3][`dollar_share_latest_${time_aggregates}`].change) === -1 ? 'ec3225' : '00c853', fontSize: 12, fontFace: "Mars Centra" } },

          ], {
            ...textOptions,
            x: 10.7,
            y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.8) - 0.5) * i)) : textOptions.y
          });

          if (card[0] === item.brand) {
            const pagesRsv = createCustomTableForBrand(psColsRsv, item.table_data, card);
            rsvArr.push(pagesRsv)
          }
          if (card[0] === item.brand) {
            const pagesShare = createCustomTableForBrandShare(psColsShare, item.table_data, card);
            shareArr.push(pagesShare)
          }
          if (card[0] === item.brand) {
            const pagesTdp = createCustomTableForBrandTdp(psColsTdp, item.table_data, card);
            tdpArr.push(pagesTdp)
          }
          if (card[0] === item.brand) {
            const pagesDollarTdp = createCustomTableForBrand$Tdp(psColsDollarTdp, item.table_data, card);
            $TdpArr.push(pagesDollarTdp)
          }
          if (card[0] === item.brand) {
            isActive = true
          }

          // For Financial Table Data
          if (item.mars_brand == true) {
            if (card[0] === item.brand) {
              const pagesFianancialGsv = createCustomTableForBrandGsv(psColsFinancialGsv, item.table_data, card);
              gsvArr.push(pagesFianancialGsv)
            }
            if (card[0] === item.brand) {
              const pagesFianancialNsv = createCustomTableForBrandNsv(psColsFinancialNSV, item.table_data, card);
              nsvArr.push(pagesFianancialNsv)
            }
            if (card[0] === item.brand) {
              const pagesFianancialMac = createCustomTableForBrandMac(psColsFinancialMAC, item.table_data, card);
              macArr.push(pagesFianancialMac)
            }
          }
        })
      }
      if (selectCard === true) {
        // Table 1
        const slideChart = pres.addSlide();

        slideChart.addText(`Filters applied: ​Brand – ${DefaultBrand}; Pet – ${exportBrand?.filters?.product_pet}; Technology – ${exportBrand?.filters?.product_technology}; Year – ${exportBrand?.filters?.time_year}; Period – ${exportBrand?.filters?.time_period}; Time Aggregates – ${getTimeAggregates(exportBrand?.filters?.time_aggregates)}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });

        slideChart.addText(`Deep dive to ${card[0]}-${card[1]} `, {
          x: 1.5,
          y: 1,
          bold: true,
          align: "center",
          fontFace: "Mars Centra",
          fontSize: 16,
        });
        const colW = [
          2, 1, 1, 1, 1, 1, 1, 1, 1, 1
        ];
        rsvArr && rsvArr[0].forEach((page, index) => {
          
          // newArr.push([...newArr, ...page])
          if (index === 0)
            slideChart.addTable(page, {
              y: 1.5,
              // x: 1,
              align: "center",
              colW,
            });
          else {
            const slide4 = pres.addSlide();
            slide4.addTable(page, {
              y: 1.5,
              align: "center",
              colW,
            });
          }
        })
        
        //  Table 2

        // slide3.addText(`Filters applied: ​Brand – ${DefaultBrand}; Pet – ${exportBrand?.filters?.product_pet}; Technology – ${exportBrand?.filters?.product_technology}; Year – ${exportBrand?.filters?.time_year}; Period – ${exportBrand?.filters?.time_period}; Time Aggregates – ${getTimeAggregates(exportBrand?.filters?.time_aggregates)}`, { x: 0.5, y: 0.5, fontSize: 14 ,fontFace: "Mars Centra"});    

        // slideChart.addText(`Deep dive to ${card[0]}-${card[1]} `, {
        //         x:1.5,
        //         y:1.5,
        //         bold:true,
        //         align: "center",
        //         fontFace: "Mars Centra",
        //         fontSize:16,
        // });
        const colW2 = [
          2, 1, 1, 1, 1, 1, 1
        ];
        shareArr[0].forEach((page, index) => {
          if (index === 0)
            slideChart.addTable(page, {
              y: 4.5,
              // x: 1,
              align: "center",
              colW2,
            });
          else {
            const slide4 = pres.addSlide();
            slide4.addTable(page, {
              y: 4.5,
              align: "center",
              colW2,
            });
          }
        })
        // Table 3
        const slide3 = pres.addSlide();

        slide3.addText(`Filters applied: ​Brand – ${DefaultBrand}; Pet – ${exportBrand?.filters?.product_pet}; Technology – ${exportBrand?.filters?.product_technology}; Year – ${exportBrand?.filters?.time_year}; Period – ${exportBrand?.filters?.time_period}; Time Aggregates – ${getTimeAggregates(exportBrand?.filters?.time_aggregates)}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });

        slide3.addText(`Deep dive to ${card[0]}-${card[1]} `, {
          x: 1.5,
          y: 1,
          bold: true,
          align: "center",
          fontFace: "Mars Centra",
          fontSize: 16,
        });
        tdpArr[0].forEach((page, index) => {
          if (index === 0)
            slide3.addTable(page, {
              y: 1.5,
              // x:0.5,
              align: "center",
              colW2,
            });
          else {
            const slide5 = pres.addSlide();
            slide5.addTable(page, {
              y: 1.5,
              align: "center",
              colW2,
            });
          }
        })
        // Table 4

        $TdpArr[0].forEach((page, index) => {
          if (index === 0)
            slide3.addTable(page, {
              y: 4.5,
              // x: 0.5,
              align: "center",
              colW2,
            });
          else {
            const slide4 = pres.addSlide();
            slide4.addTable(page, {
              y: 4.5,
              align: "center",
              colW2,
            });
          }
        })
        // Table 5
        if (gsvArr.length || nsvArr.length || macArr.length > 0) {
          const slide5 = pres.addSlide();

          slide5.addText(`Filters applied: ​Brand – ${DefaultBrand}; Pet – ${exportBrand?.filters?.product_pet}; Technology – ${exportBrand?.filters?.product_technology}; Year – ${exportBrand?.filters?.time_year}; Period – ${exportBrand?.filters?.time_period}; Time Aggregates – ${getTimeAggregates(exportBrand?.filters?.time_aggregates)}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });

          slide5.addText(`Deep dive to ${card[0]}-${card[1]} `, {
            x: 1.5,
            y: 1,
            bold: true,
            align: "center",
            fontFace: "Mars Centra",
            fontSize: 16,
          });
          gsvArr[0].forEach((page, index) => {
            if (index === 0)
              slide5.addTable(page, {
                y: 1.5,
                // x: 0.5,
                align: "center",
                colW2,
              });
            else {
              const slide6 = pres.addSlide();
              slide6.addTable(page, {
                y: 1.5,
                align: "center",
                colW,
              });
            }
          })
          // Table 6

          nsvArr[0].forEach((page, index) => {
            if (index === 0)
              slide5.addTable(page, {
                y: 4.5,
                // x: 0.5,
                align: "center",
                colW2,
              });
            else {
              const slide6 = pres.addSlide();
              slide6.addTable(page, {
                y: 4.5,
                align: "center",
                colW,
              });
            }
          })
          // Table 7
          const slide7 = pres.addSlide();

          slide7.addText(`Filters applied: ​Brand – ${DefaultBrand}; Pet – ${exportBrand?.filters?.product_pet}; Technology – ${exportBrand?.filters?.product_technology}; Year – ${exportBrand?.filters?.time_year}; Period – ${exportBrand?.filters?.time_period}; Time Aggregates – ${getTimeAggregates(exportBrand?.filters?.time_aggregates)}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });

          slide7.addText(`Deep dive to ${card[0]}-${card[1]} `, {
            x: 1.5,
            y: 1,
            bold: true,
            align: "center",
            fontFace: "Mars Centra",
            fontSize: 16,
          });
          // let newArr2 = []
          macArr[0].forEach((page, index) => {
            // newArr2.push(...page)
            if (index === 0)
              slide7.addTable(page, {
                y: 1.5,
                // x: 0.5,
                align: "center",
                colW2,
              });
            else {
              const slide8 = pres.addSlide();
              slide8.addTable(page, {
                y: 1.5,
                align: "center",
                colW2,
              });
            }
          })
        }
      }
      if (DefaultBrand.toUpperCase() !== allBrands) {
        if (exportBrand?.waterfall?.data?.length > 0) {
          let slide2 = pres.addSlide();
          let lableName = exportBrand?.waterfall?.data?.map((value) => {
            return value.label
          })
          let arrData = exportBrand?.waterfall?.data?.map((value) => {
            return value.difference
          })
          let arrData2 = []
          exportBrand?.waterfall?.data?.length > 0 && exportBrand.waterfall.data.forEach((data, index) => {
            if (index === 0) {
              arrData2.push(0)
            } else if (index === exportBrand.waterfall.data.length - 1) {
              arrData2.push(0)

            } else {
              let val1 = Math.sign(data.value);
              let val2
              if (val1 == 1 || val1 == 0) {
                let sum = 0;

                for (let i = 0; i <= index - 1; i++) {
                  sum += arrData[i];
                }
                arrData2.push(sum)
              } else {
                val2 = arrData.filter((item, i) => i < index - 1).reduce((partialSum, a) => partialSum + a, 0) - data.value
              }
              // arrData2.push(val2)
            }
          })
          
          let chart1data = [arrData[0], arrData[arrData.length - 1]]
          let chart2data = arrData.slice(1, -1);
          let chart1lable = [lableName[0], lableName[lableName.length - 1]]
          let chart2lable = lableName.slice(1, -1);
          
          let colorCode = chart1data.map((item) => Math.sign(item) === 1 ? "4E9D2D" : "DE4216")
          let colorCode1 = chart2data.map((item) => Math.sign(item) === 1 ? "4E9D2D" : "DE4216")


          let waterfalldata1 = [
            {
              name: "Region 3",
              labels: chart1lable,
              values: chart1data,
            }
          ]
          let waterfalldata2 = [
            {
              name: "Region 4",
              labels: chart2lable,
              values: chart2data,
            }
          ]
          slide2.addText(`Filters applied: ​Brand – ${DefaultBrand}; Pet – ${exportBrand?.filters?.product_pet}; Technology – ${exportBrand?.filters?.product_technology}; Year – ${exportBrand?.filters?.time_year}; Period – ${exportBrand?.filters?.time_period}; Time Aggregates – ${getTimeAggregates(exportBrand?.filters?.time_aggregates)}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });

          slide2.addText(`Volume Shifting [Eq. Lbs]`, { x: 5.3, y: 1, fontSize: 16, fontFace: "Mars Centra", bold: true });

          slide2.addText(`${chart1lable[0]}\n${formatNumber(chart1data[0])}`, {
            x: 5,
            w: 1.2,
            h: 0.8,
            y: 1.5,
            align: "center",
            line: { width: "2", color: "dcdcdc" },
            fill: "ffffff",
            shape: pres.ShapeType.roundRect,
            rectRadius: 0.2,
            fontSize: 12,
            fontFace: "Mars Centra"
          });

          slide2.addText(`${chart1lable[1]}\n${formatNumber(chart1data[1])}`, {
            x: 6.2,
            w: 1.2,
            h: 0.8,
            y: 1.5,
            align: "center",
            line: { width: "2", color: "dcdcdc" },
            fill: "ffffff",
            shape: pres.ShapeType.roundRect,
            rectRadius: 0.2,
            fontSize: 12,
            fontFace: "Mars Centra"
          });

          slide2.addChart(pres.charts.BAR, waterfalldata2, {
            x: 3,
            y: 2.5,
            w: 7,
            h: 4,
            chartArea: { fill: { color: "404040" } },
            showValue: true,
            // dataLabelFormatCode: '[>=1000000]#,##0.0,,"M";[>=1000]#,##0,"K"',
            dataLabelFormatCode: '##0.0',
            barDir: "col",
            // chartColors: [Math.sign(arrData) === 1 ?  "4E9D2D" : "0077BF"],
            chartColors: colorCode1,
            //
            // valAxisMajorUnit: 15,
            // valAxisDisplayUnit: "hundreds",
            // valAxisMaxVal: 45,
            // valLabelFormatCode: "$0", // @since v3.3.0
            catGridLine: { style: "none" },
            valGridLine: { style: "none" },
            valAxisHidden: false,
            catAxisHidden: false,
            catAxisLabelFontSize: 9,
            valAxisLabelFontSize: 9,
            //
            // showTitle: true,
            // title: "Categories can be Multi-Color",
            // titleColor: "0088CC",
            // titleFontSize: 14
          });


          // slide.addText(`Filters applied:`, { x: 1.5, y: 1.5, w: 20, fontSize: 35 ,fontFace: "Mars Centra"});

          // slide.addText(`​Brand – ${DefaultBrand}; Pet – ${exportBrand?.filters?.product_pet}; Technology – ${exportBrand?.filters?.product_technology}; Year – ${exportBrand?.filters?.time_year}; Period – ${exportBrand?.filters?.time_period}; Time Aggregates – ${getTimeAggregates(exportBrand?.filters?.time_aggregates)}`, { x: 1.5, y: 2.5, w: 20, fontSize: 34 ,fontFace: "Mars Centra" });
        }
      }
      pres.writeFile({ fileName: filename })
    }
    else {
      const items = exportData
      const result = await createGroups(items, 3)
     
      pres.defineLayout({ name: 'A3', width: 13, height: 7.5 });
      pres.layout = 'A3'
      const textLableOptions = {
        w: 1.2,
        h: 0.5,
        y: 1.5,
        align: "center",
        fill: "ffffff",
        fontSize: 12,
        fontFace: "Mars Centra"
      };
      const textOptions = {
        w: 1.1,
        h: 1,
        y: 2,
        align: "center",
        line: { width: "2", color: "dcdcdc" },
        fill: "ffffff",
        shape: pres.ShapeType.roundRect,
        rectRadius: 0.2,
        fontSize: 10,
        fontFace: "Mars Centra",
        // margin: 0.5
      };
      let scroll = false;
      let imageSize = { x: 1, y: 0.5, w: 15, h: 4 }
      // await html2canvas(
      //   document.getElementsByClassName('volume-head')[0],
      //   scroll && { scrollY: -window.scrollY }
      // ).then((canvas) => {
      //   let image = canvas.toDataURL("image/png");
      //   let imageString = String(image);
      
      //   slide.addImage({ ...imageSize, data: String(imageString.split(":")[1]) });
      // });
      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        
        let slide = pres.addSlide();
        slide.addText(`Filters applied: ​Brand – ${DefaultBrand}; Pet – ${exportBrand?.filters?.product_pet}; Technology – ${exportBrand?.filters?.product_technology}; Year – ${exportBrand?.filters?.time_year}; Period – ${exportBrand?.filters?.time_period}; Time Aggregates – ${getTimeAggregates(exportBrand?.filters?.time_aggregates)}`, { x: 0.5, y: 0.5, w: 20, fontSize: 14, fontFace: "Mars Centra" });
        if (compare_via[0] === 'retailer') {
          
          slide.addText(`Compare Via : ${compare_via[0]} , Channel : ${channel[0]}`, { x: 0.5, y: 0.8, w: 10, fontSize: 14, fontFace: "Mars Centra" })
        }
        slide.addText(`Volume Shifting`, { x: 1.5, y: 1, fontSize: 16, fontFace: "Mars Centra", bold: true, align: 'center', margin: [0, 0, 0, 3] });

        element.map((item, i) => {
          slide.addText(``, {
            ...textLableOptions,
            x: 0.4,
            y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
          });
          slide.addText(``, {
            ...textLableOptions,
            x: 1.8,
            y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
          });
          for (const [index, card] of item.cards.entries()) {

            slide.addText(`${card.brand}`, {
              ...textLableOptions,
              x: index > 0 ? 3.2 + (1.4 * index) : 3.2,
              y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
            });

          }

          //cards
          slide.addText(`${compare_via[0] === 'retailer' ? item.retailer : item.channel}`, {
            ...textOptions,
            x: 0.4,
            y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.7) - 0.5) * i)) : textOptions.y
          });
          slide.addText([
            { text: "$ Sales mm", options: { breakLine: true, fontSize: 10, fontFace: "Mars Centra", color: '4d4a4a' } },
            { text: "%CYA", options: { breakLine: true, fontSize: 12, fontFace: "Mars Centra", color: '4d4a4a' } },
            { text: "\n" },
            { text: "$ Share", options: { breakLine: true, fontSize: 12, fontFace: "Mars Centra", color: '4d4a4a' } },
            { text: "%CYA", options: { fontSize: 12, fontFace: "Mars Centra", color: '4d4a4a' } },
          ], {
            ...textOptions,
            x: 1.8,
            y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.7) - 0.5) * i)) : textOptions.y
          });
          for (const [index, card] of item.cards.entries()) {

            if (card.brand === 'TOTAL') {
              slide.addText([
                { text: card[`dollar_sum_latest_${time_aggregates}`] ? `$${formatNumber(card[`dollar_sum_latest_${time_aggregates}`])}` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
                { text: card[`dollar_variance_latest_${time_aggregates}`] ? `${(card[`dollar_variance_latest_${time_aggregates}`]).toFixed(1)} %` : 'NA', options: { color: Math.sign(card[`dollar_variance_latest_${time_aggregates}`]) === -1 ? 'ec3225' : '00c853', fontSize: 12, fontFace: "Mars Centra" } },
              ], {
                ...textOptions,
                x: index > 0 ? 4.4 + (1.1 * index) : 3.2,
                y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.7) - 0.5) * i)) : textOptions.y
              });
            } else {

              slide.addText([
                { text: card[`dollar_sum_latest_${time_aggregates}`] ? `$${formatNumber(card[`dollar_sum_latest_${time_aggregates}`])}` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
                { text: card[`dollar_variance_latest_${time_aggregates}`] ? `${(card[`dollar_variance_latest_${time_aggregates}`]).toFixed(1)}%` : 'NA', options: { breakLine: true, color: Math.sign(card[`dollar_variance_latest_${time_aggregates}`]) === -1 ? 'ec3225' : '00c853', fontSize: 12, fontFace: "Mars Centra" } },
                { text: "\n" },
                { text: card[`dollar_share_latest_${time_aggregates}`] ? `${(card[`dollar_share_latest_${time_aggregates}`]).toFixed(1)}%` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
                { text: card[`dollar_share_variance_latest_${time_aggregates}`] ? `${(card[`dollar_share_variance_latest_${time_aggregates}`]).toFixed(1)} bps` : 'NA', options: { color: Math.sign(card[`dollar_share_variance_latest_${time_aggregates}`]) === -1 ? 'ec3225' : '00c853', fontSize: 12, fontFace: "Mars Centra" } },
              ], {
                ...textOptions,
                x: index > 0 ? 3.2 + (1.4 * index) : 3.2,
                y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.7) - 0.5) * i)) : textOptions.y
              });

            }
          }


        })
      }

      if (DefaultBrand.toUpperCase() !== allBrands) {
        if (exportBrand?.waterfall?.data?.length > 0) {
          let slide2 = pres.addSlide();
          let lableName = exportBrand?.waterfall?.data?.map((value) => {
            return value.label
          })
          let arrData = exportBrand?.waterfall?.data?.map((value) => {
            return value.difference
          })
          let arrData2 = []
          exportBrand.waterfall.data.forEach((data, index) => {
            if (index === 0) {
              arrData2.push(0)
            } else if (index === exportBrand.waterfall.data.length - 1) {
              arrData2.push(0)

            } else {
              let val1 = Math.sign(data.value);
              let val2
              if (val1 == 1 || val1 == 0) {
                let sum = 0;

                for (let i = 0; i <= index - 1; i++) {
                  sum += arrData[i];
                }
                arrData2.push(sum)
              } else {
                val2 = arrData.filter((item, i) => i < index - 1).reduce((partialSum, a) => partialSum + a, 0) - data.value
              }
              // arrData2.push(val2)
            }
          })
         
          let chart1data = [arrData[0], arrData[arrData.length - 1]]
          let chart2data = arrData.slice(1, -1);
          let chart1lable = [lableName[0], lableName[lableName.length - 1]]
          let chart2lable = lableName.slice(1, -1);
          
          let colorCode = chart1data.map((item) => Math.sign(item) === 1 ? "4E9D2D" : "DE4216")
          let colorCode1 = chart2data.map((item) => Math.sign(item) === 1 ? "4E9D2D" : "DE4216")


          let waterfalldata1 = [
            {
              name: "Region 3",
              labels: chart1lable,
              values: chart1data,
            }
          ]
          let waterfalldata2 = [
            {
              name: "Region 4",
              labels: chart2lable,
              values: chart2data,
            }
          ]
          slide2.addText(`Filters applied: ​Brand – ${DefaultBrand}; Pet – ${exportBrand?.filters?.product_pet}; Technology – ${exportBrand?.filters?.product_technology}; Year – ${exportBrand?.filters?.time_year}; Period – ${exportBrand?.filters?.time_period}; Time Aggregates – ${getTimeAggregates(exportBrand?.filters?.time_aggregates)}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });

          slide2.addText(`Volume Shifting [Eq. Lbs]`, { x: 5.3, y: 1, fontSize: 16, fontFace: "Mars Centra", bold: true });

          slide2.addText(`${chart1lable[0]}\n${formatNumber(chart1data[0])}`, {
            x: 5,
            w: 1.2,
            h: 0.8,
            y: 1.5,
            align: "center",
            line: { width: "2", color: "dcdcdc" },
            fill: "ffffff",
            shape: pres.ShapeType.roundRect,
            rectRadius: 0.2,
            fontSize: 12,
            fontFace: "Mars Centra"
          });

          slide2.addText(`${chart1lable[1]}\n${formatNumber(chart1data[1])}`, {
            x: 6.2,
            w: 1.2,
            h: 0.8,
            y: 1.5,
            align: "center",
            line: { width: "2", color: "dcdcdc" },
            fill: "ffffff",
            shape: pres.ShapeType.roundRect,
            rectRadius: 0.2,
            fontSize: 12,
            fontFace: "Mars Centra"
          });

          slide2.addChart(pres.charts.BAR, waterfalldata2, {
            x: 3,
            y: 2.5,
            w: 7,
            h: 4,
            chartArea: { fill: { color: "404040" } },
            showValue: true,
            // dataLabelFormatCode: '[>=1000000]#,##0.0,,"M";[>=1000]#,##0,"K"',
            dataLabelFormatCode: '##0.0',
            barDir: "col",
            // chartColors: [Math.sign(arrData) === 1 ?  "4E9D2D" : "0077BF"],
            chartColors: colorCode1,
            //
            // valAxisMajorUnit: 15,
            // valAxisDisplayUnit: "hundreds",
            // valAxisMaxVal: 45,
            // valLabelFormatCode: "$0", // @since v3.3.0
            catGridLine: { style: "none" },
            valGridLine: { style: "none" },
            valAxisHidden: false,
            catAxisHidden: false,
            catAxisLabelFontSize: 9,
            valAxisLabelFontSize: 9,
            //
            // showTitle: true,
            // title: "Categories can be Multi-Color",
            // titleColor: "0088CC",
            // titleFontSize: 14
          })
        }
      }
      pres.writeFile({ fileName: filename })
    }


    openNotificationWithIcon('success', 'Downloading..');
  }
  catch (err) {
    
    openNotificationWithIcon('error', 'Download Failed');
  }
}

export async function pptExportBeAvailable(
  exportData,
  filename,
  macroColumns,
  microColumns,
  defaultBrand
) {
  // nutroSKUColumnsExport,
  // allSKUColumnsExport
  
  
  const { filters, bar, line, macroTableData, microTableData } = JSON.parse(
    JSON.stringify(exportData)
  );

  try {
    // if (Object.keys(pptLine).length === 0) throw new Error("No Data");
    // if (Object.keys(pptTableData).length === 0) throw new Error("No Data");
    // if (Object.keys(pptScatter).length === 0) throw new Error("No Data");

    let pres = new pptxgen();
    // Define new layout for the Presentation
    pres.defineLayout({ name: "A3", width: 13, height: 7.5 });
    pres.layout = "A3";

    const slide = pres.addSlide();
    // Filters & KPIs
    slide.addText(
      [
        { text: "Filters applied:", options: { breakLine: true } },
        {
          text: `Brand - ${defaultBrand}; Pet - ${filters?.product_pet}; Technology - ${filters?.product_technology}; Feeding Philosophy - ${filters?.product_feed_philosophy}; Texture - ${filters?.product_texture};`,
          options: { breakLine: true },
        },
        {
          text: `Retailer - ${filters?.customer_retailer_details} Time Aggregate - ${filters?.time_aggregate}; Year Period - ${filters?.year_period}; Benchmark Period - ${filters?.benchmark};`,
          options: { breakLine: true },
        },
      ],
      {
        x: 0.5,
        y: 0.5,
        w: "90%",
        valign: "top",
        fontSize: 14,
        fontFace: "Mars Centra",
      }
    );

    // slide.addText(`Filters applied: Brand - ${defaultBrand}; Pet - ${filters?.product_pet}; Technology - ${filters?.product_technology}; Feeding Philosophy - ${filters?.product_feed_philosophy}; Texture - ${filters?.product_texture}; Retailer - ${filters?.customer_retailer_details} Time Aggregate - ${filters?.time_aggregate}; Year Period - ${filters?.year_period}; Benchmark Period - ${filters?.benchmark};`, { x: 0.5, y: 0.5, w: 20, fontSize: 14, fontFace: "Mars Centra" });

    slide.addText(`Distribution Summary`, {
      x: 1.5,
      y: 3,
      fontSize: 20,
      fontFace: "Mars Centra",
      align: "center",
      bold: true,
    });

    const textOptions = {
      w: 3,
      h: 1.3,
      y: 2.5,
      align: "center",
      line: { width: "2", color: "dcdcdc" },
      fill: "ffffff",
      shape: pres.ShapeType.roundRect,
      rectRadius: 0.2,
      fontSize: 14,
      margin: 0.5,
    };
    // const textOptionsData = {
    //   w: 1.5,
    //   h: 1,
    //   y: 2,
    //   align: "center",
    //   line: { width: "2", color: "dcdcdc" },
    //   fill: "ffffff",
    //   shape: pres.ShapeType.roundRect,
    //   rectRadius: 0.2,
    //   fontSize: 14,
    //   margin: 0.5
    // };

    slide.addText(
      [
        {
          text: `${exportData?.kpi?.listed?.title}`,
          options: {
            breakLine: true,
            fontSize: 18,
            fontFace: "Mars Centra",
            color: "4d4a4a",
            bold: true,
          },
        },
        { text: "\n", options: { breakLine: true } },
        {
          text: `${addSymbol(
            exportData?.kpi?.listed?.value,
            exportData?.kpi?.listed?.valueSymbol
          )}`,
          options: {
            fontSize: 25,
            bold: true,
            fontFace: "Mars Centra",
            color: "0000A0",
          },
        },
        { text: "  " },
        {
          text: `${addSymbol(
            formatNumber(Math.abs(exportData?.kpi?.listed?.change)),
            exportData?.kpi?.listed?.changeSymbol
          )}`,
          options: {
            fontSize: 18,
            bold: true,
            fontFace: "Mars Centra",
            color: exportData?.kpi?.listed?.change < 0 ? "EC3225" : "00C853",
          },
        },
      ],
      {
        ...textOptions,
        x: 1,
        y: 3.5,
      }
    );
    // slide.addText([
    //   { text: `vs YA`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: '4d4a4a', bold: true } },
    //   { text: "\n", options: { breakLine: true } },
    //   { text: `${(exportData?.kpi?.tdp?.vsYA).toFixed(1)}%`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: '00C853' } },
    // ], {
    //   ...textOptionsData,
    //   x: 1,
    //   y: 4.4,
    // });
    // slide.addText([
    //   { text: `Mark vs YA`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: '4d4a4a', bold: true } },
    //   { text: "\n", options: { breakLine: true } },
    //   { text: `${(exportData?.kpi?.tdp?.marsVsYA).toFixed(1)}%`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: 'EC3225' } },
    // ], {
    //   ...textOptionsData,
    //   x: 2.5,
    //   y: 4.4,
    // });
    slide.addText(
      [
        {
          text: `${exportData?.kpi?.in_stock?.title}`,
          options: {
            breakLine: true,
            fontSize: 18,
            fontFace: "Mars Centra",
            color: "4d4a4a",
            bold: true,
          },
        },
        { text: "\n", options: { breakLine: true } },
        {
          text: `${addSymbol(
            exportData?.kpi?.in_stock?.value,
            exportData?.kpi?.in_stock?.valueSymbol
          )}`,
          options: {
            fontSize: 25,
            bold: true,
            fontFace: "Mars Centra",
            color: "0000A0",
          },
        },
        { text: "  " },
        {
          text: `${addSymbol(
            formatNumber(Math.abs(exportData?.kpi?.in_stock?.change)),
            exportData?.kpi?.in_stock?.changeSymbol
          )}`,
          options: {
            fontSize: 18,
            bold: true,
            fontFace: "Mars Centra",
            color: exportData?.kpi?.in_stock?.change < 0 ? "EC3225" : "00C853",
          },
        },
      ],
      {
        ...textOptions,
        x: 5,
        y: 3.5,
      }
    );
    // slide.addText([
    //   { text: `vs YA`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: '4d4a4a', bold: true } },
    //   { text: "\n", options: { breakLine: true } },
    //   { text: `${(exportData?.kpi?.dollarVsTdp?.vsYA).toFixed(1)}%`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: '00C853' } },
    // ], {
    //   ...textOptionsData,
    //   x: 5,
    //   y: 4.4,
    // });
    // slide.addText([
    //   { text: `Mark vs YA`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: '4d4a4a', bold: true } },
    //   { text: "\n", options: { breakLine: true } },
    //   { text: `${(exportData?.kpi?.dollarVsTdp?.marsVsYA).toFixed(1)}%`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: 'EC3225' } },
    // ], {
    //   ...textOptionsData,
    //   x: 6.5,
    //   y: 4.4,
    // });
    slide.addText(
      [
        {
          text: `${exportData?.kpi?.csl?.title}`,
          options: {
            breakLine: true,
            fontSize: 18,
            fontFace: "Mars Centra",
            color: "4d4a4a",
            bold: true,
          },
        },
        { text: "\n", options: { breakLine: true } },
        {
          text: `${addSymbol(
            exportData?.kpi?.csl?.value,
            exportData?.kpi?.csl?.valueSymbol
          )}`,
          options: {
            fontSize: 25,
            bold: true,
            fontFace: "Mars Centra",
            color: "0000A0",
          },
        },
        { text: "  " },
        {
          text: `${addSymbol(
            formatNumber(Math.abs(exportData?.kpi?.csl?.change)),
            exportData?.kpi?.csl?.changeSymbol
          )}`,
          options: {
            fontSize: 18,
            bold: true,
            fontFace: "Mars Centra",
            color: exportData?.kpi?.csl?.change < 0 ? "EC3225" : "00C853",
          },
        },
      ],
      {
        ...textOptions,
        x: 9,
        y: 3.5,
      }
    );
    // slide.addText([
    //   { text: `vs YA`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: '4d4a4a', bold: true } },
    //   { text: "\n", options: { breakLine: true } },
    //   { text: `${(exportData?.kpi?.csl?.vsYA).toFixed(1)} pts`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: '00C853' } },
    // ], {
    //   ...textOptionsData,
    //   x: 9,
    //   y: 4.4,
    // });
    // slide.addText([
    //   { text: `Mark vs YA`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: '4d4a4a', bold: true } },
    //   { text: "\n", options: { breakLine: true } },
    //   { text: `${(exportData?.kpi?.csl?.marsVsYA).toFixed(1)} pts`, options: { breakLine: true, fontSize: 16, fontFace: "Mars Centra", color: 'EC3225' } },
    // ], {
    //   ...textOptionsData,
    //   x: 10.5,
    //   y: 4.4,
    // });

    // Line Chart 1

    const slide2 = pres.addSlide();
    slide2.addText(
      [
        { text: "Filters applied:", options: { breakLine: true } },
        {
          text: `Brand - ${defaultBrand}; Pet - ${filters?.product_pet}; Technology - ${filters?.product_technology}; Feeding Philosophy - ${filters?.product_feed_philosophy}; Texture - ${filters?.product_texture};`,
          options: { breakLine: true },
        },
        {
          text: `Retailer - ${filters?.customer_retailer_details} Time Aggregate - ${filters?.time_aggregate}; Year Period - ${filters?.year_period}; Benchmark Period - ${filters?.benchmark};`,
          options: { breakLine: true },
        },
      ],
      {
        x: 0.5,
        y: 0.5,
        w: "90%",
        valign: "top",
        fontSize: 14,
        fontFace: "Mars Centra",
      }
    );

    const COLORS_CHART = ["#0000a0", "#e559a4"];
    const labelsSeries = bar?.data.map((item) => item.Retailer);
    const value1 = bar?.data.map((item) => item[bar.yField[0]]);
    const value2 = bar?.data.map((item) => item[bar.yField[1]]);

    

    // const dataChartBarSeries = [
    //   { name: bar?.yField[0], labels: labelsSeries, values: value2 },
    //   { name: bar?.yField[1], labels: labelsSeries, values: value1 },
    // ];
    let optsChart = {
      x: 0.5,
      y: 2,
      w: "45%",
      h: "70%",
      barDir: "col",
      barGrouping: "stacked",
      chartColors: COLORS_CHART,
      showLegend: true,
      showTitle: true,
      title: bar.title,
      titleFontFace: "Mars Centra",
      titleFontSize: 15,
      // showCatAxisTitle: true,
      // catAxisTitle: `${bar.xField}`,
      catAxisTitleFontSize: 10,
      valAxisTitleFontSize: 10,
      legendPos: "b",
      showValAxisTitle: true,
      // valAxisTitle: `${line?.yField[0]}`,
      valAxes: [
        {
          showValAxisTitle: true,
          valAxisTitle: `${bar?.yField[0]}`,
        },
        {
          showValAxisTitle: true,
          valAxisTitle: `${bar?.yField[1]}`,
          valGridLine: { style: "none" },
        },
      ],

      catAxes: [
        {
          showCatAxisTitle: true,
          catAxisTitle: `${bar.xField}`,
        },
        {
          catAxisHidden: true,
        },
      ],
      
    };
    let chartTypes1 = [
      {
        type: pres.charts.BAR,
        data: [
          { name: bar?.yField[0], labels: labelsSeries, values: value1 },
        ],
        options: {
          chartColors: ["0000a0"],
          barGrouping: "standard",
        },
      },
      {
        type: pres.charts.BAR,
        data: [
          { name: bar?.yField[1], labels: labelsSeries, values: value2 },
        ],
        options: {
          chartColors: ["e559a4"],
          barGrouping: "standard",
          barGapWidthPct: 185,
          secondaryValAxis: !!optsChart.valAxes,
          secondaryCatAxis: !!optsChart.catAxes,
        },
      },
    ];

    slide2.addChart(chartTypes1, optsChart);

    if (exportData.filters.deep_dive_list.length > 1) {
      // multiline chart
      const chartLabels = line.data.map((item) => item.Periods);
      const lineChart1 = line.data.map((item) => item[line?.series[0]]);
      const lineChart2 = line.data.map((item) => item[line?.series[1]]);
      let optsChartLine3 = {
        x: 7,
        y: 2,
        w: "45%",
        h: "70%",
        barDir: "col",
        titleFontFace: "Mars Centra",
        showLegend: true,
        showTitle: true,
        titlePos: { x: 1.2, y: 1.0 },
        title: line.title,
        catAxisTitleFontSize: 10,
        valAxisTitleFontSize: 10,
        titleFontSize: 15,
        showValAxisTitle: true,
        valAxisTitle: `${line?.yField[1]}`,
        lineDataSymbolSize: 10,
        shadow: { type: "none" },
        legendPos: "b",
      };
      const arrDataLineStat = [
        { name: line?.series[0], labels: chartLabels, values: lineChart1 },
        { name: line?.series[1], labels: chartLabels, values: lineChart2 },
      ];
      slide2.addChart(pres.charts.LINE, arrDataLineStat, optsChartLine3);
    } else {
      // line chart
      const chartLabels = line.data.map((item) => item.Periods);
      const chartValue1 = line.data.map((item) => item[line?.yField[0]]);
      const chartValue2 = line.data.map((item) => item[line?.yField[1]]);

      let opts1 = {
        x: 7,
        y: 2,
        w: "45%",
        h: "70%",
        barDir: "col",
        titleFontFace: "Mars Centra",
        showLegend: true,
        showTitle: true,
        titlePos: { x: 1.2, y: 1.0 },
        title: line.title,
        catAxisTitleFontSize: 10,
        valAxisTitleFontSize: 10,
        titleFontSize: 15,
        valAxes: [
          {
            showValAxisTitle: true,
            valAxisTitle: `${line?.yField[0]}`,
          },
          {
            showValAxisTitle: true,
            valAxisTitle: `${line?.yField[1]}`,
            valGridLine: { style: "none" },
          },
        ],

        catAxes: [
          {
            showCatAxisTitle: true,
            catAxisTitle: "Periods",
          },
          {
            catAxisHidden: true,
          },
        ],
        legendPos: "b",
      };
      let chartTypes1 = [
        {
          type: pres.charts.BAR,
          data: [
            {
              name: line?.yField[0],
              labels: chartLabels,
              values: chartValue1,
            },
          ],
          options: {
            chartColors: ["0000a0"],
            barGrouping: "stacked",
          },
        },
        {
          type: pres.charts.LINE,
          data: [
            {
              name: line?.yField[1],
              labels: chartLabels,
              values: chartValue2,
            },
          ],
          options: {
            chartColors: ["e559a4"],
            barGrouping: "standard",
            secondaryValAxis: !!opts1.valAxes,
            secondaryCatAxis: !!opts1.catAxes,
          },
        },
      ];
      slide2.addChart(chartTypes1, opts1);
    }

    //slide 3
    for (const item of [
      ...exportData?.filters?.customer_retailer_details,
      ...exportData?.filters?.selected_retailer,
    ]) {
      window[`slide3${item}`] = pres.addSlide();

      window[`slide3${item}`].addText(
        [
          { text: "Filters applied:", options: { breakLine: true } },
          {
            text: `Brand - ${defaultBrand}; Pet - ${filters?.product_pet}; Technology - ${filters?.product_technology}; Feeding Philosophy - ${filters?.product_feed_philosophy}; Texture - ${filters?.product_texture};`,
            options: { breakLine: true },
          },
          {
            text: `Retailer - ${filters?.customer_retailer_details} Time Aggregate - ${filters?.time_aggregate}; Year Period - ${filters?.year_period}; Benchmark Period - ${filters?.benchmark};`,
            options: { breakLine: true },
          },
        ],
        {
          x: 0.5,
          y: 0.5,
          w: "90%",
          valign: "top",
          fontSize: 14,
          fontFace: "Mars Centra",
        }
      );

      window[`slide3${item}`].addText(`Retailer Overview - ${item}`, {
        x: 5.5,
        y: 1.6,
        fontSize: 15,
        fontFace: "Mars Centra",
      });
      const slicedMacro = macroTableData.data.slice(0, 7);

      const macroTable = createEcomCustomTable(
        macroColumns,
        slicedMacro,
        false,
        20,
        item,
        ["Sub Brand"]
      );


      

      window[`slide3${item}`].addTable(macroTable[0], {
        x: 0.5,
        y: 1.9,
        h: 4.3,
        // w: 19,
        align: "center",
        // colW: 1,
      });
      window[`slide3${item}`].addText(
        "For complete table, please refer to the excel extract - BeAvailableTableData(tables).xlsx",
        { x: 0.5, y: 7.2, fontSize: 14, fontFace: "Mars Centra" }
      );
    }

    //slide 4
    if (microTableData.data && microTableData.data.length) {
      for (const item of [
        ...exportData?.filters?.customer_retailer_details,
        ...exportData?.filters?.selected_retailer,
      ]) {
        window[`slide4${item}`] = pres.addSlide();

        window[`slide4${item}`].addText(
          [
            { text: "Filters applied:", options: { breakLine: true } },
            {
              text: `Brand - ${defaultBrand}; Pet - ${filters?.product_pet}; Technology - ${filters?.product_technology}; Feeding Philosophy - ${filters?.product_feed_philosophy}; Texture - ${filters?.product_texture};`,
              options: { breakLine: true },
            },
            {
              text: `Retailer - ${filters?.customer_retailer_details} Time Aggregate - ${filters?.time_aggregate}; Year Period - ${filters?.year_period}; Benchmark Period - ${filters?.benchmark};`,
              options: { breakLine: true },
            },
          ],
          {
            x: 0.5,
            y: 0.5,
            w: "90%",
            valign: "top",
            fontSize: 14,
            fontFace: "Mars Centra",
          }
        );

        window[`slide4${item}`].addText(`${microTableData.title} - ${item}`, {
          x: 5,
          y: 1.6,
          fontSize: 15,
          fontFace: "Mars Centra",
        });
        const slicedMicro = microTableData.data.slice(0, 4);
        const microTable = createEcomCustomTable(
          microColumns,
          slicedMicro,
          false,
          20,
          item,
          ["Description", "UPC"]
        );

        window[`slide4${item}`].addTable(microTable[0], {
          x: 0.5,
          y: 1.9,
          h: 4.3,
          // w: 19,
          align: "center",
          // colW: 1,
        });
        window[`slide4${item}`].addText(
          "For complete table, please refer to the excel extract - BeAvailableTableData(tables).xlsx",
          { x: 0.5, y: 7.2, fontSize: 14, fontFace: "Mars Centra" }
        );
      }
    }

    openNotificationWithIcon("success", "Downloading..");
    pres.writeFile({ fileName: filename });
  } catch (error) {
    
    openNotificationWithIcon("error", "Download Failed");
  }
}

export function pptTest() {
  let pptx = new pptxgen();
  const slide = pptx.addSlide();
  let dataChartAreaLine = [
    {
      name: "Donut",
      labels: ["YTD", "", ""],
      values: [7.3, 92.7, 100],
    },
  ];

  slide.addChart(pptx.ChartType.doughnut, dataChartAreaLine, {
    x: 1,
    y: 1,
    w: 8,
    h: 4,
    firstSliceAng: 270,
    holeSize: 65,
  });

  pptx.writeFile({ fileName: "pptxgenjs-demo-react.pptx" });
}

export async function pptExportPortfolio(filename, sections = [], tableData, channel_data, retailer_data, filters, pCols, card, exportBrand, DefaultBrand, psColsRsv, psColsShare, psColsTdp, psColsDollarTdp, psColsFinancialRsv, psColsFinancialGsv, psColsFinancialNSV, psColsFinancialMAC, channel, selectCard) {
  
  const { compare_via, time_aggregates } = filters;
  let exportData
  if (compare_via[0] === 'brand') {
    exportData = tableData
  } else if (compare_via[0] === 'channel') {
    exportData = channel_data
  } else if (compare_via[0] === 'retailer') {
    exportData = retailer_data
  }
  try {
    let pres = new pptxgen();

    const createGroups = async (arr, numGroups, i) => {

      let tempArray = [];
      for (i = 0; i < arr.length; i += numGroups) {
        tempArray.push(arr.slice(i, i + numGroups));
      }
      return tempArray
    }
    if (compare_via[0] == 'brand') {
      pres.defineLayout({ name: 'A3', width: 13, height: 7.5 });
      pres.layout = 'A3'

      const items = exportData
      const result = await createGroups(items, 3)
      

      const textLableOptions = {
        w: 1.5,
        h: 0.5,
        y: 1.5,
        align: "center",
        valign: 'middle',
        bold: true,
        fill: "ffffff",
        fontSize: 12,
        fontFace: "Mars Centra"
      };
      const textOptions = {
        w: 1.6,
        h: 1,
        y: 2,
        align: "center",
        line: { width: "2", color: "dcdcdc" },
        fill: "ffffff",
        shape: pres.ShapeType.roundRect,
        rectRadius: 0.2,
        fontSize: 10,
        fontFace: "Mars Centra"
      };
      let rsvArr = []
      let shareArr = []
      let tdpArr = []
      let $TdpArr = []
      let gsvArr = []
      let nsvArr = []
      let macArr = []
      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        
        window['slide' + (index + 1)] = pres.addSlide();
        window['slide' + (index + 1)].addText(`Filters applied: Brand - ${DefaultBrand}; Pet - ${exportBrand?.filters?.product_pet}; Technology - ${exportBrand?.filters?.product_technology}; Year - ${exportBrand?.filters?.time_year}; Period - ${exportBrand?.filters?.time_period}; Time Aggregates - ${getTimeAggregates(exportBrand?.filters?.time_aggregates)}`, { x: 0.5, y: 0.5, w: 20, fontSize: 14, fontFace: "Mars Centra" });

        window['slide' + (index + 1)].addText(`Portfolio Summary`, { x: 1.5, y: 1, fontSize: 16, fontFace: "Mars Centra", bold: true, align: 'center', margin: [0, 0, 0, 3] });
        element.map((item, i) => {
          window['slide' + (index + 1)].addText(``, {
            ...textLableOptions,
            x: 0.5,
            y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
          });
          window['slide' + (index + 1)].addText(``, {
            ...textLableOptions,
            x: 2.2,
            y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
          });
          window['slide' + (index + 1)].addText(`TOTAL`, {
            ...textLableOptions,
            x: 3.9,
            y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
          });
          window['slide' + (index + 1)].addText(`FDM excluding\n Omni`, {
            ...textLableOptions,
            x: 5.6,
            y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
          });
          window['slide' + (index + 1)].addText(`Supers excluding\n Omni`, {
            ...textLableOptions,
            x: 7.3,
            y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
          });
          window['slide' + (index + 1)].addText(`Indy excluding\n Omni`, {
            ...textLableOptions,
            x: 9,
            y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
          });
          window['slide' + (index + 1)].addText(`Ecom including\n Omni`, {
            ...textLableOptions,
            x: 10.7,
            y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
          });
          //cards
          window['slide' + (index + 1)].addText(`${item.brand}`, {
            ...textOptions,
            x: 0.5,
            y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.8) - 0.5) * i)) : textOptions.y,
          });
          window['slide' + (index + 1)].addText([
            { text: "$ Sales mm", options: { breakLine: true, fontSize: 10, fontFace: "Mars Centra", color: '4d4a4a' } },
            { text: "%CYA", options: { breakLine: true, fontSize: 12, fontFace: "Mars Centra", color: '4d4a4a' } },
            { text: "\n" },
            { text: "$ Share", options: { breakLine: true, fontSize: 12, fontFace: "Mars Centra", color: '4d4a4a' } },
            { text: "%CYA", options: { fontSize: 12, fontFace: "Mars Centra", color: '4d4a4a' } },
          ], {
            ...textOptions,
            x: 2.2,
            y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.8) - 0.5) * i)) : textOptions.y
          });
          window['slide' + (index + 1)].addText([
            { text: item[`dollar_sum_latest_${time_aggregates}`] ? `$${formatNumber(item[`dollar_sum_latest_${time_aggregates}`])}` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item[`dollar_variance_latest_${time_aggregates}`] ? `${(item[`dollar_variance_latest_${time_aggregates}`]).toFixed(1)}%` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: "\n" },
            { text: item[`dollar_share_latest_${time_aggregates}`] ? `${(item[`dollar_share_latest_${time_aggregates}`]).toFixed(1)}%` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item[`dollar_share_variance_latest_${time_aggregates}`] ? `${(item[`dollar_share_variance_latest_${time_aggregates}`]).toFixed(1)} pts` : 'NA', options: { color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
          ], {
            ...textOptions,
            x: 3.9,
            y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.8) - 0.5) * i)) : textOptions.y
          });
          window['slide' + (index + 1)].addText([
            { text: item.table_data[0][`dollar_sum_latest_${time_aggregates}`].abs ? `$${formatNumber(item.table_data[0][`dollar_sum_latest_${time_aggregates}`].abs)}` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item.table_data[0][`dollar_sum_latest_${time_aggregates}`].change ? `${(item.table_data[0][`dollar_sum_latest_${time_aggregates}`].change).toFixed(1)}%` : 'NA', options: { breakLine: true, color: Math.sign(item.table_data[0][`dollar_sum_latest_${time_aggregates}`].change) === -1 ? 'ec3225' : '00c653', fontSize: 12, fontFace: "Mars Centra" } },
            { text: "\n" },
            { text: item.table_data[0][`dollar_share_latest_${time_aggregates}`].abs ? `${(item.table_data[0][`dollar_share_latest_${time_aggregates}`].abs).toFixed(1)}%` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item.table_data[0][`dollar_share_latest_${time_aggregates}`].change ? `${(item.table_data[0][`dollar_share_latest_${time_aggregates}`].change).toFixed(1)} pts` : 'NA', options: { color: Math.sign(item.table_data[0][`dollar_share_latest_${time_aggregates}`].change) === -1 ? 'ec3225' : '00c853', fontSize: 12, fontFace: "Mars Centra" } },
          ], {
            ...textOptions,
            x: 5.6,
            y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.8) - 0.5) * i)) : textOptions.y
          });
          window['slide' + (index + 1)].addText([
            { text: item.table_data[1][`dollar_sum_latest_${time_aggregates}`].abs ? `$${formatNumber(item.table_data[1][`dollar_sum_latest_${time_aggregates}`].abs)}` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item.table_data[1][`dollar_sum_latest_${time_aggregates}`].change ? `${(item.table_data[1][`dollar_sum_latest_${time_aggregates}`].change).toFixed(1)}%` : 'NA', options: { breakLine: true, fontSize: 12, fontFace: "Mars Centra", color: Math.sign(item.table_data[1][`dollar_sum_latest_${time_aggregates}`].change) === -1 ? 'ec3225' : '00c853' } },
            { text: "\n" },
            { text: item.table_data[1][`dollar_share_latest_${time_aggregates}`].abs ? `${(item.table_data[1][`dollar_share_latest_${time_aggregates}`].abs).toFixed(1)}%` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item.table_data[1][`dollar_share_latest_${time_aggregates}`].change ? `${(item.table_data[1][`dollar_share_latest_${time_aggregates}`].change).toFixed(1)} pts` : 'NA', options: { color: Math.sign(item.table_data[1][`dollar_share_latest_${time_aggregates}`].change) === -1 ? 'ec3225' : '00c853', fontSize: 12, fontFace: "Mars Centra" } },
          ], {
            ...textOptions,
            x: 7.3,
            y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.8) - 0.5) * i)) : textOptions.y
          });
          window['slide' + (index + 1)].addText([
            { text: item.table_data[2][`dollar_sum_latest_${time_aggregates}`].abs ? `$${formatNumber(item.table_data[2][`dollar_sum_latest_${time_aggregates}`].abs)}` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item.table_data[2][`dollar_sum_latest_${time_aggregates}`].change ? `${(item.table_data[2][`dollar_sum_latest_${time_aggregates}`].change).toFixed(1)}%` : 'NA', options: { breakLine: true, color: Math.sign(item.table_data[2][`dollar_sum_latest_${time_aggregates}`].change) === -1 ? 'ec3225' : '00c853', fontSize: 12, fontFace: "Mars Centra" } },
            { text: "\n" },
            { text: item.table_data[2][`dollar_share_latest_${time_aggregates}`].abs ? `${(item.table_data[2][`dollar_share_latest_${time_aggregates}`].abs).toFixed(1)}%` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item.table_data[2][`dollar_share_latest_${time_aggregates}`].change ? `${(item.table_data[2][`dollar_share_latest_${time_aggregates}`].change).toFixed(1)} pts` : 'NA', options: { color: Math.sign(item.table_data[2][`dollar_share_latest_${time_aggregates}`].change) === -1 ? 'ec3225' : '00c853', fontSize: 12, fontFace: "Mars Centra" } },

          ], {
            ...textOptions,
            x: 9,
            y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.8) - 0.5) * i)) : textOptions.y
          });
          window['slide' + (index + 1)].addText([
            { text: item.table_data[3][`dollar_sum_latest_${time_aggregates}`].abs ? `$${formatNumber(item.table_data[3][`dollar_sum_latest_${time_aggregates}`].abs)}` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item.table_data[3][`dollar_sum_latest_${time_aggregates}`].change ? `${(item.table_data[3][`dollar_sum_latest_${time_aggregates}`].change).toFixed(1)}%` : 'NA', options: { breakLine: true, color: Math.sign(item.table_data[3][`dollar_sum_latest_${time_aggregates}`].change) === -1 ? 'ec3225' : '00c853', fontSize: 12, fontFace: "Mars Centra" } },
            { text: "\n" },
            { text: item.table_data[3][`dollar_share_latest_${time_aggregates}`].abs ? `${(item.table_data[3][`dollar_share_latest_${time_aggregates}`].abs).toFixed(1)}%` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
            { text: item.table_data[3][`dollar_share_latest_${time_aggregates}`].change ? `${(item.table_data[3][`dollar_share_latest_${time_aggregates}`].change).toFixed(1)} pts` : 'NA', options: { color: Math.sign(item.table_data[3][`dollar_share_latest_${time_aggregates}`].change) === -1 ? 'ec3225' : '00c853', fontSize: 12, fontFace: "Mars Centra" } },

          ], {
            ...textOptions,
            x: 10.7,
            y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.8) - 0.5) * i)) : textOptions.y
          });

          if (card[0] === item.brand) {
            const pagesRsv = createCustomTableForBrand(psColsRsv, item.table_data, card);
            rsvArr.push(pagesRsv)
          }
          if (card[0] === item.brand) {
            const pagesShare = createCustomTableForBrandShare(psColsShare, item.table_data, card);
            shareArr.push(pagesShare)
          }
          if (card[0] === item.brand) {
            const pagesTdp = createCustomTableForBrandTdp(psColsTdp, item.table_data, card);
            tdpArr.push(pagesTdp)
          }
          if (card[0] === item.brand) {
            const pagesDollarTdp = createCustomTableForBrand$Tdp(psColsDollarTdp, item.table_data, card);
            $TdpArr.push(pagesDollarTdp)
          }

          // For Financial Table Data
          if (item.mars_brand == true) {
            if (card[0] === item.brand) {
              const pagesFianancialGsv = createCustomTableForBrandGsv(psColsFinancialGsv, item.table_data, card);
              gsvArr.push(pagesFianancialGsv)
            }
            if (card[0] === item.brand) {
              const pagesFianancialNsv = createCustomTableForBrandNsv(psColsFinancialNSV, item.table_data, card);
              nsvArr.push(pagesFianancialNsv)
            }
            if (card[0] === item.brand) {
              const pagesFianancialMac = createCustomTableForBrandMac(psColsFinancialMAC, item.table_data, card);
              macArr.push(pagesFianancialMac)
            }
          }
        })
      }
      if (selectCard === true) {
        // Table 1
        const slideChart = pres.addSlide();

        slideChart.addText(`Filters applied: ​Brand – ${DefaultBrand}; Pet – ${exportBrand?.filters?.product_pet}; Technology – ${exportBrand?.filters?.product_technology}; Year – ${exportBrand?.filters?.time_year}; Period – ${exportBrand?.filters?.time_period}; Time Aggregates – ${getTimeAggregates(exportBrand?.filters?.time_aggregates)}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });

        slideChart.addText(`Deep dive to ${card[0]}-${card[1]} `, {
          x: 1.5,
          y: 1,
          bold: true,
          align: "center",
          fontFace: "Mars Centra",
          fontSize: 16,
        });
        const colW = [
          2, 1, 1, 1, 1, 1, 1, 1, 1, 1
        ];
        rsvArr && rsvArr[0].forEach((page, index) => {
          
          if (index === 0)
            slideChart.addTable(page, {
              y: 1.5,
              align: "center",
              colW,
            });
          else {
            const slide4 = pres.addSlide();
            slide4.addTable(page, {
              y: 1.5,
              align: "center",
              colW,
            });
          }
        })
        
        const colW2 = [
          2, 1, 1, 1, 1, 1, 1
        ];
        shareArr[0].forEach((page, index) => {
          if (index === 0)
            slideChart.addTable(page, {
              y: 4.5,
              // x: 1,
              align: "center",
              colW2,
            });
          else {
            const slide4 = pres.addSlide();
            slide4.addTable(page, {
              y: 4.5,
              align: "center",
              colW2,
            });
          }
        })
        // Table 3
        const slide3 = pres.addSlide();

        slide3.addText(`Filters applied: ​Brand – ${DefaultBrand}; Pet – ${exportBrand?.filters?.product_pet}; Technology – ${exportBrand?.filters?.product_technology}; Year – ${exportBrand?.filters?.time_year}; Period – ${exportBrand?.filters?.time_period}; Time Aggregates – ${getTimeAggregates(exportBrand?.filters?.time_aggregates)}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });

        slide3.addText(`Deep dive to ${card[0]}-${card[1]} `, {
          x: 1.5,
          y: 1,
          bold: true,
          align: "center",
          fontFace: "Mars Centra",
          fontSize: 16,
        });
        tdpArr[0].forEach((page, index) => {
          if (index === 0)
            slide3.addTable(page, {
              y: 1.5,
              align: "center",
              colW2,
            });
          else {
            const slide5 = pres.addSlide();
            slide5.addTable(page, {
              y: 1.5,
              align: "center",
              colW2,
            });
          }
        })
        // Table 4

        $TdpArr[0].forEach((page, index) => {
          if (index === 0)
            slide3.addTable(page, {
              y: 4.5,
              align: "center",
              colW2,
            });
          else {
            const slide4 = pres.addSlide();
            slide4.addTable(page, {
              y: 4.5,
              align: "center",
              colW2,
            });
          }
        })
        // Table 5
        if (gsvArr.length || nsvArr.length || macArr.length > 0) {
          const slide5 = pres.addSlide();

          slide5.addText(`Filters applied: ​Brand – ${DefaultBrand}; Pet – ${exportBrand?.filters?.product_pet}; Technology – ${exportBrand?.filters?.product_technology}; Year – ${exportBrand?.filters?.time_year}; Period – ${exportBrand?.filters?.time_period}; Time Aggregates – ${getTimeAggregates(exportBrand?.filters?.time_aggregates)}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });

          slide5.addText(`Deep dive to ${card[0]}-${card[1]} `, {
            x: 1.5,
            y: 1,
            bold: true,
            align: "center",
            fontFace: "Mars Centra",
            fontSize: 16,
          });
          gsvArr[0].forEach((page, index) => {
            if (index === 0)
              slide5.addTable(page, {
                y: 1.5,
                align: "center",
                colW2,
              });
            else {
              const slide6 = pres.addSlide();
              slide6.addTable(page, {
                y: 1.5,
                align: "center",
                colW,
              });
            }
          })
          // Table 6

          nsvArr[0].forEach((page, index) => {
            if (index === 0)
              slide5.addTable(page, {
                y: 4.5,
                align: "center",
                colW2,
              });
            else {
              const slide6 = pres.addSlide();
              slide6.addTable(page, {
                y: 4.5,
                align: "center",
                colW,
              });
            }
          })
          // Table 7
          const slide7 = pres.addSlide();

          slide7.addText(`Filters applied: ​Brand – ${DefaultBrand}; Pet – ${exportBrand?.filters?.product_pet}; Technology – ${exportBrand?.filters?.product_technology}; Year – ${exportBrand?.filters?.time_year}; Period – ${exportBrand?.filters?.time_period}; Time Aggregates – ${getTimeAggregates(exportBrand?.filters?.time_aggregates)}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });

          slide7.addText(`Deep dive to ${card[0]}-${card[1]} `, {
            x: 1.5,
            y: 1,
            bold: true,
            align: "center",
            fontFace: "Mars Centra",
            fontSize: 16,
          });
          macArr[0].forEach((page, index) => {
            if (index === 0)
              slide7.addTable(page, {
                y: 1.5,
                align: "center",
                colW2,
              });
            else {
              const slide8 = pres.addSlide();
              slide8.addTable(page, {
                y: 1.5,
                align: "center",
                colW2,
              });
            }
          })
        }
      }
      if (DefaultBrand.toUpperCase() !== allBrands) {
        if (exportBrand?.waterfall?.data?.length > 0) {
          let slide2 = pres.addSlide();
          let lableName = exportBrand?.waterfall?.data?.map((value) => {
            return value.label
          })
          let arrData = exportBrand?.waterfall?.data?.map((value) => {
            return value.difference
          })
          let arrData2 = []
          exportBrand?.waterfall?.data?.length > 0 && exportBrand.waterfall.data.forEach((data, index) => {
            if (index === 0) {
              arrData2.push(0)
            } else if (index === exportBrand.waterfall.data.length - 1) {
              arrData2.push(0)

            } else {
              let val1 = Math.sign(data.value);
              if (val1 == 1 || val1 == 0) {
                let sum = 0;

                for (let i = 0; i <= index - 1; i++) {
                  sum += arrData[i];
                }
                arrData2.push(sum)
              }
            }
          })
          
          let chart1data = [arrData[0], arrData[arrData.length - 1]]
          let chart2data = arrData.slice(1, -1);
          let chart1lable = [lableName[0], lableName[lableName.length - 1]]
          let chart2lable = lableName.slice(1, -1);
          
          let colorCode1 = chart2data.map((item) => Math.sign(item) === 1 ? "4E9D2D" : "DE4216")

          let waterfalldata2 = [
            {
              name: "Region 4",
              labels: chart2lable,
              values: chart2data,
            }
          ]
          slide2.addText(`Filters applied: Brand - ${DefaultBrand}; Pet - ${exportBrand?.filters?.product_pet}; Technology - ${exportBrand?.filters?.product_technology}; Year - ${exportBrand?.filters?.time_year}; Period - ${exportBrand?.filters?.time_period}; Time Aggregates - ${getTimeAggregates(exportBrand?.filters?.time_aggregates)}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });

          slide2.addText(`Portfolio Summary [Eq. Lbs]`, { x: 5.3, y: 1, fontSize: 16, fontFace: "Mars Centra", bold: true });

          slide2.addText(`${chart1lable[0]}\n${formatNumber(chart1data[0])}`, {
            x: 5,
            w: 1.2,
            h: 0.8,
            y: 1.5,
            align: "center",
            line: { width: "2", color: "dcdcdc" },
            fill: "ffffff",
            shape: pres.ShapeType.roundRect,
            rectRadius: 0.2,
            fontSize: 12,
            fontFace: "Mars Centra"
          });

          slide2.addText(`${chart1lable[1]}\n${formatNumber(chart1data[1])}`, {
            x: 6.2,
            w: 1.2,
            h: 0.8,
            y: 1.5,
            align: "center",
            line: { width: "2", color: "dcdcdc" },
            fill: "ffffff",
            shape: pres.ShapeType.roundRect,
            rectRadius: 0.2,
            fontSize: 12,
            fontFace: "Mars Centra"
          });

          slide2.addChart(pres.charts.BAR, waterfalldata2, {
            x: 3,
            y: 2.5,
            w: 7,
            h: 4,
            chartArea: { fill: { color: "404040" } },
            showValue: true,
            dataLabelFormatCode: '##0.0',
            barDir: "col",
            chartColors: colorCode1,
            catGridLine: { style: "none" },
            valGridLine: { style: "none" },
            valAxisHidden: false,
            catAxisHidden: false,
            catAxisLabelFontSize: 9,
            valAxisLabelFontSize: 9,
          });
        }
      }
      pres.writeFile({ fileName: filename })
    }
    else {
      const items = exportData
      const result = await createGroups(items, 3)
     
      pres.defineLayout({ name: 'A3', width: 13, height: 7.5 });
      pres.layout = 'A3'
      const textLableOptions = {
        w: 1.2,
        h: 0.5,
        y: 1.5,
        align: "center",
        fill: "ffffff",
        fontSize: 12,
        fontFace: "Mars Centra"
      };
      const textOptions = {
        w: 1.1,
        h: 1,
        y: 2,
        align: "center",
        line: { width: "2", color: "dcdcdc" },
        fill: "ffffff",
        shape: pres.ShapeType.roundRect,
        rectRadius: 0.2,
        fontSize: 10,
        fontFace: "Mars Centra",
        // margin: 0.5
      };
      let scroll = false;
      let imageSize = { x: 1, y: 0.5, w: 15, h: 4 }
      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        
        let slide = pres.addSlide();
        slide.addText(`Filters applied: Brand - ${DefaultBrand}; Pet - ${exportBrand?.filters?.product_pet}; Technology – ${exportBrand?.filters?.product_technology}; Year - ${exportBrand?.filters?.time_year}; Period - ${exportBrand?.filters?.time_period}; Time Aggregates - ${getTimeAggregates(exportBrand?.filters?.time_aggregates)}`, { x: 0.5, y: 0.5, w: 20, fontSize: 14, fontFace: "Mars Centra" });
        if (compare_via[0] === 'retailer') {
          
          slide.addText(`Compare Via : ${compare_via[0]} , Channel : ${channel[0]}`, { x: 0.5, y: 0.8, w: 10, fontSize: 14, fontFace: "Mars Centra" })
        }
        slide.addText(`Portfolio Summary`, { x: 1.5, y: 1, fontSize: 16, fontFace: "Mars Centra", bold: true, align: 'center', margin: [0, 0, 0, 3] });

        element.map((item, i) => {
          slide.addText(``, {
            ...textLableOptions,
            x: 0.4,
            y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
          });
          slide.addText(``, {
            ...textLableOptions,
            x: 1.8,
            y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
          });
          for (const [index, card] of item.cards.entries()) {

            slide.addText(`${card.brand}`, {
              ...textLableOptions,
              x: index > 0 ? 3.2 + (1.4 * index) : 3.2,
              y: i > 0 ? (((textLableOptions.y + 0.7) - 0.5) * i) + textLableOptions.y : textLableOptions.y
            });

          }

          //cards
          slide.addText(`${compare_via[0] === 'retailer' ? item.retailer : item.channel}`, {
            ...textOptions,
            x: 0.4,
            y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.7) - 0.5) * i)) : textOptions.y
          });
          slide.addText([
            { text: "$ Sales mm", options: { breakLine: true, fontSize: 10, fontFace: "Mars Centra", color: '4d4a4a' } },
            { text: "%CYA", options: { breakLine: true, fontSize: 12, fontFace: "Mars Centra", color: '4d4a4a' } },
            { text: "\n" },
            { text: "$ Share", options: { breakLine: true, fontSize: 12, fontFace: "Mars Centra", color: '4d4a4a' } },
            { text: "%CYA", options: { fontSize: 12, fontFace: "Mars Centra", color: '4d4a4a' } },
          ], {
            ...textOptions,
            x: 1.8,
            y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.7) - 0.5) * i)) : textOptions.y
          });
          for (const [index, card] of item.cards.entries()) {

            if (card.brand === 'TOTAL') {
              slide.addText([
                { text: card[`dollar_sum_latest_${time_aggregates}`] ? `$${formatNumber(card[`dollar_sum_latest_${time_aggregates}`])}` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
                { text: card[`dollar_variance_latest_${time_aggregates}`] ? `${(card[`dollar_variance_latest_${time_aggregates}`]).toFixed(1)} %` : 'NA', options: { color: Math.sign(card[`dollar_variance_latest_${time_aggregates}`]) === -1 ? 'ec3225' : '00c853', fontSize: 12, fontFace: "Mars Centra" } },
              ], {
                ...textOptions,
                x: index > 0 ? 4.4 + (1.1 * index) : 3.2,
                y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.7) - 0.5) * i)) : textOptions.y
              });
            } else {

              slide.addText([
                { text: card[`dollar_sum_latest_${time_aggregates}`] ? `$${formatNumber(card[`dollar_sum_latest_${time_aggregates}`])}` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
                { text: card[`dollar_variance_latest_${time_aggregates}`] ? `${(card[`dollar_variance_latest_${time_aggregates}`]).toFixed(1)}%` : 'NA', options: { breakLine: true, color: Math.sign(card[`dollar_variance_latest_${time_aggregates}`]) === -1 ? 'ec3225' : '00c853', fontSize: 12, fontFace: "Mars Centra" } },
                { text: "\n" },
                { text: card[`dollar_share_latest_${time_aggregates}`] ? `${(card[`dollar_share_latest_${time_aggregates}`]).toFixed(1)}%` : 'NA', options: { breakLine: true, color: '4d4a4a', fontSize: 12, fontFace: "Mars Centra" } },
                { text: card[`dollar_share_variance_latest_${time_aggregates}`] ? `${(card[`dollar_share_variance_latest_${time_aggregates}`]).toFixed(1)} pts` : 'NA', options: { color: Math.sign(card[`dollar_share_variance_latest_${time_aggregates}`]) === -1 ? 'ec3225' : '00c853', fontSize: 12, fontFace: "Mars Centra" } },
              ], {
                ...textOptions,
                x: index > 0 ? 3.2 + (1.4 * index) : 3.2,
                y: i > 0 ? (textOptions.y + (((textLableOptions.y + 0.7) - 0.5) * i)) : textOptions.y
              });

            }
          }


        })
      }

      if (DefaultBrand.toUpperCase() !== allBrands) {
        if (exportBrand?.waterfall?.data?.length > 0) {
          let slide2 = pres.addSlide();
          let lableName = exportBrand?.waterfall?.data?.map((value) => {
            return value.label
          })
          let arrData = exportBrand?.waterfall?.data?.map((value) => {
            return value.difference
          })
          let arrData2 = []
          exportBrand.waterfall.data.forEach((data, index) => {
            if (index === 0) {
              arrData2.push(0)
            } else if (index === exportBrand.waterfall.data.length - 1) {
              arrData2.push(0)

            } else {
              let val1 = Math.sign(data.value);
              let val2
              if (val1 == 1 || val1 == 0) {
                let sum = 0;

                for (let i = 0; i <= index - 1; i++) {
                  sum += arrData[i];
                }
                arrData2.push(sum)
              } else {
                val2 = arrData.filter((item, i) => i < index - 1).reduce((partialSum, a) => partialSum + a, 0) - data.value
              }
            }
          })
         
          let chart1data = [arrData[0], arrData[arrData.length - 1]]
          let chart2data = arrData.slice(1, -1);
          let chart1lable = [lableName[0], lableName[lableName.length - 1]]
          let chart2lable = lableName.slice(1, -1);
          
          let colorCode1 = chart2data.map((item) => Math.sign(item) === 1 ? "4E9D2D" : "DE4216")

          let waterfalldata2 = [
            {
              name: "Region 4",
              labels: chart2lable,
              values: chart2data,
            }
          ]
          slide2.addText(`Filters applied: Brand - ${DefaultBrand}; Pet - ${exportBrand?.filters?.product_pet}; Technology - ${exportBrand?.filters?.product_technology}; Year - ${exportBrand?.filters?.time_year}; Period - ${exportBrand?.filters?.time_period}; Time Aggregates - ${getTimeAggregates(exportBrand?.filters?.time_aggregates)}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });

          slide2.addText(`Portfolio Summary [Eq. Lbs]`, { x: 5.3, y: 1, fontSize: 16, fontFace: "Mars Centra", bold: true });

          slide2.addText(`${chart1lable[0]}\n${formatNumber(chart1data[0])}`, {
            x: 5,
            w: 1.2,
            h: 0.8,
            y: 1.5,
            align: "center",
            line: { width: "2", color: "dcdcdc" },
            fill: "ffffff",
            shape: pres.ShapeType.roundRect,
            rectRadius: 0.2,
            fontSize: 12,
            fontFace: "Mars Centra"
          });

          slide2.addText(`${chart1lable[1]}\n${formatNumber(chart1data[1])}`, {
            x: 6.2,
            w: 1.2,
            h: 0.8,
            y: 1.5,
            align: "center",
            line: { width: "2", color: "dcdcdc" },
            fill: "ffffff",
            shape: pres.ShapeType.roundRect,
            rectRadius: 0.2,
            fontSize: 12,
            fontFace: "Mars Centra"
          });

          slide2.addChart(pres.charts.BAR, waterfalldata2, {
            x: 3,
            y: 2.5,
            w: 7,
            h: 4,
            chartArea: { fill: { color: "404040" } },
            showValue: true,
            dataLabelFormatCode: '##0.0',
            barDir: "col",
            chartColors: colorCode1,
            catGridLine: { style: "none" },
            valGridLine: { style: "none" },
            valAxisHidden: false,
            catAxisHidden: false,
            catAxisLabelFontSize: 9,
            valAxisLabelFontSize: 9,
          })
        }
      }
      pres.writeFile({ fileName: filename })
    }

    openNotificationWithIcon('success', 'Downloading..');
  }
  catch (err) {
    
    openNotificationWithIcon('error', 'Download Failed');
  }
}
