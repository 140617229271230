import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import "./loader-auth.scss";

const LoaderAuth = () => (
  <div className="loader-auth-container">
    <div className="loader-auth-card">
      <div className="loader-auth-left">
        <div>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
        <div className="loader-auth-title">Welcome to PN Cascade</div>
        <div className="loader-auth-description">
          Please wait, while we're setting things up for you...
        </div>
      </div>
      <div className="loader-auth-right">
        <img
          src="/assets/images/loader-auth.webp"
          alt="Welcome to PN Cascade"
        />
      </div>
    </div>
  </div>
);

export default LoaderAuth;
