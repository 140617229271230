import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Layout,
  Row,
  Col,
  Button,
  Switch
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import Loader from "../../components/loader";
import Wrapper from "../../components/wrapper";
import { allBrands, brandSplit, formatNumber, marsPN, valueTypes } from "../../utilities/helper";
import construction from "../../assets/images/construction.png";
import { getData } from "../../slicers/trackboard.slicer";
import "./trackboard.scss";
import { exportTrackboard } from "../../utilities/exports/excel";
import { setExportData } from "../../slicers/exportData.slicer";
import { resetPreFilters, resetDistributionSummary, setReportRefreshDist } from "../../slicers/distributionSummary.slicer";
import { resetPerformanceSummary, setReportRefreshPerf } from "../../slicers/performanceSummary.slicer";
import { getAllFilters, resetVolumeShifting, setReportRefreshVol } from "../../slicers/volumeShiftingSummary.slicer";
import TrackboardCard from "../../components/trackboardCard";
import { getFilterData, resetInnovationTracker, setReportRefreshInnovation } from "../../slicers/innovation-tracker.slicer";
import TrackboardCard2x2 from "../../components/trackboardCard2x2";

const { Content } = Layout;




/**
 * Trackboard 
 * It manages displaying of Trackboard with different tabs and functionalities.
 */

function TrackboardV1(props) {
  const dispatch = useDispatch();
  const { defaultBrand, user } = useSelector((state) => state.profile)
  const { loading, trackboardData, innovationData, competitionData } = useSelector((state) => state.trackboard)
  const { reportRefreshPerf } = useSelector((state) => state.perfSummary)
  const { loading: refreshing, trackboard, beAvailable } = useSelector((state) => state.refreshDate);
  const exportData = useSelector((state) => state.exportData);
  const [ytdToggle, setYTDToggle] = useState(false);
  const isDefaultBrandMarsBDB = defaultBrand.toUpperCase() === allBrands;
  const CardData = (heading, data, kpi1, kpi2, subKpiPercent = true, kpiPercent = true) => (
    <div className="inner-card">
      <Row className="card-header">{heading}</Row>
      <Row className="card-data">{data} {data === valueTypes.NA ? "" : kpiPercent ? <></> : <>%</>}</Row>
      <Row>
        <Col className="card" md={24}>
          <div className={isDefaultBrandMarsBDB ? "card-kpi-mars" : "card-kpi"}  >
            <Row className="card-kpi-black">vs YA</Row>
            <Row className={Number(kpi1) >= 0 ? "card-kpi-green" : "card-kpi-red"}>{kpi1 === valueTypes.NA ? valueTypes.NA : `${Number(kpi1) >= 0 ? "+" : "-"}${Math.abs(kpi1)}${subKpiPercent ? "%" : " bps"}`}</Row>
          </div>
          {isDefaultBrandMarsBDB ?
            !['GSV', 'NSV', '$ Sales Share', "Pound Volume Share", 'CSL'].includes(heading) &&
            < div className="card-kpi-mars">
              <Row className="card-kpi-black">Category vs YA</Row>
              <Row className={Number(kpi2) >= 0 ? "card-kpi-green" : "card-kpi-red"}>{kpi2 === valueTypes.NA ? valueTypes.NA : `${Number(kpi2) >= 0 ? "+" : "-"}${Math.abs(kpi2)}${subKpiPercent ? "%" : " bps"}`}</Row>
            </div> :
            < div className="card-kpi">
              <Row className="card-kpi-black">Mars vs YA</Row>
              <Row className={Number(kpi2) >= 0 ? "card-kpi-green" : "card-kpi-red"}>{kpi2 === valueTypes.NA ? valueTypes.NA : `${Number(kpi2) >= 0 ? "+" : "-"}${Math.abs(kpi2)}${subKpiPercent ? "%" : " bps"}`}</Row>
            </div>}
        </Col>
      </Row>
    </div >
  );

  const title = `Total ${isDefaultBrandMarsBDB ? marsPN : brandSplit(defaultBrand)} - Total Market Performance ${ytdToggle ? "YTD" : "for Period"}`;

  useEffect(() => {
    dispatch(getData(ytdToggle));
    dispatch(resetPreFilters());
    if (reportRefreshPerf) {
      dispatch(resetPerformanceSummary());
      dispatch(setReportRefreshPerf(false));
      dispatch(resetDistributionSummary());
      dispatch(setReportRefreshDist(false));
      dispatch(resetVolumeShifting());
      dispatch(setReportRefreshVol(false));
      dispatch(resetInnovationTracker());
      dispatch(setReportRefreshInnovation(false));
      dispatch(getAllFilters());
    }
  }, [defaultBrand])

  useEffect(() => {
    dispatch(getData(ytdToggle));
  }, [ytdToggle])



  const exportPage = () => exportTrackboard(exportData, isDefaultBrandMarsBDB, "Trackboard", innovationData);
  useEffect(() => {
    if (!trackboardData) return;
    if (Object.keys(trackboardData).length === 0) return;
    dispatch(setExportData({
      kpi: {
        header: "",
        title,
        data: trackboardData
      }
    }));
  }, [dispatch, trackboardData]);

  const competitionSummary =
    competitionData.competition_summary &&
    Object.keys(competitionData.competition_summary)
      .filter((channel) => !channel.toLocaleLowerCase().includes("total"))
      .slice(0, 4);


  return (
    <Wrapper heading={"1"}>
      <Content className={["site-layout-background content-body", "trackboard-content"]} >
        <Loader loading={loading > 0}>
          <Row>
            <div className="trackboard-info">
              <p>{title} - {trackboardData.latest_time_period_year}</p>
              <a href="javascript:void(0)" onClick={exportPage}><DownloadOutlined /></a>
            </div>
          </Row>
          <Row>
            <div className="refreshed-on">
              <span>Last Refreshed on: {trackboard.last_refresh}</span>
              <div className="ytd_toggle">
                <div className={ytdToggle ? "unselected" : "selected"}>Period</div>
                <div className='switch'><Switch size='small' checked={ytdToggle} onChange={(e) => setYTDToggle(e)} /></div>
                <div className={!ytdToggle ? "unselected" : "selected"}>YTD</div>
              </div>
              <span>Next Refresh on: {trackboard.next_refresh}</span>
            </div>
          </Row>
          <Row gutter={40} className="trackboard-content">
            <Col md={8}>
              <div className="deep-dive">
                <h2 className="trackboard-head">Performance Summary</h2>
                <div className="gray-background outer-card">
                  {/* {showFinancialsCallout() && <p style={{ fontWeight: 700, marginBottom: "8px" }}>{financialsCalloutMessage()}</p>} */}
                  <Row className="trackboard-row">

                    <Col md={8} className="trackboard-column-content">
                      <TrackboardCard
                        heading={"MKT RSV"}
                        data={trackboardData.perf_summary_rsv && (formatNumber(trackboardData.perf_summary_rsv.value, 1, true) === valueTypes.NA ? valueTypes.NA : `$${formatNumber(trackboardData.perf_summary_rsv.value, 1)}`)}
                        kpi1={trackboardData.perf_summary_rsv && (formatNumber(trackboardData.perf_summary_rsv.ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.perf_summary_rsv.ya, 1))}
                        kpi2={trackboardData.perf_summary_rsv && (formatNumber(trackboardData.perf_summary_rsv.mars_ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.perf_summary_rsv.mars_ya, 1))}
                        hideSubKpiPercent={true}
                        hideKpiPercent={true}
                      />
                    </Col>
                    <Col md={8} className="trackboard-column-content">
                      <TrackboardCard
                        heading={"GSV"}
                        data={trackboardData.perf_summary_gsv && (formatNumber(trackboardData.perf_summary_gsv.value, 1, true) === valueTypes.NA ? valueTypes.NA : `$${formatNumber(trackboardData.perf_summary_gsv.value, 1)}`)}
                        kpi1={trackboardData.perf_summary_gsv && (formatNumber(trackboardData.perf_summary_gsv.ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.perf_summary_gsv.ya, 1))}
                        kpi2={trackboardData.perf_summary_gsv && (formatNumber(trackboardData.perf_summary_gsv.mars_ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.perf_summary_gsv.mars_ya, 1))}
                        hideSubKpiPercent={true}
                        hideKpiPercent={true}
                      />
                    </Col>
                    <Col md={8} className="trackboard-column-content-right">
                      <TrackboardCard
                        heading={"NSV"}
                        data={trackboardData.perf_summary_nsv && (formatNumber(trackboardData.perf_summary_nsv.value, 1, true) === valueTypes.NA ? valueTypes.NA : `$${formatNumber(trackboardData.perf_summary_nsv.value, 1)}`)}
                        kpi1={trackboardData.perf_summary_nsv && (formatNumber(trackboardData.perf_summary_nsv.ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.perf_summary_nsv.ya, 1))}
                        kpi2={trackboardData.perf_summary_nsv && (formatNumber(trackboardData.perf_summary_nsv.mars_ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.perf_summary_nsv.mars_ya, 1))}
                        hideSubKpiPercent={true}
                        hideKpiPercent={true}
                      />
                    </Col>
                  </Row>
                  <Row className="button">
                    <d iv className="button-div">
                      <Button
                        className="button"
                        type="primary"
                        onClick={(e) => {
                          props.history.push("/performance-summary");
                        }}
                      >
                        Deep Dive
                      </Button>
                    </d>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md={8}>
              <div className="deep-dive">
                <h2 className="trackboard-head">Competition Summary</h2>
                <div className="outer-card">
                  <Row gutter={4} className="trackboard-row">
                    {competitionSummary?.map((channel) => {
                      const brandName = defaultBrand === allBrands ? "MARS" : defaultBrand.split(":")[0];
                      const channelName = channel.split(" ")[0].replace("Ecom", "eCom");
                      return (
                        <Col md={12}>
                          <TrackboardCard2x2
                            heading={`${brandName} ${channelName.split(" ")[0]} $ Share`}
                            data={competitionData.competition_summary?.[channel] && (formatNumber(competitionData.competition_summary[channel].dollar_share, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(competitionData.competition_summary[channel].dollar_share, 1))}
                            kpi1={competitionData.competition_summary?.[channel] && (formatNumber(competitionData.competition_summary[channel].dollar_share_variance, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(competitionData.competition_summary[channel].dollar_share_variance, 1))}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                  <Row className="button">
                    <div className="button-div">
                      <Button
                        className="button"
                        type="primary"
                        onClick={(e) => {
                          props.history.push("/volume-shifting");
                        }}
                      >
                        Deep Dive
                      </Button>
                    </div>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md={8}>
              <div className="deep-dive">
                <h2 className="trackboard-head">Distribution Summary</h2>
                <div className="outer-card">
                  <Row className="trackboard-row">
                    <Col md={8} className="trackboard-column-content" >
                      <TrackboardCard
                        heading={"TDP"}
                        data={trackboardData.dist_summary_tdp && (formatNumber(trackboardData.dist_summary_tdp.value, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.dist_summary_tdp.value, 1))}
                        kpi1={trackboardData.dist_summary_tdp && (formatNumber(trackboardData.dist_summary_tdp.ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.dist_summary_tdp.ya, 1))}
                        kpi2={trackboardData.dist_summary_tdp && (formatNumber(trackboardData.dist_summary_tdp.mars_ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.dist_summary_tdp.mars_ya, 1))}
                        hideSubKpiPercent={true}
                        hideKpiPercent={true}
                      />
                    </Col>
                    <Col md={8} className="trackboard-column-content" >
                      <TrackboardCard
                        heading={"$/TDP"}
                        data={trackboardData.dist_summary_dollar_tdp && (formatNumber(trackboardData.dist_summary_dollar_tdp.value, 1, true) === valueTypes.NA ? valueTypes.NA : `$${formatNumber(trackboardData.dist_summary_dollar_tdp.value, 1)}`)}
                        kpi1={trackboardData.dist_summary_dollar_tdp && (formatNumber(trackboardData.dist_summary_dollar_tdp.ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.dist_summary_dollar_tdp.ya, 1))}
                        kpi2={trackboardData.dist_summary_dollar_tdp && (formatNumber(trackboardData.dist_summary_dollar_tdp.mars_ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.dist_summary_dollar_tdp.mars_ya, 1))}
                        hideSubKpiPercent={true}
                        hideKpiPercent={true}
                      />
                    </Col>
                    <Col md={8} className="trackboard-column-content-right">
                      <TrackboardCard
                        heading={"CSL"}
                        data={trackboardData.dist_summary_csl && (formatNumber(trackboardData.dist_summary_csl.value, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.dist_summary_csl.value, 1))}
                        kpi1={trackboardData.dist_summary_csl && (formatNumber(trackboardData.dist_summary_csl.ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.dist_summary_csl.ya, 1))}
                        kpi2={trackboardData.dist_summary_csl && (formatNumber(trackboardData.dist_summary_csl.mars_ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.dist_summary_csl.mars_ya, 1))}
                      />
                    </Col>
                  </Row>
                  <Row className="button">
                    <div className="button-div">
                      <Button
                        className="button"
                        type="primary"
                        onClick={(e) => {
                          props.history.push("/distribution-summary");
                        }}
                      >
                        Deep Dive
                      </Button>
                    </div>
                  </Row>
                </div>
              </div >
            </Col >
          </Row >
          <Row gutter={40}>
            <Col md={8}>
              <div className="deep-dive">
                <h2 className="trackboard-head">eCom Diagnostics</h2>
                <div className="refreshed-on-ecom">
                  <span>
                    Last Refreshed on: {beAvailable.last_refresh}, Data upto:{" "}
                    {beAvailable.latest_period_available} | Next Refresh on:{" "}
                    {beAvailable.next_refresh}
                  </span>
                </div>
                <div className="outer-card">
                  <Row className="trackboard-row">
                    {<>
                      <Col md={8} className="trackboard-column-content">
                        <TrackboardCard
                          heading={"MKT RSV"}
                          data={trackboardData.ecom_rsv && (formatNumber(trackboardData.ecom_rsv.value, 1, true) === valueTypes.NA ? valueTypes.NA : `$${formatNumber(trackboardData.ecom_rsv.value, 1)}`)}
                          kpi1={trackboardData.ecom_rsv && (formatNumber(trackboardData.ecom_rsv.ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.ecom_rsv.ya, 1))}
                          kpi2={trackboardData.ecom_rsv && (formatNumber(trackboardData.ecom_rsv.mars_ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.ecom_rsv.mars_ya, 1))}
                          hideSubKpiPercent={true}
                          hideKpiPercent={true}
                        />
                      </Col>
                      <Col md={8} className="trackboard-column-content">
                        <TrackboardCard
                          heading={"$ Sales Share"}
                          data={trackboardData.ecom_sales_share && (formatNumber(trackboardData.ecom_sales_share.value, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.ecom_sales_share.value, 1))}
                          kpi1={trackboardData.ecom_sales_share && (formatNumber(trackboardData.ecom_sales_share.ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.ecom_sales_share.ya, 1))}
                          kpi2={trackboardData.ecom_sales_share && (formatNumber(trackboardData.ecom_sales_share.mars_ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.ecom_sales_share.mars_ya, 1))}
                        />
                      </Col>
                      <Col md={8} className="trackboard-column-content-right">
                        <TrackboardCard
                          heading={"Share of Shelf"}
                          data={trackboardData.ecom_share_of_shelf && (formatNumber(trackboardData.ecom_share_of_shelf.value, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.ecom_share_of_shelf.value, 1))}
                          kpi1={trackboardData.ecom_share_of_shelf && (formatNumber(trackboardData.ecom_share_of_shelf.ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.ecom_share_of_shelf.ya, 1))}
                          kpi2={trackboardData.ecom_share_of_shelf && (formatNumber(trackboardData.ecom_share_of_shelf.mars_ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.ecom_share_of_shelf.mars_ya, 1))}
                        />
                      </Col>
                    </>
                    }
                  </Row>
                  <Row className="button">
                    <div className="button-div">
                      <Button
                        className="button"
                        type="primary"
                        onClick={(e) => {
                          props.history.push("/ecom");
                        }}
                      >
                        Deep Dive
                      </Button>
                    </div>
                  </Row>
                </div >
              </div >
            </Col >
            <Col md={8}>
              <div className="deep-dive">
                <h2 className="trackboard-head">Innovation Tracker</h2>
                <div className="outer-card no-refresh-date">
                  <Row className="trackboard-row">
                    {/* <img src={construction} alt="Coming Soon" height="95%" width="100%" /> */}
                    {/* {showFinancialsCallout() && <p style={{ fontWeight: 700, marginBottom: "8px" }}>{financialsCalloutMessage()}</p>} */}
                    <Col md={8} className="trackboard-column-content">
                      <TrackboardCard
                        heading={"MKT RSV YEE"}
                        data={innovationData.rsv_actuals && (formatNumber(innovationData.rsv_actuals, 1, true) === valueTypes.NA ? valueTypes.NA : `$${formatNumber(innovationData.rsv_actuals, 1)}`)}
                        kpi1={innovationData.rsv_yee_variance && (formatNumber(innovationData.rsv_yee_variance, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(innovationData.rsv_yee_variance, 1))}
                        // kpi2={innovationData.rsv_category_avg && (formatNumber(innovationData.rsv_category_avg , 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(innovationData.rsv_category_avg , 1))}
                        hideSubKpiPercent={true}
                        hideKpiPercent={true}
                      />
                    </Col>
                    <Col md={8} className="trackboard-column-content">
                      <TrackboardCard
                        heading={"GSV YEE"}
                        data={innovationData.gsv_actuals && (formatNumber(innovationData.gsv_actuals, 1, true) === valueTypes.NA ? valueTypes.NA : `$${formatNumber(innovationData.gsv_actuals, 1)}`)}
                        kpi1={innovationData.gsv_yee_variance && (formatNumber(innovationData.gsv_yee_variance, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(innovationData.gsv_yee_variance, 1))}
                        // kpi2={innovationData.gsv_category_ya && (formatNumber(innovationData.gsv_category_ya , 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(innovationData.gsv_category_ya , 1))}
                        hideSubKpiPercent={true}
                        hideKpiPercent={true}
                      />
                    </Col>
                    <Col md={8} className="trackboard-column-content">
                      <TrackboardCard
                        heading={"NSV"}
                        data={trackboardData.perf_summary_nsv && (formatNumber(trackboardData.perf_summary_nsv.value, 1, true) === valueTypes.NA ? valueTypes.NA : `$${formatNumber(trackboardData.perf_summary_nsv.value, 1)}`)}
                        kpi1={trackboardData.perf_summary_nsv && (formatNumber(trackboardData.perf_summary_nsv.ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.perf_summary_nsv.ya, 1))}
                        kpi2={trackboardData.perf_summary_nsv && (formatNumber(trackboardData.perf_summary_nsv.mars_ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.perf_summary_nsv.mars_ya, 1))}
                        hideSubKpiPercent={true}
                        hideKpiPercent={true}
                      />
                    </Col>
                  </Row>
                  <Row className="button">
                    <div className="button-div">
                      <Button
                        className="button"
                        type="primary"
                        onClick={(e) => {
                          props.history.push("/innovation-tracker");
                        }}
                      >
                        Deep Dive
                      </Button>
                    </div>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md={8}>
              <div className="deep-dive">
                <h2 className="trackboard-head">Portfolio Summary</h2>
                <div className="outer-card no-refresh-date">
                  <Row className="trackboard-row">
                    <Col md={12} className="trackboard-column-content">
                      <TrackboardCard
                        heading={"$ Sales Share"}
                        data={trackboardData.vol_shifting_sales_share_usd && (formatNumber(trackboardData.vol_shifting_sales_share_usd.value, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.vol_shifting_sales_share_usd.value, 1))}
                        kpi1={trackboardData.vol_shifting_sales_share_usd && (formatNumber(trackboardData.vol_shifting_sales_share_usd.ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.vol_shifting_sales_share_usd.ya, 1))}
                        kpi2={trackboardData.vol_shifting_sales_share_usd && (formatNumber(trackboardData.vol_shifting_sales_share_usd.mars_ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.vol_shifting_sales_share_usd.mars_ya, 1))}
                      />
                    </Col>
                    <Col md={12} className="trackboard-column-content-right">
                      <TrackboardCard
                        heading={"Pound Volume Share"}
                        data={trackboardData.vol_shifting_sales_share_tonne && (formatNumber(trackboardData.vol_shifting_sales_share_tonne.value, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.vol_shifting_sales_share_tonne.value, 1))}
                        kpi1={trackboardData.vol_shifting_sales_share_tonne && (formatNumber(trackboardData.vol_shifting_sales_share_tonne.ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.vol_shifting_sales_share_tonne.ya, 1))}
                        kpi2={trackboardData.vol_shifting_sales_share_tonne && (formatNumber(trackboardData.vol_shifting_sales_share_tonne.mars_ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(trackboardData.vol_shifting_sales_share_tonne.mars_ya, 1))}
                      />
                    </Col>
                  </Row>
                  <Row className="button">
                    <div className="button-div">
                      <Button
                        className="button"
                        type="primary"
                        onClick={(e) => {
                          props.history.push("/portfolio-summary");
                        }}
                      >
                        Deep Dive
                      </Button>
                    </div>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Loader>
      </Content>
    </Wrapper>
  );
}

export default withRouter(TrackboardV1);
