import React, { useEffect, useState, useRef } from "react";
import {
  Layout,
  Row,
  Tabs,
} from "antd";
import { withRouter } from "react-router-dom";
import Wrapper from "../../components/wrapper";
import { Typography } from "antd";
import AlertsTab from "../../components/alerts-tab";
import { useDispatch, useSelector } from "react-redux";
import { getMessages, updateMessages } from "../../slicers/profile.slicer";
import Loader from "../../components/loader";
import { CHAT_WEBSOCKET_URL } from "../../utilities/constants";
import { nudgesDeepDive, setLoading } from "../../slicers/nudges.slicer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getBookmarks } from "../../slicers/bookmarks.slicer";
const { Text, Link } = Typography;

const { Content } = Layout;

const { TabPane } = Tabs;

function Alerts(props) {
  // TestComment
  const dispatch = useDispatch();
  const history = useHistory();
  const nudge_id = (history.location.state && history.location.state.nudge_id ? history.location.state.nudge_id : null)
  const { loading, nudgesDeepDivePerf, nudgesDeepDiveVol, nudgesDeepDiveDist, nudgesDeepDiveInn } = useSelector((state) => state.nudges)
  // const { nudgesLoading } = useSelector((state) => state.bookmarks)
  const { defaultBrand } = useSelector((state) => state.profile)
  const [nudgeId, setNudgeId] = useState(nudge_id);
  const [key, setKey] = useState("1");
  const [nudgePeriod, setNudgePeriod] = useState({ "PERF": 1, "VOL": 1, "DSTR": 1, "INN": 1 });
  const [nudgeScrollTop, setNudgeScrollTop] = useState({ "PERF": 0, "VOL": 0, "DSTR": 0, "INN": 1 });

  useEffect(() => {
    setKey(props.match.params.tab);
  }, [props.match.params.tab]);

  const loadData = () => {
    dispatch(getMessages(val => dispatch(setLoading(val))))
    dispatch(nudgesDeepDive(nudgePeriod))
    dispatch(getBookmarks(nudgePeriod))

    if (props.tab) {
      setKey(props.tab)
    }
  }

  useEffect(() => {
    loadData();
  }, [defaultBrand, nudgePeriod])

  const
    BookmarkTabs = () => {
      const chatSocket = useRef(null)
      const { allMessages } = useSelector((state) => state.profile)
      const [msg, setMsg] = useState([...allMessages])


      useEffect(() => {

        chatSocket.current = new WebSocket(CHAT_WEBSOCKET_URL);


        chatSocket.current.onopen = (e) => {
          console.log('connected')
        }

        chatSocket.current.onmessage = (e) => {
          const data = JSON.parse(e.data);
          console.log("socket triggered", data)
          const message = JSON.parse(data.message)
          dispatch(updateMessages(message))
        };

        chatSocket.current.onclose = function (e) {
          console.error('Chat socket closed unexpectedly');
        };

        return () => {
          chatSocket.current.close();
        };
      }, [])


      useEffect(() => {
        setMsg([...allMessages])
      }, [allMessages])

      const sendMsg = (msg, nudgeId) => {
        let body = {
          'nudge_id': nudgeId,
          'added_by': sessionStorage.getItem('email'),
          'message': msg
        }
        try {
          chatSocket.current.send(JSON.stringify(body));
        }
        catch (e) {
          console.log(e)
        }

      }

      const tabChange = (key) => {
        if (key) {
          props.history.push("/alerts-" + key);
          setKey(key)
          setNudgeId(null);
        }
      }

      return (
        <Tabs defaultActiveKey={key} onChange={(key) => tabChange(key)} style={{ width: '100%' }}>
          <TabPane tab="Performance Summary" key="1">
            <AlertsTab load={6} tab={"Performance-Summary"} loadReport={"PERF"} messages={msg} sendMsg={sendMsg} nudge_id={nudgeId} scroll={true} remainingNudges={nudgesDeepDivePerf["remaining_nudges"]} data={nudgesDeepDivePerf["nudges"] && nudgesDeepDivePerf["nudges"]} nudgePeriod={nudgePeriod} setNudgePeriod={setNudgePeriod} nudgeScrollTop={nudgeScrollTop} setNudgeScrollTop={setNudgeScrollTop} loadData={loadData} />
          </TabPane>
          <TabPane tab="Competition Summary" key="2">
            <AlertsTab load={6} tab={"Competition-Summary"} loadReport={"VOL"} messages={msg} sendMsg={sendMsg} nudge_id={nudgeId} scroll={true} remainingNudges={nudgesDeepDiveVol["remaining_nudges"]} data={nudgesDeepDiveVol["nudges"] && nudgesDeepDiveVol["nudges"]} nudgePeriod={nudgePeriod} setNudgePeriod={setNudgePeriod} nudgeScrollTop={nudgeScrollTop} setNudgeScrollTop={setNudgeScrollTop} loadData={loadData} />
          </TabPane>
          <TabPane tab="Distribution Summary" key="3">
            <AlertsTab load={6} tab={"Distribution-Summary"} loadReport={"DSTR"} messages={msg} sendMsg={sendMsg} nudge_id={nudgeId} scroll={true} remainingNudges={nudgesDeepDiveDist["remaining_nudges"]} data={nudgesDeepDiveDist["nudges"] && nudgesDeepDiveDist["nudges"]} nudgePeriod={nudgePeriod} setNudgePeriod={setNudgePeriod} nudgeScrollTop={nudgeScrollTop} setNudgeScrollTop={setNudgeScrollTop} loadData={loadData} />
          </TabPane>
          {/* <TabPane tab="Innovation Tracker" key="4">
            <AlertsTab load={6} tab={"Innovation-Tracker"} loadReport={"INN"} messages={msg} sendMsg={sendMsg} nudge_id={nudgeId} scroll={true} remainingNudges={nudgesDeepDiveInn["remaining_nudges"]} data={nudgesDeepDiveInn["nudges"] && nudgesDeepDiveInn["nudges"]} nudgePeriod={nudgePeriod} setNudgePeriod={setNudgePeriod} nudgeScrollTop={nudgeScrollTop} setNudgeScrollTop={setNudgeScrollTop} loadData={loadData} />
          </TabPane> */}
        </Tabs>
      )
    }

  return (
    <Wrapper heading={"0"} innerHeading={"0"}>
      <Content className="site-layout-background content-body" style={{ background: '#fff' }}>
        <Loader loading={loading}>
          <Row>
            <BookmarkTabs />
          </Row>
        </Loader>
      </Content>
    </Wrapper>
  );
}

export default withRouter(Alerts);
