import { createSlice } from "@reduxjs/toolkit";
import CustomAxios from "../utilities/services/api.service";
import { ENDPOINTS } from "../utilities/constants";
import { findData, formatDate, getMaxDate, getMaxValue } from "../utilities/helper";
import { customerChannelDefault, customerRetailerDefault, setFilters } from "./dataSources.slicer";

/**
 * DataSource Refresh Dates Slicer
 * It manages the Redux State/Reducer/Actions for DataSource Refresh Dates usecase.
 */

// List of DataSources
const Nielsen = "NIELSEN";
const Stackline = "STACKLINE";
const Earnest = "EARNEST";
const TractorSupply = "TRACTOR SUPPLY";
const Financials = "FINANCIALS";
const Shipments = "SHIPMENTS";
const VolumeShifting = "VOLUME SHIFTING";
const PortfolioSummary = "VOLUME SHIFTING";
const ECOM = "ECOM";

const trackboardDS = [
  Nielsen,
]

const perfSummaryDS = [
  Nielsen,
];

const volumeShiftingDS = [
  Nielsen,
];

const portfolioSummaryDS = [
  Nielsen,
];

const distSummaryDS = [
  Nielsen,
];

const innovationDS = [
  Nielsen,
];

const diagnosticsDS = [
  ECOM,
];

const beAvailableDS = [
  ECOM,
];

const shareOfShelfDS = [
  ECOM,
];

const searchTermDS = [
  ECOM,
];

// Define the initial state for the DataSource Refresh Dates slice.
const initialState = {
  loading: 0,
  latestDate: { data: [] },
  dataSources: {},
  performanceSummary: {},
  volumeShifting: {},
  portfolioSummary: {},
  distributionSummary: {},
  innovationTracker: {},
  latestTimeYear: [],
  trackboard: {},
  diagnostics: {},
  beAvailable: {},
  shareOfShelf: {},
  searchTerm: {},
  dataSourceExport: {
    Nielsen: [],
    Stackline: [],
    Shipments: [],
    Financials: [],
  },
  thisPeriod: "",
  lastPeriod: "",
  latestTimePeriod: "",
  latestTimeYear: "",
};

// Create a Redux slice for managing data source refresh dates state and actions.
const refreshDateSlicer = createSlice({
  name: "refreshDate",
  initialState,
  reducers: {
    setLoading: (state, action) => ({
      ...state,
      loading: state.loading + (action.payload ? 1 : -1),
    }),
    setRefreshDates: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setDataSourceExport: (state, action) => ({
      ...state,
      dataSourceExport: action.payload.dataSourceExport
        ? { ...state.dataSourceExport, ...action.payload.dataSourceExport }
        : state.dataSourceExport,
    }),
    setLatestPeriod: (state, action) => ({
      ...state,
      latestTimePeriod: action.payload,
    }),
    setLatestYear: (state, action) => ({
      ...state,
      latestTimeYear: action.payload,
    })
  },
});

// Extract actions from the slice.
export const { setLoading, setRefreshDates, setDataSourceExport, setLatestPeriod, setLatestYear } =
  refreshDateSlicer.actions;

// Thunk to fetch all data sources refresh dates.
export const getDataSourcesRefresh = () => async (dispatch) => {
  dispatch(setRefreshDates(initialState));
  dispatch(setLoading(true));
  await CustomAxios(ENDPOINTS.getLatestTime, "GET")
    .then((response2) => {
      const data = response2.data.data;
      let { last_period: lastPeriodResponse, last_year: lastYearResponse, this_period: thisPeriodResponse, this_year: thisYearResponse } = response2.data.year_period_data;
      dispatch(setLatestPeriod(thisPeriodResponse));
      dispatch(setLatestYear(Number(thisYearResponse)));
      // if (!lastPeriodResponse) { 
      //   if (thisPeriodResponse === "P13") { lastPeriodResponse = "P01"; lastYearResponse = thisYearResponse - 1 }
      //   else { lastPeriodResponse = `${thisPeriodResponse.substring(0, 1)}${thisPeriodResponse.substring(2, thisPeriodResponse.length - 1) - 1}`; lastYearResponse = thisYearResponse;  } 

      // }
      // const latestDate = response2.data;

      // const dataSources = {
      //   Nielsen: {
      //     last_refresh: formatDate("2022-04-05T00:00:00Z"),
      //     next_refresh: formatDate("2022-05-02T00:00:00Z"),
      //     latest_period_available: "P3 2022"
      //   },
      //   Stackline: {
      //     last_refresh: formatDate("2022-04-01T00:00:00Z"),
      //     next_refresh: formatDate("2022-05-06T00:00:00Z"),
      //     latest_period_available: "P3 2022"
      //   },
      //   Financials: {
      //     last_refresh: formatDate("2022-03-14T00:00:00Z"),
      //     next_refresh: formatDate("2022-04-15T00:00:00Z"),
      //     latest_period_available: "P2 2022"
      //   },

      // };


      // const performanceSummary = {
      //   last_refresh: formatDate("2022-04-05T00:00:00Z"),
      //   next_refresh: formatDate("2022-05-02T00:00:00Z"),
      //   latest_period_available: "P3 2022"
      // };

      // const volumeShifting = {
      //   last_refresh: formatDate("2022-04-05T00:00:00Z"),
      //   next_refresh: formatDate("2022-05-02T00:00:00Z"),
      //   latest_period_available: "P3 2022"
      // };

      // const distributionSummary = {
      //   last_refresh: formatDate("2022-04-05T00:00:00Z"),
      //   next_refresh: formatDate("2022-05-02T00:00:00Z"),
      //   latest_period_available: "P3 2022"
      // };

      // const trackboard = {
      //   last_refresh: formatDate("2022-04-05T00:00:00Z"),
      //   next_refresh: formatDate("2022-05-02T00:00:00Z"),
      //   latest_period_available: "P3 2022"
      // };

      const dataSources = {};
      const findDataSource = (dataSourceName) => {
        const dataSource = findData(data, "data_source", dataSourceName);

        if (dataSource)
          dataSources[dataSourceName] = {
            ...dataSource,
            last_refresh: formatDate(dataSource.last_refresh),
            next_refresh: formatDate(dataSource.next_refresh),
          };
      };

      const findSummary = (summaryDS) => {
        const ds = data.filter(({ data_source }) =>
          summaryDS.includes(data_source?.toUpperCase())
        );

        return {
          last_refresh: getMaxDate(ds, "last_refresh"),
          next_refresh: getMaxDate(ds, "next_refresh"),
          latest_period_available: getMaxValue(ds, "latest_period_available"),
        };
      };

      // Call findDataSource function for each data source.
      findDataSource(Nielsen);
      findDataSource(Stackline);
      findDataSource(Earnest);
      findDataSource(TractorSupply);
      findDataSource(Financials);
      findDataSource(VolumeShifting);
      findDataSource(PortfolioSummary);
      findDataSource(Shipments);
      findDataSource(ECOM);

      // Call findSummary function for different data source types
      const trackboard = findSummary(trackboardDS);
      const performanceSummary = findSummary(perfSummaryDS);
      const volumeShifting = findSummary(volumeShiftingDS);
      const portfolioSummary = findSummary(portfolioSummaryDS);
      const distributionSummary = findSummary(distSummaryDS);
      const innovationTracker = findSummary(innovationDS);
      const diagnostics = findSummary(diagnosticsDS);
      const beAvailable = findSummary(beAvailableDS);
      const shareOfShelf = findSummary(shareOfShelfDS);
      const searchTerm = findSummary(searchTermDS);

      dispatch(
        setRefreshDates({
          // latestDate,
          dataSources,
          performanceSummary,
          volumeShifting,
          portfolioSummary,
          distributionSummary,
          innovationTracker,
          trackboard,
          diagnostics,
          beAvailable,
          shareOfShelf,
          searchTerm,
          thisPeriod: `${thisPeriodResponse} ${thisYearResponse}`,
          lastPeriod: `${lastPeriodResponse} ${lastYearResponse}`
        })
      );
      dispatch(setFilters({
        product_pet: [],
        product_technology: [],
        product_brand: [],
        product_manufacturer: [],
        product_sub_brand: [],
        product_pricing_tier: [],
        product_pricing_tier_partition: [],
        product_texture: [],
        product_package: [],
        product_package_size: [],
        customer_channel: customerChannelDefault,
        customer_retailer_details: customerRetailerDefault,
        period_year: [
          `${thisYearResponse}:${thisPeriodResponse}`
        ]
      }));
      dispatch(setLoading(false));
    })
    .catch((err) => {

      dispatch(setLoading(false));
    });
};

export default refreshDateSlicer.reducer;
