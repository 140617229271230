import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: {},
  kpi: {},
  tableData: {},
  macroTableData: {},
  microTableData: {},
  bubbleChart: {},
  bar: {},
  waterfall: {},
  line: {},
  pptTableData: {},
  pptLine: {},
  pptScatter: {},
  pptWaterfall: {},
  pptBar: {},
  pptPie: {},
};

const exportDataSlicer = createSlice({
  name: "exportData",
  initialState,
  reducers: {
    setExportData: (state, action) => ({
      ...state,
      filters: action.payload.filters ? action.payload.filters : state.filters,
      kpi: action.payload.kpi
        ? { ...state.kpi, ...action.payload.kpi }
        : state.kpi,
      tableData: action.payload.tableData
        ? { ...state.tableData, ...action.payload.tableData }
        : state.tableData,
      macroTableData: action.payload.macroTableData
        ? { ...state.macroTableData, ...action.payload.macroTableData }
        : state.macroTableData,
      microTableData: action.payload.microTableData
        ? { ...state.microTableData, ...action.payload.microTableData }
        : state.microTableData,
      bar: action.payload.bar
        ? { ...state.bar, ...action.payload.bar }
        : state.bar,
      waterfall: action.payload.waterfall
        ? { ...state.waterfall, ...action.payload.waterfall }
        : state.waterfall,
      line: action.payload.line
        ? { ...state.line, ...action.payload.line }
        : state.line,
      bubbleChart: action.payload.bubbleChart
        ? { ...state.bubbleChart, ...action.payload.bubbleChart }
        : state.bubbleChart,
    }),
    setPPTExportData: (state, action) => ({
      ...state,
      pptTableData: action.payload.pptTableData
        ? { ...state.pptTableData, ...action.payload.pptTableData }
        : state.pptTableData,
      pptLine: action.payload.pptLine
        ? { ...state.pptLine, ...action.payload.pptLine }
        : state.pptLine,
      pptScatter: action.payload.pptScatter
        ? { ...state.pptScatter, ...action.payload.pptScatter }
        : state.pptScatter,
      pptWaterfall: action.payload.pptWaterfall
        ? { ...state.pptWaterfall, ...action.payload.pptWaterfall }
        : state.pptWaterfall,
      pptBar: action.payload.pptBar
        ? { ...state.pptBar, ...action.payload.pptBar }
        : state.pptBar,
      pptPie: action.payload.pptPie
        ? { ...state.pptPie, ...action.payload.pptPie }
        : state.pptPie,
    }),
  },
});

export const { setExportData, setPPTExportData } = exportDataSlicer.actions;
export default exportDataSlicer.reducer;
