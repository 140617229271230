import React from 'react';
import { Layout } from 'antd';
import Header from '../header';
import { useSelector } from 'react-redux';

/**
 * Wrapper Component
 * It provides  header and footer message functionality based on certain conditions. 
 */

function Wrapper(props) {
  const { userNote } = useSelector((state) => state.admin);
  const is_note_enabled = userNote.is_note_enabled;

  const layoutStyle = Boolean(is_note_enabled)
    ? { minHeight: "calc(100vh - 45px)", overflowY: "auto" }
    : { minHeight: "calc(100vh - 12px)" };
  
  return (
    <Layout>
       {/* Render the header if hideHeader is not set to true */}
      {!props.hideHeader && <Header hideHeaderBrand={props.hideHeaderBrand} tab={props.heading} innerTab={props.innerHeading} ecomTab={props.ecomTab} reportName={props.reportName} />}
      <Layout
      // Set style for the page content based on hidePage prop
        style={!props.hidePage ? layoutStyle : {}}
      >
        <Layout style={{ background: '#FAFAFA' }} className={Boolean(is_note_enabled) ? 'footer-message' : ''}>
          {/* Render the child components */}
          {props.children}
        </Layout>
        {/* Render the user note message if is_note_enabled is true */}
        {Boolean(is_note_enabled) && <div className='trackboard-info' style={{ position: 'fixed', bottom: 0, left: 30, right: 30, width: 'auto' }}>
          <p><span style={{ color: 'red' }}>Notification:</span> {userNote.user_note}</p>
          {/* <p><span style={{color: 'red'}}>Note:</span> Refresh of Finance data on 05/30/22 failed. The Cascade team is urgently working with the source team(MOSOT) to rectify the problem. <br/>Please avoid using the GSV, NSV and MAC% in the meantime.</p> */}
        </div>}
      </Layout>
    </Layout>
  );
}

export default Wrapper;
