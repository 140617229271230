import React from "react";
import { CaretUpFilled, CaretDownFilled } from '@ant-design/icons';
import { formatNumberExcel, numFmt } from "../../utilities/exports/excel";
import { formatNumber, hasValue, valueTypes } from '../../utilities/helper';

// render for UI
const renderFunction = (val, rec) => {
  let isZero = true;
  let dec = 1;

  if (val) {
    if (
      formatNumber(val.abs, 1, true) === valueTypes.NA &&
      formatNumber(val.abs, 2, true) !== valueTypes.NA
    ) {
      isZero = false;
      dec = 2;
    }
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs, dec)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance, dec)}
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance, dec)}
          </div>
        )}
      </div>
    );
};

const renderNumberPercentFunction = (val, rec) => {
  let isZero = true;
  let dec = 1;

  if (val) {
    if (
      formatNumber(val.abs, 1, true) === valueTypes.NA &&
      formatNumber(val.abs, 2, true) !== valueTypes.NA
    ) {
      isZero = false;
      dec = 2;
    }
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs, dec)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance, dec)}%
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance, dec)}%
          </div>
        )}
      </div>
    );
};

const renderPointsFunction = (val, rec) => {
  let isZero = true;
  let dec = 1;

  if (val) {
    if (
      formatNumber(val.abs, 1, true) === valueTypes.NA &&
      formatNumber(val.abs, 2, true) !== valueTypes.NA
    ) {
      isZero = false;
      dec = 2;
    }
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs, dec)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance, dec)}pts
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance, dec)}pts
          </div>
        )}
      </div>
    );
};

const renderPercentPointsFunction = (val, rec) => {
  let isZero = true;
  let dec = 1;

  if (val) {
    if (
      formatNumber(val.abs, 1, true) === valueTypes.NA &&
      formatNumber(val.abs, 2, true) !== valueTypes.NA
    ) {
      isZero = false;
      dec = 2;
    }
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs, dec)}%</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance, dec)}pts
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance, dec)}pts
          </div>
        )}
      </div>
    );
};

const renderWholeNumberPercentFunction = (val, rec) => {
  let isZero = true;
  let dec = 1;

  if (val) {
    if (
      formatNumber(val.abs, 1, true) === valueTypes.NA &&
      formatNumber(val.abs, 2, true) !== valueTypes.NA
    ) {
      isZero = false;
      dec = 2;
    }
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs, 0)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance, dec)}%
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance, dec)}%
          </div>
        )}
      </div>
    );
};

const renderDollarFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">${formatNumber(val.abs)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}
          </div>
        )}
      </div>
    );
};

const renderDollarPointsFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">${formatNumber(val.abs)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}pts
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}pts
          </div>
        )}
      </div>
    );
};

const renderDollarPercentFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">${formatNumber(val.abs)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}%
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}%
          </div>
        )}
      </div>
    );
};

const renderPercentageFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs)}%</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}
          </div>
        )}
      </div>
    );
};

const renderPercentagePointsFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs)}%</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}pts
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}pts
          </div>
        )}
      </div>
    );
};

const renderAbsPercentageFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}%
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}%
          </div>
        )}
      </div>
    );
};

// Format the value before writing in the excel.
const renderTextVarianceNumberFunction = (val, rec) => ({
  value: val && val.variance,
  numFmt: numFmt.number,
});

const renderTextWholeNumberFunction = (val, rec) => ({
  value: val && val.abs,
  numFmt: numFmt.wholeNumber,
});

const renderTextVariancePercentageFunction = (val, rec) => ({
  value: formatNumberExcel(val && val.variance, true),
  numFmt: numFmt.percentage,
});

const renderTextAbsPercentageFunction = (val, rec) => ({
  value: formatNumberExcel(val && val.abs, true),
  numFmt: numFmt.percentage,
});

// renderPPT
const renderPPTWholeNumberPercentageFunction = (val) =>
  `${formatNumber(val && val.abs, 0)}\n${formatNumber(val && val.variance)}%`;

const renderPPTPercentagePtsFunction = (val) =>
  `${formatNumber(val && val.abs)}%\n${formatNumber(val && val.variance)}pts`;

const renderPPTFunction = (val) =>
  `${formatNumber(val && val.abs)}\n${formatNumber(val && val.variance)}`;

const renderPPTDollarPercentFunction = (val) =>
  `$${formatNumber(val && val.abs)}\n${formatNumber(val && val.variance)}%`;

const renderPPTNumberPercentFunction = (val) =>
  `${formatNumber(val && val.abs)}\n${formatNumber(val && val.variance)}%`;


// UI
const baMacroCols = [
  {
    title: "Sub Brand", // 0
    children: [
      {
        title: "",
        dataIndex: "table_key",
        key: "table_key",
        width: '20%',
        // fixed: 'left',
      },
    ],
  },
  {
    title: "CSL", // 1
  },
  {
    title: "# of Items Listed", // 2
  },
  {
    title: "In Stock %", // 3
  },
  {
    title: "Sales", // 4
  },
];
const dgEcomPerf = [
  {
    title: "", // 0
    children: [
      {
        title: "",
        children: [
          {
            title: "Sub Brand",
            dataIndex: "table_key",
            dataIndexPPT: "table_key",
            key: "table_key",
            width: 200,
            // align: "left"
            // fixed: 'left',
          },
        ]
      }
    ]

  },
  {
    title: "Sales",
    children: [
      {
        title: "$ Share",
        className: "td-border-left",
      },
      {
        title: "$ Sales",
        className: "td-border-left",
      },
    ],
  },
  {
    title: "Be Available",
    children: [
      {
        title: "# of Items Listed",
        className: "td-border-left",
      },
      {
        title: "In Stock %",
        className: "td-border-left",
      },
    ],
  },
  {
    title: "Be Visible",
    children: [
      // {
      //   title: "Traffic",
      //   className: "td-border-left",
      // },
      {
        title: "Share of Shelf",
        className: "td-border-left",
      },
    ],
  },
  // hide the Be Selected KPI (task id : 237423)
  // {
  //   title: "Be Selected",
  //   children: [
  //     // {
  //     //   title: "Compliance Score",
  //     //   className: "td-border-left",
  //     // },
  //     {
  //       title: "Average Rating",
  //       className: "td-border-left",
  //     },
  //     {
  //       title: "# of reviews",
  //       className: "td-border-left",
  //     },
  //     // {
  //     //   title: "Conversion Rate",
  //     //   className: "td-border-left",
  //     // },
  //   ],
  // },
]
const dgEcomPerfExport = [
  {
    title: "Sub Brand", // 0
    children: [
      {
        title: "",
        dataIndex: "table_key",
        key: "table_key",
        width: 200,
        // align: "left"
        // fixed: 'left',
      },
    ],
  },
]
const psEcomPerf = [
  {
    title: "",
    children: [
      {
        title: "Sub Brands",
        dataIndex: "retailer_key",
        key: "retailer_key",
        width: '10%',
      },
    ],
  },
  {
    title: "Be Available",
    children: [
      {
        title: "# of Items Listed",
        dataIndex: "dollar_latest_4w",
        key: "dollar_latest_4w",
        render: renderFunction,
        width: "5.75%",
        className: "td-border-left",
      },
      {
        title: "In Stock %",
        dataIndex: "dollar_latest_13w",
        key: "dollar_latest_13w",
        render: renderPercentageFunction,
        width: "5.75%",
        className: "td-border-left",
      },
    ],
  },
  {
    title: "Be Visible",
    children: [
      {
        title: "Traffic",
        dataIndex: "gsv_latest_4w",
        key: "gsv_latest_4w",
        render: renderFunction,
        width: "6%",
        className: "td-border-left",
      },
      {
        title: "Share of Shelf",
        dataIndex: "gsv_latest_13w",
        key: "gsv_latest_13w",
        render: renderPercentageFunction,
        width: "6%",
        className: "td-border-left",
      },
    ],
  },
  {
    title: "Be Selected",
    children: [
      {
        title: "Compliance Score",
        dataIndex: "dollar_latest_52w",
        key: "dollar_latest_52w",
        render: renderFunction,
        width: "6%",
        className: "td-border-left",
      },
      {
        title: "Average Rating",
        dataIndex: "nsv_latest_13w",
        key: "nsv_latest_13w",
        render: renderFunction,
        width: "6%",
        className: "td-border-left",
      },
      {
        title: "# of reviews",
        dataIndex: "nsv_latest_52w",
        key: "nsv_latest_52w",
        render: renderFunction,
        width: "6%",
        className: "td-border-left",
      },
      {
        title: "Conversion Rate",
        dataIndex: "nsv_latest_4w",
        key: "nsv_latest_4w",
        render: renderPercentageFunction,
        width: "6%",
        className: "td-border-left",
      },
    ],
  },
]
const psEcomCompitition = [
  {
    title: "Benchmark",
    children: [
      {
        title: "",
        dataIndex: "title",
        key: "title",
        width: '12%',
      },
    ],
  },
  {
    title: "Basic KPIs",
    children: [
      {
        title: "$ share",
        dataIndex: "dollar_share_total_ecom",
        key: "dollar_share_total_ecom",
        render: renderPercentageFunction,
        // width: "4%",
        className: "td-border-left",
      },
      {
        title: "MKT RSV",
        dataIndex: "dollar_share_retailer",
        key: "dollar_share_retailer",
        render: renderDollarFunction,
        // width: "4%",
      },
    ],
  },
  {
    title: "Be Available",
    children: [
      {
        title: "# of Items Listed",
        dataIndex: "dollar_sales_total_ecom",
        key: "dollar_sales_total_ecom",
        // width: "5%",
        render: renderFunction,
        className: "td-border-left",
      },
      {
        title: "In Stock %",
        dataIndex: "dollar_sales_retailer",
        key: "dollar_sales_retailer",
        render: renderFunction,
        // width: "5%",
      },
    ],
  },
  {
    title: "Be Visible",
    children: [
      {
        title: "Traffic",
        dataIndex: "in_stock_total_ecom",
        key: "in_stock_total_ecom",
        // width: "4%",
        render: renderFunction,
        className: "td-border-left",
      },
      {
        title: "Share of Shelf",
        dataIndex: "in_stock_retailer",
        key: "in_stock_retailer",
        render: renderPercentageFunction,
        // width: "4%",
      },
    ],
  },
  {
    title: "Be Selected",
    children: [
      {
        title: "Compliance Score",
        dataIndex: "number_items_total_ecom",
        key: "number_items_total_ecom",
        render: renderFunction,
        // width: "5%",
        className: "td-border-left",
      },
      {
        title: "Avg. Rating",
        dataIndex: "number_items_retailer",
        key: "number_items_retailer",
        render: renderFunction,
        // width: "5%",
      },
      {
        title: "# of Reviews",
        dataIndex: "number_items_reviews",
        key: "number_items_reviews",
        render: renderFunction,
        // width: "5%",
      },
      {
        title: "Conversion Rate",
        dataIndex: "number_items_rate",
        key: "number_items_rate",
        render: renderPercentageFunction,
        // width: "5%",
      },
    ],
  },
];

export default {
  psEcomPerf,
  psEcomCompitition,
  dgEcomPerf,
  dgEcomPerfExport,
  baMacroCols,
  renderFunction,
  renderDollarFunction,
  renderPercentageFunction,
  renderPointsFunction,
  renderDollarPointsFunction,
  renderPercentagePointsFunction,
  renderDollarPercentFunction,
  renderNumberPercentFunction,
  renderPercentPointsFunction,
  renderWholeNumberPercentFunction,
  renderTextWholeNumberFunction,
  renderTextVarianceNumberFunction,
  renderTextVariancePercentageFunction,
  renderTextAbsPercentageFunction,
  renderAbsPercentageFunction,

  renderPPTFunction,
  renderPPTWholeNumberPercentageFunction,
  renderPPTPercentagePtsFunction,
  renderPPTDollarPercentFunction,
  renderPPTNumberPercentFunction,

}
