import { formatNumber, itemsListed } from "../../utilities/helper";
import columns from "./stColumns";

export const rowKey = "index";

export const KeywordCategory = [
  {
    title: "Dog",
    value: "Dog",
    checkable: false,
    children: [
      {
        title: "Total Dog",
        value: "Total Dog",
      },
      {
        title: "Dry Dog",
        value: "Dry Dog",
      },
      {
        title: "Wet Dog",
        value: "Wet Dog",
      },
      {
        title: "Dog Treats",
        value: "Dog Treats",
      },
    ],
  },
  {
    title: "Cat",
    value: "Cat",
    checkable: false,
    children: [
      {
        title: "Total Cat",
        value: "Total Cat",
      },
    ],
  },
];
export const shareCompititionData = [
  {
    title: "Internal",
    brand_data: [
      {
        title: "Mars",
        dollar_share_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        dollar_share_retailer: {
          abs: 12,
          variance: 10.0,
        },
        dollar_sales_total_ecom: {
          abs: 110000,
          variance: 10.0,
        },
        dollar_sales_retailer: {
          abs: 110000,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        number_items_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_reviews: {
          abs: 12,
          variance: 10.0,
        },
        number_items_rate: {
          abs: 12,
          variance: 10.0,
        },
      },
      {
        title: "Greenies",
        dollar_share_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        dollar_share_retailer: {
          abs: 12,
          variance: 10.0,
        },
        dollar_sales_total_ecom: {
          abs: 110000,
          variance: 10.0,
        },
        dollar_sales_retailer: {
          abs: 110000,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        number_items_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_reviews: {
          abs: 12,
          variance: 10.0,
        },
        number_items_rate: {
          abs: 12,
          variance: 10.0,
        },
      },
    ],
  },
  {
    title: "Category Benchmark",
    brand_data: [
      {
        title: "Nutro",
        dollar_share_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        dollar_share_retailer: {
          abs: 12,
          variance: 10.0,
        },
        dollar_sales_total_ecom: {
          abs: 110000,
          variance: 10.0,
        },
        dollar_sales_retailer: {
          abs: 110000,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        number_items_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_reviews: {
          abs: 12,
          variance: 10.0,
        },
        number_items_rate: {
          abs: 12,
          variance: 10.0,
        },
      },
      {
        title: "Cesar",
        dollar_share_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        dollar_share_retailer: {
          abs: 12,
          variance: 10.0,
        },
        dollar_sales_total_ecom: {
          abs: 110000,
          variance: 10.0,
        },
        dollar_sales_retailer: {
          abs: 110000,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        number_items_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_reviews: {
          abs: 12,
          variance: 10.0,
        },
        number_items_rate: {
          abs: 12,
          variance: 10.0,
        },
      },
    ],
  },
  {
    title: "Competition Benchmark",
    brand_data: [
      {
        title: "Crave",
        dollar_share_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        dollar_share_retailer: {
          abs: 12,
          variance: 10.0,
        },
        dollar_sales_total_ecom: {
          abs: 110000,
          variance: 10.0,
        },
        dollar_sales_retailer: {
          abs: 110000,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        number_items_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_reviews: {
          abs: 12,
          variance: 10.0,
        },
        number_items_rate: {
          abs: 12,
          variance: 10.0,
        },
      },
      {
        title: "IAMS",
        dollar_share_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        dollar_share_retailer: {
          abs: 12,
          variance: 10.0,
        },
        dollar_sales_total_ecom: {
          abs: 110000,
          variance: 10.0,
        },
        dollar_sales_retailer: {
          abs: 110000,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        number_items_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_reviews: {
          abs: 12,
          variance: 10.0,
        },
        number_items_rate: {
          abs: 12,
          variance: 10.0,
        },
      },
    ],
  },
];

const numberTraffic = "# of Traffic";
const percentTraffic = "% of Traffic";
export const viewBySearch = [numberTraffic, percentTraffic];

export const barLegends = {
  [numberTraffic]: ["Search Term Traffic", "Cumulative Traffic"],
  [percentTraffic]: ["Traffic %", "Cumulative Traffic %"],
};

export const viewByStretagy = ["Category", "Brand"];
export const stretagyCategory = ["Dry Dog", "Wet Dog", "Dry Cat", "Wet Cat"];
export const keywordType = [
  {
    title: "Generic",
    value: "Generic",
    key: "Generic",
    children: [],
  },
  {
    title: "Branded Mars",
    value: "Branded Mars",
    key: "Branded Mars",
    children: [],
  },
  {
    title: "Branded Compititor",
    value: "Branded Compititor",
    key: "Branded Compititor",
    children: [],
  },
];
export const searchTermKPI = [
  "$ Share",
  "Unit Share",
  "In Stock %",
  itemsListed,
  "Total Share of Shelf",
  "Paid Share of Shelf",
  "Organic Share of Shelf",
  "Average Number of Placements",
  "Average Placement",
];

export const trafficSourceData = ["Total", "Organic", "Paid"];
export const sharekpiData = ["Total", "Organic", "Paid"];

export const columnBulletShareData = [
  {
    customer_ratailer_details: "Amazon",
    "Share of Shelf": 58,
    "$ Share": [350000, 352500],
  },
  {
    customer_ratailer_details: "Chewy",
    "Share of Shelf": 47,
    "$ Share": [280000, 282500],
  },
  {
    customer_ratailer_details: "Walmart",
    "Share of Shelf": 40,
    "$ Share": [180000, 182500],
  },
  {
    customer_ratailer_details: "Target",
    "Share of Shelf": 39,
    "$ Share": [160000, 162500],
  },
  {
    customer_ratailer_details: "Retailer 5",
    "Share of Shelf": 32,
    "$ Share": [140000, 142500],
  },
  {
    customer_ratailer_details: "Retailer 6",
    "Share of Shelf": 25,
    "$ Share": [80000, 82500],
  },
  {
    customer_ratailer_details: "Retailer 7",
    "Share of Shelf": 22,
    "$ Share": [60000, 62500],
  },
  {
    customer_ratailer_details: "Retailer 8",
    "Share of Shelf": 17,
    "$ Share": [45000, 47500],
  },
];

export const columnBulletShareSubBrandData = [
  {
    customer_ratailer_details: "Original",
    "Share of Shelf": 58,
    "$ Share": [300000, 302500],
  },
  {
    customer_ratailer_details: "Pill pocket",
    "Share of Shelf": 47,
    "$ Share": [250000, 252500],
  },
  {
    customer_ratailer_details: "Dental",
    "Share of Shelf": 40,
    "$ Share": [180000, 182500],
  },
  {
    customer_ratailer_details: "SmartBytes",
    "Share of Shelf": 39,
    "$ Share": [160000, 162500],
  },
  {
    customer_ratailer_details: "Blueberry",
    "Share of Shelf": 32,
    "$ Share": [140000, 142500],
  },
  {
    customer_ratailer_details: "Hip&Joint",
    "Share of Shelf": 25,
    "$ Share": [80000, 82500],
  },
  {
    customer_ratailer_details: "BreathBuster",
    "Share of Shelf": 22,
    "$ Share": [60000, 62500],
  },
  {
    customer_ratailer_details: "Variety Pack",
    "Share of Shelf": 17,
    "$ Share": [45000, 47500],
  },
];

export const columnBulletShareCompititionData = [
  {
    customer_ratailer_details: "Greenies",
    "Share of Shelf": 58,
    "$ Share": [300000, 302500],
  },
  {
    customer_ratailer_details: "Milk Bone",
    "Share of Shelf": 47,
    "$ Share": [250000, 252500],
  },
  {
    customer_ratailer_details: "Whimzees",
    "Share of Shelf": 40,
    "$ Share": [180000, 182500],
  },
  {
    customer_ratailer_details: "Bluecore",
    "Share of Shelf": 39,
    "$ Share": [160000, 162500],
  },
  {
    customer_ratailer_details: "Merrick",
    "Share of Shelf": 32,
    "$ Share": [130000, 132500],
  },
  {
    customer_ratailer_details: "Brand 6",
    "Share of Shelf": 25,
    "$ Share": [90000, 92500],
  },
  {
    customer_ratailer_details: "Brand 7",
    "Share of Shelf": 22,
    "$ Share": [60000, 62500],
  },
  {
    customer_ratailer_details: "Brand 8",
    "Share of Shelf": 17,
    "$ Share": [35000, 37500],
  },
];

export const columnLineShareData = [
  {
    period_week: "P6W04",
    "Share of Shelf": 20,
    "$ Share": 350000,
  },
  {
    period_week: "P7W01",
    "Share of Shelf": 43,
    "$ Share": 270000,
  },
  {
    period_week: "P7W02",
    "Share of Shelf": 37,
    "$ Share": 260000,
  },
  {
    period_week: "P7W03",
    "Share of Shelf": 37,
    "$ Share": 305000,
  },
  {
    period_week: "P7W04",
    "Share of Shelf": 37,
    "$ Share": 340000,
  },
  {
    period_week: "P8W01",
    "Share of Shelf": 43,
    "$ Share": 310000,
  },
  {
    period_week: "P8W02",
    "Share of Shelf": 37,
    "$ Share": 308000,
  },
  {
    period_week: "P8W03",
    "Share of Shelf": 43,
    "$ Share": 300000,
  },
  {
    period_week: "P8W04",
    "Share of Shelf": 37,
    "$ Share": 300000,
  },
  {
    period_week: "P9W01",
    "Share of Shelf": 37,
    "$ Share": 240000,
  },
  {
    period_week: "P9W02",
    "Share of Shelf": 37,
    "$ Share": 220000,
  },
  {
    period_week: "P9W03",
    "Share of Shelf": 43,
    "$ Share": 320000,
  },
  {
    period_week: "P9W04",
    "Share of Shelf": 43,
    "$ Share": 475000,
  },
];

export const shareTableData = [
  {
    title: "Dog Dry",
    key: "Dog Dry",
    items_listed_total_ecom: {
      abs: 10,
      variance: 10.0,
    },
    sub_brand_data: [
      {
        title: "Original",
        key: "Original",
        csl_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        csl_retailer: {
          abs: 12,
          variance: 10.0,
        },
        items_listed_total_ecom: {
          abs: 10,
          variance: 10.0,
        },
        items_listed_retailer: {
          abs: 10,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        sales_total_ecom: {
          abs: "Overall Positive",
          // variance: '',
        },
        sales_retailer: {
          abs: 2000,
          variance: 10.0,
        },
        sub_brand_data: [
          {
            title: "5 oz",
            key: "5 oz",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 10,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 10,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: "Overall Positive",
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
            sub_brand_data: [
              {
                title: "UPC 1",
                key: "UPC 1",
                csl_total_ecom: {
                  abs: 12,
                  variance: 10.0,
                },
                csl_retailer: {
                  abs: 12,
                  variance: 10.0,
                },
                items_listed_total_ecom: {
                  abs: 10,
                  variance: 10.0,
                },
                items_listed_retailer: {
                  abs: 10,
                  variance: 10.0,
                },
                in_stock_total_ecom: {
                  abs: 12,
                  variance: 10.0,
                },
                in_stock_retailer: {
                  abs: 12,
                  variance: 10.0,
                },
                sales_total_ecom: {
                  abs: "Overall Positive",
                  // variance: '',
                },
                sales_retailer: {
                  abs: 2000,
                  variance: 10.0,
                },
              },
              {
                title: "UPC 2",
                key: "UPC 2",
                csl_total_ecom: {
                  abs: 12,
                  variance: 10.0,
                },
                csl_retailer: {
                  abs: 12,
                  variance: 10.0,
                },
                items_listed_total_ecom: {
                  abs: 10,
                  variance: 10.0,
                },
                items_listed_retailer: {
                  abs: 10,
                  variance: 10.0,
                },
                in_stock_total_ecom: {
                  abs: 12,
                  variance: 10.0,
                },
                in_stock_retailer: {
                  abs: 12,
                  variance: 10.0,
                },
                sales_total_ecom: {
                  abs: "Overall Positive",
                  // variance: '',
                },
                sales_retailer: {
                  abs: 2000,
                  variance: 10.0,
                },
              },
            ],
          },
          {
            title: "10 oz",
            key: "10 oz",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 10,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 10,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: "Overall Positive",
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
          },
        ],
      },
    ],
  },
  {
    title: "Pill Pocket",
    key: "Pill Pocket",
    items_listed_total_ecom: {
      abs: 10,
      variance: 10.0,
    },
    sub_brand_data: [
      {
        title: "Pill Pocket 1",
        key: "Pill Pocket 1",
        csl_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        csl_retailer: {
          abs: 12,
          variance: 10.0,
        },
        items_listed_total_ecom: {
          abs: 10,
          variance: 10.0,
        },
        items_listed_retailer: {
          abs: 10,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        sales_total_ecom: {
          abs: "Overall Positive",
          // variance: '',
        },
        sales_retailer: {
          abs: 2000,
          variance: 10.0,
        },
      },
    ],
  },
];

export const keywordData = [
  {
    upc: "Keyword 1",
    csl_total_ecom: {
      abs: "4th",
      variance: 2,
    },
    csl_retailer: {
      abs: 100,
      variance: 2,
    },
    items_listed_total_ecom: {
      abs: "Generic",
      variance: "",
    },
    items_listed_retailer: {
      abs: 16,
      variance: 1,
    },
    in_stock_total_ecom: {
      abs: 24,
      variance: 2,
    },
    in_stock_retailer: {
      abs: 150,
      variance: 1,
    },
    sales_total_ecom: {
      abs: "Overall Positive",
      // variance: '',
    },
    sales_retailer: {
      abs: 2000,
      variance: 10.0,
    },
  },
  {
    upc: "Keyword 2",
    csl_total_ecom: {
      abs: "4th",
      variance: 2,
    },
    csl_retailer: {
      abs: 100,
      variance: 2,
    },
    items_listed_total_ecom: {
      abs: "Generic",
      variance: "",
    },
    items_listed_retailer: {
      abs: 16,
      variance: 1,
    },
    in_stock_total_ecom: {
      abs: 24,
      variance: 2,
    },
    in_stock_retailer: {
      abs: 150,
      variance: 1,
    },
    sales_total_ecom: {
      abs: "Overall Positive",
      // variance: '',
    },
    sales_retailer: {
      abs: 2000,
      variance: 10.0,
    },
  },
  {
    upc: "Keyword 3",
    csl_total_ecom: {
      abs: "4th",
      variance: 2,
    },
    csl_retailer: {
      abs: 100,
      variance: 2,
    },
    items_listed_total_ecom: {
      abs: "Generic",
      variance: "",
    },
    items_listed_retailer: {
      abs: 16,
      variance: 1,
    },
    in_stock_total_ecom: {
      abs: 24,
      variance: 2,
    },
    in_stock_retailer: {
      abs: 150,
      variance: 1,
    },
    sales_total_ecom: {
      abs: "Overall Positive",
      // variance: '',
    },
    sales_retailer: {
      abs: 2000,
      variance: 10.0,
    },
  },
  {
    upc: "Keyword 4",
    csl_total_ecom: {
      abs: "4th",
      variance: 2,
    },
    csl_retailer: {
      abs: 100,
      variance: 2,
    },
    items_listed_total_ecom: {
      abs: "Generic",
      variance: "",
    },
    items_listed_retailer: {
      abs: 16,
      variance: 1,
    },
    in_stock_total_ecom: {
      abs: 24,
      variance: 2,
    },
    in_stock_retailer: {
      abs: 150,
      variance: 1,
    },
    sales_total_ecom: {
      abs: "Overall Positive",
      // variance: '',
    },
    sales_retailer: {
      abs: 2000,
      variance: 10.0,
    },
  },
];

export const opportunityData = [
  {
    keyword: "[other traffic]",
    traffic: 21.065675340768276,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "[other traffic]",
    traffic: 90.53754502632309,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "[other traffic]",
    traffic: 36.15323224261772,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "[other traffic]",
    traffic: 100.0,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "[other traffic]",
    traffic: 38.760563380281695,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "[other traffic]",
    traffic: 78.86710239651417,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "[other traffic]",
    traffic: 80.91962346125996,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "[other traffic]",
    traffic: 44.842924659438424,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "acana dog treats",
    traffic: 0.056338028169014086,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "acana dog treats",
    traffic: 0.027800945232137893,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "appetite stimulant for dogs",
    traffic: 0.23942537909018355,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "appetite stimulant for dogs",
    traffic: 0.08340283569641367,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "big dog treats",
    traffic: 0.11267605633802817,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "big dog treats",
    traffic: 0.055601890464275786,
    traffic_change: 0,
    traffic_growth: 0,
  },
  { keyword: "broths", traffic: 0.0, traffic_change: 0, traffic_growth: 0 },
  { keyword: "broths", traffic: 0.0, traffic_change: 0, traffic_growth: 0 },
  {
    keyword: "busy bones dog treats",
    traffic: 0.8450704225352111,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "busy bones dog treats",
    traffic: 0.4170141784820684,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "can chicken",
    traffic: 0.29093931837073983,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "can chicken",
    traffic: 0.25343953656770457,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "canidae life stages for dogs",
    traffic: 0.23942537909018355,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "canidae life stages for dogs",
    traffic: 0.08340283569641367,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "cat food topper",
    traffic: 0.05541701302299806,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "cat food topper",
    traffic: 0.04827419744146754,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "cat wet food",
    traffic: 0.4433361041839845,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "cat wet food",
    traffic: 0.3861935795317403,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "cheese sticks for dogs",
    traffic: 1.0704225352112675,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "cheese sticks for dogs",
    traffic: 0.52821795941062,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "chomps",
    traffic: 5.464788732394366,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "chomps",
    traffic: 2.696691687517376,
    traffic_change: 0,
    traffic_growth: 0,
  },
  { keyword: "cure", traffic: 0.0, traffic_change: 0, traffic_growth: 0 },
  { keyword: "cure", traffic: 0.0, traffic_change: 0, traffic_growth: 0 },
  {
    keyword: "dental dog chews",
    traffic: 4.563380281690141,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dental dog chews",
    traffic: 2.2518765638031693,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dental treats",
    traffic: 0.7323943661971831,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dental treats",
    traffic: 0.3614122880177926,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "diamond pet foods",
    traffic: 7.2796934865900385,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "diamond pet foods",
    traffic: 0.2293024378469708,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dog bones for large dogs",
    traffic: 2.535211267605634,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dog bones for large dogs",
    traffic: 1.2510425354462051,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dog bones large",
    traffic: 0.22535211267605634,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dog bones large",
    traffic: 0.11120378092855157,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dog dental treats large",
    traffic: 2.084507042253521,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dog dental treats large",
    traffic: 1.028634973589102,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dog food for sensitive skin",
    traffic: 8.060654429369512,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dog food for sensitive skin",
    traffic: 2.807895468445927,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dog formula for puppies",
    traffic: 3.192338387869114,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dog formula for puppies",
    traffic: 1.1120378092855159,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dog plates for food",
    traffic: 3.7037037037037033,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dog plates for food",
    traffic: 0.4726160689463442,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dog pop bags",
    traffic: 0.056338028169014086,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dog pop bags",
    traffic: 0.027800945232137893,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dogs dental chews",
    traffic: 11.492957746478872,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dogs dental chews",
    traffic: 5.67139282735613,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dough conditioner",
    traffic: 1.1267605633802817,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dough conditioner",
    traffic: 0.5560189046427579,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dream bones",
    traffic: 0.4507042253521127,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dream bones",
    traffic: 0.22240756185710314,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dreambone peanut butter",
    traffic: 3.943661971830986,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "dreambone peanut butter",
    traffic: 1.9460661662496523,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "fancy feast appetizers",
    traffic: 0.1662510390689942,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "fancy feast appetizers",
    traffic: 0.1448225923244026,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "fancy feast appetizers for cats",
    traffic: 2.0781379883624274,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "fancy feast appetizers for cats",
    traffic: 1.8102824040550327,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "fancy feast delights",
    traffic: 0.30479357162648935,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "fancy feast delights",
    traffic: 0.26550808592807146,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "fancy feast grilled turkey",
    traffic: 1.773344416735938,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "fancy feast grilled turkey",
    traffic: 1.5447743181269613,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "friskies canned cat food",
    traffic: 3.4497090606816294,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "friskies canned cat food",
    traffic: 3.005068790731354,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "funky chunky",
    traffic: 0.2178649237472767,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "funky chunky",
    traffic: 0.027800945232137893,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "hartz lickable treats for cats",
    traffic: 11.648079306071871,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "hartz lickable treats for cats",
    traffic: 1.1344436398744873,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "hills prescription cat food",
    traffic: 7.662835249042145,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "hills prescription cat food",
    traffic: 0.24137098720733766,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "hills science",
    traffic: 0.07980845969672785,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "hills science",
    traffic: 0.027800945232137893,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "i and love and you",
    traffic: 0.13854253255749516,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "i and love and you",
    traffic: 0.12068549360366883,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "lamb lung dog treats",
    traffic: 0.11267605633802817,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "lamb lung dog treats",
    traffic: 0.055601890464275786,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "large dog bones",
    traffic: 0.4507042253521127,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "large dog bones",
    traffic: 0.22240756185710314,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "low protein dog food",
    traffic: 0.4357298474945534,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "low protein dog food",
    traffic: 0.055601890464275786,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "mack",
    traffic: 0.3325020781379884,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "mack",
    traffic: 0.2896451846488052,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "marosnacks",
    traffic: 0.3380281690140845,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "marosnacks",
    traffic: 0.16680567139282734,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "merrick lil plates for dogs",
    traffic: 0.6535947712418301,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "merrick lil plates for dogs",
    traffic: 0.08340283569641367,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "milkbone maro snacks for dogs",
    traffic: 0.5633802816901409,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "milkbone maro snacks for dogs",
    traffic: 0.27800945232137897,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "natural dog bones",
    traffic: 0.056338028169014086,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "natural dog bones",
    traffic: 0.027800945232137893,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "on running",
    traffic: 10.727969348659004,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "on running",
    traffic: 0.3379193820902728,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "oranges fresh bagged",
    traffic: 3.5115722266560256,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "oranges fresh bagged",
    traffic: 1.2232415902140672,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "pro plan lamb and rice dog food",
    traffic: 3.910614525139665,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "pro plan lamb and rice dog food",
    traffic: 1.3622463163747567,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "puppy bag",
    traffic: 0.4788507581803671,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "puppy bag",
    traffic: 0.16680567139282734,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "puppy treats",
    traffic: 22.929577464788732,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "puppy treats",
    traffic: 11.314984709480122,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina cat food wet",
    traffic: 0.013854253255749516,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina cat food wet",
    traffic: 0.012068549360366885,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina chicken and rice",
    traffic: 0.7182761372705506,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina chicken and rice",
    traffic: 0.25020850708924103,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina fancy feast",
    traffic: 0.41562759767248547,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina fancy feast",
    traffic: 0.3620564808110065,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina healthy weight dog food",
    traffic: 1.1173184357541899,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina healthy weight dog food",
    traffic: 0.3892132332499305,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina one sensitive skin and stomach",
    traffic: 1.9154030327214684,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina one sensitive skin and stomach",
    traffic: 0.6672226855713094,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina one weight control dog food",
    traffic: 1.1173184357541899,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina one weight control dog food",
    traffic: 0.3892132332499305,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina pro plan 18 lb bag",
    traffic: 0.5586592178770949,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina pro plan 18 lb bag",
    traffic: 0.19460661662496526,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina pro plan dog food lamb and rice adult",
    traffic: 10.853950518754989,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina pro plan dog food lamb and rice adult",
    traffic: 3.780928551570754,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina pro plan gastroenteric dog food",
    traffic: 0.4788507581803671,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina pro plan gastroenteric dog food",
    traffic: 0.16680567139282734,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina pro plan high protein shredded blend chicken",
    traffic: 7.741420590582601,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina pro plan high protein shredded blend chicken",
    traffic: 2.696691687517376,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina pro plan lamb and rice dog food adult",
    traffic: 14.046288906624103,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina pro plan lamb and rice dog food adult",
    traffic: 4.892966360856269,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina pro plan probiotics for dogs",
    traffic: 0.23942537909018355,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina pro plan probiotics for dogs",
    traffic: 0.08340283569641367,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina pro plan shredded",
    traffic: 0.1596169193934557,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina pro plan shredded",
    traffic: 0.055601890464275786,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina sensitive skin and stomach",
    traffic: 0.23942537909018355,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina sensitive skin and stomach",
    traffic: 0.08340283569641367,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina weight management dog food",
    traffic: 3.0501089324618738,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "purina weight management dog food",
    traffic: 0.3892132332499305,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "rachael ray cat food",
    traffic: 69.34865900383141,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "rachael ray cat food",
    traffic: 2.1844074342264057,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "ribs",
    traffic: 0.3380281690140845,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "ribs",
    traffic: 0.16680567139282734,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "scallop",
    traffic: 0.49566294919454773,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "scallop",
    traffic: 0.04827419744146754,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "science diet small bites",
    traffic: 0.7980845969672785,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "science diet small bites",
    traffic: 0.27800945232137897,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "sensitive skin and stomach dog food",
    traffic: 1.9154030327214684,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "sensitive skin and stomach dog food",
    traffic: 0.6672226855713094,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "sorghum seeds",
    traffic: 0.39904229848363926,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "sorghum seeds",
    traffic: 0.13900472616068948,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "squeeze cat treat",
    traffic: 7.930607187112764,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "squeeze cat treat",
    traffic: 0.7723871590634807,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "squeeze up cat treats",
    traffic: 6.195786864931847,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "squeeze up cat treats",
    traffic: 0.6034274680183441,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "squeeze ups for cats",
    traffic: 40.89219330855018,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "squeeze ups for cats",
    traffic: 3.982621288921072,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "urinary cat food dry",
    traffic: 4.980842911877394,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "urinary cat food dry",
    traffic: 0.1568911416847695,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "vegan treats",
    traffic: 0.11267605633802817,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "vegan treats",
    traffic: 0.055601890464275786,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "vet iq",
    traffic: 0.9014084507042254,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "vet iq",
    traffic: 0.4448151237142063,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "weight control dog food",
    traffic: 0.3192338387869114,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "weight control dog food",
    traffic: 0.11120378092855157,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "weight management dog food",
    traffic: 1.5163607342378291,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "weight management dog food",
    traffic: 0.52821795941062,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "wellness core for cats",
    traffic: 0.0,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "wellness core for cats",
    traffic: 0.0,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "wellness kittles",
    traffic: 11.771995043370508,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "wellness kittles",
    traffic: 1.146512189234854,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "weruva dog food",
    traffic: 13.071895424836603,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "weruva dog food",
    traffic: 1.6680567139282736,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "yeti dog chew",
    traffic: 0.22535211267605634,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "yeti dog chew",
    traffic: 0.11120378092855157,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "ziwi",
    traffic: 0.4507042253521127,
    traffic_change: 0,
    traffic_growth: 0,
  },
  {
    keyword: "ziwi",
    traffic: 0.22240756185710314,
    traffic_change: 0,
    traffic_growth: 0,
  },
];

export const keywordTrafficData = [
  {
    period_week: "P6W4",
    "Share of Shelf": 20,
    Traffic: 200000,
  },
  {
    period_week: "P7W1",
    "Share of Shelf": 43,
    Traffic: 270000,
  },
  {
    period_week: "P7W2",
    "Share of Shelf": 37,
    Traffic: 260000,
  },
  {
    period_week: "P7W3",
    "Share of Shelf": 37,
    Traffic: 305000,
  },
  {
    period_week: "P7W4",
    "Share of Shelf": 37,
    Traffic: 340000,
  },
  {
    period_week: "P8W1",
    "Share of Shelf": 43,
    Traffic: 310000,
  },
  {
    period_week: "P8W2",
    "Share of Shelf": 37,
    Traffic: 308000,
  },
  {
    period_week: "P8W3",
    "Share of Shelf": 43,
    Traffic: 300000,
  },
  {
    period_week: "P8W4",
    "Share of Shelf": 37,
    Traffic: 300000,
  },
  {
    period_week: "P9W01",
    "Share of Shelf": 37,
    Traffic: 240000,
  },
  {
    period_week: "P9W2",
    "Share of Shelf": 37,
    Traffic: 220000,
  },
  {
    period_week: "P9W3",
    "Share of Shelf": 43,
    Traffic: 320000,
  },
  {
    period_week: "P9W4",
    "Share of Shelf": 20,
    Traffic: 275000,
  },
];

export const ShareCompititionkpiData = [
  {
    title: "Basic KPIs",
    value: "Basic KPIs",
    key: "Basic KPIs",
    checkable: false,
    children: [
      {
        title: "$ share",
        value: "$ share",
        key: "$ share",
      },
      {
        title: "$ sales",
        value: "$ sales",
        key: "$ sales",
      },
      {
        title: "Unit Sales",
        value: "Unit Sales",
        key: "Unit Sales",
      },
      {
        title: "Share of Unit Sales",
        value: "Share of Unit Sales",
        key: "Share of Unit Sales",
      },
    ],
  },
  {
    title: "Be Visible",
    value: "Be Visible",
    key: "Be Visible",
    checkable: false,
    children: [
      {
        title: "Traffic",
        value: "Traffic",
        key: "Traffic",
      },
      {
        title: "Share of Shelf",
        value: "Share of Shelf",
        key: "Share of Shelf",
      },
      {
        title: "Traffic Share",
        value: "Traffic Share",
        key: "Traffic Share",
      },
    ],
  },
];

export const defaultCompetitionKpi = [
  "Basic KPIs:$ Share",
  "Basic KPIs:$ Sales",
  "Basic KPIs:Unit Sales",
  "Basic KPIs:Share of Unit Sales",
  "Be Visible:Traffic",
  "Be Visible:Share of Shelf",
  "Be Visible:Traffic Share",
];

export const competitionKpiData = [
  {
    title: "Basic KPIs",
    value: "Basic KPIs",
    checkable: false,
    children: [
      {
        title: "$ Share",
        value: "Basic KPIs:$ Share",
        key: "dollar_share",
        renderFunction: columns.renderPercentageFunction,
      },
      {
        title: "$ Sales",
        value: "Basic KPIs:$ Sales",
        key: "dollar_sales",
        renderFunction: columns.renderDollarFunction,
      },
      {
        title: "Unit Sales",
        value: "Basic KPIs:Unit Sales",
        key: "unit_sales",
        renderFunction: columns.renderFunction,
      },
      {
        title: "Share of Unit Sales",
        value: "Basic KPIs:Share of Unit Sales",
        key: "share_unit_sales",
        renderFunction: columns.renderFunction,
      },
    ],
  },
  {
    title: "Be Visible",
    value: "Be Visible",
    checkable: false,
    children: [
      {
        title: "Traffic",
        value: "Be Visible:Traffic",
        key: "traffic",
        renderFunction: columns.renderPercentageFunction,
      },
      {
        title: "Share of Shelf",
        value: "Be Visible:Share of Shelf",
        key: "share_shelf",
        renderFunction: columns.renderFunction,
      },
      {
        title: "Traffic Share",
        value: "Be Visible:Traffic Share",
        key: "traffic_share",
        renderFunction: columns.renderFunction,
      },
    ],
  },
];

export const competitionBenchmarkFormat = [
  { index: 0, table_key: "Internal", table_hierarchy_data: [] },
  { index: 1, table_key: "Category Benchmark", table_hierarchy_data: [] },
  { index: 2, table_key: "Competition Benchmark", table_hierarchy_data: [] },
];
