import React from "react";
import {
  CaretUpFilled,
  CaretDownFilled,
  PlusSquareOutlined,
  MinusSquareOutlined,
} from "@ant-design/icons";
import { formatNumberExcel, numFmt } from "../../utilities/exports/excel";
import {
  formatNumber,
  getOrdinalIndicator,
  hasValue,
  valueTypes,
} from "../../utilities/helper";
import { Tooltip } from "antd";

// render for UI
const renderFunction = (val, rec) => {
  let isZero = true;
  let dec = 1;

  if (val) {
    if (
      formatNumber(val.abs, 1, true) === valueTypes.NA &&
      formatNumber(val.abs, 2, true) !== valueTypes.NA
    ) {
      isZero = false;
      dec = 2;
    }
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs, dec)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance, dec)}
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance, dec)}
          </div>
        )}
      </div>
    );
};

const renderNumberPercentFunction = (val, rec) => {
  let isZero = true;
  let dec = 1;

  if (val) {
    if (
      formatNumber(val.abs, 1, true) === valueTypes.NA &&
      formatNumber(val.abs, 2, true) !== valueTypes.NA
    ) {
      isZero = false;
      dec = 2;
    }
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs, dec)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance, dec)}%
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance, dec)}%
          </div>
        )}
      </div>
    );
};

const renderPointsFunction = (val, rec) => {
  let isZero = true;
  let dec = 1;

  if (val) {
    if (
      formatNumber(val.abs, 1, true) === valueTypes.NA &&
      formatNumber(val.abs, 2, true) !== valueTypes.NA
    ) {
      isZero = false;
      dec = 2;
    }
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs, dec)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance, dec)}pts
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance, dec)}pts
          </div>
        )}
      </div>
    );
};

const renderDollarFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">${formatNumber(val.abs)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}
          </div>
        )}
      </div>
    );
};

const renderPercentageFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs)}%</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}
          </div>
        )}
      </div>
    );
};

const renderPercentagePointsFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs)}%</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}pts
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}pts
          </div>
        )}
      </div>
    );
};

const renderOrdinalFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">
          {formatNumber(val.abs)}
          {getOrdinalIndicator(val.abs)}
        </div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}
          </div>
        )}
      </div>
    );
};

const renderAbsVarPercentageFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs)}%</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}%
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}%
          </div>
        )}
      </div>
    );
};
const renderAbsPercentageFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}%
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}%
          </div>
        )}
      </div>
    );
};

const renderTextFunction = (val, rec) => ({
  value: val,
  numFmt: numFmt.text,
});

const renderTextAbsCommaFunction = (val, rec) => ({
  value: formatNumberExcel(val.abs),
  numFmt: numFmt.comma,
});

const renderTextAbsPercentageFunction = (val, rec) => ({
  value: formatNumberExcel(val && val.abs, true),
  numFmt: numFmt.percentage,
});

const renderTextVariancePercentageFunction = (val, rec) => ({
  value: formatNumberExcel(val && val.variance, true),
  numFmt: numFmt.percentage,
});

const renderTextVarianceNumberFunction = (val, rec) => ({
  value: formatNumberExcel(val && val.variance),
  numFmt: numFmt.number,
});

const stRetailerCols = [
  {
    title: "Search Terms", // 0
    children: [
      {
        title: "",
        dataIndex: "search_term",
        key: "search_term",
        width: 120,
        // fixed: 'left',
      },
    ],
  },
  {
    title: "Traffic", // 1
  },
  {
    title: "Traffic %", // 2
  },
];

const stRetailerColsExport = [
  {
    title: "Search Terms",
    dataIndex: "search_term",
    key: "search_term",
  },
];

export default {
  stRetailerCols,
  stRetailerColsExport,
  renderFunction,
  renderPercentageFunction,
  renderPointsFunction,
  renderPercentagePointsFunction,
  renderNumberPercentFunction,
  renderTextFunction,
  renderTextAbsCommaFunction,
  renderTextAbsPercentageFunction,
  renderTextVariancePercentageFunction,
  renderTextVarianceNumberFunction,
};
