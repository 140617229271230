import { Col, Row } from "antd";
import React from "react";
import { withRouter } from "react-router-dom";
import { valueTypes } from "../../utilities/helper";
import "./trackboardCard.scss";

/**
 * Trackboard Card 2x2
 * The reusable component to show the KPIs aggregated information on the trackboard.
 */
function TrackboardCard2x2(props) {
  const { heading, data, kpi1 } = props;

  return (
    <div className="container">
      <div className="kpi1">
        <div>{heading}</div>
        {data && <div>{data} {data === valueTypes.NA ? "" : "%"}</div>}
      </div>
      {kpi1 && <Row className="kpi2">
        <Col span={12}>
          <span className={Number(kpi1) <= 0 ? "negative" : "positive"}>
            {kpi1} {kpi1 === valueTypes.NA ? "" : "bps"}
          </span>
        </Col>
        <Col span={12}>vs YA</Col>
      </Row>}
    </div>
  );
}

export default withRouter(TrackboardCard2x2);
