import React from 'react';
import RouteConfig from './routing/routes';

import './assets/styles/app.less';
import './assets/styles/style.scss';
import appInsights from './utilities/appInsights';

function App() {
  return (
    <div style={{ marginTop: 66 }}>
      <RouteConfig />
    </div>
  );
}

export default appInsights(App);
