import { useFormikContext } from "formik";
import React from "react";
import { Form, Input } from "antd";

import ErrorMessage from "./ErrorMessage";

function FormInput({ name, label, ...props }) {
  const { errors, setFieldValue, setFieldTouched, touched, values } =
    useFormikContext();

  return (
    <Form.Item label={label}>
      <Input
        name={name}
        // onBlur={setFieldTouched(name)}
        onChange={(e) => setFieldValue(name, e.target.value)}
        value={values[name]}
        {...props}
      />
      <ErrorMessage error={errors[name]} visible={touched[name]} />
    </Form.Item>
  );
}

export default FormInput;
