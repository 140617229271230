import React, { useState } from "react";
import { useFormikContext } from "formik";
import { Form, Select, Divider, Input, Typography, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import ErrorMessage from "./ErrorMessage";

const { Option } = Select;

function FormSelect({ name, options, label, dropdownRender, onAdd, ...props }) {
  const { errors, setFieldValue, setFieldTouched, touched, values } =
    useFormikContext();

  const [item, setItem] = useState("");

  return (
    <Form.Item label={label}>
      <Select
        size="large"
        // onBlur={() => setFieldTouched(name)}
        onChange={(value) => {
          setFieldValue(name, value);
        }}
        dropdownRender={(menu) => (
          <>
            {menu}
            {dropdownRender && (
              <>
                <Divider style={{ margin: "8px 0" }} />
                <Space align="center" style={{ padding: "0 8px 4px" }}>
                  <Input
                    placeholder={`Please enter ${label}`}
                    value={item}
                    onChange={(e) => setItem(e.target.value)}
                  />
                  <Typography.Link
                    onClick={() => {
                      onAdd(item);
                      setItem("");
                    }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <PlusOutlined /> Add {label}
                  </Typography.Link>
                </Space>
              </>
            )}
          </>
        )}
        value={values[name]}
        {...props}
      >
        <Option value="">Select</Option>
        {options?.map((option, index) => (
          <Option key={index} value={option}>
            {option}
          </Option>
        ))}
      </Select>
      <ErrorMessage error={errors[name]} visible={touched[name]} />
    </Form.Item>
  );
}

export default FormSelect;
