import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    CaretUpFilled,
    CaretDownFilled,
    MoreOutlined,
} from "@ant-design/icons";
import { DualAxes, Line, Column } from "@ant-design/plots";
import { isEqual, max, maxBy, minBy, uniq } from "lodash";
import { Dropdown, Menu, Tooltip } from "antd";
import {
    addSymbol,
    formatNumber,
    hasValue,
    valueTypes,
    itemsListed,
} from "../../utilities/helper";
import { trackboardKpiDataForMars } from "../../utilities/constants";

export function ColumnBulletChart({
    kpiOption,
    title,
    kpi,
    data,
    xField,
    yField,
    yFieldNumFmt,
    viewBy,
    yBarUnit,
    yBulletUnit,
    value,
    onChange,
    stored,
    setStored,
    storageKey,
}) {
    const dispatch = useDispatch();
    function getMin() {
        if (data && yField) {
            if (Math.min(...data.map((val) => val[yField[1]])) > 0) return 0;
            else return Math.min(...data.map((val) => val[yField[1]]));
        } else {
            return 0;
        }
    }

    function getMax(index) {
        if (data && yField) {
            const max = Math.max(...data.map((val) => val[yField[index]]));
            return max + (max * 10) / 100;
        } else {
            return 0;
        }
    }

    const [chartHeight, setChartHeight] = useState(400);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (data && yField) {
            const max = maxBy(data, yField[1]);

            // Calculating the bullet (pink line) vertical width dynamically.
            setChartData(
                data.map((item) => {
                    let bulletRange = 0;
                    if (item[yField[1]])
                        bulletRange = item[yField[1]] + (max[yField[1]] * 1.5) / 100;

                    return { ...item, [yField[1]]: [item[yField[1]], bulletRange] };
                })
            );
        } else { setChartData([]) };

    }, [data, yField]);

    useEffect(() => {
        if (data?.length === 0) return;
        if (!hasValue(data[0][xField])) return;

        const checks = [
            [0, 4, 425],
            [4, 8, 450],
            [8, 16, 475],
            [16, 20, 490],
            [20, 24, 525],
            [24, 28, 550],
            [28, 32, 575],
            [32, Infinity, 600],
        ];

        const maxLabelLength = max(data.map((item) => item[xField].length));

        if (maxLabelLength)
            checks.some((check) => {
                if (maxLabelLength > check[0] && maxLabelLength <= check[1]) {
                    setChartHeight(check[2]);
                    return true;
                }
            });
    }, [data]);

    useEffect(() => {
        if (
            (storageKey && value.length &&
                (!stored || !JSON.parse(sessionStorage.getItem(storageKey)))) || (JSON.parse(sessionStorage.getItem(storageKey)) && !isEqual(value, [...JSON.parse(sessionStorage.getItem(storageKey))]))
        ) {
            sessionStorage.setItem(storageKey, JSON.stringify(value));
            setStored(true);
        }
    }, [value]);

    const onEvent = (e, event) => {
        if (event.type === "plot:click") {
            if (event.data === null) {
                const storedValue = JSON.parse(sessionStorage.getItem(storageKey));
                const newValue = [data[0][viewBy]];

                if (!isEqual(storedValue, newValue)) {
                    sessionStorage.setItem(storageKey, JSON.stringify(newValue));
                    onChange(newValue);
                }
            }
        }

        if (event.type === "element:click") {
            const eventValue = event.data.data[viewBy];
            const storedValue = JSON.parse(sessionStorage.getItem(storageKey));
            let newValue = [...storedValue];
            if (!newValue) newValue = [...value];

            const index = newValue.findIndex((item) => item === eventValue);

            if (window.event.ctrlKey)
                if (index === -1) newValue.push(eventValue);
                else newValue.splice(index, 1);
            else newValue = [eventValue];



            if (newValue.length > 0 && !isEqual(storedValue, newValue)) {
                sessionStorage.setItem(storageKey, JSON.stringify(newValue));
                onChange(newValue);
            }
        }

    };

    const config = {
        data: [chartData, chartData],
        xField,
        yField,
        height: "100%",
        appendPadding: 20,
        animation: false,
        tooltip: {
            marker: {},
            customItems: (originalItems) => {
                let field0Value =
                    ["tdp", "dollar", "traffic_similarweb", "traffic_stackline", "itmes_in_stock"].includes(yField[0])
                        ? `${formatNumber(originalItems[0].data[yField[0]], 1)}`
                        : `${formatNumber(originalItems[0].data[yField[0]], 1)}%`

                let field1Value =
                    ["tdp", "dollar", "traffic_similarweb", "traffic_stackline", "itmes_in_stock"].includes(yField[1])
                        ? `${formatNumber(originalItems[0].data[yField[1]][0], 1)}`
                        : `${formatNumber(originalItems[0].data[yField[1]][0], 1)}%`
                return [
                    {
                        color: "#8080cf",
                        name: kpiOption[yField[0]],
                        value: field0Value,
                        marker: true,
                    },
                    {
                        color: "#e559a4",
                        name: kpiOption[yField[1]],
                        value:
                            yField[1] === "Average Placement"
                                ? originalItems[0].data[yField[1]][0]
                                : field1Value,
                        marker: true,
                    },
                ];
            },
        },
        xAxis: {
            title: {
                text: xField[0] === "customer_channel" ? "Channel" : xField[0] === "customer_retailer_details" ? "Retailer" : "Category",
                style: {
                    fontWeight: "700",
                },
            },
            label: {
                rotate: -1,
                offsetX: -30,
                offsetY: 10,
            },
        },
        yAxis: {
            [yField[0]]: {
                title: {
                    text: kpiOption[yField[0]],
                },
                label: {
                    style: {
                        fill: "#000000",
                        opacity: 0.7,
                    },
                    formatter: (val) => ["tdp", "dollar", "traffic_similarweb", "traffic_stackline", "itmes_in_stock"].includes(yField[0])
                        ? `${formatNumber(val, 0)}`
                        : `${formatNumber(val, 0)}%`,
                },
                max: getMax(0),
            },
            [yField[1]]: {
                title: { text: kpiOption[yField[1]] },
                label: {
                    style: {
                        fill: "#000000",
                        opacity: 0.7,
                    },
                    formatter: (val) =>
                        ["tdp", "dollar", "traffic_similarweb", "traffic_stackline", "itmes_in_stock"].includes(yField[1])
                            ? `${formatNumber(val, 0)}`
                            : `${formatNumber(val, 0)}%`,
                },
            },
        },
        geometryOptions: [
            {
                geometry: "column",
                isRange: true,
                columnWidthRatio: 0.5,
                color: (type) => {
                    return value?.includes(type[viewBy]) ? "#0000a0" : "#8080cf";
                },
                columnStyle: () => ({ cursor: "pointer" }),
            },
            {
                geometry: "column",
                color: "#e559a4",
                columnWidthRatio: 0.7,
            },
        ],
        legend: {
            position: "top-left",
            itemName: {
                formatter: (data) => {
                    return kpiOption[data]
                        ? kpiOption[data]
                        : data;
                },
            },
        },
    };
    return storageKey ? (
        <div style={{ height: chartHeight }}>
            <DualAxes {...config} onEvent={onEvent} />
        </div>
    ) : (
        <div style={{ height: chartHeight }}>
            <DualAxes {...config} />
        </div>
    );
}

export function ColumnLineChart({
    kpiOption,
    title,
    kpi,
    data,
    xField,
    yField,
    yFieldNumFmt,
    yBarUnit,
    yBulletUnit,
  }) {
    const dispatch = useDispatch();
    const [chartHeight, setChartHeight] = useState(400);
    const [chartData, setChartData] = useState([]);
  
    useEffect(() => {
      if (data && yField) {
        const filteredData = data.filter((item) => {
          const isTdpZero = yField.includes("tdp") && item["tdp"] === 0;
          const isTdpShareZero =
            yField.includes("tdp_share") && item["tdp_share"] === 0;
          return !isTdpZero && !isTdpShareZero;
        });
  
        setChartData(filteredData);
      }
    }, [data, yField]);
  
    function getMin() {
      if (chartData && yField) {
        const nonZeroData = chartData.filter((item) => item[yField[1]] !== 0);
        if (nonZeroData.length === 0) return 0;
        return Math.min(...nonZeroData.map((val) => val[yField[1]]));
      } else {
        return 0;
      }
    }
  
    function getMax(index) {
      if (chartData && yField) {
        const nonZeroData = chartData.filter((item) => item[yField[index]] !== 0);
        if (nonZeroData.length === 0) return 0;
        const max = Math.max(...nonZeroData.map((val) => val[yField[index]]));
        return max + (max * 10) / 100;
      } else {
        return 0;
      }
    }
  
    const config = {
      data: [chartData, chartData],
      xField,
      yField,
      appendPadding: 20,
      animation: false,
      tooltip: {
        marker: {},
        customItems: (originalItems) => {
          let field0Value =  
          ["tdp", "dollar", "traffic_similarweb", "traffic_stackline", "itmes_in_stock"].includes(yField[0])
          ? `${formatNumber(originalItems[0].data[yField[0]], 1)}`
          : `${formatNumber(originalItems[0].data[yField[0]], 1)}%`
  
          let field1Value = 
          ["tdp", "dollar", "traffic_similarweb", "traffic_stackline", "itmes_in_stock"].includes(yField[1])
          ? `${formatNumber(originalItems[0].data[yField[1]], 1)}`
          : `${formatNumber(originalItems[0].data[yField[1]], 1)}%`
  
          return [
            {
              color: "#8080cf",
              name: kpiOption[yField[0]],
              value: field0Value,
              marker: true,
            },
            {
              color: "#e559a4",
              name: kpiOption[yField[1]],
              value:
                yField[1] === "Average Placement"
                  ? originalItems[0].data[yField[1]][0]
                  : field1Value,
              marker: true,
            },
          ];
        },
      },
      xAxis: {
        title: {
          text: "Period",
          style: {
            fontWeight: "700",
          },
        },
        label: {
          rotate: -1,
          offsetX: -30,
          offsetY: 10,
        },
      },
      yAxis: {
        [yField[0]]: {
          title: {
            text: kpiOption[yField[0]],
          },
          label: {
            style: {
              fill: "#000000",
              opacity: 0.7,
            },
            formatter: (val) => ["tdp", "dollar", "traffic_similarweb", "traffic_stackline", "itmes_in_stock"].includes(yField[0])
            ? `${formatNumber(val, 0)}`
            : `${formatNumber(val, 0)}%`,
          },
          max: getMax(0),
        },
        [yField[1]]: {
          title: { text: kpiOption[yField[1]] },
          label: {
            style: {
              fill: "#000000",
              opacity: 0.7,
            },
            formatter: (val) =>
            ["tdp", "dollar", "traffic_similarweb", "traffic_stackline", "itmes_in_stock"].includes(yField[1])
                ? `${formatNumber(val, 0)}`
                : `${formatNumber(val, 0)}%`,
          },
          min: 0,
          max: getMax(1),
        },
      },
      geometryOptions: [
        {
          geometry: "column",
          color: "#8080cf",
        },
        {
          geometry: "line",
          lineStyle: {
            lineWidth: 2,
          },
          point: {},
          color: "#e559a4",
        },
      ],
      legend: {
        position: "top-left",
        itemName: {
          formatter: (data) => {
            return kpiOption[data]
              ? kpiOption[data]
              : data;
          },
        },
      },
    };
  
    return <DualAxes {...config} />;
  }

export function MultiLineChart({
    compareVia,
    kpiOption,
    title,
    kpi,
    data,
    xField,
    yField,
    yFieldNumFmt,
    seriesField,
    yBulletUnit,
    legendSize = 12,
    yBarUnit,
}) {
    // Excel Export
    function getMax(index) {
        if (data && yField) {
            const max = Math.max(...data.map((val) => val[yField]));
            return max + (max * 10) / 100;
        } else {
            return 0;
        }
    }
    console.log("chartDataa", data)
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (data && yField) {
            setChartData(data);
        }
    }, [data, yField]);

    const config = {
        data,
        xField,
        yField,
        seriesField,
        animation: false,
        point: {
            shape: "circle",
        },
        tooltip: {
            marker: {},
            customItems: (originalItems) => {

                let kpiData = originalItems.map((item) => {
                    let field0Value = ["tdp", "dollar", "traffic_similarweb", "traffic_stackline", "itmes_in_stock"].includes(yField)
                        ? `${formatNumber(item.data[yField], 1)}`
                        : `${formatNumber(item.data[yField], 1)}%`
                    return {
                        color: item.color,
                        name: item.name,
                        value: field0Value,
                        marker: true,
                    };
                });

                return kpiData;
            },
        },
        xAxis: {
            title: {
                text: "Period",
                style: {
                    fontWeight: "700",
                },
            },
            label: {
                rotate: -1,
                offsetX: -30,
                offsetY: 10,
            },
        },
        yAxis: {
            title: {
                text: compareVia,
            },
            label: {
                style: {
                    fill: "#000000",
                    opacity: 0.7,
                },
                formatter: (val) => {
                    return ["tdp", "dollar", "traffic_similarweb", "traffic_stackline", "itmes_in_stock"].includes(yField)
                        ? `${formatNumber(val, 0)}`
                        : `${formatNumber(val, 0)}%`;
                },
            },
            max: getMax(0),
        },
        legend: {
            maxRow: 2,
            itemName: {
                style: {
                    fontSize: legendSize,
                },
            },
        },
    };

    return <Line {...config} />;
}
