import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    appliedFilters: {
        product_pet: [],
        product_technology: [],
        product_package: [],
        customer_channel: [],
        customer_retailer_details: [],
        default_brand: ["NUTRO CORE"],
        innovation_type: [],
        innovation: [],
        time_year: [],
        innovation_years: [],
        time_period: [],
        top: [1],
        kpi: ["TDP"],
    },
}

const reportSlicer = createSlice({
    name: 'report',
    initialState,
    reducers: {
        setAppliedFilters: (state, action) => ({
            ...state,
            appliedFilters: action.payload
        })
    },
})

export const { setAppliedFilters } = reportSlicer.actions

export default reportSlicer.reducer