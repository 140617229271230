import React, { useEffect, useState } from 'react';
import { Pie, Progress, Bullet } from '@ant-design/plots';
import { Checkbox, Dropdown, Menu, Switch } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import './donut.scss';
import { colors, formatNumber, valueTypes } from '../../utilities/helper';
import { setExportData } from '../../slicers/exportData.slicer';
import { formatNumberExcel, numFmt } from '../../utilities/exports/excel';

const menu = (
  <Menu>
    <Menu.Item key="0">Export Data</Menu.Item>
  </Menu>
);



export default function DonutChart(props) {
  console.log(props, 'Props')
  const dispatch = useDispatch();
  const [periodToggle, setPeriodToggle] = useState(true);
  const data = props.getData((periodToggle ? Math.abs(props.kpiData.percent_growth) : Math.abs(props.kpiData.percent_growth_ytd)), (periodToggle ? Math.abs(props.kpiData.target) : Math.abs(props.kpiData.target_ytd)), (periodToggle ? 'Period' : 'YTD')) ?? []
  const bulletData = props.getBulletData((periodToggle ? props.kpiData.percent_growth : props.kpiData.percent_growth_ytd), (periodToggle ? (props.kpiData.target) : (props.kpiData.target_ytd)), (periodToggle ? 'Period' : 'YTD')) ?? []

  // const [config, setConfig] = useState({
  //   appendPadding: 10,
  //   data,
  //   angleField: 'value',
  //   colorField: 'type',
  //   radius: 1,
  //   innerRadius: 0.6,
  //   startAngle: 3.14,
  //   endAngle: 6.28,
  //   pieStyle: ({ type }) => {
  //     if (type === 'Target') {
  //       return {
  //         stroke: 'black'
  //       }
  //     }
  //   },
  //   label: {
  //     type: 'inner',
  //     offset: '-50%',
  //     content: ({ type, value }) => {
  //       if (type === 'YTD') return `${formatNumber(props.kpiData.percent_growth_ytd)}%`
  //       else if (type === 'Period') return `${formatNumber(props.kpiData.percent_growth)}%`
  //       // else if(type==='Target')return `${formatNumber(periodToggle?props.kpiData.target:props.kpiData.target_ytd)}%`
  //       else return null
  //     },
  //     style: {
  //       textAlign: 'center',
  //       fontSize: 15,
  //       fontWeight: 'bold',
  //       fill: '#000000',
  //     },
  //   },
  //   interactions: [
  //     {
  //       type: 'element-selected',
  //     },
  //     {
  //       type: 'element-active',
  //     },
  //   ],
  //   legend: {
  //     layout: 'horizontal',
  //     position: 'top',
  //     items: data.filter((val) => val.type === 'Target' || val.type === 'YTD' || val.type === 'Period').map((val) => ({
  //       name: val.type === 'Target' ? 'Target' : (periodToggle ? 'Period' : 'YTD'),
  //       value: val.value,
  //       marker: {
  //         symbol: 'square',
  //         style: {
  //           fill: (val.type === 'YTD' || val.type === 'Period' ? ((periodToggle ? (props.kpiData.percent_growth >= 0 ? '#80EBDC' : 'red') : (props.kpiData.percent_growth_ytd >= 0 ? '#80EBDC' : 'red'))) : '#022A3E')
  //         }
  //       }
  //     }))
  //   },
  //   tooltip: {
  //     showContent: (params) => {
  //       console.log(params)
  //       return (params[0].data.type.startsWith('Target') || params[0].data.type.startsWith('YTD') || params[0].data.type.startsWith('Period'))
  //     },
  //     formatter: (params) => {
  //       if (params.type.startsWith('Target')) {
  //         return { name: 'Target', value: `${formatNumber(periodToggle ? props.kpiData.target : props.kpiData.target_ytd)}%` }
  //       }
  //       else if (params.type.startsWith('YTD')) {
  //         return { name: props.kpiData.percent_growth >= 0 ? props.fieldTitle.split(' ')[1] : 'Decline', value: `${formatNumber(props.kpiData.percent_growth_ytd)}%` }
  //       }
  //       else if (params.type.startsWith('Period')) {
  //         return { name: props.kpiData.percent_growth >= 0 ? props.fieldTitle.split(' ')[1] : 'Decline', value: `${formatNumber(props.kpiData.percent_growth)}%` }
  //       }

  //       return null
  //     }
  //   },
  //   statistic: {
  //     title: false,
  //     content: false
  //   },
  //   animation: false,
  //   color: ({ type }) => {
  //     if (type.startsWith('YTD') || type.startsWith('Period')) {
  //       return (periodToggle ? (props.kpiData.percent_growth >= 0 ? '#80EBDC' : 'red') : (props.kpiData.percent_growth_ytd >= 0 ? '#80EBDC' : 'red'))
  //     }
  //     else if (type.startsWith('Target')) {
  //       return '#022A3E'
  //     }
  //     else return '#c4c4c4'
  //   }
  // });
  // console.log("data", data);
  const kpiValues = props.kpiType === "MAC%" ? [
    {
      title: "MAC%",
      value: formatNumber(props.kpiData.sum_value_4w, 1, true) === valueTypes.NA ? valueTypes.NA : `${formatNumber(props.kpiData.sum_value_4w)}%`,
      valueExport: props.kpiData.sum_value_4w
    },
    {
      title: "MAC% YA",
      value: formatNumber(props.kpiData.sum_value_4w_ya, 1, true) === valueTypes.NA ? valueTypes.NA : `${formatNumber(props.kpiData.sum_value_4w_ya)}%`,
      valueExport: props.kpiData.sum_value_4w_ya
    },
  ] : [
    {
      title: props.kpiType,
      value: formatNumber(props.kpiData.sum_value_4w, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(props.kpiData.sum_value_4w),
      valueExport: props.kpiData.sum_value_4w
    },
    {
      title: (props.kpiType + ' YA'),
      value: formatNumber(props.kpiData.sum_value_4w_ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(props.kpiData.sum_value_4w_ya),
      valueExport: props.kpiData.sum_value_4w_ya
    },
  ];

  if (props.kpiType === "GSV")
    kpiValues.push({
      title: (props.kpiType + ' YEE'),
      value: formatNumber(props.kpiData.fact_yee, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(props.kpiData.fact_yee),
      valueExport: props.kpiData.fact_yee
    });

  const ytdValues = props.kpiType === "MAC%" ? [
    {
      title: 'MAC% YTD',
      value: formatNumber(props.kpiData.sum_value_ytd, 1, true) === valueTypes.NA ? valueTypes.NA : `${formatNumber(props.kpiData.sum_value_ytd)}%`,
      valueExport: props.kpiData.sum_value_ytd,
    },
    {
      title: 'MAC% YTD YA',
      value: formatNumber(props.kpiData.sum_value_ytd_ya, 1, true) === valueTypes.NA ? valueTypes.NA : `${formatNumber(props.kpiData.sum_value_ytd_ya)}%`,
      valueExport: props.kpiData.sum_value_ytd_ya
    }
  ] : [
    {
      title: props.kpiType + ' YTD',
      value: formatNumber(props.kpiData.sum_value_ytd, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(props.kpiData.sum_value_ytd),
      valueExport: props.kpiData.sum_value_ytd,
    },
    {
      title: props.kpiType + ' YTD YA',
      value: formatNumber(props.kpiData.sum_value_ytd_ya, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(props.kpiData.sum_value_ytd_ya),
      valueExport: props.kpiData.sum_value_ytd_ya
    }
  ];

  if (props.kpiType === "GSV")
    ytdValues.push({
      title: (props.kpiType + ' YEE'),
      value: formatNumber(props.kpiData.fact_yee, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(props.kpiData.fact_yee),
      valueExport: props.kpiData.fact_yee
    });

  // const config = {
  //   appendPadding: 10,
  //   data,
  //   angleField: 'value',
  //   colorField: 'type',
  //   radius: 1,
  //   innerRadius: 0.6,
  //   startAngle: 3.14,
  //   endAngle: 6.28,
  //   pieStyle: ({ type }) => {
  //     if (type === 'Target') {
  //       return {
  //         stroke: 'black'
  //       }
  //     }
  //   },
  //   label: {
  //     type: 'inner',
  //     offset: '-50%',
  //     content: ({ type, value }) => {
  //       if (type === 'YTD') return `${formatNumber(props.kpiData.percent_growth_ytd)}%`
  //       else if (type === 'Period') return `${formatNumber(props.kpiData.percent_growth)}%`
  //       // else if(type==='Target')return `${formatNumber(periodToggle?props.kpiData.target:props.kpiData.target_ytd)}%`
  //       else return null
  //     },
  //     style: {
  //       textAlign: 'center',
  //       fontSize: 15,
  //       fontWeight: 'bold',
  //       fill: '#000000',
  //     },
  //   },
  //   interactions: [
  //     {
  //       type: 'element-selected',
  //     },
  //     {
  //       type: 'element-active',
  //     },
  //   ],
  //   legend: {
  //     layout: 'horizontal',
  //     position: 'top',
  //     items: data.filter((val) => val.type === 'Target' || val.type === 'YTD' || val.type === 'Period').map((val) => ({
  //       name: val.type === 'Target' ? 'Target' : (periodToggle ? 'Period' : 'YTD'),
  //       value: val.value,
  //       marker: {
  //         symbol: 'square',
  //         style: {
  //           fill: (val.type === 'YTD' || val.type === 'Period' ? ((periodToggle ? (props.kpiData.percent_growth >= 0 ? '#80EBDC' : 'red') : (props.kpiData.percent_growth_ytd >= 0 ? '#80EBDC' : 'red'))) : '#022A3E')
  //         }
  //       }
  //     }))
  //   },
  //   tooltip: {
  //     showContent: (params) => {
  //       console.log(params)
  //       return (params[0].data.type.startsWith('Target') || params[0].data.type.startsWith('YTD') || params[0].data.type.startsWith('Period'))
  //     },
  //     formatter: (params) => {
  //       if (params.type.startsWith('Target')) {
  //         return { name: 'Target', value: `${formatNumber(periodToggle ? props.kpiData.target : props.kpiData.target_ytd)}%` }
  //       }
  //       else if (params.type.startsWith('YTD')) {
  //         return { name: props.kpiData.percent_growth >= 0 ? props.fieldTitle.split(' ')[1] : 'Decline', value: `${formatNumber(props.kpiData.percent_growth_ytd)}%` }
  //       }
  //       else if (params.type.startsWith('Period')) {
  //         return { name: props.kpiData.percent_growth >= 0 ? props.fieldTitle.split(' ')[1] : 'Decline', value: `${formatNumber(props.kpiData.percent_growth)}%` }
  //       }

  //       return null
  //     }
  //   },
  //   statistic: {
  //     title: false,
  //     content: false
  //   },
  //   animation: false,
  //   color: ({ type }) => {
  //     if (type.startsWith('YTD') || type.startsWith('Period')) {
  //       return (periodToggle ? (props.kpiData.percent_growth >= 0 ? '#80EBDC' : 'red') : (props.kpiData.percent_growth_ytd >= 0 ? '#80EBDC' : 'red'))
  //     }
  //     else if (type.startsWith('Target')) {
  //       return '#022A3E'
  //     }
  //     else return '#c4c4c4'
  //   }
  // };

  // const [datan, setDatan] = useState([]);
  const getMeasureData = () =>
    periodToggle
      ? props.kpiData.percent_growth
      : props.kpiData.percent_growth_ytd;

  const getTargetData = () =>
    periodToggle
      ? props.kpiData.target
      : props.kpiData.target_ytd;

  const getMeasureColor = () =>
    getMeasureData() < 0
      ? colors.danger
      : getMeasureData() >= getTargetData()
        ? colors.success
        : colors.warning;


  const getMeasureName = () =>
    periodToggle
      ? props.kpiData.percent_growth >= 0
        ? "Growth %"
        : "Decline %"
      : props.kpiData.percent_growth_ytd >= 0
        ? "Growth %"
        : "Decline %";

  // const getMeasurePosition = () =>
  //   periodToggle
  //   ? props.kpiData.target >= 0
  //     ? "middle"
  //     : "top"
  //   : props.kpiData.target_ytd >= 0
  //     ? "middle"
  //     : "top";

  const getMeasureContent = () => {
    if (getMeasureData() === 0 && getTargetData() === 0) return "";
    return `${formatNumber(getMeasureData())}%`;
  }

  const getTargetContent = () => `${formatNumber(getTargetData())}%`;

  const getConfig = () => ({
    data: bulletData,
    height: 120,
    measureField: 'measures',
    rangeField: 'ranges',
    targetField: 'target',
    yAxis: {
      position: 'left',
      tickMethod: () => [0, 100]
    },
    color: {
      range: '#F2F2F2',
      measure: getMeasureColor(),
      target: '#0000A0',
    },
    xAxis: {
      line: null,
      label: null
    },
    size: {
      range: 68,
      measure: 68,
      target: 68,
    },
    animation: false,
    label: {
      measure: {
        // position: getMeasurePosition(),
        position: "bottom",
        content: getMeasureContent(),
        style: {
          fill: '#000',
          fontWeight: 'bold'
        },
      },
      target: {
        position: 'top',
        content: getTargetContent(),
        style: {
          fill: '#000',
          fontWeight: 'bold'
        },
      }
    },
    legend: {
      custom: true,
      position: 'top-right',
      items: [
        {
          value: getMeasureContent(),
          name: getMeasureName(),
          marker: {
            symbol: 'square',
            style: {
              fill: getMeasureColor(),
              r: 5,
            },
          },
        },
        {
          value: 'Target',
          name: 'Target',
          marker: {
            symbol: 'square',
            style: {
              fill: '#0000A0',
              r: 5,
            },
          },
        },
      ],
    },
    tooltip: {
      showTitle: false,
      formatter: (params) => {
        if (params.tKey) return { name: 'Target', value: getTargetContent() }
        if (params.mKey) return { name: getMeasureName(), value: `${formatNumber(getMeasureData())}%` };
        return null;
      }

    },
  })

  const [config, setConfig] = useState(getConfig());

  useEffect(() => {
    const { kpiType, kpiData, ytdVal, targetVal, fieldTitle } = props;
    dispatch(
      setExportData({
        kpi: {
          [kpiType.toLowerCase()]: {
            kpiType,
            periodToggle,
            data,
            kpiData,
            ytdVal,
            targetVal,
            fieldTitle: kpiData.percent_growth >= 0 ? fieldTitle : fieldTitle.split(' ')[0] + ' Decline',
            kpiValues,
            ytdValues
          },
        },
      })
    );
  }, [dispatch, props.kpiData, periodToggle]);

  useEffect(() => {

    if (props.loading < 1 && props.apply) {
      props.setApply(false)
      setConfig({ ...getConfig(), animation: true })
    }
    else {
      setConfig({ ...getConfig(), animation: false })
    }
  }, [props.loading, periodToggle])

  return <div className='donut-container'>
    <div className="data-head">
      {props.checkbox && <Checkbox onChange={props.onChange} className="d-check"></Checkbox>}
      <h4>{(periodToggle ? (props.kpiData.percent_growth >= 0 ? props.fieldTitle : props.fieldTitle.split(' ')[0] + ' Decline') : (props.kpiData.percent_growth_ytd >= 0 ? props.fieldTitle : props.fieldTitle.split(' ')[0] + ' Decline'))}</h4>
      {props.showExport && <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" className="d-more">
        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
          <MoreOutlined />
        </a>
      </Dropdown>}
    </div>
    {/* <div className="ytd_toggle">
      <div className={periodToggle ? "unselected" : "selected"}>YTD</div>
      <div><Switch checked={periodToggle} onChange={(e) => setPeriodToggle(e)} /></div>
      <div className={!periodToggle ? "unselected" : "selected"}>Period</div>
    </div> 
    <Pie height={150} {...config} /> */}
    <div className='bullet-chat-wrap'>
      {/* <Bullet {...config} /> */}
      <div className="ytd_toggle">
        <div className={periodToggle ? "unselected" : "selected"}>YTD</div>
        <div className='switch'><Switch size='small' checked={periodToggle} onChange={(e) => setPeriodToggle(e)} /></div>
        <div className={!periodToggle ? "unselected" : "selected"}>Period</div>
      </div>
    </div>
    <div className='kpi-container'>
      {(periodToggle ? kpiValues : ytdValues).map((val) => (<div className='kpi-box'>
        <div className='kpi-title'>{val.title}</div>
        <div className='kpi-value'>{val.value}</div>
      </div>))}
    </div>
  </div>;
};

