import { cloneDeep } from "lodash";
import React from "react";
import { formatNumber, hasValue, setDeclareAppliedFilters, valueTypes } from "../../utilities/helper";
export const rowKey = "customer_retailer_details";

/**
 * UIHelper
 * This component represents a KPI card that displays the title, target, actual and variance with a specified symbol.
 */
export const KPICard = ({ title, target, actual, variance, symbol }) => {
  return (
    <div className="item">
      <div>
        <h5>{title}</h5>
      </div>
      <div className="item-value-wrap">
        <div className="item-value">
          <h5>TARGET</h5>
          <p>
            {hasValue(target) &&
              (formatNumber(target, 1, true) === valueTypes.NA
                ? valueTypes.NA
                : `${symbol ? symbol : ""}${formatNumber(target, 1)}`)}
          </p>
        </div>
        <div className="item-value">
          <h5>ACTUAL</h5>
          <p>
            {hasValue(actual) &&
              (formatNumber(actual, 1, true) === valueTypes.NA
                ? valueTypes.NA
                : `${symbol ? symbol : ""}${formatNumber(actual, 1)}`)}{" "}
            <span>
              {hasValue(variance) &&
                (formatNumber(variance, 1, true) === valueTypes.NA
                  ? valueTypes.NA
                  : `${formatNumber(variance, 1)}%`)}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};



export const declareAppliedFilters = (inputFilter, filtersData) => {
  const { pets, techs,subTechs, packSize, innovationTypes, years, innovation, retailerList } = filtersData;
  let finalFilter = cloneDeep(inputFilter);

  finalFilter = setDeclareAppliedFilters(finalFilter, "product_pet", pets);
  if (finalFilter["product_pet"].length === 0) finalFilter["product_pet"] = ["ALL"];
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_technology", techs);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_sub_technology", subTechs);
  if (finalFilter.product_package && packSize) finalFilter = setDeclareAppliedFilters(finalFilter, "product_package", packSize);
  finalFilter = setDeclareAppliedFilters(finalFilter, "innovation_type", innovationTypes);
  finalFilter = setDeclareAppliedFilters(finalFilter, "time_year", years);
  finalFilter = setDeclareAppliedFilters(finalFilter, "innovation", innovation);
  finalFilter = setDeclareAppliedFilters(finalFilter, "customer_retailer_details", retailerList);

  return finalFilter;
}