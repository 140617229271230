import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep, isEqual } from "lodash";
import CustomAxios from "../utilities/services/api.service";
import { ENDPOINTS } from "../utilities/constants";
import {
  decodeTreeSelectValue,
  decodeTreeSelectKey,
  encodeTreeSelectValue,
  encodeTreeSelectKey,
} from "../utilities/helper";
import {
  competitionBenchmarkFormat,
  defaultCompetitionKpi,
  kpiDropdownBeSelected,
  kpiDropdownBeSelectedAvgRating,
  rowKeyMacro,
  rowKeyMicro,
  viewBy,
  viewByOpportunity,
  reivewRetailersRnR,
} from "../pages/be-available/baUIHelper";
import { itemsNotYetClassified, declareAppliedFilters } from "../pages/be-selected/beUIHelper";
import { getState } from "../store";


const initialState = {
  loading: 0,
  microLoading: false,
  consumerLoading: false,
  dropdownLoading: false,

  // Top filters data
  pets: [],
  techs: [],
  feedingPhilosophies: [],
  textures: [],
  retailers: [],
  timeAggregates: [],
  yearPeriods: [],
  benchmarkPeriods: [],


  // Performance filters data
  competitors: [],




  // kpis: [],

  filters: {
    view: ["ratings_reviews"],
    product_pet: [],
    product_technology: [],
    product_feed_philosophy: [],
    product_texture: [],
    customer_retailer_details: ["Amazon"],
    year_period: [],
    time_aggregate: [],
    benchmark: [],
    default_brand: ["NUTRO"],
    view_by: [Object.keys(viewBy)[0]],
    selected_competitor: [],
    be_selected_comparison_kpi: [kpiDropdownBeSelected[0]],
    be_selected_deepdive_kpi: [kpiDropdownBeSelected[0]],
    deep_dive_list: [],
    selected_retailer: [],
    opportunity_view_by: [],
    opportunity_selection: [],
    filter_by: [],
    sub_filter_by: [],
    review_retailer: [reivewRetailersRnR[0]],
  },
  appliedFilters: {
    view: ["ratings_reviews"],
    product_pet: ["ALL"],
    product_technology: ["ALL"],
    product_feed_philosophy: ["ALL"],
    product_texture: ["ALL"],
    customer_retailer_details: [],
    year_period: [],
    time_aggregate: [],
    benchmark: [],
    default_brand: ["NUTRO"],
    view_by: [Object.keys(viewBy)[0]],
    selected_competitor: [],
    be_selected_comparison_kpi: [kpiDropdownBeSelected[0]],
    be_selected_deepdive_kpi: [kpiDropdownBeSelected[0]],
    deep_dive_list: [],
    selected_retailer: [],
    opportunity_view_by: [],
    opportunity_selection: [],
    filter_by: [],
    sub_filter_by: [],
    review_retailer: [],
  },

  comparisonTrendData: [],
  cardData: [],
  scaterPlotDropdown: [],
  deepdiveSingleTrendData: [],
  deepdiveMultiTrendData: [],
  macroTableData: [],
  microTableData: [],
  scaterPlot: [],
  reviewData: {},
  retailerImage: [],

  // On mouse leave
  setSelectedCompetitor: [],
  opportunitySelection: []
};

// Redux Slice for BeSelected state
const beSelectedSlicer = createSlice({
  name: "beSelected",
  initialState,
  reducers: {
    // Reset the BeSelected state to its initial state
    resetBeSelected: () => initialState,
    // Set loading state
    setLoading: (state, action) => ({
      ...state,
      loading: state.loading + (action.payload ? 1 : -1),
    }),
    // Set micro loading state
    setMicroLoading: (state, action) => ({
      ...state,
      microLoading: action.payload,
    }),
    setConsumerLoading: (state, action) => ({
      ...state,
      consumerLoading: action.payload,
    }),
    // Set dropdown loading state
    setDropdownLoading: (state, action) => ({
      ...state,
      dropdownLoading: action.payload,
    }),
    // Set top filters
    setPets: (state, action) => ({ ...state, pets: action.payload }),
    setTechs: (state, action) => ({ ...state, techs: action.payload }),
    setFeedingPhilosophies: (state, action) => ({
      ...state,
      feedingPhilosophies: action.payload,
    }),
    setTextures: (state, action) => ({ ...state, textures: action.payload }),
    setRetailers: (state, action) => ({ ...state, retailers: action.payload }),
    setTimeAggregates: (state, action) => ({
      ...state,
      timeAggregates: action.payload,
    }),
    setYearPeriods: (state, action) => ({
      ...state,
      yearPeriods: action.payload,
    }),
    setBenchmarkPeriods: (state, action) => ({
      ...state,
      benchmarkPeriods: action.payload,
    }),
    setCompetitors: (state, action) => ({
      ...state,
      competitors: action.payload,
    }),

    // Report
    setFilters: (state, action) => ({
      ...state,
      filters: action.payload,
    }),
    setAppliedFilters: (state, action) => ({
      ...state,
      appliedFilters: action.payload,
    }),
    setCardData: (state, action) => ({
      ...state,
      cardData: action.payload,
    }),
    setComparisonTrendData: (state, action) => ({
      ...state,
      comparisonTrendData: action.payload,
    }),
    setDeepdiveSingleTrendData: (state, action) => ({
      ...state,
      deepdiveSingleTrendData: action.payload,
    }),
    setDeepdiveMultiTrendData: (state, action) => ({
      ...state,
      deepdiveMultiTrendData: action.payload,
    }),
    setMacroTableData: (state, action) => ({
      ...state,
      macroTableData: action.payload,
    }),
    setMicroTableData: (state, action) => ({
      ...state,
      microTableData: action.payload,
    }),
    setScaterPlotDropdown: (state, action) => ({
      ...state,
      scaterPlotDropdown: action.payload,
    }),
    setScaterPlot: (state, action) => ({
      ...state,
      scaterPlot: action.payload,
    }),
    setReviewData: (state, action) => ({
      ...state,
      reviewData: action.payload
    }),
    setRetailerImage: (state, action) => ({
      ...state,
      retailerImage: action.payload
    }),

    // on Mouse Leave
    setSelectedCompetitor: (state, action) => ({
      ...state,
      selectedCompetitor: action.payload,
    }),
    // on Mouse Leave
    setOpportunitySelection: (state, action) => ({
      ...state,
      opportunitySelection: action.payload,
    }),
  },
});

// Export individual actions from the slice
export const {
  resetBeSelected,
  setLoading,
  setMicroLoading,
  setConsumerLoading,
  setDropdownLoading,
  setPets,
  setTechs,
  setFeedingPhilosophies,
  setTextures,
  setRetailers,
  setTimeAggregates,
  setYearPeriods,
  setBenchmarkPeriods,
  setCompetitors,
  setCardData,
  setScaterPlotDropdown,
  setScaterPlot,
  setComparisonTrendData,
  setDeepdiveSingleTrendData,
  setDeepdiveMultiTrendData,


  setFilters,
  setAppliedFilters,
  setMacroTableData,
  setMicroTableData,

  setReviewData,
  setRetailerImage,

  // On mouse leave
  setSelectedCompetitor,
  setOpportunitySelection,
} = beSelectedSlicer.actions;

// Helper function to get a deep clone of the initial filters
export const getInitialFilters = () => cloneDeep(initialState.filters);

export const getInitialBottomFilters = () => {
  const state = getState().beAvailable;

  const initialFilters = cloneDeep(initialState.filters);
  const oldFilters = cloneDeep(state.filters);

  return {
    ...initialFilters,
    product_pet: oldFilters.product_pet,
    product_technology: oldFilters.product_technology,
    product_feed_philosophy: oldFilters.product_feed_philosophy,
    product_texture: oldFilters.product_texture,
    customer_retailer_details: oldFilters.customer_retailer_details,
    year_period: oldFilters.year_period,
    time_aggregate: oldFilters.time_aggregate,
    benchmark: oldFilters.benchmark,
  }
}

// Helper function to process the filters before using them
export const processFilters = (filters, getState) => {
  let state = getState().beSelected;
  let brand = getState().profile.defaultBrand;

  console.log("process", filters["selected_competitor"])

  let tempFilters = { ...filters };
  if (
    tempFilters.product_pet &&
    (tempFilters.product_pet.length === 0)
  ) {
    tempFilters["product_pet"] = "ALL";
  }
  if (
    tempFilters.product_technology &&
    (tempFilters.product_technology.length === 0 ||
      tempFilters.product_technology.length === state.techs.length)
  ) {
    tempFilters["product_technology"] = "ALL";
  }
  if (
    tempFilters.product_feed_philosophy &&
    (tempFilters.product_feed_philosophy.length === 0 ||
      tempFilters.product_feed_philosophy.length ===
      state.feedingPhilosophies.length)
  ) {
    tempFilters["product_feed_philosophy"] = "ALL";
  }
  if (
    tempFilters.product_texture &&
    (tempFilters.product_texture.length === 0 ||
      tempFilters.product_texture.length === state.textures.length)
  ) {
    tempFilters["product_texture"] = "ALL";
  }
  if (brand) {
    if (brand.split(":").length === 2) {
      tempFilters["product_brand"] = brand.split(":")[0];
      tempFilters["product_secondary_brand"] = brand.split(":")[1];
    } else {
      tempFilters["product_brand"] = brand;
      tempFilters["product_secondary_brand"] = "";
    }
  }

  if (tempFilters["selected_competitor"])
    tempFilters["selected_competitor"] = encodeTreeSelectKey(
      tempFilters["selected_competitor"]
    );
  if (tempFilters["category_benchmark"])
    tempFilters["category_benchmark"] = encodeTreeSelectValue(
      tempFilters["category_benchmark"]
    );
  if (tempFilters["competition_benchmark"])
    tempFilters["competition_benchmark"] = encodeTreeSelectValue(
      tempFilters["competition_benchmark"]
    );
  if (tempFilters["competition_kpi"])
    tempFilters["competition_kpi"] = encodeTreeSelectKey(
      tempFilters["competition_kpi"]
    );

  return tempFilters;
};

// Helper function to set default filters based on the response from an API call
const setFiltersDefault = (
  dispatch,
  response,
  getState,
  initialLoad = false
) => {
  const state = getState().beSelected;
  const filters = state.filters;
  const appliedFilters = state.appliedFilters;

  const newFilters = { ...filters };
  if (
    // newFilters.customer_retailer_details.length === 0 &&
    response.data.default_customer_retailer_details
  )
    if (filters.customer_retailer_details[0] === "Amazon") {
      newFilters.customer_retailer_details = [
        "Amazon"
        // response.data.default_customer_retailer_details,
      ];
    }
  if (
    // newFilters.time_aggregate.length === 0 &&
    response.data.default_time_aggregate
  )
    newFilters.time_aggregate = [response.data.default_time_aggregate];
  if (newFilters.year_period.length === 0 && response.data.default_year_period)
    newFilters.year_period = [response.data.default_year_period];
  if (
    // newFilters.benchmark.length === 0 &&
    response.data.default_benchmark_period
  )
    newFilters.benchmark = [response.data.default_benchmark_period];

  // Remove the selected values which are not prsent in the dropdown's items.
  newFilters.product_pet = newFilters.product_pet.filter(item => (response.data.product_pet || state.pets).includes(item));
  newFilters.product_technology = newFilters.product_technology.filter(item => (response.data.product_technology || state.techs).includes(item));
  newFilters.product_feed_philosophy = newFilters.product_feed_philosophy.filter(item => (response.data.product_feed_philosophy || state.feedingPhilosophies).includes(item));
  newFilters.product_texture = newFilters.product_texture.filter(item => (response.data.product_texture || state.textures).includes(item));

  dispatch(setFilters(newFilters));

  if (initialLoad) {
    const newAppliedFilters = { ...appliedFilters };
    if (response.data.default_customer_retailer_details)
      if (filters.customer_retailer_details[0] === "Amazon") {
        newAppliedFilters.customer_retailer_details = [
          "Amazon"
          // response.data.default_customer_retailer_details,
        ];
      }
    if (response.data.default_time_aggregate)
      newAppliedFilters.time_aggregate = [response.data.default_time_aggregate];
    if (response.data.default_year_period)
      newAppliedFilters.year_period = [response.data.default_year_period];
    if (response.data.default_benchmark_period)
      newAppliedFilters.benchmark = [response.data.default_benchmark_period];


    newAppliedFilters.product_pet = newFilters.product_pet;
    newAppliedFilters.product_technology = newFilters.product_technology;
    newAppliedFilters.product_sub_technology = newFilters.product_sub_technology;
    newAppliedFilters.product_feed_philosophy = newFilters.product_feed_philosophy;
    newAppliedFilters.product_texture = newFilters.product_texture;
    dispatch(setAppliedFilters(declareAppliedFilters(newAppliedFilters, state)));
  }

  return newFilters;
};

export const getAllFilters = () => async (dispatch, getState) => {
  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.ecomDropdown, "GET")
    .then((response) => {
      if (response.data.product_pet)
        dispatch(setPets(response.data.product_pet));
      if (response.data.product_technology)
        dispatch(setTechs(response.data.product_technology));
      if (response.data.product_feed_philosophy)
        dispatch(setFeedingPhilosophies(response.data.product_feed_philosophy));
      if (response.data.product_texture)
        dispatch(setTextures(response.data.product_texture));
      if (response.data.customer_retailer_details)
        dispatch(setRetailers(response.data.customer_retailer_details));
      if (response.data.year_period_dropdown)
        dispatch(setYearPeriods(response.data.year_period_dropdown));
      if (response.data.time_aggregate_dropdown)
        dispatch(setTimeAggregates(response.data.time_aggregate_dropdown));
      if (response.data.benchmark_dropdown)
        dispatch(setBenchmarkPeriods(response.data.benchmark_dropdown));

      const newFilters = setFiltersDefault(dispatch, response, getState, true);
      dispatch(getComparisonTrendData(newFilters));
      dispatch(getCompetitionFilterData(newFilters));
      dispatch(getCardData(newFilters));
      dispatch(getScatterPlotDropdown(newFilters));
      dispatch(getScatterPlot(newFilters));
      dispatch(getReviewData(newFilters));

      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
    });
};

export const getFilterData = (filters, loadPage = false, isBrandChanged = false, changeSelectedBrand = null) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setDropdownLoading(true));
  CustomAxios(ENDPOINTS.ecomDropdown + "?" + encodedURL, "GET")
    .then((response) => {
      if (response.data.product_pet)
        dispatch(setPets(response.data.product_pet));
      if (response.data.product_technology)
        dispatch(setTechs(response.data.product_technology));
      if (response.data.product_feed_philosophy)
        dispatch(setFeedingPhilosophies(response.data.product_feed_philosophy));
      if (response.data.product_texture)
        dispatch(setTextures(response.data.product_texture));
      if (response.data.customer_retailer_details)
        dispatch(setRetailers(response.data.customer_retailer_details));
      if (response.data.year_period_dropdown)
        dispatch(setYearPeriods(response.data.year_period_dropdown));
      if (response.data.time_aggregate_dropdown)
        dispatch(setTimeAggregates(response.data.time_aggregate_dropdown));
      if (response.data.benchmark_dropdown)
        dispatch(setBenchmarkPeriods(response.data.benchmark_dropdown));

      const newFilters = setFiltersDefault(dispatch, response, getState);
      if (loadPage) {
        dispatch(getCompetitionFilterData(newFilters));
        dispatch(getCardData(newFilters));
        dispatch(getScatterPlotDropdown(newFilters));
        dispatch(getScatterPlot(newFilters));
        dispatch(getReviewData(newFilters));
      }

      dispatch(setDropdownLoading(false));
    })
    .catch((err) => {
      dispatch(setDropdownLoading(false));
    });
};

// Function to fetch competition filter data based on the provided filters
export const getCompetitionFilterData =
  (filters) => async (dispatch, getState) => {
    let tempFilters = processFilters(filters, getState);
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

    dispatch(setLoading(true));
    CustomAxios(ENDPOINTS.ecomCompetitionDropdown + "?" + encodedURL, "GET")
      .then((response) => {
        if (response.data.competitor_list)
          dispatch(
            setCompetitors(decodeTreeSelectKey(response.data.competitor_list))
          );

        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

export const getCardData = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.beSelectedCardData + "?" + encodedURL, "GET")
    .then((response) => {
      dispatch(setCardData(response.data));

      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      dispatch(setCardData(initialState.cardData));
    });
};
export const getScatterPlotDropdown = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.beSelectedScaterPlotDropdown + "?" + encodedURL, "GET")
    .then((response) => {
      dispatch(setScaterPlotDropdown(response.data));
      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      dispatch(setScaterPlotDropdown(initialState.scaterPlotDropdown));
    });
};
export const getScatterPlot = (filters) => async (dispatch, getState) => {

  if (filters.opportunity_view_by[0] === "Sub Brand") {
    filters = {
      ...filters,
      opportunity_selection: filters?.opportunity_selection?.length && !filters?.opportunity_selection.includes("ALL") ? filters.opportunity_selection.map((i) => Number(i.split(":")[1])) : ["ALL"]
    }
  }
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.beSelectedScaterPlot + "?" + encodedURL, "GET")
    .then((response) => {
      dispatch(setScaterPlot(response.data.scatter_plot));
      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      dispatch(setScaterPlot(initialState.scaterPlot));
    });
};

const updateDeepdiveList = (comparisonTrendData, dispatch, filters) => {
  const newFilters = { ...filters };
  if (newFilters.be_selected_deepdive_kpi[0] === kpiDropdownBeSelectedAvgRating)
    newFilters.be_selected_deepdive_kpi = [kpiDropdownBeSelected[0]];

  if (newFilters.view_by[0] === "R")
    newFilters.deep_dive_list = newFilters.customer_retailer_details;
  else if (comparisonTrendData.length === 0) newFilters.deep_dive_list = [];
  else
    newFilters.deep_dive_list = [
      comparisonTrendData[0][viewBy[newFilters.view_by[0]]],
    ];

  dispatch(setFilters(newFilters));
  return newFilters;
};

export const getComparisonTrendData =
  (filters) => async (dispatch, getState) => {
    let tempFilters = processFilters(filters, getState);
    tempFilters.overview_trend_kpi =
      tempFilters.be_selected_comparison_kpi[0];
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

    dispatch(setLoading(true));
    CustomAxios(ENDPOINTS.beSelectedComparisonTrend + "?" + encodedURL, "GET")
      .then((response) => {
        const filtersInState = cloneDeep(getState().beSelected.filters);

        if (response.data.default_brand && filters.view_by[0] == "C") {
          const defaultBrand = response.data.default_brand;
          const defaultCompetitors = [];
          Object.keys(defaultBrand).forEach((brand) => {
            defaultBrand[brand].forEach((type) => {
              defaultCompetitors.push(`${brand}:${type}`);
            });
          });

          const selectedCompetitorsState = [
            ...filtersInState.selected_competitor,
          ];
          if (
            !isEqual(selectedCompetitorsState.sort(), defaultCompetitors.sort())
          ) {
            filtersInState.selected_competitor = defaultCompetitors;
            dispatch(setSelectedCompetitor(defaultCompetitors));
          }
        }

        let newFilters;
        if (response.data.overview_trend) {
          const comparisonTrend = response.data.overview_trend.bar_grid.map(
            ({ bar_label, kpi_value, avg_year_period_fact_average_rating_time_aggregate }) => ({
              [viewBy[filters.view_by[0]]]: bar_label,
              [filters.be_selected_comparison_kpi[0]]: kpi_value,
              "Average Rating": avg_year_period_fact_average_rating_time_aggregate,
            })
          );
          dispatch(setComparisonTrendData(comparisonTrend));
          newFilters = updateDeepdiveList(
            comparisonTrend,
            dispatch,
            filtersInState
          );
        } else {
          dispatch(setComparisonTrendData(initialState.comparisonTrendData));
          newFilters = updateDeepdiveList(
            initialState.comparisonTrendData,
            dispatch,
            filtersInState
          );
        }

        dispatch(getDeepdiveTrendData(newFilters));
        dispatch(getMacroTableData(newFilters));
        dispatch(getMicroTableData(newFilters));

        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        const filtersInState = getState().beSelected.filters;
        dispatch(setComparisonTrendData(initialState.comparisonTrendData));
        updateDeepdiveList(
          initialState.comparisonTrendData,
          dispatch,
          filtersInState
        );
      });
  };

export const getDeepdiveTrendData = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  tempFilters.overview_trend_kpi =
    tempFilters.be_selected_comparison_kpi;
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));
  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.beSelectedDeepDive + "?" + encodedURL, "GET")
    .then((response) => {
      if (response.data.ratings_reviews_deepdive) {
        if (filters.deep_dive_list.length > 1) {
          const { line_kpi, ...lineData } =
            response.data.ratings_reviews_deepdive.line_dict;
          const deepdiveTrend = [];
          Object.keys(lineData).forEach((trend) => {
            deepdiveTrend.push(
              ...lineData[trend].map(({ label, value }) => ({
                Periods: label,
                [filters.be_selected_deepdive_kpi[0]]: value,
                Trend: trend,
              }))
            );
          });
          dispatch(setDeepdiveMultiTrendData(deepdiveTrend));
        } else {
          const deepdiveTrend =
            response.data.ratings_reviews_deepdive.bar_dict.bar_grid.map(
              ({ time_year_period, kpi_value, avg_rating }) => ({
                Periods: time_year_period,
                [filters.be_selected_deepdive_kpi[0]]: kpi_value,
                "Average Rating": avg_rating,
              })
            );
          dispatch(setDeepdiveSingleTrendData(deepdiveTrend));
        }
      } else {
        dispatch(
          setDeepdiveSingleTrendData(initialState.deepdiveSingleTrendData)
        );
        dispatch(
          setDeepdiveMultiTrendData(initialState.deepdiveMultiTrendData)
        );
      }

      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      setDeepdiveSingleTrendData(initialState.deepdiveSingleTrendData);
      setDeepdiveMultiTrendData(initialState.deepdiveMultiTrendData);
    });
};

// Helper function to process macro table data recursively
function processMacro(data, parentKey = null) {
  return data.reduce((previousValue, currentValue) => {
    const table_hierarchy_data = processMacro(
      currentValue.table_hierarchy_data || [],
      currentValue.table_key
    );

    previousValue.push({
      ...currentValue,
      [rowKeyMacro]: currentValue.table_hierarchy_data
        ? { parent_key: [currentValue.table_key] }
        : { parent_key: [parentKey], child_key: [currentValue.table_key] },
      table_hierarchy_data: table_hierarchy_data.length
        ? table_hierarchy_data
        : null,
    });
    return previousValue;
  }, []);
}

export const getMacroTableData = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  // tempFilters = { ...tempFilters, deep_dive_list: [tempFilters.customer_retailer_details[0]] };
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.reviewRatingMacro + "?" + encodedURL, "GET")
    .then((response) => {
      dispatch(setMacroTableData(processMacro(response.data)));

      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
      dispatch(setMacroTableData(initialState.macroTableData));
    });
};

function processMicro(data) {
  return data.map((record, index) => ({ ...record, [rowKeyMicro]: index }));
}

export const getMicroTableData = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setMicroLoading(true));
  CustomAxios(ENDPOINTS.reviewRatingMicro + "?" + encodedURL, "GET")
    .then((response) => {
      if (response.data && Array.isArray(response.data))
        dispatch(setMicroTableData(processMicro(response.data)));
      else dispatch(setMicroTableData(initialState.microTableData));
      dispatch(setMicroLoading(false));
    })
    .catch((err) => {
      dispatch(setMicroLoading(false));
      dispatch(setMicroTableData(initialState.microTableData));
    });
};

// Action to fetch review data for the be selected based on the selected filters.
export const getReviewData = (filters) => async (dispatch, getState) => {
  let tempFilters = processFilters(filters, getState);

  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters));

  dispatch(setConsumerLoading(true));
  CustomAxios(ENDPOINTS.consumerRatingsandReview + "?" + encodedURL, "GET")
    .then(({ data }) => {
      dispatch(setConsumerLoading(false));
      if (data) {

        // Reset the default theme (not to be "Item Not Yet Classified")
        if (data.filter_by?.Themes?.[itemsNotYetClassified]) {
          const notClassifiedThemes = cloneDeep(data.filter_by.Themes[itemsNotYetClassified]);
          delete data.filter_by.Themes[itemsNotYetClassified];
          data.filter_by.Themes = { ...data.filter_by.Themes, [itemsNotYetClassified]: notClassifiedThemes }
        }

        dispatch(setReviewData(data));
        const newFilters = {
          ...getState().beSelected.filters,
          filter_by: [],
          sub_filter_by: [],
        };
        if (data.filter_by && Object.keys(data.filter_by).length > 0) {
          newFilters.filter_by = [Object.keys(data.filter_by)[0]];

          if (
            data.filter_by[Object.keys(data.filter_by)[0]] &&
            Object.keys(data.filter_by[Object.keys(data.filter_by)[0]]).length >
            0
          )
            newFilters.sub_filter_by = [
              Object.keys(data.filter_by[Object.keys(data.filter_by)[0]])[0],
            ];
          else newFilters.sub_filter_by = [];
        }
        dispatch(setFilters(newFilters));
      } else dispatch(setReviewData(initialState.reviewData));
    })
    .catch((err) => {
      dispatch(setConsumerLoading(false));
      dispatch(setReviewData(initialState.reviewData));
    });

  dispatch(setConsumerLoading(true));
  CustomAxios(ENDPOINTS.retailerImage + "?" + encodedURL, "GET")
    .then(({ data }) => {
      dispatch(setConsumerLoading(false));
      if (data && Array.isArray(data)) dispatch(setRetailerImage(data));
      else dispatch(setRetailerImage(initialState.retailerImage));
    })
    .catch((err) => {
      dispatch(setConsumerLoading(false));
      dispatch(setRetailerImage(initialState.retailerImage));
    });
};

export default beSelectedSlicer.reducer;
