import { useFormikContext } from "formik";
import React from "react";
import { Form, TreeSelect } from "antd";

import ErrorMessage from "./ErrorMessage";

function FormTreeSelect({ name, data, label, ...props }) {
  const { errors, setFieldValue, setFieldTouched, touched, values } =
    useFormikContext();

  return (
    <Form.Item label={label}>
      <TreeSelect
        // dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        size="large"
        value={values[name]}
        treeData={data}
        onChange={(value) => {
          setFieldValue(name, value);
        }}
        {...props}
      />
      <ErrorMessage error={errors[name]} visible={touched[name]} />
    </Form.Item>
  );
}

export default FormTreeSelect;
