export const paths = {
  home: "/",
  trackboard: "/trackboard",
  trackboardV1: "/trackboard-v1",
  profile: "/profile",
  profile1: "/profile-1",
  profile2: "/profile-2",
  profile3: "/profile-3",
  profile4: "/profile-4",
  review: "/review",
  dataSources: "/data-sources",
  export: "/export",
  alerts1: "/alerts-1",
  alerts2: "/alerts-2",
  alerts3: "/alerts-3",
  alerts4: "/alerts-4",
  performanceSummary: "/performance-summary",
  volumeShifting: "/volume-shifting",
  portfolioSummary: "/portfolio-summary",
  distributionSummary: "/distribution-summary",
  innovationTracker: "/innovation-tracker",
  beAvailable: "/be-available",
  searchTerm: "/search-term",
  shareOfShelf: "/share-of-shelf",
  report: "/report",
  admin: "/admin",
  ratingsReviews: "/ratings-reviews",
  diagnostics: "/diagnostics",
  innovationTrackerReview: "/innovation-tracker-review",
  ecom: "/ecom",
  traffic: "/traffic",
  conversion: "/conversion",
};
