import React, { useEffect, useRef, useState } from 'react';
import { Layout, Row, Col, Radio, Tabs, Button, Modal, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { hasValue, reviewTypes } from "../../utilities/helper";
import Wrapper from "../../components/wrapper";
import { useSelector, useDispatch } from "react-redux";
import "./profile.scss";
import AlertsTab from '../../components/alerts-tab';
import { getMessages, getReports, setLoading, deleteReport, updateMessages } from '../../slicers/profile.slicer';
import Loader from '../../components/loader';
import { useHistory } from 'react-router-dom';
import { CHAT_WEBSOCKET_URL } from '../../utilities/constants';
import { nudgesDeepDive } from '../../slicers/nudges.slicer';
import { getBookmarks } from '../../slicers/bookmarks.slicer';
import ProfileReports from '../../components/profile-reports';
import ProfileReview from '../../components/profile-review';
import { getReviewAlerts } from '../../slicers/reviewAlerts.slicer';

const { Content } = Layout;
const { TabPane } = Tabs;

function Profile(props) {
  const [profileTab, setProfileTab] = useState("reports");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reportSelect, setReportSelect] = useState({});
  const history = useHistory();
  const { loading, defaultBrand, reportMap, user } = useSelector((state) => state.profile)
  const { bookmarksPerf, bookmarksVol, bookmarksDist, bookmarksInn } = useSelector((state) => state.bookmarks)
  const { loading: alertsLoading, alerts } = useSelector((state) => state.reviewAlerts)
  const dispatch = useDispatch();
  const [nudgePeriod, setNudgePeriod] = useState({ "PERF": 1, "VOL": 1, "DSTR": 1, "INN": 1 });
  const [nudgeScrollTop, setNudgeScrollTop] = useState({ "PERF": 0, "VOL": 0, "DSTR": 0, "INN": 0 });
  const [key, setKey] = useState(1);
  const [reviewType, setReviewType] = useState(reviewTypes.pending);

  const loadData = () => {
    dispatch(nudgesDeepDive(nudgePeriod))
    dispatch(getBookmarks(nudgePeriod))
    dispatch(getReviewAlerts(reviewTypes.pending))
    if (props.tabName) {
      setProfileTab(props.tabName);
    } else if (props.tab) {
      setProfileTab("bookmarked")
      setKey(props.tab)
    }
  }

  useEffect(() => {
    loadData();
  }, [defaultBrand, nudgePeriod])

  const showModal = (report, type) => {
    setIsModalVisible(true);
    setReportSelect({ ...report, type: type })
  };

  const handleOk = () => {
    console.log("delete clicked")
    setIsModalVisible(false);
    dispatch(deleteReport(reportSelect.report_id, reportSelect.type))
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const BookmarkTabs = () => {
    const chatSocket = useRef(null)
    const { allMessages } = useSelector((state) => state.profile)
    const history = useHistory();
    const [msg, setMsg] = useState([...allMessages])

    useEffect(() => {

      chatSocket.current = new WebSocket(CHAT_WEBSOCKET_URL);


      chatSocket.current.onopen = (e) => {
        console.log('connected')
      }

      chatSocket.current.onmessage = (e) => {
        const data = JSON.parse(e.data);
        const message = JSON.parse(data.message)
        dispatch(updateMessages(message))
      };

      chatSocket.current.onclose = function (e) {
        console.error('Chat socket closed unexpectedly');
      };

      return () => {
        chatSocket.current.close();
      };
    }, [])

    useEffect(() => {
      setMsg([...allMessages])
    }, [allMessages])

    const tabChange = (key) => {
      if (key) {
        history.push("/profile-" + key);
        setKey(key)
      }
    }

    const sendMsg = (msg, nudgeId) => {
      let body = {
        'nudge_id': nudgeId,
        'added_by': sessionStorage.getItem('email'),
        'message': msg
      }
      try {
        chatSocket.current.send(JSON.stringify(body));
      }
      catch (e) {
        console.log(e)
      }

    }

    return (
      <Tabs defaultActiveKey={key} onChange={tabChange} style={{ width: '100%' }}>
        <TabPane tab="Performance Summary" key="1">
          <AlertsTab load={2} profile={true} loadReport={"PERF"} tab={"Performance-Summary"} messages={msg} scroll={true} sendMsg={sendMsg} remainingNudges={bookmarksPerf["remaining_nudges"]} nudgePeriod={nudgePeriod} setNudgePeriod={setNudgePeriod} nudgeScrollTop={nudgeScrollTop} setNudgeScrollTop={setNudgeScrollTop} data={bookmarksPerf["nudges"] && bookmarksPerf["nudges"]} loadData={loadData} />
        </TabPane>
        <TabPane tab="Volume Shifting" key="2">
          <AlertsTab load={2} profile={true} loadReport={"VOL"} tab={"Competition-Summary"} messages={msg} scroll={true} sendMsg={sendMsg} remainingNudges={bookmarksVol["remaining_nudges"]} nudgePeriod={nudgePeriod} setNudgePeriod={setNudgePeriod} nudgeScrollTop={nudgeScrollTop} setNudgeScrollTop={setNudgeScrollTop} data={bookmarksVol["nudges"] && bookmarksVol["nudges"]} loadData={loadData} />
        </TabPane>
        <TabPane tab="Distribution Summary" key="3">
          <AlertsTab load={2} profile={true} loadReport={"DSTR"} tab={"Distribution-Summary"} messages={msg} scroll={true} sendMsg={sendMsg} remainingNudges={bookmarksDist["remaining_nudges"]} nudgePeriod={nudgePeriod} setNudgePeriod={setNudgePeriod} nudgeScrollTop={nudgeScrollTop} setNudgeScrollTop={setNudgeScrollTop} data={bookmarksDist["nudges"] && bookmarksDist["nudges"]} loadData={loadData} />
        </TabPane>
        {/* <TabPane tab="Innovation Tracker" key="4">
          <AlertsTab load={2} profile={true} loadReport={"INN"} tab={"Innovation-Tracker"} messages={msg} scroll={true} sendMsg={sendMsg} remainingNudges={bookmarksInn["remaining_nudges"]} nudgePeriod={nudgePeriod} setNudgePeriod={setNudgePeriod} nudgeScrollTop={nudgeScrollTop} setNudgeScrollTop={setNudgeScrollTop} data={bookmarksInn["nudges"] && bookmarksInn["nudges"]} loadData={loadData} />
        </TabPane> */}
      </Tabs >
    )
  }

  const ReviewTabs = () => {
    const chatSocket = useRef(null)
    const { allMessages } = useSelector((state) => state.profile)
    const [msg, setMsg] = useState([...allMessages])

    useEffect(() => {

      chatSocket.current = new WebSocket(CHAT_WEBSOCKET_URL);


      chatSocket.current.onopen = (e) => {
        console.log('connected')
      }

      chatSocket.current.onmessage = (e) => {
        const data = JSON.parse(e.data);
        console.log("socket triggered", data)
        const message = JSON.parse(data.message)
        dispatch(updateMessages(message))
      };

      chatSocket.current.onclose = function (e) {
        console.error('Chat socket closed unexpectedly');
      };

      return () => {
        chatSocket.current.close();
      };
    }, [])

    useEffect(() => {
      setMsg([...allMessages])
    }, [allMessages])

    const sendMsg = (msg, nudgeId) => {
      let body = {
        'nudge_id': nudgeId,
        'added_by': sessionStorage.getItem('email'),
        'message': msg
      }
      try {
        chatSocket.current.send(JSON.stringify(body));
      }
      catch (e) {
        console.log(e)
      }

    }

    return (
      <ProfileReview load={2} profile={true} loadReport={"PERF"} tab={"Performance-Summary"} messages={msg} scroll={true} sendMsg={sendMsg} remainingNudges={alerts.remaining_nudges} nudgePeriod={nudgePeriod} setNudgePeriod={setNudgePeriod} data={alerts.nudge_list && alerts.nudge_list} reviewType={reviewType} setReviewType={setReviewType} user={user} loadData={loadData} />
    )
  }

  const profileTabChange = (value) => {
    if (value === "reports") {
      history.push('/profile')
      setProfileTab("reports")
    }
    else if (value === "bookmarked") {
      history.push('/profile-1')
      setProfileTab("bookmarked")
    }
    else if (value === "review") {
      history.push('/review')
      setProfileTab("review")
    }
  }

  useEffect(() => {
    dispatch(getMessages((val) => dispatch(setLoading(val))));
  }, [])

  useEffect(() => {
    dispatch(getReports())
  }, [defaultBrand])

  return (
    <>
      <Wrapper heading={"4"}>
        <Content
          className="site-layout-background content-body"
          style={{
            background: '#fff'
          }}
        >
          <Loader loading={loading || alertsLoading}>
            <div className="user-profile-info-wrap">
              <div className="user-profile-image">
                <Avatar src={<UserOutlined />} size={60} style={{ background: '#DCDCDC', fontSize: 28, color: '#525252' }} />
              </div>
              <div className="user-profile-info">
                <h4>{user.user_name}</h4>
                <p>{user.user_designation}</p>
              </div>
            </div>

            <Col md={24}>

              <Row>
                <Radio.Group size="large" defaultValue="reports" value={profileTab} onChange={(e) => profileTabChange(e.target.value)} buttonStyle="solid">
                  <Radio.Button value="reports">My Reports</Radio.Button>
                  <Radio.Button value="bookmarked">Bookmarked Alerts</Radio.Button>
                  <Radio.Button value="review">Alerts Review {hasValue(alerts.to_be_reviewed_nudges_count) && alerts.to_be_reviewed_nudges_count > 0 && <span style={{
                    width: '33px',
                    height: '33px',
                    display: 'block',
                    background: '#EC3225',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '32px',
                    color: '#fff',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    borderRadius: '50%',
                    textAlign: 'center',
                    transform: 'translate(16px, -16px)'
                  }}>{alerts.to_be_reviewed_nudges_count > 10 ? "10+" : alerts.to_be_reviewed_nudges_count}</span>}</Radio.Button>
                </Radio.Group>
              </Row>

              {profileTab === "reports" &&
                <>
                  <Row style={{ marginTop: 16 }} gutter={22}>
                    <Col span={8}>
                      <ProfileReports
                        header={"My Reports"}
                        number={Object.keys(reportMap).length !== 0 && reportMap["M"] && reportMap["M"].length}
                        type={"M"}
                        showModal={showModal}
                      />
                    </Col>
                    <Col span={8}>
                      <ProfileReports
                        header={"Team Reports"}
                        number={Object.keys(reportMap).length !== 0 && reportMap["T"] && reportMap["T"].length}
                        type={"T"}
                        showModal={showModal}
                      />
                    </Col>
                    <Col span={8}>
                      <ProfileReports
                        header={"Public Reports"}
                        number={Object.keys(reportMap).length !== 0 && reportMap["P"] && reportMap["P"].length}
                        type={"P"}
                        showModal={showModal}
                      />
                    </Col>
                  </Row>
                </>
              }
              {profileTab === "bookmarked" && <Row style={{ marginTop: 16 }}><BookmarkTabs /></Row>}
              {profileTab === "review" && <Row style={{ marginTop: 16 }}><ReviewTabs /></Row>}
            </Col>
          </Loader>
        </Content>
      </Wrapper>
      <Modal title="Are you sure you want to delete this report?" visible={isModalVisible} footer={[
        <Button key="back" onClick={handleOk}>
          Confirm
        </Button>,
        <Button key="submit" type="primary" onClick={handleCancel}>
          Cancel
        </Button>
      ]}>
        <p>This operation cannot be reversed.</p>
      </Modal>
    </>
  );
}

export default Profile;
