import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Tooltip } from "antd";
import "./ecomCard.scss";
import icIcon from "../../assets/images/ic_outline-info.svg";

/**
 * Ecom Card
 * It manages displaying of data through card on trackboard.
 */
function EcomCard(props) {
  const {
    history,
    imageSrc,
    iconSrc,
    title,
    description,
    questions,
    deepDiveLink,
    tooltipContent,
    buttonDisabled = false
  } = props;

  return (
    <Col className="diagnostic-card">
      <div className="card-image-container">
        <img src={imageSrc} alt="diagnostic" className="card-image" />
      </div>
      <div className="card-content">
        <div className="card-header">
          <div className="header-icon">
            <img src={iconSrc} alt="Icon" />
          </div>
          <span className="header-title">{title}</span>
          <Tooltip
            trigger="hover"
            placement="bottom"
            color="#0D0C0C"
            arrow={false}
            overlayInnerStyle={{ borderRadius: "10px", width: "350px", padding: 5 }}
            title={() => (
              <div className="tooltip-content">{tooltipContent}</div>
            )}
          >
            <img src={icIcon} alt="Icon" className="tooltip-icon" />
          </Tooltip>
        </div>
        <div className="card-description">
          <p>{description}</p>
        </div>
        <div className="card-questions">
          <span className="questions-label">Questions Answered:</span>
          {questions.map((q, index) => (
            <span key={index} className="question">
              {q}
            </span>
          ))}
        </div>
        <div className="card-button-container">
          <Button
            className={buttonDisabled ? "card-button-disable" :"card-button"}
            disabled={buttonDisabled}
            onClick={() => {
              history.push(deepDiveLink);
            }}
          >
            Deep Dive
          </Button>
        </div>
      </div>
    </Col>
  );
}

export default withRouter(EcomCard);
