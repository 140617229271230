import { createSlice } from '@reduxjs/toolkit';
import CustomAxios from '../utilities/services/api.service';
import { ENDPOINTS } from '../utilities/constants';
import { allBrands, isSubBrand, openNotificationWithIcon, setWindowHistory, timeAggregates } from '../utilities/helper'
import { isPackSize } from '../pages/portfolio-summary';
import { getState } from '../store';
import { cloneDeep, orderBy } from 'lodash';

export const compareVia = { brand: "brand", retailer: "retailer", channel: "channel" };

export const defaultManufacturer = ["MARS BDB"]


/**
 * Portfolio Summary Slicer
 * It manages the Redux State/Reducer/Actions for Portfolio Summary usecase.
 */

const initialState = {
    topManufacturers: [],
    pets: [],
    techs: [],
    subTechs: [],
    lifestage:[],
    packSize: [],
    years: [],
    periods: [],
    brands: [],
    manufacturers: [],
    granuls: [],
    kpis: [],   
    load: false,
    channels: [],
    retailers: [],
    channel_retailer: {},
    table_data: [],
    channel_data: [],
    retailer_data: [],
    images: [],
    retailerImages: [],
    waterfallData: {},
    loading: 0,
    filterLoading: 0,
    top_bottom_sku: {
        top_sku: {},
        bottom_sku: {}
    },
    filters: {
        view: ["portfolio_summary"],
        product_manufacturer: [defaultManufacturer[0]],
        product_pet: [],
        product_technology: [],
        product_sub_technology: [],
        product_lifestage:[],
        product_package: [],
        time_year: [],
        time_period: [],
        granuls: [],
        kpi: [],
        channel: ['FDM excluding Omni'],
        default_brand: ['ALL BRANDS'],
        customer_retailer_details: [],
        brandList: [],
        compare_via: [compareVia.brand],
        time_aggregates: [timeAggregates.l4w],
        // brandList: ['MARS', 'NUTRO CORE', 'BLUE CORE', 'MERRICK', 'RCHL RAY NUTRISH'],
        channelList: [],
        retailerList: [],
    },
    appliedFilters: {
        view: ["portfolio_summary"],
        product_manufacturer: [defaultManufacturer[0]],
        product_pet: ["ALL"],
        product_technology: ["ALL"],
        product_sub_technology: ["ALL"],
        product_lifestage:["ALL"],
        product_package: ["ALL"],
        time_year: [],
        time_period: [],
        granuls: [],
        kpi: [],
        channel: ['FDM excluding Omni'],
        default_brand: ['ALL BRANDS'],
        customer_retailer_details: [],
        brandList: [],
        compare_via: [compareVia.brand],
        time_aggregates: [timeAggregates.l4w],
        // brandList: ['MARS', 'NUTRO CORE', 'BLUE CORE', 'MERRICK', 'RCHL RAY NUTRISH'],
        channelList: [],
        retailerList: [],
    },
    reportRefreshVol: false,
}

const portfolioSummarySlicer = createSlice({
    name: 'portfolioSummary',
    initialState,
    reducers: {
        resetPortfolioSummary: (state) => initialState,
        setLoading: (state, action) => ({ ...state, loading: state.loading + (action.payload ? 1 : -1) }),
        setFilterLoading: (state, action) => ({ ...state, filterLoading: state.filterLoading + (action.payload ? 1 : -1) }),
        setTopManufacturers: (state, action) => ({
            ...state,
            topManufacturers: action.payload
        }),
        setPets: (state, action) => ({
            ...state,
            pets: action.payload
        }),
        setTechs: (state, action) => ({
            ...state,
            techs: action.payload
        }),
        setSubTechs: (state, action) => ({
            ...state,
            subTechs: action.payload
        }),
        setLifeStage: (state, action) => ({
            ...state,
            lifestage: action.payload
        }),
        setPackSize: (state, action) => ({
            ...state,
            packSize: action.payload
        }),
        setYears: (state, action) => ({
            ...state,
            years: action.payload
        }),
        setPeriods: (state, action) => ({
            ...state,
            periods: action.payload
        }),
        setBrands: (state, action) => ({
            ...state,
            brands: action.payload,
        }),
        setManufacturers: (state, action) => ({
            ...state,
            manufacturers: action.payload,
        }),
        setReportRefreshVol: (state, action) => ({
            ...state,
            reportRefreshVol: action.payload,
        }),
        setTopBrand: (state, action) => {
            let brands = [...state.filters.brandList]
            brands.splice(0, 1, action.payload)
            let filters = {
                ...state.filters,
                brandList: brands,
            }
            return {
                ...state,
                filters: filters
            }
        },
        setImages: (state, action) => ({
            ...state,
            images: action.payload,
        }),
        addImages: (state, action) => {
            let imageData = [...state.images]
            imageData.push(action.payload)
            return {
                ...state,
                images: imageData
            }
        },
        setRetailerImages: (state, action) => ({
            ...state,
            retailerImages: action.payload,
        }),
        addRetailerImages: (state, action) => {
            let imageData = [...state.retailerImages]
            imageData.push(action.payload)
            return {
                ...state,
                retailerImages: imageData
            }
        },
        setGranuls: (state, action) => ({
            ...state,
            granuls: action.payload
        }),
        setKpis: (state, action) => ({
            ...state,
            kpis: action.payload
        }),
        setChannels: (state, action) => ({
            ...state,
            channels: action.payload
        }),
        setRetailers: (state, action) => ({
            ...state,
            retailers: action.payload
        }),
        setChannelRetailers: (state, action) => ({
            ...state,
            channel_retailer: action.payload
        }),
        setChannelData: (state, action) => ({
            ...state,
            channel_data: action.payload
        }),
        setRetailerData: (state, action) => ({
            ...state,
            retailer_data: action.payload
        }),
        setTableData: (state, action) => ({
            ...state,
            table_data: action.payload
        }),
        addTableData: (state, action) => {
            let tableData = [...state.table_data]
            let brandList = [...state.filters.brandList]
            let index = tableData.length
            tableData.push({ ...action.payload, id: index })
            tableData = tableData.sort((a, b) => {
                if (a["brand"] === '') {
                    return 1
                }
                else if (b["brand"] === '') {
                    return -1
                }
                else {
                    return brandList.indexOf(a["brand"]) - brandList.indexOf(b["brand"])
                }
            }).map((row, i) => ({ ...row, psSection: `vs-section-ps-${i}` }))
            return { ...state, table_data: tableData }
        },
        replaceTableData: (state, action) => {
            let tableData = [...state.table_data]
            let index = tableData.findIndex(val => val.brand === action.payload[0])
            if (action.payload[1] === -1) {
                tableData.splice(index, 1)
            }
            else {
                tableData.splice(index, 1, action.payload[1])
            }
            return {
                ...state, table_data: tableData
            }
        },
        addChannelData: (state, action) => {
            let channelData = [...state.channel_data]
            let channelList = [...state.filters.channelList]
            let index = channelData.length
            channelData.push({ ...action.payload, id: index })
            channelData = channelData.sort((a, b) => {
                if (a["channel"] === '') {
                    return 1
                }
                else if (b["channel"] === '') {
                    return -1
                }
                else {
                    return channelList.indexOf(a["channel"]) - channelList.indexOf(b["channel"])
                }
            })
            return { ...state, channel_data: channelData }
        },
        replaceChannelData: (state, action) => {
            let channelData = [...state.channel_data]
            let index = channelData.findIndex(val => val.channel === action.payload[0])
            if (action.payload[1] === -1) {
                channelData.splice(index, 1)
            }
            else {
                channelData.splice(index, 1, action.payload[1])
            }
            return {
                ...state, channel_data: channelData
            }
        },
        addRetailerData: (state, action) => {
            let retailerData = [...state.retailer_data]
            let retailerList = [...state.filters.retailerList]
            let index = retailerData.length
            retailerData.push({ ...action.payload, id: index })
            retailerData = retailerData.sort((a, b) => {
                if (a["retailer"] === '') {
                    return 1
                }
                else if (b["retailer"] === '') {
                    return -1
                }
                else {
                    return retailerList.indexOf(a["retailer"]) - retailerList.indexOf(b["retailer"])
                }
            })
            return { ...state, retailer_data: retailerData }
        },
        replaceRetailerData: (state, action) => {
            let retailerData = [...state.retailer_data]
            let index = retailerData.findIndex(val => val.retailer === action.payload[0])
            if (action.payload[1] === -1) {
                retailerData.splice(index, 1)
            }
            else {
                retailerData.splice(index, 1, action.payload[1])
            }
            return {
                ...state, retailer_data: retailerData
            }
        },
        setTopBottomSku: (state, action) => ({
            ...state,
            top_bottom_sku: action.payload
        }),
        sortTableData: (state) => {
            // Sorting the Portfolio Summary KPIs vertically and horizontally using $ Sales
            let tableData = [...state.table_data];
            let filters = { ...state.filters };
            tableData = orderBy(
                tableData,
                [`dollar_sum_latest_${filters.time_aggregates}`],
                ["desc"]
            ).map((row, i) => ({
                ...row,
                psSection: `vs-section-ps-${i}`,
                table_data: orderBy(
                    row.table_data,
                    [`dollar_sum_latest_${filters.time_aggregates}.abs`],
                    ["desc"]
                ),
            }));

            return {
                ...state,
                table_data: tableData,
            };
        },
        sortChannelData: (state, action) => {
            let channelData = [...state.channel_data]
            let channelList = [...state.filters.channelList]
            channelData = channelData.sort((a, b) => {
                if (a["channel"] === '') {
                    return 1
                }
                else if (b["channel"] === '') {
                    return -1
                }
                else {
                    return channelList.indexOf(a["channel"]) - channelList.indexOf(b["channel"])
                }
            })
            return {
                ...state, channel_data: channelData
            }
        },
        sortRetailerData: (state, action) => {
            let retailerData = [...state.retailer_data]
            let retailerList = [...state.filters.retailerList]
            retailerData = retailerData.sort((a, b) => {
                if (a["retailer"] === '') {
                    return 1
                }
                else if (b["retailer"] === '') {
                    return -1
                }
                else {
                    return retailerList.indexOf(a["retailer"]) - retailerList.indexOf(b["retailer"])
                }
            })
            return {
                ...state, retailer_data: retailerData
            }
        },
        setFilters: (state, action) => ({
            ...state,
            filters: action.payload
        }),
        setAppliedFilters: (state, action) => ({
            ...state,
            appliedFilters: action.payload
        }),
        setWaterfallData: (state, action) => ({
            ...state,
            waterfallData: action.payload
        })
    },
})
export const { resetPortfolioSummary, setLoading, setFilterLoading, setTopManufacturers, setPets, setTechs, setSubTechs, setLifeStage, setPackSize, setYears, setPeriods, setBrands, setManufacturers, setTopBrand, setReportRefreshVol, setImages, addImages, setRetailerImages, addRetailerImages, setFilters, setAppliedFilters, setGranuls, setKpis, setChannels, setRetailers, setChannelRetailers, setTableData, addTableData, setChannelData, addChannelData, replaceChannelData, sortChannelData, setRetailerData, addRetailerData, replaceRetailerData, sortRetailerData, setWaterfallData, replaceTableData, sortTableData, setTopBottomSku } = portfolioSummarySlicer.actions

export const getInitialBottomFilters = () => {
    const state = getState().portfolioSummary;

    const initialFilters = cloneDeep(initialState.filters);
    const oldFilters = cloneDeep(state.filters);

    return {
        ...initialFilters,
        product_manufacturer: oldFilters.product_manufacturer,
        product_pet: oldFilters.product_pet,
        product_technology: oldFilters.product_technology,
        product_sub_technology: oldFilters.product_sub_technology,
        product_lifestage: oldFilters.product_lifestage,
        product_package: oldFilters.product_package,
        customer_channel: oldFilters.customer_channel,
        time_year: oldFilters.time_year,
        time_period: oldFilters.time_period,
        brandList: oldFilters.brandList,
        channelList: oldFilters.channelList,
        retailerList: oldFilters.retailerList,
    }
}

// Function to set default filters and applied filters in the state
const setFiltersDefault = (
    dispatch,
    response,
    getState,
    initialLoad = false
) => {
    // Extract the required data from the state and response
    const state = getState().portfolioSummary;
    const filters = state.filters;
    const years = state.years;
    const periods = state.periods;

    const newFilters = { ...filters };

    if (initialLoad) {
        if (response.data.latest_time_year) newFilters.time_year = [response.data.latest_time_year];
        if (response.data.latest_time_period) newFilters.time_period = [response.data.latest_time_period];
    } 
    else {
        newFilters.time_year = newFilters.time_year.length === 1 && years.includes(newFilters.time_year[0]) ? newFilters.time_year : [response.data.latest_time_year];
        newFilters.time_period = newFilters.time_period.length > 0 && periods.includes(newFilters.time_period[0]) ? newFilters.time_period : [response.data.latest_time_period];
    }
    if (response.data.customer_retailer_details) newFilters.channelList = Object.keys(response.data.customer_retailer_details);
    if (response.data.customer_retailer_details) newFilters.retailerList = response.data.customer_retailer_details[newFilters.channel];

    // Remove the selected values which are not prsent in the dropdown's items.
    newFilters.product_pet = newFilters.product_pet.filter(item => (response.data.product_pet || state.pets).includes(item));
    newFilters.product_technology = newFilters.product_technology.filter(item => ((response.data.product_package && Object.keys(response.data.product_package)) || state.techs).includes(item));
    newFilters.product_sub_technology = newFilters.product_sub_technology.filter(item => (response.data.product_sub_technology || state.subTechs).includes(item));
    newFilters.product_lifestage = newFilters.product_lifestage.filter(item => (response.data.product_lifestage || state.lifestage).includes(item));
    if (newFilters.product_package) newFilters.product_package = newFilters.product_package.filter(item => (response.data.product_package[newFilters.product_technology[0]] || state.packSize[newFilters.product_technology[0]] || []).includes(item));

    if (!initialLoad) {
        dispatch(setFilters(newFilters));  // Dispatch the new filters to update the state
    }

    return newFilters;
};

const getFilterForBrandChange = (filters) => {
    const newFilters = {};
    if (filters.product_pet.length > 0) {
        newFilters.product_pet = filters.product_pet;
    }
    if (filters.product_technology.length > 0) {
        newFilters.product_pet = filters.product_pet;
        newFilters.product_technology = filters.product_technology;
    }

    return newFilters;
}

export const getAllFilters = (fetchInitialData = null, isBrandChanged = false, changeSelectedBrand = null) => async (dispatch, getState) => {
    let brand = getState().profile.defaultBrand;
    let tempFilters = {};
    if (brand) {
        if (brand.split(":").length === 2) {
            tempFilters["product_brand"] = brand.split(":")[0];
            tempFilters["product_secondary_brand"] = brand.split(":")[1];
        } else {
            tempFilters["product_brand"] = brand;
            tempFilters["product_secondary_brand"] = "";
        }
    }

    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters))

    dispatch(setFilterLoading(true))
    CustomAxios(ENDPOINTS.portfolioSummary + '?' + encodedURL, 'GET').then((response) => {
        if (response.data.product_manufacturer) dispatch(setTopManufacturers(response.data.product_manufacturer))
        // if (response.data.default_manufacturer) dispatch(setDefaultManufacturer(response.data.default_manufacturer))
        if (response.data.product_pet) dispatch(setPets(response.data.product_pet))
        if (response.data.product_package && Object.keys(response.data.product_package)) dispatch(setTechs(Object.keys(response.data.product_package)))
        if (response.data.product_sub_technology) dispatch(setSubTechs(response.data.product_sub_technology))
        if (response.data.product_lifestage) dispatch(setLifeStage(response.data.product_lifestage))
        if (response.data.product_package) dispatch(setPackSize(response.data.product_package))
        if (response.data.time_year) dispatch(setYears(response.data.time_year))
        if (response.data.time_period) dispatch(setPeriods(response.data.time_period))
        if (response.data.product_brand) dispatch(setBrands(response.data.product_brand))
        // if (response.data.product_manufacturer) dispatch(setManufacturers(response.data.product_manufacturer))
        // if (response.data.customer_retailer_details) dispatch(setRetailers(response.data.customer_retailer_details))
        if (response.data.customer_retailer_details) dispatch(setChannels(Object.keys(response.data.customer_retailer_details)))
        if (response.data.customer_retailer_details) dispatch(setChannelRetailers(response.data.customer_retailer_details))


        const newFilters = setFiltersDefault(dispatch, response, getState, true);
        if (fetchInitialData) dispatch(setManufacturers(fetchInitialData(newFilters)));
        if (isBrandChanged && changeSelectedBrand) {
            changeSelectedBrand();
            dispatch(getFilterData(getFilterForBrandChange(getState().portfolioSummary.filters)));
        }

        dispatch(setFilterLoading(false))
    }).catch((err) => {

        dispatch(setFilterLoading(false))
    })
}

export const getFilterData = (filters, preFilters = null, fetchReportData = null, isReport = false, loadPage = false) => async (dispatch, getState) => {
    let defaultBrand = getState().profile.defaultBrand

    let tempFilters = { ...filters }
    if (tempFilters.product_manufacturer && tempFilters.product_manufacturer.length === 0) {
        tempFilters['product_manufacturer'] = 'ALL'
    }
    if (tempFilters.product_pet && tempFilters.product_pet.length === 0) {
        tempFilters['product_pet'] = 'ALL'
    }
    if (tempFilters.product_technology && tempFilters.product_technology.length === 0) {
        tempFilters['product_technology'] = 'ALL'
    }
    if (tempFilters.product_sub_technology && tempFilters.product_sub_technology.length === 0) {
        tempFilters['product_sub_technology'] = 'ALL'
    }
    if (tempFilters.product_lifestage && tempFilters.product_lifestage.length === 0) {
        tempFilters['product_lifestage'] = 'ALL'
    }
    if (
        tempFilters.product_package &&
        tempFilters.product_technology.length === 1 &&
        isPackSize.includes(tempFilters.product_technology[0]) &&
        tempFilters.product_package.length === 0
    ) {
        tempFilters['product_package'] = 'ALL'
    }
    if (tempFilters.time_year && tempFilters.time_year.length === 0) {
        tempFilters['time_year'] = 'ALL'
    }
    if (tempFilters.time_period && tempFilters.time_period.length === 0) {
        tempFilters['time_period'] = 'ALL'
    }
    if (defaultBrand) {
        if (isSubBrand(defaultBrand)) {
            tempFilters['product_brand'] = defaultBrand.split(":")[0]
            tempFilters['product_secondary_brand'] = defaultBrand.split(":")[1]
            tempFilters['primary_brand_indicator'] = 0
        }
        else {
            tempFilters['product_brand'] = defaultBrand
            tempFilters['product_secondary_brand'] = ""
            tempFilters['primary_brand_indicator'] = 1
        }
    }

    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters))

    dispatch(setFilterLoading(true));
    CustomAxios(ENDPOINTS.portfolioSummary + '?' + encodedURL, 'GET').then((response) => {
        if (response.data.product_manufacturer) dispatch(setTopManufacturers(response.data.product_manufacturer))
        // if (response.data.default_manufacturer) dispatch(setDefaultManufacturer(response.data.default_manufacturer))
        if (response.data.product_pet) dispatch(setPets(response.data.product_pet))
        if (response.data.product_package && Object.keys(response.data.product_package)) dispatch(setTechs(Object.keys(response.data.product_package)))
        if (response.data.product_sub_technology) dispatch(setSubTechs(response.data.product_sub_technology))
        if (response.data.product_lifestage) dispatch(setLifeStage(response.data.product_lifestage))
        if (response.data.product_package) dispatch(setPackSize(response.data.product_package))
        if (response.data.time_year) dispatch(setYears(response.data.time_year))
        if (response.data.time_period) dispatch(setPeriods(response.data.time_period))
        if (response.data.product_brand) dispatch(setBrands(response.data.product_brand))

        const newFilters = setFiltersDefault(dispatch, response, getState, loadPage);

        if (loadPage) {
            dispatch(getWaterFallData(newFilters));
            if (fetchReportData) dispatch(setManufacturers(fetchReportData(newFilters, preFilters, isReport)));
            setWindowHistory(encodeURIComponent(JSON.stringify(newFilters)));
        }

        dispatch(setFilterLoading(false))
    }).catch((err) => {
        dispatch(setFilterLoading(false))
    })
}

export const getImages = (brand, pet) => async (dispatch, getState) => {
    let state = getState().portfolioSummary;
    let defaultBrand = getState().profile.defaultBrand;
    let product_pet = "ALL"
    if (pet.length !== 0) {
        product_pet = pet
    }
    let subBrand = isSubBrand(defaultBrand)
    let url = {
        'brand': [brand],
        "product_brand": brand,
        "product_secondary_brand": subBrand ? defaultBrand.split(":")[1] : "",
        "product_pet": product_pet,
        "image_type": ['BR'],
        "primary_brand_indicator": subBrand ? 0 : 1
    }
    const encodedURL = encodeURIComponent(JSON.stringify(url))
    CustomAxios(ENDPOINTS.getPortfolioImage + '?' + encodedURL, 'GET').then((response) => {
        if (response.data) {
            dispatch(addImages({
                brand: brand,
                imageString: response.data
            }))
        }
        // }
    }).catch((err) => {

    })

}

export const getRetailerImages = (retailer, pet) => async (dispatch, getState) => {
    let product_pet = "ALL"
    if (pet.length !== 0) {
        product_pet = pet
    }
    const encodedURL = encodeURIComponent(JSON.stringify({ customer_retailer_details: [retailer], product_pet, image_type: ['RE'] }))
    CustomAxios(ENDPOINTS.getPortfolioImage + '?' + encodedURL, 'GET').then((response) => {
        if (response.data) {
            dispatch(addRetailerImages({
                retailer,
                imageString: response.data
            }))
        }
        // }
    }).catch((err) => {

    })

}



export const saveVSReport = (reportName, accessValue, reportId, waterfall, brands, channel, channels, retailers, defaultBrands, defaultChannels, defaultRetailers, createReport, setComponents, setBrands, setChannels, setRetailers, setNewReport, setOpenSaveDialog, setSaveLoading) => async (dispatch, getState) => {
    const state = getState().portfolioSummary
    const profile = getState().profile
    const default_brand = profile.defaultBrand
    const subBrand = isSubBrand(default_brand)
    const channel_data = state.channel_data
    const retailer_data = state.retailer_data
    const userDesignation = profile.userDesignation

    let channelFinal = {}
    if (!(channels.length === 1 && channels.includes(defaultChannels))) {
        for (let i = 0; i < channels.length; i++) {
            channelFinal[channels[i]] = {
                brandList: channel_data.find(val1 => val1.channel === channels[i]).cards.map(val2 => val2.brand)
            }
        }
    }


    let retailerFinal = {}
    if (!(retailers.length === 1 && retailers.includes(defaultRetailers))) {
        for (let i = 0; i < retailers.length; i++) {
            retailerFinal[retailers[i]] = {
                brandList: retailer_data.find(val1 => val1.retailer === retailers[i]).cards.map(val2 => val2.brand)
            }
        }
    }


    let saveReport = {
        report_id: reportId,
        report_name: reportName,
        report_access: accessValue,
        user_email: sessionStorage.getItem('email'),
        user_name: profile.user.user_name,
        user_designation: userDesignation,
        // team_cluster: profile.teamCluster,
        product_pet: state.filters.product_pet,
        product_technology: state.filters.product_technology,
        product_sub_technology: state.filters.product_sub_technology,
        product_lifestage:state.filters.product_lifestage,
        product_package: state.filters.product_package,
        product_brand: subBrand ? default_brand.split(":")[0] : default_brand,
        product_secondary_brand: subBrand ? default_brand.split(":")[1] : "",
        primary_brand_indicator: subBrand ? 0 : 1,
        time_year: state.filters.time_year,
        time_period: state.filters.time_period,
        competitor_brand: brands.length === 2 && brands.includes(defaultBrands[0], defaultBrands[1]) ? [] : brands.filter(val => val !== allBrands),
        competitor_channel: channels.length === 1 && channels.includes(defaultChannels) ? {} : channelFinal,
        competitor_retailer: retailers.length === 1 && retailers.includes(defaultRetailers) ? {} : { [channel]: retailerFinal },
        volume_waterfall_indicator: waterfall ? 1 : 0,
    }
    let addToExisting = {
        report_id: reportId,
        report_name: reportName,
        user_email: sessionStorage.getItem('email'),
        user_name: profile.user.user_name,
        time_year: state.filters.time_year,
        time_period: state.filters.time_period,
        product_brand: subBrand ? default_brand.split(":")[0] : default_brand,
        product_secondary_brand: subBrand ? default_brand.split(":")[1] : "",
        // team_cluster: profile.teamCluster,
        competitor_brand: brands.length === 2 && brands.includes(defaultBrands[0], defaultBrands[1]) ? [] : brands.filter(val => val !== allBrands),
        competitor_channel: channels.length === 1 && channels.includes(defaultChannels) ? {} : channelFinal,
        competitor_retailer: retailers.length === 1 && retailers.includes(defaultRetailers) ? {} : { [channel]: retailerFinal },
        volume_waterfall_indicator: waterfall ? 1 : 0,
    }
    setSaveLoading(true);
    CustomAxios(ENDPOINTS.saveReport, 'POST', createReport ? saveReport : addToExisting).then((response) => {
        if (response.data.error_code === undefined) {
            openNotificationWithIcon('success', response.data.message);
            setComponents([])
            setBrands(defaultBrands)
            setChannels([defaultChannels])
            setRetailers([defaultRetailers])
            setOpenSaveDialog(false)
            setNewReport(false)
        }
        else {
            openNotificationWithIcon('error', "Error saving report. Please contact support.");
        }
        setSaveLoading(false);
    })
        .catch((err) => {
            setSaveLoading(false);

            openNotificationWithIcon('error', 'Error Occured');
        })
}

export const getWaterFallData = (filters) => async (dispatch, getState) => {
    let state = getState().portfolioSummary
    let brand = getState().profile.defaultBrand
    dispatch(setLoading(true))
    let tempFilters = { ...filters }
    if (tempFilters.product_pet && tempFilters.product_pet.length === 0) {
        tempFilters['product_pet'] = 'ALL'
    }
    if (tempFilters.product_technology && tempFilters.product_technology.length === 0) {
        tempFilters['product_technology'] = 'ALL'
    }
    if (tempFilters.product_sub_technology && tempFilters.product_sub_technology.length === 0) {
        tempFilters['product_sub_technology'] = 'ALL'
    }
    if (tempFilters.product_lifestage && tempFilters.product_lifestage.length === 0) {
        tempFilters['product_lifestage'] = 'ALL'
    }
    if (
        tempFilters.product_package &&
        tempFilters.product_technology.length === 1 &&
        isPackSize.includes(tempFilters.product_technology[0]) &&
        tempFilters.product_package.length === 0
    ) {
        tempFilters['product_package'] = 'ALL'
    }
    if (tempFilters.time_year && tempFilters.time_year.length === 0) {
        tempFilters['time_year'] = 'ALL'
    }
    if (tempFilters.time_period && tempFilters.time_period.length === 0) {
        tempFilters['time_period'] = 'ALL'
    }
    if (brand) {
        if (brand.split(":").length === 2) {
            tempFilters['product_brand'] = brand.split(":")[0]
            tempFilters['product_secondary_brand'] = brand.split(":")[1]
            tempFilters['primary_brand_indicator'] = 0
        }
        else {
            tempFilters['product_brand'] = brand
            tempFilters['product_secondary_brand'] = ""
            tempFilters['primary_brand_indicator'] = 1
        }
    }
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters))
    CustomAxios(ENDPOINTS.volShiftWaterfall + '?' + encodedURL, 'GET').then((response) => {
        if (response.data) dispatch(setWaterfallData(response.data))
        dispatch(setLoading(false))
    }).catch((err) => {
        dispatch(setLoading(false))
    })
}

export const getTableData = (newBrand, filters, brand = -1) => async (dispatch, getState) => {
    let defaultBrand = getState().profile.defaultBrand
    dispatch(setLoading(true))
    let tempFilters = { ...filters }
    if (tempFilters.product_pet && tempFilters.product_pet.length === 0) {
        tempFilters['product_pet'] = 'ALL'
    }
    if (tempFilters.product_technology && tempFilters.product_technology.length === 0) {
        tempFilters['product_technology'] = 'ALL'
    }
    if (tempFilters.product_sub_technology && tempFilters.product_sub_technology.length === 0) {
        tempFilters['product_sub_technology'] = 'ALL'
    }
    if (tempFilters.product_lifestage && tempFilters.product_lifestage.length === 0) {
        tempFilters['product_lifestage'] = 'ALL'
    }
    if (
        tempFilters.product_package &&
        tempFilters.product_technology.length === 1 &&
        isPackSize.includes(tempFilters.product_technology[0]) &&
        tempFilters.product_package.length === 0
    ) {
        tempFilters['product_package'] = 'ALL'
    }
    if (tempFilters.time_year && tempFilters.time_year.length === 0) {
        tempFilters['time_year'] = 'ALL'
    }
    if (tempFilters.time_period && tempFilters.time_period.length === 0) {
        tempFilters['time_period'] = 'ALL'
    }
    if (defaultBrand) {
        if (isSubBrand(defaultBrand)) {
            tempFilters['product_brand'] = defaultBrand.split(":")[0]
            tempFilters['product_secondary_brand'] = defaultBrand.split(":")[1]
            tempFilters['primary_brand_indicator'] = 0
        }
        else {
            tempFilters['product_brand'] = defaultBrand
            tempFilters['product_secondary_brand'] = ""
            // When A Brand is Added on the Page, Primary Brand Indicator = 0, for default Brands on load = 1
            tempFilters['primary_brand_indicator'] = brand !== -1 ? 0 : 1
        }
    }
    tempFilters['brand'] = [newBrand.toUpperCase()];
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters))
    CustomAxios(ENDPOINTS.portfolioSummaryTable + '?' + encodedURL, 'GET').then((response) => {
        if (response.data) {
            let brandData = {
                ...response.data,
                brand: newBrand,
                edit: 0,
            }
            if (brand === -1) {
                dispatch(addTableData(brandData))
                dispatch(sortTableData())
            }
            else {
                let state = getState().portfolioSummary
                dispatch(replaceTableData([brand, brandData]))
                if (!state.filters.brandList.includes(newBrand)) {
                    let newBrands = [...state.filters.brandList]
                    newBrands.push(newBrand)
                    dispatch(setFilters({
                        ...state.filters,
                        brandList: newBrands
                    }))
                }
            }
        }

        dispatch(setLoading(false))
    }).catch((err) => {

        openNotificationWithIcon('error', 'Brand could not be added')
        dispatch(setLoading(false))
    })
}

export const getChannelRetailerData = (newChannel, newRetailer, filters, brand = -1, isReport = false) => async (dispatch, getState) => {
    let state = getState().portfolioSummary;
    if (state.filters.compare_via[0] === compareVia.brand && !isReport) return;
    let defaultBrand = getState().profile.defaultBrand
    dispatch(setLoading(true))
    let tempFilters = { ...filters }
    if (tempFilters.product_pet && tempFilters.product_pet.length === 0) {
        tempFilters['product_pet'] = 'ALL'
    }
    if (tempFilters.product_technology && tempFilters.product_technology.length === 0) {
        tempFilters['product_technology'] = 'ALL'
    }
    if (tempFilters.product_sub_technology && tempFilters.product_sub_technology.length === 0) {
        tempFilters['product_sub_technology'] = 'ALL'
    }
    if (tempFilters.product_lifestage && tempFilters.product_lifestage.length === 0) {
        tempFilters['product_lifestage'] = 'ALL'
    }
    if (
        tempFilters.product_package &&
        tempFilters.product_technology.length === 1 &&
        isPackSize.includes(tempFilters.product_technology[0]) &&
        tempFilters.product_package.length === 0
    ) {
        tempFilters['product_package'] = 'ALL'
    }
    if (tempFilters.time_year && tempFilters.time_year.length === 0) {
        tempFilters['time_year'] = 'ALL'
    }
    if (tempFilters.time_period && tempFilters.time_period.length === 0) {
        tempFilters['time_period'] = 'ALL'
    }
    if (defaultBrand) {
        if (isSubBrand(defaultBrand)) {
            tempFilters['product_brand'] = defaultBrand.split(":")[0]
            tempFilters['product_secondary_brand'] = defaultBrand.split(":")[1]
            tempFilters['primary_brand_indicator'] = 0
        }
        else {
            tempFilters['product_brand'] = defaultBrand
            tempFilters['product_secondary_brand'] = ""
            tempFilters['primary_brand_indicator'] = 1
        }
    }
    tempFilters['customer_channel'] = [newChannel];
    tempFilters['customer_retailer_details'] = [newRetailer];
    const isChannel = isReport ? (newRetailer ? false : true) : state.filters.compare_via[0] === compareVia.channel;
    if (isChannel) delete tempFilters.customer_retailer_details;

    // let tableData = [...state.table_data];
    let encodedURL = encodeURIComponent(JSON.stringify(tempFilters))
    CustomAxios(ENDPOINTS.portfolioSummaryChannel + '?' + encodedURL, 'GET').then((response) => {
        if (response.data) {
            let channelRetailerData = {
                cards: Object.keys(response.data).map((brand) => ({ ...response.data[brand], brand })).slice(0, 7),
                channel: newChannel,
                retailer: newRetailer,
                edit: 0,
            }

            // dispatch(setChannelData(channelData))

            if (brand === -1) {
                if (isChannel) {
                    dispatch(addChannelData(channelRetailerData))
                    dispatch(sortChannelData())
                } else {
                    dispatch(addRetailerData(channelRetailerData))
                    dispatch(sortRetailerData())
                }
            }
            else {
                if (isChannel) {
                    dispatch(replaceChannelData([brand, channelRetailerData]))
                    // TODO: update retailer list.
                    if (!state.filters.channelList.includes(newChannel) || isReport) {
                        let newChannels = [...state.filters.channelList]
                        newChannels.push(newChannel)
                        dispatch(setFilters({
                            ...state.filters,
                            channelList: newChannels
                        }))
                    }
                } else {
                    dispatch(replaceRetailerData([brand, channelRetailerData]))
                    if (!state.filters.retailerList.includes(newRetailer) || isReport) {
                        let newRetailers = [...state.filters.retailerList]
                        newRetailers.push(newRetailer)
                        dispatch(setFilters({
                            ...state.filters,
                            retailerList: newRetailers
                        }))
                    }
                }
            }
        }


        dispatch(setLoading(false))
    }).catch((err) => {

        openNotificationWithIcon('error', 'Competitor could not be added')
        dispatch(setLoading(false))
    })

}



export default portfolioSummarySlicer.reducer