import React, { useEffect, useState } from "react";
import { Form, Input, Modal, Avatar, Radio, Space, Select, Button } from "antd";
import { UserOutlined, TeamOutlined, CaretDownOutlined, ArrowLeftOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { saveReport } from "../../slicers/performanceSummary.slicer";
import { saveVSReport } from "../../slicers/volumeShiftingSummary.slicer";
import { saveDSReport } from "../../slicers/distributionSummary.slicer";
import { getExistingReports } from "../../slicers/profile.slicer";
import { saveITReport } from "../../slicers/innovation-tracker.slicer";

const { Option } = Select;

export default function NewReportForm(props) {
    const dispatch = useDispatch();
    const [saveLoading, setSaveLoading] = useState(false);
    const [accessSelection, setAccessSelection] = useState(false);
    const [accessValue, setAccessValue] = useState('T');
    const [newReportName, setNewReportName] = useState(null);
    const [addToReport, setAddToReport] = useState("");
    const { user, existingReports } = useSelector((state) => state.profile)
    const { setNewReport, createReport, newReportComponents, newReportBrands, newReportChannel, newReportChannels, newReportRetailers, defaultReportBrands, defaultReportChannels, defaultReportRetailers, setNewReportComponents, setNewReportBrands, setNewReportChannels, setNewReportRetailers, openSaveReportDialog, setOpenSaveReportDialog } = props;
    const [form] = Form.useForm();

    const closeSaveReportDialog = () => {
        setOpenSaveReportDialog(false)
    }

    const onSaveClick = () => {
        console.log("Report Name: ", newReportName)
        form.validateFields()
            .then(values => {
                console.log("Success:", values);
                if (props.reportType === "performance-summary") {
                    dispatch(saveReport(newReportName, accessValue, createReport ? "" : addToReport, newReportComponents, createReport, (i) => setNewReportComponents(i), (i) => setNewReport(i), (i) => setOpenSaveReportDialog(i), (i) => setSaveLoading(i)))
                }
                else if (props.reportType === "competition-summary") {
                    dispatch(saveVSReport(newReportName, accessValue, createReport ? "" : addToReport, newReportComponents.includes('1'), newReportBrands, newReportChannel, newReportChannels, newReportRetailers, defaultReportBrands, defaultReportChannels, defaultReportRetailers, createReport, (i) => setNewReportComponents(i), (i) => setNewReportBrands(i), (i) => setNewReportChannels(i), (i) => setNewReportRetailers(i), (i) => setNewReport(i), (i) => setOpenSaveReportDialog(i), (i) => setSaveLoading(i)))
                }
                else if (props.reportType === "distribution-summary") {
                    dispatch(saveDSReport(props.filters, accessValue, createReport ? "" : addToReport, newReportName, newReportComponents, createReport, (i) => setNewReportComponents(i), (i) => setNewReport(i), (i) => setOpenSaveReportDialog(i), (i) => setSaveLoading(i)))
                }
                else if (props.reportType === "innovation-tracker") {
                    dispatch(saveITReport(props.filters, accessValue, createReport ? "" : addToReport, newReportName, newReportComponents, createReport, (i) => setNewReportComponents(i), (i) => setNewReport(i), (i) => setOpenSaveReportDialog(i), (i) => setSaveLoading(i)))
                }
            })
            .catch(errorInfo => {
                console.log('Failed:', errorInfo);
            });
    }

    useEffect(() => {
        if (createReport === false) {
            dispatch(getExistingReports(props.reportType));
        }
    }, [createReport])

    return (
        <Modal
            title={accessSelection ? <ArrowLeftOutlined onClick={() => { setAccessSelection(false); }} /> : createReport ? "Create New Report" : "Add to Existing Report"}
            visible={openSaveReportDialog}
            onOk={onSaveClick}
            confirmLoading={saveLoading}
            okText={createReport ? "Create Report" : "Add to Report"}
            onCancel={closeSaveReportDialog}
            footer={accessSelection ? false : [
                <Button key="back" onClick={closeSaveReportDialog}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={saveLoading} onClick={onSaveClick}>
                    {createReport ? "Create Report" : "Add to Report"}
                </Button>]}
        >
            <div className="">
                {createReport && !accessSelection && <div className="user-info-wrap">
                    <Avatar size={28} icon={<UserOutlined />} style={{ color: '#525252' }} />
                    <div className="user-info">
                        <h4>{user.user_name}</h4>
                        <button className="audience" onClick={() => setAccessSelection(true)}>{accessValue === 'P' ? <UsergroupAddOutlined /> : accessValue === 'T' ? <TeamOutlined /> : <UserOutlined />}<span className="audience-name">{accessValue === 'P' ? <>Public</> : accessValue === 'T' ? <>Team Members</> : <>Only me</>}</span> <CaretDownOutlined style={{ fontSize: 10 }} /></button>
                    </div>
                </div>}
                {(createReport && !accessSelection) && <Form form={form} name="basic" layout="vertical">
                    <Form.Item
                        name={"reportName"}
                        label="Name of the report"
                        rules={[
                            {
                                required: true,
                                message: 'Report name cannot be empty!',
                            }]}>
                        <Input placeholder="Type the name here" value={newReportName} onChange={(e) => setNewReportName(e.target.value)} onPressEnter={onSaveClick} />
                    </Form.Item>
                </Form>}
                {(!createReport && !accessSelection) && <Form layout="vertical">
                    <Form.Item label="Select existing report to modify" rules={[{ required: true }]}>
                        <Select
                            placeholder="Select a report"
                            showSearch={true}
                            optionFilterProp="children"
                            style={{ width: 200 }}
                            onChange={(value) => setAddToReport(value)}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {existingReports.map(report => (<Option value={report.report_id}>{report.report_name}</Option>))}
                        </Select>
                    </Form.Item>
                </Form>}
            </div>
            {accessSelection && <div className="">
                <p><b>Choose who can view your report</b></p>
                <Radio.Group value={accessValue} onChange={(e) => { setAccessValue(e.target.value) }}>
                    <Space direction="vertical">
                        <Radio value={'P'}>Anyone on this tool</Radio>
                        <Radio value={'T'}>Team members only</Radio>
                        <Radio value={'M'}>Only myself</Radio>
                    </Space>
                </Radio.Group>
            </div>}
        </Modal>
    )
}
