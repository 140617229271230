import React from "react";
import DateTime from "../date-time";
import decreasePNG from "../../../src/assets/images/decrease.png";
import increasePNG from "../../../src/assets/images/increase.png";
import './styles.scss';

export default function NudgesList(props) {

    return (
        <div className={"nudges-list" + (props.selected ? ' selected' : '')} onClick={props.onClick}>
            <div className="card-symbol">
                <img src={props.nudgeTrend ? increasePNG : decreasePNG} style={{ width: "20px" }} />
            </div>
            <h4>{props.title}</h4>
        </div>
    );
}