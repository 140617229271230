import React from "react";
import { CaretUpFilled, CaretDownFilled } from "@ant-design/icons";
import { formatNumberExcel, numFmt } from "../../utilities/exports/excel";
import { formatNumber, valueTypes } from "../../utilities/helper";

// UI
const renderFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.change).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (isZero) return "NA";
  else
    return (
      <>
        <div>{formatNumber(val.abs)}</div>
        {Number(val.change) >= 0 ? (
          <div>
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.change)}
          </div>
        ) : (
          <div>
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.change)}
          </div>
        )}
      </>
    );
};

const renderNumberPercentFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.change).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (isZero) return "NA";
  else
    return (
      <>
        <div>{formatNumber(val.abs)}</div>
        {Number(val.change) >= 0 ? (
          <div>
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.change)}%
          </div>
        ) : (
          <div>
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.change)}%
          </div>
        )}
      </>
    );
};

const renderPercentPointsFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.change).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (isZero) return "NA";
  else
    return (
      <>
        <div>{formatNumber(val.abs)}%</div>
        {Number(val.change) >= 0 ? (
          <div>
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.change)}bp
          </div>
        ) : (
          <div>
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.change)}bp
          </div>
        )}
      </>
    );
};

const renderDollarFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.change).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (isZero) return "NA";
  else
    return (
      <>
        <div>$ {formatNumber(val.abs)}</div>
        {Number(val.change.toFixed(1)) >= 0 ? (
          <div>
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.change)}
          </div>
        ) : (
          <div>
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.change)}
          </div>
        )}
      </>
    );
};

const renderDollarPercentFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.change).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (isZero) return "NA";
  else
    return (
      <>
        <div>$ {formatNumber(val.abs)}</div>
        {Number(val.change.toFixed(1)) >= 0 ? (
          <div>
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.change)}%
          </div>
        ) : (
          <div>
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.change)}%
          </div>
        )}
      </>
    );
};

const renderPercentageFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.change).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (isZero) return "NA";
  else
    return (
      <>
        <div>{formatNumber(val.abs)}%</div>
        {Number(val.change.toFixed(1)) >= 0 ? (
          <div>
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.change)}
          </div>
        ) : (
          <div>
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.change)}
          </div>
        )}
      </>
    );
};

const renderPercentagePointsFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.change).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (isZero) return "NA";
  else
    return (
      <>
        <div>{formatNumber(val.abs)}%</div>
        {Number(val.change.toFixed(1)) >= 0 ? (
          <div>
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.change)}bp
          </div>
        ) : (
          <div>
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.change)}bp
          </div>
        )}
      </>
    );
};

// Excel
const renderTextDollarFunction = (val, rec) => ({
  value: formatNumberExcel(val.abs),
  numFmt: numFmt.dollar,
});

const renderTextAbsPercentageFunction = (val, rec) => ({
  value: formatNumberExcel(val.abs, true),
  numFmt: numFmt.percentage,
});

const renderTextChangePercentageFunction = (val, rec) => ({
  value: formatNumberExcel(val.change, true),
  numFmt: numFmt.percentage,
});

const renderTextAbsNumberFunction = (val, rec) => ({
  value: formatNumberExcel(val.abs),
  numFmt: numFmt.number,
});

const renderTextChangeNumberFunction = (val, rec) => ({
  value: formatNumberExcel(val.change),
  numFmt: numFmt.number,
});

const renderTextCYAFunction = (val, rec) => ({
  value: formatNumberExcel(val.change),
  numFmt: numFmt.number,
});

// Excel export
const psColsExport = [
  {
    title: "Customer",
    dataIndex: "customer_channel",
    key: "customer",
    width: "15%",
  },
  {
    title: "MKT RSV L4W",
    dataIndex: "dollar_sum_latest_4w",
    key: "dollar_sum_4w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV L13W",
    dataIndex: "dollar_sum_latest_13w",
    key: "dollar_sum_13w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV L52W",
    dataIndex: "dollar_sum_latest_52w",
    key: "dollar_sum_52w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV YTD",
    dataIndex: "dollar_sum_latest_ytd",
    key: "dollar_sum_latest_ytd",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV CYA L4W",
    dataIndex: "dollar_sum_latest_4w",
    key: "dollar_sum_4w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "MKT RSV CYA L13W",
    dataIndex: "dollar_sum_latest_13w",
    key: "dollar_sum_13w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "MKT RSV CYA L52W",
    dataIndex: "dollar_sum_latest_52w",
    key: "dollar_sum_52w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "MKT RSV CYA YTD",
    dataIndex: "dollar_sum_latest_ytd",
    key: "dollar_sum_latest_ytd",
    render: renderDollarFunction,
    renderText: renderTextChangeNumberFunction,
  },
  {
    title: "$ Share L4W",
    dataIndex: "dollar_share_latest_4w",
    key: "dollar_share_4w",
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "$ Share L13W",
    dataIndex: "dollar_share_latest_13w",
    key: "dollar_share_13w",
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "$ Share L52W",
    dataIndex: "dollar_share_latest_52w",
    key: "dollar_share_52w",
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "$ Share YTD",
    dataIndex: "dollar_share_latest_ytd",
    key: "dollar_share_ytd",
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "$ Share CYA L4W",
    dataIndex: "dollar_share_latest_4w",
    key: "dollar_share_4w",
    renderText: renderTextCYAFunction,
  },
  {
    title: "$ Share CYA L13W",
    dataIndex: "dollar_share_latest_13w",
    key: "dollar_share_13w",
    renderText: renderTextCYAFunction,
  },
  {
    title: "$ Share CYA L52W",
    dataIndex: "dollar_share_latest_52w",
    key: "dollar_share_52w",
    renderText: renderTextCYAFunction,
  },
  {
    title: "$ Share CYA YTD",
    dataIndex: "dollar_share_latest_ytd",
    key: "dollar_share_ytd",
    renderText: renderTextCYAFunction,
  },
  {
    title: "TDP L4W",
    dataIndex: "tdp_latest_4w",
    key: "tdp_latest_4w",
    renderText: renderTextAbsNumberFunction,
  },
  {
    title: "TDP L13W",
    dataIndex: "tdp_latest_13w",
    key: "tdp_latest_13w",
    renderText: renderTextAbsNumberFunction,
  },
  {
    title: "TDP L52W",
    dataIndex: "tdp_latest_52w",
    key: "tdp_latest_52w",
    renderText: renderTextAbsNumberFunction,
  },
  {
    title: "TDP YTD",
    dataIndex: "tdp_latest_ytd",
    key: "tdp_latest_ytd",
    renderText: renderTextAbsNumberFunction,
  },
  {
    title: "TDP CYA L4W",
    dataIndex: "tdp_latest_4w",
    key: "tdp_latest_4w",
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "TDP CYA L13W",
    dataIndex: "tdp_latest_13w",
    key: "tdp_latest_13w",
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "TDP CYA L52W",
    dataIndex: "tdp_latest_52w",
    key: "tdp_latest_52w",
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "TDP CYA YTD",
    dataIndex: "tdp_latest_ytd",
    key: "tdp_latest_ytd",
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "$/TDP L4W",
    dataIndex: "acv_latest_4w",
    key: "acv_latest_4w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "$/TDP L13W",
    dataIndex: "acv_latest_13w",
    key: "acv_latest_13w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "$/TDP L52W",
    dataIndex: "acv_latest_52w",
    key: "acv_latest_52w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "$/TDP YTD",
    dataIndex: "acv_latest_ytd",
    key: "acv_latest_ytd",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "$/TDP CYA L4W",
    dataIndex: "acv_latest_4w",
    key: "acv_latest_4w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "$/TDP CYA L13W",
    dataIndex: "acv_latest_13w",
    key: "acv_latest_13w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "$/TDP CYA L52W",
    dataIndex: "acv_latest_52w",
    key: "acv_latest_52w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "$/TDP CYA YTD",
    dataIndex: "acv_latest_ytd",
    key: "acv_latest_ytd",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
];
const psColsCSLExport = [
  {
    title: "Customer",
    dataIndex: "customer_channel",
    key: "customer",
    width: "15%",
  },
  {
    title: "CSL",
    dataIndex: "csl_sum_latest_4w",
    key: "csl_sum_latest_4w",
    renderText: (val, rec) => ({
      value: formatNumberExcel(val, true),
      numFmt: numFmt.percentage,
    }),
  },
  {
    title: "MKT RSV L4W",
    dataIndex: "dollar_sum_latest_4w",
    key: "dollar_sum_4w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV L13W",
    dataIndex: "dollar_sum_latest_13w",
    key: "dollar_sum_13w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV L52W",
    dataIndex: "dollar_sum_latest_52w",
    key: "dollar_sum_52w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV YTD",
    dataIndex: "dollar_sum_latest_ytd",
    key: "dollar_sum_latest_ytd",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV CYA L4W",
    dataIndex: "dollar_sum_latest_4w",
    key: "dollar_sum_4w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "MKT RSV CYA L13W",
    dataIndex: "dollar_sum_latest_13w",
    key: "dollar_sum_13w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "MKT RSV CYA L52W",
    dataIndex: "dollar_sum_latest_52w",
    key: "dollar_sum_52w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "MKT RSV CYA YTD",
    dataIndex: "dollar_sum_latest_ytd",
    key: "dollar_sum_latest_ytd",
    render: renderDollarFunction,
    renderText: renderTextChangeNumberFunction,
  },
  {
    title: "$ Share L4W",
    dataIndex: "dollar_share_latest_4w",
    key: "dollar_share_4w",
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "$ Share L13W",
    dataIndex: "dollar_share_latest_13w",
    key: "dollar_share_13w",
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "$ Share L52W",
    dataIndex: "dollar_share_latest_52w",
    key: "dollar_share_52w",
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "$ Share YTD",
    dataIndex: "dollar_share_latest_ytd",
    key: "dollar_share_ytd",
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "$ Share CYA L4W",
    dataIndex: "dollar_share_latest_4w",
    key: "dollar_share_4w",
    renderText: renderTextCYAFunction,
  },
  {
    title: "$ Share CYA L13W",
    dataIndex: "dollar_share_latest_13w",
    key: "dollar_share_13w",
    renderText: renderTextCYAFunction,
  },
  {
    title: "$ Share CYA L52W",
    dataIndex: "dollar_share_latest_52w",
    key: "dollar_share_52w",
    renderText: renderTextCYAFunction,
  },
  {
    title: "$ Share CYA YTD",
    dataIndex: "dollar_share_latest_ytd",
    key: "dollar_share_ytd",
    renderText: renderTextCYAFunction,
  },
  {
    title: "TDP L4W",
    dataIndex: "tdp_latest_4w",
    key: "tdp_latest_4w",
    renderText: renderTextAbsNumberFunction,
  },
  {
    title: "TDP L13W",
    dataIndex: "tdp_latest_13w",
    key: "tdp_latest_13w",
    renderText: renderTextAbsNumberFunction,
  },
  {
    title: "TDP L52W",
    dataIndex: "tdp_latest_52w",
    key: "tdp_latest_52w",
    renderText: renderTextAbsNumberFunction,
  },
  {
    title: "TDP YTD",
    dataIndex: "tdp_latest_ytd",
    key: "tdp_latest_ytd",
    renderText: renderTextAbsNumberFunction,
  },
  {
    title: "TDP CYA L4W",
    dataIndex: "tdp_latest_4w",
    key: "tdp_latest_4w",
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "TDP CYA L13W",
    dataIndex: "tdp_latest_13w",
    key: "tdp_latest_13w",
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "TDP CYA L52W",
    dataIndex: "tdp_latest_52w",
    key: "tdp_latest_52w",
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "TDP CYA YTD",
    dataIndex: "tdp_latest_ytd",
    key: "tdp_latest_ytd",
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "$/TDP L4W",
    dataIndex: "acv_latest_4w",
    key: "acv_latest_4w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "$/TDP L13W",
    dataIndex: "acv_latest_13w",
    key: "acv_latest_13w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "$/TDP L52W",
    dataIndex: "acv_latest_52w",
    key: "acv_latest_52w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "$/TDP YTD",
    dataIndex: "acv_latest_ytd",
    key: "acv_latest_ytd",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "$/TDP CYA L4W",
    dataIndex: "acv_latest_4w",
    key: "acv_latest_4w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "$/TDP CYA L13W",
    dataIndex: "acv_latest_13w",
    key: "acv_latest_13w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "$/TDP CYA L52W",
    dataIndex: "acv_latest_52w",
    key: "acv_latest_52w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "$/TDP CYA YTD",
    dataIndex: "acv_latest_ytd",
    key: "acv_latest_ytd",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
];
const psColsFinancialsExport = [
  {
    title: "Customer",
    dataIndex: "customer_channel",
    key: "customer",
    width: "15%",
  },
  {
    title: "MKT RSV L4W",
    dataIndex: "dollar_sum_latest_4w",
    key: "dollar_sum_4w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV L13W",
    dataIndex: "dollar_sum_latest_13w",
    key: "dollar_sum_13w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV L52W",
    dataIndex: "dollar_sum_latest_52w",
    key: "dollar_sum_52w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV YTD",
    dataIndex: "dollar_sum_latest_ytd",
    key: "dollar_sum_latest_ytd",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV CYA L4W",
    dataIndex: "dollar_sum_latest_4w",
    key: "dollar_sum_4w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "MKT RSV CYA L13W",
    dataIndex: "dollar_sum_latest_13w",
    key: "dollar_sum_13w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "MKT RSV CYA L52W",
    dataIndex: "dollar_sum_latest_52w",
    key: "dollar_sum_52w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "MKT RSV CYA YTD",
    dataIndex: "dollar_sum_latest_ytd",
    key: "dollar_sum_latest_ytd",
    render: renderDollarFunction,
    renderText: renderTextChangeNumberFunction,
  },
  {
    title: "GSV L4W",
    dataIndex: "gsv_latest_4w",
    key: "gsv_latest_4w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "GSV L13W",
    dataIndex: "gsv_latest_13w",
    key: "gsv_latest_13w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "GSV L52W",
    dataIndex: "gsv_latest_52w",
    key: "gsv_latest_52w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "GSV YTD",
    dataIndex: "gsv_latest_ytd",
    key: "gsv_latest_ytd",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "GSV CYA L4W",
    dataIndex: "gsv_latest_4w",
    key: "gsv_latest_4w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "GSV CYA L13W",
    dataIndex: "gsv_latest_13w",
    key: "gsv_latest_13w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "GSV CYA L52W",
    dataIndex: "gsv_latest_52w",
    key: "gsv_latest_52w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "GSV CYA YTD",
    dataIndex: "gsv_latest_ytd",
    key: "gsv_latest_ytd",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "NSV L4W",
    dataIndex: "nsv_latest_4w",
    key: "nsv_latest_4w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "NSV L13W",
    dataIndex: "nsv_latest_13w",
    key: "nsv_latest_13w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "NSV L52W",
    dataIndex: "nsv_latest_52w",
    key: "nsv_latest_52w",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "NSV YTD",
    dataIndex: "nsv_latest_ytd",
    key: "nsv_latest_ytd",
    render: renderDollarFunction,
    renderText: renderTextDollarFunction,
  },
  {
    title: "NSV CYA L4W",
    dataIndex: "nsv_latest_4w",
    key: "nsv_latest_4w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "NSV CYA L13W",
    dataIndex: "nsv_latest_13w",
    key: "nsv_latest_13w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "NSV CYA L52W",
    dataIndex: "nsv_latest_52w",
    key: "nsv_latest_52w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "NSV CYA YTD",
    dataIndex: "nsv_latest_ytd",
    key: "nsv_latest_ytd",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "MAC% L4W",
    dataIndex: "mac_latest_4w",
    key: "mac_latest_4w",
    render: renderPercentageFunction,
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "MAC% L13W",
    dataIndex: "mac_latest_13w",
    key: "mac_latest_13w",
    render: renderPercentageFunction,
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "MAC% L52W",
    dataIndex: "mac_latest_52w",
    key: "mac_latest_52w",
    render: renderPercentageFunction,
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "MAC% YTD",
    dataIndex: "mac_latest_ytd",
    key: "mac_latest_ytd",
    render: renderPercentageFunction,
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "MAC% CYA L4W",
    dataIndex: "mac_latest_4w",
    key: "mac_latest_4w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "MAC% CYA L13W",
    dataIndex: "mac_latest_13w",
    key: "mac_latest_13w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "MAC% CYA L52W",
    dataIndex: "mac_latest_52w",
    key: "mac_latest_52w",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
  {
    title: "MAC% CYA YTD",
    dataIndex: "mac_latest_ytd",
    key: "mac_latest_ytd",
    render: renderDollarFunction,
    renderText: renderTextChangePercentageFunction,
  },
];

// PPT Export
const pCols = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "customer_channel",
        key: "customer",
        width: "15%",
      },
    ],
  },
  {
    title: "CSL",
    children: [
      {
        title: "",
        dataIndex: "csl_sum_latest_4w",
        key: "csl_sum_latest_4w",
        render: (val, rec) => <div>{formatNumber(val, 1)}</div>,
      },
    ],
  },
  {
    title: "MKT RSV",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_sum_latest_4w",
        key: "dollar_sum_4w",
        render: renderFunction,
        renderText: renderDollarFunction,
      },
      {
        title: "L13W",
        dataIndex: "dollar_sum_latest_13w",
        key: "dollar_sum_13w",
        render: renderFunction,
        renderText: renderDollarFunction,
      },
      {
        title: "L52W",
        dataIndex: "dollar_sum_latest_52w",
        key: "dollar_sum_52w",
        render: renderFunction,
        renderText: renderDollarFunction,
      },
      {
        title: "YTD",
        dataIndex: "dollar_sum_latest_ytd",
        key: "dollar_sum_latest_ytd",
        render: renderFunction,
        renderText: renderDollarFunction,
      },
    ],
  },
  {
    title: "$ Share",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_share_latest_4w",
        key: "dollar_share_4w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.change) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.change)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.change)}
              </div>
            )}
          </>
        ),
        renderText: renderDollarFunction,
      },
      {
        title: "L13W",
        dataIndex: "dollar_share_latest_13w",
        key: "dollar_share_13w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.change) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.change)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.change)}
              </div>
            )}
          </>
        ),
        renderText: renderDollarFunction,
      },
      {
        title: "L52W",
        dataIndex: "dollar_share_latest_52w",
        key: "dollar_share_52w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.change) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.change)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.change)}
              </div>
            )}
          </>
        ),
        renderText: renderDollarFunction,
      },
      {
        title: "YTD",
        dataIndex: "dollar_share_latest_ytd",
        key: "dollar_share_ytd",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.change) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.change)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.change)}
              </div>
            )}
          </>
        ),
        renderText: renderDollarFunction,
      },
    ],
  },
  {
    title: "TDP",
    children: [
      {
        title: "L4W",
        dataIndex: "tdp_latest_4w",
        key: "tdp_latest_4w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.change) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.change)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.change)}
              </div>
            )}
          </>
        ),
        renderText: renderDollarFunction,
      },
      {
        title: "L13W",
        dataIndex: "tdp_latest_13w",
        key: "tdp_latest_13w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.change) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.change)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.change)}
              </div>
            )}
          </>
        ),
        renderText: renderDollarFunction,
      },
      {
        title: "L52W",
        dataIndex: "tdp_latest_52w",
        key: "tdp_latest_52w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.change) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.change)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.change)}
              </div>
            )}
          </>
        ),
        renderText: renderDollarFunction,
      },
      {
        title: "YTD",
        dataIndex: "tdp_latest_ytd",
        key: "tdp_latest_ytd",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.change) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.change)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.change)}
              </div>
            )}
          </>
        ),
        renderText: renderDollarFunction,
      },
    ],
  },
  {
    title: "$/TDP",
    children: [
      {
        title: "L4W",
        dataIndex: "acv_latest_4w",
        key: "acv_latest_4w",
        render: renderFunction,
        renderText: renderDollarFunction,
      },
      {
        title: "L13W",
        dataIndex: "acv_latest_13w",
        key: "acv_latest_13w",
        render: renderFunction,
        renderText: renderDollarFunction,
      },
      {
        title: "L52W",
        dataIndex: "acv_latest_52w",
        key: "acv_latest_52w",
        render: renderFunction,
        renderText: renderDollarFunction,
      },
      {
        title: "YTD",
        dataIndex: "acv_latest_ytd",
        key: "acv_latest_ytd",
        render: renderFunction,
        renderText: renderDollarFunction,
      },
    ],
  },
];
const psColsRsv = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "customer_channel",
        key: "customer",
        // width: '15%'
      },
    ],
  },
  {
    title: "CSL",
    children: [
      {
        title: "",
        dataIndex: "csl_sum_latest_4w",
        key: "csl_sum_latest_4w",
        render: (val, rec) =>
          formatNumber(val, 2, true) === valueTypes.NA
            ? valueTypes.NA
            : formatNumber(val, 1),
        width: "6%",
        className: "td-border-left",
      },
    ],
  },
  {
    title: "MKT RSV",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_sum_latest_4w",
        key: "dollar_sum_4w",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "dollar_sum_latest_13w",
        key: "dollar_sum_13w",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "dollar_sum_latest_52w",
        key: "dollar_sum_52w",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "dollar_sum_latest_ytd",
        key: "dollar_sum_latest_ytd",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
    ],
  },
  {
    title: "MKT RSV Growth",
    children: [
      {
        title: "MKT RSV CYA L4W",
        dataIndex: "dollar_sum_latest_4w",
        key: "dollar_sum_4w",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "MKT RSV CYA L13W",
        dataIndex: "dollar_sum_latest_13w",
        key: "dollar_sum_13w",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "MKT RSV CYA L52W",
        dataIndex: "dollar_sum_latest_52w",
        key: "dollar_sum_52w",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "MKT RSV CYA YTD",
        dataIndex: "dollar_sum_latest_ytd",
        key: "dollar_sum_latest_ytd",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change),
          numFmt: numFmt.number,
        }),
      },
    ],
  },
];
const psColsShare = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "customer_channel",
        key: "customer",
        // width: '15%'
      },
    ],
  },
  {
    title: "$ Share",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_share_latest_4w",
        key: "dollar_share_4w",
        render: renderFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "dollar_share_latest_13w",
        key: "dollar_share_13w",
        render: renderFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "dollar_share_latest_52w",
        key: "dollar_share_52w",
        render: renderFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "dollar_share_latest_ytd",
        key: "dollar_share_ytd",
        render: renderFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
    ],
  },
  {
    title: "$ Share Growth",
    children: [
      {
        title: "$ Share CYA L4W",
        dataIndex: "dollar_share_latest_4w",
        key: "dollar_share_4w",
        renderText: renderTextCYAFunction,
      },
      {
        title: "$ Share CYA L13W",
        dataIndex: "dollar_share_latest_13w",
        key: "dollar_share_13w",
        renderText: renderTextCYAFunction,
      },
      {
        title: "$ Share CYA L52W",
        dataIndex: "dollar_share_latest_52w",
        key: "dollar_share_52w",
        renderText: renderTextCYAFunction,
      },
      {
        title: "$ Share CYA YTD",
        dataIndex: "dollar_share_latest_52w",
        key: "dollar_share_52w",
        renderText: renderTextCYAFunction,
      },
    ],
  },
];
const psColsTdp = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "customer_channel",
        key: "customer",
        // width: '15%'
      },
    ],
  },
  {
    title: "TDP",
    children: [
      {
        title: "L4W",
        dataIndex: "tdp_latest_4w",
        key: "tdp_latest_4w",
        render: renderFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "tdp_latest_13w",
        key: "tdp_latest_13w",
        render: renderFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "tdp_latest_52w",
        key: "tdp_latest_52w",
        render: renderFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "tdp_latest_ytd",
        key: "tdp_latest_ytd",
        render: renderFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
    ],
  },
  {
    title: "TDP Growth",
    children: [
      {
        title: "TDP CYA L4W",
        dataIndex: "tdp_latest_4w",
        key: "tdp_latest_4w",
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "TDP CYA L13W",
        dataIndex: "tdp_latest_13w",
        key: "tdp_latest_13w",
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "TDP CYA L52W",
        dataIndex: "tdp_latest_52w",
        key: "tdp_latest_52w",
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "TDP CYA YTD",
        dataIndex: "tdp_latest_ytd",
        key: "tdp_latest_ytd",
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
    ],
  },
];
const psColsDollarTdp = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "customer_channel",
        key: "customer",
        // width: '15%'
      },
    ],
  },
  {
    title: "$/TDP",
    children: [
      {
        title: "L4W",
        dataIndex: "acv_latest_4w",
        key: "acv_latest_4w",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "acv_latest_13w",
        key: "acv_latest_13w",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "acv_latest_52w",
        key: "acv_latest_52w",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "acv_latest_ytd",
        key: "acv_latest_ytd",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
    ],
  },
  {
    title: "$/TDP Growth",
    children: [
      {
        title: "$/TDP CYA L4W",
        dataIndex: "acv_latest_4w",
        key: "acv_latest_4w",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "$/TDP CYA L13W",
        dataIndex: "acv_latest_13w",
        key: "acv_latest_13w",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "$/TDP CYA L52W",
        dataIndex: "acv_latest_52w",
        key: "acv_latest_52w",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "$/TDP CYA YTD",
        dataIndex: "acv_latest_ytd",
        key: "acv_latest_ytd",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
    ],
  },
];
const psColsFinancialRsv = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "customer_channel",
        key: "customer",
        // width: '15%'
      },
    ],
  },
  {
    title: "MKT RSV",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_sum_latest_4w",
        key: "dollar_sum_4w",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "dollar_sum_latest_13w",
        key: "dollar_sum_13w",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "dollar_sum_latest_52w",
        key: "dollar_sum_52w",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "dollar_sum_latest_ytd",
        key: "dollar_sum_latest_ytd",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
    ],
  },
  {
    title: "MKT RSV Growth",
    children: [
      {
        title: "MKT RSV CYA L4W",
        dataIndex: "dollar_sum_latest_4w",
        key: "dollar_sum_4w",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "MKT RSV CYA L13W",
        dataIndex: "dollar_sum_latest_13w",
        key: "dollar_sum_13w",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "MKT RSV CYA L52W",
        dataIndex: "dollar_sum_latest_52w",
        key: "dollar_sum_52w",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "MKT RSV CYA YTD",
        dataIndex: "dollar_sum_latest_ytd",
        key: "dollar_sum_latest_ytd",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change),
          numFmt: numFmt.number,
        }),
      },
    ],
  },
];
const psColsFinancialGsv = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "customer_channel",
        key: "customer",
        // width: '15%'
      },
    ],
  },
  {
    title: "GSV",
    children: [
      {
        title: "L4W",
        dataIndex: "gsv_latest_4w",
        key: "gsv_latest_4w",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "gsv_latest_13w",
        key: "gsv_latest_13w",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "gsv_latest_52w",
        key: "gsv_latest_52w",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "gsv_latest_ytd",
        key: "gsv_latest_ytd",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
    ],
  },
  {
    title: "GSV Growth",
    children: [
      {
        title: "GSV CYA L4W",
        dataIndex: "gsv_latest_4w",
        key: "gsv_latest_4w",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "GSV CYA L13W",
        dataIndex: "gsv_latest_13w",
        key: "gsv_latest_13w",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "GSV CYA L52W",
        dataIndex: "gsv_latest_52w",
        key: "gsv_latest_52w",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "GSV CYA YTD",
        dataIndex: "gsv_latest_ytd",
        key: "gsv_latest_ytd",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
    ],
  },
];
const psColsFinancialNSV = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "customer_channel",
        key: "customer",
        // width: '15%'
      },
    ],
  },
  {
    title: "NSV",
    children: [
      {
        title: "L4W",
        dataIndex: "nsv_latest_4w",
        key: "nsv_latest_4w",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "nsv_latest_13w",
        key: "nsv_latest_13w",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "nsv_latest_52w",
        key: "nsv_latest_52w",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "nsv_latest_ytd",
        key: "nsv_latest_ytd",
        render: renderDollarFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
    ],
  },
  {
    title: "NSV Growth",
    children: [
      {
        title: "NSV CYA L4W",
        dataIndex: "nsv_latest_4w",
        key: "nsv_latest_4w",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "NSV CYA L13W",
        dataIndex: "nsv_latest_13w",
        key: "nsv_latest_13w",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "NSV CYA L52W",
        dataIndex: "nsv_latest_52w",
        key: "nsv_latest_52w",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "NSV CYA YTD",
        dataIndex: "nsv_latest_ytd",
        key: "nsv_latest_ytd",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
    ],
  },
];
const psColsFinancialMAC = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "customer_channel",
        key: "customer",
        // width: '15%'
      },
    ],
  },
  {
    title: "MAC%",
    children: [
      {
        title: "L4W",
        dataIndex: "mac_latest_4w",
        key: "mac_latest_4w",
        render: renderPercentageFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "mac_latest_13w",
        key: "mac_latest_13w",
        render: renderPercentageFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "mac_latest_52w",
        key: "mac_latest_52w",
        render: renderPercentageFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "mac_latest_ytd",
        key: "mac_latest_ytd",
        render: renderPercentageFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
    ],
  },
  {
    title: "MAC% Growth",
    children: [
      {
        title: "MAC% CYA L4W",
        dataIndex: "mac_latest_4w",
        key: "mac_latest_4w",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "MAC% CYA L13W",
        dataIndex: "mac_latest_13w",
        key: "mac_latest_13w",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "MAC% CYA L52W",
        dataIndex: "mac_latest_52w",
        key: "mac_latest_52w",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
      {
        title: "MAC% CYA YTD",
        dataIndex: "mac_latest_ytd",
        key: "mac_latest_ytd",
        render: renderDollarFunction,
        renderText: (val, rec) => ({
          value: formatNumberExcel(val.change, true),
          numFmt: numFmt.percentage,
        }),
      },
    ],
  },
];

// UI
const psCols = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "customer_channel",
        key: "customer",
        width: '15%'
      },
    ],
  },
  {
    title: "MKT RSV",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_sum_latest_4w",
        key: "dollar_sum_4w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "dollar_sum_latest_13w",
        key: "dollar_sum_13w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "L52W",
        dataIndex: "dollar_sum_latest_52w",
        key: "dollar_sum_52w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "YTD",
        dataIndex: "dollar_sum_latest_ytd",
        key: "dollar_sum_latest_ytd",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
    ],
  },
  {
    title: "$ Share",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_share_latest_4w",
        key: "dollar_share_4w",
        render: renderPercentPointsFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "dollar_share_latest_13w",
        key: "dollar_share_13w",
        render: renderPercentPointsFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "L52W",
        dataIndex: "dollar_share_latest_52w",
        key: "dollar_share_52w",
        render: renderPercentPointsFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "YTD",
        dataIndex: "dollar_share_latest_ytd",
        key: "dollar_share_ytd",
        render: renderPercentPointsFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
    ],
  },
  {
    title: "TDP",
    children: [
      {
        title: "L4W",
        dataIndex: "tdp_latest_4w",
        key: "tdp_latest_4w",
        render: renderNumberPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "tdp_latest_13w",
        key: "tdp_latest_13w",
        render: renderNumberPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "L52W",
        dataIndex: "tdp_latest_52w",
        key: "tdp_latest_52w",
        render: renderNumberPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "YTD",
        dataIndex: "tdp_latest_ytd",
        key: "tdp_latest_ytd",
        render: renderNumberPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
    ],
  },
  {
    title: "$/TDP",
    children: [
      {
        title: "L4W",
        dataIndex: "acv_latest_4w",
        key: "acv_latest_4w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "acv_latest_13w",
        key: "acv_latest_13w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "L52W",
        dataIndex: "acv_latest_52w",
        key: "acv_latest_52w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "YTD",
        dataIndex: "acv_latest_ytd",
        key: "acv_latest_ytd",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
    ],
  },
];
const psColsNoShare = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "customer_channel",
        key: "customer",
        width: '15%'
      },
    ],
  },
  {
    title: "MKT RSV",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_sum_latest_4w",
        key: "dollar_sum_4w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "dollar_sum_latest_13w",
        key: "dollar_sum_13w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "L52W",
        dataIndex: "dollar_sum_latest_52w",
        key: "dollar_sum_52w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "YTD",
        dataIndex: "dollar_sum_latest_ytd",
        key: "dollar_sum_latest_ytd",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
    ],
  },
  {
    title: "TDP",
    children: [
      {
        title: "L4W",
        dataIndex: "tdp_latest_4w",
        key: "tdp_latest_4w",
        render: renderNumberPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "tdp_latest_13w",
        key: "tdp_latest_13w",
        render: renderNumberPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "L52W",
        dataIndex: "tdp_latest_52w",
        key: "tdp_latest_52w",
        render: renderNumberPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "YTD",
        dataIndex: "tdp_latest_ytd",
        key: "tdp_latest_ytd",
        render: renderNumberPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
    ],
  },
  {
    title: "$/TDP",
    children: [
      {
        title: "L4W",
        dataIndex: "acv_latest_4w",
        key: "acv_latest_4w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "acv_latest_13w",
        key: "acv_latest_13w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "L52W",
        dataIndex: "acv_latest_52w",
        key: "acv_latest_52w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "YTD",
        dataIndex: "acv_latest_ytd",
        key: "acv_latest_ytd",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
    ],
  },
];
const psColsCSL = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "customer_channel",
        key: "customer",
        // width: '15%'
      },
    ],
  },
  {
    title: "CSL",
    children: [
      {
        title: "",
        dataIndex: "csl_sum_latest_4w",
        key: "csl_sum_latest_4w",
        render: (val, rec) =>
          formatNumber(val, 2, true) === valueTypes.NA
            ? valueTypes.NA
            : `${formatNumber(val, 1)}%`,
        width: "4%",
        className: "td-border-left",
      },
    ],
  },
  {
    title: "MKT RSV",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_sum_latest_4w",
        key: "dollar_sum_4w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "dollar_sum_latest_13w",
        key: "dollar_sum_13w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "dollar_sum_latest_52w",
        key: "dollar_sum_52w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "dollar_sum_latest_ytd",
        key: "dollar_sum_latest_ytd",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
    ],
  },
  {
    title: "$ Share",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_share_latest_4w",
        key: "dollar_share_4w",
        render: renderPercentPointsFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "dollar_share_latest_13w",
        key: "dollar_share_13w",
        render: renderPercentPointsFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "L52W",
        dataIndex: "dollar_share_latest_52w",
        key: "dollar_share_52w",
        render: renderPercentPointsFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "YTD",
        dataIndex: "dollar_share_latest_ytd",
        key: "dollar_share_ytd",
        render: renderPercentPointsFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
    ],
  },
  {
    title: "TDP",
    children: [
      {
        title: "L4W",
        dataIndex: "tdp_latest_4w",
        key: "tdp_latest_4w",
        render: renderNumberPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "tdp_latest_13w",
        key: "tdp_latest_13w",
        render: renderNumberPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "L52W",
        dataIndex: "tdp_latest_52w",
        key: "tdp_latest_52w",
        render: renderNumberPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "YTD",
        dataIndex: "tdp_latest_ytd",
        key: "tdp_latest_ytd",
        render: renderNumberPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
    ],
  },
  {
    title: "$/TDP",
    children: [
      {
        title: "L4W",
        dataIndex: "acv_latest_4w",
        key: "acv_latest_4w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "acv_latest_13w",
        key: "acv_latest_13w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "L52W",
        dataIndex: "acv_latest_52w",
        key: "acv_latest_52w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "YTD",
        dataIndex: "acv_latest_ytd",
        key: "acv_latest_ytd",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
    ],
  },
];
const psColsCSLNoShare = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "customer_channel",
        key: "customer",
        // width: '15%'
      },
    ],
  },
  {
    title: "CSL",
    children: [
      {
        title: "",
        dataIndex: "csl_sum_latest_4w",
        key: "csl_sum_latest_4w",
        render: (val, rec) =>
          formatNumber(val, 2, true) === valueTypes.NA
            ? valueTypes.NA
            : `${formatNumber(val, 1)}%`,
        width: "4%",
        className: "td-border-left",
      },
    ],
  },
  {
    title: "MKT RSV",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_sum_latest_4w",
        key: "dollar_sum_4w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "dollar_sum_latest_13w",
        key: "dollar_sum_13w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "dollar_sum_latest_52w",
        key: "dollar_sum_52w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "dollar_sum_latest_ytd",
        key: "dollar_sum_latest_ytd",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "6%",
      },
    ],
  },
  {
    title: "TDP",
    children: [
      {
        title: "L4W",
        dataIndex: "tdp_latest_4w",
        key: "tdp_latest_4w",
        render: renderNumberPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "tdp_latest_13w",
        key: "tdp_latest_13w",
        render: renderNumberPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "L52W",
        dataIndex: "tdp_latest_52w",
        key: "tdp_latest_52w",
        render: renderNumberPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "YTD",
        dataIndex: "tdp_latest_ytd",
        key: "tdp_latest_ytd",
        render: renderNumberPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
    ],
  },
  {
    title: "$/TDP",
    children: [
      {
        title: "L4W",
        dataIndex: "acv_latest_4w",
        key: "acv_latest_4w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "acv_latest_13w",
        key: "acv_latest_13w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "L52W",
        dataIndex: "acv_latest_52w",
        key: "acv_latest_52w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
      {
        title: "YTD",
        dataIndex: "acv_latest_ytd",
        key: "acv_latest_ytd",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "4.5%",
      },
    ],
  },
];

const psColsFinancials = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "customer_channel",
        key: "customer",
        // width: '15%'
      },
    ],
  },
  {
    title: "MKT RSV",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_sum_latest_4w",
        key: "dollar_sum_4w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "5.25%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "dollar_sum_latest_13w",
        key: "dollar_sum_13w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "5.25%",
      },
      {
        title: "L52W",
        dataIndex: "dollar_sum_latest_52w",
        key: "dollar_sum_52w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "5.25%",
      },
      {
        title: "YTD",
        dataIndex: "dollar_sum_latest_ytd",
        key: "dollar_sum_latest_ytd",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "5.25%",
      },
    ],
  },
  {
    title: "GSV",
    children: [
      {
        title: "L4W",
        dataIndex: "gsv_latest_4w",
        key: "gsv_latest_4w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "5.25%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "gsv_latest_13w",
        key: "gsv_latest_13w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "5.25%",
      },
      {
        title: "L52W",
        dataIndex: "gsv_latest_52w",
        key: "gsv_latest_52w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "5.25%",
      },
      {
        title: "YTD",
        dataIndex: "gsv_latest_ytd",
        key: "gsv_latest_ytd",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "5.25%",
      },
    ],
  },
  {
    title: "NSV",
    children: [
      {
        title: "L4W",
        dataIndex: "nsv_latest_4w",
        key: "nsv_latest_4w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "5.25%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "nsv_latest_13w",
        key: "nsv_latest_13w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "5.25%",
      },
      {
        title: "L52W",
        dataIndex: "nsv_latest_52w",
        key: "nsv_latest_52w",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "5.25%",
      },
      {
        title: "YTD",
        dataIndex: "nsv_latest_ytd",
        key: "nsv_latest_ytd",
        render: renderDollarPercentFunction,
        renderText: renderTextDollarFunction,
        width: "5.25%",
      },
    ],
  },
  {
    title: "MAC%",
    children: [
      {
        title: "L4W",
        dataIndex: "mac_latest_4w",
        key: "mac_latest_4w",
        render: renderPercentagePointsFunction,
        renderText: renderTextAbsNumberFunction,
        width: "5.25%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "mac_latest_13w",
        key: "mac_latest_13w",
        render: renderPercentagePointsFunction,
        renderText: renderTextDollarFunction,
        width: "5.25%",
      },
      {
        title: "L52W",
        dataIndex: "mac_latest_52w",
        key: "mac_latest_52w",
        render: renderPercentagePointsFunction,
        renderText: renderTextDollarFunction,
        width: "5.25%",
      },
      {
        title: "YTD",
        dataIndex: "mac_latest_ytd",
        key: "mac_latest_ytd",
        render: renderPercentagePointsFunction,
        renderText: renderTextDollarFunction,
        width: "5.25%",
      },
    ],
  },
];

export default {
  pCols,
  psColsExport,
  psColsCSLExport,
  psColsFinancialsExport,
  psCols,
  psColsNoShare,
  psColsRsv,
  psColsShare,
  psColsTdp,
  psColsDollarTdp,
  psColsCSL,
  psColsCSLNoShare,
  psColsFinancials,
  psColsFinancialRsv,
  psColsFinancialGsv,
  psColsFinancialNSV,
  psColsFinancialMAC,
};
