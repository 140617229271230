import { createSlice } from '@reduxjs/toolkit';
import CustomAxios from '../utilities/services/api.service';
import { ENDPOINTS } from '../utilities/constants';
import { hasValue, isSubBrand, openNotificationWithIcon, reviewTypes } from '../utilities/helper'

const initialState = {
    alerts: {},
    loading: 0,
}

const reviewAlertsSlicer = createSlice({
    name: 'reviewAlerts',
    initialState,
    reducers: {
        setLoading: (state, action) => ({ ...state, loading: state.loading + (action.payload ? 1 : -1) }),
        setAlerts: (state, action) => ({
            ...state,
            alerts: action.payload,
        }),
    },
})

export const { setLoading, setAlerts } = reviewAlertsSlicer.actions;

export const getReviewAlerts = (status) => (dispatch, getState) => {
    let email_id = sessionStorage.getItem("email");
    let defaultBrand = getState().profile.defaultBrand;

    let currentString = {
        user_email: email_id,
        nudge_status: status,
        nudge_count: 6,
        nudge_count_view: 0,
        product_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[0] : defaultBrand,
        product_secondary_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[1] : "",
    }
    let encodedURL = encodeURIComponent(JSON.stringify(currentString))

    dispatch(setLoading(true))
    CustomAxios(ENDPOINTS.listNudges + '?' + encodedURL, 'GET').then((response) => {
        if (response.data.nudge_list) dispatch(setAlerts(response.data))
        else dispatch(setAlerts({}))
        dispatch(setLoading(false))
    }).catch((err) => {
        console.log("Review Alerts Get Data error occured", err)
        dispatch(setLoading(false))
    })
}

export const loadReviewAlerts = (status, load) => async (dispatch, getState) => {
    let state = getState().reviewAlerts;
    let email_id = sessionStorage.getItem("email");
    let defaultBrand = getState().profile.defaultBrand;
    let nudge_count_view = hasValue(state.alerts?.nudge_list.length) ? state.alerts?.nudge_list.length : 6

    let currentString = {
        user_email: email_id,
        nudge_status: status,
        nudge_count: load,
        nudge_count_view,
        product_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[0] : defaultBrand,
        product_secondary_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[1] : "",
    }
    let encodedURL = encodeURIComponent(JSON.stringify(currentString))

    dispatch(setLoading(true))
    CustomAxios(ENDPOINTS.listNudges + '?' + encodedURL, 'GET').then((response) => {
        if (response.data.nudge_list) {
            let existingNudges = [...state.alerts.nudge_list]
            existingNudges.push(...response.data.nudge_list)
            let nudges = {
                nudge_list: [...existingNudges],
                remaining_nudges: response.data.remaining_nudges
            }
            dispatch(setAlerts(nudges))
        }
        dispatch(setLoading(false))
    }).catch((err) => {
        console.log("ReviewAlerts Load error occured", err)
        dispatch(setLoading(false))
    })
}

export const updateInaccurateNudge = (nudge_id, status, comment) => (dispatch, getState) => {
    // let state = getState().bookmarks;
    let nudgesState = getState().reviewAlerts;
    let user_name = getState().profile.user.user_name;
    let email_id = sessionStorage.getItem("email");

    const nudge = nudgesState.alerts.nudge_list.filter(item => item.nudge_id === nudge_id)[0]
    const time_year = nudge.time_year
    const time_period = nudge.time_period
    const report = nudge.nudge_report


    if (nudge && time_period && time_year) {
        let body = {
            "user_email": email_id,
            "nudge_time_year": time_year,
            "nudge_time_period": time_period,
            "nudge_id": String(nudge_id),
            "review_status": status,
            "review_comment": comment,
            "nudge_report": report,
            "reviewed_by_user_name": user_name,
        }

        dispatch(setLoading(true));
        CustomAxios(ENDPOINTS.updateReviewStatus, 'POST', body).then((response) => {
            dispatch(setLoading(false))
            // reload nudges
            dispatch(getReviewAlerts(reviewTypes.pending));
            openNotificationWithIcon("success", response.data.message);
        }).catch((err) => {
            openNotificationWithIcon("error", "Inaccurate Error")
            console.error(err)
            dispatch(setLoading(false))
        })
    }
    else {
        openNotificationWithIcon("error", "Inaccurate Error")
    }
}

export default reviewAlertsSlicer.reducer