import { Dropdown, Input, Menu } from "antd";
import { UserOutlined, DeleteTwoTone, MoreOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateTime from "../date-time";
import { setBrandReset, setdefaultBrand } from "../../slicers/profile.slicer";
import { hasValue } from "../../utilities/helper";
import { useHistory } from "react-router-dom";

const { Search } = Input;


export default function ProfileReports(props) {
    const dispatch = useDispatch();
    const history = useHistory()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [reportSelect, setReportSelect] = useState({});
    const [reportData, setReportData] = useState([]);
    const { header, number, type } = props
    const { reportMap, defaultBrand } = useSelector((state) => state.profile)
    const { loading: refreshing, performanceSummary, volumeShifting, distributionSummary } = useSelector((state) => state.refreshDate)



    const filterMenu = () => {
        return (
            <Menu>
                <Menu.Item onClick={() => sortReports(reportData, "created_on", 0)}>New Reports</Menu.Item>
                <Menu.Item onClick={() => sortReports(reportData, "created_on", 1)}>Old Reports</Menu.Item>
            </Menu>
        );
    }

    const goTo = (data) => {

        if (defaultBrand === "ALL BRANDS" && data.product_brand.length > 0 && data.product_brand !== "ALL BRANDS") {
            dispatch(setBrandReset(1))
        }

        data.product_brand.length > 0 ? data.product_secondary_brand && data.product_secondary_brand.length > 0 ? dispatch(setdefaultBrand(data.product_brand + ":" + data.product_secondary_brand)) : dispatch(setdefaultBrand(data.product_brand)) : dispatch(setdefaultBrand("ALL BRANDS"))
        let query = {
            report_id: (data.report_id ? data.report_id : ""),
            view: ["report"],
            product_pet: ((data.product_pet && data.product_pet.length > 0 ? data.product_pet.split(',') : [])),
            product_technology: (data.product_technology && data.product_technology.length > 0 ? data.product_technology.split(',') : []),
            product_package: (data.product_package && data.product_package.length > 0 ? data.product_package.split(',') : []),
            default_brand: (data.product_brand && data.product_brand.length > 0 ? data.product_secondary_brand && data.product_secondary_brand.length > 0 ? [data.product_brand + ":" + data.product_secondary_brand] : data.product_brand.split(",") : ["NUTRO"]),
            // secondary_brand: (data.product_secondary_brand && data.product_secondary_brand.length > 0 ? data.product_secondary_brand : ""),
            customer_retailer_details: (data.distribution_retailer && data.distribution_retailer.length > 0 ? [data.distribution_retailer] : []),
            customer_channel: (data.distribution_channel && data.distribution_channel.length > 0 ? [data.distribution_channel] : []),
            reportBrands: (data.competitor_brand && data.competitor_brand.length > 0 ? data.competitor_brand.split(",") : []),
            reportChannels: (typeof data.competitor_channel !== 'string' && data.competitor_channel && Object.keys(data.competitor_channel).length > 0 ? data.competitor_channel : {}),
            reportChannel: (typeof data.competitor_retailer !== 'string' && data.competitor_retailer && Object.keys(data.competitor_retailer).length > 0 ? Object.keys(data.competitor_retailer) : []),
            reportRetailers: (typeof data.competitor_retailer !== 'string' && data.competitor_retailer && Object.keys(data.competitor_retailer).length > 0 ? data.competitor_retailer : {}),
            time_year: [data.time_year],
            time_period: [data.time_period],
            distribution_record_count: data.distribution_record_count && data.distribution_record_count ? data.distribution_record_count : 20,
            distribution_kpi: data.distribution_kpi && data.distribution_kpi ? data.distribution_kpi : "TDP",
            distribution_sort_order: data.distribution_sort_order && data.distribution_sort_order ? data.distribution_sort_order : 1,
            performance_components: data.performance_components && data.performance_components.length > 0 ? data.performance_components.split(",") : [],
            distribution_components: data.distribution_components && data.distribution_components.length > 0 ? data.distribution_components.split(",") : [],
            innovation_components: data.innovation_components && data.innovation_components.length > 0 ? data.innovation_components.split(",") : [],
            innovation: data.innovation && data.innovation.length > 0 ? data.innovation.split(",") : [],
            innovation_type: data.innovation_type && data.innovation_type.length > 0 ? data.innovation_type.split(",") : [],
            benchmark_values: data.innovation_benchmark_values && data.innovation_benchmark_values.length > 0 ? data.innovation_benchmark_values.split(",") : [],
            trendchart_kpi: data.innovation_trendchart_kpi && data.innovation_trendchart_kpi.length > 0 ? data.innovation_trendchart_kpi.split(",") : [],
            innovation_years: data.innovation_years && data.innovation_years.length > 0 ? data.innovation_years.split(",").map(val => Number(val)) : [],
            selected_innovation: data.selected_innovation && data.selected_innovation.length > 0 ? data.selected_innovation.split(",") : [],
            reportDistBrands: (data.distribution_scatter_brands && data.distribution_scatter_brands.length > 0) ? data.distribution_scatter_brands.split(",") : [],
            waterfall: data.volume_waterfall_indicator,
            report_name: [data.report_name]
        };

        // if (data.report_type === "distribution-summary") {
        //     query = {
        //         ...query,
        //         kpi: data.report_kpi ? [data.report_kpi] : ["TDP"],
        //         top: hasValue(data.report_sort_order) ? [data.report_sort_order] : [1],
        //     }
        // }

        let tempEncodedURL = encodeURIComponent(JSON.stringify(query));
        history.push("/report?" + tempEncodedURL,
            {
                profileLoad: true,
                //   report: data.components.split(','),
                //   reportBrands: data.product_brand ? data.product_brand.split(',') : null,
                //   reportRecordCount: data.report_record_count ? data.report_record_count : null,
            }
        );
    }

    const DropdownMenu = (props) => (
        <Dropdown
            overlay={menu(props.report)}
            trigger={["click"]}
            // placement="bottomRight"
            className="d-more"
        >
            <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
            >
                <MoreOutlined />
            </a>
        </Dropdown>
    );

    const menu = (report) => (
        <Menu
            onClick={() => { }}
            style={{ width: 100 }}
            defaultOpenKeys={['sub1']}
            mode="inline"
        >
            <Menu.Item key="sub2" icon={<DeleteTwoTone />} onClick={() => props.showModal(report, props.type)}>
                Delete
            </Menu.Item>
        </Menu>
    );

    useEffect(() => {
        if (Object.keys(reportMap).length !== 0 && reportMap && reportMap[type]) {
            sortReports(reportMap[type], "created_on", 0)
        }
    }, [reportMap])

    const sortReports = (reports, column, ascending) => {
        if (ascending) {
            const sortedReports = [...reports].sort((a, b) => new Date(a[column]) - new Date(b[column]));
            setReportData(sortedReports);
        }
        else {
            const sortedReports = [...reports].sort((a, b) => new Date(b[column]) - new Date(a[column]));
            setReportData(sortedReports);
        }

    }

    const onChange = e => {
        const newReportData = reportMap[type].filter(report => report.report_name && report.report_name.toLowerCase().includes(e.target.value.toLowerCase()) || (report.created_by_user && report.created_by_user.toLowerCase().includes(e.target.value.toLowerCase())))
        setReportData(newReportData);
    }

    return (
        <div className="reports-wrap">
            <div className="reports-head">
                <div class="rh-left">
                    <h5>{header}</h5>
                    <span>{number} reports</span>
                </div>
                <div class="rh-right">
                    <Dropdown overlay={filterMenu} trigger={['click']}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 11H17V13H7V11ZM4 7H20V9H4V7ZM10 15H14V17H10V15Z" fill="black" />
                        </svg>
                    </Dropdown>
                </div>
            </div>
            <div className="reports-search">
                <Search placeholder="Search by Title or Owner" style={{ width: 250 }} onChange={onChange} />
            </div>
            <div className="reports-body custom-tracker">
                {reportData && reportData.map(report => {
                    return (<div className="report-tab">
                        <div className="report-header">
                            <h4 onClick={() => goTo(report)}>{report.report_name && report.report_name}</h4>
                            <DropdownMenu report={report} />
                        </div>
                        <p className="report-details">Created by: {report && report.created_by_user && report.created_by_user}</p>
                        <p className="report-details">Report last modified on: {(new Date(report.created_on)).toLocaleString()}</p>
                        {/* <p className="report-details">Last data refreshed on: </p> */}
                    </div>)
                })}
            </div>
        </div>
    )
}