import { formatNumber, valueTypes } from "../../utilities/helper";
import { formatNumberExcel, numFmt } from "../../utilities/exports/excel";

// render UI
const renderFunction = (val, rec) =>
  formatNumber(val, 1, true) === valueTypes.NA
    ? valueTypes.NA
    : formatNumber(val, 1);
const renderDollarFunction = (val, rec) =>
  formatNumber(val, 1, true) === valueTypes.NA
    ? valueTypes.NA
    : `$ ${formatNumber(val, 1)}`;
const renderPercentageFunction = (val, rec) =>
  formatNumber(val, 1, true) === valueTypes.NA
    ? valueTypes.NA
    : `${formatNumber(val, 1)}%`;

// renderPPT
const renderPPTFunction = (val, rec) => formatNumber(val, 1);
const renderPPTDollarFunction = (val, rec) => `$ ${formatNumber(val, 1)}`;

// renderText for excel export
const renderTextFunction = (val, rec) => ({
  value: val,
  numFmt: numFmt.text,
});

const renderTextPercentageFunction = (val, rec) => ({
  value: formatNumberExcel(val, true),
  numFmt: numFmt.percentage,
});

const renderTextNumberFunction = (val, rec) => ({
  value: formatNumberExcel(val),
  numFmt: numFmt.number,
});

const renderTextDollarFunction = (val, rec) => ({
  value: formatNumberExcel(val),
  numFmt: numFmt.dollar,
});

const renderTextCommaFunction = (val, rec) => ({
  value: formatNumberExcel(val),
  numFmt: numFmt.comma,
});

/** 
 * dsColsNutroSKUColumnsExport
 * It exports excel
 **/
const dsColsNutroSKUColumnsExport = [
  {
    title: "Rank",
    dataIndex: "index",
    key: "index",
    width: "4%",
  },
  {
    title: "Brand",
    dataIndex: "product_brand",
    key: "product_brand",
    width: "10%",
  },
  {
    title: "UPC",
    dataIndex: "product_upc",
    key: "product_upc",
    width: "10%",
    renderText: renderTextFunction,
  },
  {
    title: "Product",
    dataIndex: "product_sku_desc",
    key: "product_sku_desc",
    width: "10%",
  },
  {
    title: "CSL",
    dataIndex: "fact_csl_current_period",
    key: "fact_csl_current_period",
    renderText: renderTextPercentageFunction,
    className: "td-border-left",
  },
  {
    title: "TDP L4W",
    dataIndex: "fact_tdp_current_period",
    key: "fact_tdp_current_period",
    renderText: renderTextNumberFunction,
    className: "td-border-left",
  },
  {
    title: "TDP L13W",
    dataIndex: "fact_tdp_latest_13w",
    key: "fact_tdp_latest_13w",
    renderText: renderTextNumberFunction,
  },
  {
    title: "TDP L52W",
    dataIndex: "fact_tdp_latest_52w",
    key: "fact_tdp_latest_52w",
    renderText: renderTextNumberFunction,
  },
  {
    title: "Velocity L4W",
    dataIndex: "fact_velocity_current_period",
    key: "fact_velocity_current_period",
    renderText: renderTextDollarFunction,
    className: "td-border-left",
  },
  {
    title: "Velocity L13W",
    dataIndex: "fact_velocity_latest_13w",
    key: "fact_velocity_latest_13w",
    renderText: renderTextDollarFunction,
  },
  {
    title: "Velocity L52W",
    dataIndex: "fact_velocity_latest_52w",
    key: "fact_velocity_latest_52w",
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV L4W",
    dataIndex: "fact_value_sales_current_period",
    key: "fact_value_sales_current_period",
    renderText: renderTextDollarFunction,
    className: "td-border-left",
  },
  {
    title: "MKT RSV L13W",
    dataIndex: "fact_value_sales_latest_13w",
    key: "fact_value_sales_latest_13w",
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV L52W",
    dataIndex: "fact_value_sales_latest_52w",
    key: "fact_value_sales_latest_52w",
    renderText: renderTextDollarFunction,
  },
  {
    title: "Eq LBs L4W",
    dataIndex: "fact_volume_current_period",
    key: "fact_volume_current_period",
    renderText: renderTextCommaFunction,
    className: "td-border-left",
  },
  {
    title: "Eq LBs L13W",
    dataIndex: "fact_volume_latest_13w",
    key: "fact_volume_latest_13w",
    renderText: renderTextCommaFunction,
  },
  {
    title: "Eq LBs L52W",
    dataIndex: "fact_volume_latest_52w",
    key: "fact_volume_latest_52w",
    renderText: renderTextCommaFunction,
  },
//   {
//     title: "MAC% L4W",
//     dataIndex: "fact_mac_current_period",
//     key: "fact_mac_current_period",
//     renderText: renderTextPercentageFunction,
//     className: "td-border-left",
//   },
//   {
//     title: "MAC% L13W",
//     dataIndex: "fact_mac_latest_13w",
//     key: "fact_mac_latest_13w",
//     renderText: renderTextPercentageFunction,
//   },
//   {
//     title: "MAC% L52W",
//     dataIndex: "fact_mac_latest_52w",
//     key: "fact_mac_latest_52w",
//     renderText: renderTextPercentageFunction,
//   },
];
const dsColsAllSKUColumnsExport = [
  {
    title: "Brand",
    dataIndex: "product_brand",
    key: "product_brand",
    width: "10%",
  },
  {
    title: "UPC",
    dataIndex: "product_upc",
    key: "product_upc",
    width: "10%",
    renderText: renderTextFunction,
  },
  {
    title: "Product Description",
    dataIndex: "product_sku_desc",
    key: "product_sku_desc",
    width: "35%",
  },
  {
    title: "CSL",
    dataIndex: "fact_csl_current_period",
    key: "fact_csl_current_period",
    renderText: renderTextPercentageFunction,
  },
  {
    title: "Eq LBs",
    dataIndex: "fact_volume_current_period",
    key: "fact_volume_current_period",
    renderText: renderTextCommaFunction,
  },
  {
    title: "MKT RSV",
    dataIndex: "fact_value_sales_current_period",
    key: "fact_value_sales_current_period",
    renderText: renderTextDollarFunction,
  },
  {
    title: "TDP",
    dataIndex: "fact_tdp_current_period",
    key: "fact_tdp_current_period",
    renderText: renderTextNumberFunction,
  },
  {
    title: "Velocity",
    dataIndex: "fact_velocity_current_period",
    key: "fact_velocity_current_period",
    renderText: renderTextDollarFunction,
  },
];

// UI and PPT
const dsColsNutroSKUColumns = [
  {
    title: "Rank",
    children: [
      {
        title: "",
        dataIndex: "index",
        key: "index",
        width: "3%",
        render: (val, rec) => formatNumber(val, 0),
        renderPPT: (val, rec) => formatNumber(val, 0)
      },
    ],
  },
  {
    title: "Product",
    children: [
      {
        title: "",
        dataIndex: "product_sku_desc",
        key: "product_sku_desc",
        width: "20%",
        className: "product-column",
      },
    ],
  },
  {
    title: "CSL",
    children: [
      {
        title: "",
        dataIndex: "fact_csl_current_period",
        key: "fact_csl_current_period",
        render: renderPercentageFunction,
        className: "td-border-left",
        width: "4%",
      },
    ],
  },
  {
    title: "TDP",
    children: [
      {
        title: "L4W",
        dataIndex: "fact_tdp_current_period",
        key: "fact_tdp_current_period",
        render: renderFunction,
        renderPPT: renderPPTFunction,
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "fact_tdp_latest_13w",
        key: "fact_tdp_latest_13w",
        render: renderFunction,
        renderPPT: renderPPTFunction,
      },
      {
        title: "L52W",
        dataIndex: "fact_tdp_latest_52w",
        key: "fact_tdp_latest_52w",
        render: renderFunction,
        renderPPT: renderPPTFunction,
        width: "5%",
      },
    ],
  },
  {
    title: "Velocity",
    children: [
      {
        title: "L4W",
        dataIndex: "fact_velocity_current_period",
        key: "fact_velocity_current_period",
        render: renderDollarFunction,
        renderPPT: renderPPTDollarFunction,
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "fact_velocity_latest_13w",
        key: "fact_velocity_latest_13w",
        render: renderDollarFunction,
        renderPPT: renderPPTDollarFunction,
      },
      {
        title: "L52W",
        dataIndex: "fact_velocity_latest_52w",
        key: "fact_velocity_latest_52w",
        render: renderDollarFunction,
        renderPPT: renderPPTDollarFunction,
        width: "5%",
      },
    ],
  },
  {
    title: "MKT RSV",
    children: [
      {
        title: "L4W",
        dataIndex: "fact_value_sales_current_period",
        key: "fact_value_sales_current_period",
        render: renderDollarFunction,
        renderPPT: renderPPTDollarFunction,
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "fact_value_sales_latest_13w",
        key: "fact_value_sales_latest_13w",
        render: renderDollarFunction,
        renderPPT: renderPPTDollarFunction,
      },
      {
        title: "L52W",
        dataIndex: "fact_value_sales_latest_52w",
        key: "fact_value_sales_latest_52w",
        render: renderDollarFunction,
        renderPPT: renderPPTDollarFunction,
        width: "5%",
      },
    ],
  },
  {
    title: "Eq LBs",
    children: [
      {
        title: "L4W",
        dataIndex: "fact_volume_current_period",
        key: "fact_volume_current_period",
        render: renderFunction,
        renderPPT: renderPPTFunction,
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "fact_volume_latest_13w",
        key: "fact_volume_latest_13w",
        render: renderFunction,
        renderPPT: renderPPTFunction,
      },
      {
        title: "L52W",
        dataIndex: "fact_volume_latest_52w",
        key: "fact_volume_latest_52w",
        render: renderFunction,
        renderPPT: renderPPTFunction,
        width: "5%",
      },
    ],
  },
//   {
//     title: "MAC%",
//     children: [
//       {
//         title: "L4W",
//         dataIndex: "fact_mac_current_period",
//         key: "fact_mac_current_period",
//         render: renderFunction,
//         renderPPT: renderPPTFunction,
//         className: "td-border-left",
//       },
//       {
//         title: "L13W",
//         dataIndex: "fact_mac_latest_13w",
//         key: "fact_mac_latest_13w",
//         render: renderFunction,
//         renderPPT: renderPPTFunction,
//       },
//       {
//         title: "L52W",
//         dataIndex: "fact_mac_latest_52w",
//         key: "fact_mac_latest_52w",
//         render: renderFunction,
//         renderPPT: renderPPTFunction,
//         width: "5%",
//       },
//     ],
//   },
];
const dsColsAllSKUColumns = [
  {
    title: "Product Description",
    dataIndex: "product_sku_desc",
    key: "product_sku_desc",
    width: "35%",
  },
  {
    title: "CSL",
    dataIndex: "fact_csl_current_period",
    key: "fact_csl_current_period",
    render: renderPercentageFunction,
    renderPPT: renderPPTFunction,
  },
  {
    title: "Eq LBs",
    dataIndex: "fact_volume_current_period",
    key: "fact_volume_current_period",
    render: renderFunction,
    renderPPT: renderPPTFunction,
  },
  {
    title: "MKT RSV",
    dataIndex: "fact_value_sales_current_period",
    key: "fact_value_sales_current_period",
    render: renderDollarFunction,
    renderPPT: renderPPTDollarFunction,
  },
  {
    title: "TDP",
    dataIndex: "fact_tdp_current_period",
    key: "fact_tdp_current_period",
    render: renderFunction,
    renderPPT: renderPPTFunction,
  },
  {
    title: "Velocity",
    dataIndex: "fact_velocity_current_period",
    key: "fact_velocity_current_period",
    render: renderDollarFunction,
    renderPPT: renderPPTDollarFunction,
  },
];

export default {
  dsColsNutroSKUColumnsExport,
  dsColsAllSKUColumnsExport,
  dsColsNutroSKUColumns,
  dsColsAllSKUColumns,
}
