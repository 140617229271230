import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Input, Modal, Select } from "antd";
import { PlusCircleTwoTone, CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import './export-form.scss';
import { initiateTask } from "../../slicers/dataSources.slicer";


const { Option } = Select;

export default function DummyExportForm(props) {
    const dispatch = useDispatch();
    const { year, period, pets, techs, manufacturers, brands, sub_brands, regions, country, channels, retailers, columns } = useSelector((state) => state.dataSources)
    const { openDummyExportDialog, setOpenDummyExportDialog } = props
    const [filterCount, setFilterCount] = useState([0]);
    const [form] = Form.useForm();

    const closeDummyExportDialog = () => {
        setOpenDummyExportDialog(false)
    }

    const onExportClick = () => {
        form.validateFields()
            .then(values => {
                dispatch(initiateTask(values));
                console.log("Success:", values);
            })
            .catch(errorInfo => {
                console.log('Failed:', errorInfo);
            });
    }

    const { Option } = Select;


    return (
        <Modal
            title={"Export External Data"}
            visible={openDummyExportDialog}
            onOk={onExportClick}
            okText={"Export"}
            onCancel={closeDummyExportDialog}
            width={700}
            style={{ top: 20 }}
        >
            <div className="">
                <Form form={form} name="basic" layout="vertical">
                    <Form.Item
                        name={"column_select"}
                        label="Column"
                        rules={[
                            {
                                required: false,
                                message: 'Please choose a data source',
                            }]}>
                        <Select placeholder="Select" mode="multiple" style={{ width: 200 }}>
                            {columns?.map((val) => (
                                <Option value={val}>{val}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <div className="mytextdiv">
                        <div className="mytexttitle">Products</div>
                        <div className="divider" ></div>
                    </div>
                    {filterCount.map((val) => (<Row gutter={10} style={{ marginTop: 10 }}>
                        <Col md={8}>
                            <Form.Item
                                name={"product_pet"}
                                label="Pet"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select a dimension',
                                    }]}>
                                <Select placeholder="Select" mode="multiple"
                                    allowClear maxTagCount="responsive" style={{ width: '100%' }}>
                                    {pets?.map((val) => (
                                        <Option value={val}>{val}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={8}>
                            <Form.Item
                                name={"product_technology"}
                                label="Technology"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select a filter',
                                    }]}>
                                <Select placeholder="Select" mode="multiple"
                                    allowClear maxTagCount="responsive" style={{ width: '100%' }}>
                                    {techs?.map((val) => (
                                        <Option value={val}>{val}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={8}>
                            <Form.Item
                                name={"product_manufacturer"}
                                label="Manufacturer"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select a brand',
                                    }]}>
                                <Select placeholder="Select" mode="multiple"
                                    allowClear maxTagCount="responsive" style={{ width: '100%' }}>
                                    {manufacturers?.map((val) => (
                                        <Option value={val}>{val}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={8}>
                            <Form.Item
                                name={"product_brand"}
                                label="Brand"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select a brand',
                                    }]}>
                                <Select placeholder="Select" mode="multiple"
                                    allowClear maxTagCount="responsive" style={{ width: '100%' }}>
                                    {brands?.map((val) => (
                                        <Option value={val}>{val}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={8}>
                            <Form.Item
                                name={"product_sub_brand"}
                                label="Sub Brand"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select a brand',
                                    }]}>
                                <Select placeholder="Select" mode="multiple"
                                    allowClear maxTagCount="responsive" style={{ width: '100%' }}>
                                    {sub_brands?.map((val) => (
                                        <Option value={val}>{val}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>))}
                    <div className="mytextdiv">
                        <div className="mytexttitle">Customer</div>
                        <div className="divider" ></div>
                    </div>
                    <Row gutter={10}>
                        <Col md={8}>
                            <Form.Item
                                name={"product_region"}
                                label="Region"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select a brand',
                                    }]}>
                                <Select placeholder="Select" mode="multiple"
                                    allowClear maxTagCount="responsive" style={{ width: '100%' }}>
                                    {regions?.map((val) => (
                                        <Option value={val}>{val}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={8}>
                            <Form.Item
                                name={"product_country"}
                                label="Country"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select a brand',
                                    }]}>
                                <Select placeholder="Select" mode="multiple"
                                    allowClear maxTagCount="responsive" style={{ width: '100%' }}>
                                    {country?.map((val) => (
                                        <Option value={val}>{val}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={8}>
                            <Form.Item
                                name={"product_channel"}
                                label="Channel"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select a brand',
                                    }]}>
                                <Select placeholder="Select" mode="multiple"
                                    allowClear maxTagCount="responsive" style={{ width: '100%' }}>
                                    {channels?.map((val) => (
                                        <Option value={val}>{val}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={8}>
                            <Form.Item
                                name={"product_retailer"}
                                label="Retailer"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select a brand',
                                    }]}>
                                <Select placeholder="Select" mode="multiple"
                                    allowClear maxTagCount="responsive" style={{ width: '100%' }}>
                                    {retailers?.map((val) => (
                                        <Option value={val}>{val}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="mytextdiv">
                        <div className="mytexttitle">Time Period</div>
                        <div className="divider" ></div>
                    </div>
                    <Row gutter={10}>
                        <Col md={8}>
                            <Form.Item
                                name={"time_year"}
                                label="Year"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a year',
                                    }]}>
                                <Select placeholder="Select" mode="multiple"
                                    allowClear maxTagCount="responsive" style={{ width: '100%' }}>
                                    {year?.map((val) => (
                                        <Option value={val}>{val}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={8}>
                            <Form.Item
                                name={"time_period"}
                                label="Period"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a period',
                                    }]}>
                                <Select placeholder="Select" mode="multiple"
                                    allowClear maxTagCount="responsive" style={{ width: '100%' }}>
                                    {period?.map((val) => (
                                        <Option value={val}>{val}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </div >
        </Modal >
    )
}
