import * as msal from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri: window.location.origin,
    },
};

export const msalInstance = new msal.PublicClientApplication(msalConfig);
export const request = { scopes: [process.env.REACT_APP_SCOPE_EXTENSION] };
