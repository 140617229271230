import React, { useEffect, useState } from 'react';
import {
  Router,
  Switch,
  Route
} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import history from '../utilities/history';
import Nudges from '../pages/nudges';
import NudgesOld from '../pages/nudgesOld';
import Admin from '../pages/admin';
import Trackboard from '../pages/trackboard';
import Profile from '../pages/profile';
import PerformanceSummary from '../pages/performance-summary';
import VolumeShifting from '../pages/volume-shifting';
import PortfolioSummary from '../pages/portfolio-summary';
import DataSources from '../pages/data-sources';
import Export from '../pages/export';
import DistributionSummary from '../pages/distribution-summary';
import Alerts from '../pages/alerts';
import { getDataSourcesRefresh } from '../slicers/refreshDate.slicer';
import Report from '../pages/report';
import { fetchUserNote } from '../slicers/admin.slicer';
import EcomBeSelected from '../pages/be-selected/index';
import EcomBeVisible from '../pages/be-visible/index';
import SearchTerm from '../pages/search-term/index';
import Diagnostics from '../pages/ecom/diagnostics';
import { InnovationTracker } from '../pages/innovation-tracker';
import BeAvailable from '../pages/be-available';
import { InnovationTrackerReview } from '../pages/innovation-tracker-review';
import { getFilterData } from '../slicers/innovation-tracker.slicer';
import { openNotificationWithIcon } from '../utilities/helper';
import EcomDashboard from '../pages/ecom/ecomDashboard';
import { paths } from '../utilities/paths';
import TrackboardV1 from '../pages/trackboard-v1';

/**
 * Route Configuration
 * It manages the routing throughout the whole web app.
 */
function RouteConfig() {
  const { user } = useSelector((state) => state.profile)
  const [loggedIn, setloggedIn] = useState(false);
  const { clusterNotification } = useSelector((state) => state.dataSources)
  const dispatch = useDispatch();
  useEffect(() => {
    let auth = JSON.parse(sessionStorage.getItem("h32g1g3h2g3h12g3g"));
    dispatch(getDataSourcesRefresh());
    dispatch(fetchUserNote());
  }, []);

  useEffect(() => {
    if (clusterNotification) {
      openNotificationWithIcon('success', 'Cluster Running. You can now preview/export files.', 0)
    }
  }, [clusterNotification])

  // temp code
  function login() {
    setloggedIn(true)
  }
  return (
    <Router history={history}>
      {

        <Switch>
          <Route path={paths.home} exact={true}>
            <Nudges />
            {/* <NudgesOld /> */}
          </Route>
          <Route path={paths.trackboardV1} exact={true}>
            <TrackboardV1 />
          </Route>
          <Route path={paths.trackboard} exact={true}>
            <Trackboard />
          </Route>
          <Route path={paths.profile} exact={true}>
            <Profile />
          </Route>
          <Route path={paths.profile1} exact={true}>
            <Profile tab={"1"} />
          </Route>
          <Route path={paths.profile2} exact={true}>
            <Profile tab={"2"} />
          </Route>
          <Route path={paths.profile3} exact={true}>
            <Profile tab={"3"} />
          </Route>
          <Route path={paths.profile4} exact={true}>
            <Profile tab={"4"} />
          </Route>
          <Route path={paths.review} exact={true}>
            <Profile tabName={"review"} />
          </Route>
          <Route path={paths.dataSources} exact={true}>
            <DataSources />
          </Route>
          <Route path={paths.alerts1} exact={true}>
            <Alerts tab={"1"} />
          </Route>
          <Route path={paths.alerts2} exact={true}>
            <Alerts tab={"2"} />
          </Route>
          <Route path={paths.alerts3} exact={true}>
            <Alerts tab={"3"} />
          </Route>
          <Route path={paths.alerts4} exact={true}>
            <Alerts tab={"4"} />
          </Route>
          <Route path={paths.performanceSummary} exact={true}>
            <PerformanceSummary />
          </Route>
          <Route path={paths.volumeShifting} exact={true}>
            <VolumeShifting />
          </Route>
          <Route path={paths.portfolioSummary} exact={true}>
            <PortfolioSummary />
          </Route>
          <Route path={paths.distributionSummary} exact={true}>
            <DistributionSummary />
          </Route>
          <Route path={paths.innovationTracker} exact={true}>
            <InnovationTracker />
          </Route>
          <Route path={paths.beAvailable} exact={true}>
            <BeAvailable />
          </Route>
          <Route path={paths.searchTerm} exact={true}>
            <SearchTerm />
          </Route>
          <Route path={paths.shareOfShelf} exact={true}>
            <EcomBeVisible />
          </Route>
          <Route path={paths.report} exact={true}>
            <Report />
          </Route>
          <Route path={paths.admin} exact={true}>
            <Admin />
          </Route>
          <Route path={paths.ratingsReviews} exact={true}>
            <EcomBeSelected />
          </Route>
          <Route path={paths.diagnostics} exact={true}>
            <Diagnostics />
          </Route>
          <Route path={paths.innovationTrackerReview} exact={true}>
            <InnovationTrackerReview />
          </Route>
          <Route path={paths.ecom} exact={true}>
            <EcomDashboard />
          </Route>
          <Route path={paths.export} exact={true}>
            <Export page={"General"} />
          </Route>
        </Switch>

      }
    </Router>
  );
}

export default RouteConfig;
