import React from "react";
import { Scatter } from "@ant-design/plots";
import { formatNumber } from "../../utilities/helper";
import './circle-chart.scss';

function CircleChart({ data, xField, yField, xBaseline, yBaseline, onSelect, brandColors }) {
  // console.log("data, xField, yField, xBaseline, yBaseline, brandColors". data, xField, yField, xBaseline, yBaseline, brandColors);
  const config = {
    data,
    xField,
    yField,
    colorField: "product_brand",
    sizeField: "fact_value_sales_current_period",
    size: [10, 20],
    color: ({ product_brand }) => {
      return brandColors[product_brand] === "" ? "#006E7F" : brandColors[product_brand];
    },
    tooltip: {
      customItems: (originalItems) => {
        return [
          {
            name: "Weighted Distribution",
            value: formatNumber(originalItems[0].data["Weighted Distribution"], 1),
          },
          {
            name: "Velocity",
            value: formatNumber(originalItems[0].data.Velocity, 1),
          },
          {
            name: "MKT RSV",
            value: formatNumber(
              originalItems[0].data.fact_value_sales_current_period,
              1
            ),
          },
        ];
      },
    },
    shape: "circle",
    yAxis: {
      title: {},
      nice: true,
      line: {
        style: {
          stroke: "#C4C4C4",
          lineWidth: 2,
          lineDash: [6, 4],
          strokeOpacity: 0.4,
        },
      },
      grid: {
        line: {
          style: {
            stroke: "#C4C4C4",
            lineWidth: 2,
            lineDash: [6, 4],
            strokeOpacity: 0.4,
            cursor: "pointer",
          },
        },
      },
    },
    xAxis: {
      title: {},
      line: {
        style: {
          stroke: "#C4C4C4",
          lineWidth: 2,
          lineDash: [6, 4],
          strokeOpacity: 0.4,
        },
      },
      grid: {
        line: {
          style: {
            stroke: "#C4C4C4",
            lineWidth: 2,
            lineDash: [6, 4],
            strokeOpacity: 0.4,
            cursor: "pointer",
          },
        },
      },
    },
    brush: {
      enabled: true,
      mask: {
        style: {
          fill: "rgba(255,0,0,0.5)",
        },
      }
    },
    quadrant: {
      xBaseline: xBaseline === null ? undefined : xBaseline,
      yBaseline: yBaseline === null ? undefined : yBaseline,
      lineStyle: {
        stroke: "#ff0000",
        lineWidth: 1,
      }
    },
  };
  const onEvent = (e, event) => {
    if (
      event.type === "brush-filter:afterfilter" ||
      event.type === "brush-reset-button:click"
    )
      onSelect && onSelect(event.view.filteredData);
    if (event.type === "element:mousedown")
      onSelect && onSelect([event.data.data]);
  };
  return (
    <>
      {data ? (
        <>
          <Scatter {...config} onEvent={onEvent} />
          <div className="median-box">
            <h4>Category Median</h4>
            <div className="inner-box">
              <div>{xField}: {formatNumber(xBaseline, 1)}</div>
              <div>{yField}: {formatNumber(yBaseline, 1)}</div>
            </div>
          </div>
        </>
      ) : (
        <div style={{ fontSize: 20, marginLeft: "10%" }}>
          No data available to display
        </div>
      )}
    </>
  );
}
export default CircleChart;
