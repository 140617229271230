import ExcelJS from "exceljs/dist/es5/exceljs.browser.js";
import * as FileSaver from "file-saver";
import { brandSplit, getTimeAggregates } from "../helper";
import { cloneDeep, orderBy } from "lodash";
import moment from 'moment';
import { getState } from "../../store";
import { rowKey } from "../../pages/innovation-tracker/itUIHelper";
/**
 * Excel
 * Helper function to format a number for Excel export by considering percentage and decimal options.
 */

// Function to format numbers.
export function formatNumberExcel(val, percentage = false, decimal = 1) {
  let num = Number(val);
  if (isNaN(num)) return val;

  // if (percentage) return Number(num.toFixed(decimal)) / 100;
  // return Number(num.toFixed(decimal));

  return percentage ? num / 100 : num;
}

// Number formats for Excel cells
export const numFmt = {
  dollar:
    '_-[$$-en-US]* #,##0.0_ ;_-[$$-en-US]* -#,##0.0 ;_-[$$-en-US]* "-"??_ ;_-@_ ',
  comma: '_ * #,##0.0_ ;_ * -#,##0.0_ ;_ * "-"??_ ;_ @_ ',
  percentage: "0.0%",
  number: "0.0",
  wholeNumber: "0",
  text: "@",
  date: "yyyy-mm-dd",
};

// Function to export data as an Excel table
export async function exportTable(columns, exportData, filename) {

  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  if (exportData && Object.keys(exportData).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    //Export column titles
    columns && columns.forEach(({ title }) => {
      ws.getRow(row).getCell(cell).value = title;
      cell++;
    });
    // Export data records
    exportData && exportData.forEach((record) => {
      nextRow();
      columns.forEach(({ dataIndex }) => {
        ws.getRow(row).getCell(cell++).value = record[dataIndex];
      }
      );
    });
  }

  // Save the Excel workbook as a file
  const buf = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
}

// Function to export multiple Excel tables into a single workbook
export async function exportMarketFinancialsTable(columnsMarket, columnsFinancials, exportData, filename) {
  const wb = new ExcelJS.Workbook();
  exportCustomTable(columnsMarket, exportData, `${filename}_M`, true, wb);
  exportCustomTable(columnsFinancials, exportData, `${filename}_F`, true, wb);

  // Save the Excel workbook as a file
  const buf = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buf]), `${filename}.xlsx`);
}

// Function to export launch performance data as an Excel table
export async function exportlaunchperfTable(itColsRevenue, itColsDistribution, tableData, filename, filter, isRevenueData, tableLaunchYear, localFilter) {
  const wb = new ExcelJS.Workbook();
  // {isRevenueData ? columns.itColsDistribution : columns.itColsRevenue}
  if (isRevenueData) {
    exportLuanchPerfCustomTable(itColsDistribution, tableData, `${filename}_D`, true, wb, filter, tableLaunchYear, localFilter);
  } else {
    exportLuanchPerfCustomTable(itColsRevenue, tableData, `${filename}_R`, true, wb, filter, tableLaunchYear, localFilter);
  }

  // Save the Excel workbook as a file
  const buf = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buf]), `${filename}.xlsx`);
}

// Function to export custom launch performance data as an Excel table
export async function exportLuanchPerfCustomTable(columns, tableData, filename, addToExisting = false, wb = null, filters, tableLaunchYear, localFilter) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  // const { filters, tableData } = exportData;

  if (Object.keys(tableData).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    // const { data } = tableData;

    // Filters

    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      if (filter === 'product_pet' || filter === 'product_technology' || filter === 'innovation_type' || filter === 'time_year' || filter === 'innovation' || filter === 'selected_innovation') {
        nextRow();
        if (filter === 'time_year') {
          ws.getRow(row).getCell(++cell).value = 'launch_year';
          ws.getRow(row).getCell(++cell).value = filters[filter].join();
        }
        else if (filter === 'selected_innovation') {
          ws.getRow(row).getCell(++cell).value = filter;
          ws.getRow(row).getCell(++cell).value = localFilter.selected_innovation[0];
        }
        else {
          ws.getRow(row).getCell(++cell).value = filter;
          ws.getRow(row).getCell(++cell).value = filters[filter].join();
        }
      }
    });

    nextRow();
    nextRow();

    ws.getRow(row).getCell(cell++).value = "Launch Performance via Retailers";
    nextRow();
    nextRow();

    const children =
      columns.findIndex(({ children }) => children && children.length) > -1;
    // columns
    columns.forEach(({ title, children, dataIndex }) => {
      if (children) {
        ws.mergeCells(row, cell, row, cell + (children.length - 1));
        cell += children.length - 1;
      }
      if (dataIndex === 'fact_revenue_er' || dataIndex === 'fact_revenue_target') {
        ws.getRow(row).getCell(cell).value = `${title} (${tableLaunchYear})`;
      } else {
        ws.getRow(row).getCell(cell).value = title;
      }

      if (children) {
        let childCell = 0;
        children.forEach(({ title }) => {
          const offset = cell - (children.length - 1);
          ws.getRow(row + 1).getCell(offset + childCell++).value = title;
        });
      }
      cell++;
    });
    if (children) nextRow();

    // data
    tableData.forEach((record) => {
      nextRow();
      columns.forEach(({ dataIndex, children, renderText }) => {
        if (children) {
          children.forEach(({ dataIndex, renderText }) => {
            if (renderText) {
              ws.getRow(row).getCell(cell).value = renderText(
                record[dataIndex]
              ).value;
              ws.getRow(row).getCell(cell++).numFmt = renderText(
                record[dataIndex]
              ).numFmt;
            } else {
              if (dataIndex === 'fact_rsv_target_yee' || dataIndex === 'fact_rsv_actuals_yee' || dataIndex === 'er_dollar_per_tdp_target' || dataIndex === 'er_dollar_per_tdp_actual') {
                ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;
                ws.getRow(row).getCell(cell++).value = record[dataIndex];
              } else if (dataIndex === 'fact_percent_acv_reach_actual') {
                ws.getRow(row).getCell(cell++).value = record[dataIndex];
                ws.getRow(row).getCell(cell).numFmt = numFmt.percentage;
              }
              else {
                ws.getRow(row).getCell(cell++).value = record[dataIndex];
              }
            }
          });
        } else {
          if (renderText) {
            ws.getRow(row).getCell(cell).value = renderText(
              record[dataIndex]
            ).value;
            ws.getRow(row).getCell(cell++).numFmt = renderText(
              record[dataIndex]
            ).numFmt;
          } else {
            if (dataIndex === 'fact_rsv_target_yee' || dataIndex === 'fact_rsv_actuals_yee' || dataIndex === 'er_dollar_per_tdp_target' || dataIndex === 'er_dollar_per_tdp_actual') {
              ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;
              ws.getRow(row).getCell(cell++).value = record[dataIndex];
            } else if (dataIndex === 'fact_percent_acv_reach_actual') {
              ws.getRow(row).getCell(cell++).value = record[dataIndex];
              ws.getRow(row).getCell(cell).numFmt = numFmt.percentage;
            }
            else {
              ws.getRow(row).getCell(cell++).value = record[dataIndex];
            }
          }
        }
      });
    });
  }

  if (!addToExisting) {
    // Save the Excel workbook as a file
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export consumer review data as an Excel table
export async function exportConsumerReview(filters, sortOrder, filename, addToExisting = false, wb = null) {
  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  // Extract necessary data from the innovationTracker state
  const { skuList, retailerList, reviewFilterBy, reviewData } = getState().innovationTracker;



  // Check if retailerList is empty
  if (retailerList.length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    // Export filter information
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      if (filter === 'product_pet' || filter === 'product_technology' || filter === 'innovation_type' || filter === 'time_year' || filter === 'innovation') {
        nextRow();
        if (filter === 'time_year') {
          ws.getRow(row).getCell(++cell).value = 'launch_year';
          ws.getRow(row).getCell(++cell).value = filters[filter].join();
        } else {
          ws.getRow(row).getCell(++cell).value = filter;
          ws.getRow(row).getCell(++cell).value = filters[filter].length === 0 ? "ALL" : filters[filter].join();
        }
      }
    });

    // Export selected innovation and SKU
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell++).value = "Innovation";
    ws.getRow(row).getCell(cell).value = filters.selected_innovation[0];

    // SKU
    nextRow();
    ws.getRow(row).getCell(cell++).value = "SKU";
    ws.getRow(row).getCell(cell).value = filters.selected_sku[0];

    // Retailers
    nextRow();
    if (filters.consumer_tab[0] === "A") ws.getRow(row).getCell(cell++).value = "All Retailers";
    if (filters.consumer_tab[0] === "B") ws.getRow(row).getCell(cell++).value = "Best Retailer";
    if (filters.consumer_tab[0] === "W") ws.getRow(row).getCell(cell++).value = "Worst Retailer";
    if (filters.consumer_tab[0] === "C") ws.getRow(row).getCell(cell++).value = "Compare Retailers";
    ws.getRow(row).getCell(cell).value = filters.customer_retailer_details.length === 0 ? retailerList.join() : filters.customer_retailer_details.join();


    // Ratings
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = "Ratings";

    nextRow();
    const ratings = reviewData?.ratings?.ratings_grid;
    if (ratings) {
      ws.getRow(row).getCell(cell++).value = "Rating Label";
      ws.getRow(row).getCell(cell++).value = "Rating Value";

      ratings.forEach(({ rating_label, rating_value }) => {
        nextRow();
        ws.getRow(row).getCell(cell++).value = rating_label;
        if (filters.consumer_tab[0] === "C") {
          ws.getRow(row).getCell(cell).value = rating_value;
          ws.getRow(row).getCell(cell).numFmt = numFmt.number;
        } else {
          ws.getRow(row).getCell(cell).value = formatNumberExcel(rating_value, true);
          ws.getRow(row).getCell(cell).numFmt = numFmt.percentage;
        }
      });
    }

    // Sentiment Analysis
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = "Sentiment Analysis";

    nextRow();
    const sentiment = reviewData?.sentiment;
    if (sentiment) {
      ws.getRow(row).getCell(cell++).value = "Overall Sentiment";
      ws.getRow(row).getCell(cell++).value = "Overall Sentiment Count";

      sentiment.forEach(({ overall_sentiment, overall_sentiment_count }) => {
        nextRow();
        ws.getRow(row).getCell(cell++).value = overall_sentiment;
        ws.getRow(row).getCell(cell++).value = overall_sentiment_count;
      });
    }

    // Top Themes
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell++).value = "Top Themes";
    const review_tags = reviewData?.review_tags;
    if (review_tags) ws.getRow(row).getCell(cell).value = review_tags.join();

    // Review by retailer
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = "Reviews";

    nextRow();
    if (Object.keys(reviewFilterBy).length > 0) {

      ws.getRow(row).getCell(cell++).value = "Filter By";
      ws.getRow(row).getCell(cell++).value = filters.filter_by[0];
      ws.getRow(row).getCell(cell++).value = filters.sub_filter_by[0];

      nextRow();
      ws.getRow(row).getCell(cell++).value = "Sentence Rating";
      ws.getRow(row).getCell(cell++).value = "Sentence";
      ws.getRow(row).getCell(cell++).value = "Reviewed By";
      ws.getRow(row).getCell(cell++).value = "Retailer";
      ws.getRow(row).getCell(cell++).value = "Reviewed Date";

      if (reviewFilterBy[filters.filter_by[0]])
        orderBy(reviewFilterBy[filters.filter_by[0]][filters.sub_filter_by[0]], ["review_date"], sortOrder ? ["desc"] : ["asc"]).forEach((review) => {
          nextRow();
          ws.getRow(row).getCell(cell++).value = review.sentence_rating;
          ws.getRow(row).getCell(cell).value = review.sentence;
          ws.getRow(row).getCell(cell++).alignment = { wrapText: true };
          ws.getRow(row).getCell(cell++).value = review.reviewed_by_user;
          ws.getRow(row).getCell(cell++).value = review.retailer;
          ws.getRow(row).getCell(cell).value = moment(review.review_date).format("YYYY-MM-DD");
          ws.getRow(row).getCell(cell++).numFmt = numFmt.date;
        });
    }

  }

  if (!addToExisting) {
    // Save the Excel workbook as a file
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export custom data table to Excel
export async function exportCustomTable(columns, exportData, filename, addToExisting = false, wb = null) {

  // If the workbook is not provided, create a new Excel workbook
  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);


  // Extract necessary data from exportData
  const { filters, tableData } = exportData;


  // Check if data is available
  if (Object.keys(tableData).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    const { data } = tableData;


    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = filters[filter].join();
    });

    nextRow();
    nextRow();

    if (exportData.header) {
      ws.getRow(row).getCell(cell).value = exportData.header;
      nextRow();
      nextRow();
    }

    const children =
      columns.findIndex(({ children }) => children && children.length) > -1;

    // columns
    columns.forEach(({ title, children }) => {
      if (children) {
        ws.mergeCells(row, cell, row, cell + (children.length - 1));
        cell += children.length - 1;
      }
      ws.getRow(row).getCell(cell).value = title;

      if (children) {
        let childCell = 0;
        children.forEach(({ title }) => {
          const offset = cell - (children.length - 1);
          ws.getRow(row + 1).getCell(offset + childCell++).value = title;
        });
      }
      cell++;
    });
    if (children) nextRow();

    // data
    data.forEach((record) => {
      nextRow();
      columns.forEach(({ dataIndex, children, renderText }) => {
        if (children) {
          children.forEach(({ dataIndex, renderText }) => {
            if (renderText) {
              ws.getRow(row).getCell(cell).value = renderText(
                record[dataIndex]
              ).value;
              ws.getRow(row).getCell(cell++).numFmt = renderText(
                record[dataIndex]
              ).numFmt;
            } else ws.getRow(row).getCell(cell++).value = record[dataIndex];
          });
        } else {
          if (renderText) {
            ws.getRow(row).getCell(cell).value = renderText(
              record[dataIndex]
            ).value;
            ws.getRow(row).getCell(cell++).numFmt = renderText(
              record[dataIndex]
            ).numFmt;
          } else ws.getRow(row).getCell(cell++).value = record[dataIndex];
        }
      });
    });
  }

  if (!addToExisting) {
    // Save the Excel workbook as a file
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export data table to Excel
export async function exportTableData(columns, exportData, filename) {

  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  // Extract necessary data from exportData
  const { tableData } = exportData;

  // Check if data is available
  if (Object.keys(tableData).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    const { data } = tableData;

    const children =
      columns.findIndex(({ children }) => children && children.length) > -1;

    // columns
    columns.forEach(({ title, children }) => {
      if (children) {
        ws.mergeCells(row, cell, row, cell + (children.length - 1));
        cell += children.length - 1;
      }
      ws.getRow(row).getCell(cell).value = title;

      if (children) {
        let childCell = 0;
        children.forEach(({ title }) => {
          const offset = cell - (children.length - 1);
          ws.getRow(row + 1).getCell(offset + childCell++).value = title;
        });
      }
      cell++;
    });
    if (children) nextRow();

    // data
    data.forEach((record) => {
      nextRow();
      columns.forEach(({ dataIndex, children, renderText }) => {
        if (children) {
          children.forEach(({ dataIndex, renderText }) => {
            if (renderText) {
              ws.getRow(row).getCell(cell).value = renderText(
                record[dataIndex]
              ).value;
              ws.getRow(row).getCell(cell++).numFmt = renderText(
                record[dataIndex]
              ).numFmt;
            } else ws.getRow(row).getCell(cell++).value = record[dataIndex];
          });
        } else {
          if (renderText) {
            ws.getRow(row).getCell(cell).value = renderText(
              record[dataIndex]
            ).value;
            ws.getRow(row).getCell(cell++).numFmt = renderText(
              record[dataIndex]
            ).numFmt;
          } else ws.getRow(row).getCell(cell++).value = record[dataIndex];
        }
      });
    });
  }

  // Save the Excel workbook as a file
  const buf = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
}

export async function exportUsageDashboardData(exportData, filename) {

  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);
  let row = 1;
  let cell = 1;
  const nextRow = () => {
    row++;
    cell = 1;
  };
  ws.getRow(row).getCell(cell).value = "Date"
  cell++;
  ws.getRow(row).getCell(cell).value = `${moment(exportData.datePicker[0]?._d).format('YYYY-MM-DD')} 00:00 To ${moment(exportData.datePicker[1]?._d).format('YYYY-MM-DD')} 23:59`;

  nextRow()
  nextRow()

  ws.getRow(row).getCell(cell).value = "Total Users"
  cell++;
  ws.getRow(row).getCell(cell).value = exportData.reportOpen && exportData.reportOpen.totalUser ? exportData.reportOpen.totalUser : 0;
  nextRow()

  ws.getRow(row).getCell(cell).value = "Total View Pages"
  cell++;
  ws.getRow(row).getCell(cell).value = exportData.reportOpen && exportData.reportOpen.totalPage ? exportData.reportOpen.totalPage : 0;
  nextRow()

  ws.getRow(row).getCell(cell).value = "Unique Users"
  cell++;
  ws.getRow(row).getCell(cell).value = exportData.reportOpen && exportData.reportOpen.uniqueUser ? exportData.reportOpen.uniqueUser : 0;
  nextRow()

  ws.getRow(row).getCell(cell).value = "Unique User Trend"
  cell++;
  ws.getRow(row).getCell(cell).value = exportData.reportOpen && exportData.reportOpen.trend ? `${exportData.reportOpen.trend} %` : '0 %';
  nextRow()
  nextRow()

  if (exportData.userDataChart && exportData.userDataChart.length) {
    const outputArray = [];
    // Process the input array
    for (const entry of exportData.userDataChart) {
      const existingEntry = outputArray.find(item => item.time === entry.time);

      if (existingEntry) {
        if (entry.name === "Total User") {
          existingEntry.totalUsers = parseInt(entry.chart);
        } else if (entry.name === "Unique User") {
          existingEntry.uniqueUsers = parseInt(entry.chart);
        }
      } else {
        const newEntry = {
          time: entry.time,
          totalUsers: entry.name === "Total User" ? parseInt(entry.chart) : 0,
          uniqueUsers: entry.name === "Unique User" ? parseInt(entry.chart) : 0,
        };
        outputArray.push(newEntry);
      }
    }
    ws.getRow(row).getCell(cell).value = "View trend data"
    nextRow()
    nextRow()

    ws.getRow(row).getCell(cell).value = "Time"
    cell++;
    ws.getRow(row).getCell(cell).value = "Total User"
    cell++;
    ws.getRow(row).getCell(cell).value = "Unique User"
    outputArray.map((i) => {
      nextRow()
      ws.getRow(row).getCell(cell).value = i.time
      cell++;
      ws.getRow(row).getCell(cell).value = i.totalUsers
      cell++;
      ws.getRow(row).getCell(cell).value = i.uniqueUsers
    })
  } else {
    ws.getRow(row).getCell(cell).value = "No data available for selected time period"
  }

  nextRow()
  nextRow()
  ws.getRow(row).getCell(cell).value = "Country wise usage"
  cell++;
  nextRow()
  nextRow()
  if (exportData.countries && exportData.countries.length) {
    ws.getRow(row).getCell(cell).value = "Countries"
    cell++;
    ws.getRow(row).getCell(cell).value = "Usage (%)"

    exportData.countries.map((i) => {
      nextRow()
      ws.getRow(row).getCell(cell).value = i.countries
      cell++;
      ws.getRow(row).getCell(cell).value = i.Users
    })
  } else {
    ws.getRow(row).getCell(cell).value = "No data available for country wise usage"
  }
  nextRow()
  nextRow()
  ws.getRow(row).getCell(cell).value = "Brand wise usage"
  cell++;
  nextRow()
  nextRow()
  if (exportData.brand && exportData.brand.length) {
    ws.getRow(row).getCell(cell).value = "Brand"
    cell++;
    ws.getRow(row).getCell(cell).value = "Usage (%)"

    exportData.brand.map((i) => {
      nextRow()
      ws.getRow(row).getCell(cell).value = i.brands
      cell++;
      ws.getRow(row).getCell(cell).value = i.Users
    })
  } else {
    ws.getRow(row).getCell(cell).value = "No data available for brand wise usage"
  }

  nextRow()
  nextRow()
  ws.getRow(row).getCell(cell).value = "Browser wise usage"
  cell++;
  nextRow()
  nextRow()
  if (exportData.browsers && exportData.browsers.length) {
    ws.getRow(row).getCell(cell).value = "Browser"
    cell++;
    ws.getRow(row).getCell(cell).value = "Usage (%)"

    exportData.browsers.map((i) => {
      nextRow()
      ws.getRow(row).getCell(cell).value = i.browser
      cell++;
      ws.getRow(row).getCell(cell).value = i.Users
    })
  } else {
    ws.getRow(row).getCell(cell).value = "No data available for browsers wise usage"
  }



  const buf = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
}
export async function exportUsagePageviewData(exportData, filename) {

  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);
  let row = 1;
  let cell = 1;
  const nextRow = () => {
    row++;
    cell = 1;
  };
  ws.getRow(row).getCell(cell).value = "Date"
  cell++;
  ws.getRow(row).getCell(cell).value = `${moment(exportData.datePicker[0]?._d).format('YYYY-MM-DD')} 00:00 To ${moment(exportData.datePicker[1]?._d).format('YYYY-MM-DD')} 23:59`;

  nextRow()
  nextRow()

  ws.getRow(row).getCell(cell).value = "Page wise total views, unique users count and page loadtime"
  nextRow()
  nextRow()

  if (exportData.alertspageCount && exportData.alertspageCount.length) {
    ws.getRow(row).getCell(cell).value = "Page"
    cell++;
    ws.getRow(row).getCell(cell).value = "Views"
    cell++;
    ws.getRow(row).getCell(cell).value = "Unique users"
    cell++;
    ws.getRow(row).getCell(cell).value = "Load time"

    exportData.alertspageCount.map((i) => {
      nextRow()
      ws.getRow(row).getCell(cell).value = i.PAGE
      cell++;
      ws.getRow(row).getCell(cell).value = i.PAGE_VIEWS
      cell++;
      ws.getRow(row).getCell(cell).value = i.UNIQUE_USERS
      cell++;
      ws.getRow(row).getCell(cell).value = i.AVERAGE_LOAD_TIME

    })
  } else {
    ws.getRow(row).getCell(cell).value = "No data available"
  }

  nextRow()
  nextRow()
  ws.getRow(row).getCell(cell).value = `${exportData.pieHeader} : views by brand`
  nextRow()
  nextRow()

  if (exportData.totalBrand && exportData.totalBrand.length) {
    ws.getRow(row).getCell(cell).value = "Brands"
    cell++;
    ws.getRow(row).getCell(cell).value = "Views"


    exportData.totalBrand.map((i) => {
      nextRow()
      ws.getRow(row).getCell(cell).value = i.brands
      cell++;
      ws.getRow(row).getCell(cell).value = i.value
    })
  } else {
    ws.getRow(row).getCell(cell).value = "No data available"
  }
  nextRow()
  nextRow()
  ws.getRow(row).getCell(cell).value = `${exportData.pieHeader} : views by brand`
  nextRow()
  nextRow()

  if (exportData.totalBrand && exportData.totalBrand.length) {
    ws.getRow(row).getCell(cell).value = "Brands"
    cell++;
    ws.getRow(row).getCell(cell).value = "Views"


    exportData.totalBrand.map((i) => {
      nextRow()
      ws.getRow(row).getCell(cell).value = i.brands
      cell++;
      ws.getRow(row).getCell(cell).value = i.value
    })
  } else {
    ws.getRow(row).getCell(cell).value = "No data available"
  }

  nextRow()
  nextRow()
  ws.getRow(row).getCell(cell).value = `${exportData.pieHeader} : views by users`
  nextRow()
  nextRow()
  if (exportData.totalTableData && exportData.totalTableData.length) {
    ws.getRow(row).getCell(cell).value = "User"
    cell++;
    ws.getRow(row).getCell(cell).value = "Views"
    cell++;
    ws.getRow(row).getCell(cell).value = "Brand view count"
    cell++;
    ws.getRow(row).getCell(cell).value = "country"
    cell++;
    ws.getRow(row).getCell(cell).value = "Number of logins"


    exportData.totalTableData.map((i) => {
      nextRow()
      ws.getRow(row).getCell(cell).value = i.UNIQUE_USER
      cell++;
      ws.getRow(row).getCell(cell).value = i.TOTAL_PAGES
      cell++;
      ws.getRow(row).getCell(cell).value = i.COUNTRY
      cell++;
      ws.getRow(row).getCell(cell).value = i.BRAND_COUNT
      cell++;
      ws.getRow(row).getCell(cell).value = i.SESSION_COUNT
    })
  } else {
    ws.getRow(row).getCell(cell).value = "No data available"
  }




  const buf = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
}
const totalEventCount = (events, user) => {
  return events.reduce((count, key) => {
    if (user[key]) {
      count += user[key]
    }
    return count
  }, 0)
}
export async function exportUsageCustomEventData(exportData, filename) {

  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);
  let row = 1;
  let cell = 1;
  const nextRow = () => {
    row++;
    cell = 1;
  };
  ws.getRow(row).getCell(cell).value = "Date"
  cell++;
  ws.getRow(row).getCell(cell).value = `${moment(exportData.datePicker[0]?._d).format('YYYY-MM-DD')} 00:00 To ${moment(exportData.datePicker[1]?._d).format('YYYY-MM-DD')} 23:59`;

  nextRow()
  nextRow()

  ws.getRow(row).getCell(cell).value = "Alerts Bookmarked"
  cell++;
  ws.getRow(row).getCell(cell).value = exportData.pptExportCount[2];
  nextRow()

  ws.getRow(row).getCell(cell).value = "Total PPT Exports"
  cell++;
  ws.getRow(row).getCell(cell).value = exportData.pptExportCount[0];
  nextRow()

  ws.getRow(row).getCell(cell).value = "Total Excel Exports"
  cell++;
  ws.getRow(row).getCell(cell).value = exportData.pptExportCount[1];
  nextRow()

  ws.getRow(row).getCell(cell).value = "New Self Serve Reports Created"
  cell++;
  ws.getRow(row).getCell(cell).value = exportData.pptExportCount[3];
  nextRow()

  ws.getRow(row).getCell(cell).value = "Data Export"
  cell++;
  ws.getRow(row).getCell(cell).value = exportData.pptExportCount[4];
  nextRow()
  nextRow()


  nextRow()
  ws.getRow(row).getCell(cell).value = "User"
  cell++;
  ws.getRow(row).getCell(cell).value = "Bookmarks"
  cell++;
  ws.getRow(row).getCell(cell).value = "PPT Exports"
  cell++;
  ws.getRow(row).getCell(cell).value = "Excel Exports"
  cell++;
  ws.getRow(row).getCell(cell).value = "Report Created"
  cell++;
  ws.getRow(row).getCell(cell).value = "Report Updated"


  exportData.card3Table.map((user) => {
    nextRow()
    ws.getRow(row).getCell(cell).value = user.unique_user
    cell++;
    ws.getRow(row).getCell(cell).value = totalEventCount(["BOOKMARK_FOR_PERFORMANCE", "BOOKMARK_FOR_COMPITITION", "BOOKMARK_FOR_DISTRIBUTION", "BOOKMARK_FOR_INNOVATION"], user)
    cell++;
    ws.getRow(row).getCell(cell).value = totalEventCount(["PPT_FOR_PERFORMANCE", "PPT_FOR_VOLUMESHIFTING", "PPT_FOR_DISTRIBUTION", "PPT_FOR_INNOVATION", "PPT_FOR_DIAGNOSTICS", "PPT_FOR_SEARCH_TERM", "PPT_FOR_SHARE_OF_SHELF", "PPT_FOR_BE_AVAILABLE"], user)
    cell++;
    ws.getRow(row).getCell(cell).value = totalEventCount(["EXCEL_FOR_PERFORMANCE", "EXCEL_FOR_VOLUMESHIFTING", "EXCEL_FOR_DISTRIBUTION", "EXCEL_FOR_INNOVATION", "EXCEL_FOR_DIAGNOSTICS", "EXCEL_FOR_SEARCH_TERM", "EXCEL_FOR_SHARE_OF_SHELF", "EXCEL_FOR_BE_AVAILABLE"], user)
    cell++;
    ws.getRow(row).getCell(cell).value = totalEventCount(["CREATE_SELF_SERVE_REPORT_PERFORMANCE", "CREATE_SELF_SERVE_REPORT_COMPITITION", "CREATE_SELF_SERVE_REPORT_DISTRIBUTION", "CREATE_SELF_SERVE_REPORT_INNOVATION"], user)
    cell++;
    ws.getRow(row).getCell(cell).value = totalEventCount(["ADD_EXISTING_REPORT_PERFORMANCE", "ADD_EXISTING_REPORT_COMPITITON", "ADD_EXISTING_REPORT_DISTRIBUTION", "ADD_EXISTING_REPORT_INNOVATION"], user)
  })
  const buf = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
}

// Function to export waterfall data to Excel
export async function exportWaterfall(exportData, filename, addToExisting = false, wb = null) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  // Extract necessary data from exportData
  const { filters, waterfall } = exportData;

  // Check if data is available
  if (Object.keys(waterfall).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = filters[filter].join();
    });

    // Extract data from waterfall
    const { filters: subFilters, data } = waterfall;

    // Filters
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = "Sub Filters";
    Object.keys(subFilters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = subFilters[filter].join();
    });

    // columns
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell++).value = "Label";
    ws.getRow(row).getCell(cell++).value = "Value";

    // data
    data.forEach(({ label, value, numFmt }) => {
      nextRow();
      ws.getRow(row).getCell(cell++).value = label;
      ws.getRow(row).getCell(cell).value = value;
      ws.getRow(row).getCell(cell++).numFmt = numFmt;

      // if (legend.toLowerCase() === "decrease")
      //   ws.getRow(row).getCell(cell++).value = -value;
      // else ws.getRow(row).getCell(cell++).value = value;
    });
  }

  if (!addToExisting) {
    // Save the Excel workbook as a file
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export waterfall data with percentage to Excel
export async function exportWaterfallPercentage(exportData, filename, addToExisting = false, wb = null) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  // Extract necessary data from exportData
  const { filters, waterfall } = exportData;

  if (Object.keys(waterfall).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = filters[filter].join();
    });

    // Extract data from waterfall
    const { data } = waterfall;

    // columns
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell++).value = "Label";
    ws.getRow(row).getCell(cell++).value = "Value";
    ws.getRow(row).getCell(cell++).value = "Value %";

    // data
    data.forEach(({ label, difference, pvalue }, index) => {
      nextRow();
      ws.getRow(row).getCell(cell++).value = label;
      ws.getRow(row).getCell(cell).value = formatNumberExcel(difference);
      ws.getRow(row).getCell(cell++).numFmt = numFmt.comma;

      if (index === 0 || index === data.length - 1) {
        ws.getRow(row).getCell(cell).value = formatNumberExcel(difference);
        ws.getRow(row).getCell(cell++).numFmt = numFmt.comma;
      } else {
        ws.getRow(row).getCell(cell).value = formatNumberExcel(pvalue, true);
        ws.getRow(row).getCell(cell++).numFmt = numFmt.percentage;
      }
    });
  }
  if (!addToExisting) {
    // Save the Excel workbook as a file
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export bar chart data to Excel
export async function exportBar(exportData, filename, addToExisting = false, wb = null) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  // Extract necessary data from exportData
  const { filters, bar } = exportData;

  // Check if data is available
  if (Object.keys(bar).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = filters[filter].join();
    });

    // Extract data from bar
    const { filters: subFilters, sku } = bar;

    // Filters
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = "Sub Filters";
    Object.keys(subFilters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = subFilters[filter].join();
    });

    // Function to render SKU data
    const { top, bottom } = sku;

    const renderSKU = (sku) => {
      nextRow();
      nextRow();
      ws.getRow(row).getCell(cell++).value = sku.title;
      // ws.getRow(row).getCell(cell++).value = sku.legend;

      // columns
      nextRow();
      ws.getRow(row).getCell(cell++).value = "Label";
      ws.getRow(row).getCell(cell++).value = "CY";
      ws.getRow(row).getCell(cell++).value = "YA";

      // data
      sku.data.forEach(({ label, current_year, year_ago }) => {
        nextRow();
        ws.getRow(row).getCell(cell++).value = label;

        if (subFilters.KPI) {
          // Apply different formatting based on KPI type

          if (
            subFilters.KPI[0].toLowerCase() === "rsv" ||
            subFilters.KPI[0].toLowerCase() === "velocity"
          ) {
            ws.getRow(row).getCell(cell).value =
              formatNumberExcel(current_year);
            ws.getRow(row).getCell(cell++).numFmt = numFmt.dollar;
            ws.getRow(row).getCell(cell).value = formatNumberExcel(year_ago);
            ws.getRow(row).getCell(cell++).numFmt = numFmt.dollar;
          } else if (subFilters.KPI[0].toLowerCase() === "rsv share") {
            ws.getRow(row).getCell(cell).value = formatNumberExcel(
              current_year,
              true
            );
            ws.getRow(row).getCell(cell++).numFmt = numFmt.percentage;
            ws.getRow(row).getCell(cell).value = formatNumberExcel(
              year_ago,
              true
            );
            ws.getRow(row).getCell(cell++).numFmt = numFmt.percentage;
          } else if (
            subFilters.KPI[0].toLowerCase() === "eq lbs"
          ) {
            ws.getRow(row).getCell(cell).value =
              formatNumberExcel(current_year);
            ws.getRow(row).getCell(cell++).numFmt = numFmt.comma;
            ws.getRow(row).getCell(cell).value = formatNumberExcel(year_ago);
            ws.getRow(row).getCell(cell++).numFmt = numFmt.comma;
          } else {
            ws.getRow(row).getCell(cell).value =
              formatNumberExcel(current_year);
            ws.getRow(row).getCell(cell++).numFmt = numFmt.number;
            ws.getRow(row).getCell(cell).value = formatNumberExcel(year_ago);
            ws.getRow(row).getCell(cell++).numFmt = numFmt.number;
          }
        }
      });
    };

    // Render data for top and bottom SKU
    renderSKU(top);
    renderSKU(bottom);
  }

  if (!addToExisting) {
    // Save the Excel workbook as a file
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export launch performance trend data to Excel
export async function exportLaunchPerfTrend(exportData, filename, filters, localFilter, selectedRow, addToExisting = false, wb = null) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  // const { filters, bar } = exportData;

  if (Object.keys(exportData).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      if (filter === 'product_pet' || filter === 'product_technology' || filter === 'innovation_type' || filter === 'time_year' || filter === 'innovation') {
        nextRow();
        if (filter === 'time_year') {
          ws.getRow(row).getCell(++cell).value = 'launch_year';
          ws.getRow(row).getCell(++cell).value = filters[filter].join();
        } else {
          ws.getRow(row).getCell(++cell).value = filter;
          ws.getRow(row).getCell(++cell).value = filters[filter].join();
        }
      }
    });

    // const { filters: subFilters, sku } = bar;

    // Filters
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = "Sub Filters";
    nextRow();
    Object.keys(localFilter).forEach((filter) => {
      if (filter === 'selected_innovation' || filter === 'trendchart_kpi' || filter === 'benchmark_values') {
        nextRow();
        ws.getRow(row).getCell(++cell).value = filter;
        ws.getRow(row).getCell(++cell).value = localFilter[filter].join();
      }
    });

    // const { top, bottom } = sku;

    const renderSKU = (exportData) => {
      nextRow();
      nextRow();
      ws.getRow(row).getCell(cell++).value = `Launch Performance Trend - ${selectedRow[0] && selectedRow[0][rowKey]}`;
      nextRow();
      // ws.getRow(row).getCell(cell++).value = sku.legend;

      // columns
      nextRow();
      ws.getRow(row).getCell(cell++).value = "Time Week";
      ws.getRow(row).getCell(cell++).value = "KPI Result";
      ws.getRow(row).getCell(cell++).value = "Innovation";

      // data
      exportData && exportData.forEach(({ time_week, kpi_result, innovation }) => {
        nextRow();
        ws.getRow(row).getCell(cell++).value = time_week;
        ws.getRow(row).getCell(cell++).value = kpi_result;
        // ws.getRow(row).getCell(cell++).numFmt = numFmt.number;
        ws.getRow(row).getCell(cell++).value = innovation;

      });
    };

    renderSKU(exportData);
    // renderSKU(bottom);
  }

  if (!addToExisting) {
    // Save the Excel workbook as a file
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

export async function exportLine(
  exportData,
  filename,
  addToExisting = false,
  wb = null
) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  const { filters, line } = exportData;

  if (Object.keys(line).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = filters[filter].join();
    });

    const { tdpVelocity, velocityCsl, title } = line;

    nextRow();
    nextRow();

    ws.getRow(row).getCell(cell).value = title;

    nextRow();
    nextRow();

    const renderLine = (report, type) => {
      ws.getRow(row).getCell(cell++).value = report.title;
      nextRow();

      if (type === "tv") {
        ws.getRow(row).getCell(cell++).value = "Period";
        ws.getRow(row).getCell(cell++).value = "TDP";
        ws.getRow(row).getCell(cell++).value = "Velocity";
        ws.getRow(row).getCell(cell++).value = "TDP YA";
        ws.getRow(row).getCell(cell++).value = "Velocity YA";

        // data
        report.data.forEach(
          ({ period_year, tdp, velocity, tdp_ya, velocity_ya }) => {
            nextRow();
            ws.getRow(row).getCell(cell++).value = period_year;
            ws.getRow(row).getCell(cell).value = formatNumberExcel(tdp);
            ws.getRow(row).getCell(cell++).numFmt = numFmt.number;
            ws.getRow(row).getCell(cell).value = formatNumberExcel(velocity);
            ws.getRow(row).getCell(cell++).numFmt = numFmt.number;
            ws.getRow(row).getCell(cell).value = formatNumberExcel(tdp_ya);
            ws.getRow(row).getCell(cell++).numFmt = numFmt.number;
            ws.getRow(row).getCell(cell).value = formatNumberExcel(velocity_ya);
            ws.getRow(row).getCell(cell++).numFmt = numFmt.number;
          }
        );
      } else if (type === "vc") {
        ws.getRow(row).getCell(cell++).value = "Period";
        ws.getRow(row).getCell(cell++).value = "CSL";
        ws.getRow(row).getCell(cell++).value = "Velocity";
        ws.getRow(row).getCell(cell++).value = "CSL YA";
        ws.getRow(row).getCell(cell++).value = "Velocity YA";

        // data
        report.data.forEach(
          ({ period_year, csl, velocity, csl_ya, velocity_ya }) => {
            nextRow();
            ws.getRow(row).getCell(cell++).value = period_year;
            ws.getRow(row).getCell(cell).value = formatNumberExcel(csl);
            ws.getRow(row).getCell(cell++).numFmt = numFmt.number;
            ws.getRow(row).getCell(cell).value = formatNumberExcel(velocity);
            ws.getRow(row).getCell(cell++).numFmt = numFmt.number;
            ws.getRow(row).getCell(cell).value = formatNumberExcel(csl_ya);
            ws.getRow(row).getCell(cell++).numFmt = numFmt.number;
            ws.getRow(row).getCell(cell).value = formatNumberExcel(velocity_ya);
            ws.getRow(row).getCell(cell++).numFmt = numFmt.number;
          }
        );
      }

      nextRow();
      nextRow();
    };

    renderLine(tdpVelocity, "tv");
    renderLine(velocityCsl, "vc");
  }

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}
export async function exportGsvDemandChart(
  exportData,
  filename,
  filters,
  addToExisting = false,
  wb = null
) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  // const { filters, line } = exportData;

  if (Object.keys(exportData).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      if (filter === 'product_pet' || filter === 'product_technology' || filter === 'innovation_type' || filter === 'time_year' || filter === 'innovation') {
        nextRow();
        if (filter === 'time_year') {
          ws.getRow(row).getCell(++cell).value = 'launch_year';
          ws.getRow(row).getCell(++cell).value = filters[filter].join();
        } else {
          ws.getRow(row).getCell(++cell).value = filter;
          ws.getRow(row).getCell(++cell).value = filters[filter].join();
        }
      }
    });

    nextRow();
    nextRow();

    ws.getRow(row).getCell(cell).value = "GSV : Actual vs Demand Forecast - Total Market";

    nextRow();
    nextRow();

    const renderLine = (report) => {

      ws.getRow(row).getCell(cell++).value = "Period";
      ws.getRow(row).getCell(cell++).value = "Innovation";
      ws.getRow(row).getCell(cell++).value = "Type";
      ws.getRow(row).getCell(cell++).value = "Value";

      // data
      report.forEach(
        ({ period, innovation, value }) => {
          nextRow();
          ws.getRow(row).getCell(cell++).value = period;
          ws.getRow(row).getCell(cell++).value = innovation.split("_")[1];
          ws.getRow(row).getCell(cell++).value = innovation.split("_")[0];
          ws.getRow(row).getCell(cell).value = formatNumberExcel(value);
          ws.getRow(row).getCell(cell++).numFmt = numFmt.number;
        }
      );

      nextRow();
      nextRow();
    };

    renderLine(exportData);
  }

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export GSV demand chart data to Excel
export async function exportTrend(
  exportData,
  filename,
  addToExisting = false,
  wb = null
) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  const { filters, line } = exportData;

  if (Object.keys(line).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = filters[filter].join();
    });

    const { velocityCsl, title } = line;

    nextRow();
    nextRow();

    ws.getRow(row).getCell(cell).value = title;

    nextRow();
    nextRow();

    const renderLine = (report) => {
      ws.getRow(row).getCell(cell++).value = report.title;
      nextRow();

      ws.getRow(row).getCell(cell++).value = "Period";
      ws.getRow(row).getCell(cell++).value = "CSL";
      // ws.getRow(row).getCell(cell++).value = "Velocity";
      ws.getRow(row).getCell(cell++).value = "CSL YA";
      // ws.getRow(row).getCell(cell++).value = "Velocity YA";

      // data
      report.data.forEach(
        ({ period_year, csl, velocity, csl_ya, velocity_ya }) => {
          nextRow();
          ws.getRow(row).getCell(cell++).value = period_year;
          ws.getRow(row).getCell(cell).value = formatNumberExcel(csl);
          ws.getRow(row).getCell(cell++).numFmt = numFmt.number;
          // ws.getRow(row).getCell(cell).value = formatNumberExcel(velocity);
          // ws.getRow(row).getCell(cell++).numFmt = numFmt.number;
          ws.getRow(row).getCell(cell).value = formatNumberExcel(csl_ya);
          ws.getRow(row).getCell(cell++).numFmt = numFmt.number;
          // ws.getRow(row).getCell(cell).value = formatNumberExcel(velocity_ya);
          // ws.getRow(row).getCell(cell++).numFmt = numFmt.number;
        }
      );

      nextRow();
      nextRow();
    };

    renderLine(velocityCsl);
  }

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export Performance KPIs data to Excel
export async function exportPerfKPIs(exportData, filename, addToExisting = false, wb = null) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  const { filters, kpi } = exportData;
  // dd.mergeCells("B2:H2");

  if (Object.keys(kpi).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    const { header, data, gsv, nsv } = kpi;

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = filters[filter].join();
    });

    // KPIs
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = "KPIs";
    nextRow();
    ws.getRow(row).getCell(++cell).value = "MKT RSV";
    ws.getRow(row).getCell(++cell).value = formatNumberExcel(
      data.sum_fact_dollar
    );
    ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;
    nextRow();
    ws.getRow(row).getCell(++cell).value = "% Growth";
    ws.getRow(row).getCell(++cell).value = formatNumberExcel(
      data.sum_fact_percent_growth,
      true
    );
    ws.getRow(row).getCell(cell).numFmt = numFmt.percentage;
    nextRow();
    ws.getRow(row).getCell(++cell).value = "TDP";
    ws.getRow(row).getCell(++cell).value = formatNumberExcel(data.sum_fact_tdp);
    ws.getRow(row).getCell(cell).numFmt = numFmt.number;
    nextRow();
    ws.getRow(row).getCell(++cell).value = "$/TDP";
    ws.getRow(row).getCell(++cell).value = formatNumberExcel(
      data.sum_fact_dollar_acv
    ); // $
    ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;
    nextRow();
    ws.getRow(row).getCell(++cell).value = "CSL %";
    ws.getRow(row).getCell(++cell).value = formatNumberExcel(
      data.sum_fact_percent_csl_worst_case_4w, true
    );
    ws.getRow(row).getCell(cell).numFmt = numFmt.percentage;

    // if (header) {
    //   nextRow();
    //   nextRow();
    //   ws.getRow(row).getCell(cell).value = header;  
    // }

    // Growth KPI
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = "Growth";

    nextRow();
    const renderGrowth = (growth) => {
      ws.getRow(row).getCell(++cell).value = `${growth.fieldTitle} - ${growth.periodToggle ? "Period" : "YTD"
        }`;
      nextRow();
      ws.getRow(row).getCell(++cell).value = growth.periodToggle
        ? "Period"
        : "YTD";
      if (growth.periodToggle) {
        if (growth.fieldTitle.toLowerCase().includes("mac")) {
          ws.getRow(row).getCell(++cell).value =
            growth.data.findIndex(({ type }) => type === "Period") > -1
              ? formatNumberExcel(growth.kpiData.percent_growth)
              : null;
          ws.getRow(row).getCell(cell).numFmt = numFmt.number;
        } else {
          ws.getRow(row).getCell(++cell).value =
            growth.data.findIndex(({ type }) => type === "Period") > -1
              ? formatNumberExcel(growth.kpiData.percent_growth, true)
              : null;
          ws.getRow(row).getCell(cell).numFmt = numFmt.percentage;
        }
      } else {
        if (growth.fieldTitle.toLowerCase().includes("mac")) {
          ws.getRow(row).getCell(++cell).value =
            growth.data.findIndex(({ type }) => type === "YTD") > -1
              ? formatNumberExcel(growth.kpiData.percent_growth_ytd)
              : null;
          ws.getRow(row).getCell(cell).numFmt = numFmt.number;
        } else {
          ws.getRow(row).getCell(++cell).value =
            growth.data.findIndex(({ type }) => type === "YTD") > -1
              ? formatNumberExcel(growth.kpiData.percent_growth_ytd, true)
              : null;
          ws.getRow(row).getCell(cell).numFmt = numFmt.percentage;
        }
      }

      (growth.periodToggle ? growth.kpiValues : growth.ytdValues).forEach(
        (val) => {
          nextRow();
          ws.getRow(row).getCell(++cell).value = val.title;
          if (val.title.toLowerCase().includes("mac")) {
            ws.getRow(row).getCell(++cell).value = formatNumberExcel(val.valueExport, true);
            ws.getRow(row).getCell(cell).numFmt = numFmt.percentage;
          } else {
            ws.getRow(row).getCell(++cell).value = formatNumberExcel(val.valueExport);
            ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;
          }
        }
      );

      nextRow();
      nextRow();
    };

    renderGrowth(gsv);
    renderGrowth(nsv);
    renderGrowth(kpi["mac%"]);
  }

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export Innovation KPIs data to Excel
export async function exportInnoKPIs(exportData, filename, filters, localFilter, addToExisting = false, wb = null) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  // const { filters, kpi } = exportData;
  // dd.mergeCells("B2:H2");

  // Check if data is available
  if (Object.keys(exportData).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    // const { header, data, gsv, nsv } = kpi;

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      if (filter === 'product_pet' || filter === 'product_technology' || filter === 'innovation_type' || filter === 'time_year' || filter === 'innovation' || filter === 'selected_innovation') {
        nextRow();
        if (filter === 'time_year') {
          ws.getRow(row).getCell(++cell).value = 'launch_year';
          ws.getRow(row).getCell(++cell).value = filters[filter].join();
        }
        else if (filter === 'selected_innovation') {
          ws.getRow(row).getCell(++cell).value = filter;
          ws.getRow(row).getCell(++cell).value = localFilter.selected_innovation[0];
        }
        else {
          ws.getRow(row).getCell(++cell).value = filter;
          ws.getRow(row).getCell(++cell).value = filters[filter].join();
        }
      }
    });

    // KPIs 
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = "KPIs";
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = "Launch Performance: Total Market";
    nextRow();
    nextRow();
    ws.getRow(row).getCell(++cell).value = `MKT RSV YEE (${exportData?.launch_year})`;
    nextRow();
    ws.getRow(row).getCell(++cell).value = "Target";
    ws.getRow(row).getCell(++cell).value = formatNumberExcel(
      exportData.fact_rsv_target_yee
    );
    ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;
    nextRow();
    ws.getRow(row).getCell(++cell).value = "Actual";
    ws.getRow(row).getCell(++cell).value = formatNumberExcel(
      exportData.fact_rsv_actuals_yee
    );
    ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;
    nextRow();
    ws.getRow(row).getCell(++cell).value = "Variance";
    ws.getRow(row).getCell(++cell).value = formatNumberExcel(
      exportData.rsv_yee_variance,
    );
    ws.getRow(row).getCell(cell).numFmt = numFmt.percentage;
    nextRow();
    nextRow();
    ws.getRow(row).getCell(++cell).value = `GSV YEE (${exportData?.launch_year})`;
    nextRow();
    ws.getRow(row).getCell(++cell).value = "Target";
    ws.getRow(row).getCell(++cell).value = formatNumberExcel(
      exportData.fact_gsv_target_yee
    );
    ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;
    nextRow();
    ws.getRow(row).getCell(++cell).value = "Actual";
    ws.getRow(row).getCell(++cell).value = formatNumberExcel(
      exportData.fact_gsv_actuals_yee
    );
    ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;
    nextRow();
    ws.getRow(row).getCell(++cell).value = "Variance";
    ws.getRow(row).getCell(++cell).value = formatNumberExcel(
      exportData.gsv_yee_variance,
    );
    ws.getRow(row).getCell(cell).numFmt = numFmt.percentage;
    nextRow();
    nextRow();
    // ws.getRow(row).getCell(++cell).value = `Media Spends (${exportData?.launch_year})`;
    // nextRow();
    // ws.getRow(row).getCell(++cell).value = "Target";
    // ws.getRow(row).getCell(++cell).value = formatNumberExcel(
    //   exportData.fact_media_spends_target
    // );
    // ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;
    // nextRow();
    // ws.getRow(row).getCell(++cell).value = "Actual";
    // ws.getRow(row).getCell(++cell).value = formatNumberExcel(
    //   exportData.fact_media_spends_actuals
    // );
    // ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;
    // nextRow();
    // ws.getRow(row).getCell(++cell).value = "Variance";
    // ws.getRow(row).getCell(++cell).value = formatNumberExcel(
    //   exportData.media_variance,
    // );
    // ws.getRow(row).getCell(cell).numFmt = numFmt.percentage;
    // nextRow();
    // nextRow();
    // ws.getRow(row).getCell(++cell).value = `TDP (${exportData?.launch_year})`;
    // nextRow();
    // ws.getRow(row).getCell(++cell).value = "Target";
    // ws.getRow(row).getCell(++cell).value = formatNumberExcel(
    //   exportData.fact_tdp_target
    // );
    // ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;
    // nextRow();
    // ws.getRow(row).getCell(++cell).value = "Actual";
    // ws.getRow(row).getCell(++cell).value = formatNumberExcel(
    //   exportData.fact_tdp_actuals
    // );
    // ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;
    // nextRow();
    // ws.getRow(row).getCell(++cell).value = "Variance";
    // ws.getRow(row).getCell(++cell).value = formatNumberExcel(
    //   exportData.tdp_variance,
    // );
    // ws.getRow(row).getCell(cell).numFmt = numFmt.percentage;

    // if (header) {
    //   nextRow();
    //   nextRow();
    //   ws.getRow(row).getCell(cell).value = header;  
    // }


  }

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export Distribution KPIs data to Excel
export async function exportDistKPIs(
  exportData,
  isMars,
  filename,
  addToExisting = false,
  wb = null
) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  const { filters, kpi } = exportData;
  // dd.mergeCells("B2:H2");

  if (Object.keys(kpi).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    const { tdp, dollarVsTdp, csl } = kpi;

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = filters[filter].join();
    });

    // KPIs
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = "KPIs";

    nextRow();
    // Function to render KPIs in the worksheet
    const renderKPIs = (kpi, percentage = true) => {
      const isCategoryVsYa = !['CSL'].includes(kpi.title);

      ws.getRow(row).getCell(++cell).value = kpi.title;
      nextRow();
      ws.getRow(row).getCell(++cell).value = "Value";
      ws.getRow(row).getCell(++cell).value = kpi.value;
      ws.getRow(row).getCell(cell).numFmt = kpi.numFmt;

      if (percentage) {
        nextRow();
        ws.getRow(row).getCell(++cell).value = "vs YA";
        ws.getRow(row).getCell(++cell).value = formatNumberExcel(
          kpi.vsYA,
          true
        );
        ws.getRow(row).getCell(cell).numFmt = numFmt.percentage;
        if (!isMars || isCategoryVsYa) {
          nextRow();
          ws.getRow(row).getCell(++cell).value = isMars ? "Category vs YA" : "Mars vs YA";
          ws.getRow(row).getCell(++cell).value = formatNumberExcel(
            kpi.marsVsYA,
            true
          );
          ws.getRow(row).getCell(cell).numFmt = numFmt.percentage;
        }
      } else {
        nextRow();
        ws.getRow(row).getCell(++cell).value = "vs YA";
        ws.getRow(row).getCell(++cell).value = formatNumberExcel(kpi.vsYA);
        ws.getRow(row).getCell(cell).numFmt = numFmt.number;
        if (!isMars || isCategoryVsYa) {
          nextRow();
          ws.getRow(row).getCell(++cell).value = isMars ? "Category vs YA" : "Mars vs YA";
          ws.getRow(row).getCell(++cell).value = formatNumberExcel(kpi.marsVsYA);
          ws.getRow(row).getCell(cell).numFmt = numFmt.number;
        }
      }

      nextRow();
      nextRow();
    };

    renderKPIs(tdp);
    renderKPIs(dollarVsTdp);
    renderKPIs(csl, false);
  }

  if (!addToExisting) {
    //save file 
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

export async function exportVolumeKPIs(exportData, isMars, filename, addToExisting = false, wb = null) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  const { filters, kpi } = exportData;
  // dd.mergeCells("B2:H2");

  if (Object.keys(kpi).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    const { data, isBrand, isChannel, isRetailer } = kpi;
    const { time_aggregates } = filters;

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      if (filter === "time_aggregates") ws.getRow(row).getCell(++cell).value = getTimeAggregates(filters[filter]).join();
      else ws.getRow(row).getCell(++cell).value = filters[filter].join();
    });

    // KPIs
    nextRow();
    nextRow();
    nextRow();

    if (isBrand)
      data.forEach((val) => {
        // headers
        ws.getRow(row).getCell(cell++).value = val.brand;

        ws.getRow(row).getCell(cell).value = "$ Sales mm";
        ws.getRow(row + 1).getCell(cell).value = "%CYA";
        ws.getRow(row + 2).getCell(cell).value = "$ Share";
        ws.getRow(row + 3).getCell(cell++).value = "%CYA";

        ws.getRow(row - 1).getCell(cell).value = "TOTAL";

        // $ Sales mm
        ws.getRow(row).getCell(cell).value = val[`dollar_sum_latest_${time_aggregates}`]
          ? formatNumberExcel(val[`dollar_sum_latest_${time_aggregates}`])
          : 0.0;
        ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;

        // %CYA
        ws.getRow(row + 1).getCell(cell).value = val[`dollar_variance_latest_${time_aggregates}`]
          ? formatNumberExcel(val[`dollar_variance_latest_${time_aggregates}`], true)
          : 0.0;
        ws.getRow(row + 1).getCell(cell).numFmt = numFmt.percentage;

        // $ Share
        ws.getRow(row + 2).getCell(cell).value =
          val[`dollar_share_latest_${time_aggregates}`]
            ? formatNumberExcel(val[`dollar_share_latest_${time_aggregates}`], true)
            : 0.0;
        ws.getRow(row + 2).getCell(cell).numFmt = numFmt.percentage;

        // %CYA
        ws.getRow(row + 3).getCell(cell).value = val[`dollar_share_variance_latest_${time_aggregates}`]
          ? formatNumberExcel(val[`dollar_share_variance_latest_${time_aggregates}`])
          : 0.0;
        ws.getRow(row + 3).getCell(cell++).numFmt = numFmt.number;

        // values
        val.table_data.forEach((val1) => {
          ws.getRow(row - 1).getCell(cell).value = val1.customer_channel;

          // $ Sales mm
          ws.getRow(row).getCell(cell).value = val1[`dollar_sum_latest_${time_aggregates}`]
            ? formatNumberExcel(val1[`dollar_sum_latest_${time_aggregates}`].abs)
            : 0.0;
          ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;

          // %CYA
          ws.getRow(row + 1).getCell(cell).value = val1[`dollar_sum_latest_${time_aggregates}`]
            ? formatNumberExcel(val1[`dollar_sum_latest_${time_aggregates}`].change, true)
            : 0.0;
          ws.getRow(row + 1).getCell(cell).numFmt = numFmt.percentage;

          // $ Share
          ws.getRow(row + 2).getCell(cell).value = val1[`dollar_share_latest_${time_aggregates}`]
            ? formatNumberExcel(val1[`dollar_share_latest_${time_aggregates}`].abs, true)
            : 0.0;
          ws.getRow(row + 2).getCell(cell).numFmt = numFmt.percentage;

          // %CYA
          ws.getRow(row + 3).getCell(cell).value = val1[`dollar_share_sum_latest_${time_aggregates}`]
            ? formatNumberExcel(val1[`dollar_share_sum_latest_${time_aggregates}`].change)
            : 0.0;
          ws.getRow(row + 3).getCell(cell++).numFmt = numFmt.number;
        });

        nextRow();
        nextRow();
        nextRow();
        nextRow();
        nextRow();
        nextRow();
      });
    else if (isChannel)
      data.forEach((val) => {
        // headers
        ws.getRow(row).getCell(cell++).value = val.channel;

        ws.getRow(row).getCell(cell).value = "$ Sales mm";
        ws.getRow(row + 1).getCell(cell).value = "%CYA";
        ws.getRow(row + 2).getCell(cell).value = "$ Share";
        ws.getRow(row + 3).getCell(cell++).value = "%CYA";

        // values
        val.cards.forEach((val1) => {
          ws.getRow(row - 1).getCell(cell).value = val1.brand;

          // $ Sales mm
          ws.getRow(row).getCell(cell).value = val1[`dollar_sum_latest_${time_aggregates}`]
            ? formatNumberExcel(val1[`dollar_sum_latest_${time_aggregates}`])
            : 0.0;
          ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;

          // %CYA
          ws.getRow(row + 1).getCell(cell).value = val1[`dollar_variance_latest_${time_aggregates}`]
            ? formatNumberExcel(val1[`dollar_variance_latest_${time_aggregates}`], true)
            : 0.0;
          ws.getRow(row + 1).getCell(cell).numFmt = numFmt.percentage;

          if (val1.brand !== "TOTAL" || !isMars) {
            // $ Share
            ws.getRow(row + 2).getCell(cell).value = val1[`dollar_share_latest_${time_aggregates}`]
              ? formatNumberExcel(val1[`dollar_share_latest_${time_aggregates}`], true)
              : 0.0;
            ws.getRow(row + 2).getCell(cell).numFmt = numFmt.percentage;

            // %CYA
            ws.getRow(row + 3).getCell(cell).value = val1[`dollar_share_variance_latest_${time_aggregates}`]
              ? formatNumberExcel(val1[`dollar_share_variance_latest_${time_aggregates}`])
              : 0.0;
            ws.getRow(row + 3).getCell(cell++).numFmt = numFmt.number;
          } else {
            cell++;
          }
        });

        nextRow();
        nextRow();
        nextRow();
        nextRow();
        nextRow();
        nextRow();
      });
    else if (isRetailer)
      data.forEach((val) => {
        // headers
        ws.getRow(row).getCell(cell++).value = val.retailer;

        ws.getRow(row).getCell(cell).value = "$ Sales mm";
        ws.getRow(row + 1).getCell(cell).value = "%CYA";
        ws.getRow(row + 2).getCell(cell).value = "$ Share";
        ws.getRow(row + 3).getCell(cell++).value = "%CYA";

        // values
        val.cards.forEach((val1) => {
          ws.getRow(row - 1).getCell(cell).value = val1.brand;

          // $ Sales mm
          ws.getRow(row).getCell(cell).value = val1[`dollar_sum_latest_${time_aggregates}`]
            ? formatNumberExcel(val1[`dollar_sum_latest_${time_aggregates}`])
            : 0.0;
          ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;

          // %CYA
          ws.getRow(row + 1).getCell(cell).value = val1[`dollar_variance_latest_${time_aggregates}`]
            ? formatNumberExcel(val1[`dollar_variance_latest_${time_aggregates}`], true)
            : 0.0;
          ws.getRow(row + 1).getCell(cell).numFmt = numFmt.percentage;

          if (val1.brand !== "TOTAL" || !isMars) {
            // $ Share
            ws.getRow(row + 2).getCell(cell).value = val1[`dollar_share_latest_${time_aggregates}`]
              ? formatNumberExcel(val1[`dollar_share_latest_${time_aggregates}`], true)
              : 0.0;
            ws.getRow(row + 2).getCell(cell).numFmt = numFmt.percentage;

            // %CYA
            ws.getRow(row + 3).getCell(cell).value = val1[`dollar_share_variance_latest_${time_aggregates}`]
              ? formatNumberExcel(val1[`dollar_share_variance_latest_${time_aggregates}`])
              : 0.0;
            ws.getRow(row + 3).getCell(cell++).numFmt = numFmt.number;
          } else {
            cell++;
          }
        });

        nextRow();
        nextRow();
        nextRow();
        nextRow();
        nextRow();
        nextRow();
      });
  }
  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export BA KPIs data to Excel
export async function exportBAKPIs(
  exportData,
  filename,
  addToExisting = false,
  wb = null
) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  const { filters, kpi } = exportData;
  // dd.mergeCells("B2:H2");

  if (Object.keys(kpi).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    const { listed, in_stock, csl } = kpi;

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = Array.isArray(filters[filter])
        ? filters[filter].join()
        : JSON.stringify(filters[filter]).replace(/"/gm, "");
    });

    // KPIs
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = "KPIs";

    nextRow();
    // Function to render KPIs in the worksheet
    const renderKPIs = (kpi) => {

      ws.getRow(row).getCell(++cell).value = kpi.title;
      if (kpi.callout) ws.getRow(row).getCell(++cell).value = kpi.callout;

      nextRow();
      ws.getRow(row).getCell(++cell).value = "Value";
      ws.getRow(row).getCell(++cell).value = kpi.abs;
      ws.getRow(row).getCell(cell).numFmt = kpi.absNumFmt;

      nextRow();
      ws.getRow(row).getCell(++cell).value = "vs Benchmark";
      ws.getRow(row).getCell(++cell).value = kpi.variance;
      ws.getRow(row).getCell(cell).numFmt = kpi.varianceNumFmt;

      nextRow();
      nextRow();
    };

    renderKPIs(listed);
    renderKPIs(in_stock);
    renderKPIs(csl);
  }

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export DG KPIs data to Excel
export async function exportDGKPIs(
  exportData,
  filename,
  addToExisting = false,
  wb = null
) {
  console.log("excel", exportData);
  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  const { filters, kpi } = exportData;
  // dd.mergeCells("B2:H2");

  if (Object.keys(kpi).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    const { rsv, dollarShare, eqLbs, lbsShare, unit, unitShare } = kpi;

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = Array.isArray(filters[filter])
        ? filters[filter].join()
        : JSON.stringify(filters[filter]).replace(/"/gm, "");
    });

    // KPIs
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = "KPIs";

    nextRow();
    // Function to render KPIs in the worksheet
    const renderKPIs = (kpi) => {
      ws.getRow(row).getCell(++cell).value = kpi.title;
      if (kpi.callout) ws.getRow(row).getCell(++cell).value = kpi.callout;

      nextRow();
      ws.getRow(row).getCell(++cell).value = "Value";
      ws.getRow(row).getCell(++cell).value = kpi.abs;
      ws.getRow(row).getCell(cell).numFmt = kpi.absNumFmt;

      nextRow();
      ws.getRow(row).getCell(++cell).value = "vs Benchmark";
      ws.getRow(row).getCell(++cell).value = kpi.variance;
      ws.getRow(row).getCell(cell).numFmt = kpi.varianceNumFmt;

      nextRow();
      nextRow();
    };

    renderKPIs(rsv);
    renderKPIs(dollarShare);
    renderKPIs(eqLbs);
    renderKPIs(lbsShare);
    renderKPIs(unit);
    renderKPIs(unitShare);
  }

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export SOS KPIs data to Excel
export async function exportSOSKPIs(
  exportData,
  filename,
  addToExisting = false,
  wb = null
) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  const { filters, kpi } = exportData;
  // dd.mergeCells("B2:H2");

  if (Object.keys(kpi).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    const { data } = kpi;

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = Array.isArray(filters[filter])
        ? filters[filter].join()
        : JSON.stringify(filters[filter]).replace(/"/gm, "");
    });

    // KPIs
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = "KPIs";

    nextRow();
    ws.getRow(row).getCell(++cell).value = "Title";
    ws.getRow(row).getCell(++cell).value = "Value";
    ws.getRow(row).getCell(++cell).value = "vs Benchmark";

    nextRow();
    ws.getRow(row).getCell(++cell).value = "Market Share";
    ws.getRow(row).getCell(++cell).value = formatNumberExcel(data?.fact_dollar_impressions?.abs, true);
    ws.getRow(row).getCell(cell).numFmt = numFmt.percentage;
    ws.getRow(row).getCell(++cell).value = formatNumberExcel(data?.fact_dollar_impressions?.variance);
    ws.getRow(row).getCell(cell).numFmt = numFmt.number;

    nextRow();
    ws.getRow(row).getCell(++cell).value = "Total Share of Shelf";
    ws.getRow(row).getCell(++cell).value = formatNumberExcel(data?.fact_total_impressions?.abs, true);
    ws.getRow(row).getCell(cell).numFmt = numFmt.percentage;
    ws.getRow(row).getCell(++cell).value = formatNumberExcel(data?.fact_total_impressions?.variance);
    ws.getRow(row).getCell(cell).numFmt = numFmt.number;

    nextRow();
    ws.getRow(row).getCell(++cell).value = "Organic Share of Shelf";
    ws.getRow(row).getCell(++cell).value = formatNumberExcel(data?.fact_organic_impressions?.abs, true);
    ws.getRow(row).getCell(cell).numFmt = numFmt.percentage;
    ws.getRow(row).getCell(++cell).value = formatNumberExcel(data?.fact_organic_impressions?.variance);
    ws.getRow(row).getCell(cell).numFmt = numFmt.number;

    nextRow();
    ws.getRow(row).getCell(++cell).value = "Paid Share of Shelf";
    ws.getRow(row).getCell(++cell).value = formatNumberExcel(data?.fact_paid_impressions?.abs, true);
    ws.getRow(row).getCell(cell).numFmt = numFmt.percentage;
    ws.getRow(row).getCell(++cell).value = formatNumberExcel(data?.fact_paid_impressions?.variance);
    ws.getRow(row).getCell(cell).numFmt = numFmt.number;

    nextRow();
    ws.getRow(row).getCell(++cell).value = "Average Placement";
    ws.getRow(row).getCell(++cell).value = data?.fact_average_placements?.abs
    ws.getRow(row).getCell(++cell).value = data?.fact_average_placements?.variance;
  }

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export Column Bullet Chart data to Excel
export async function exportColumnBulletChart(
  exportData,
  filename,
  addToExisting = false,
  wb = null
) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  const { filters, bar } = exportData;
  // dd.mergeCells("B2:H2");

  if (Object.keys(bar).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = Array.isArray(filters[filter])
        ? filters[filter].join()
        : JSON.stringify(filters[filter])?.replace(/"/gm, "");
    });

    const { title, data, xField, yField, yFieldNumFmt } = bar;

    // Bar
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = title;

    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = xField;
    ws.getRow(row).getCell(++cell).value = yField[0] === "Total" ||
      yField[0] === "Organic" ||
      yField[0] === "Paid"
      ? `${yField[0]} Share of Shelf`
      : yField[0];

    ws.getRow(row).getCell(++cell).value = yField[1];

    nextRow();
    data.forEach(item => {
      ws.getRow(row).getCell(cell).value = item[xField];
      ws.getRow(row).getCell(++cell).value = formatNumberExcel(item[yField[0]], yFieldNumFmt[0] === numFmt.percentage);
      ws.getRow(row).getCell(cell).numFmt = yFieldNumFmt[0];
      ws.getRow(row).getCell(++cell).value = formatNumberExcel(item[yField[1]], yFieldNumFmt[1] === numFmt.percentage);
      ws.getRow(row).getCell(cell).numFmt = yFieldNumFmt[1];

      nextRow();
    });
  }

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export Column Bullet Chart with single target data to Excel
export async function exportColumnBulletChartST(
  exportData,
  filename,
  addToExisting = false,
  wb = null
) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  const { filters, bar } = exportData;
  // dd.mergeCells("B2:H2");

  if (Object.keys(bar).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = Array.isArray(filters[filter])
        ? filters[filter].join()
        : JSON.stringify(filters[filter])?.replace(/"/gm, "");
    });

    const { title, data, xField, yField } = bar;

    // Bar
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = title;

    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = xField;
    ws.getRow(row).getCell(++cell).value = yField[0];
    ws.getRow(row).getCell(++cell).value = yField[1];

    nextRow();
    data.forEach(item => {
      ws.getRow(row).getCell(cell).value = item[xField];
      ws.getRow(row).getCell(++cell).value = formatNumberExcel(item[yField[0]]);
      ws.getRow(row).getCell(cell).numFmt = item[yField];
      ws.getRow(row).getCell(++cell).value = formatNumberExcel(item[yField[1]]);
      // ws.getRow(row).getCell(cell).numFmt = yFieldNumFmt[1];

      nextRow();
    });
  }

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export Column Line Chart data to Excel
export async function exportColumnLineChart(
  exportData,
  filename,
  addToExisting = false,
  wb = null
) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  const { filters, line } = exportData;
  // dd.mergeCells("B2:H2");

  if (Object.keys(line).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = Array.isArray(filters[filter])
        ? filters[filter].join()
        : JSON.stringify(filters[filter]).replace(/"/gm, "");
    });

    const { title, data, xField, yField, yFieldNumFmt } = line;

    // Bar
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = title;

    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = xField;
    ws.getRow(row).getCell(++cell).value = yField[0];
    ws.getRow(row).getCell(++cell).value = yField[1];

    nextRow();
    data.forEach(item => {
      ws.getRow(row).getCell(cell).value = item[xField];
      ws.getRow(row).getCell(++cell).value = formatNumberExcel(item[yField[0]], yFieldNumFmt[0] === numFmt.percentage);
      ws.getRow(row).getCell(cell).numFmt = yFieldNumFmt[0];
      ws.getRow(row).getCell(++cell).value = formatNumberExcel(item[yField[1]], yFieldNumFmt[1] === numFmt.percentage);
      ws.getRow(row).getCell(cell).numFmt = yFieldNumFmt[1];

      nextRow();
    });
  }

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export Column MultiLine Chart data to Excel
export async function exportMultiLineChart(
  exportData,
  filename,
  addToExisting = false,
  wb = null
) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  const { filters, line } = exportData;
  // dd.mergeCells("B2:H2");

  if (Object.keys(line).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = Array.isArray(filters[filter])
        ? filters[filter].join()
        : JSON.stringify(filters[filter]).replace(/"/gm, "");
    });

    const { title, data, xField, series, yFieldNumFmt } = line;

    // Bar
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = title;

    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = xField;
    series.forEach(seriesItem => {
      ws.getRow(row).getCell(++cell).value = seriesItem
    })

    nextRow();
    data.forEach(item => {
      ws.getRow(row).getCell(cell).value = item[xField];

      series.forEach(seriesItem => {
        ws.getRow(row).getCell(++cell).value = formatNumberExcel(item[seriesItem], yFieldNumFmt === numFmt.percentage);
        ws.getRow(row).getCell(cell).numFmt = yFieldNumFmt;
      })

      nextRow();
    });
  }

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export Multi-Line Chart data with single target to Excel
export async function exportMultiLineChartST(
  exportData,
  filename,
  addToExisting = false,
  wb = null
) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  const { filters, line } = exportData;
  // dd.mergeCells("B2:H2");

  if (Object.keys(line).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = Array.isArray(filters[filter])
        ? filters[filter].join()
        : JSON.stringify(filters[filter]).replace(/"/gm, "");
    });

    const { title, data, xField, series } = line;

    // Bar
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = title;

    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = xField;
    series.forEach(seriesItem => {
      ws.getRow(row).getCell(++cell).value = seriesItem
    })

    nextRow();
    data.forEach(item => {
      ws.getRow(row).getCell(cell).value = item[xField];

      series.forEach(seriesItem => {
        ws.getRow(row).getCell(++cell).value = formatNumberExcel(item[seriesItem]);
      })

      nextRow();
    });
  }

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}
// Function to export Multi-Line Chart data with dual goals to Excel
export async function exportMultiLineChartDG(
  exportData,
  filename,
  addToExisting = false,
  wb = null
) {
  console.log("excel", exportData);
  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  const { filters, line } = exportData;
  // dd.mergeCells("B2:H2");

  if (Object.keys(line).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = Array.isArray(filters[filter])
        ? filters[filter].join()
        : JSON.stringify(filters[filter]).replace(/"/gm, "");
    });

    const { title, data, xField, series } = line;

    // Bar
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = title;

    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = xField;
    series.forEach((seriesItem) => {
      ws.getRow(row).getCell(++cell).value = seriesItem;
    });

    nextRow();
    data.forEach((item) => {
      ws.getRow(row).getCell(cell).value = item[xField];
      ws.getRow(row).getCell(++cell).value = item["kpi_1"];
      ws.getRow(row).getCell(++cell).value = item["kpi_2"];
      if (item["kpi_3"] || item["kpi_4"]) {
        ws.getRow(row).getCell(++cell).value = item["kpi_3"];
        ws.getRow(row).getCell(++cell).value = item["kpi_4"];
      }

      nextRow();
    });
  }

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export Bubble Chart data with single target data to Excel
export async function exportBubbleChartST(
  exportData,
  filename,
  addToExisting = false,
  wb = null,
  title
) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  const { filters, bubbleChart } = exportData;
  // dd.mergeCells("B2:H2");

  if (Object.keys(bubbleChart).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = Array.isArray(filters[filter])
        ? filters[filter].join()
        : JSON.stringify(filters[filter]).replace(/"/gm, "");
    });

    const { data, xField, yField } = bubbleChart;

    // Bar
    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = title;

    nextRow();
    nextRow();
    ws.getRow(row).getCell(cell).value = "keyword";
    ws.getRow(row).getCell(++cell).value = xField;
    ws.getRow(row).getCell(++cell).value = yField;
    ws.getRow(row).getCell(++cell).value = "traffic";


    nextRow();
    data.forEach(item => {
      ws.getRow(row).getCell(cell).value = item["keyword"];
      ws.getRow(row).getCell(++cell).value = item[xField];
      ws.getRow(row).getCell(++cell).value = `${item[yField]}%`;
      ws.getRow(row).getCell(++cell).value = item["traffic"];

      nextRow();
    });
  }

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export Scatter Chart data to Excel
export async function exportSOSScatter(
  exportData,
  filename,
  addToExisting = false,
  wb = null
) {

  // Initialize ExcelJS Workbook if not adding to an existing workbook
  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  const { filters, bubbleChart } = exportData;
  // dd.mergeCells("B2:H2");

  if (Object.keys(bubbleChart).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    const { data, name, xField, yField } = bubbleChart;

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = Array.isArray(filters[filter])
        ? filters[filter].join()
        : JSON.stringify(filters[filter]).replace(/"/gm, "");
    });

    // KPIs
    nextRow();
    nextRow();

    ws.getRow(row).getCell(cell).value = name;
    ws.getRow(row).getCell(++cell).value = xField;
    ws.getRow(row).getCell(++cell).value = yField;

    data.forEach((item) => {
      nextRow();
      ws.getRow(row).getCell(cell).value = item[name];
      ws.getRow(row).getCell(++cell).value = formatNumberExcel(item[xField]);
      ws.getRow(row).getCell(cell).numFmt = numFmt.number;
      ws.getRow(row).getCell(++cell).value = formatNumberExcel(item[yField], true);
      ws.getRow(row).getCell(cell).numFmt = numFmt.percentage;
    });
  }

  if (!addToExisting) {
    // Save the workbook as Excel file
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export Performance Summary data to Excel
export async function exportPerformanceSummary(isReport, defaultBrand, components, exportData, psColsExport, psColsFinancialsExport) {
  const wb = new ExcelJS.Workbook();
  if (!isReport || components.includes('1')) exportPerfKPIs(exportData, "Performance_Summary_KPI_Layer", true, wb);
  if (!isReport || components.includes('2')) exportCustomTable(psColsExport, exportData, "Channel_Retailer_Level_M", true, wb);
  if (!isReport || components.includes('2')) exportCustomTable(psColsFinancialsExport, exportData, "Channel_Retailer_Level_F", true, wb);
  if (!isReport || components.includes('3')) exportWaterfall(exportData, `${brandSplit(defaultBrand)}_RSV_Change_vs_YA`, true, wb);
  if (!isReport || components.includes('4')) exportBar(exportData, "TOP_BOTTOM_DRIVERS", true, wb);

  // Save the workbook as Excel file
  const buf = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buf]), "Performance_Summary.xlsx");
}

// Function to export Volume Shifting Data to Excel
export async function exportVSData(isReport, isBrand, isMars, components, exportData, psColsExport, psColsFinancialsExport) {
  const wb = new ExcelJS.Workbook();

  // Export Volume Shifting KPIs
  exportVolumeKPIs(exportData, isMars, "Volume_Shifting_KPIs", true, wb);

  // Create a copy of exportData to modify for tableData
  const exportDataInParams = JSON.parse(JSON.stringify(exportData));
  exportDataInParams.tableData.data = exportDataInParams.allTablesData;
  isBrand && exportCustomTable(psColsExport, exportDataInParams, "Volume_Shifting_Table_Data_M", true, wb);
  isBrand && exportCustomTable(psColsFinancialsExport, exportDataInParams, "Volume_Shifting_Table_Data_F", true, wb);
  // exportCustomTable(pColsExport, exportData, "Volume_Shifting_Table_Data", true, wb);
  if (!isReport || components.includes('2')) !isMars && exportWaterfallPercentage(exportData, "Volume_Shifting_Waterfall", true, wb);

  // Save the workbook as Excel file
  const buf = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buf]), "Volume_Shifting.xlsx");
}

// Function to export Distribution Summary Data to Excel
export async function exportDistributionSummary(isReport, isMars, isDcom, defaultBrand, components, { kpiData, tableData, lineData, scatterData }, nutroSKUColumns, allSKUColumns) {
  const wb = new ExcelJS.Workbook();
  // Export Distribution Summary KPIs
  if (!isReport || components.includes('1')) exportDistKPIs(kpiData, isMars, "Distribution_Summary_KPI_Layer", true, wb);
  // Export Custom Tables for Nutro SKU and All SKU data
  if (!isReport || components.includes('2')) exportCustomTable(nutroSKUColumns, tableData, `${brandSplit(defaultBrand)}_SKUs`, true, wb);
  // Export Trend or Line Chart data based on Dcom
  if (!isReport || components.includes('3')) isDcom ? exportTrend(lineData, "CSL - Last 4 weeks", true, wb) : exportLine(lineData, "TDP vs Velocity - Last 4 weeks_Velocity vs CSL - Last 4 weeks", true, wb);
  // Export Custom Table for Scatter Data (Distribution vs Velocity - All SKUs)
  if (!isReport || components.includes('4')) exportCustomTable(allSKUColumns, scatterData, "Distribution vs Velocity - All SKUs", true, wb);

  // Save the workbook as Excel file
  const buf = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buf]), "Distribution_Summary.xlsx");
}

export async function exportInnovationTracker(itColsRevenue, itColsDistribution, cardData, tableData, trendData, gsvChart, appliedFilters, localFilter, isRevenueData, tableLaunchYear, sortOrder, selectedRow) {

  const wb = new ExcelJS.Workbook();

  // Export Innovation Tracker KPIs
  exportInnoKPIs(cardData, "Innovation_Tracker_KPI_Layer", appliedFilters, localFilter, true, wb);

  // Export Launch Performance via Retailers Custom Table based on Revenue or Distribution data
  if (isRevenueData) {
    exportLuanchPerfCustomTable(itColsDistribution, tableData, 'Launch Performance via Retailers', true, wb, appliedFilters, tableLaunchYear, localFilter);
  } else {
    exportLuanchPerfCustomTable(itColsRevenue, tableData, 'Launch Performance via Retailers', true, wb, appliedFilters, tableLaunchYear, localFilter);
  }

  // Export Launch Performance Trend
  exportLaunchPerfTrend(trendData, "Launch_Performance_Trend", appliedFilters, localFilter, selectedRow, true, wb);
  // Export Innovation GSV Actual vs Demand Forecast Chart
  exportGsvDemandChart(gsvChart, "Innovation_GSV_Actual_vs_Demand_Forecast", appliedFilters, true, wb);
  // Export Consumer Review Data
  exportConsumerReview(localFilter, sortOrder, "Consumer Review", true, wb);

  // Save the workbook as Excel file
  const buf = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buf]), "Innovation_Tracker.xlsx");
}

export async function exportBeAvailable({ kpiData, trendData, tableData }, macroColumnsExport, microColumnsExport, microSheetName) {
  const wb = new ExcelJS.Workbook();
  exportBAKPIs(kpiData, "BeAvailable_KPI_Layer", true, wb);
  exportBATrends(trendData, true, wb);
  exportBATables(macroColumnsExport, microColumnsExport, tableData, microSheetName, true, wb);

  const buf = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buf]), "BeAvailable.xlsx");
}

export async function exportShareOfShelf(exportData, macroColumnsExport, microColumnsExport, microSheetName) {
  const wb = new ExcelJS.Workbook();
  exportSOSKPIs(exportData, "ShareOfShelf_KPI_Layer", true, wb);
  exportBATrends(exportData, true, wb);
  exportSosTables(macroColumnsExport, microColumnsExport, exportData, microSheetName, true, wb);
  exportSOSScatter(exportData, "Keyword Strategy", true, wb);

  const buf = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buf]), "ShareOfShelf.xlsx");
}

//Export Trackboard Data to Excel
export async function exportTrackboard(exportData, isMars, filename, innovationData) {

  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  const { kpi } = exportData;
  // dd.mergeCells("B2:H2");

  if (Object.keys(kpi).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    const { header, title, data } = kpi;

    // Set the title for the worksheet
    ws
      .getRow(row)
      .getCell(cell).value = `${title} - ${data.latest_time_period_year}`;

    const cardData = (
      heading,
      value,
      valueNumFmt,
      kpi1,
      kpi2,
      percent = true,
      section = null,
    ) => {
      // Helper function to add card data to the worksheet
      const isCategoryVsYa = !['GSV', 'NSV', 'Sales Share (USD)', "Sales Share (Tonne / '000 kg)", 'CSL', 'MKT RSV YEE', 'GSV YEE'].includes(heading);
      nextRow();
      if (section) {
        ws.getRow(row).getCell(cell++).value = section;
        ws.getRow(row).getCell(cell++).value = "Value";
        ['MKT RSV YEE', 'GSV YEE'].includes(heading) ? ws.getRow(row).getCell(cell++).value = "vs Target" : ws.getRow(row).getCell(cell++).value = "vs YA";
        ws.getRow(row).getCell(cell++).value = isMars ? "Category vs YA" : "Mars vs YA";
        nextRow();
      }

      // Add card data to the worksheet
      ws.getRow(row).getCell(cell++).value = heading;
      ws.getRow(row).getCell(cell).value = value;
      ws.getRow(row).getCell(cell++).numFmt = valueNumFmt;
      if (percent) {
        ws.getRow(row).getCell(cell).value = formatNumberExcel(kpi1, true);
        ws.getRow(row).getCell(cell++).numFmt = numFmt.percentage;
        if (!isMars || isCategoryVsYa) {
          ws.getRow(row).getCell(cell).value = formatNumberExcel(kpi2, true);
          ws.getRow(row).getCell(cell++).numFmt = numFmt.percentage;
        }
      } else {
        ws.getRow(row).getCell(cell).value = formatNumberExcel(kpi1);
        ws.getRow(row).getCell(cell++).numFmt = numFmt.number;
        if (!isMars || isCategoryVsYa) {
          ws.getRow(row).getCell(cell).value = formatNumberExcel(kpi2);
          ws.getRow(row).getCell(cell++).numFmt = numFmt.number;
        }
      }
    };

    // if (header) {
    //   nextRow();
    //   nextRow();
    //   ws.getRow(row).getCell(cell).value = header;  
    // }

    // Add card data to the worksheet
    nextRow();
    cardData(
      "MKT RSV",
      data.perf_summary_rsv && formatNumberExcel(data.perf_summary_rsv.value),
      numFmt.dollar,
      data.perf_summary_rsv && data.perf_summary_rsv.ya,
      data.perf_summary_rsv && data.perf_summary_rsv.mars_ya,
      true,
      "Performance Summary"
    );
    cardData(
      "GSV",
      data.perf_summary_gsv && formatNumberExcel(data.perf_summary_gsv.value),
      numFmt.dollar,
      data.perf_summary_gsv && data.perf_summary_gsv.ya,
      data.perf_summary_gsv && data.perf_summary_gsv.mars_ya
    );
    cardData(
      "NSV",
      data.perf_summary_nsv && formatNumberExcel(data.perf_summary_nsv.value),
      numFmt.dollar,
      data.perf_summary_nsv && data.perf_summary_nsv.ya,
      data.perf_summary_nsv && data.perf_summary_nsv.mars_ya
    );

    nextRow();
    cardData(
      "Sales Share (USD)",
      data.vol_shifting_sales_share_usd &&
      formatNumberExcel(data.vol_shifting_sales_share_usd.value, true),
      numFmt.percentage,
      data.vol_shifting_sales_share_usd && data.vol_shifting_sales_share_usd.ya,
      data.vol_shifting_sales_share_usd &&
      data.vol_shifting_sales_share_usd.mars_ya,
      false,
      "Volume Shifting"
    );
    cardData(
      "Sales Share (Tonne / '000 kg)",
      data.vol_shifting_sales_share_tonne &&
      formatNumberExcel(data.vol_shifting_sales_share_tonne.value, true),
      numFmt.percentage,
      data.vol_shifting_sales_share_tonne &&
      data.vol_shifting_sales_share_tonne.ya,
      data.vol_shifting_sales_share_tonne &&
      data.vol_shifting_sales_share_tonne.mars_ya,
      false
    );

    nextRow();
    cardData(
      "TDP",
      data.dist_summary_tdp && formatNumberExcel(data.dist_summary_tdp.value),
      numFmt.number,
      data.dist_summary_tdp && data.dist_summary_tdp.ya,
      data.dist_summary_tdp && data.dist_summary_tdp.mars_ya,
      true,
      "Distribution Summary"
    );
    cardData(
      "$/TDP",
      data.dist_summary_dollar_tdp &&
      formatNumberExcel(data.dist_summary_dollar_tdp.value),
      numFmt.dollar,
      data.dist_summary_dollar_tdp && data.dist_summary_dollar_tdp.ya,
      data.dist_summary_dollar_tdp && data.dist_summary_dollar_tdp.mars_ya
    );
    cardData(
      "CSL",
      data.dist_summary_csl &&
      formatNumberExcel(data.dist_summary_csl.value, true),
      numFmt.percentage,
      data.dist_summary_csl && data.dist_summary_csl.ya,
      data.dist_summary_csl && data.dist_summary_csl.mars_ya,
      false
    );

    nextRow();
    cardData(
      "MKT RSV YEE",
      innovationData.rsv_actuals && formatNumberExcel(innovationData.rsv_actuals),
      numFmt.dollar,
      innovationData.rsv_yee_variance && innovationData.rsv_yee_variance,
      innovationData.rsv_category_avg && innovationData.rsv_category_avg,
      true,
      "Innovation Tracker"
    );
    cardData(
      "GSV YEE",
      innovationData.gsv_actuals && formatNumberExcel(innovationData.gsv_actuals),
      numFmt.dollar,
      // innovationData.gsv_yee_variance && innovationData.gsv_yee_variance,
      // data.perf_summary_gsv && data.perf_summary_gsv.mars_ya
    );

  }

  // Save the workbook as Excel file
  const buf = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
}


//Export an Array of Data to Excel
export async function exportArray(exportData, fileName) {

  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(fileName);

  if (!exportData)
    ws.getCell("B2").value = "No data available to display for this selection";
  else if (exportData.length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    const columns = Object.keys(exportData[0]);
    columns.forEach((column) => {
      ws.getRow(row).getCell(cell).value = column.toUpperCase();
      cell++;
    });

    // data
    exportData.forEach((record) => {
      nextRow();
      columns.forEach((column) => {
        ws.getRow(row).getCell(cell++).value = record[column];
      });
    });
  }

  const buf = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buf]), fileName + ".xlsx");
}

// ECom Diagnostics
export async function exportEComTable(
  columns,
  exportData,
  filename,
  addToExisting = false,
  wb = null
) {
  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  const { filters, tableData } = exportData;

  if (Object.keys(tableData).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    const { data, callout, description } = tableData;
   
    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      ws.getRow(row).getCell(++cell).value = Array.isArray(filters[filter])
        ? filters[filter].join()
        : JSON.stringify(filters[filter]).replace(/"/gm, "");
    });

    nextRow();
    nextRow();

    if (callout) {
      nextRow();
      ws.getRow(row).getCell(cell).value = callout;
    }
    if (description) {
      nextRow();
      ws.getRow(row).getCell(cell).value = description;
    }
    if (exportData.header) {
      nextRow();
      ws.getRow(row).getCell(cell).value = exportData.header;
    }

    if (callout || description || exportData.header) {
      nextRow();
      nextRow();
    }

    const children =
      columns.findIndex(({ children }) => children && children.length) > -1;

    // columns
    columns.forEach(({ title, children }) => {
      if (children) {
        ws.mergeCells(row, cell, row, cell + (children.length - 1));
        cell += children.length - 1;
      }
      ws.getRow(row).getCell(cell).value = title;

      if (children) {
        let childCell = 0;
        children.forEach(({ title }) => {
          const offset = cell - (children.length - 1);
          ws.getRow(row + 1).getCell(offset + childCell++).value = title;
        });
      }
      cell++;
    });
    if (children) nextRow();

    // data
    data.forEach((record) => {
      nextRow();
      columns.forEach(({ dataIndex, children, renderText }) => {
        if (children) {

          children.forEach(({ dataIndex, renderText }) => {

            if (renderText) {
              ws.getRow(row).getCell(cell).value = renderText(
                record[dataIndex]
              ).value;
              ws.getRow(row).getCell(cell++).numFmt = renderText(
                record[dataIndex]
              ).numFmt;
            } else {

              ws.getRow(row).getCell(cell++).value = record['index'].includes("child_key") ? `  ${record[dataIndex]}` : record[dataIndex];
            }
          });
        } else {
          if (renderText) {
            ws.getRow(row).getCell(cell).value = renderText(
              record[dataIndex]
            ).value;
            ws.getRow(row).getCell(cell++).numFmt = renderText(
              record[dataIndex]
            ).numFmt;
          } else ws.getRow(row).getCell(cell++).value = record[dataIndex];
        }
      });
    });
  }

  if (!addToExisting) {
    // Save the workbook as Excel file
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

//Export Business Analytics Trends to Excel
export async function exportBATrends(
  exportData,
  addToExisting = false,
  wb = null
) {

  if (!addToExisting) wb = new ExcelJS.Workbook();

  exportColumnBulletChart(exportData, "Comparison Trend", true, wb);

  if (exportData.filters.deep_dive_list.length > 1)
    exportMultiLineChart(exportData, "Deepdive Trend", true, wb);
  else exportColumnLineChart(exportData, "Deepdive Trend", true, wb);

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), "BeAvailableTrends.xlsx");
  }
}

//Export Search Trends to Excel
export async function exportSTTrends(
  exportData,
  addToExisting = false,
  wb = null
) {
  if (!addToExisting) wb = new ExcelJS.Workbook();

  exportColumnBulletChartST(exportData, "Comparison Trend", true, wb);

  exportMultiLineChartST(exportData, "Deepdive Trend", true, wb);

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), "SearchInsightsTrends.xlsx");
  }
}

//Export Diagnostics Trends to Excel
export async function exportDGTrends(
  exportData,
  addToExisting = false,
  wb = null
) {
  if (!addToExisting) wb = new ExcelJS.Workbook();
  exportMultiLineChartDG(exportData, "KPI Comparision Trend", true, wb);

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), "Diagnistics_Trends.xlsx");
  }
}

//Export Search Term Strategy Trends to Excel
export async function exportSTBubbleChart(
  exportData,
  addToExisting = false,
  wb = null
) {
  if (!addToExisting) wb = new ExcelJS.Workbook();

  exportBubbleChartST(exportData, "Search Term Strategy", true, wb, "Search Term Strategy");

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), "SearchTermStrategy.xlsx");
  }
}


//Export Diagnostics Data to Excel
export async function exportDGExcel(
  exportData,
  columns,
  macroColumnsExport,
  fileName,
  addToExisting = false,
  wb = null
) {
  if (!addToExisting) wb = new ExcelJS.Workbook();
  const macroExportData = cloneDeep(exportData);
  macroExportData.tableData = macroExportData.macroTableData;
  exportDGKPIs(exportData, "Diagnostics KPI Layer", true, wb);
  exportEComTable(
    macroColumnsExport,
    macroExportData,
    "Total eCom Performance",
    true,
    wb
  );
  exportEComTable(columns, exportData, "Competition Benchmark", true, wb);

  exportMultiLineChartDG(exportData, "KPI Comparision Trend", true, wb);

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), "Diagnostics.xlsx");
  }
}

//Export Search Term Strategy Data to Excel
export async function exportSTExport(
  exportData,
  columns,
  fileName,
  addToExisting = false,
  wb = null
) {
  if (!addToExisting) wb = new ExcelJS.Workbook();

  exportColumnBulletChartST(exportData, "Comparison Trend", true, wb);

  exportMultiLineChartST(exportData, "Deepdive Trend", true, wb);

  exportEComTable(columns, exportData, fileName, true, wb);
  exportBubbleChartST(exportData, "Search Term Strategy", true, wb, "Search Term Strategy");

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), "SearchTermStrategy.xlsx");
  }
}

//Export Business Analytics Tables to Excel
export async function exportBATables(
  macroColumnsExport,
  microColumnsExport,
  exportData,
  microSheetName,
  addToExisting = false,
  wb = null
) {
  if (!addToExisting) wb = new ExcelJS.Workbook();

  const macroExportData = cloneDeep(exportData);
  macroExportData.tableData = macroExportData.macroTableData;
  exportEComTable(
    macroColumnsExport,
    macroExportData,
    "Retailer_Overview",
    true,
    wb
  );

  const microExportData = cloneDeep(exportData);
  microExportData.tableData = microExportData.microTableData;
  exportEComTable(
    microColumnsExport,
    microExportData,
    microSheetName,
    true,
    wb
  );

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), "BeAvailableTableData(tables).xlsx");
  }
}

//Export Share of Shelf Tables to Excel
export async function exportSosTables(
  macroColumnsExport,
  microColumnsExport,
  exportData,
  microSheetName,
  addToExisting = false,
  wb = null
) {
  if (!addToExisting) wb = new ExcelJS.Workbook();

  const macroExportData = cloneDeep(exportData);
  macroExportData.tableData = macroExportData.macroTableData;
  exportEComTable(
    macroColumnsExport,
    macroExportData,
    "category-share of shelf",
    true,
    wb
  );

  if (microSheetName) { 
    const microExportData = cloneDeep(exportData);
    microExportData.tableData = microExportData.microTableData;
    exportEComTable(
      microColumnsExport,
      microExportData,
      microSheetName,
      true,
      wb
    );
  }

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), "share-of-shelfTableData.xlsx");
  }
}


//Export Diagnostics Tables to Excel
export async function exportDGTables(
  exportData,
  columns,
  macroColumnsExport,
  addToExisting = false,
  wb = null
) {
  if (!addToExisting) wb = new ExcelJS.Workbook();
  const macroExportData = cloneDeep(exportData);
  macroExportData.tableData = macroExportData.macroTableData;
  exportEComTable(columns, exportData, "Competition Benchmark", true, wb);
  exportEComTable(
    macroColumnsExport,
    macroExportData,
    "Total eCom Performance",
    true,
    wb
  );

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), "Diagnostics(table).xlsx");
  }
}


//Export Competition Benchmark Table to Excel
export async function exportCompetitionBenchmarkTable(
  macroColumnsExport,
  exportData,
  addToExisting = false,
  wb = null) {
  if (!addToExisting) wb = new ExcelJS.Workbook();

  const macroExportData = cloneDeep(exportData);
  exportEComTable(
    macroColumnsExport,
    macroExportData,
    "Competition Benchmark",
    true,
    wb
  );

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), "competition-benchmarkTable.xlsx");
  }
}

//Export Total Ecom Performance Table to Excel
export async function exportTotalEcomTable(
  macroColumnsExport,
  exportData,
  addToExisting = false,
  wb = null) {
  if (!addToExisting) wb = new ExcelJS.Workbook();
  console.log("macroColumnsExport", macroColumnsExport)
  const macroExportData = cloneDeep(exportData);
  macroExportData.tableData = macroExportData.macroTableData;
  exportEComTable(
    macroColumnsExport,
    macroExportData,
    "Total Ecom Performance",
    true,
    wb
  );

  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), "Total_Ecom_Performance.xlsx");
  }
}


export async function exportPortfolioKPIs(exportData, isMars, filename, addToExisting = false, wb = null) {

  if (!addToExisting) wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);

  const { filters, kpi } = exportData;
  // dd.mergeCells("B2:H2");

  if (Object.keys(kpi).length === 0)
    ws.getCell("B2").value = "No data available to display for this selection";
  else {
    // data is available
    let row = 1;
    let cell = 1;
    const nextRow = () => {
      row++;
      cell = 1;
    };

    const { data, isBrand, isChannel, isRetailer } = kpi;
    const { time_aggregates } = filters;

    // Filters
    ws.getRow(row).getCell(cell).value = "Filters";
    Object.keys(filters).forEach((filter) => {
      nextRow();
      ws.getRow(row).getCell(++cell).value = filter;
      if (filter === "time_aggregates") ws.getRow(row).getCell(++cell).value = getTimeAggregates(filters[filter]).join();
      else ws.getRow(row).getCell(++cell).value = filters[filter].join();
    });

    // KPIs
    nextRow();
    nextRow();
    nextRow();

    if (isBrand)
      data.forEach((val) => {
        // headers
        ws.getRow(row).getCell(cell++).value = val.brand;

        ws.getRow(row).getCell(cell).value = "$ Sales mm";
        ws.getRow(row + 1).getCell(cell).value = "%CYA";
        ws.getRow(row + 2).getCell(cell).value = "$ Share";
        ws.getRow(row + 3).getCell(cell++).value = "%CYA";

        ws.getRow(row - 1).getCell(cell).value = "TOTAL";

        // $ Sales mm
        ws.getRow(row).getCell(cell).value = val[`dollar_sum_latest_${time_aggregates}`]
          ? formatNumberExcel(val[`dollar_sum_latest_${time_aggregates}`])
          : 0.0;
        ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;

        // %CYA
        ws.getRow(row + 1).getCell(cell).value = val[`dollar_variance_latest_${time_aggregates}`]
          ? formatNumberExcel(val[`dollar_variance_latest_${time_aggregates}`], true)
          : 0.0;
        ws.getRow(row + 1).getCell(cell).numFmt = numFmt.percentage;

        // $ Share
        ws.getRow(row + 2).getCell(cell).value =
          val[`dollar_share_latest_${time_aggregates}`]
            ? formatNumberExcel(val[`dollar_share_latest_${time_aggregates}`], true)
            : 0.0;
        ws.getRow(row + 2).getCell(cell).numFmt = numFmt.percentage;

        // %CYA
        ws.getRow(row + 3).getCell(cell).value = val[`dollar_share_variance_latest_${time_aggregates}`]
          ? formatNumberExcel(val[`dollar_share_variance_latest_${time_aggregates}`])
          : 0.0;
        ws.getRow(row + 3).getCell(cell++).numFmt = numFmt.number;

        // values
        val.table_data.forEach((val1) => {
          ws.getRow(row - 1).getCell(cell).value = val1.customer_channel;

          // $ Sales mm
          ws.getRow(row).getCell(cell).value = val1[`dollar_sum_latest_${time_aggregates}`]
            ? formatNumberExcel(val1[`dollar_sum_latest_${time_aggregates}`].abs)
            : 0.0;
          ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;

          // %CYA
          ws.getRow(row + 1).getCell(cell).value = val1[`dollar_sum_latest_${time_aggregates}`]
            ? formatNumberExcel(val1[`dollar_sum_latest_${time_aggregates}`].change, true)
            : 0.0;
          ws.getRow(row + 1).getCell(cell).numFmt = numFmt.percentage;

          // $ Share
          ws.getRow(row + 2).getCell(cell).value = val1[`dollar_share_latest_${time_aggregates}`]
            ? formatNumberExcel(val1[`dollar_share_latest_${time_aggregates}`].abs, true)
            : 0.0;
          ws.getRow(row + 2).getCell(cell).numFmt = numFmt.percentage;

          // %CYA
          ws.getRow(row + 3).getCell(cell).value = val1[`dollar_share_latest_${time_aggregates}`]
            ? formatNumberExcel(val1[`dollar_share_latest_${time_aggregates}`].change)
            : 0.0;
          ws.getRow(row + 3).getCell(cell++).numFmt = numFmt.number;
        });

        nextRow();
        nextRow();
        nextRow();
        nextRow();
        nextRow();
        nextRow();
      });
    else if (isChannel)
      data.forEach((val) => {
        // headers
        ws.getRow(row).getCell(cell++).value = val.channel;

        ws.getRow(row).getCell(cell).value = "$ Sales mm";
        ws.getRow(row + 1).getCell(cell).value = "%CYA";
        ws.getRow(row + 2).getCell(cell).value = "$ Share";
        ws.getRow(row + 3).getCell(cell++).value = "%CYA";

        // values
        val.cards.forEach((val1) => {
          ws.getRow(row - 1).getCell(cell).value = val1.brand;

          // $ Sales mm
          ws.getRow(row).getCell(cell).value = val1[`dollar_sum_latest_${time_aggregates}`]
            ? formatNumberExcel(val1[`dollar_sum_latest_${time_aggregates}`])
            : 0.0;
          ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;

          // %CYA
          ws.getRow(row + 1).getCell(cell).value = val1[`dollar_variance_latest_${time_aggregates}`]
            ? formatNumberExcel(val1[`dollar_variance_latest_${time_aggregates}`], true)
            : 0.0;
          ws.getRow(row + 1).getCell(cell).numFmt = numFmt.percentage;

          if (val1.brand !== "TOTAL" || !isMars) {
            // $ Share
            ws.getRow(row + 2).getCell(cell).value = val1[`dollar_share_latest_${time_aggregates}`]
              ? formatNumberExcel(val1[`dollar_share_latest_${time_aggregates}`], true)
              : 0.0;
            ws.getRow(row + 2).getCell(cell).numFmt = numFmt.percentage;

            // %CYA
            ws.getRow(row + 3).getCell(cell).value = val1[`dollar_share_variance_latest_${time_aggregates}`]
              ? formatNumberExcel(val1[`dollar_share_variance_latest_${time_aggregates}`])
              : 0.0;
            ws.getRow(row + 3).getCell(cell++).numFmt = numFmt.number;
          } else {
            cell++;
          }
        });

        nextRow();
        nextRow();
        nextRow();
        nextRow();
        nextRow();
        nextRow();
      });
    else if (isRetailer)
      data.forEach((val) => {
        // headers
        ws.getRow(row).getCell(cell++).value = val.retailer;

        ws.getRow(row).getCell(cell).value = "$ Sales mm";
        ws.getRow(row + 1).getCell(cell).value = "%CYA";
        ws.getRow(row + 2).getCell(cell).value = "$ Share";
        ws.getRow(row + 3).getCell(cell++).value = "%CYA";

        // values
        val.cards.forEach((val1) => {
          ws.getRow(row - 1).getCell(cell).value = val1.brand;

          // $ Sales mm
          ws.getRow(row).getCell(cell).value = val1[`dollar_sum_latest_${time_aggregates}`]
            ? formatNumberExcel(val1[`dollar_sum_latest_${time_aggregates}`])
            : 0.0;
          ws.getRow(row).getCell(cell).numFmt = numFmt.dollar;

          // %CYA
          ws.getRow(row + 1).getCell(cell).value = val1[`dollar_variance_latest_${time_aggregates}`]
            ? formatNumberExcel(val1[`dollar_variance_latest_${time_aggregates}`], true)
            : 0.0;
          ws.getRow(row + 1).getCell(cell).numFmt = numFmt.percentage;

          if (val1.brand !== "TOTAL" || !isMars) {
            // $ Share
            ws.getRow(row + 2).getCell(cell).value = val1[`dollar_share_latest_${time_aggregates}`]
              ? formatNumberExcel(val1[`dollar_share_latest_${time_aggregates}`], true)
              : 0.0;
            ws.getRow(row + 2).getCell(cell).numFmt = numFmt.percentage;

            // %CYA
            ws.getRow(row + 3).getCell(cell).value = val1[`dollar_share_variance_latest_${time_aggregates}`]
              ? formatNumberExcel(val1[`dollar_share_variance_latest_${time_aggregates}`])
              : 0.0;
            ws.getRow(row + 3).getCell(cell++).numFmt = numFmt.number;
          } else {
            cell++;
          }
        });

        nextRow();
        nextRow();
        nextRow();
        nextRow();
        nextRow();
        nextRow();
      });
  }
  if (!addToExisting) {
    const buf = await wb.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
  }
}

// Function to export Portfolio Summary Data to Excel
export async function exportPFSData(isReport, isBrand, isMars, components, exportData, psColsExport, psColsFinancialsExport) {
  const wb = new ExcelJS.Workbook();

  // Export Volume Shifting KPIs
  exportPortfolioKPIs(exportData, isMars, "Portfolio_Summary_KPIs", true, wb);

  // Create a copy of exportData to modify for tableData
  const exportDataInParams = JSON.parse(JSON.stringify(exportData));
  exportDataInParams.tableData.data = exportDataInParams.allTablesData;
  isBrand && exportCustomTable(psColsExport, exportDataInParams, "Portfolio_Summary_Table_Data_M", true, wb);
  isBrand && exportCustomTable(psColsFinancialsExport, exportDataInParams, "Portfolio_Summary_Table_Data_F", true, wb);
  // exportCustomTable(pColsExport, exportData, "Portfolio_Summary_Table_Data", true, wb);
  if (!isReport || components.includes('2')) !isMars && exportWaterfallPercentage(exportData, "Portfolio_Summary_Waterfall", true, wb);

  // Save the workbook as Excel file
  const buf = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buf]), "Portfolio_Summary.xlsx");
}