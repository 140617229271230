import React, { useEffect, useState } from "react";
import { Col, Layout, Row, Form, Button, Select, Menu, Dropdown, Checkbox } from "antd";
import { withRouter } from "react-router-dom";
import Wrapper from "../../components/wrapper";
import { DownloadOutlined, PlusCircleOutlined } from '@ant-design/icons';
import DataCard from "../../components/data-card";
import CustomTable from "../../components/customTable";
import DonutChart from "../../components/donut-chart";
import WaterfallChart from "../../components/waterfall-chart";
import BarChart from "../../components/bar-chart";
import { useSelector, useDispatch } from "react-redux";
import { getAllFilters, getData, getFilterData, getInitialBottomFilters, setAppliedFilters, setFilters } from "../../slicers/performanceSummary.slicer";
import Loader from "../../components/loader";
import { formatNumber, openNotificationWithIcon, valueTypes, brandSplit, allBrands, marsPN, setWindowHistory } from "../../utilities/helper";
import { setExportData, setPPTExportData } from "../../slicers/exportData.slicer";
import { exportPerfKPIs, exportWaterfall, exportBar, exportPerformanceSummary, exportMarketFinancialsTable } from "../../utilities/exports/excel";
import NewReportForm from "../../components/newReportForm";
import { pptExportPerformance } from "../../utilities/exports/ppt";
import { isPackSize } from "../volume-shifting";
import { onChange, rowKey, noRowData, getPieData, getBulletData, dummyKpiData, declareAppliedFilters } from "./psUIHelper";
import columns from "./psColumns";
import { appInsights } from "../../utilities/appInsights";

const { Content } = Layout;
const { Option } = Select;


/**
 * PerformanceSummary 
 * It manages displaying of PerformanceSummary with different tabs and functionalities.
 */

function PerformanceSummary(props) {
  const dispatch = useDispatch();
  const { defaultBrand } = useSelector((state) => state.profile)
  const { loading, filterLoading, pets, techs, subTechs, lifestage, packSize, years, periods, table_data, filters, appliedFilters } = useSelector((state) => state.perfSummary)
  const [selectedBrand, setSelectedBrand] = useState(defaultBrand);
  const exportData = useSelector((state) => state.exportData);
  const { performanceSummary } = useSelector((state) => state.refreshDate);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState([]);
  const [filterChanged, setFilterChanged] = useState(false);
  const [expand, setExpand] = useState(false);
  const [newReport, setNewReport] = useState(false);
  const [createReport, setCreateReport] = useState(true);
  const [openSaveReportDialog, setOpenSaveReportDialog] = useState(false);
  const [newReportComponents, setNewReportComponents] = useState([]);
  const [brandToggle, setBrandToggle] = useState(false);
  const pCols = brandToggle ? columns.psColsFinancials : columns.psCols;
  const [components, setComponents] = useState([]);
  const [apply, setApply] = useState(false);

  const isReport = props.isReport

  const changeNewReportComponent = (val, e) => {
    let temp = [...newReportComponents];
    if (e.target.checked) {
      temp.push(val)
      setNewReportComponents(temp);
    }
    else {
      let ind = temp.findIndex((i) => i === val)
      if (ind > -1) {
        temp.splice(ind, 1)
        setNewReportComponents(temp)
      }
    }
  }

  const changeFilter = (label, selectedValue) => {
    if (JSON.stringify(filters[label]) !== JSON.stringify(selectedValue)) {
      setFilterChanged(true);
    }
    let newFilters = { ...filters }
    if (selectedValue && selectedValue.length && selectedValue.includes("all")) {
      let all = [];
      if (label === 'product_pet') all = pets;
      else if (label === 'product_package' && filters.product_technology.length === 1) all = packSize[filters.product_technology[0]];
      else if (label === 'product_technology') all = techs;
      else if (label === 'product_sub_technology') all = subTechs;
      else all= lifestage;
      if (selectedValue.length === all.length + 1) {
        selectedValue = [];
      }
      else selectedValue = [...all];
    }

    if (label === "product_technology" && !isPackSize.includes(selectedValue)) newFilters["product_package"] = []
    newFilters[label] = selectedValue
    dispatch(setFilters(newFilters))
  }

  const getRemainingFilters = (label) => {

    if (!filterChanged) return

    setFilterChanged(false)
    if (label === 'product_pet') {
      dispatch(getFilterData({
        'product_pet': filters.product_pet
      }))
    }
    else if (label === 'product_technology') {
      dispatch(getFilterData({
        'product_pet': filters.product_pet,
        'product_technology': filters.product_technology
      }))
    }
    else if (label === "product_package") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_package: filters.product_package,
        })
      );
    }
    else if (label === "product_sub_technology") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_package: filters.product_package,
          product_sub_technology: filters.product_sub_technology,
        })
      );
    }
    else if (label === "product_lifestage") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_package: filters.product_package,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
        })
      );
    }
    else if (label === 'time_year') {
      dispatch(getFilterData({
        'product_pet': filters.product_pet,
        'product_technology': filters.product_technology,
        'product_sub_technology': filters.product_sub_technology,
        'product_lifestage': filters.product_lifestage,
        'time_year': filters.time_year
      }))
    }
  }

  const applyFliters = () => {
    let encodedSearch = encodeURIComponent(JSON.stringify({ ...filters, default_brand: [defaultBrand] }))
    if (window.history.pushState) {
      let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + encodedSearch;
      window.history.pushState({ path: newurl }, '', newurl);
    }
    setApply(true);
    dispatch(getData(filters))
    dispatch(setAppliedFilters(declareAppliedFilters(filters, { techs, subTechs,lifestage, packSize: packSize[filters.product_technology[0]] })));
  }

  const changeSelectedBrand = () => setSelectedBrand(defaultBrand);

  const fetchData = (filters, fetchFilterData = true, initialFilterData = true) => {
    dispatch(setAppliedFilters(declareAppliedFilters(filters, { techs, subTechs, packSize: packSize[filters.product_technology[0]] })));
    dispatch(setFilters(filters));

    const isBrandChanged = defaultBrand !== selectedBrand;


    // API calls
    if (fetchFilterData)
      if (initialFilterData) dispatch(getAllFilters(isBrandChanged, changeSelectedBrand));
      else dispatch(getFilterData({}, true, isBrandChanged, changeSelectedBrand));
    else {
      dispatch(getData(filters));
      setWindowHistory(encodeURIComponent(JSON.stringify(filters)));
    }
  };

  useEffect(() => {
    const initialFilters = {
      ...getInitialBottomFilters(),
      default_brand: [defaultBrand],
    };

    let temp = new URL(window.location.href)
    if (temp.search.length > 1) {
      try {
        let preFilters = JSON.parse(decodeURIComponent(temp.search.substring(1)))
        let currentFilter = { ...filters }
        for (const [k, v] of Object.entries(preFilters)) {
          if (k in currentFilter) {
            currentFilter = {
              ...currentFilter,
              [k]: [...v]
            }
          }
        }

        setComponents(preFilters.performance_components)

        if (!isReport || preFilters.performance_components.length) {
          fetchData(currentFilter, true, false);
        }
      }
      catch (e) {
        fetchData(initialFilters);
      }
    }
    else {
      fetchData(initialFilters);
    }

  }, [defaultBrand, props.reportApply])


  const formatTableData = (table_data) => {
    const tableDataInStore = JSON.parse(JSON.stringify(table_data))
    const tableData = [];
    tableDataInStore.forEach((data) => {
      tableData.push(data);
      if (data.retailer_data) tableData.push(...data.retailer_data);
    })

    dispatch(setExportData({ tableData: { data: tableData } }));
    dispatch(setPPTExportData({ pptTableData: { data: tableData } }));
  }

  const alterExpand = (table_data) => {
    const tableDataInStore = JSON.parse(JSON.stringify(table_data))
    const tableData = [];
    tableDataInStore.forEach((data) => {
      if (data.retailer_key === "Total Market") {
        delete data.retailer_data
      }
      tableData.push({ ...data });
    })
    return tableData
  }

  useEffect(() => {
    if (table_data.length) {
      setSelectedRowKey([table_data[0][rowKey]])
      setSelectedRow([table_data[0]])
      formatTableData(table_data);
    }
    else {
      setSelectedRow(noRowData)
    }
  }, [table_data])

  const getWaterfallHeaderText = () => {
    const waterfallHeaderText = brandSplit(defaultBrand);
    return waterfallHeaderText.toUpperCase() === allBrands ? marsPN : waterfallHeaderText;
  }
  const exportKPIs = () => exportPerfKPIs(exportData, "Performance_Summary_KPI_Layer");
  const exportDataTable = () => exportMarketFinancialsTable(columns.psColsExport, columns.psColsFinancialsExport, exportData, "Channel_Retailer_Level");
  const exportNutroChange = () => exportWaterfall(exportData, `${getWaterfallHeaderText()} MKT RSV Change vs YA`);
  const exportTopBottom = () => exportBar(exportData, "TOP_BOTTOM_DRIVERS");

  useEffect(() => {

    if (selectedRow.length > 0)
      dispatch(setExportData({
        kpi: {
          header: "",
          data: selectedRow[0]
        }
      }));
  }, [selectedRow]);
  useEffect(() => {
    let tempFilters = { ...filters }
    if (tempFilters.product_pet && (tempFilters.product_pet.length === 0 || tempFilters.product_pet.length === pets.length)) {
      tempFilters['product_pet'] = ['ALL']
    }
    if (tempFilters.product_technology && (tempFilters.product_technology.length === 0 || tempFilters.product_technology.length === techs.length)) {
      tempFilters['product_technology'] = ['ALL']
    }
    if (tempFilters.product_sub_technology && (tempFilters.product_sub_technology.length === 0 || tempFilters.product_sub_technology.length === subTechs.length)) {
      tempFilters['product_sub_technology'] = ['ALL']
    }
    if (tempFilters.time_year && (tempFilters.time_year.length === 0)) {
      tempFilters['time_year'] = ['ALL']
    }
    if (tempFilters.product_lifestage && (tempFilters.product_lifestage.length === 0 || tempFilters.product_lifestage.length === lifestage.length)) {
      tempFilters['product_lifestage'] = ['ALL']
    }
    if (tempFilters.time_period && (tempFilters.time_period.length === 0)) {
      tempFilters['time_period'] = ['ALL']
    }

    const { product_pet, product_technology, product_sub_technology,product_lifestage,time_year, time_period } = tempFilters;
    dispatch(setExportData({ filters: { product_pet, product_technology, product_sub_technology,product_lifestage, time_year, time_period } }));
  }, [filters]);

  return (
    <Wrapper hideHeader={props.hideReportHeader} hidePage={isReport} heading={"1"} innerHeading={"6"}>
      <Content className={"site-layout-background" + (isReport && components.length === 0 ? " content-none" : " content-body")}>
        <Loader loading={isReport ? props.reportLoader : loading > 0}>
          {!props.hideReportFilters && <><Row>
            <div className="refreshed-on">
              <span>Last Refreshed on: {performanceSummary.last_refresh}, Data upto: {performanceSummary.latest_period_available}</span>
              <span>Next Refresh on: {performanceSummary.next_refresh}</span>
            </div>
          </Row>
            <Row align="middle" style={{ marginBottom: 16 }} gutter={[16, 16]}>
              <Col span={16}>
                <Form name="horizontal_login" className="performance-filter" layout="inline">
                  <Row gutter={12}>
                    <div className="filters-ps">
                      <Col>
                        <label className="label-block">Pet</label>
                        <Select loading={filterLoading} placeholder="All" onMouseLeave={() => getRemainingFilters('product_pet')} mode="multiple" defaultValue={['all']} value={filters.product_pet} onChange={(val) => { changeFilter('product_pet', val); }} dropdownMatchSelectWidth={false} style={{ width: 130 }} placement="bottomLeft" maxTagCount="responsive" >
                          <Option value={'all'}>{'All'}</Option>
                          {pets.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>
                      <Col>
                        <label className="label-block">Technology</label>
                        <Select loading={filterLoading} placeholder="All" onMouseLeave={() => getRemainingFilters('product_technology')} mode="multiple" defaultValue={['all']} value={filters.product_technology} onChange={(val) => { changeFilter('product_technology', val); }} dropdownMatchSelectWidth={false} placement="bottomLeft" style={{ width: 140 }} maxTagCount="responsive" >
                          <Option value={'all'}>{'All'}</Option>
                          {techs.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>
                      <Col>
                        <label className="label-block">Sub Technology</label>
                        <Select
                          loading={filterLoading}
                          placeholder="All"
                          mode="multiple"
                          // defaultValue={["all"]}
                          dropdownMatchSelectWidth={false}
                          placement="bottomLeft"
                          style={{ width: 110 }}
                          maxTagCount="responsive"
                          value={filters.product_sub_technology}
                          // loading={dropdownLoading}
                          autoClearSearchValue={false}
                          onMouseLeave={() => {
                            getRemainingFilters("product_sub_technology")
                          }}
                          onChange={(val) => {
                            changeFilter("product_sub_technology", val);
                          }}
                        >
                          <Option value={"all"}>{"All"}</Option>
                          {subTechs.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>
                      <Col>
                        <label className="label-block">Life Stage </label>
                        <Select
                          loading={filterLoading}
                          placeholder="All"
                          mode="multiple"
                          // defaultValue={["all"]}
                          dropdownMatchSelectWidth={false}
                          placement="bottomLeft"
                          style={{ width: 110 }}
                          maxTagCount="responsive"
                          value={filters.product_lifestage}
                          // loading={dropdownLoading}
                          autoClearSearchValue={false}
                          onMouseLeave={() => {
                            getRemainingFilters("product_lifestage")
                          }}
                          onChange={(val) => {
                            changeFilter("product_lifestage", val);
                          }}
                        >
                          <Option value={"all"}>{"All"}</Option>
                          {lifestage.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>
                      {filters.product_technology.length === 1 && isPackSize.includes(filters.product_technology[0]) && <Col>
                        <label className="label-block">Pack Type</label>
                        <Select loading={filterLoading} placeholder="All" onMouseLeave={() => getRemainingFilters('product_package')} mode="multiple" defaultValue={['all']} value={filters.product_package} onChange={(val) => { changeFilter('product_package', val) }} dropdownMatchSelectWidth={false} placement="bottomLeft" style={{ width: 85 }} maxTagCount="responsive" >
                          <Option value={'all'}>{'All'}</Option>
                          {packSize[filters.product_technology[0]] && packSize[filters.product_technology[0]].map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>}
                      <Col>
                        <label className="label-block">Year</label>
                        <Select
                          loading={filterLoading}
                          placeholder="All"
                          onMouseLeave={() => getRemainingFilters('time_year')}
                          value={filters.time_year}
                          onChange={(val) => changeFilter('time_year', [val])}
                          style={{ width: 80 }} >

                          {years.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>
                      <Col>
                        <label className="label-block">Period</label>
                        <Select loading={filterLoading} placeholder="All" value={filters.time_period} onChange={(val) => changeFilter('time_period', [val])} style={{ width: 68 }} >
                          {periods.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>
                    </div>
                    <Col>
                      <Button type="primary" style={{ marginTop: '27%' }} onClick={applyFliters} disabled={filterLoading}>Apply</Button>
                    </Col>
                  </Row>

                </Form>
              </Col>
              <Col span={8}>
                <Row gutter={20} justify="end" style={{ marginTop: '3%' }}>
                  {!newReport &&
                    <>
                      <Col>
                        <Dropdown
                          overlay={() => (
                            <Menu>
                              <Menu.Item onClick={() => { appInsights.trackEvent({ name: 'PPT_FOR_PERFORMANCE' }); pptExportPerformance(exportData, "Performance Report", columns.psColsRsv, columns.psColsShare, columns.psColsTdp, columns.psCols$Tdp, table_data, defaultBrand, brandToggle, columns.psColsFinancialRsv, columns.psColsFinancialGsv, columns.psColsFinancialNSV, columns.psColsFinancialMAC); }}>Download as PPT</Menu.Item>
                              <Menu.Item onClick={() => { appInsights.trackEvent({ name: 'EXCEL_FOR_PERFORMANCE' }); exportPerformanceSummary(isReport, defaultBrand, components, exportData, columns.psColsExport, columns.psColsFinancialsExport) }}>Download as Excel</Menu.Item>
                            </Menu>
                          )}
                          className="d-more">
                          <Button icon={<DownloadOutlined />} onClick={() => { setExpand(true); }}>Export Report</Button>
                        </Dropdown>
                      </Col>
                      {!isReport && <Col>
                        <Dropdown
                          placement="bottomRight"
                          overlay={() => (
                            <Menu>
                              <Menu.Item onClick={(e) => { appInsights.trackEvent({ name: 'CREATE_SELF_SERVE_REPORT_PERFORMANCE' }); setNewReport(true); setCreateReport(true); }}>Create self serve Report</Menu.Item>
                              <Menu.Item onClick={(e) => { appInsights.trackEvent({ name: 'ADD_EXISTING_REPORT_PERFORMANCE' }); setNewReport(true); setCreateReport(false); }}>Add to Existing Report</Menu.Item>
                            </Menu>
                          )}>
                          <Button
                            icon={<PlusCircleOutlined />}
                            type="primary"
                          >
                            Create Report
                          </Button>
                        </Dropdown>
                      </Col>}
                    </>}
                  {newReport && <>
                    <Col>
                      <Button type="secondary" onClick={(e) => { setNewReport(false); setNewReportComponents([]); }}>Cancel</Button>
                    </Col>
                    <Col>
                      <Button type="primary" onClick={(e) => { if (newReportComponents.length === 0) openNotificationWithIcon('error', 'No KPI components selected'); else setOpenSaveReportDialog(true); }}>Save Report</Button>
                    </Col>
                  </>}
                </Row>

              </Col>
            </Row>
          </>}
          {
            !isReport && <Row className="sticky-filter">
              <Col span={24}>
                <div className="selected-filters">
                  <label>Filters Applied:</label>
                  <div className="filter-item">Pet - <span>{Array.isArray(appliedFilters.product_pet) && appliedFilters.product_pet.length > 0 ? appliedFilters.product_pet.join() : 'CAT,DOG'}</span></div>
                  <div className="filter-item">Technology - <span>{Array.isArray(appliedFilters.product_technology) ? appliedFilters.product_technology.join() : "ALL"}</span></div>
                  {appliedFilters.product_technology.length === 1 && isPackSize.includes(appliedFilters.product_technology[0]) && <div className="filter-item">Pack Type - <span>{Array.isArray(appliedFilters.product_package) ? appliedFilters.product_package.join() : "ALL"}</span></div>}
                  <div className="filter-item">Sub Technology - <span>{Array.isArray(appliedFilters.product_sub_technology) ? appliedFilters.product_sub_technology.join() : "ALL"}</span></div>
                  <div className="filter-item">Life Stage - <span>{Array.isArray(appliedFilters.product_lifestage) ? appliedFilters.product_lifestage.join() : "ALL"}</span></div>
                  <div className="filter-item">Year - <span>{appliedFilters.time_year}</span></div>
                  <div className="filter-item">Period - <span>{appliedFilters.time_period}</span></div>
                </div>
              </Col>
            </Row>
          }
          {(isReport ? (components.length !== 0) : true) && <div className="vs-title" style={{ marginTop: 24 }}>
            <div>
              <h2>Performance Summary</h2>
            </div>
          </div>}
          {
            newReport && <Row>
              <Col span={24}>
                <div className="common-callout">
                  <p>Select KPI Components you want to add in your report by clicking on the checkboxes</p>
                </div>
              </Col>
            </Row>
          }
          {
            (!isReport || components.includes('1')) &&
            <><div className={`first-section-ps ps-top ${newReport ? 'trackboard-edit' : ''} ${newReport && newReportComponents.includes('1') ? 'trackboard-selected' : ''}`} >
              {newReport && <Checkbox value={newReportComponents.includes('1')} onChange={(e) => changeNewReportComponent('1', e)} className="d-check" style={{ marginBottom: 10 }}></Checkbox>}
              <Row gutter={[12, 12]} justify="space-around">
                <Col className="perf-cards">
                  <DataCard fieldTitle="MKT RSV" fieldValue={(selectedRow.length ? formatNumber(selectedRow[0].sum_fact_dollar, 1, true) === valueTypes.NA ? valueTypes.NA : "$" + formatNumber(selectedRow[0].sum_fact_dollar) : valueTypes.NA)} checkbox={false} onChange={onChange} />
                </Col>
                <Col className="perf-cards">
                  <DataCard fieldTitle="% Growth" fieldValue={(selectedRow.length ? formatNumber(selectedRow[0].sum_fact_percent_growth, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(selectedRow[0].sum_fact_percent_growth) + "%" : valueTypes.NA)} checkbox={false} onChange={onChange} />
                </Col>
                <Col className="perf-cards">
                  <DataCard fieldTitle="TDP" fieldValue={(selectedRow.length ? formatNumber(selectedRow[0].sum_fact_tdp, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(selectedRow[0].sum_fact_tdp) : valueTypes.NA)} checkbox={false} onChange={onChange} />
                </Col>
                <Col className="perf-cards">
                  <DataCard fieldTitle="$/TDP" fieldValue={(selectedRow.length ? formatNumber(selectedRow[0].sum_fact_dollar_acv, 1, true) === valueTypes.NA ? valueTypes.NA : "$" + formatNumber(selectedRow[0].sum_fact_dollar_acv) : valueTypes.NA)} checkbox={false} onChange={onChange} />
                </Col>
                <Col className="perf-cards">
                  <DataCard fieldTitle="CSL %" fieldValue={(selectedRow.length ? formatNumber(selectedRow[0].sum_fact_percent_csl_worst_case_4w, 1, true) === valueTypes.NA ? valueTypes.NA : formatNumber(selectedRow[0].sum_fact_percent_csl_worst_case_4w) + "%" : valueTypes.NA)} checkbox={false} showExport={true} exportData={exportKPIs} onChange={onChange} />
                </Col>
              </Row>
              {/* {showFinancialsCallout() && <Row>
                <Col span={24}>
                  <div className="selection-message" style={{ textAlign: "center", fontWeight: 700 }}>{financialsCalloutMessage()}</div>
                </Col>
              </Row>} */}
              <Row gutter={[16, 16]} justify="space-around">
                <Col md={8}>
                  <DonutChart kpiType='GSV' kpiData={selectedRow.length ? selectedRow[0].gsv_pie : dummyKpiData} getData={getPieData} getBulletData={getBulletData} ytdVal={70} targetVal={85} fieldTitle="GSV Growth" loading={loading} apply={apply} setApply={setApply} />
                </Col>
                <Col md={8}>
                  <DonutChart kpiType='NSV' kpiData={selectedRow.length ? selectedRow[0].nsv_pie : dummyKpiData} getData={getPieData} getBulletData={getBulletData} ytdVal={70} targetVal={35} fieldTitle="NSV Growth" loading={loading} apply={apply} setApply={setApply} />
                </Col>
                <Col md={8}>
                  <DonutChart kpiType='MAC%' kpiData={selectedRow.length ? selectedRow[0].mac_pie : dummyKpiData} getData={getPieData} getBulletData={getBulletData} ytdVal={70} targetVal={70} fieldTitle="MAC% Growth" loading={loading} apply={apply} setApply={setApply} />
                </Col>
              </Row>
            </div>
            </>
          }
          {!isReport && <hr />}
          {
            (!isReport || components.includes('2')) && <div className="second-section-ps">
              <CustomTable rowKey={rowKey} expandAll={expand} childrenColumnName="retailer_data" hideFilter={true}
                data={alterExpand(table_data)}
                heading="Brand Performance at Channel/Retailer Level"
                brandToggle={brandToggle}
                setBrandToggle={setBrandToggle}
                rowSelection={{
                  checkStrictly: true,
                  selectedRowKeys: selectedRowKey,
                  type: 'radio',
                  onChange: (selectedRowKeys, selectedRows) => {
                    setSelectedRowKey(selectedRowKeys)
                    setSelectedRow(selectedRows)
                  },
                  hideSelectAll: true
                }}
                checkbox={newReport}
                value={newReportComponents.includes('2')}
                onChange={(e) => changeNewReportComponent('2', e)}
                columns={pCols}
                exportData={exportDataTable} />
            </div>
          }
          {
            (!isReport || components.includes('3')) && <div className="third-section-ps" style={{ marginBottom: 24 }}>
              <Row gutter={[16, 16]} justify="space-around">
                <Col md={24}>
                  <WaterfallChart checkbox={newReport} value={newReportComponents.includes('3')} onChange={(e) => changeNewReportComponent('3', e)} dropdown={true} heading={`${getWaterfallHeaderText()} MKT RSV Change vs YA${selectedRow.length ? ' - ' + selectedRow[0].retailer_key : ''}`} options={selectedRow.length && selectedRow[0].waterfall_data && selectedRow[0].waterfall_data.length > 0 ? Object.keys(selectedRow[0].waterfall_data[0]) : []} data={selectedRow.length && selectedRow[0].waterfall_data && selectedRow[0].waterfall_data.length > 0 ? selectedRow[0].waterfall_data[0] : {}} exportData={exportNutroChange} />
                </Col >
              </Row >
            </div >
          }
          {
            (!isReport || components.includes('4')) && <div className="fourth-section-ps">
              <Row gutter={[16, 16]} justify="space-around">
                <Col md={24}>
                  <h2>TOP/BOTTOM DRIVERS{selectedRow.length ? ' - ' + selectedRow[0].retailer_key : ''}</h2>
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify="space-around">
                <Col md={24}>
                  <BarChart checkbox={newReport} value={newReportComponents.includes('4')} onChange={(e) => changeNewReportComponent('4', e)} data={selectedRow.length ? selectedRow[0].top_bottom_sku[0].top_sku : {}} bottomData={selectedRow.length ? selectedRow[0].top_bottom_sku[0].bottom_sku : {}} productOptions={['Flavor']} kpiOptions={['Sales']} exportData={exportTopBottom} />
                </Col>
              </Row>
            </div>
          }
          <NewReportForm setNewReport={setNewReport} createReport={createReport} newReportComponents={newReportComponents} reportType={'performance-summary'} setNewReportComponents={setNewReportComponents} openSaveReportDialog={openSaveReportDialog} setOpenSaveReportDialog={setOpenSaveReportDialog} />
        </Loader >
      </Content >
    </Wrapper >
  );
}

export default withRouter(PerformanceSummary);