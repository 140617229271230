import { cloneDeep } from "lodash";
import { setDeclareAppliedFilters } from "../../utilities/helper";

export const wData = [
  {
    label: 'P9 YA',
    value: "1600",
  },
  {
    label: 'Market Contraction/Expansion',
    value: "1900",
  },
  {
    label: 'Category Non-Buyers',
    value: "1700",
  },
  {
    label: 'Shifting',
    value: "2700",
  },
  {
    label: 'P9 CY',
    value: "2700",
  }
];

// Set the filters applied based on the filters selected on the UI while clicking on Apply button (Special for Conversion usecase)
export const declareAppliedFilters = (inputFilter, filtersData) => {
  const { techs, subTechs, packSize } = filtersData;
  let finalFilter = cloneDeep(inputFilter);

  // finalFilter = setDeclareAppliedFilters(finalFilter, "product_pet", pets);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_technology", techs);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_sub_technology", subTechs);
  if (finalFilter.product_package && packSize) finalFilter = setDeclareAppliedFilters(finalFilter, "product_package", packSize);

  return finalFilter;
};