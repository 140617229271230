import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from "history";
import { getState } from '../store';

const browserHistory = createBrowserHistory({ basename: '' });

const userName = sessionStorage.getItem('email')
const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        },
    },
})
ai.addTelemetryInitializer(t => {
    // Update criteria as per your need.
    if (t.ext.trace.name === '/admin') // or anything else
      return false; // disable
    return true; // enable everything else
  });
ai.loadAppInsights()
ai.addTelemetryInitializer((envelope) => {
    let user = '';
    const data = getState();
    if (envelope.baseData.user_AccountId) {
        user = envelope.baseData.user_AccountId.toLowerCase()
    } else if (userName) {
        console.log("userNameuserName", userName)
        user = userName.toLowerCase()
    } else {
        user = data.profile.user.user_email.toLowerCase()
    }
    console.log("envelope", envelope.baseData.user_AccountId, user)
    // console.log("dataaaaaaa", data.profile.defaultBrand)
    envelope.tags = envelope.tags || [];
    console.log("userName", userName);
    envelope.tags.push({ "ai.user.accountId": user });
    console.log("UserEmail", data.profile.user.user_email)
    // envelope.tags.push({ "BrandName": data.profile.defaultBrand });
    if (envelope.data) {
        envelope.data["BrandName"] = data.profile.defaultBrand;
    }
});
export default (Component) => withAITracking(reactPlugin, Component)
export const appInsights = ai.appInsights


