import React, { useEffect } from 'react';
import { DualAxes } from '@ant-design/plots';
import { useState } from 'react';
import { formatNumber } from '../../utilities/helper';

export default function DualAxesChart(props) {

  function getMin() {
    if (props.data && props.yField) {
      if (Math.min(...props.data.map(val => val[props.yField[1]])) > 0)
        return 0
      else
        return Math.min(...props.data.map(val => val[props.yField[1]]))
    }
    else {
      return 0
    }
  }

  const configSet = {
    data: [props.data, props.data],
    xField: props.xField,
    yField: props.yField,
    appendPadding: 20,
    animation: false,
    tooltip: {
      marker: {},
      customItems: (originalItems) => {
        // console.log("Original Items", originalItems)
        let field0Value = formatNumber(originalItems[0].data[props.yField[0]], 1);
        if (props.yBarUnit === "$") field0Value = `$${field0Value}`;
        else if (props.yBarUnit === "%") field0Value = `${field0Value}%`;

        return [
          {
            color: "#A1A1DB",
            name: props.yField[0],
            value: field0Value,
            marker: true,
          },
          {
            color: "#0000A0",
            name: props.yField[1],
            value: `${formatNumber(originalItems[0].data[props.yField[1]], 1)}%`,
            marker: true,
          },
        ];
      },
    },
    // tooltip: {
    //   formatter: (datum) => {
    //     let field1 = props.yField[0]
    //     let field2 = props.yField[1]
    //     console.log("Datum", datum)
    //     return { field1: datum.field1, field2: datum.field2 };
    //   },
    // },
    geometryOptions: [
      {
        geometry: 'column',
        label: {
          position: 'middle',
          style: {
            fill: '#FFFFFF',
            opacity: 0.6,
          },
        },
      },
      {
        geometry: 'line',
        lineStyle: {
          lineWidth: 2,
        },
        point: {},
        label: {

        },
      },
    ],
    interactions: [
      {
        type: 'element-highlight',
      },
      {
        type: 'active-region',
      },
    ],
  };

  const [config, setConfig] = useState(configSet);

  useEffect(() => {
    if (props.yField.length !== 0) {
      // console.log("yAxis", {
      //   ...configSet,
      //   yAxis: {
      //     [props.yField[1]]: {
      //       min: 0,
      //     }
      //   }
      // })
      setConfig({
        ...configSet,
        geometryOptions: [
          {
            geometry: 'column',
            label: {
              position: 'middle',
              formatter: (val) => {
                let value = formatNumber(val[props.yField[0]], 1);
                if (props.yBarUnit === "$") return `$${value}`;
                if (props.yBarUnit === "%") return `${value}%`;
                return value;
              },
              style: {
                fill: '#FFFFFF',
                opacity: 0.6,
              },
            },
            color: '#A1A1DB'
          },
          {
            geometry: 'line',
            lineStyle: {
              lineWidth: 2,
            },
            point: {},
            label: {
              formatter: (val) => {
                return `${formatNumber(val[props.yField[1]], 1)}%`
              },
              style: {
                fill: '#000000',
                opacity: 0.8,
              },
            },
            color: '#0000A0'
          },
        ],
        xAxis: {
          title: { text: "Period Year" },
        },
        yAxis: {
          [props.yField[0]]: {
            title: { text: props.yBar },
            label: {
              style: {
                fill: '#000000',
                opacity: 0.7,
              },
              formatter: (val) => `${formatNumber(val, 1)}`,
            },
          },
          [props.yField[1]]: {
            title: { text: props.yLine },
            label: {
              style: {
                fill: '#000000',
                opacity: 0.7,
              },
              formatter: (val) => {
                return `${formatNumber(val, 1)}`
              },
            },
            min: getMin(),
          }
        },

      })
    }
  }, [props.yField])
  return <DualAxes {...config} />
}