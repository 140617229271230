import React from "react";
import { Line, G2 } from "@ant-design/plots";
import { each, findIndex } from "@antv/util";
import { formatNumber } from "../../utilities/helper";

function TrendChart({ data, xField, yField }) {
  // console.log("data, xField, yField", data, xField, yField);

  const color = ({ key }) => {
    // let colorSet = ["#F06055", "#FAB518", "#098BCB"]
    let colorSet = ["#FCD374"]
    if (data) {
      let keys = [...new Set(data.map(val => val.key))]
      let value = keys.filter(val => val === key)
      return colorSet[keys.findIndex(val => val === value[0])]
    }
    else {
      return colorSet[0]
    }
  }

  const config = {
    data,
    color,
    xField,
    yField,
    label: {},
    seriesField: "key",
    // point: {
    //   size: 5,
    //   shape: "custom-point",
    //   style: {
    //     fill: "white",
    //     stroke: "#5B8FF9",
    //     lineWidth: 2,
    //   },
    // },
    point: {
      // shape: "custom-point",
      style: {
        fill: "#FCD374",
        stroke: "white",
        // lineWidth: 2,
      },
    },
    tooltip: {
      marker: {},
      customItems: (originalItems) => {
        // console.log("Original Items", originalItems)
        return [
          {
            color: "#FCD374",
            name: yField,
            value: formatNumber(originalItems[0].data[yField], 1),
            marker: true,
          },
        ];
      },
    },
    xAxis: {
      title: {
        text: "Periods"
      }
    },
    label: {
      // formatter: (val) => `${formatNumber(val[yField], 1)}`,
      formatter: () => "",
    },
    yAxis: {
      grid: {
        line: {
          style: {
            stroke: '#C4C4C4',
            lineWidth: 1,
            lineDash: [6, 4],
            strokeOpacity: 0.4,
            cursor: 'pointer'
          }
        }
      }
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    interactions: [
      {
        type: "custom-marker-interaction",
      },
    ],
  };
  return (
    <>
      {data ? (
        <Line {...config} />
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', fontSize: 30, marginLeft: "10%",  }}>
          No data available to display
        </div>
      )}
    </>
  );
}

export default TrendChart;
