// URL constants for various services and APIs
export const SERVICE_URL = process.env.REACT_APP_SERVICE_URL;
export const CHAT_WEBSOCKET_URL = process.env.REACT_APP_CHAT_WEBSOCKET_URL;
export const API_INSIGHTS_URL = process.env.REACT_APP_API_INSIGHTS_URL;
export const API_INSIGHTS_API_KEY = process.env.REACT_APP_API_INSIGHTS_API_KEY;
export const LOGIC_APP_URL = process.env.REACT_APP_LOGIC_APP_URL;
export const LOGIC_APP_NOTIFICATION = process.env.REACT_APP_LOGIC_APP_NOTIFICATION;

// List of allowed send mail hosts (array)
export const ALLOWED_SEND_MAIL_HOSTS = [process.env.REACT_APP_SEND_MAIL_HOSTS];
// export const allowedSendMailHosts = [];


// Endpoint URLs for different functionalities
export const ENDPOINTS = {
  logicApp: LOGIC_APP_URL,
  logicAppNotification: LOGIC_APP_NOTIFICATION,
  perfSummary: `${SERVICE_URL}/trackboard/performance_summary/view`,
  volSummary: `${SERVICE_URL}/trackboard/volume_shifting/view`,
  ecomDropdown: `${SERVICE_URL}/ecom/dropdown`,
  trafficDropdown: `${SERVICE_URL}/ecom/traffic/dropdown`,
  conversionDropdown: `${SERVICE_URL}/ecom/conversion/dropdown`,
  ecomCompetitionDropdown: `${SERVICE_URL}/ecom/competition`,
  ecomDiagnosticsCompetition: `${SERVICE_URL}/ecom/diagnostics_competition`,
  cardData: `${SERVICE_URL}/ecom/card`,
  comparisonTrend: `${SERVICE_URL}/ecom/comparison_trend`,
  deepdiveTrend: `${SERVICE_URL}/ecom/deepdive`,
  marcroTable: `${SERVICE_URL}/ecom/performance_macro`,
  trafficMarcroTable: `${SERVICE_URL}/ecom/traffic/performance_macro`,
  shareShelfMarcroTable: `${SERVICE_URL}/ecom/share_of_shelf/performance_macro`,
  shareShelfMircroTable: `${SERVICE_URL}/ecom/share_of_shelf/performance_micro`,
  totalPet: `${SERVICE_URL}/ecom/share_of_shelf/total_pet`,
  mircroTable: `${SERVICE_URL}/ecom/performance_micro`,
  trafficMircroTable: `${SERVICE_URL}/ecom/traffic/performance_micro`,
  benchmarkInternal: `${SERVICE_URL}/ecom/benchmark/internal`,
  benchmarkCategory: `${SERVICE_URL}/ecom/benchmark/category`,
  benchmarkCompetition: `${SERVICE_URL}/ecom/benchmark/competition`,
  shareOfShelfDropdown: `${SERVICE_URL}/ecom/share_of_shelf/dropdown`,
  shareOfShelfCardData: `${SERVICE_URL}/ecom/share_of_shelf/card`,
  shareOFShelfComparisonTrend: `${SERVICE_URL}/ecom/share_of_shelf/comparison_trend`,
  shareOFShelfDeepDiveTrend: `${SERVICE_URL}/ecom/share_of_shelf/deepdive`,
  shareOfShelfCompetitionDropdown: `${SERVICE_URL}/ecom/share_of_shelf/competition`,
  shareOfShelfScatterChart: `${SERVICE_URL}/ecom/share_of_shelf/keyword_bubble_chart`,
  shareOfShelfTraffic_chart: `${SERVICE_URL}/ecom/share_of_shelf/traffic_chart`,
  searchTermDropdown: `${SERVICE_URL}/ecom/search_term/dropdown`,
  searchTermSubDropdown: `${SERVICE_URL}/ecom/search_term/category`,
  searchTermOverview: `${SERVICE_URL}/ecom/search_term/overview`,
  searchTermBarChart: `${SERVICE_URL}/ecom/search_term/bar_chart`,
  searchTermLineChart: `${SERVICE_URL}/ecom/search_term/line_chart`,
  searchTermTable: `${SERVICE_URL}/ecom/search_term/macro`,
  searchTermBubbleChart: `${SERVICE_URL}/ecom/search_term/keyword_bubble_chart`,
  reviewRatingMacro: `${SERVICE_URL}/ecom/review_ratings/performance_macro`,
  reviewRatingMicro: `${SERVICE_URL}/ecom/review_ratings/performance_micro`,
  innovationTracker: `${SERVICE_URL}/trackboard/innovation/view`,
  launchPerformance: `${SERVICE_URL}/launch_performance`,
  benchmarkDropdown: `${SERVICE_URL}/benchmark_dropdown`,
  benchmarkData: `${SERVICE_URL}/benchmark_data`,
  consumerReview: `${SERVICE_URL}/consumer_review`,
  retailerImage: `${SERVICE_URL}/retailer_image`,
  volShiftTable: `${SERVICE_URL}/trackboard/volume_shifting/test`,
  volShiftChannel: `${SERVICE_URL}/trackboard/volume_shifting/channel`,
  volShiftWaterfall: `${SERVICE_URL}/trackboard/volume_shifting/waterfall`,
  trackboard: `${SERVICE_URL}/trackboard/getData`,
  trackboardChart: `${SERVICE_URL}/trackboard/total`,
  innovation: `${SERVICE_URL}/trackboard/innovation`,
  competition: `${SERVICE_URL}/trackboard/competition_summary`,
  trackboardTotal: `${SERVICE_URL}/trackboard/total`,
  trackboardEcomTotal: `${SERVICE_URL}/trackboard/ecom_total`,
  categoryTotal: `${SERVICE_URL}/trackboard/category_total`,
  deepDiveEcom: `${SERVICE_URL}/trackboard/deepdive_ecom`,
  nudges: `${SERVICE_URL}/nudges/view`,
  nudgesDeepDive: `${SERVICE_URL}/nudges/deepdive`,
  distSummary: `${SERVICE_URL}/trackboard/distribution_summary/view`,
  chartTrend: `${SERVICE_URL}/chart_trend`,
  productSubBrand: `${SERVICE_URL}/product_sub_brand`,
  reportAccess: `${SERVICE_URL}/reports/ReportAccess`,
  bookmarkNudge: `${SERVICE_URL}/nudges_bookmark/`,
  irrelevantNudge: `${SERVICE_URL}/irrelevant_nudges/`,
  inaccurateNudge: `${SERVICE_URL}/inaccurate_nudges`,
  listNudges: `${SERVICE_URL}/list_nudges`,
  updateReviewStatus: `${SERVICE_URL}/update_review_status`,
  saveReport: `${SERVICE_URL}/reports/saveReport`,
  existingReports: `${SERVICE_URL}/reports/userReports`,
  deleteReport: `${SERVICE_URL}/reports/deleteReport`,
  getGenericImage: `${SERVICE_URL}/generic/get_image`,
  getReports: `${SERVICE_URL}/reports/getReports`,
  getImage: `${SERVICE_URL}/volume_shifting/brand_logo`,
  getMessages: `${SERVICE_URL}/reports/getMessages`,
  getLatestTime: `${SERVICE_URL}/trackboard/getLatestTime`,
  verifyUser: `${SERVICE_URL}/generic/verifyUser`,
  // verifyUser: `${SERVICE_URL}/generic/verifyUser/`,
  getCompetitorBrands: `${SERVICE_URL}/generic/get_competitor`,
  dataSource: `${SERVICE_URL}/trackboard/datasource`,
  dataSourcesDropdown: `${SERVICE_URL}/data_export/data_export_dropdown`,
  ecomDataSourcesDropdown: `${SERVICE_URL}/data_export/ecom_data_export_dropdown`,
  dataSourcesTask: `${SERVICE_URL}/data_export/start_file_generation`,
  initiateCluster: `${SERVICE_URL}/data_export/initiate_export`,
  clusterInitiated: `${SERVICE_URL}/data_export/check_cluster_state`,
  dataSourcesCompletion: `${SERVICE_URL}/data_export/check_file_download_status`,
  periodDataSourcesCompletion: `${SERVICE_URL}/data_export/status_data_file`,
  ecomDataSourcesCompletion: `${SERVICE_URL}/data_export/check_ecom_file_download_status`,
  deleteTask: `${SERVICE_URL}/data_export/delete_file`,
  admin: `${SERVICE_URL}/admin`,
  adminDropdown: `${SERVICE_URL}/drop_down`,
  adminEditUser: `${SERVICE_URL}/edit_user`,
  adminDeleteUser: `${SERVICE_URL}/delete_admin`,
  apiInsights: `${API_INSIGHTS_URL}/${API_INSIGHTS_API_KEY}`,
  start_file: `${SERVICE_URL}/data_export/start_file_generation`,
  start_period_file: `${SERVICE_URL}/data_export/download_data_file`,
  ecom_start_file: `${SERVICE_URL}/data_export/start_ecom_file_generation`,
  dataPreviewTable: `${SERVICE_URL}/data_export/table_preview`,
  ecomDataPreviewTable: `${SERVICE_URL}/data_export/ecom_table_preview`,
  userNote: `${SERVICE_URL}/user_note`,
  innovationChart: `${SERVICE_URL}/demand_forecast`,
  drainerChart: `${SERVICE_URL}/incrementality`,
  diagnosticsDropdown: `${SERVICE_URL}/ecom/diagnostics/dropdown`,
  diagnosticsCardData: `${SERVICE_URL}/ecom/diagnostics/card`,
  diagnosticsTableData: `${SERVICE_URL}/ecom/diagnostics/retailertable`,
  diagnosticsKpiTrends: `${SERVICE_URL}/ecom/diagnostics/comparison_trend`,
  diagnosticsBenchmarkTotalPet: `${SERVICE_URL}/ecom/benchmark/diagnostics_total_pet`,
  diagnosticsBenchmarkInternal: `${SERVICE_URL}/ecom/benchmark/diagnostics_internal`,
  diagnosticsBenchmarkCategory: `${SERVICE_URL}/ecom/benchmark/diagnostics_category`,
  diagnosticsBenchmarkCompetition: `${SERVICE_URL}/ecom/benchmark/diagnostics_competition_v1`,
  beSelectedCardData: `${SERVICE_URL}/ecom/reviews_ratings/card`,
  beSelectedComparisonTrend: `${SERVICE_URL}/ecom/reviews_ratings/overview_trend`,
  beSelectedDeepDive: `${SERVICE_URL}/ecom/reviews_ratings/deep_dive`,
  beSelectedScaterPlotDropdown: `${SERVICE_URL}/ecom/reviews_ratings/scatter_plot_dropdown`,
  beSelectedScaterPlot: `${SERVICE_URL}/ecom/reviews_ratings/scatter_plot`,
  trafficCard: `${SERVICE_URL}/ecom/traffic/card`,
  trafficComparisonTrend: `${SERVICE_URL}/ecom/traffic/overview_trend`,
  externalTrafficChart: `${SERVICE_URL}/ecom/traffic/traffic_chart`,
  trafficDeepdiveTrend: `${SERVICE_URL}/ecom/traffic/deep_dive`,
  consumerRatingsandReview: `${SERVICE_URL}/ecom/review_ratings/consumer_review`,
  conversionCard: `${SERVICE_URL}/ecom/conversion/card`,
  conversionComparisonTrend: `${SERVICE_URL}/ecom/conversion/overview_trend`,
  conversionDeepDiveTrend: `${SERVICE_URL}/ecom/conversion/deep_dive`,
  conversionMacro: `${SERVICE_URL}/ecom/conversion/performance_macro`,
  conversionMicro: `${SERVICE_URL}/ecom/conversion/performance_micro`,
  portfolioSummary: `${SERVICE_URL}/trackboard/portfolio_summary/view`,
  portfolioSummaryChannel: `${SERVICE_URL}/trackboard/portfolio_summary/channel`,
  getPortfolioImage: `${SERVICE_URL}/portfolio_summary/brand_logo`,
  portfolioSummaryTable: `${SERVICE_URL}/trackboard/portfolio_summary/test`,
};

// Innovation Tracker Constants
export const itConstants = {
  launchPeriod: "Launch Period"
}

//Diagnostics trend chart compare via constants
export const compareViaForTrend = {
  manufacturer: "Manufacturer",
  brand: "Brand",
  // subBrand: "Sub Brand",
};

export const compareViaForTrackboard = [
  "Category",
  "Channel",
]
export const compareViaForTrackboardEcom = [
  "Retailer",
  "Category",
]


export const trackboardKpiDataForMars = {
  dollar: "Sales",
  dollar_share: "$ share",
  tdp: "TDP",
  tdp_share: "Velocity",
};
export const trackboardKpiDataForEcom = {
  dollar: "Sales",
  dollar_share: "$ share",
  share_of_shelf: "Share of Shelf",
  traffic_similarweb: "Traffic Similarweb",
  traffic_stackline: "Traffic Stackline",
  itmes_in_stock: "In Stock %"
};
export const yearTimePeriodLimits = {
  l12p: 12,
  l24p: 24,
  bottomToTopl12p: -12,
  bottomToTopl24p: -24
}
export const trackboardKpiData = [
  {
    title: "Be Available",
    value: "Be Available",
    key: "Be Available",
    checkable: false,
    selectable: false,
    children: [
      {
        title: "In stock %",
        value: "In stock %",
        key: "In stock %",
      },
      {
        title: "CSL",
        value: "CSL",
        key: "CSL",
      },
      {
        title: "# of Items Listed",
        value: "# of Items Listed",
        key: "# of Items Listed",
      },
    ],
  },
  {
    title: "Be Visible",
    value: "Be Visible",
    key: "Be Visible",
    checkable: false,
    selectable: false,
    children: [
      {
        title: "Traffic",
        value: "Traffic",
        key: "Traffic",
      },
      {
        title: "Share of Shelf",
        value: "Share of Shelf",
        key: "Share of Shelf",
      },
      {
        title: "Share of Traffic",
        value: "Share of Traffic",
        key: "Share of Traffic",
      },
      {
        title: "Average Placement",
        value: "Average Placement",
        key: "Average Placement",
      },
      {
        title: "Fair Share Index",
        value: "Fair Share Index",
        key: "Fair Share Index",
      },
    ],
  },
  {
    title: "Be Selected",
    value: "Be Selected",
    key: "Be Selected",
    checkable: false,
    children: [
      {
        title: "Conversion Rate",
        value: "Conversion Rate",
        key: "Conversion Rate",
      },
      {
        title: "Total Unit Sold",
        value: "Total Unit Sold",
        key: "Total Unit Sold",
      },
      {
        title: "Content Compliance Score",
        value: "Content Compliance Score",
        key: "Content Compliance Score",
      },
      {
        title: "Hero Image",
        value: "Hero Image",
        key: "Hero Image",
      },
      {
        title: "Product Title",
        value: "Product Title",
        key: "Product Title",
      },
      {
        title: "Product Discription",
        value: "Product Discription",
        key: "Product Discription",
      },
      {
        title: "# of Images",
        value: "# of Images",
        key: "# of Images",
      },
      {
        title: "Video Content",
        value: "Video Content",
        key: "Video Content",
      },
      {
        title: "Rich Content",
        value: "Rich Content",
        key: "Rich Content",
      },
      {
        title: "Average Ratings",
        value: "Average Ratings",
        key: "Average Ratings",
      },
      {
        title: "Average # of Reviews",
        value: "Average # of Reviews",
        key: "Average # of Reviews",
      },
      {
        title: "Average # of Reviews Per Item",
        value: "Average # of Reviews Per Item",
        key: "Average # of Reviews Per Item",
      },
      {
        title: "Average Sentiment",
        value: "Average Sentiment",
        key: "Average Sentiment",
      },
    ],
  },
];