import React, { useEffect, useState } from "react";
import {
  Col,
  Layout,
  Row,
  Form,
  Button,
  Select,
  Menu,
  Dropdown,
  Checkbox,
  Radio,
  TreeSelect,
  Switch,
  Tooltip,
  Rate,
} from "antd";
import { cloneDeep, isEqual, orderBy } from "lodash";
import moment from "moment";
import { Scatter, Bar } from "@ant-design/plots";
import { useHistory, withRouter } from "react-router-dom";
import Wrapper from "../../components/wrapper";
import {
  DownloadOutlined,
  PlusCircleOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import DataCard from "../../components/data-card";
import CustomTable from "../../components/customTable";
import DonutChart from "../../components/donut-chart";
import WaterfallChart from "../../components/waterfall-chart";
import BarChart from "../../components/bar-chart";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllFilters,
  getFilterData,
  getInitialFilters,
  getMacroTableData,
  getMicroTableData,
  setAppliedFilters,
  getComparisonTrendData,
  getDeepdiveTrendData,
  getCardData,
  getScatterPlotDropdown,
  getScatterPlot,
  setFilters,
  getReviewData,
  setOpportunitySelection,
  setSelectedCompetitor,
  getCompetitionFilterData,
  getInitialBottomFilters,
} from "../../slicers/be-selected.slicer";
import {
  numFmt
} from "../../utilities/exports/excel";
import Loader from "../../components/loader";
import {
  formatNumber,
  formatDateString,
  openNotificationWithIcon,
  setWindowHistory,
  valueTypes,
  brandSplit,
  allBrands,
  marsPN,
  getTitleWidth,
  arraySearch,
  handleTab,
} from "../../utilities/helper";
import {
  setExportData,
  setPPTExportData,
} from "../../slicers/exportData.slicer";
import {
  exportPerfKPIs,
  exportWaterfall,
  exportBar,
  exportPerformanceSummary,
  exportMarketFinancialsTable,
} from "../../utilities/exports/excel";
import NewReportForm from "../../components/newReportForm";
import { pptExportPerformance } from "../../utilities/exports/ppt";
import { isPackSize } from "../volume-shifting";
import mdiCart from "../../assets/images/mdi_cart.svg";
import mdiDecagram from "../../assets/images/mdi_check-decagram.svg";
import mdiCheck from "../../assets/images/mdi_eye-check.svg";
import mdiView from "../../assets/images/mdi_view-dashboard.svg";
import reviewImg from "../../assets/images/amazon.png";
import { appInsights } from "../../utilities/appInsights";
import { CaretUpFilled, CaretDownFilled } from "@ant-design/icons";
import {
  pet,
  technology,
  retailer,
  periods,
  timeAggrigate,
  benchMarkPeriod,
} from "../ecom/data";
import "./be-selected.scss";
import {
  ColumnBulletChart,
  ColumnLineChart,
  MultiLineChart,
  kpiDropdownBeSelected,
  kpiDropdownBeSelectedAverageRating,
  rowKeyCompetitionBenchmark,
  rowKeyMacro,
  rowKeyMicro,
  rowKeyPerfOverview,
  viewBy,
  viewByOpportunity,
  viewByOpportunitySub,
  reivewRetailersRnR,
} from "../be-available/baUIHelper";
import columns from "./beColumns";
import {
  columnBulletData,
  columnLineData,
  competitorData,
  columnBulletSubBrandData,
  columnBulletCompititionData,
  multiLineData,
  perfTableData,
  config,
  getConfigBarConsumerReview,
  reviewFilterBy,
  kpiData,
  declareAppliedFilters,
} from "./beUIHelper";
import CompetitionAndBenchmark from "../../components/competition-benchmark";
import { categoryData, compititionData, ecomCompititionData } from "../ecom/dgUIHelper";
import { compareVia } from "../../components/competition-benchmark";
import BubbleChart from "../../components/bubble-chart";
import Navigator from "../../components/bread-crumbs";
const { Content } = Layout;
const { Option } = Select;

function EcomBeSelected(props) {
  const dispatch = useDispatch();
  const { defaultBrand } = useSelector((state) => state.profile);
  //   const { loading, pets, techs, packSize, years, periods, table_data, filters, appliedFilters } = useSelector((state) => state.perfSummary)
  const exportData = useSelector((state) => state.exportData);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState([]);
  const [filterChanged, setFilterChanged] = useState(false);
  const bubblechartLabel = {
    label: "label",
    sizeField: "avg_no_of_reviews",
    keywordColor: "sentiment",
    size: true,
  };
  const [sortOrder, setSortOrder] = useState(false);
  const [sortLoading, setSortLoading] = useState(false);

  const {
    loading,
    microLoading,
    consumerLoading,
    dropdownLoading,
    pets,
    techs,
    feedingPhilosophies,
    textures,
    retailers,
    timeAggregates,
    yearPeriods,
    benchmarkPeriods,
    comparisonTrendData,
    deepdiveSingleTrendData,
    deepdiveMultiTrendData,
    competitors,
    cardData,
    filters,
    appliedFilters,
    macroTableData,
    scaterPlot,
    microTableData,
    scaterPlotDropdown,
    reviewData,
    retailerImage,

    // On mouse leave
    selectedCompetitor,
    opportunitySelection,
  } = useSelector((state) => state.beSelected);


  const [expand, setExpand] = useState(false);
  const [newReport, setNewReport] = useState(false);
  const [createReport, setCreateReport] = useState(true);
  const [openSaveReportDialog, setOpenSaveReportDialog] = useState(false);
  const [newReportComponents, setNewReportComponents] = useState([]);
  const [brandToggle, setBrandToggle] = useState(false);
  const [components, setComponents] = useState([]);
  const [lastDefaultBrand, setLastDefaultBrand] = useState("");

  const [apply, setApply] = useState(false);
  const isAllBrands = defaultBrand === allBrands;

  const [stored, setStored] = useState(false);


  if (isAllBrands) viewBy.S = "Brand";
  else viewBy.S = "Sub Brand";
  const history = useHistory();

  const [retailerDropdown, setRetailerDropdown] = useState([]);

  // On Mouse Leave events
  const [selectedRetailer, setSelectedRetailer] = useState([]);
  const [macroColumns, setMacroColumns] = useState(columns.beMacroCols);
  const [microColumns, setMicroColumns] = useState(columns.beMicroCols);
  const [macroColumnsExport, setMacroColumnsExport] = useState(
    columns.beMacroColsExport
  );
  const [microColumnsExport, setMicroColumnsExport] = useState(
    columns.beMicroColsExport
  );
  const [showLabel, setShowLabel] = useState(false);
  const { shareOfShelf } = useSelector((state) => state.refreshDate);

  const isReport = props.isReport;
  const { SHOW_PARENT } = TreeSelect;

  function TopThemes() {
    return (
      <div className="top-keywords">
        <h5 style={{ textAlign: "center" }}>Top Themes</h5>
        <ul>
          {reviewData?.review_tags?.map((tag) => <li>{tag}</li>)}
          {/* <li>Product Attribute: Flavour & Taste (20)</li>
          <li>Product Attribute: Ingredients (18)</li>
          <li>Product Effectiveness: Digestion (20)</li> */}
        </ul>
      </div>
    );
  }

  const getMicroSheetName = () =>
    selectedRow.length > 0 ? `${selectedRow[0].table_key} UPCs` : "UPCs";

  const getTertileTitle = () => {
    let title = "";
    if (
      (arraySearch(appliedFilters.product_pet, "CAT") &&
        arraySearch(appliedFilters.product_pet, "DOG")) ||
      !Array.isArray(appliedFilters.product_pet) ||
      arraySearch(appliedFilters.product_pet, "ALL") ||
      appliedFilters.product_pet.length === 0 ||
      appliedFilters.product_pet.length === pets.length
    )
      title = "TOTAL PET";
    else if (arraySearch(appliedFilters.product_pet, "CAT")) title = "CAT";
    else if (arraySearch(appliedFilters.product_pet, "DOG")) title = "DOG";

    title +=
      !Array.isArray(appliedFilters.product_technology) ||
        arraySearch(appliedFilters.product_technology, "ALL") ||
        appliedFilters.product_technology.length === 0 ||
        appliedFilters.product_technology.length === techs.length
        ? ""
        : " " + appliedFilters.product_technology.filter(tech => tech !== "Items Not Yet Classified").join(", ");
    // return "MM EMRGNG,MM WET,SUPPLEMENTS,TREATS,Items Not Yet Classified";
    return title;
  };


  const updateRetailerDropdown = (filters, isApply = false) => {
    const newFilters = { ...filters };
    if (retailers.length === 0) return [];
    const retailerDropdown = retailers.filter(
      (retailer) =>
        !(
          isApply ? filters : appliedFilters
        ).customer_retailer_details.includes(retailer)
    );
    //Test Comment
    const filteredSelectedRetailers = [...newFilters.selected_retailer].filter(
      (retailer) => retailerDropdown.includes(retailer)
    );
    const selectedRetailers = [...newFilters.selected_retailer];
    if (!isEqual(filteredSelectedRetailers.sort(), selectedRetailers.sort())) {
      newFilters.selected_retailer = filteredSelectedRetailers;
      setSelectedRetailer(filteredSelectedRetailers);
    }

    setRetailerDropdown(retailerDropdown);
    return newFilters;
  };


  const getData = (filters, fetchFilterData = true, clearHistory = true, initialFilterData = true) => {
    dispatch(setAppliedFilters(declareAppliedFilters(filters, { pets, techs, feedingPhilosophies, textures }))); dispatch(setFilters(filters));



    // API calls
    if (fetchFilterData)
      if (initialFilterData) dispatch(getAllFilters());
      else dispatch(getFilterData({
        customer_retailer_details: filters.customer_retailer_details,
        time_aggregate: filters.time_aggregate,
        year_period: filters.year_period,
        benchmark: filters.benchmark,
      }, true));
    else {
      dispatch(getComparisonTrendData(filters));
      dispatch(getCompetitionFilterData(filters));
      dispatch(getCardData(filters));
      dispatch(getScatterPlotDropdown(filters));
      dispatch(getScatterPlot(filters));
      dispatch(getReviewData(filters));
    }
  };

  const getComparisonTitle = () =>
    `${filters.be_selected_comparison_kpi[0]} - ${viewBy[filters.view_by[0]]
    } Comparison`;
  const getDeepdiveTitle = () =>
    `${filters.be_selected_deepdive_kpi[0]} - ${filters.deep_dive_list.length === 1
      ? filters.deep_dive_list[0]
      : "Deep Dive"
    }`;



  const applyFilters = () => {
    const newFilters = {
      ...updateRetailerDropdown(filters, true),
      default_brand: [defaultBrand],
      deep_dive_list: [],
    };

    setWindowHistory(encodeURIComponent(JSON.stringify(newFilters)));
    getData(newFilters, false, false);
  };

  const getRemainingFilters = (label) => {
    if (!filterChanged) return;

    setFilterChanged(false);
    const isProductPackage =
      filters.product_technology.length === 1 &&
      isPackSize.includes(filters.product_technology[0]);

    if (label === "product_pet") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_technology") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_feed_philosophy") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_feed_philosophy: filters.product_feed_philosophy,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "product_texture") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,

          // default filters
          customer_retailer_details: filters.customer_retailer_details,
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "customer_retailer_details") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          customer_retailer_details: filters.customer_retailer_details,

          // default filters
          time_aggregate: filters.time_aggregate,
          year_period: filters.year_period,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "year_period") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          customer_retailer_details: filters.customer_retailer_details,
          year_period: filters.year_period,

          // default filters
          time_aggregate: filters.time_aggregate,
          benchmark: filters.benchmark,
        })
      );
    } else if (label === "time_aggregate") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_feed_philosophy: filters.product_feed_philosophy,
          product_texture: filters.product_texture,
          customer_retailer_details: filters.customer_retailer_details,
          year_period: filters.year_period,
          time_aggregate: filters.time_aggregate,

          // default filters
          benchmark: filters.benchmark,
        })
      );
    }
  };
  const handleLabel = (checked) => {
    setShowLabel(checked);
  };
  const changeFilter = (label, selectedValue) => {
    if (!isEqual(filters[label], selectedValue)) setFilterChanged(true);

    let newFilters = { ...filters };
    if (
      selectedValue &&
      selectedValue.length &&
      selectedValue.includes("all")
    ) {
      let all = [];
      if (label === "product_pet") all = pets;
      else if (label === "product_technology") all = techs;
      else if (label === "product_feed_philosophy") all = feedingPhilosophies;
      else if (label === "product_texture") all = textures;
      if (selectedValue.length === all.length + 1) selectedValue = [];
      else selectedValue = [...all];
    }

    newFilters[label] = selectedValue;
    dispatch(setFilters(newFilters));
    // setLocalFilter(newFilters);
    return newFilters;
  };
  // const dropdownOption = (type) => {
  //   const option = viewByOpportunity.filter((kpi) => { 
  //     if(filters.default_brand[0] === "ALL BRANDS" && kpi !== "Sub Brand"){
  //       return({
  //         value: kpi,
  //         label: kpi,
  //       })  
  //     }
  //     else if(filters.default_brand[0] !== "ALL BRANDS"  && kpi !== "Brand"){
  //       return({
  //         value: kpi,
  //         label: kpi,
  //       })  
  //     }
  //     })
  //     console.log("option", option);
  //     return option
  // }
  const appendRetailers = (
    columnIndex,
    columns,
    record,
    kpi,
    renderFunction,
    renderPPTFunction,
    sort
  ) => {
    if (record[kpi]) {
      const sorting = kpi === "sales" && sort === "sortTrue";
      const isSingleRetailer = Object.keys(record[kpi]).length === 1;

      const parentTitle = columns[columnIndex].title;
      const parentWidth = getTitleWidth(parentTitle, sorting);
      columns[columnIndex].children = Object.keys(record[kpi]).map(
        (retailer) => {
          const retailerWidth = getTitleWidth(retailer, sorting);
          return {
            title: retailer,
            dataIndex: [kpi, retailer],
            dataIndexPPT: kpi,
            key: retailer,
            width: isSingleRetailer
              ? Math.max(parentWidth, retailerWidth)
              : retailerWidth,
            render: (val) => renderFunction(val),
            renderPPT: (val) => renderPPTFunction(val),
            sorter: sorting
              ? (a, b) => a.sales[retailer]?.abs - b.sales[retailer]?.abs
              : false,
            className: "sub-td-border-left",
          }
        }
      );
      if (columns[columnIndex].children.length)
        columns[columnIndex].children[0].className = "td-border-left";
    }
  };

  const appendRetailersNested = (
    columnIndex,
    childIndex,
    columns,
    record,
    kpi,
    renderFunction,
    renderPPT,
    sort
  ) => {
    if (record[kpi]) {
      const sortingKpis = ["NO SORTING YET"];
      const sorting = sort && sortingKpis.includes(kpi);
      const isSingleRetailer = Object.keys(record[kpi]).length === 1;

      const parentTitle = columns[columnIndex].children[childIndex].title;
      const parentWidth = getTitleWidth(parentTitle, sorting);
      columns[columnIndex].children[childIndex].children = Object.keys(
        record[kpi]
      ).map((retailer) => {
        const retailerWidth = getTitleWidth(retailer, sorting);
        return {
          title: retailer,
          dataIndex: [kpi, retailer],
          key: `${kpi}${retailer}`,
          width: isSingleRetailer
            ? Math.max(parentWidth, retailerWidth)
            : retailerWidth,
          render: (val) => renderFunction(val),
          // sorter: sorting
          //   ? (a, b) => (a[kpi][retailer]?.abs < b[kpi][retailer]?.abs ? -1 : 1)
          //   : false,
          className: "sub-td-border-left",
        };
      });

      if (columns[columnIndex].children.length)
        columns[columnIndex].children[0].className = "td-border-left";
    }
  };

  const appendRetailersExport = (
    columns,
    record,
    kpi,
    title,
    renderTextAbs,
    renderTextVariance
  ) => {

    if (record[kpi]) {
      Object.keys(record[kpi]).forEach((retailer) => {
        // abs
        columns.push({
          title: `${title} ${retailer}`,
          dataIndex: kpi,
          key: kpi,
          renderText: (val) => renderTextAbs(val && val[retailer]),
        });

        // variance
        columns.push({
          title: `${title} ${retailer} vs Benchmark`,
          dataIndex: kpi,
          key: kpi,
          renderText: (val) => renderTextVariance(val && val[retailer]),
        });
      });
    }
  };

  const formatTableData = (table_data) => {
    const tableDataInStore = JSON.parse(JSON.stringify(table_data));
    const tableData = [];
    tableDataInStore.forEach((data) => {
      tableData.push(data);
      if (data.table_hierarchy_data)
        tableData.push(...data.table_hierarchy_data);
    });

    dispatch(setExportData({ macroTableData: { data: tableData } }));
  };
  // useEffect(()=>{
  //   let newFilter
  //   if(isAllBrands){
  //     newFilter = {
  //       ...filters,
  //       opportunity_view_by: ["Brand"],
  //       opportunity_selection: [],
  //     }
  //   } else {
  //     newFilter = {
  //       ...filters,
  //       opportunity_view_by: ["Sub Brand"],
  //       opportunity_selection: [],
  //     }
  //   }
  //   dispatch(setFilters(newFilter));
  // },[])
  useEffect(() => {
    // Macro table dynamic column binding
    if (macroTableData.length) {
      const record = macroTableData[0];
      const beMacroColumns = cloneDeep(columns.beMacroCols);
      const beMacroColumnsExport = cloneDeep(columns.beMacroColsExport);

      if (filters.view_by[0] === "C") beMacroColumns[0].title = "Brand";
      if (filters.view_by[0] === "C") beMacroColumnsExport[0].title = "Brand";
      beMacroColumns[6].title = getTertileTitle();

      // UI
      // $ Sales
      appendRetailers(
        1,
        beMacroColumns,
        record,
        "sales",
        columns.renderDollarPercentFunction,
        columns.renderPPTDollarPercentageFunction,
        "sortTrue"
      );
      // $ Share
      appendRetailers(
        2,
        beMacroColumns,
        record,
        "dollar_share",
        columns.renderPercentagePointsFunction,
        columns.renderPPTPercentagePtsFunction,
        "sortFalse"
      );
      // Average Rating
      appendRetailers(
        3,
        beMacroColumns,
        record,
        "average_ratings",
        columns.renderPercentFunction,
        columns.renderPPTFunction,
        "sortFalse"
      );
      // Average # of Reviews
      appendRetailers(
        4,
        beMacroColumns,
        record,
        "no_of_reviews",
        columns.renderPercentFunction,
        columns.renderPPTFunction,
        "sortFalse"
      );
      // Average # of Reviews per Item
      appendRetailers(
        5,
        beMacroColumns,
        record,
        "avg_no_of_reviews",
        columns.renderPercentFunction,
        columns.renderPPTFunction,
        "sortFalse"
      );
      // Dog Dry: Top Tertile Average Rating
      appendRetailersNested(
        6,
        0,
        beMacroColumns,
        record,
        "tertile_average_ratings",
        columns.renderFunction
      );
      // Dog Dry: Top Tertile Average # of Reviews
      appendRetailersNested(
        6,
        1,
        beMacroColumns,
        record,
        "tertile_no_of_reviews",
        columns.renderFunction
      );

      // Export
      appendRetailersExport(
        beMacroColumnsExport,
        record,
        "sales",
        beMacroColumns[1].title,
        columns.renderTextDollarFunction,
        columns.renderTextVariancePercentageFunction
      );
      appendRetailersExport(
        beMacroColumnsExport,
        record,
        "dollar_share",
        beMacroColumns[2].title,
        columns.renderTextAbsPercentageFunction,
        columns.renderTextVarianceNumberFunction
      );
      appendRetailersExport(
        beMacroColumnsExport,
        record,
        "average_ratings",
        beMacroColumns[3].title,
        columns.renderTextAbsNumber,
        columns.renderTextVariancePercentageFunction
      );
      appendRetailersExport(
        beMacroColumnsExport,
        record,
        "no_of_reviews",
        beMacroColumns[4].title,
        columns.renderTextAbsNumber,
        columns.renderTextVariancePercentageFunction
      );
      appendRetailersExport(
        beMacroColumnsExport,
        record,
        "avg_no_of_reviews",
        beMacroColumns[5].title,
        columns.renderTextAbsNumber,
        columns.renderTextVariancePercentageFunction
      );

      setMacroColumns(beMacroColumns);
      setMacroColumnsExport(beMacroColumnsExport);
      formatTableData(macroTableData);
    }
  }, [macroTableData]);

  // Micro table dynamic column binding
  useEffect(() => {
    if (microTableData.length) {
      const record = microTableData[0];
      const beMicroColumns = cloneDeep(columns.beMicroCols);
      const beMicroColumnsExport = cloneDeep(columns.beMicroColsExport);
      beMicroColumns[7].title = getTertileTitle();

      // $ Sales
      appendRetailers(
        2,
        beMicroColumns,
        record,
        "sales",
        columns.renderDollarPercentFunction,
        columns.renderPPTDollarPercentageFunction,
        "sortTrue"
      );
      // $ Share
      appendRetailers(
        3,
        beMicroColumns,
        record,
        "dollar_share",
        columns.renderPercentagePointsFunction,
        columns.renderPPTPercentagePtsFunction,
        "sortFalse"
      );
      // Average Rating
      appendRetailers(
        4,
        beMicroColumns,
        record,
        "average_ratings",
        columns.renderPercentFunction,
        columns.renderPPTFunction,
        "sortFalse"
      );
      // Average # of Reviews
      appendRetailers(
        5,
        beMicroColumns,
        record,
        "no_of_reviews",
        columns.renderPercentFunction,
        columns.renderPPTFunction,
        "sortFalse"
      );
      // Rating Percentile
      appendRetailers(
        6,
        beMicroColumns,
        record,
        "rating_percentile",
        columns.renderPercentageFunction,
        columns.renderPPTFunction,
        "sortFalse"
      );
      // Review Percentile
      appendRetailers(
        7,
        beMicroColumns,
        record,
        "review_percentile",
        columns.renderPercentageFunction,
        columns.renderPPTFunction,
        "sortFalse"
      );
      // Dog Dry: Top Tertile Average Rating
      appendRetailersNested(
        8,
        0,
        beMicroColumns,
        record,
        "tertile_average_ratings",
        columns.renderFunction,
      );
      // Dog Dry: Top Tertile Average # of Reviews
      appendRetailersNested(
        8,
        1,
        beMicroColumns,
        record,
        "tertile_no_of_reviews",
        columns.renderFunction,
      );

      // Export
      // appendRetailersExport(
      //   beMicroColumnsExport,
      //   record,
      //   "sales",
      //   beMicroColumns[2].title,
      //   columns.renderTextDollarFunction,
      //   columns.renderTextVariancePercentageFunction
      // );

      // appendRetailersExport(
      //   beMicroColumnsExport,
      //   record,
      //   "in_stock",
      //   beMicroColumns[3].title,
      //   columns.renderTextAbsPercentageFunction,
      //   columns.renderTextVarianceNumberFunction
      // );

      // appendRetailersExport(
      //   beMicroColumnsExport,
      //   record,
      //   "csl",
      //   beMicroColumns[4].title,
      //   columns.renderTextAbsPercentageFunction,
      //   columns.renderTextVarianceNumberFunction
      // );

      setMicroColumns(beMicroColumns);
      setMicroColumnsExport(beMicroColumnsExport);
      dispatch(setExportData({ microTableData: { title: getMicroSheetName(), data: microTableData } }));
    }
  }, [microTableData]);

  useEffect(() => {
    const initialFilters = {
      ...getInitialBottomFilters(),
      default_brand: [defaultBrand],
      opportunity_view_by: isAllBrands ? ["Brand"] : ["Sub Brand"],
      opportunity_selection: [],
    };

    let temp = new URL(window.location.href);
    if (temp.search.length > 1) {
      try {
        let preFilters = JSON.parse(
          decodeURIComponent(temp.search.substring(1))
        );
        let currentFilter = { ...filters };
        for (const [k, v] of Object.entries(preFilters)) {
          if (k in currentFilter) {
            currentFilter = {
              ...currentFilter,
              [k]: [...v],
            };
          }
        }

        currentFilter = {
          ...currentFilter,
          default_brand: [defaultBrand],
          opportunity_view_by: isAllBrands ? ["Brand"] : ["Sub Brand"],
          opportunity_selection: [],
        };

        setWindowHistory(encodeURIComponent(JSON.stringify(currentFilter)));
        getData(currentFilter, true, false, false);
      } catch (e) {
        console.log(e);
        getData(initialFilters);
      }
    } else {
      getData(initialFilters);
    }

    setLastDefaultBrand(defaultBrand);
  }, [defaultBrand, props.reportApply]);


  useEffect(() => {
    if (comparisonTrendData.length) setStored(false);
  }, [comparisonTrendData]);


  useEffect(() => {
    if (macroTableData.length) {
      setSelectedRowKey([macroTableData[0][rowKeyMacro]]);
      setSelectedRow([macroTableData[0]]);
    } else {
      setSelectedRowKey([]);
      setSelectedRow([]);
    }
  }, [macroTableData]);

  useEffect(() => {
    setSelectedRowKey([perfTableData[0][rowKeyPerfOverview]]);
    // formatTableData(perfTableData);
  }, [perfTableData]);

  function ReviewBox(props) {
    const retailer =
      retailerImage &&
      retailerImage.find(
        ({ customer_retailer_details }) =>
          customer_retailer_details === props.retailer
      );

    return (
      <div className="review-box">
        <Row style={{ justifyContent: "space-between" }}>
          <Rate
            allowHalf
            disabled
            defaultValue={props.rating}
            style={{ fontSize: "larger" }}
          />
          {retailer ? (
            <img
              src={`data:image/png;base64,${retailer.image_data}`}
              style={{ height: 30, width: 70 }}
            />
          ) : (
            props.retailer
          )}
        </Row>
        <Row>
          <p style={{ fontWeight: "bold" }}>
            {props.review ? props.review : "NA"}
          </p>
        </Row>
        <Row style={{ fontSize: "smaller" }}>
          <p>
            {props.owner ? props.owner : "NA"} |{" "}
            {props.reviewDate ? props.reviewDate : "NA"}
          </p>
        </Row>
      </div>
    );
  }
  return (
    <Wrapper
      hideHeader={props.hideReportHeader}
      hidePage={isReport}
      heading={"1"}
      innerHeading={"17"}
      ecomTab={"0"}
    >
      <Content
        className={
          "site-layout-background" +
          (isReport && components.length === 0
            ? " content-none"
            : " content-body")
        }
      >
        <Loader
          loading={(isReport ? props.reportLoader : loading > 0) || sortLoading}
        >
          {/* <Loader loading={isReport ? props.reportLoader : loading > 0}> */}
          {!props.hideReportFilters && (
            <>
              <Row>
                <Navigator refreshDate={shareOfShelf} />
              </Row>
              <Row className="beSelected-card">
                <div>
                  <span>
                    <img src={mdiCart} alt="Diagnostics" width="22" />
                  </span>
                  <b> Be Selected</b> Conversion is pivotal, reflecting a brand's success in turning shoppers into paying customers. Content, ratings, reviews, and sentiment drive brand conversions.
                </div>
              </Row>

              <Row>
                <Col className="btn-grp">
                  <div>
                    <Radio.Group defaultValue="rating" buttonStyle="solid" onChange={(e) => handleTab(e, history)}>
                      <Radio.Button value="conversion">Conversion</Radio.Button>
                      <Radio.Button value="content">
                        Content Compliance
                      </Radio.Button>
                      <Radio.Button value="rating">Ratings & Reviews</Radio.Button>
                    </Radio.Group>
                  </div>
                </Col>
              </Row>

              <Row align="middle" style={{ marginBottom: 16 }} gutter={[16, 16]}>
                <Col span={18}>
                  <Form
                    name="horizontal_login"
                    className="performance-filter"
                    layout="inline"
                  >
                    <Row gutter={12}>
                      <div className="filters-ps">
                        <Col>
                          <Tooltip title="Use filters to view selected Category, Tech, Feeding Philosophy, and/or Texture by Retailer">
                            <label className="label-block">Pet</label>
                            <Select
                              placeholder="All"
                              mode="multiple"
                              defaultValue={["all"]}
                              dropdownMatchSelectWidth={false}
                              autoClearSearchValue={false}
                              style={{ width: 70 }}
                              placement="bottomLeft"
                              maxTagCount="responsive"
                              value={filters.product_pet}
                              onMouseLeave={() =>
                                getRemainingFilters("product_pet")
                              }
                              onChange={(val) => {
                                changeFilter("product_pet", val);
                              }}
                            >
                              <Option value={"all"}>{"All"}</Option>
                              {pets.map((val) => (
                                <Option value={val}>{val}</Option>
                              ))}
                            </Select>
                          </Tooltip>
                        </Col>
                        <Col>
                          <label className="label-block">Technology</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            placement="bottomLeft"
                            style={{ width: 76 }}
                            maxTagCount="responsive"
                            value={filters.product_technology}
                            loading={dropdownLoading}
                            autoClearSearchValue={false}
                            onMouseLeave={() =>
                              getRemainingFilters("product_technology")
                            }
                            onChange={(val) => {
                              changeFilter("product_technology", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {techs.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">
                            Feeding Philosophy
                          </label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            placement="bottomLeft"
                            style={{ width: 128 }}
                            maxTagCount="responsive"
                            value={filters.product_feed_philosophy}
                            autoClearSearchValue={false}
                            loading={dropdownLoading}
                            onMouseLeave={() =>
                              getRemainingFilters("product_feed_philosophy")
                            }
                            onChange={(val) => {
                              changeFilter("product_feed_philosophy", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {feedingPhilosophies.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Texture</label>
                          <Select
                            placeholder="All"
                            mode="multiple"
                            defaultValue={["all"]}
                            dropdownMatchSelectWidth={false}
                            placement="bottomLeft"
                            style={{ width: 80 }}
                            autoClearSearchValue={false}
                            maxTagCount="responsive"
                            value={filters.product_texture}
                            loading={dropdownLoading}
                            onMouseLeave={() =>
                              getRemainingFilters("product_texture")
                            }
                            onChange={(val) => {
                              changeFilter("product_texture", val);
                            }}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {textures.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <Tooltip title="Top 7 KPIs coming soon">
                            <label className="label-block">Retailer</label>
                            <Select
                              placeholder="All"
                              placement="bottomLeft"
                              dropdownMatchSelectWidth={false}
                              style={{ width: 100 }}
                              maxTagCount="responsive"
                              value={filters.customer_retailer_details}
                              onChange={(val) => {
                                dispatch(
                                  setFilters({
                                    ...filters,
                                    deep_dive_list: [],
                                    customer_retailer_details: [val],
                                  })
                                );
                              }}
                            >
                              {retailers?.map((retailer) => (
                                <Option value={retailer}>{retailer}</Option>
                              ))}
                            </Select>
                          </Tooltip>
                        </Col>
                        <Col>
                          <label className="label-block">Time Aggregate</label>
                          <Select
                            placeholder="All"
                            placement="bottomLeft"
                            style={{ width: 105 }}
                            maxTagCount="responsive"
                            dropdownMatchSelectWidth={false}
                            value={filters.time_aggregate}
                            loading={dropdownLoading}
                            onMouseLeave={() =>
                              getRemainingFilters("time_aggregate")
                            }
                            onChange={(val) => {
                              changeFilter("time_aggregate", [val]);
                            }}
                          >
                            {timeAggregates.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Year Period</label>
                          <Select
                            placeholder="All"
                            placement="bottomLeft"
                            dropdownMatchSelectWidth={false}
                            style={{ width: 105 }}
                            maxTagCount="responsive"
                            value={filters.year_period}
                            loading={dropdownLoading}
                            onMouseLeave={() =>
                              getRemainingFilters("year_period")
                            }
                            onChange={(val) => {
                              changeFilter("year_period", [val]);
                            }}
                          >
                            {yearPeriods.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">
                            Benchmark Period
                          </label>
                          <Select
                            placeholder="All"
                            style={{ width: 130 }}
                            maxTagCount="responsive"
                            dropdownMatchSelectWidth={false}
                            value={filters.benchmark}
                            loading={dropdownLoading}
                            onChange={(val) => {
                              changeFilter("benchmark", [val]);
                            }}
                          >
                            {benchmarkPeriods.map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select>
                        </Col>
                      </div>
                      <Col>
                        <Button
                          type="primary"
                          style={{ marginTop: "27%" }}
                          onClick={applyFilters}
                          disabled={dropdownLoading}
                        >
                          Apply
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col span={6}>
                  <Row gutter={20} justify="end" style={{ marginTop: "3%" }}>
                    {!newReport && (
                      <>
                        <Col style={{ padding: 0 }}>
                          <Dropdown
                            overlay={() => (
                              <Menu>
                                {/* <Menu.Item onClick={() => {appInsights.trackEvent({name:'PPT_FOR_PERFORMANCE'}); pptExportPerformance(exportData, "Performance Report", columns.psColsRsv, columns.psColsShare, columns.psColsTdp, columns.psCols$Tdp, table_data, defaultBrand, brandToggle, columns.psColsFinancialRsv, columns.psColsFinancialGsv, columns.psColsFinancialNSV, columns.psColsFinancialMAC); }}>Download as PPT</Menu.Item>
                              <Menu.Item onClick={() => {appInsights.trackEvent({name:'EXCEL_FOR_PERFORMANCE'}); exportPerformanceSummary(isReport, defaultBrand, components, exportData, columns.psColsExport, columns.psColsFinancialsExport) }}>Download as Excel</Menu.Item> */}
                              </Menu>
                            )}
                            className="d-more"
                          >
                            <Button
                              icon={<DownloadOutlined />}
                              onClick={() => {
                                setExpand(true);
                              }}
                            >
                              Export Report
                            </Button>
                          </Dropdown>
                        </Col>
                        {/* {!isReport && (
                          <Col>
                            <Dropdown
                              placement="bottomRight"
                              overlay={() => (
                                <Menu>
                                  <Menu.Item
                                    onClick={(e) => {
                                      appInsights.trackEvent({
                                        name: "CREATE_SELF_SERVE_REPORT_PERFORMANCE",
                                      });
                                      setNewReport(true);
                                      setCreateReport(true);
                                    }}
                                  >
                                    Create self serve Report
                                  </Menu.Item>
                                  <Menu.Item
                                    onClick={(e) => {
                                      appInsights.trackEvent({
                                        name: "ADD_EXISTING_REPORT_PERFORMANCE",
                                      });
                                      setNewReport(true);
                                      setCreateReport(false);
                                    }}
                                  >
                                    Add to Existing Report
                                  </Menu.Item>
                                </Menu>
                              )}
                            >
                              <Button
                                icon={<PlusCircleOutlined />}
                                type="primary"
                              >
                                Create Report
                              </Button>
                            </Dropdown>
                          </Col>
                        )} */}
                      </>
                    )}
                    {newReport && (
                      <>
                        <Col>
                          <Button
                            type="secondary"
                            onClick={(e) => {
                              setNewReport(false);
                              setNewReportComponents([]);
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            type="primary"
                            onClick={(e) => {
                              if (newReportComponents.length === 0)
                                openNotificationWithIcon(
                                  "error",
                                  "No KPI components selected"
                                );
                              else setOpenSaveReportDialog(true);
                            }}
                          >
                            Save Report
                          </Button>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
            </>
          )}
          {!isReport && (
            <Row className="sticky-filter">
              <Col span={24}>
                <div className="selected-filters">
                  <label>Filters Applied:</label>
                  <div className="filter-item">
                    Pet -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_pet) && appliedFilters.product_pet.length > 0 && !appliedFilters.product_pet.includes("ALL") ? appliedFilters.product_pet.join() : 'CAT,DOG'}
                    </span>
                  </div>
                  <div className="filter-item">
                    Technology -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_technology)
                        ? appliedFilters.product_technology.length === 0 ||
                          appliedFilters.product_technology.length ===
                          techs.length
                          ? "ALL"
                          : appliedFilters.product_technology.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Feeding Philosophy -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_feed_philosophy)
                        ? appliedFilters.product_feed_philosophy.length === 0 ||
                          appliedFilters.product_feed_philosophy.length ===
                          feedingPhilosophies.length
                          ? "ALL"
                          : appliedFilters.product_feed_philosophy.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Texture -{" "}
                    <span>
                      {Array.isArray(appliedFilters.product_texture)
                        ? appliedFilters.product_texture.length === 0 ||
                          appliedFilters.product_texture.length === textures.length
                          ? "ALL"
                          : appliedFilters.product_texture.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Retailer -{" "}
                    <span>
                      {Array.isArray(appliedFilters.customer_retailer_details)
                        ? appliedFilters.customer_retailer_details.length ===
                          0 ||
                          appliedFilters.customer_retailer_details.length ===
                          retailers.length
                          ? "ALL"
                          : appliedFilters.customer_retailer_details.join()
                        : "ALL"}
                    </span>
                  </div>
                  <div className="filter-item">
                    Time Aggregate -{" "}
                    <span>{appliedFilters.time_aggregate}</span>
                  </div>
                  <div className="filter-item">
                    Year Period - <span>{appliedFilters.year_period}</span>
                  </div>
                  <div className="filter-item">
                    Benchmark Period - <span>{appliedFilters.benchmark}</span>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          <div className={`first-section-ps ps-top`} style={{ marginTop: 15 }}>
            <Row
              gutter={[12, 12]}
              justify="space-around"
              style={{ height: "150px" }}
            >
              <Col flex="0 1 33%" style={{ maxWidth: "33%", height: "100%" }}>
                <div
                  className="Selecteddata-card"
                  style={{ marginBottom: 12, height: "100%" }}
                >
                  <div className="Selecteddata-head">
                    <h4>Average Rating</h4>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="beSelected-cards">
                      <h5>{cardData.fact_avg_rating && cardData.fact_avg_rating.abs}</h5>
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "space-around" }}
                    >
                      <div className="diagnosticsdata-value">
                        {props.fieldValue}
                      </div>
                      <div
                        style={{
                          color: cardData.fact_avg_rating && cardData.fact_avg_rating.variance > 0 ? "#00c853" : "#ec3225",
                          fontSize: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {cardData.fact_avg_rating && cardData.fact_avg_rating.variance > 0 ? <CaretUpFilled
                          style={{
                            color: "#00c853",
                            marginRight: 3,
                            fontSize: "20px",
                          }}
                        /> : <CaretDownFilled
                          style={{
                            color: "#ec3225",
                            marginRight: 3,
                            fontSize: "20px",
                          }}
                        />}{" "}
                        {cardData.fact_avg_rating && formatNumber(cardData.fact_avg_rating.variance)}%
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              {/* <Col flex="0 1 33%" style={{ maxWidth: "33%", height: "100%" }}>
                <div
                  className="Selecteddata-card"
                  style={{ marginBottom: 12, height: "100%" }}
                >
                  <div className="Selecteddata-head">
                    <h4># of Reviews</h4>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="beSelected-cards">
                      <h5>{cardData.fact_avg_reviews && cardData.fact_avg_reviews.abs}</h5>
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "space-around" }}
                    >
                      <div className="diagnosticsdata-value">
                        {props.fieldValue}
                      </div>
                      <div
                        style={{
                          color: cardData.fact_avg_reviews && cardData.fact_avg_reviews.variance > 0 ? "#00c853" : "#ec3225",
                          fontSize: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {cardData.fact_avg_reviews && cardData.fact_avg_reviews.variance > 0 ? <CaretUpFilled
                          style={{
                            color: "#00c853",
                            marginRight: 3,
                            fontSize: "20px",
                          }}
                        /> : <CaretDownFilled
                          style={{
                            color: "#ec3225",
                            marginRight: 3,
                            fontSize: "20px",
                          }}
                        />} {" "}
                        {cardData.fact_avg_reviews && formatNumber(cardData.fact_avg_reviews.variance)}%
                      </div>
                    </div>
                  </div>
                </div>
              </Col> */}
              <Col flex="0 1 33%" style={{ maxWidth: "33%", height: "100%" }}>
                <div
                  className="Selecteddata-card"
                  style={{ marginBottom: 12, height: "100%" }}
                >
                  <div className="Selecteddata-head">
                    <h4>Average # of Reviews per Item</h4>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="beSelected-cards">
                      <h5>{cardData.fact_avg_reviews_per_item && cardData.fact_avg_reviews_per_item.abs}</h5>
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "space-around" }}
                    >
                      <div className="diagnosticsdata-value">
                        {props.fieldValue}
                      </div>
                      <div
                        style={{
                          color: cardData.fact_avg_reviews_per_item && cardData.fact_avg_reviews_per_item.variance > 0 ? "#00c853" : "#ec3225",
                          fontSize: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {cardData.fact_avg_reviews_per_item && cardData.fact_avg_reviews_per_item.variance > 0 ? <CaretUpFilled
                          style={{
                            color: "#00c853",
                            marginRight: 3,
                            fontSize: "20px",
                          }}
                        /> : <CaretDownFilled
                          style={{
                            color: "#ec3225",
                            marginRight: 3,
                            fontSize: "20px",
                          }}
                        />}{" "}
                        {/* {cardData.fact_avg_reviews_per_item && cardData.fact_avg_reviews_per_item.variance > 0 ? <>+</> : <>-</>} */}
                        {cardData.fact_avg_reviews_per_item && formatNumber(cardData.fact_avg_reviews_per_item.variance)}%
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col span={24}>
              <div className="nutro-skus">
                <div className="section-title">
                  <h4>Ratings & Reviews Trends</h4>
                </div>
                <div className="nutro-skus-filter">
                  <div className="nutro-skus-filter-category">
                    <div>
                      <span className="view-label">View By</span>
                      {/* <Radio.Group defaultValue="Retailer" size="small"> */}
                      <Radio.Group
                        value={filters.view_by[0]}
                        size="small"
                        onChange={(e) => {
                          const newFilters = {
                            ...updateRetailerDropdown(filters),
                            view_by: [e.target.value],
                            deep_dive_list: [],
                          };
                          dispatch(setFilters(newFilters));
                          dispatch(getComparisonTrendData(newFilters));
                        }}
                      >
                        {Object.keys(viewBy).map((viewType) => (
                          <Radio.Button value={viewType}>
                            {viewBy[viewType]}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </div>
                    <div>
                      {filters.view_by[0] === "C" && (
                        <>
                          <span className="view-label">Competitor</span>
                          <TreeSelect
                            placeholder="Please select"
                            treeCheckable
                            showCheckedStrategy={SHOW_PARENT}
                            style={{ width: 250 }}
                            dropdownStyle={{
                              maxHeight: 400,
                              overflow: "auto",
                            }}
                            treeData={competitors}
                            dropdownMatchSelectWidth={false}
                            maxTagCount="responsive"
                            allowClear
                            autoClearSearchValue={false}
                            value={filters.selected_competitor}
                            onChange={(val) =>
                              dispatch(
                                setFilters({
                                  ...filters,
                                  selected_competitor: val,
                                  deep_dive_list: [],
                                })
                              )
                            }
                            onMouseLeave={() => {
                              const competitorsOnMouseLeave = [
                                ...selectedCompetitor,
                              ];
                              const competitorsOnChange = [
                                ...filters.selected_competitor,
                              ];
                              if (
                                !isEqual(
                                  competitorsOnMouseLeave.sort(),
                                  competitorsOnChange.sort()
                                )
                              ) {
                                dispatch(
                                  setSelectedCompetitor(
                                    filters.selected_competitor
                                  )
                                );
                                dispatch(setFilters(filters));
                                dispatch(getComparisonTrendData(filters));
                              }
                            }}
                          />
                        </>
                      )}
                      <span className="view-label">KPI</span>
                      <Select
                        placeholder="All"
                        dropdownMatchSelectWidth={false}
                        style={{ width: 110 }}
                        placement="bottomLeft"
                        maxTagCount="responsive"
                        value={filters.be_selected_comparison_kpi[0]}
                        onChange={(value) => {
                          const newFilters = {
                            ...filters,
                            be_selected_comparison_kpi: [value],
                            be_selected_deepdive_kpi: [value],
                            deep_dive_list: [],
                          };
                          dispatch(setFilters(newFilters));
                          dispatch(getComparisonTrendData(newFilters));
                        }}
                      >
                        {kpiDropdownBeSelected.map((kpi) => (
                          <Option value={kpi}>{kpi}</Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>

                <div className="sub-card-container">
                  <div className="nutro-skus sub-card-item">
                    <div className="section-title">
                      <h4>{getComparisonTitle()}</h4>
                      <p>Select a retailer bar for details analysis</p>
                    </div>
                    <ColumnBulletChart
                      kpi={filters.be_selected_comparison_kpi[0]}
                      data={comparisonTrendData}
                      xField={viewBy[filters.view_by[0]]}
                      yField={[
                        "Average Rating",
                        filters.be_selected_comparison_kpi[0],
                      ]}
                      viewBy={viewBy[filters.view_by[0]]}
                      value={filters.deep_dive_list}
                      onChange={(value) => {
                        const newFilters = {
                          ...filters,
                          deep_dive_list: value,
                        };
                        dispatch(setFilters(newFilters));
                        dispatch(getDeepdiveTrendData(newFilters));
                        dispatch(getMacroTableData(newFilters));
                        dispatch(getMicroTableData(newFilters));
                      }}
                      stored={stored}
                      setStored={setStored}
                      storageKey="BeSelectedComparisonTrendChart"
                    />
                    {/* <DemoDualAxes /> */}
                  </div>
                  <div className="nutro-skus sub-card-item">
                    <div className="section-title">
                      <h4>{getDeepdiveTitle()}</h4>
                      <p>
                        Select more retailers in the left graph to compare them
                        here
                      </p>
                    </div>
                    {filters.deep_dive_list.length > 1 ? (
                      <MultiLineChart
                        title={getDeepdiveTitle()}
                        kpi={filters.be_selected_comparison_kpi[0]}
                        data={deepdiveMultiTrendData}
                        xField="Periods"
                        yField={filters.be_selected_comparison_kpi[0]}
                        seriesField="Trend"
                      />
                    ) : (
                      <ColumnLineChart
                        title={getDeepdiveTitle()}
                        kpi={filters.be_selected_comparison_kpi[0]}
                        data={deepdiveSingleTrendData}
                        xField="Periods"
                        yField={[
                          "Average Rating",
                          filters.be_selected_comparison_kpi[0],
                        ]}
                      />
                    )}
                  </div>
                </div>

                <div className="next-skus">
                  <CustomTable
                    rowKey={rowKeyMacro}
                    heading="Ratings &#38; Reviews Overview"
                    description="Select a product from this table to see UPC level data"
                    childrenColumnName="table_hierarchy_data"
                    hideFilter={true}
                    data={macroTableData}
                    checkbox={newReport}
                    value={newReportComponents.includes("2")}
                    columns={macroColumns}
                    scroll={true}
                    xScroll="max-content"
                    addDropdown={
                      filters.view_by[0] === "R"
                        ? false
                        : {
                          options: retailerDropdown,
                          value: filters.selected_retailer,
                          onChange: (val) =>
                            dispatch(
                              setFilters({
                                ...filters,
                                selected_retailer: val,
                              })
                            ),
                          onMouseLeave: () => {
                            const selectedRetailerOnMouseLeave = [
                              ...selectedRetailer,
                            ];
                            const selectedRetailerOnChange = [
                              ...filters.selected_retailer,
                            ];

                            if (
                              !isEqual(
                                selectedRetailerOnMouseLeave.sort(),
                                selectedRetailerOnChange.sort()
                              )
                            ) {
                              setSelectedRetailer(
                                filters.selected_retailer
                              );
                              dispatch(getMacroTableData(filters));
                              dispatch(getMicroTableData(filters));
                            }
                          },
                        }
                    }
                    rowSelection={{
                      checkStrictly: true,
                      selectedRowKeys: selectedRowKey,
                      type: "radio",
                      onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedRowKey(selectedRowKeys);
                        setSelectedRow(selectedRows);
                        const newFilters = {
                          ...filters,
                          ...selectedRows[0][rowKeyMacro],
                        };
                        dispatch(getMicroTableData(newFilters));
                      },
                      hideSelectAll: true,
                    }}
                  />
                </div>

                <div className="next-skus">
                  <CustomTable
                    loading={microLoading}
                    hideDropdown={true}
                    rowKey={rowKeyMicro}
                    heading={
                      selectedRow.length > 0
                        ? `${selectedRow[0].table_key} UPC Information`
                        : "UPC Information"
                    }
                    description="UPC level information for sub-brand"
                    hideFilter={true}
                    data={microTableData}
                    columns={microColumns}
                    scroll={true}
                    xScroll="max-content"
                  />
                </div>

                <Row className="diagnostics-chart">
                  <div className="kpiChart">
                    <div>
                      <h4>Opportunity Areas</h4>
                      {/* <p style={{textAlign: 'center'}}>Select the KPI's to see the trend</p> */}
                    </div>
                    <div className="kpi-dropdown">
                      <Row>
                        <Col>
                          <label style={{ marginLeft: 15, marginRight: 10 }}>
                            View by
                          </label>
                          <Select
                            defaultValue={isAllBrands ? viewByOpportunity[0] : viewByOpportunitySub[0]}
                            value={filters.opportunity_view_by}
                            onChange={(value) => {
                              const newFilters = {
                                ...filters,
                                opportunity_view_by: [value],
                                opportunity_selection: [],
                              };
                              // setSelectedStrategyBrands([])
                              // dispatch(getAllSubFilters(newFilters));
                              dispatch(setFilters(newFilters));
                              // dispatch(getScatterPlotDropdown(newFilters));
                              dispatch(setOpportunitySelection([]));
                              dispatch(getScatterPlot(newFilters));
                            }}
                            options={
                              isAllBrands ?
                                viewByOpportunity.map((item) => {
                                  return ({
                                    value: item,
                                    label: item,
                                  })
                                }) :
                                viewByOpportunitySub.map((item) => {
                                  return ({
                                    value: item,
                                    label: item,
                                  })
                                })}
                          />
                        </Col>
                        {filters.opportunity_view_by[0] === "Brand" ? (
                          <Col>
                            <label style={{ marginLeft: 15, marginRight: 10 }}>
                              Brand
                            </label>
                            <Select
                              style={{
                                width: 120,
                              }}
                              // defaultValue={["All"]}
                              mode="multiple"
                              maxTagCount="responsive"
                              placeholder="Select Brand"
                              value={filters.opportunity_selection}
                              onChange={(value) => {
                                const newFilters = {
                                  ...filters,
                                  opportunity_selection: value,
                                };
                                // setSelectedStrategyBrands([])
                                // dispatch(getAllSubFilters(newFilters));
                                dispatch(setFilters(newFilters));
                                // dispatch(getScatterPlotDropdown(newFilters));
                              }}
                              onMouseLeave={() => {
                                if (!isEqual([...opportunitySelection].sort(), [...filters.opportunity_selection].sort())) {
                                  dispatch(setOpportunitySelection(filters.opportunity_selection));
                                  dispatch(getScatterPlot(filters));
                                }
                              }}
                            >
                              {/* <Option value={"All"}>{"All"}</Option> */}
                              {scaterPlotDropdown?.brands?.length && scaterPlotDropdown.brands.map((val) => {
                                console.log("val", val)
                                return (
                                  <Option value={val}>{val}</Option>
                                )
                              })}
                            </Select>
                          </Col>)
                          : filters.opportunity_view_by[0] === "Sub Brand" ? (
                            scaterPlotDropdown?.sub_brands && scaterPlotDropdown?.sub_brands?.length &&
                            <Col>
                              <label style={{ marginLeft: 15, marginRight: 10 }}>
                                Sub Brand
                              </label>
                              <Select
                                style={{
                                  width: 120,
                                }}
                                // defaultValue={["All"]}
                                mode="multiple"
                                maxTagCount="responsive"
                                placeholder="Select Sub Brand"
                                value={filters.opportunity_selection}
                                onChange={(value) => {
                                  console.log("filters", filters)
                                  const newFilters = {
                                    ...filters,
                                    opportunity_selection: value,
                                  };
                                  dispatch(setFilters(newFilters));
                                }}
                                onMouseLeave={() => {
                                  if (!isEqual([...opportunitySelection].sort(), [...filters.opportunity_selection].sort())) {
                                    dispatch(setOpportunitySelection(filters.opportunity_selection));
                                    dispatch(getScatterPlot(filters));
                                  }
                                }}

                              >
                                {/* <Option value={"All"}>{"All"}</Option> */}
                                {scaterPlotDropdown?.sub_brands?.map((val) => (
                                  <Option value={val}>{val}</Option>
                                ))}
                              </Select>
                            </Col>

                          ) : ""}
                        <Col>
                          <label style={{ marginLeft: 15, marginRight: 10 }}>
                            Show Labels
                          </label>
                          <Switch onChange={handleLabel} checked={showLabel} />
                        </Col>
                      </Row>
                    </div>
                    <Row style={{ width: "100%" }}>
                      <Col className="scatterChart" style={{ width: "100%", marginTop: 20 }}>
                        <BubbleChart
                          data={scaterPlot}
                          yField="avg_no_of_reviews"
                          xField="avg_rating"
                          yFieldName="# of Reviews"
                          xFieldName="Average rating"
                          showLabel={showLabel}
                          // setChartClick={setChartClick}
                          // chartClick={chartClick}
                          // value={filters.keyword}
                          onChange={(value) => ({
                            //   // const newFilters = {
                            //   //   ...filters,
                            //   //   keyword: value,
                            //   // };
                            //   // dispatch(setFilters(newFilters));
                            //   // dispatch(getTrendData(newFilters));
                            //   // if (!value.data.length) {
                            //   //   dispatch(setStrategyData(strategyDataDuplicate));
                            //   // }
                            //   // if (value.data.length) {
                            //   //   dispatch(setStrategyData(value.data));
                            //   // }
                          })}
                          storageKey="BubbleChart"
                          bubblechartLabel={bubblechartLabel}
                          // isBubbleClickable={false}
                          showLegend={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
          {(!isReport || components.includes("7")) && (
            <Loader
              loading={consumerLoading}
            >
              <div
                className={`innovation-card ${newReport ? "trackboard-edit" : ""} ${newReport && newReportComponents.includes("7")
                  ? "trackboard-selected"
                  : ""
                  }`}
                style={{ marginBottom: 60, marginTop: 15 }}
              >
                {newReport && (
                  <Checkbox
                    className="d-check-consumer"
                    style={{ marginBottom: 10 }}
                  ></Checkbox>
                )}
                <h5 className="innovation-card-title">Consumer Reviews</h5>
                <div className="chart-filter">
                  <span className="chart-filter-title">Filter Retailers</span>
                  <Select
                    // mode="multiple"
                    placeholder="Please Select"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 160 }}
                    placement="bottomLeft"
                    maxTagCount="responsive"
                    value={filters.review_retailer[0]}
                    onChange={(val) => { dispatch(setFilters({ ...filters, review_retailer: [val] })); dispatch(getReviewData({ ...filters, review_retailer: [val] })); }}
                  >
                    {reivewRetailersRnR.map((val) => (
                      <Option value={val}>{val}</Option>
                    ))}
                  </Select>
                </div>

                <div className="reviews-wrap">
                  <div className="rating-wrap" style={{ flex: '0 0 50%', border: 'none' }}>
                    <div className="rating" style={{ padding: 16, flex: '0 0 100%' }}>
                      <h4 className="rating-title">Average Ratings and Sentiments</h4>
                      {/* <div className="filter-inner">
                    Filter Retailer
                    <Select
                      style={{ marginLeft: 5, width: 100 }}
                      dropdownMatchSelectWidth={false}
                      placement="bottomLeft"
                      defaultValue="topSales"
                      maxTagCount="responsive"
                    >
                      <Option value="all">All Retailer</Option>
                      <Option value="topSales">Top 5 By Sales</Option>
                      <Option value="bottomSales">Bottom 5 By Sales</Option>
                      <Option value="topRate">Top 5 By Rating</Option>
                      <Option value="bottomRate">Bottom 5 By Rating</Option>
                    </Select>
                  </div> */}
                      <div className="rating-stats" style={{ marginTop: 10, padding: 10 }}>
                      </div>
                      <div className="rating-main-box">
                        <div className="rating-chart-wrap">
                          <div className="ratings-data">
                            <div>
                              <label>Overall ratings:</label>
                              <span></span>
                              <span>
                                <Rate allowHalf disabled value={reviewData?.ratings?.ratings_summary?.overall_ratings} />
                              </span>
                            </div>
                            <div>
                              <label>Total Reviews:</label>
                              <span><b>{reviewData?.ratings?.ratings_summary?.total_reviews}</b></span>
                            </div>
                          </div>
                          <div className="consumer-legends">
                            <div className="consumer-legend"><span className="positive"><img src={`assets/images/icon-review-positive.svg`} alt="smiley" width="16" /></span> <p>Positive Sentiment</p></div>
                            <div className="consumer-legend"><span className="neutral"><img src={`assets/images/icon-review-neutral.svg`} alt="smiley" width="16" /></span> <p>Neutral Sentiment</p></div>
                            <div className="consumer-legend"><span className="negative"><img src={`assets/images/icon-review-negative.svg`} alt="smiley" width="16" /></span> <p>Negative Sentiment</p></div>
                          </div>
                          <Bar {...getConfigBarConsumerReview(reviewData?.ratings?.ratings_grid)} />
                        </div>
                        <div className="themes-box">
                          <TopThemes />
                        </div>
                      </div>
                    </div>
                    {/* <div className="analysis-list">
                  <h4 className="review-section-title" style={{ color: 'black' }}>Sentiment Analysis</h4>
                  <div className="beSelected-box">
                    <img
                      src="/assets/images/icon-review-positive.svg"
                      alt="smiley"
                    />
                    <h5>
                      Amazon - <span>Overall Positive</span>
                    </h5>
                  </div>
                  <div className="beSelected-box">
                    <img
                      src="/assets/images/icon-review-positive.svg"
                      alt="smiley"
                    />
                    <h5>
                      Chewy - <span>Overall Positive</span>
                    </h5>
                  </div>
                  <div className="beSelected-box">
                    <img
                      src="/assets/images/icon-review-positive.svg"
                      alt="smiley"
                    />
                    <h5>
                      Petco Omni - <span>Overall Positive</span>
                    </h5>
                  </div>
                  <div className="beSelected-box">
                    <img
                      src="/assets/images/icon-review-positive.svg"
                      alt="smiley"
                    />
                    <h5>
                      Petsmart Omni - <span>Overall Positive</span>
                    </h5>
                  </div>
                </div> */}
                  </div>
                  <div className="traffic" style={{ flex: '0 0 47%', border: 'none' }}>
                    <div className="review-section">
                      <h4
                        className="review-section-title"
                        style={{ display: "inline-block", textAlign: 'center', width: '100%', color: 'black' }}
                      >
                        Reviews
                      </h4>
                    </div>
                    <div className="filter-box" style={{ justifyContent: 'start' }}>
                      <div className="filter-inner">
                        Filter By
                        <Select
                          style={{ marginLeft: 5, width: 100 }}
                          dropdownMatchSelectWidth={false}
                          placement="bottomLeft"
                          maxTagCount="responsive"
                          value={filters.filter_by[0]}
                          onChange={(val) => {
                            const newFilters = { ...filters, filter_by: [val] };
                            if (reviewData.filter_by && Object.keys(reviewData.filter_by[val]).length > 0)
                              newFilters.sub_filter_by = [Object.keys(reviewData.filter_by[val])[0]];
                            else newFilters.sub_filter_by = [];
                            dispatch(setFilters((newFilters)));
                          }}
                        >
                          {reviewData.filter_by && Object.keys(reviewData.filter_by).map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                          Reviews
                        </Select>
                      </div>
                      <div className="filter-inner">
                        {filters.filter_by.length > 0 ? filters.filter_by[0] : "Sub Filter By"}
                        <Select
                          style={{ marginLeft: 5, width: 120 }}
                          dropdownMatchSelectWidth={false}
                          placement="bottomLeft"
                          maxTagCount="responsive"
                          value={filters.sub_filter_by[0]}
                          onChange={(val) => {
                            dispatch(setFilters(({ ...filters, sub_filter_by: [val] })));
                          }}
                        >
                          {reviewData.filter_by && reviewData.filter_by[filters.filter_by[0]] && Object.keys(reviewData.filter_by[filters.filter_by[0]]).map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </div>
                      <div style={{ fontSize: 23, marginTop: '15px' }}>
                        {/* <SortAscendingOutlined /> */}
                        {sortOrder ? <SortAscendingOutlined onClick={() => { setSortLoading(true); setTimeout(() => { setSortOrder(!sortOrder); setSortLoading(false); }) }} /> : <SortDescendingOutlined onClick={() => { setSortLoading(true); setTimeout(() => { setSortOrder(!sortOrder); setSortLoading(false); }) }} />}
                      </div>
                      <div className="export-btn">
                        <Button type="link" icon={<DownloadOutlined />}>
                          Export
                        </Button>
                      </div>
                    </div>
                    <div className="review-list">
                      {reviewData.filter_by && reviewData.filter_by[filters.filter_by[0]] && orderBy(reviewData.filter_by[filters.filter_by[0]][filters.sub_filter_by[0]], ["review_date"], sortOrder ? ["desc"] : ["asc"])?.map((val, i) => (
                        <ReviewBox
                          rating={val.sentence_rating}
                          review={val.sentence}
                          owner={val.reviewed_by_user}
                          reviewDate={`Reviewed on ${moment(val.review_date).format(
                            "D MMM YYYY"
                          )}`}
                          image={"/assets/images/mars-logo.svg"}
                          retailer={val.retailer}
                        />
                      ))}
                      {/* {[1, 2, 3, 4, 5].map((val, i) =>
                  <ReviewBox rating={2.5} review={"Review goes here " + val} owner={"Mark Bridge"} reviewDate={`Reviewed on ${moment().format("D MMM YYYY")}`} image={"/assets/images/mars-logo.svg"} />
                )} */}
                    </div>
                  </div>
                </div>
              </div>
            </Loader>
          )}
          {/* <Row className="compitition-table">
          <CompetitionAndBenchmark
            description="Select a row to compare KPI's in the graph below"
            // subBrandData={subBradnData}
            categoryData={categoryData}
            competitionData={compititionData}
            kpiData={kpiData}
            columns={columns.beEcomCompitition}
            tableData={ecomCompititionData}
            rowKey={rowKeyCompetitionBenchmark}
            xScroll={false}
            childrenColumnName="brand_data"
            rowSelection={{
              type: 'radio',
              hideSelectAll: true,
              renderCell: (val, rec, index, node) => !rec.brand_data ? node : null
            }}
            defaultExpandedRowKeys={['Internal']}
          />
        </Row> */}
          {/* </Loader > */}
        </Loader>
      </Content>
    </Wrapper>
  );
}

export default withRouter(EcomBeSelected);
