import React from "react";
import { Col, Row, Dropdown, Menu, Select, TreeSelect } from "antd";
import CustomTable from "../customTable";
import { MoreOutlined } from '@ant-design/icons';

const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;

export const compareVia = {
  manufacturer: "Manufacturer",
  brand: "Brand",
  subBrand: "Sub Brand",
  retailers: "Retailers",
};


const menu = (exportData) => (
  <Menu>
    <Menu.Item key="0" onClick={exportData}>Export Data</Menu.Item>
  </Menu>
);

/**
 * Competition & Benchmark
 * The reusable component to show the Competition & Benchmark KPIs and the comparison 
 * between brands, sub-brands and retailers etc, on the eCom Diagnostics Screen.
 */
function CompetitionAndBenchmark({
  isAllBrands,
  description,
  columns,
  childrenColumnName,
  rowKey,
  xScroll,
  rowSelection,
  defaultExpandedRowKeys,

  //data
  brandData,
  subBrandData,
  retailerData,
  categoryData,
  competitionData,
  kpiData,
  tableData,
  exportData,

  // values
  compareViaValue,
  deepdiveValue,
  categoryValue,
  competitionValue,
  kpiValue,

  // onChange
  compareViaOnChange,
  deepdiveOnChange,
  categoryOnChange,
  competitionOnChange,
  kpiOnChange,

  // onMouseLeave
  deepdiveOnMouseLeave,
  categoryOnMouseLeave,
  competitionOnMouseLeave,
  kpiOnMouseLeave,
})
  // Filter the possible options for comparison based on whether it is all brands or not
{
  const compareViaList = Object.keys(compareVia).filter((compare) =>
    isAllBrands ? compare !== "subBrand" : compare !== "manufacturer"
  );
  return (
    <div className="nutro-table">
      <div>
        <h4>Competition &#38; Benchmark</h4>
        {description && <p style={{ textAlign: "center" }}>{description}</p>}
        {/* Dropdown menu for exporting data */}
        <Dropdown overlay={() => menu(exportData)} trigger={['click']} placement="bottomRight" className="d-more">
          <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ float: 'right', marginTop: '-20px' }}>
            <MoreOutlined />
          </a>
        </Dropdown>
      </div>
      <div className="table-dropdown">
         {/* Row for selecting comparison options */}
        <Row
          gutter={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Col>
              <label className="label-block">Compare Via</label>
              <Select
                placeholder="Please select"
                style={{ width: 150 }}
                dropdownMatchSelectWidth={false}
                maxTagCount="responsive"
                value={compareViaValue}
                onChange={compareViaOnChange}
              >
                {compareViaList.map((compare) => (
                  <Option value={compareVia[compare]}>
                    {compareVia[compare]}
                  </Option>
                ))}
              </Select>
            </Col>
            {isAllBrands && compareViaValue === compareVia.brand && (
              <Col>
                <label className="label-block">Select Brand</label>
                <Select
                  placeholder="Please select"
                  mode="multiple"
                  style={{ width: 150 }}
                  dropdownMatchSelectWidth={false}
                  maxTagCount="responsive"
                  value={deepdiveValue}
                  onChange={deepdiveOnChange}
                  onMouseLeave={deepdiveOnMouseLeave}
                >
                  {brandData &&
                    brandData.map((val) => <Option value={val}>{val}</Option>)}
                </Select>
              </Col>
            )}
            {compareViaValue === compareVia.subBrand && (
              <Col>
                <label className="label-block">Select Sub Brand</label>
                <Select
                  placeholder="Please select"
                  mode="multiple"
                  style={{ width: 150 }}
                  dropdownMatchSelectWidth={false}
                  maxTagCount="responsive"
                  value={deepdiveValue}
                  onChange={deepdiveOnChange}
                  onMouseLeave={deepdiveOnMouseLeave}
                  allowClear
                >
                  {subBrandData &&
                    subBrandData.map((val) => (
                      <Option value={val}>{val}</Option>
                    ))}
                </Select>
              </Col>
            )}
            {compareViaValue === compareVia.retailers && (
              <Col>
                <label className="label-block">Retailer</label>
                <Select
                  placeholder="Please select"
                  mode="multiple"
                  style={{ width: 150 }}
                  dropdownMatchSelectWidth={false}
                  maxTagCount="responsive"
                  value={deepdiveValue}
                  onChange={deepdiveOnChange}
                  onMouseLeave={deepdiveOnMouseLeave}
                >
                  {retailerData &&
                    retailerData.map((val) => (
                      <Option value={val}>{val}</Option>
                    ))}
                </Select>
              </Col>
            )}
          </div>
         
          {/* Additional dropdowns for selecting category benchmark, competition benchmark, and KPI */}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Col>
              <label className="label-block">Category Benchmark</label>
              <TreeSelect
                style={{ width: 150 }}
                dropdownStyle={{ maxHeight: "400px", overflow: "hidden" }}
                treeData={categoryData}
                treeCheckable
                treeDefaultExpandAll
                showCheckedStrategy={SHOW_PARENT}
                placeholder="Please select"
                dropdownMatchSelectWidth={false}
                maxTagCount="responsive"
                allowClear
                autoClearSearchValue={false}
                value={categoryValue}
                onChange={categoryOnChange}
                onMouseLeave={categoryOnMouseLeave}
                filterTreeNode={(search, item) => {
                  return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                }}
              />
            </Col>
            <Col>
              <label className="label-block">Competition Benchmark</label>
              <TreeSelect
                style={{ width: 150 }}
                dropdownStyle={{
                  maxHeight: "400px",
                  overflow: "hidden",
                  maxWidth: 350,
                }}
                treeData={competitionData}
                treeCheckable
                treeDefaultExpandAll
                showCheckedStrategy={SHOW_PARENT}
                placeholder="Please select"
                dropdownMatchSelectWidth={false}
                maxTagCount="responsive"
                allowClear
                autoClearSearchValue={false}
                value={competitionValue}
                onChange={competitionOnChange}
                onMouseLeave={competitionOnMouseLeave}
                filterTreeNode={(search, item) => {
                  return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                }}
              />
            </Col>
            <Col>
              <label className="label-block">KPI</label>
              <TreeSelect
                style={{ width: 150 }}
                dropdownStyle={{
                  maxHeight: "400px",
                  overflow: "hidden",
                  maxWidth: 350,
                }}
                treeData={kpiData}
                treeCheckable
                treeDefaultExpandAll
                showCheckedStrategy={SHOW_PARENT}
                placeholder="Please select"
                dropdownMatchSelectWidth={false}
                maxTagCount="responsive"
                allowClear
                value={kpiValue}
                onChange={kpiOnChange}
                onMouseLeave={kpiOnMouseLeave}
              />
            </Col>
          </div>
        </Row>
      </div>
       {/* CustomTable component to display the data in a table */}
      <CustomTable
        rowKey={rowKey}
        childrenColumnName={childrenColumnName}
        hideFilter={true}
        data={tableData}
        columns={columns}
        hideDropdown={true}
        rowSelection={rowSelection}
        defaultExpandedRowKeys={defaultExpandedRowKeys}
        scroll={true}
        xScroll="max-content"
      />
    </div>
  );
}

export default CompetitionAndBenchmark;
