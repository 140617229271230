import React, { useState } from 'react';
import { Table, Radio, Dropdown, Menu, Select, Checkbox, Switch } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import './customTable.scss';
import { cloneDeep, isEqual } from 'lodash';

const menu = (exportData) => (
  <Menu>
    <Menu.Item key="0" onClick={exportData}>Export Data</Menu.Item>
  </Menu>
);

const { Option } = Select;

export default function CustomTable(props) {

  const scroll = { y: 352 };
  if (props.scroll) {
    if (props.xScroll) scroll.x = props.xScroll;
    else scroll.x = 1500;
  }

  return <div className={`nutro-skus ${props.checkbox ? 'trackboard-edit' : ''} ${props.checkbox && props.value ? 'trackboard-selected' : ''}`} style={{ marginBottom: 16 }}>
    {!props.hideUpperSection && <div className="section-title">
      {props.checkbox && <Checkbox value={props.value} onChange={props.onChange} className="d-check"></Checkbox>}
      {props.heading && <h4>{props.heading}</h4>}
      {props.description && <p>{props.description}</p>}
      {props.callout && <h4 className="section-title-callout">{props.callout}</h4>}
      {!props.hideDropdown && <Dropdown overlay={() => menu(props.exportData)} trigger={['click']} placement="bottomRight" className="d-more">
        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
          <MoreOutlined />
        </a>
      </Dropdown>}
      {props.addDropdown && <div className="chart-filter">
        <span className="chart-filter-title">Add Retailer</span>
        <Select
          mode="multiple"
          placeholder="Please Select"
          dropdownMatchSelectWidth={false}
          style={{ width: 110 }}
          placement="bottomLeft"
          maxTagCount="responsive"
          value={props.addDropdown.value}
          onChange={props.addDropdown.onChange}
          onMouseLeave={props.addDropdown.onMouseLeave}
        >
          {props.addDropdown.options.map((val) => (
            <Option value={val}>{val}</Option>
          ))}
        </Select>
      </div>}
    </div>}
    {!props.hideFilter && <div className="nutro-skus-filter">
      <div>
        <span className="view-label">View Top</span>
        <Radio.Group defaultValue="a" size="small">
          <Radio.Button value="a">20</Radio.Button>
          <Radio.Button value="b">40</Radio.Button>
          <Radio.Button value="c">60</Radio.Button>
          <Radio.Button value="d">80</Radio.Button>
          <Radio.Button value="e">1000</Radio.Button>
        </Radio.Group>
      </div>
      <div>
        <Radio.Group defaultValue="a" size="small">
          <Radio.Button value="a">Winners</Radio.Button>
          <Radio.Button value="b">Losers</Radio.Button>
        </Radio.Group>
      </div>
      {props.showCore && <div><Radio.Group defaultValue="a" size="small">
        <Radio.Button value="a">Core</Radio.Button>
      </Radio.Group></div>}
      <div>
        <span className="view-label">Sort by</span>
        <Select defaultValue="TDP" size="small" style={{ width: 110 }} >
          <Option value="TDP">TDP</Option>
          <Option value="">Velocity</Option>
          <Option value="">Eq LBs</Option>
          <Option value="">MKT RSV</Option>
        </Select>
      </div>
    </div>}
    {props.setBrandToggle && <div className="table-switch" style={{ marginBottom: 16 }}>
      <div className="toggle-switch">
        <span className={!props.brandToggle ? 'active' : ''}>Market</span>
        <Switch size="small" checked={props.brandToggle} onChange={(e) => props.setBrandToggle(e)} />
        <span className={props.brandToggle ? 'active' : ''}>Financials</span>
      </div>
    </div>}
    {props.setIsRevenueData && <div className="toggle-switch" style={{ marginBottom: 16 }}>
      <span className={!props.isRevenueData ? 'active' : ''}>Revenue</span>
      <Switch size="small" checked={props.isRevenueData} onChange={(e) => props.setIsRevenueData(e)} />
      <span className={props.isRevenueData ? 'active' : ''}>Distribution</span>
    </div>}
    {props.IscompareVia && props.compareDropdown && <div className="table-header"><span className="view-label">Add Retailer</span>
      {/* <Select defaultValue="Select reatiler" style={{ width: 150 }} options={[{ value: "Amazon", label: "Amazon" }, { value: "Chewy", label: "Chewy" }, { value: "Walmart", label: "Walmart" }]}/> */}
      <Select
          mode="multiple"
          placeholder="Please Select"
          dropdownMatchSelectWidth={false}
          style={{ width: 110 }}
          placement="bottomLeft"
          maxTagCount="responsive"
          autoClearSearchValue={false}
          value={props.compareDropdown.value}
          onChange={props.compareDropdown.onChange}
          onMouseLeave={props.compareDropdown.onMouseLeave}
        >
          {props.compareDropdown.options.map((val) => (
            <Option value={val}>{val}</Option>
          ))}
        </Select>
    </div>}
    <div className="nutro-table">
    {/* <Table id="nutro-table-ps" scroll={{ y: 240 }} rowKey={props.rowKey} expandable={{ defaultExpandedRowKeys: props.defaultExpandedRowKeys,childrenColumnName: props.childrenColumnName, defaultExpandAllRows: props.expand, expandedRowKeys: props.expandAll && props.data.map(val => val[props.rowKey]) }} pagination={false} scroll={props.scroll && { x: props.xScroll ? props.xScroll : 1500 }} rowSelection={props.rowSelection} columns={props.columns} dataSource={props.data} /> */}
      {props.xScroll 
        ? <Table id="nutro-table-ps" scroll={scroll} rowKey={props.rowKey} expandable={{ onExpand: props.onExpand, defaultExpandedRowKeys: props.defaultExpandedRowKeys,childrenColumnName: props.childrenColumnName, defaultExpandAllRows: props.expand, expandedRowKeys: props.expandAll && props.data.map(val => val[props.rowKey]) }} pagination={props.pagination ? props.pagination : false} rowSelection={props.rowSelection} columns={props.columns} dataSource={props.data} loading={props.loading} onChange={props.onChange} />
        : <Table id="nutro-table-ps" scroll={{ y: 240 }} rowKey={props.rowKey} expandable={{ defaultExpandedRowKeys: props.defaultExpandedRowKeys,childrenColumnName: props.childrenColumnName, defaultExpandAllRows: props.expand, expandedRowKeys: props.expandAll && props.data.map(val => val[props.rowKey]) }} pagination={ props.pagination ? props.pagination : false } scroll={props.scroll && { x: 1500 }} rowSelection={props.rowSelection} columns={props.columns} dataSource={props.data} onChange={props.onChange} />
      }
      
    </div>
  </div>
}