import { createSlice } from '@reduxjs/toolkit';
import CustomAxios from '../utilities/services/api.service';
import { ENDPOINTS } from '../utilities/constants';

/**
 * Trackboard Slicer
 * It manages the Redux State/Reducer/Actions for Trackboard usecase.
 */

const initialState = {
    compareVia: "Category",
    compareViaEcom: "Retailer",
    kpi: ["dollar", "dollar_share"],
    kpi1: "dollar",
    kpi2: "dollar_share",
    kpiForDeepDive: "dollar",
    ecomKpiForDeepDive: "dollar",
    deepDiveList: [],
    trackboardData: {},
    innovationData: {},
    comparisonChartData: [],
    ecomComparisonChartData: [],
    deepDiveChartData: [],
    ecomDeepDiveChartData: [],
    ecomDeepDiveList: [],
    competitionData: {
        competition_summary: {
            "FDM excluding Omni": {
                dollar_share: 0,
                dollar_share_variance: 0,
            },
            "eCom including Omni": {
                dollar_share: 0,
                dollar_share_variance: 0,
            },
            "Supers excluding Omni": {
                dollar_share: 0,
                dollar_share_variance: 0,
            },
            "Indy excluding Omni": {
                dollar_share: 0,
                dollar_share_variance: 0,
            },
        },
    },
    loading: 0,
}

const trackboardSlicer = createSlice({
    name: 'trackboard',
    initialState,
    reducers: {
        setLoading: (state, action) => ({ ...state, loading: state.loading + (action.payload ? 1 : -1) }),
        setTrackboardData: (state, action) => ({
            ...state,
            trackboardData: action.payload
        }),
        setInnovationData: (state, action) => ({
            ...state,
            innovationData: action.payload
        }),
        setCompetitionData: (state, action) => ({
            ...state,
            competitionData: action.payload
        }),
        setComparisonChartData: (state, action) => ({
            ...state,
            comparisonChartData: action.payload
        }),
        setEcomComparisonChartData: (state, action) => ({
            ...state,
            ecomComparisonChartData: action.payload
        }),
        setDeepDiveChart: (state, action) => ({
            ...state,
            deepDiveChartData: action.payload
        }),
        setEcomDeepDiveChart: (state, action) => ({
            ...state,
            ecomDeepDiveChartData: action.payload
        }),
        setCompareVia: (state, action) => ({
            ...state,
            compareVia: action.payload
        }),
        setCompareViaEcom: (state, action) => ({
            ...state,
            compareViaEcom: action.payload
        }),
        setKpi: (state, action) => ({
            ...state,
            kpi: action.payload
        }),
        setKpi1: (state, action) => ({
            ...state,
            kpi1: action.payload
        }),
        setKpi2: (state, action) => ({
            ...state,
            kpi2: action.payload
        }),
        setKpiForDeepDive: (state, action) => ({
            ...state,
            kpiForDeepDive: action.payload
        }),
        setEcomKpiForDeepDive: (state, action) => ({
            ...state,
            ecomKpiForDeepDive: action.payload
        }),
        setDeepDiveList: (state, action) => ({
            ...state,
            deepDiveList: action.payload
        }),
        setEcomDeepDiveList: (state, action) => ({
            ...state,
            ecomDeepDiveList: action.payload
        }),
    },
})

export const { setLoading, setTrackboardData, setInnovationData, setCompetitionData, setComparisonChartData, setEcomComparisonChartData, setDeepDiveChart, setDeepDiveList, setCompareVia, setKpi, setKpiForDeepDive, setEcomKpiForDeepDive, setCompareViaEcom, setKpi1, setKpi2, setEcomDeepDiveList, setEcomDeepDiveChart } = trackboardSlicer.actions

export const getData = (ytdToggle) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    let brand = getState().profile.defaultBrand

    let url = { product_brand: brand.split(":").length === 2 ? [brand.split(":")[0]] : [brand], product_secondary_brand: brand.split(":").length === 2 ? [brand.split(":")[1]] : [], period_toggle: !ytdToggle ? 1 : 0 }

    let encodedURL = encodeURIComponent(JSON.stringify(url))
    CustomAxios(ENDPOINTS.trackboard + '?' + encodedURL, 'GET').then((response2) => {
        dispatch(setTrackboardData(response2.data))
        CustomAxios(ENDPOINTS.innovation + '?' + encodedURL, 'GET').then((response2) => {

            dispatch(setInnovationData(response2.data))
            // dispatch(setLoading(false))
        }).catch((err) => {
            // dispatch(setLoading(false))
        })
        dispatch(setLoading(false))
    }).catch((err) => {
        dispatch(setLoading(false))
    })

    // Fetch competiton summary data
    dispatch(setLoading(true))
    CustomAxios(ENDPOINTS.competition + '?' + encodedURL, 'GET').then((response2) => {
        dispatch(setCompetitionData(response2.data))

        dispatch(setLoading(false))
    }).catch((err) => {
        dispatch(setLoading(false))
    })
}

export const getKPIComparisonChartData = (ytdToggle) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    let brand = getState().profile.defaultBrand
    let trackboard = getState().trackboard

    let url = { product_brand: brand.split(":").length === 2 ? [brand.split(":")[0]] : [brand], product_secondary_brand: brand.split(":").length === 2 ? [brand.split(":")[1]] : [], period_toggle: !ytdToggle ? 1 : 0, Compare_via: trackboard.compareVia }

    let encodedURL = encodeURIComponent(JSON.stringify(url))
    CustomAxios(ENDPOINTS.trackboardTotal + '?' + encodedURL, 'GET').then((response2) => {
        dispatch(setComparisonChartData(response2.data.total_performance))
        dispatch(setDeepDiveList([response2.data.total_performance[0][trackboard.compareVia === "Category" ? "product_category" : "customer_channel"]]))
        dispatch(setLoading(false))
    }).catch((err) => {
        dispatch(setLoading(false))
    })
    dispatch(setLoading(true))
    CustomAxios(ENDPOINTS.categoryTotal + '?' + encodedURL, 'GET').then((response2) => {
        dispatch(setDeepDiveChart(response2.data.perf_summary_deepdive.bar_dict.sort((a, b) => a.time_year_period > b.time_year_period ? 1 : -1)))

        dispatch(setLoading(false))
    }).catch((err) => {
        dispatch(setLoading(false))
    })

}
export const getEcomKPIComparisonChartData = (ytdToggle) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    let brand = getState().profile.defaultBrand
    let trackboard = getState().trackboard

    let url = { product_brand: brand.split(":").length === 2 ? [brand.split(":")[0]] : [brand], product_secondary_brand: brand.split(":").length === 2 ? [brand.split(":")[1]] : [], period_toggle: !ytdToggle ? 1 : 0, Compare_via: trackboard.compareViaEcom }

    let encodedURL = encodeURIComponent(JSON.stringify(url))
    CustomAxios(ENDPOINTS.trackboardEcomTotal + '?' + encodedURL, 'GET').then((response2) => {
        dispatch(setEcomComparisonChartData(response2.data.total_performance))
        dispatch(setEcomDeepDiveList([response2.data.total_performance[0][trackboard.compareViaEcom === "Category" ? "product_category" : "customer_retailer_details"]]))
        dispatch(setLoading(false))
    }).catch((err) => {
        dispatch(setLoading(false))
    })
    dispatch(setLoading(true))
    CustomAxios(ENDPOINTS.deepDiveEcom + '?' + encodedURL, 'GET').then((response2) => {
        dispatch(setEcomDeepDiveChart(response2.data.perf_summary_deepdive.bar_dict.sort((a, b) => a.time_year_period > b.time_year_period ? 1 : -1)))

        dispatch(setLoading(false))
    }).catch((err) => {
        dispatch(setLoading(false))
    })

}


export default trackboardSlicer.reducer