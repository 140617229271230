import React, {useEffect} from "react";
import { Scatter } from "@ant-design/plots";
import { formatNumber, symbols } from "../../utilities/helper";
import './bubble.scss';
import { useDispatch } from "react-redux";
import { setExportData } from "../../slicers/exportData.slicer";

function BubbleChart({ data, xField, yField, xFieldName, yFieldName, showLabel, setChartClick, chartClick, value, onChange, storageKey, bubblechartLabel, isBubbleClickable, setAllTableData }) {
  const dispatch = useDispatch();
  const getValueSymbol = (itemName) => {
    if (itemName === "traffic_share") return symbols.percent;
    if (itemName === "traffic_share_change") return symbols.pts;
    return "";
  }
  const config = {
      data,
      width: 800,
      height: 400,
      xField ,
      yField,
      isGroup: 'true',
      sizeField: bubblechartLabel.sizeField,
      colorField: bubblechartLabel && bubblechartLabel.keywordColor ? bubblechartLabel.keywordColor : "",
      // color:  brandData && brandData.manufacturer_color ? brandData.manufacturer_color : "#0000a0",
      color: (item) => {
        const brandData = data.find((val)=>val.keyword === item.keyword)
        return brandData && brandData.manufacturer_color ? brandData.manufacturer_color : "#0000a0"
      },
      // color:(item) => value?.includes(type[viewBy]) ? "#0000a0" : "#8080cf",
      // size: (item) => {
      //   const count = Math.abs(item[bubblechartLabel.sizeField]) * 5
      //   return count;
      // },
     size: [10,20],
      shape: "circle",
      yAxis: {
        title: {
          text: yFieldName,
          style: {
            fontWeight: "700",
          },
        },
        
        line: {
          style: {
            stroke: "#eee"
          }
        },
        label: {
          formatter: (v) => (`${formatNumber(v)}`),
        },
      },
      xAxis: {
        title: {
          text: xFieldName,
          style: {
            fontWeight: "700",
          },
        },
        // maxLimit: slider,
        // minLimit: -(slider),
        grid: {
          line: {
            style: {
              stroke: "#eee"
            }
          }
        },
        line: {
          style: {
            stroke: "#eee"
          }
        }
      },
      tooltip: {
        showMarkers: false,
        // fields: ['Traffic', 'Share of Shelf', 'Share of Shelf % Change'],
        customContent: (title,data) => {
          const keywordName = data.map((item)=>item.data.keyword)
          let htmlStr = `<div style="margin-bottom:4px;border-bottom:1px solid white;line-height:22px">${keywordName[0]}</div>`;
          data && data.forEach((item) => {
            if(item.name !== "keyword"){
            htmlStr += `<div style=""><div class="g2-tooltip-item" style="margin-bottom:8px;display:flex;justify-content:space-between;">
            <span class="g2-tooltip-item-label" style="margin-right: 12px;">${ item.name }:</span>
            <!-- <span class="g2-tooltip-item-value">${(item.name !== "traffic") && (item.name !== "share_of_shelf_change") ? `${formatNumber(item.value)} %` : formatNumber(item.value)}</span> -->
            <span class="g2-tooltip-item-value">${formatNumber(item.value)}${getValueSymbol(item.name)}</span>
          </div>
          </div>`;
            }
          });
          htmlStr += '</div>';
          return htmlStr;
      },
    },

    // tooltip: {
    //   showLabel: true
    // },

    label: {
      formatter: (item) => {
        // if(item[bubblechartLabel.label] === item.share_of_shelf_change){
        //   return !showLabel  ? `${formatNumber(item[bubblechartLabel.label])}` : `${formatNumber(item[bubblechartLabel.label])} \n ${item.keyword}`;
        // }else{
          return showLabel  ? `${item.keyword}` : ``;
        // }
            
      },
      offsetY: 30,
      style: {
        fontSize: 12,
        fill: '#000000',
      },
    },
    legend: false,
    quadrant: {
      // xBaseline: 50,
      // yBaseline: 60000,
      // labels: [
      //   {
      //     content: 'High Traffic Low Share of Shelf',
      //     position: [2, 80000],
      //     style: {
      //       fill: 'rgba(0,0,0, 0.85)',
      //       textAlign: 'start',
      //       fontSize: 25,
      //       opacity: 0.3,
      //       height: 15
      //     },
      //   },
      //   {
      //     content: 'High Traffic High Share of Shelf',
      //     position: [61, 80000],
      //     style: {
      //       fill: 'rgba(0,0,0, 0.85)',
      //       textAlign: 'start',
      //       fontSize: 25,
      //       opacity: 0.3,
      //       height: 15
      //     },
      //   },
      //   {
      //     content: 'Low Traffic Low Share of Shelf',
      //     position: [0, 30000],
      //     style: {
      //       fill: 'rgba(0,0,0, 0.85)',
      //       textAlign: 'start',
      //       fontSize: 25,
      //       opacity: 0.3,
      //       height: 15
      //     },
      //   },
      //   {
      //     content: 'Low Traffic High Share of Shelf',
      //     position: [63, 30000],
      //     style: {
      //       fill: 'rgba(0,0,0, 0.85)',
      //       textAlign: 'start',
      //       fontSize: 25,
      //       opacity: 0.3,
      //       height: 15
      //     },
      //   },
      // ],
    },   
    brush: {
      enabled: true,
      mask: {
        style: {
          fill: "rgba(255,0,0,0.5)",
        },
      }
    },
  interactions: [{ type: 'element-active' }, { type: 'brush' }]
}
useEffect(() => {
  if (data.length === 0) {
    dispatch(setExportData({ bubbleChart: {} }));
    return;
  }
  dispatch(
    setExportData({
      bubbleChart: {
        data,
        name: "keyword",
        xField,
        yField,
      },
    })
  );
}, [data]);
  const onEvent = (e, event) => {
    if (
      event.type === "brush-filter:afterfilter"
    ){
    onChange({data:event.view.filteredData,keyword: []})
    }
    if (
      event.type === "brush-reset-button:click"
    ){
    onChange({data:[],keyword: []})
    }
    if (event.type === "element:mousedown"){
    onChange({data:[],keyword:[event?.data?.data?.keyword]});
    }
  };
  const onWithoutClick = (e, event) =>{
    if (
      event.type === "brush-filter:afterfilter"
    ){
    onChange({data:event.view.filteredData})
    }
    if (
      event.type === "brush-reset-button:click"
    ){
    onChange({data:[]})
    }
  }
  return (
    <>
      {data ? (
        <>
        {/* {isBubbleClickable ? 
          <Scatter {...config} onEvent={onEvent} />
          :  */}
          <Scatter {...config} onEvent={onWithoutClick}/>
           {/* }  */}
        </>
      ) : (
        <div className="nodata-style">
          No data available to display
        </div>
      )}
    </>
  );
}
export default BubbleChart;
