import React from 'react';
import { Checkbox, Dropdown, Menu } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { CaretUpFilled, CaretDownFilled } from "@ant-design/icons";
import './card.scss'
import {   
  addSymbol,
  formatNumber,
  hasValue,
  valueTypes,
  symbols, } from '../../utilities/helper';

const menu = (exportData) => (
    <Menu>
      <Menu.Item key="0" onClick={exportData}>Export Data</Menu.Item>
    </Menu>
  );

export default function DiagnosticsCard({ title, value, variance, symbol, exportKPIs }){
    return(
      <div className="diagnosticsdata-card" style={{marginBottom: 12 }}>
      <div className="diagnosticsdata-head">
      <h4>{title}</h4>
      {exportKPIs && (
        <Dropdown
          overlay={() => menu(exportKPIs)}
          trigger={["click"]}
          placement="bottomRight"
          className="d-more"
        >
          <a
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            <MoreOutlined />
          </a>
        </Dropdown>
      )}
      <p>
        {hasValue(value) &&
          (title === "Average Placement" ? value : formatNumber(value, 1, true) === valueTypes.NA
            ? valueTypes.NA
            : addSymbol(formatNumber(value, 1), symbol))}
        {Number(variance) >= 0 ? (
          <span className="positive">
            {hasValue(variance) &&
              (formatNumber(variance, 1, true) === valueTypes.NA ? (
                valueTypes.NA
              ) : (
                <>
                  <CaretUpFilled style={{ color: "#00c853", marginLeft: 5 }} />+
                  {addSymbol(formatNumber(variance, 1), symbols.percent)}
                </>
              ))}
          </span>
        ) : (
          <span className="negative">
            {hasValue(variance) &&
              (formatNumber(variance, 1, true) === valueTypes.NA ? (
                valueTypes.NA
              ) : (
                <>
                  <CaretDownFilled style={{ color: "#ec3225", marginLeft: 5 }} />
                  {addSymbol(formatNumber(variance, 1), symbols.percent)}
                </>
              ))}
          </span>
        )}
  
        {/* <span>
          <CaretUpFilled style={{ color: "#00c853", marginLeft: 5 }} />
          {hasValue(variance) &&
          (formatNumber(variance, 1, true) === valueTypes.NA
            ? valueTypes.NA
            : addSymbol(formatNumber(variance, 1), symbols.percent))}
        </span> */}
      </p>
    </div>
    </div>



  //    <div className="diagnosticsdata-card" style={{marginBottom: 12 }}>
  //   <div className="diagnosticsdata-head">
  //     {/* {<Checkbox className="d-check"></Checkbox>} */}
  //     <h4>{props.fieldTitle}</h4>
  //     {/* {props?.showExport&&<Dropdown overlay={() => menu(props?.exportData)} trigger={['click']} placement="bottomRight" className="d-more">
  //       <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
  //         <MoreOutlined />
  //       </a>
  //     </Dropdown>} */}
  //   </div>
  //   <div style={{display: 'flex', justifyContent: 'space-around'}}>
  //   <div className="diagnosticsdata-value">{props.fieldValue}</div>
  //       <div style={{color:'#1dd21d', fontSize:'20px', display: 'flex', alignItems: 'center'}}><CaretUpFilled style={{ color: "#00c853", marginRight: 3,fontSize:'20px'}} />{props.percentValue}</div>
  //   </div>
  // </div>
  )
}