import React, { useEffect, useState } from "react";
import {
  Col,
  Layout,
  Row,
  Form,
  Button,
  Select,
  Menu,
  Dropdown,
  Radio,
  Checkbox,
  Alert,
  Tooltip,
  InputNumber,
} from "antd";
import { withRouter } from "react-router-dom";
import Wrapper from "../../components/wrapper";
import { DownloadOutlined, InfoCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { MoreOutlined } from "@ant-design/icons";
import CircleChart from "../../components/circle-chart";
import NutroSKUsTable from "./NutroSKUsTable";
import {
  allBrands,
  brandSplit,
  formatNumber,
  formatNumberData,
  getTimeAggregates,
  marsPN,
  openNotificationWithIcon,
  setWindowHistory,
  symbols,
} from "../../utilities/helper";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllFilters,
  getData,
  getFilterData,
  getInitialBottomFilters,
  getScatterData,
  getSubBrandFilter,
  getTableData,
  getTrendData,
  setAppliedFilters,
  setFilters,
} from "../../slicers/distributionSummary.slicer";
import {
  setExportData,
  setPPTExportData,
} from "../../slicers/exportData.slicer";
import {
  exportCustomTable,
  exportDistKPIs,
  exportDistributionSummary,
  exportLine,
  exportTrend,
  formatNumberExcel,
  numFmt,
} from "../../utilities/exports/excel";
import { TreeSelect } from "antd";
import NewReportForm from "../../components/newReportForm";
import CustomLoader from "../../components/custom-loader/custom-loader";
import { pptExportDistribution } from "../../utilities/exports/ppt";
import { isPackSize } from "../volume-shifting";
import DualAxesLine from "../../components/dual-axes-line";
import columns from "./dsColumns";
import { defaultScatterValue, menu, KPICard, dcom, declareAppliedFilters } from "./dsUIHelper";
import { appInsights } from "../../utilities/appInsights";
import TrendChart from "../../components/trend-chart";
import CustomScatterTable from "./CustomScatterTable";
import { setScatterTable } from "../../slicers/scatter.slicer";

const { Content } = Layout;
const { Option } = Select;
/**
 * DistributionSummary
 * It manages displaying of DistributionSummary with different tabs and functionalities.
 */

function DistributionSummary(props) {
  const dispatch = useDispatch();
  const {
    loading,
    filterLoading,
    pets,
    techs,
    subTechs,
    lifestage,
    packSize,
    retailers,
    years,
    periods,
    kpis,
    brand_dropdown,
    subBrands,
    subBrandsLoaded,
    brand_colors,
    card_data,
    table_data,
    trend_data,
    card_data_is_distribution,
    table_data_is_distribution,
    scatter_data_is_distribution,
    bubble_chart,
    preFilters,
    filters,
    appliedFilters
  } = useSelector((state) => state.distSummary);
  const { defaultBrand, competitorBrands } = useSelector((state) => state.profile)
  const [selectedBrand, setSelectedBrand] = useState(defaultBrand);
  const { loading: perfLoading } = useSelector((state) => state.perfSummary)
  const { loading: volumeShiftingLoading } = useSelector((state) => state.volShiftSummary)
  const { loading: innovationLoading } = useSelector((state) => state.innovationTracker)
  const exportData = useSelector((state) => state.exportData);
  const { distributionSummary } = useSelector(
    (state) => state.refreshDate
  );
  const [channelRetailer, setChannelRetailer] = useState("");
  const [channels, setChannels] = useState([]);
  const [allSKUChartData, setAllSKUChartData] = useState([]);
  const [allSKUTableDataExport, setAllSKUTableDataExport] = useState([]);
  const [brandNames, setBrandNames] = useState([]);
  const [tdpVsVelocity, setTdpVsVelocity] = useState(null);
  const [velocityVsCsl, setVelocityVsCsl] = useState(null);
  const [nutroSKUData, setNutroSKUData] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [tdpVelocityYear, setTdpVelocityYear] = useState("cy");
  const [velocityCslYear, setVelocityCslYear] = useState("cy");
  const [onlyCslYear, setOnlyCslYear] = useState("cy");
  const [tdpVelocityYField, setTdpVelocityYField] = useState(["Velocity", "TDP"]);
  const [velocityCslYField, setVelocityCslYField] = useState(["Velocity", "CSL"]);
  const [onlyCslYField, setOnlyCslYField] = useState("CSL");
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState([]);
  const [filterChanged, setFilterChanged] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [tableLoaded, setTableLoaded] = useState(false);
  const [scatterLoaded, setScatterLoaded] = useState(false);
  const loadedTable = () => setTableLoaded(true);
  const loadedScatter = () => setScatterLoaded(true);
  const [tailCutOff, setTailCutOff] = useState({
    tail_cut_off_rsv: ["1000"],
    tail_cut_off_wd: ["0.1"],
  });

  const isDefaultBrandMarsBDB = defaultBrand.toUpperCase() === allBrands;

  const [newReport, setNewReport] = useState(false);
  const [createReport, setCreateReport] = useState(true);
  const [openSaveReportDialog, setOpenSaveReportDialog] = useState(false);
  const [newReportComponents, setNewReportComponents] = useState([]);
  const [components, setComponents] = useState([]);
  const [reportBrands, setReportBrands] = useState([]);
  const [reportRecordCount, setReportRecordCount] = useState(20);

  const isReport = props.isReport

  function removeDuplicates(arr) {
    return arr.filter((item,
      index) => arr.indexOf(item) === index);
  }

  const changeNewReportComponent = (val, e) => {
    let temp = [...newReportComponents];
    if (e.target.checked) {
      temp.push(val);
      setNewReportComponents(removeDuplicates(temp));
    } else {
      let ind = temp.findIndex((i) => i === val);
      if (ind > -1) {
        temp.splice(ind, 1);
        setNewReportComponents(temp);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(preFilters).length === 0) return;
    if (filters.customer_channel.length === 0) {
      const {
        time_period,
        time_year,
        customer_channel,
        customer_retailer_details,
      } = preFilters;

      const newFilters = {
        ...filters,
        time_period:
          periods.includes(time_period) && filters.time_period.length === 0
            ? [time_period]
            : filters.time_period,
        time_year:
          years.includes(time_year) && filters.time_year.length === 0
            ? [time_year]
            : filters.time_year,
        customer_channel:
          Object.keys(retailers).includes(customer_channel) &&
            filters.customer_channel.length === 0
            ? [customer_channel]
            : filters.customer_channel,
        customer_retailer_details:
          retailers[customer_channel] &&
            filters.customer_retailer_details.length === 0
            ? retailers[customer_channel].includes(customer_retailer_details)
              ? [customer_retailer_details]
              : filters.customer_retailer_details
            : filters.customer_retailer_details,
      };
      dispatch(setFilters(newFilters));
      dispatch(setAppliedFilters(declareAppliedFilters(newFilters, { techs, subTechs, lifestage, packSize: packSize[newFilters.product_technology[0]] })));
      if (newFilters.customer_channel.length > 0)
        if (newFilters.customer_retailer_details.length > 0)
          setChannelRetailer(
            `${newFilters.customer_channel[0]}:${newFilters.customer_retailer_details[0]}`
          );
        else setChannelRetailer(newFilters.customer_channel[0]);
    } else {
      if (filters.customer_channel.length > 0)
        if (filters.customer_retailer_details.length > 0)
          setChannelRetailer(
            `${filters.customer_channel[0]}:${filters.customer_retailer_details[0]}`
          );
        else setChannelRetailer(filters.customer_channel[0]);
    }
  }, [preFilters, periods, years, retailers]);

  const setAllSKUTableData = (data) => dispatch(setScatterTable(data));

  // converting the customer retailer data to tree data
  useEffect(() => {
    const channels = JSON.parse(JSON.stringify(retailers));
    setChannels(
      Object.keys(channels).map((channel) => ({
        title: channel,
        value: channel,
        children: channels[channel].map((retailer) => ({
          title: retailer,
          value: `${channel}:${retailer}`,
        })),
      }))
    );
  }, [retailers]);

  const getTrendHeader = () => filters.search[0] === "SKU"
    ? selectedRow[0] && selectedRow[0].product_sku_desc
    : filters.search[0] === "product_brand"
      ? filters.default_brand[0] === allBrands
        ? marsPN
        : filters.default_brand[0]
      : filters.trend_secondary_brand[0]
        ? filters.trend_secondary_brand[0]
        : filters.default_brand[0];

  const getExportCslTitle = () => appliedFilters.customer_channel[0] !== dcom ? "Velocity vs CSL - Last 4 weeks" : "CSL - Last 4 weeks";

  useEffect(() => {
    if (selectedRow.length === 0) {
      setTdpVsVelocity(null);
      setVelocityVsCsl(null);
      return;
    }

    const { tdp_vs_velocity, velocity_vs_csl } = selectedRow[0];

    // PPT Export
    const tdpVelocity_TdpKey = tdpVelocityYear === "cy" ? "TDP" : "TDP YA";
    const tdpVelocity_VelocityKey =
      tdpVelocityYear === "cy" ? "Velocity" : "Velocity YA";
    const pptTdpVelocityExport = [
      {
        name: tdpVelocity_TdpKey,
        labels: tdp_vs_velocity[tdpVelocityYear]
          .filter(({ key }) => key === tdpVelocity_TdpKey)
          .map(({ period_year }) => period_year),
        values: tdp_vs_velocity[tdpVelocityYear]
          .filter(({ key }) => key === tdpVelocity_TdpKey)
          .map(({ value }) => (value === 0 ? "0" : value)),
      },
      {
        name: tdpVelocity_VelocityKey,
        labels: tdp_vs_velocity[tdpVelocityYear]
          .filter(({ key }) => key === tdpVelocity_VelocityKey)
          .map(({ period_year }) => period_year),
        values: tdp_vs_velocity[tdpVelocityYear]
          .filter(({ key }) => key === tdpVelocity_VelocityKey)
          .map(({ value }) => (value === 0 ? "0" : value)),
      },
    ];

    const velocityCsl_VelocityKey =
      velocityCslYear === "cy" ? "Velocity" : "Velocity YA";
    const velocityCsl_CslKey = velocityCslYear === "cy" ? "CSL" : "CSL YA";
    const pptVelocityCslExport = [
      {
        name: velocityCsl_VelocityKey,
        labels: velocity_vs_csl[velocityCslYear]
          .filter(({ key }) => key === velocityCsl_VelocityKey)
          .map(({ period_year }) => period_year),
        values: velocity_vs_csl[velocityCslYear]
          .filter(({ key }) => key === velocityCsl_VelocityKey)
          .map(({ value }) => (value === 0 ? "0" : value)),
      },
      {
        name: velocityCsl_CslKey,
        labels: velocity_vs_csl[velocityCslYear]
          .filter(({ key }) => key === velocityCsl_CslKey)
          .map(({ period_year }) => period_year),
        values: velocity_vs_csl[velocityCslYear]
          .filter(({ key }) => key === velocityCsl_CslKey)
          .map(({ value }) => (value === 0 ? "0" : value)),
      },
    ];

    dispatch(
      setPPTExportData({
        pptLine: {
          title: getTrendHeader(),
          tdpVelocity: {
            title: "TDP vs Velocity - Last 4 weeks",
            data: pptTdpVelocityExport,
          },
          velocityCsl: {
            title: getExportCslTitle(),
            data: pptVelocityCslExport,
          },
        },
      })
    );

    // Excel Export
    // const sample = {
    //   period_year: "P01 2022",
    //   tdp: 0.0,
    //   velocity: 0.0,
    //   tdp_ya: 0.0,
    //   velocity_ya: 0.0,
    // };

    // TDP vs Velocity
    const tdpVsVelocityExport = [];
    // TDP vs Velocity: TDP/CY
    tdp_vs_velocity.cy.forEach(({ period_year, key, value }) => {
      if (key === "TDP")
        tdpVsVelocityExport.push({
          period_year,
          tdp: value,
          TDP: value
        });
    });

    // TDP vs Velocity: Velocity/CY
    tdp_vs_velocity.cy.forEach(({ period_year, key, value }) => {
      if (key === "Velocity") {
        const index = tdpVsVelocityExport.findIndex(
          (report) => report.period_year === period_year
        );
        if (index !== -1) {
          tdpVsVelocityExport[index].velocity = value;
          tdpVsVelocityExport[index].Velocity = value;
        }
      }
    });

    // TDP vs Velocity: TDP/YA
    tdp_vs_velocity.ya.forEach(({ period_year, key, value }) => {
      if (key === "TDP YA") {
        const index = tdpVsVelocityExport.findIndex(
          (report) => report.period_year === period_year
        );
        if (index !== -1) {
          tdpVsVelocityExport[index].tdp_ya = value;
          tdpVsVelocityExport[index]["TDP YA"] = value;
        }
      }
    });

    // TDP vs Velocity: Velocity/YA
    tdp_vs_velocity.ya.forEach(({ period_year, key, value }) => {
      if (key === "Velocity YA") {
        const index = tdpVsVelocityExport.findIndex(
          (report) => report.period_year === period_year
        );
        if (index !== -1) {
          tdpVsVelocityExport[index].velocity_ya = value;
          tdpVsVelocityExport[index]["Velocity YA"] = value;
        }
      }
    });

    // Velocity vs CSL
    const velocityVsCslExport = [];
    // Velocity vs CSL: Velocity/CY
    velocity_vs_csl.cy.forEach(({ period_year, key, value }) => {
      if (key === "Velocity")
        velocityVsCslExport.push({
          period_year,
          velocity: value,
          Velocity: value,
        });
    });

    // Velocity vs CSL: CSL/CY
    velocity_vs_csl.cy.forEach(({ period_year, key, value }) => {
      if (key === "CSL") {
        const index = velocityVsCslExport.findIndex(
          (report) => report.period_year === period_year
        );
        if (index !== -1) {
          velocityVsCslExport[index].csl = value;
          velocityVsCslExport[index]["CSL"] = value;
        }
      }
    });

    // Velocity vs CSL: Velocity/YA
    velocity_vs_csl.ya.forEach(({ period_year, key, value }) => {
      if (key === "Velocity YA") {
        const index = velocityVsCslExport.findIndex(
          (report) => report.period_year === period_year
        );
        if (index !== -1) {
          velocityVsCslExport[index].velocity_ya = value;
          velocityVsCslExport[index]["Velocity YA"] = value;
        }
      }
    });

    // Velocity vs CSL: CSL/YA
    velocity_vs_csl.ya.forEach(({ period_year, key, value }) => {
      if (key === "CSL YA") {
        const index = velocityVsCslExport.findIndex(
          (report) => report.period_year === period_year
        );
        if (index !== -1) {
          velocityVsCslExport[index].csl_ya = value;
          velocityVsCslExport[index]["CSL YA"] = value;
        }
      }
    });

    setTdpVsVelocity(tdpVsVelocityExport);
    setVelocityVsCsl(velocityVsCslExport);

    dispatch(
      setExportData({
        line: {
          title: getTrendHeader(),
          tdpVelocity: {
            title: "TDP vs Velocity - Last 4 weeks",
            data: tdpVsVelocityExport,
          },
          velocityCsl: {
            title: getExportCslTitle(),
            data: velocityVsCslExport,
          },
        },
      })
    );
  }, [selectedRow, tdpVelocityYear, velocityCslYear]);

  const getFilterTitle = () => {
    if (
      preFilters.customer_retailer_details &&
      preFilters.customer_retailer_details !== "Total Market"
    ) {
      return preFilters.customer_retailer_details;
    }

    return preFilters.customer_channel ? preFilters.customer_channel : "";
  };

  const getScatterHeader = () =>
    `Tail cut-off: MKT RSV > ${preFilters.rsv_limit} $ and Weighted Distribution > ${preFilters.wd_limit} Number of SKUs in tail: ${preFilters.sku_in_tail} Sales value in tail: $${preFilters.tail_sales_value} Tail sales as % of total: ${preFilters.tail_sales_percentage}%`;

  const updateScatter = (selectedBrands) => {
    const scatterData =
      scatterLoaded && selectedBrands.length === 0 ? {} : bubble_chart;

    let brandsDetails = [];
    Object.keys(scatterData).forEach((brand) =>
      brandsDetails.push(
        ...scatterData[brand].map((brandDetail) => ({
          ...brandDetail,
          nutroCore:
            defaultScatterValue.findIndex((value) => value === brand) > -1,
          brandColor: { [brand]: brand_colors[brand] },
          "Weighted Distribution": brandDetail.fact_tdp_current_period,
          Velocity: brandDetail.fact_velocity_current_period,
        }))
      )
    );

    brandsDetails = brandsDetails.map((detail, index) => ({
      ...detail,
      index,
    }));

    setAllSKUChartData(brandsDetails);
    setAllSKUTableData(brandsDetails);
    setAllSKUTableDataExport(brandsDetails);

    const xTitleScatter = "Weighted Distribution";
    const yTitleScatter = "Velocity";
    const pptScatterData = [
      {
        name: xTitleScatter,
        values: brandsDetails.map((brand) => brand[xTitleScatter]),
      },
      {
        name: yTitleScatter,
        values: brandsDetails.map((brand) => brand[yTitleScatter]),
      },
      {
        name: `${yTitleScatter} - NUTRO`,
        values: brandsDetails
          .filter(({ nutroCore }) => nutroCore)
          .map((brand) => brand[yTitleScatter]),
      },
    ];

    dispatch(
      setPPTExportData({
        pptScatter: {
          chart: {
            title: `Distribution vs Velocity - All SKUs - ${getFilterTitle()}`,
            header: getScatterHeader(),
            xTitle: xTitleScatter,
            yTitle: yTitleScatter,
            xBaseline: formatNumber(preFilters.median_x_axis, 1),
            yBaseline: formatNumber(preFilters.median_y_axis, 1),
            data: pptScatterData,
          },
          table: {
            data: brandsDetails.slice(0, 10),
          },
        },
      })
    );
  };

  useEffect(() => updateScatter(brandNames), [bubble_chart, preFilters]);

  const handleSelectBrand = (brands, callApi = true) => {
    if (brands.length === 0) {
      setBrandNames([]);
      return updateScatter([]);
    }

    if (brands.includes("all")) {
      if (brands.length > 1 && brands[0] === "all")
        return openNotificationWithIcon(
          "error",
          "If you want to select any specific brand then please unselect/remove All"
        );
      const all = brand_dropdown;
      setBrandNames(["all"]);
      if (callApi) dispatch(getScatterData(filters, [...all], loadedScatter));
    } else {
      setBrandNames(brands);
      if (callApi) dispatch(getScatterData(filters, brands, loadedScatter));
    }
  };

  useEffect(() => {
    if (initialLoad) {
      if (Object.keys(bubble_chart).length !== 0) {
        handleSelectBrand(isReport ? reportBrands : Object.keys(bubble_chart), false)
        setInitialLoad(false)
      }
      else {
        handleSelectBrand(isReport ? reportBrands : [], false)
      }
    }
  }, [bubble_chart]);

  // nutro SKU table inner filters
  useEffect(() => {
    const dataInStore = JSON.parse(JSON.stringify(table_data));
    const filtered = dataInStore.slice(
      0,
      isReport ? reportRecordCount : pageSize
    );
    const formattedData = formatNumberData(filtered);
    setNutroSKUData(formattedData);
    dispatch(setExportData({ tableData: { data: filtered } }));
    dispatch(setPPTExportData({ pptTableData: { data: formattedData } }));
  }, [pageSize, table_data, filters.search, filters.trend_secondary_brand]);

  const changeFilter = (label, selectedValue) => {
    let newFilters = { ...filters };
    if (
      selectedValue &&
      selectedValue.length &&
      selectedValue.includes("all")
    ) {
      let all = [];
      if (label === "product_pet") all = pets;
      else if (label === 'product_package' && filters.product_technology.length === 1) all = packSize[filters.product_technology[0]];
      else if (label === "product_technology") all = techs;
      else if (label === "product_sub_technology") all = subTechs;
      else if (label === "product_lifestage") all = lifestage;
      else all = retailers;
      if (selectedValue.length === all.length + 1) {
        selectedValue = [];
      } else selectedValue = [...all];
    }
    if (JSON.stringify(filters[label]) !== JSON.stringify(selectedValue)) {
      setFilterChanged(true);
    }
    if (label === "product_technology" && !isPackSize.includes(selectedValue)) newFilters["product_package"] = []
    newFilters[label] = selectedValue;
    dispatch(setFilters(newFilters));
  };

  const getRemainingFilters = (label) => {
    if (!filterChanged) return;

    setFilterChanged(false);
    if (label === "product_pet") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
        })
      );
    } else if (label === "product_technology") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
        })
      );
    }
    else if (label === "product_sub_technology") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
        })
      );
    }
    else if (label === "product_lifestage") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage:filters.product_lifestage,
        })
      );
    }

    else if (label === "product_package") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_package: filters.product_package,
        })
      );
    } else if (label === "customer_channel") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_package: filters.product_package,
          customer_channel: filters.customer_channel,
          customer_retailer_details: filters.customer_retailer_details,
        })
      );
    } else if (label === "time_year") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          product_package: filters.product_package,
          customer_channel: filters.customer_channel,
          customer_retailer_details: filters.customer_retailer_details,
          time_year: filters.time_year,
        })
      );
    }
  };

  const onChannelChange = (val) => {
    const value = val.split(":");
    if (value.length === 1)
      dispatch(setFilters({
        ...filters,
        customer_channel: [value[0]],
        customer_retailer_details: [],
      }));
    else if (value.length === 2)
      dispatch(setFilters({
        ...filters,
        customer_channel: [value[0]],
        customer_retailer_details: [value[1]],
      }));
    setChannelRetailer(val);
    setFilterChanged(true);
  };

  // apply all filters
  const applyFliters = (type = "all") => {
    if (!isReport) {
      let encodedSearch = encodeURIComponent(JSON.stringify({ ...filters, default_brand: [defaultBrand] }));
      if (window.history.pushState) {
        let newurl =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          "?" +
          encodedSearch;
        window.history.pushState({ path: newurl }, "", newurl);
      }

      if (type === "all") {
        dispatch(getData(filters, isReport ? reportBrands : brandNames, loadedTable, loadedScatter));
      } else if (type === "table") dispatch(getTableData(filters, loadedTable));
      else if (type === "trend") dispatch(getTrendData(filters));
      else if (type === "scatter") {
        dispatch(getScatterData(filters, isReport ? reportBrands : brandNames, loadedScatter));
      }
      dispatch(setAppliedFilters(declareAppliedFilters(filters, { techs, subTechs, lifestage, packSize: packSize[filters.product_technology[0]] })));
    }
  };

  useEffect(() => {
    if (isDataFetched && tableLoaded) applyFliters("table");
  }, [filters.kpi, filters.top]);

  useEffect(() => {
    if (isDataFetched && scatterLoaded) applyFliters("scatter");
  }, [filters.time_aggregates, filters.tail_cut_off_rsv, filters.tail_cut_off_wd]);

  useEffect(() => {
    if (isDataFetched) {
      if (filters.search[0] === "product_brand") applyFliters("trend");
      else if (filters.search[0] === "trend_secondary_brand") {
        if (filters.trend_secondary_brand.length > 0 && filters.trend_secondary_brand[0]) applyFliters("trend");
      }
    }
  }, [filters.search, filters.trend_secondary_brand]);

  const changeSelectedBrand = () => setSelectedBrand(defaultBrand);

  const fetchData = (filters, brand, loadedTable, loadedScatter, fetchFilterData = true, initialFilterData = true) => {
    setIsDataFetched(true);
    dispatch(setAppliedFilters(declareAppliedFilters(filters, { techs, subTechs,lifestage,packSize: packSize[filters.product_technology[0]] })));
    dispatch(setFilters(filters));

    const isBrandChanged = defaultBrand !== selectedBrand;

    // API calls
    if (fetchFilterData)
      if (initialFilterData) dispatch(getAllFilters(brand, loadedTable, loadedScatter, isBrandChanged, changeSelectedBrand));
      else dispatch(getFilterData({}, true, brand, loadedTable, loadedScatter, isBrandChanged, changeSelectedBrand));
    else {
      dispatch(getData(filters, brand, loadedTable, loadedScatter));
      setWindowHistory(encodeURIComponent(JSON.stringify(filters)));
    }
  };

  const getSubBrands = () => {
    const brands = JSON.parse(JSON.stringify(competitorBrands));
    if (!brands) return [""];

    const subBrands = [];
    Object.keys(brands).forEach((brand) => {
      subBrands.push(brand);
      if (brands[brand].secondary_brand_data) subBrands.push(...Object.keys(brands[brand].secondary_brand_data));
    })

    return subBrands.filter((brand) => brand !== allBrands);
  };

  const getFilters = (temp) => {
    let preFilters = JSON.parse(
      decodeURIComponent(temp.search.substring(1))
    );
    let currentFilter = { ...filters };
    for (const [k, v] of Object.entries(preFilters)) {
      if (k in currentFilter) {
        currentFilter = {
          ...currentFilter,
          [k]: [...v],
        };
      }
    }

    return { preFilters, currentFilter };
  }

  useEffect(() => {
    if (Object.keys(competitorBrands).length === 0) return;

    if (!defaultBrand) return;

    const initialFilters = {
      ...getInitialBottomFilters(),
      default_brand: [defaultBrand],
    };

    setInitialLoad(true);
    let temp = new URL(window.location.href);
    if (temp.search.length > 1) {
      try {
        let { preFilters, currentFilter } = getFilters(temp);
        currentFilter.top = isReport && preFilters.distribution_sort_order ? [preFilters.distribution_sort_order] : currentFilter.top;
        currentFilter.kpi = isReport && preFilters.distribution_kpi ? preFilters.distribution_kpi : currentFilter.kpi;

        setTailCutOff({ tail_cut_off_rsv: currentFilter.tail_cut_off_rsv, tail_cut_off_wd: currentFilter.tail_cut_off_wd })
        setComponents(preFilters.distribution_components);
        setReportRecordCount(preFilters.distribution_record_count);
        if (!isReport || preFilters.distribution_components.length) {
          fetchData(currentFilter, isReport ? preFilters.reportDistBrands : [brandSplit(defaultBrand)], loadedTable, loadedScatter, true, false);
        }
        setReportBrands(preFilters.reportDistBrands);
      } catch (e) {
        dispatch(setFilters({ ...filters, default_brand: [defaultBrand] }));
        fetchData(initialFilters, [brandSplit(defaultBrand)], loadedTable, loadedScatter);
      }
    } else {
      fetchData(initialFilters, [brandSplit(defaultBrand)], loadedTable, loadedScatter);
    }
  }, [defaultBrand, props.reportApply, competitorBrands]);

  useEffect(() => {
    if (!isDefaultBrandMarsBDB && (!subBrandsLoaded || (!subBrands || subBrands.length === 0 || !subBrands[0]))) return;
    const calculatedSubBrands = isDefaultBrandMarsBDB ? getSubBrands() : subBrands;
    let temp = new URL(window.location.href);
    if (temp.search.length > 1) {
      let { currentFilter } = getFilters(temp);
      const trend_secondary_brand = currentFilter.trend_secondary_brand.length > 0 && currentFilter.trend_secondary_brand[0] ? calculatedSubBrands.includes(currentFilter.trend_secondary_brand[0]) ? currentFilter.trend_secondary_brand : [calculatedSubBrands[0]] : [calculatedSubBrands[0]];
      dispatch(setFilters({ ...currentFilter, trend_secondary_brand }));
    } else dispatch(setFilters({ ...filters, trend_secondary_brand: [calculatedSubBrands[0]] }));
  }, [subBrands]);

  useEffect(() => {
    if (defaultBrand && filters.default_brand.length > 0) dispatch(getSubBrandFilter(filters));
  }, [defaultBrand]);

  useEffect(() => {
    if (filters.search[0] === "SKU") {
      const index = selectedRowKey.length === 0 ? 0 : nutroSKUData.findIndex(({ index }) => index === selectedRowKey[0]);

      if (nutroSKUData.length) {
        setSelectedRowKey([nutroSKUData[index].index]);
        setSelectedRow([nutroSKUData[index]]);
      } else {
        setSelectedRowKey([]);
        setSelectedRow([]);
      }
    } else {
      if (Object.keys(trend_data).length) setSelectedRow([trend_data]);
      else setSelectedRow([]);
    }
  }, [nutroSKUData, trend_data, filters.search]);

  const getWaterfallHeaderText = () => {
    const waterfallHeaderText = brandSplit(defaultBrand);
    return waterfallHeaderText.toUpperCase() === allBrands ? marsPN : waterfallHeaderText;
  }

  const exportKPIs = () =>
    exportDistKPIs(
      prepareKPIExport(exportData),
      isDefaultBrandMarsBDB,
      "Distribution_Summary_KPI_Layer"
    );

  const exportTableData = () => {
    if (isDefaultBrandMarsBDB) columns.dsColsNutroSKUColumnsExport[1].title = "Manufacturer";

    exportCustomTable(
      columns.dsColsNutroSKUColumnsExport,
      prepareTableExport(exportData),
      `${getWaterfallHeaderText()}_SKUs`
    );
  }

  const exportTDPVelocityCSL = () =>
    appliedFilters.customer_channel[0] !== dcom ? exportLine(
      prepareLineExport(exportData),
      "TDP vs Velocity - Last 4 weeks_Velocity vs CSL - Last 4 weeks"
    ) : exportTrend(
      prepareLineExport(exportData),
      "CSL - Last 4 weeks"
    );

  const exportAllSKUTableData = () => {
    if (isDefaultBrandMarsBDB) columns.dsColsAllSKUColumnsExport[0].title = "Manufacturer";

    exportCustomTable(
      columns.dsColsAllSKUColumnsExport,
      prepareScatterExport(exportData),
      "Distribution vs Velocity - All SKUs"
    );
  }

  const prepareKPIExport = (exportData) => {
    const data = JSON.parse(JSON.stringify(exportData));
    const {
      product_pet,
      product_technology,
      product_sub_technology,
      product_lifestage,
      customer_channel,
      customer_retailer_details,
      time_year,
      time_period,
    } = data.filters;
    data.filters = {
      product_pet,
      product_technology,
      product_sub_technology,
      product_lifestage,
      customer_channel,
      customer_retailer_details,
      time_year,
      time_period,
    };

    return data;
  };

  const prepareTableExport = (exportData) => {
    const data = JSON.parse(JSON.stringify(exportData));
    const {
      product_pet,
      product_technology,
      product_sub_technology,
      product_lifestage,
      product_package,
      customer_channel,
      customer_retailer_details,
      time_year,
      time_period,
      top,
      kpi,
    } = data.filters;

    data.filters = {
      product_pet,
      product_technology,
      product_sub_technology,
      product_lifestage,
      product_package,
      customer_channel,
      customer_retailer_details,
      time_year,
      time_period,
      kpi: [
        `${kpi[0]} - ${isReport ? reportRecordCount : pageSize} - ${top[0] ? "Winners" : "Losers"
        }`,
      ],
    };

    return data;
  };

  const prepareLineExport = (exportData) => {
    const data = JSON.parse(JSON.stringify(exportData));
    const {
      product_pet,
      product_technology,
      product_sub_technology,
      product_lifestage,
      product_package,
      customer_channel,
      customer_retailer_details,
      time_year,
      time_period,
      top,
      kpi,
    } = data.filters;

    data.filters = {
      product_pet,
      product_technology,
      product_sub_technology,
      product_lifestage,
      product_package,
      customer_channel,
      customer_retailer_details,
      time_year,
      time_period,
      top,
      kpi,
    };

    return data;
  };

  const prepareScatterExport = (exportData) => {
    let data = JSON.parse(JSON.stringify(exportData));
    const {
      product_pet,
      product_technology,
      product_sub_technology,
      product_lifestage,
      product_package,
      customer_channel,
      customer_retailer_details,
      time_year,
      time_period,
      brand,
      time_aggregates
    } = data.filters;

    data = {
      filters: {
        product_pet,
        product_technology,
        product_sub_technology,
        product_lifestage,
        product_package,
        customer_channel,
        customer_retailer_details,
        time_year,
        time_period,
        time_aggregates: getTimeAggregates(time_aggregates),
      },
      header: getScatterHeader(),
      tableData: { data: allSKUTableDataExport },
    };

    if (isDefaultBrandMarsBDB) data.filters.manufacturer = brand
    else data.filters.brand = brand

    return data;
  };

  useEffect(() => {
    if (!card_data) return;
    if (Object.keys(card_data).length === 0) return;
    dispatch(
      setExportData({
        kpi: {
          tdp: {
            title: "TDP",
            value: formatNumberExcel(card_data.fact_tdp_current_period),
            numFmt: numFmt.number,
            vsYA: card_data.fact_tdp_period_current_vs_ya,
            marsVsYA: card_data.fact_tdp_mars_vs_mars_ya,
          },
          dollarVsTdp: {
            title: "$/TDP",
            value: formatNumberExcel(
              card_data.fact_dollar_per_dollar_million_acv_current_period
            ),
            numFmt: numFmt.dollar,
            vsYA: card_data.fact_dollar_per_dollar_million_acv_current_vs_ya,
            marsVsYA:
              card_data.fact_dollar_per_dollar_million_acv_mars_vs_mars_ya,
          },
          csl: {
            title: "CSL",
            value: formatNumberExcel(card_data.fact_csl_current_period_worst_case, true),
            numFmt: numFmt.percentage,
            vsYA: card_data.fact_csl_current_vs_ya_worst_case,
            marsVsYA: card_data.fact_csl_mars_vs_mars_ya_worst_case,
          },
        },
      })
    );
  }, [dispatch, card_data]);

  useEffect(() => {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters.brand = brandNames;
    let tempFilters = { ...newFilters };
    if (
      tempFilters.product_pet &&
      (tempFilters.product_pet.length === 0 ||
        tempFilters.product_pet.length === pets.length)
    ) {
      tempFilters["product_pet"] = ["ALL"];
    }
    if (
      tempFilters.product_technology &&
      (tempFilters.product_technology.length === 0 ||
        tempFilters.product_technology.length === techs.length)
    ) {
      tempFilters["product_technology"] = ["ALL"];
    }
    if (
      tempFilters.product_sub_technology &&
      (tempFilters.product_sub_technology.length === 0 ||
        tempFilters.product_sub_technology.length === subTechs.length)
    ) {
      tempFilters["product_sub_technology"] = ["ALL"];
    }
    if (
      tempFilters.product_lifestage &&
      (tempFilters.product_lifestage .length === 0 ||
        tempFilters.product_lifestage .length === lifestage.length)
    ) {
      tempFilters["product_lifestage "] = ["ALL"];
    }
    if (
      tempFilters.customer_channel &&
      tempFilters.customer_channel.length === 0
    ) {
      tempFilters["customer_channel"] = ["ALL"];
    }
    if (
      tempFilters.customer_retailer_details &&
      tempFilters.customer_retailer_details.length === 0
    ) {
      tempFilters["customer_retailer_details"] = ["Total Market"];
    }
    if (tempFilters.time_year && tempFilters.time_year.length === 0) {
      tempFilters["time_year"] = ["ALL"];
    }
    if (tempFilters.time_period && tempFilters.time_period.length === 0) {
      tempFilters["time_period"] = ["ALL"];
    }
    if (
      (tempFilters.brand && tempFilters.brand.length === 0) ||
      tempFilters.brand.length === brand_dropdown.length
    ) {
      tempFilters["brand"] = ["ALL"];
    }
    dispatch(setExportData({ filters: tempFilters }));
  }, [filters, brandNames]);

  return (
    <Wrapper hideHeader={props.hideReportHeader} hidePage={isReport} heading={"1"} innerHeading={"8"}>
      <CustomLoader loading={props.reportLoader ? (perfLoading > 0 || loading > 0 || volumeShiftingLoading > 0 || innovationLoading > 0) : loading > 0} />
      <Content className={"site-layout-background" + (isReport && components.length === 0 ? "content-none" : " content-body")}>
        {!props.hideReportFilters && <><Row>
          <div className="refreshed-on">
            <span>
              Last Refreshed on: {distributionSummary.last_refresh}, Data upto:{" "}
              {distributionSummary.latest_period_available}
            </span>
            <span>Next Refresh on: {distributionSummary.next_refresh}</span>
          </div>
        </Row>
          <Row style={{ marginBottom: 16 }} align="bottom">
            <Col span={16}>
              <Form
                name="horizontal_login"
                className="performance-filter"
                layout="inline"
              >
                <Row gutter={12}>
                  <div className="filters-ps">
                    <Col>
                      <label className="label-block">Pet</label>
                      <Select
                        loading={filterLoading}
                        placeholder="All"
                        onMouseLeave={() => getRemainingFilters("product_pet")}
                        mode="multiple"
                        defaultValue={["all"]}
                        value={filters.product_pet}
                        onChange={(val) => changeFilter("product_pet", val)}
                        dropdownMatchSelectWidth={false} style={{ width: 130 }} placement="bottomLeft" maxTagCount="responsive"
                      >
                        <Option value={"all"}>{"All"}</Option>
                        {pets.map((val, index) => (
                          <Option key={index} value={val}>
                            {val}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col>
                      <label className="label-block">Technology</label>
                      <Select
                        loading={filterLoading}
                        placeholder="All"
                        onMouseLeave={() => getRemainingFilters("product_technology")}
                        defaultValue={["all"]}
                        mode="multiple"
                        value={filters.product_technology}
                        onChange={(val) =>
                          changeFilter("product_technology", val)
                        }
                        dropdownMatchSelectWidth={false} placement="bottomLeft" style={{ width: 140 }} maxTagCount="responsive"
                      >
                        <Option value={"all"}>{"All"}</Option>
                        {techs.map((val, index) => (
                          <Option key={index} value={val}>
                            {val}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    {filters.product_technology.length === 1 && isPackSize.includes(filters.product_technology[0]) && <Col>
                      <label className="label-block">Pack Type</label>
                      <Select loading={filterLoading} placeholder="All" onMouseLeave={() => getRemainingFilters('product_package')} mode="multiple" defaultValue={['all']} value={filters.product_package} onChange={(val) => { changeFilter('product_package', val) }} dropdownMatchSelectWidth={false} placement="bottomLeft" style={{ width: 85 }} maxTagCount="responsive" >
                        <Option value={'all'}>{'All'}</Option>
                        {packSize[filters.product_technology[0]] && packSize[filters.product_technology[0]].map((val) => (
                          <Option value={val}>{val}</Option>
                        ))}
                      </Select>
                    </Col>}
                    <Col>
                      <label className="label-block">Sub Technology</label>
                      <Select
                        loading={filterLoading}
                        placeholder="All"
                        onMouseLeave={() => getRemainingFilters("product_sub_technology")}
                        defaultValue={["all"]}
                        mode="multiple"
                        value={filters.product_sub_technology}
                        onChange={(val) =>
                          changeFilter("product_sub_technology", val)
                        }
                        dropdownMatchSelectWidth={false} placement="bottomLeft" style={{ width: 140 }} maxTagCount="responsive"
                      >
                        <Option value={"all"}>{"All"}</Option>
                        {subTechs.map((val, index) => (
                          <Option key={index} value={val}>
                            {val}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col>
                      <label className="label-block">Life Stage</label>
                      <Select
                        loading={filterLoading}
                        placeholder="All"
                        onMouseLeave={() => getRemainingFilters("product_lifestage")}
                        defaultValue={["all"]}
                        mode="multiple"
                        value={filters.product_lifestage}
                        onChange={(val) =>
                          changeFilter("product_lifestage", val)
                        }
                        dropdownMatchSelectWidth={false} placement="bottomLeft" style={{ width: 140 }} maxTagCount="responsive"
                      >
                        <Option value={"all"}>{"All"}</Option>
                        {lifestage.map((val, index) => (
                          <Option key={index} value={val}>
                            {val}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col>
                      <label className="label-block">Channel/Retailer</label>
                      <TreeSelect
                        loading={filterLoading}
                        placeholder="All"
                        defaultValue={["all"]}
                        onMouseLeave={() => getRemainingFilters("customer_channel")}
                        style={{ width: "200px" }}
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        value={channelRetailer}
                        treeData={channels}
                        onChange={onChannelChange}
                      />
                    </Col>
                    <Col>
                      <label className="label-block">Year</label>
                      <Select
                        loading={filterLoading}
                        placeholder="All"
                        onMouseLeave={() => getRemainingFilters("time_year")}
                        value={filters.time_year}
                        onChange={(val) => changeFilter("time_year", [val])}
                        style={{ width: 80 }}
                      >
                        {years.map((val, index) => (
                          <Option key={index} value={val}>
                            {val}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col>
                      <label className="label-block">Period</label>
                      <Select
                        loading={filterLoading}
                        placeholder="All"
                        value={filters.time_period}
                        onChange={(val) => changeFilter("time_period", [val])}
                        style={{ width: 68 }}
                      >
                        {periods.map((val, index) => (
                          <Option key={index} value={val}>
                            {val}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                  </div>
                  <Col>
                    <Button
                      type="primary"
                      style={{ marginTop: "27%" }}
                      onClick={() => applyFliters("all")}
                      disabled={filterLoading}
                    >
                      Apply
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col span={8}>
              <Row gutter={20} justify="end">
                {!newReport && (
                  <>
                    <Col>
                      <Dropdown
                        overlay={() => (
                          <Menu>
                            <Menu.Item
                              onClick={() => {
                                appInsights.trackEvent({ name: 'PPT_FOR_DISTRIBUTION' });
                                if (isDefaultBrandMarsBDB) {
                                  columns.dsColsAllSKUColumnsExport[0].title = "manufacturer";
                                  columns.dsColsNutroSKUColumnsExport[1].title = "manufacturer";
                                }
                                pptExportDistribution(
                                  exportData,
                                  "Distribution Report",
                                  columns.dsColsNutroSKUColumns,
                                  columns.dsColsAllSKUColumns,
                                  defaultBrand,
                                  isReport,
                                  components,
                                  {
                                    kpiData: prepareKPIExport(exportData),
                                    tableData: prepareTableExport(exportData),
                                    lineData: prepareLineExport(exportData),
                                    scatterData:
                                      prepareScatterExport(exportData),
                                  },
                                  columns.dsColsNutroSKUColumnsExport,
                                  columns.dsColsAllSKUColumnsExport
                                );
                              }}
                            >
                              Download as PPT
                            </Menu.Item>
                            <Menu.Item
                              onClick={() => {
                                appInsights.trackEvent({ name: 'EXCEL_FOR_DISTRIBUTION' });
                                if (isDefaultBrandMarsBDB) {
                                  columns.dsColsAllSKUColumnsExport[0].title = "manufacturer";
                                  columns.dsColsNutroSKUColumnsExport[1].title = "manufacturer";
                                }

                                exportDistributionSummary(
                                  isReport,
                                  isDefaultBrandMarsBDB,
                                  appliedFilters.customer_channel[0] === dcom,
                                  defaultBrand,
                                  components,
                                  {
                                    kpiData: prepareKPIExport(exportData),
                                    tableData: prepareTableExport(exportData),
                                    lineData: prepareLineExport(exportData),
                                    scatterData:
                                      prepareScatterExport(exportData),
                                  },
                                  columns.dsColsNutroSKUColumnsExport,
                                  columns.dsColsAllSKUColumnsExport
                                );
                              }}
                            >
                              Download as Excel
                            </Menu.Item>
                          </Menu>
                        )}
                        className="d-more"
                      >
                        <Button icon={<DownloadOutlined />}>
                          Export Report
                        </Button>
                      </Dropdown>
                    </Col>
                    {!isReport && (
                      <Col>
                        <Dropdown
                          placement="bottomRight"
                          overlay={() => (
                            <Menu>
                              <Menu.Item onClick={(e) => { appInsights.trackEvent({ name: 'CREATE_SELF_SERVE_REPORT_DISTRIBUTION' }); setNewReport(true); setCreateReport(true); }}>Create self serve Report</Menu.Item>
                              <Menu.Item onClick={(e) => { appInsights.trackEvent({ name: 'ADD_EXISTING_REPORT_DISTRIBUTION' }); setNewReport(true); setCreateReport(false); }}>Add to Existing Report</Menu.Item>
                            </Menu>
                          )}>
                          <Button
                            icon={<PlusCircleOutlined />}
                            type="primary"
                          >
                            Create Report
                          </Button>
                        </Dropdown>
                      </Col>
                    )}
                  </>
                )}
                {newReport && (
                  <>
                    <Col>
                      <Button
                        type="secondary"
                        onClick={(e) => { setNewReport(false); setNewReportComponents([]); }}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        onClick={(e) => { if (newReportComponents.length === 0) openNotificationWithIcon('error', 'No KPI components selected'); else setOpenSaveReportDialog(true); }}
                      >
                        Save Report
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </>}
        {!isReport && <Row className="sticky-filter">
          <Col span={24}>
            <div className="selected-filters">
              <label>Filters Applied:</label>
              <div className="filter-item">Pet - <span>{Array.isArray(appliedFilters.product_pet) && appliedFilters.product_pet.length > 0 ? appliedFilters.product_pet.join() : 'CAT,DOG'}</span></div>
              <div className="filter-item">Technology - <span>{Array.isArray(appliedFilters.product_technology) && appliedFilters.product_technology.length > 0 ? appliedFilters.product_technology.join() : "ALL"}</span></div>
              <div className="filter-item">Sub Technology - <span>{Array.isArray(appliedFilters.product_sub_technology) && appliedFilters.product_sub_technology.length > 0 ? appliedFilters.product_sub_technology.join() : "ALL"}</span></div>
              <div className="filter-item">Life Stage - <span>{Array.isArray(appliedFilters.product_lifestage) && appliedFilters.product_lifestage.length > 0 ? appliedFilters.product_lifestage.join() : "ALL"}</span></div>
              {appliedFilters.product_technology.length === 1 && isPackSize.includes(appliedFilters.product_technology[0]) && <div className="filter-item">Pack Type - <span>{Array.isArray(appliedFilters.product_package) ? appliedFilters.product_package.join() : "ALL"}</span></div>}
              <div className="filter-item">Channel/Retailer - <span>{getFilterTitle()}</span></div>
              <div className="filter-item">Year - <span>{appliedFilters.time_year}</span></div>
              <div className="filter-item">Period - <span>{appliedFilters.time_period}</span></div>
            </div>
          </Col>
        </Row>}
        {(isReport ? (components.length !== 0) : false) && <div className="vs-title" style={{ margin: 0 }}>
          <div>
            <h2>Distribution Summary</h2>
          </div>
        </div>}
        {(isReport ? (components.length !== 0) : false) && <Row style={{ marginBottom: 25 }}><Col>
          <label className="label-block">Channel/Retailer</label>
          <TreeSelect
            placeholder="All"
            defaultValue={["all"]}
            onMouseLeave={() => {
              if (!props.filterChanged)
                return
              else {
                props.applyFilters("distribution-summary")
                props.setFilterChanged(false)
              }
            }}
            style={{ width: "200px" }}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            value={props.channelValue}
            treeData={props.channelData}
            onChange={(val) => props.onChannelChange(val)}
          />
        </Col></Row>}
        {newReport && <Row>
          <Col span={24}>
            <div className="common-callout" style={{ marginTop: 10 }}>
              <p>Select KPI Components you want to add in your report by clicking on the checkboxes</p>
            </div>
          </Col>
        </Row>}

        {(!card_data_is_distribution ||
          !table_data_is_distribution ||
          !scatter_data_is_distribution) &&
          filters.customer_channel.includes(dcom) && (
            <Row>
              <Col span={24}>
                <Alert
                  style={{
                    padding: "4px 10px",
                    marginTop: 10,
                    width: "100%",
                  }}
                  message={`Distribution data not applicable for ${dcom.split(" ")[0]}`}
                  type="info"
                />
              </Col>
            </Row>
          )}

        {(!isReport || components.includes("1")) && (
          <div className={`first-section-ps ps-top ${newReport ? 'trackboard-edit' : ''} ${newReport && newReportComponents.includes('1') ? 'trackboard-selected' : ''}`} style={{ marginBottom: 16, marginTop: 24 }}>
            <Row
              gutter={12}
              justify="space-between"
            >
              <Col span={8}>
                <KPICard
                  newReport={newReport}
                  newReportComponents={newReportComponents}
                  changeNewReportComponent={changeNewReportComponent}
                  title="TDP"
                  isDefaultBrandMarsBDB={isDefaultBrandMarsBDB}
                  value={card_data.fact_tdp_current_period}
                  vsYa={card_data.fact_tdp_period_current_vs_ya}
                  vsYsSymbol={symbols.percent}
                  marsVsYa={card_data.fact_tdp_mars_vs_mars_ya}
                  marsVsYaSymbol={symbols.percent} />
              </Col>
              <Col span={8}>
                <KPICard
                  newReport={newReport}
                  newReportComponents={newReportComponents}
                  changeNewReportComponent={changeNewReportComponent}
                  title="$/TDP"
                  isDefaultBrandMarsBDB={isDefaultBrandMarsBDB}
                  value={card_data.fact_dollar_per_dollar_million_acv_current_period}
                  valueSymbol={symbols.dollar}
                  vsYa={card_data.fact_dollar_per_dollar_million_acv_current_vs_ya}
                  vsYsSymbol={symbols.percent}
                  marsVsYa={card_data.fact_dollar_per_dollar_million_acv_mars_vs_mars_ya}
                  marsVsYaSymbol={symbols.percent} />
              </Col>
              <Col span={8}>
                <KPICard
                  isCSL={true}
                  newReport={newReport}
                  newReportComponents={newReportComponents}
                  changeNewReportComponent={changeNewReportComponent}
                  title="CSL"
                  isDefaultBrandMarsBDB={isDefaultBrandMarsBDB}
                  value={card_data.fact_csl_current_period_worst_case}
                  valueSymbol={symbols.percent}
                  vsYa={card_data.fact_csl_current_vs_ya_worst_case}
                  vsYsSymbol={symbols.points}
                  marsVsYa={card_data.fact_csl_mars_vs_mars_ya_worst_case}
                  marsVsYaSymbol={symbols.points}
                  exportKPIs={exportKPIs} />
              </Col>
            </Row>
          </div>
        )}
        {(!isReport || components.includes("3")) && (
          <div className="second-section-ps">
            <NutroSKUsTable
              heading={`${getWaterfallHeaderText()} SKUs - ${getFilterTitle()}`}
              showCore={false}
              rowKey="index"
              hideFilter={false}
              data={nutroSKUData}
              dropdownData={kpis}
              columns={columns.dsColsNutroSKUColumns}
              filters={filters}
              changeFilter={changeFilter}
              pageSize={isReport ? reportRecordCount : pageSize}
              setPageSize={setPageSize}
              rowSelection={{
                checkStrictly: true,
                selectedRowKeys: selectedRowKey,
                type: "radio",
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelectedRowKey(selectedRowKeys);
                  if (filters.search[0] === "SKU") setSelectedRow(selectedRows);
                },
                hideSelectAll: true,
              }}
              exportData={exportTableData}
              checkbox={newReport}
              value={newReportComponents.includes("3")}
              onChange={(e) => changeNewReportComponent("3", e)}
            />
          </div>
        )}

        {(!isReport || components.includes("3")) && (
          <div className={`table-chart ${newReport ? 'trackboard-edit' : ''} ${newReport && newReportComponents.includes("3") ? 'trackboard-selected' : ''}`} style={{ marginBottom: 16 }}>
            <Row
              gutter={10}
              style={{ marginBottom: 16 }}
              className="third-section-ps"
            >
              <Col span={24}>
                <h3 style={{ textAlign: "center" }}>
                  {getTrendHeader()}
                </h3>
              </Col>
              {appliedFilters.customer_channel[0] !== dcom ?
                <>
                  <Col span={12}>
                    <div className="chart-wrap">
                      <div className="section-title">
                        {newReport && (
                          <Checkbox
                            style={{ marginRight: "5%" }}
                            value={newReportComponents.includes("3")}
                            onChange={(e) => changeNewReportComponent("3", e)}
                            className="d-check"
                          ></Checkbox>
                        )}
                        <h4>Velocity vs TDP - Last 4 weeks</h4>
                      </div>
                      <div className="year-filter">
                        <div>
                          <Radio.Group
                            defaultValue={filters.search[0]}
                            value={filters.search[0]}
                            size="small"
                            onChange={(e) => changeFilter("search", [e.target.value])}
                          >
                            <Radio.Button value="SKU">SKU</Radio.Button>
                            <Radio.Button value="product_brand">{isDefaultBrandMarsBDB ? "Manufacturer" : "Brand"}</Radio.Button>
                            <Radio.Button value="trend_secondary_brand">{isDefaultBrandMarsBDB ? "Brand" : "Sub Brand"}</Radio.Button>
                          </Radio.Group>
                          {filters.search[0] === "trend_secondary_brand" && <Select
                            defaultValue={filters.trend_secondary_brand}
                            size="small"
                            style={{ width: 130, marginLeft: 10 }}
                            value={filters.trend_secondary_brand}
                            dropdownMatchSelectWidth={false}
                            onChange={(val) => changeFilter("trend_secondary_brand", [val])}
                          >
                            {(isDefaultBrandMarsBDB ? getSubBrands() : subBrands).map((val, index) => (
                              <Option key={index} value={val}>
                                {val === "" ? "Select" : val}
                              </Option>
                            ))}
                          </Select>}
                        </div>
                        <Radio.Group
                          defaultValue={tdpVelocityYear}
                          size="small"
                          onChange={(e) => {
                            const { value } = e.target;
                            setTdpVelocityYear(value);
                            setTdpVelocityYField(value === "cy" ? ["Velocity", "TDP"] : ["Velocity YA", "TDP YA"]);
                          }}
                        >
                          <Radio.Button value="cy">This Year</Radio.Button>
                          <Radio.Button value="ya">Year Ago</Radio.Button>
                        </Radio.Group>
                      </div>
                      <div className="chart">
                        <DualAxesLine
                          xField="period_year"
                          yField={tdpVelocityYField}
                          data={tdpVsVelocity} />
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="chart-wrap">
                      <div className="section-title">
                        <h4>Velocity vs CSL - Last 4 weeks</h4>
                        <Dropdown
                          overlay={() => menu(exportTDPVelocityCSL)}
                          trigger={["click"]}
                          placement="bottomRight"
                          className="d-more"
                        >
                          <a
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}
                          >
                            <MoreOutlined />
                          </a>
                        </Dropdown>
                      </div>
                      <div className="year-filter">
                        <Radio.Group
                          defaultValue={velocityCslYear}
                          size="small"
                          onChange={(e) => {
                            const { value } = e.target;
                            setVelocityCslYear(value);
                            setVelocityCslYField(value === "cy" ? ["Velocity", "CSL"] : ["Velocity YA", "CSL YA"]);
                          }}
                        >
                          <Radio.Button value="cy">This Year</Radio.Button>
                          <Radio.Button value="ya">Year Ago</Radio.Button>
                        </Radio.Group>
                      </div>
                      <div className="chart">
                        <DualAxesLine
                          xField="period_year"
                          yField={velocityCslYField}
                          data={velocityVsCsl} />
                      </div>
                    </div>
                  </Col>
                </> : <Col span={24}>
                  <div className="chart-wrap">
                    <div className="section-title">
                      <h4>CSL - Last 4 weeks</h4>
                      <Dropdown
                        overlay={() => menu(exportTDPVelocityCSL)}
                        trigger={["click"]}
                        placement="bottomRight"
                        className="d-more"
                      >
                        <a
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <MoreOutlined />
                        </a>
                      </Dropdown>
                    </div>
                    <div className="year-filter">
                      <div>
                        <Radio.Group
                          defaultValue={filters.search[0]}
                          value={filters.search[0]}
                          size="small"
                          onChange={(e) => changeFilter("search", [e.target.value])}
                        >
                          <Radio.Button value="SKU">SKU</Radio.Button>
                          <Radio.Button value="product_brand">{isDefaultBrandMarsBDB ? "Manufacturer" : "Brand"}</Radio.Button>
                          <Radio.Button value="trend_secondary_brand">{isDefaultBrandMarsBDB ? "Brand" : "Sub Brand"}</Radio.Button>
                        </Radio.Group>
                        {filters.search[0] === "trend_secondary_brand" && <Select
                          defaultValue={filters.trend_secondary_brand}
                          size="small"
                          style={{ width: 130, marginLeft: 10 }}
                          value={filters.trend_secondary_brand}
                          dropdownMatchSelectWidth={false}
                          onChange={(val) => changeFilter("trend_secondary_brand", [val])}
                        >
                          {(isDefaultBrandMarsBDB ? getSubBrands() : subBrands).map((val, index) => (
                            <Option key={index} value={val}>
                              {val === "" ? "Select" : val}
                            </Option>
                          ))}
                        </Select>}
                      </div>
                      <Radio.Group
                        defaultValue={onlyCslYear}
                        size="small"
                        onChange={(e) => {
                          const { value } = e.target;
                          setOnlyCslYear(value);
                          setOnlyCslYField(value === "cy" ? "CSL" : "CSL YA");
                        }}
                      >
                        <Radio.Button value="cy">This Year</Radio.Button>
                        <Radio.Button value="ya">Year Ago</Radio.Button>
                      </Radio.Group>
                    </div>
                    <div className="chart">
                      {/* <DualAxesLine
                        xField="period_year"
                        yField={onlyCslYField}
                        data={onlyCsl} /> */}
                      <TrendChart
                        xField="period_year"
                        yField={onlyCslYField}
                        data={velocityVsCsl}
                      />
                    </div>
                  </div>
                </Col>}
            </Row>
          </div>
        )}

        {(!isReport || components.includes("4")) &&
          scatter_data_is_distribution === 1 && (
            <div className={`sku-wrap fourth-section-ps ${newReport ? 'trackboard-edit' : ''} ${newReport && newReportComponents.includes("4") ? 'trackboard-selected' : ''}`}>
              <Row gutter={16}>
                <Col span={24}>
                  <div className="section-title">
                    {newReport && (
                      <Checkbox
                        style={{ marginRight: "5%" }}
                        value={newReportComponents.includes("4")}
                        onChange={(e) => changeNewReportComponent("4", e)}
                        className="d-check"
                      ></Checkbox>
                    )}
                    <h4>
                      Distribution vs Velocity - All SKUs - {getFilterTitle()}
                    </h4>
                    <Dropdown
                      overlay={() => menu(exportAllSKUTableData)}
                      trigger={["click"]}
                      placement="bottomRight"
                      className="d-more"
                    >
                      <a
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        <MoreOutlined />
                      </a>
                    </Dropdown>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="sku-chart-left">
                    <Form
                      name="horizontal_login"
                      style={{ marginBottom: 10 }}
                      className="performance-filter"
                      layout="inline"
                    >
                      <Row gutter={10}>
                        <Col>
                          <label className="label-block">{defaultBrand.toUpperCase() !== allBrands ? <>Brand</> : <>Manufacturer</>}</label>
                          <Select
                            allowClear
                            defaultValue={["All"]}
                            mode="multiple"
                            style={{ minWidth: 148 }}
                            value={brandNames}
                            onChange={handleSelectBrand}
                          >
                            <Option value={"all"}>{"All"}</Option>
                            {brand_dropdown.map((brand, index) => (
                              <Option key={index} value={brand}>
                                {brand}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">Time Aggregates</label>.
                          <Select style={{ width: 100 }} placeholder="All" defaultValue={["4w"]} value={filters.time_aggregates} onChange={(val) => { changeFilter('time_aggregates', [val]); }} dropdownMatchSelectWidth={false} maxTagCount="responsive">
                            <Option value="4w">L4W</Option>
                            <Option value="52w">L52W</Option>
                          </Select>
                        </Col>
                        <Col>
                          <label className="label-block">MKT RSV</label>
                          <InputNumber min={0} className="dist-input" defaultValue={Number(tailCutOff.tail_cut_off_rsv)} value={Number(tailCutOff.tail_cut_off_rsv)} onChange={(val) => setTailCutOff({ ...tailCutOff, tail_cut_off_rsv: [val.toString()] })} onBlur={(e) => { if (filters.tail_cut_off_rsv[0] !== e.target.value) changeFilter('tail_cut_off_rsv', [e.target.value.toString()]) }} />
                        </Col>
                        <Col>
                          <label className="label-block">Weighted Distribution</label>
                          <InputNumber min={0} className="dist-input" defaultValue={Number(tailCutOff.tail_cut_off_wd)} value={Number(tailCutOff.tail_cut_off_wd)} onChange={(val) => setTailCutOff({ ...tailCutOff, tail_cut_off_wd: [val.toString()] })} onBlur={(e) => { if (filters.tail_cut_off_wd[0] !== e.target.value) changeFilter('tail_cut_off_wd', [e.target.value.toString()]) }} />
                        </Col>
                      </Row>
                    </Form>
                    <div className="scatter-info">
                      <ul>
                        <li>
                          <label>Tail cut-off:</label>
                          MKT RSV &gt; {preFilters.rsv_limit} $ and Weighted
                          Distribution &gt;{" "}
                          {formatNumber(preFilters.wd_limit, 1)}
                          <Tooltip
                            title={`Exclusion of SKUs with MKT RSV > ${preFilters.rsv_limit
                              } $ and Weighted Distribution > ${formatNumber(
                                preFilters.wd_limit,
                                1
                              )} for all items. All calculations exclude tail, to give a comprehensive view.`}
                            placement="right"
                          >
                            <InfoCircleOutlined />
                          </Tooltip>
                        </li>
                        <li>
                          <label>Number of SKUs in tail:</label>{" "}
                          {preFilters.sku_in_tail}
                        </li>
                        <li>
                          <label>Sales value in tail:</label> $
                          {formatNumber(preFilters.tail_sales_value, 1)}
                        </li>
                        <li>
                          <label>Tail sales as % of total:</label>{" "}
                          {formatNumber(preFilters.tail_sales_percentage, 1)}%
                        </li>
                      </ul>
                    </div>
                    <div className="circle-chart">
                      <p className="selection-message">
                        Select an item from the quadrants to view details
                      </p>

                      <CircleChart
                        data={allSKUChartData}
                        brandColors={brand_colors}
                        xField="Weighted Distribution"
                        yField="Velocity"
                        xBaseline={preFilters.median_x_axis}
                        yBaseline={preFilters.median_y_axis}
                        onSelect={setAllSKUTableData}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="sku-table-wrap">
                    <CustomScatterTable
                      columns={columns.dsColsAllSKUColumns}
                    />
                    {/* <Table
                      rowKey="index"
                      dataSource={scatterTable}
                      className="custom-table"
                      columns={columns.dsColsAllSKUColumns}
                      scroll={{ y: 580 }}
                    /> */}
                  </div>
                </Col>
              </Row>
            </div>
          )}
        <NewReportForm
          filters={{ ...filters, brand: brandNames, pageSize: [pageSize] }}
          createReport={createReport}
          setNewReport={setNewReport}
          newReportComponents={newReportComponents}
          reportType={"distribution-summary"}
          setNewReportComponents={setNewReportComponents}
          openSaveReportDialog={openSaveReportDialog}
          setOpenSaveReportDialog={setOpenSaveReportDialog}
        />
      </Content>
    </Wrapper >
  );
}

export default withRouter(DistributionSummary);
