import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Layout, Row, Form, Button, Select, Divider, Menu, Dropdown } from "antd";
import { withRouter } from "react-router-dom";
import Wrapper from "../../components/wrapper";
import { DownloadOutlined, MoreOutlined, PlusCircleOutlined, EditOutlined, DeleteOutlined, SaveFilled } from '@ant-design/icons';
import CustomTable from "../../components/customTable";
import './volume_shifting.scss';
import { getAllFilters, getFilterData, getTableData, addTableData, addChannelData, addRetailerData, setFilters, setTableData, replaceTableData, sortTableData, getWaterFallData, getImages, getRetailerImages, setImages, setRetailerImages, compareVia, getChannelRetailerData, setChannelData, replaceChannelData, setRetailerData, replaceRetailerData, sortChannelData, sortRetailerData, setAppliedFilters, getInitialBottomFilters, allBrandTotal } from "../../slicers/volumeShiftingSummary.slicer";
import WaterfallChart from "../../components/waterfall-chart";
import Loader from "../../components/loader";
import { formatNumber, openNotificationWithIcon, valueTypes, brandSplit, allBrands, timeAggregates, setWindowHistory, hasValue } from "../../utilities/helper";
import { exportCustomTable, exportMarketFinancialsTable, exportVolumeKPIs, exportVSData, exportWaterfallPercentage } from "../../utilities/exports/excel";
import { setExportData } from "../../slicers/exportData.slicer";
import NewReportForm from "../../components/newReportForm";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { pptExportVolume } from "../../utilities/exports/ppt";
import columns from "./vsColumns";
import { wData, declareAppliedFilters } from "./vsUIHelper";
import { appInsights } from "../../utilities/appInsights";

const { Content } = Layout;
const { Option } = Select;

export const isPackSize = ["TOTAL WET"];

/**
 * Competition Summary
 * It allows the end user to compare the different products and their sales value across brands, sub-brands & retailers.
 */
function VolumeShifting(props) {
  const [psSections, setPsSections] = useState([]);
  const [card, setCard] = useState([]);
  const dispatch = useDispatch();
  const { defaultBrand, competitorBrands } = useSelector((state) => state.profile)
  const [selectedBrand, setSelectedBrand] = useState(defaultBrand);
  const { loading, filterLoading, pets, techs, subTechs, lifestage, packSize, years, periods, brands, manufacturers, filters, appliedFilters, channels, channel_retailer, waterfallData, table_data, channel_data, retailer_data, images, retailerImages } = useSelector((state) => state.volShiftSummary)
  const exportData = useSelector((state) => state.exportData);
  const { volumeShifting } = useSelector((state) => state.refreshDate);
  const [filterChanged, setFilterChanged] = useState(false);
  const [productPetChanged, setProductPetChanged] = useState(false);
  const [newReport, setNewReport] = useState(false);
  const [createReport, setCreateReport] = useState(true);
  const [openSaveReportDialog, setOpenSaveReportDialog] = useState(false);
  const [newReportComponents, setNewReportComponents] = useState([]);
  const [newReportBrands, setNewReportBrands] = useState(["MARS BDB", brandSplit(defaultBrand)]);
  const [newReportChannels, setNewReportChannels] = useState(['FDM excluding Omni']);
  const [newReportRetailers, setNewReportRetailers] = useState(['Walmart B&M + Omni']);
  const [tableDataExport, setTableDataExport] = useState([]);
  const [compareViaLocal, setCompareViaLocal] = useState("");
  const [retailerListLocal, setRetailerListLocal] = useState([]);
  const [channelLocal, setChannelLocal] = useState("");
  const [components, setComponents] = useState([]);
  const [reportBrands, setReportBrands] = useState([]);
  const [reportChannels, setReportChannels] = useState([]);
  const [reportRetailers, setReportRetailers] = useState([]);
  const [brandToggle, setBrandToggle] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const isReport = props.isReport

  const isBrand = isReport || filters.compare_via && filters.compare_via.length > 0 && filters.compare_via[0] === compareVia.brand;
  const isChannel = isReport || filters.compare_via && filters.compare_via.length > 0 && filters.compare_via[0] === compareVia.channel;
  const isRetailer = isReport || filters.compare_via && filters.compare_via.length > 0 && filters.compare_via[0] === compareVia.retailer;

  const isDefaultBrandMarsBDB = defaultBrand === allBrands;

  const changeNewReportBrand = (val, e) => {
    let temp = [...newReportBrands];
    if (e.target.checked) {
      temp.push(val)
      setNewReportBrands(temp);
    }
    else {
      let ind = temp.findIndex((i) => i === val)
      if (ind > -1) {
        temp.splice(ind, 1)
        setNewReportBrands(temp)
      }
    }
  }

  const fetchCompetitorBrands = (newBrand, pet) => {
    if (Object.keys(competitorBrands).length !== 0) {
      if (newBrand.split(":").length === 2) {
        if (competitorBrands[newBrand.split(":")[0]] && competitorBrands[newBrand.split(":")[0]]["secondary_brand_data"] && competitorBrands[newBrand.split(":")[0]]["secondary_brand_data"][newBrand.split(":")[1]] && competitorBrands[newBrand.split(":")[0]]["secondary_brand_data"][newBrand.split(":")[1]]["competitor_data"] && competitorBrands[newBrand.split(":")[0]]["secondary_brand_data"][newBrand.split(":")[1]]["competitor_data"][pet] && competitorBrands[newBrand.split(":")[0]]["secondary_brand_data"][newBrand.split(":")[1]]["competitor_data"][pet].length !== 0) {
          return competitorBrands[newBrand.split(":")[0]]["secondary_brand_data"][newBrand.split(":")[1]]["competitor_data"][pet]
        }
        else if (competitorBrands[newBrand.split(":")[0]] && competitorBrands[newBrand.split(":")[0]]["secondary_brand_data"] && competitorBrands[newBrand.split(":")[0]]["secondary_brand_data"][newBrand.split(":")[1]] && competitorBrands[newBrand.split(":")[0]]["secondary_brand_data"][newBrand.split(":")[1]]["competitor_data"] && competitorBrands[newBrand.split(":")[0]]["secondary_brand_data"][newBrand.split(":")[1]]["competitor_data"]["ALL"]) {
          return competitorBrands[newBrand.split(":")[0]]["secondary_brand_data"][newBrand.split(":")[1]]["competitor_data"]["ALL"].length !== 0 ? competitorBrands[newBrand.split(":")[0]]["secondary_brand_data"][newBrand.split(":")[1]]["competitor_data"]["ALL"] : []
        }
        else {
          return []
        }
      }
      else {
        if (competitorBrands[newBrand] && competitorBrands[newBrand]["competitor_data"] && competitorBrands[newBrand]["competitor_data"][pet] && competitorBrands[newBrand]["competitor_data"][pet].length !== 0) {
          return competitorBrands[newBrand]["competitor_data"][pet];
        }
        else if (competitorBrands[newBrand] && competitorBrands[newBrand]["competitor_data"] && competitorBrands[newBrand]["competitor_data"]["ALL"]) {
          return competitorBrands[newBrand]["competitor_data"]["ALL"].length !== 0 ? competitorBrands[newBrand]["competitor_data"]["ALL"] : [];
        }
        else {
          return []
        }
      }
    }
  }

  const changeNewReportChannel = (val, e) => {
    let temp = [...newReportChannels];
    if (e.target.checked) {
      temp.push(val)
      setNewReportChannels(temp);
    }
    else {
      let ind = temp.findIndex((i) => i === val)
      if (ind > -1) {
        temp.splice(ind, 1)
        setNewReportChannels(temp)
      }
    }
  }

  const changeNewReportRetailer = (val, e) => {
    let temp = [...newReportRetailers];
    if (e.target.checked) {
      temp.push(val)
      setNewReportRetailers(temp);
    }
    else {
      let ind = temp.findIndex((i) => i === val)
      if (ind > -1) {
        temp.splice(ind, 1)
        setNewReportRetailers(temp)
      }
    }
  }


  const changeNewReportComponent = (val, e) => {
    let temp = [...newReportComponents];
    if (e.target.checked) {
      temp.push(val)
      setNewReportComponents(temp);
    }
    else {
      let ind = temp.findIndex((i) => i === val)
      if (ind > -1) {
        temp.splice(ind, 1)
        setNewReportComponents(temp)
      }
    }
  }

  const changeFilter = (label, selectedValue) => {
    if (label === "product_pet" && JSON.stringify(filters[label]) !== JSON.stringify(selectedValue)) {
      setProductPetChanged(true);
    }
    let newFilters = { ...filters }
    if (selectedValue && selectedValue.length && selectedValue.includes("all")) {
      let all = [];
      if (label === 'product_pet') all = pets;
      else if (label === 'product_package' && filters.product_technology.length === 1) all = packSize[filters.product_technology[0]];
      else all = techs;
      if (label === 'product_sub_technology') all = subTechs;
      if (label === 'product_lifestage') all = lifestage;
      if (selectedValue.length === all.length + 1) {
        selectedValue = [];
      }
      else selectedValue = [...all];
    }
    if (JSON.stringify(filters[label]) !== JSON.stringify(selectedValue)) {
      setFilterChanged(true);
    }
    if (label === "product_technology" && !isPackSize.includes(selectedValue)) newFilters["product_package"] = []
    newFilters[label] = selectedValue
    dispatch(setFilters(newFilters))
  }

  useEffect(() => {
    let currentBrands = [...newReportBrands]
    currentBrands.splice(1, 1, brandSplit(defaultBrand))
    setNewReportBrands(currentBrands)
  }, [defaultBrand])

  const fetchReportData = (currentFilter, preFilters, isReport) => {
    let loadBrands = fetchCompetitorBrands(defaultBrand, "ALL")
    loadBrands = loadBrands.includes(allBrandTotal) ? [...loadBrands] : [allBrandTotal, ...loadBrands]

    currentFilter.brandList = loadBrands ? [...loadBrands] : [];

    let loadChannels = Object.keys(competitorBrands).length !== 0 ? [...currentFilter.channelList] : [];
    let loadRetailers = Object.keys(competitorBrands).length !== 0 ? [...currentFilter.retailerList] : [];

    if (isReport) {
      let reportFilter = { ...currentFilter, brandList: preFilters.reportBrands, channelList: Object.keys(preFilters.reportChannels), channelMap: preFilters.reportChannels, retailerList: Object.values(preFilters.reportRetailers).map(val => Object.keys(val)).flat(), retailerMap: preFilters.reportRetailers, channel: preFilters.reportRetailers && Object.keys(preFilters.reportRetailers) }

      dispatch(setFilters(reportFilter));
      dispatch(setAppliedFilters(declareAppliedFilters(reportFilter, { pets, techs, subTechs, lifestage, packSize: packSize[reportFilter.product_technology[0]] })));
      setComponents(preFilters.waterfall === "1" ? ['1'] : [])
      setReportBrands(preFilters.reportBrands)
      setReportChannels(reportFilter.channelList)
      setReportRetailers(reportFilter.retailerList)

      Object.keys(competitorBrands).length && reportFilter.brandList && reportFilter.brandList.map(val => { dispatch(getTableData(val, reportFilter)); dispatch(getImages(val, reportFilter.product_pet)); })
      Object.keys(competitorBrands).length && reportFilter.channelMap && reportFilter.channelList.map(val => { dispatch(getChannelRetailerData(val, null, { ...reportFilter, compare_via: ["channel"], brandList: reportFilter.channelMap[val] && reportFilter.channelMap[val]["brandList"] && reportFilter.channelMap[val]["brandList"] }, -1, true)); })
      Object.keys(competitorBrands).length &&
        reportFilter.channel &&
        reportFilter.channel.length &&
        reportFilter.channel.map(channel => {
          reportFilter.retailerMap && reportFilter.retailerMap[channel] && Object.keys(reportFilter.retailerMap[channel]).map(val => {

            dispatch(getChannelRetailerData(channel, val, { ...reportFilter, compare_via: ["retailer"], brandList: reportFilter.retailerMap[channel] && reportFilter.retailerMap[channel][val] && reportFilter.retailerMap[channel][val]["brandList"] && reportFilter.retailerMap[channel][val]["brandList"] }, -1, true)); dispatch(getRetailerImages(val, reportFilter.product_pet))
          })
        })

    } else {
      const isBrand = currentFilter.compare_via && currentFilter.compare_via.length > 0 && currentFilter.compare_via[0] === compareVia.brand;
      const isChannel = currentFilter.compare_via && currentFilter.compare_via.length > 0 && currentFilter.compare_via[0] === compareVia.channel;
      const isRetailer = currentFilter.compare_via && currentFilter.compare_via.length > 0 && currentFilter.compare_via[0] === compareVia.retailer;

      dispatch(setFilters(currentFilter));
      dispatch(setAppliedFilters(declareAppliedFilters(currentFilter, { pets, techs, subTechs,lifestage, packSize: packSize[currentFilter.product_technology[0]] })));

      isBrand && loadBrands && loadBrands.map(val => { dispatch(getTableData(val, currentFilter)); dispatch(getImages(val, currentFilter.product_pet)); })
      isChannel && loadChannels && loadChannels.map(val => { dispatch(getChannelRetailerData(val, null, removeAllBrandTotal(currentFilter))); })
      isRetailer && currentFilter.channel && currentFilter.channel.length && loadRetailers && loadRetailers.map(val => { dispatch(getChannelRetailerData(currentFilter.channel[0], val, removeAllBrandTotal(currentFilter))); dispatch(getRetailerImages(val, currentFilter.product_pet)) })
    }
  }

  const fetchInitialData = (newFilter) => {
    let loadBrands = fetchCompetitorBrands(defaultBrand, "ALL")
    loadBrands = loadBrands.includes(allBrandTotal) ? [...loadBrands] : [allBrandTotal, ...loadBrands]

    newFilter.brandList = loadBrands ? [...loadBrands] : [];

    let loadChannels = Object.keys(competitorBrands).length !== 0 ? [...newFilter.channelList] : [];
    let loadRetailers = Object.keys(competitorBrands).length !== 0 ? [...newFilter.retailerList] : [];

    dispatch(getWaterFallData(newFilter));

    const isBrand = newFilter.compare_via && newFilter.compare_via.length > 0 && newFilter.compare_via[0] === compareVia.brand;
    const isChannel = newFilter.compare_via && newFilter.compare_via.length > 0 && newFilter.compare_via[0] === compareVia.channel;
    const isRetailer = newFilter.compare_via && newFilter.compare_via.length > 0 && newFilter.compare_via[0] === compareVia.retailer;

    dispatch(setFilters(newFilter));
    dispatch(setAppliedFilters(declareAppliedFilters(newFilter, { pets, techs, subTechs,lifestage,packSize: packSize[newFilter.product_technology[0]] })));

    isBrand && loadBrands && loadBrands.map(val => { dispatch(getTableData(val, newFilter)); dispatch(getImages(val, newFilter.product_pet)); })
    isChannel && loadChannels && loadChannels.map(val => { dispatch(getChannelRetailerData(val, null, removeAllBrandTotal(newFilter))); })
    isRetailer && newFilter.channel && newFilter.channel.length && loadRetailers && loadRetailers.map(val => { dispatch(getChannelRetailerData(newFilter.channel[0], val, removeAllBrandTotal(newFilter))); dispatch(getRetailerImages(val, newFilter.product_pet)) })
  }

  const changeSelectedBrand = () => setSelectedBrand(defaultBrand);

  const fetchData = (filters, preFilters, isReport, fetchFilterData = true, initialFilterData = true) => {
    setIsDataFetched(true);
    dispatch(setTableData([]));
    dispatch(setChannelData([]));
    dispatch(setRetailerData([]));
    dispatch(setImages([]));
    dispatch(setRetailerImages([]));

    dispatch(sortChannelData());
    dispatch(sortRetailerData());
    dispatch(sortTableData());

    const isBrandChanged = defaultBrand !== selectedBrand;

    // API calls
    if (fetchFilterData)
      if (initialFilterData) dispatch(getAllFilters(fetchInitialData, isBrandChanged, changeSelectedBrand));
      else dispatch(getFilterData({}, preFilters, fetchReportData, isReport, true, isBrandChanged, changeSelectedBrand));
  };

  useEffect(() => {
    if (Object.keys(competitorBrands).length === 0) return;

    const initialFilters = {
      ...getInitialBottomFilters(),
      default_brand: [defaultBrand],
    };

    let temp = new URL(window.location.href)
    if (temp.search.length > 1) {
      let preFilters = JSON.parse(decodeURIComponent(temp.search.substring(1)))
      let currentFilter = { ...filters }
      for (const [k, v] of Object.entries(preFilters)) {
        if (k in currentFilter) {
          currentFilter = {
            ...currentFilter,
            [k]: [...v]
          }
        }
      }

      currentFilter = {
        ...currentFilter,
        default_brand: [defaultBrand],
      };

      setWindowHistory(encodeURIComponent(JSON.stringify(currentFilter)));
      fetchData(currentFilter, preFilters, isReport, true, false);
    }
    else fetchData(initialFilters);

  }, [defaultBrand, competitorBrands, props.reportApply])

  useEffect(() => {
    if (compareViaLocal !== "") {
      applyFilters()
    }
  }, [compareViaLocal]);

  useEffect(() => {
    if (retailerListLocal.length !== 0) {
      applyFilters()
    }
  }, [retailerListLocal])

  useEffect(() => {


    if (channelLocal !== "" && channel_retailer[channelLocal]) {
      changeFilter('retailerList', channel_retailer[channelLocal]);
      setRetailerListLocal(channel_retailer[channelLocal])
      setNewReportRetailers([channel_retailer[channelLocal][0]]) //Check If this requires [] or not
    }
  }, [channel_retailer, channelLocal]);

  const getRemainingFilters = (label) => {
    if (!filterChanged) return

    setFilterChanged(false)
    if (label === 'product_pet') {
      dispatch(getFilterData({
        'product_pet': filters.product_pet,
        'time_year': filters.time_year,
      }))
    }
    else if (label === 'product_technology') {
      dispatch(getFilterData({
        'product_pet': filters.product_pet,
        'product_technology': filters.product_technology,
        'time_year': filters.time_year,
      }))
    }
    else if (label === 'product_sub_technology') {
      dispatch(getFilterData({
        'product_pet': filters.product_pet,
        'product_technology': filters.product_technology,
        'product_sub_technology': filters.product_sub_technology,
        'time_year': filters.time_year,
      }))
    }
    else if (label === 'product_lifestage') {
      dispatch(getFilterData({
        'product_pet': filters.product_pet,
        'product_technology': filters.product_technology,
        'product_sub_technology': filters.product_sub_technology,
        'product_lifestage': filters.product_lifestage,
        'time_year': filters.time_year
      }))
    }
    else if (label === "product_package") {
      dispatch(
        getFilterData({
          product_pet: filters.product_pet,
          product_technology: filters.product_technology,
          product_package: filters.product_package,
          product_sub_technology: filters.product_sub_technology,
          product_lifestage: filters.product_lifestage,
          'time_year': filters.time_year,
        })
      );
    }
    else if (label === 'time_year') {
      dispatch(getFilterData({
        'product_pet': filters.product_pet,
        'product_technology': filters.product_technology,
        'product_sub_technology': filters.product_sub_technology,
        'product_lifestage': filters.product_lifestage,
        'time_year': filters.time_year,
      }))
    }

  }

  const removeAllBrandTotal = (oldFilters) => {
    return { ...oldFilters, brandList: oldFilters.brandList.filter(val => val !== allBrandTotal) }
  }
  // Changed Url
  const getImageSrc = (brand) => images.filter(image => image.brand === brand)[0]?.imageString
  const getRetailerImageSrc = (retailer) => retailerImages.filter(retailerImage => retailerImage.retailer === retailer)[0]?.imageString

  const applyFilters = () => {
    if (!isReport && isDataFetched) {
      let newBrands = fetchCompetitorBrands(defaultBrand, filters.product_pet.length > 1 || filters.product_pet.length === 0 ? "ALL" : filters.product_pet[0].toUpperCase())
      if (productPetChanged) {
        dispatch(setFilters({
          ...filters,
          brandList: newBrands
        }))
      }
      let applyFilters = { ...filters, brandList: productPetChanged ? newBrands : filters.brandList, default_brand: [defaultBrand] }
      let encodedSearch = encodeURIComponent(JSON.stringify(applyFilters));
      if (window.history.pushState) {
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + encodedSearch;

        window.history.pushState({ path: newurl }, '', newurl);
      }
      dispatch(getWaterFallData(filters));
      dispatch(setTableData([]));
      dispatch(setChannelData([]));
      dispatch(setRetailerData([]));
      dispatch(setImages([]));
      dispatch(setRetailerImages([]));
      let loadBrands = productPetChanged ? newBrands : [...filters.brandList];
      loadBrands = loadBrands.includes(allBrandTotal) ? [...loadBrands] : [allBrandTotal, ...loadBrands]
      let loadChannels = [...filters.channelList];
      let loadRetailers = [...filters.retailerList];
      isBrand && loadBrands && loadBrands.map(val => { dispatch(getTableData(val, applyFilters)); dispatch(getImages(val, applyFilters.product_pet)) })
      isChannel && loadChannels && loadChannels.map(val => { dispatch(getChannelRetailerData(val, null, removeAllBrandTotal(filters))); })
      isRetailer && filters.channel && filters.channel.length && loadRetailers && loadRetailers.map(val => { dispatch(getChannelRetailerData(filters.channel[0], val, removeAllBrandTotal(filters))); dispatch(getRetailerImages(val, filters.product_pet)) })
      setProductPetChanged(false);
      dispatch(setAppliedFilters(filters));
    }
  }

  const [selectCard, setSelectCard] = useState(false);

  const exportCompetitionAnalysis = () => exportVolumeKPIs(exportData, isDefaultBrandMarsBDB, "Volume_Shifting_KPIs");
  const exportVolumeShifting = () => exportWaterfallPercentage(exportData, "Volume_Shifting_Waterfall");
  const exportTableData = () => exportCustomTable(columns.psColsExport, exportData, "Volume_Shifting_Table_Data");
  const exportTableDataWithSwitch = () => exportMarketFinancialsTable(columns.psColsCSLExport, columns.psColsFinancialsExport, exportData, "Volume_Shifting_Table");

  useEffect(() => {
    let tempFilters = { ...filters }
    if (tempFilters.product_pet && (tempFilters.product_pet.length === 0 || tempFilters.product_pet.length === pets.length)) {
      tempFilters['product_pet'] = ['ALL']
    }
    if (tempFilters.product_technology && (tempFilters.product_technology.length === 0 || tempFilters.product_technology.length === techs.length)) {
      tempFilters['product_technology'] = ['ALL']
    }

    if (tempFilters.product_sub_technology && (tempFilters.product_sub_technology.length === 0 || tempFilters.product_sub_technology.length === subTechs.length)) {
      tempFilters['product_sub_technology'] = ['ALL']
    }
    if (tempFilters.product_lifestage && (tempFilters.product_lifestage.length === 0 || tempFilters.product_lifestage.length === lifestage.length)) {
      tempFilters['product_lifestage'] = ['ALL']
    }
    if (tempFilters.time_year && (tempFilters.time_year.length === 0)) {
      tempFilters['time_year'] = ['ALL']
    }
    if (tempFilters.time_period && (tempFilters.time_period.length === 0)) {
      tempFilters['time_period'] = ['ALL']
    }

    const { product_pet, product_technology, product_sub_technology,product_lifestage, time_year, time_period, time_aggregates } = tempFilters;
    dispatch(setExportData({ filters: { product_pet, product_technology, product_sub_technology,product_lifestage, time_year, time_period, time_aggregates } }));
  }, [filters]);

  const formatTableData = (table_data) => {
    const tableDataInStore = JSON.parse(JSON.stringify(table_data))
    const tableData = [];
    tableDataInStore.forEach((data) => {
      tableData.push(data);
      if (data.retailer_data) tableData.push(...data.retailer_data.map((record) => ({ ...record, brand: data.brand })));
    })

    return tableData;
  }

  const prepareTableExport = (table_data) => dispatch(setExportData({ tableData: { data: formatTableData(table_data) } }));

  useEffect(() => {
    if (!table_data) return;
    if (table_data.length === 0) {
      setTableDataExport([]);
      return;
    }
    const tableData = [];
    table_data && table_data.map((val) =>
      val.table_data && val.table_data.map((val1) => {
        const data = formatTableData(table_data.filter(val7 => val7.brand === val.brand)[0]["table_data"]?.filter(val7 => val7.customer_channel === val1.customer_channel).map((record) => ({ ...record, brand: val.brand })))
        tableData.push(...data);
      })
    )

    setTableDataExport(tableData);
  }, [table_data]);

  useEffect(() => {
    isBrand && table_data && dispatch(setExportData({ kpi: { isBrand, isChannel, isRetailer, data: table_data } }));
    isChannel && channel_data && dispatch(setExportData({ kpi: { isBrand, isChannel, isRetailer, data: channel_data } }));
    isRetailer && retailer_data && dispatch(setExportData({ kpi: { isBrand, isChannel, isRetailer, data: retailer_data } }));
  }, [table_data, channel_data, retailer_data, filters.compare_via]);


  useEffect(() => {
    if (filters.compare_via[0] === 'brand') {
      setPsSections(table_data.map(({ psSection }) => psSection));
    }
    else if (filters.compare_via[0] === 'channel') {
      setPsSections(channel_data.map(({ psSection }) => psSection));
    }
    else if (filters.compare_via[0] === 'retailer') {
      setPsSections(retailer_data.map(({ psSection }) => psSection));
    }
  }, [table_data, channel_data, retailer_data]);

  const getPlaceholder = () => isDefaultBrandMarsBDB ? "Select Manufacturer" : "Select Brand";

  const handleEditChannelBrand = (channel, value, index, cards) => {
    const filtersInStore = JSON.parse(JSON.stringify(filters));
    const cardsInStore = JSON.parse((JSON.stringify(cards)));

    let brands = cardsInStore.map(({ brand }) => brand);
    if (isDefaultBrandMarsBDB) brands.splice(0, 1);

    brands[index] = value;
    filtersInStore.brandList = brands;

    dispatch(getChannelRetailerData(channel, null, filtersInStore, channel));
  }

  const handleEditRetailerBrand = (retailer, value, index, cards) => {
    const filtersInStore = JSON.parse(JSON.stringify(filters));
    const cardsInStore = JSON.parse((JSON.stringify(cards)));

    const brands = cardsInStore.map(({ brand }) => brand);
    if (isDefaultBrandMarsBDB) brands.splice(0, 1);

    brands[index] = value;
    filtersInStore.brandList = brands;

    dispatch(getChannelRetailerData(filters.channel[0], retailer, filtersInStore, retailer));
  }

  const getPCols = (mars_brand, totalCheck) => {
    if (brandToggle) return columns.psColsFinancials;
    return mars_brand ? totalCheck ? columns.psColsCSLNoShare : columns.psColsCSL : totalCheck ? columns.psColsNoShare : columns.psCols;
  }

  const menu = (exportData) => (
    <Menu>
      <Menu.Item onClick={exportData}>Export Data</Menu.Item>
    </Menu>
  );

  return (
    <Wrapper hideHeader={props.hideReportHeader} hidePage={isReport} heading={"1"} innerHeading={"7"}>
      <Content className={"site-layout-background" + (isReport && (reportBrands.length === 0 && reportChannels.length === 0 && reportRetailers.length === 0 && components.length === 0) ? " content-none" : " content-body")}>
        <Loader loading={isReport ? props.reportLoader : loading > 0}>
          {!props.hideReportFilters && <> <Row>
            <Col span={24}>
              <div className="refreshed-on">
                <span>Last Refreshed on: {volumeShifting.last_refresh}, Data upto: {volumeShifting.latest_period_available}</span>
                <span>Next Refresh on: {volumeShifting.next_refresh}</span>
              </div>
            </Col>
          </Row>
            <Row align="middle" className="header-filter" gutter={[16, 16]}>
              <Col span={16} >
                <Form name="horizontal_login" className="performance-filter" layout="inline">
                  <Row gutter={12}>
                    <div className="filters-ps">
                      <Col>
                        <label className="label-block">Pet</label>
                        <Select loading={filterLoading} placeholder="All" onMouseLeave={() => getRemainingFilters('product_pet')} mode="multiple" defaultValue={['all']} value={filters.product_pet} onChange={(val) => changeFilter('product_pet', val)} dropdownMatchSelectWidth={false} className="category-first" placement="bottomLeft" maxTagCount="responsive" >
                          <Option value={'all'}>{'All'}</Option>
                          {pets.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>
                      <Col>
                        <label className="label-block">Technology</label>
                        <Select loading={filterLoading} placeholder="All" onMouseLeave={() => getRemainingFilters('product_technology')} mode="multiple" defaultValue={['all']} value={filters.product_technology} onChange={(val) => changeFilter('product_technology', val)} dropdownMatchSelectWidth={false} placement="bottomLeft" className="category-second" maxTagCount="responsive" >
                          <Option value={'all'}>{'All'}</Option>
                          {techs.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>
                      {filters.product_technology.length === 1 && isPackSize.includes(filters.product_technology[0]) && <Col>
                        <label className="label-block">Pack Type</label>
                        <Select loading={filterLoading} placeholder="All" onMouseLeave={() => getRemainingFilters('product_package')} mode="multiple" defaultValue={['all']} value={filters.product_package} onChange={(val) => { changeFilter('product_package', val) }} dropdownMatchSelectWidth={false} placement="bottomLeft" className="category-third" maxTagCount="responsive" >
                          <Option value={'all'}>{'All'}</Option>
                          {packSize[filters.product_technology[0]] && packSize[filters.product_technology[0]].map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>}
                      <Col>
                        <label className="label-block">Sub Technology</label>
                        <Select
                          placeholder="All"
                          mode="multiple"
                          defaultValue={["all"]}
                          dropdownMatchSelectWidth={false}
                          placement="bottomLeft"
                          style={{ width: 110 }}
                          maxTagCount="responsive"
                          value={filters.product_sub_technology}
                          loading={filterLoading}
                          autoClearSearchValue={false}
                          onMouseLeave={() => {
                            getRemainingFilters("product_sub_technology")
                          }}
                          onChange={(val) => {
                            changeFilter("product_sub_technology", val);
                          }}
                        >
                          <Option value={"all"}>{"All"}</Option>
                          {subTechs.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>
                      <Col>
                        <label className="label-block">Life Stage</label>
                        <Select
                          placeholder="All"
                          mode="multiple"
                          defaultValue={["all"]}
                          dropdownMatchSelectWidth={false}
                          placement="bottomLeft"
                          style={{ width: 110 }}
                          maxTagCount="responsive"
                          value={filters.product_lifestage}
                          loading={filterLoading}
                          autoClearSearchValue={false}
                          onMouseLeave={() => {
                            getRemainingFilters("product_lifestage")
                          }}
                          onChange={(val) => {
                            changeFilter("product_lifestage", val);
                          }}
                        >
                          <Option value={"all"}>{"All"}</Option>
                          {lifestage.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>
                      <Col>
                        <label className="label-block">Year</label>
                        <Select loading={filterLoading} onMouseLeave={() => getRemainingFilters('time_year')} value={filters.time_year} defaultValue={years[0]} onChange={(val) => changeFilter('time_year', [val])} className="category-fourth" >
                          {years.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>
                      <Col>
                        <label className="label-block">Period</label>
                        <Select loading={filterLoading} value={filters.time_period} onChange={(val) => changeFilter('time_period', [val])} className="category-fifth" >
                          {periods.map((val) => (
                            <Option value={val}>{val}</Option>
                          ))}
                        </Select>
                      </Col>
                    </div>
                    <Col>
                      <Button type="primary" className="category-button" onClick={applyFilters} disabled={filterLoading}>Apply</Button>
                    </Col>
                  </Row>

                </Form>
              </Col>
              <Col span={8}>
                <Row gutter={20} justify="end" className="report-header">
                  {!newReport &&
                    <>
                      <Col>
                        <Dropdown
                          overlay={() => (
                            <Menu>
                              <Menu.Item onClick={() => { appInsights.trackEvent({ name: 'PPT_FOR_VOLUMESHIFTING' }); pptExportVolume("Volume Shifting Report", psSections, table_data, channel_data, retailer_data, filters, columns.pCols, card, exportData, defaultBrand, columns.psColsRsv, columns.psColsShare, columns.psColsTdp, columns.psColsDollarTdp, columns.psColsFinancialRsv, columns.psColsFinancialGsv, columns.psColsFinancialNSV, columns.psColsFinancialMAC, filters.channel, selectCard); }}>Download as PPT</Menu.Item>
                              <Menu.Item onClick={() => { appInsights.trackEvent({ name: 'EXCEL_FOR_VOLUMESHIFTING' }); exportVSData(isReport, isBrand, isDefaultBrandMarsBDB, components, { ...exportData, allTablesData: tableDataExport }, [{ title: 'Brand', dataIndex: 'brand' }, ...columns.psColsCSLExport], [{ title: 'Brand', dataIndex: 'brand' }, ...columns.psColsFinancialsExport]) }}>Download as Excel</Menu.Item>
                            </Menu>
                          )}
                          className="d-more">
                          <Button icon={<DownloadOutlined />}>Export Report</Button>
                        </Dropdown>
                      </Col>
                      {!isReport && <Col>
                        <Dropdown
                          placement="bottomRight"
                          overlay={() => (
                            <Menu>
                              <Menu.Item onClick={(e) => { appInsights.trackEvent({ name: 'CREATE_SELF_SERVE_REPORT_COMPITITION' }); setNewReport(true); setCreateReport(true); }}>Create self serve Report</Menu.Item>
                              <Menu.Item onClick={(e) => { appInsights.trackEvent({ name: 'ADD_EXISTING_REPORT_COMPITITON' }); setNewReport(true); setCreateReport(false); }}>Add to Existing Report</Menu.Item>
                            </Menu>
                          )}>
                          <Button
                            icon={<PlusCircleOutlined />}
                            type="primary"
                          >
                            Create Report
                          </Button>
                        </Dropdown>
                      </Col>}
                    </>}
                  {newReport && <>
                    <Col>
                      <Button type="secondary" onClick={(e) => { setNewReport(false); setNewReportBrands(["MARS BDB", brandSplit(defaultBrand)]); setNewReportChannels(["FDM excluding Omni"]); setNewReportRetailers([newReportRetailers[0]]); }}>Cancel</Button>
                    </Col>
                    <Col>
                      <Button type="primary" onClick={(e) => { if (newReportBrands.length === 2 && newReportChannels.length === 1 && newReportRetailers.length === 1 && newReportComponents.length === 0) openNotificationWithIcon('error', 'No KPI components selected'); else setOpenSaveReportDialog(true); }} icon={<SaveFilled />}>Save Report</Button>
                    </Col>
                  </>}
                </Row>
              </Col>
            </Row></>}
          {!isReport && <><Row className="sticky-filter">
            <Col span={24}>
              <div className="selected-filters">
                <label>Filters Applied:</label>
                <div className="filter-item">Pet - <span>{Array.isArray(appliedFilters.product_pet) && appliedFilters.product_pet.length > 0 ? appliedFilters.product_pet.join() : 'CAT,DOG'}</span></div>

                <div className="filter-item">Technology - <span>{Array.isArray(appliedFilters.product_technology) && appliedFilters.product_technology.length ? appliedFilters.product_technology.join() : "ALL"}</span></div>

                <div className="filter-item">Sub Technology - <span>{Array.isArray(appliedFilters.product_sub_technology) && appliedFilters.product_sub_technology.length ? appliedFilters.product_sub_technology.join() : "ALL"}</span></div>
                <div className="filter-item">Life Stage - <span>{Array.isArray(appliedFilters.product_lifestage) && appliedFilters.product_lifestage.length ? appliedFilters.product_lifestage.join() : "ALL"}</span></div>

                {appliedFilters.product_technology.length === 1 && isPackSize.includes(appliedFilters.product_technology[0]) && <div className="filter-item">Pack Type - <span>{Array.isArray(appliedFilters.product_package) ? appliedFilters.product_package.join() : "ALL"}</span></div>}
                <div className="filter-item">Year - <span>{appliedFilters.time_year}</span></div>
                <div className="filter-item">Period - <span>{appliedFilters.time_period}</span></div>
              </div>
            </Col>
          </Row>
            <Divider className="divider-margin" /></>}
          {(isReport ? (reportBrands.length !== 0 || reportChannels.length !== 0 || reportRetailers.length !== 0 || components.length !== 0) : true) && <div className="vs-title">
            <div>
              {loading < 1 && <h2>Competition Summary</h2>}
              {!isReport && <Row gutter={10}>
                <Col>
                  <label className="label-block filter-label">Compare Via</label>
                  <Select className="competition-header" placeholder="All" defaultValue={[compareVia.brand]} value={filters.compare_via} onChange={(val) => { changeFilter('compare_via', [val]); setCompareViaLocal(val); }} dropdownMatchSelectWidth={false} maxTagCount="responsive">
                    <Option value={compareVia.retailer}>Retailer</Option>
                    <Option value={compareVia.channel}>Channel</Option>
                    <Option value={compareVia.brand}>All</Option>
                  </Select>
                </Col>
                {filters.compare_via && filters.compare_via.length > 0 && filters.compare_via[0] === compareVia.retailer && (
                  <Col>
                    <label className="label-block filter-label">Channel</label>
                    <Select className="competition-header-second" placeholder="All" value={filters.channel} onChange={(val) => { changeFilter('channel', [val]); setChannelLocal(val); }}>
                      {channels.map((val, index) => (
                        <Option key={index} value={val}>{val}</Option>
                      ))}
                    </Select>
                  </Col>
                )}
                <Col>
                  <label className="label-block filter-label">Time Aggregates</label>
                  <Select className="competition-header" placeholder="All" defaultValue={[timeAggregates.l4w]} value={filters.time_aggregates} onChange={(val) => { changeFilter('time_aggregates', [val]); setCompareViaLocal(val); }} dropdownMatchSelectWidth={false} maxTagCount="responsive">
                    <Option value={timeAggregates.l4w}>L4W</Option>
                    <Option value={timeAggregates.l13w}>L13W</Option>
                    <Option value={timeAggregates.l52w}>L52W</Option>
                    <Option value={timeAggregates.ytd}>YTD</Option>
                  </Select>
                </Col>
              </Row>}
            </div >
            {isBrand && !isReport && <Button id="add" type="link" onClick={() => {
              {
                document.querySelector('#brand-container-' + (table_data.length - 1)).scrollIntoView({
                  behavior: 'smooth'
                })
              };
              dispatch(addTableData({
                brand: "",
                dollar_sum_latest_4w: "0",
                percent_growth_sum_latest_4w: "0",
                dollar_share_sum_latest_4w: "0",
                dollar_sum_latest_4w_ya: "0",
                table_data: [],
                edit: 2,
                id: table_data.length,
              }));
              dispatch(sortTableData());
            }}>
              Add Competitor <PlusCircleOutlined />
            </Button>
            }
            {isChannel && !isReport && channels.length > filters.channelList.length && <Button id="add" type="link" onClick={() => {
              {
                document.querySelector('#brand-container-' + (channel_data.length - 1)).scrollIntoView({
                  behavior: 'smooth'
                })
              };
              dispatch(addChannelData({
                channel: "",
                retailer: "",
                cards: [],
                edit: 2,
                id: channel_data.length,
              }));
              dispatch(sortChannelData());
            }}>
              Add Channel <PlusCircleOutlined />
            </Button>
            }
            {isRetailer && !isReport && channel_retailer[filters.channel] && channel_retailer[filters.channel].length > filters.retailerList.length && <Button id="add" type="link" onClick={() => {
              {
                document.querySelector('#brand-container-' + (retailer_data.length - 1)).scrollIntoView({
                  behavior: 'smooth'
                })
              };
              dispatch(addRetailerData({
                channel: "",
                retailer: "",
                cards: [],
                edit: 2,
                id: retailer_data.length,
              }));
              dispatch(sortRetailerData());
            }}>
              Add Retailer <PlusCircleOutlined />
            </Button>
            }
          </div>}
          {newReport && <Row>
            <Col span={24}>
              <div className="common-callout">
                <p>Select KPI Components you want to add in your report by clicking on the checkboxes</p>
              </div>
            </Col>
          </Row>}
          <div className="dynamic-brands">
            {isReport && reportBrands.length !== 0 && loading < 1 && <h3>Competitor Brands</h3>}
            {isBrand && table_data.length !== 0 && table_data?.map((val, i) => {
              return (
                (!isReport || reportBrands.includes(val.brand)) && <div key={i} className={val.psSection}>
                  <div id={"brand-container-" + i} className={`competition-analysis ${newReport ? 'trackboard-edit' : ''} ${newReport && newReportBrands.includes(val.brand) ? 'trackboard-selected' : ''}`}>
                    {i == 0 && <Dropdown overlay={() => menu(exportCompetitionAnalysis)} trigger={['click']} placement="bottomRight" className="d-more">
                      <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <MoreOutlined />
                      </a>
                    </Dropdown>}
                    <Row align="bottom" wrap="false" className="row-card" gutter="8">
                      <Col>
                        {newReport && !["MARS BDB", brandSplit(defaultBrand)].includes(val.brand) && <Checkbox value={newReportBrands.includes(val.brand)} onChange={(e) => changeNewReportBrand(val.brand, e)} className="d-check"></Checkbox>}
                        <div className="brand-data">
                          <div className="brand-image">
                            <img src={"data:image/png;base64, " + getImageSrc(val.brand)} alt="" />
                          </div>
                          {val.edit !== 0 ? <Select dropdownMatchSelectWidth={false} showSearch={true} placeholder={getPlaceholder()} onChange={(value) => { dispatch(getTableData(value, filters, val.brand)); dispatch(getImages(value, filters.product_pet)); }} className="filter-dataa">
                            {(isDefaultBrandMarsBDB ? manufacturers : brands).filter(item => !filters.brandList.includes(item)).map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select> : <p>{val.brand === "NUTRO CORE" ? "NUTRO" : val.brand}</p>}
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <div className="analysis-data-wrap">
                            <div className="analysis-data">
                              <p className="analysis-data-title">$ Sales mm</p>
                              <p className="analysis-data-value">%CYA</p>
                            </div>
                            {val.brand === allBrandTotal ? <></> : <><Divider />
                              <div className="analysis-data">
                                <p className="analysis-data-title">$ Share</p>
                                <p className="analysis-data-value">%CYA</p>
                              </div>
                            </>}
                          </div>
                        </div>
                      </Col>
                      <Col className="competition-card">
                        <div className="l-card">
                          <h4 className="a-card-title">TOTAL</h4>
                          <div className="analysis-data-wrap">
                            <div className="analysis-data">
                              <p className="analysis-data-title">{val[`dollar_sum_latest_${filters.time_aggregates}`] ? formatNumber(val[`dollar_sum_latest_${filters.time_aggregates}`], 2, true) === valueTypes.NA ? valueTypes.NA : `$ ${formatNumber(val[`dollar_sum_latest_${filters.time_aggregates}`], 1)}` : valueTypes.NA}</p>
                              <p className="analysis-data-value">{val[`dollar_variance_latest_${filters.time_aggregates}`] ? formatNumber(val[`dollar_variance_latest_${filters.time_aggregates}`], 2, true) === valueTypes.NA ? valueTypes.NA : `${formatNumber(val[`dollar_variance_latest_${filters.time_aggregates}`], 1)}%` : valueTypes.NA}</p>
                            </div>
                            {val.brand === allBrandTotal ? <></> : <><Divider />
                              <div className="analysis-data">
                                <p className="analysis-data-title">{val[`dollar_share_latest_${filters.time_aggregates}`] ? formatNumber(val[`dollar_share_latest_${filters.time_aggregates}`], 2, true) === valueTypes.NA ? valueTypes.NA : `${formatNumber(val[`dollar_share_latest_${filters.time_aggregates}`], 1)}%` : valueTypes.NA}</p>
                                <p className="analysis-data-value">{val[`dollar_share_variance_latest_${filters.time_aggregates}`] ? formatNumber(val[`dollar_share_variance_latest_${filters.time_aggregates}`], 2, true) === valueTypes.NA ? valueTypes.NA : `${formatNumber(val[`dollar_share_variance_latest_${filters.time_aggregates}`], 1)} bps` : valueTypes.NA}</p>
                              </div></>}
                          </div>
                        </div>
                      </Col>
                      {val.table_data.map((val1, i1) => {
                        return (
                          <Col key={i1} className="competition-card">
                            <div className="l-card">
                              <h4 className="a-card-title">{val1.customer_channel}</h4>
                              <div className={`analysis-data-wrap ${selectCard && card[0] === val.brand && card[1] === val1.customer_channel ? 'active' : ''}`} onClick={() => { setSelectCard(!selectCard); if (selectCard) setBrandToggle(false); setCard([val.brand, val1.customer_channel]); prepareTableExport(table_data.filter(val7 => val7.brand === val.brand)[0]["table_data"].filter(val7 => val7.customer_channel === val1.customer_channel)); }}>
                                {val1.kpi && <div className="kpi-status bg-success">
                                  <p>KPI up by 2.7%</p>
                                </div>}
                                <div className="analysis-data">
                                  <p className="analysis-data-title">{val1[`dollar_sum_latest_${filters.time_aggregates}`] ? formatNumber(val1[`dollar_sum_latest_${filters.time_aggregates}`].abs, 2, true) === valueTypes.NA ? valueTypes.NA : `$ ${formatNumber(val1[`dollar_sum_latest_${filters.time_aggregates}`].abs, 1)}` : valueTypes.NA}</p>
                                  <p className={"analysis-data-value " + (val1[`dollar_sum_latest_${filters.time_aggregates}`] ? ((Number(val1[`dollar_sum_latest_${filters.time_aggregates}`].change)) > 0 ? "text-success" : "text-danger") : "text-danger")}>{val1[`dollar_sum_latest_${filters.time_aggregates}`] ? formatNumber(val1[`dollar_sum_latest_${filters.time_aggregates}`].change, 2, true) === valueTypes.NA ? valueTypes.NA : `${formatNumber(val1[`dollar_sum_latest_${filters.time_aggregates}`].change, 1)}%` : valueTypes.NA}</p>
                                </div>
                                {val.brand === allBrandTotal ? <></> : <><Divider />
                                  <div className="analysis-data">
                                    <p className="analysis-data-title">{val1[`dollar_share_latest_${filters.time_aggregates}`] ? formatNumber(val1[`dollar_share_latest_${filters.time_aggregates}`].abs, 2, true) === valueTypes.NA ? valueTypes.NA : `${formatNumber(val1[`dollar_share_latest_${filters.time_aggregates}`].abs, 1)}%` : valueTypes.NA}</p>
                                    <p className={"analysis-data-value " + (val1[`dollar_share_latest_${filters.time_aggregates}`] ? ((Number(val1[`dollar_share_latest_${filters.time_aggregates}`].change)) > 0 ? "text-success" : "text-danger") : "text-danger")}>{val1[`dollar_share_latest_${filters.time_aggregates}`] ? formatNumber(val1[`dollar_share_latest_${filters.time_aggregates}`].change, 2, true) === valueTypes.NA ? valueTypes.NA : `${formatNumber(val1[`dollar_share_latest_${filters.time_aggregates}`].change, 1)} bps` : valueTypes.NA}</p>
                                  </div></>}
                              </div>
                            </div>
                          </Col>
                        )
                      })}
                    </Row>
                    {
                      selectCard && card[0] === val.brand &&
                      <div div className="customer-channel-data">
                        <CustomTable rowKey="customer_channel" childrenColumnName="retailer_data" hideFilter={true} expand={true} data={table_data && table_data.filter(val7 => val7.brand === val.brand)[0] && table_data.filter(val7 => val7.brand === val.brand)[0]["table_data"].filter(val7 => val7.customer_channel === card[1])} heading={false}
                          rowSelection={false}
                          columns={getPCols(val.mars_brand, val.brand === allBrandTotal)}
                          brandToggle={brandToggle}
                          setBrandToggle={val.mars_brand ? setBrandToggle : null}
                          exportData={val.mars_brand ? exportTableDataWithSwitch : exportTableData}
                        />
                      </div>
                    }
                    {!["MARS BDB", brandSplit(defaultBrand)].includes(val.brand) && !isReport && val.brand !== "TOTAL CATEGORY"&&
                      <div className="competition-analysis-footer">
                        {val.edit === 0 && <Button onClick={() => {

                          let newBrands = [...filters.brandList];
                          newBrands = newBrands.filter(val6 => val6 !== val.brand);
                          dispatch(setFilters({
                            ...filters,
                            brandList: newBrands
                          }))

                          let editTableData = table_data.map(val3 => {
                            if (val3.brand === val.brand) {
                              return {
                                ...val3,
                                edit: 1
                              }
                            }
                            else {
                              return { ...val3 }
                            }
                          })
                          dispatch(setTableData(editTableData));
                        }}>Edit <EditOutlined /></Button>}

                        {val.edit === 1 && !isReport && <Button onClick={() => {

                          let newBrands = [...filters.brandList];
                          newBrands.push(val.brand);
                          dispatch(setFilters({
                            ...filters,
                            brandList: newBrands
                          }))

                          let editTableData = table_data.map(val3 => {
                            if (val3.brand === val.brand) {
                              return {
                                ...val3,
                                edit: 0,
                              }
                            }
                            else {
                              return { ...val3 }
                            }
                          })
                          dispatch(setTableData(editTableData));
                        }}>Cancel</Button>}

                        {!isReport && <Button onClick={() => {

                          let newBrands = [...filters.brandList];
                          newBrands = newBrands.filter(val5 => val5 !== val.brand);
                          dispatch(setFilters({
                            ...filters,
                            brandList: newBrands
                          }))

                          dispatch(replaceTableData([val.brand, -1]));
                        }}>Delete <DeleteOutlined /></Button>}
                      </div>}
                  </div>
                </div>
              )
            })}
            {isReport && reportChannels.length !== 0 && loading < 1 && <h3>Competitor Channels</h3>}
            {isChannel && channel_data.length > 0 && channel_data?.map((val, i) => {
              return (
                (!isReport || reportChannels.includes(val.channel)) && <div key={i} className={val.psSection}>
                  <div id={"brand-container-" + i} className={`competition-analysis ${newReport ? 'trackboard-edit' : ''} ${newReport && newReportChannels.includes(val.channel) ? 'trackboard-selected' : ''}`}>
                    {i == 0 && <Dropdown overlay={() => menu(exportCompetitionAnalysis)} trigger={['click']} placement="bottomRight" className="d-more">
                      <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <MoreOutlined />
                      </a>
                    </Dropdown>}
                    <Row align="bottom" wrap="false" className="row-card" gutter="8">
                      <Col>
                        {newReport && < Checkbox Checkbox value={newReportChannels.includes(val.channel)} onChange={(e) => changeNewReportChannel(val.channel, e)} className="d-check"></Checkbox>}
                        <div className="brand-data">
                          {val.edit === 2 ? <Select dropdownMatchSelectWidth={false} showSearch={true} placeholder="Select Channel" onChange={(value) => { dispatch(getChannelRetailerData(value, null, removeAllBrandTotal(filters), val.channel)); }} className="filter-dataa">
                            {channels.filter(item => !filters.channelList.includes(item)).map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select> : <p>{val.channel}</p>}
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <div className="analysis-data-wrap">
                            <div className="analysis-data">
                              <p className="analysis-data-title">$ Sales mm</p>
                              <p className="analysis-data-value">%CYA</p>
                            </div>
                            <Divider />
                            <div className="analysis-data">
                              <p className="analysis-data-title">$ Share</p>
                              <p className="analysis-data-value">%CYA</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      {val.cards.map((val1, i1) => {
                        if (i1 > 6) return;
                        return (
                          <Col key={i1} className="competition-card">
                            <div className="l-card">
                              <div className="a-card-title-container">
                                {val.edit !== 0 && i1 > 1 ? <Select showSearch={true} placeholder={getPlaceholder()} onChange={(value) => handleEditChannelBrand(val.channel, value, isDefaultBrandMarsBDB ? i1 - 1 : i1, val.cards)} className="show-search" dropdownMatchSelectWidth={false}>
                                  {(isDefaultBrandMarsBDB ? manufacturers : brands).filter(item => !val.cards.map((card) => card.brand).includes(item)).map((val) => (
                                    <Option value={val}>{val}</Option>
                                  ))}
                                </Select > : <h4 className="a-card-title">{val1.brand}</h4>}
                              </div>
                              <div className="analysis-data-wrap">
                                <div className="analysis-data">
                                  <p className="analysis-data-title">{val1[`dollar_sum_latest_${filters.time_aggregates}`] ? formatNumber(val1[`dollar_sum_latest_${filters.time_aggregates}`], 2, true) === valueTypes.NA ? valueTypes.NA : `$ ${formatNumber(val1[`dollar_sum_latest_${filters.time_aggregates}`], 1)}` : valueTypes.NA}</p>
                                  <p className={"analysis-data-value " + (val1[`dollar_variance_latest_${filters.time_aggregates}`] ? ((Number(val1[`dollar_variance_latest_${filters.time_aggregates}`])) > 0 ? "text-success" : "text-danger") : "text-danger")}>{val1[`dollar_variance_latest_${filters.time_aggregates}`] ? formatNumber(val1[`dollar_variance_latest_${filters.time_aggregates}`], 2, true) === valueTypes.NA ? valueTypes.NA : `${formatNumber(val1[`dollar_variance_latest_${filters.time_aggregates}`], 1)}%` : valueTypes.NA}</p>
                                </div>
                                {(val1.brand !== "TOTAL" || !isDefaultBrandMarsBDB) && <>
                                  <Divider />
                                  <div className="analysis-data">
                                    <p className="analysis-data-title">{val1[`dollar_share_latest_${filters.time_aggregates}`] ? formatNumber(val1[`dollar_share_latest_${filters.time_aggregates}`], 2, true) === valueTypes.NA ? valueTypes.NA : `${formatNumber(val1[`dollar_share_latest_${filters.time_aggregates}`], 1)}%` : valueTypes.NA}</p>
                                    <p className={"analysis-data-value " + (val1[`dollar_share_variance_latest_${filters.time_aggregates}`] ? ((Number(val1[`dollar_share_variance_latest_${filters.time_aggregates}`])) > 0 ? "text-success" : "text-danger") : "text-danger")}>{val1[`dollar_share_variance_latest_${filters.time_aggregates}`] ? formatNumber(val1[`dollar_share_variance_latest_${filters.time_aggregates}`], 2, true) === valueTypes.NA ? valueTypes.NA : `${formatNumber(val1[`dollar_share_variance_latest_${filters.time_aggregates}`], 1)} bps` : valueTypes.NA}</p>
                                  </div>
                                </>}
                              </div>
                            </div >
                          </Col >
                        )
                      })}
                    </Row >
                    {
                      val.channel.toUpperCase() !== "TOTAL MARKET" && !isReport &&
                      <div className="competition-analysis-footer">
                        {val.edit === 0 && <Button onClick={() => {

                          let newChannels = [...filters.channelList];
                          newChannels = newChannels.filter(val6 => val6 !== val.channel);
                          dispatch(setFilters({
                            ...filters,
                            channelList: newChannels
                          }))

                          let editChannelData = channel_data.map(val3 => {
                            if (val3.channel === val.channel) {
                              return {
                                ...val3,
                                edit: 1
                              }
                            }
                            else {
                              return { ...val3 }
                            }
                          })
                          dispatch(setChannelData(editChannelData));
                        }}>Edit <EditOutlined /></Button>}

                        {val.edit === 1 && !isReport && <Button onClick={() => {

                          let newChannels = [...filters.channelList];
                          newChannels.push(val.channel);
                          dispatch(setFilters({
                            ...filters,
                            channelList: newChannels
                          }))

                          let editChannelData = channel_data.map(val3 => {
                            if (val3.channel === val.channel) {
                              return {
                                ...val3,
                                edit: 0,
                              }
                            }
                            else {
                              return { ...val3 }
                            }
                          })
                          dispatch(setChannelData(editChannelData));
                        }}>Cancel</Button>}

                        {!isReport && <Button onClick={() => {

                          let newChannels = [...filters.channelList];
                          newChannels = newChannels.filter(val5 => val5 !== val.channel);
                          dispatch(setFilters({
                            ...filters,
                            channelList: newChannels
                          }))

                          dispatch(replaceChannelData([val.channel, -1]));
                        }}>Delete <DeleteOutlined /></Button>}
                      </div>
                    }
                  </div >
                </div >
              )
            })}
            {isReport && reportRetailers.length !== 0 && loading < 1 && <h3>Competitor Retailers</h3>}
            {isRetailer && retailer_data.length > 0 && retailer_data?.map((val, i) => {
              return (
                (!isReport || reportRetailers.includes(val.retailer)) && <div key={i} className={val.psSection}>
                  <div id={"brand-container-" + i} className={`competition-analysis ${newReport ? 'trackboard-edit' : ''} ${newReport && newReportRetailers.includes(val.retailer) ? 'trackboard-selected' : ''}`}>
                    {isReport && <span>{val.channel}</span>}
                    {i == 0 && <Dropdown overlay={() => menu(exportCompetitionAnalysis)} trigger={['click']} placement="bottomRight" className="d-more">
                      <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <MoreOutlined />
                      </a>
                    </Dropdown>}
                    <Row align="bottom" wrap="false" className="row-card" gutter="8">
                      <Col>
                        {newReport && < Checkbox Checkbox value={newReportRetailers.includes(val.retailer)} onChange={(e) => changeNewReportRetailer(val.retailer, e)} className="d-check"></Checkbox>}
                        <div className="brand-data">
                          <div className="brand-image">
                            <img src={"data:image/png;base64, " + getRetailerImageSrc(val.retailer)} alt="" />
                          </div>
                          {val.edit === 2 ? <Select dropdownMatchSelectWidth={false} showSearch={true} placeholder="Select Retailer" onChange={(value) => { dispatch(getChannelRetailerData(filters.channel[0], value, removeAllBrandTotal(filters), val.retailer)); dispatch(getRetailerImages(value, filters.product_pet)); }} className="filter-dataa">
                            {channel_retailer[filters.channel].filter(item => !filters.retailerList.includes(item)).map((val) => (
                              <Option value={val}>{val}</Option>
                            ))}
                          </Select> : <p>{val.retailer}</p>}
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <div className="analysis-data-wrap">
                            <div className="analysis-data">
                              <p className="analysis-data-title">$ Sales mm</p>
                              <p className="analysis-data-value">%CYA</p>
                            </div>
                            <Divider />
                            <div className="analysis-data">
                              <p className="analysis-data-title">$ Share</p>
                              <p className="analysis-data-value">%CYA</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      {val.cards.map((val1, i1) => {
                        if (i1 > 6) return;
                        return (
                          <Col key={i1} className="competition-card">
                            <div className="l-card">
                              <div className="a-card-title-container">
                                {val.edit !== 0 && i1 > 1 ? <Select showSearch={true} placeholder={getPlaceholder()} onChange={(value) => handleEditRetailerBrand(val.retailer, value, isDefaultBrandMarsBDB ? i1 - 1 : i1, val.cards)} className="show-search" dropdownMatchSelectWidth={false}>
                                  {(isDefaultBrandMarsBDB ? manufacturers : brands).filter(item => !val.cards.map((card) => card.brand).includes(item)).map((val) => (
                                    <Option value={val}>{val}</Option>
                                  ))}
                                </Select > : <h4 className="a-card-title">{val1.brand}</h4>}
                              </div>
                              <div className="analysis-data-wrap">
                                <div className="analysis-data">
                                  <p className="analysis-data-title">{val1[`dollar_sum_latest_${filters.time_aggregates}`] ? formatNumber(val1[`dollar_sum_latest_${filters.time_aggregates}`], 2, true) === valueTypes.NA ? valueTypes.NA : `$ ${formatNumber(val1[`dollar_sum_latest_${filters.time_aggregates}`], 1)}` : valueTypes.NA}</p>
                                  <p className={"analysis-data-value " + (val1[`dollar_variance_latest_${filters.time_aggregates}`] ? ((Number(val1[`dollar_variance_latest_${filters.time_aggregates}`])) > 0 ? "text-success" : "text-danger") : "text-danger")}>{val1[`dollar_variance_latest_${filters.time_aggregates}`] ? formatNumber(val1[`dollar_variance_latest_${filters.time_aggregates}`], 2, true) === valueTypes.NA ? valueTypes.NA : `${formatNumber(val1[`dollar_variance_latest_${filters.time_aggregates}`], 1)}%` : valueTypes.NA}</p>
                                </div>
                                {(val1.brand !== "TOTAL" || !isDefaultBrandMarsBDB) && <>
                                  <Divider />
                                  <div className="analysis-data">
                                    <p className="analysis-data-title">{val1[`dollar_share_latest_${filters.time_aggregates}`] ? formatNumber(val1[`dollar_share_latest_${filters.time_aggregates}`], 2, true) === valueTypes.NA ? valueTypes.NA : `${formatNumber(val1[`dollar_share_latest_${filters.time_aggregates}`], 1)}%` : valueTypes.NA}</p>
                                    <p className={"analysis-data-value " + (val1[`dollar_share_variance_latest_${filters.time_aggregates}`] ? ((Number(val1[`dollar_share_variance_latest_${filters.time_aggregates}`])) > 0 ? "text-success" : "text-danger") : "text-danger")}>{val1[`dollar_share_variance_latest_${filters.time_aggregates}`] ? formatNumber(val1[`dollar_share_variance_latest_${filters.time_aggregates}`], 2, true) === valueTypes.NA ? valueTypes.NA : `${formatNumber(val1[`dollar_share_variance_latest_${filters.time_aggregates}`], 1)} bps` : valueTypes.NA}</p>
                                  </div>
                                </>}
                              </div>
                            </div >
                          </Col >
                        )
                      })}
                    </Row >
                    {
                      <div className="competition-analysis-footer">
                        {val.edit === 0 && !isReport && <Button onClick={() => {
                          let editRetailerData = retailer_data.map(val3 => {
                            if (val3.retailer === val.retailer) {
                              return {
                                ...val3,
                                edit: 1
                              }
                            }
                            else {
                              return { ...val3 }
                            }
                          })
                          dispatch(setRetailerData(editRetailerData));
                        }}>Edit <EditOutlined /></Button>}

                        {val.edit === 1 && !isReport && <Button onClick={() => {
                          let editRetailerData = retailer_data.map(val3 => {
                            if (val3.retailer === val.retailer) {
                              return {
                                ...val3,
                                edit: 0,
                              }
                            }
                            else {
                              return { ...val3 }
                            }
                          })
                          dispatch(setRetailerData(editRetailerData));
                        }}>Cancel</Button>}

                        {!isReport && <Button onClick={() => {

                          let newRetailers = [...filters.retailerList];
                          newRetailers = newRetailers.filter(val5 => val5 !== val.retailer);
                          dispatch(setFilters({
                            ...filters,
                            retailerList: newRetailers
                          }))

                          dispatch(replaceRetailerData([val.retailer, -1]));
                        }}>Delete <DeleteOutlined /></Button>}
                      </div>
                    }
                  </div >
                </div >
              )
            })
            }

          </div >


          {(!isReport || components.includes('1')) && defaultBrand.toUpperCase() !== allBrands && <Divider className="divider-data" />
          }
          {
            (!isReport || components.includes('1')) && defaultBrand.toUpperCase() !== allBrands && <div className="second-section-ps">
              <div className="volume-shifing-container">
                <WaterfallChart checkbox={newReport} value={newReportComponents.includes('1')} hideTooltip={true} onChange={(e) => changeNewReportComponent('1', e)} heading="Volume Shifting [Eq. Lbs]" options={Object.values(wData).map(val => Object.values(val)[0])} filtr_quarter={['Q1', 'Q2', 'Q3', 'Q4']} data={waterfallData["absoluteData"] && waterfallData["absoluteData"]} percentData={waterfallData["percentData"] && waterfallData["percentData"]} exportData={exportVolumeShifting} />
              </div>
            </div>
          }
          <NewReportForm setNewReport={setNewReport} createReport={createReport} newReportComponents={newReportComponents} newReportBrands={newReportBrands} newReportChannel={filters.channel} newReportChannels={newReportChannels} newReportRetailers={newReportRetailers} defaultReportBrands={["MARS BDB", brandSplit(defaultBrand)]} defaultReportChannels={newReportChannels[0]} defaultReportRetailers={newReportRetailers[0]} setNewReportComponents={setNewReportComponents} setNewReportBrands={setNewReportBrands} setNewReportChannels={setNewReportChannels} setNewReportRetailers={setNewReportRetailers} reportType={"competition-summary"} openSaveReportDialog={openSaveReportDialog} setOpenSaveReportDialog={setOpenSaveReportDialog} />
        </Loader >
      </Content >
    </Wrapper >
  );
}

export default withRouter(VolumeShifting);
