import React from "react";
import { CaretUpFilled, CaretDownFilled } from "@ant-design/icons";
import { formatNumberExcel, numFmt } from "../../utilities/exports/excel";
import { formatNumber, hasValue, valueTypes } from "../../utilities/helper";
import "../be-available/be-available.scss";

// render for UI
const renderFunction = (val, rec) => {
  let isZero = true;
  let dec = 1;

  if (val) {
    if (
      formatNumber(val.abs, 1, true) === valueTypes.NA &&
      formatNumber(val.abs, 2, true) !== valueTypes.NA
    ) {
      isZero = false;
      dec = 2;
    }
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs, dec)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance, dec)}
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance, dec)}
          </div>
        )}
      </div>
    );
};

const renderPercentFunction = (val, rec) => {
  let isZero = true;
  let dec = 1;

  if (val) {
    if (
      formatNumber(val.abs, 1, true) === valueTypes.NA &&
      formatNumber(val.abs, 2, true) !== valueTypes.NA
    ) {
      isZero = false;
      dec = 2;
    }
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs, dec)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance, dec)}%
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance, dec)}%
          </div>
        )}
      </div>
    );
};

const renderDollarFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">${formatNumber(val.abs)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}
          </div>
        )}
      </div>
    );
};

const renderDollarPercentFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">${formatNumber(val.abs)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}%
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}%
          </div>
        )}
      </div>
    );
};

const renderWholeNumberPercentFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs, 0)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}%
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}%
          </div>
        )}
      </div>
    );
};

const renderPercentageFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs)}%</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}
          </div>
        )}
      </div>
    );
};

const renderPointsFunction = (val, rec) => {
  let isZero = true;
  let dec = 1;

  if (val) {
    if (
      formatNumber(val.abs, 1, true) === valueTypes.NA &&
      formatNumber(val.abs, 2, true) !== valueTypes.NA
    ) {
      isZero = false;
      dec = 2;
    }
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs, dec)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance, dec)}pts
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance, dec)}pts
          </div>
        )}
      </div>
    );
};

const renderDollarPointsFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">${formatNumber(val.abs)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}pts
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}pts
          </div>
        )}
      </div>
    );
};

const renderPercentagePointsFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs)}%</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}pts
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}pts
          </div>
        )}
      </div>
    );
};

const renderPPTFunction = (val) =>
  `$${formatNumber(val && val.abs)}\n${formatNumber(val && val.variance)}%`;

const renderPPTDollarPercentageFunction = (val) =>
  `$${formatNumber(val && val.abs)}\n${formatNumber(val && val.variance)}%`;

const renderPPTWholeNumberPercentageFunction = (val) =>
  `${formatNumber(val && val.abs, 0)}\n${formatNumber(val && val.variance)}%`;

const renderPPTPercentagePtsFunction = (val) =>
  `${formatNumber(val && val.abs)}%\n${formatNumber(val && val.variance)}pts`;

// renderText for excel export
const renderTextFunction = (val, rec) => ({
  value: val,
  numFmt: numFmt.text,
});

const renderTextDollarFunction = (val, rec) => ({
  value: formatNumberExcel(val && val.abs),
  numFmt: numFmt.dollar,
});

const renderTextWholeNumberFunction = (val, rec) => ({
  value: val && val.abs,
  numFmt: numFmt.wholeNumber,
});

const renderTextVariancePercentageFunction = (val, rec) => ({
  value: formatNumberExcel(val && val.variance, true),
  numFmt: numFmt.percentage,
});

const renderTextAbsPercentageFunction = (val, rec) => ({
  value: formatNumberExcel(val && val.abs, true),
  numFmt: numFmt.percentage,
});

const renderTextVarianceNumberFunction = (val, rec) => ({
  value: formatNumberExcel(val && val.variance),
  numFmt: numFmt.number,
});

const renderTextAbsNumber = (val, rec) => ({
  value: formatNumberExcel(val && val.abs),
  numFmt: numFmt.number,
});

const renderChild = (val, rec, title) => {
  return renderPercentageFunction(val);
};

const beMacroCols = [
  {
    title: "", // 0
    children: [
      {
        title: "Sub Brand",
        dataIndex: "table_key",
        dataIndexPPT: "table_key",
        key: "table_key",
        width: 150,
        // fixed: 'left',
      },
    ],
  },
  {
    title: "$ Sales", // 1
  },
  {
    title: "$ Share", // 2
  },
  {
    title: "Average Rating", // 3
  },
  {
    title: "Average # of Reviews", // 4
  },
  {
    title: "Average # of Reviews per Item", // 5
  },
  {
    title: "TOTAL PET", // 6
    children: [
      {
        title: "Top Tertile Average Rating",
      },
      {
        title: "Top Tertile Average # of Reviews",
      }
    ]
  },
];

const beMacroColsExport = [
  {
    title: "Sub Brand",
    dataIndex: "table_key",
    key: "table_key",
  },
];

const beMicroCols = [
  {
    title: "", // 0
    children: [
      {
        title: "Description",
        dataIndex: "table_key",
        dataIndexPPT: "table_key",
        key: "table_key",
        width: 300,
        align: "left",
        // fixed: 'left',
      },
    ],
  },
  {
    title: "", // 0
    children: [
      {
        title: "UPC",
        dataIndex: "product_upc",
        dataIndexPPT: "product_upc",
        key: "product_upc",
        width: 120,
        className: "td-border-left",
        // fixed: 'left',
      },
    ],
  },
  {
    title: "$ Sales", // 1
  },
  {
    title: "$ Share", // 2
  },
  {
    title: "Average Rating", // 3
  },
  {
    title: "Average # of Reviews", // 4
  },
  {
    title: "Rating Percentile", // 5
  },
  {
    title: "Review Percentile", // 6
  },
  {
    title: "TOTAL PET", // 7
    children: [
      {
        title: "Top Tertile Average Rating",
      },
      {
        title: "Top Tertile Average # of Reviews",
      }
    ]
  },
];

const beMicroColsExport = [
  {
    title: "Description",
    dataIndex: "table_key",
    key: "table_key",
  },
  {
    title: "UPC",
    dataIndex: "product_upc",
    key: "product_upc",
    renderText: renderTextFunction,
  },
];

export default {
  beMacroCols,
  beMacroColsExport,
  beMicroCols,
  beMicroColsExport,
  renderFunction,
  renderDollarFunction,
  renderPercentageFunction,
  renderPointsFunction,
  renderDollarPointsFunction,
  renderPercentagePointsFunction,
  renderPercentFunction,
  renderDollarPercentFunction,
  renderWholeNumberPercentFunction,

  renderTextDollarFunction,
  renderTextVariancePercentageFunction,
  renderTextWholeNumberFunction,
  renderTextAbsPercentageFunction,
  renderTextVarianceNumberFunction,
  renderTextAbsNumber,

  renderPPTDollarPercentageFunction,
  renderPPTWholeNumberPercentageFunction,
  renderPPTPercentagePtsFunction,
  renderPPTFunction,
};
