import React from 'react';
import { Col, Row } from 'antd';
import './nudge-card.scss';

export default function NudgeCard(props) {
    return (
        <div className="inner-card-alerts">
            <div className="card-symbol">
                {props.rate === -1 ? <></> : props.rate == 1 ? <img src={props.increase} style={{ width: "20px" }} /> : <img src={props.decrease} style={{ width: "20px" }} />}
            </div>
            <div className="card-title">{props.heading}</div>
        </div>
    )
}