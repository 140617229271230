import axios from 'axios';
import { msalInstance, request } from '../../utilities/auth/msalConfig';
let numberOfAjaxCAllPending = 0;

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    numberOfAjaxCAllPending++;
    // show loader
    return config;
}, function (error) {
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    numberOfAjaxCAllPending--;

    if (numberOfAjaxCAllPending === 0) {
        //hide loader
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});


export async function AuthorizationAxios(url, method, msalAccount) {
    try {
        let headers = {}
        const param = { ...request, ...{ account: msalAccount } };
        const token = await msalInstance.acquireTokenSilent(param);
        if (token && token.accessToken) headers.Authorization = `Bearer ${token.accessToken}`;

        return axios({
            url,
            method,
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
        });
    } catch (error) {
        console.log("Authorization Axios Error", error);

    }
}

export async function CustomAxiosTimeout(url, method, timeoutSeconds = 30, body, arrayBuffer = false, headers = {}) {
    try {
        const param = { ...request, ...{ account: msalInstance.getAllAccounts()[0] } };
        const token = await msalInstance.acquireTokenSilent(param);
        if (token && token.accessToken) headers.Authorization = `Bearer ${token.accessToken}`;

        return arrayBuffer ? axios({
            url,
            method,
            data: body,
            timeout: 1000 * timeoutSeconds,
            headers: {
                'Content-Type': 'application/zip',
                ...headers
            },
            responseType: 'arraybuffer'
        }) : axios({
            url,
            method,
            data: body,
            timeout: 1000 * timeoutSeconds,
            headers: {
                'Content-Type': 'application/json',
                ...headers
            },
        });
    } catch (error) {
        console.log("Timeout Axios Error", error);

    }
}

export async function CustomAxiosPlane(url, method, body) {
    try {
        return axios({
            url,
            method,
            data: body,
        });
    } catch (error) {
        console.log("Custom Axios Error", error);

    }
}

async function CustomAxios(url, method, body, arrayBuffer = false, headers = {}) {
    try {
        const param = { ...request, ...{ account: msalInstance.getAllAccounts()[0] } };
        console.log("param", param);
        const token = await msalInstance.acquireTokenSilent(param);
        if (token && token.accessToken) headers.Authorization = `Bearer ${token.accessToken}`;

        return arrayBuffer ? axios({
            url,
            method,
            data: body,
            headers: {
                'Content-Type': 'application/zip',
                ...headers
            },
            responseType: 'arraybuffer'
        }) : axios({
            url,
            method,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                ...headers
            },
        });
    } catch (error) {
        console.log("Custom Axios Error", error);

    }
}

export async function CustomAxiosDE(url, method, body, arrayBuffer = false, headers = {}) {
    try {
        return arrayBuffer ? axios({
            url,
            method,
            data: body,
            headers: {
                'Content-Type': 'application/zip',
                ...headers
            },
            responseType: 'arraybuffer'
        }) : axios({
            url,
            method,
            data: body,
            headers: {
                'Content-Type': 'application/json',
                ...headers
            },
        });
    } catch (error) {
        console.log("Custom Axios Error", error);

    }
}

export default CustomAxios;
