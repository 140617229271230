import React from "react";
import ExcelJS from "exceljs/dist/es5/exceljs.browser.js";
import pptxgen from "pptxgenjs";
import html2canvas from 'html2canvas';
import * as FileSaver from "file-saver";
import { Menu, notification } from "antd";
import moment from 'moment'
import { paths } from "./paths";

/**
 * Utilities & Helper Functions
 * These are the reusable helper functions which are being used across the source code in many different files
 * They contain the buisness logic and some constants to provide the short and concise code base to other parts of the app. 
 */

// This function returns a dropdown menu for exporting data
export const menu = (exportData) => (
  <Menu>
    <Menu.Item key="0" onClick={exportData}>Export Data</Menu.Item>
  </Menu>
);

export const allBrands = "ALL BRANDS";
export const marsPN = "MARS PN";
export const valueTypes = {
  NA: "NA",
  nil: 0
}

export const itemsListed = "# of items listed";

export const userActions = {
  added: "user_added_email",
  requested: "user_requested_email"
}




export const colors = {
  success: "#00c853",
  warning: "#ffdc00",
  danger: "#f0675e",
  info: "#80EBDC"
};

export const reviewTypes = {
  approved: "approved",
  declined: "declined",
  pending: "pending",
  accurate: "accurate",
}

export const symbols = {
  dollar: "$",
  percent: "%",
  points: "bps",
  pts: "pts"
};

export const signNames = {
  NEGATIVE: "negative",
  POSITIVE: "positive",
}

export const signs = {
  [signNames.POSITIVE]: "+",
  [signNames.NEGATIVE]: "-",
}

export const noHeaderPaths = [paths.searchTerm, paths.portfolioSummary];

// Function to add a symbol to a value
export const addSymbol = (value, symbol) => {
  if (!symbol) return value;
  if (symbol === symbols.dollar) return `${symbol} ${value}`;
  if (symbol === symbols.percent) return `${value}${symbol}`;
  if (symbol === symbols.points) return `${value} ${symbol}`;
  if (symbol === symbols.pts) return `${value}${symbol}`;
}

// Function to check if a brand is a sub-brand
export const isSubBrand = (brand) => {
  return brand.split(":").length === 2
}

// Function to split the brand and return the sub-brand
export const brandSplit = (brand) => {
  return brand.split(":").length === 2 ? brand.split(":")[1] : brand
}

export const brandSplitMain = (brand) => {
  return brand.split(":")[0];
}

// Function to set window history
export const setWindowHistory = (encodedSearch) => {
  if (window.history.pushState) {
    let newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;

    if (encodedSearch) newurl += `?${encodedSearch}`;
    window.history.pushState({ path: newurl }, "", newurl);
  }
}

// Function to check if a value is not null or undefined
export const hasValue = (value) => value !== null && value !== undefined;

// Function to show a notification with an icon
export const openNotificationWithIcon = (type, message, duration = 2) => {
  notification[type]({
    message,
    duration: type === "error" ? 0 : duration,
    // style: {
    //   width: 400,
    //   marginRight: (window.innerWidth - 400) / 2,
    // },
    placement: "top"
  });
};

// Function to format decimals
export const formatDecimals = (val, decimals = 1) => {
  let num = Number(val);
  if (isNaN(num)) return val;

  return Number(num.toFixed(decimals));
}

// Function to format numbers with symbols
export const formatNumber = (val, decimals = 1, applyNA = false) => {
  let num = Number(val)
  if (isNaN(num)) return val
  if (applyNA && num === valueTypes.nil) return valueTypes.NA;
  if (Number(num.toFixed(decimals)) === valueTypes.nil) return num.toFixed(2);

  // If the number is too large, convert it to the appropriate unit (e.g., B, M, K, T) and format it.
  if (num >= 1e12) {
    return Number(num / 1e12).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'T';
  }
  if (num >= 1e9) {
    return Number(num / 1e9).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'B';
  }
  if (num >= 1e6) {
    return Number(num / 1e6).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'M';
  }
  else if (num >= 1e3) {
    return Number(num / 1e3).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'K';
  }
  else if (num <= -1e12) {
    return Number(num / 1e12).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'T';
  }
  else if (num <= -1e9) {
    return Number(num / 1e9).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'B';
  }
  else if (num <= -1e6) {
    return Number(num / 1e6).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'M';
  }
  else if (num <= -1e3) {
    return Number(num / 1e3).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'K';
  }
  else return Number(num).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// Function to format numbers in an array of objects with specified decimals.
export const formatNumberData = (array, decimals = 1) => {
  if (!array) return array;
  if (!Array.isArray(array)) return array;

  const arrayInParams = JSON.parse(JSON.stringify(array));
  return arrayInParams.map((object) => {
    Object.keys(object).forEach((key) => {
      object[key] = formatNumber(object[key], decimals);
    });
    return object;
  });
};

export const sortData = (data, column, order) => {
  if (!data) return data;
  if (!Array.isArray(data)) return data;
  const dataInParams = JSON.parse(JSON.stringify(data));

  dataInParams.sort((a, b) => {
    const periodYearA = a[column].toLowerCase();
    const periodYearB = b[column].toLowerCase();

    if (periodYearA < periodYearB) return order === 1 ? 1 : -1;
    if (periodYearA > periodYearB) return order === 1 ? -1 : 1;
    return 0;
  });

  return dataInParams;
};

// Function to format a date in the format "MM/DD/YYYY".
export const formatDate = (date) => {
  if (!hasValue(date)) return date;
  const dateInParams = new Date(date);
  if (dateInParams.toString() === "Invalid Date") return date;

  const dd = String(dateInParams.getDate()).padStart(2, '0');
  const mm = String(dateInParams.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = dateInParams.getFullYear();
  return `${mm}/${dd}/${yyyy}`;
}

// Function to format a date in DD/MMM/YY format
export const formatDateShort = (date) => {
  if (!hasValue(date)) return date;
  const dateInParams = new Date(date);
  if (dateInParams.toString() === "Invalid Date") return date;

  // const mm = String(dateInParams.getMonth() + 1).padStart(2, '0'); //January is 0!
  // const yyyy = dateInParams.getFullYear();
  // return `${mm}/${yyyy}`;
  const dd = String(dateInParams.getDate()).padStart(2, '0');
  let mmm = moment(dateInParams).format("MMM");  // "Apr" - current date
  const yyyy = (dateInParams.getFullYear().toString().substr(-2));
  return `${dd}/${mmm}/${yyyy}`
}

export const formatMonth = (date) => {
  if (!hasValue(date)) return date;
  const dateInParams = new Date(date);
  if (dateInParams.toString() === "Invalid Date") return date;

  let mmm = moment(dateInParams).format("MMM");  // "Apr" - current date
  const yyyy = dateInParams.getFullYear();
  return `${mmm}/${yyyy}`;
}

export const getOrdinalIndicator = (number, concatenate = false) => {
  if (isNaN(Number(number))) return number;

  let ordinalIndicator = "th";
  if (number % 10 === 1) ordinalIndicator = "st";
  if (number % 10 === 2) ordinalIndicator = "nd";
  if (number % 10 === 3) ordinalIndicator = "rd";

  return concatenate ? `${number}${ordinalIndicator}` : ordinalIndicator;
}

export const formatDateString = (date) => {
  if (!hasValue(date)) return date;
  const dateInParams = new Date(date).toDateString();
  if (dateInParams === "Invalid Date") return date;

  const dateString = dateInParams.split(" ");

  const dateNumber = Number(dateString[2]);
  const ordinalIndicator = getOrdinalIndicator(dateNumber);
  return `${dateString[2]}${ordinalIndicator} ${dateString[1]}' ${dateString[3]}`;
}

export const formatDateString1 = (date) => {
  if (!hasValue(date)) return date;
  const dateInParams = new Date(date).toDateString();
  if (dateInParams === "Invalid Date") return date;

  const dateString = dateInParams.split(" ");
  return `${dateString[2]} ${dateString[1]}'${dateString[3].substr(2, 2)}`;
}

export const formatDateString2 = (date) => {
  if (!hasValue(date)) return date;
  const dateInParams = new Date(date).toDateString();
  if (dateInParams === "Invalid Date") return date;

  const dateString = dateInParams.split(" ");
  return `${dateString[2]} ${dateString[1]} ${dateString[3]}`;
}

export const formatTime = (date) => {
  if (!hasValue(date)) return date;
  const dateInParams = new Date(date).toTimeString();
  if (dateInParams === "Invalid Date") return date;

  const dateString = dateInParams.split(":");
  return `${dateString[0]}:${dateString[1]}`
}

export const formatTime1 = (date) => {
  if (!hasValue(date)) return date;
  const dateInParams = new Date(date);
  if (dateInParams.toTimeString() === "Invalid Date") return date;

  let hours = dateInParams.getHours();
  let minutes = dateInParams.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes} ${ampm}`;
}

export const getMaxDate = (data, column) => {
  if (!data || data.length === 0) return null;
  const validData = data.filter((record) => hasValue(record[column]) && new Date(record[column]).toString() !== "Invalid Date");
  if (validData.length === 0) return;
  return formatDate(new Date(Math.max(...validData.map((record) => new Date(record[column])))));
};

export const getMaxValue = (data, column) => {
  if (!data || data.length === 0) return null;
  return sortData(data, column, -1)[0][column];
};

// Function to find data based on a key and value in a dataset
export const findData = (data, key, value) => data.find((record) => record[key]?.toUpperCase() === value?.toUpperCase());

// Function to create a range of numbers starting from a specific value
function range(size, startAt = 0) {
  return [...Array(size).keys()].map((i) => i + startAt);
}

// Function to execute a callback when scrolling stops
export const onScrollStop = (event, callback) => {
  let isScrolling;
  event.target.addEventListener(
    'scroll',
    e => {
      clearTimeout(isScrolling);
      isScrolling = setTimeout(() => {
        callback(e);
      }, 150);
    },
    false
  );
};

// Function to download an Excel file
export async function downloadExcel(filename) {
  const wb = new ExcelJS.Workbook();
  const dd = wb.addWorksheet("DATA DICTIONARY");

  dd.mergeCells("B2:H2");
  dd.getCell("B2").value =
    "Please do not modify this sheet unless there is an addition";

  const buf = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buf]), filename + ".xlsx");
}

// var i = -1;
// function nextStep(i, section, imageSize, pres) {
//   i++;
//   if (i >= section.length - 1) return;
//   // some body
//   let slide5 = pres.addSlide();

//   // slideImage(section[i], slide5, { ...imageSize, y: 1.4 }, pres);
//   html2canvas(document.getElementsByClassName(section[i])[0], {
//     onrendered: function (canvas) {
//       let image = canvas.toDataURL("image/png");
//       let imageString = String(image);
//       slide5.addImage({ ...imageSize, data: String(imageString.split(":")[1]) });
//     }
//   });
//   nextStep(i, section, imageSize, pres)
// }

function slideImage(className, slide, imageSize, presentation, scroll = false, filename = "", pres = false) {
  html2canvas(document.getElementsByClassName(className)[0], scroll && { scrollY: -window.scrollY }).then((canvas) => {
    let image = canvas.toDataURL("image/png");
    let imageString = String(image);

    slide.addImage({ ...imageSize, data: String(imageString.split(":")[1]) });
    if (pres) {
      presentation.writeFile({ fileName: filename })
    }
  });
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// Function to download a PowerPoint presentation
export async function downloadPPT(filename, sections = [], setExpand = false, tableData = null) {
  if (filename === "Performance Report") {
    try {
      let pres = new pptxgen();

      // 1. Define the layout
      pres.defineLayout({ name: 'A3', width: 20, height: 21 });
      pres.layout = 'A3'
      // 2. Add a Slide to the presentation
      let slide = pres.addSlide();
      let slide2 = pres.addSlide();

      const imageSize = { x: 1, y: 0.7, w: 18, h: 6 };

      slideImage("filters-ps", slide, { ...imageSize, y: 0.35, w: 18, h: 1.5 }, pres)
      slideImage("first-section-ps", slide, { ...imageSize, y: 2 }, pres)
      slideImage("third-section-ps", slide, { ...imageSize, y: 8 }, pres)
      slideImage("fourth-section-ps", slide, { ...imageSize, y: 14 }, pres)
      slideImage("filters-ps", slide2, { ...imageSize, y: 0.35, w: 18, h: 1.5 }, pres)
      slideImage("second-section-ps", slide2, { ...imageSize, y: 2, h: 18 }, pres, true, filename, true)
      if (setExpand) {
        setExpand(false);
      }
      openNotificationWithIcon('success', 'Downloading..');
    }
    catch {
      openNotificationWithIcon('error', 'Download Failed');
    }

  }
  else if (filename === "Distribution Report") {
    try {
      let pres = new pptxgen();
      // Define new layout for the Presentation
      pres.defineLayout({ name: 'A3', width: 16.5, height: 25.7 });
      pres.layout = 'A3'

      let slide = pres.addSlide();
      let slide2 = pres.addSlide();

      slideImage("filters-ps", slide, { x: 0.5, y: 0.5, w: 10, h: 0.7 }, pres);
      slideImage("first-section-ps", slide, { x: 0.5, y: 1.7, w: 15.5, h: 3.5 }, pres);
      slideImage("third-section-ps", slide, { x: 0.5, y: 6.5, w: 15.5, h: 7.5 }, pres);
      slideImage("fourth-section-ps", slide, { x: 0.5, y: 14, w: 15.5, h: 10 }, pres);
      slideImage("ant-table-thead", slide2, { x: 1.25, y: 1.7, w: 14, h: 1 }, pres, true);
      slideImage("ant-table-tbody", slide2, { x: 1.25, y: 2.7, w: 14, h: 22.5 }, pres, true);
      slideImage("filters-ps", slide2, { x: 0.5, y: 0.5, w: 10, h: 0.7 }, pres, false, filename, true);
      openNotificationWithIcon('success', 'Downloading..');
    }
    catch {
      openNotificationWithIcon('error', 'Download Failed');
    }
  }
  else if (filename === "Volume Shifting Report") {
    try {
      let pres = new pptxgen();
      const imageSize = { x: 0, y: 0, w: 10, h: (18 + (sections.length - 4) * 3) };

      pres.defineLayout({ name: 'A3', width: 10, height: (18 + (sections.length - 4) * 3) });
      pres.layout = 'A3'

      let slide = pres.addSlide();

      html2canvas(document.body, { scrollY: -window.scrollY }).then((canvas) => {
        let image = canvas.toDataURL("image/png");
        let imageString = String(image);

        slide.addImage({ ...imageSize, data: String(imageString.split(":")[1]) });
        pres.writeFile({ fileName: filename })
      });
      openNotificationWithIcon('success', 'Downloading..');
    }
    catch {
      openNotificationWithIcon('error', 'Download Failed');
    }


  }
}

// Function to render the total for a table cell based on specified values and selection
const totalRender = (value1, value2, value3, value4, value5, value6, value7, value8, selection) => (text, record) => {
  let count
  if (value1 !== "DATA_EXPORT") {

    count = (record[value1] && (selection.includes(1) || selection.length === 0) ? record[value1] : 0) + (record[value2] && (selection.includes(2) || selection.length === 0) ? record[value2] : 0) + (record[value3] && (selection.includes(3) || selection.length === 0) ? record[value3] : 0) + (record[value4] && (selection.includes(4) || selection.length === 0) ? record[value4] : 0) + (record[value5] && (selection.includes(5) || selection.length === 0) ?
      record[value5] : 0) +
      (record[value6] && (selection.includes(6) || selection.length === 0) ?
        record[value6] : 0) +
      (record[value7] && (selection.includes(7) || selection.length === 0) ?
        record[value7] : 0) +
      (record[value8] && (selection.includes(8) || selection.length === 0) ?
        record[value8] : 0);
  } else {
    count = record[value1]
  }
  return (
    <div style={{ display: "flex", wordBreak: "break-all" }} >
      <div>
        {count !== 0 ? count : ''}
      </div>
    </div >
  );
}

// Function to decode the hierarchy dropdown data
export const decodeHierarchyDropdown = (data, parentCheckable = true) => {
  return Object.keys(data).reduce((previousValue, currentValue) => {
    const children = Array.isArray(data[currentValue])
      ? data[currentValue]
      : decodeHierarchyDropdown(data[currentValue], parentCheckable);

    previousValue.push({
      title: currentValue,
      value: currentValue,
      checkable: parentCheckable,
      children: Array.isArray(data[currentValue])
        ? data[currentValue].map((item) => ({
          title: item,
          value: `${currentValue}:${item}`,
        }))
        : children.map((item) => ({
          title: item.title,
          value: `${currentValue}:${item.value}`,
          checkable: parentCheckable,
          children: item.children.map((child) => ({
            title: child.title,
            value: `${currentValue}:${child.value}`,
            checkable: parentCheckable,
          })),
        })),
    });
    return previousValue;
  }, []);
}

// Function to decode the hierarchy dropdown data values
export const decodeHierarchyDropdownValue = (data, parentCheckable = true) => {
  return Object.keys(data).reduce((previousValue, currentValue) => {
    const processed = Array.isArray(data[currentValue])
      ? data[currentValue]
      : decodeHierarchyDropdownValue(data[currentValue], parentCheckable);
    previousValue.push({
      title: currentValue,
      value: currentValue,
      checkable: parentCheckable,
      children: processed.map((item) => ({
        title: item.title,
        value: `${currentValue}:${item.children.length ? item.value : data[currentValue][item.value]
          }`,
        checkable: parentCheckable,
        children: item.children.map((child) => {
          return {
            title: child.title,
            value: `${currentValue}:${child.value}`,
            checkable: parentCheckable,
          };
        }),
      })),
    });
    return previousValue;
  }, []);
};

// Function to decode the hierarchy dropdown data keys
export const decodeHierarchyDropdownKey = (data, parentCheckable = true) => {
  return Object.keys(data).reduce((previousValue, currentValue) => {
    const processed = Array.isArray(data[currentValue])
      ? data[currentValue]
      : decodeHierarchyDropdownKey(data[currentValue], parentCheckable);
    previousValue.push({
      title: currentValue,
      value: currentValue,
      checkable: parentCheckable,
      children: processed.map((item) => ({
        title: item.title,
        value: `${currentValue}:${item.title}`,
        checkable: parentCheckable,
        children: item.children.map((child) => {
          return {
            title: child.title,
            value: `${currentValue}:${child.title}`,
            checkable: parentCheckable,
          };
        }),
      })),
    });
    return previousValue;
  }, []);
};

// export const decodeTreeSelectValue = (data) => {
//   return Object.keys(data).map((type1) => ({
//     title: type1,
//     value: typeof data[type1] === "object" ? type1 : data[type1],
//     children: Object.keys(data[type1]).map((type2) => ({
//       title: type2,
//       value:
//         typeof data[type1][type2] === "object"
//           ? `${type1}:${type2}`
//           : `${type1}:${data[type1][type2]}`,
//       children: Object.keys(data[type1][type2]).map((type3) => ({
//         title: type3,
//         value:
//           typeof data[type1][type2][type3] === "object"
//             ? `${type1}:${type2}:${type3}`
//             : `${type1}:${type2}:${data[type1][type2][type3]}`,
//         children: Object.keys(data[type1][type2][type3]).map((type4) => ({
//           title: type4,
//           value:
//             typeof data[type1][type2][type3][type4] === "object"
//               ? `${type1}:${type2}:${type3}:${type4}`
//               : `${type1}:${type2}:${type3}:${data[type1][type2][type3][type4]}`,
//           children: Object.keys(data[type1][type2][type3][type4]).map(
//             (type5) => ({
//               title: type5,
//               value:
//                 typeof data[type1][type2][type3][type4][type5] === "object"
//                   ? `${type1}:${type2}:${type3}:${type4}:${type5}`
//                   : `${type1}:${type2}:${type3}:${type4}:${data[type1][type2][type3][type4][type5]}`,
//             })
//           ),
//         })),
//       })),
//     })),
//   }));
// };

// export const decodeTreeSelectKey = (data) => {
//   return Object.keys(data).map((type1) => ({
//     title: type1,
//     value: type1,
//     children: Object.keys(data[type1]).map((type2) => ({
//       title: type2,
//       value:`${type1}:${type2}`,
//       children: Object.keys(data[type1][type2]).map((type3) => ({
//         title: type3,
//         value: `${type1}:${type2}:${type3}`,
//         children: Object.keys(data[type1][type2][type3]).map((type4) => ({
//           title: type4,
//           value: `${type1}:${type2}:${type3}:${type4}`,
//           children: Object.keys(data[type1][type2][type3][type4]).map(
//             (type5) => ({
//               title: type5,
//               value: `${type1}:${type2}:${type3}:${type4}:${type5}`,
//             })
//           ),
//         })),
//       })),
//     })),
//   }));
// };

// TODO: Recursive implementation

export const decodeTreeSelectKey = (data, parentCheckable = true, recursionkey = "") => {
  return Object.keys(data).reduce((accumulator, currentValue) => {
    // Recursively process the data and create a tree structure
    const newKey = recursionkey ? `${recursionkey}:${currentValue}` : currentValue;
    const children = decodeTreeSelectKey(data[currentValue], parentCheckable, newKey);


    accumulator.push({
      title: currentValue,
      value: newKey,
      checkable: parentCheckable,
      children,
    });

    return accumulator;
  }, []);
};

// Helper function to recursively decode tree-select values
export const decodeTreeSelectValue = (data, parentCheckable = true, recursionkey = "") => {
  return Object.keys(data).reduce((accumulator, currentValue) => {
    const newKey = recursionkey
      ? typeof data[currentValue] === "object"
        ? `${recursionkey}:${currentValue}`
        : `${recursionkey}:${data[currentValue]}`
      : currentValue;
    const children = decodeTreeSelectValue(data[currentValue], parentCheckable, newKey);


    accumulator.push({
      title: currentValue,
      value: newKey,
      checkable: parentCheckable,
      children,
    });

    return accumulator;
  }, []);
};
export const decodeTrendTreeSelectValue = (data, parentCheckable = true, recursionkey = "", parentSelectable = true) => {
  return Object.keys(data).reduce((accumulator, currentValue) => {
    const newKey = recursionkey
      ? typeof data[currentValue] === "object"
        ? `${recursionkey}:${currentValue}`
        : `${recursionkey}:${data[currentValue]}`
      : currentValue;
    const children = decodeTrendTreeSelectValue(data[currentValue], parentCheckable, newKey);


    accumulator.push({
      title: currentValue,
      value: currentValue,
      checkable: parentCheckable,
      selectable: parentSelectable,
      children,
    });

    return accumulator;
  }, []);
};

// Helper function to recursively decode tree-select keys and values
export const decodeTreeSelect = (
  data,
  parentCheckable = true,
  recursionkey = ""
) => {
  // Recursively process the data and create a tree structure
  return Object.keys(data).reduce((accumulator, currentValue) => {
    const newKey = recursionkey
      ? `${recursionkey}:${currentValue}`
      : currentValue;

    let children = [];
    // If the value is an array, create child elements with the given properties
    if (Array.isArray(data[currentValue])) {
      children = data[currentValue].map((child) => ({
        title: child,
        value: `${currentValue}:${child}`,
      }));
    } else
      // Otherwise, recursively process the nested data
      children = decodeTreeSelectKey(
        data[currentValue],
        parentCheckable,
        newKey
      );

    accumulator.push({
      title: currentValue,
      value: newKey,
      checkable: parentCheckable,
      children,
    });

    return accumulator;
  }, []);
};

// Helper function to encode tree-select values
export const encodeTreeSelectValue = (data) => {
  // Process the data and encode the tree-select values
  let encoded = {};
  data.forEach((value) => {
    const item = value.split(":");
    console.log("encoded[item[0]", item)

    if (item.length === 1) encoded[item[0]] = ["ALL"];
    else if (item.length === 2) {
      // Handle two-level tree-select values
      if (isNaN(item[1]) || item[1] === "") {
        if (!hasValue(encoded[item[0]])) encoded[item[0]] = {};
        encoded[item[0]][item[1]] = ["ALL"];
      } else if (encoded[item[0]]) {
        encoded[item[0]].push(item[1]);
      } else encoded[item[0]] = [item[1]];
    } else if (item.length === 3) {
      // Handle three-level tree-select values
      if (isNaN(item[2]) || item[2] === "") {
        if (!hasValue(encoded[item[0]])) encoded[item[0]] = {};
        if (!hasValue(encoded[item[0]][item[1]]))
          encoded[item[0]][item[1]] = {};
        encoded[item[0]][item[1]][item[2]] = ["ALL"];
      } else if (encoded[item[0]]) {
        if (encoded[item[0]][item[1]]) {
          encoded[item[0]][item[1]].push(item[2]);
        } else encoded[item[0]][item[1]] = [item[2]];
      } else {
        encoded[item[0]] = {};
        encoded[item[0]][item[1]] = [item[2]];
      }
    }
  });

  return encoded;
};

// Helper function to encode tree-select keys
export const encodeTreeSelectKey = (data) => {
  // Process the data and encode the tree-select keys
  let encoded = {};
  data.forEach(value => {

    const item = value.split(":");

    if (item.length === 1)
      encoded = { ...encoded, [item]: ["ALL"] };
    else if (encoded[item[0]]) {
      encoded[item[0]].push(item[1])
    } else encoded[item[0]] = [item[1]]
  })

  return encoded;

}

export const usagePage3Columns = (selection) => [{
  title: "Unique User",
  dataIndex: "unique_user",
  key: "unique_user",
  width: 400,
  sorter: (a, b) =>
    a.unique_user.toLowerCase() < b.unique_user.toLowerCase() ? -1 : 1,

},
// {
//   title: "Performance Bookmarks",
//   dataIndex: "BOOKMARK_FOR_PERFORMANCE",
//   key: "BOOKMARK_FOR_PERFORMANCE",
// },
// {
//   title: "Volume Bookmarks",
//   dataIndex: "BOOKMARK_FOR_VOLUME",
//   key: "BOOKMARK_FOR_VOLUME",
// },
// {
//   title: "Distribution Bookmarks",
//   dataIndex: "BOOKMARK_FOR_DISTRIBUTION",
//   key: "BOOKMARK_FOR_DISTRIBUTION",
// },
{
  title: "Bookmarks",
  render: totalRender("BOOKMARK_FOR_PERFORMANCE", "BOOKMARK_FOR_COMPITITION", "BOOKMARK_FOR_DISTRIBUTION", "BOOKMARK_FOR_INNOVATION", null, null, null, null, selection),
},
// {
//   title: "PPT Exports for Performance",
//   dataIndex: "PPT_FOR_PERFORMANCE",
//   key: "PPT_FOR_PERFORMANCE",
// },
// {
//   title: "PPT Exports for Volume",
//   dataIndex: "PPT_FOR_VOLUMESHIFTING",
//   key: "PPT_FOR_VOLUMESHIFTING",
// },
// {
//   title: "PPT Exports for Distribution",
//   dataIndex: "PPT_FOR_DISTRIBUTION",
//   key: "PPT_FOR_DISTRIBUTION",
// },
{
  title: "PPT Exports",
  render: totalRender("PPT_FOR_PERFORMANCE", "PPT_FOR_VOLUMESHIFTING", "PPT_FOR_DISTRIBUTION", "PPT_FOR_INNOVATION", "PPT_FOR_DIAGNOSTICS", "PPT_FOR_SEARCH_TERM", "PPT_FOR_SHARE_OF_SHELF","PPT_FOR_STACKLINE", "PPT_FOR_BE_AVAILABLE", selection),
},
{
  title: "Excel Exports",
  render: totalRender("EXCEL_FOR_PERFORMANCE", "EXCEL_FOR_VOLUMESHIFTING", "EXCEL_FOR_DISTRIBUTION", "EXCEL_FOR_INNOVATION", "EXCEL_FOR_DIAGNOSTICS", "EXCEL_FOR_SEARCH_TERM", "EXCEL_FOR_SHARE_OF_SHELF","EXCEL_FOR_TRAFFIC_STACKLINE", "EXCEL_FOR_BE_AVAILABLE", selection),
},
{
  title: "New Self Serve Reports Created",
  render: totalRender("CREATE_SELF_SERVE_REPORT_PERFORMANCE", "CREATE_SELF_SERVE_REPORT_COMPITITION", "CREATE_SELF_SERVE_REPORT_DISTRIBUTION", "CREATE_SELF_SERVE_REPORT_INNOVATION", null, null, null, null, selection),
},
{
  title: "Reports Updated",
  render: totalRender("ADD_EXISTING_REPORT_PERFORMANCE", "ADD_EXISTING_REPORT_COMPITITON", "ADD_EXISTING_REPORT_DISTRIBUTION", "ADD_EXISTING_REPORT_INNOVATION", null, null, null, null, selection),
},
{
  title: "Data Export",
  render: totalRender("DATA_EXPORT", selection),
},]

export const page2columns = (setPieData) => [
  {
    title: "Page",
    dataIndex: "PAGE",
    key: "PAGE",
    render: (text, record) => {
      if (text === "/alerts-1") {
        text = "/alerts-performance"
      } else if (text === "/alerts-2") {
        text = "/alerts-competition"
      } else if (text === "/alerts-3") {
        text = "/alerts-distribution"
      } else if (text === "/alerts-4") {
        text = "/alerts-innovation"
      } else if (text === "/volume-shifting") {
        text = "/competition-summary"
      } else if (text === "/search-term") {
        text = "/search-insights"
      } else if (text === "/export") {
        text = "/data-selector"
      }
      return (
        <div>
          <a onClick={() => setPieData(text)}><u>{text}</u></a>
        </div>
      )
    }
  },
  {
    title: "Views",
    dataIndex: "PAGE_VIEWS",
    key: "PAGE_VIEWS",
    sorter: (a, b) => a.PAGE_VIEWS - b.PAGE_VIEWS,
    defaultSortOrder: 'descend',
  },
  {
    title: "Unique Users",
    dataIndex: "UNIQUE_USERS",
    key: "UNIQUE_USERS",

  },
  {
    title: " Average Load Time",
    dataIndex: "AVERAGE_LOAD_TIME",
    key: "AVERAGE_LOAD_TIME",
  }]

export const card2table = [
  {
    title: "Unique User",
    dataIndex: "UNIQUE_USER",
    key: "UNIQUE_USER",
    sorter: (a, b) =>
      a.UNIQUE_USER.toLowerCase() < b.UNIQUE_USER.toLowerCase() ? -1 : 1,
    // defaultSortOrder: 'ascend'
  },
  {
    title: "Views",
    dataIndex: "TOTAL_PAGES",
    key: "TOTAL_PAGES",
    sorter: (a, b) => a.TOTAL_PAGES - b.TOTAL_PAGES,
    defaultSortOrder: 'descend',
  },
  // {
  //   title: "Pages",
  //   dataIndex: "UNIQUE_PAGES",
  //   key: "UNIQUE_PAGES",
  // },
  {
    title: "Brand View Count",
    dataIndex: "BRAND_COUNT",
    key: "BRAND_COUNT",
  },
  {
    title: "Country",
    dataIndex: "COUNTRY",
    key: "COUNTRY",
  },
  {
    title: "Number of Logins",
    dataIndex: "SESSION_COUNT",
    key: "SESSION_COUNT",
  },
]

export const timeAggregates = { l4w: "4w", l13w: "13w", l52w: "52w", ytd: "ytd" };
export const getTimeAggregates = (time_aggregates) => {
  const index = Object.values(timeAggregates).findIndex((value) => value === time_aggregates[0]);
  return [Object.keys(timeAggregates)[index].toUpperCase()];
}

// const data = [
//   {
//     period: 'p1',
//     value: 8000,
//     type: 'actual'
//   },
//   {
//     period: 'p1',
//     value: 10000,
//     type: 'actual1'
//   },
//   {
//     period: 'p2',
//     value: 14000,
//     type: 'actual'
//   },

//   {
//     period: 'p2',
//     value: 18000,
//     type: 'actual1'
//   },
//   {
//     period: 'p3',
//     value: 18000,
//     type: 'actual'
//   },
//   {
//     period: 'p4',
//     value: 14000,
//     type: 'actual'
//   },
//   {
//     period: 'p5',
//     value: 15500,
//     type: 'actual'
//   },
//   {
//     period: 'p5',
//     value: 15500,
//     type: 'forecast'
//   },
//   {
//     period: 'p5',
//     value: 15000,
//     type: 'actual1'
//   },
//   {
//     period: 'p6',
//     value: 14000,
//     type: 'forecast'
//   },
//   {
//     period: 'p6',
//     value: 15000,
//     type: 'forecast1'
//   },
//   {
//     period: 'p7',
//     value: 13000,
//     type: 'forecast'
//   },
//   {
//     period: 'p8',
//     value: 8000,
//     type: 'forecast'
//   },
//   {
//     period: 'p9',
//     value: 8000,
//     type: 'forecast'
//   },
//   {
//     period: 'p10',
//     value: 8000,
//     type: 'forecast'
//   },
//   {
//     period: 'p11',
//     value: 8000,
//     type: 'forecast'
//   },
//   {
//     period: 'p12',
//     value: 8000,
//     type: 'forecast'
//   },
//   {
//     period: 'p13',
//     value: 8000,
//     type: 'forecast'
//   }
// ]
// const data = [
//   {
//       "time_year": 2022,
//       "time_period": "P1",
//       "innovation": "Pedigree Wet Can Hi Pro Line Extension",
//       "gsv_forecast_flag": "ACTUALS",
//       "actual": 963982.622
//   },
//   {
//       "time_year": 2022,
//       "time_period": "P2",
//       "innovation": "Pedigree Wet Can Hi Pro Line Extension",
//       "gsv_forecast_flag": "ACTUALS",
//       "actual": 1257256.85
//   },
//   {
//       "time_year": 2022,
//       "time_period": "P3",
//       "innovation": "Pedigree Wet Can Hi Pro Line Extension",
//       "gsv_forecast_flag": "ACTUALS",
//       "actual": 1083530.18
//   },
//   {
//       "time_year": 2022,
//       "time_period": "P4",
//       "innovation": "Pedigree Wet Can Hi Pro Line Extension",
//       "gsv_forecast_flag": "ACTUALS",
//       "actual": 36712.68
//   },
//   {
//       "time_year": 2022,
//       "time_period": "P5",
//       "innovation": "Pedigree Wet Can Hi Pro Line Extension",
//       "gsv_forecast_flag": "ACTUALS",
//       "actual": 1361781.207
//   },

//   {
//       "time_year": 2022,
//       "time_period": "P6",
//       "innovation": "Pedigree Wet Can Hi Pro Line Extension",
//       "gsv_forecast_flag": "ACTUALS",
//       "actual": 977731.14
//   },
//   {
//     "time_year": 2022,
//     "time_period": "P6",
//     "forecast_innovation": "Pedigree Wet Can Hi Pro Line Extension",
//     "gsv_forecast_flag": "FORECAST",
//     "forecast": 977732.14
// },
//   {
//       "time_year": 2022,
//       "time_period": "P7",
//       "forecast_innovation": "Pedigree Wet Can Hi Pro Line Extension",
//       "gsv_forecast_flag": "FORECAST",
//       "forecast": 2923167.0
//   },
//   {
//       "time_year": 2022,
//       "time_period": "P8",
//       "forecast_innovation": "Pedigree Wet Can Hi Pro Line Extension",
//       "gsv_forecast_flag": "FORECAST",
//       "forecast": 678050.66
//   },
//   {
//       "time_year": 2022,
//       "time_period": "P9",
//       "forecast_innovation": "Pedigree Wet Can Hi Pro Line Extension",
//       "gsv_forecast_flag": "FORECAST",
//       "forecast": 957552.17
//   },
//   {
//     "time_year": 2022,
//     "time_period": "P1",
//     "innovation": "Pedigree Wet Can Hi Pro",
//     "gsv_forecast_flag": "ACTUALS",
//     "actual": 90000.622
// },
// {
//     "time_year": 2022,
//     "time_period": "P2",
//     "innovation": "Pedigree Wet Can Hi Pro",
//     "gsv_forecast_flag": "ACTUALS",
//     "actual": 1457256.85
// },
// {
//     "time_year": 2022,
//     "time_period": "P3",
//     "innovation": "Pedigree Wet Can Hi Pro",
//     "gsv_forecast_flag": "ACTUALS",
//     "actual": 1283530.18
// },
// {
//     "time_year": 2022,
//     "time_period": "P4",
//     "innovation": "Pedigree Wet Can Hi Pro",
//     "gsv_forecast_flag": "ACTUALS",
//     "actual": 38712.68
// },
// {
//     "time_year": 2022,
//     "time_period": "P5",
//     "innovation": "Pedigree Wet Can Hi Pro",
//     "gsv_forecast_flag": "ACTUALS",
//     "actual": 1381781.207
// },
// {
//     "time_year": 2022,
//     "time_period": "P6",
//     "innovation": "Pedigree Wet Can Hi Pro",
//     "gsv_forecast_flag": "ACTUALS",
//     "actual": 1007731.14
// },
// {
//   "time_year": 2022,
//   "time_period": "P6",
//   "forecast_innovation": "Pedigree Wet Can Hi Pro",
//   "gsv_forecast_flag": "FORECAST",
//   "forecast": 1007731.14
// },
// {
//     "time_year": 2022,
//     "time_period": "P7",
//     "forecast_innovation": "Pedigree Wet Can Hi Pro",
//     "gsv_forecast_flag": "FORECAST",
//     "forecast": 2943167.0
// },
// {
//     "time_year": 2022,
//     "time_period": "P8",
//     "forecast_innovation": "Pedigree Wet Can Hi Pro",
//     "gsv_forecast_flag": "FORECAST",
//     "forecast": 698050.66
// },
// {
//     "time_year": 2022,
//     "time_period": "P9",
//     "forecast_innovation": "Pedigree Wet Can Hi Pro",
//     "gsv_forecast_flag": "FORECAST",
//     "forecast": 977552.17
// }
// ]
export const data = [
  {
    time_year: 2022,
    time_period: "P1",
    innovation: "ACTUALS_Pedigree Wet Can Hi Pro Line Extension",
    gsv_forecast_flag: "ACTUALS",
    actual: 963982.622
  },
  {
    time_year: 2022,
    time_period: "P2",
    innovation: "ACTUALS_Pedigree Wet Can Hi Pro Line Extension",
    gsv_forecast_flag: "ACTUALS",
    actual: 1257256.85
  },
  {
    time_year: 2022,
    time_period: "P3",
    innovation: "ACTUALS_Pedigree Wet Can Hi Pro Line Extension",
    gsv_forecast_flag: "ACTUALS",
    actual: 1083530.18
  },
  {
    time_year: 2022,
    time_period: "P4",
    innovation: "ACTUALS_Pedigree Wet Can Hi Pro Line Extension",
    gsv_forecast_flag: "ACTUALS",
    actual: 36712.68
  },
  {
    time_year: 2022,
    time_period: "P5",
    innovation: "ACTUALS_Pedigree Wet Can Hi Pro Line Extension",
    gsv_forecast_flag: "ACTUALS",
    actual: 1361781.207
  },
  {
    time_year: 2022,
    time_period: "P6",
    innovation: "ACTUALS_Pedigree Wet Can Hi Pro Line Extension",
    gsv_forecast_flag: "ACTUALS",
    actual: 977731.14
  },
  {
    time_year: 2022,
    time_period: "P1",
    innovation: "ACTUALS_Pedigree Wet Can Hi Pro",
    gsv_forecast_flag: "ACTUALS",
    actual: 90000.622
  },
  {
    time_year: 2022,
    time_period: "P2",
    innovation: "ACTUALS_Pedigree Wet Can Hi Pro",
    gsv_forecast_flag: "ACTUALS",
    actual: 1457256.85
  },
  {
    time_year: 2022,
    time_period: "P3",
    innovation: "ACTUALS_Pedigree Wet Can Hi Pro",
    gsv_forecast_flag: "ACTUALS",
    actual: 1283530.18
  },
  {
    time_year: 2022,
    time_period: "P4",
    innovation: "ACTUALS_Pedigree Wet Can Hi Pro",
    gsv_forecast_flag: "ACTUALS",
    actual: 38712.68
  },
  {
    time_year: 2022,
    time_period: "P5",
    innovation: "ACTUALS_Pedigree Wet Can Hi Pro",
    gsv_forecast_flag: "ACTUALS",
    actual: 1381781.207
  },
  {
    time_year: 2022,
    time_period: "P6",
    innovation: "ACTUALS_Pedigree Wet Can Hi Pro",
    gsv_forecast_flag: "ACTUALS",
    actual: 1007731.14
  },
  {
    time_year: 2022,
    time_period: "P6",
    innovation: "FORECAST_Pedigree Wet Can Hi Pro Line Extension",
    gsv_forecast_flag: "FORECAST",
    actual: 977732.14
  },
  {
    time_year: 2022,
    time_period: "P7",
    innovation: "FORECAST_Pedigree Wet Can Hi Pro Line Extension",
    gsv_forecast_flag: "FORECAST",
    actual: 2923167.0
  },
  {
    time_year: 2022,
    time_period: "P8",
    innovation: "FORECAST_Pedigree Wet Can Hi Pro Line Extension",
    gsv_forecast_flag: "FORECAST",
    actual: 678050.66
  },
  {
    time_year: 2022,
    time_period: "P9",
    innovation: "FORECAST_Pedigree Wet Can Hi Pro Line Extension",
    gsv_forecast_flag: "FORECAST",
    actual: 957552.17
  },
  {
    time_year: 2022,
    time_period: "P6",
    innovation: "FORECAST_Pedigree Wet Can Hi Pro",
    gsv_forecast_flag: "FORECAST",
    actual: 1007731.14
  },
  {
    time_year: 2022,
    time_period: "P7",
    innovation: "FORECAST_Pedigree Wet Can Hi Pro",
    gsv_forecast_flag: "FORECAST",
    actual: 2943167.0
  },
  {
    time_year: 2022,
    time_period: "P8",
    innovation: "FORECAST_Pedigree Wet Can Hi Pro",
    gsv_forecast_flag: "FORECAST",
    actual: 698050.66
  },
  {
    time_year: 2022,
    time_period: "P9",
    innovation: "FORECAST_Pedigree Wet Can Hi Pro",
    gsv_forecast_flag: "FORECAST",
    actual: 977552.17
  }
];

export const dataSource = [
  {
    key: '1',
    retailer: 'Walmart',
    revenue: '$2.4',
    revenue1: '$4.3',
    evvtarget: '$1.5',
    launch: 'P2',
    period: 'P4'
  },
  {
    key: '2',
    retailer: 'Meijer',
    revenue: '$2.4',
    revenue1: '$4.3',
    evvtarget: '$1.5',
    launch: 'P2',
    period: 'P4'
  },
  {
    key: '3',
    retailer: 'Petco',
    revenue: '$2.4',
    revenue1: '$4.3',
    evvtarget: '$1.5',
    launch: 'P2',
    period: 'P4'
  },
  {
    key: '4',
    retailer: 'Ahold',
    revenue: '$2.4',
    revenue1: '$4.3',
    evvtarget: '$1.5',
    launch: 'P2',
    period: 'P4'
  },
  {
    key: '5',
    retailer: 'Publix',
    revenue: '$2.4',
    revenue1: '$4.3',
    evvtarget: '$1.5',
    launch: 'P2',
    period: 'P4'
  },
  {
    key: '6',
    retailer: 'Petsmart',
    revenue: '$2.4',
    revenue1: '$4.3',
    evvtarget: '$1.5',
    launch: 'P2',
    period: 'P4'
  }
]

export const dataSource1 = [
  {
    key: 1,
    retailer: 'Walmart',
    storeSelling: '80%',
    acvReach: '98.3%',
    erEstimated: '6',
    itemsAccepted: '6',
    estimatedAniReach: '5.5',
    actualAniReach: '5.5',
    tdpEr: '$673',
    tdpActual: '$673',
    dollorTdpEr: '$673',
    dollorTdpActual: '$673',
  },
  {
    key: 2,
    retailer: 'Meijer',
    storeSelling: '80%',
    acvReach: '98.3%',
    erEstimated: '6',
    itemsAccepted: '6',
    estimatedAniReach: '5.5',
    actualAniReach: '5.5',
    tdpEr: '$673',
    tdpActual: '$673',
    dollorTdpEr: '$673',
    dollorTdpActual: '$673',
  },
  {
    key: 3,
    retailer: 'Petco',
    storeSelling: '80%',
    acvReach: '98.3%',
    erEstimated: '6',
    itemsAccepted: '6',
    estimatedAniReach: '5.5',
    actualAniReach: '5.5',
    tdpEr: '$673',
    tdpActual: '$673',
    dollorTdpEr: '$673',
    dollorTdpActual: '$673',
  },
  {
    key: 4,
    retailer: 'Ahold',
    storeSelling: '80%',
    acvReach: '98.3%',
    erEstimated: '6',
    itemsAccepted: '6',
    estimatedAniReach: '5.5',
    actualAniReach: '5.5',
    tdpEr: '$673',
    tdpActual: '$673',
    dollorTdpEr: '$673',
    dollorTdpActual: '$673',
  },
  {
    key: 5,
    retailer: 'Publix',
    storeSelling: '80%',
    acvReach: '98.3%',
    erEstimated: '6',
    itemsAccepted: '6',
    estimatedAniReach: '5.5',
    actualAniReach: '5.5',
    tdpEr: '$673',
    tdpActual: '$673',
    dollorTdpEr: '$673',
    dollorTdpActual: '$673',
  },
  {
    key: 6,
    retailer: 'Petsmart',
    storeSelling: '80%',
    acvReach: '98.3%',
    erEstimated: '6',
    itemsAccepted: '6',
    estimatedAniReach: '5.5',
    actualAniReach: '5.5',
    tdpEr: '$673',
    tdpActual: '$673',
    dollorTdpEr: '$673',
    dollorTdpActual: '$673',
  }
]

export const columns2 = [
  {
    title: '',
    dataIndex: 'type',
    key: 'type',
    className: 'type'
  },
  {
    title: '6 months',
    dataIndex: 'half',
    key: 'half',
    align: 'center',
    className: 'data'
  },
  {
    title: '1 year',
    dataIndex: 'one',
    key: 'one',
    align: 'center',
    className: 'data'
  },
  {
    title: '2 years',
    dataIndex: 'two',
    key: 'two',
    align: 'center',
    className: 'data'
  },
  {
    title: '3 years',
    dataIndex: 'three',
    key: 'three',
    align: 'center',
    className: 'data'
  }
];
export const dataSource2 = [
  {
    key: 1,
    type: 'Brand',
    half: '87%',
    one: '85%',
    two: '85%',
    three: '84%'
  },
  {
    key: 2,
    type: 'Manufacturer',
    half: '75%',
    one: '74%',
    two: '71%',
    three: '70%'
  },
  {
    key: 3,
    type: 'Category',
    half: '18%',
    one: '18%',
    two: '15%',
    three: '15%'
  }
]

export const data2 = [
  // {
  //   brands: 'DIST',
  //   revenue: 1234,
  //   type: 0
  // },
  // {
  //   brands: 'DIST 1',
  //   revenue: 1252,
  //   type: 0
  // },
  // {
  //   brands: 'DIST 2',
  //   revenue: 861,
  //   type: 0
  // },
  // {
  //   brands: 'DIST 3',
  //   revenue: -600,
  //   type: 1
  // },
  // {
  //   brands: 'VELOCITY',
  //   revenue: -1234,
  //   type: 1
  // },
  // {
  //   brands: 'VELOCITY 1',
  //   revenue: 1234,
  //   type: 0
  // }
];
export const config2 = {
  data: data2,
  height: 320,
  xField: 'revenue',
  yField: 'brands',
  seriesField: 'type',
  minBarWidth: 28,
  maxBarWidth: 28,
  label: {
    position: 'right',
    offset: 4,
    style: {
      fill: '#393939'
    },
  },
  legend: {
    position: 'bottom',
    offsetY: 10
  },
  color: ({ type }) => {

    return type === 'drivers' ? '#00C853' : '#F06055';
  }
};

export const data3 = [
  {
    mediaType: 'TV',
    value: 50000,
    target: 60000
  },
  {
    mediaType: 'Print',
    value: 65000,
    target: 78000
  },
  {
    mediaType: 'Digital',
    value: 75000,
    target: 85000
  },
];
export const config3 = {
  data: [data3, data3],
  height: 320,
  xField: 'mediaType',
  yField: ['value', 'target'],
  padding: [20, 0, 20, 20],
  legend: {
    position: 'bottom',
    offsetY: 10
  },
  yAxis: {
    // 格式化左坐标轴
    value: {
      min: 0,
      label: {
        formatter: (val) => `${val / 1000}`,
      },
    },
    // 隐藏右坐标轴
    target: {
      min: 0,
      label: false
    },
  },
  geometryOptions: [
    {
      geometry: 'column',
      color: '#8080CF',
      minColumnWidth: 28,
      maxColumnWidth: 28,
      label: {
        formatter: (val) => `$${val.value}`,
        position: 'top',
        offset: 4,
        style: {
          fill: '#393939'
        },
      },
    },
    {
      geometry: 'line',
      // smooth: true,
      color: '#80EBDC',
    },
  ],
};

export const data4 = [
  {
    type: 'Brand',
    value: 40,
  },
  {
    type: 'Other Brands',
    value: 35,
  },
  {
    type: 'Category',
    value: 25,
  }
];

export const config4 = {
  data: data4,
  xField: 'type',
  yField: 'value',
  height: 300,
  minColumnWidth: 46,
  maxColumnWidth: 46,
  padding: [30, 0, 20, 26],
  color: ({ type }) => {

    if (type == "Brand") {
      return '#8080CF'
    } else if (type == "Other Brands") {
      return '#80EBDC'
    } else if (type == "Category") {
      return '#FF9D8A'
    }
  },
  label: {
    formatter: (val) => `${val.value}%`,
    position: 'top',
    offset: 4,
    style: {
      fill: '#393939',
      fontSize: 22,
      fontWeight: '700'
    },
  },
  min: 0
};

export const data5 = [
  {
    type: 'Cesar Canine Cuisine',
    sales: 95,
    brand: 'mars'
  },
  {
    type: 'Bluecore',
    sales: 92,
    brand: 'other'
  },
  {
    type: 'Purina One',
    sales: 85,
    brand: 'other'
  },
  {
    type: 'Mighty Dog',
    sales: 75,
    brand: 'other'
  },
  {
    type: 'Cesar Savory Delights',
    sales: 48,
    brand: 'mars'
  },
  {
    type: 'Cesar Home Delights',
    sales: 38,
    brand: 'mars'
  }
];
export const config5 = {
  data: data5,
  height: 300,
  xField: 'sales',
  yField: 'type',
  seriesField: 'brand',
  minBarWidth: 28,
  maxBarWidth: 28,
  color: ({ brand }) => {
    return brand === 'mars' ? '#8080CF' : '#80EBDC';
  },
  legend: {
    position: 'bottom'
  },
};

export const dataTrend = [
  {
    week: 'w1',
    key: 'value',
    value: 10000,
  },
  {
    week: 'w1',
    key: 'median',
    value: 12500,
  },
  {
    week: 'w2',
    key: 'value',
    value: 12500,
  },
  {
    week: 'w2',
    key: 'median',
    value: 16000,
  },
  {
    week: 'w4',
    key: 'value',
    value: 15000,
  },
  {
    week: 'w4',
    key: 'median',
    value: 19000,
  },
  {
    week: 'w8',
    key: 'value',
    value: 15000,
  },
  {
    week: 'w8',
    key: 'median',
    value: 20500,
  },
  {
    week: 'w10',
    key: 'value',
    value: 14000,
  },
  {
    week: 'w10',
    key: 'median',
    value: 21000,
  },
  {
    week: 'w12',
    key: 'value',
    value: 16000,
  },
  {
    week: 'w12',
    key: 'median',
    value: 21000,
  },
  {
    week: 'w14',
    key: 'value',
    value: 18000,
  },
  {
    week: 'w14',
    key: 'median',
    value: 19000,
  },
  {
    week: 'w16',
    key: 'value',
    value: 17000,
  },
  {
    week: 'w16',
    key: 'median',
    value: 17000,
  },
  {
    week: 'w18',
    key: 'value',
    value: 16000,
  },
  {
    week: 'w18',
    key: 'median',
    value: 15500,
  },
  {
    week: 'w20',
    key: 'value',
    value: 14500,
  },
  {
    week: 'w20',
    key: 'median',
    value: 14000,
  },
  {
    week: 'w22',
    key: 'value',
    value: 15000,
  },
  {
    week: 'w22',
    key: 'median',
    value: 21000,
  },
  {
    week: 'w24',
    key: 'value',
    value: 15000,
  },
  {
    week: 'w24',
    key: 'median',
    value: 21000,
  },
  {
    week: 'w26',
    key: 'value',
    value: 15000,
  },
  {
    week: 'w26',
    key: 'median',
    value: 21000,
  },
  {
    week: 'w28',
    key: 'value',
    value: 15000,
  },
  {
    week: 'w28',
    key: 'median',
    value: 21000,
  },
  {
    week: 'w30',
    key: 'value',
    value: 15000,
  },
  {
    week: 'w30',
    key: 'median',
    value: 21000,
  },
  {
    week: 'w32',
    key: 'value',
    value: 15000,
  },
  {
    week: 'w32',
    key: 'median',
    value: 21000,
  },
  {
    week: 'w34',
    key: 'value',
    value: 15000,
  },
  {
    week: 'w34',
    key: 'median',
    value: 21000,
  },
  {
    week: 'w36',
    key: 'value',
    value: 15000,
  },
  {
    week: 'w36',
    key: 'median',
    value: 21000,
  },
  {
    week: 'w38',
    key: 'value',
    value: 15000,
  },
  {
    week: 'w38',
    key: 'median',
    value: 21000,
  },
  {
    week: 'w40',
    key: 'value',
    value: 15000,
  },
  {
    week: 'w40',
    key: 'median',
    value: 21000,
  },
  {
    week: 'w42',
    key: 'value',
    value: 15000,
  },
  {
    week: 'w42',
    key: 'median',
    value: 21000,
  },
  {
    week: 'w46',
    key: 'value',
    value: 15000,
  },
  {
    week: 'w46',
    key: 'median',
    value: 21000,
  },
  {
    week: 'w48',
    key: 'value',
    value: 25000,
  },
  {
    week: 'w48',
    key: 'median',
    value: 18000,
  },
  {
    week: 'w50',
    key: 'value',
    value: 25000,
  },
  {
    week: 'w50',
    key: 'median',
    value: 19000,
  },
  {
    week: 'w52',
    key: 'value',
    value: 25000,
  },
  {
    week: 'w52',
    key: 'median',
    value: 21000,
  }
];

export const data7 = [
  {
    rating: '5',
    value: 60,
  },
  {
    rating: '4',
    value: 40,
  },
  {
    rating: '3',
    value: 30,
  },
  {
    rating: '2',
    value: 20,
  },
  {
    rating: '1',
    value: 10,
  }
];

export const data8 = [
  {
    type: 'Organic',
    value: 40,
  },
  {
    type: 'Paid',
    value: 16,
  },
  {
    type: 'Social Media',
    value: 36,
  },
  {
    type: 'XXX',
    value: 36,
  }
];
export const config8 = {
  data: data8,
  height: 194,
  xField: 'type',
  yField: 'value',
  color: '#FF9D8A',
  minColumnWidth: 28,
  maxColumnWidth: 28,
};

export const handleTab = (e, history) => {
  const { value } = e.target
  if (value === "searchTerm") history.push("/search-term")
  else if (value === "shareOfShelf") history.push("/share-of-shelf")
  else if (value === "traffic") history.push("/traffic")
  else if (value === "conversion") history.push("/conversion")
  else if (value === "rating") history.push("/ratings-reviews")
}

export const setMinLimit = (limit, value) => (value < limit ? limit : value);
export const getTitleWidth = (title, sorting = false) =>
  sorting
    ? title.includes(" ")
      ? setMinLimit(80, title.length * 8)
      : setMinLimit(80, title.length * 9)
    : setMinLimit(70, title.length * 7);

export const arraySearch = (array, search) =>
  array.find((t) => t.toLowerCase().includes(search.toLocaleLowerCase()));

// Update the applied filters section
// if nothing is selecte set ALL otherwise display the actual items
export const setDeclareAppliedFilters = (finalFilter, filterName, filterData) => {
  if (
    (finalFilter[filterName].length === filterData.length ||
      finalFilter[filterName].length === 0) &&
    finalFilter[filterName].length !== 1
  )
    finalFilter[filterName] = ["ALL"];

  return finalFilter;
}