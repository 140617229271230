import pptxgen from "pptxgenjs";
import html2canvas from "html2canvas";
import ExcelJS from "exceljs/dist/es5/exceljs.browser.js";
import * as FileSaver from "file-saver";
import {  maxBy, minBy } from "lodash";
import {openNotificationWithIcon, formatNumber, addSymbol} from '../helper.js'
import { exportSosTables } from "./excel.js";

export function createEcomCustomTable(columns, data, isDataBold = true, pageSize = 20, filters,fixedColumn) {
  
  const headerOptions = {
    // bold: true,
    align: "center",
    fill: "0000A0",
    color: "ffffff",
    border: { type: "solid" },
  };
  const dataOptions = {
    // bold: isDataBold,
    align: "center",
    border: { type: "solid" },
  };

  const header = [];
  const subHeader = [];
  const headerText = [];
  const rows = [];
  const pages = [];

  columns.forEach(({ title, children }) => {
    
      header.push({
      text: title,
      options: { ...headerOptions, colspan: children.length },
    });
    if (children) {
      children.map(({ title, children }) => {
        if(children){
          subHeader.push({
            text: title,
            options: { ...headerOptions},
          })
          children.map(({title}, index) => {
            if(title === filters){
            headerText.push({
              text: title,
              options: headerOptions,
            })
          } 
          else {
            if(!!fixedColumn.find((i)=> i === title)){
            headerText.push({
              text: title,
              options: headerOptions,
            })
          }
          }
          })
      }else {
        subHeader.push({
          text: title,
          options: headerOptions,
        })
      }
        }

      );
    } else {
      header.push({ text: title, options: headerOptions });
    }
  });
  const hasChildren = subHeader.length > 0;
  rows.push(header);
  if (hasChildren) rows.push(subHeader);
  rows.push(headerText);
  // data
  data.forEach((record, index) => {
    const row = [];
    columns.forEach(({ dataIndex, children, renderPPT }) => {
      let recLength = record?.retailer_data
      if (children) {
        children.forEach(({ children, dataIndex, renderPPT }) => { // second level loop
          if(children){
            children.forEach(({ dataIndex, renderPPT, title }) => {
              if (renderPPT){
               Object.keys(record[dataIndex]).forEach((val) => {
                if(val===filters && val === title){
                  row.push({
                    text: renderPPT(record[dataIndex][val]),
                    options: dataOptions,
                  })
                } else {
                  if(!!fixedColumn.find((i)=> i === val)){
                    row.push({
                      text: renderPPT(record[dataIndex][val]),
                      options: dataOptions,
                    })
                  }
                }
                })

              } else{
                row.push({
                  text: record[dataIndex],
                  options: dataOptions,
                })
              }
            })
          } else {
          if (renderPPT){
            row.push({
              text: renderPPT(record[dataIndex]),
              options: dataOptions,
            });
          } 
          else {
            row.push({ text: record[dataIndex], options: { ...dataOptions, align: 'left', margin: recLength && recLength ? 0 : [0, 0, 0, 0.2] } });
          }
        }
        });
      } else {
        if (renderPPT)
          row.push({
            text: renderPPT(record[dataIndex]),
            options: dataOptions,
          });
        else row.push({ text: record[dataIndex], options: { ...dataOptions, align: 'left', margin: recLength && recLength ? 0 : [0, 0, 0, 0.2] } });
      }
    });
    rows.push(row);
    if ((index + 1) % pageSize === 0) {
      pages.push(rows.splice(0));
      rows.push(header);
      if (hasChildren) rows.push(subHeader);
    }
})
  if (data.length < pageSize) pages.push(rows);
  return pages;
}

export function createDGCustomTable(columns, data, isDataBold = true, pageSize = 20, filters,fixedColumn) {
  console.log("columns", columns, filters);
  const headerOptions = {
    // bold: true,
    align: "center",
    fill: "0000A0",
    color: "ffffff",
    border: { type: "solid" },
  };
  const dataOptions = {
    // bold: isDataBold,
    align: "center",
    border: { type: "solid" },
  };

  const header = [];
  const subHeader = [];
  const headerText = [];
  const rows = [];
  const pages = [];

  columns.forEach(({ title, children }) => {
    // console.log("title, children", title, children);
      header.push({
      text: title,
      options: { ...headerOptions, colspan: children.length },
    });
    if (children) {
      children.map(({ title, children }) => {
        if(children){
          subHeader.push({
            text: title,
            options: { ...headerOptions},
          })
          children.map(({title}, index) => {
            if(title === filters){
            headerText.push({
              text: title,
              options: headerOptions,
            })
          } 
          else {
            if(!!fixedColumn.find((i)=> i === title)){
            headerText.push({
              text: title,
              options: headerOptions,
            })
          }
          }
          })
      }else {
        subHeader.push({
          text: title,
          options: headerOptions,
        })
      }
        }

      );
    } else {
      header.push({ text: title, options: headerOptions });
    }
  });
  const hasChildren = subHeader.length > 0;
  rows.push(header);
  if (hasChildren) rows.push(subHeader);
  rows.push(headerText);
  // data
  data.forEach((record, index) => {
    const row = [];
    columns.forEach(({ dataIndexPPT, children, renderPPT }) => {
      let recLength = record?.retailer_data
      if (children) {
        children.forEach(({ children, dataIndexPPT, renderPPT }) => { // second level loop
          if(children){
            children.forEach(({ dataIndexPPT, renderPPT, title }) => {
              if (renderPPT){
               Object.keys(record[dataIndexPPT]).forEach((val) => {
                if(val===filters && val === title){
                  row.push({
                    text: renderPPT(record[dataIndexPPT][val]),
                    options: dataOptions,
                  })
                } else {
                  if(!!fixedColumn.find((i)=> i === val)){
                    row.push({
                      text: renderPPT(record[dataIndexPPT][val]),
                      options: dataOptions,
                    })
                  }
                }
                })

              } else{
                row.push({
                  text: record[dataIndexPPT],
                  options: dataOptions,
                })
              }
            })
          } else {
          if (renderPPT){
            row.push({
              text: renderPPT(record[dataIndexPPT]),
              options: dataOptions,
            });
          } 
          else {
            row.push({ text: record[dataIndexPPT], options: { ...dataOptions, align: 'left', margin: recLength && recLength ? 0 : [0, 0, 0, 0.2] } });
          }
        }
        });
      } else {
        if (renderPPT)
          row.push({
            text: renderPPT(record[dataIndexPPT]),
            options: dataOptions,
          });
        else row.push({ text: record[dataIndexPPT], options: { ...dataOptions, align: 'left', margin: recLength && recLength ? 0 : [0, 0, 0, 0.2] } });
      }
    });
    rows.push(row);
    if ((index + 1) % pageSize === 0) {
      pages.push(rows.splice(0));
      rows.push(header);
      if (hasChildren) rows.push(subHeader);
    }
})
  if (data.length < pageSize) pages.push(rows);
  return pages;
}

export function createSTCustomTable(columns, data, isDataBold = true, pageSize = 20, filters,fixedColumn, viewBy) {
  
  const headerOptions = {
    // bold: true,
    align: "center",
    fill: "0000A0",
    color: "ffffff",
    border: { type: "solid" },
  };
  const dataOptions = {
    // bold: isDataBold,
    align: "center",
    border: { type: "solid" },
  };

  const header = [];
  const subHeader = [];
  const headerText = [];
  const rows = [];
  const pages = [];

  columns && columns.forEach(({ title, children }) => {
    
      header.push({
      text: title,
      options: { ...headerOptions, colspan: 1 },
    });
    if (children) {
      children.map(({ title, children }) => {
        if(children){
            children.map(({title}, index) => {
            if(title === filters){
            headerText.push({
              text: title,
              options: headerOptions,
            })
          } 
          else {
            if(!!fixedColumn.find((i)=> i === title)){
            headerText.push({
              text: title,
              options: headerOptions,
            })
          }
          }
          })
      }else {
        if(title === filters){
        subHeader.push({
          text: title,
          options: headerOptions,
        })
      } else {
        if(!!fixedColumn.find((i)=> i === title || title === "")){
          subHeader.push({
            text: title,
            options: headerOptions,
          })
        } 
      }
      }
        }
      );
}})
    
  const hasChildren = subHeader.length > 0;
  rows.push(header);
  if (hasChildren) rows.push(subHeader);
  // rows.push(headerText);

  // data
  data.forEach((record, index) => {
    const row = [];
    columns && columns.forEach(({ dataIndex, children, renderPPT }) => {
      if (children) {
        children.forEach(({ children, title, dataIndex, renderPPT }) => { // second level loop
          if(renderPPT){
          Object.keys(record[dataIndex]).forEach((val) => {
            if(val===filters && val === title){
              row.push({
                text: renderPPT(record[dataIndex][val]),
                options: dataOptions,
              })
            } else {
              if(!!fixedColumn.find((i)=> i === val)){
                row.push({
                  text: renderPPT(record[dataIndex][val]),
                  options: dataOptions,
                })
              }
            }
          })
        }else {
          row.push({
            text: record[dataIndex],
            options: dataOptions,
          });
        }
        });
      } else {
          row.push({
            text: record[dataIndex],
            options: dataOptions,
          });
      }
    });
    rows.push(row);
    if ((index + 1) % pageSize === 0) {
      pages.push(rows.splice(0));
      rows.push(header);
      if (hasChildren) rows.push(subHeader);
    }
})
  if (data.length < pageSize) pages.push(rows);
  return pages;
}

export function createBACustomTable(
  columns,
  data,
  isDataBold = true,
  pageSize = 20,
  filters,
  fixedColumn
) {
  console.log("columns", columns, filters, data);
  const headerOptions = {
    // bold: true,
    align: "center",
    fill: "0000A0",
    color: "ffffff",
    border: { type: "solid" },
  };
  const dataOptions = {
    // bold: isDataBold,
    align: "center",
    border: { type: "solid" },
  };

  const header = [];
  const subHeader = [];
  const headerText = [];
  const rows = [];
  const pages = [];

  columns.forEach(({ title, children }) => {
    // console.log("title, children", title, children);
    if (children) {
      header.push({
        text: title,
        options: { ...headerOptions },
      });
      subHeader.push(
        ...children.filter(({ title }) => title === filters || fixedColumn.includes(title)).map(({ title }) => ({
          text: title,
          options: headerOptions,
        }))
      );
    } else {
      header.push({ text: title, options: headerOptions });
    }
  });

  // console.log("header", header);
  const hasChildren = subHeader.length > 0;
  rows.push(header);
  if (hasChildren) rows.push(subHeader);

  // rows.push(headerText);
  // data
  data.forEach((record, index) => {
    const row = [];
    columns.forEach(({ dataIndexPPT, children, renderPPT }) => {
      let recLength = record?.table_hierarchy_data;
      if (children) {
        children.forEach(({ dataIndexPPT, renderPPT, title }) => {
          // second level loop
          if (renderPPT) {
            Object.keys(record[dataIndexPPT]).forEach((val) => {
              if (val === filters && val === title) {
                row.push({
                  text: renderPPT(record[dataIndexPPT][val]),
                  options: dataOptions,
                });
              } else {
                if (!!fixedColumn.find((i) => i === val)) {
                  row.push({
                    text: renderPPT(record[dataIndexPPT][val]),
                    options: dataOptions,
                  });
                }
              }
            });
          } else {
            row.push({
              text: record[dataIndexPPT],
              options: dataOptions,
            });
          }
        });
      } else {
        if (renderPPT)
          row.push({
            text: renderPPT(record[dataIndexPPT]),
            options: dataOptions,
          });
        else
          row.push({
            text: record[dataIndexPPT],
            options: {
              ...dataOptions,
              align: "left",
              margin: recLength && recLength ? 0 : [0, 0, 0, 0.2],
            },
          });
      }
    });
    rows.push(row);
    if ((index + 1) % pageSize === 0) {
      pages.push(rows.splice(0));
      rows.push(header);
      if (hasChildren) rows.push(subHeader);
    }
  });
  if (data.length < pageSize) pages.push(rows);
  return pages;
}

export function createCBCustomTable(
  columns,
  data,
  isDataBold = true,
  pageSize = 20,
  filters,
  fixedColumn
) {
  console.log("columns", columns, filters, data);
  const headerOptions = {
    // bold: true,
    align: "center",
    fill: "0000A0",
    color: "ffffff",
    border: { type: "solid" },
  };
  const dataOptions = {
    // bold: isDataBold,
    align: "center",
    border: { type: "solid" },
  };

  const header = [];
  const subHeader = [];
  const headerText = [];
  const rows = [];
  const pages = [];

  columns.forEach(({ title, children }) => {
    // console.log("title, children", title, children);
    if (children) {
      header.push({
        text: title,
        options: { ...headerOptions },
      });
      subHeader.push(
        ...children.filter(({ title }) => title === filters || fixedColumn.includes(title)).map(({ title }) => ({
          text: title,
          options: headerOptions,
        }))
      );
    } else {
      header.push({ text: title, options: headerOptions });
    }
  });

  // console.log("header", header);
  const hasChildren = subHeader.length > 0;
  rows.push(header);
  if (hasChildren) rows.push(subHeader);

  // rows.push(headerText);
  // data
  data.forEach((record, index) => {
    const row = [];
    columns.forEach(({ dataIndexPPT, children, renderPPT }) => {
      let recLength = record?.table_hierarchy_data;
      if (children) {
        children.forEach(({ dataIndexPPT, renderPPT, title }) => {
          // second level loop
          if (renderPPT) {
            if (record[dataIndexPPT]) {
              Object.keys(record[dataIndexPPT]).forEach((val) => {
                if (val === filters && val === title) {
                  row.push({
                    text: renderPPT(record[dataIndexPPT][val]),
                    options: dataOptions,
                  });
                } else {
                  if (!!fixedColumn.find((i) => i === val)) {
                    row.push({
                      text: renderPPT(record[dataIndexPPT][val]),
                      options: dataOptions,
                    });
                  }
                }
              });
            }
          } else {
            row.push({
              text: record[dataIndexPPT],
              options: dataOptions,
            });
          }
        });
      } else {
        if (renderPPT)
          row.push({
            text: renderPPT(record[dataIndexPPT]),
            options: dataOptions,
          });
        else
          row.push({
            text: record[dataIndexPPT],
            options: {
              ...dataOptions,
              align: "left",
              margin: recLength && recLength ? 0 : [0, 0, 0, 0.2],
            },
          });
      }
    
    });
    rows.push(row);
    if ((index + 1) % pageSize === 0) {
      pages.push(rows.splice(0));
      rows.push(header);
      if (hasChildren) rows.push(subHeader);
    }
  });
  if (data.length < pageSize) pages.push(rows);
  return pages;
}

export async function pptExportSos(exportData, macroColumns, microColumns) {
    const { kpi, bar, line, macroTableData, microTableData, bubbleChart } = exportData
    console.log("exportData", exportData)
    try {
      if (Object.keys(macroTableData.data).length === 0) throw new Error("No Data");
  
      let pres = new pptxgen();
  
      // 1. Define the layout
      pres.defineLayout({ name: "A3", width: 13, height: 7.5 });
      pres.layout = "A3";
      // 2. Add a Slide to the presentation
      const slide = pres.addSlide();
  
      slide.addText(`Report Filters: Brand – ${exportData?.filters.product_brand};  Pet – ${exportData?.filters.product_pet}; Technology – ${exportData?.filters.product_technology}; Feeding Philosophy - ${exportData?.filters.product_feed_philosophy}; Texture - ${exportData?.filters.product_texture}; Retailer - ${exportData?.filters.customer_retailer_details}; Time Aggregate - ${exportData?.filters.time_aggregate}  Year Period – ${exportData?.filters?.year_period}; Benchmark Period – ${exportData?.filters?.benchmark}`, { x: 0.5, y: 1.5, fontSize: 14, fontFace: "Mars Centra" });
   
      slide.addText(`Keyword Filters: Keyword Category – ${exportData?.filters.product_keyword_category};  Keyword Type – ${exportData?.filters.product_keyword_type}; Keyword – ${exportData?.filters.product_keyword};`, { x: 0.5, y: 2.5, fontSize: 14, fontFace: "Mars Centra" });


      const textOptionsChart = {
        w: 2.2,
        h: 1.5,
        y: 3.5,
        align: "center",
        line: { width: "2", color: "dcdcdc" },
        fill: "ffffff",
        shape: pres.ShapeType.roundRect,
        lineSpacing: 35,
        rectRadius: 0.2,
        fontSize: 20,
        margin: 0.5
      };
      slide.addText([
        { text: `Market Share`, options: { breakLine: true, fontSize: 14, bold: true, fontFace: "Mars Centra" } },
        { text: `${formatNumber(kpi?.data?.fact_dollar_impressions.abs)} %   ${formatNumber(kpi?.data?.fact_dollar_impressions.variance)} pts`, options: { breakLine: true, fontSize: 17, fontFace: "Mars Centra", color: '0000A0' } },
      ], {
        ...textOptionsChart,
        x: 0.4,
        y: 3.5,
      });
      slide.addText([
        { text: `Total Share of Shelf`, options: { breakLine: true, fontSize: 14, bold: true, fontFace: "Mars Centra" } },
        { text: `${formatNumber(kpi?.data?.fact_total_impressions.abs)} %   ${formatNumber(kpi?.data?.fact_total_impressions.variance)} pts`, options: { breakLine: true, fontSize: 17, fontFace: "Mars Centra", color: '0000A0' } },
      ], {
        ...textOptionsChart,
        x: 2.9,
        y: 3.5,
      });
      slide.addText([
        { text: `Organic Share of Shelf`, options: { breakLine: true, fontSize: 14, bold: true, fontFace: "Mars Centra" } },
        { text: `${formatNumber(kpi?.data?.fact_organic_impressions.abs)} %   ${formatNumber(kpi?.data?.fact_organic_impressions.variance)} pts`, options: { breakLine: true, fontSize: 17, fontFace: "Mars Centra", color: '0000A0' } },
      ], {
        ...textOptionsChart,
        x: 5.4,
        y: 3.5,
      });
      slide.addText([
        { text: `Paid Share of Shelf`, options: { breakLine: true, fontSize: 14, bold: true, fontFace: "Mars Centra" } },
        { text: `${formatNumber(kpi?.data?.fact_paid_impressions.abs)} %   ${formatNumber(kpi?.data?.fact_paid_impressions.variance)} pts`, options: { breakLine: true, fontSize: 17, fontFace: "Mars Centra", color: '0000A0' } },
      ], {
        ...textOptionsChart,
        x: 7.9,
        y: 3.5,
      });
      slide.addText([
        { text: `Average Placement`, options: { breakLine: true, fontSize: 14, bold: true, fontFace: "Mars Centra" } },
        { text: `${kpi?.data?.fact_average_placements.abs}   ${kpi?.data?.fact_average_placements.variance}`, options: { breakLine: true, fontSize: 17, fontFace: "Mars Centra", color: '0000A0' } },
      ], {
        ...textOptionsChart,
        x: 10.4,
        y: 3.5,
      });
  
//slide 2
      const slide2 = pres.addSlide();
      slide2.addText(`Report Filters: Brand – ${exportData?.filters.product_brand};  Pet – ${exportData?.filters.product_pet}; Technology – ${exportData?.filters.product_technology}; Feeding Philosophy - ${exportData?.filters.product_feed_philosophy}; Texture - ${exportData?.filters.product_texture}; Retailer - ${exportData?.filters.customer_retailer_details}; Time Aggregate - ${exportData?.filters.time_aggregate}  Year Period – ${exportData?.filters?.year_period}; Benchmark Period – ${exportData?.filters?.benchmark}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });
   
      slide2.addText(`Keyword Filters: Keyword Category – ${exportData?.filters.product_keyword_category};  Keyword Type – ${exportData?.filters.product_keyword_type}; Keyword – ${exportData?.filters.product_keyword};`, { x: 0.5, y: 1.0, fontSize: 14, fontFace: "Mars Centra" });


      slide2.addText(`Filters: View by – ${exportData?.filters.view_by[0]};  Share of Shelf Type – ${exportData?.filters.performance_summary_type[0]};  Keyword Type – ${exportData?.filters.performance_summary_keyword};  KPI – ${exportData?.filters.performance_summary_kpi};`, { x: 0.5, y: 1.3, fontSize: 14, fontFace: "Mars Centra" });

      const COLORS_CHART = ["#0000a0", "#e559a4"];
      const labelsSeries = bar?.data.map((item) => item.Retailer);
      // const value1 = bar?.data.map((item) => item[bar.yField[1]])
      // const value2 = bar?.data.map((item) => item[bar.yField[0]])
      const value1 = bar?.data.map((item) => item[bar.yField[0]]);
      const value2 = bar?.data.map((item) => item[bar.yField[1]]);

    // const dataChartBarSeries = [
    //     { name: bar?.yField[0], labels: labelsSeries, values: value2 },
    //     { name: bar?.yField[1], labels: labelsSeries, values: value1 },
    // ];
    let optsChart = {
		x: 0.5,
		y: 2,
		w: "45%",
		h: "70%",
		barDir: "col",
    barGrouping: "stacked",
		chartColors: COLORS_CHART,
		showLegend: true,
		showTitle: true,
		title: `${exportData.filters.performance_summary_type[0]} Share of Shelf - ${exportData.filters.view_by[0]} Comparison`,
		titleFontFace: "Mars Centra",
    titleFontSize: 15,
		// showCatAxisTitle: true,
		// catAxisTitle: `${bar.xField}`,
		catAxisTitleFontSize: 10,
		valAxisTitleFontSize: 10,
    legendPos: 'b',
    showValAxisTitle: true,
    // valAxisTitle: `${bar?.yField[0]} Share of Shlef`,
    // valAxisTitle: `${line?.yField[0]}`,
    valAxes: [
      {
        showValAxisTitle: true,
        valAxisTitle: `${bar?.yField[0]} Share of Shlef`,
      },
      {
        showValAxisTitle: true,
        valAxisTitle: `${bar?.yField[1]}`,
        valGridLine: { style: "none" },
      },
    ],

    catAxes: [
      {
        showCatAxisTitle: true,
        catAxisTitle: `${bar.xField}`,
      },
      {
        catAxisHidden: true,
      },
    ],
	};

  let chartTypes1 = [
    {
      type: pres.charts.BAR,
      data: [
        { name: bar?.yField[0], labels: labelsSeries, values: value1 },
      ],
      options: {
        chartColors: ["0000a0"],
        barGrouping: "standard",
      },
    },
    {
      type: pres.charts.BAR,
      data: [
        { name: bar?.yField[1], labels: labelsSeries, values: value2 },
      ],
      options: {
        chartColors: ["e559a4"],
        barGrouping: "standard",
        barGapWidthPct: 185,
        secondaryValAxis: !!optsChart.valAxes,
        secondaryCatAxis: !!optsChart.catAxes,
      },
    },
  ];

  // slide2.addChart(pres.charts.BAR, dataChartBarSeries, optsChart);
  slide2.addChart(chartTypes1, optsChart);


if(exportData.filters.deep_dive_list.length > 1){
  // multiline chart
  const chartLabels = line.data.map((item) => item.Periods)
  const lineChart1 = line.data.map((item) => item[line?.series[0]])
  const lineChart2 = line.data.map((item) => item[line?.series[1]])
  let optsChartLine3 = {
    x: 7,
    y: 2,
    w: "45%",
    h: "70%",
    barDir: "col",
    titleFontFace: "Mars Centra",
    showLegend: true,
    showTitle: true,
    titlePos: {x: 1.2, y: 1.0},
    title: `${exportData.filters.performance_summary_type[0]} Share of Shelf -
    ${exportData.filters.deep_dive_list.length === 1
      ? exportData.filters.deep_dive_list[0]
      : "Deep Dive"}`,
    catAxisTitleFontSize: 10,
    valAxisTitleFontSize: 10,
    titleFontSize: 15,
    showValAxisTitle: true,
    valAxisTitle: `${line?.yField[1]}`,
    lineDataSymbolSize: 10,
    shadow: { type: "none" },
    //displayBlanksAs: 'gap', // NOTE: uncomment only for test - looks broken otherwise!
    legendPos: "b",
  };
   const arrDataLineStat = [
    { name:line?.series[0], labels: chartLabels, values: lineChart1 },
    { name: line?.series[1], labels: chartLabels, values: lineChart2 },
  ];
  slide2.addChart(pres.charts.LINE, arrDataLineStat, optsChartLine3);
} else {
  // line chart
const chartLabels = line.data.map((item) => item.Periods)
const chartValue1 = line.data.map((item) => item[line?.yField[0]])
const chartValue2 = line.data.map((item) => item[line?.yField[1]])

let opts1 = {
      x: 7,
			y: 2,
			w: "45%",
			h: "70%",
			barDir: "col",
			titleFontFace: "Mars Centra",
			showLegend: true,
      showTitle: true,
      titlePos: {x: 1.2, y: 1.0},
      title: `${exportData.filters.performance_summary_type[0]} Share of Shelf -
      ${exportData.filters.deep_dive_list.length === 1
        ? exportData.filters.deep_dive_list[0]
        : "Deep Dive"}`,
      catAxisTitleFontSize: 10,
      valAxisTitleFontSize: 10,
      titleFontSize: 15,
			valAxes: [
				{
					showValAxisTitle: true,
					valAxisTitle: `${line?.yField[0]} Share of Shlef`,
				},
				{
					showValAxisTitle: true,
					valAxisTitle: `${line?.yField[1]}`,
					valGridLine: { style: "none" },
				},
			],

			catAxes: [
				{
          showCatAxisTitle: true,
					catAxisTitle: "Periods",
				},
				{
					catAxisHidden: true,
				},
			],
      legendPos: 'b'
};
let chartTypes1 = [
  {
    type: pres.charts.BAR,
    data: [
      {
        name: line?.yField[0],
        labels: chartLabels,
        values: chartValue1,
      },
    ],
    options: {
      chartColors: ["0000a0"],
      barGrouping: "stacked",
    },
  },
  {
    type: pres.charts.LINE,
    data: [
      {
        name: line?.yField[1],
        labels: chartLabels,
        values: chartValue2,
      },
    ],
    options: {
      chartColors: ["e559a4"],
      barGrouping: "standard",
      secondaryValAxis: !!opts1.valAxes,
      secondaryCatAxis: !!opts1.catAxes,
    },
  },

];
slide2.addChart(chartTypes1, opts1);
}

//slide 3
for (const item of [...exportData?.filters?.customer_retailer_details, ...exportData?.filters?.selected_retailer]) {
 window[`slide3${item}`] = pres.addSlide();

window[`slide3${item}`].addText(`Report Filters: Brand – ${exportData?.filters.product_brand};  Pet – ${exportData?.filters.product_pet}; Technology – ${exportData?.filters.product_technology}; Feeding Philosophy - ${exportData?.filters.product_feed_philosophy}; Texture - ${exportData?.filters.product_texture}; Retailer - ${exportData?.filters.customer_retailer_details}; Time Aggregate - ${exportData?.filters.time_aggregate}  Year Period – ${exportData?.filters?.year_period}; Benchmark Period – ${exportData?.filters?.benchmark}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });
   
window[`slide3${item}`].addText(`Keyword Filters: Keyword Category – ${exportData?.filters.product_keyword_category};  Keyword Type – ${exportData?.filters.product_keyword_type}; Keyword – ${exportData?.filters.product_keyword};`, { x: 0.5, y: 1, fontSize: 14, fontFace: "Mars Centra" });

window[`slide3${item}`].addText(`Category - Share of Shelf - ${item}`, { x: 5.5, y: 1.6, fontSize: 15, fontFace: "Mars Centra" });
const numberOfData = macroTableData.data.slice(0,7)


  const macroTable = createEcomCustomTable(macroColumns, numberOfData, false, 20, item,["Category"]);
  
  window[`slide3${item}`].addTable(macroTable[0], {
        x: 0.5,
        y: 1.9,
        h: 4.3,
        // w: 19,
        align: "center",
        // colW: 1,
      });
      window[`slide3${item}`].addText("For complete table, please refer to the excel extract - share-of-shelf(tables).xlsx", { x: 0.5, y: 7.2, fontSize: 14, fontFace: "Mars Centra" });
   
}


//slide 4
if(microTableData.data && microTableData.data.length){
for (const item of [...exportData?.filters?.customer_retailer_details, ...exportData?.filters?.selected_retailer]) {
  window[`slide4${item}`] = pres.addSlide();

window[`slide4${item}`].addText(`Report Filters: Brand – ${exportData?.filters.product_brand};  Pet – ${exportData?.filters.product_pet}; Technology – ${exportData?.filters.product_technology}; Feeding Philosophy - ${exportData?.filters.product_feed_philosophy}; Texture - ${exportData?.filters.product_texture}; Retailer - ${exportData?.filters.customer_retailer_details}; Time Aggregate - ${exportData?.filters.time_aggregate}  Year Period – ${exportData?.filters?.year_period}; Benchmark Period – ${exportData?.filters?.benchmark}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });
   
window[`slide4${item}`].addText(`Keyword Filters: Keyword Category – ${exportData?.filters.product_keyword_category};  Keyword Type – ${exportData?.filters.product_keyword_type}; Keyword – ${exportData?.filters.product_keyword};`, { x: 0.5, y: 1, fontSize: 14, fontFace: "Mars Centra" });

window[`slide4${item}`].addText(`${microTableData.title} - ${item}`, { x: 5, y: 1.6, fontSize: 15, fontFace: "Mars Centra" });
const numberOfColumns = microTableData.data.slice(0,7)
const microTable = createEcomCustomTable(microColumns, numberOfColumns, false,20, item,["Keyword Type", "Keywords"]);

    window[`slide4${item}`].addTable(microTable[0], {
      x: 0.5,
      y: 1.9,
      h: 4.3,
      // w: 19,
      align: "center",
      // colW: 1,
    });
    window[`slide4${item}`].addText("For complete table, please refer to the excel extract - share-of-shelf(tables).xlsx", { x: 0.5, y: 7.2, fontSize: 14, fontFace: "Mars Centra" });

  }
}
    //slide 5
const slide5 = pres.addSlide();

slide5.addText(`Report Filters: Brand – ${exportData?.filters.product_brand};  Pet – ${exportData?.filters.product_pet}; Technology – ${exportData?.filters.product_technology}; Feeding Philosophy - ${exportData?.filters.product_feed_philosophy}; Texture - ${exportData?.filters.product_texture}; Retailer - ${exportData?.filters.customer_retailer_details}; Time Aggregate - ${exportData?.filters.time_aggregate}  Year Period – ${exportData?.filters?.year_period}; Benchmark Period – ${exportData?.filters?.benchmark}`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });
   
slide5.addText(`Keyword Filters: Keyword Category – ${exportData?.filters.product_keyword_category};  Keyword Type – ${exportData?.filters.product_keyword_type}; Keyword – ${exportData?.filters.product_keyword};`, { x: 0.5, y: 1, fontSize: 14, fontFace: "Mars Centra" });

slide5.addText(`Keyword Strategy`, { x: 5, y: 1.6, fontSize: 15, fontFace: "Mars Centra" });
const keywords = bubbleChart?.data.map((val) => val.keyword)
const trafficData = bubbleChart?.data.map((val) => val[bubbleChart.yField])
const sosChangeData = bubbleChart?.data.map((val) => val[bubbleChart.xField])
const bubbleSize = bubbleChart.data.map((item) => {
    var randNum =  Math.floor(Math.random() * 20) + 1;
    return randNum
  })
let arrDataBubble1 = [
  { name: "keywords", labels: keywords, values: sosChangeData },
  { name: "keywords",labels: keywords, values: trafficData },
];

// TOP-LEFT
let optsChartBubble1 = {
  x: 0.5,
  y: 1.9,
  h: 5,
  w: 11,
  chartArea: { fill: { color: "F1F1F1" } },
  chartColors: ["0000a0"],
  lineSize: 0,
  chartColorsOpacity: 40,
  catAxisTitleFontSize: 10,
  valAxisTitleFontSize: 10,
  titleFontSize: 15,
  titleFontFace: "Mars Centra",
  //valAxisCrossesAt: 4,
  //catAxisCrossesAt: 4,
  dataLabelFontFace: "Mars Centra",
  dataLabelFontSize: 10,
  dataLabelColor: "363636",
  dataLabelPosition: "r",
  showSerName: true,
  showLeaderLines: true,
  showCatAxisTitle: true,
  catAxisTitle: `${bubbleChart.xField} %`,
  legendPos: 'b',
  showValAxisTitle: true,
  showLabel: true,
  valAxisTitle: `${bubbleChart?.yField} %`,
};
slide5.addChart(pres.charts.SCATTER, arrDataBubble1, optsChartBubble1);



      pres.writeFile({ fileName: 'Share of Shelf' })
      openNotificationWithIcon("success", "Downloading..");
    } catch (error) {
      console.log("error", error)
      openNotificationWithIcon("error", "Download Failed");
    }
  }

  export async function pptExportST(exportData, tableColumn, appliedFilters, searchTermOverview) {
    const { kpi, bar, line, tableData, filters, bubbleChart } = exportData
    
    try {
      // if (Object.keys(macroTableData.data).length === 0) throw new Error("No Data");
  
      let pres = new pptxgen();
  
      // 1. Define the layout
      pres.defineLayout({ name: "A3", width: 13, height: 7.5 });
      pres.layout = "A3";
      // 2. Add a Slide to the presentation
      const slide = pres.addSlide();
  
      slide.addText(`Filters: Keyword Category – ${appliedFilters.product_keyword_category};  Keyword Type – ${filters.product_keyword_type}; Feeding Philosophy – ${filters.product_feed_philosophy}; Texture/Format - ${filters.product_texture}; Price Tier - ${filters.product_price_tier}; Function - ${filters.product_function}; Life Stage - ${filters.product_life_stage}  Breed Size – ${filters.product_breed_size}; Package Type – ${filters?.product_package_type}; Protein – ${filters?.product_protein}; Retailer – ${filters.customer_retailer_details};  Time Aggregate – ${filters.time_aggregate}; Year Period – ${filters.year_period}; Benchmark Period – ${filters.benchmark};`, { x: 0.5, y: 1, fontSize: 14, fontFace: "Mars Centra" });
   
      slide.addText(`Filters: View By – ${filters.view_by};  ${filters.trend_product_brand ? `Brand – ${filters.product_keyword_type};` : ''} View – ${filters.trend_view} - ${filters.trend_limit[0] === "ALL" ? "All" : "custom"} - ${filters?.trend_limit}; Total Search Terms - ${searchTermOverview.total_search_term_count}; ${searchTermOverview.search_term_count} Search Terms ${formatNumber(searchTermOverview.search_term_percent)} % of Search Terms = ${formatNumber(searchTermOverview.traffic_percent)}%`, { x: 0.5, y: 1.7, fontSize: 14, fontFace: "Mars Centra" });

      const COLORS_CHART = ["#0000a0", "#e559a4"];
      const labelsSeries = bar?.data.map((item) => item[bar.xField]);
      const value1 = bar?.data.map((item) => item[bar.yField[1]])
      const value2 = bar?.data.map((item) => item[bar.yField[0]])
      let opts1 = {
        x: 0.5,
        y: 2,
        w: "45%",
        h: "70%",
        barDir: "col",
        titleFontFace: "Mars Centra",
        showLegend: true,
        showTitle: true,
        chartColors: COLORS_CHART,
        titlePos: {x: 1.2, y: 1.0},
        title: 'Search Terms',
        catAxisTitleFontSize: 10,
        valAxisTitleFontSize: 10,
        titleFontSize: 15,
        valAxisLabelFormatCode: '[>=1000000]#,##0.0,,"M";[>=1000]#,##0,"K"',
        dataLabelFontFace: "Mars Centra",
        catAxisLabelFontSize: 12,
        dataLabelFontSize: 8,
        valAxes: [
          {
            showValAxisTitle: true,
            valAxisTitle: `${bar?.yField[0]} Share of Shlef`,
          },
          {
            showValAxisTitle: true,
            valAxisTitle: `${bar?.yField[1]}`,
            valGridLine: { style: "none" },
          },
        ],
  
        catAxes: [
          {
            showCatAxisTitle: true,
            catAxisTitle: `${bar?.xField}`,
          },
          {
            catAxisHidden: true,
          },
        ],
        legendPos: 'b'
    };
        let chartTypes1 = [
          {
            type: pres.charts.BAR,
            data: [
              {
                name: bar?.yField[0],
                labels: labelsSeries,
                values: value2,
              },
            ],
            options: {
              chartColors: ["0000a0"],
              barGrouping: "stacked",
            },
          },
          {
            type: pres.charts.LINE,
            data: [
              {
                name: bar?.yField[1],
                labels: labelsSeries,
                values: value1,
              },
            ],
            options: {
              chartColors: ["e559a4"],
              barGrouping: "standard",
              secondaryValAxis: !!opts1.valAxes,
              secondaryCatAxis: !!opts1.catAxes,
            },
          },
        
        ];
        slide.addChart(chartTypes1, opts1);



  let optsChartLine3 = {
    x: 6.8,
    y: 2,
    w: "45%",
    h: "70%",
    barDir: "col",
    titleFontFace: "Mars Centra",
    showLegend: true,
    showTitle: true,
    titlePos: {x: 1.2, y: 1.0},
    title: `Search Term - Trend`,
    valAxisLabelFormatCode: '[>=1000000]#,##0.0,,"M";[>=1000]#,##0,"K"',
    dataLabelFontFace: "Mars Centra",
    catAxisLabelFontSize: 12,
    catAxisTitleFontSize: 10,
    valAxisTitleFontSize: 10,
    titleFontSize: 15,
    showValAxisTitle: true,
    valAxisTitle: `${line?.yField}`,
    showCatAxisTitle: true,
		catAxisTitle: `${line?.xField}`,
    lineDataSymbolSize: 10,
    shadow: { type: "none" },
    legendPos: "b",
  };
  const lineData = line.series.map((val) => {
    const chartLabels = line.data.map((item) => item[line?.xField])
    const keywordData = line.data.map((item) => item[val])
    return({
      name: val,
      labels: chartLabels,
      values: keywordData
    })
  })
  slide.addChart(pres.charts.LINE, lineData, optsChartLine3);

// slide 2


if(filters?.retailer_view[0] === "Retailer View"){
for (const item of [...filters?.customer_retailer_details, ...filters?.selected_retailer]) {
    window[`slide2${item}`] = pres.addSlide();
   
    window[`slide2${item}`].addText(`Filters: Keyword Category – ${appliedFilters.product_keyword_category};  Keyword Type – ${filters.product_keyword_type}; Feeding Philosophy – ${filters.product_feed_philosophy}; Texture/Format - ${filters.product_texture}; Price Tier - ${filters.product_price_tier}; Function - ${filters.product_function}; Life Stage - ${filters.product_life_stage}  Breed Size – ${filters.product_breed_size}; Package Type – ${filters?.product_package_type}; Protein – ${filters?.product_protein}; Retailer – ${filters.customer_retailer_details};  Time Aggregate – ${filters.time_aggregate}; Year Period – ${filters.year_period}; Benchmark Period – ${filters.benchmark};`, { x: 0.5, y: 1, fontSize: 14, fontFace: "Mars Centra" });

   
   window[`slide2${item}`].addText(`Search Terms - Retailer View`, { x: 5, y: 1.6, fontSize: 15, fontFace: "Mars Centra" });
   const numberOfData = tableData.data.slice(0,7)
   
   
     const searchTable = createSTCustomTable(tableColumn, numberOfData, false, 20, item,["Search Terms"], "retailer-view");
     
     window[`slide2${item}`].addTable(searchTable[0], {
           x: 0.5,
           y: 1.9,
           h: 4.3,
           // w: 19,
           align: "center",
           // colW: 1,
         });
         window[`slide2${item}`].addText("For complete table, please refer to the excel extract - search-term(tables).xlsx", { x: 0.5, y: 7.2, fontSize: 14, fontFace: "Mars Centra" });
      
   }
} else {
  const brands = Object.values(filters?.retailer_view_brand ?? []).flat();

  const retailerBrand = tableData?.brands?.flatMap(item =>
    item.children.filter(brand =>
      brands.some(i => brand.value.includes(i))
    ).map(brand => brand.title)
  ).filter(Boolean);

  // retailer_view_brand
  for (const item of ["TOTAL PET", ...retailerBrand]) {
    window[`slide2${item}`] = pres.addSlide();
   
    window[`slide2${item}`].addText(`Filters: Keyword Category – ${appliedFilters.product_keyword_category};  Keyword Type – ${filters.product_keyword_type}; Feeding Philosophy – ${filters.product_feed_philosophy}; Texture/Format - ${filters.product_texture}; Price Tier - ${filters.product_price_tier}; Function - ${filters.product_function}; Life Stage - ${filters.product_life_stage}  Breed Size – ${filters.product_breed_size}; Package Type – ${filters?.product_package_type}; Protein – ${filters?.product_protein}; Retailer – ${filters.customer_retailer_details};  Time Aggregate – ${filters.time_aggregate}; Year Period – ${filters.year_period}; Benchmark Period – ${filters.benchmark};`, { x: 0.5, y: 1, fontSize: 14, fontFace: "Mars Centra" });

   
   window[`slide2${item}`].addText(`Search Terms - Retailer Deep Dive View (${item})`, { x: 5, y: 1.6, fontSize: 15, fontFace: "Mars Centra" });
   const numberOfData = tableData.data.slice(0,7)
   
   
     const searchTable = createSTCustomTable(tableColumn, numberOfData, false, 20, item,["Search Terms"], "deepDive-view");
     
     window[`slide2${item}`].addTable(searchTable[0], {
           x: 0.5,
           y: 1.9,
           h: 4.3,
           // w: 19,
           align: "center",
           // colW: 1,
         });
         window[`slide2${item}`].addText("For complete table, please refer to the excel extract - search-term(tables).xlsx", { x: 0.5, y: 7.2, fontSize: 14, fontFace: "Mars Centra" });
      
   }
  }

// slide 3
const slide3 = pres.addSlide();
// get brand name
const brands = Object.values(filters?.retailer_view_brand ?? []).flat();

const retailerBrand = tableData?.brands?.flatMap(item =>
  item.children.filter(brand =>
    brands.some(i => brand.value.includes(i))
  ).map(brand => brand.title)
).filter(Boolean);

slide3.addText(`Filters: Keyword Category – ${appliedFilters.product_keyword_category};  Keyword Type – ${filters.product_keyword_type}; Feeding Philosophy – ${filters.product_feed_philosophy}; Texture/Format - ${filters.product_texture}; Price Tier - ${filters.product_price_tier}; Function - ${filters.product_function}; Life Stage - ${filters.product_life_stage}  Breed Size – ${filters.product_breed_size}; Package Type – ${filters?.product_package_type}; Protein – ${filters?.product_protein}; Retailer – ${filters.customer_retailer_details};  Time Aggregate – ${filters.time_aggregate}; Year Period – ${filters.year_period}; Benchmark Period – ${filters.benchmark};`, { x: 0.5, y: 0.5, fontSize: 14, fontFace: "Mars Centra" });

slide3.addText(`View By - ${filters?.strategy_view_by}; ${filters?.strategy_view_by[0] === "Category" ? `Category: ${filters?.strategy_category.length ? filters?.strategy_category : "ALL"}` : `Brand: ${retailerBrand.join()}`}`, { x: 0.5, y: 1.1, fontSize: 14, fontFace: "Mars Centra" });

slide3.addText(`Search Term Strategy`, { x: 5, y: 1.6, fontSize: 15, fontFace: "Mars Centra" });
const keywords = bubbleChart?.data.map((val) => val.keyword)
const trafficData = bubbleChart?.data.map((val) => val[bubbleChart?.xField])
const sosChangeData = bubbleChart?.data.map((val) => val[bubbleChart?.yField])

// const chartColor =  bubbleChart?.data.map((item) => {

//     if(keywords.includes(item?.keyword)) {
//       return item?.manufacturer_color?.slice(1)
//     }
//     })


    // filters?.filters?.strategy_view_by === "Brand" ? 
let arrDataBubble1 = [
  { name: bubbleChart?.xField,labels: keywords, values: trafficData },
  { name: bubbleChart?.yField, labels: keywords, values: sosChangeData },
];

// TOP-LEFT
let optsChartBubble1 = {
  x: 0.5,
  y: 1.9,
  h: 5,
  w: 11,
  chartColors:  ["0000a0"],
  lineSize: 0,
  catAxisTitleFontSize: 10,
  valAxisTitleFontSize: 10,
  titleFontSize: 15,
  titleFontFace: "Mars Centra",
  //valAxisCrossesAt: 4,
  //catAxisCrossesAt: 4,
  dataLabelFontFace: "Mars Centra",
  dataLabelFontSize: 10,
  dataLabelColor: "363636",
  dataLabelPosition: "r",
  showSerName: false,
  showLeaderLines: false,
  showCatAxisTitle: true,
  catAxisTitle: `${bubbleChart.xField} pts`,
  legendPos: 'b',
  showLabel: true,
  showValAxisTitle: true,
  valAxisTitle: `${bubbleChart?.yField} %`,
};
slide3.addChart(pres.charts.SCATTER, arrDataBubble1, optsChartBubble1);


      pres.writeFile({ fileName: 'Search Insights' })
      openNotificationWithIcon("success", "Downloading..");
    } catch (error) {
      
      openNotificationWithIcon("error", "Download Failed");
    }
  }

  export async function pptExportBeAvailable(
    exportData,
    filename,
    macroColumns,
    microColumns,
    defaultBrand
  ) {
    // nutroSKUColumnsExport,
    // allSKUColumnsExport
    console.log("exportData", exportData);
    // console.log("defaultBrand", DefaultBrand);
    // console.log("filename", nutroSKUColumns);
    const { filters, bar, line, macroTableData, microTableData } = JSON.parse(
      JSON.stringify(exportData)
    );
  
    try {
      // if (Object.keys(pptLine).length === 0) throw new Error("No Data");
      // if (Object.keys(pptTableData).length === 0) throw new Error("No Data");
      // if (Object.keys(pptScatter).length === 0) throw new Error("No Data");
  
      let pres = new pptxgen();
      // Define new layout for the Presentation
      pres.defineLayout({ name: "A3", width: 13, height: 7.5 });
      pres.layout = "A3";
  
      const slide = pres.addSlide();
      // Filters & KPIs
      slide.addText(
        [
          { text: "Filters applied:", options: { breakLine: true } },
          {
            text: `Brand - ${defaultBrand}; Pet - ${filters?.product_pet}; Technology - ${filters?.product_technology}; Feeding Philosophy - ${filters?.product_feed_philosophy}; Texture - ${filters?.product_texture};`,
            options: { breakLine: true },
          },
          {
            text: `Retailer - ${filters?.customer_retailer_details} Time Aggregate - ${filters?.time_aggregate}; Year Period - ${filters?.year_period}; Benchmark Period - ${filters?.benchmark};`,
            options: { breakLine: true },
          },
        ],
        {
          x: 0.5,
          y: 0.5,
          w: "90%",
          valign: "top",
          fontSize: 14,
          fontFace: "Mars Centra",
        }
      );
  
  
      slide.addText(`Be Available`, {
        x: 1.5,
        y: 3,
        fontSize: 20,
        fontFace: "Mars Centra",
        align: "center",
        bold: true,
      });
  
      const textOptions = {
        w: 3,
        h: 1.3,
        y: 2.5,
        align: "center",
        line: { width: "2", color: "dcdcdc" },
        fill: "ffffff",
        shape: pres.ShapeType.roundRect,
        rectRadius: 0.2,
        fontSize: 14,
        margin: 0.5,
      };
  
      slide.addText(
        [
          {
            text: `${exportData?.kpi?.listed?.title}`,
            options: {
              breakLine: true,
              fontSize: 18,
              fontFace: "Mars Centra",
              color: "4d4a4a",
              bold: true,
            },
          },
          { text: "\n", options: { breakLine: true } },
          {
            text: `${addSymbol(
              exportData?.kpi?.listed?.value,
              exportData?.kpi?.listed?.valueSymbol
            )}`,
            options: {
              fontSize: 25,
              bold: true,
              fontFace: "Mars Centra",
              color: "0000A0",
            },
          },
          { text: "  " },
          {
            text: `${addSymbol(
              formatNumber(Math.abs(exportData?.kpi?.listed?.change)),
              exportData?.kpi?.listed?.changeSymbol
            )}`,
            options: {
              fontSize: 18,
              bold: true,
              fontFace: "Mars Centra",
              color: exportData?.kpi?.listed?.change < 0 ? "EC3225" : "00C853",
            },
          },
        ],
        {
          ...textOptions,
          x: 1,
          y: 3.5,
        }
      );
      slide.addText(
        [
          {
            text: `${exportData?.kpi?.in_stock?.title}`,
            options: {
              breakLine: true,
              fontSize: 18,
              fontFace: "Mars Centra",
              color: "4d4a4a",
              bold: true,
            },
          },
          { text: "\n", options: { breakLine: true } },
          {
            text: `${addSymbol(
              exportData?.kpi?.in_stock?.value,
              exportData?.kpi?.in_stock?.valueSymbol
            )}`,
            options: {
              fontSize: 25,
              bold: true,
              fontFace: "Mars Centra",
              color: "0000A0",
            },
          },
          { text: "  " },
          {
            text: `${addSymbol(
              formatNumber(Math.abs(exportData?.kpi?.in_stock?.change)),
              exportData?.kpi?.in_stock?.changeSymbol
            )}`,
            options: {
              fontSize: 18,
              bold: true,
              fontFace: "Mars Centra",
              color: exportData?.kpi?.in_stock?.change < 0 ? "EC3225" : "00C853",
            },
          },
        ],
        {
          ...textOptions,
          x: 5,
          y: 3.5,
        }
      );
      slide.addText(
        [
          {
            text: `${exportData?.kpi?.csl?.title}`,
            options: {
              breakLine: true,
              fontSize: 18,
              fontFace: "Mars Centra",
              color: "4d4a4a",
              bold: true,
            },
          },
          { text: "\n", options: { breakLine: true } },
          {
            text: `${addSymbol(
              exportData?.kpi?.csl?.value,
              exportData?.kpi?.csl?.valueSymbol
            )}`,
            options: {
              fontSize: 25,
              bold: true,
              fontFace: "Mars Centra",
              color: "0000A0",
            },
          },
          { text: "  " },
          {
            text: `${addSymbol(
              formatNumber(Math.abs(exportData?.kpi?.csl?.change)),
              exportData?.kpi?.csl?.changeSymbol
            )}`,
            options: {
              fontSize: 18,
              bold: true,
              fontFace: "Mars Centra",
              color: exportData?.kpi?.csl?.change < 0 ? "EC3225" : "00C853",
            },
          },
        ],
        {
          ...textOptions,
          x: 9,
          y: 3.5,
        }
      );
  
      // Line Chart 1
  
      const slide2 = pres.addSlide();
      slide2.addText(
        [
          { text: "Filters applied:", options: { breakLine: true } },
          {
            text: `Brand - ${defaultBrand}; Pet - ${filters?.product_pet}; Technology - ${filters?.product_technology}; Feeding Philosophy - ${filters?.product_feed_philosophy}; Texture - ${filters?.product_texture};`,
            options: { breakLine: true },
          },
          {
            text: `Retailer - ${filters?.customer_retailer_details} Time Aggregate - ${filters?.time_aggregate}; Year Period - ${filters?.year_period}; Benchmark Period - ${filters?.benchmark};`,
            options: { breakLine: true },
          },
        ],
        {
          x: 0.5,
          y: 0.5,
          w: "90%",
          valign: "top",
          fontSize: 14,
          fontFace: "Mars Centra",
        }
      );
  
      const COLORS_CHART = ["#0000a0", "#e559a4"];
      const labelsSeries = bar?.data.map((item) => item.Retailer);
      const value1 = bar?.data.map((item) => item[bar.yField[0]]);
      const value2 = bar?.data.map((item) => item[bar.yField[1]]);
  
      let optsChart = {
        x: 0.5,
        y: 2,
        w: "45%",
        h: "70%",
        barDir: "col",
        barGrouping: "stacked",
        chartColors: COLORS_CHART,
        showLegend: true,
        showTitle: true,
        title: bar.title,
        titleFontFace: "Mars Centra",
        titleFontSize: 15,
        // showCatAxisTitle: true,
        // catAxisTitle: `${bar.xField}`,
        catAxisTitleFontSize: 10,
        valAxisTitleFontSize: 10,
        legendPos: "b",
        showValAxisTitle: true,
        // valAxisTitle: `${line?.yField[0]}`,
        valAxes: [
          {
            showValAxisTitle: true,
            valAxisTitle: `${bar?.yField[0]}`,
          },
          {
            showValAxisTitle: true,
            valAxisTitle: `${bar?.yField[1]}`,
            valGridLine: { style: "none" },
          },
        ],
  
        catAxes: [
          {
            showCatAxisTitle: true,
            catAxisTitle: `${bar.xField}`,
          },
          {
            catAxisHidden: true,
          },
        ],
        
      };
      let chartTypes1 = [
        {
          type: pres.charts.BAR,
          data: [
            { name: bar?.yField[0], labels: labelsSeries, values: value1 },
          ],
          options: {
            chartColors: ["0000a0"],
            barGrouping: "standard",
          },
        },
        {
          type: pres.charts.BAR,
          data: [
            { name: bar?.yField[1], labels: labelsSeries, values: value2 },
          ],
          options: {
            chartColors: ["e559a4"],
            barGrouping: "standard",
            barGapWidthPct: 185,
            secondaryValAxis: !!optsChart.valAxes,
            secondaryCatAxis: !!optsChart.catAxes,
          },
        },
      ];
  
      slide2.addChart(chartTypes1, optsChart);
  
      if (exportData.filters.deep_dive_list.length > 1) {
        // multiline chart
        const chartLabels = line.data.map((item) => item.Periods);
        const lineChart1 = line.data.map((item) => item[line?.series[0]]);
        const lineChart2 = line.data.map((item) => item[line?.series[1]]);
        let optsChartLine3 = {
          x: 7,
          y: 2,
          w: "45%",
          h: "70%",
          barDir: "col",
          titleFontFace: "Mars Centra",
          showLegend: true,
          showTitle: true,
          titlePos: { x: 1.2, y: 1.0 },
          title: line.title,
          catAxisTitleFontSize: 10,
          valAxisTitleFontSize: 10,
          titleFontSize: 15,
          showValAxisTitle: true,
          valAxisTitle: `${line?.yField[1]}`,
          lineDataSymbolSize: 10,
          shadow: { type: "none" },
          legendPos: "b",
        };
        const arrDataLineStat = [
          { name: line?.series[0], labels: chartLabels, values: lineChart1 },
          { name: line?.series[1], labels: chartLabels, values: lineChart2 },
        ];
        slide2.addChart(pres.charts.LINE, arrDataLineStat, optsChartLine3);
      } else {
        // line chart
        const chartLabels = line.data.map((item) => item.Periods);
        const chartValue1 = line.data.map((item) => item[line?.yField[0]]);
        const chartValue2 = line.data.map((item) => item[line?.yField[1]]);
  
        let opts1 = {
          x: 7,
          y: 2,
          w: "45%",
          h: "70%",
          barDir: "col",
          titleFontFace: "Mars Centra",
          showLegend: true,
          showTitle: true,
          titlePos: { x: 1.2, y: 1.0 },
          title: line.title,
          catAxisTitleFontSize: 10,
          valAxisTitleFontSize: 10,
          titleFontSize: 15,
          valAxes: [
            {
              showValAxisTitle: true,
              valAxisTitle: `${line?.yField[0]}`,
            },
            {
              showValAxisTitle: true,
              valAxisTitle: `${line?.yField[1]}`,
              valGridLine: { style: "none" },
            },
          ],
  
          catAxes: [
            {
              showCatAxisTitle: true,
              catAxisTitle: "Periods",
            },
            {
              catAxisHidden: true,
            },
          ],
          legendPos: "b",
        };
        let chartTypes1 = [
          {
            type: pres.charts.BAR,
            data: [
              {
                name: line?.yField[0],
                labels: chartLabels,
                values: chartValue1,
              },
            ],
            options: {
              chartColors: ["0000a0"],
              barGrouping: "stacked",
            },
          },
          {
            type: pres.charts.LINE,
            data: [
              {
                name: line?.yField[1],
                labels: chartLabels,
                values: chartValue2,
              },
            ],
            options: {
              chartColors: ["e559a4"],
              barGrouping: "standard",
              secondaryValAxis: !!opts1.valAxes,
              secondaryCatAxis: !!opts1.catAxes,
            },
          },
        ];
        slide2.addChart(chartTypes1, opts1);
      }
  
      //slide 3
      for (const item of [
        ...exportData?.filters?.customer_retailer_details,
        ...exportData?.filters?.selected_retailer,
      ]) {
        window[`slide3${item}`] = pres.addSlide();
  
        window[`slide3${item}`].addText(
          [
            { text: "Filters applied:", options: { breakLine: true } },
            {
              text: `Brand - ${defaultBrand}; Pet - ${filters?.product_pet}; Technology - ${filters?.product_technology}; Feeding Philosophy - ${filters?.product_feed_philosophy}; Texture - ${filters?.product_texture};`,
              options: { breakLine: true },
            },
            {
              text: `Retailer - ${filters?.customer_retailer_details} Time Aggregate - ${filters?.time_aggregate}; Year Period - ${filters?.year_period}; Benchmark Period - ${filters?.benchmark};`,
              options: { breakLine: true },
            },
          ],
          {
            x: 0.5,
            y: 0.5,
            w: "90%",
            valign: "top",
            fontSize: 14,
            fontFace: "Mars Centra",
          }
        );
  
        window[`slide3${item}`].addText(`Retailer Overview - ${item}`, {
          x: 5.5,
          y: 1.6,
          fontSize: 15,
          fontFace: "Mars Centra",
        });
        const slicedMacro = macroTableData.data.slice(0, 7);
  
        const macroTable = createBACustomTable(
          macroColumns,
          slicedMacro,
          false,
          20,
          item,
          ["Sub Brand"]
        );
  
  
        console.log("macroTable", macroTable);
  
        window[`slide3${item}`].addTable(macroTable[0], {
          x: 0.5,
          y: 1.9,
          h: 4.3,
          // w: 19,
          align: "center",
          // colW: 1,
        });
        window[`slide3${item}`].addText(
          "For complete table, please refer to the excel extract - BeAvailableTableData(tables).xlsx",
          { x: 0.5, y: 7.2, fontSize: 14, fontFace: "Mars Centra" }
        );
      }
  
      //slide 4
      if (microTableData.data && microTableData.data.length) {
        for (const item of [
          ...exportData?.filters?.customer_retailer_details,
          ...exportData?.filters?.selected_retailer,
        ]) {
          window[`slide4${item}`] = pres.addSlide();
  
          window[`slide4${item}`].addText(
            [
              { text: "Filters applied:", options: { breakLine: true } },
              {
                text: `Brand - ${defaultBrand}; Pet - ${filters?.product_pet}; Technology - ${filters?.product_technology}; Feeding Philosophy - ${filters?.product_feed_philosophy}; Texture - ${filters?.product_texture};`,
                options: { breakLine: true },
              },
              {
                text: `Retailer - ${filters?.customer_retailer_details} Time Aggregate - ${filters?.time_aggregate}; Year Period - ${filters?.year_period}; Benchmark Period - ${filters?.benchmark};`,
                options: { breakLine: true },
              },
            ],
            {
              x: 0.5,
              y: 0.5,
              w: "90%",
              valign: "top",
              fontSize: 14,
              fontFace: "Mars Centra",
            }
          );
  
          window[`slide4${item}`].addText(`${microTableData.title} - ${item}`, {
            x: 5,
            y: 1.6,
            fontSize: 15,
            fontFace: "Mars Centra",
          });
          const slicedMicro = microTableData.data.slice(0, 4);
          const microTable = createBACustomTable(
            microColumns,
            slicedMicro,
            false,
            20,
            item,
            ["Description", "UPC"]
          );
  
          window[`slide4${item}`].addTable(microTable[0], {
            x: 0.5,
            y: 1.9,
            h: 4.3,
            // w: 19,
            align: "center",
            // colW: 1,
          });
          window[`slide4${item}`].addText(
            "For complete table, please refer to the excel extract - BeAvailableTableData(tables).xlsx",
            { x: 0.5, y: 7.2, fontSize: 14, fontFace: "Mars Centra" }
          );
        }
      }
  
      openNotificationWithIcon("success", "Downloading..");
      pres.writeFile({ fileName: filename });
    } catch (error) {
      console.log("error", error);
      openNotificationWithIcon("error", "Download Failed");
    }
  }
  
  export async function pptExportDiagnostics(
    exportData,
    cbRetailers,
    macroColumns,
    competitionColumns,
    defaultBrand,
    filename
  ) {
    // nutroSKUColumnsExport,
    // allSKUColumnsExport
    // console.log("defaultBrand", DefaultBrand);
    // console.log("filename", nutroSKUColumns);
    const { filters, line, macroTableData, tableData } = JSON.parse(
      JSON.stringify(exportData)
    );
  
    try {
      // if (Object.keys(pptLine).length === 0) throw new Error("No Data");
      // if (Object.keys(pptTableData).length === 0) throw new Error("No Data");
      // if (Object.keys(pptScatter).length === 0) throw new Error("No Data");
  
      let pres = new pptxgen();
      // Define new layout for the Presentation
      pres.defineLayout({ name: "A3", width: 13, height: 7.5 });
      pres.layout = "A3";
  
      const slide = pres.addSlide();
      // Filters & KPIs
      slide.addText(
        [
          { text: "Filters applied:", options: { breakLine: true } },
          {
            text: `Brand - ${defaultBrand}; Pet - ${filters?.product_pet}; Technology - ${filters?.product_technology}; Feeding Philosophy - ${filters?.product_feed_philosophy}; Texture - ${filters?.product_texture};`,
            options: { breakLine: true },
          },
          {
            text: `Retailer - ${filters?.customer_retailer_details} Time Aggregate - ${filters?.time_aggregate}; Year Period - ${filters?.year_period}; Benchmark Period - ${filters?.benchmark};`,
            options: { breakLine: true },
          },
          {
            text: `Keyword Category - ${filters?.product_keyword_category} Keyword Type - ${filters?.product_keyword_type}; Keyword - ${filters?.product_keyword};`,
            options: { breakLine: true },
          },
        ],
        {
          x: 0.5,
          y: 0.5,
          w: "90%",
          valign: "top",
          fontSize: 14,
          fontFace: "Mars Centra",
        }
      );
  
      slide.addText(`Diagnostics`, {
        x: 1.5,
        y: 3,
        fontSize: 20,
        fontFace: "Mars Centra",
        align: "center",
        bold: true,
      });
  
      const textOptions = {
        w: 3,
        h: 1.3,
        y: 2.5,
        align: "center",
        line: { width: "2", color: "dcdcdc" },
        fill: "ffffff",
        shape: pres.ShapeType.roundRect,
        rectRadius: 0.2,
        fontSize: 14,
        margin: 0.5,
      };
  
      slide.addText(
        [
          {
            text: `${addSymbol(
              exportData?.kpi?.rsv?.title,
              exportData?.kpi?.rsv?.valueSymbol
            )}`,
            options: {
              breakLine: true,
              fontSize: 18,
              fontFace: "Mars Centra",
              color: "4d4a4a",
              bold: true,
            },
          },
          { text: "\n", options: { breakLine: true } },
          {
            text: `${addSymbol(
              exportData?.kpi?.rsv?.value,
              exportData?.kpi?.rsv?.valueSymbol
            )}`,
            options: {
              fontSize: 25,
              bold: true,
              fontFace: "Mars Centra",
              color: "0000A0",
            },
          },
          { text: "  " },
          {
            text: `${addSymbol(
              formatNumber(Math.abs(exportData?.kpi?.rsv?.change)),
              exportData?.kpi?.rsv?.changeSymbol
            )}`,
            options: {
              fontSize: 18,
              bold: true,
              fontFace: "Mars Centra",
              color: exportData?.kpi?.rsv?.change < 0 ? "EC3225" : "00C853",
            },
          },
        ],
        {
          ...textOptions,
          x: 1,
          y: 3.5,
        }
      );
      slide.addText(
        [
          {
            text: `${addSymbol(
              exportData?.kpi?.dollarShare?.title,
              exportData?.kpi?.dollarShare?.valueSymbol
            )}`,
            options: {
              breakLine: true,
              fontSize: 18,
              fontFace: "Mars Centra",
              color: "4d4a4a",
              bold: true,
            },
          },
          { text: "\n", options: { breakLine: true } },
          {
            text: `${addSymbol(
              exportData?.kpi?.dollarShare?.value,
              exportData?.kpi?.dollarShare?.valueSymbol
            )}`,
            options: {
              fontSize: 25,
              bold: true,
              fontFace: "Mars Centra",
              color: "0000A0",
            },
          },
          { text: "  " },
          {
            text: `${addSymbol(
              formatNumber(Math.abs(exportData?.kpi?.dollarShare?.change)),
              exportData?.kpi?.dollarShare?.changeSymbol
            )}`,
            options: {
              fontSize: 18,
              bold: true,
              fontFace: "Mars Centra",
              color:
                exportData?.kpi?.dollarShare?.change < 0 ? "EC3225" : "00C853",
            },
          },
        ],
        {
          ...textOptions,
          x: 5,
          y: 3.5,
        }
      );
      slide.addText(
        [
          {
            text: `${addSymbol(
              exportData?.kpi?.eqLbs?.title,
              exportData?.kpi?.eqLbs?.valueSymbol
            )}`,
            options: {
              breakLine: true,
              fontSize: 18,
              fontFace: "Mars Centra",
              color: "4d4a4a",
              bold: true,
            },
          },
          { text: "\n", options: { breakLine: true } },
          {
            text: `${addSymbol(
              exportData?.kpi?.eqLbs?.value,
              exportData?.kpi?.eqLbs?.valueSymbol
            )}`,
            options: {
              fontSize: 25,
              bold: true,
              fontFace: "Mars Centra",
              color: "0000A0",
            },
          },
          { text: "  " },
          {
            text: `${addSymbol(
              formatNumber(Math.abs(exportData?.kpi?.eqLbs?.change)),
              exportData?.kpi?.eqLbs?.changeSymbol
            )}`,
            options: {
              fontSize: 18,
              bold: true,
              fontFace: "Mars Centra",
              color: exportData?.kpi?.eqLbs?.change < 0 ? "EC3225" : "00C853",
            },
          },
        ],
        {
          ...textOptions,
          x: 9,
          y: 3.5,
        }
      );
  
      slide.addText(
        [
          {
            text: `${addSymbol(
              exportData?.kpi?.lbsShare?.title,
              exportData?.kpi?.lbsShare?.valueSymbol
            )}`,
            options: {
              breakLine: true,
              fontSize: 18,
              fontFace: "Mars Centra",
              color: "4d4a4a",
              bold: true,
            },
          },
          { text: "\n", options: { breakLine: true } },
          {
            text: `${addSymbol(
              exportData?.kpi?.lbsShare?.value,
              exportData?.kpi?.lbsShare?.valueSymbol
            )}`,
            options: {
              fontSize: 25,
              bold: true,
              fontFace: "Mars Centra",
              color: "0000A0",
            },
          },
          { text: "  " },
          {
            text: `${addSymbol(
              formatNumber(Math.abs(exportData?.kpi?.lbsShare?.change)),
              exportData?.kpi?.lbsShare?.changeSymbol
            )}`,
            options: {
              fontSize: 18,
              bold: true,
              fontFace: "Mars Centra",
              color: exportData?.kpi?.lbsShare?.change < 0 ? "EC3225" : "00C853",
            },
          },
        ],
        {
          ...textOptions,
          x: 1,
          y: 5.3,
        }
      );
      slide.addText(
        [
          {
            text: `${addSymbol(
              exportData?.kpi?.unit?.title,
              exportData?.kpi?.unit?.valueSymbol
            )}`,
            options: {
              breakLine: true,
              fontSize: 18,
              fontFace: "Mars Centra",
              color: "4d4a4a",
              bold: true,
            },
          },
          { text: "\n", options: { breakLine: true } },
          {
            text: `${addSymbol(
              exportData?.kpi?.unit?.value,
              exportData?.kpi?.unit?.valueSymbol
            )}`,
            options: {
              fontSize: 25,
              bold: true,
              fontFace: "Mars Centra",
              color: "0000A0",
            },
          },
          { text: "  " },
          {
            text: `${addSymbol(
              formatNumber(Math.abs(exportData?.kpi?.unit?.change)),
              exportData?.kpi?.unit?.changeSymbol
            )}`,
            options: {
              fontSize: 18,
              bold: true,
              fontFace: "Mars Centra",
              color: exportData?.kpi?.unit?.change < 0 ? "EC3225" : "00C853",
            },
          },
        ],
        {
          ...textOptions,
          x: 5,
          y: 5.3,
        }
      );
      slide.addText(
        [
          {
            text: `${addSymbol(
              exportData?.kpi?.unitShare?.title,
              exportData?.kpi?.unitShare?.valueSymbol
            )}`,
            options: {
              breakLine: true,
              fontSize: 18,
              fontFace: "Mars Centra",
              color: "4d4a4a",
              bold: true,
            },
          },
          { text: "\n", options: { breakLine: true } },
          {
            text: `${addSymbol(
              exportData?.kpi?.unitShare?.value,
              exportData?.kpi?.unitShare?.valueSymbol
            )}`,
            options: {
              fontSize: 25,
              bold: true,
              fontFace: "Mars Centra",
              color: "0000A0",
            },
          },
          { text: "  " },
          {
            text: `${addSymbol(
              formatNumber(Math.abs(exportData?.kpi?.unitShare?.change)),
              exportData?.kpi?.unitShare?.changeSymbol
            )}`,
            options: {
              fontSize: 18,
              bold: true,
              fontFace: "Mars Centra",
              color: exportData?.kpi?.unitShare?.change < 0 ? "EC3225" : "00C853",
            },
          },
        ],
        {
          ...textOptions,
          x: 9,
          y: 5.3,
        }
      );
  
      //slide 2
      for (const item of exportData?.filters?.customer_retailer_details) {
        window[`slide4${item}`] = pres.addSlide();
  
        window[`slide4${item}`].addText(
          [
            { text: "Filters applied:", options: { breakLine: true } },
            {
              text: `Brand - ${defaultBrand}; Pet - ${filters?.product_pet}; Technology - ${filters?.product_technology}; Feeding Philosophy - ${filters?.product_feed_philosophy}; Texture - ${filters?.product_texture};`,
              options: { breakLine: true },
            },
            {
              text: `Retailer - ${filters?.customer_retailer_details} Time Aggregate - ${filters?.time_aggregate}; Year Period - ${filters?.year_period}; Benchmark Period - ${filters?.benchmark};`,
              options: { breakLine: true },
            },
            {
              text: `Keyword Category - ${filters?.product_keyword_category} Keyword Type - ${filters?.product_keyword_type}; Keyword - ${filters?.product_keyword};`,
              options: { breakLine: true },
            },
          ],
          {
            x: 0.5,
            y: 0.5,
            w: "90%",
            valign: "top",
            fontSize: 14,
            fontFace: "Mars Centra",
          }
        );
  
        window[`slide4${item}`].addText("Total eCom Performance", {
          x: 5.5,
          y: 1.6,
          fontSize: 15,
          fontFace: "Mars Centra",
        });
        const slicedMacro = macroTableData.data.slice(0, 7);
  
        const macroTable = createDGCustomTable(
          macroColumns,
          slicedMacro,
          false,
          20,
          item,
          ["Sub Brand"]
        );
  
        console.log("macroTable", macroTable);
  
        window[`slide4${item}`].addTable(macroTable[0], {
          x: 0.5,
          y: 1.9,
          h: 4.3,
          // w: 19,
          align: "center",
          // colW: 1,
        });
        window[`slide4${item}`].addText(
          "For complete table, please refer to the excel extract - Diagnostics(tables).xlsx",
          { x: 0.5, y: 7.2, fontSize: 14, fontFace: "Mars Centra" }
        );
      }
  
      //slide 4
      if (tableData.data && tableData.data.length) {
        for (const item of [
          ...exportData?.filters?.customer_retailer_details,
          ...cbRetailers,
        ]) {
          window[`slide3${item}`] = pres.addSlide();
  
          window[`slide3${item}`].addText(
            [
              { text: "Filters applied:", options: { breakLine: true } },
              {
                text: `Brand - ${defaultBrand}; Pet - ${filters?.product_pet}; Technology - ${filters?.product_technology}; Feeding Philosophy - ${filters?.product_feed_philosophy}; Texture - ${filters?.product_texture};`,
                options: { breakLine: true },
              },
              {
                text: `Retailer - ${filters?.customer_retailer_details} Time Aggregate - ${filters?.time_aggregate}; Year Period - ${filters?.year_period}; Benchmark Period - ${filters?.benchmark};`,
                options: { breakLine: true },
              },
              {
                text: `Keyword Category - ${filters?.product_keyword_category} Keyword Type - ${filters?.product_keyword_type}; Keyword - ${filters?.product_keyword};`,
                options: { breakLine: true },
              },
            ],
            {
              x: 0.5,
              y: 0.5,
              w: "90%",
              valign: "top",
              fontSize: 14,
              fontFace: "Mars Centra",
            }
          );
  
          window[`slide3${item}`].addText(`Competition & Benchmark - ${item}`, {
            x: 5,
            y: 1.6,
            fontSize: 15,
            fontFace: "Mars Centra",
          });
          const slicedMicro = tableData.data.slice(0, 4);
          const slicedColumn = competitionColumns.slice(0, 8);
  
          const microTable = createCBCustomTable(
            slicedColumn,
            slicedMicro,
            false,
            20,
            item,
            ["Title"]
          );
  
          window[`slide3${item}`].addTable(microTable[0], {
            x: 0.5,
            y: 1.9,
            h: 4.3,
            // w: 19,
            align: "center",
            // colW: 1,
          });
          window[`slide3${item}`].addText(
            "For complete table, please refer to the excel extract - Diagnostics(tables).xlsx",
            { x: 0.5, y: 7.2, fontSize: 14, fontFace: "Mars Centra" }
          );
        }
      }
  
      const slide4 = pres.addSlide();
  
      slide4.addText(
        [
          { text: "Filters applied:", options: { breakLine: true } },
          {
            text: `Brand - ${defaultBrand}; Pet - ${filters?.product_pet}; Technology - ${filters?.product_technology}; Feeding Philosophy - ${filters?.product_feed_philosophy}; Texture - ${filters?.product_texture};`,
            options: { breakLine: true },
          },
          {
            text: `Retailer - ${filters?.customer_retailer_details} Time Aggregate - ${filters?.time_aggregate}; Year Period - ${filters?.year_period}; Benchmark Period - ${filters?.benchmark};`,
            options: { breakLine: true },
          },
          {
            text: `Keyword Category - ${filters?.product_keyword_category} Keyword Type - ${filters?.product_keyword_type}; Keyword - ${filters?.product_keyword};`,
            options: { breakLine: true },
          },
        ],
        {
          x: 0.5,
          y: 0.1,
          w: "90%",
          valign: "top",
          fontSize: 14,
          fontFace: "Mars Centra",
        }
      );
  
      slide4.addText(line.title, {
        x: 1.5,
        y: 1.5,
        fontSize: 20,
        fontFace: "Mars Centra",
        align: "center",
        // bold: true,
      });
  
      // let labels = line.data[0].labels;
      let chartTypes = [
        {
          type: pres.charts.LINE,
          data: [
            {
              name: line.series[0],
              labels: line.data.map((item) => item[line.xField]),
              values: line.data.map((item) => item["kpi_1"]),
            },
          ],
          options: {
            chartColors: ["FBEC5D"],
            barGrouping: "standard",
            secondaryValAxis: true,
            secondaryCatAxis: true,
          },
        },
        {
          type: pres.charts.LINE,
          data: [
            {
              name: line.series[1],
              labels: line.data.map((item) => item[line.xField]),
              values: line.data.map((item) => item["kpi_2"]),
            },
          ],
          options: {
            chartColors: ["ff5733"],
            barGrouping: "stacked",
          },
        },
      ];
      let opts = {
        x: 0.5,
        y: 2,
        w: 12,
        h: 5.5,
        chartArea: { fill: { color: "F1F1F1" } },
  
        barDir: "col",
        catAxisLabelColor: "666666",
        catAxisLabelFontFace: "Mars Centra",
        catAxisLabelFontSize: 10,
        catAxisOrientation: "minMax",
        showLegend: false,
        showTitle: false,
        // valAxisMaxVal: 100,
        // valAxisMajorUnit: 10,
        valAxes: [
          {
            showValAxisTitle: true,
            valAxisTitle: line.series[0],
            valGridLine: { style: "none" },
          },
          {
            showValAxisTitle: true,
            valAxisTitle: line.series[1],
            valGridLine: { style: "none" },
          },
        ],
  
        catAxes: [
          {
            catAxisTitle: "Primary Category Axis",
          },
          {
            catAxisHidden: true,
            valGridLine: { style: "none" },
          },
        ],
      };
      slide4.addChart(chartTypes, opts);
  
      openNotificationWithIcon("success", "Downloading..");
      pres.writeFile({ fileName: filename });
    } catch (error) {
      console.log("error", error);
      openNotificationWithIcon("error", "Download Failed");
    }
  }