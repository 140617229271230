import React from 'react';
import { Checkbox, Dropdown, Menu } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

const menu = (exportData) => (
    <Menu>
      <Menu.Item key="0" onClick={exportData}>Export Data</Menu.Item>
    </Menu>
  );

export default function DataCard(props){
    return <div className="data-card" style={{marginBottom: 12 }}>
    <div className="data-head">
      {props.checkbox&&<Checkbox value={props.value} onChange={props.onChange} className="d-check"></Checkbox>}
      <h4>{props.fieldTitle}</h4>
      {props?.showExport&&<Dropdown overlay={() => menu(props?.exportData)} trigger={['click']} placement="bottomRight" className="d-more">
        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
          <MoreOutlined />
        </a>
      </Dropdown>}
    </div>
    <div className="data-value">{props.fieldValue}</div>
  </div>
}