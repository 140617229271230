import React from "react";
import { Dropdown, Menu } from "antd";
import { CaretUpFilled, CaretDownFilled, MoreOutlined } from "@ant-design/icons";
import { cloneDeep } from "lodash";
import { setDeclareAppliedFilters } from "../../utilities/helper";
import { addSymbol, formatNumber, hasValue, valueTypes, symbols, itemsListed } from "../../utilities/helper";

export const rowKeyMacro = "index";
export const rowKeyMicro = "table_key";
export const rowKeyCompetition = "index";
export const rowKeyCompetitionBenchmark = "title";

// Set the filters applied based on the filters selected on the UI while clicking on Apply button (Special for Conversion usecase)
export const declareAppliedFilters = (inputFilter, filtersData) => {
  const { techs, feedingPhilosophies, textures, keywordCategory, subKeywordCategory, keywordType, keyword } = filtersData;
  let finalFilter = cloneDeep(inputFilter);
  if (
    finalFilter["product_pet"].length === 0
  )
    finalFilter["product_pet"] = ["ALL"];
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_technology", techs);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_feed_philosophy", feedingPhilosophies);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_texture", textures);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_keyword_category", keywordCategory);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_keyword_sub_technology", subKeywordCategory);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_keyword_type", keywordType);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_keyword", keyword);


  return finalFilter;
};

export const KeywordCategory = [
  {
    title: "Dog",
    value: "Dog",
    checkable: false,
    children: [
      {
        title: "Total Dog",
        value: "Total Dog",
      },
      {
        title: "Dry Dog",
        value: "Dry Dog",
      },
      {
        title: "Wet Dog",
        value: "Wet Dog",
      },
      {
        title: "Dog Treats",
        value: "Dog Treats",
      },
    ],
  },
  {
    title: "Cat",
    value: "Cat",
    checkable: false,
    children: [
      {
        title: "Total Cat",
        value: "Total Cat",
      },
    ],
  },
];
export const shareCompititionData = [
  {
    title: "Internal",
    brand_data: [
      {
        title: "Mars",
        dollar_share_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        dollar_share_retailer: {
          abs: 12,
          variance: 10.0,
        },
        dollar_sales_total_ecom: {
          abs: 110000,
          variance: 10.0,
        },
        dollar_sales_retailer: {
          abs: 110000,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        number_items_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_reviews: {
          abs: 12,
          variance: 10.0,
        },
        number_items_rate: {
          abs: 12,
          variance: 10.0,
        },
      },
      {
        title: "Greenies",
        dollar_share_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        dollar_share_retailer: {
          abs: 12,
          variance: 10.0,
        },
        dollar_sales_total_ecom: {
          abs: 110000,
          variance: 10.0,
        },
        dollar_sales_retailer: {
          abs: 110000,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        number_items_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_reviews: {
          abs: 12,
          variance: 10.0,
        },
        number_items_rate: {
          abs: 12,
          variance: 10.0,
        },
      },
    ],
  },
  {
    title: "Category Benchmark",
    brand_data: [
      {
        title: "Nutro",
        dollar_share_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        dollar_share_retailer: {
          abs: 12,
          variance: 10.0,
        },
        dollar_sales_total_ecom: {
          abs: 110000,
          variance: 10.0,
        },
        dollar_sales_retailer: {
          abs: 110000,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        number_items_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_reviews: {
          abs: 12,
          variance: 10.0,
        },
        number_items_rate: {
          abs: 12,
          variance: 10.0,
        },
      },
      {
        title: "Cesar",
        dollar_share_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        dollar_share_retailer: {
          abs: 12,
          variance: 10.0,
        },
        dollar_sales_total_ecom: {
          abs: 110000,
          variance: 10.0,
        },
        dollar_sales_retailer: {
          abs: 110000,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        number_items_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_reviews: {
          abs: 12,
          variance: 10.0,
        },
        number_items_rate: {
          abs: 12,
          variance: 10.0,
        },
      },
    ],
  },
  {
    title: "Competition Benchmark",
    brand_data: [
      {
        title: "Crave",
        dollar_share_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        dollar_share_retailer: {
          abs: 12,
          variance: 10.0,
        },
        dollar_sales_total_ecom: {
          abs: 110000,
          variance: 10.0,
        },
        dollar_sales_retailer: {
          abs: 110000,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        number_items_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_reviews: {
          abs: 12,
          variance: 10.0,
        },
        number_items_rate: {
          abs: 12,
          variance: 10.0,
        },
      },
      {
        title: "IAMS",
        dollar_share_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        dollar_share_retailer: {
          abs: 12,
          variance: 10.0,
        },
        dollar_sales_total_ecom: {
          abs: 110000,
          variance: 10.0,
        },
        dollar_sales_retailer: {
          abs: 110000,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        number_items_retailer: {
          abs: 12,
          variance: 10.0,
        },
        number_items_reviews: {
          abs: 12,
          variance: 10.0,
        },
        number_items_rate: {
          abs: 12,
          variance: 10.0,
        },
      },
    ],
  },
];
export const viewByShare = {
  R: "Retailer",
  S: "Sub Brand",
  C: "Competition",
};
export const keywordType = [
  {
    title: "Generic",
    value: "Generic",
    key: "Generic",
    children: [],
  },
  {
    title: "Branded Mars",
    value: "Branded Mars",
    key: "Branded Mars",
    children: [],
  },
  {
    title: "Branded Compititor",
    value: "Branded Compititor",
    key: "Branded Compititor",
    children: [],
  },
];
export const shareOfShelfKPI = [
  "$ Share",
  "$ Sales",
  "Unit Sales",
  "Unit Share",
  "In Stock %",
  itemsListed,
  "Average Number of Placements",
  "Average Placement",
  "Total Share of Shelf"
];

export const trafficSourceData = ["Total", "Organic", "Paid"];

export const shareOfShelfKeyword = ["All", "Generic", "Branded"];

export const columnBulletShareData = [
  {
    customer_ratailer_details: "Amazon",
    "Share of Shelf": 58,
    "$ Share": [350000, 352500],
  },
  {
    customer_ratailer_details: "Chewy",
    "Share of Shelf": 47,
    "$ Share": [280000, 282500],
  },
  {
    customer_ratailer_details: "Walmart",
    "Share of Shelf": 40,
    "$ Share": [180000, 182500],
  },
  {
    customer_ratailer_details: "Target",
    "Share of Shelf": 39,
    "$ Share": [160000, 162500],
  },
  {
    customer_ratailer_details: "Retailer 5",
    "Share of Shelf": 32,
    "$ Share": [140000, 142500],
  },
  {
    customer_ratailer_details: "Retailer 6",
    "Share of Shelf": 25,
    "$ Share": [80000, 82500],
  },
  {
    customer_ratailer_details: "Retailer 7",
    "Share of Shelf": 22,
    "$ Share": [60000, 62500],
  },
  {
    customer_ratailer_details: "Retailer 8",
    "Share of Shelf": 17,
    "$ Share": [45000, 47500],
  },
];

export const columnBulletShareSubBrandData = [
  {
    customer_ratailer_details: "Original",
    "Share of Shelf": 58,
    "$ Share": [300000, 302500],
  },
  {
    customer_ratailer_details: "Pill pocket",
    "Share of Shelf": 47,
    "$ Share": [250000, 252500],
  },
  {
    customer_ratailer_details: "Dental",
    "Share of Shelf": 40,
    "$ Share": [180000, 182500],
  },
  {
    customer_ratailer_details: "SmartBytes",
    "Share of Shelf": 39,
    "$ Share": [160000, 162500],
  },
  {
    customer_ratailer_details: "Blueberry",
    "Share of Shelf": 32,
    "$ Share": [140000, 142500],
  },
  {
    customer_ratailer_details: "Hip&Joint",
    "Share of Shelf": 25,
    "$ Share": [80000, 82500],
  },
  {
    customer_ratailer_details: "BreathBuster",
    "Share of Shelf": 22,
    "$ Share": [60000, 62500],
  },
  {
    customer_ratailer_details: "Variety Pack",
    "Share of Shelf": 17,
    "$ Share": [45000, 47500],
  },
];

export const columnBulletShareCompititionData = [
  {
    customer_ratailer_details: "Greenies",
    "Share of Shelf": 58,
    "$ Share": [300000, 302500],
  },
  {
    customer_ratailer_details: "Milk Bone",
    "Share of Shelf": 47,
    "$ Share": [250000, 252500],
  },
  {
    customer_ratailer_details: "Whimzees",
    "Share of Shelf": 40,
    "$ Share": [180000, 182500],
  },
  {
    customer_ratailer_details: "Bluecore",
    "Share of Shelf": 39,
    "$ Share": [160000, 162500],
  },
  {
    customer_ratailer_details: "Merrick",
    "Share of Shelf": 32,
    "$ Share": [130000, 132500],
  },
  {
    customer_ratailer_details: "Brand 6",
    "Share of Shelf": 25,
    "$ Share": [90000, 92500],
  },
  {
    customer_ratailer_details: "Brand 7",
    "Share of Shelf": 22,
    "$ Share": [60000, 62500],
  },
  {
    customer_ratailer_details: "Brand 8",
    "Share of Shelf": 17,
    "$ Share": [35000, 37500],
  },
];

export const columnLineShareData = [
  {
    period_week: "P6W04",
    "Share of Shelf": 20,
    "$ Share": 350000,
  },
  {
    period_week: "P7W01",
    "Share of Shelf": 43,
    "$ Share": 270000,
  },
  {
    period_week: "P7W02",
    "Share of Shelf": 37,
    "$ Share": 260000,
  },
  {
    period_week: "P7W03",
    "Share of Shelf": 37,
    "$ Share": 305000,
  },
  {
    period_week: "P7W04",
    "Share of Shelf": 37,
    "$ Share": 340000,
  },
  {
    period_week: "P8W01",
    "Share of Shelf": 43,
    "$ Share": 310000,
  },
  {
    period_week: "P8W02",
    "Share of Shelf": 37,
    "$ Share": 308000,
  },
  {
    period_week: "P8W03",
    "Share of Shelf": 43,
    "$ Share": 300000,
  },
  {
    period_week: "P8W04",
    "Share of Shelf": 37,
    "$ Share": 300000,
  },
  {
    period_week: "P9W01",
    "Share of Shelf": 37,
    "$ Share": 240000,
  },
  {
    period_week: "P9W02",
    "Share of Shelf": 37,
    "$ Share": 220000,
  },
  {
    period_week: "P9W03",
    "Share of Shelf": 43,
    "$ Share": 320000,
  },
  {
    period_week: "P9W04",
    "Share of Shelf": 43,
    "$ Share": 475000,
  },
];

export const shareTableData = [
  {
    title: "Dog Dry",
    key: "Dog Dry",
    items_listed_total_ecom: {
      abs: 10,
      variance: 10.0,
    },
    sub_brand_data: [
      {
        title: "Original",
        key: "Original",
        csl_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        csl_retailer: {
          abs: 12,
          variance: 10.0,
        },
        items_listed_total_ecom: {
          abs: 10,
          variance: 10.0,
        },
        items_listed_retailer: {
          abs: 10,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        sales_total_ecom: {
          abs: "Overall Positive",
          // variance: '',
        },
        sales_retailer: {
          abs: 2000,
          variance: 10.0,
        },
        sub_brand_data: [
          {
            title: "5 oz",
            key: "5 oz",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 10,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 10,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: "Overall Positive",
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
            sub_brand_data: [
              {
                title: "UPC 1",
                key: "UPC 1",
                csl_total_ecom: {
                  abs: 12,
                  variance: 10.0,
                },
                csl_retailer: {
                  abs: 12,
                  variance: 10.0,
                },
                items_listed_total_ecom: {
                  abs: 10,
                  variance: 10.0,
                },
                items_listed_retailer: {
                  abs: 10,
                  variance: 10.0,
                },
                in_stock_total_ecom: {
                  abs: 12,
                  variance: 10.0,
                },
                in_stock_retailer: {
                  abs: 12,
                  variance: 10.0,
                },
                sales_total_ecom: {
                  abs: "Overall Positive",
                  // variance: '',
                },
                sales_retailer: {
                  abs: 2000,
                  variance: 10.0,
                },
              },
              {
                title: "UPC 2",
                key: "UPC 2",
                csl_total_ecom: {
                  abs: 12,
                  variance: 10.0,
                },
                csl_retailer: {
                  abs: 12,
                  variance: 10.0,
                },
                items_listed_total_ecom: {
                  abs: 10,
                  variance: 10.0,
                },
                items_listed_retailer: {
                  abs: 10,
                  variance: 10.0,
                },
                in_stock_total_ecom: {
                  abs: 12,
                  variance: 10.0,
                },
                in_stock_retailer: {
                  abs: 12,
                  variance: 10.0,
                },
                sales_total_ecom: {
                  abs: "Overall Positive",
                  // variance: '',
                },
                sales_retailer: {
                  abs: 2000,
                  variance: 10.0,
                },
              },
            ],
          },
          {
            title: "10 oz",
            key: "10 oz",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 10,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 10,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: "Overall Positive",
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
          },
        ],
      },
    ],
  },
  {
    title: "Pill Pocket",
    key: "Pill Pocket",
    items_listed_total_ecom: {
      abs: 10,
      variance: 10.0,
    },
    sub_brand_data: [
      {
        title: "Pill Pocket 1",
        key: "Pill Pocket 1",
        csl_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        csl_retailer: {
          abs: 12,
          variance: 10.0,
        },
        items_listed_total_ecom: {
          abs: 10,
          variance: 10.0,
        },
        items_listed_retailer: {
          abs: 10,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        sales_total_ecom: {
          abs: "Overall Positive",
          // variance: '',
        },
        sales_retailer: {
          abs: 2000,
          variance: 10.0,
        },
      },
    ],
  },
];

export const keywordData = [
  {
    upc: "Keyword 1",
    csl_total_ecom: {
      abs: "4th",
      variance: 2,
    },
    csl_retailer: {
      abs: 100,
      variance: 2,
    },
    items_listed_total_ecom: {
      abs: "Generic",
      variance: "",
    },
    items_listed_retailer: {
      abs: 16,
      variance: 1,
    },
    in_stock_total_ecom: {
      abs: 24,
      variance: 2,
    },
    in_stock_retailer: {
      abs: 150,
      variance: 1,
    },
    sales_total_ecom: {
      abs: "Overall Positive",
      // variance: '',
    },
    sales_retailer: {
      abs: 2000,
      variance: 10.0,
    },
  },
  {
    upc: "Keyword 2",
    csl_total_ecom: {
      abs: "4th",
      variance: 2,
    },
    csl_retailer: {
      abs: 100,
      variance: 2,
    },
    items_listed_total_ecom: {
      abs: "Generic",
      variance: "",
    },
    items_listed_retailer: {
      abs: 16,
      variance: 1,
    },
    in_stock_total_ecom: {
      abs: 24,
      variance: 2,
    },
    in_stock_retailer: {
      abs: 150,
      variance: 1,
    },
    sales_total_ecom: {
      abs: "Overall Positive",
      // variance: '',
    },
    sales_retailer: {
      abs: 2000,
      variance: 10.0,
    },
  },
  {
    upc: "Keyword 3",
    csl_total_ecom: {
      abs: "4th",
      variance: 2,
    },
    csl_retailer: {
      abs: 100,
      variance: 2,
    },
    items_listed_total_ecom: {
      abs: "Generic",
      variance: "",
    },
    items_listed_retailer: {
      abs: 16,
      variance: 1,
    },
    in_stock_total_ecom: {
      abs: 24,
      variance: 2,
    },
    in_stock_retailer: {
      abs: 150,
      variance: 1,
    },
    sales_total_ecom: {
      abs: "Overall Positive",
      // variance: '',
    },
    sales_retailer: {
      abs: 2000,
      variance: 10.0,
    },
  },
  {
    upc: "Keyword 4",
    csl_total_ecom: {
      abs: "4th",
      variance: 2,
    },
    csl_retailer: {
      abs: 100,
      variance: 2,
    },
    items_listed_total_ecom: {
      abs: "Generic",
      variance: "",
    },
    items_listed_retailer: {
      abs: 16,
      variance: 1,
    },
    in_stock_total_ecom: {
      abs: 24,
      variance: 2,
    },
    in_stock_retailer: {
      abs: 150,
      variance: 1,
    },
    sales_total_ecom: {
      abs: "Overall Positive",
      // variance: '',
    },
    sales_retailer: {
      abs: 2000,
      variance: 10.0,
    },
  },
];

export const opportunityData = [
  {
    keyword: "keyword 1",
    traffic: 42000,
    share_of_shelf: 38,
    share_of_shelf_change: 20,
  },
  {
    keyword: "keyword 2",
    traffic: 6000,
    share_of_shelf: 18,
    share_of_shelf_change: 70,
  },
  {
    keyword: "keyword 3",
    traffic: 10000,
    share_of_shelf: 93,
    share_of_shelf_change: -30,
  },
  {
    keyword: "keyword 4",
    traffic: 50000,
    share_of_shelf: 2,
    share_of_shelf_change: 40,
  },
  {
    keyword: "keyword 5",
    traffic: 60000,
    share_of_shelf: 47,
    share_of_shelf_change: 80,
  },
  {
    keyword: "keyword 6",
    traffic: 77000,
    share_of_shelf: 62,
    share_of_shelf_change: -50,
  },
  {
    keyword: "keyword 7",
    traffic: 190000,
    share_of_shelf: 16,
    share_of_shelf_change: 90,
  },
  {
    keyword: "keyword 8",
    traffic: 46000,
    share_of_shelf: 10,
    share_of_shelf_change: 97,
  },
  {
    keyword: "keyword 9",
    traffic: 90000,
    share_of_shelf: 81,
    share_of_shelf_change: 86,
  },
  {
    keyword: "keyword 10",
    traffic: 98000,
    share_of_shelf: 5,
    share_of_shelf_change: -44,
  },
  {
    keyword: "keyword 11",
    traffic: 51000,
    share_of_shelf: 50,
    share_of_shelf_change: 50,
  },
  {
    keyword: "keyword 12",
    traffic: 29000,
    share_of_shelf: 22,
    share_of_shelf_change: -2,
  },
  {
    keyword: "keyword 13",
    traffic: 7000,
    share_of_shelf: 24,
    share_of_shelf_change: 90,
  },
];

// export const Keywordconfig = {
//   data: opportunityData,
//   width: 800,
//   height: 400,
//   xField: "Share_of_shelf",
//   yField: ["Traffic", "Share_of_shelf_changes"],
//   colorField: "keyword",
//   color: ["#00c853", "rgb(240, 103, 94)"],
//   sizeField: "Share_of_shelf",
//   size: [40, 30, 25],
//   shape: "circle",
//   yAxis: {
//     title: {
//       text: "Traffic",
//       style: {
//         fontWeight: "700",
//       },
//     },
//     line: {
//       style: {
//         stroke: "#eee",
//       },
//     },
//     label: {
//       formatter: (v) => `${formatNumber(v)}`,
//     },
//   },
//   xAxis: {
//     title: {
//       text: "Share of Shelf",
//       style: {
//         fontWeight: "700",
//       },
//     },
//     grid: {
//       line: {
//         style: {
//           stroke: "#eee",
//         },
//       },
//     },
//     line: {
//       style: {
//         stroke: "#eee",
//       },
//     },
//   },
//   tooltip: {
//     showMarkers: false,
//     fields: ["Traffic", "Share of Shelf", "Share of Shelf % Change"],
//     customContent: (title, items) => {
//       let htmlStr = ``;
//       items &&
//         items.forEach((item) => {
//           htmlStr += `<div style=""><div class="g2-tooltip-item" style="margin-bottom:8px;display:flex;justify-content:space-between;">
//             <span class="g2-tooltip-item-label" style="margin-right: 12px;">${
//               item.name
//             }:</span>
//             <span class="g2-tooltip-item-value">${formatNumber(
//               item.value
//             )}</span>
//           </div>
//           <div class="g2-tooltip-item" style="margin-bottom:8px;display:flex;justify-content:space-between;">
//             <span class="g2-tooltip-item-label" style="margin-right: 12px;">Share of Shelf % Change:</span>
//             <span class="g2-tooltip-item-value">${
//               item.data?.Share_of_shelf
//             } %</span>
//           </div>
//           <div class="g2-tooltip-item" style="margin-bottom:8px;display:flex;justify-content:space-between;">
//             <span class="g2-tooltip-item-label" style="margin-right: 12px;">Share of Shelf:</span>
//             <span class="g2-tooltip-item-value">20%</span>
//           </div></div>`;
//         });
//       htmlStr += "</div>";
//       return htmlStr;
//     },
//   },
//   label: {
//     formatter: (item) => {
//       return `${formatNumber(item.Share_of_shelf)}%`;
//     },
//     offsetY: 12,
//     style: {
//       fontSize: 12,
//       fill: "rgba(0,0,0,0.85)",
//     },
//   },
//   legend: false,
//   quadrant: {
//     xBaseline: 50,
//     yBaseline: 60000,
//     labels: [
//       {
//         content: "High Traffic Low Share of Shelf",
//         position: [2, 80000],
//         style: {
//           fill: "rgba(0,0,0, 0.85)",
//           textAlign: "start",
//           fontSize: 25,
//           opacity: 0.3,
//           height: 15,
//         },
//       },
//       {
//         content: "High Traffic High Share of Shelf",
//         position: [61, 80000],
//         style: {
//           fill: "rgba(0,0,0, 0.85)",
//           textAlign: "start",
//           fontSize: 25,
//           opacity: 0.3,
//           height: 15,
//         },
//       },
//       {
//         content: "Low Traffic Low Share of Shelf",
//         position: [0, 30000],
//         style: {
//           fill: "rgba(0,0,0, 0.85)",
//           textAlign: "start",
//           fontSize: 25,
//           opacity: 0.3,
//           height: 15,
//         },
//       },
//       {
//         content: "Low Traffic High Share of Shelf",
//         position: [63, 30000],
//         style: {
//           fill: "rgba(0,0,0, 0.85)",
//           textAlign: "start",
//           fontSize: 25,
//           opacity: 0.3,
//           height: 15,
//         },
//       },
//     ],
//   },
// };

export const keywordTrafficData = [
  {
    period_week: "P6W4",
    "Share of Shelf": 20,
    Traffic: 200000,
  },
  {
    period_week: "P7W1",
    "Share of Shelf": 43,
    Traffic: 270000,
  },
  {
    period_week: "P7W2",
    "Share of Shelf": 37,
    Traffic: 260000,
  },
  {
    period_week: "P7W3",
    "Share of Shelf": 37,
    Traffic: 305000,
  },
  {
    period_week: "P7W4",
    "Share of Shelf": 37,
    Traffic: 340000,
  },
  {
    period_week: "P8W1",
    "Share of Shelf": 43,
    Traffic: 310000,
  },
  {
    period_week: "P8W2",
    "Share of Shelf": 37,
    Traffic: 308000,
  },
  {
    period_week: "P8W3",
    "Share of Shelf": 43,
    Traffic: 300000,
  },
  {
    period_week: "P8W4",
    "Share of Shelf": 37,
    Traffic: 300000,
  },
  {
    period_week: "P9W01",
    "Share of Shelf": 37,
    Traffic: 240000,
  },
  {
    period_week: "P9W2",
    "Share of Shelf": 37,
    Traffic: 220000,
  },
  {
    period_week: "P9W3",
    "Share of Shelf": 43,
    Traffic: 320000,
  },
  {
    period_week: "P9W4",
    "Share of Shelf": 20,
    Traffic: 275000,
  },
];

export const ShareCompititionkpiData = [
  {
    title: "Basic KPIs",
    value: "Basic KPIs",
    key: "Basic KPIs",
    checkable: false,
    children: [
      {
        title: "$ share",
        value: "$ share",
        key: "$ share",
      },
      {
        title: "$ sales",
        value: "$ sales",
        key: "$ sales",
      },
      {
        title: "Unit Sales",
        value: "Unit Sales",
        key: "Unit Sales",
      },
      {
        title: "Share of Unit Sales",
        value: "Share of Unit Sales",
        key: "Share of Unit Sales",
      },
    ],
  },
  {
    title: "Be Visible",
    value: "Be Visible",
    key: "Be Visible",
    checkable: false,
    children: [
      {
        title: "Traffic",
        value: "Traffic",
        key: "Traffic",
      },
      {
        title: "Share of Shelf",
        value: "Share of Shelf",
        key: "Share of Shelf",
      },
      {
        title: "Traffic Share",
        value: "Traffic Share",
        key: "Traffic Share",
      },
    ],
  },
];



export const competitionBenchmarkFormat = [
  { index: 0, table_key: "TOTAL PET" },
  { index: 1, table_key: "Internal", table_hierarchy_data: [] },
  { index: 2, table_key: "Competition Benchmark", table_hierarchy_data: [] },
  { index: 3, table_key: "Category Benchmark", table_hierarchy_data: [] },

];

export const menu = (exportData) => (
  <Menu>
    <Menu.Item key="0" onClick={exportData}>
      Export Data
    </Menu.Item>
  </Menu>
);

export const KPICard = ({ title, abs, variance, absSymbol, varianceSymbol, chewyCallout, exportKPIs }) => (
  <div className="kpi-item">
    <div className="kpi-item-head">
      <h5>{title}</h5>
      {exportKPIs && (
        <Dropdown
          overlay={() => menu(exportKPIs)}
          trigger={["click"]}
          placement="bottomRight"
          className="d-more"
        >
          <a
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            <MoreOutlined />
          </a>
        </Dropdown>
      )}
    </div>
    <p>
      {hasValue(abs) &&
        (title === "Average Placement"
          ? abs
          : formatNumber(abs, 1, true) === valueTypes.NA
            ? valueTypes.NA
            : addSymbol(formatNumber(abs, 1), absSymbol))}
      {Number(variance) >= 0 ? (
        <span className="positive">
          {hasValue(variance) &&
            (formatNumber(variance, 1, true) === valueTypes.NA ? (
              valueTypes.NA
            ) : (
              <>
                <CaretUpFilled style={{ color: "#00c853", marginLeft: 5 }} />
                {title === "Average Placement"
                  ? formatNumber(variance, 0)
                  : addSymbol(formatNumber(variance, 1), varianceSymbol)}
              </>
            ))}
        </span>
      ) : (
        <span className="negative">
          {hasValue(variance) &&
            (formatNumber(variance, 1, true) === valueTypes.NA ? (
              valueTypes.NA
            ) : (
              <>
                <CaretDownFilled style={{ color: "#ec3225", marginLeft: 5 }} />
                {title === "Average Placement"
                  ? formatNumber(-1 * variance, 0)
                  : addSymbol(formatNumber(-1 * variance, 1), varianceSymbol)}
              </>
            ))}
        </span>
      )}
    </p>
    {chewyCallout && <h6 style={{ color: 'black', fontWeight: 'initial', marginRight: 10, marginLeft: 10, fontSize: 11 }}>{chewyCallout}</h6>}
  </div>
);
