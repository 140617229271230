import { createSlice } from "@reduxjs/toolkit";
import CustomAxios, { CustomAxiosPlane } from "../utilities/services/api.service";
import { ENDPOINTS, URL_EXP, ALLOWED_SEND_MAIL_HOSTS } from "../utilities/constants";
import { hasValue, openNotificationWithIcon, userActions } from "../utilities/helper";
import { updateUser } from "./profile.slicer";
import moment from 'moment'

/**
 * Admin Slicer
 * It manages the Redux State/Reducer/Actions for Admin usecase.
 */

// Initial state for the admin slice
const initialState = {
  loading: 0,
  customEventsLoading: 0,
  dashboardLoading: 0,
  pageViewsLoading: 0,
  dropdowns: {
    roles: [],
    markets: [],
    brands: {},
    teams: [],
  },
  users: [],
  selectedUser: {
    user_email: "",
    user_designation: "",
    customer_country: "",
    preferred_brand: "",
    // team_cluster: "",
    user_first_name: "",
    uesr_middle_name: "",
    user_last_name: "",
    user_is_admin: 0,
  },
  userNote: {
    is_note_enabled: 0,
    user_note: "",
  },
  userPageViewCount: {},
  alertsBookmarked: 0,
  reportOpen: {},
  userDataChart: [],
  chartSelectedUser: "1",
  countries: [],
  brand: [],
  browsers: [],
  pptExportCount: 0,
  excelExportCount: 0,
  newReportCount: 0,
  tableData: [],
  alertspageCount: [],
  brandChart: [],
  alertTwoChart: [],
  alertThreeChart: [],
  trackBoardBrand: [],
  performanceBrand: [],
  compititionBrand: [],
  distributionBrand: [],
  ProfileBrand: [],
  totalBrand: [],
  userTableData: [],
  alertsTwoTable: [],
  alertsThreeTable: [],
  trackboardTableData: [],
  performanceTableData: [],
  compititionTableData: [],
  distributionTableData: [],
  profileTableData: [],
  totalTableData: [],
  datePicker: [moment().add(-1, 'days'), moment()],
  addNewUser:[]
};

// Create a slice for the admin state using Redux Toolkit
const adminSlicer = createSlice({
  name: "admin",
  initialState,
  // Reducer to handle loading state
  reducers: {
    setLoading: (state, action) => ({
      ...state,
      loading: state.loading + (action.payload ? 1 : -1),
    }),
    // Reducer to handle loading state
    setCustomEventsLoading: (state, action) => ({
      ...state,
      customEventsLoading: state.customEventsLoading + (action.payload ? 1 : -1),
    }),
    // Reducer to handle loading state
    setDashboardLoading: (state, action) => ({
      ...state,
      dashboardLoading: state.dashboardLoading + (action.payload ? 1 : -1),
    }),
    // Reducer to handle loading state
    setPageViewsLoading: (state, action) => ({
      ...state,
      pageViewsLoading: state.pageViewsLoading + (action.payload ? 1 : -1),
    }),
    // Reducer to handle loading state
    setDropdowns: (state, action) => ({
      ...state,
      dropdowns: action.payload,
    }),
    // Reducer to handle loading state
    addTeam: (state, action) => {
      const index = state.dropdowns.teams.findIndex(
        (team) => team === action.payload
      );
      if (index === -1) state.dropdowns.teams.push(action.payload);
    },
    setUsers: (state, action) => ({
      ...state,
      users: action.payload,
    }),
    setSelectedUser: (state, action) => ({
      ...state,
      selectedUser: action.payload,
    }),
    setUserNote: (state, action) => ({
      ...state,
      userNote: action.payload,
    }),
    setAlertsBookmarked: (state, action) => ({
      ...state,
      alertsBookmarked: action.payload,
    }),
    setPPTExportCount: (state, action) => ({
      ...state,
      pptExportCount: action.payload,
    }),
    setExcelExportCount: (state, action) => ({
      ...state,
      excelExportCount: action.payload,
    }),
    setNewReportCount: (state, action) => ({
      ...state,
      newReportCount: action.payload,
    }),
    setTableData: (state, action) => ({
      ...state,
      tableData: action.payload,
    }),
    setDatePicker: (state, action) => ({
      ...state,
      datePicker: action.payload,
    }),
    setReportOpen: (state, action) => ({
      ...state,
      reportOpen: action.payload,
    }),
    setUserData: (state, action) => ({
      ...state,
      userDataChart: action.payload,
    }),
    setChartSelect: (state, action) => ({
      ...state,
      chartSelectedUser: action.payload,
    }),
    setCountries: (state, action) => ({
      ...state,
      countries: action.payload,
    }),
    setBrands: (state, action) => ({
      ...state,
      brand: action.payload,
    }),
    setBrowsers: (state, action) => ({
      ...state,
      browsers: action.payload,
    }),
    setAlertsPageCount: (state, action) => ({
      ...state,
      alertspageCount: action.payload,
    }),
    setBrandChart: (state, action) => ({
      ...state,
      brandChart: action.payload,
    }),
    setAlertTwoChart: (state, action) => ({
      ...state,
      alertTwoChart: action.payload,
    }),
    setAlertThreeChart: (state, action) => ({
      ...state,
      alertThreeChart: action.payload,
    }),
    setTrackBoardBrand: (state, action) => ({
      ...state,
      trackBoardBrand: action.payload,
    }),
    setPerformanceBrand: (state, action) => ({
      ...state,
      performanceBrand: action.payload,
    }),
    setCompititionBrand: (state, action) => ({
      ...state,
      compititionBrand: action.payload,
    }),
    setDistributionBrand: (state, action) => ({
      ...state,
      distributionBrand: action.payload,
    }),
    setProfileBrand: (state, action) => ({
      ...state,
      ProfileBrand: action.payload,
    }),
    setUserTableData: (state, action) => ({
      ...state,
      userTableData: action.payload,
    }),
    setAlertsTwoTable: (state, action) => ({
      ...state,
      alertsTwoTable: action.payload,
    }),
    setAlertsThreeTable: (state, action) => ({
      ...state,
      alertsThreeTable: action.payload,
    }),
    setTrackBoardTable: (state, action) => ({
      ...state,
      trackboardTableData: action.payload,
    }),
    setPerformanceTable: (state, action) => ({
      ...state,
      performanceTableData: action.payload,
    }),
    setCompititionTable: (state, action) => ({
      ...state,
      compititionTableData: action.payload,
    }),
    setDistributionTable: (state, action) => ({
      ...state,
      distributionTableData: action.payload,
    }),
    setProfileTable: (state, action) => ({
      ...state,
      profileTableData: action.payload,
    }),
    setTotalBrand: (state, action) => ({
      ...state,
      totalBrand: action.payload,
    }),
    setTotalTableData: (state, action) => ({
      ...state,
      totalTableData: action.payload,
    }),
    setAddNewUser:(state, action) => ({
      ...state,
      addNewUser: action.payload,
    }),
  },
});

// Exporting the admin slice actions and reducers
export const { setLoading, setCustomEventsLoading, setAlertTwoChart, setAlertThreeChart, setAlertsTwoTable, setAlertsThreeTable, setTrendView, setDashboardLoading, setPageViewsLoading, setDropdowns, setUsers, setAlertsBookmarked, setDatePicker, setPPTExportCount, setExcelExportCount, setNewReportCount, setTableData, setSelectedUser, addTeam, setUserNote, setReportOpen, setPageViews, setUniqueUsers, setUserData, setChartSelect, setCountries, setBrands, setBrowsers, setAlertsPageCount, setBrandChart, setTrackBoardBrand, setPerformanceBrand, setCompititionBrand, setDistributionBrand, setProfileBrand, setUserTableData, setTrackBoardTable, setPerformanceTable, setCompititionTable, setDistributionTable, setProfileTable, setTotalBrand, setTotalTableData } =
  adminSlicer.actions;

  const userEmail = (users)  =>{
    let url = window.location.href
    let email = ""
    if(url.includes('mars-us-pn-cascade-uat') || url.includes('mars-us-pn-cascade-dev')){
       email += "and user_AccountId != 'undefined' and user_AccountId != '' "
    }else{
     email += " and user_AccountId != 'paras.shah@effem.com' and user_AccountId != ''and user_AccountId !in('undefined'"
    const userTeam = users.filter((value)=>value.user_designation === "Cascade Development")
    for (const data of userTeam) {
      email += `,"${data.user_email}"`
    }
    email += ')'
  }
    return email
  }
export const fetchDropdowns = () => (dispatch) => {
  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.adminDropdown, "GET")
  .then((response) => {
    dispatch(setLoading(false));
    const dropdowns = {
      roles: response.data.user_designation,
      markets: response.data.market,
      brands: response.data.preferred_brand,
      teams: [],
    };
    
    dispatch(setDropdowns(dropdowns));
  })
  .catch((err) => {
    dispatch(setLoading(false));
  });
};

export const fetchUsers = () => (dispatch) => {
  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.admin, "GET")
    .then((response) => {
      dispatch(setUsers(response.data));
      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
    });
};

export const addEditUser =
  (user, clear, edit = false, action = null) =>
    (dispatch,getState) => {
      let email_id = sessionStorage.getItem("email");
      dispatch(setLoading(true));
      const url = edit ? ENDPOINTS.adminEditUser : ENDPOINTS.admin;
      CustomAxios(url, "POST", user)
        .then(() => {
          dispatch(setLoading(false));
          clear();
          if (action === userActions.added) {
            dispatch(fetchUsers());
            if (user.user_email === email_id) dispatch(updateUser({ ...user, user_name: `${user.user_first_name} ${user.user_last_name}` }));
          }
          // if (!edit) dispatch(sendUserAceessMail({ ...user, action }));
          if (!edit && ALLOWED_SEND_MAIL_HOSTS.includes(window.location.origin)) dispatch(sendUserAceessMail({ ...user, action }));
            
          
          // let { users } = getState().admin
          // let filterData = users.filter((item) => item.is_audit_enabled === 1)
          // let emails = ''
          // for(const index in filterData){
          // emails += `${filterData[index].user_email};`
          // }
          // let emailString = emails.slice(0, -1)
          // // let emailString = users.filter((item)=>item.is_audit_enabled === 1).map((item) => item.user_email).join(";")
          
          // const body = {  "user_first_name": user.user_first_name,
          // "user_last_name": user.user_last_name,
          // "user_designation": user.user_designation,
          // "preferred_brand": user.preferred_brand,
          // "email": user.user_email,
          // "action": "user_added_email",
          // "admin_users": 'ashish.vora@effem.com'}
          // CustomAxiosPlane(ENDPOINTS.logicApp, "POST", body).then((res) => {
          
          //   // setAceessLoading(false);
          // }).catch(() => {
          //   // openError();     
          //   // setAceessLoading(false);        
          // });
        })
        .catch((err) => {
          dispatch(setLoading(false));
          openNotificationWithIcon('error', err.response ? err.response.data.message : (edit ? "Edit" : "Add") + " Failed");
        });
    };

    export const addnew = (user, clear, edit = false) => (dispatch) => {
        
        let email_id = sessionStorage.getItem("email");
        dispatch(setLoading(true));
        // const body = { ...values, email, action: "user_requested_email", admin_users: null }
        CustomAxiosPlane(ENDPOINTS.logicApp, "POST", user).then(() => {
          // openSuccess();
          // setAceessLoading(false);
        }).catch(() => {
          // openError();     
          // setAceessLoading(false);        
        });
      };    

export const deleteUser = (user_email) => (dispatch) => {
  let email_id = sessionStorage.getItem("email");
  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.adminDeleteUser, "POST", { user_email })
    .then(() => {
      dispatch(setLoading(false));
      if (user_email === email_id) window.location = "/";
      else dispatch(fetchUsers());
    })
    .catch((err) => {
      dispatch(setLoading(false));
      openNotificationWithIcon('error', err.response ? err.response.data.message : "Delete Failed");
    });
};

export const fetchUserNote = () => (dispatch) => {
  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.userNote, "GET")
    .then((response) => {
      if (hasValue(response.data.note_id)) dispatch(setUserNote(response.data));
      else dispatch(setUserNote(initialState.userNote));
      dispatch(setLoading(false));
    })
    .catch((err) => {
      dispatch(setLoading(false));
    });
};

export const updateUserNote = (body) => (dispatch, getState) => {
  const { note_id, is_note_enabled, user_note } = getState().admin.userNote;
  const last_updated_by = sessionStorage.getItem("email");
  dispatch(setLoading(true));
  CustomAxios(ENDPOINTS.userNote, "POST", { ...body, last_updated_by, note_id })
    .then(() => {
      dispatch(fetchUserNote());
      dispatch(setLoading(false));
      if (is_note_enabled !== body.is_note_enabled && ALLOWED_SEND_MAIL_HOSTS.includes(window.location.origin))
        if (body.is_note_enabled) dispatch(sendUserNoteMail(body.user_note, 1))
        else dispatch(sendUserNoteMail(user_note, 0))
    })
    .catch((err) => {
      dispatch(setLoading(false));
    });
};

export const sendUserAceessMail = (body) => (dispatch, getState) => {
  let users = getState().admin.users.filter(({ user_is_admin }) => user_is_admin === 1); 
  // users = [{ user_email: "priyamvada.vanamali@effem.com" }, { user_email: "rushabh.devalia@effem.com" }, { user_email: "mohammad.shoaib@effem.com" }, { user_email: "ashish.vora@effem.com" }];
  const admin_users = users.map(({ user_email }) => user_email).join(";");

  dispatch(setLoading(true));
  CustomAxiosPlane(ENDPOINTS.logicApp, "POST", { ...body, admin_users })
    .then(() => {
      // openNotificationWithIcon('success', 'Submitted successfully.');
      dispatch(setLoading(false));
    })
    .catch((err) => {
      // openNotificationWithIcon('error', 'Failed to Submit.');
      dispatch(setLoading(false));
    });
};

export const sendUserNoteMail = (body, is_note_enabled) => (dispatch, getState) => {
  let { users } = getState().admin;
  users = [
    { user_email: "stephen.weintrob@effem.com" },
    { user_email: "bethany.mcdonald@effem.com" },
    { user_email: "priyamvada.vanamali@effem.com" },
    { user_email: "flevina.almeida@effem.com" },
    { user_email: "mohammad.imran@effem.com" },
    { user_email: "pavani.kolluru@effem.com" },
  ];
  
  const to = users.map(({ user_email }) => user_email).join(";");
  dispatch(setLoading(true));
  CustomAxiosPlane(ENDPOINTS.logicAppNotification, "POST", { to, body, is_note_enabled })
    .then(() => {
      openNotificationWithIcon('success', 'Mail sent successfully.');
      dispatch(setLoading(false));
    })
    .catch((err) => {
      openNotificationWithIcon('error', 'Failed to send mail.');
      dispatch(setLoading(false));
    });
};

export const reportOpenCount = () => async (dispatch, getState) => {
  const datePicker = getState().admin.datePicker
  let { users } = getState().admin;
  const chartSelectedUser = getState().admin.chartSelectedUser
  let cascadeTeam = userEmail(users)
  // const where = `${datePicker?.length > 0 ? `between (datetime(${moment(datePicker[0]?._d).format('YYYY-MM-DD')} 00:00) .. datetime(${moment(datePicker[1]?._d).format('YYYY-MM-DD')} 23:59))` : " >= ago(24h)"}`
 const startDate = moment(datePicker[0]?._d).format('YYYY-MM-DD');
const endDate = moment(datePicker[1]?._d).format('YYYY-MM-DD');

const where = `effectiveTimestamp between (datetime(${startDate} 00:00) .. datetime(${endDate} 23:59))
`;
let firstDate = new Date(`${moment(datePicker[0]?._d).format('YYYY-MM-DD')} 00:00`).getTime()
let lastDate = new Date(`${moment(datePicker[1]?._d).format('YYYY-MM-DD')} 23:59`).getTime()
let beforeDate = `${moment(datePicker[0]?._d).format('YYYY-MM-DD')} 00:00`
let afterDate = `${moment(datePicker[1]?._d).format('YYYY-MM-DD')} 23:59`

  const date = (firstDate + lastDate) / 2
  const midDate = moment(new Date(date)).format('YYYY-MM-DD HH:mm')
  const query = `let usg_events = dynamic(["LOGIN"]);
  let grain = iff(true,${chartSelectedUser === "1" ? '1d' : chartSelectedUser === "2" ? '7d' : '30d'}, 1h);
  let all_table_filter = pageViews
        | extend effectiveTimestamp = coalesce(todatetime(customDimensions.timestamp), timestamp)
        | where  ${where} and  user_AccountId != "null" ${cascadeTeam}
        | union (customEvents
        | extend effectiveTimestamp = coalesce(todatetime(customDimensions.timestamp), timestamp)
        | where  ${where} and  user_AccountId != "null" ${cascadeTeam})
        | union (requests
        | extend effectiveTimestamp = coalesce(todatetime(customDimensions.timestamp), timestamp)
        | where  ${where} and  user_AccountId != "null" ${cascadeTeam});
  let card_data=all_table_filter
        | summarize
          pages = countif(name == "PN Cascade"),
          login = countif(name == "LOGIN"),
          unique = dcountif(tolower(user_AccountId), name == "LOGIN"),
          before=dcountif(tolower(user_AccountId),effectiveTimestamp between (datetime(${beforeDate}) .. datetime(${midDate}))),
          after=dcountif(tolower(user_AccountId), effectiveTimestamp between (datetime(${midDate}) .. datetime(${afterDate})))
        | extend PackedRecord = pack_all()
        | summarize Result = make_list(PackedRecord);
  let usage_data=all_table_filter
        | summarize count() by client_CountryOrRegion
        | extend PackedRecord = pack_all()
        | summarize Result = make_list(PackedRecord);
  let trends=all_table_filter
        | where isempty(operation_SyntheticSource)
        | where '*' in (usg_events) or name in (usg_events)
        | where true
        | make-series totalUsers = countif(tolower(user_AccountId) != user_AuthenticatedId) default = 0 , users = dcountif(tolower(user_AccountId), tolower(user_AccountId) != user_AuthenticatedId or (tolower(user_AccountId) == user_AuthenticatedId and isnotempty(tolower(user_AccountId)))) on effectiveTimestamp from datetime(${moment(datePicker[0]?._d).format('YYYY-MM-DD')} 00:00) to datetime(${moment(datePicker[1]?._d).format('YYYY-MM-DD')} 23:59) step grain
        | mvexpand effectiveTimestamp, totalUsers, users
        | extend PackedRecord = pack_all()
        | summarize Result = make_list(PackedRecord);
  print card_data = toscalar(card_data), usage_data = toscalar(usage_data),trends=toscalar(trends)`
  dispatch(setDashboardLoading(true))
  CustomAxios(ENDPOINTS.apiInsights + '/query?query=' + query, 'GET', {}, false, { 'x-api-key': process.env.REACT_APP_API_INSIGHTS_API_TOKEN }).then((response) => {
    let dashboardData = response.data.tables[0]?.rows[0]
    let cardData = JSON.parse(dashboardData[0])
    let countryData = JSON.parse(dashboardData[1])
    let ChartData = JSON.parse(dashboardData[2])

    if (response) {
      let calRasioTotal = cardData[0]?.before + cardData[0]?.after
      let data = {
        totalUser: cardData[0]?.login,
        totalPage: cardData[0]?.pages,
        uniqueUser: cardData[0]?.unique,
        trend: (cardData[0].after && cardData[0].before) === 0 ? 0 :  (((cardData[0]?.after - cardData[0]?.before) / calRasioTotal) * 100).toFixed(1),
      }
      let chart = []
      for (const index in ChartData) {
        chart.push({
          name: "Total User",
          time: moment(ChartData[index].effectiveTimestamp).format("YYYY-MM-DD"),
          chart: ChartData[index].totalUsers
        },
          {
            name: "Unique User",
            time: moment(ChartData[index].effectiveTimestamp).format("YYYY-MM-DD"),
            chart: ChartData[index].users
          }
        )
      }
      let Country = []
      const sum = countryData.map((item, index) => (item.count_)).reduce((partialSum, a) => partialSum + a, 0)
      for (const index in countryData) {
        const percentage = parseFloat(parseFloat(countryData[index].count_) / sum * 100).toFixed(1);
        Country.push({
          countries: countryData[index].client_CountryOrRegion,
          Users: Number(`${percentage}`)
        }
        )
      }
      const data21 = Country.sort((a, b) => a.Users - b.Users)
      dispatch(setCountries(data21));
      dispatch(BrandsCount())
      dispatch(BrowsersCount())
      dispatch(setUserData(chart));
      dispatch(setReportOpen(data));
    }

    dispatch(setDashboardLoading(false))
  }).catch((error) => {
    
    dispatch(setDashboardLoading(false))
  })
}

export const BrandsCount = () => async(dispatch, getState) => {
  const datePicker = getState().admin.datePicker
  let { users } = getState().admin;
  let cascadeTeam = userEmail(users)
  const where = `${datePicker?.length > 0 ? `between (datetime(${moment(datePicker[0]?._d).format('YYYY-MM-DD')} 00:00) .. datetime(${moment(datePicker[1]?._d).format('YYYY-MM-DD')} 23:59))` : " >= ago(24h)"}`
  const query = `pageViews 
  | extend effectiveTimestamp = coalesce(todatetime(customDimensions.timestamp), timestamp)
  | where effectiveTimestamp ${where} ${cascadeTeam} and user_AccountId != "null" | extend CommandType = tostring( customDimensions.BrandName)| where  CommandType != "null" and CommandType != "" and CommandType != "undefined"  |summarize count() by CommandType `
  
  dispatch(setDashboardLoading(true))
  let response = await CustomAxios(ENDPOINTS.apiInsights + '/query?query=' + query, 'GET', {}, false, { 'x-api-key': process.env.REACT_APP_API_INSIGHTS_API_TOKEN })
  let brandData = response.data?.tables[0]?.rows
  

  const sum = brandData.map((item, index) => (item[1])).reduce((partialSum, a) => partialSum + a, 0)

  let filterBrand = []
  for (const index in brandData) {
    const percentage = parseFloat(parseFloat(brandData[index][1] / sum * 100)).toFixed(1);
    filterBrand.push({
      brands: brandData[index][0],
      Users: Number(`${percentage}`)
    }
    )
  }
  const brand = filterBrand.sort((a, b) => a.Users - b.Users)
  dispatch(setBrands(brand));
  dispatch(setDashboardLoading(false))

}

export const BrowsersCount = () => (dispatch, getState) => {
  const datePicker = getState().admin.datePicker
  let { users } = getState().admin;
  let cascadeTeam = userEmail(users)
  const where = `${datePicker?.length > 0 ? `between (datetime(${moment(datePicker[0]?._d).format('YYYY-MM-DD')} 00:00) .. datetime(${moment(datePicker[1]?._d).format('YYYY-MM-DD')} 23:59))` : " >= ago(24h)"}`
  const query = `union pageViews
  | extend effectiveTimestamp = coalesce(todatetime(customDimensions.timestamp), timestamp)
  | where effectiveTimestamp ${where} ${cascadeTeam} and user_AccountId != "null"
  |summarize count() by client_Browser`
  dispatch(setDashboardLoading(true))
  CustomAxios(ENDPOINTS.apiInsights + '/query?query=' + query, 'GET', {}, false, { 'x-api-key': process.env.REACT_APP_API_INSIGHTS_API_TOKEN }).then((response) => {
    let BrowserData = response.data?.tables[0]?.rows
    const sum = BrowserData.map((item, index) => (item[1])).reduce((partialSum, a) => partialSum + a, 0)
    let brow = BrowserData.map(val => [val[0].replace(/[^A-Za-z]+/g, ''), val[1]])

    let jsonData = [];
    for (const data of brow) {
      jsonData.push({
        browser: data[0],
        Users: Number(`${data[1]}`)
      })
    }
    const unique = [...new Map(jsonData.map(item =>
      [item['browser'], item])).values()];

    let filterdata = []
    for (const item of unique) {
      const newData = brow.filter((value) => value[0] === item.browser).reduce((a, b) => a + b[1], 0)
      const percentage = parseFloat(newData / sum * 100).toFixed(1);
      filterdata.push({
        browser: item.browser,
        Users: Number(`${percentage ? percentage : 0}`)
      })
    }

    const browser = filterdata.sort((a, b) => a.Users - b.Users)
    dispatch(setBrowsers(browser));
    dispatch(setDashboardLoading(false))
  }).catch((error) => {
    console.error(error);
    dispatch(setDashboardLoading(false))
  })
}

export const getPPTExportCount = () => (dispatch, getState) => {
  const datePicker = getState().admin.datePicker
  let { users } = getState().admin;
  let cascadeTeam = userEmail(users)
  const where = `${datePicker?.length > 0 ? `between (datetime(${moment(datePicker[0]?._d).format('YYYY-MM-DD')} 00:00) .. datetime(${moment(datePicker[1]?._d).format('YYYY-MM-DD')} 23:59))` : " >= ago(24h)"}`
  const query = `customEvents
  | extend effectiveTimestamp = coalesce(todatetime(customDimensions.timestamp), timestamp)
  | where effectiveTimestamp ${where} ${cascadeTeam} and user_AccountId != "null"
  | summarize pptCount =  countif(name == "PPT_FOR_PERFORMANCE" or name ==  "PPT_FOR_VOLUMESHIFTING" or name ==  "PPT_FOR_DISTRIBUTION"  or name ==  "PPT_FOR_INNOVATION"  or name == "PPT_FOR_DIAGNOSTICS" or name == "PPT_FOR_SHARE_OF_SHELF"  or name == "PPT_FOR_BE_AVAILABLE" or name == "PPT_FOR_SEARCH_TERM" ) , excelCount = countif(name == "EXCEL_FOR_PERFORMANCE" or name ==  "EXCEL_FOR_VOLUMESHIFTING" or name ==  "EXCEL_FOR_DISTRIBUTION" or name ==  "EXCEL_FOR_INNOVATION" or name == "EXCEL_FOR_DIAGNOSTICS" or name == "EXCEL_FOR_SHARE_OF_SHELF" or name == "EXCEL_FOR_BE_AVAILABLE" or name == "EXCEL_FOR_SEARCH_TERM" ), bookmarkCount = countif(name == "BOOKMARK_FOR_PERFORMANCE" or name ==  "BOOKMARK_FOR_COMPITITION" or name ==  "BOOKMARK_FOR_DISTRIBUTION" or name == "BOOKMARK_FOR_INNOVATION") , reportCount = countif( name == "CREATE_SELF_SERVE_REPORT_PERFORMANCE" or name ==  "CREATE_SELF_SERVE_REPORT_COMPITITION" or name ==  "CREATE_SELF_SERVE_REPORT_DISTRIBUTION" or name == "ADD_EXISTING_REPORT_PERFORMANCE" or name == "ADD_EXISTING_REPORT_COMPITITON" or name == "ADD_EXISTING_REPORT_DISTRIBUTION" or name == "CREATE_SELF_SERVE_REPORT_INNOVATION" or name == "ADD_EXISTING_REPORT_INNOVATION"), dataExportCount = countif(name == "DATA_EXPORT")`
  dispatch(setCustomEventsLoading(true))
  CustomAxios(ENDPOINTS.apiInsights + '/query?query=' + query, 'GET', {}, false, { 'x-api-key': process.env.REACT_APP_API_INSIGHTS_API_TOKEN }).then((response) => {
    dispatch(setPPTExportCount(response.data.tables[0]?.rows[0] ? response.data.tables[0]?.rows[0] : 0));
    dispatch(setCustomEventsLoading(false))
  }).catch((error) => {
    console.error(error);
    dispatch(setCustomEventsLoading(false))
  })
}

export const getTableData = () => (dispatch, getState) => {
  const datePicker = getState().admin.datePicker
  let { users } = getState().admin;
  let cascadeTeam = userEmail(users)
  const where = `${datePicker?.length > 0 ? `between (datetime(${moment(datePicker[0]?._d).format('YYYY-MM-DD')} 00:00) .. datetime(${moment(datePicker[1]?._d).format('YYYY-MM-DD')} 23:59))` : " >= ago(24h)"}`
  const query = `customEvents 
  | extend effectiveTimestamp = coalesce(todatetime(customDimensions.timestamp), timestamp)
  | where effectiveTimestamp ${where} and user_AccountId != "null" and name != "LOGIN" and name != "EXPORT_DATA_ALERTS_PERFORMANCE" and name != "EXPORT_DATA_ALERTS_COMPITITION" and name != "EXPORT_DATA_ALERTS_DISTRIBUTUION" and name != "EXPORT_DATA_ALERTS_INNOVATION" and name !="ALERTS_FOR_PERFORMANCE" and name != "ALERTS_FOR_COMPITITION" and name!="ALERTS_FOR_DISTRIBUTION" and name!="ALERTS_FOR_INNOVATION" and name!="MY_PROFILE" ${cascadeTeam}| summarize count() by user_Id,user_AccountId, name`
  dispatch(setCustomEventsLoading(true))
  CustomAxios(ENDPOINTS.apiInsights + '/query?query=' + query, 'GET', {}, false, { 'x-api-key': process.env.REACT_APP_API_INSIGHTS_API_TOKEN }).then((response) => {
    dispatch(setTableData(response.data.tables[0]?.rows.length ? response.data.tables[0]?.rows : []));
    dispatch(setCustomEventsLoading(false))
  }).catch((error) => {
    console.error(error);
    dispatch(setCustomEventsLoading(false))
  })
}

export const getCustomEventsData = () => (dispatch) => {
  dispatch(getPPTExportCount());
  dispatch(getTableData());
}
export const trackboardBrand = (pageName) => (dispatch, getState) => {
  const datePicker = getState().admin.datePicker
  let { users } = getState().admin;
  let cascadeTeam = userEmail(users)
  const where = `${datePicker?.length > 0 ? `between (datetime(${moment(datePicker[0]?._d).format('YYYY-MM-DD')} 00:00) .. datetime(${moment(datePicker[1]?._d).format('YYYY-MM-DD')} 23:59))` : " >= ago(24h)"}`
  const query = `let all_table_filter = pageViews
  | extend effectiveTimestamp = coalesce(todatetime(customDimensions.timestamp), timestamp) |
  where effectiveTimestamp ${where} ${cascadeTeam} and user_AccountId != "null"| where operation_Name in ("${pageName}")| project client_CountryOrRegion,name, user_Id, operation_Name, duration, user_AccountId, customDimensions;
  let per_page_view=all_table_filter| summarize view=count(), avg=avg(duration), uniq=dcount(tolower(user_AccountId)) by operation_Name| extend PackedRecord = pack_all()| summarize Result = make_list(PackedRecord);
  let brand_count_on_view=all_table_filter| extend CommandType = tostring(customDimensions.BrandName)| summarize count() by CommandType | extend PackedRecord = pack_all()| summarize Result = make_list(PackedRecord);
  let stats_by_user=(customEvents 
    | extend effectiveTimestamp = coalesce(todatetime(customDimensions.timestamp), timestamp) |
    where effectiveTimestamp ${where} ${cascadeTeam} and user_AccountId != "null")| union all_table_filter| extend CommandType = tostring(customDimensions.BrandName)| summarize report_view_count=countif(operation_Name in( "${pageName}") and name == "PN Cascade"),login_count=countif(name == "LOGIN"),distinct_brand=dcountif(CommandType, name == "PN Cascade")by user_name=tolower(user_AccountId), country=client_CountryOrRegion| where report_view_count > 0| extend PackedRecord = pack_all()| summarize Result = make_list(PackedRecord);
  print per_page_view = toscalar(per_page_view),brand_count_on_view = toscalar(brand_count_on_view),stats_by_user=toscalar(stats_by_user)`
  dispatch(setPageViewsLoading(true))
  CustomAxios(ENDPOINTS.apiInsights + '/query?query=' + query, 'GET', {}, false, { 'x-api-key': process.env.REACT_APP_API_INSIGHTS_API_TOKEN }).then((response) => {
    const brandValue = JSON.parse(response.data?.tables[0]?.rows[0][1])
    const userValue = JSON.parse(response.data?.tables[0]?.rows[0][2])

    let trackBoard = []
    for (const index in brandValue) {
      trackBoard.push({
        brands: brandValue[index].CommandType,
        value: brandValue[index].count_
      }
      )
    }
    let table2 = []
    for (const index in userValue) {
      table2.push({
        UNIQUE_USER: userValue[index].user_name,
        TOTAL_PAGES: userValue[index].report_view_count,
        COUNTRY: userValue[index].country,
        BRAND_COUNT: userValue[index].distinct_brand,
        SESSION_COUNT: userValue[index].login_count
      }
      )
    }
    dispatch(setTrackBoardTable(table2));
    dispatch(setTrackBoardBrand(trackBoard));
    dispatch(setPageViewsLoading(false))
  }).catch((error) => {
    console.error(error);
    dispatch(setPageViewsLoading(false))
  })
}

export const TotalBrandChart = () => async (dispatch, getState) => {
  const datePicker = getState().admin.datePicker
  let { users } = getState().admin;
  let cascadeTeam = userEmail(users)
  const where = `${datePicker?.length > 0 ? `between (datetime(${moment(datePicker[0]?._d).format('YYYY-MM-DD')} 00:00) .. datetime(${moment(datePicker[1]?._d).format('YYYY-MM-DD')} 23:59))` : " >= ago(24h)"}`
  const query = `let all_table_filter = pageViews    | extend effectiveTimestamp = coalesce(todatetime(customDimensions.timestamp), timestamp)
  | where effectiveTimestamp ${where} ${cascadeTeam} and user_AccountId != "null" | where operation_Name in ("/trackboard", "/performance-summary", "/volume-shifting", "/distribution-summary", "/innovation-tracker", "/alerts-1", "/alerts-2", "/alerts-3", "/alerts-4", "/profile",  "/diagnostics", "/be-available", "/share-of-shelf", "/search-term", "/export")| project client_CountryOrRegion,name, user_Id, operation_Name, duration, user_AccountId, customDimensions;
  let per_page_view=all_table_filter | summarize view=count(), avg=avg(duration), uniq=dcount(tolower(user_AccountId)) by operation_Name| extend PackedRecord = pack_all()| summarize Result = make_list(PackedRecord);
  let brand_count_on_view=all_table_filter | extend CommandType = tostring(customDimensions.BrandName) | summarize count() by CommandType| extend PackedRecord = pack_all()| summarize Result = make_list(PackedRecord);
  let stats_by_user=(customEvents    | extend effectiveTimestamp = coalesce(todatetime(customDimensions.timestamp), timestamp)
  | where effectiveTimestamp ${where} ${cascadeTeam} and user_AccountId != "null" )| union all_table_filter| extend CommandType = tostring(customDimensions.BrandName)| summarize report_view_count=countif(operation_Name in("/alerts-4", "/alerts-3", "/alerts-2", "/alerts-1", "/performance-summary", "/volume-shifting", "/distribution-summary", "/profile", "/trackboard", "/innovation-tracker",  "/diagnostics", "/be-available", "/share-of-shelf", "/search-term", "/export") and name == "PN Cascade"),        login_count=countif(name == "LOGIN"),distinct_brand=dcountif(CommandType, name == "PN Cascade") by user_name=tolower(user_AccountId), country=client_CountryOrRegion| where report_view_count > 0| extend PackedRecord = pack_all()| summarize Result = make_list(PackedRecord);
  print per_page_view = toscalar(per_page_view), brand_count_on_view = toscalar(brand_count_on_view),stats_by_user=toscalar(stats_by_user)`
  dispatch(setPageViewsLoading(true))
  CustomAxios(ENDPOINTS.apiInsights + '/query?query=' + query, 'GET', {}, false, { 'x-api-key': process.env.REACT_APP_API_INSIGHTS_API_TOKEN }).then((response) => {
    const defValue = JSON.parse(response.data?.tables[0]?.rows[0][0])
    const brandValue = JSON.parse(response.data?.tables[0]?.rows[0][1])
    const userValue = JSON.parse(response.data?.tables[0]?.rows[0][2])
    let total = []
    for (const index in brandValue) {
      total.push({
        brands: brandValue[index].CommandType,
        value: brandValue[index].count_
      }
      )
    }
    let table3 = []
    for (const index in userValue) {
      table3.push({
        UNIQUE_USER: userValue[index].user_name,
        TOTAL_PAGES: userValue[index].report_view_count,
        COUNTRY: userValue[index].country,
        BRAND_COUNT: userValue[index].distinct_brand,
        SESSION_COUNT: userValue[index].login_count
      }
      )
    }
    let count = []
    for (const index in defValue) {

      
      let seconds = ((defValue[index].avg % 60000) / 1000).toFixed(1);

      count.push({
        PAGE: defValue[index].operation_Name,
        PAGE_VIEWS: defValue[index].view,
        UNIQUE_USERS: defValue[index].uniq,
        AVERAGE_LOAD_TIME: `${seconds} s`
      })
    }
    dispatch(setTotalBrand(total));
    dispatch(setTotalTableData(table3));
    dispatch(setAlertsPageCount(count));
    dispatch(setPageViewsLoading(false))
  }).catch((error) => {
    console.error(error);
    dispatch(setPageViewsLoading(false))
  })
}

export default adminSlicer.reducer;
