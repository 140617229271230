import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Layout,
  Row,
  Col,
  Menu,
  Button,
  Form,
  Input,
  Select,
  Radio,
  Space,
  Table,
  Checkbox,
  Switch,
  DatePicker,
  Tabs,
  Empty
} from "antd";
import { Bar } from '@ant-design/plots';
import {
  PlusCircleOutlined,
  DownloadOutlined,
  DashboardOutlined,
  LeftCircleOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  FilterOutlined,
} from "@ant-design/icons";
// import Highlighter from 'react-highlight-words';
import Modal from "antd/lib/modal/Modal";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";

import Wrapper from "../../components/wrapper";
import {
  addEditUser,
  addTeam,
  deleteUser,
  fetchDropdowns,
  fetchUsers,
  setSelectedUser,
  updateUserNote,
  setUser,
  setDatePicker,
  alertsBookmarkCount,
  getPPTExportCount,
  getAllCardData,
  reportOpenCount,
  pageOpenCount,
  uniqueUserCount,
  ChartData,
  setChartSelect,
  CountriesCount,
  BrandsCount,
  BrowsersCount,
  AlertsPageCount,
  getCustomEventsData,
  BrandDataCount,
  trackboardBrand,
  performanceBrandCount,
  compititionBrandCount,
  DistributionBrandCount,
  ProfileBrandCount,
  usersTable,
  TrackboardTable,
  PerformanceTable,
  CompititionTable,
  DistributionTable,
  ProfileTable,
  AlertOneDataCount,
  AlertTwoDataCount,
  AlertThreeDataCount,
  AlertsTwoTableData,
  AlertsThreeTableData,
  trendviewcount,
  TotalBrandChart,
  TotalTableData,
} from "../../slicers/admin.slicer";
import "../../assets/styles/pages/admin.scss";
import CustomLoader from "../../components/custom-loader/custom-loader";
import FormInput from "../../components/forms/FormInput";
import FormSelect from "../../components/forms/FormSelect";
import { setExportData } from "../../slicers/exportData.slicer";
import { exportTableData,exportUsageCustomEventData,exportUsageDashboardData, exportUsagePageviewData, numFmt } from "../../utilities/exports/excel";
import FormTreeSelect from "../../components/forms/FormTreeSelect";
import { card2table, hasValue, page2columns, usagePage3Columns, allBrands, userActions } from "../../utilities/helper";
import DataCard from "../../components/data-card";
import { Pie } from '@ant-design/charts';
import ColumnChart from "../../components/column";
import Loader from "../../components/loader";

const { Content } = Layout;

const { TabPane } = Tabs;




/**
 * Admin Page
 * It displays admin page with its data.
 */

function Admin(props) {
  const dispatch = useDispatch();
  const { datePicker, alertsBookmarked, pptExportCount, trendView, alertTwoChart, alertThreeChart, alertsTwoTable, alertsThreeTable, excelExportCount, newReportCount, tableData, reportOpen, pageViews, uniqueUsers, userDataChart, chartSelectedUser, countries, brand, browsers, alertspageCount, brandChart, trackBoardBrand, performanceBrand, compititionBrand, distributionBrand, ProfileBrand, userTableData, trackboardTableData, performanceTableData, compititionTableData, distributionTableData, profileTableData, totalBrand, totalTableData } = useSelector(
    (state) => state.admin
  );
  const { user: { user_is_admin } } = useSelector((state) => state.profile)
  const [selectedTab, setselectedTab] = useState("1");
  const [disableOpen, setDisableOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("0");
  const [adminTab, setAdminTab] = useState("new_contacts");
  const history = useHistory();
  const [key, setKey] = useState(1);
  const [editing, setEditing] = useState(false);
  const [deleteUserEmail, setDeleteUserEmail] = useState("");
  const [brands, setBrands] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [filteredValues, setFilteredValues] = useState({});
  const [card3Table, setCard3Table] = useState([]);
  const [userNote, setUserNote] = useState(false);
  const [daily, setDaily] = useState("1");
  const [pieChartData, setPieChartData] = useState([]);
  const [uniqueUserTable, setUniqueUserTable] = useState([]);
  const [chartselect, setChartselect] = useState("")
  const [pieHeader, setPieHeader] = useState("");
  const [chartRadio, setChartRadio] = useState(1);
  const [totalCheck, setTotalCheck] = useState(true)
  const [pageViewSelect, setPageViewSelect] = useState(1);
  const { loading, dashboardLoading, pageViewsLoading, customEventsLoading, dropdowns, users, selectedUser, userNote: userNoteData } = useSelector(
    (state) => state.admin
  );
  const exportData = useSelector((state) => state.exportData);
  // const moment = require('moment');
  // const [datepicker, setDatepicker] = useState([moment().add(-1, 'days'), moment()])
  const { RangePicker } = DatePicker;
  const DateFormate = 'DD/MMM/YYYY';

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function clean(obj) {
    return Object.keys(obj) // get own keys
      .filter(function (key) {  // keep non '0' values
        return obj[key] !== 0
      })
      .reduce(function (out, key) { // recreate object using filtered keys
        out[key] = obj[key]

        return out
      }, {})
  }
  const reformatTableData = () => {
    if (tableData.length) {
      let emails = tableData.map(entries => entries[1]).filter(onlyUnique);
      let result = emails.map(email => tableData.map(entry => ({
        "unique_user": entry[1],
        [entry[2]]: entry[3]
      })).filter(entry => entry.unique_user === email)).map(email => {
        let result = {}
        for (let i in email) {
          let key = Object.keys(email[i])[1]
          if (Object.keys(result).includes(key)) {
            result[key] = result[key] + email[i][key]
          } else {
            result = Object.assign(result, email[i])
          }
        }
        return result
      })
      result = result.map(clean) // Removing Zero Key Value Pairs
      result = result.filter(val => !Object.values(val).splice(1).every(val => val === 0)) // Removing data with all zeros.
      setCard3Table(result)
      
    }
    else {
      setCard3Table([]);
    }
  }

  useEffect(() => {
    reformatTableData();
  }, [tableData])

  useEffect(()=> {
   if(activeTabKey === "2"){
    dispatch(TotalBrandChart())
  }
  else if(activeTabKey === "3"){
    dispatch(getCustomEventsData())
  }

  }, [activeTabKey, datePicker])

  useEffect(() => {
    if (chartRadio === 2) {
      dispatch(BrandsCount())
    }
    else if (chartRadio === 3) {
      dispatch(BrowsersCount())
    }
  }, [chartRadio])


  useEffect(() => {
    if (activeTabKey === "1") {
      dispatch(reportOpenCount())
    }
  }, [chartSelectedUser, activeTabKey, datePicker])

  useEffect(() => {
    if (selectedTab === '3') {
      setActiveTabKey("1")
    }

  }, [selectedTab])

  let config = {
    data: countries,
    xField: 'Users',
    yField: 'countries',
    autoFit: true,
    marginRatio: 0,
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      }
    },
    xAxis: {
      title: { text: "Usage" },
      min: 0,
    },
    yAxis: {
      title: { text: "Countries" },
    },
    tooltip: {
      showTitle: false,
      formatter: (params) => {
        return { name: params.countries, value: `${params.Users} %` }
      }

    }
  };
  let config2 = {
    data: brand,
    xField: 'Users',
    yField: 'brands',
    autoFit: true,
    marginRatio: 0,
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      }
    },
    xAxis: {
      title: { text: "Usage" },
      min: 0,
    },
    yAxis: {
      title: { text: "brands" },
    },
    tooltip: {
      showTitle: false,
      formatter: (params) => {
        return { name: params.brands, value: `${params.Users} %` }
      }

    }
  };
  let config3 = {
    data: browsers,
    xField: 'Users',
    yField: 'browser',
    autoFit: true,
    marginRatio: 0,
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      }
    },
    xAxis: {
      title: { text: "Usage" },
      min: 0,
    },
    yAxis: {
      title: { text: "browser" },
    },
    tooltip: {
      showTitle: false,
      formatter: (params) => {
        return { name: params.browser, value: `${params.Users} %` }
      }

    }
  };

  useEffect(()=>{
    if(chartselect === "/alerts-performance"){
      dispatch(trackboardBrand("/alerts-1"))
    } else if (chartselect === "/alerts-competition") {
      dispatch(trackboardBrand("/alerts-2"))
    } else if (chartselect === "/alerts-distribution") {
      dispatch(trackboardBrand("/alerts-3"))
    } else if (chartselect === "/alerts-innovation") {
      dispatch(trackboardBrand("/alerts-4"))
    } else if (chartselect === "/trackboard") {
      dispatch(trackboardBrand("/trackboard"))
    } else if (chartselect === "/performance-summary") {
      dispatch(trackboardBrand("/performance-summary"))
    } else if (chartselect === "/competition-summary") {
      dispatch(trackboardBrand("/volume-shifting"))
    } else if (chartselect === "/distribution-summary") {
      dispatch(trackboardBrand("/distribution-summary"))
    } else if (chartselect === "/innovation-tracker") {
      dispatch(trackboardBrand("/innovation-tracker"))
    } else if (chartselect === "/profile") {
      dispatch(trackboardBrand("/profile"))
    } else if (chartselect === "/diagnostics") {
      dispatch(trackboardBrand("/diagnostics"))
    } else if (chartselect === "/be-available") {
      dispatch(trackboardBrand("/be-available"))
    } else if (chartselect === "/share-of-shelf") {
      dispatch(trackboardBrand("/share-of-shelf"))
    } else if (chartselect === "/search-insights") {
      dispatch(trackboardBrand("/search-term"))
    } else if (chartselect === "/data-selector") {
      dispatch(trackboardBrand("/export"))
    } 
    setChartselect()
  }, [chartselect])

  useEffect(() => {
    setPieChartData(trackBoardBrand)
    setUniqueUserTable(trackboardTableData)
  },[trackBoardBrand,trackboardTableData])
    useEffect(()=>{
    setPieChartData(totalBrand)
    setUniqueUserTable(totalTableData)
  },[totalBrand,totalTableData])


  const handleChange = () =>{
    setTotalCheck(false)
    if(totalCheck){
      setPieHeader(TotalBrandChart && TotalBrandChart.length && TotalBrandChart[0].PAGE.substring(1).toUpperCase());
      setChartselect(TotalBrandChart && TotalBrandChart.length && TotalBrandChart[0].PAGE)
    }else{
      setChartselect('')
      setPieHeader('Total')
      dispatch(TotalBrandChart())
    }
  }


  useEffect(() => {
    if (totalBrand.length > 0) {
      setPieHeader('Total')
    }
    // setPieHeader(alertspageCount && alertspageCount.length && alertspageCount[0].PAGE.substring(1).toUpperCase());
    // setChartselect(alertspageCount && alertspageCount.length && alertspageCount[0].PAGE)
  }, [totalBrand])

  const setPieData = (selection) => {
    setPieHeader(selection.substring(1).toUpperCase());
    setChartselect(selection)
  }

  const Piechart = (pieChartData) => ({
    appendPadding: 10,
    data: pieChartData,
    angleField: 'value',
    colorField: 'brands',
    radius: 1,
    legend: {
      itemWidth: 100,
      maxWidthRatio: '0.9'
    },
    color: (type) => {
      if (type.brands === 'NUTRO' || type.brands === 'NUTRO:NUTRO MAX' || type.brands === 'NUTRO:NUTRO CORE' || type.brands === 'NUTRO:NUTRO ULTRA') {
        return '#000000';
      } else if (type.brands === "PEDIGREE" || type.brands === 'PEDIGREE:PEDIGREE MM' || type.brands === 'PEDIGREE MM' || type.brands === 'PEDIGREE:PEDIGREE CORE TREATS' || type.brands === 'PEDIGREE:PEDIGREE DENTASTIX') {
        return '#FFBF00';
      } else if (type.brands === "GREENIES") {
        return '#006400';
      } else if (type.brands === "IAMS") {
        return '#FF8C00';
      } else if (type.brands === "KARMA") {
        return '#66CDAA';
      } else if (type.brands === "CESAR") {
        return '#800080';
      } else if (type.brands === "CRAVE") {
        return '#C0C0C0';
      } else if (type.brands === "SHEBA") {
        return '#D2B48C';
      } else if (type.brands === "TEMPTATIONS") {
        return '#FFFF00';
      }
    },
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },

  });


  const data = [
    {
      key: "1",
      email: "ateet@effem.com",
      designation: "BM",
      customer_country: "US",
      preferred_brand: "Nutro Care",
      are_you_admin: "Yes",
      actions: "",
    },
    {
      key: "2",
      email: "pratap@effem.com",
      designation: "BM",
      customer_country: "US",
      preferred_brand: "Nutro Care",
      are_you_admin: "Yes",
      actions: "",
    },
  ];

  const columnsExport = [
    {
      title: "Email",
      dataIndex: "user_email",
      key: "user_email",
    },
    {
      title: "First Name",
      dataIndex: "user_first_name",
      key: "user_first_name",
    },
    {
      title: "Last Name",
      dataIndex: "user_last_name",
      key: "user_last_name",
    },
    {
      title: "Designation",
      dataIndex: "user_designation",
      key: "user_designation",
    },
    {
      title: "Market",
      dataIndex: "customer_country",
      key: "customer_country",
    },
    {
      title: "Preferred Brand",
      dataIndex: "preferred_brand",
      key: "preferred_brand",
    },
    {
      title: "Admin user",
      dataIndex: "user_is_admin",
      key: "user_is_admin",
      renderText: (val, rec) => ({
        value: val === 1 ? "Yes" : "No",
        numFmt: numFmt.text,
      }),
    },
    {
      title: "Access to Audit Reports",
      dataIndex: "is_audit_enabled",
      key: "is_audit_enabled",
      renderText: (val, rec) => ({
        value: val === 1 ? "Yes" : "No",
        numFmt: numFmt.text,
      }),
    },
    {
      title: "Review Alert",
      dataIndex: "is_review_alert",
      key: "is_review_alert",
      renderText: (val, rec) => ({
        value: val === 1 ? "Yes" : "No",
        numFmt: numFmt.text,
      }),
    },
  ];

  const columns = [
    {
      title: "Email",
      dataIndex: "user_email",
      key: "user_email",
      sorter: (a, b) =>
        a.user_email.toLowerCase() < b.user_email.toLowerCase() ? -1 : 1,
    },
    // {
    //   title: "First Name",
    //   dataIndex: "user_first_name",
    //   key: "user_first_name",
    //   sorter: (a, b) =>
    //     a.user_first_name.toLowerCase() < b.user_first_name.toLowerCase()
    //       ? -1
    //       : 1,
    // },
    // {
    //   title: "Last Name",
    //   dataIndex: "user_last_name",
    //   key: "user_last_name",
    //   sorter: (a, b) =>
    //     a.user_last_name?.toLowerCase() < b.user_last_name?.toLowerCase()
    //       ? -1
    //       : 1,
    // },
    {
      title: "Designation",
      dataIndex: "user_designation",
      key: "user_designation",
      sorter: (a, b) =>
        a.user_designation.toLowerCase() < b.user_designation.toLowerCase()
          ? -1
          : 1,
      width: 190,
    },
    {
      title: "Market",
      dataIndex: "customer_country",
      key: "customer_country",
      sorter: (a, b) =>
        a.customer_country.toLowerCase() < b.customer_country.toLowerCase() ? -1 : 1,
      width: 190,
    },
    {
      title: "Preferred Brand",
      dataIndex: "preferred_brand",
      key: "preferred_brand",
      sorter: (a, b) =>
        a.preferred_brand.toLowerCase() < b.preferred_brand.toLowerCase()
          ? -1
          : 1,
      width: 210,
    },
    {
      title: "Admin user",
      dataIndex: "user_is_admin",
      key: "user_is_admin",
      render: (val, rec) => (val === 1 ? "Yes" : "No"),
      sorter: (a, b) => (a.user_is_admin < b.user_is_admin ? -1 : 1),
      width: 160,
    },
    {
      title: "Access to Audit Reports",
      dataIndex: "is_audit_enabled",
      key: "is_audit_enabled",
      render: (val, rec) => (val === 1 ? "Yes" : "No"),
      sorter: (a, b) => (a.is_audit_enabled < b.is_audit_enabled ? -1 : 1),
      width: 180,
    },
    {
      title: "Review Alert",
      dataIndex: "is_review_alert",
      key: "is_review_alert",
      render: (val, rec) => (val === 1 ? "Yes" : "No"),
      sorter: (a, b) => (a.is_review_alert < b.is_review_alert ? -1 : 1),
      width: 180,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              setDeleteUserEmail(record.user_email);
              setOpenModalDelete(true);
            }}
          >
            <DeleteOutlined style={{ fontSize: 16 }} />
          </a>
          <a
            onClick={() => {
              dispatch(setSelectedUser(record));
              setEditing(true);
              setOpenModal(true);
            }}
          >
            <EditOutlined style={{ fontSize: 16 }} />
          </a>
        </Space>
      ),
      width: 80,
      align: "center",
    },
  ];

  const options = [{
    label: 'Performance Summary',
    value: 1,
  },
  {
    label: 'Competition Summary',
    value: 2,
  },
  {
    label: 'Distribution Summary',
    value: 3,
  },
  {
    label: 'Innovation Tracker',
    value: 4,
  }, 
  {
    label: 'Diagnostics',
    value: 5,
  },
  {
    label: 'Search Insights',
    value: 6,
  },
  {
    label: 'Share of Shelf',
    value: 7,
  },
  {
    label: 'Be Available',
    value: 8,
  }];

  const [value, setValue] = useState([]);

  const chartHandle = (data) => {
    setDaily(data)
    dispatch(setChartSelect(data))
  }
  const chartSelect = (value) => {
    setChartRadio(value);
  }
  const selectProps = {
    mode: 'multiple',
    style: {
      width: '30%',
    },
    value,
    options,
    onChange: (newValue) => {
      setValue(newValue);
      
    },
    placeholder: 'Select Page...',
  };

  columns.forEach((column, index) => {
    if (
      column.dataIndex !== "actions"
      // column.dataIndex !== "user_is_admin" &&
      // column.dataIndex !== "is_audit_enabled"
      // column.dataIndex !== "is_review_alert"
    ) {
      const filters = [];
      users.forEach((user) => {
        const data =
          column.dataIndex === "user_is_admin" ||
            column.dataIndex === "is_audit_enabled" ||
            column.dataIndex === "is_review_alert"
            ? user[column.dataIndex]
              ? "YES"
              : "NO"
            : user[column.dataIndex];
        const index = filters.findIndex(({ text }) => text === data);
        if (index === -1) filters.push({ text: data, value: data });
      });
      columns[index] = {
        ...column,
        filterDropdown: ({ confirm }) => (
          <>
            <div className="filter-dropdown">
              {filters.map((filter, index) => (
                <div key={index}>
                  <Checkbox
                    checked={(filteredValues[column.dataIndex] || []).includes(
                      filter.text
                    )}
                    onChange={(e) => {
                      let temp = [...(filteredValues[column.dataIndex] || [])];
                      if (e.target.checked) {
                        temp.push(filter.text);
                      } else {
                        
                        let ind = temp.findIndex((x) => x === filter.text);
                        if (ind > -1) {
                          temp.splice(ind, 1);
                        }
                      }
                      
                      setFilteredValues({
                        ...filteredValues,
                        [column.dataIndex]: temp,
                      });
                    }}
                  >
                    {filter.text}
                  </Checkbox>
                </div>
              ))}
            </div>
            <div className="filter-buttons">
              <Button
                type="primary"
                onClick={() => {
                  confirm();
                  setFilteredInfo({
                    ...filteredInfo,
                    [column.dataIndex]: filteredValues[[column.dataIndex]],
                  });
                }}
                icon={<FilterOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Filter
              </Button>
              <Button
                onClick={() => {
                  confirm();
                  setFilteredValues({
                    ...filteredValues,
                    [column.dataIndex]: null,
                  });
                  setFilteredInfo({
                    ...filteredInfo,
                    [column.dataIndex]: null,
                  });
                }}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </div>
          </>
        ),
        filteredValue: filteredInfo[column.dataIndex] || null,
        // onFilter: (value, record) => record[column.dataIndex].indexOf(value) === 0,
        onFilter: (value, record) => {
          const data =
            column.dataIndex === "user_is_admin" ||
              column.dataIndex === "is_audit_enabled" ||
              column.dataIndex === "is_review_alert"
              ? record[column.dataIndex]
                ? "YES"
                : "NO"
              : record[column.dataIndex];
          return data.indexOf(value) === 0;
        },
      };
    }
  });
  
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const getColumnSearchProps = ({ title, dataIndex }) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
       
        <Input
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch([], confirm, dataIndex)
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: <SearchOutlined />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    render: (text) => text,
  });

  columns[0] = { ...columns[0], ...getColumnSearchProps(columns[0]) };
  columns[3] = { ...columns[3], ...getColumnSearchProps(columns[3]) };

  const adminTabChange = (value) => {
    if (value === "new_contacts") {
      // history.push("/admin/manage-user-access");
      setAdminTab("new_contacts");
      setselectedTab('1');
    }
    else if (value === "usage_track") {
      setAdminTab("usage_track")
      setselectedTab('3')
    }
    else {
      // history.push("/admin/user-note");
      setAdminTab("user_note");
      setselectedTab('2');
    }
  };

  useEffect(() => {
    if (Object.keys(dropdowns.brands).length === 0) return;

    const brandsList = Object.keys(dropdowns.brands).map((brand) => ({
      title: brand,
      value: brand,
      children: dropdowns.brands[brand].map((subBrand) => ({
        title: subBrand,
        value: subBrand,
      })),
    }));

    setBrands(brandsList);
  }, [dropdowns]);

  useEffect(() => {
    if (props.tab && Boolean(user_is_admin)) {
      setAdminTab("bookmarked");
      setKey(props.tab);
    }
  }, []);

  useEffect(() => {
    if (Boolean(user_is_admin)) {
      dispatch(fetchDropdowns());
      dispatch(fetchUsers());
    }
  }, []);

  useEffect(() => {
    setUserNote(Boolean(userNoteData.is_note_enabled));
  }, [userNoteData]);

  // Formik
  const initialValues = {
    user_email: "",
    user_designation: "",
    customer_country: "",
    preferred_brand: allBrands,
    user_first_name: "",
    uesr_middle_name: "",
    user_last_name: "",
    user_is_admin: 0,
    is_audit_enabled: 0,
    is_review_alert: 0,
  };

  const formikRef = useRef();
  const validationSchema = Yup.object().shape({
    // user_email: Yup.string()
    //   .matches(
    //     /^[A-Za-z0-9]+[\._]?[A-Za-z0-9]+[@]\w+[.]\w{2,3}$/,
    //     "Invalid Email ID"
    //   )
    //   .required("Required Field"),
    user_email: Yup.string()
      .email("Email should be in the format abc.def@effem.com")
      .test(
        "test-email",
        "Email should be in the format abc.def@effem.com",
        (value) => {
          if (value)
            return (
              value.indexOf(
                "@effem.com",
                value.length - "@effem.com".length
              ) !== -1
            );
          else return true;
        }
      )
      .required("Required Field"),
    user_designation: Yup.string().required("Required Field"),
    customer_country: Yup.string().required("Required Field"),
    // preferred_brand: Yup.string().required("Required Field"),
    // team_cluster: Yup.string(),
    user_first_name: Yup.string().required("Required Field"),
    user_last_name: Yup.string().required("Required Field"),
  });

  const resetForm = () => {
    if (!formikRef.current) return;
    formikRef.current.resetForm();
  };

  const onSubmit = (values) => {
    
    dispatch(
      addEditUser(
        values,
        () => {
          resetForm();
          setOpenModal(false);
        },
        editing,
        userActions.added
      )
    );
  };

  useEffect(() => {
    dispatch(setExportData({ tableData: { data: users } }));
  }, [users]);

  const onTableChange = (pagination, filters, sorter, extra) => setFilteredInfo(filters);

  const onChange = (value) => {
    setUserNote(value || Boolean(userNoteData.is_note_enabled));
    if (!value && userNoteData.is_note_enabled) setDisableOpen(true);
  }

  return (
    <>{Boolean(user_is_admin) ?
      <Wrapper heading={"5"} hideHeaderBrand>
        <CustomLoader loading={loading > 0} />
        <Content
          className="site-layout-background content-body"
          style={{ paddingTop: 30 }}
        >
          {openModal && (
            <Formik
              innerRef={formikRef}
              initialValues={editing ? selectedUser : initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, resetForm, setFieldValue, values }) => (
                <Modal
                  title={editing ? "Edit User" : "Add New User"}
                  centered
                  visible={openModal}
                  onCancel={() => {
                    setOpenModal(false);
                    resetForm();
                  }}
                  onOk={handleSubmit}
                  okText="Save"
                  width={680}
                >
                  <Form layout="vertical">
                    <CustomLoader loading={loading > 0} />
                    <Row gutter={20}>
                      <Col span={12}>
                        <FormInput name="user_first_name" label="First Name" />
                      </Col>
                      <Col span={12}>
                        <FormInput name="user_last_name" label="Last Name" />
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col span={12}>
                        <FormInput
                          name="user_email"
                          label="Email"
                          disabled={editing}
                        />
                      </Col>
                      <Col span={12}>
                        <FormSelect
                          name="user_designation"
                          options={dropdowns.roles}
                          label="Role"
                        />
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col span={12}>
                        <FormSelect
                          name="customer_country"
                          options={dropdowns.markets}
                          label="Market"
                        />
                      </Col>
                      <Col span={12}>
                        {/* <FormSelect
                        name="preferred_brand"
                        options={dropdowns.brands}
                        label="Preferred Brand"
                      /> */}
                        <FormTreeSelect
                          name="preferred_brand"
                          data={brands}
                          label="Preferred Brand"
                        />
                      </Col>
                    </Row>
                    {/* <Row gutter={20}>
                    <Col span={12}>
                      <FormSelect
                        name="team_cluster"
                        options={dropdowns.teams}
                        label="Team"
                        dropdownRender={true}
                        onAdd={(team) => dispatch(addTeam(team))}
                      />
                    </Col>
                  </Row> */}
                    <Row>
                      <Col span={8}>
                        <Form.Item label="Admin user (Y/N)">
                          <Radio.Group
                            name="user_is_admin"
                            onChange={(e) =>
                              setFieldValue(
                                "user_is_admin",
                                Number(e.target.value)
                              )
                            }
                            value={hasValue(values.user_is_admin) ? Number(values.user_is_admin) : 0}
                          >
                            <Radio value={1}>Yes</Radio>
                            <Radio value={0}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="Audit report access (Y/N)">
                          <Radio.Group
                            name="is_audit_enabled"
                            onChange={(e) =>
                              setFieldValue(
                                "is_audit_enabled",
                                Number(e.target.value)
                              )
                            }
                            value={hasValue(values.is_audit_enabled) ? Number(values.is_audit_enabled) : 0}
                          >
                            <Radio value={1}>Yes</Radio>
                            <Radio value={0}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="Review Alert (Y/N)">
                          <Radio.Group
                            name="is_review_alert"
                            onChange={(e) =>
                              setFieldValue(
                                "is_review_alert",
                                Number(e.target.value)
                              )
                            }
                            value={hasValue(values.is_review_alert) ? Number(values.is_review_alert) : 0}
                          >
                            <Radio value={1}>Yes</Radio>
                            <Radio value={0}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Modal>
              )}
            </Formik>
          )}

          <Modal
            title="Delete User"
            centered
            visible={openModalDelete}
            onCancel={() => setOpenModalDelete(false)}
            onOk={() => {
              dispatch(deleteUser(deleteUserEmail));
              setOpenModalDelete(false);
            }}
            okText="Delete"
            width={384}
          >
            <p>Are you sure?</p>
          </Modal>

          <Modal
            title="Disable Notification"
            centered
            visible={disableOpen}
            onCancel={() => {
              setDisableOpen(false);
              setUserNote(true);
            }}
            onOk={() => {
              dispatch(updateUserNote({ is_note_enabled: 0, user_note: "" }));
              setUserNote(false);
              setDisableOpen(false);
            }}
            okText="Confirm"
            width={384}
          >
            <p>Disabling this note will send an email to all the users about this action. Please confirm if you want to proceed.</p>
          </Modal>

          <Row>
            <Radio.Group
              size="large"
              defaultValue="reports"
              value={adminTab}
              onChange={(e) => adminTabChange(e.target.value)}
              buttonStyle="solid"
            >
              <Radio.Button value="new_contacts">Manage User Access</Radio.Button>
              <Radio.Button value="user_note">Add Notification</Radio.Button>
              <Radio.Button value="usage_track">Usage Tracker</Radio.Button>
            </Radio.Group>
            {selectedTab === "3" && (
            <div className="export-button">
              <Button
                icon={<DownloadOutlined />}
                onClick={() =>{
                  if(activeTabKey === "0" || activeTabKey === "1"){
                  exportUsageDashboardData(
                    {totalBrand, totalTableData,trackboardTableData,trackBoardBrand,tableData, reportOpen, datePicker, userDataChart, chartSelectedUser, countries, brand, browsers, alertspageCount },
                    "export_usage_logs_data(Dashboard)"
                  )
                } else if(activeTabKey === "2"){
                    exportUsagePageviewData(
                      {totalBrand, totalTableData,trackboardTableData,trackBoardBrand,tableData, reportOpen, datePicker, userDataChart, chartSelectedUser, countries, brand, browsers, alertspageCount, pieHeader },
                      "export_usage_logs_data(Page views)"
                    )
                    
                  } else if(activeTabKey === "3"){
                    exportUsageCustomEventData(
                      {totalBrand, totalTableData,trackboardTableData,trackBoardBrand,tableData, reportOpen, datePicker, userDataChart, chartSelectedUser, countries, brand, browsers, alertspageCount,card3Table,pptExportCount },
                      "export_usage_logs_data(Custom event)"
                    )
                  
                  }
                }
                }
                type="primary"
                
              >
                Export data
              </Button>
            </div>
            )}
          </Row>
          <Row gutter={16} style={{ marginBottom: 16 }}>
            <Col xs={24}>
              {selectedTab === "1" && (
                <>
                  <Row gutter={20} justify="end" style={{ marginBottom: 20 }}>
                    <Col>
                      <Button
                        icon={<PlusCircleOutlined />}
                        onClick={(e) => {
                          setEditing(false);
                          setOpenModal(true);
                        }}
                        type="primary"
                      >
                        Add User
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        icon={<DownloadOutlined />}
                        onClick={() =>
                          exportTableData(
                            columnsExport,
                            exportData,
                            "Admin_Page_Users"
                          )
                        }
                      >
                        Export
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Table
                        dataSource={users}
                        className="custom-table"
                        columns={columns}
                        onChange={onTableChange}
                      />
                    </Col>
                  </Row>
                </>
              )}
              {selectedTab === "2" && (
                <Form
                  name="userNote"
                  initialValues={{ remember: true }}
                  onFinish={(values) => {
                    
                    dispatch(updateUserNote({ ...values, is_note_enabled: Number(userNote) }));
                  }}
                >
                  <>
                    <Row style={{ marginTop: 20 }}>
                      <Col>
                        <Form.Item
                          name="is_note_enabled"
                          label="Add a notification"
                          // valuePropName="checked"
                          className="f-note"
                        >
                          <Switch checked={userNote} onChange={onChange} />
                        </Form.Item>
                      </Col>
                    </Row>
                    {userNote && (
                      <Row>
                        <Col span={8}>
                          <h4 style={{ color: "#000" }}>Add notification text:</h4>
                          <Form.Item
                            name="user_note"
                            initialValue={userNoteData.user_note}
                            rules={[
                              {
                                required: true,
                                message: "Required Field!",
                              },
                            ]}>
                            <Input.TextArea placeholder="Add text here" />
                          </Form.Item>
                          <Button type="primary" htmlType="submit">Apply</Button>
                        </Col>
                      </Row>
                    )}
                  </>
                </Form>
              )}
              {selectedTab === "3" && (
                <div style={{ paddingTop: "20px" }}>
                  <Row>
                    <Col md={24}>
                      <div>
                        <RangePicker style={{ padding: "10px", position: "absolute", zIndex: 10, top: 6, right: 0 }} allowClear={false} format={DateFormate} value={datePicker} onChange={(e) => { dispatch(setDatePicker(e)) }} />
                      </div>
                      <Tabs centered={true} defaultActiveKey="1" type="card" size={'small'} onChange={(activeKey) => {
                        setActiveTabKey(activeKey)
                      }}>
                        <TabPane tab="Dashboard" key="1">
                          <Loader loading={dashboardLoading > 0}>
                            <Row gutter={[12, 12]} style={{ justifyContent: "center" }}>
                              <Col flex="0 1 20%" style={{ maxWidth: '15%' }}>
                                <div className="card-style">
                                  <DataCard fieldTitle="Total Users" fieldValue={reportOpen && reportOpen.totalUser ? reportOpen.totalUser : 0} checkbox={false} onChange={onChange} />
                                </div>
                              </Col>
                              <Col flex="0 1 20%" style={{ maxWidth: '18%' }}>
                                <div className="card-style">
                                  <DataCard fieldTitle="Total View Pages" fieldValue={reportOpen && reportOpen.totalPage ? reportOpen.totalPage : 0} checkbox={false} onChange={onChange} />
                                </div>
                              </Col>
                              <Col flex="0 1 20%" style={{ maxWidth: '15%' }}>
                                <div className="card-style">
                                  <DataCard fieldTitle="Unique Users" fieldValue={reportOpen && reportOpen.uniqueUser ? reportOpen.uniqueUser : 0} checkbox={false} onChange={onChange} />
                                </div>
                              </Col>
                              <Col flex="0 1 20%" style={{ maxWidth: '18%' }}>
                                <div className="card-style">
                                  <DataCard fieldTitle="Unique User Trend" fieldValue={`${reportOpen && reportOpen.trend ? reportOpen.trend : 0} %`} checkbox={false} onChange={onChange} />
                                </div>
                              </Col>
                              {/* <Col flex="0 1 20%" >
                           
                          </Col> */}
                            </Row>
                            <Row>
                              <Col span={14}>
                                <div className="velocity-chart-wrap">
                                  <div className="velocity-chart-head">
                                    <h4>View Trends</h4>
                                    <Radio.Group style={{ marginBottom: "15px", display: "flex", justifyContent: "right" }} defaultValue={daily} buttonStyle="solid">
                                      <Radio.Button value="1" onChange={() => chartHandle("1")}>Daily</Radio.Button>
                                      <Radio.Button value="2" onChange={() => chartHandle("2")}>Weekly</Radio.Button>
                                      <Radio.Button value="3" onChange={() => chartHandle("3")}>Monthly</Radio.Button>
                                    </Radio.Group>
                                  </div>
                                  {userDataChart.length ?
                                    <ColumnChart
                                      data={userDataChart}
                                      point={{ size: 5, shape: 'diamon' }}
                                      isGroup="true"
                                      xField='time'
                                      yField='chart'
                                      seriesField='name'
                                      dodgePadding={5}
                                      xTitle="Time"
                                      yTitle="Numbers of Users"
                                      select={daily}
                                    /> :
                                    <Empty />}
                                </div>

                              </Col>
                              <Col md={1}>
                              </Col>
                              <Col span={9}>
                                <div className="velocity-chart-wrap">
                                  <Row>
                                    <Col md={6}>
                                      <div className="velocity-chart-head">
                                        <h4>Usage</h4>
                                      </div>
                                    </Col>
                                    <Col md={18}>
                                      <Radio.Group style={{ marginBottom: "20px", display: "flex", justifyContent: "right" }} defaultValue={chartRadio} buttonStyle="solid">
                                        <Radio.Button value={1} onChange={() => setChartRadio(1)}>Countries</Radio.Button>
                                        <Radio.Button value={2} onChange={() => setChartRadio(2)}>Brands</Radio.Button>
                                        <Radio.Button value={3} onChange={() => setChartRadio(3)}>Browsers</Radio.Button>
                                      </Radio.Group>
                                    </Col>
                                  </Row>

                                  {chartRadio === 1 &&
                                    <>
                                      {countries && countries.length ?
                                        <Bar {...config} /> :
                                        <Empty />
                                      }
                                    </>
                                  }
                                  {chartRadio === 2 &&
                                    <>
                                      {brand && brand.length ?
                                        <Bar  {...config2} /> :
                                        <Empty />
                                      }
                                    </>
                                  }
                                  {chartRadio === 3 &&
                                    <>
                                      {browsers && browsers.length ?
                                        <Bar  {...config3} /> :
                                        <Empty />
                                      }
                                    </>
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Loader>
                        </TabPane>
                        <TabPane tab="Page Views" key="2">
                          <Loader loading={pageViewsLoading > 0}>
                            <Row >
                              <Col span={16}>

                                {pieHeader !== "Total" ?
                                  <Button type="primary" onClick={handleChange} style={{ marginBottom: '4px', float: 'right' }} >
                                    Click to view all Pages
                                  </Button> : <p>Select a <b>page</b> from the table below to change selection</p>
                                }
                                <Table
                                  dataSource={alertspageCount}
                                  className="custom-table"
                                  columns={page2columns(setPieData).map(val => {
                                    return {
                                      sorter: (a, b) => val.dataIndex === "AVERAGE_LOAD_TIME" ? (a[val.dataIndex] < b[val.dataIndex] ? -1 : 1) : a[val.dataIndex] - b[val.dataIndex],
                                      ...val,
                                    }
                                  })}
                                  pagination={false}
                                  onRow={(record, index) => (
                                    {
                                      onClick: () => {
                                        let text = record.PAGE
                                        if (text === "/alerts-1") {
                                          text = "/alerts-performance"
                                        } else if (text === "/alerts-2") {
                                          text = "/alerts-competition"
                                        } else if (text === "/alerts-3") {
                                          text = "/alerts-distribution"
                                        } else if (text === "/alerts-4") {
                                          text = "/alerts-innovation"
                                        } else if (text === "/volume-shifting") {
                                          text = "/competition-summary"
                                        } else if (text === "/search-term") {
                                          text = "/search-insights"
                                        }  else if (text === "/export") {
                                          text = "/data-selector"
                                        } 
                                        setPieData(text);
                                        setTotalCheck(false)
                                      },

                                    })}
                                // onChange={onTableChange}
                                />
                              </Col>
                              <Col span={8}>
                                {pieHeader ? <h3 style={{ marginLeft: "25px", padding: "12px" }}>{`${pieHeader} : Views By Brands`}</h3> : ""}
                                {pieChartData && pieChartData.length ?
                                  <Pie {...Piechart(pieChartData)} width={600} height={250} /> :
                                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }
                              </Col>
                            </Row>

                            <Row style={{ marginTop: '20px' }}>
                              {pieHeader ? <h3>{pieHeader}</h3> : ""}
                              <Col span={24}>
                                <Table
                                  dataSource={uniqueUserTable}
                                  className="custom-table"
                                  columns={card2table.map(val => {
                                    return {
                                      sorter: (a, b) => val.dataIndex === "COUNTRY" ? (a[val.dataIndex] < b[val.dataIndex] ? -1 : 1) : a[val.dataIndex] - b[val.dataIndex],
                                      ...val,
                                    }
                                  })}
                                // onChange={onTableChange}
                                />
                              </Col>
                            </Row>


                          </Loader>
                        </TabPane>
                        <TabPane tab="Custom Events" key="3">
                          <Loader loading={customEventsLoading > 0} >
                            <Row gutter={[12, 12]} style={{ justifyContent: "center" }}>
                              <Col flex="0 1 20%" style={{ maxWidth: '18%' }}>
                                <div className="card-style">
                                  <DataCard fieldTitle="Alerts Bookmarked" fieldValue={pptExportCount[2]} checkbox={false} onChange={onChange} />
                                </div>
                              </Col>
                              <Col flex="0 1 20%" style={{ maxWidth: '18%' }}>
                                <div className="card-style">
                                  <DataCard fieldTitle="Total PPT Exports" fieldValue={pptExportCount[0]} checkbox={false} onChange={onChange} />
                                </div>
                              </Col>
                              <Col flex="0 1 20%" style={{ maxWidth: '18%' }}>
                                <div className="card-style">
                                  <DataCard fieldTitle="Total Excel Exports" fieldValue={pptExportCount[1]} checkbox={false} onChange={onChange} />
                                </div>
                              </Col>
                              <Col flex="0 1 20%" style={{ maxWidth: '20%' }}>
                                <div className="card-style">
                                  <DataCard fieldTitle="New Self Serve Reports Created" fieldValue={pptExportCount[3]} checkbox={false} onChange={onChange} />
                                </div>
                              </Col>
                              <Col flex="0 1 20%" style={{ maxWidth: '20%' }}>
                                <div className="card-style">
                                  <DataCard fieldTitle="Data Export" fieldValue={pptExportCount[4]} checkbox={false} onChange={onChange} />
                                </div>
                              </Col>
                            </Row>
                            <Row style={{ marginTop: '50px' }} >
                              <Select {...selectProps} />
                            </Row>
                            <Row style={{ marginTop: '20px' }}>
                              <Col span={24}>
                                <Table
                                  dataSource={card3Table}
                                  className="custom-table"
                                  columns={usagePage3Columns(value)}
                                // onChange={onTableChange}
                                />
                              </Col>
                            </Row>
                          </Loader>
                        </TabPane>
                      </Tabs>
                    </Col>
                  </Row>

                </div>


              )}
            </Col>
          </Row>
        </Content>
      </Wrapper >
      :
      <div className="authorization"><h3>Restricted Admin Access</h3><p><a onClick={() => { history.push("/") }}><LeftCircleOutlined style={{ marginRight: "5px" }} />Home</a></p></div>
    }
    </>
  );
}

export default Admin;
