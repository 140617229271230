export const dummyPets = ['All', 'CAT', 'DOG'];
export const dummyTechs = ['All', 'MM Dry', 'MM Emerging', 'MM Wet', 'Supplements', 'Treats'];
export const dummyFeedPhilosophy = ['All', 'Cat MM Emerging AO', 'Cat Treats AO', 'Cat Treats H&W', 'Cat Treats Meaty', 'Cat Treats Play'];
export const dummyTexture = ['All', 'AO', 'AO Cat Texture', 'AO Dog Texture', 'Bisc/Crunchy', 'Chew/Soft Chewy'];
export const dummyPackSize = ['All', 'AO', 'AO Cat Texture', 'AO Dog Texture', 'Bisc/Crunchy', 'Chew/Soft Chewy'];
export const dummyRetailer = [{ title: 'Amazon', value: 'Amazon' }, { title: 'Chewy', value: 'Chewy' }];
export const dummyManufacturer = ['All', 'TDC', '21st Century Healthcare', '4LIFE', 'ABC LLC', 'ABO Gear LLC'];
export const dummyBrand = ['All', '1 - TDC', '21st Century', '4LIFE', '9 LIVES', 'A+ Answers'];
export const dummySubBrand = ['All', '1 - TDC', '21st Century', '21st Century Essential Vet', '21st Century Pet-Vite', '21st Century Slim Pet'];
export const timePeriodWeek = [{
    title: 2021,
    value: 2021,
    children: [{
        title: 'P1 2021',
        value: 'P1 2021',
        children: [{
            title: 'P1 W1',
            value: 'P1 W1'
        }, {
            title: 'P1 W2',
            value: 'P1 W2'
        }, {
            title: 'P1 W3',
            value: 'P1 W3'
        }, {
            title: 'P1 W4',
            value: 'P1 W4'
        }]
    }, {
        title: 'P2 2021',
        value: 'P2 2021',
        children: [{
            title: 'P2 W1',
            value: 'P2 W1'
        }, {
            title: 'P2 W2',
            value: 'P2 W2'
        }, {
            title: 'P2 W3',
            value: 'P2 W3'
        }, {
            title: 'P2 W4',
            value: 'P2 W4'
        }]
    }, {
        title: 'P3 2021',
        value: 'P3 2021',
        children: [{
            title: 'P3 W1',
            value: 'P3 W1'
        }, {
            title: 'P3 W2',
            value: 'P3 W2'
        }, {
            title: 'P3 W3',
            value: 'P3 W3'
        }, {
            title: 'P3 W4',
            value: 'P3 W4'
        }]
    }, {
        title: 'P4 2021',
        value: 'P4 2021',
        children: [{
            title: 'P4 W1',
            value: 'P4 W1'
        }, {
            title: 'P4 W2',
            value: 'P4 W2'
        }, {
            title: 'P4 W3',
            value: 'P4 W3'
        }, {
            title: 'P4 W4',
            value: 'P4 W4'
        }]
    }]
}, {
    title: 2022,
    value: 2022,
    children: [{
        title: 'P1 2022',
        value: 'P1 2022',
        children: [{
            title: 'P1 W1',
            value: 'P1 W1'
        }, {
            title: 'P1 W2',
            value: 'P1 W2'
        }, {
            title: 'P1 W3',
            value: 'P1 W3'
        }, {
            title: 'P1 W4',
            value: 'P1 W4'
        }]
    }, {
        title: 'P2 2022',
        value: 'P2 2022',
        children: [{
            title: 'P2 W1',
            value: 'P2 W1'
        }, {
            title: 'P2 W2',
            value: 'P2 W2'
        }, {
            title: 'P2 W3',
            value: 'P2 W3'
        }, {
            title: 'P2 W4',
            value: 'P2 W4'
        }]
    }, {
        title: 'P3 2022',
        value: 'P3 2022',
        children: [{
            title: 'P3 W1',
            value: 'P3 W1'
        }, {
            title: 'P3 W2',
            value: 'P3 W2'
        }, {
            title: 'P3 W3',
            value: 'P3 W3'
        }, {
            title: 'P3 W4',
            value: 'P3 W4'
        }]
    }, {
        title: 'P4 2022',
        value: 'P4 2022',
        children: [{
            title: 'P4 W1',
            value: 'P4 W1'
        }, {
            title: 'P4 W2',
            value: 'P4 W2'
        }, {
            title: 'P4 W3',
            value: 'P4 W3'
        }, {
            title: 'P4 W4',
            value: 'P4 W4'
        }]
    }]
}, {
    title: 2023,
    value: 2023,
    children: [{
        title: 'P1 2023',
        value: 'P1 2023',
        children: [{
            title: 'P1 W1',
            value: 'P1 W1'
        }, {
            title: 'P1 W2',
            value: 'P1 W2'
        }, {
            title: 'P1 W3',
            value: 'P1 W3'
        }, {
            title: 'P1 W4',
            value: 'P1 W4'
        }]
    }, {
        title: 'P2 2023',
        value: 'P2 2023',
        children: [{
            title: 'P2 W1',
            value: 'P2 W1'
        }, {
            title: 'P2 W2',
            value: 'P2 W2'
        }, {
            title: 'P2 W3',
            value: 'P2 W3'
        }, {
            title: 'P2 W4',
            value: 'P2 W4'
        }]
    }, {
        title: 'P3 2023',
        value: 'P3 2023',
        children: [{
            title: 'P3 W1',
            value: 'P3 W1'
        }, {
            title: 'P3 W2',
            value: 'P3 W2'
        }, {
            title: 'P3 W3',
            value: 'P3 W3'
        }, {
            title: 'P3 W4',
            value: 'P3 W4'
        }]
    }, {
        title: 'P4 2023',
        value: 'P4 2023',
        children: [{
            title: 'P4 W1',
            value: 'P4 W1'
        }, {
            title: 'P4 W2',
            value: 'P4 W2'
        }, {
            title: 'P4 W3',
            value: 'P4 W3'
        }, {
            title: 'P4 W4',
            value: 'P4 W4'
        }]
    }]
}]
export const kpiTree = [{
    title: 'General',
    value: 'General',
    checkable: false,
    children: [{
        title: '$ Sales',
        value: '$ Sales',
    }, {
        title: 'Unit Sales',
        value: 'Unit Sales',
    }, {
        title: '$ Conversion',
        value: '$ Conversion',
    }, {
        title: 'Unit Conversion',
        value: 'Unit Conversion',
    }]
}
    , {
    title: 'Be Available',
    value: 'Be Available',
    checkable: false,
    children: [{
        title: '# of items listed',
        value: '# of items listed',
    }, {
        title: 'In Stock %',
        value: 'In Stock %',
    }]
}, {
    title: 'Be Visible',
    value: 'Be Visible',
    checkable: false,
    children: [{
        title: 'Share of Shelf',
        value: 'Share of Shelf',
    }]
}, {
    title: 'Be Selected',
    value: 'Be Selected',
    checkable: false,
    children: [{
        title: 'Average Rating',
        value: 'Average Rating',
    }, {
        title: 'No. of Reviews',
        value: 'No. of Reviews',
    }, {
        title: 'Conversion Rate',
        value: 'Conversion Rate',
    }, {
        title: 'Average Placement',
        value: 'Average Placement',
    }, {
        title: 'Click Rate',
        value: 'Click Rate',
    }]
}]

export const dummyKeywordCategory = [
    {
        title: "DOG",
        value: "DOG",
        children: [{
            title: "Total Dog",
            value: "Total Dog"
        }, {
            title: "Dry Dog",
            value: "Dry Dog"
        }, {
            title: "Wet Dog",
            value: "Wet Dog"
        }, {
            title: "Dog Treats",
            value: "Dog Treats"
        }]
    }]
export const dummyKeywordType = ['All', 'Branded - Mars', 'Branded - Competition', 'Generic']
export const dummyKeywords = ['All', 'Acana Puppy Food', 'Adult Cat', 'Adult Cat Food', 'Adult Dog Food']
