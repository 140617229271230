
import React, { useEffect, useState } from "react";
import { cloneDeep, isEqual } from "lodash";
import {
    Layout,
    Row,
    Col,
    Select,
    Button,
    Collapse,
    Form,
    Table,
    message,
    TreeSelect,
    Checkbox,
    Radio
} from "antd";
import { withRouter } from "react-router-dom";
import Wrapper from "../../components/wrapper";
import { useSelector, useDispatch } from "react-redux";
import { DownloadOutlined, TableOutlined } from '@ant-design/icons';
import "./export.scss";
import Loader from "../../components/loader";
import { getDataPreview, getDropdownFilters, getEcomDropdownFilters, initiateTask, setAggregates, setDataAggregations, setClusterNotification, setFilterCount, setFilters, setGroupBy, setKpis, setPeriods, setTaskCompletion, setTimePeriodCheckbox, setTimePeriodYA, initiateCluster, setEcomKpis, setEcomFilters, getEcomDataPreview, setGroupByEcom, setEcomTimePeriodCheckbox, initiateEcomTask, initiatePeriodTask, setPeriodTaskCompletion } from "../../slicers/dataSources.slicer";
import CustomAxios, { CustomAxiosDE } from "../../utilities/services/api.service";
import { ENDPOINTS } from "../../utilities/constants";
import { openNotificationWithIcon } from "../../utilities/helper";
import { appInsights } from "../../utilities/appInsights";
import { dummyBrand, dummyFeedPhilosophy, dummyKeywordCategory, dummyKeywordType, dummyKeywords, dummyManufacturer, dummyPackSize, dummyPets, dummyRetailer, dummySubBrand, dummyTechs, dummyTexture, kpiTree, timePeriodWeek } from "./exportUIHelper";
import { onChange } from "../performance-summary/psUIHelper";
import Password from "antd/lib/input/Password";

const { Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;

/**
 * Data Export
 * It enables the user to filter the data source by several KPIs and run the export process in background.
 */

function Export(props) {
    const dispatch = useDispatch();
    const { loading } = useSelector(
        (state) => state.refreshDate
    );
    const { page } = props;
    const { loading: dataSourcesLoading,
        dropdownLoading,
        data_filters,
        ecom_data_filters,
        data_aggregation,
        ecom_data_aggregation,
        filters,
        ecomFilters,
        groupBy,
        groupByEcom,
        product_pet,
        product_technology,
        product_sub_technology,
        product_manufacturer,
        product_brand,
        product_sub_brand,
        product_pricing_tier,
        product_pricing_tier_partition,
        product_texture,
        product_package,
        clusterNotification,
        product_package_size,
        ecom_product_pet,
        ecom_product_technology,
        ecom_product_feed_philosophy,
        ecom_product_manufacturer,
        ecom_product_brand,
        ecom_product_sub_brand,
        ecom_product_texture,
        ecom_product_package_size,
        ecom_customer_retailer_details,
        ecom_product_upc,
        ecomDataPreview,
        ecomDataPreviewColumns,
        dataPreview,
        dataPreviewColumns,
        timePeriodCheckbox,
        ecomTimePeriodCheckbox,
        tableLoading,
        task_id,
        period_task_id,
        task_completion, period_task_completion, filterCount, kpis, ecomKpis, periods, aggregates, ecomPeriods, ecomAggregates } = useSelector((state) => state.dataSources)

    const [applyError, setApplyError] = useState(false);
    const [groupDropdown, setGroupDropdown] = useState([]);
    const [ecomGroupDropdown, setEcomGroupDropdown] = useState([]);
    const [zipEnable, setZipEnabled] = useState(false);
    const [periodZipEnable, setPeriodZipEnabled] = useState(false);
    const [timePeriod, setTimePeriod] = useState([]);
    const [ecomtimePeriod, setEcomTimePeriod] = useState([]);
    const [customerDetails, setCustomerDetails] = useState([]);
    const [clusterLoading, setClusterLoading] = useState(0);
    const [exportCategory, setExportCategory] = useState(page);
    const upcExportAccess = useSelector((state) => state.profile.user.upc_export_access);
    const [notificationShown, setNotificationShown] = useState(false);

    const checkEcomCategory = () => {
        return exportCategory === "Ecom UPC"
    }

    const timePeriodSelection = () => (e) => {
        if (e.target.checked) {
            let value = [...timePeriodCheckbox]
            value.push(e.target.value)
            dispatch(setTimePeriodCheckbox(value))
        }
        else {
            let value = [...timePeriodCheckbox]
            value = value.filter(val => val !== e.target.value)
            dispatch(setTimePeriodCheckbox(value))
        }
    }

    const ecomTimePeriodSelection = () => (e) => {
        if (e.target.checked) {
            let value = [...ecomTimePeriodCheckbox]
            value.push(e.target.value)
            dispatch(setEcomTimePeriodCheckbox(value))
        }
        else {
            let value = [...ecomTimePeriodCheckbox]
            value = value.filter(val => val !== e.target.value)
            dispatch(setEcomTimePeriodCheckbox(value))
        }
    }

    useEffect(() => {
        if (data_filters && data_filters.period_year && Object.keys(data_filters).length > 0 && Object.keys(data_filters.period_year).length > 0) {
            let periodYear = data_filters.period_year
            // let periodYear = { 'Current Period': ['L4W', 'L12W', 'L52W', 'YTD'], 'L4W': ['2022P13', '2022P12', '2022P11', '2022P10', '2022P09', '2022P08', '2022P07', '2022P06', '2021P13', '2021P12', '2021P11', '2021P10', '2021P09', '2021P08', '2021P07', '2021P06', '2020P13', '2020P12', '2020P11', '2020P10', '2020P09'], 'L52W': ['2022P13', '2022P12', '2022P11', '2022P10', '2022P09', '2022P08', '2022P07', '2022P06', '2021P13', '2021P12', '2021P11', '2021P10', '2021P09', '2021P08', '2021P07', '2021P06', '2020P13', '2020P12', '2020P11', '2020P10', '2020P09'], 'YTD': ['2022P13', '2022P12', '2022P11', '2022P10', '2022P09', '2022P08', '2022P07', '2022P06', '2021P13', '2021P12', '2021P11', '2021P10', '2021P09', '2021P08', '2021P07', '2021P06', '2020P13', '2020P12', '2020P11', '2020P10', '2020P09'] }
            setTimePeriod(
                Object.keys(periodYear).map((year) => ({
                    title: year,
                    value: year,
                    children: periodYear[year] && periodYear[year].length > 0 ? periodYear[year].map((period) => ({
                        title: `${period} ${year}`,
                        // title: `${period}`,
                        value: `${year}:${period}`
                    })) : []
                }))
            );

        }

        if (ecom_data_filters && Object.keys(ecom_data_filters).length > 0 && ecom_data_filters.period_year && Object.keys(ecom_data_filters.period_year).length > 0) {
            let ecomPeriodYear = ecom_data_filters.period_year;

            setEcomTimePeriod(
                Object.keys(ecomPeriodYear).map((year) => ({
                    title: year,
                    value: year,
                    children: ecomPeriodYear[year] ? Object.keys(ecomPeriodYear[year]).map((period) => ({
                        title: `${period} ${year}`,
                        value: `${year}:${period}`,
                        children: ecomPeriodYear[year][period] ? ecomPeriodYear[year][period].map((week) => ({
                            title: `${week} ${period} ${year}`,
                            value: `${year}:${period}:${week}`
                        })) : []
                    })) : []
                }))
            );

        }

        if (Object.keys(data_filters).length > 0 && data_filters.customer_retailer_details && Object.keys(data_filters.customer_retailer_details).length > 0) {
            let customerdetailsdropdown = data_filters.customer_retailer_details;
            setCustomerDetails(
                Object.keys(customerdetailsdropdown).map((customer) => ({
                    title: customer,
                    value: customer,
                    children: customerdetailsdropdown[customer] && customerdetailsdropdown[customer].length > 0 ? customerdetailsdropdown[customer].map((retailer) => {
                        if (retailer === "Total Market") {
                            return {
                                title: `${retailer}-${customer}`,
                                // title: `${retailer}`,
                                value: `${customer}:${retailer}`
                            }
                        } else {
                            return {
                                title: `${retailer}`,
                                // title: `${retailer}`,
                                value: `${customer}:${retailer}`
                            }
                        }
                    }) : []
                }))
            );


            console.log('customerdetails', Object.keys(customerdetailsdropdown).map((customer) => ({
                title: customer,
                value: customer,
                children: customerdetailsdropdown[customer] && customerdetailsdropdown[customer].length > 0 ? customerdetailsdropdown[customer].map((retailer) => ({
                    title: `${retailer} ${customer}`,
                    // title: `${retailer}`,
                    value: `${customer}:${retailer}`
                })) : []
            })))
        }

        if (data_aggregation && data_aggregation.group_drop_down && Object.keys(data_aggregation).length > 0 && Object.keys(data_aggregation.group_drop_down).length > 0) {
            let groups = data_aggregation.group_drop_down
            setGroupDropdown(
                Object.keys(data_aggregation.group_drop_down).map((group) => ({
                    title: group,
                    value: group,
                    children: groups[group] && groups[group].length > 0 ? groups[group].map((column) => ({
                        title: `${group} ${column}`,
                        value: `${column}`
                    })) : []
                }))
            );
        }

        if (ecom_data_aggregation && ecom_data_aggregation.group_drop_down && Object.keys(ecom_data_aggregation).length > 0 && Object.keys(ecom_data_aggregation.group_drop_down).length > 0) {
            let ecomGroups = ecom_data_aggregation.group_drop_down
            setEcomGroupDropdown(
                Object.keys(ecom_data_aggregation.group_drop_down).map((group) => ({
                    title: group,
                    value: group,
                    children: ecomGroups[group] && ecomGroups[group].length > 0 ? ecomGroups[group].map((column) => ({
                        title: `${group} ${column}`,
                        value: `${column}`
                    })) : []
                }))
            );
        }

    }, [data_filters, ecom_data_filters, data_aggregation, ecom_data_aggregation])

    useEffect(() => {

    }, [groupBy, groupByEcom])

    const handleKpiChange = (value) => {
        dispatch(setEcomKpis(value));

        const includesTraffic = value.some(kpi => kpi.includes('TRAFFIC SIMILARWEB'));

        if ((includesTraffic) && !notificationShown) {
            openNotificationWithIcon('info', 'Similarweb Traffic have one month lag');
            setNotificationShown(true);
        } else if (!includesTraffic) {
            setNotificationShown(false);
        }
    };
    let taskInterval = null;

    async function checkTaskCompletion() {
        let encodedURL = "task_id=" + task_id
        CustomAxios((checkEcomCategory() ? ENDPOINTS.ecomDataSourcesCompletion : ENDPOINTS.dataSourcesCompletion) + '?' + encodedURL, 'GET').then((response) => {
            if (!zipEnable) {
                if (response.data.error_code) {
                    if (response.data.error_code === 8003) {
                        if (response.data.message === "FAILURE") {
                            dispatch(setTaskCompletion(true))
                            clearInterval(taskInterval);
                            openNotificationWithIcon('error', 'Download Failed')
                        }
                    }
                    else if (response.data.error_code === 8000) {
                        dispatch(setTaskCompletion(true))
                        clearInterval(taskInterval);
                        openNotificationWithIcon('error', 'Download Failed')
                    }
                    else {
                        dispatch(setTaskCompletion(true))
                        clearInterval(taskInterval);
                        openNotificationWithIcon('error', response.data.error_code)
                    }
                }
                else {
                    clearInterval(taskInterval)
                    setZipEnabled(true)
                }
            }
        }).catch((err) => {
            dispatch(setTaskCompletion(true))
            clearInterval(taskInterval);
            openNotificationWithIcon('error', 'Export Failed')
        })
    }

    async function checkTaskCompletionZip() {
        let encodedURL = "task_id=" + task_id
        CustomAxios((checkEcomCategory() ? ENDPOINTS.ecomDataSourcesCompletion : ENDPOINTS.dataSourcesCompletion) + '?' + encodedURL, 'GET', {}, true).then((response) => {
            if (zipEnable) {
                clearInterval(taskInterval);
                dispatch(setTaskCompletion(true))
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'export' + '.zip');
                document.body.appendChild(link);
                link.click()
                // dispatch(deleteTask(task_id))
                setZipEnabled(false);

            }
        }).catch((err) => {
            dispatch(setTaskCompletion(true))
            clearInterval(taskInterval);
            openNotificationWithIcon('error', 'Export Failed')
        })
    }

    let periodTaskInterval = null;

    async function checkPeriodTaskCompletion() {
        let encodedURL = "task_id=" + period_task_id
        CustomAxios((ENDPOINTS.periodDataSourcesCompletion) + '?' + encodedURL, 'GET').then((response) => {
            const { error_code, message } = response.data;
            if (!periodZipEnable) {
                if (error_code) {
                    handleError(error_code, message);
                }
                else {
                    clearInterval(periodTaskInterval)
                    setPeriodZipEnabled(true)
                }
            }
        }).catch((err) => {
            handleError();
        })
    }

    function handleError(error_code, message) {

        const errorActions = {
            8003: () => {
                if (message === "FAILURE") {
                    clearInterval(periodTaskInterval);
                    dispatch(setPeriodTaskCompletion(true));
                    openNotificationWithIcon('error', 'Data File Download Failed');
                }
            },
            8000: () => {
                clearInterval(periodTaskInterval);
                dispatch(setPeriodTaskCompletion(true));
                openNotificationWithIcon('error', 'Data File Download Failed');
            },
            default: () => {
                clearInterval(periodTaskInterval);
                dispatch(setPeriodTaskCompletion(true));
                openNotificationWithIcon('error', error_code);
            },
        };

        (errorActions[error_code] || errorActions.default)();
    }

    async function checkPeriodTaskCompletionZip() {
        let encodedURL = "task_id=" + period_task_id
        CustomAxios((ENDPOINTS.periodDataSourcesCompletion) + '?' + encodedURL, 'GET', {}, true).then((response) => {
            if (periodZipEnable) {
                clearInterval(periodTaskInterval);
                dispatch(setPeriodTaskCompletion(true))
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'export' + '.zip');
                document.body.appendChild(link);
                link.click()
                // dispatch(deleteTask(task_id))
                setPeriodZipEnabled(false);

            }
        }).catch((err) => {
            dispatch(setPeriodTaskCompletion(true))
            clearInterval(periodTaskInterval);
            openNotificationWithIcon('error', 'Data File Download Failed')
        })
    }

    let clusterInterval = null;

    async function checkClusterInitiation() {
        setClusterLoading(1)
        CustomAxios(ENDPOINTS.clusterInitiated, 'GET').then((response) => {
            if (response.data.cluster_state) {
                if (response.data.cluster_state === "PENDING") {

                }
                else if (response.data.cluster_state === "TERMINATED") {
                    dispatch(initiateCluster());

                    openNotificationWithIcon('error', 'Cluster terminated')
                }
                else if (response.data.cluster_state === "RUNNING") {
                    setClusterLoading(0)
                    dispatch(setClusterNotification(true))
                    clearInterval(clusterInterval);
                }
            }
            else {
                setClusterLoading(0)
                dispatch(setClusterNotification(true))
                clearInterval(clusterInterval);
                openNotificationWithIcon('error', response.data)
            }
        }).catch((err) => {
            setClusterLoading(0)
            clearInterval(clusterInterval);
            openNotificationWithIcon('error', 'Cluster could not start')
        })
    }


    useEffect(() => {
        if (!task_completion) {
            taskInterval = setInterval(checkTaskCompletion, 5000)
        }
    }, [task_completion])

    useEffect(() => {
        if (!period_task_completion) {
            periodTaskInterval = setInterval(checkPeriodTaskCompletion, 5000)
        }

    }, [period_task_completion])

    useEffect(() => {
        if (!clusterNotification) {
            setClusterLoading(1)
            clusterInterval = setInterval(checkClusterInitiation, 5000)
        }
    }, [clusterNotification])


    useEffect(() => {
        if (zipEnable && !task_completion) {
            openNotificationWithIcon('success', 'Export file download almost ready..')
            clearInterval(taskInterval)
            taskInterval = setInterval(checkTaskCompletionZip, 5000)
        }
    }, [zipEnable])

    useEffect(() => {
        if (periodZipEnable && !period_task_completion) {
            openNotificationWithIcon('success', 'Data file download almost ready..')
            clearInterval(periodTaskInterval)
            periodTaskInterval = setInterval(checkPeriodTaskCompletionZip, 7500)
        }

    }, [periodZipEnable])



    const onSelection = (key, value, ecom = false) => {
        if (!ecom) {
            let newFilters = {
                ...filters,
                [key]: value
            }
            dispatch(setFilters(newFilters));

        }
        else {
            let newEcomFilters = {
                ...ecomFilters,
                [key]: value
            }
            dispatch(setEcomFilters(newEcomFilters));
        }

    }
    const onChannelSelection = (key, value) => {
        let newFilters = {
            ...filters,
        }
        key.map((item, i) => {
            newFilters = {
                ...newFilters,
                [item]: i === 0 ? value : []
            }
        }
        )


        dispatch(setFilters(newFilters));
    }

    const onChangeGeneral = (filterName, value, getDropdown = true) => {
        onSelection(filterName, value);
        if (getDropdown) {
            dispatch(getDropdownFilters({ ...filters, [filterName]: value }, filterName))
        }
    }

    const onChangeEcom = (value, filterName, getDropdown = true) => {
        onSelection(filterName, value, checkEcomCategory());
        if (getDropdown) {
            dispatch(getEcomDropdownFilters({ ...ecomFilters, [filterName]: value }, filterName))
        }
    }

    const kpiDropdown = () => {
        return data_aggregation.kpi && Object.keys(data_aggregation.kpi)
    }

    const ecomKpiDropdown = () => {
        return ecom_data_aggregation.kpi && Object.keys(ecom_data_aggregation.kpi)
    }

    const groupBySelection = (value, ecom = false) => {
        let groupBySelect = value
        if (!ecom) {
            if (groupBySelect.includes("Retailer Details") && !groupBySelect.includes("Channel")) {
                groupBySelect.push('Channel')
            }
            dispatch(setGroupBy(groupBySelect))
        }
        else {
            dispatch(setGroupByEcom(groupBySelect))
        }
    }



    return (
        <Wrapper heading={"2"} innerHeading={"10"} hideHeaderBrand>
            <Content className="site-layout-background content-body" style={{ background: '#fff', display: 'block' }}>
                <Loader loading={loading > 0} tip={"Please wait.."}>
                    <Row gutter={20} wrap={false} className="export-row-width">
                        <Col span={24}>
                            <Loader loading={false} tip="Please wait while export filters are loaded..">
                                <div className="d-left">
                                    <h3 style={{ paddingLeft: "10px" }}>Data Export</h3>
                                    <Row style={{ padding: "0px 0px 10px 10px" }}>
                                        <Col span={12}>
                                            <Radio.Group buttonStyle="solid" defaultValue={page} onChange={(e) => { setExportCategory(e.target.value); props.history.push(e.target.value === "General" ? '/export' : "/export-ecom"); }} value={exportCategory}>
                                                <Radio.Button value="General">General</Radio.Button>
                                                {/* <Radio.Button value="Ecom UPC">Ecom UPC</Radio.Button> */}
                                            </Radio.Group>
                                        </Col>
                                        {page === "General" && <Col span={11} style={{ "textAlign": "end" }}>
                                            <Button type="primary" style={{ "marginRight": "5px" }} icon={<DownloadOutlined />} size={"medium"} onClick={() => {
                                                if (clusterNotification) {
                                                    appInsights.trackEvent({ name: 'DATA_EXPORT' })
                                                    dispatch(initiatePeriodTask())
                                                }
                                                else {
                                                    clusterInterval = setInterval(checkClusterInitiation, 5000)
                                                }
                                            }} loading={(!periodZipEnable && !period_task_completion) || dataSourcesLoading > 0 || clusterLoading > 0 || dropdownLoading > 0}>
                                                {clusterLoading > 0 || dropdownLoading > 0 ? "Loading" : "Data File Download"}
                                            </Button>
                                        </Col>}
                                    </Row>
                                    {exportCategory === "General" && <><Row style={{ paddingLeft: "10px" }}>
                                        <Col md={12}>
                                            <h5>Product</h5>
                                            <Row gutter={10} style={{ marginTop: 10, marginBottom: 10 }}>
                                                <Col md={8}>
                                                    <label className="label-block-export">Pet</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        value={filters.product_pet}
                                                        onChange={(val) => onChangeGeneral('product_pet', val)}
                                                        // onBlur={() => dispatch(getDropdownFilters(filters, 'product_pet'))}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        loading={dropdownLoading}
                                                        maxTagCount="responsive"
                                                        placement="bottomLeft"
                                                        allowClear
                                                        style={{ width: '100%' }} >
                                                        {product_pet?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col md={8}>
                                                    <label className="label-block-export">Technology</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        value={filters.product_technology}
                                                        onChange={(val) => onChangeGeneral('product_technology', val)}
                                                        // onBlur={() => dispatch(getDropdownFilters(filters, 'product_technology'))}
                                                        mode="multiple"
                                                        loading={dropdownLoading}
                                                        allowClear
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {product_technology?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col md={8}>
                                                    <label className="label-block-export">Sub Technology</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        value={filters.product_sub_technology}
                                                        onChange={(val) => onChangeGeneral('product_sub_technology', val)}
                                                        // onBlur={() => dispatch(getDropdownFilters(filters, 'product_sub_technology'))}
                                                        mode="multiple"
                                                        loading={dropdownLoading}
                                                        allowClear
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {product_sub_technology?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                            </Row>
                                            <Row gutter={10} style={{ marginTop: 10, marginBottom: 10 }}>
                                                <Col md={8}>
                                                    <label className="label-block-export">Manufacturer</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        value={filters.product_manufacturer}
                                                        onChange={(val) => onChangeGeneral('product_manufacturer', val)}
                                                        loading={dropdownLoading}
                                                        // onBlur={() => dispatch(getDropdownFilters(filters, 'product_manufacturer'))}
                                                        allowClear
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {product_manufacturer?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col md={8}>
                                                    <label className="label-block-export">Brand</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        value={filters.product_brand}
                                                        onChange={(val) => onChangeGeneral('product_brand', val)}
                                                        // onBlur={() => dispatch(getDropdownFilters(filters, 'product_brand'))}
                                                        allowClear
                                                        loading={dropdownLoading}
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {product_brand?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col md={8}>
                                                    <label className="label-block-export">Sub Brand</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        value={filters.product_sub_brand}
                                                        onChange={(val) => onChangeGeneral('product_sub_brand', val)}
                                                        // onBlur={() => dispatch(getDropdownFilters(filters, 'product_sub_brand'))}
                                                        allowClear
                                                        loading={dropdownLoading}
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {product_sub_brand?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                            </Row>
                                            <Row gutter={10} style={{ marginTop: 10, marginBottom: 10 }}>
                                                <Col md={8}>
                                                    <label className="label-block-export">Price Tier</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        value={filters.product_pricing_tier}
                                                        onChange={(val) => onChangeGeneral('product_pricing_tier', val)}
                                                        // onBlur={() => dispatch(getDropdownFilters(filters, 'product_pricing_tier'))}
                                                        allowClear
                                                        loading={dropdownLoading}
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {product_pricing_tier?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col md={8}>
                                                    <label className="label-block-export">Texture</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        value={filters.product_texture}
                                                        onChange={(val) => onChangeGeneral('product_texture', val)}
                                                        // onBlur={() => dispatch(getDropdownFilters(filters, 'product_texture'))}
                                                        allowClear
                                                        loading={dropdownLoading}
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {product_texture?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col md={8}>
                                                    <label className="label-block-export">Pack Type</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        value={filters.product_package}
                                                        loading={dropdownLoading}
                                                        onChange={(val) => onChangeGeneral('product_package', val)}
                                                        // onBlur={() => dispatch(getDropdownFilters(filters, 'product_package'))}
                                                        allowClear
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {product_package?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                            </Row>
                                            <Row gutter={10} style={{ marginTop: 10, marginBottom: 10 }}>
                                                <Col md={8}>
                                                    <label className="label-block-export">Pack Size</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        value={filters.product_package_size}
                                                        loading={dropdownLoading}
                                                        onChange={(val) => onChangeGeneral('product_package_size', val)}
                                                        // onBlur={() => dispatch(getDropdownFilters(filters, 'product_package_size'))}
                                                        allowClear
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {product_package_size?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={2}>

                                        </Col>
                                        <Col md={10}>
                                            <h5>Customer</h5>
                                            <Row gutter={10} style={{ marginTop: 10 }}>
                                                {/* <Col md={12}>
                                                    <label className="label-block-export">Channel</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        value={filters.customer_channel}
                                                        onChange={(val) => onChannelSelection(['customer_channel', 'customer_retailer_details'], val)}
                                                        allowClear
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {Object.keys(data_filters).length > 0 && Object.keys(data_filters.customer_retailer_details).length > 0 && Object.keys(data_filters.customer_retailer_details).map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col md={12}>
                                                    <label className="label-block-export">Retailer</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        value={filters.customer_retailer_details}
                                                        onChange={(val) => onChannelSelection(['customer_retailer_details'], val)}
                                                        // onBlur={() => dispatch(getDropdownFilters(filters))}
                                                        allowClear
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {Object.keys(data_filters).length > 0 && Object.keys(data_filters.customer_retailer_details).length > 0 && filters.customer_channel && data_filters.customer_retailer_details[filters.customer_channel].length > 0 && data_filters.customer_retailer_details[filters.customer_channel].map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col> */}
                                                <Col md={12}>
                                                    <TreeSelect
                                                        dropdownMatchSelectWidth={false}
                                                        showSearch={true}
                                                        placeholder={"All"}
                                                        multiple
                                                        treeCheckable={true}
                                                        value={filters.customer_retailer_details}
                                                        onChange={(value) => onSelection('customer_retailer_details', value)}
                                                        style={{ width: '100%', minWidth: 300 }}
                                                        dropdownStyle={{ minWidth: "20%" }}
                                                        treeData={customerDetails}
                                                        allowClear
                                                    />
                                                </Col>
                                            </Row>
                                            <h5 style={{ marginTop: 10 }}>Length of Time</h5>
                                            <Row style={{ marginTop: 10, justifyContent: "left" }}>
                                                <Checkbox value={"4W"} checked={timePeriodCheckbox.includes("4W")} onChange={timePeriodSelection()}>4W Period</Checkbox>
                                                <Checkbox value={"13W"} checked={timePeriodCheckbox.includes("13W")} onChange={timePeriodSelection()}>13W Period</Checkbox>
                                                <Checkbox value={"26W"} checked={timePeriodCheckbox.includes("26W")} onChange={timePeriodSelection()}>26W Period</Checkbox>
                                            </Row>
                                            <Row style={{ marginTop: 10, justifyContent: "left" }}>
                                                <Checkbox value={"52W"} checked={timePeriodCheckbox.includes("52W")} onChange={timePeriodSelection()}>52W MAT</Checkbox>
                                                <Checkbox value={"YTD"} checked={timePeriodCheckbox.includes("YTD")} onChange={timePeriodSelection()}>YTD</Checkbox>
                                                <Checkbox value={"Include YA"} checked={timePeriodCheckbox.includes("Include YA")} onChange={timePeriodSelection()}>Include YA</Checkbox>
                                            </Row>
                                            <Row gutter={10} style={{ marginTop: 10 }}>
                                                <Col md={5}>
                                                    <label className="label-block-export">Period Selection</label>
                                                    <TreeSelect
                                                        dropdownMatchSelectWidth={false}
                                                        showSearch={true}
                                                        placeholder={"All"}
                                                        multiple
                                                        treeCheckable={true}
                                                        value={filters.period_year}
                                                        onChange={(value) => onSelection('period_year', value)}
                                                        style={{ width: '100%', minWidth: 300 }}
                                                        dropdownStyle={{ minWidth: "20%" }}
                                                        treeData={timePeriod}
                                                        allowClear
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                        <Row gutter={10} style={{ paddingLeft: "5px" }}>
                                            <Col md={12}>
                                                <Col md={24}>
                                                    <Row style={{ marginTop: 10 }}>
                                                        <h5 style={{ marginTop: 10 }}>Group By</h5>
                                                        <TreeSelect
                                                            dropdownMatchSelectWidth={false}
                                                            showSearch={true}
                                                            placeholder={"All"}
                                                            multiple
                                                            treeCheckable={true}
                                                            onChange={(value) => groupBySelection(value)}
                                                            style={{ width: '100%', minWidth: 300 }}
                                                            dropdownStyle={{ minWidth: "10%" }}
                                                            value={checkEcomCategory() ? groupByEcom : groupBy}
                                                            treeData={checkEcomCategory() ? ecomGroupDropdown : groupDropdown}
                                                            allowClear
                                                        />
                                                    </Row>
                                                </Col>
                                            </Col>
                                            <Col md={2}>

                                            </Col>
                                            <Col md={10}>
                                                <Row gutter={10} style={{ marginTop: 10 }} >
                                                    <Col md={8}>
                                                        <h5 style={{ marginTop: 10 }}>KPI</h5>
                                                        <Select mode="multiple" dropdownMatchSelectWidth={false} placeholder="Select" value={kpis} onChange={(value) => {
                                                            if (value) {
                                                                dispatch(setKpis(value))
                                                            }
                                                        }}
                                                            allowClear maxTagCount="responsive" style={{ width: '100%' }}>
                                                            {kpiDropdown()?.map((option) => (
                                                                <Option value={option} disabled={timePeriodCheckbox.includes("26W") && (option == "NSV" || option == "GSV" || option == "MAC $")}>{option} {timePeriodCheckbox.includes("26W") && (option == "NSV" || option == "GSV" || option == "MAC $") ? " - 26W not available" : ""}</Option>
                                                            ))}
                                                        </Select>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: 10 }}>
                                                    {applyError && <p style={{ "color": "red" }}>Please fill all required fields</p>}
                                                </Row>
                                                <Row gutter={10} style={{ marginTop: 10 }}>
                                                    <Col md={12}>
                                                        {/* <Button type="text" icon={<PlusCircleOutlined />} onClick={() => dispatch(setFilterCount([...filterCount, Math.max(...filterCount) + 1]))}>Add KPI</Button> */}
                                                    </Col>
                                                    <Col md={4} style={{ display: "flex", justifyContent: "end" }}>
                                                        {clusterLoading === 0 && <Button type="primary" icon={<TableOutlined />} loading={tableLoading > 0} onClick={() => {
                                                            if (clusterNotification) {
                                                                if (kpis.length !== 0) {
                                                                    dispatch(getDataPreview(filters, groupBy, kpis, periods, aggregates, timePeriodCheckbox))
                                                                    setApplyError(false)
                                                                }
                                                                else {
                                                                    setApplyError(true)
                                                                }
                                                            }
                                                            else {
                                                                clusterInterval = setInterval(checkClusterInitiation, 5000)
                                                            }
                                                        }}>Preview</Button>}
                                                    </Col>
                                                    <Col md={8}>
                                                        <Button type="primary" icon={<DownloadOutlined />} onClick={() => {
                                                            if (clusterNotification) {
                                                                if (kpis.length !== 0) {
                                                                    appInsights.trackEvent({ name: 'DATA_EXPORT' })
                                                                    dispatch(initiateTask(filters, groupBy, kpis, periods, aggregates, timePeriodCheckbox))
                                                                    setApplyError(false)
                                                                }
                                                                else {
                                                                    setApplyError(true)
                                                                }
                                                            }
                                                            else {
                                                                clusterInterval = setInterval(checkClusterInitiation, 5000)
                                                            }
                                                        }} style={{ "width": "150px" }} loading={(!zipEnable && !task_completion) || dataSourcesLoading > 0 || clusterLoading > 0 || dropdownLoading > 0}>{clusterLoading > 0 || dropdownLoading > 0 ? "Export Loading" : "Export File"}</Button></Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </>}
                                    {checkEcomCategory() && <><Row style={{ paddingLeft: "10px" }}>
                                        <Col md={12}>
                                            <h5>Product</h5>
                                            <Row gutter={10} style={{ marginTop: 10, marginBottom: 10 }}>
                                                <Col md={8}>
                                                    <label className="label-block-export">Pet</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        value={ecomFilters.product_pet}
                                                        onChange={(val) => onChangeEcom(val, 'product_pet')}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        loading={dropdownLoading}
                                                        maxTagCount="responsive"
                                                        placement="bottomLeft"
                                                        allowClear
                                                        style={{ width: '100%' }} >
                                                        {ecom_product_pet?.map((val, i) => (
                                                            <Option key={i} value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col md={8}>
                                                    <label className="label-block-export">Technology</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        value={ecomFilters.product_technology}
                                                        onChange={(val) => onChangeEcom(val, "product_technology")}
                                                        // onBlur={() => dispatch(getEcomDropdownFilters(ecomFilters, "product_technology"))}
                                                        mode="multiple"
                                                        loading={dropdownLoading}
                                                        allowClear
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {ecom_product_technology?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col md={8}>
                                                    <label className="label-block-export">Feeding Philoshopy</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        value={ecomFilters.product_feed_philosophy}
                                                        onChange={(val) => onChangeEcom(val, 'product_feed_philosophy')}
                                                        // onBlur={() => dispatch(getEcomDropdownFilters(ecomFilters, "product_feed_philosophy"))}
                                                        allowClear
                                                        loading={dropdownLoading}
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {ecom_product_feed_philosophy?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                            </Row>
                                            <Row gutter={10} style={{ marginTop: 10, marginBottom: 10 }}>
                                                <Col md={8}>
                                                    <label className="label-block-export">Manufacturer</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        value={ecomFilters.product_manufacturer}
                                                        onChange={(val) => onChangeEcom(val, 'product_manufacturer')}
                                                        // onBlur={() => dispatch(getEcomDropdownFilters(ecomFilters, "product_manufacturer"))}
                                                        allowClear
                                                        loading={dropdownLoading}
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {ecom_product_manufacturer?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col md={8}>
                                                    <label className="label-block-export">Brand</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        value={ecomFilters.product_brand}
                                                        onChange={(val) => onChangeEcom(val, 'product_brand')}
                                                        // onBlur={() => dispatch(getEcomDropdownFilters(ecomFilters, "product_brand"))}
                                                        allowClear
                                                        loading={dropdownLoading}
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {ecom_product_brand?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col md={8}>
                                                    <label className="label-block-export">Sub Brand</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        value={ecomFilters.product_sub_brand}
                                                        onChange={(val) => onChangeEcom(val, 'product_sub_brand')}
                                                        // onBlur={() => dispatch(getEcomDropdownFilters(ecomFilters, "product_sub_brand"))}
                                                        allowClear
                                                        loading={dropdownLoading}
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {ecom_product_sub_brand?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                            </Row>
                                            <Row gutter={10} style={{ marginTop: 10, marginBottom: 10 }}>
                                                <Col md={8}>
                                                    <label className="label-block-export">Texture</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        value={ecomFilters.product_texture}
                                                        onChange={(val) => onChangeEcom(val, 'product_texture')}
                                                        // onBlur={() => dispatch(getEcomDropdownFilters(ecomFilters, "product_texture"))}
                                                        allowClear
                                                        loading={dropdownLoading}
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {ecom_product_texture?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col md={8}>
                                                    <label className="label-block-export">Pack Size</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        value={ecomFilters.product_package_size}
                                                        onChange={(val) => onChangeEcom(val, 'product_package_size')}
                                                        // onBlur={() => dispatch(getEcomDropdownFilters(ecomFilters, "product_package_size"))}
                                                        allowClear
                                                        loading={dropdownLoading}
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {ecom_product_package_size?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col md={8}>
                                                    <label className="label-block-export">Select UPC</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={true}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        value={ecomFilters.product_upc}
                                                        onChange={(val) => onChangeEcom(val, 'product_upc')}
                                                        // onBlur={() => dispatch(getEcomDropdownFilters(ecomFilters, "product_upc"))}
                                                        allowClear
                                                        loading={dropdownLoading}
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {ecom_product_upc?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                            </Row>
                                            {ecomKpis.includes('Share of Shelf') && <Row gutter={10} style={{ marginTop: 10, marginBottom: 10 }}>
                                                <Col md={8}>
                                                    <label className="label-block-export">Keyword Category</label>
                                                    <TreeSelect
                                                        dropdownMatchSelectWidth={false}
                                                        mode="multiple"
                                                        value={ecomFilters.keyword_category}
                                                        onChange={(val) => onChangeEcom(val, 'keyword_category')}
                                                        // onBlur={() => dispatch(getEcomDropdownFilters(ecomFilters, "keyword_category"))}
                                                        allowClear
                                                        loading={dropdownLoading}
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}
                                                        treeData={dummyKeywordCategory}
                                                    >
                                                    </TreeSelect>
                                                </Col>
                                                <Col md={8}>
                                                    <label className="label-block-export">Keyword Type</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        value={ecomFilters.keyword_type}
                                                        onChange={(val) => onChangeEcom(val, 'keyword_type')}
                                                        // onBlur={() => dispatch(getEcomDropdownFilters(ecomFilters, "keyword_type"))}
                                                        allowClear
                                                        loading={dropdownLoading}
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {dummyKeywordType?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col md={8}>
                                                    <label className="label-block-export">Keywords</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        value={ecomFilters.keywords}
                                                        onChange={(val) => onChangeEcom(val, 'keywords', false)}
                                                        // onBlur={() => dispatch(getEcomDropdownFilters(ecomFilters, "keywords"))}
                                                        allowClear
                                                        loading={dropdownLoading}
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {dummyKeywords?.map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                            </Row>}
                                        </Col>
                                        <Col md={2}>

                                        </Col>
                                        <Col md={10}>
                                            <h5>Customer</h5>
                                            <Row gutter={10} style={{ marginTop: 10 }}>
                                                {/* <Col md={12}>
                                                    <label className="label-block-export">Channel</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        value={filters.customer_channel}
                                                        onChange={(val) => onChannelSelection(['customer_channel', 'customer_retailer_details'], val)}
                                                        allowClear
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {Object.keys(data_filters).length > 0 && Object.keys(data_filters.customer_retailer_details).length > 0 && Object.keys(data_filters.customer_retailer_details).map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col md={12}>
                                                    <label className="label-block-export">Retailer</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        value={filters.customer_retailer_details}
                                                        onChange={(val) => onChannelSelection(['customer_retailer_details'], val)}
                                                        // onBlur={() => dispatch(getDropdownFilters(filters))}
                                                        allowClear
                                                        maxTagCount="responsive"
                                                        style={{ width: '100%' }}>
                                                        {Object.keys(data_filters).length > 0 && Object.keys(data_filters.customer_retailer_details).length > 0 && filters.customer_channel && data_filters.customer_retailer_details[filters.customer_channel].length > 0 && data_filters.customer_retailer_details[filters.customer_channel].map((val) => (
                                                            <Option value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col> */}
                                                <Col md={12}>
                                                    <label className="label-block-export">Retailer</label>
                                                    <Select
                                                        dropdownMatchSelectWidth={false}
                                                        value={ecomFilters.customer_retailer_details}
                                                        onChange={(val) => onSelection('customer_retailer_details', val, checkEcomCategory())}
                                                        // onBlur={() => dispatch(getEcomDropdownFilters(ecomFilters))}
                                                        placeholder="All"
                                                        mode="multiple"
                                                        maxTagCount="responsive"
                                                        placement="bottomLeft"
                                                        allowClear
                                                        style={{ width: '100%' }} >
                                                        {ecom_customer_retailer_details?.map((val, i) => (
                                                            <Option key={i} value={val}>{val}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                            </Row>
                                            <h5 style={{ marginTop: 10 }}>Length of Time</h5>
                                            <Row style={{ marginTop: 10, justifyContent: "left" }}>
                                                <Checkbox value={"Weekly"} checked={ecomTimePeriodCheckbox.includes("Weekly")} onChange={ecomTimePeriodSelection()}>Weekly</Checkbox>
                                                <Checkbox value={"4W"} checked={ecomTimePeriodCheckbox.includes("4W")} onChange={ecomTimePeriodSelection()}>4W Period</Checkbox>
                                                <Checkbox value={"13W"} checked={ecomTimePeriodCheckbox.includes("13W")} onChange={ecomTimePeriodSelection()}>13W Period</Checkbox>
                                                <Checkbox value={"26W"} checked={ecomTimePeriodCheckbox.includes("26W")} onChange={ecomTimePeriodSelection()}>26W Period</Checkbox>
                                            </Row>
                                            <Row style={{ marginTop: 10, justifyContent: "left" }}>
                                                <Checkbox value={"52W"} checked={ecomTimePeriodCheckbox.includes("52W")} onChange={ecomTimePeriodSelection()}>52W MAT</Checkbox>
                                                <Checkbox value={"Include YA"} checked={ecomTimePeriodCheckbox.includes("Include YA")} onChange={ecomTimePeriodSelection()}>Include YA</Checkbox>
                                            </Row>
                                            <Row gutter={10} style={{ marginTop: 10 }}>
                                                <Col md={5}>
                                                    <label className="label-block-export">Week Selection</label>
                                                    <TreeSelect
                                                        dropdownMatchSelectWidth={false}
                                                        showSearch={true}
                                                        placeholder={"All"}
                                                        multiple
                                                        treeCheckable={true}
                                                        defaultValue={ecomtimePeriod[0]}
                                                        maxTagCount={'responsive'}
                                                        value={ecomFilters.period_year}
                                                        onChange={(value) => onSelection('period_year', value, checkEcomCategory())}
                                                        style={{ width: '100%', minWidth: 300 }}
                                                        dropdownStyle={{ minWidth: "20%" }}
                                                        treeData={ecomtimePeriod}
                                                        allowClear
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                        <Row gutter={10} style={{ paddingLeft: "5px" }}>
                                            <Col md={12}>
                                                <Col md={24}>
                                                    <Row style={{ marginTop: 10 }}>
                                                        <h5 style={{ marginTop: 10 }}>Group By</h5>
                                                        <TreeSelect
                                                            dropdownMatchSelectWidth={false}
                                                            showSearch={true}
                                                            placeholder={"All"}
                                                            multiple
                                                            treeCheckable={true}
                                                            onChange={(value) => groupBySelection(value, checkEcomCategory())}
                                                            style={{ width: '100%', minWidth: 300 }}
                                                            dropdownStyle={{ minWidth: "10%" }}
                                                            value={checkEcomCategory() ? groupByEcom : groupBy}
                                                            treeData={checkEcomCategory() ? ecomGroupDropdown : groupDropdown}
                                                            allowClear
                                                        />
                                                    </Row>
                                                </Col>
                                            </Col>
                                            <Col md={2}>

                                            </Col>
                                            <Col md={10}>
                                                <Row gutter={10} style={{ marginTop: 10 }} >
                                                    <Col md={8}>
                                                        <h5 style={{ marginTop: 10 }}>KPI</h5>
                                                        <Select mode="multiple" dropdownMatchSelectWidth={false} placeholder="Select" value={ecomKpis}
                                                            onChange={handleKpiChange}
                                                            allowClear maxTagCount="responsive" style={{ width: '100%' }}>
                                                            {ecomKpiDropdown()?.map((option) => (
                                                                //<Option value={option}>{option} {ecom_data_aggregation.kpi[option] == "AVG" ? <>AVG</> : <>SUM</>}</Option>
                                                                <Option value={option}>{option}</Option>
                                                            ))}
                                                        </Select>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: 10 }}>
                                                    {applyError && <p style={{ "color": "red" }}>Please fill all required fields</p>}
                                                </Row>
                                                <Row gutter={10} style={{ marginTop: 10 }}>
                                                    <Col md={12}>
                                                        {/* <Button type="text" icon={<PlusCircleOutlined />} onClick={() => dispatch(setFilterCount([...filterCount, Math.max(...filterCount) + 1]))}>Add KPI</Button> */}
                                                    </Col>
                                                    <Col md={4} style={{ display: "flex", justifyContent: "end" }}>
                                                        {upcExportAccess == 1 && clusterLoading === 0 && <Button type="primary" loading={tableLoading > 0} onClick={() => {
                                                            if (clusterNotification) {
                                                                if (kpis.length !== 0) {
                                                                    if (exportCategory === "Ecom UPC") {
                                                                        dispatch(getEcomDataPreview(ecomFilters, groupByEcom, ecomKpis, periods, aggregates, ecomTimePeriodCheckbox))
                                                                    }
                                                                    else {
                                                                        dispatch(getEcomDataPreview(ecomFilters, groupByEcom, ecomKpis, periods, aggregates, ecomTimePeriodCheckbox))
                                                                    }
                                                                    setApplyError(false)
                                                                }
                                                                else {
                                                                    setApplyError(true)
                                                                }
                                                            }
                                                            else {
                                                                clusterInterval = setInterval(checkClusterInitiation, 5000)
                                                            }
                                                        }}>Preview</Button>}
                                                    </Col>
                                                    <Col md={8}>
                                                        {upcExportAccess == 1 && (<Button type="primary" onClick={() => {
                                                            if (clusterNotification) {
                                                                if (kpis.length !== 0) {
                                                                    appInsights.trackEvent({ name: 'DATA_EXPORT' })
                                                                    dispatch(initiateEcomTask(ecomFilters, groupByEcom, ecomKpis, ecomTimePeriodCheckbox))
                                                                    setApplyError(false)
                                                                }
                                                                else {
                                                                    setApplyError(true)
                                                                }
                                                            }
                                                            else {
                                                                clusterInterval = setInterval(checkClusterInitiation, 5000)
                                                            }
                                                        }} disabled={dropdownLoading > 0} style={{ "width": "150px" }} loading={(!zipEnable && !task_completion) || dataSourcesLoading > 0 || clusterLoading > 0}>
                                                            {clusterLoading > 0 ? "Export Loading" : "Export File"}
                                                        </Button>)}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </>}
                                    {(exportCategory == "General" || upcExportAccess === 1) && (<Loader loading={tableLoading > 0} tip="Loading..">
                                        <Row style={{ padding: "20px" }}>
                                            <h5>Data Preview</h5>
                                            <Table
                                                rowKey="index"
                                                dataSource={exportCategory === "Ecom UPC" ? ecomDataPreview : dataPreview}
                                                className="custom-table"
                                                columns={exportCategory === "Ecom UPC" ? ecomDataPreviewColumns : dataPreviewColumns}
                                                scroll={{ x: dataPreviewColumns.length * 250, y: 400 }}
                                            />
                                        </Row>
                                    </Loader>)}

                                    <Row style={{ marginTop: 10 }}>
                                        <Col span={24}>
                                            <div className="d-right">
                                                <div style={{ textAlign: 'right', marginBottom: 10 }}>

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Loader>
                        </Col>
                    </Row>

                </Loader>
            </Content>
        </Wrapper >
    );
}

export default withRouter(Export);
