import React from "react";
import { CaretUpFilled, CaretDownFilled } from '@ant-design/icons';
import { formatNumberExcel, numFmt } from "../../utilities/exports/excel";
import { formatNumber, valueTypes } from '../../utilities/helper';

// render for UI
const renderFunction = (val, rec) => {
  let isZero = true;
  let dec = 1;

  if (val) {
    if (
      formatNumber(val.abs, 1, true) === valueTypes.NA &&
      formatNumber(val.abs, 2, true) !== valueTypes.NA
    ) {
      isZero = false;
      dec = 2;
    }
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (isZero) return "NA";
  else
    return (
      <>
        <div>{formatNumber(val.abs, dec)}</div>
        {Number(val.variance) >= 0 ? (
          <div>
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance, dec)}
          </div>
        ) : (
          <div>
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance, dec)}
          </div>
        )}
      </>
    );
};

const renderNumberPercentFunction = (val, rec) => {
  let isZero = true;
  let dec = 1;

  if (val) {
    if (
      formatNumber(val.abs, 1, true) === valueTypes.NA &&
      formatNumber(val.abs, 2, true) !== valueTypes.NA
    ) {
      isZero = false;
      dec = 2;
    }
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (isZero) return "NA";
  else
    return (
      <>
        <div>{formatNumber(val.abs, dec)}</div>
        {Number(val.variance) >= 0 ? (
          <div>
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance, dec)}%
          </div>
        ) : (
          <div>
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance, dec)}%
          </div>
        )}
      </>
    );
};

const renderPercentPointsFunction = (val, rec) => {
  let isZero = true;
  let dec = 1;

  if (val) {
    if (
      formatNumber(val.abs, 1, true) === valueTypes.NA &&
      formatNumber(val.abs, 2, true) !== valueTypes.NA
    ) {
      isZero = false;
      dec = 2;
    }
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (isZero) return "NA";
  else
    return (
      <>
        <div>{formatNumber(val.abs, dec)}%</div>
        {Number(val.variance) >= 0 ? (
          <div>
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance, dec)}bp
          </div>
        ) : (
          <div>
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance, dec)}bp
          </div>
        )}
      </>
    );
};

const renderDollarFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (isZero) return "NA";
  else
    return (
      <>
        <div>$ {formatNumber(val.abs)}</div>
        {Number(val.variance) >= 0 ? (
          <div>
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}
          </div>
        ) : (
          <div>
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}
          </div>
        )}
      </>
    );
};

const renderDollarPercentFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (isZero) return "NA";
  else
    return (
      <>
        <div>$ {formatNumber(val.abs)}</div>
        {Number(val.variance) >= 0 ? (
          <div>
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}%
          </div>
        ) : (
          <div>
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}%
          </div>
        )}
      </>
    );
};

const renderPercentageFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (isZero) return "NA";
  else
    return (
      <>
        <div>{formatNumber(val.abs)}%</div>
        {Number(val.variance) >= 0 ? (
          <div>
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}
          </div>
        ) : (
          <div>
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}
          </div>
        )}
      </>
    );
};

const renderPercentagePointsFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (isZero) return "NA";
  else
    return (
      <>
        <div>{formatNumber(val.abs)}%</div>
        {Number(val.variance) >= 0 ? (
          <div>
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}bp
          </div>
        ) : (
          <div>
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}bp
          </div>
        )}
      </>
    );
};

// renderPPT
const renderPPTDollarFunction = (val, rec) =>
  `$ ${formatNumber(val.abs)}\n${formatNumber(val.variance)}`;

// renderText for excel export
const renderTextDollarFunction = (val, rec) => ({
  value: formatNumberExcel(val.abs),
  numFmt: numFmt.dollar,
});

const renderTextVariancePercentageFunction = (val, rec) => ({
  value: formatNumberExcel(val.variance, true),
  numFmt: numFmt.percentage,
});

const renderTextAbsPercentageFunction = (val, rec) => ({
  value: formatNumberExcel(val.abs, true),
  numFmt: numFmt.percentage,
});

const renderTextVarianceNumberFunction = (val, rec) => ({
  value: formatNumberExcel(val.variance),
  numFmt: numFmt.number,
});

const renderTextAbsNumber = (val, rec) => ({
  value: formatNumberExcel(val.abs),
  numFmt: numFmt.number,
});

// Excel Exports
const psColsExport = [
  {
    title: "Customer",
    dataIndex: "retailer_key",
    key: "retailer_key",
    width: "10%",
  },
  {
    title: "CSL",
    dataIndex: "sum_fact_percent_csl_worst_case_4w",
    key: "sum_fact_percent_csl_worst_case_4w",
    renderText: (val, rec) => ({
      value: formatNumberExcel(val, true),
      numFmt: numFmt.percentage,
    }),
  },
  {
    title: "MKT RSV L4W",
    dataIndex: "dollar_latest_4w",
    key: "dollar_latest_4w",
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV L13W",
    dataIndex: "dollar_latest_13w",
    key: "dollar_latest_13w",
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV L52W",
    dataIndex: "dollar_latest_52w",
    key: "dollar_latest_52w",
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV YTD",
    dataIndex: "dollar_latest_ytd",
    key: "dollar_latest_ytd",
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV CYA L4W",
    dataIndex: "dollar_latest_4w",
    key: "dollar_latest_4w",
    renderText: renderTextVariancePercentageFunction,
  },
  {
    title: "MKT RSV CYA L13W",
    dataIndex: "dollar_latest_13w",
    key: "dollar_latest_13w",
    renderText: renderTextVariancePercentageFunction,
  },
  {
    title: "MKT RSV CYA L52W",
    dataIndex: "dollar_latest_52w",
    key: "dollar_latest_52w",
    renderText: renderTextVariancePercentageFunction,
  },
  {
    title: "MKT RSV CYA YTD",
    dataIndex: "dollar_latest_ytd",
    key: "dollar_latest_ytd",
    renderText: renderTextVariancePercentageFunction,
  },
  {
    title: "$ Share L4W",
    dataIndex: "dollar_share_latest_4w",
    key: "dollar_share_latest_4w",
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "$ Share L13W",
    dataIndex: "dollar_share_latest_13w",
    key: "dollar_share_latest_13w",
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "$ Share L52W",
    dataIndex: "dollar_share_latest_52w",
    key: "dollar_share_latest_52w",
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "$ Share YTD",
    dataIndex: "dollar_share_latest_ytd",
    key: "dollar_share_latest_ytd",
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "$ Share CYA L4W",
    dataIndex: "dollar_share_latest_4w",
    key: "dollar_share_latest_4w",
    renderText: renderTextVarianceNumberFunction,
  },
  {
    title: "$ Share CYA L13W",
    dataIndex: "dollar_share_latest_13w",
    key: "dollar_share_latest_13w",
    renderText: renderTextVarianceNumberFunction,
  },
  {
    title: "$ Share CYA L52W",
    dataIndex: "dollar_share_latest_52w",
    key: "dollar_share_latest_52w",
    renderText: renderTextVarianceNumberFunction,
  },
  {
    title: "$ Share CYA YTD",
    dataIndex: "dollar_share_latest_ytd",
    key: "dollar_share_latest_ytd",
    renderText: renderTextVarianceNumberFunction,
  },
  {
    title: "TDP L4W",
    dataIndex: "tdp_latest_4w",
    key: "tdp_latest_4w",
    renderText: renderTextAbsNumber,
  },
  {
    title: "TDP L13W",
    dataIndex: "tdp_latest_13w",
    key: "tdp_latest_13w",
    renderText: renderTextAbsNumber,
  },
  {
    title: "TDP L52W",
    dataIndex: "tdp_latest_52w",
    key: "tdp_latest_52w",
    renderText: renderTextAbsNumber,
  },
  {
    title: "TDP YTD",
    dataIndex: "tdp_latest_ytd",
    key: "tdp_latest_ytd",
    renderText: renderTextAbsNumber,
  },
  {
    title: "TDP CYA L4W",
    dataIndex: "tdp_latest_4w",
    key: "tdp_latest_4w",
    renderText: renderTextVariancePercentageFunction,
  },
  {
    title: "TDP CYA L13W",
    dataIndex: "tdp_latest_13w",
    key: "tdp_latest_13w",
    renderText: renderTextVariancePercentageFunction,
  },
  {
    title: "TDP CYA L52W",
    dataIndex: "tdp_latest_52w",
    key: "tdp_latest_52w",
    renderText: renderTextVariancePercentageFunction,
  },
  {
    title: "TDP CYA YTD",
    dataIndex: "tdp_latest_ytd",
    key: "tdp_latest_ytd",
    renderText: renderTextVariancePercentageFunction,
  },
  {
    title: "$/TDP L4W",
    dataIndex: "acv_latest_4w",
    key: "acv_latest_4w",
    renderText: renderTextDollarFunction,
  },
  {
    title: "$/TDP L13W",
    dataIndex: "acv_latest_13w",
    key: "acv_latest_13w",
    renderText: renderTextDollarFunction,
  },
  {
    title: "$/TDP L52W",
    dataIndex: "acv_latest_52w",
    key: "acv_latest_52w",
    renderText: renderTextDollarFunction,
  },
  {
    title: "$/TDP YTD",
    dataIndex: "acv_latest_ytd",
    key: "acv_latest_ytd",
    renderText: renderTextDollarFunction,
  },
  {
    title: "$/TDP CYA L4W",
    dataIndex: "acv_latest_4w",
    key: "acv_latest_4w",
    renderText: renderTextVariancePercentageFunction,
  },
  {
    title: "$/TDP CYA L13W",
    dataIndex: "acv_latest_13w",
    key: "acv_latest_13w",
    renderText: renderTextVariancePercentageFunction,
  },
  {
    title: "$/TDP CYA L52W",
    dataIndex: "acv_latest_52w",
    key: "acv_latest_52w",
    renderText: renderTextVariancePercentageFunction,
  },
  {
    title: "$/TDP CYA YTD",
    dataIndex: "acv_latest_ytd",
    key: "acv_latest_ytd",
    renderText: renderTextVariancePercentageFunction,
  },
];

const psColsFinancialsExport = [
  {
    title: "Customer",
    dataIndex: "retailer_key",
    key: "retailer_key",
    width: "10%",
  },
  {
    title: "MKT RSV L4W",
    dataIndex: "dollar_latest_4w",
    key: "dollar_latest_4w",
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV L13W",
    dataIndex: "dollar_latest_13w",
    key: "dollar_latest_13w",
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV L52W",
    dataIndex: "dollar_latest_52w",
    key: "dollar_latest_52w",
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV YTD",
    dataIndex: "dollar_latest_ytd",
    key: "dollar_latest_ytd",
    renderText: renderTextDollarFunction,
  },
  {
    title: "MKT RSV CYA L4W",
    dataIndex: "dollar_latest_4w",
    key: "dollar_latest_4w",
    renderText: renderTextVariancePercentageFunction,
  },
  {
    title: "MKT RSV CYA L13W",
    dataIndex: "dollar_latest_13w",
    key: "dollar_latest_13w",
    renderText: renderTextVariancePercentageFunction,
  },
  {
    title: "MKT RSV CYA L52W",
    dataIndex: "dollar_latest_52w",
    key: "dollar_latest_52w",
    renderText: renderTextVariancePercentageFunction,
  },
  {
    title: "MKT RSV CYA YTD",
    dataIndex: "dollar_latest_ytd",
    key: "dollar_latest_ytd",
    renderText: renderTextVariancePercentageFunction,
  },
  {
    title: "GSV L4W",
    dataIndex: "gsv_latest_4w",
    key: "gsv_latest_4w",
    renderText: renderTextDollarFunction,
  },
  {
    title: "GSV L13W",
    dataIndex: "gsv_latest_13w",
    key: "gsv_latest_13w",
    renderText: renderTextDollarFunction,
  },
  {
    title: "GSV L52W",
    dataIndex: "gsv_latest_52w",
    key: "gsv_latest_52w",
    renderText: renderTextDollarFunction,
  },
  {
    title: "GSV YTD",
    dataIndex: "gsv_latest_ytd",
    key: "gsv_latest_ytd",
    renderText: renderTextDollarFunction,
  },
  {
    title: "GSV CYA L4W",
    dataIndex: "gsv_latest_4w",
    key: "gsv_latest_4w",
    renderText: renderTextVarianceNumberFunction,
  },
  {
    title: "GSV CYA L13W",
    dataIndex: "gsv_latest_13w",
    key: "gsv_latest_13w",
    renderText: renderTextVarianceNumberFunction,
  },
  {
    title: "GSV CYA L52W",
    dataIndex: "gsv_latest_52w",
    key: "gsv_latest_52w",
    renderText: renderTextVarianceNumberFunction,
  },
  {
    title: "GSV CYA YTD",
    dataIndex: "gsv_latest_ytd",
    key: "gsv_latest_ytd",
    renderText: renderTextVarianceNumberFunction,
  },
  {
    title: "NSV L4W",
    dataIndex: "nsv_latest_4w",
    key: "nsv_latest_4w",
    renderText: renderTextDollarFunction,
  },
  {
    title: "NSV L13W",
    dataIndex: "nsv_latest_13w",
    key: "nsv_latest_13w",
    renderText: renderTextDollarFunction,
  },
  {
    title: "NSV L52W",
    dataIndex: "nsv_latest_52w",
    key: "nsv_latest_52w",
    renderText: renderTextDollarFunction,
  },
  {
    title: "NSV YTD",
    dataIndex: "nsv_latest_ytd",
    key: "nsv_latest_ytd",
    renderText: renderTextDollarFunction,
  },
  {
    title: "NSV CYA L4W",
    dataIndex: "nsv_latest_4w",
    key: "nsv_latest_4w",
    renderText: renderTextVarianceNumberFunction,
  },
  {
    title: "NSV CYA L13W",
    dataIndex: "nsv_latest_13w",
    key: "nsv_latest_13w",
    renderText: renderTextVarianceNumberFunction,
  },
  {
    title: "NSV CYA L52W",
    dataIndex: "nsv_latest_52w",
    key: "nsv_latest_52w",
    renderText: renderTextVarianceNumberFunction,
  },
  {
    title: "NSV CYA YTD",
    dataIndex: "nsv_latest_ytd",
    key: "nsv_latest_ytd",
    renderText: renderTextVarianceNumberFunction,
  },
  {
    title: "MAC% L4W",
    dataIndex: "mac_latest_4w",
    key: "mac_latest_4w",
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "MAC% L13W",
    dataIndex: "mac_latest_13w",
    key: "mac_latest_13w",
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "MAC% L52W",
    dataIndex: "mac_latest_52w",
    key: "mac_latest_52w",
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "MAC% YTD",
    dataIndex: "mac_latest_ytd",
    key: "mac_latest_ytd",
    renderText: renderTextAbsPercentageFunction,
  },
  {
    title: "MAC% CYA L4W",
    dataIndex: "mac_latest_4w",
    key: "mac_latest_4w",
    renderText: renderTextVarianceNumberFunction,
  },
  {
    title: "MAC% CYA L13W",
    dataIndex: "mac_latest_13w",
    key: "mac_latest_13w",
    renderText: renderTextVarianceNumberFunction,
  },
  {
    title: "MAC% CYA L52W",
    dataIndex: "mac_latest_52w",
    key: "mac_latest_52w",
    renderText: renderTextVarianceNumberFunction,
  },
  {
    title: "MAC% CYA YTD",
    dataIndex: "mac_latest_ytd",
    key: "mac_latest_ytd",
    renderText: renderTextVarianceNumberFunction,
  },
];

// PPT Export
const psColsRsv = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "retailer_key",
        key: "retailer_key",
        // width: '10%'
      },
    ],
  },
  {
    title: "CSL",
    children: [
      {
        title: "",
        dataIndex: "sum_fact_percent_csl",
        key: "sum_fact_percent_csl",
        render: (val, rec) => <div>{formatNumber(val, 1)}</div>,
        renderPPT: (val, rec) => formatNumber(val, 1),
        width: "6%",
      },
    ],
  },
  {
    title: "MKT RSV",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_latest_4w",
        key: "dollar_latest_4w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "L13W",
        dataIndex: "dollar_latest_13w",
        key: "dollar_latest_13w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "dollar_latest_52w",
        key: "dollar_latest_52w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "dollar_latest_ytd",
        key: "dollar_latest_ytd",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
    ],
  },
  {
    title: "MKT RSV Growth",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_latest_4w",
        key: "dollar_latest_4w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => ` ${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "L13W",
        dataIndex: "dollar_latest_13w",
        key: "dollar_latest_13w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => ` ${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "dollar_latest_52w",
        key: "dollar_latest_52w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => ` ${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "dollar_latest_ytd",
        key: "dollar_latest_ytd",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
    ],
  },
];

const psColsShare = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "retailer_key",
        key: "retailer_key",
        // width: '10%'
      },
    ],
  },
  {
    title: "$ Share",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_share_latest_4w",
        key: "dollar_share_latest_4w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "L13W",
        dataIndex: "dollar_share_latest_13w",
        key: "dollar_share_latest_13w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "dollar_share_latest_52w",
        key: "dollar_share_latest_52w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "dollar_share_latest_ytd",
        key: "dollar_share_latest_ytd",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.abs)}`,
        width: "6%",
      },
    ],
  },
  {
    title: "$ Share Growth",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_share_latest_4w",
        key: "dollar_share_latest_4w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => ` ${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "L13W",
        dataIndex: "dollar_share_latest_13w",
        key: "dollar_share_latest_13w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => ` ${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "dollar_share_latest_52w",
        key: "dollar_share_latest_52w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => ` ${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "dollar_share_latest_ytd",
        key: "dollar_share_latest_ytd",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => ` ${formatNumber(val.variance)}`,
        width: "6%",
      },
    ],
  },
];

const psColsTdp = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "retailer_key",
        key: "retailer_key",
        // width: '10%'
      },
    ],
  },
  {
    title: "TDP",
    children: [
      {
        title: "L4W",
        dataIndex: "tdp_latest_4w",
        key: "tdp_latest_4w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "L13W",
        dataIndex: "tdp_latest_13w",
        key: "tdp_latest_13w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "tdp_latest_52w",
        key: "tdp_latest_52w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "tdp_latest_ytd",
        key: "tdp_latest_ytd",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.abs)}`,
        width: "6%",
      },
    ],
  },
  {
    title: "TDP Growth",
    children: [
      {
        title: "L4W",
        dataIndex: "tdp_latest_4w",
        key: "tdp_latest_4w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => ` ${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "L13W",
        dataIndex: "tdp_latest_13w",
        key: "tdp_latest_13w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "tdp_latest_52w",
        key: "tdp_latest_52w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "tdp_latest_ytd",
        key: "tdp_latest_ytd",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
    ],
  },
];

const psCols$Tdp = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "retailer_key",
        key: "retailer_key",
        // width: '10%'
      },
    ],
  },
  {
    title: "$/TDP",
    children: [
      {
        title: "L4W",
        dataIndex: "acv_latest_4w",
        key: "acv_latest_4w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "L13W",
        dataIndex: "acv_latest_13w",
        key: "acv_latest_13w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "acv_latest_52w",
        key: "acv_latest_52w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "acv_latest_ytd",
        key: "acv_latest_ytd",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
    ],
  },
  {
    title: "$/TDP Growth",
    children: [
      {
        title: "L4W",
        dataIndex: "acv_latest_4w",
        key: "acv_latest_4w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "L13W",
        dataIndex: "acv_latest_13w",
        key: "acv_latest_13w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "acv_latest_52w",
        key: "acv_latest_52w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "acv_latest_ytd",
        key: "acv_latest_ytd",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
    ],
  },
];

const psColsFinancialRsv = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "retailer_key",
        key: "retailer_key",
        // width: '10%'
      },
    ],
  },
  {
    title: "MKT RSV",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_latest_4w",
        key: "dollar_latest_4w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "L13W",
        dataIndex: "dollar_latest_13w",
        key: "dollar_latest_13w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "dollar_latest_52w",
        key: "dollar_latest_52w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "dollar_latest_ytd",
        key: "dollar_latest_ytd",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
    ],
  },
  {
    title: "MKT RSV Growth",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_latest_4w",
        key: "dollar_latest_4w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "L13W",
        dataIndex: "dollar_latest_13w",
        key: "dollar_latest_13w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "dollar_latest_52w",
        key: "dollar_latest_52w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "dollar_latest_ytd",
        key: "dollar_latest_ytd",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
    ],
  },
];

const psColsFinancialGsv = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "retailer_key",
        key: "retailer_key",
        // width: '10%'
      },
    ],
  },
  {
    title: "GSV",
    children: [
      {
        title: "L4W",
        dataIndex: "gsv_latest_4w",
        key: "gsv_latest_4w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "L13W",
        dataIndex: "gsv_latest_13w",
        key: "gsv_latest_13w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "gsv_latest_52w",
        key: "gsv_latest_52w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "gsv_latest_ytd",
        key: "gsv_latest_ytd",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
    ],
  },
  {
    title: "GSV Growth",
    children: [
      {
        title: "L4W",
        dataIndex: "gsv_latest_4w",
        key: "gsv_latest_4w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "L13W",
        dataIndex: "gsv_latest_13w",
        key: "gsv_latest_13w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "gsv_latest_52w",
        key: "gsv_latest_52w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "gsv_latest_ytd",
        key: "gsv_latest_ytd",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
    ],
  },
];

const psColsFinancialNSV = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "retailer_key",
        key: "retailer_key",
        // width: '10%'
      },
    ],
  },
  {
    title: "NSV",
    children: [
      {
        title: "L4W",
        dataIndex: "nsv_latest_4w",
        key: "nsv_latest_4w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "L13W",
        dataIndex: "nsv_latest_13w",
        key: "nsv_latest_13w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "nsv_latest_52w",
        key: "nsv_latest_52w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "nsv_latest_ytd",
        key: "nsv_latest_ytd",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `$ ${formatNumber(val.abs)}`,
        width: "6%",
      },
    ],
  },
  {
    title: "NSV Growth",
    children: [
      {
        title: "L4W",
        dataIndex: "nsv_latest_4w",
        key: "nsv_latest_4w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "L13W",
        dataIndex: "nsv_latest_13w",
        key: "nsv_latest_13w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "nsv_latest_52w",
        key: "nsv_latest_52w",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "nsv_latest_ytd",
        key: "nsv_latest_ytd",
        render: (val, rec) => (
          <>
            <div>$ {formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
    ],
  },
];

const psColsFinancialMAC = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "retailer_key",
        key: "retailer_key",
        // width: '10%'
      },
    ],
  },
  {
    title: "MAC%",
    children: [
      {
        title: "L4W",
        dataIndex: "mac_latest_4w",
        key: "mac_latest_4w",
        render: (val, rec) => (
          <>
            <div>{`${formatNumber(val.abs)}%`}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.abs)}%`,
        width: "6%",
      },
      {
        title: "L13W",
        dataIndex: "mac_latest_13w",
        key: "mac_latest_13w",
        render: (val, rec) => (
          <>
            <div>{`${formatNumber(val.abs)}%`}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.abs)}%`,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "mac_latest_52w",
        key: "mac_latest_52w",
        render: (val, rec) => (
          <>
            <div>{`${formatNumber(val.abs)}%`}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.abs)}%`,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "mac_latest_ytd",
        key: "mac_latest_ytd",
        render: (val, rec) => (
          <>
            <div>{`${formatNumber(val.abs)}%`}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.abs)}%`,
        width: "6%",
      },
    ],
  },
  {
    title: "MAC% Growth",
    children: [
      {
        title: "L4W",
        dataIndex: "mac_latest_4w",
        key: "mac_latest_4w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "L13W",
        dataIndex: "mac_latest_13w",
        key: "mac_latest_13w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "mac_latest_52w",
        key: "mac_latest_52w",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "mac_latest_ytd",
        key: "mac_latest_ytd",
        render: (val, rec) => (
          <>
            <div>{formatNumber(val.abs)}</div>
            {Number(val.variance) >= 0 ? (
              <div>
                <CaretUpFilled style={{ color: "#00c853" }} />
                {formatNumber(val.variance)}
              </div>
            ) : (
              <div>
                <CaretDownFilled style={{ color: "#ec3225" }} />
                {formatNumber(-1 * val.variance)}
              </div>
            )}
          </>
        ),
        renderPPT: (val, rec) => `${formatNumber(val.variance)}`,
        width: "6%",
      },
    ],
  },
];

// UI
const psCols = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "retailer_key",
        key: "retailer_key",
        // width: '10%'
      },
    ],
  },
  {
    title: "CSL",
    children: [
      {
        title: "",
        dataIndex: "sum_fact_percent_csl_worst_case_4w",
        key: "sum_fact_percent_csl_worst_case_4w",
        render: (val, rec) => (
          <div>
            {formatNumber(val, 1, true) === valueTypes.NA
              ? valueTypes.NA
              : formatNumber(val, 1)}%
          </div>
        ),
        renderPPT: (val, rec) => formatNumber(val, 1),
        width: "4%",
        className: "td-border-left",
      },
    ],
  },
  {
    title: "MKT RSV",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_latest_4w",
        key: "dollar_latest_4w",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "6%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "dollar_latest_13w",
        key: "dollar_latest_13w",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "6%",
      },
      {
        title: "L52W",
        dataIndex: "dollar_latest_52w",
        key: "dollar_latest_52w",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "6%",
      },
      {
        title: "YTD",
        dataIndex: "dollar_latest_ytd",
        key: "dollar_latest_ytd",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "6%",
      },
    ],
  },
  {
    title: "$ Share",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_share_latest_4w",
        key: "dollar_share_latest_4w",
        render: renderPercentPointsFunction,
        renderPPT: renderPPTDollarFunction,
        width: "4.5%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "dollar_share_latest_13w",
        key: "dollar_share_latest_13w",
        render: renderPercentPointsFunction,
        renderPPT: renderPPTDollarFunction,
        width: "4.5%",
      },
      {
        title: "L52W",
        dataIndex: "dollar_share_latest_52w",
        key: "dollar_share_latest_52w",
        render: renderPercentPointsFunction,
        renderPPT: renderPPTDollarFunction,
        width: "4.5%",
      },
      {
        title: "YTD",
        dataIndex: "dollar_share_latest_ytd",
        key: "dollar_share_latest_ytd",
        render: renderPercentPointsFunction,
        renderPPT: renderPPTDollarFunction,
        width: "4.5%",
      },
    ],
  },
  {
    title: "TDP",
    children: [
      {
        title: "L4W",
        dataIndex: "tdp_latest_4w",
        key: "tdp_latest_4w",
        render: renderNumberPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "4.5%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "tdp_latest_13w",
        key: "tdp_latest_13w",
        render: renderNumberPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "4.5%",
      },
      {
        title: "L52W",
        dataIndex: "tdp_latest_52w",
        key: "tdp_latest_52w",
        render: renderNumberPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "4.5%",
      },
      {
        title: "YTD",
        dataIndex: "tdp_latest_ytd",
        key: "tdp_latest_ytd",
        render: renderNumberPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "4.5%",
      },
    ],
  },
  {
    title: "$/TDP",
    children: [
      {
        title: "L4W",
        dataIndex: "acv_latest_4w",
        key: "acv_latest_4w",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "4.5%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "acv_latest_13w",
        key: "acv_latest_13w",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "4.5%",
      },
      {
        title: "L52W",
        dataIndex: "acv_latest_52w",
        key: "acv_latest_52w",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "4.5%",
      },
      {
        title: "YTD",
        dataIndex: "acv_latest_ytd",
        key: "acv_latest_ytd",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "4.5%",
      },
    ],
  },
];

const psColsFinancials = [
  {
    title: "Customer",
    children: [
      {
        title: "",
        dataIndex: "retailer_key",
        key: "retailer_key",
        // width: '10%'
      },
    ],
  },
  {
    title: "MKT RSV",
    children: [
      {
        title: "L4W",
        dataIndex: "dollar_latest_4w",
        key: "dollar_latest_4w",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "5.75%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "dollar_latest_13w",
        key: "dollar_latest_13w",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "5.75%",
      },
      {
        title: "L52W",
        dataIndex: "dollar_latest_52w",
        key: "dollar_latest_52w",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "5.75%",
      },
      {
        title: "YTD",
        dataIndex: "dollar_latest_ytd",
        key: "dollar_latest_ytd",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "5.75%",
      },
    ],
  },
  {
    title: "GSV",
    children: [
      {
        title: "L4W",
        dataIndex: "gsv_latest_4w",
        key: "gsv_latest_4w",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "5.75%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "gsv_latest_13w",
        key: "gsv_latest_13w",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "5.75%",
      },
      {
        title: "L52W",
        dataIndex: "gsv_latest_52w",
        key: "gsv_latest_52w",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "5.75%",
      },
      {
        title: "YTD",
        dataIndex: "gsv_latest_ytd",
        key: "gsv_latest_ytd",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "5.75%",
      },
    ],
  },
  {
    title: "NSV",
    children: [
      {
        title: "L4W",
        dataIndex: "nsv_latest_4w",
        key: "nsv_latest_4w",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "5.75%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "nsv_latest_13w",
        key: "nsv_latest_13w",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "5.75%",
      },
      {
        title: "L52W",
        dataIndex: "nsv_latest_52w",
        key: "nsv_latest_52w",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "5.75%",
      },
      {
        title: "YTD",
        dataIndex: "nsv_latest_ytd",
        key: "nsv_latest_ytd",
        render: renderDollarPercentFunction,
        renderPPT: renderPPTDollarFunction,
        width: "5.75%",
      },
    ],
  },
  {
    title: "MAC%",
    children: [
      {
        title: "L4W",
        dataIndex: "mac_latest_4w",
        key: "mac_latest_4w",
        render: renderPercentagePointsFunction,
        renderPPT: renderPPTDollarFunction,
        width: "4%",
        className: "td-border-left",
      },
      {
        title: "L13W",
        dataIndex: "mac_latest_13w",
        key: "mac_latest_13w",
        render: renderPercentagePointsFunction,
        renderPPT: renderPPTDollarFunction,
        width: "4%",
      },
      {
        title: "L52W",
        dataIndex: "mac_latest_52w",
        key: "mac_latest_52w",
        render: renderPercentagePointsFunction,
        renderPPT: renderPPTDollarFunction,
        width: "4%",
      },
      {
        title: "YTD",
        dataIndex: "mac_latest_ytd",
        key: "mac_latest_ytd",
        render: renderPercentagePointsFunction,
        renderPPT: renderPPTDollarFunction,
        width: "4%",
      },
    ],
  },
];

export default {
  psColsExport,
  psColsFinancialsExport,
  psCols,
  psColsRsv,
  psColsShare,
  psColsTdp,
  psCols$Tdp,
  psColsFinancials,
  psColsFinancialRsv,
  psColsFinancialGsv,
  psColsFinancialNSV,
  psColsFinancialMAC,
}
