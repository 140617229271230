import React from "react";
import { formatNumber, hasValue, valueTypes } from "../../utilities/helper";
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from "antd";
export const rowKey = 'customer_retailer_details'

export const KPICard = ({ title, target, actual, variance, symbol, showExport, exportData }) => {
  const menu = (exportData) => (
    <Menu>
      <Menu.Item key="0" onClick={exportData}>Export Data</Menu.Item>
    </Menu>
  );
  return (
    <div className="item">
      <div>
      <h5>{title}        
      {showExport && <Dropdown overlay={() => menu(exportData)} trigger={['click']} placement="bottomRight" className="d-more">
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{position:'absolute', right:'30px'}}>
                  <MoreOutlined />
                </a>
          </Dropdown>
          }</h5> 
          </div>
      <div className="item-value-wrap">
        <div className="item-value">
          <h5>TARGET</h5>
          <p>
            {hasValue(target) &&
              (formatNumber(target, 1, true) === valueTypes.NA
                ? valueTypes.NA
                : `${symbol ? symbol : ""}${formatNumber(target, 1)}`)}
          </p>
        </div>
        <div className="item-value">
          <h5>ACTUAL</h5>
          <p>
            {hasValue(actual) &&
              (formatNumber(actual, 1, true) === valueTypes.NA
                ? valueTypes.NA
                : `${symbol ? symbol : ""}${formatNumber(actual, 1)}`)}{" "}
            <span>
              {hasValue(variance) &&
                (formatNumber(variance, 1, true) === valueTypes.NA
                  ? valueTypes.NA
                  : `${formatNumber(variance, 1)}%`)}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
