import React from 'react';
import { CaretUpFilled, CaretDownFilled } from '@ant-design/icons';

import { formatNumber, valueTypes } from "../../utilities/helper";

const renderDollarFunction = (val, rec) =>
  formatNumber(val, 1, true) === valueTypes.NA
    ? valueTypes.NA
    : `$${formatNumber(val, 1)}`;

const renderNumberFunction = (val, rec) =>
  formatNumber(val, 1, true) === valueTypes.NA
    ? valueTypes.NA
    : formatNumber(val, 1);

const renderPercentageFunction = (val, rec) =>
  formatNumber(val, 1, true) === valueTypes.NA
    ? valueTypes.NA
    : `${formatNumber(val, 1)}%`;

const renderDollarCaretFunction = (val, rec) => {
  let isZero = true;
  let dec = 1;

  if (val) {
    if (
      formatNumber(val, 1, true) === valueTypes.NA &&
      formatNumber(val, 2, true) !== valueTypes.NA
    ) {
      isZero = false;
      dec = 2;
    }
    if (formatNumber(val, 1, true) !== valueTypes.NA) isZero = false;
  }

  if (isZero) return "NA";
  else
    return (
      <>
        {Number(val) >= 0 ? (
          <div>
            {formatNumber(val, dec)}
            <CaretUpFilled style={{ color: "#00c853", marginLeft: 5 }} />
          </div>
        ) : (
          <div>
            {formatNumber(-1 * val, dec)}
            <CaretDownFilled style={{ color: "#ec3225", marginLeft: 5 }} />
          </div>
        )}
      </>
    );
};

const itColsRevenue = [
  {
    title: "Retailer",
    dataIndex: "customer_retailer_details",
    key: "customer_retailer_details",
    width: 260,
  },
  {
    title: "MKT RSV YEE Target ",
    dataIndex: "fact_rsv_target_yee",
    key: "fact_rsv_target_yee",
    align: "center",
    width: 196,
    render: renderDollarFunction,
    className: "td-border-left",
  },
  {
    title: "MKT RSV YEE Actual ",
    dataIndex: "fact_rsv_actuals_yee",
    key: "fact_rsv_actuals_yee",
    align: "center",
    width: 196,
    render: renderDollarFunction,
    className: "td-border-left",
  },
  {
    title: "MKT RSV vs Target",
    dataIndex: "er_vs_target",
    key: "er_vs_target",
    align: "center",
    width: 196,
    render: renderDollarCaretFunction,
    className: "td-border-left",
  },
  {
    title: "Launch Period",
    dataIndex: "time_er_launch_period",
    key: "time_er_launch_period",
    align: "center",
    width: 196,
    className: "td-border-left",
  },
  {
    title: "Period at Scale",
    dataIndex: "time_first_period_at_scale",
    key: "time_first_period_at_scale",
    align: "center",
    width: 220,
    className: "td-border-left",
  },
];

const itColsDistribution = [
  {
    title: "Retailer",
    dataIndex: "customer_retailer_details",
    key: "customer_retailer_details",
    width: 380,
  },
  {
    title: "% ACV Reach",
    children: [
      {
        title: "Actual",
        dataIndex: "fact_percent_acv_reach_actual",
        key: "fact_percent_acv_reach_actual",
        align: "center",
        render: renderPercentageFunction,
        className: "td-border-left",
      },
    ]
  },
  {
    title: "# of items",
    children: [
      {
        title: "Actual",
        dataIndex: "qty_er_items_actual",
        key: "qty_er_items_actual",
        align: "center",
        render: renderNumberFunction,
        className: "td-border-left",
      }
    ]
  },
  {
    title: "TDP",
    children: [
      {
        title: "Target",
        dataIndex: "fact_tdp_target",
        key: "fact_tdp_target",
        align: "center",
        render: renderNumberFunction,
        className: "td-border-left",
      },
      {
        title: "Actual",
        dataIndex: "fact_tdp_actuals",
        key: "fact_tdp_actuals",
        align: "center",
        render: renderNumberFunction,
      },
    ]
  },
  {
    title: "$/TDP",
    children: [
      {
        title: "Target",
        dataIndex: "er_dollar_per_tdp_target",
        key: "er_dollar_per_tdp_target",
        align: "center",
        render: renderDollarFunction,
        className: "td-border-left",
      },
      {
        title: "Actual",
        dataIndex: "er_dollar_per_tdp_actual",
        key: "er_dollar_per_tdp_actual",
        align: "center",
        render: renderDollarFunction,
      },
    ]
  },
];

const itColsDistributionPpt = [
  {
    title: "Retailer",
    children:[{
      title: "",
      dataIndex: "customer_retailer_details",
      key: "customer_retailer_details",
      align: "center",
      render: renderPercentageFunction,
      className: "td-border-left",
    }],
    dataIndex: "customer_retailer_details",
    key: "customer_retailer_details",
    width: 260,
  },
  {
    title: "% ACV Reach",
    children: [
      {
        title: "Actual",
        dataIndex: "fact_percent_acv_reach_actual",
        key: "fact_percent_acv_reach_actual",
        align: "center",
        render: renderPercentageFunction,
        className: "td-border-left",
      },
    ]
  },
  {
    title: "# of items",
    children: [
      {
        title: "Actual",
        dataIndex: "qty_er_items_actual",
        key: "qty_er_items_actual",
        align: "center",
        render: renderNumberFunction,
        className: "td-border-left",
      }
    ]
  },
  {
    title: "TDP",
    children: [
      {
        title: "Target",
        dataIndex: "fact_tdp_target",
        key: "fact_tdp_target",
        align: "center",
        render: renderNumberFunction,
        className: "td-border-left",
      },
      {
        title: "Actual",
        dataIndex: "fact_tdp_actuals",
        key: "fact_tdp_actuals",
        align: "center",
        render: renderNumberFunction,
      },
    ]
  },
  {
    title: "$/TDP",
    children: [
      {
        title: "Target",
        dataIndex: "er_dollar_per_tdp_target",
        key: "er_dollar_per_tdp_target",
        align: "center",
        render: renderDollarFunction,
        className: "td-border-left",
      },
      {
        title: "Actual",
        dataIndex: "er_dollar_per_tdp_actual",
        key: "er_dollar_per_tdp_actual",
        align: "center",
        render: renderDollarFunction,
      },
    ]
  },
];

export default {
  itColsRevenue,
  itColsDistribution,
  itColsDistributionPpt
};
