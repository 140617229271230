import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "./slicers/admin.slicer";
import perfSummaryReducer from "./slicers/performanceSummary.slicer";
import distSummaryReducer from "./slicers/distributionSummary.slicer";
import volShiftReducer from "./slicers/volumeShiftingSummary.slicer";
import innovationTrackerReducer from "./slicers/innovation-tracker.slicer";
import reportReducer from "./slicers/report.slicer";
import trackboardReducer from './slicers/trackboard.slicer';
import nudgesReducer from './slicers/nudges.slicer';
import exportDataReducer from "./slicers/exportData.slicer";
import profileReducer from "./slicers/profile.slicer";
import refreshDateReducer from "./slicers/refreshDate.slicer";
import dataSourcesReducer from "./slicers/dataSources.slicer";
import bookmarkReducer from "./slicers/bookmarks.slicer";
import reviewAlertsReducer from "./slicers/reviewAlerts.slicer";
import scatterReducer from "./slicers/scatter.slicer";
import beAvailableReducer from "./slicers/be-available.slicer";
import shareOfShelfReducer from "./slicers/shareOfShelf.slicer";
import searchTermReducer from "./slicers/searchTerm.slicer";
import diagnosticsReducer from "./slicers/diagnostics.slicer";
import portfolioSummaryReducer from "./slicers/portfolioSummary.slicer";

const store = configureStore({
  reducer: {
    admin: adminReducer,
    dataSources: dataSourcesReducer,
    perfSummary: perfSummaryReducer,
    distSummary: distSummaryReducer,
    innovationTracker: innovationTrackerReducer,
    report: reportReducer,
    volShiftSummary: volShiftReducer,
    trackboard: trackboardReducer,
    nudges: nudgesReducer,
    bookmarks: bookmarkReducer,
    exportData: exportDataReducer,
    profile: profileReducer,
    refreshDate: refreshDateReducer,
    reviewAlerts: reviewAlertsReducer,
    scatter: scatterReducer,
    beAvailable: beAvailableReducer,
    shareOfShelf: shareOfShelfReducer,
    searchTerm: searchTermReducer,
    diagnostics: diagnosticsReducer,
    portfolioSummary: portfolioSummaryReducer,

  },
});

export default store;

const getStore = () => store;
const getState = () => store.getState();

export { getState, getStore };
