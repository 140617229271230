import React, { useEffect, useState } from 'react';
import { Bar } from '@ant-design/plots';
import './bar.scss';
import { MoreOutlined } from '@ant-design/icons';
import { formatNumber, menu } from '../../utilities/helper';
import { Dropdown, Select, Checkbox } from 'antd';
import { each, groupBy } from '@antv/util';
import { useDispatch } from "react-redux";
import { setExportData } from '../../slicers/exportData.slicer';

const { Option } = Select;
export default function BarChart(props) {
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedKpi, setSelectedKpi] = useState(null);
  const [data, setData] = useState([]);
  const [bottomData, setBottomData] = useState([]);
  const [topNumber, setTopNumber] = useState(5);
  const [bottomNumber, setBottomNumber] = useState(5);
  const [topSkuConfig, setTopSkuConfig] = useState({
    xField: 'current_year',
    yField: 'label',
    padding: [0, 50, 0, 150],
    color: '#80EBDC',
    label: {
      position: 'right',
      formatter: (params) => formatNumber(Number(params.current_year)) + (['MKT RSV Share', 'Eq LBs Share'].includes(selectedKpi) ? '%' : ''),
      // offsetX:-20,
    },
    xAxis: {
      grid: null,
      label: {
        formatter: (text) => {
          return formatNumber(text, 0).replace(',', '')
        }
      }
    },
    yAxis: {
      label: {
        formatter: (text) => {
          return text.length > 25 ? text.substr(0, 22) + '...' : text
        }
      }
    },
    tooltip: {
      formatter: (params) => {
        return { name: 'CY Value', value: formatNumber(params.current_year, 1) + (['MKT RSV Share', 'Eq LBs Share'].includes(selectedKpi) ? '%' : '') }
      },
      customItems: (originalItems) => {
        // process originalItems, 
        return originalItems.concat([{ name: 'YA Value', value: formatNumber(originalItems[0].data.year_ago, 1) + (['MKT RSV Share', 'Eq LBs Share'].includes(selectedKpi) ? '%' : '') }]);
      }
    },
  });
  const [bottomSkuConfig, setBottomSkuConfig] = useState({
    xField: 'current_year',
    padding: [0, 50, 0, 150],
    xAxis: {
      grid: null,
      label: {
        formatter: (text) => {
          return formatNumber(text, 0).replace(',', '')
        }
      }
    },
    yAxis: {
      label: {
        formatter: (text) => {
          return text.length > 25 ? text.substr(0, 22) + '...' : text
        }
      }
    },
    yField: 'label',
    label: {
      position: 'right',
      formatter: (params) => formatNumber(Number(params.current_year)) + (['MKT RSV Share', 'Eq LBs Share'].includes(selectedKpi) ? '%' : ''),
      // offsetX:-20,
    },
    color: '#f1b47d',
    tooltip: {
      formatter: (params) => {
        return { name: 'CY Value', value: formatNumber(params.current_year, 1) + (['MKT RSV Share', 'Eq LBs Share'].includes(selectedKpi) ? '%' : '') }
      },
      customItems: (originalItems) => {
        // process originalItems, 
        return originalItems.concat([{ name: 'YA Value', value: formatNumber(originalItems[0].data.year_ago, 1) + (['MKT RSV Share', 'Eq LBs Share'].includes(selectedKpi) ? '%' : '') }]);
      }
    },
  });

  useEffect(() => {
    let temp = props.data && Object.keys(props.data) ? Object.keys(props.data) : [];
    if (temp.length) {
      setSelectedProduct(temp[0])
      let temp2 = Object.keys(props.data[temp[0]])
      if (temp2.length) {
        setSelectedKpi(temp2[0])
        setTopSkuConfig({ ...topSkuConfig, annotations: [] })
        setBottomSkuConfig({ ...bottomSkuConfig, annotations: [] })
      }
    }
  }, [props.data, props.bottomData])

  useEffect(() => {
    if (props.data && Object.keys(props.data).length !== 0 && props.data[selectedProduct] && Object.keys(props.data[selectedProduct]).includes(selectedKpi) && props.data[selectedProduct][selectedKpi] && props.data[selectedProduct][selectedKpi].length !== 0) {
      setData(props.data[selectedProduct][selectedKpi]);
    }
    if (props.bottomData && Object.keys(props.bottomData).length !== 0 && props.bottomData[selectedProduct] && Object.keys(props.bottomData[selectedProduct]).includes(selectedKpi) && props.bottomData[selectedProduct][selectedKpi] && props.bottomData[selectedProduct][selectedKpi].length !== 0) {
      setBottomData(props.bottomData[selectedProduct][selectedKpi])
    }
    else {
      setBottomData([]);
    }
  }, [selectedProduct, selectedKpi])

  useEffect(() => {
    // let data = [];
    // let bottomData = [];
    let annotations = [];
    let bottomAnnotations = [];
    // debugger
    // console.log("Data", props.data, data, "Bottom Data", bottomData, props.bottomData, selectedKpi)
    if (data && data.length && bottomData && bottomData.length) {
      each(groupBy(data, 'label'), (values, k) => {
        // annotations.push({
        //     type: 'text',
        //     position: [k, Number(values[0].current_year)+1],
        //     content: `${formatNumber(Number(values[0].current_year))}`
        //     // offsetY: -10,
        //   });
        annotations.push({
          type: 'dataMarker',
          position: [k, Number(values[0].year_ago)],
          point: null,
          direction: 'downward',
          line: {
            length: 14,
            style: {
              stroke: '#022A3E'
            }
          }
          // offsetY: -10,
        });
        annotations.push({
          type: 'dataMarker',
          position: [k, Number(values[0].year_ago)],
          point: null,
          direction: 'upward',
          line: {
            length: 14,
            style: {
              stroke: '#022A3E'
            }
          }
          // offsetY: -10,
        });
      });;
      each(groupBy(bottomData, 'label'), (values, k) => {
        // console.log(values[0])
        // bottomAnnotations.push({
        //     type: 'text',
        //     position: [k, Number(values[0].current_year)+1],
        //     content: `${formatNumber(Number(values[0].current_year))}`
        //     // offsetY: -10,
        //   });
        bottomAnnotations.push({
          type: 'dataMarker',
          position: [k, Number(values[0].year_ago)],
          point: null,
          direction: 'downward',
          line: {
            length: 14,
            style: {
              stroke: '#022A3E'
            }
          }
          // offsetY: -10,
        });
        bottomAnnotations.push({
          type: 'dataMarker',
          position: [k, Number(values[0].year_ago)],
          point: null,
          direction: 'upward',
          line: {
            length: 14,
            style: {
              stroke: '#022A3E'
            }
          }
          // offsetY: -10,
        });
      });
    }

    setTopSkuConfig({
      ...{
        xField: 'current_year',
        yField: 'label',
        padding: [0, 50, 0, 190],
        color: '#80EBDC',
        label: {
          position: 'right',
          formatter: (params) => formatNumber(Number(params.current_year)) + (['MKT RSV Share', 'Eq LBs Share'].includes(selectedKpi) ? '%' : ''),
          // offsetX:-20,
        },
        xAxis: {
          grid: null,
          label: {
            formatter: (text) => {
              return formatNumber(text, 0).replace(',', '')
            }
          }
        },
        yAxis: {
          label: {
            formatter: (text) => {
              return text.length > 25 ? text.substr(0, 22) + '...' : text
            }
          }
        },
        tooltip: {
          formatter: (params) => {
            return { name: 'CY Value', value: formatNumber(params.current_year, 1) + (['MKT RSV Share', 'Eq LBs Share'].includes(selectedKpi) ? '%' : '') }
          },
          customItems: (originalItems) => {
            // process originalItems, 
            return originalItems.concat([{ name: 'YA Value', value: formatNumber(originalItems[0].data.year_ago, 1) + (['MKT RSV Share', 'Eq LBs Share'].includes(selectedKpi) ? '%' : '') }]);
          }
        },
      },
      annotations: annotations
    });
    setBottomSkuConfig({
      ...{
        xField: 'current_year',
        padding: [0, 50, 0, 190],
        xAxis: {
          grid: null,
          label: {
            formatter: (text) => {
              return formatNumber(text, 0).replace(',', '')
            }
          }
        },
        yAxis: {
          label: {
            formatter: (text) => {
              return text.length > 25 ? text.substr(0, 22) + '...' : text
            }
          }
        },
        yField: 'label',
        label: {
          position: 'right',
          formatter: (params) => formatNumber(Number(params.current_year)) + (['MKT RSV Share', 'Eq LBs Share'].includes(selectedKpi) ? '%' : ''),
          // offsetX:-20,
        },
        color: '#f1b47d',
        tooltip: {
          formatter: (params) => {
            return { name: 'CY Value', value: formatNumber(params.current_year, 1) + (['MKT RSV Share', 'Eq LBs Share'].includes(selectedKpi) ? '%' : '') }
          },
          customItems: (originalItems) => {
            // process originalItems, 
            return originalItems.concat([{ name: 'YA Value', value: formatNumber(originalItems[0].data.year_ago, 1) + (['MKT RSV Share', 'Eq LBs Share'].includes(selectedKpi) ? '%' : '') }]);
          }
        },
      },
      annotations: bottomAnnotations
    })
  }, [data, bottomData])



  useEffect(() => {
    if (!props.data) return;
    if (Object.keys(props.data).length === 0) return;

    props.data && props.data[selectedProduct] && props.data[selectedProduct][selectedKpi] && setTopNumber(props.data[selectedProduct][selectedKpi].length)
    props.bottomData && props.bottomData[selectedProduct] && props.bottomData[selectedProduct][selectedKpi] && setBottomNumber(props.bottomData[selectedProduct][selectedKpi].length)

    dispatch(
      setExportData({
        bar: {
          filters: {
            "Product Granularity": [selectedProduct],
            KPI: [selectedKpi],
          },
          sku: {
            top: {
              title: `Top 5 ${selectedProduct}s by ${selectedKpi}`,
              legend: "| vs YA",
              data: props.data &&
                props.data[selectedProduct] &&
                props.data[selectedProduct][selectedKpi]
                ? props.data[selectedProduct][selectedKpi]
                : [],
            },
            bottom: {
              title: `Bottom 5 ${selectedProduct}s by ${selectedKpi}`,
              legend: "| vs YA",
              data: props.bottomData &&
                props.bottomData[selectedProduct] &&
                props.bottomData[selectedProduct][selectedKpi]
                ? props.bottomData[selectedProduct][selectedKpi]
                : [],
            },
          },
        },
      })
    );
  }, [dispatch, props.data, selectedProduct, selectedKpi]);



  return (
    <div className={`bar-container ${props.checkbox ? 'trackboard-edit' : ''} ${props.checkbox && props.value ? 'trackboard-selected' : ''}`}>
      <div className="section-title">
        <div className='filter'>
          {props.checkbox && <Checkbox style={{ marginRight: '5%' }} value={props.value} onChange={props.onChange} className="d-check"></Checkbox>}
          <div className='filter-div'>
            <label className="label-block">Product Granularity</label>
            <Select value={selectedProduct} onChange={(e) => setSelectedProduct(e)} style={{ width: 180 }} >
              {(props.data && Object.keys(props.data) ? Object.keys(props.data) : []).map((val) => (<Option value={val}>{val}</Option>))}
            </Select>
          </div>
          <div className='filter-div'>
            <label className="label-block">KPI</label>
            <Select value={selectedKpi} onChange={(e) => setSelectedKpi(e)} style={{ width: 110 }} >
              {(props.data && props.data[selectedProduct] && Object.keys(props.data[selectedProduct]) ? Object.keys(props.data[selectedProduct]) : []).map((val) => (<Option value={val}>{val}</Option>))}
            </Select>
          </div>
        </div>
        <h4>{props.heading}</h4>
        <div>
          <Dropdown overlay={() => menu(props.exportData)} trigger={['click']} placement="bottomRight" className="d-more">
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              <MoreOutlined />
            </a>
          </Dropdown>
        </div>
      </div>
      <div className='bar-charts'>
        <div className='bar-chart'>
          <div className='heading'>Top {topNumber} {selectedProduct}s by {selectedKpi}</div>
          <div className='legend'>
            <span style={{ width: 0, border: 'solid 1px black', height: '18px', marginRight: '2%' }} />
            vs YA
          </div>
          <div className='chart'>
            {(props.data && props.data[selectedProduct] && props.data[selectedProduct][selectedKpi])
              ? <Bar data={props.data[selectedProduct][selectedKpi]} {...topSkuConfig} />
              : <div className="no-data">No data available to display</div>}
          </div>
        </div>
        {data ? bottomData.length > 0 && <div div className='bar-chart'>
          <div className='heading'>Bottom {bottomNumber} {selectedProduct}s by {selectedKpi}</div>
          <div className='legend'>
            <span style={{ width: 0, border: 'solid 1px black', height: '18px', marginRight: '2%' }} />
            vs YA
          </div>
          <div className='chart'>
            {(props.bottomData && props.bottomData[selectedProduct] && props.bottomData[selectedProduct][selectedKpi])
              ? <Bar data={props.bottomData[selectedProduct][selectedKpi]} {...bottomSkuConfig} />
              : <div className="no-data">No data available to display</div>}
          </div>
        </div>
          :
          <div className='bar-chart'>
            <div className='heading'>Bottom {bottomNumber} {selectedProduct}s by {selectedKpi}</div>
            <div className='legend'>
              <span style={{ width: 0, border: 'solid 1px black', height: '18px', marginRight: '2%' }} />
              vs YA
            </div>
            <div className='chart'>
              {(bottomData.length > 0)
                ? <Bar data={bottomData} {...bottomSkuConfig} />
                : <div className="no-data">No data available to display</div>}
            </div>
          </div>
        }
      </div>
    </div >);
};

