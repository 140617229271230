import { Spin, Radio } from 'antd';
import React from 'react';
import { useHistory } from "react-router-dom";
import mdiCart from '../../assets/images/mdi_cart_green.svg';
import mdiDecagram from '../../assets/images/mdi_check-decagram_blue.svg';
import mdiCheck from '../../assets/images/mdi_eye-check_pink.svg';
import mdiView from '../../assets/images/mdi_view-dashboard_orange.svg';



function Navigator({ refreshDate }) {
    const history = useHistory();
    const pathMapping = {
        "/search-term": "/search-term",
        "/traffic": "/traffic",
    };

    return (
        <div className="refreshed-on">
            <div className="nested-breadcrumbs">
                <Radio.Group defaultValue={history.location.pathname} buttonStyle="solid">
                    <Radio.Button onChange={() => history.push("/diagnostics")} value="/diagnostics">
                        <img
                            src={mdiView}
                            alt="Diagnostics"
                            width="20"
                        />Diagnostics</Radio.Button>
                    <Radio.Button onChange={() => history.push("/be-available")} value="/be-available">
                        <img
                            src={mdiDecagram}
                            alt="Be Available"
                            width="20"
                        />Be Available</Radio.Button>
                    <Radio.Button
                        onChange={() => history.push("/share-of-shelf")}
                        value={pathMapping[history.location.pathname] || "/share-of-shelf"}
                    >
                        <img src={mdiCheck} alt="Be Visible" width="20" />
                        Be Visible
                    </Radio.Button>
                    <Radio.Button onChange={() => history.push("/be-selected")} value="/be-selected" disabled>
                        <img
                            src={mdiCart}
                            alt="Be Selected"
                            width="20"
                        />Be Selected</Radio.Button>
                </Radio.Group>
            </div>
            <span>
                Last Refreshed on: {refreshDate.last_refresh}, Data upto:{" "}
                {refreshDate.latest_period_available} &nbsp; | Next Refresh
                on: {refreshDate.next_refresh}
            </span>
        </div>
    )
}
export default Navigator;
