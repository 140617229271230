import { formatNumber } from "../../utilities/helper";
import { cloneDeep } from "lodash";
import { setDeclareAppliedFilters } from "../../utilities/helper";


// Set the filters applied based on the filters selected on the UI while clicking on Apply button (Special for Conversion usecase)
export const declareAppliedFilters = (inputFilter, filtersData) => {
  const { techs, feedingPhilosophies, textures } = filtersData;
  let finalFilter = cloneDeep(inputFilter);

  if (
    finalFilter["product_pet"].length === 0
  )
    finalFilter["product_pet"] = ["ALL"];
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_technology", techs);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_feed_philosophy", feedingPhilosophies);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_texture", textures);

  return finalFilter;
};

export const itemsNotYetClassified = "Items Not Yet Classified";

export const columnBulletData = [
    {
      customer_ratailer_details: "Amazon",
      "Average Rating": 58,
      "# of Reviews": [350000, 352500],
    },
    {
      customer_ratailer_details: "Chewy",
      "Average Rating": 47,
      "# of Reviews": [280000, 282500],
    },
    {
      customer_ratailer_details: "Walmart",
      "Average Rating": 40,
      "# of Reviews": [180000, 182500],
    },
    {
      customer_ratailer_details: "Target",
      "Average Rating": 39,
      "# of Reviews": [160000, 162500],
    },
    {
      customer_ratailer_details: "Retailer 5",
      "Average Rating": 32,
      "# of Reviews": [140000, 142500],
    },
    {
      customer_ratailer_details: "Retailer 6",
      "Average Rating": 25,
      "# of Reviews": [80000, 82500],
    },
    {
      customer_ratailer_details: "Retailer 7",
      "Average Rating": 22,
      "# of Reviews": [60000, 62500],
    },
    {
      customer_ratailer_details: "Retailer 8",
      "Average Rating": 17,
      "# of Reviews": [45000, 47500],
    },
  ];

export const columnBulletSubBrandData = [
    {
      customer_ratailer_details: "Original",
      "Average Rating": 58,
      "# of Reviews": [300000, 302500],
    },
    {
      customer_ratailer_details: "Pill pocket",
      "Average Rating": 47,
      "# of Reviews": [250000, 252500],
    },
    {
      customer_ratailer_details: "Dental",
      "Average Rating": 40,
      "# of Reviews": [180000, 182500],
    },
    {
      customer_ratailer_details: "SmartBytes",
      "Average Rating": 39,
      "# of Reviews": [160000, 162500],
    },
    {
      customer_ratailer_details: "Blueberry",
      "Average Rating": 32,
      "# of Reviews": [140000, 142500],
    },
    {
      customer_ratailer_details: "Hip&Joint",
      "Average Rating": 25,
      "# of Reviews": [80000, 82500],
    },
    {
      customer_ratailer_details: "BreathBuster",
      "Average Rating": 22,
      "# of Reviews": [60000, 62500],
    },
    {
      customer_ratailer_details: "Variety Pack",
      "Average Rating": 17,
      "# of Reviews": [45000, 47500],
    },
  ];

export const columnBulletCompititionData = [
    {
      customer_ratailer_details: "Greenies",
      "Average Rating": 58,
      "# of Reviews": [300000, 302500],
    },
    {
      customer_ratailer_details: "Milk Bone",
      "Average Rating": 47,
      "# of Reviews": [250000, 252500],
    },
    {
      customer_ratailer_details: "Whimzees",
      "Average Rating": 40,
      "# of Reviews": [180000, 182500],
    },
    {
      customer_ratailer_details: "Bluecore",
      "Average Rating": 39,
      "# of Reviews": [160000, 162500],
    },
    {
      customer_ratailer_details: "Merrick",
      "Average Rating": 32,
      "# of Reviews": [130000, 132500],
    },
    {
      customer_ratailer_details: "Brand 6",
      "Average Rating": 25,
      "# of Reviews": [90000, 92500],
    },
    {
      customer_ratailer_details: "Brand 7",
      "Average Rating": 22,
      "# of Reviews": [60000, 62500],
    },
    {
      customer_ratailer_details: "Brand 8",
      "Average Rating": 17,
      "# of Reviews": [35000, 37500],
    },
  ];

export const columnLineData = [
    {
      period_week: "P6W04",
      "Average Rating": 20,
      "# of Reviews": 350000,
    },
    {
      period_week: "P7W01",
      "Average Rating": 43,
      "# of Reviews": 270000,
    },
    {
      period_week: "P7W02",
      "Average Rating": 37,
      "# of Reviews": 260000,
    },
    {
      period_week: "P7W03",
      "Average Rating": 37,
      "# of Reviews": 305000,
    },
    {
      period_week: "P7W04",
      "Average Rating": 37,
      "# of Reviews": 340000,
    },
    {
      period_week: "P8W01",
      "Average Rating": 43,
      "# of Reviews": 310000,
    },
    {
      period_week: "P8W02",
      "Average Rating": 37,
      "# of Reviews": 308000,
    },
    {
      period_week: "P8W03",
      "Average Rating": 43,
      "# of Reviews": 300000,
    },
    {
      period_week: "P8W04",
      "Average Rating": 37,
      "# of Reviews": 300000,
    },
    {
      period_week: "P9W01",
      "Average Rating": 37,
      "# of Reviews": 240000,
    },
    {
      period_week: "P9W02",
      "Average Rating": 37,
      "# of Reviews": 220000,
    },
    {
      period_week: "P9W03",
      "Average Rating": 43,
      "# of Reviews": 320000,
    },
    {
      period_week: "P9W04",
      "Average Rating": 43,
      "# of Reviews": 475000,
    },
  ];

export const competitorData = [
  {
    title: "Mars",
    value: "Mars",
    children: [
      {
        title: "Temptations",
        value: "Temptations",
      },
      {
        title: "Pedigree",
        value: "Pedigree",
      },
      {
        title: "Sheba",
        value: "Sheba",
      },
    ],
  },
  {
    title: "Competition",
    value: "Competition",
    children: [
      {
        title: "Milkbone",
        value: "Milkbone",
      },
      {
        title: "Whimzees",
        value: "Whimzees",
      },
      {
        title: "Blue Core",
        value: "Blue Core",
      },
    ],
  },
];

export const multiLineData = [
  {
    period_week: "P06W04",
    "In Stock %": 50,
    customer_ratailer_details: "Amazon",
  },
  {
    period_week: "P07W01",
    "In Stock %": 58,
    customer_ratailer_details: "Amazon",
  },
  {
    period_week: "P07W02",
    "In Stock %": 55,
    customer_ratailer_details: "Amazon",
  },
  {
    period_week: "P07W03",
    "In Stock %": 62,
    customer_ratailer_details: "Amazon",
  },
  {
    period_week: "P07W04",
    "In Stock %": 70,
    customer_ratailer_details: "Amazon",
  },
  {
    period_week: "P08W01",
    "In Stock %": 65,
    customer_ratailer_details: "Amazon",
  },
  {
    period_week: "P08W02",
    "In Stock %": 63,
    customer_ratailer_details: "Amazon",
  },
  {
    period_week: "P08W03",
    "In Stock %": 61,
    customer_ratailer_details: "Amazon",
  },
  {
    period_week: "P08W04",
    "In Stock %": 61,
    customer_ratailer_details: "Amazon",
  },
  {
    period_week: "P09W01",
    "In Stock %": 50,
    customer_ratailer_details: "Amazon",
  },
  {
    period_week: "P09W02",
    "In Stock %": 45,
    customer_ratailer_details: "Amazon",
  },
  {
    period_week: "P09W03",
    "In Stock %": 70,
    customer_ratailer_details: "Amazon",
  },
  {
    period_week: "P09W04",
    "In Stock %": 95,
    customer_ratailer_details: "Amazon",
  },
  {
    period_week: "P06W04",
    "In Stock %": 40,
    customer_ratailer_details: "Chewy",
  },
  {
    period_week: "P07W01",
    "In Stock %": 48,
    customer_ratailer_details: "Chewy",
  },
  {
    period_week: "P07W02",
    "In Stock %": 45,
    customer_ratailer_details: "Chewy",
  },
  {
    period_week: "P07W03",
    "In Stock %": 52,
    customer_ratailer_details: "Chewy",
  },
  {
    period_week: "P07W04",
    "In Stock %": 60,
    customer_ratailer_details: "Chewy",
  },
  {
    period_week: "P08W01",
    "In Stock %": 55,
    customer_ratailer_details: "Chewy",
  },
  {
    period_week: "P08W02",
    "In Stock %": 53,
    customer_ratailer_details: "Chewy",
  },
  {
    period_week: "P08W03",
    "In Stock %": 51,
    customer_ratailer_details: "Chewy",
  },
  {
    period_week: "P08W04",
    "In Stock %": 51,
    customer_ratailer_details: "Chewy",
  },
  {
    period_week: "P09W01",
    "In Stock %": 40,
    customer_ratailer_details: "Chewy",
  },
  {
    period_week: "P09W02",
    "In Stock %": 35,
    customer_ratailer_details: "Chewy",
  },
  {
    period_week: "P09W03",
    "In Stock %": 60,
    customer_ratailer_details: "Chewy",
  },
  {
    period_week: "P09W04",
    "In Stock %": 85,
    customer_ratailer_details: "Chewy",
  },
  {
    period_week: "P06W04",
    "In Stock %": 30,
    customer_ratailer_details: "Walmart",
  },
  {
    period_week: "P07W01",
    "In Stock %": 68,
    customer_ratailer_details: "Walmart",
  },
  {
    period_week: "P07W02",
    "In Stock %": 25,
    customer_ratailer_details: "Walmart",
  },
  {
    period_week: "P07W03",
    "In Stock %": 12,
    customer_ratailer_details: "Walmart",
  },
  {
    period_week: "P07W04",
    "In Stock %": 20,
    customer_ratailer_details: "Walmart",
  },
  {
    period_week: "P08W01",
    "In Stock %": 75,
    customer_ratailer_details: "Walmart",
  },
  {
    period_week: "P08W02",
    "In Stock %": 83,
    customer_ratailer_details: "Walmart",
  },
  {
    period_week: "P08W03",
    "In Stock %": 31,
    customer_ratailer_details: "Walmart",
  },
  {
    period_week: "P08W04",
    "In Stock %": 21,
    customer_ratailer_details: "Walmart",
  },
  {
    period_week: "P09W01",
    "In Stock %": 20,
    customer_ratailer_details: "Walmart",
  },
  {
    period_week: "P09W02",
    "In Stock %": 15,
    customer_ratailer_details: "Walmart",
  },
  {
    period_week: "P09W03",
    "In Stock %": 80,
    customer_ratailer_details: "Walmart",
  },
  {
    period_week: "P09W04",
    "In Stock %": 75,
    customer_ratailer_details: "Walmart",
  },

  {
    period_week: "P06W04",
    "In Stock %": 40,
    customer_ratailer_details: "Target",
  },
  {
    period_week: "P07W01",
    "In Stock %": 78,
    customer_ratailer_details: "Target",
  },
  {
    period_week: "P07W02",
    "In Stock %": 35,
    customer_ratailer_details: "Target",
  },
  {
    period_week: "P07W03",
    "In Stock %": 22,
    customer_ratailer_details: "Target",
  },
  {
    period_week: "P07W04",
    "In Stock %": 30,
    customer_ratailer_details: "Target",
  },
  {
    period_week: "P08W01",
    "In Stock %": 85,
    customer_ratailer_details: "Target",
  },
  {
    period_week: "P08W02",
    "In Stock %": 93,
    customer_ratailer_details: "Target",
  },
  {
    period_week: "P08W03",
    "In Stock %": 41,
    customer_ratailer_details: "Target",
  },
  {
    period_week: "P08W04",
    "In Stock %": 31,
    customer_ratailer_details: "Target",
  },
  {
    period_week: "P09W01",
    "In Stock %": 30,
    customer_ratailer_details: "Target",
  },
  {
    period_week: "P09W02",
    "In Stock %": 25,
    customer_ratailer_details: "Target",
  },
  {
    period_week: "P09W03",
    "In Stock %": 90,
    customer_ratailer_details: "Target",
  },
  {
    period_week: "P09W04",
    "In Stock %": 85,
    customer_ratailer_details: "Target",
  },
];

export const perfTableData = [
  {
    sub_brand: "Original",
    csl_total_ecom: {
      abs: 12,
      variance: 10.0,
    },
    csl_retailer: {
      abs: 12,
      variance: 10.0,
    },
    items_listed_total_ecom: {
      abs: 100,
      variance: 10.0,
    },
    items_listed_retailer: {
      abs: 100,
      variance: 10.0,
    },
    in_stock_total_ecom: {
      abs: 12,
      variance: 10.0,
    },
    in_stock_retailer: {
      abs: 12,
      variance: 10.0,
    },
    sales_total_ecom: {
      abs: 'Overall Positive',
      // variance: null,
    },
    sales_retailer: {
      abs: 2000,
      variance: 10.0,
    },
    upc_data: [
      {
        upc: "UPC1 - 25gm",
        csl_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        csl_retailer: {
          abs: 12,
          variance: 10.0,
        },
        items_listed_total_ecom: {
          abs: 100,
          variance: 10.0,
        },
        items_listed_retailer: {
          abs: 100,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
        sales_retailer: {
          abs: 2000,
          variance: 10.0,
        },
      },
      {
        upc: "UPC1 - 50gm",
        csl_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        csl_retailer: {
          abs: 12,
          variance: 10.0,
        },
        items_listed_total_ecom: {
          abs: 100,
          variance: 10.0,
        },
        items_listed_retailer: {
          abs: 100,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
        sales_retailer: {
          abs: 2000,
          variance: 10.0,
        },
      },
      {
        upc: "UPC2 - 25gm",
        csl_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        csl_retailer: {
          abs: 12,
          variance: 10.0,
        },
        items_listed_total_ecom: {
          abs: 100,
          variance: 10.0,
        },
        items_listed_retailer: {
          abs: 100,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
        sales_retailer: {
          abs: 2000,
          variance: 10.0,
        },
      },
      {
        upc: "UPC2 - 50gm",
        csl_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        csl_retailer: {
          abs: 12,
          variance: 10.0,
        },
        items_listed_total_ecom: {
          abs: 100,
          variance: 10.0,
        },
        items_listed_retailer: {
          abs: 100,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
        sales_retailer: {
          abs: 2000,
          variance: 10.0,
        },
      },
    ],
    sub_brand_data: [
      {
        sub_brand: "Adult Lamb Rice",
        csl_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        csl_retailer: {
          abs: 12,
          variance: 10.0,
        },
        items_listed_total_ecom: {
          abs: 100,
          variance: 10.0,
        },
        items_listed_retailer: {
          abs: 100,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
        sales_retailer: {
          abs: 2000,
          variance: 10.0,
        },
        upc_data: [
          {
            upc: "UPC1 - 25gm",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 100,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 100,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
          },
          {
            upc: "UPC1 - 50gm",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 100,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 100,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
          },
          {
            upc: "UPC2 - 25gm",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 100,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 100,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
          },
          {
            upc: "UPC2 - 50gm",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 100,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 100,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
          },
        ],
      },
      {
        sub_brand: "Large Breed Adult",
        csl_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        csl_retailer: {
          abs: 12,
          variance: 10.0,
        },
        items_listed_total_ecom: {
          abs: 100,
          variance: 10.0,
        },
        items_listed_retailer: {
          abs: 100,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
        sales_retailer: {
          abs: 2000,
          variance: 10.0,
        },
        upc_data: [
          {
            upc: "UPC1 - 25gm",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 100,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 100,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
          },
          {
            upc: "UPC1 - 50gm",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 100,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 100,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
          },
          {
            upc: "UPC2 - 25gm",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 100,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 100,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
          },
          {
            upc: "UPC2 - 50gm",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 100,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 100,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
          },
        ],
      },
    ],
  },
  {
    sub_brand: "Pill Pockets",
    csl_total_ecom: {
      abs: 12,
      variance: 10.0,
    },
    csl_retailer: {
      abs: 12,
      variance: 10.0,
    },
    items_listed_total_ecom: {
      abs: 100,
      variance: 10.0,
    },
    items_listed_retailer: {
      abs: 100,
      variance: 10.0,
    },
    in_stock_total_ecom: {
      abs: 12,
      variance: 10.0,
    },
    in_stock_retailer: {
      abs: 12,
      variance: 10.0,
    },
    sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
    sales_retailer: {
      abs: 2000,
      variance: 10.0,
    },
    upc_data: [
      {
        upc: "UPC1 - 25gm",
        csl_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        csl_retailer: {
          abs: 12,
          variance: 10.0,
        },
        items_listed_total_ecom: {
          abs: 100,
          variance: 10.0,
        },
        items_listed_retailer: {
          abs: 100,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
        sales_retailer: {
          abs: 2000,
          variance: 10.0,
        },
      },
      {
        upc: "UPC1 - 50gm",
        csl_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        csl_retailer: {
          abs: 12,
          variance: 10.0,
        },
        items_listed_total_ecom: {
          abs: 100,
          variance: 10.0,
        },
        items_listed_retailer: {
          abs: 100,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
        sales_retailer: {
          abs: 2000,
          variance: 10.0,
        },
      },
      {
        upc: "UPC2 - 25gm",
        csl_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        csl_retailer: {
          abs: 12,
          variance: 10.0,
        },
        items_listed_total_ecom: {
          abs: 100,
          variance: 10.0,
        },
        items_listed_retailer: {
          abs: 100,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
        sales_retailer: {
          abs: 2000,
          variance: 10.0,
        },
      },
      {
        upc: "UPC2 - 50gm",
        csl_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        csl_retailer: {
          abs: 12,
          variance: 10.0,
        },
        items_listed_total_ecom: {
          abs: 100,
          variance: 10.0,
        },
        items_listed_retailer: {
          abs: 100,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
        sales_retailer: {
          abs: 2000,
          variance: 10.0,
        },
      },
    ],
    sub_brand_data: [
      {
        sub_brand: "5 oz",
        csl_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        csl_retailer: {
          abs: 12,
          variance: 10.0,
        },
        items_listed_total_ecom: {
          abs: 100,
          variance: 10.0,
        },
        items_listed_retailer: {
          abs: 100,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
        sales_retailer: {
          abs: 2000,
          variance: 10.0,
        },
        upc_data: [
          {
            upc: "UPC1 - 25gm",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 100,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 100,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
          },
          {
            upc: "UPC1 - 50gm",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 100,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 100,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
          },
          {
            upc: "UPC2 - 25gm",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 100,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 100,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
          },
          {
            upc: "UPC2 - 50gm",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 100,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 100,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
          },
        ],
      },
      {
        sub_brand: "10 oz",
        csl_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        csl_retailer: {
          abs: 12,
          variance: 10.0,
        },
        items_listed_total_ecom: {
          abs: 100,
          variance: 10.0,
        },
        items_listed_retailer: {
          abs: 100,
          variance: 10.0,
        },
        in_stock_total_ecom: {
          abs: 12,
          variance: 10.0,
        },
        in_stock_retailer: {
          abs: 12,
          variance: 10.0,
        },
        sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
        sales_retailer: {
          abs: 2000,
          variance: 10.0,
        },
        upc_data: [
          {
            upc: "UPC1 - 25gm",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 100,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 100,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
          },
          {
            upc: "UPC1 - 50gm",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 100,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 100,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
          },
          {
            upc: "UPC2 - 25gm",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 100,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 100,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
          },
          {
            upc: "UPC2 - 50gm",
            csl_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            csl_retailer: {
              abs: 12,
              variance: 10.0,
            },
            items_listed_total_ecom: {
              abs: 100,
              variance: 10.0,
            },
            items_listed_retailer: {
              abs: 100,
              variance: 10.0,
            },
            in_stock_total_ecom: {
              abs: 12,
              variance: 10.0,
            },
            in_stock_retailer: {
              abs: 12,
              variance: 10.0,
            },
            sales_total_ecom: {
              abs: 'Overall Positive',
              // variance: '',
            },
            sales_retailer: {
              abs: 2000,
              variance: 10.0,
            },
          },
        ],
      },
    ],
  },
];
const opportunityData = [
  {
    Sales: 42000,
    Avg_Rating: 38,
    genre: "Positive Sentiment"
  },
  {
    Sales: 6000,
    Avg_Rating: 18,
    genre: "Positive Sentiment"
  },
  {
    Sales: 10000,
    Avg_Rating: 93,
    genre: "Positive Sentiment"
  },
  {
    Sales: 50000,
    Avg_Rating: 2,
    genre: "Positive Sentiment"
  },
  {
    Sales: 60000,
    Avg_Rating: 47,
    genre: "Positive Sentiment"
  },
  {
    Sales: 77000,
    Avg_Rating: 62,
    genre: "Positive Sentiment"
  },
  {
    Sales: 19000,
    Avg_Rating: 16,
    genre: "Positive Sentiment"
  },
  {
    Sales: 46000,
    Avg_Rating: 10,
    genre: "Positive Sentiment"
  },
  {
    Sales: 9000,
    Avg_Rating: 81,
    genre: "Negative Sentiment"
  },
  {
    Sales: 98000,
    Avg_Rating: 5,
    genre: "Negative Sentiment"
  },
  {
    Sales: 51000,
    Avg_Rating: 50,
    genre: "Negative Sentiment"
  },
  {
    Sales: 29000,
    Avg_Rating: 22,
    genre: "Negative Sentiment"
  },
  {
    Sales: 70000,
    Avg_Rating: 24,
    genre: "Negative Sentiment"
  },
  {
    Sales: 89000,
    Avg_Rating: 76,
    genre: "Netural Sentiment"
  },
  {
    Sales: 110000,
    Avg_Rating: 74,
    genre: "Netural Sentiment"
  },
  {
    Sales: 20000,
    Avg_Rating: 56,
    genre: "Netural Sentiment"
  },
  {
    Sales: 52000,
    Avg_Rating: 56,
    genre: "Netural Sentiment"
  },
];

export   const config = {
  data: opportunityData,
  xField: "Avg_Rating",
  yField: "Sales",
  colorField: "genre",
  color: ['#00c853','#fab518','rgb(240, 103, 94)'],
  sizeField: "Sales",
  size: [40, 30, 25],
  shape: "circle",
  yAxis: {
    title: {
      text: "# of Reviews",
      style: {
        fontWeight: "700",
      },
    },
    line: {
      style: {
        stroke: "#eee"
      }
    },
    label: {
      formatter: (v) => (formatNumber(v)),
    },
  },
  xAxis: {
    title: {
      text: "Average Rating",
      style: {
        fontWeight: "700",
      },
    },
    grid: {
      line: {
        style: {
          stroke: "#eee"
        }
      }
    },
    line: {
      style: {
        stroke: "#eee"
      }
    }
  },
  tooltip: {
    showMarkers: false,
    fields: ['Sales', 'Avg Rating', '# of Reviews'],
    customContent: (title, items) => {
      let htmlStr = ``;
      items && items.forEach((item) => {
        htmlStr += `<div style=""><div class="g2-tooltip-item" style="margin-bottom:8px;display:flex;justify-content:space-between;">
              <span class="g2-tooltip-item-label" style="margin-right: 12px;">${item.name}:</span>
              <span class="g2-tooltip-item-value">$${formatNumber(item.value)}</span>
            </div>
            <div class="g2-tooltip-item" style="margin-bottom:8px;display:flex;justify-content:space-between;">
              <span class="g2-tooltip-item-label" style="margin-right: 12px;">Average Rating:</span>
              <span class="g2-tooltip-item-value">${item.data?.Avg_Rating}</span>
            </div>
            <div class="g2-tooltip-item" style="margin-bottom:8px;display:flex;justify-content:space-between;">
              <span class="g2-tooltip-item-label" style="margin-right: 12px;"># of Reviews:</span>
              <span class="g2-tooltip-item-value">8.3k</span>
            </div></div>`;
      });
      htmlStr += '</div>';
      return htmlStr;
  },
},
label: {
  formatter: (item) => {
        return formatNumber(item.Sales);
  },
  offsetY: 12,
  style: {
    fontSize: 12,
    fill: 'rgba(0,0,0,0.85)',
  },
},
legend: {
  position: 'top-left',
},
};
const ratingData = 
  [
    {
        "rating_label": 'Amazon',
        "rating_value": 81.89054726368158
    },
    {
        "rating_label": 'Chewy',
        "rating_value": 10.746268656716417
    },
    {
        "rating_label": 'Petco Omni',
        "rating_value": 4.303482587064677
    },
    {
        "rating_label": 'Petsmart Omni',
        "rating_value": 1.2437810945273633
    },
    {
        "rating_label": 'Target Omni',
        "rating_value": 1.8159203980099503
    }
]


export const getConfigBarConsumerReview = (data) => ({
  data: (data ? data : []).map((rec) => ({ ...rec, rating_label: rec.rating_label.toString() })),
  xField: 'rating_value',
  yField: 'rating_label',
  color: '#FCD374',
  minBarWidth: 28,
  maxBarWidth: 28,
  autoFit: true,
  legend: false,
  tooltip: {
    showTitle: false,
    formatter: (data) => ({ name: data.rating_label, value: formatNumber(data.rating_value)}),
  },
  label: {
    position: 'right',
    formatter: (data) => formatNumber(data.rating_value) ,
  },
});

export const reviewFilterBy = [
  {
    "sentence": "Perfect brand name product for small dogs, i.e. my chihuahua.",
    "sentence_rating": 5,
    "reviewed_by_user": "Valerie",
    "review_date": "2021-05-25T00:00:00",
    "retailer": "WALMART"
},
{
    "sentence": "Small bites, which my dogs like.",
    "sentence_rating": 4,
    "reviewed_by_user": "Lisa",
    "review_date": "2021-09-02T00:00:00",
    "retailer": "WALMART"
},
{
    "sentence": "It makes his meals more enjoyable.My little dog loves the tender chunks.",
    "sentence_rating": 5,
    "reviewed_by_user": "John",
    "review_date": "2021-07-20T00:00:00",
    "retailer": "WALMART"
},
{
    "sentence": "My picky dog loves this food and its nice small pieces for her to chew easily.",
    "sentence_rating": 4,
    "reviewed_by_user": "Nikki",
    "review_date": "2021-04-22T00:00:00",
    "retailer": "WALMART"
},
{
    "sentence": "Good for their coat n weight",
    "sentence_rating": 5,
    "reviewed_by_user": "Kathleen",
    "review_date": "2021-05-15T00:00:00",
    "retailer": "WALMART"
},
{
    "sentence": "The person that delivered this to my door was very professional also will be ordering againMy dog is very picky decided to try this one so far she loves it.",
    "sentence_rating": 5,
    "reviewed_by_user": "lnlywolf",
    "review_date": "2021-06-22T00:00:00",
    "retailer": "WALMART"
},
{
    "sentence": "It doesn't hurt that they love this flavor beyond reasoning.Dry sometimes is better for their teeth than always can.Now they have another flavor in a brand I trust.Pedigree for small dogs is great even for my tiniest of dogs.Also for tiny breeds what is considered \"for small breeds\" isn't always small enough.It's definitely better in how many choices there are for small dogs, however it is still limited.",
    "sentence_rating": 5,
    "reviewed_by_user": "Elaine",
    "review_date": "2021-05-18T00:00:00",
    "retailer": "WALMART"
},
{
    "sentence": "Shasha seems to like this dry food so far.",
    "sentence_rating": 5,
    "reviewed_by_user": "judy",
    "review_date": "2021-04-28T00:00:00",
    "retailer": "WALMART"
},
{
    "sentence": "Pkg well.Puppy likes this food.Quick ship.",
    "sentence_rating": 5,
    "reviewed_by_user": "painthorse54",
    "review_date": "2021-01-05T00:00:00",
    "retailer": "WALMART"
},
{
    "sentence": "Health has improved.Put some much needed weight on her.Easy for my older small to eat.",
    "sentence_rating": 5,
    "reviewed_by_user": "PJRH",
    "review_date": "2021-03-27T00:00:00",
    "retailer": "WALMART"
},
{
    "sentence": "I mix a little of each (canPerfect compliment to the Pedigree Pal canned food I feed my dogs.",
    "sentence_rating": 5,
    "reviewed_by_user": "Barbara",
    "review_date": "2020-12-19T00:00:00",
    "retailer": "WALMART"
},
{
    "sentence": "Hope it stays aroundMy two dogs LOVE this food, have never seem them eat their food like this one.",
    "sentence_rating": 5,
    "reviewed_by_user": "Tomuchtroubletofillout",
    "review_date": "2020-05-13T00:00:00",
    "retailer": "WALMART"
},
{
    "sentence": "my schnauzers love this alot especially because there small bits",
    "sentence_rating": 5,
    "reviewed_by_user": "Marlen",
    "review_date": "2020-04-03T00:00:00",
    "retailer": "WALMART"
},
{
    "sentence": "This food makes their coats dark and shiny.Both dogs seem to enjoy the taste of the food.They are both small-breed (Shih-Tzu and Miniature Poodle).This food is easy for the dogs to chew and swallow.",
    "sentence_rating": 5,
    "reviewed_by_user": "coffeebreath",
    "review_date": "2020-07-18T00:00:00",
    "retailer": "WALMART"
},
{
    "sentence": "My dogs are Chihuahua mixes and they love the small soft bites.",
    "sentence_rating": 5,
    "reviewed_by_user": "Darlene",
    "review_date": "2020-04-16T00:00:00",
    "retailer": "WALMART"
},
{
    "sentence": "I can say at this point that the Pedigree with Tender Bites for small dogs Chicken and Steak flavor has been deemed a winner.I have had this new kibble for a week and the bowl has been refilled 4 times.I feed the pouches half in the morning and half in the evening and have kibble down just in case.So when I saw this new flavor for small dogs I bought to check it out and Lord behold he loves it.I have always given Pedigree small breed grilled chicken dry kibble which at the end of a starvation period he will finally eat it.I have a picky eater that does seem to like chicken over all other flavors.",
    "sentence_rating": 5,
    "reviewed_by_user": "CharlieC1934",
    "review_date": "2020-09-08T00:00:00",
    "retailer": "WALMART"
},
{
    "sentence": "As soon as I introduced it to my very picky dog he loved this food more than his normal pedigree.However, they loves the tender bites more than the other bags.My dogs have always eaten this brand.",
    "sentence_rating": 5,
    "reviewed_by_user": "Abigail",
    "review_date": "2020-07-30T00:00:00",
    "retailer": "WALMART"
},
{
    "sentence": "The price was great ony budget right nowMy dog's went crazy for this food!!",
    "sentence_rating": 5,
    "reviewed_by_user": "lori",
    "review_date": "2020-12-24T00:00:00",
    "retailer": "WALMART"
},
] 

export const kpiData = [
  {
    title: "Basic KPIs",
    value: "Basic KPIs",
    key: "Basic KPIs",
    checkable: false,
    children: [
    {
      title: "$ share",
      value: "$ share",
      key: "$ share",
    },
    {
      title: "$ sales",
      value: "$ sales",
      key: "$ sales",
    },
    {
      title: "Unit Sales",
      value: "Unit Sales",
      key: "Unit Sales",
    },
  ]
  },
  {
    title: "Be Selected",
    value: "Be Selected",
    key: "Be Selected",
    checkable: false,
    children: [
      {
        title: "Conversion Rate",
        value: "Conversion Rate",
        key: "Conversion Rate",
      },
      {
        title: "Content Compliance Score",
        value: "Content Compliance Score",
        key: "Content Compliance Score",
      },
      {
        title: "Average Ratings",
        value: "Average Ratings",
        key: "Average Ratings",
      },
      {
        title: "Avg. # of Reviews",
        value: "Avg. # of Reviews",
        key: "Avg. # of Reviews",
      },
      {
        title: "Avg Sentiment",
        value: "Avg Sentiment",
        key: "Avg Sentiment",
      }
    ]
  },
]