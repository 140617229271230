import { createSlice } from '@reduxjs/toolkit';
import CustomAxios from '../utilities/services/api.service';
import { ENDPOINTS } from '../utilities/constants';
import { isSubBrand } from '../utilities/helper'

const initialState = {
    nudgesPerfData: {},
    nudgesVolData: {},
    nudgesDistData: {},
    nudgesInnData: {},
    nudgesDeepDivePerf: {},
    nudgesDeepDiveVol: {},
    nudgesDeepDiveDist: {},
    nudgesDeepDiveInn: {},
    isLoggedIn: false,
    // bookmarksPerf: {},
    // bookmarksVol: {},
    // bookmarksDist: {},
    loading: 0,
}

const nudgesSlicer = createSlice({
    name: 'nudges',
    initialState,
    reducers: {
        setLoading: (state, action) => ({ ...state, loading: state.loading + (action.payload ? 1 : -1) }),
        setNudgesPerfData: (state, action) => ({
            ...state,
            nudgesPerfData: action.payload
        }),
        setNudgesVolData: (state, action) => ({
            ...state,
            nudgesVolData: action.payload
        }),
        setNudgesDistData: (state, action) => ({
            ...state,
            nudgesDistData: action.payload
        }),
        setNudgesInnData: (state, action) => ({
            ...state,
            nudgesInnData: action.payload
        }),
        setNudgesDeepDivePerf: (state, action) => ({
            ...state,
            nudgesDeepDivePerf: action.payload
        }),
        setNudgesDeepDiveVol: (state, action) => ({
            ...state,
            nudgesDeepDiveVol: action.payload
        }),
        setNudgesDeepDiveDist: (state, action) => ({
            ...state,
            nudgesDeepDiveDist: action.payload
        }),
        setNudgesDeepDiveInn: (state, action) => ({
            ...state,
            nudgesDeepDiveInn: action.payload
        }),
        setBookmarkedNudges: (state, action) => ({
            ...state,
            bookmarkedNudges: action.payload,
        }),
        setLoggedIn: (state, action) => ({
            ...state,
            isLoggedIn: true
        })
    },
})

export const { setLoading, setNudgesPerfData, setNudgesVolData, setNudgesDistData, setNudgesInnData, setNudgesDeepDivePerf, setNudgesDeepDiveVol, setNudgesDeepDiveDist, setNudgesDeepDiveInn, setLoggedIn } = nudgesSlicer.actions

export const getData = () => async (dispatch, getState) => {
    let reports = ["PERF", "VOL", "DSTR", "INN"]
    let defaultBrand = getState().profile.defaultBrand
    let user_email = sessionStorage.getItem("email");

    reports.forEach(val => {
        dispatch(setLoading(true))
        let currentString = {
            report: [val],
            nudge_count: [4],
            nudge_count_view: [0],
            product_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[0] : defaultBrand,
            product_secondary_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[1] : "",
            user_email,
        }
        let encodedURL = encodeURIComponent(JSON.stringify(currentString))

        CustomAxios(ENDPOINTS.nudges + '?' + encodedURL, 'GET').then((response) => {
            if (response.data.nudges) {
                if (val === "PERF") {
                    dispatch(setNudgesPerfData(response.data))
                }
                else if (val === "VOL") {
                    dispatch(setNudgesVolData(response.data))
                }
                else if (val === "DSTR") {
                    dispatch(setNudgesDistData(response.data))
                }
                else if (val === "INN") {
                    dispatch(setNudgesInnData(response.data))
                }
            }
            else {
                if (val === "PERF") {
                    dispatch(setNudgesPerfData({}))
                }
                else if (val === "VOL") {
                    dispatch(setNudgesVolData({}))
                }
                else if (val === "DSTR") {
                    dispatch(setNudgesDistData({}))
                }
                else if (val === "INN") {
                    dispatch(setNudgesInnData({}))
                }
            }
            dispatch(setLoading(false))
        }).catch((err) => {
            console.log("Nudges Get Data error occured", err)
            dispatch(setLoading(false))
        })
    }
    )

}

export const nudgesDeepDive = (nudgePeriod) => async (dispatch, getState) => {
    let state = getState().nudges
    let reports = ["PERF", "VOL", "DSTR", "INN"]
    let defaultBrand = getState().profile.defaultBrand
    let user_email = sessionStorage.getItem("email");

    reports.forEach(val => {
        dispatch(setLoading(true))
        let currentString = {
            report: [val],
            nudge_count: [6],
            nudge_count_view: [0],
            product_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[0] : defaultBrand,
            product_secondary_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[1] : "",
            user_email,
            nudge_period: nudgePeriod && nudgePeriod[val],
        }
        let encodedURL = encodeURIComponent(JSON.stringify(currentString))

        CustomAxios(ENDPOINTS.nudgesDeepDive + '?' + encodedURL, 'GET').then((response) => {
            if (response.data.nudges) {
                if (val === "PERF") {
                    dispatch(setNudgesDeepDivePerf(response.data))
                }
                else if (val === "VOL") {
                    dispatch(setNudgesDeepDiveVol(response.data))
                }
                else if (val === "DSTR") {
                    dispatch(setNudgesDeepDiveDist(response.data))
                }
                else if (val === "INN") {
                    dispatch(setNudgesDeepDiveInn(response.data))
                }
            }
            else {
                if (val === "PERF") {
                    dispatch(setNudgesDeepDivePerf({}))
                }
                else if (val === "VOL") {
                    dispatch(setNudgesDeepDiveVol({}))
                }
                else if (val === "DSTR") {
                    dispatch(setNudgesDeepDiveDist({}))
                }
                else if (val === "INN") {
                    dispatch(setNudgesDeepDiveInn({}))
                }
            }
            dispatch(setLoading(false))
        }).catch((err) => {
            console.log("Nudges Deep Dive error occured", err)
            dispatch(setLoading(false))
        })
    }
    )

}


export const loadNudges = (report, load, nudge_period) => async (dispatch, getState) => {
    let state = getState().nudges
    let defaultBrand = getState().profile.defaultBrand
    let user_email = sessionStorage.getItem("email");
    dispatch(setLoading(true))
    let nudge_count_view = []
    if (report === "PERF") {
        nudge_count_view = [state.nudgesDeepDivePerf?.nudges.length]
    }
    else if (report === "VOL") {
        nudge_count_view = [state.nudgesDeepDiveVol?.nudges.length]
    }
    else if (report === "DSTR") {
        nudge_count_view = [state.nudgesDeepDiveDist?.nudges.length]
    }
    else if (report === "INN") {
        nudge_count_view = [state.nudgesDeepDiveInn?.nudges.length]
    }
    else {
        nudge_count_view = [6]
    }

    let currentString = {
        report: [report],
        nudge_count: [load],
        nudge_count_view: nudge_count_view,
        product_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[0] : defaultBrand,
        product_secondary_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[1] : "",
        user_email,
        nudge_period,
    }
    let encodedURL = encodeURIComponent(JSON.stringify(currentString))

    CustomAxios(ENDPOINTS.nudgesDeepDive + '?' + encodedURL, 'GET').then((response) => {
        if (response.data.nudges) {
            if (report === "PERF") {
                let existingNudges = [...state.nudgesDeepDivePerf.nudges]
                existingNudges.push(...response.data.nudges)
                let nudges = {
                    nudges: [...existingNudges],
                    remaining_nudges: response.data.remaining_nudges
                }
                dispatch(setNudgesDeepDivePerf(nudges))
            }
            else if (report === "VOL") {
                let existingNudges = [...state.nudgesDeepDiveVol.nudges]
                existingNudges.push(...response.data.nudges)
                let nudges = {
                    nudges: [...existingNudges],
                    remaining_nudges: response.data.remaining_nudges
                }
                dispatch(setNudgesDeepDiveVol(nudges))
            }
            else if (report === "DSTR") {
                let existingNudges = [...state.nudgesDeepDiveDist.nudges]
                existingNudges.push(...response.data.nudges)
                let nudges = {
                    nudges: [...existingNudges],
                    remaining_nudges: response.data.remaining_nudges
                }
                dispatch(setNudgesDeepDiveDist(nudges))
            }
            else if (report === "INN") {
                let existingNudges = [...state.nudgesDeepDiveInn.nudges]
                existingNudges.push(...response.data.nudges)
                let nudges = {
                    nudges: [...existingNudges],
                    remaining_nudges: response.data.remaining_nudges
                }
                dispatch(setNudgesDeepDiveInn(nudges))
            }
        }
        dispatch(setLoading(false))
    }).catch((err) => {
        console.log("Nudges Load error occured", err)
        dispatch(setLoading(false))
    })

}

export const isUserLoggedIn = () => (dispatch) => {

    dispatch(setLoggedIn())
}

export default nudgesSlicer.reducer