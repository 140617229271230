import { cloneDeep } from "lodash";
import { setDeclareAppliedFilters } from "../../utilities/helper";

export const wData = [
  {
    label: 'P9 YA',
    value: "1600",
  },
  {
    label: 'Market Contraction/Expansion',
    value: "1900",
  },
  {
    label: 'Category Non-Buyers',
    value: "1700",
  },
  {
    label: 'Shifting',
    value: "2700",
  },
  {
    label: 'P9 CY',
    value: "2700",
  }
];

export const hardcodedBrands = {
  "MARS BDB": "%7B%22image_type%22%3A%5B%22BR%22%5D%2C%22product_brand%22%3A%22ALL%20BRANDS%22%2C%22product_secondary_brand%22%3A%22%22%7D",
  NUTRO: "%7B%22image_type%22%3A%5B%22BR%22%5D%2C%22product_brand%22%3A%22NUTRO%22%2C%22product_secondary_brand%22%3A%22%22%7D",
  PEDIGREE: "%7B%22image_type%22%3A%5B%22BR%22%5D%2C%22product_brand%22%3A%22PEDIGREE%22%2C%22product_secondary_brand%22%3A%22%22%7D",
  CRAVE: "%7B%22image_type%22%3A%5B%22BR%22%5D%2C%22product_brand%22%3A%22CRAVE%22%2C%22product_secondary_brand%22%3A%22%22%7D",
  KARMA: "%7B%22image_type%22%3A%5B%22BR%22%5D%2C%22product_brand%22%3A%22KARMA%22%2C%22product_secondary_brand%22%3A%22%22%7D",
  CESAR: "%7B%22image_type%22%3A%5B%22BR%22%5D%2C%22product_brand%22%3A%22CESAR%22%2C%22product_secondary_brand%22%3A%22%22%7D",
  IAMS: "%7B%22image_type%22%3A%5B%22BR%22%5D%2C%22product_brand%22%3A%22IAMS%22%2C%22product_secondary_brand%22%3A%22%22%7D",
};

export const getHardcodedChannelRetailerData = () => ({
  "TOTAL": {
    "dollar_sum_latest_4w": 742951447.9887863,
    "dollar_sum_latest_13w": 2384315879.0800114,
    "dollar_sum_latest_52w": 9469316420.59788,
    "dollar_sum_latest_ytd": 8007703410.937067,
    "dollar_variance_latest_4w": 4.907890073597879,
    "dollar_variance_latest_13w": 6.697575103198476,
    "dollar_variance_latest_52w": 13.623970524286275,
    "dollar_variance_latest_ytd": 12.486569453785153
  },
  "MARS BDB": {
    "dollar_sum_latest_4w": 169089625.17237023,
    "dollar_sum_latest_13w": 547997878.0089326,
    "dollar_sum_latest_52w": 2250295672.8981333,
    "dollar_sum_latest_ytd": 1904727526.6398942,
    "dollar_share_latest_4w": 22.759175667549457,
    "dollar_share_latest_13w": 22.983442874204137,
    "dollar_share_latest_52w": 23.764077288654516,
    "dollar_share_latest_ytd": 23.78618973373043,
    "dollar_variance_latest_4w": 1.8074317738242462,
    "dollar_variance_latest_13w": 4.588896179929743,
    "dollar_variance_latest_52w": 16.782849990407396,
    "dollar_variance_latest_ytd": 14.879822617593508,
    "dollar_share_variance_latest_4w": -0.6931112381974707,
    "dollar_share_variance_latest_13w": -0.4633828574843051,
    "dollar_share_variance_latest_52w": 0.642798628262728,
    "dollar_share_variance_latest_ytd": 0.4955297852843117
  },
  "NUTRO": {
    "dollar_sum_latest_4w": 252863356.95749173,
    "dollar_sum_latest_13w": 809428864.0880426,
    "dollar_sum_latest_52w": 3239123833.631935,
    "dollar_sum_latest_ytd": 2732172498.9418635,
    "dollar_share_latest_4w": 34.03497733829147,
    "dollar_share_latest_13w": 33.948054919651035,
    "dollar_share_latest_52w": 34.206522305940865,
    "dollar_share_latest_ytd": 34.11930186138278,
    "dollar_variance_latest_4w": 3.776903759180682,
    "dollar_variance_latest_13w": 4.861648356211193,
    "dollar_variance_latest_52w": 13.81391793205724,
    "dollar_variance_latest_ytd": 12.093290553359163,
    "dollar_share_variance_latest_4w": -0.3709215845409233,
    "dollar_share_variance_latest_13w": -0.5943654616553502,
    "dollar_share_variance_latest_52w": 0.05708827495730162,
    "dollar_share_variance_latest_ytd": -0.11970744594174221
  },
  "PEDIGREE": {
    "dollar_sum_latest_4w": 252863356.95749173,
    "dollar_sum_latest_13w": 809428864.0880426,
    "dollar_sum_latest_52w": 3239123833.631935,
    "dollar_sum_latest_ytd": 2732172498.9418635,
    "dollar_share_latest_4w": 34.03497733829147,
    "dollar_share_latest_13w": 33.948054919651035,
    "dollar_share_latest_52w": 34.206522305940865,
    "dollar_share_latest_ytd": 34.11930186138278,
    "dollar_variance_latest_4w": 3.776903759180682,
    "dollar_variance_latest_13w": 4.861648356211193,
    "dollar_variance_latest_52w": 13.81391793205724,
    "dollar_variance_latest_ytd": 12.093290553359163,
    "dollar_share_variance_latest_4w": -0.3709215845409233,
    "dollar_share_variance_latest_13w": -0.5943654616553502,
    "dollar_share_variance_latest_52w": 0.05708827495730162,
    "dollar_share_variance_latest_ytd": -0.11970744594174221
  },
  "CESAR": {
    "dollar_sum_latest_4w": 30851628.49565358,
    "dollar_sum_latest_13w": 99342585.25428157,
    "dollar_sum_latest_52w": 399661682.9682385,
    "dollar_sum_latest_ytd": 338157511.25464845,
    "dollar_share_latest_4w": 4.152576669602135,
    "dollar_share_latest_13w": 4.166502690600414,
    "dollar_share_latest_52w": 4.220596980990994,
    "dollar_share_latest_ytd": 4.22290254647527,
    "dollar_variance_latest_4w": 3.723686016118925,
    "dollar_variance_latest_13w": 2.0974223191898016,
    "dollar_variance_latest_52w": 4.0672767069487765,
    "dollar_variance_latest_ytd": 4.557377311494794,
    "dollar_share_variance_latest_4w": -0.04740959688196078,
    "dollar_share_variance_latest_13w": -0.18772803971312957,
    "dollar_share_variance_latest_52w": -0.38758536160499535,
    "dollar_share_variance_latest_ytd": -0.32024718437049415
  },
  "IAMS": {
    "dollar_sum_latest_4w": 297.0768150364,
    "dollar_sum_latest_13w": 887.5768559986,
    "dollar_sum_latest_52w": 3193.5438785731,
    "dollar_sum_latest_ytd": 2726.1793198657,
    "dollar_share_latest_4w": 3.9986033520845076e-05,
    "dollar_share_latest_13w": 3.722564043574091e-05,
    "dollar_share_latest_52w": 3.372517863725019e-05,
    "dollar_share_latest_ytd": 3.4044459190911526e-05,
    "dollar_variance_latest_4w": 139.3224982733007,
    "dollar_variance_latest_13w": 126.15802293586287,
    "dollar_variance_latest_52w": 84.39159987927931,
    "dollar_variance_latest_ytd": 76.55924971693302,
    "dollar_share_variance_latest_4w": 2.245800987346699e-05,
    "dollar_share_variance_latest_13w": 1.9663205486070645e-05,
    "dollar_share_variance_latest_52w": 1.2943382145902441e-05,
    "dollar_share_variance_latest_ytd": 1.235460476847427e-05
  },
  "CRAVE": {
    "dollar_sum_latest_4w": 383600.3449807601,
    "dollar_sum_latest_13w": 1254691.9990608722,
    "dollar_sum_latest_52w": 8873968.658665905,
    "dollar_sum_latest_ytd": 7054454.446027951,
    "dollar_share_latest_4w": 0.05163195334219875,
    "dollar_share_latest_13w": 0.052622725456368445,
    "dollar_share_latest_52w": 0.09371287497968744,
    "dollar_share_latest_ytd": 0.08809585075781964,
    "dollar_variance_latest_4w": -52.75027083645184,
    "dollar_variance_latest_13w": -52.190111479523516,
    "dollar_variance_latest_52w": -22.364180637204285,
    "dollar_variance_latest_ytd": -23.91381626571385,
    "dollar_share_variance_latest_4w": -0.06300572567517167,
    "dollar_share_variance_latest_13w": -0.06481568269032995,
    "dollar_share_variance_latest_52w": -0.04344068418711167,
    "dollar_share_variance_latest_ytd": -0.04214592966143249
  },
  "KARMA": {
    "dollar_sum_latest_4w": 54524149.54464393,
    "dollar_sum_latest_13w": 174319978.48637685,
    "dollar_sum_latest_52w": 674508327.65441,
    "dollar_sum_latest_ytd": 566494764.346779,
    "dollar_share_latest_4w": 7.33885769955009,
    "dollar_share_latest_13w": 7.311110915120787,
    "dollar_share_latest_52w": 7.123094188585817,
    "dollar_share_latest_ytd": 7.074372454567312,
    "dollar_variance_latest_4w": 1.9478291395552207,
    "dollar_variance_latest_13w": 5.696003317016799,
    "dollar_variance_latest_52w": 6.634150967679547,
    "dollar_variance_latest_ytd": 5.211256572146427,
    "dollar_share_variance_latest_4w": -0.2130841447069889,
    "dollar_share_variance_latest_13w": -0.06927984207943005,
    "dollar_share_variance_latest_52w": -0.4669155482657681,
    "dollar_share_variance_latest_ytd": -0.48918979513309413
  }
});

// Set the filters applied based on the filters selected on the UI while clicking on Apply button (Special for Conversion usecase)
export const declareAppliedFilters = (inputFilter, filtersData) => {
  const { techs, subTechs, packSize } = filtersData;
  let finalFilter = cloneDeep(inputFilter);


  if (
    finalFilter["product_pet"].length === 0
  )
    finalFilter["product_pet"] = ["ALL"];
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_technology", techs);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_sub_technology", subTechs);
  if (finalFilter.product_package && packSize) finalFilter = setDeclareAppliedFilters(finalFilter, "product_package", packSize);

  return finalFilter;
};