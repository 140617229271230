import React, { useEffect, useState } from "react";
import './style.scss';
import { Row, Col, Button, Divider, Select, Modal, Input, Form, Radio } from "antd";
import { useSelector } from "react-redux";
import NudgesList from "../nudges-list";
import VelocityChart from "../velocity-chart";
import UserCommnet from "../user-comment";
import CommentBox from "../comment-box";
import { loadNudges } from "../../slicers/nudges.slicer";
import { useDispatch } from "react-redux";
import { loadBookmarks } from "../../slicers/bookmarks.slicer";
import { onScrollStop } from "../../utilities/helper";

const { Option } = Select;

export default function AlertsTab(props) {
  const { thisPeriod, lastPeriod } = useSelector((state) => state.refreshDate);
	const [selectedNudgeId, setSelectedNudgeId] = useState(props.nudge_id);
	const [selectedNudge, setSelectedNudge] = useState({});
	const dispatch = useDispatch();
	const [chartData, setChartData] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);

	const layout = {
		labelCol: { span: 4 },
		wrapperCol: { span: 20 },
	};

	useEffect(() => {
		if (props.data && props.data.length > 0 && (!selectedNudgeId || props.data.filter(val => val.nudge_id === selectedNudgeId).length === 0)) setSelectedNudgeId(props.data[0].nudge_id);

		if (props.data && props.data.length > 0) setSelectedNudge(props.data[0]); else setSelectedNudge({});
		props.data && props.data.filter(val => val.nudge_id === selectedNudgeId) ? setChartData(props.data.filter(val => val.nudge_id === selectedNudgeId)[0]) : setChartData(null);

		// const nudgeListElement = document.getElementById("nudges-list");
		// if (nudgeListElement) nudgeListElement.scrollTop = nudgeListElement.scrollHeight;
	}, [props.data])

	useEffect(() => {
		props.data && selectedNudgeId && props.data.filter(val => val.nudge_id === selectedNudgeId) ? setChartData(props.data.filter(val => val.nudge_id === selectedNudgeId)[0]) : setChartData(null)
	}, [selectedNudgeId])

	const showModal = (report) => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleScroll = (e) => {
		// if (!e.target) return;
		// if (e.target.scrollTop && e.target.scrollTop !== props.nudgeScrollTop[props.loadReport])
		//   props.setNudgeScrollTop({ ...props.nudgeScrollTop, [props.loadReport]: e.target.scrollTop });
	};

	return (
		<div className="performance-wrap">
			<Row gutter={16} style={{ justifyContent: "center" }}>
				<> <Col span={12}>
					<div className="performance-left">
						{/* <h2 className="p-title">This Period</h2> */}
						<Radio.Group size="large" style={{ marginBottom: 8 }} onChange={(e) => { props.setNudgePeriod({ ...props.nudgePeriod, [props.loadReport]: e.target.value }); }} value={Number(props.nudgePeriod[props.loadReport])}>
							<Radio.Button value={1}>{thisPeriod}</Radio.Button>
							<Radio.Button value={0}>{lastPeriod}</Radio.Button>
						</Radio.Group>
						{props.data && props.data.length !== 0 ?
							<div id="nudges-list" className="nudges-list-wrap" onScroll={(e) => onScrollStop(e, handleScroll)}>
								{props.data && props.data.map((val) => (
									<NudgesList title={val.nudge_description} selected={selectedNudgeId === val.nudge_id} onClick={() => { setSelectedNudgeId(val.nudge_id); setSelectedNudge(val); }} nudgeTrend={val.nudge_trend} />))}
							</div>
							:
							props.profile ?
								<div className="no-data">
									No bookmarked alerts
								</div>
								:
								<div className="no-data">
									No alerts
								</div>}
						{props.remainingNudges > 0 && <div style={{ textAlign: "center", marginTop: 8 }}>
							<Button onClick={() => { props.profile ? dispatch(loadBookmarks(props.loadReport, 4, props.nudgePeriod[props.loadReport])) : dispatch(loadNudges(props.loadReport, 4, props.nudgePeriod[props.loadReport])) }}>Load More</Button>
						</div>}
					</div>
				</Col>
					<Col span={12}>
						{props.data && props.data.length !== 0 && selectedNudgeId && <VelocityChart tab={props.tab} id={selectedNudgeId} scroll={props.scroll} title={chartData && chartData.nudge_kpi} data={chartData} tableData={[]} hideBookMark={props.hideBookMark} loadData={props.loadData} selectedNudge={selectedNudge} />}
						{props.data && props.data.length !== 0 && props.messages.filter((val) => val.nudge_id === selectedNudgeId).length !== 0 && <div className="messages-area">
							{props.messages.filter((val) => val.nudge_id === selectedNudgeId).map((val2) => (
								<>
									<UserCommnet message={val2} />
									<Divider style={{ margin: '16px 0', borderColor: '#E6E6E6' }} />
								</>
							))
							}
						</div>}
						{props.data && props.data.length !== 0 && <CommentBox sendMsg={props.sendMsg} id={selectedNudgeId} />}
					</Col></>
			</Row>
			<Modal title="Share Nudge" visible={isModalVisible} footer={[
				<Button key="back" onClick={handleOk}>
					Confirm
				</Button>,
				<Button key="submit" type="primary" onClick={handleCancel}>
					Cancel
				</Button>
			]}>
				<Form {...layout} name="share">
					<Form.Item name={['user', 'email']} label="Email" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item name={['user', 'Subject']} label="Subject" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item name={['user', 'message']} label="Message">
						<Input.TextArea />
					</Form.Item>
				</Form>
			</Modal>

		</div >
	);
}