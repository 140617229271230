import React from "react";
import { Col, Row, Select, TreeSelect } from "antd";

const { Option } = Select;
const filterDropDown = ({ filters, compareVia, compareViaOnChange, compititionOnLeave, compareViaList, trendCompetitionBenchmarks, SHOW_PARENT, compititionOnChanege, isAllBrands, brandOnchange, trendBrands, subBrandOnChange, trendSubBrands, kpiData, kpi1OnChange, kpi2OnChange }) => {

  return (
    <Row className="alldropdown">
      <div className="comparision-dropdown">
        <Col>
          <label className="kpi-labels">Compare Via</label>
          <Select
            placeholder="Please select"
            style={{ width: 150 }}
            dropdownMatchSelectWidth={false}
            maxTagCount="responsive"
            value={filters?.trend_compare_via && filters?.trend_compare_via[0]}
            onChange={compareViaOnChange}
          >
            {compareViaList && compareViaList.map((compare, index) => (
              <Option key={index} value={compareVia[compare]}>{compareVia[compare]}</Option>
            ))}
          </Select>
        </Col>
        {filters?.trend_compare_via && filters.trend_compare_via[0] === compareVia.manufacturer && (
          <Col>
            <label className="kpi-labels">Select Manufacturer</label>
            <TreeSelect
              style={{ width: 150 }}
              dropdownStyle={{
                maxHeight: "400px",
                overflow: "hidden",
                maxWidth: 350,
              }}
              showSearch
              treeData={trendCompetitionBenchmarks && trendCompetitionBenchmarks}
              treeCheckable={false}
              multiple={false}
              treeDefaultExpandAll
              showCheckedStrategy={SHOW_PARENT}
              placeholder="Please select"
              dropdownMatchSelectWidth={false}
              maxTagCount="responsive"
              allowClear
              autoClearSearchValue={false}
              value={
                filters?.trend_compare_deep_dive_list?.length
                  ? filters.trend_compare_deep_dive_list[0]
                  : null
              }
              onChange={compititionOnChanege}
              onMouseLeave={compititionOnLeave}
              filterTreeNode={(search, item) => {
                return (
                  item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
                );
              }}
            />
          </Col>
        )}
        {!isAllBrands && filters?.trend_compare_via && filters.trend_compare_via[0] === compareVia.brand && (
          <Col>
            <label className="kpi-labels">Select Brand</label>
            <TreeSelect
              style={{ width: 150 }}
              dropdownStyle={{
                maxHeight: "400px",
                overflow: "hidden",
                maxWidth: 350,
              }}
              showSearch
              treeData={trendCompetitionBenchmarks && trendCompetitionBenchmarks}
              treeCheckable={false}
              multiple={false}
              treeDefaultExpandAll
              showCheckedStrategy={SHOW_PARENT}
              placeholder="Please select"
              dropdownMatchSelectWidth={false}
              maxTagCount="responsive"
              allowClear
              autoClearSearchValue={false}
              value={
                filters?.trend_compare_deep_dive_list?.length
                  ? filters.trend_compare_deep_dive_list[0]
                  : null
              }
              onChange={compititionOnChanege}
              filterTreeNode={(search, item) => {
                return (
                  item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
                );
              }}
            />
          </Col>
        )}
        {isAllBrands && filters?.trend_compare_via && filters.trend_compare_via[0] === compareVia.brand && (
          <Col>
            <label className="kpi-labels">Select Brand</label>
            <Select
              placeholder="Please select"
              style={{ width: 150 }}
              dropdownMatchSelectWidth={false}
              maxTagCount="responsive"
              showSearch
              value={
                filters?.trend_compare_deep_dive_list?.length
                  ? filters.trend_compare_deep_dive_list[0]
                  : null
              }
              onChange={brandOnchange}
            >
              {trendBrands &&
                trendBrands.map((val) => <Option value={val}>{val}</Option>)}
            </Select>
          </Col>
        )}
        {filters?.trend_compare_via && filters.trend_compare_via[0] === compareVia.subBrand && (
          <Col>
            <label className="kpi-labels">Select Sub Brand</label>
            <Select
              placeholder="Please select"
              style={{ width: 150 }}
              dropdownMatchSelectWidth={false}
              maxTagCount="responsive"
              showSearch
              value={
                filters?.trend_compare_deep_dive_list?.length
                  ? filters.trend_compare_deep_dive_list[0]
                  : null
              }
              onChange={subBrandOnChange}
            >
              {trendSubBrands &&
                trendSubBrands.map((val) => <Option value={val}>{val}</Option>)}
            </Select>
          </Col>
        )}
      </div>
      <div className="other-dropdown">
        <Col>
          <label className="kpi-labels">KPI 1</label>
          <TreeSelect
            style={{ width: 200 }}
            dropdownStyle={{
              maxHeight: "400px",
              overflow: "hidden",
              maxWidth: 400,
            }}
            treeData={kpiData?.length && kpiData.map((category) => {
              return {
                ...category,
                children: category.children.filter((item) => item.title !== filters?.kpi2[0]),
              };
            })}
            value={filters?.kpi1}
            showCheckedStrategy={SHOW_PARENT}
            onChange={kpi1OnChange}
            placeholder="Please select"
            dropdownMatchSelectWidth={false}
            maxTagCount="responsive"
            allowClear={false}
            treeDefaultExpandAll
          />
        </Col>
        <Col>
          <label className="kpi-labels">KPI 2</label>
          <TreeSelect
            style={{ width: 200 }}
            dropdownStyle={{
              maxHeight: "400px",
              overflow: "hidden",
              maxWidth: 400,
            }}
            treeData={kpiData?.length && kpiData.map((category) => {
              return {
                ...category,
                children: category.children.filter((item) => item.title !== filters?.kpi1[0]),
              };
            })}
            value={filters?.kpi2}
            showCheckedStrategy={SHOW_PARENT}
            onChange={kpi2OnChange}
            placeholder="Please select"
            dropdownMatchSelectWidth={false}
            maxTagCount="responsive"
            allowClear={false}
            treeDefaultExpandAll
          />
        </Col>
      </div>
    </Row>
  );
};
export default filterDropDown;
