import { createSlice } from "@reduxjs/toolkit";

const initialState = { scatterTable: [] };

const scatterSlicer = createSlice({
  name: "scatter",
  initialState,
  reducers: {
    setScatterTable: (state, action) => ({
      ...state,
      scatterTable: action.payload,
    }),
  },
});

export const { setScatterTable } = scatterSlicer.actions;
export default scatterSlicer.reducer;
