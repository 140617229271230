import React from "react";
import {
  CaretUpFilled,
  CaretDownFilled,
  PlusSquareOutlined,
  MinusSquareOutlined,
} from "@ant-design/icons";
import { formatNumberExcel, numFmt } from "../../utilities/exports/excel";
import {
  formatNumber,
  getOrdinalIndicator,
  hasValue,
  valueTypes,
} from "../../utilities/helper";
import { Tooltip } from "antd";
import "./be-visible.scss";

// render for UI
const renderFunction = (val, rec) => {
  let isZero = true;
  let dec = 1;

  if (val) {
    if (
      formatNumber(val.abs, 1, true) === valueTypes.NA &&
      formatNumber(val.abs, 2, true) !== valueTypes.NA
    ) {
      isZero = false;
      dec = 2;
    }
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs, dec)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance, dec)}
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance, dec)}
          </div>
        )}
      </div>
    );
};

const renderPlaneFunction = (val, rec) => {
  let isZero = true;
  let dec = 1;

  if (val) {
    if (
      formatNumber(val.abs, 1, true) === valueTypes.NA &&
      formatNumber(val.abs, 2, true) !== valueTypes.NA
    ) {
      isZero = false;
      dec = 2;
    }
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{val.abs}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {val.variance}
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {-1 * val.variance}
          </div>
        )}
      </div>
    );
};

const renderPointsFunction = (val, rec) => {
  let isZero = true;
  let dec = 1;

  if (val) {
    if (
      formatNumber(val.abs, 1, true) === valueTypes.NA &&
      formatNumber(val.abs, 2, true) !== valueTypes.NA
    ) {
      isZero = false;
      dec = 2;
    }
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs, dec)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance, dec)}pts
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance, dec)}pts
          </div>
        )}
      </div>
    );
};

const renderDollarFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">${formatNumber(val.abs)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}
          </div>
        )}
      </div>
    );
};

const renderPercentageFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs)}%</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}
          </div>
        )}
      </div>
    );
};

const renderPercentagePointsFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }
  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs)}%</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}pts
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}pts
          </div>
        )}
      </div>
    );
};

const renderOrdinalFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (val.abs !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance)) !== valueTypes.nil) isZero = false;
  }

  if (!hasValue(val)) return "";
  if (isZero) return val.abs;
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{val.abs}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance, 0)}
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance, 0)}
          </div>
        )}
      </div>
    );
};

const renderAbsVarPercentageFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs)}%</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}%
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}%
          </div>
        )}
      </div>
    );
};
const renderAbsPercentageFunction = (val, rec) => {
  let isZero = true;

  if (val) {
    if (formatNumber(val.abs, 1, true) !== valueTypes.NA) isZero = false;
    if (Number(Number(val.variance).toFixed(1)) !== valueTypes.nil)
      isZero = false;
  }

  if (!hasValue(val)) return "";
  if (isZero) return "NA";
  else
    return (
      <div className="ant-table-kpi">
        <div className="ant-table-abs">{formatNumber(val.abs)}</div>
        {Number(val.variance) >= 0 ? (
          <div className="ant-table-variance">
            <CaretUpFilled style={{ color: "#00c853" }} />
            {formatNumber(val.variance)}%
          </div>
        ) : (
          <div className="ant-table-variance">
            <CaretDownFilled style={{ color: "#ec3225" }} />
            {formatNumber(-1 * val.variance)}%
          </div>
        )}
      </div>
    );
};
const renderNumberPercentagePPT = (val) => {
  return `${formatNumber(val && val.abs)}\n${formatNumber(val && val.variance)}%`
}
const renderPercentagePointsPPT = (val) => {
  return `${formatNumber(val && val.abs)}%\n${formatNumber(val && val.variance)}pts`
}
const renderNumberPointsPPT = (val) => {
  return `${formatNumber(val && val.abs, 1)}\n${formatNumber(val && val.variance)}pts`
}
const renderNumberPPT = (val) => {
  return `${val && val.abs}\n${val && val.variance}`
}
const renderTextPPT = (val) => {
  return val && val
}
const renderPPTFunction = (val, rec) => formatNumber(val, 1);

const macroCols = (open, setOpen) => [
  {
    title: "",
    children: [
      {
        title: "",
        children: [
          {
            title: "Category",
            dataIndex: "table_key",
            key: "table_key",
            width: 150,
          },
        ],
      },
    ],
  },
  {
    title: "Sales",
    children: [
      {
        title: "$ Sales",
      },
      {
        title: "$ Share",
      },
    ],
  },
  {
    className: "has-ant-table-columm-expand",
    title: (
      <>
        <div className="ant-table-columm-expand">
          <div> Share of Shelf </div>
          <div style={{ margin: -4, marginLeft: 8 }}>
            <a
              onClick={(e) => {
                e.stopPropagation();
                setOpen(!open);
              }}
            >
              <div
                style={{ marginLeft: "2px", color: "white", marginTop: "5px" }}
              >
                {!open ? <PlusSquareOutlined /> : <MinusSquareOutlined />}
              </div>
            </a>
          </div>
        </div>
      </>
    ),
    showSorterTooltip: false,
    children: [
      {
        title: "Share of Shelf",
      },
      {
        title: "Fair Share Index",
      },
      {
        title: "Organic Share of Shelf",
        extra: true,
      },
      {
        title: "Organic Fair Share Index",
        extra: true,
      },
      {
        title: "Paid Share of Shelf",
        extra: true,
      },
      {
        title: "Paid Fair Share Index",
        extra: true,
      },
    ],
  },
  {
    title: "Placement",
    children: [
      {
        title: "No of Placements",
      },
      {
        title: "Average Placement",
      },
    ],
  },
];

// macro ppt export columns
const macroColsPPtExport = [
  {
    title: "",
    children: [
      {
        title: "",
        children: [
          {
            title: "Category",
            dataIndex: "table_key",
            key: "table_key",
            width: 150,
          },
        ],
      },
    ],
  },
  {
    title: "Sales",
    children: [
      {
        title: "$ Sales",
        dataIndex: "sales",
      },
      {
        title: "$ Share",
        dataIndex: "sales_share",
      },
    ],
  },
  {
    title: "Share of Shelf",
    children: [
      {
        title: "Share of Shelf",
        dataIndex: "total_share_of_shelf",
      },
      {
        title: "Fair Share Index",
        dataIndex: "fair_share_of_index",
      },
      {
        title: "Organic Share of Shelf",
        dataIndex: "organic_share_of_shelf",
      },
      {
        title: "Organic Fair Share Index",
        dataIndex: "organic_fair_share_of_index",
      },
      {
        title: "Paid Share of Shelf",
        dataIndex: "paid_share_of_shelf",
      },
      {
        title: "Paid Fair Share Index",
        dataIndex: "paid_fair_share_of_index",
      },
    ],
  },
  {
    title: "Placement",
    children: [
      {
        title: "No of Placements",
        dataIndex: "number_of_placements",
      },
      {
        title: "Average Placement",
        dataIndex: "average_placements",
      },
    ],
  },
];
//micro ppt export columns
const microColsPPTExport = [
  {
    title: "",
    children: [
      {
        title: "",
        children: [
          {
            title: "Keywords",
            dataIndex: "product_keyword",
            key: "product_keyword",
            width: 150,
          },
        ],
      },
    ],
  },
  {
    title: "",
    children: [
      {
        title: "",
        children: [
          {
            title: "Keyword Type",
            dataIndex: "product_keyword_type",
            key: "product_keyword_type",
            width: 100,
            className: "td-border-left",
          },
        ],
      },
    ],
  },
  {
    title: "",
    children: [
      {
        title: "Keyword Traffic %",
        className: "td-border-left",
      },
    ],
  },
  {
    title: "Sales",
    children: [
      {
        title: "$ Sales",
        className: "td-border-left",
      },
      {
        title: "$ Share",
      },
    ],
  },
  {
    title: "Share of Shelf",
    children: [
      {
        title: "Share of Shelf",
        className: "td-border-left",
      },
      {
        title: "Fair Share Index",
      },
      {
        title: "Organic Share of Shelf",
      },
      {
        title: "Organic Fair Share Index",
      },
      {
        title: "Paid Share of Shelf",
      },
      {
        title: "Paid Fair Share Index",
      },
    ],
  },
  {
    title: "Others",
    children: [
      {
        title: "Average Placement",
      },
    ],
  },
];
const macroColsExport = () => [
  {
    title: "Category",
    dataIndex: "table_key",
    key: "table_key",
  }
]
const microColsExport = () => [
  {
    title: "Keywords",
    dataIndex: "product_keyword",
    key: "product_keyword",
  },
  {
    title: "Keyword Type",
    dataIndex: "product_keyword_type",
    key: "product_keyword_type",
  }
]
const microCols = (open, setOpen) => [
  {
    title: "",
    children: [
      {
        title: "",
        children: [
          {
            title: "Keywords",
            dataIndex: "product_keyword",
            key: "product_keyword",
            width: 150,
          },
        ],
      },
    ],
  },
  {
    title: "",
    children: [
      {
        title: "",
        children: [
          {
            title: "Keyword Type",
            dataIndex: "product_keyword_type",
            key: "product_keyword_type",
            width: 100,
            className: "td-border-left",
          },
        ],
      },
    ],
  },
  {
    title: "",
    children: [
      {
        title: "Keyword Traffic %",
        className: "td-border-left",
      },
    ],
  },
  {
    title: "Sales",
    children: [
      {
        title: "$ Sales",
        className: "td-border-left",
      },
      {
        title: "$ Share",
      },
    ],
  },
  {
    className: "has-ant-table-columm-expand",
    title: (
      <>
        <div className="ant-table-columm-expand">
          <div> Share of Shelf </div>
          <div style={{ margin: -4, marginLeft: 8 }}>
            <a
              onClick={(e) => {
                e.stopPropagation();
                setOpen(!open);
              }}
            >
              <div
                style={{ marginLeft: "2px", color: "white", marginTop: "5px" }}
              >
                {!open ? <PlusSquareOutlined /> : <MinusSquareOutlined />}
              </div>
            </a>
          </div>
        </div>
      </>
    ),
    showSorterTooltip: false,
    children: [
      {
        title: "Share of Shelf",
        className: "td-border-left",
      },
      {
        title: "Fair Share Index",
      },
      {
        title: "Organic Share of Shelf",
        extra: true,
      },
      {
        title: "Organic Fair Share Index",
        extra: true,
      },
      {
        title: "Paid Share of Shelf",
        extra: true,
      },
      {
        title: "Paid Fair Share Index",
        extra: true,
      },
    ],
  },
  {
    title: "Others",
    children: [
      {
        title: "Average Placement",
      },
    ],
  },
];

const ShareCompititionData = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    // width: '10%',
    fixed: "left",
  },
  {
    title: "$ Share",
    children: [
      {
        title: "Total ecom",
        dataIndex: "dollar_share_total_ecom",
        key: "dollar_share_total_ecom",
        render: renderPercentageFunction,
        // width: "6%",
        className: "td-border-left",
      },
      {
        title: "Amazon",
        dataIndex: "dollar_share_retailer",
        key: "dollar_share_retailer",
        render: renderPercentageFunction,
        // width: "6%",
      },
    ],
  },
  {
    title: "Traffic",
    children: [
      {
        title: "Total ecom",
        dataIndex: "dollar_sales_total_ecom",
        key: "dollar_sales_total_ecom",
        // width: "6%",
        render: renderFunction,
        className: "td-border-left",
      },
      {
        title: "Amazon",
        dataIndex: "dollar_sales_retailer",
        key: "dollar_sales_retailer",
        render: renderFunction,
        // width: "6%",
      },
    ],
  },
  {
    title: "Traffic Share",
    children: [
      {
        title: "Total ecom",
        dataIndex: "in_stock_total_ecom",
        key: "in_stock_total_ecom",
        // width: "6%",
        render: renderPercentageFunction,
        className: "td-border-left",
      },
      {
        title: "Amazon",
        dataIndex: "in_stock_retailer",
        key: "in_stock_retailer",
        render: renderPercentageFunction,
        // width: "6%",
      },
    ],
  },
  {
    title: "Share of Shelf",
    children: [
      {
        title: "Total ecom",
        dataIndex: "number_items_total_ecom",
        key: "number_items_total_ecom",
        render: renderFunction,
        // width: "6%",
        className: "td-border-left",
      },
      {
        title: "Amazon",
        dataIndex: "number_items_retailer",
        key: "number_items_retailer",
        render: renderFunction,
        // width: "6%",
      },
    ],
  },
];

const baCompetitionCols = [
  {
    title: "",
    children: [
      {
        title: "Title",
        dataIndex: "table_key",
        dataIndexPPT: "table_key",
        key: "table_key",
        width: 200,
        // fixed: 'left',
      }
    ]
  }
];

const baCompetitionColsExport = [
  {
    title: "Title",
    dataIndex: "table_key",
    key: "table_key",
    width: 200,
    // fixed: 'left',
  },
];
export default {
  macroCols,
  microCols,
  macroColsPPtExport,
  microColsPPTExport,
  macroColsExport,
  microColsExport,
  baCompetitionCols,
  baCompetitionColsExport,
  ShareCompititionData,
  renderFunction,
  renderDollarFunction,
  renderPercentageFunction,
  renderAbsPercentageFunction,
  renderAbsVarPercentageFunction,
  renderOrdinalFunction,
  renderPPTFunction,
  renderPointsFunction,
  renderPercentagePointsFunction,
  renderPlaneFunction,
  renderNumberPercentagePPT,
  renderPercentagePointsPPT,
  renderNumberPointsPPT,
  renderNumberPPT,
  renderTextPPT
};
