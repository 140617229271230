import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  CaretUpFilled,
  CaretDownFilled,
  MoreOutlined,
} from "@ant-design/icons";
import { DualAxes, Line, Column } from "@ant-design/plots";
import { isEqual, max, maxBy, minBy, uniq } from "lodash";
import columns from "./baColumns";
import { Dropdown, Menu, Tooltip } from "antd";
import {
  addSymbol,
  formatNumber,
  hasValue,
  valueTypes,
  itemsListed,
} from "../../utilities/helper";
import { setExportData } from "../../slicers/exportData.slicer";
import { cloneDeep } from "lodash";
import { setDeclareAppliedFilters } from "../../utilities/helper";


// Set the filters applied based on the filters selected on the UI while clicking on Apply button (Special for Conversion usecase)
export const declareAppliedFilters = (inputFilter, filtersData) => {
  const { techs, feedingPhilosophies, textures } = filtersData;
  let finalFilter = cloneDeep(inputFilter);
  if (
    finalFilter["product_pet"].length === 0
  )
    finalFilter["product_pet"] = ["ALL"];
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_technology", techs);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_feed_philosophy", feedingPhilosophies);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_texture", textures);

  return finalFilter;
};
// Temp: for Be Selecte
export const rowKeyPerfOverview = "index";

export const rowKeyMacro = "index";
export const rowKeyMicro = "micro_table_key";
export const rowKeyCompetition = "index";
export const rowKeyCompetitionBenchmark = "table_key";
export const viewByOpportunity = ["Brand", "UPC"];
export const viewByOpportunitySub = ["Sub Brand", "UPC"];

export const viewBy = {
  R: "Retailer",
  S: "Sub Brand",
  C: "Competition",
};

export const upcGraphDropdown = ["In Stock %", "CLS", "$ Sales", itemsListed];
export const kpiDropdown = ["In Stock %", itemsListed];
export const kpiDropdownBeSelected = ["Average # of Reviews", "Average # of Reviews per Item", "Average Sentiment"];
export const trafficSourceDropdown = ["Total", "Organic", "Paid"];
export const kpiDropdownSales = "Sales";
export const kpiDropdownBeSelectedAvgRating = "Average Rating";
export const reivewRetailersRnR = ["Top 5 by Sales", "Bottom 5 by Sales"];



export const defaultCompetitionKpi = [
  "Basic KPIs:$ Share",
  "Basic KPIs:$ Sales",
  "Be Available:In Stock %",
  "Be Available:# of items",
];

export const competitionKpiData = [
  {
    title: "Basic KPIs",
    value: "Basic KPIs",
    checkable: false,
    children: [
      {
        title: "$ Share",
        value: "Basic KPIs:$ Share",
        key: "dollar_share",
        renderFunction: columns.renderPercentageFunction,
      },
      {
        title: "$ Sales",
        value: "Basic KPIs:$ Sales",
        key: "dollar_sales",
        renderFunction: columns.renderDollarFunction,
      },
      {
        title: "Unit Sales",
        value: "Basic KPIs:Unit Sales",
        key: "unit_sales",
        renderFunction: columns.renderFunction,
      },
      {
        title: "Share of Unit Sales",
        value: "Basic KPIs:Share of Unit Sales",
        key: "share_unit_sales",
        renderFunction: columns.renderFunction,
      },
    ],
  },
  {
    title: "Be Available",
    value: "Be Available",
    checkable: false,
    children: [
      {
        title: "In Stock %",
        value: "Be Available:In Stock %",
        key: "in_stock",
        renderFunction: columns.renderPercentageFunction,
      },
      {
        title: "# of items",
        value: "Be Available:# of items",
        key: "number_items",
        renderFunction: columns.renderFunction,
      },
    ],
  },
];

export const competitionBenchmarkFormat = [
  { index: 0, table_key: "Internal", table_hierarchy_data: [] },
  { index: 1, table_key: "Category Benchmark", table_hierarchy_data: [] },
  { index: 2, table_key: "Competition Benchmark", table_hierarchy_data: [] },
];

export const noRowData = [
  {
    sub_brand: "",
    csl_total_ecom: {
      abs: 0,
      variance: 0,
    },
    csl_retailer: {
      abs: 0,
      variance: 0,
    },
    items_listed_total_ecom: {
      abs: 0,
      variance: 0,
    },
    items_listed_retailer: {
      abs: 0,
      variance: 0,
    },
    in_stock_total_ecom: {
      abs: 0,
      variance: 0,
    },
    in_stock_retailer: {
      abs: 0,
      variance: 0,
    },
    sales_total_ecom: {
      abs: 0,
      variance: 0,
    },
    sales_retailer: {
      abs: 0,
      variance: 0,
    },
    sentiment_data: [],
    upc_data: [
      {
        upc: "",
        csl_total_ecom: {
          abs: 0,
          variance: 0,
        },
        csl_retailer: {
          abs: 0,
          variance: 0,
        },
        items_listed_total_ecom: {
          abs: 0,
          variance: 0,
        },
        items_listed_retailer: {
          abs: 0,
          variance: 0,
        },
        in_stock_total_ecom: {
          abs: 0,
          variance: 0,
        },
        in_stock_retailer: {
          abs: 0,
          variance: 0,
        },
        sales_total_ecom: {
          abs: 0,
          variance: 0,
        },
        sales_retailer: {
          abs: 0,
          variance: 0,
        },
      },
    ],
    sub_brand_data: [],
  },
];

// using this dummy records in UPC level graph UI 
export const upcGraphData = [
  { "Period": "P6W1", "In Stock %": 45, "CSL": 67 },
  { "Period": "P6W2", "In Stock %": 78, "CSL": 22 },
  { "Period": "P6W3", "In Stock %": 31, "CSL": 56 },
  { "Period": "P6W4", "In Stock %": 62, "CSL": 89 },
  { "Period": "P7W1", "In Stock %": 21, "CSL": 75 },
  { "Period": "P7W2", "In Stock %": 87, "CSL": 41 },
  { "Period": "P7W3", "In Stock %": 54, "CSL": 33 },
  { "Period": "P7W4", "In Stock %": 23, "CSL": 68 },
  { "Period": "P8W1", "In Stock %": 95, "CSL": 20 },
  { "Period": "P8W2", "In Stock %": 36, "CSL": 49 },
  { "Period": "P8W3", "In Stock %": 67, "CSL": 81 },
  { "Period": "P8W4", "In Stock %": 18, "CSL": 95 },
  { "Period": "P9W1", "In Stock %": 50, "CSL": 28 },
  { "Period": "P9W2", "In Stock %": 82, "CSL": 37 },
  { "Period": "P9W3", "In Stock %": 24, "CSL": 60 },
  { "Period": "P9W4", "In Stock %": 73, "CSL": 45 }
]

export const menu = (exportData) => (
  <Menu>
    <Menu.Item key="0" onClick={exportData}>
      Export Data
    </Menu.Item>
  </Menu>
);

export const KPICard = ({
  title,
  abs,
  variance,
  absSymbol,
  varianceSymbol,
  exportKPIs,
}) => {
  const absDecimals = title === itemsListed ? 0 : 1;

  return (
    <>
      {title === "CSL" ? (
        <Tooltip
          className="csl-tile"
          title="CSL available for Mars only"
          placement="top"
        >
          <div className="ba-kpi-item">
            <div className="ba-kpi-item-head">
              <h5>{title}</h5>
              {exportKPIs && (
                <Dropdown
                  overlay={() => menu(exportKPIs)}
                  trigger={["click"]}
                  placement="bottomRight"
                  className="d-more"
                >
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    <MoreOutlined />
                  </a>
                </Dropdown>
              )}
            </div>
            <p>
              {hasValue(abs) &&
                (title === "Average Placement"
                  ? abs
                  : formatNumber(abs, 1, true) === valueTypes.NA
                    ? valueTypes.NA
                    : addSymbol(formatNumber(abs, absDecimals), absSymbol))}
              {Number(variance) >= 0 ? (
                <span className="positive">
                  {hasValue(variance) &&
                    (formatNumber(variance, 1, true) === valueTypes.NA ? (
                      valueTypes.NA
                    ) : (
                      <>
                        <CaretUpFilled
                          style={{ color: "#00c853", marginLeft: 5 }}
                        />
                        +{addSymbol(formatNumber(variance, 1), varianceSymbol)}
                      </>
                    ))}
                </span>
              ) : (
                <span className="negative">
                  {hasValue(variance) &&
                    (formatNumber(variance, 1, true) === valueTypes.NA ? (
                      valueTypes.NA
                    ) : (
                      <>
                        <CaretDownFilled
                          style={{ color: "#ec3225", marginLeft: 5 }}
                        />
                        {addSymbol(formatNumber(variance, 1), varianceSymbol)}
                      </>
                    ))}
                </span>
              )}
            </p>
          </div>
        </Tooltip>
      ) : (
        <div className="ba-kpi-item">
          <div className="ba-kpi-item-head">
            <h5>{title}</h5>
            {exportKPIs && (
              <Dropdown
                overlay={() => menu(exportKPIs)}
                trigger={["click"]}
                placement="bottomRight"
                className="d-more"
              >
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <MoreOutlined />
                </a>
              </Dropdown>
            )}
          </div>
          <p>
            {hasValue(abs) &&
              (title === "Average Placement"
                ? abs
                : formatNumber(abs, 1, true) === valueTypes.NA
                  ? valueTypes.NA
                  : addSymbol(formatNumber(abs, absDecimals), absSymbol))}
            {Number(variance) >= 0 ? (
              <span className="positive">
                {hasValue(variance) &&
                  (formatNumber(variance, 1, true) === valueTypes.NA ? (
                    valueTypes.NA
                  ) : (
                    <>
                      <CaretUpFilled
                        style={{ color: "#00c853", marginLeft: 5 }}
                      />
                      +{addSymbol(formatNumber(variance, 1), varianceSymbol)}
                    </>
                  ))}
              </span>
            ) : (
              <span className="negative">
                {hasValue(variance) &&
                  (formatNumber(variance, 1, true) === valueTypes.NA ? (
                    valueTypes.NA
                  ) : (
                    <>
                      <CaretDownFilled
                        style={{ color: "#ec3225", marginLeft: 5 }}
                      />
                      {addSymbol(formatNumber(variance, 1), varianceSymbol)}
                    </>
                  ))}
              </span>
            )}
          </p>
        </div>
      )}
    </>
  );
};

export function ColumnLineChart({
  title,
  kpi,
  data,
  xField,
  yField,
  yFieldNumFmt,
  yBarUnit,
  yBulletUnit,
}) {
  const dispatch = useDispatch();


  function getMin() {
    if (data && yField) {
      if (Math.min(...data.map((val) => val[yField[1]])) > 0) return 0;
      else return Math.min(...data.map((val) => val[yField[1]]));
    } else {
      return 0;
    }
  }

  function getMax() {
    if (data && yField) {
      const max = Math.max(...data.map((val) => val[yField[1]]));
      return max + (max * 10) / 100;
    } else {
      return 0;
    }
  }

  // Excel Export
  useEffect(() => {
    if (data.length === 0) {
      dispatch(setExportData({ line: {} }));
      return;
    }
    dispatch(
      setExportData({
        line: {
          title,
          data,
          xField,
          yField,
          yFieldNumFmt,
        },
      })
    );
  }, [data]);

  const config = {
    data: [data, data],
    xField,
    yField,
    appendPadding: 20,
    animation: false,
    tooltip: {
      marker: {},
      customItems: (originalItems) => {

        let field0Value = formatNumber(originalItems[0].data[yField[0]], 1);
        if (yBarUnit === "$") field0Value = `$${field0Value}`;
        else if (yBarUnit === "%") field0Value = `${field0Value}%`;

        const field1ValueDecimals = kpi === itemsListed ? 0 : 1;

        let field1Value = formatNumber(originalItems[0].data[yField[1]], field1ValueDecimals);
        if (yBulletUnit === "$") field1Value = `$${field1Value}`;
        else if (yBulletUnit === "%") field1Value = `${field1Value}%`;

        return [
          {
            color: "#8080cf",
            name:
              yField[0] === "Total" ||
                yField[0] === "Organic" ||
                yField[0] === "Paid"
                ? `${yField[0]} Share of Shelf`
                : yField[0],
            value: field0Value,
            marker: true,
          },
          {
            color: "#e559a4",
            name: yField[1],
            value:
              yField[1] === "Average Placement"
                ? originalItems[0].data[yField[1]]
                : field1Value,
            marker: true,
          },
        ];
      },
    },
    xAxis: {
      title: {
        text: "Periods",
        style: {
          fontWeight: "700",
        },
      },
    },
    yAxis: {
      [yField[0]]: {
        title: {
          text:
            yField[0] === "Total" ||
              yField[0] === "Organic" ||
              yField[0] === "Paid"
              ? `${yField[0]} Share of Shelf`
              : yField[0],
        },
        label: {
          style: {
            fill: "#000000",
            opacity: 0.7,
          },
          formatter: (val) => formatNumber(val, 1),
        },
        // max: 100,
      },
      [yField[1]]: {
        title: { text: yField[1] },
        label: {
          style: {
            fill: "#000000",
            opacity: 0.7,
          },
          formatter: (val) => {
            return formatNumber(val, 1);
          },
        },
        min: getMin(),
        max: getMax(),
      },
    },
    geometryOptions: [
      {
        geometry: "column",
        color: "#8080cf",
      },
      {
        geometry: "line",
        lineStyle: {
          lineWidth: 2,
        },
        point: {},
        color: "#e559a4",
      },
    ],
    legend: {
      position: "top-left",
      itemName: {
        formatter: (data) => {
          if (data === "Total") {
            return "Total Share of Shelf";
          } else if (data === "Organic") {
            return "Organic Share of Shelf";
          } else if (data === "Paid") {
            return "Paid Share of Shelf";
          } else if (data === "Traffic_Kpi") {
            return "Traffic";
          } else if (data === "Traffic_Share_Kpi") {
            return "Traffic Share";
          } else {
            return data;
          }
        },
      },
    },
    // interactions: [
    //   {
    //     type: "element-highlight",
    //   },
    //   {
    //     type: "active-region",
    //   },
    // ],
  };

  return <DualAxes {...config} />;
}
// Column Line Chart component (using this component to show UPC level performance) 
export function UPCColumnLineChart({
  title,
  data,
  xField,
  yField,
  yFieldNumFmt,
}) {
  const dispatch = useDispatch();



  // Excel Export
  useEffect(() => {
    if (data.length === 0) {
      dispatch(setExportData({ line: {} }));
      return;
    }
    dispatch(
      setExportData({
        line: {
          title,
          data,
          xField,
          yField,
          yFieldNumFmt,
        },
      })
    );
  }, [data]);

  const config = {
    data: [data, data],
    xField,
    yField,
    appendPadding: 20,
    animation: false,
    tooltip: {
      customItems: (originalItems) => {

        let field0Value = `${formatNumber(originalItems[0].data[yField[0]], 1)}%`;
        let field1Value = `${formatNumber(originalItems[0].data[yField[1]], 1)}%`;

        return [
          {
            color: "#8080cf",
            name: yField[0],
            value: field0Value,
            marker: true,
          },
          {
            color: "#e559a4",
            name: yField[1],
            value: field1Value,
            marker: true,
          },
        ];
      },
    },
    xAxis: {
      title: {
        text: "Periods",
        style: {
          fontWeight: "700",
        },
      },
    },
    yAxis: {
      [yField[0]]: {
        title: {
          text: yField[0],
        },
        label: {
          style: {
            fill: "#000000",
            opacity: 0.7,
          },
          formatter: (val) => `${formatNumber(val, 0)}%`,
        },
        max: 100,
        min: 20,
      },
      [yField[1]]: {
        title: { text: yField[1] },
        label: {
          style: {
            fill: "#000000",
            opacity: 0.7,
          },
          formatter: (val) => {
            return `${formatNumber(val, 0)}%`;
          },
        },
        min: 20,
        max: 100,
      },
    },
    geometryOptions: [
      {
        geometry: "column",
        color: "#8080cf",
      },
      {
        geometry: "line",
        lineStyle: {
          lineWidth: 2,
        },
        point: {},
        color: "#e559a4",
      },
    ],

  };

  return <DualAxes {...config} />;
}

export function MultiLineChart({
  title,
  kpi,
  data,
  xField,
  yField,
  yFieldNumFmt,
  seriesField,
  yBulletUnit,
  legendSize = 12,
}) {

  const dispatch = useDispatch();

  // Excel Export
  useEffect(() => {
    if (data.length === 0) {
      dispatch(setExportData({ line: {} }));
      return;
    }

    const series = uniq(data.map((item) => item[seriesField]));
    const xFields = uniq(data.map((item) => item[xField]));

    const newData = [];
    xFields.forEach((field) => {
      const row = {
        [xField]: field,
      };

      series.forEach((seriesItem) => {
        const item = data.find(
          (record) =>
            record[xField] === field && record[seriesField] === seriesItem
        );
        if (item) row[seriesItem] = item[yField];
      });

      newData.push(row);
    });

    dispatch(
      setExportData({
        line: {
          title,
          data: newData,
          xField,
          yField,
          series,
          yFieldNumFmt,
        },
      })
    );
  }, [data]);

  const config = {
    data,
    xField,
    yField,
    seriesField,
    animation: false,
    point: {
      shape: "circle",
    },
    xAxis: {
      title: { text: xField, style: { fontWeight: "700" } },
    },
    yAxis: {
      title: { text: yField },
      label: {
        formatter: (val) => formatNumber(val, 1),
      },
    },
    // color: ({ innovation }) => innovation === "Median" ? '#FC8970' : randomColor[innovation],
    tooltip: {
      formatter: (data) => {
        const field1ValueDecimals = kpi === itemsListed ? 0 : 1;

        let field1Value = formatNumber(data[yField], field1ValueDecimals);
        if (yBulletUnit === "$") field1Value = `$${field1Value}`;
        else if (yBulletUnit === "%") field1Value = `${field1Value}%`;

        return {
          name: data[seriesField],
          value: field1Value,
        };
      },
    },
    legend: {
      maxRow: 2,
      itemName: {
        style: {
          fontSize: legendSize,
        },
      },
    },
  };

  return <Line {...config} />;
}

export function ColumnBulletChart({
  title,
  kpi,
  data,
  xField,
  yField,
  yFieldNumFmt,
  viewBy,
  yBarUnit,
  yBulletUnit,
  value,
  onChange,
  stored,
  setStored,
  storageKey,
}) {
  const dispatch = useDispatch();

  function getMin() {
    if (data && yField) {
      if (Math.min(...data.map((val) => val[yField[1]])) > 0) return 0;
      else return Math.min(...data.map((val) => val[yField[1]]));
    } else {
      return 0;
    }
  }

  function getMax() {
    if (data && yField) {
      const max = Math.max(...data.map((val) => val[yField[1]]));
      return max + (max * 10) / 100;
    } else {
      return 0;
    }
  }

  const [chartHeight, setChartHeight] = useState(400);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (data && yField) {
      const max = maxBy(data, yField[1]);

      // Calculating the bullet (pink line) vertical width dynamically.
      setChartData(
        data.map((item) => {
          let bulletRange = 0;
          if (item[yField[1]]) bulletRange = item[yField[1]] + (max[yField[1]] * 1.5 / 100);
          return { ...item, [yField[1]]: [item[yField[1]], bulletRange] };
        })
      );
    } else setChartData([]);
  }, [data]);

  // Excel Export
  useEffect(() => {
    if (data.length === 0) {
      dispatch(setExportData({ bar: {} }));
      return;
    }
    dispatch(
      setExportData({
        bar: {
          title,
          data,
          xField,
          yField,
          yFieldNumFmt
        },
      })
    );
  }, [data]);

  useEffect(() => {
    if (data?.length === 0) return;
    if (!hasValue(data[0][xField])) return;

    const checks = [
      [0, 4, 425],
      [4, 8, 450],
      [8, 16, 475],
      [16, 20, 490],
      [20, 24, 525],
      [24, 28, 550],
      [28, 32, 575],
      [32, Infinity, 600],
    ];

    const maxLabelLength = max(data.map(item => item[xField].length));

    if (maxLabelLength)
      checks.some(check => {
        if (maxLabelLength > check[0] && maxLabelLength <= check[1]) {
          setChartHeight(check[2]);
          return true;
        }
      });
  }, [data]);

  const config = {
    data: [chartData, chartData],
    xField,
    yField,
    height: "100%",
    appendPadding: 20,
    animation: false,
    tooltip: {
      marker: {},
      customItems: (originalItems) => {


        let field0Value = formatNumber(originalItems[0].data[yField[0]], 1);
        if (yBarUnit === "$") field0Value = `$${field0Value}`;
        else if (yBarUnit === "%") field0Value = `${field0Value}%`;

        const field1ValueDecimals = kpi === itemsListed ? 0 : 1;

        let field1Value = formatNumber(originalItems[0].data[yField[1]][0], field1ValueDecimals);
        if (yBulletUnit === "$") field1Value = `$${field1Value}`;
        else if (yBulletUnit === "%") field1Value = `${field1Value}%`;

        return [
          {
            color: "#8080cf",
            name:
              yField[0] === "Total" ||
                yField[0] === "Organic" ||
                yField[0] === "Paid"
                ? `${yField[0]} Share of Shelf`
                : yField[0],
            value: field0Value,
            marker: true,
          },
          {
            color: "#e559a4",
            name: yField[1],
            value:
              yField[1] === "Average Placement"
                ? originalItems[0].data[yField[1]][0]
                : field1Value,
            marker: true,
          },
        ];
      },
    },
    xAxis: {
      title: {
        text: xField,
        style: {
          fontWeight: "700",
        },
      },
      label: {
        rotate: -1,
        offsetX: -30,
        offsetY: 10,
      },
    },
    yAxis: {
      [yField[0]]: {
        title: {
          text:
            yField[0] === "Total" ||
              yField[0] === "Organic" ||
              yField[0] === "Paid"
              ? `${yField[0]} Share of Shelf`
              : yField[0],
        },
        label: {
          style: {
            fill: "#000000",
            opacity: 0.7,
          },
          formatter: (val) => formatNumber(val, 1),
        },
      },
      [yField[1]]: {
        title: { text: yField[1] },
        label: {
          style: {
            fill: "#000000",
            opacity: 0.7,
          },
          formatter: (val) => {
            return formatNumber(val, 1);
          },
        },
        // min: getMin(),
        // max: 500000,
        max: getMax(),
      },
    },
    geometryOptions: [
      {
        geometry: "column",
        isRange: true,
        columnWidthRatio: 0.5,
        color: (type) =>
          value?.includes(type[viewBy]) ? "#0000a0" : "#8080cf",
        columnStyle: () => ({ cursor: "pointer" }),
      },
      {
        geometry: "column",
        color: "#e559a4",
        columnWidthRatio: 0.7,
      },
    ],
    legend: {
      position: "top-left",
      itemName: {
        formatter: (data) => {
          if (data === "Total") {
            return "Total Share of Shelf";
          } else if (data === "Organic") {
            return "Organic Share of Shelf";
          } else if (data === "Paid") {
            return "Paid Share of Shelf";
          } else if (data === "Traffic_Kpi") {
            return "Traffic";
          } else if (data === "Traffic_Share_Kpi") {
            return "Traffic Share";
          } else {
            return data;
          }
        },
      },
    },
  };

  useEffect(() => {
    if (
      storageKey &&
      (!stored || !JSON.parse(sessionStorage.getItem(storageKey)))
    ) {
      sessionStorage.setItem(storageKey, JSON.stringify(value));
      setStored(true);
    }
  }, [value]);

  const onEvent = (e, event) => {
    if (event.type === "plot:click") {
      if (event.data === null) {
        const storedValue = JSON.parse(sessionStorage.getItem(storageKey));
        const newValue = [data[0][viewBy]];

        if (!isEqual(storedValue, newValue)) {
          sessionStorage.setItem(storageKey, JSON.stringify(newValue));
          onChange(newValue);
        }
      }
    }

    if (event.type === "element:click") {
      const eventValue = event.data.data[viewBy];
      const storedValue = JSON.parse(sessionStorage.getItem(storageKey));
      let newValue = [...storedValue];
      if (!newValue) newValue = [...value];

      const index = newValue.findIndex((item) => item === eventValue);

      if (window.event.ctrlKey)
        if (index === -1) newValue.push(eventValue);
        else newValue.splice(index, 1);
      else newValue = [eventValue];

      if (newValue.length > 0 && !isEqual(storedValue, newValue)) {
        sessionStorage.setItem(storageKey, JSON.stringify(newValue));
        onChange(newValue);
      }
    }
  };

  return storageKey ? (
    <div style={{ height: chartHeight }}>
      <DualAxes {...config} onEvent={onEvent} />
    </div>
  ) : (
    <div style={{ height: chartHeight }}>
      <DualAxes {...config} />
    </div>
  );
}

export function SelectColumnLineChart({
  data,
  xField,
  yField,
  yBarUnit,
  viewBy,
  value,
  onChange,
  stored,
  setStored,
  storageKey,
}) {
  const dispatch = useDispatch()

  function getMin() {
    if (data && yField) {
      if (Math.min(...data.map((val) => val[yField[1]])) > 0) return 0;
      else return Math.min(...data.map((val) => val[yField[1]]));
    } else {
      return 0;
    }
  }

  function getMax() {
    if (data && yField) {
      const max = Math.max(...data.map((val) => val[yField[1]]));
      return max + (max * 10) / 100;
    } else {
      return 0;
    }
  }
  useEffect(() => {
    if (data.length === 0) {
      dispatch(setExportData({ bar: {} }));
      return;
    }
    dispatch(
      setExportData({
        bar: {
          data,
          xField,
          yField,
        },
      })
    );
  }, [data]);
  const config = {
    data: [data, data],
    xField,
    yField,
    appendPadding: 20,
    animation: false,
    tooltip: {
      marker: {},
      customItems: (originalItems) => {

        let field0Value = formatNumber(originalItems[0].data[yField[0]], 1);
        if (yBarUnit === "$") field0Value = `$${field0Value}`;
        else if (yBarUnit === "%") field0Value = `${field0Value}%`;

        return [
          {
            color: "#8080cf",
            name: yField[0],
            value: field0Value,
            marker: true,
          },
          {
            color: "#e559a4",
            name: yField[1],
            value: formatNumber(originalItems[0].data[yField[1]], 1),
            marker: true,
          },
        ];
      },
    },
    xAxis: {
      title: {
        text: xField,
        style: {
          fontWeight: "700",
        },
      },
      label: {
        rotate: -1,
        offsetX: -30,
        offsetY: 10,
      },
    },
    yAxis: {
      [yField[0]]: {
        title: { text: yField[0] },
        label: {
          style: {
            fill: "#000000",
            opacity: 0.7,
          },
          formatter: (val) => formatNumber(val, 1),
        },
        // max: 100,
      },
      [yField[1]]: {
        title: { text: yField[1] },
        label: {
          style: {
            fill: "#000000",
            opacity: 0.7,
          },
          formatter: (val) => formatNumber(val, 1),
        },
        min: getMin(),
        max: getMax(),
      },
    },
    geometryOptions: [
      {
        geometry: "column",
        color: (type) =>
          value?.includes(type[viewBy]) ? "#0000a0" : "#8080cf",
        columnStyle: () => ({ cursor: "pointer" }),
      },
      {
        geometry: "line",
        lineStyle: {
          lineWidth: 2,
        },
        point: {},
        color: "#e559a4",
      },
    ],
    // interactions: [
    //   {
    //     type: "element-highlight",
    //   },
    //   {
    //     type: "active-region",
    //   },
    // ],
  };

  useEffect(() => {
    if (
      storageKey &&
      (!stored || !JSON.parse(sessionStorage.getItem(storageKey)))
    ) {
      sessionStorage.setItem(storageKey, JSON.stringify(value));
      setStored(true);
    }
  }, [value]);

  const onEvent = (e, event) => {
    if (event.type === "plot:click") {
      if (event.data === null) {
        const storedValue = JSON.parse(sessionStorage.getItem(storageKey));
        const newValue = [data[0][viewBy]];

        if (!isEqual(storedValue, newValue)) {
          onChange(newValue);
        }
      }
    }

    if (event.type === "element:click") {
      const eventValue = event.data.data[viewBy];
      const storedValue = JSON.parse(sessionStorage.getItem(storageKey));
      let newValue = [...storedValue];
      if (!newValue) newValue = [...value];

      const index = newValue.findIndex((item) => item === eventValue);

      if (window.event.ctrlKey)
        if (index === -1) newValue.push(eventValue);
        else newValue.splice(index, 1);
      else newValue = [eventValue];

      if (newValue.length > 0 && !isEqual(storedValue, newValue)) {
        sessionStorage.setItem(storageKey, JSON.stringify(newValue));
        onChange(newValue);
      }
    }
  };

  return storageKey ? (
    <DualAxes {...config} onEvent={onEvent} />
  ) : (
    <DualAxes {...config} />
  );
}

export function ColumnChart({ data, xField, yField, yBarUnit }) {

  function getMin() {
    if (data && yField) {
      if (Math.min(...data.map((val) => val[yField[1]])) > 0) return 0;
      else return Math.min(...data.map((val) => val[yField[1]]));
    } else {
      return 0;
    }
  }

  function getMax() {
    if (data && yField) {
      const max = Math.max(...data.map((val) => val[yField[1]]));
      return max + (max * 10) / 100;
    } else {
      return 0;
    }
  }

  const config = {
    data,
    xField,
    yField,
    appendPadding: 20,
    animation: false,
    color: () => "#8080cf",
    tooltip: {
      marker: {},
      customItems: (originalItems) => {

        // let field0Value = formatNumber(originalItems[0].data[yField], 1);
        // if (yBarUnit === "$") field0Value = `$${field0Value}`;
        // else if (yBarUnit === "%") field0Value = `${field0Value}%`;

        return [
          // {
          //   color: "#8080cf",
          //   name: yField[0],
          //   value: field0Value,
          //   marker: true,
          // },
          {
            color: "#8080cf",
            name: yField,
            value: formatNumber(originalItems[0].data[yField], 1),
            marker: true,
          },
        ];
      },
    },
    xAxis: {
      title: {
        text: "Periods",
        style: {
          fontWeight: "700",
        },
      },
    },
    yAxis: {
      title: { text: yField },
      label: {
        style: {
          fill: "#000000",
          opacity: 0.7,
        },
        formatter: (val) => formatNumber(val, 1),
      },
      // max: 100,
    },
  };

  return <Column {...config} />;
}
