import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Input, Modal, Select } from "antd";
import { PlusCircleTwoTone, CloseOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import './export-form.scss';


const { Option } = Select;

export default function ExportForm(props) {
    const dispatch = useDispatch();
    const { openExportDialog, setOpenExportDialog } = props
    const [filterCount, setFilterCount] = useState([0]);
    const [form] = Form.useForm();

    const closeExportDialog = () => {
        setOpenExportDialog(false)
    }

    const onSaveClick = () => {
        form.validateFields()
            .then(values => {
                console.log("Success:", values);
            })
            .catch(errorInfo => {
                console.log('Failed:', errorInfo);
            });
    }

    const { Option } = Select;


    return (
        <Modal
            title={"Export External Data"}
            visible={openExportDialog}
            onOk={onSaveClick}
            okText={"Export"}
            onCancel={closeExportDialog}
            width={700}
        >
            <div className="">
                <Form form={form} name="basic" layout="vertical">
                    <Form.Item
                        name={"Data Source"}
                        label="Data Source"
                        rules={[
                            {
                                required: true,
                                message: 'Please choose a data source',
                            }]}>
                        <Select placeholder="Select" style={{ width: 200 }}>
                            {['Nielsen', 'Stackline'].map((val) => (
                                <Option value={val}>{val}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <div className="mytextdiv"><div className="mytexttitle">Filter</div><div class="divider" ></div></div>
                    {filterCount.map((val) => (<Row style={{ marginTop: 10 }}>
                        <Col md={7}>
                            <Form.Item
                                name={"Dimension" + val}
                                label="Select Dimension"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a dimension',
                                    }]}>
                                <Select placeholder="Select" style={{ width: 180 }}>
                                    {['Nielsen', 'Stackline'].map((val) => (
                                        <Option value={val}>{val}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={7}>
                            <Form.Item
                                name={"Filter" + val}
                                label="Select Filter"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a filter',
                                    }]}>
                                <Select placeholder="Select" style={{ width: 180 }}>
                                    {['Nielsen', 'Stackline'].map((val) => (
                                        <Option value={val}>{val}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={7}>
                            <Form.Item
                                name={"Brand" + val}
                                label="Select Brand"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a brand',
                                    }]}>
                                <Select placeholder="Select" style={{ width: 180 }}>
                                    {['Nielsen', 'Stackline'].map((val) => (
                                        <Option value={val}>{val}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {val !== 0 && <Col md={3}>
                            <CloseOutlined onClick={() => {
                                const index = [...filterCount].indexOf(val);
                                let filterArray = [...filterCount]
                                filterArray.splice(index, 1)
                                setFilterCount(filterArray)
                            }} />
                        </Col>}
                    </Row>))}
                </Form>
                <div onClick={() => setFilterCount([...filterCount, Math.max(...filterCount) + 1])} style={{ width: "15%", fontWeight: "700px" }}>
                    <PlusCircleTwoTone /> Add Filter
                </div>
            </div >
        </Modal >
    )
}
