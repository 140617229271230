import React, { useEffect, useState, useRef } from "react";
import { Col, Layout, Row, Form, Button, Select, Input, Modal, Menu, Dropdown, Checkbox } from "antd";
import { useHistory, withRouter } from "react-router-dom";
import Wrapper from "../../components/wrapper";
import PerformanceSummary from "../performance-summary";
import VolumeShifting from "../volume-shifting";
import DistributionSummary from "../distribution-summary";
import { useDispatch, useSelector } from "react-redux";
import { TreeSelect } from "antd";
import Loader from "../../components/loader";
import { isPackSize } from "../volume-shifting";
import { getData, getAllFilters, getFilterData, getScatterData, getTableData, setReportRefreshDist } from "../../slicers/distributionSummary.slicer";
import { setReportRefreshPerf } from "../../slicers/performanceSummary.slicer";
import { setReportRefreshVol } from "../../slicers/volumeShiftingSummary.slicer";
import CustomAxios from "../../utilities/services/api.service";
import { ENDPOINTS } from "../../utilities/constants";
import { openNotificationWithIcon } from "../../utilities/helper";
import { setLoading } from "../../slicers/profile.slicer";
import { setAppliedFilters } from "../../slicers/report.slicer";
import { InnovationTracker } from "../innovation-tracker";
import { setReportRefreshInnovation, getFilterData as getFilterDataInnovation } from "../../slicers/innovation-tracker.slicer";

const { Option } = Select;

const { Content } = Layout;



function Report(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const profileLoad =
        history.location.state && history.location.state.profileLoad
            ? history.location.state.profileLoad
            : false;
    const {
        pets,
        techs,
        packSize,
        retailers,
        years,
        periods,
        preFilters,
    } = useSelector((state) => state.distSummary);
    const { innovationTypes, innovation } = useSelector((state) => state.innovationTracker)
    const { latestTimeYear } = useSelector((state) => state.profile)
    const { appliedFilters } = useSelector((state) => state.report)
    const { defaultBrand, userDesignation } = useSelector((state) => state.profile)
    const [channelRetailer, setChannelRetailer] = useState("");
    const [brandNames, setBrandNames] = useState([]); // TODO: try history state direct in useState brand, pageSize
    const [channels, setChannels] = useState([]);
    const [reportApply, setReportApply] = useState(false);
    const [reportInnovationApply, setReportInnovationApply] = useState(false);
    const [reportVolumeShiftingApply, setReportVolumeShiftingApply] = useState(false);
    const [reportPerformanceSummaryApply, setReportPerformanceSummaryApply] = useState(false);
    const [reportDistributionSummaryApply, setReportDistributionSummaryApply] = useState(false);
    const [reportAccess, setReportAccess] = useState(profileLoad);
    const [accessLoading, setAccessLoading] = useState(false);
    // const [initialLoad, setInitialLoad] = useState(true);
    const [filters, setFilters] = useState({
        product_pet: [],
        product_technology: [],
        product_package: [],
        customer_channel: [],
        customer_retailer_details: [],
        default_brand: ["NUTRO CORE"],
        innovation_type: [],
        innovation: [],
        time_year: [],
        time_period: [],
        top: [1],
        kpi: ["TDP"],
        innovation_years: [],
        selected_innovation: [],
        selected_retailer: [],
        launch_as_benchmark: [1],
        benchmark_values: [],
        trendchart_kpi: [],
        consumer_tab: ["A"],
        selected_sku: [],
        selected_retailer_key: [-1],
    });
    const [changeInnovationFilters, setChangeInnovationFilters] = useState(false);
    const [reportPreFilters, setReportPreFilters] = useState({});
    const [filterChanged, setFilterChanged] = useState(false);
    const [disableInnovationFilter, setDisableInnovationFilter] = useState(false);

    const isProductPackage = filters.product_technology.length === 1 && isPackSize.includes(filters.product_technology[0]);


    const changeFilter = (label, selectedValue) => {
        let newFilters = { ...filters };
        if (
            selectedValue &&
            selectedValue.length &&
            selectedValue.includes("all")
        ) {
            let all = [];
            if (label === "product_pet") all = pets;
            else if (label === 'product_package' && filters.product_technology.length === 1) all = packSize[filters.product_technology[0]];
            else if (label === "product_technology") all = techs;
            else all = retailers;
            if (selectedValue.length === all.length + 1) {
                selectedValue = [];
            } else selectedValue = [...all];
        }
        console.log("Filters Change", filters[label], selectedValue, JSON.stringify(filters[label]) !== JSON.stringify(selectedValue))
        if (JSON.stringify(filters[label]) !== JSON.stringify(selectedValue)) {
            console.log("Filters Changed True")
            setFilterChanged(true);
        }
        if (label === "product_technology" && !isPackSize.includes(selectedValue)) newFilters["product_package"] = []
        newFilters[label] = selectedValue;
        if (label === "product_pet" || label === "product_technology") {
            //Resetting Innovation Section Filters on Report 
            newFilters["innovation"] = []
            newFilters["innovation_years"] = latestTimeYear
            newFilters["innovation_type"] = []
        }
        setFilters(newFilters);
    };

    useEffect(() => {
        let newFilters = { ...filters }
        newFilters["innovation"] = []
        newFilters["innovation_years"] = []
        newFilters["innovation_type"] = []
        setFilters(newFilters);
    }, [defaultBrand])

    useEffect(() => {
        setAccessLoading(true);
        // setDisableInnovationFilter(false);
        // setInitialLoad(false);
        if (profileLoad) {
            setReportAccess(true)
            setAccessLoading(false);
            dispatch(setReportRefreshPerf(true))
            dispatch(setReportRefreshDist(true))
            dispatch(setReportRefreshVol(true))
            dispatch(setReportRefreshInnovation(true))
        }
        else {
            let temp = new URL(window.location.href);
            if (temp.search.length > 1) {
                let preFilters = JSON.parse(
                    decodeURIComponent(temp.search.substring(1))
                );

                let encodedURL = encodeURIComponent(JSON.stringify({
                    email: sessionStorage.getItem('email'),
                    report_id: preFilters.report_id,
                    user_designation: userDesignation,
                    // team_cluster: teamCluster
                }))
                // dispatch(setLoading(true))
                CustomAxios(ENDPOINTS.reportAccess + '?' + encodedURL, 'GET').then((response) => {
                    if (response.data.error_code === undefined) {
                        if (response.data.access_indicator === 1) {
                            setReportAccess(true);
                            dispatch(setReportRefreshPerf(true))
                            dispatch(setReportRefreshDist(true))
                            dispatch(setReportRefreshVol(true))
                        }
                        else {
                            setReportAccess(false);
                        }
                    }
                    else {
                        openNotificationWithIcon('error', response.data.message)
                    }
                    setAccessLoading(false)
                    // dispatch(setLoading(false))
                }).catch((err) => {
                    // dispatch(setLoading(false))
                    openNotificationWithIcon('error', 'Error Occured')
                    setAccessLoading(false)
                })
            }
        }
    }, [])

    const getRemainingFilters = (label) => {
        console.log("Entry Report", filterChanged)
        if (!filterChanged) return;

        setFilterChanged(false);
        if (label === "product_pet") {
            dispatch(
                getFilterData({
                    product_pet: filters.product_pet,
                })
            );
            // Object.keys(reportPreFilters).length !== 0 && reportPreFilters.innovation_components.length !== 0 && dispatch(
            //     getFilterDataInnovation({
            //         product_pet: filters.product_pet
            //     })
            // )
        } else if (label === "product_technology") {
            dispatch(
                getFilterData({
                    product_pet: filters.product_pet,
                    product_technology: filters.product_technology,
                })
            );
            // Object.keys(reportPreFilters).length !== 0 && reportPreFilters.innovation_components.length !== 0 && dispatch(
            //     getFilterDataInnovation({
            //         product_pet: filters.product_pet,
            //         product_technology: filters.product_technology
            //     })
            // )
        } else if (label === "product_package") {
            dispatch(
                getFilterData({
                    product_pet: filters.product_pet,
                    product_technology: filters.product_technology,
                    product_package: filters.product_package,
                })
            );
            // pply
        } else if (label === "customer_channel") {
            dispatch(
                getFilterData({
                    product_pet: filters.product_pet,
                    product_technology: filters.product_technology,
                    product_package: filters.product_package,
                    customer_channel: filters.customer_channel,
                    customer_retailer_details: filters.customer_retailer_details,
                })
            );
        }
        else if (label === "innovation_type") {
            dispatch(
                getFilterDataInnovation(isProductPackage ? {
                    product_pet: filters.product_pet,
                    product_technology: filters.product_technology,
                    product_package: filters.product_package,
                    innovation_type: filters.innovation_type
                } : {
                    product_pet: filters.product_pet,
                    product_technology: filters.product_technology,
                    innovation_type: filters.innovation_type
                })
            );
        } else if (label === "time_year") {
            // dispatch(
            //     getFilterData({
            //         product_pet: filters.product_pet,
            //         product_technology: filters.product_technology,
            //         product_package: filters.product_package,
            //         customer_channel: filters.customer_channel,
            //         customer_retailer_details: filters.customer_retailer_details,
            //         time_year: filters.time_year,
            //     })
            // );
            dispatch(
                getFilterDataInnovation(isProductPackage ? {
                    product_pet: filters.product_pet,
                    product_technology: filters.product_technology,
                    product_package: filters.product_package,
                    innovation_type: filters.innovation_type,
                    time_year: filters.innovation_years,
                } :
                    {
                        product_pet: filters.product_pet,
                        product_technology: filters.product_technology,
                        innovation_type: filters.innovation_type,
                        time_year: filters.innovation_years,
                    })
            )
        }
    };

    const applyFilters = (page = "") => {
        let temp = new URL(window.location.href);
        let preFilters = JSON.parse(
            decodeURIComponent(temp.search.substring(1))
        );
        console.log("Report Current Filter", filters)
        let currentFilter = { ...filters };
        for (const [k, v] of Object.entries(currentFilter)) {
            if (k in preFilters) {
                preFilters = {
                    ...preFilters,
                    [k]: [...v],
                };
            }
        }
        let encodedSearch = encodeURIComponent(JSON.stringify({ ...preFilters }));
        if (window.history.pushState) {
            var newurl =
                window.location.protocol +
                "//" +
                window.location.host +
                window.location.pathname +
                "?" +
                encodedSearch;
            window.history.pushState({ path: newurl }, "", newurl);
        }

        if (page === "innovation") {
            setReportInnovationApply(!reportInnovationApply)
        }
        if (page === "distribution-summary") {
            setReportDistributionSummaryApply(!reportDistributionSummaryApply)
        }
        if (page === "") {
            setReportVolumeShiftingApply(!reportVolumeShiftingApply)
            setReportPerformanceSummaryApply(!reportPerformanceSummaryApply)
            setReportDistributionSummaryApply(!reportDistributionSummaryApply)
            setReportInnovationApply(!reportInnovationApply)
            dispatch(getFilterDataInnovation({}));
        }
        dispatch(setAppliedFilters(filters));
    };
    const onChannelChange = (val) => {
        const value = val.split(":");
        if (value.length === 1)
            setFilters({
                ...filters,
                customer_channel: [value[0]],
                customer_retailer_details: [],
            });
        else if (value.length === 2)
            setFilters({
                ...filters,
                customer_channel: [value[0]],
                customer_retailer_details: [value[1]],
            });
        setChannelRetailer(val);
        setFilterChanged(true);
    };

    useEffect(() => {
        const channels = JSON.parse(JSON.stringify(retailers));
        setChannels(
            Object.keys(channels).map((channel) => ({
                title: channel,
                value: channel,
                children: channels[channel].map((retailer) => ({
                    title: retailer,
                    value: `${channel}:${retailer}`,
                })),
            }))
        );
    }, [retailers]);

    useEffect(() => {
        if (Object.keys(preFilters).length === 0) return;
        if (filters.customer_channel.length === 0) {
            const {
                time_period,
                time_year,
                customer_channel,
                customer_retailer_details,
            } = preFilters;

            const newFilters = {
                ...filters,
                time_period:
                    periods.includes(time_period) && filters.time_period.length === 0
                        ? [time_period]
                        : filters.time_period,
                time_year:
                    years.includes(time_year) && filters.time_year.length === 0
                        ? [time_year]
                        : filters.time_year,
                customer_channel:
                    Object.keys(retailers).includes(customer_channel) &&
                        filters.customer_channel.length === 0
                        ? [customer_channel]
                        : filters.customer_channel,
                customer_retailer_details:
                    retailers[customer_channel] &&
                        filters.customer_retailer_details.length === 0
                        ? retailers[customer_channel].includes(customer_retailer_details)
                            ? [customer_retailer_details]
                            : filters.customer_retailer_details
                        : filters.customer_retailer_details,
            };
            console.log("New Filters Report", newFilters);
            setFilters(newFilters);
            if (newFilters.customer_channel.length > 0)
                if (newFilters.customer_retailer_details.length > 0)
                    setChannelRetailer(
                        `${newFilters.customer_channel[0]}:${newFilters.customer_retailer_details[0]}`
                    );
                else setChannelRetailer(newFilters.customer_channel[0]);
        } else {
            if (filters.customer_channel.length > 0)
                if (filters.customer_retailer_details.length > 0)
                    setChannelRetailer(
                        `${filters.customer_channel[0]}:${filters.customer_retailer_details[0]}`
                    );
                else setChannelRetailer(filters.customer_channel[0]);
        }
    }, [preFilters, periods, years, retailers]);

    useEffect(() => {
        let temp = new URL(window.location.href);
        if (temp.search.length > 1) {
            try {
                let preFilters = JSON.parse(
                    decodeURIComponent(temp.search.substring(1))
                );
                let currentFilter = { ...filters };
                for (const [k, v] of Object.entries(preFilters)) {
                    if (k in currentFilter) {
                        currentFilter = {
                            ...currentFilter,
                            [k]: [...v]
                        }
                    }
                }
                console.log("Report Loading", currentFilter)
                setFilters({ ...currentFilter });
                dispatch(setAppliedFilters({ ...currentFilter }));
                setReportPreFilters({ ...preFilters });
            } catch (e) {
                console.error("Set Filters Error", e);
            }
        }
    }, []);

    const getFilterTitle = () => {
        if (
            preFilters.customer_retailer_details &&
            preFilters.customer_retailer_details !== "Total Market"
        ) {
            return preFilters.customer_retailer_details;
        }

        return preFilters.customer_channel ? preFilters.customer_channel : "";
    };


    return (
        <>{accessLoading ? <Loader loading={accessLoading} tip="Verifying Access"><div className="authorization"></div></Loader> : reportAccess ?
            <>
                <Wrapper heading={"4"} innerHeading={"9"} reportName={reportPreFilters.report_name && reportPreFilters.report_name.length && reportPreFilters.report_name[0]}>
                    <Row style={{ marginBottom: 8, padding: '10px 30px' }} align="bottom">
                        <Col span={17}>
                            <Form
                                name="horizontal_login"
                                className="performance-filter"
                                layout="inline"
                            >
                                <Row gutter={12}>
                                    <div className="filters-ps">
                                        <Col>
                                            <label className="label-block">Pet</label>
                                            <Select
                                                placeholder="All"
                                                onMouseLeave={() => getRemainingFilters("product_pet")}
                                                mode="multiple"
                                                defaultValue={["all"]}
                                                value={filters.product_pet}
                                                onChange={(val) => changeFilter("product_pet", val)}
                                                style={{ width: 100 }}
                                            >
                                                <Option value={"all"}>{"All"}</Option>
                                                {pets.map((val, index) => (
                                                    <Option key={index} value={val}>
                                                        {val}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Col>
                                        <Col>
                                            <label className="label-block">Technology</label>
                                            <Select
                                                placeholder="All"
                                                onMouseLeave={() => getRemainingFilters("product_technology")}
                                                defaultValue={["all"]}
                                                mode="multiple"
                                                value={filters.product_technology}
                                                onChange={(val) =>
                                                    changeFilter("product_technology", val)
                                                }
                                                style={{ width: 180 }}
                                            >
                                                <Option value={"all"}>{"All"}</Option>
                                                {techs.map((val, index) => (
                                                    <Option key={index} value={val}>
                                                        {val}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Col>
                                        {filters.product_technology.length === 1 && isPackSize.includes(filters.product_technology[0]) && <Col>
                                            <label className="label-block">Pack Type</label>
                                            <Select placeholder="All" onMouseLeave={() => getRemainingFilters('product_package')} mode="multiple" defaultValue={['all']} value={filters.product_package} onChange={(val) => { changeFilter('product_package', val) }} dropdownMatchSelectWidth={false} placement="bottomLeft" style={{ width: 85 }} maxTagCount="responsive" >
                                                <Option value={'all'}>{'All'}</Option>
                                                {packSize[filters.product_technology[0]] && packSize[filters.product_technology[0]].map((val) => (
                                                    <Option value={val}>{val}</Option>
                                                ))}
                                            </Select>
                                        </Col>}
                                        {/* {Object.keys(reportPreFilters).length !== 0 && reportPreFilters.distribution_components.length !== 0 && <Col>
                                            <label className="label-block">Channel/Retailer</label>
                                            <TreeSelect
                                                placeholder="All"
                                                defaultValue={["all"]}
                                                onMouseLeave={() => getRemainingFilters("customer_channel")}
                                                style={{ width: "200px" }}
                                                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                                value={channelRetailer}
                                                treeData={channels}
                                                onChange={onChannelChange}
                                            />
                                        </Col>} */}
                                        {Object.keys(reportPreFilters).length !== 0 && (reportPreFilters.performance_components.length !== 0 || reportPreFilters.reportBrands.length !== 0 || reportPreFilters.reportChannel.length !== 0 || reportPreFilters.distribution_components.length !== 0) && <Col>
                                            <label className="label-block">Year</label>
                                            <Select
                                                placeholder="All"
                                                onMouseLeave={() => getRemainingFilters("time_year")}
                                                value={filters.time_year}
                                                onChange={(val) => changeFilter("time_year", [val])}
                                                style={{ width: 80 }}
                                            >
                                                {years.map((val, index) => (
                                                    <Option key={index} value={val}>
                                                        {val}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Col>}
                                        {Object.keys(reportPreFilters).length !== 0 && (reportPreFilters.performance_components.length !== 0 || reportPreFilters.reportBrands.length !== 0 || reportPreFilters.reportChannel.length !== 0 || reportPreFilters.distribution_components.length !== 0) && <Col>
                                            <label className="label-block">Period</label>
                                            <Select
                                                placeholder="All"
                                                value={filters.time_period}
                                                onChange={(val) => changeFilter("time_period", [val])}
                                                style={{ width: 68 }}
                                            >
                                                {periods.map((val, index) => (
                                                    <Option key={index} value={val}>
                                                        {val}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Col>}
                                        {/* {Object.keys(reportPreFilters).length !== 0 && reportPreFilters.innovation_components.length !== 0 && <><Col>
                                            <label className="label-block">Innovation Type</label>
                                            <Select
                                                placeholder="All"
                                                onMouseLeave={() => getRemainingFilters('innovation_type')}
                                                mode="multiple"
                                                defaultValue={['all']}
                                                value={filters.innovation_type}
                                                onChange={(val) => { changeFilter('innovation_type', val) }}
                                                dropdownMatchSelectWidth={false}
                                                allowClear
                                                placement="bottomLeft"
                                                style={{ width: 140 }}
                                                maxTagCount="responsive" >

                                                <Option value={'all'}>{'All'}</Option>
                                                {innovationTypes.map((val) => (
                                                    <Option value={val}>{val}</Option>
                                                ))}
                                            </Select>
                                        </Col>
                                            <Col>
                                                <label className="label-block">Innovation</label>
                                                <Select
                                                    placeholder="All"
                                                    mode="multiple"
                                                    defaultValue={['all']}
                                                    value={filters.innovation}
                                                    onChange={(val) => { changeFilter('innovation', val) }}
                                                    dropdownMatchSelectWidth={false}
                                                    allowClear
                                                    placement="bottomLeft"
                                                    style={{ width: 200 }}
                                                    maxTagCount="responsive">

                                                    <Option value={'all'}>{'All'}</Option>
                                                    {innovation.map((val) => (
                                                        <Option value={val}>{val}</Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                        </>} */}
                                    </div>
                                    <Col>
                                        <Button
                                            type="primary"
                                            style={{ marginTop: "27%" }}
                                            onClick={() => applyFilters()}
                                        >
                                            Apply
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    <Row style={{ position: "sticky", top: 68, zIndex: 99, padding: '0px 30px' }}>
                        <Col span={24}>
                            <div className="selected-filters">
                                <label>Filters Applied:</label>
                                <div className="filter-item">Pet - <span>{Array.isArray(appliedFilters.product_pet) ? appliedFilters.product_pet.length === 0 || appliedFilters.product_pet.length === pets.length ? "ALL" : appliedFilters.product_pet.join() : "ALL"}</span></div>
                                <div className="filter-item">Technology - <span>{Array.isArray(appliedFilters.product_technology) ? appliedFilters.product_technology.length === 0 || appliedFilters.product_technology.length === techs.length ? "ALL" : appliedFilters.product_technology.join() : "ALL"}</span></div>
                                {appliedFilters.product_technology.length === 1 && isPackSize.includes(appliedFilters.product_technology[0]) && <div className="filter-item">Pack Type - <span>{Array.isArray(appliedFilters.product_package) ? appliedFilters.product_package.length === 0 || appliedFilters.product_package.length === packSize.length ? "ALL" : appliedFilters.product_package.join() : "ALL"}</span></div>}
                                {Object.keys(reportPreFilters).length !== 0 && reportPreFilters.distribution_components.length !== 0 && <div className="filter-item">Channel/Retailer - <span>{getFilterTitle()}</span></div>}
                                {Object.keys(reportPreFilters).length !== 0 && (reportPreFilters.innovation_components.length !== 0) && <div className="filter-item">Innovation Type - <span>{Array.isArray(appliedFilters.innovation_type) ? appliedFilters.innovation_type.length === 0 || appliedFilters.innovation_type.length === innovationTypes.length ? "ALL" : appliedFilters.innovation_type.join() : "ALL"}</span></div>}
                                {Object.keys(reportPreFilters).length !== 0 && (reportPreFilters.performance_components.length !== 0 || reportPreFilters.reportBrands.length !== 0 || reportPreFilters.reportChannel.length !== 0 || reportPreFilters.distribution_components.length !== 0) && <div className="filter-item">Year - <span>{Array.isArray(appliedFilters.time_year) ? appliedFilters.time_year.length === 0 || appliedFilters.time_year.length === years.length ? "ALL" : appliedFilters.time_year.join() : "ALL"}</span></div>}
                                {Object.keys(reportPreFilters).length !== 0 && (reportPreFilters.innovation_components.length !== 0) && <div className="filter-item">Launch Year - <span>{Array.isArray(appliedFilters.innovation_years) ? appliedFilters.innovation_years.length === 0 || appliedFilters.innovation_years.length === years.length ? "ALL" : appliedFilters.innovation_years.join() : "ALL"}</span></div>}
                                {Object.keys(reportPreFilters).length !== 0 && (reportPreFilters.performance_components.length !== 0 || reportPreFilters.reportBrands.length !== 0 || reportPreFilters.reportChannel.length !== 0 || reportPreFilters.distribution_components.length !== 0) && <div className="filter-item">Period - <span>{appliedFilters.time_period}</span></div>}
                                {Object.keys(reportPreFilters).length !== 0 && (reportPreFilters.innovation_components.length !== 0) && <div className="filter-item">Innovation - <span>{Array.isArray(appliedFilters.innovation) ? appliedFilters.innovation.length === 0 || appliedFilters.innovation.length === innovation.length ? "ALL" : appliedFilters.innovation.join() : "ALL"}</span></div>}
                            </div>
                        </Col>
                    </Row>
                    {Object.keys(reportPreFilters).length !== 0 && reportPreFilters.performance_components.length !== 0 && <><PerformanceSummary
                        isReport={true}
                        hideReportHeader={true}
                        reportLoader={false}
                        hideReportFilters={true}
                        reportApply={reportPerformanceSummaryApply}
                    /></>}
                    <VolumeShifting
                        isReport={true}
                        hideReportHeader={true}
                        reportLoader={false}
                        hideReportFilters={true}
                        reportApply={reportVolumeShiftingApply} />
                    <DistributionSummary
                        isReport={true}
                        hideReportHeader={true}
                        reportLoader={true}
                        hideReportFilters={true}
                        reportApply={reportDistributionSummaryApply}
                        onChannelChange={onChannelChange}
                        filterChanged={filterChanged}
                        setFilterChanged={(val) => setFilterChanged(val)}
                        channelValue={channelRetailer}
                        channelData={channels}
                        channelChange={() => getRemainingFilters('customer_channel')}
                        applyFilters={(val) => applyFilters(val)} />
                    <InnovationTracker
                        isReport={true}
                        hideReportHeader={true}
                        reportLoader={false}
                        hideReportFilters={true}
                        reportApply={reportInnovationApply}
                        reportInnovationFilters={changeInnovationFilters}
                        disablePageFilter={disableInnovationFilter}
                        setDisablePageFilter={setDisableInnovationFilter}
                        reportFilter={filters}
                        changeFilter={(label, val) => changeFilter(label, val)}
                        filterChanged={filterChanged}
                        setFilterChanged={(val) => setFilterChanged(val)}
                        onInnovationTypeChange={(val) => changeFilter('innovation_type', val)}
                        onInnovationYearChange={(val) => changeFilter('innovation_years', val)}
                        onInnovationChange={(val) => changeFilter('innovation', val)}
                        remainingFilters={(field) => getRemainingFilters(field)}
                        applyFilters={(val) => applyFilters(val)} />

                </Wrapper ></> :
            //  initialLoad ? <div className="authorization"></div> :
            <div className="authorization">
                <h3>You do not have permissions to view this report.</h3>
            </div>
        }
        </>
    );
}

export default withRouter(Report);
