
import { createSlice } from '@reduxjs/toolkit';
import CustomAxios from '../utilities/services/api.service';
import { ENDPOINTS } from '../utilities/constants';
import { openNotificationWithIcon, setWindowHistory } from '../utilities/helper';
import { isPackSize } from "../pages/volume-shifting";
import { declareAppliedFilters } from '../pages/performance-summary/psUIHelper';
import { getState } from '../store';
import { cloneDeep } from 'lodash';

/**
 * Performance Summary Slicer
 * It manages the Redux State/Reducer/Actions for Performance Summary usecase.
 */

// Define the initial state for the performance summary slice.
const initialState = {
  pets: [],
  techs: [],
  subTechs: [],
  lifestage:[],
  packSize: {},
  years: [],
  periods: [],
  granuls: [],
  kpis: [],
  table_data: [],
  loading: 0,
  filterLoading: 0,
  filters: {
    view: ["performance_summary"],
    product_pet: [],
    product_technology: [],
    product_sub_technology: [],
    product_lifestage:[],
    product_package: [],
    default_brand: ["NUTRO"],
    time_year: [],
    time_period: [],
    granuls: [],
    kpi: [],
  },
  reportRefreshPerf: false,
  appliedFilters: {
    view: ["performance_summary"],
    product_pet: [],
    product_technology: ["ALL"],
    product_package: ["ALL"],
    product_sub_technology: ["ALL"],
    product_lifestage:["ALL"],
    default_brand: ["NUTRO"],
    time_year: [],
    time_period: [],
    granuls: [],
    kpi: [],
  },
}

// Create a Redux slice for managing performance summary state and actions.
const perfSummarySlicer = createSlice({
  name: 'perfSummary',
  initialState,
  reducers: {
    resetPerformanceSummary: (state) => initialState,
    setLoading: (state, action) => ({ ...state, loading: state.loading + (action.payload ? 1 : -1) }),
    setFilterLoading: (state, action) => ({ ...state, filterLoading: state.filterLoading + (action.payload ? 1 : -1) }),
    setPets: (state, action) => ({
      ...state,
      pets: action.payload
    }),
    setTechs: (state, action) => ({
      ...state,
      techs: action.payload
    }),
    setSubTechs: (state, action) => ({
      ...state,
      subTechs: action.payload
    }),
    setPackSize: (state, action) => ({
      ...state,
      packSize: action.payload
    }),
    setLifeStage: (state, action) => ({
      ...state,
      lifestage: action.payload
    }),
    setYears: (state, action) => ({
      ...state,
      years: action.payload
    }),
    setPeriods: (state, action) => ({
      ...state,
      periods: action.payload
    }),
    setGranuls: (state, action) => ({
      ...state,
      granuls: action.payload
    }),
    setReportRefreshPerf: (state, action) => ({
      ...state,
      reportRefreshPerf: action.payload,
    }),
    setKpis: (state, action) => ({
      ...state,
      kpis: action.payload
    }),
    setTableData: (state, action) => ({
      ...state,
      table_data: action.payload
    }),
    setTopBottomSku: (state, action) => ({
      ...state,
      top_bottom_sku: action.payload
    }),
    setFilters: (state, action) => ({
      ...state,
      filters: action.payload
    }),
    setAppliedFilters: (state, action) => ({
      ...state,
      appliedFilters: action.payload
    })
  },
})

// Extract action creators from the slice.
export const { resetPerformanceSummary, setLoading, setFilterLoading, setPets, setTechs, setSubTechs,setLifeStage, setPackSize, setYears, setPeriods, setGranuls, setKpis, setTableData, setReportRefreshPerf, setTopBottomSku, setFilters, setAppliedFilters } = perfSummarySlicer.actions

export const getInitialBottomFilters = () => {
  const state = getState().perfSummary;

  const initialFilters = cloneDeep(initialState.filters);
  const oldFilters = cloneDeep(state.filters);

  return {
    ...initialFilters,
    product_pet: oldFilters.product_pet,
    product_technology: oldFilters.product_technology,
    product_sub_technology: oldFilters.product_sub_technology,
    product_lifestage: oldFilters.product_lifestage,
    product_package: oldFilters.product_package,
    time_year: oldFilters.time_year,
    time_period: oldFilters.time_period,
  }
}

const processFilters = (filters, getState) => {
  let brand = getState().profile.defaultBrand;

  let tempFilters = { ...filters };
  if (tempFilters.product_pet && tempFilters.product_pet.length === 0) {
    tempFilters["product_pet"] = "ALL";
  }
  if (
    tempFilters.product_technology &&
    tempFilters.product_technology.length === 0
  ) {
    tempFilters["product_technology"] = "ALL";
  }
  if (
    tempFilters.product_sub_technology &&
    tempFilters.product_sub_technology.length === 0
  ) {
    tempFilters["product_sub_technology"] = "ALL";
  }
  if (
    tempFilters.product_lifestage &&
    tempFilters.product_lifestage.length === 0
  ) {
    tempFilters["product_lifestage"] = "ALL";
  }
  if (
    tempFilters.product_package &&
    tempFilters.product_technology.length === 1 &&
    isPackSize.includes(tempFilters.product_technology[0]) &&
    tempFilters.product_package.length === 0
  ) {
    tempFilters["product_package"] = "ALL";
  }
  if (tempFilters.time_year && tempFilters.time_year.length === 0) {
    tempFilters["time_year"] = "ALL";
  }
  if (tempFilters.time_period && tempFilters.time_period.length === 0) {
    tempFilters["time_period"] = "ALL";
  }
  if (brand) {
    if (brand.split(":").length === 2) {
      tempFilters["product_brand"] = brand.split(":")[0];
      tempFilters["product_secondary_brand"] = brand.split(":")[1];
    } else {
      tempFilters["product_brand"] = brand;
      tempFilters["product_secondary_brand"] = "";
    }
  }

  return tempFilters;
};

// Function to set default filters and applied filters in the state
const setFiltersDefault = (
  dispatch,
  response,
  getState,
  initialLoad = false
) => {
  // Extract the required data from the state and response
  const state = getState().perfSummary;
  const filters = state.filters;
  const appliedFilters = state.appliedFilters;

  const newFilters = { ...filters };

  // Remove the selected values which are not prsent in the dropdown's items.
  newFilters.product_pet = newFilters.product_pet.filter(item => (response.data.product_pet || state.pets).includes(item));
  newFilters.product_technology = newFilters.product_technology.filter(item => ((response.data.product_package && Object.keys(response.data.product_package)) || state.techs).includes(item));
  newFilters.product_sub_technology = newFilters.product_sub_technology.filter(item => (response.data.product_sub_technology || state.subTechs).includes(item));
  newFilters.product_lifestage = newFilters.product_lifestage.filter(item => (response.data.product_lifestage || state.lifestage).includes(item));
  if (newFilters.product_package) newFilters.product_package = newFilters.product_package.filter(item => (response.data.product_package[newFilters.product_technology[0]] || state.packSize[newFilters.product_technology[0]] || []).includes(item));

  dispatch(setFilters(newFilters));  // Dispatch the new filters to update the state

  if (initialLoad) {
    const newAppliedFilters = { ...appliedFilters };

    // Filters Applied: Remove the selected values which are not prsent in the dropdown's items.
    newAppliedFilters.product_pet = newFilters.product_pet;
    newAppliedFilters.product_technology = newFilters.product_technology;
    newAppliedFilters.product_sub_technology = newFilters.product_sub_technology;
    newAppliedFilters.product_lifestage = newFilters.product_lifestage;
    if (newFilters.product_package) newAppliedFilters.product_package = newFilters.product_package;

    dispatch(setAppliedFilters(declareAppliedFilters(newAppliedFilters, { techs: state.techs, subTechs: state.subTechs , lifestage:state.lifestage,packSize: state.packSize[newFilters.product_technology[0]] })));
  }

  return newFilters;
};

const getFilterForBrandChange = (filters) => {
  const newFilters = {};
  if (filters.product_pet.length > 0) {
    newFilters.product_pet = filters.product_pet;
  }
  if (filters.product_technology.length > 0) {
    newFilters.product_pet = filters.product_pet;
    newFilters.product_technology = filters.product_technology;
  }

  return newFilters;
}

// Action to get all filters for the performance summary.
export const getAllFilters = (isBrandChanged = false, changeSelectedBrand = null) => async (dispatch, getState) => {
  let brand = getState().profile.defaultBrand;
  let tempFilters = {};
  if (brand) {
    if (brand.split(":").length === 2) {
      tempFilters["product_brand"] = brand.split(":")[0];
      tempFilters["product_secondary_brand"] = brand.split(":")[1];
    } else {
      tempFilters["product_brand"] = brand;
      tempFilters["product_secondary_brand"] = "";
    }
  }

  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters))

  dispatch(setFilterLoading(true))
  CustomAxios(ENDPOINTS.perfSummary + '?' + encodedURL, 'GET').then((response) => {
    if (response.data.product_pet) dispatch(setPets(response.data.product_pet))
    if (response.data.product_sub_technology) dispatch(setSubTechs(response.data.product_sub_technology))
    if (response.data.product_lifestage) dispatch(setLifeStage(response.data.product_lifestage))
    if (response.data.product_package && Object.keys(response.data.product_package)) dispatch(setTechs(Object.keys(response.data.product_package)))
    if (response.data.product_package) dispatch(setPackSize(response.data.product_package))
    if (response.data.time_year) dispatch(setYears(response.data.time_year))
    if (response.data.time_period) dispatch(setPeriods(response.data.time_period))

    const newFilters = setFiltersDefault(dispatch, response, getState, true);
    dispatch(getData(newFilters));

    if (isBrandChanged && changeSelectedBrand) {
      changeSelectedBrand();
      dispatch(getFilterData(getFilterForBrandChange(getState().perfSummary.filters)));
    }

    dispatch(setFilterLoading(false))
  }).catch((err) => {
    dispatch(setFilterLoading(false))
  })
}

// Action to get filter data for the performance summary.
export const getFilterData = (filters, loadPage = false, isBrandChanged = false, changeSelectedBrand = null) => async (dispatch, getState) => {
  dispatch(setFilterLoading(true))

  let tempFilters = processFilters(filters, getState);
  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters))

  CustomAxios(ENDPOINTS.perfSummary + '?' + encodedURL, 'GET').then((response) => {
    if (response.data.product_pet) dispatch(setPets(response.data.product_pet))
    if (response.data.product_sub_technology) dispatch(setSubTechs(response.data.product_sub_technology))
    if (response.data.product_lifestage) dispatch(setLifeStage(response.data.product_lifestage))
    if (response.data.product_package && Object.keys(response.data.product_package)) dispatch(setTechs(Object.keys(response.data.product_package)))
    if (response.data.product_package) dispatch(setPackSize(response.data.product_package))
    if (response.data.time_year) dispatch(setYears(response.data.time_year))
    if (response.data.time_period) dispatch(setPeriods(response.data.time_period))

    const newFilters = setFiltersDefault(dispatch, response, getState, loadPage);

    if (loadPage) {
      dispatch(getData(newFilters));
      setWindowHistory(encodeURIComponent(JSON.stringify(newFilters)));
    }

    if (isBrandChanged && changeSelectedBrand) {
      changeSelectedBrand();
      dispatch(getFilterData(getFilterForBrandChange(getState().perfSummary.filters)));
    }

    dispatch(setFilterLoading(false))
  }).catch((err) => {
    dispatch(setFilterLoading(false))
  })

}

// Action to save the report for the performance summary.
export const saveReport = (reportName, accessValue, reportId, components, createReport, setComponents, setNewReport, setOpenSaveDialog, setSaveLoading) => async (dispatch, getState) => {
  let state = getState().perfSummary
  let brand = getState().profile.defaultBrand
  let profile = getState().profile
  let userDesignation = profile.userDesignation
  let saveReport = {
    report_id: reportId,
    report_name: reportName,
    report_access: accessValue,
    user_name: profile.user.user_name,
    user_designation: userDesignation,
    // team_cluster: profile.teamCluster,
    user_email: sessionStorage.getItem('email'),
    performance_components: components,
    product_pet: state.filters.product_pet,
    product_sub_technology: state.filters.product_sub_technology,
    product_lifestage: state.filters.product_lifestage,
    product_technology: state.filters.product_technology,
    time_year: state.filters.time_year,
    time_period: state.filters.time_period,
    product_package: state.filters.product_package,
    product_brand: brand.split(":").length === 2 ? brand.split(":")[0] : brand,
    product_secondary_brand: brand.split(":").length === 2 ? brand.split(":")[1] : "",
  }
  let addToExisting = {
    report_id: reportId,
    report_name: reportName,
    user_name: profile.user.user_name,
    product_brand: brand.split(":").length === 2 ? brand.split(":")[0] : brand,
    product_secondary_brand: brand.split(":").length === 2 ? brand.split(":")[1] : "",
    time_year: state.filters.time_year,
    time_period: state.filters.time_period,
    // team_cluster: profile.teamCluster,
    user_email: sessionStorage.getItem('email'),
    performance_components: components,
  }
  setSaveLoading(true);
  CustomAxios(ENDPOINTS.saveReport, 'POST', createReport ? saveReport : addToExisting).then((response) => {
    if (response.data.error_code === undefined) {
      openNotificationWithIcon('success', response.data.message);
      setComponents([])
      setOpenSaveDialog(false)
      setNewReport(false)
    }
    else {
      openNotificationWithIcon('error', "Error saving report. Please contact support.");
    }
    setSaveLoading(false);
  })
    .catch((err) => {
      setSaveLoading(false);

      openNotificationWithIcon('error', 'Error Occured');
    })
}

// Action to get data for the performance summary based on applied filters.
export const getData = (filters) => async (dispatch, getState) => {

  dispatch(setLoading(true))

  let tempFilters = processFilters(filters, getState);

  let encodedURL = encodeURIComponent(JSON.stringify(tempFilters))
  CustomAxios(ENDPOINTS.perfSummary + '?' + encodedURL, 'GET').then((response2) => {
    let state = getState().perfSummary
    // response2.data.channel_retailer_table[0].mac_pie.percent_growth = -16.756432;
    // response2.data.channel_retailer_table[0].mac_pie.percent_growth_ytd = -20.85637;
    // response2.data.channel_retailer_table[0].mac_pie.target = 25.85637;
    // response2.data.channel_retailer_table[0].mac_pie.target_ytd = 21.85637;

    // response2.data.channel_retailer_table[0].gsv_pie.percent_growth = 29.756432;
    // response2.data.channel_retailer_table[0].gsv_pie.percent_growth_ytd = 25.85637;
    // response2.data.channel_retailer_table[0].gsv_pie.target = 35.85637;
    // response2.data.channel_retailer_table[0].gsv_pie.target_ytd = 33.85637;

    // response2.data.channel_retailer_table[0].nsv_pie.percent_growth = 35.85637;
    // response2.data.channel_retailer_table[0].nsv_pie.percent_growth_ytd = 33.85637;
    // response2.data.channel_retailer_table[0].nsv_pie.target = 29.756432;
    // response2.data.channel_retailer_table[0].nsv_pie.target_ytd = 25.85637;

    dispatch(setTableData(response2.data.channel_retailer_table))
    dispatch(setFilters({
      ...state.filters,
      time_year: [response2.data.time_year],
      time_period: [response2.data.time_period]
    }))
    dispatch(setAppliedFilters(declareAppliedFilters({
      ...processFilters(state.filters, getState),
      time_year: [response2.data.time_year],
      time_period: [response2.data.time_period]
    }, { techs: state.techs, subTechs: state.subTechs,lifestage:state.lifestage, packSize: state.packSize[state.filters.product_technology[0]] })));

    dispatch(setLoading(false))
  }).catch((err) => {
    dispatch(setLoading(false))
  })
}

export default perfSummarySlicer.reducer