import React, { useState, useEffect } from "react";
import { DualAxes } from "@ant-design/plots";
import { Line, G2 } from "@ant-design/plots";
import { each, findIndex } from "@antv/util";
import { formatNumber } from "../../utilities/helper";

const DualAxesLine = ({ data, xField, yField }) => {
  const { InteractionAction, registerInteraction, registerAction } = G2;
  
  const config = {
    data: [data, data],
    xField,
    yField,
    tooltip: {
      marker: {},
      customItems: (originalItems) => {
        // console.log("Original Items", originalItems)
        return [
          {
            color: "#FC8970",
            name: yField[0],
            value: formatNumber(originalItems[0].data[yField[0]], 1),
            marker: true,
          },
          {
            color: "#FCD374",
            name: yField[1],
            value: formatNumber(originalItems[0].data[yField[1]], 1),
            marker: true,
          },
        ];
      },
    },
    xAxis: {
      title: {
        text: "Periods",
      },
    },
    yAxis: {
      [yField[0]]: {
        title: { text: yField[0] },
        label: {
          formatter: (val) => `${formatNumber(val, 1)}`,
        },
        min: 0,
      },
      [yField[1]]: {
        title: { text: yField[1] },
        label: {
          formatter: (val) => `${formatNumber(val, 1)}`,
        },
        min: 0
      },
    },
    geometryOptions: [
      {
        geometry: "line",
        color: "#FC8970",
        point: {},
      },
      {
        geometry: "line",
        color: "#FCD374",
        point: {},
      },
    ],
    interactions: [
      {
        type: "custom-marker-interaction",
      },
    ],
  };
  
  return (
    <>
      {data ? (
        <DualAxes {...config} />
      ) : (
        <div style={{ fontSize: 30, marginLeft: "10%" }}>
          No data available to display
        </div>
      )}
    </>
  );
};

export default DualAxesLine;
