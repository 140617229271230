import { Table } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

/**
 * CustomScatterTable
 * This Component renders a custom scatter table with given columns and data.
 */
function CustomScatterTable({ columns}) {
  const {scatterTable} = useSelector((state) => state.scatter);
  return (
    <Table
      rowKey="index"
      dataSource={scatterTable}
      className="custom-table"
      columns={columns}
      scroll={{ y: 580 }}
    />
  );
}

export default CustomScatterTable;