import { createSlice } from '@reduxjs/toolkit';
import CustomAxios from '../utilities/services/api.service';
import { ENDPOINTS } from '../utilities/constants';
import { isSubBrand, openNotificationWithIcon } from '../utilities/helper';

const initialState = {
    reportMap: {},
    brandReset: 0,
    loading: 0,
    allMessages: [],
    // teamCluster: "",
    userDesignation: "",
    defaultBrand: "NUTRO",
    data_export_enabled: 0,
    latestTimeYear: [],
    competitorBrands: {},
    existingReports: [],
    genericImage: "",
    brandList: [],
    user: {},
}

const profileSlicer = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setLoading: (state, action) => ({ ...state, loading: state.loading + (action.payload ? 1 : -1) }),
        setAllMessages: (state, action) => ({
            ...state,
            allMessages: action.payload
        }),
        setReportMap: (state, action) => {
            let access = action.payload[0]
            let reports = action.payload[1]
            let existingReports = {
                ...state.reportMap,
                [access]: reports
            }
            return {
                ...state,
                reportMap: existingReports
            }
        },
        setAllReports: (state, action) => {
            return {
                ...state,
                reportMap: action.payload,
            }
        },
        setBrandReset: (state, action) => {
            return {
                ...state,
                brandReset: action.payload,
            }
        },
        setUser: (state, action) => ({
            ...state,
            user: action.payload
        }),
        updateUser: (state, action) => ({
            ...state,
            user: { ...state.user, ...action.payload }
        }),
        // setTeamCluster: (state, action) => ({
        //     ...state,
        //     teamCluster: action.payload,
        // }),
        setUserDesignation: (state, action) => ({
            ...state,
            userDesignation: action.payload,
        }),
        setdefaultBrand: (state, action) => ({
            ...state,
            defaultBrand: action.payload,
        }),
        setBrandList: (state, action) => ({
            ...state,
            brandList: action.payload,
        }),
        setGenericImage: (state, action) => ({
            ...state,
            genericImage: action.payload,
        }),
        setCompetitorBrands: (state, action) => ({
            ...state,
            competitorBrands: action.payload,
        }),
        setExistingReports: (state, action) => ({
            ...state,
            existingReports: action.payload,
        }),
        setLatestTimeYear: (state, action) => ({
            ...state,
            latestTimeYear: action.payload,
        })
    },
})

export const { setLoading, setReportMap, setAllReports, setBrandReset, setAllMessages, setUser, updateUser, setTeamCluster, setUserDesignation, setdefaultBrand, setBrandList, setLatestTimeYear, setCompetitorBrands, setGenericImage, setExistingReports } = profileSlicer.actions

export const getReports = () => async (dispatch, getState) => {
    let defaultBrand = getState().profile.defaultBrand
    // let teamCluster = getState().profile.teamCluster
    let userDesignation = getState().profile.userDesignation
    let team = ["P", "T", "M"].forEach(val => {
        let encodedURL = encodeURIComponent(JSON.stringify({
            email: sessionStorage.getItem('email'),
            report_access: val,
            // team_cluster: teamCluster,
            user_designation: userDesignation,
            product_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[0] : defaultBrand,
            product_secondary_brand: isSubBrand(defaultBrand) ? defaultBrand.split(":")[1] : ""
        }))
        dispatch(setLoading(true))
        CustomAxios(ENDPOINTS.getReports + '?' + encodedURL, 'GET').then((response) => {
            if (response.data.error_code) {
                openNotificationWithIcon('error', response.data.message)
            }
            else {
                dispatch(setReportMap([val, response.data.reports]))
            }
            dispatch(setLoading(false))
        }).catch((err) => {
            dispatch(setLoading(false))
            openNotificationWithIcon('error', 'Error Occured')
        })
    })
}

export const getCompetitorBrands = () => async (dispatch, getState) => {
    setLoading(true);
    CustomAxios(ENDPOINTS.getCompetitorBrands, 'GET').then((response) => {
        setLoading(false);
        if (response.data.errorCode === undefined) {
            dispatch(setCompetitorBrands(response.data))
            dispatch(setBrandList(Object.keys(response.data)))
        }
        else {
            console.log("Could not load competitor brands")
        }
    }).catch((err) => {
        setLoading(false);
        console.log("Could not load competitor brands api", err)
    })
}


export const getExistingReports = (reportType) => async (dispatch, getState) => {
    let defaultBrand = getState().profile.defaultBrand
    let encodedURL = encodeURIComponent(JSON.stringify({
        email: sessionStorage.getItem('email'),
        primary_brand_indicator: isSubBrand(defaultBrand) ? 0 : 1,
        product_brand: defaultBrand.split(":")[0],
        product_secondary_brand: defaultBrand.split(":")[1] ? defaultBrand.split(":")[1] : null,
        competition_view: reportType === "competition-summary" ? 1 : 0,
    }))
    CustomAxios(ENDPOINTS.existingReports + '?' + encodedURL, 'GET').then((response) => {
        if (response.data.reports_list) {
            dispatch(setExistingReports(response.data.reports_list))
        }
    }).catch((err) => {
        console.log("Error Occurred", err)
    })
}

export const getHeaderImage = (brand) => async (dispatch, getState) => {

    let encodedURL = isSubBrand(brand) ? encodeURIComponent(JSON.stringify({
        image_type: ['BR'],
        product_brand: brand.split(":")[0],
        product_secondary_brand: brand.split(":")[1]
    })) : encodeURIComponent(JSON.stringify({
        image_type: ['BR'],
        product_brand: brand.split(":")[0],
        product_secondary_brand: "",
    }))

    CustomAxios(ENDPOINTS.getGenericImage + '?' + encodedURL, 'GET').then((response) => {
        if (response.data.image_data) {
            dispatch(setGenericImage(response.data.image_data))
        }
    }).catch((err) => {
        console.log("Error Occurred", err)
    })
}

export const updateMessages = (value) => async (dispatch, getState) => {
    let state = getState().profile;
    dispatch(setAllMessages([...state.allMessages].concat([value])))
}

export const getMessages = (changeLoading) => async (dispatch) => {

    changeLoading(true)
    CustomAxios(ENDPOINTS.getMessages, 'GET').then((response) => {
        if (response.data.error_code) {
            openNotificationWithIcon('error', response.data.message)
        }
        else {
            dispatch(setAllMessages(response.data.messages))
        }
        changeLoading(false)
    }).catch((err) => {
        changeLoading(false)
        openNotificationWithIcon('error', 'Error Occured')
    })
}

export const deleteReport = (reportId, type) => async (dispatch, getState) => {
    let state = getState().profile
    let encodedURL = encodeURIComponent(JSON.stringify({
        report_id: reportId
    }))
    dispatch(setLoading(true))
    CustomAxios(ENDPOINTS.deleteReport + '?' + encodedURL, 'GET').then((response) => {
        if (response.data.error_code) {
            openNotificationWithIcon('error', response.data.message)
        }
        else {
            let temp = [...state.reportMap[type]]
            let ind = temp.findIndex((val) => val.report_id === reportId)
            if (ind > -1) {
                temp.splice(ind, 1)
            }
            let reportNew = {
                ...state.reportMap,
                [type]: temp
            }
            console.log("Report Deleted", state.reportMap, reportNew, type, temp)
            dispatch(setAllReports(reportNew))
            openNotificationWithIcon('success', response.data.message)
        }
        dispatch(setLoading(false))
    }).catch((err) => {
        dispatch(setLoading(false))
        openNotificationWithIcon('error', 'Error Occured')
    })
}


export default profileSlicer.reducer