import React from "react";
import { Checkbox, Dropdown, Menu } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { addSymbol, formatNumber, hasValue, setDeclareAppliedFilters, valueTypes } from "../../utilities/helper";
import { cloneDeep } from "lodash";

export const defaultScatterValue = ["NUTRO CORE", "NUTRO MAX", "NUTRO ULTRA"];
export const kpiColor = (value) => (value < 0 ? "red" : "green");
export const dcom = "Ecom including Omni";

export const menu = (exportData) => (
  <Menu>
    <Menu.Item key="0" onClick={exportData}>
      Export Data
    </Menu.Item>
  </Menu>
);
/**
 * KPICard
 * This component renders KPI card with various values and comparisons.
 */
export const KPICard = ({
  newReport,
  newReportComponents,
  changeNewReportComponent,
  title,
  isDefaultBrandMarsBDB,
  isCSL,
  value,
  valueSymbol,
  vsYa,
  vsYsSymbol,
  marsVsYa,
  marsVsYaSymbol,
  exportKPIs,
}) => {
  return (
    <div className="data-card">
      <div className="data-head">
        {newReport && (
          <Checkbox
            value={newReportComponents.includes("1")}
            onChange={(e) => changeNewReportComponent("1", e)}
            className="d-check"
          ></Checkbox>
        )}
        <h4>{title}</h4>
        {exportKPIs && (
          <Dropdown
            overlay={() => menu(exportKPIs)}
            trigger={["click"]}
            placement="bottomRight"
            className="d-more"
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <MoreOutlined />
            </a>
          </Dropdown>
        )}
      </div>
      <div className="data-value">
        {hasValue(value) &&
          (formatNumber(value, 1, true) === valueTypes.NA
            ? valueTypes.NA
            : addSymbol(formatNumber(value, 1), valueSymbol))}
      </div>
      <div className="comparison-wrap">
        <div className="c-box">
          <div className="c-box-title">vs YA</div>
          <div className={`c-box-value ${kpiColor(vsYa)}`}>
            {hasValue(vsYa) &&
              (formatNumber(vsYa, 1, true) === valueTypes.NA
                ? valueTypes.NA
                : addSymbol(formatNumber(vsYa, 1), vsYsSymbol))}
          </div>
        </div>
        {isCSL ? (
          !isDefaultBrandMarsBDB && (
            <div className="c-box">
              <div className="c-box-title">Mars vs YA</div>
              <div className={`c-box-value ${kpiColor(marsVsYa)}`}>
                {hasValue(marsVsYa) &&
                  (formatNumber(marsVsYa, 1, true) === valueTypes.NA
                    ? valueTypes.NA
                    : addSymbol(formatNumber(marsVsYa, 1), marsVsYaSymbol))}
              </div>
            </div>
          )
        ) : (
          <div className="c-box">
            <div className="c-box-title">
              {isDefaultBrandMarsBDB ? <>Category vs YA</> : <>Mars vs YA</>}
            </div>
            <div className={`c-box-value ${kpiColor(marsVsYa)}`}>
              {hasValue(marsVsYa) &&
                (formatNumber(marsVsYa, 1, true) === valueTypes.NA
                  ? valueTypes.NA
                  : addSymbol(formatNumber(marsVsYa, 1), marsVsYaSymbol))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Set the filters applied based on the filters selected on the UI while clicking on Apply button (Special for Conversion usecase)
export const declareAppliedFilters = (inputFilter, filtersData) => {
  const { techs, subTechs, packSize } = filtersData;
  let finalFilter = cloneDeep(inputFilter);

  // finalFilter = setDeclareAppliedFilters(finalFilter, "product_pet", pets);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_technology", techs);
  finalFilter = setDeclareAppliedFilters(finalFilter, "product_sub_technology", subTechs);
  if (finalFilter.product_package && packSize) finalFilter = setDeclareAppliedFilters(finalFilter, "product_package", packSize);

  return finalFilter;
};